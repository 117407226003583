import { useParams } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import { getMethod } from '../selectors';
import { IPayMethod } from '../interfaces/reduces.refill.interfaces';
import { IStore } from 'store/interface';

export const useMethodPay = () => {
  const { name, type } = useParams();

  const method = useSelector<IStore, IPayMethod>(
    store => getMethod(store, type, name || 'giftcards'),
    shallowEqual
  );

  return {
    method,
  };
};
