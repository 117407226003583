export default {
  GAME: '遊戲',
  Winner: '获胜者',
  Won: '奖金总额',
  Chance: '获胜几率',
  Prize: '獎品',
  'At stake': '赌注赌注',
  'Sign in through STEAM to participate': '透過STEAM登入，參加遊戲',
  'Steam trade link': 'Steam 交易链接',
  'Your chance': 'Your chance',
  'Be first to deposit': '成为第一个下注的人成为第',
  'Place a bet': '下注',
  'Deposit more': '押注更多',
  'Min bet': '最小赌注',
  'Max bet': '最高賭注',
  'Bet amount': '赌注金额',
  'max. items': '最大道具',
  hidden: '隐藏',
  Copy: '複製',
  'Winning after commission': '在委託後獲勝',
  Loading: '正在加载',
};
