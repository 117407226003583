import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { addToClearance, deleteFromClearance, resetClearance } from '../../ducks/clearance.duck';
import { hasInClearance } from '../../selector';
import { IStore } from 'store/interface';
import { IFastInventoryElement } from 'core/User';

export const useClearanceControllers = (id?: number) => {
  const dispatch = useDispatch();
  const has = useSelector<IStore, boolean>(hasInClearance(id));

  const reset = useCallback(() => {
    dispatch(resetClearance());
  }, [dispatch]);

  const toggleElement = useCallback(
    (item: IFastInventoryElement) => () => {
      if (has) dispatch(deleteFromClearance(item));
      else dispatch(addToClearance(item));
    },
    [has, dispatch]
  );

  return {
    toggleElement,
    reset,
    has,
  };
};
