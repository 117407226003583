import { URLS } from 'services/constant-urls';

export const skinItemImage = (
  src?: string,
  width: number | string = '',
  height: number | string = ''
) => {
  const hasDefaultUrl = src?.split(URLS.urlInventoryExchange)?.[1];

  return src ? `${hasDefaultUrl ? '' : URLS.urlInventoryExchange}${src}/${width}x${height}` : '';
};
