/* eslint-disable */
export default {
  Error: 'Hata',
  'Error placing a bet': 'Bahis koyma hatası',
  'Error entering code': 'Error entering code',
  'Invalid bet': 'Invalid bet',
  'Error reserving an item': 'Eşyaları gösterirken bir hata oluştu',
  'Link saved successfully': 'Linki kaydetme başarılı',
  'Skins was successful sold': 'Skins was successful sold',
  "You've successfully updated a bet": 'Bir bahsi başarılı bir şekilde güncelledin.',
  "You've successfully sell winning item": 'Kazanılan eşyayı başarılı bir şekilde sattın',
  "You've successfully take winning item": 'Kazanılan eşyayı başarıyla aldın',
  'Incorrect link. Enter the correct link and try again.':
    'Yanlış bağlantı. Doğru bağlantıyı girin ve tekrar deneyin.',
  'This is not your link. Enter your link and try again.':
    'Link yok. Linkinizi girin ve tekrardan deneyin.',
  "Don't forget to make your inventory public to get your winnings!":
    'Envanterinizi herkese açık hale getirmeyi unutmayın',
  'min. number of items per trade not exceeded': 'alım satım başına minimum ürün sayısı aşılmadı',
  'Too many requests, try again later': 'Çok fazla istek var, daha sonra tekrar dene.',
  'Your trade has been cancelled for the following reason': 'Takasınız iptal edildi çünkü',
  'cheating attempt': 'Hile girişimi',
  'max. number of items per trade exceeded': 'En yüksek eşya sayısını geçtiniz',
  'player not registered on website': 'Oyuncu siteye kaydolmamış',
  'souvenir items are not allowed': 'Hatıra eşyaları kabul edilemez',
  'some of the items cannot be appraised': 'Öğelerin bazıları değerlendirilemez',
  'some of the items are below minimum price': 'Bazı öğeler minimum fiyatın altındadır',
  'total price of a trade is below minimum': 'Toplam ücret minimum tutarın altındadır',
  'Your trade has been accepted!': 'Takas teklifiniz kabul edildi!',
  'The items have been added to the queue.': 'Eşyalar kuyruğa eklendi',
  'Your trade has been checked out!': 'Takas teklifini kontrol ediniz',
  'It is currently being accepted': 'Şu anda kabul edilmektedir',
  'Unable to send a trade': 'Hatalı takas teklifi',
  'Update your trade link in the settings of the site':
    'Takas teklifinizi ayarlardan siteye kaydediniz',
  'Enter correct trade link and make sure your inventory is not full.':
    'Doğru takas bağlantısını girin veya envanterinizin dolu olmadığından emin olun',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Hesabınız takas yapmaya uygun değildir. Daha fazla bilgi için birini takasa davet ederek öğrenebilirsin.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam şuanda çalışmıyor. Lütfen bekle ve tekrar dene.',
  'Not enough money on balance': 'Bu kadar para hesabınızda bulunmamaktadır',
  'Not enough points on balance': 'Bu kadar para hesabınızda bulunmamaktadır',
  'Game is ending, try again later': 'Oyun sona erdi, daha sonra tekrar dene',
  'Congratulations!': 'Tebrikler!',
  "You've successfully placed a bet": 'Başarıyla teklif yaptınız',
  "You've successfully placed a bet </amount>": '{{</amount>}} ile başarılı bir bahis yaptınız',
  'Item has not been found, coins have been returned': 'Eşya bulunamadı, paranız iade edildi',
  'Your order in the store has been cancelled': 'Satın alımınız market tarafından iptal edildi.',
  'The min bet is </minBet>': 'Minimum bahis {{</minBet>}}',
  'The max bet is </maxBet>': 'En yüksek bahis {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Aynı anda kırmızı ve siyaha bahis oynayamazsın.',
  "You don't have CSGOFAST.com in Steam nickname!": 'Steam takma adında CSGOFAST.com yok!',
  'You have exceeded the limit of 150 free coins per account':
    '150 paralık hesap limitinizi aştınız',
  "You've bet </amount> on the crash game #</game>!":
    'Crash oyunu {{</amount>}} bahis yaptın! #{{</game>}}',
  "You've updated coef to </coef> for crash game #</game>!":
    'crash oyunu için oranı {{</coef>}} olarak güncelledin! #{{</game>}}',
  "You've won </amount> in the crash game #</number>!":
    'crash oyununda {{</amount>}} kazandın! #{{</number>}}',
  'Code for </amount> has been created': '{{</amount>}} için kod oluşturuldu',
  'You already have an item': 'Zaten bir eşyan var',
  '</amount> refilled on your balance': '{{</amount>}} bakiyenize yeniden yüklendi',
  'We will sent it to your Steam in few moments': "Birkaç dakika içinde Steam'ine göndereceğiz",
  'Your items has been sended to game!': 'Eşyaların oyuna gönderildi!',
  '</amount> have been returned': '{{</amount>}} dolar iade edildi',
  'Item has not been found, retry in few minutes (</amount>)':
    'Eşya bulunamadı, birkaç dakika içinde tekrar dene ({{</amount>}})',
  'It is currently being processing by game bots':
    'Şu anda oyun botları tarafından işleme tabi tutuluyor',
  '</amount> have been refilled': '{{</amount>}} yeniden yüklendi',
  'Request successfully created': 'İstek başarıyla oluşturuldu',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    "{{</amount>}}'lık #{{</id>}} siparişiniz kabul edildi, Steam'de ticaret için bekleyin.",
  'Reason of order rejection': 'Sipariş reddinin nedeni',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    "{{</amount>}}'lık bahsiniz kabul edildi ve {{</time>}} saniye sonra oyuna eklenecek",
  'Your bet was added to queue, in few seconds will added to the game':
    'Bahsiniz sıraya eklendi, birkaç saniye sonra oyuna eklenecek',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Para çekme kilitlendi. Kilidi açmak için ${currency(amount)} daha kazanın',
    },
  },
};
