import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  margin-top: 20px;
  margin-bottom: 56px;

  @media (min-width: 660px) {
    flex-direction: row;
  }
`;

export const TabBtn = styled.div<{ isActive: boolean }>`
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: space-between;
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--color-dark-layout);
  background: url(${eventImagesPath.tabBgPath}) #1d1f29;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ isActive }) =>
    isActive &&
    'border: 1px solid #00D1FF;  background: linear-gradient(52deg, #0C8C94 0%,  #F209E6 100%);'}
`;

export const TabImg = styled.img`
  width: 153px;
  height: 109px;
  top: -15px;
  right: 0;
  position: absolute;
  z-index: 1;

  &[data-name='comicsCollection'] {
    width: 140px;
    top: -12px;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 24px;
`;

export const TabSpan = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 33.6px;
  color: var(--color-white);
`;

export const EmptyBlock = styled.div`
  height: 32px;
`;
