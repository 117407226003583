import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useVirtual, VirtualItem } from 'react-virtual';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button } from '@components/Common';
import { useResetSelected, useSalesSelectedIdentifiers, useSteamStatus } from '../../../hooks';

import { InventorySelectedElement } from './inventory-selected-element';
import { useTranslation } from 'react-i18next';

const isValidMode = (v: boolean): boolean => !!v;

export const InventorySelectedItems = () => {
  const { t } = useTranslation('marketplace');
  const match = useRouteMatch();
  const { handleResetSelected } = useResetSelected();
  const parentRef = useRef<HTMLDivElement>(null);
  const { register, errors, getValues } = useForm<{ isSelloutMode: boolean }>({
    mode: 'all',
    defaultValues: {
      isSelloutMode: true,
    },
  });

  const { selected } = useSalesSelectedIdentifiers();
  const { status } = useSteamStatus();

  const elements = useVirtual({
    size: selected.identifiers.length,
    parentRef,
    estimateSize: useCallback(() => 60, []),
    overscan: 2,
    horizontal: true,
  });

  const isSelloutMode = getValues('isSelloutMode');

  return (
    <Container className={selected.identifiers.length ? 'visible' : ''}>
      <div className={`${status} selected-header`}>
        <label htmlFor="isSelloutMode" className="link-light">
          {t('Sales options')}
        </label>
        <input
          ref={register({ validate: isValidMode })}
          name="isSelloutMode"
          type="checkbox"
          id="isSelloutMode"
        />
        <span>{t('Sell')}</span>
        <Button type="button" className="link-light" onClick={handleResetSelected}>
          {t('close')}
        </Button>
      </div>
      <div ref={parentRef} className={'selected-container'}>
        <div className="scrollable-container" style={{ width: elements.totalSize + 32 }}>
          {elements.virtualItems.map(({ index, start }: VirtualItem) => (
            <div
              key={selected.identifiers[index]}
              className="scrollable-container-item"
              style={{ transform: `translateX(${start + 16}px)` }}
            >
              <InventorySelectedElement
                key={selected.identifiers[index]}
                isSelloutMode={isSelloutMode}
                id={selected.identifiers[index]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="controllers">
        <Button
          as={Link}
          to={`${match.url}/publication`}
          className={`primary ${!!errors?.isSelloutMode?.type && 'disabled'}`}
        >
          <span className="sell multi-line">
            <span>{t('Confirm')}</span>
            <span>
              {t('{{count}} items for {amount}', {
                count: selected.identifiers.length,
                amount: selected.computed.amount,
                postProcess: 'with-template',
              })}
            </span>
          </span>
          <span className="tune">
            {t('Select a skin whose sales options you want to configure')}
          </span>
        </Button>
      </div>
      <div className="warn">{t('5% off applied, you can set discount in sale settings')}</div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 2.5rem 1fr 3.125rem 1.4rem;
  height: 13.25rem;
  background: var(--color-dark-600);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  transform: translate3d(0, 100%, 0);
  transition: transform 300ms var(--transition-ease-out);

  &.visible {
    transform: translate3d(0, 0, 0);
  }

  & .selected-header {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 2.25rem;
    grid-gap: 0.25rem;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--color-gray-900);
    z-index: 10;

    & span {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-white);
    }

    & label,
    & button {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
      text-transform: none;
      position: absolute;
      z-index: 10;
    }

    & label {
      left: 0.75rem;
    }

    & input {
      display: none;
    }

    & button {
      right: 0.75rem;
    }

    &.offline {
      --color-status: var(--color-red);
    }
    &.surge,
    &.delayed {
      --color-status: var(--yellow);
    }
    &.normal {
      --color-status: var(--color-green);
    }

    &:before {
      content: '';
      display: block;
      width: 100vw;
      height: 175px;
      position: absolute;
      top: 100%;
      left: 50%;
      background: radial-gradient(
        57.49% 102.86% at 49.87% 100%,
        var(--color-status) 0%,
        transparent 61.3%
      );
      opacity: 0.2;
      transform: translate3d(-50%, 0, 0) matrix(1, 0, 0, -1, 0, 0);
      transition: background 100ms ease-in;
      z-index: 5;
      pointer-events: none;
    }
  }

  & .selected-container {
    overflow: scroll hidden;
    align-self: stretch;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    & .scrollable-container {
      width: 100%;
      height: 100%;
      position: relative;
      top: -0.125rem;
      z-index: 10;

      &-item {
        width: 3.5rem;
        height: 3.5rem;
        position: absolute;
        top: 1.5rem;
        left: 0;
        transition: transform 70ms ease-out;
        overflow: hidden;
      }
    }
  }

  & .controllers {
    padding: 0 1rem;
    position: relative;
    z-index: 10;

    & a {
      position: relative;

      & .sell {
        opacity: 1;
      }

      & .tune {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        text-decoration: none;
      }

      & .tune,
      & .sell {
        transition: opacity 120ms ease-in;
      }

      &.disabled {
        background: transparent;

        & .sell {
          opacity: 0;
        }

        & .tune {
          opacity: 1;
        }
      }
    }
  }
  & .warn {
    align-self: center;
    justify-self: center;
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.3px;
    color: var(--color-gray-200);
  }
`;
