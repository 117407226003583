import React from 'react';
import {
  CancelItemContainer,
  Container,
  IconContainer,
  OperatorContainer,
  PriceRange,
  PriceRangeContainer,
  RangeContainer,
  SearchContainer,
  SearchSpan,
  SortContainer,
  WordContainer,
} from './filters-container.styles';
import { GlobalAssets } from 'assets';
import { CurrencyText } from '@components/currency';
import { SvgIcons } from 'games/CaseGame/assets';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';

interface FiltersContainerProps {
  sortProps?: {
    text: string;
    openSortDialog: () => void;
  };
  priceRangeProps?: {
    maxPrice: number | null;
    minPrice: number | null;
    openPriceRangeDialog: () => void;
    cleanRangePrice: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  };
  searchProps?: {
    query?: string;
    openSearchDialog: () => void;
    cleanSearch: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  };
  i18nextKey?: string;
}

export const FiltersContainer: React.FC<FiltersContainerProps> = ({
  sortProps,
  priceRangeProps,
  searchProps,
  i18nextKey,
}) => {
  const { t } = useTranslation(i18nextKey);
  //TODO: check is destruction will work with
  return (
    <Container className="tablet">
      {!!sortProps && (
        <SortContainer onClick={sortProps.openSortDialog}>
          <IconContainer>
            <GlobalAssets.SortIcon />
          </IconContainer>
          <span>{t(`sortBy.${sortProps.text ?? 'popular'}`)}</span>
        </SortContainer>
      )}
      {!!priceRangeProps && (
        <PriceRangeContainer onClick={priceRangeProps.openPriceRangeDialog}>
          {!isNumber(priceRangeProps.minPrice) ? (
            <span>{t('priceRange.label')}</span>
          ) : (
            <RangeContainer>
              {isNumber(priceRangeProps.maxPrice) ? (
                <>
                  <PriceRange>
                    <CurrencyText value={priceRangeProps.minPrice} />
                  </PriceRange>
                  {'-'}
                  <PriceRange>
                    <CurrencyText value={priceRangeProps.maxPrice} />
                  </PriceRange>
                </>
              ) : (
                <>
                  <OperatorContainer>{'>'}</OperatorContainer>
                  <PriceRange>
                    <CurrencyText value={priceRangeProps.minPrice} />
                  </PriceRange>
                </>
              )}
              <CancelItemContainer>
                <SvgIcons.CancelIcon onClick={priceRangeProps.cleanRangePrice} />
              </CancelItemContainer>
            </RangeContainer>
          )}
        </PriceRangeContainer>
      )}
      {!!searchProps && (
        <SearchContainer>
          {!searchProps.query ? (
            <SvgIcons.SearchIcon onClick={searchProps.openSearchDialog} />
          ) : (
            <WordContainer onClick={searchProps.openSearchDialog}>
              <SearchSpan>{searchProps.query}</SearchSpan>
              <CancelItemContainer>
                <SvgIcons.CancelIcon onClick={searchProps.cleanSearch} />
              </CancelItemContainer>
            </WordContainer>
          )}
        </SearchContainer>
      )}
    </Container>
  );
};
