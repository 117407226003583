import { ICrossService } from './interfaces/crossServices.reducer.inteface';

export const getMirrorConfig = ({ cross }: ICrossService) => cross.crossData.mirrorConfig;

export const getGamesList = ({ cross }: ICrossService) => cross.crossData.gamesList;

export const getCrossGameId = ({ cross }: ICrossService) => cross.crossData.gameId;

export const getCrossAppId = ({ cross }: ICrossService) =>
  cross.crossData.gamesList.find(({ id }) => id === cross.crossData.gameId);
