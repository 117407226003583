import React, { FC, useState } from 'react';
import { Container, TeamTitle, TeamsContainer } from './Players.styles';
import { useGetCaseBattleGameData, useGetTeams } from 'games/CaseBattle/hooks';
import { Player } from '../Player';
import { PlayerSkeleton } from '../Skeletons/PlayerSkeleton';
import { calculateGap } from 'games/CaseBattle/utils';
import { DEFAULT_PRICE_HEIGHT } from 'games/CaseBattle/constants';

export const Players: FC = () => {
  const [priceHeight, setPriceHeight] = useState(DEFAULT_PRICE_HEIGHT);
  const [containerWidth, setContainerWidth] = useState(0);

  const {
    data: { game, gameStatus },
  } = useGetCaseBattleGameData();
  const { players_in_team, players_count, creator_id, settings, winner_ids } = game;
  const { delayedTeams } = useGetTeams();

  const maxTotalPrice = Math.max(
    ...delayedTeams.map(team => Math.max(...team.players.map(item => item.totalPrice)))
  );

  return (
    <Container
      isTeam={players_in_team > 1}
      gap={calculateGap(players_count)}
      playersCount={players_in_team > 1 ? players_count / players_in_team : players_count}
    >
      {delayedTeams.map(team => (
        <TeamsContainer key={team.team_id} playersCount={players_in_team}>
          {team.players.map(player => {
            if (player.playerInfo.name) {
              return (
                <Player
                  key={player.playerInfo.user_id}
                  player={player.playerInfo}
                  hasAllPlayers={players_count === 4}
                  totalPrice={player.totalPrice}
                  creatorId={creator_id}
                  maxTotalPrice={maxTotalPrice}
                  gameStatus={gameStatus}
                  winnerIds={winner_ids}
                  priceHeight={priceHeight}
                  setPriceHeight={(height: number) => setPriceHeight(height)}
                  containerWidth={containerWidth}
                  setContainerWidth={(width: number) => setContainerWidth(width)}
                />
              );
            } else {
              return <PlayerSkeleton key={player.playerInfo.user_id} />;
            }
          })}
        </TeamsContainer>
      ))}
      {players_in_team > 1 && <TeamTitle>{settings.winnersSplit ? 'VS TEAM' : 'VS'}</TeamTitle>}
    </Container>
  );
};
