import * as React from 'react';
import styled from 'styled-components';
import { IQuest } from '../../interfaces/reducer.leaders.interfaces';
import { useTranslation } from 'react-i18next';
import { gameIconConfig } from '../../quests.utils';
import games from 'games';

interface IQuestItem extends IQuest {
  takeRewarHandle: () => void;
}

export const QuestWidgetItem: React.FC<IQuestItem> = ({
  userCount = 0,
  name,
  params,
  targetCount,
  gameTypeId,
  type,
  isHidden,
  isOpened,
  rewardTaken,
  takeRewarHandle,
}) => {
  const gameType = gameIconConfig[gameTypeId.toString()];
  const { t } = useTranslation();

  return (
    <QuestContainer
      progress={(userCount / targetCount) * 100}
      type={type}
      gameType={gameType}
      special={isHidden}
      blocked={!isOpened}
      completed={userCount === targetCount}
    >
      <div className="quest-progress">
        <div>
          {gameType && (
            <div className="quest-progress_type-game">
              {(!isHidden || isOpened) && <i className={gameType.icon} />}
            </div>
          )}
        </div>
        <span className="quest-progress_type">
          {!rewardTaken && (
            <>
              <i className="icon-coupon" />
              {' 1'}
            </>
          )}
        </span>
        <span>
          {(!isHidden || isOpened) && !rewardTaken && (
            <>
              {userCount}/{targetCount}
            </>
          )}
          {rewardTaken && <>{t('Completed')}</>}
        </span>
      </div>

      <div className="quest-description">
        {!rewardTaken && userCount === targetCount ? (
          <button className="quest-progress_take" onClick={takeRewarHandle}>
            <span>{t('Take')}</span>
            <span className="quest-progress_type">
              <i className="icon-coupon" />
              {' 1'}
            </span>
          </button>
        ) : isHidden && !isOpened ? (
          t('Special quest. Unlocked after completing all weekly quests')
        ) : (
          <>
            {t(name, {
              ...params,
              postProcess: 'with-template',
            })}
            {gameType && <>{gameType.name}</>}
          </>
        )}
      </div>
    </QuestContainer>
  );
};

type QuestContainer = {
  progress: number;
  type: string;
  special: boolean;
  completed: boolean;
  blocked: boolean;
  gameType: {
    name: string;
    icon: string;
  };
};

const QuestContainer = styled.div.attrs((props: QuestContainer): any => ({
  ...props,
  gameType:
    props.gameType &&
    games[props.gameType.name.toLowerCase()] &&
    games[props.gameType.name.toLowerCase()].type,
}))`
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-gap: 0.2rem;
  flex-basis: calc(100% - 4.2rem - 10%);
  flex-shrink: 0;
  min-height: 110px;
  margin-right: 0.8rem;
  padding: 0.8rem 0.9rem;

  border-radius: var(--size-radius-m);
  background-color: var(--color-gray-700);

  position: relative;
  z-index: 1;
  overflow: hidden;

  font-size: 0.75rem;

  @media screen and (max-width: 350px) {
    font-size: 0.65rem;
  }

  &:before {
    content: '';
    display: block;
    width: ${p => p.progress}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--color-gray-300);
  }

  &:last-of-type {
    margin-right: 0;
  }

  & > .quest-description:first-of-type {
    align-self: center;
  }

  & > .quest-description {
    align-self: end;
    font-size: inherit;
    line-height: 1.33;
    color: ${p => (p.completed ? 'var(--color-gray-100)' : 'var(--color-white)')};
    opacity: ${p => p.blocked && 0.3};
    hyphens: auto;
  }

  & .quest-progress_take {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: var(--color-gray-700);
    border: 0;

    & > span:not(.quest-progress_type) {
      font-weight: normal;
      line-height: 1.25;
      letter-spacing: 0.5px;
      color: var(--color-white);
      margin-right: 0.4rem;
    }

    & > span {
      font-size: inherit;
    }
  }

  & .quest-progress_type {
    color: ${p => (p.special ? 'var(--special-quest)' : `var(--${p.type}-quest)`)};
    font-weight: bold;
    font-size: 0.8rem;
  }

  & > .quest-progress {
    display: grid;
    grid-template-columns: 30px 1fr 1fr;
    grid-gap: 1rem;
    align-self: center;

    position: relative;

    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.23;
    color: var(--color-white);

    & > span:not(.quest-progress_type) {
      justify-self: end;
      text-transform: uppercase;
      align-self: center;
      font-weight: bold;
      letter-spacing: 0.5px;
      opacity: ${p => p.blocked && 0.3};
      ${p =>
        p.special &&
        p.blocked &&
        `
          background-color: #303b42 ;
          width: 50%;
          height: 50%;
          opacity: 1;
      `};
    }

    & .quest-progress_type-game {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border: 2px solid;
      border-radius: 50%;
      position: absolute;
      top: -0.5rem;
      left: -4px;

      border-color: ${p => (p.gameType ? `var(--color-${p.gameType})` : 'var(--yellow)')};

      ${p =>
        p.special &&
        p.blocked &&
        `
        border-color: #303b42!important;
      `};

      &:before,
      &:after {
        position: absolute;
      }

      &:before {
        content: '';
        width: 34px;
        height: 34px;
        background-color: var(--color-gray-700);
        z-index: -1;
        border-radius: 50%;
      }

      &:after {
        width: 12px;
        height: 12px;
        top: -4px;
        right: -5px;
        border-radius: 50%;
        font-family: icomoon;
      }

      ${p =>
        p.completed &&
        `
        &:after {
          content: "\\e940";
          box-sizing: content-box;
          font-size: 0.4rem;
          background: red;
          text-align: center;
          display: inline-block;
          line-height: 12px;
          color: var(--color-gray-700); 
          background-color: var(--color-white);
          border: 2px solid var(--color-gray-700);
        }
      `};

      ${p =>
        p.blocked &&
        `
        &:after {
          content: "\\e941";
          text-align: center;
          border-radius: none;
          width: 14px;
          height: 15px;
          line-height: 15px;
          text-shadow: 2px 2px 0 var(--color-gray-700), -2px -2px 0 var(--color-gray-700), 2px -2px 0 var(--color-gray-700), -2px 2px 0 var(--color-gray-700), 2px 2px 0 var(--color-gray-700);
        }
      `};

      ${p =>
        p.special &&
        `
        &:after {
          content: "";
          display: none;
        }
      `};

      & > i {
        font-size: 1rem;
      }
    }
  }
`;
