/* eslint-disable no-template-curly-in-string */
export default {
  'WHAT IS CSGOFAST.COM?': 'WHAT IS CSGOFAST.COM?',

  'The Project CSGOFAST.COM is a Service for Counter-Strike: Global Offensive players. Right here everyone can try his luck and get awesome skins.':
    'The Project CSGOFAST.COM is a Service for Counter-Strike: Global Offensive players. Right here everyone can try his luck and get awesome skins.',

  'General rules and questions': 'General rules and questions',
  // links
  'What games are there in the project?': 'What games are there in the project?',
  'How to take part in the game?': 'How to take part in the game?',
  'How to place a bet?': 'How to place a bet?',
  'What is acceptable as a bet?': 'What is acceptable as a bet?',
  'What are the max and min bets?': 'What are the max and min bets?',
  'How many skins can be accepted per one bet?': 'How many skins can be accepted per one bet?',
  'How many bets can be placed per one round?': 'How many bets can be placed per one round?',
  'What max number of skins is accepted per one round?':
    'What max number of skins is accepted per one round?',
  'How long is one round?': 'How long is one round?',
  'How are skins appraised?': 'How are skins appraised?',
  'What is the Ticket System?': 'What is the Ticket System?',
  'What is Fair Game?': 'What is Fair Game?',
  'How is the winner determined?': 'How is the winner determined?',
  'What does the winner get at the end of a round?':
    'What does the winner get at the end of a round?',
  'What Service Commission is there?': 'What Service Commission is there?',
  'How to increase the chances of winning?': 'How to increase the chances of winning?',
  'When is the winner determined?': 'When is the winner determined?',
  'How to get the winning?': 'How to get the winning?',
  'Can I accept a Trade Offer later?': 'Can I accept a Trade Offer later?',
  'How to get a bonus to my winning?': 'How to get a bonus to my winning?',
  'What is Referral?': 'What is Referral?',
  'I`ve got an issue Where can I apply for help?': 'I`ve got an issue. Where can I apply for help?',
  'How does tech support work? How long should I wait for reply?':
    'How does tech support work? How long should I wait for reply?',

  'Bet questions': 'Bet questions',
  // links
  'Why does a bot cancel my bet?': 'Why does a bot cancel my bet?',
  'What is Escrow?': 'What is Escrow?',
  'My skins went to another game': 'My skins went to another game',
  'My skins did not go to any game': 'My skins did not go to any game',
  "I've canceled a trade, but a bet went in": "I've canceled a trade, but a bet went in",
  'I placed a bet accidently, how to get my skins back?':
    'I placed a bet accidently, how to get my skins back?',

  'Getting your winning': 'Getting your winning',
  //links
  'When does the winning come?': 'When does the winning come?',
  'I did not get the winning according to Terms of Service':
    'I did not get the winning according to Terms of Service.',
  'The winning has come, but some skins are not available for a Trade Offer':
    'The winning has come, but some skins are not available for a Trade Offer',
  'I canceled a Trade Offer with my winning accidently':
    'I canceled a Trade Offer with my winning accidently',
  'How to get my winning if I didn`t get it for some reason or other?':
    'How to get my winning if I didn`t get it for some reason or other?',
  "I've got a trade ban I want my winning to be sent to my secondary account or friend`s account":
    "I've got a trade ban. I want my winning to be sent to my secondary account or friend`s account.",

  'Referral program': 'Referral program',
  //links
  'What is it?': 'What is it?',
  'Who is recognized as my referral?': 'Who is recognized as my referral?',
  'How many coins can it be received?': 'How many coins can it be received?',
  'How to determine the percent and what is a "Level" in Referral Program?':
    'How to determine the percent and what is a "Level" in Referral Program?',
  'What can I exchange my Coins for?': 'What can I exchange my Coins for?',
  'When will Coins for referrals be sent to me?': 'When will Coins for referrals be sent to me?',
  'Where should my code be entered?': 'Where should my code be entered?',
  'What bonuses does the referral get?': 'What bonuses does the referral get?',

  'Service commission': 'Service commission',
  'What is Service Commission?': 'What is Service Commission?',
  'My skins were taken as a commission How to get them back or exchange for others?':
    'My skins were taken as a commission. How to get them back or exchange for others?',
  'Service has deducted a big Service Commission': 'Service has deducted a big Service Commission',

  'Chat rules': 'Chat rules',

  // GENERAL

  // How to take part in the game?
  '1 Sign in through Steam': '1. Sign in through Steam.',
  '2 Put an actual Trade Offer link into a Settings (You can get your actual link here)':
    '2. Put an actual Trade Offer link into a PersonalArea.',
  '3 Make your inventory public in the Steam Profile':
    '3. Make your inventory public in the Steam Profile.',
  '4 Select the game, place a bet and try your luck!':
    '4. Select the game, place a bet and try your luck!',
  // How to place a bet?
  'For Classic Game and Fast Game: Select the game mode and click at Deposit more or Place a bet Select skins that you want to deposit as a bet from your inventory Click at Make Offer Bot accepts your bet and places it into the game':
    'For Classic Game and Fast Game: Select the game mode and click at Deposit more or Place a bet. Select skins that you want to deposit as a bet from your inventory. Click at Make Offer. Bot accepts your bet and places it into the game.',
  'For Double Game: Enter a wishful number of Fast Coins as a bet and click at the color slot button, that will win in the round from your coin of view The bet of a chosen color will be placed automatically':
    'For Double Game: Enter a wishful number of Fast Coins as a bet and click at the color slot button, that will win in the round from your coin of view. The bet of a chosen color will be placed automatically.',
  // What is acceptable as a bet?
  'For Classic Game and Fast Game: Bot accepts skins from Counter-Strike: Global Offensive only Trade Offers with the items from other games or containing souvenir items (other than gift sets) are canceled automatically':
    'For Classic Game and Fast Game: Bot accepts skins from Counter-Strike: Global Offensive only. Trade Offers with the items from other games or containing souvenir items (other than gift sets) are canceled automatically.',
  'For Double Game: In this game Fast Coins are acceptable as bets The ways to get Fast Coins are listed here':
    'For Double Game: In this game Fast Coins are acceptable as bets.',
  // What are the max and min bets?
  'A bet sum limit is indicated in the selected game Inappropriate bets are canceled automatically':
    'A bet sum limit is indicated in the selected game. Inappropriate bets are canceled automatically.',
  // How many skins can be accepted per one bet?
  'For Classic Game and Fast Game: The acceptable number of skins per one bet is indicated in the selected game mode Inappropriate bets are canceled automatically':
    'For Classic Game and Fast Game: The acceptable number of skins per one bet is indicated in the selected game mode. Inappropriate bets are canceled automatically.',
  // How many bets can be placed per one round?
  'The number of accepted bets per one round is different for every game mode. Have a look at more details about bet limitations per round for Classic Game, Fast Game and Double Game.':
    'The number of accepted bets per one round is different for every game mode. Have a look at more details about bet limitations per round for Classic Game, Fast Game and Double Game.',
  // What max number of skins is accepted per one round?
  'The max number of skins (if it is prescribed) on the round deposit for Classic Game and Fast Game is indicated in the selected game mode':
    'The max number of skins (if it is prescribed) on the round deposit for Classic Game and Fast Game is indicated in the selected game mode.',
  // How long is one round?
  'The allotted time (if it is prescribed) of one round is indicated in the selected game':
    'The allotted time (if it is prescribed) of one round is indicated in the selected game.',
  // How are skins appraised?
  'Skins are appraised regularly in accordance with Markets Prices and set up as an average sum for a certain period of time Site Skin prices can differ from prices of Steam Market':
    'Skins are appraised regularly in accordance with Markets Prices and set up as an average sum for a certain period of time. Site Skin prices can differ from prices of Steam Market.',
  // What is the Ticket System?
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'When players place the bets they get virtual tickets, one of them becomes a winner. If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on.',
  'The higher and bigger your bets, the more tickets you get':
    'The higher and bigger your bets, the more tickets you get.',
  'The more tickets you get, the greater chance to become a winner':
    'The more tickets you get, the greater chance to become a winner.',
  // What is Fair Game?
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (eg 0282796734) and encrypts it through the sha224 algorithm The result is displayed at the beginning of a round':
    'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0.282796734) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round.',
  'At the end of the round the Service multiplies the number by the general number of all tickets thus getting the number of a winner':
    'At the end of the round the Service multiplies the number by the general number of all tickets thus getting the number of a winner.',
  'An example: At the end of a round the general deposit is 1000$ (there are 100000 tickets in the game), a generated number was 0.282796734 The Service multiplies the number 0282796734 by 100000 thus getting the number of a winner 28279 that is being displayed at the moment of a raffle':
    'An example: At the end of a round the general deposit is 1000$ (there are 100000 tickets in the game), a generated number was 0.282796734. The Service multiplies the number 0.282796734 by 100000 thus getting the number of a winner 28279 that is being displayed at the moment of a raffle.',
  "A Fair Game Principle: we can't know a tickets number that is displayed at the end of a round in advance and the multiplied random number is generated at the very beginning of a game That is why we have no opportunity to influence the results of a raffle":
    "A Fair Game Principle: we can't know a tickets number that is displayed at the end of a round in advance and the multiplied random number is generated at the very beginning of a game. That is why we have no opportunity to influence the results of a raffle.",
  // How is the winner determined?
  'The ways of winner determination differ in every game mode Have a look at winner determination terms for Classic Game, Fast Game and Double Game':
    'The ways of winner determination differ in every game mode. Have a look at winner determination terms for Classic Game, Fast Game and Double Game.',
  // What does the winner get at the end of a round?
  'The Classic Game and Fast Game winner gets all deposited skins except a Service Commission Have a look at Service Commission Terms for Classic Game and Fast Game':
    'The Classic Game and Fast Game winner gets all deposited skins except a Service Commission. Have a look at Service Commission Terms for Classic Game and Fast Game.',
  'In the Double Game if the winner is black or red slot, the gamer wins the placed bet multiplied by 2. The green slot returns the placed bet multiplied by 14.':
    'In the Double Game if the winner is black or red slot, the gamer wins the placed bet multiplied by 2. The green slot returns the placed bet multiplied by 14.',
  // What Service Commission is there?
  'A Service Commission depends on a selected game and general round deposit Have a look at Service Commission Terms for Classic Game and Fast Game':
    'A Service Commission depends on a selected game and general round deposit. Have a look at Service Commission Terms for Classic Game and Fast Game',
  'There is no Commission in the Double Game': 'There is no Commission in the Double Game.',
  // How to increase the chances of winning?
  'The higher and bigger your bets in the Fast and Classic games, the greater chance to become a winner':
    'The higher and bigger your bets in the Fast and Classic games, the greater chance to become a winner.',
  "A max bet doesn't ensure a win, but it increases a chance of winning vastly":
    "A max bet doesn't ensure a win, but it increases a chance of winning vastly.",
  'In the Double Game everything depends on your luck and ability to guess the winner color slot of the round':
    'In the Double Game everything depends on your luck and ability to guess the winner color slot of the round.',
  'Play any game you like and try your luck!': 'Play any game you like and try your luck!',
  // When is the winner determined?
  'Have a look at terms of round winner determination in the Classic Game, Fast Game, Double Game, Crash Game':
    'Have a look at terms of round winner determination in the Classic Game, Fast Game, Double Game, Crash Game.',
  // How to get the winning?
  "Trade Offers with the winner's prize are transferred by our bot in automatic mode":
    "Trade Offers with the winner's prize are transferred by our bot in automatic mode.",
  'If you win you will see a pop-up with congratulations and the won sum':
    'If you win you will see a pop-up with congratulations and the won sum.',
  'A Trade Offer is sent to the indicated Trade Offer link in your Settings within 5-30 minutes You should accept the Trade Offer':
    'A Trade Offer is sent to the indicated Trade Offer link in your PersonalArea within 5-30 minutes. You should accept the Trade Offer.',
  'Sending of items can rarely take longer period of time because of Steam Lags or issues with Mobile Confirmation':
    'Sending of items can rarely take longer period of time because of Steam Lags or issues with Mobile Confirmation.',
  'If you have got any issues with getting your prize, contact our 24/7 tech support:':
    'If you have got any issues with getting your prize, contact our 24/7 tech support: ',
  // Can I accept a Trade Offer later?
  'It is necessary to accept a winning as soon as you get a Trade Offer from our bot':
    'It is necessary to accept a winning as soon as you get a Trade Offer from our bot.',
  'A winning that had not been accepted for an hour is refused It is necessary to avoid bot inventory overloading in order to keep system working without malfunctions':
    'A winning that had not been accepted for an hour is annulled. It is necessary to avoid bot inventory overloading in order to keep system working without malfunctions.',
  // How to get a bonus to my winning?
  'Add to any part of your Steam Nickname an adscript CSGOFASTCOM and get 5% to your winning The adscript must be written in one word This bonus operates at CLASSIC GAME and FAST GAME only':
    'Add to any part of your Steam Nickname an adscript CSGOFAST.COM and get 5% to your winning. The adscript must be written in one word. This bonus operates at CLASSIC GAME and FAST GAME only.',
  // What is Referral?
  'There is a Referral Program in our Project You get some Fast Coins for every invited player and his games Then you can exchange these coins for skins in the Store There is detailed information in the Referral':
    'There is a Referral Program in our Project. You get some Fast Coins for every invited player and his games. Then you can exchange these coins for skins in the Store. There is detailed information in the Referral.',
  // I`ve got an issue. Where can I apply for help?
  "It's our pleasure to help you in case of any issues occurred to our Service In order to solve your issue write a letter to our tech support: ":
    "It's our pleasure to help you in case of any issues occurred to our Service. In order to solve your issue write a letter to our tech support: ",
  // How does tech support work? How long should I wait for reply?
  'Our tech support works 24/7 If you need help write about your issue to':
    'Our tech support works 24/7. If you need help write about your issue to ',
  'As a rule, we reply within 60 min, but there might be longer period because of many requests or holidays':
    'As a rule, we reply within 60 min, but there might be longer period because of many requests or holidays.',

  // BET QUESTIONS

  // Why does a bot cancel my bet?
  'Make sure that:': 'Make sure that:',
  'You send skins from Counter-Strike: Global Offensive, there are no souvenir items or skins from other games;':
    'You send skins from Counter-Strike: Global Offensive, there are no souvenir items or skins from other games;',
  'Number of deposited skins meets the rules of a selected game;':
    'Number of deposited skins meets the rules of a selected game;',
  'The value of a bet meets the rules of a selected game;':
    'The value of a bet meets the rules of a selected game;',
  'Your account is attached to your Steam Guard Mobile Authenticator minimum 7 days;':
    'Your account is attached to your Steam Guard Mobile Authenticator minimum 7 days;',
  'While trading an offer you should not ask a bot for skins':
    'While trading an offer you should not ask a bot for skins.',
  // What is Escrow?
  "- a new type of SteamGuard, that secures users accounts from unauthorized trades Starting on Dec 9, it's necessary to confirm all Trade Offers by Steam Guard Mobile Authenticator, which is attached to your account minimum 7 days Otherwise, Trade Offers are frozen for 12 hours to secure the users Our bot accepts the Trade Offers which are confirmed by Steam Guard Mobile Authenticator only":
    " - a new type of SteamGuard, that secures users accounts from unauthorized trades. Starting on Dec 9, it's necessary to confirm all Trade Offers by Steam Guard Mobile Authenticator, which is attached to your account minimum 7 days. Otherwise, Trade Offers are frozen for 12 hours to secure the users. Our bot accepts the Trade Offers which are confirmed by Steam Guard Mobile Authenticator only.",
  // My skins went to another game
  'The time period of placing a bet in a round depends on the load upon the Steam Servers and can range from 2 up to 180 seconds Items can rarely be added after a longer period of time because of extreme load upon Steam If a bet is placed close to the end of a current round, it can be placed to the next game We are not responsible for the situations which happen because of Steam Lags':
    'The time period of placing a bet in a round depends on the load upon the Steam Servers and can range from 2 up to 180 seconds. Items can rarely be added after a longer period of time because of extreme load upon Steam. If a bet is placed close to the end of a current round, it can be placed to the next game. We are not responsible for the situations which happen because of Steam Lags.',
  // My skins did not go to any game
  '1 Make sure, you confirmed a bet by Steam Guard Mobile Authenticator':
    '1. Make sure, you confirmed a bet by Steam Guard Mobile Authenticator.',
  '2 Check the status of a Trade Offer with our bot':
    '2. Check the status of a Trade Offer with our bot.',
  '3 If a Trade Offer is accepted, but the bet is not placed to any game contact our tech support: support@csgofastcom':
    '3. If a Trade Offer is accepted, but the bet is not placed to any game contact our tech support: support@csgofast.com.',
  // I’ve canceled a trade, but a bet went in
  'Our bots accept incoming trades with min lags, then they send skins to the game, thus it is almost impossible to cancel a deposited bet':
    'Our bots accept incoming trades with min lags, then they send skins to the game, thus it is almost impossible to cancel a deposited bet.',
  'If you cancel your bet and get a confirmation "Trade Offer Cancelled", but the skins play in, so it means your cancellation was made too late, and you got a confirmation "Trade Offer Cancelled", because of Steam Error We can\'t return the skins which have been already raffled':
    'If you cancel your bet and get a confirmation "Trade Offer Cancelled", but the skins play in, so it means your cancellation was made too late, and you got a confirmation ”Trade Offer Cancelled”, because of Steam Error. We can\'t return the skins which have been already raffled.',
  // I placed a bet accidently, how to get my skins back?
  'We are working only with Steam Trade Offer System Every bet is confirmed by Steam Guard Mobile Authenticator Thus, the situations when your items play in the game without your confirmation or accidently are impossible We cannot return the deposited and raffled skins':
    'We are working only with Steam Trade Offer System. Every bet is confirmed by Steam Guard Mobile Authenticator. Thus, the situations when your items play in the game without your confirmation or accidently are impossible. We cannot return the deposited and raffled skins.',

  // GETTING YOUR WINNING

  // When does the winning come?
  'The winning is sent by our bot within 5-30 minutes automatically The sending rarely takes more time because of Steam Lags or issues with Mobile confirmations':
    'The winning is sent by our bot within 5-30 minutes automatically. The sending rarely takes more time because of Steam Lags or issues with Mobile confirmations.',
  // I did not get the winning according to Terms of Service.
  "If you won and didn't receive the winning within indicated allotted time, check the following:":
    "If you won and didn't receive the winning within indicated allotted time, check the following:",
  'You have entered a proper link in the Settings;':
    'You have entered a proper link in the Settings;',
  'You have enough space for new items in your inventory to accept the winning (less than 1000 items for Counter-Strike: Global Offensive);':
    'You have enough space for new items in your inventory to accept the winning. (less than 1000 items for Counter-Strike: Global Offensive);',
  'Your inventory is public': 'Your inventory is public.',
  'If there are some errors in above mentioned coins and you solve them, our bot will send you a Trade Offer repeatedly within 5-60 minutes (it depends on the load upon Steam Server)':
    'If there are some errors in above mentioned coins and you solve them, our bot will send you a Trade Offer repeatedly within 5-60 minutes (it depends on the load upon Steam Server).',
  // The winning has come, but some skins are not available for a Trade Offer
  'If you get a Trade Offer with unavailable skins, contact our tech support support@csgofastcom':
    'If you get a Trade Offer with unavailable skins, contact our tech support support@csgofast.com.',
  // I canceled a Trade Offer with my winning accidently
  'If you cancel a Trade Offer, contact our tech support support@csgofastcom':
    'If you cancel a Trade Offer, contact our tech support support@csgofast.com.',
  // How to get my winning if I didn`t get it for some reason or other?
  "If you don't get your winning for some reason or other, contact our tech support support@csgofastcom After a prompt investigation we will send you the winning in a manual way In this case we reserve the right to send you the winning by random skins that are equal to the winning sum":
    "If you don't get your winning for some reason or other, contact our tech support support@csgofast.com. After a prompt investigation we will send you the winning in a manual way. In this case we reserve the right to send you the winning by random skins that are equal to the winning sum.",
  // I’ve got a trade ban. I want my winning to be sent to my secondary account or friend`s account.
  "We don't send the winnings to other accounts": "We don't send the winnings to other accounts.",
  'For the avoidance of scam we send the winnings to the account that won only':
    'For the avoidance of scam we send the winnings to the account that won only.',

  // REFERRAL PROGRAM

  // What is it?
  'Referral Program - an encouragement system of users, who invite new players (referrals) to our project You will get Coins for each referral and game he/she plays':
    'Referral Program - an encouragement system of users, who invite new players (referrals) to our project. You will get Coins for each referral and game he/she plays.',
  // Who is recognized as my referral?
  'Referral is a new project user, who follows your referral link or enters your personal code If the invited user had been signed in previously, the Referral Program will not accept him':
    'Referral is a new project user, who follows your referral link or enters your personal code. If the invited user had been signed in previously, the Referral Program will not accept him.',
  // How many coins can it be received?
  'You get Coins for each referral, who is signed in following your referral link or entering your personal code The number of Coins depends on your level in Referral System Also, you get a certain percent of referral`s bets in any game of our project':
    'You get Coins for each referral, who is signed in following your referral link or entering your personal code. The number of Coins depends on your level in Referral System. Also, you get a certain percent of referral`s bets in any game of our project.',
  'Referral should have a public Steam account and Counter-Strike: Global Offensive game to get the bonus':
    'Referral should have a public Steam account and Counter-Strike: Global Offensive game to get the bonus',
  //'How to determine the percent and what is a "Level" in Referral Program?'
  'Percent, that you get of your referrals` bets, depends on your level in Referral System - the higher your level, the higher per cent you get':
    'Percent, that you get of your referrals` bets, depends on your level in Referral System - the higher your level, the higher per cent you get.',
  'You can raise your level in Referral System increasing the number of your referrals - more referrals you have, the higher level you get':
    'You can raise your level in Referral System increasing the number of your referrals - more referrals you have, the higher level you get.',
  // What can I exchange my Coins for?
  'Collected Coins can be exchanged for skins in the Store Also, they can be used in order to play in Double Game':
    'Collected Coins can be exchanged for skins in the Store. Also, they can be used in order to play in Double Game.',
  // When will Coins for referrals be sent to me?
  'The Coins are sent to you after your referral has been signed in following your link or entering your referral code The page information is updated every 15 minutes':
    'The Coins are sent to you after your referral has been signed in following your link or entering your referral code. The page information is updated every 15 minutes.',
  // Where should my code be entered?
  'Code entering for new users is available on the page of Referral System':
    'Code entering for new users is available on the page of Referral System.',
  'Referral should enter the code within 15 minutes since the moment of the first authorization':
    'Referral should enter the code within 15 minutes since the moment of the first authorization.',
  // What bonuses does the referral get?
  'Everyone, who becomes a referral, gets the number of Coins, which depends on the Referral level of user who invites, to his/her account':
    'Everyone, who becomes a referral, gets the number of Coins, which depends on the Referral level of user who invites, to his/her account.',

  'Forbidden to use:': 'Forbidden to use:',
  'It is not allowed to use official CSGOFASTCOM sources: groups, social networking pages, forums, etc':
    '1. It is not allowed to use official CSGOFAST.COM sources: groups, social networking pages, forums, etc.',
  'Use invalid/unacceptable sources: PPC and SEO advertising for the CSGOFASTCOM brand':
    '2. Use invalid/unacceptable sources: PPC and SEO advertising for the CSGOFAST.COM brand',
  'Use sources that violate legislation, including legislation on intellectual property':
    '3. Use sources that violate legislation, including legislation on intellectual property.',
  'Complete the achievement of the goals of registration by forcing other users as a result of a request, compensation, deception or misrepresentation':
    '4. Complete the achievement of the goals of registration by forcing other users as a result of a request, compensation, deception or misrepresentation.',
  "Change the IP address within the same subnet or using a proxy server and anonymizers, hide or modify the 'referer'":
    "5. Change the IP address within the same subnet or using a proxy server and anonymizers, hide or modify the 'referer'.",
  "Use invalid/unacceptable sources: clichunder / popander including clichunder / popander via prelanding; Popup format; PPC advertising for the CSGOFAST.COM brand; Motivated traffic by BUX's(Active advertising system); Doorway traffic.":
    "6. Use invalid/unacceptable sources: clichunder / popander including clichunder / popander via prelanding; Popup format; PPC advertising for the CSGOFAST.COM brand; Motivated traffic by BUX's(Active advertising system); Doorway traffic.",
  'Abusing spam to attract users or other webmasters':
    '7. Abusing spam to attract users or other webmasters.',
  'Initiate the automatic actions of users by using scripts, bots, and any other means':
    '8. Initiate the automatic actions of users by using scripts, bots, and any other means.',
  'Use the toolbars and any extensions for browsers to force traffic':
    '9. Use the toolbars and any extensions for browsers to force traffic.',
  'Referral accounts of violators will be blocked':
    'Referral accounts of violators will be blocked.',

  // SERVICE COMMISSION

  // What is Service Commission?
  'Commission depends on a selected game mode and total deposit of a round Have a look at Service Commission for Classic Game and Fast Game There is no commission in the Double Game':
    'Commission depends on a selected game mode and total deposit of a round. Have a look at Service Commission for Classic Game and Fast Game. There is no commission in the Double Game.',
  // My skins were taken as a commission. How to get them back or exchange for others
  'Random skins from a general deposit are taken as a Service Commission':
    'Random skins from a general deposit are taken as a Service Commission.',
  'There is no opportunity to return you taken as a Service Commission skins or exchange them for others':
    'There is no opportunity to return you taken as a Service Commission skins or exchange them for others.',
  // Service has deducted a big Service Commission
  'If a Service Commission is bigger than it is indicated by our rules, contact our tech support: support@csgofastcom':
    'If a Service Commission is bigger than it is indicated by our rules, contact our tech support: support@csgofast.com.',

  // CHAT RULES

  'In the chat it is forbidden': 'In the chat it is forbidden:',
  '1 To offend, humiliate and threaten other participants of the project;':
    '1. To offend, humiliate and threaten other participants of the project;',
  '2 To ask skins, to be engaged in begging;': '2. To ask skins, to be engaged in begging;',
  "3 To place any links which don't belong to service work;":
    "3.To place any links which don't belong to service work;",
  '4 To place advertizing links, to advertize and mention third-party services and communities on Counter-Strike: Global Offensive;':
    '4. To place advertizing links, to advertize and mention third-party services and communities on Counter-Strike: Global Offensive;',
  '5 To send spam and flood;': '5. To send spam and flood;',
  '6 To post referral links and codes;': '6. To post referral links and codes;',
  '7 To be fake service administrator or moderator;':
    '7. To be fake service administrator or moderator;',
  '8 To imitate a nickname or an avatar of system messages;':
    '8. To imitate a nickname or an avatar of system messages;',
  '9 To offer purchase, sale of skins and game values bypassing service Store;':
    '9. To offer purchase, sale of skins and game values bypassing service Store;',
  '10 To offer purchase, sale and boost accounts;':
    '10. To offer purchase, sale and boost accounts;',
  '11 To send to a chat phone numbers, messengers, e-wallets;':
    '11. To send to a chat phone numbers, messengers, e-wallets;',
  '12 To arrange conflict situations in the other language chat;':
    '12. To arrange conflict situations in the other language chat;',
  '13 To communicate on political or religious subjects;':
    '13. To communicate on political or religious subjects;',
  '14 To show signs of racism and nationalism;': '14. To show signs of racism and nationalism;',
  '15 To propagandize drugs, alcohol, violence;': '15. To propagandize drugs, alcohol, violence;',
  '16 To offend and threaten service administration.':
    '16. To offend and threaten service administration',

  'Violators will be blocked on all chat channels for the period from 10 minutes to a permanent block':
    'Violators will be blocked on all chat channels for the period from 10 minutes to a permanent block.',
  'Chat Moderating is provided manually and automatically.':
    'Chat Moderating is provided manually and automatically.',
  'Relocks duration increases on time automatically':
    'Relocks duration increases on time automatically.',
  'The appeal of the received one week period Ban is carried out through tech support':
    'The appeal of the received one week period Ban is carried out through tech support.',
  'Moderators have the right to delete messages or ban the chat participant without explanation':
    'Moderators have the right to delete messages or ban the chat participant without explanation.',

  // GAMES

  'How many bets can I place per one round?': 'How many bets can I place per one round?',
  'What Service Commission is there in this game?':
    'What Service Commission is there in this game?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'the game for those, who like playing for the high stakes. Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'The winner is determined by the Ticket System in the Classic Game.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'A Service Commission is from 0 up to 10% depending on a sum of winning.\nRandom skins of the general deposit are taken as a Service Commission.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'the game to try your luck instantly. There are only 3 players, the max bet is limited. The winner gets a total deposit of 3 players.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'The winner is determined randomly, but the higher the bet, the greater the chance to win.',
  'One player can place one bet per one game round in this game.':
    'One player can place one bet per one game round in this game.',
  'Where can I see my chances of the winning?': 'Where can I see my chances of the winning?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).',
  'The winner is determined once 3 players place their bets in the current game round.':
    'The winner is determined once 3 players place their bets in the current game round.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'A Service Commission is from 0 up to 15% depending on a sum of winning.\nRandom skins from the general deposit of the round are taken as a Service Commission.',

  // Double

  'in this mode you can get Fast Coins (service currency) and exchange them for skins in the Store Guess the winner color and return your bet multiplied by 2 or 14':
    'in this mode you can get Fast Coins (service currency) and exchange them for skins in the Store. Guess the winner color and return your bet multiplied by 2 or 14.',
  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.',
  'The green slot returns the placed bet multiplied by 14.':
    'The green slot returns the placed bet multiplied by 14.',
  'The bet sum of every slot can be different.': 'The bet sum of every slot can be different.',

  'How can I Get Fast Coins?': 'How can I Get Fast Coins?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.',

  'How is the winner slot determined (fair game)?':
    'How is the winner slot determined (fair game)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.",

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.',

  'What bet limitations are there?': 'What bet limitations are there?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Attention! During one draw you can not bet on red and black at the same time. ListBets on Green are available every round!',

  'When does the determination of the winner slot begin?':
    'When does the determination of the winner slot begin?',
  'The winner slot is determined at the end of a round.':
    'The winner slot is determined at the end of a round.',

  'Is there any commission in this mode?': 'Is there any commission in this mode?',
  'There is no commission at all. :)': 'There is no commission at all. :)',

  // Crash

  'is a mode, where players have to press Stop button before Crash comes Crash comes randomly but as long as you are in game the coefficient rises as your winning':
    'is a mode, where players have to press Stop button before Crash comes. Crash comes randomly but as long as you are in game the coefficient rises as your winning.',
  "Bet ${currency(amount)} before round will start and choose the strategy: manual or automatic with the coefficient you want. With the game beginning the coef starts to grow up till the CRASH comes. Those who press Stop button in time before the Crash win the game. Winners' winning rise depends of the coefficient on which you stop the game.":
    "Bet ${currency(amount)} before round will start and choose the strategy: manual or automatic with the coefficient you want. With the game beginning the coef starts to grow up till the CRASH comes. Those who press Stop button in time before the Crash win the game. Winners' winning rise depends of the coefficient on which you stop the game.",
  'Using automatic strategy you can also press Stop button before the coefficient you put in the box.':
    'Using automatic strategy you can also press Stop button before the coefficient you put in the box.',
  'At the beginning of each round, the service generates a random long random number from 0 to 1 (for example, 0.223088) and encrypts it through the sha224 algorithm. The result of the encryption can be seen in the early rounds. At the end of the round, the service uses this random number to calculate the coefficient using the following formula:':
    'At the beginning of each round, the service generates a random long random number from 0 to 1 (for example, 0.223088) and encrypts it through the sha224 algorithm. The result of the encryption can be seen in the early rounds. At the end of the round, the service uses this random number to calculate the coefficient using the following formula:',
  'Example: At the beginning of the round, the number 0.24412312303230728 was encrypted. The service uses the formula above to get the number 1.32.':
    'Example: At the beginning of the round, the number 0.24412312303230728 was encrypted. The service uses the formula above to get the number 1.32.',
  'You can independently verify the correct definition of the victorious sector. To do this, at the end of the round, take a number that has been encrypted and re-encode it using any of the online services, for example http://sha224.net. You will see the same value of hash as in the beginning of the round. This means that the result of the game was not adjusted.':
    'You can independently verify the correct definition of the victorious sector. To do this, at the end of the round, take a number that has been encrypted and re-encode it using any of the online services, for example http://sha224.net. You will see the same value of hash as in the beginning of the round. This means that the result of the game was not adjusted.',
  'Since the system determines the final coefficient even before the game starts and any player can control the absence of its changes - interference in the game makes no sense for us. Therefore, this system is the guarantor of the honesty of our games.':
    'Since the system determines the final coefficient even before the game starts and any player can control the absence of its changes - interference in the game makes no sense for us. Therefore, this system is the guarantor of the honesty of our games.',

  // Craft

  'How do I create an item?': 'How do I create an item?',
  "What do I do with an item once it's created?": "What do I do with an item once it's created?",
  'gives all users a great opportunity to obtain their desired items below their market value':
    'gives all users a great opportunity to obtain their desired items below their market value.',
  'It\'s very simple. Pick an item you like from the list, drag the slider to indicate how many money you are prepared to spend on the parts, and press "Create".':
    'It\'s very simple. Pick an item you like from the list, drag the slider to indicate how many money you are prepared to spend on the parts, and press "Create".',
  'Keep in mind that the more expensive the part, the higher your chances to create an item.':
    'Keep in mind that the more expensive the part, the higher your chances to create an item.',
  'Once you have successfully created an item, you can either sell it to the shop straight away at its full value or add it to your inventory by pressing the "Claim" button. The bot will automatically send you a trade offer for your item.':
    'Once you have successfully created an item, you can either sell it to the shop straight away at its full value or add it to your inventory by pressing the "Claim" button. The bot will automatically send you a trade offer for your item.',
  'If you claimed an item and the bot sent you an exchange offer, you must confirm the exchange within an hour, otherwise the item will be automatically sold and the money for it will be credited to your account':
    'If you claimed an item and the bot sent you an exchange offer, you must confirm the exchange within an hour, otherwise the item will be automatically sold and the money for it will be credited to your account',

  // Wheel

  'is a mode of game where you can spin the wheel of fortune and get chance to win great prizes!':
    'is a mode of game where you can spin the wheel of fortune and get chance to win great prizes!',
  'How to play Wheel of Fortune?': 'How to play Wheel of Fortune?',
  'After selecting this mode, you need to click the "Spin" button. After that the wheel starts to spin and after stopping it you will see your prize.':
    'After selecting this mode, you need to click the "Spin" button. After that the wheel starts to spin and after stopping it you will see your prize.',
  'All winnings will come directly to your balance.':
    'All winnings will come directly to your balance.',

  // Tower

  'is a classic mode to climb to the top of the tower and collect a coin chest guessing the winning sectors.':
    'is a classic mode to climb to the top of the tower and collect a coin chest guessing the winning sectors.',

  'How to play Tower Game?': 'How to play Tower Game?',
  'Choose the complexity you need:': 'Choose the complexity you need:',
  Easy: 'Easy',
  'in this mode, the tower has three columns with two winning sectors in each line. In light mode you have the highest chance to get to the top!':
    'in this mode, the tower has three columns with two winning sectors in each line. In light mode you have the highest chance to get to the top!',
  Medium: 'Medium',
  'in the middle mode two columns with one losing sector in the line. Chances for each line are 50/50.':
    'in the middle mode two columns with one losing sector in the line. Chances for each line are 50/50.',
  Hard: 'Hard',
  'there are also three columns, but unlike previous modes in each line only one winning sector. This mode is for real luckies! With increasing complexity, the reward for passing the tower also increases!':
    'there are also three columns, but unlike previous modes in each line only one winning sector. This mode is for real luckies! With increasing complexity, the reward for passing the tower also increases!',
  "Having chosen the appropriate complexity, you only need to determine the bet and try your luck by opening the winning sectors in each line from the bottom of the tower. The amount of winnings for each line depends on the size of the bet. The higher the bet, the higher the win, it's easy! You can stop at any step in the tower and withdraw the winnings.":
    "Having chosen the appropriate complexity, you only need to determine the bet and try your luck by opening the winning sectors in each line from the bottom of the tower. The amount of winnings for each line depends on the size of the bet. The higher the bet, the higher the win, it's easy! You can stop at any step in the tower and withdraw the winnings.",

  'Fair play': 'Fair play',
  'You can independently verify the correct definition of the lucky sector.':
    'You can independently verify the correct definition of the lucky sector.',
  'To do this, at the end of the round, take the number that was encrypted, add to it the hash salt (without a space between the number and the salt) and re-encode it using any of the online services, for example http://sha224.net.':
    'To do this, at the end of the round, take the number that was encrypted, add to it the hash salt (without a space between the number and the salt) and re-encode it using any of the online services, for example http://sha224.net.',
  "You will see the same value of 'hash' in the beginning of the round. This means that the result of the game was not adjusted.":
    "You will see the same value of 'hash' in the beginning of the round. This means that the result of the game was not adjusted.",
  'The Demo account cannot get experience for the bets made and cannot increase its game level Demo account balance can not exceed $5, and the maximum bet is limited to $ 050':
    'The Demo account cannot get experience for the bets made and cannot increase its game level Demo account balance can not exceed $5, and the maximum bet is limited to $ 0.50',
};
