import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useUserProviders } from 'core/User';
import { IDialogComponent } from 'core/interfaces';
import { Icons, authProviderConfig, useBindingProvider } from 'core/Auth';
import { AuthTypes } from 'core/Auth/interfaces';
import { unbindSocial } from 'core/User/ducks/duck';
import { SvgIcons } from '../assets';
import {
  Container,
  MyButton,
  SocialIconCross,
  SocialIconWrap,
  SocialsList,
  Title,
} from './social-dialog.styles';

interface ISocialDialog extends IDialogComponent<boolean> {}

export const SocialDialog: FC<ISocialDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userProviders } = useUserProviders();
  const { handleBindingProvider } = useBindingProvider();
  const connectedSocial = Object.keys(userProviders).length;
  const allSocial = authProviderConfig.length;

  const handleBind = (provider: AuthTypes, isLinked: boolean) => {
    if (!isLinked) {
      return handleBindingProvider(provider)();
    }
  };

  const handleUnbind = (provider: AuthTypes) => {
    dispatch(unbindSocial(provider));
  };

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>
        {t('Add social networks')} (
        {t('Count of', {
          count: connectedSocial,
          postProcess: 'with-template',
        })}{' '}
        {allSocial})
      </Title>
      <SocialsList>
        {authProviderConfig.map((provider: AuthTypes) => {
          const isLinked = !!userProviders[provider];

          const getMessage = () => {
            if (isLinked) {
              if (provider === 'steam' || connectedSocial === 1) {
                return t('Linked');
              }
              return t('Unbind');
            }
            return t('Add');
          };

          const hasDeleteIcon = provider !== 'steam' && connectedSocial > 1 && isLinked;

          return (
            <MyButton
              key={provider}
              onClick={() => handleBind(provider, isLinked)}
              provider={provider}
              isLinked={isLinked}
            >
              <SocialIconWrap>{Icons[provider](24, 24)}</SocialIconWrap>
              {getMessage()} {provider}
              {hasDeleteIcon && (
                <SocialIconCross onClick={() => handleUnbind(provider)}>
                  <SvgIcons.CrossIcon />
                </SocialIconCross>
              )}
            </MyButton>
          );
        })}
      </SocialsList>
    </Container>
  );
};
