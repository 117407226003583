import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCreateParticipate } from '../duck';
import { FactorTypes } from '../interfaces';

export const useGameController = () => {
  const dispatch = useDispatch();

  const handleCreateParticipate = useCallback(
    (type: FactorTypes, amount: number) => () => {
      dispatch(fetchCreateParticipate(type, amount));
    },
    [dispatch]
  );

  return {
    handleCreateParticipate,
  };
};
