import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';

class PayoutRepository {
  getPayoutConfig(userId: number): Observable<AjaxResponse> {
    return ajax({
      url: `https://pay.csgofastbackend.com/payout-methods/${userId}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  getPayoutFee(input: number): Observable<AjaxResponse> {
    return ajax({
      url: `https://pay.csgofastbackend.com/bitcoin-commission/${input}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  getPayoutCurrency(): Observable<AjaxResponse> {
    return ajax({
      url: 'https://upi-pay.com/stats/currency',
      method: 'GET',
      crossDomain: true,
    });
  }
}

export default new PayoutRepository();
