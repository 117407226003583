import { ReactComponent as MetaIcon } from './meta.svg';
import { ReactComponent as SteamIcon } from './steam.svg';
import { ReactComponent as VkIcon } from './vk.svg';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as TwitchIcon } from './twitch.svg';
import { ReactComponent as TelegramIcon } from './telegram.svg';
// import { ReactComponent as YandexIcon } from './yandex.svg';
import React from 'react';
import { AuthTypes } from '../interfaces';

export const Icons: Record<Partial<AuthTypes>, (width: number, height: number) => JSX.Element> = {
  meta: (width: number, height: number) => <MetaIcon width={width} height={height} />,
  steam: (width: number, height: number) => <SteamIcon width={width} height={height} />,
  vkontakte: (width: number, height: number) => <VkIcon width={width} height={height} />,
  google: (width: number, height: number) => <GoogleIcon width={width} height={height} />,
  twitter: (width: number, height: number) => <TwitterIcon width={width} height={height} />,
  // yandex: (width: number, height: number) => (
  //   <YandexIcon width={width} height={height} />
  // ),
  twitch: (width: number, height: number) => <TwitchIcon width={width} height={height} />,
  telegram: (width: number, height: number) => <TelegramIcon width={width} height={height} />,
};
