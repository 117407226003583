import React, { FC, useEffect, useState } from 'react';
import { AppBar } from '@components/bars';
import {
  actionRequestReplay,
  actionRequestSuitCase,
  rouletteActionRequest,
  rouletteStatusActionBeforeRollAll,
} from '../../ducks';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CountUp from 'react-countup';
import { useGetSoundTypeForRoulettes, useGetCaseData } from 'games/CaseGame/hooks';
import { CaseItemsList, RouletteDecorator } from 'games/CaseGame/components';

import { BarInfo, BarName, BarProgress, CategoryImage, Container } from './CasesGame.page.styles';
import { Loader } from '@components/Loader';
import { useGetCategory, useGetGeneralQuestInfo } from 'modules/event-module/comics-event/hooks';

export const CasesGame: FC = () => {
  const { isLoading, data } = useGetCaseData();
  const { caseId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();
  const soundType = useGetSoundTypeForRoulettes();
  const search = new URLSearchParams(history.location.search).get('uuid');
  const { isLoading: isLoadingEvent, progressEndAt } = useGetGeneralQuestInfo();
  const { category, loader, progressCategory } = useGetCategory(data.category?.id);
  const ticketType = new URLSearchParams(history.location.search).get('ticketType');
  const [hasProgress, setHasProgress] = useState(false);
  const currentDate = new Date();

  useEffect(() => {
    if (
      (category?.count - category?.progress > 0 && loader < 100) ||
      (progressCategory > loader && hasProgress)
    ) {
      setHasProgress(true);
    } else {
      setHasProgress(false);
    }
  }, [category, hasProgress, loader, progressCategory]);

  useEffect(() => {
    if (caseId) {
      dispatch(actionRequestSuitCase(+caseId));

      if (!search) {
        dispatch(rouletteActionRequest({ caseId, lines: '1' }));
      } else {
        dispatch(actionRequestReplay(search));
      }
    }
    return () => dispatch(rouletteStatusActionBeforeRollAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, dispatch]);

  return !isLoading ? (
    <>
      <AppBar deep progressCategory={progressCategory} loader={loader} category={category}>
        {!!category && new Date(progressEndAt) > currentDate ? (
          <Container>
            <CategoryImage src={category.image} alt="" />
            <BarInfo>
              <BarName>{data.name}</BarName>
              {hasProgress && (
                <BarProgress>
                  <CountUp
                    decimal="."
                    decimals={
                      Number.isInteger(progressCategory > 100 ? 100 : progressCategory) ? 0 : 2
                    }
                    duration={1}
                    start={loader}
                    end={progressCategory > 100 ? 100 : progressCategory}
                    suffix="%"
                    delay={0}
                  />
                </BarProgress>
              )}
            </BarInfo>
          </Container>
        ) : (
          <span style={{ marginRight: '20px' }}>{isLoadingEvent ? ' ' : data.name}</span>
        )}
      </AppBar>
      <div className="content dark-layout">
        <RouletteDecorator
          type={soundType}
          caseId={caseId}
          caseType={data.type}
          free={data?.free}
          categoryId={data.category?.id}
          ticketData={!!ticketType ? data?.ticketQuest : null}
        />
        <CaseItemsList
          caseItems={data.itemsForCaseShow}
          caseType={data.type}
          isFree={Boolean(data?.free)}
        />
      </div>
    </>
  ) : (
    <Loader />
  );
};
