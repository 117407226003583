import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { UserProfileAvatar } from '@components/UserProfile';
import { URLS } from 'services/constant-urls';
import { userModals } from 'core/User/modal.config';
import { useDialog } from 'core/hooks';

interface IProfileAvatar {
  avatar: string;
  profileType: string;
  country: string;
}

export const ProfileAvatar: FC<IProfileAvatar> = ({ avatar, profileType, country }) => {
  const {
    handleOpenDialog: handleOpenProfileDialog,
    handleCloseDialog: handleCloseProfileDialog,
  } = useDialog(userModals.PROFILE_SETTINGS);
  const {
    handleToggleDialog: handleToggleRefillDialog,
    handleCloseDialog: handleCloseRefillDialog,
  } = useDialog(userModals.REFILL_COUNTRY);

  useEffect(() => {
    return () => {
      handleCloseProfileDialog();
      handleCloseRefillDialog();
    };
  }, [handleCloseProfileDialog, handleCloseRefillDialog]);

  return (
    <>
      {country && (
        <UserCountry
          onClick={handleToggleRefillDialog(userModals.REFILL_COUNTRY, {
            userProfileCountry: country,
          })}
          src={`${URLS.countryFlags}/${country.toLowerCase()}.png`}
        />
      )}
      <EditProfile className="ripple" onClick={handleOpenProfileDialog}>
        <div className="ripple-effect ripple-light-effect">
          <i className="icon-edit" />
        </div>
      </EditProfile>
      <UserProfileAvatar profileType={profileType} avatar={avatar} />
    </>
  );
};

const EditProfile = styled.div`
  color: var(--color-white);
  display: block;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  z-index: 10;

  & > img {
    height: 20px;
    width: 20px;
  }
`;

const UserCountry = styled.img`
  border-radius: 50%;
  height: 30px;
  left: 1rem;
  object-fit: cover;
  position: absolute;
  top: 1rem;
  width: 30px;
  z-index: 10;
  pointer-events: auto;
`;
