import { IDialogComponent } from 'core/interfaces';
import React from 'react';
import { ReferralDialog } from '../referral-dialog/referral-dialog';
import { useUserReferrerInfo } from 'core/User';

interface BonusCodeNotAuthProps extends IDialogComponent<boolean> {}

export const BonusCodeNotAuth: React.FC<BonusCodeNotAuthProps> = ({ handleCloseModal }) => {
  const { referrerInfo } = useUserReferrerInfo();
  return (
    <ReferralDialog
      code={referrerInfo.code}
      userName={referrerInfo?.referrer?.user?.name}
      avatar={referrerInfo?.referrer?.user?.avatar}
      reward={referrerInfo.reward}
      type="not-authenticated"
      bonusType={referrerInfo.type}
      handleCloseModal={handleCloseModal}
    />
  );
};
