import { DroppedItemsResults } from '../ducks';

export const getRandomLine = (ids: number[][], prevItemReward: DroppedItemsResults[]) => {
  return ids.map((idsLine, index) => {
    const newLine = idsLine.map((_, _index, array) => {
      const randomIndex = Math.abs(Math.floor(Math.random() * array.length - 1));
      return array[randomIndex];
    });
    if (prevItemReward[index]?.caseRevisionItem?.inventoryItem.id) {
      return [
        ...idsLine.slice(27, 28),
        prevItemReward[index]?.caseRevisionItem?.inventoryItem.id,
        ...idsLine.slice(29, 30),
        ...newLine.slice(3),
      ];
    } else {
      return [...idsLine.slice(0, 3), ...newLine.slice(3)];
    }
  });
};
