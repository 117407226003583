import React from 'react';

export const SteamApiKey = () => (
  <svg width="34" height="27" viewBox="0 0 34 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1C13.4445 1 14.8749 1.28452 16.2095 1.83733C17.5441 2.39013 18.7567 3.20038 19.7782 4.22183C20.7996 5.24327 21.6099 6.4559 22.1627 7.79048C22.7155 9.12506 23 10.5555 23 12C23 14.9174 21.8411 17.7153 19.7782 19.7782C17.7153 21.8411 14.9174 23 12 23C6.94 23 2.705 19.612 1.396 15.003L5.609 16.741C5.895 18.16 7.16 19.238 8.667 19.238C10.383 19.238 11.78 17.841 11.78 16.125V15.982L15.52 13.309H15.608C17.896 13.309 19.755 11.45 19.755 9.162C19.755 6.874 17.896 5.015 15.608 5.015C13.32 5.015 11.45 6.874 11.45 9.162V9.217L8.843 13.023L8.667 13.012C8.018 13.012 7.413 13.21 6.918 13.551L1 11.12C1.473 5.455 6.203 1 12 1ZM7.908 17.687C8.788 18.05 9.8 17.643 10.163 16.763C10.526 15.883 10.108 14.882 9.25 14.519L7.842 13.936C8.381 13.738 8.986 13.727 9.558 13.969C10.141 14.2 10.592 14.651 10.823 15.234C11.065 15.806 11.065 16.444 10.823 17.016C10.35 18.204 8.953 18.776 7.765 18.281C7.215 18.05 6.797 17.632 6.566 17.137L7.908 17.687ZM18.38 9.162C18.38 10.691 17.137 11.934 15.608 11.934C14.09 11.934 12.847 10.691 12.847 9.162C12.8455 8.79901 12.916 8.43933 13.0542 8.10369C13.1924 7.76806 13.3958 7.46311 13.6524 7.20643C13.9091 6.94976 14.2141 6.74644 14.5497 6.60821C14.8853 6.46997 15.245 6.39955 15.608 6.401C17.137 6.401 18.38 7.644 18.38 9.162ZM13.54 9.162C13.54 10.306 14.464 11.241 15.619 11.241C16.763 11.241 17.687 10.306 17.687 9.162C17.687 8.018 16.763 7.083 15.619 7.083C14.464 7.083 13.54 8.018 13.54 9.162Z"
      fill="white"
    />
    <rect x="15" y="14" width="19" height="13" rx="6" fill="#131A1D" />
    <path
      d="M21 21C20.7348 21 20.4804 20.8946 20.2929 20.7071C20.1054 20.5196 20 20.2652 20 20C20 19.7348 20.1054 19.4804 20.2929 19.2929C20.4804 19.1054 20.7348 19 21 19C21.2652 19 21.5196 19.1054 21.7071 19.2929C21.8946 19.4804 22 19.7348 22 20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21ZM23.825 19C23.415 17.835 22.305 17 21 17C20.2044 17 19.4413 17.3161 18.8787 17.8787C18.3161 18.4413 18 19.2044 18 20C18 20.7956 18.3161 21.5587 18.8787 22.1213C19.4413 22.6839 20.2044 23 21 23C22.305 23 23.415 22.165 23.825 21H27V23H29V21H30V19H23.825Z"
      fill="white"
    />
  </svg>
);
