import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useUser, useUserTakePromoBonus } from 'core/User';
import { SignIn } from 'core/Auth';

const games = ['double', 'x50', 'hilo', 'baccarat', 'crash', 'slot', 'poggi', 'tower'];

export const PromoScreen = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [takePromoAction] = useUserTakePromoBonus();

  return (
    <Container>
      <div className="promo-logo">
        <img
          src="//d2lomvz2jrw9ac.cloudfront.net/events-banners/promo/promo-logo-alt.png"
          alt="promo-logo"
        />
      </div>
      <div className="promo-description">{t('100$ for the new players!')}</div>
      <div className="promo-access">
        <span className="promo-access__title">
          <i />
          {t('100$ bonus is credited to a demo balance')}
        </span>
        <div className="promo-access__game-list">
          {games.map((game: string) => (
            <Link key={game} className="game-item" to={`/${game}`}>
              <div className={`game-item__outline ${game}`}>
                <i className={`icon-${game}-mini`} />
              </div>
              <span className="game-item__name">{game}</span>
            </Link>
          ))}
        </div>
      </div>
      <div className="promo-actions">
        {user?.id ? (
          <Button className="primary" onClick={takePromoAction} disabled={user.bonuses.promo}>
            {t('Get 100$ now!')}
          </Button>
        ) : (
          <SignIn className="primary full" />
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows:
    minmax(16.125rem, 1.5fr) minmax(5.375rem, 0.5fr) minmax(10.75rem, 1fr)
    4.625rem;
  height: 100%;

  & .promo-logo {
    display: flex;
    justify-content: center;
    padding: 0 1rem;

    & img {
      height: 40vh;
      object-fit: contain;
    }
  }

  & .promo-description {
    padding: 1.4rem 0;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    text-align: center;
    letter-spacing: 0.54px;
    color: white;
  }

  & .promo-access {
    overflow: hidden;

    &__title {
      display: block;
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.9rem;
      letter-spacing: 0.54px;
      color: white;
      padding: 0 1rem;
    }

    &__game-list {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.25rem;
      overflow-x: scroll;
      margin-top: 0.85rem;

      &::-webkit-scrollbar {
        display: none;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        width: 0.75rem;
        height: 100%;
      }

      & .game-item {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-flow: column;
        width: 4.25rem;
        height: 4.25rem;
        background-color: var(--color-dark-200);
        border-radius: var(--size-radius-m);

        &__outline {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.875rem;
          height: 1.875rem;
          border: 2px solid var(--color-pve);
          border-radius: 50%;

          &.slot,
          &.poggi,
          &.tower {
            border-color: var(--color-solo-pve);
          }

          & i {
            font-size: 1rem;
            color: white;
          }
        }

        &__name {
          font-size: 0.75rem;
          line-height: 0.875rem;
          color: white;
          text-transform: capitalize;
        }
      }
    }
  }

  & .promo-actions {
    align-self: self-start;
    padding: 0 1rem 1.5rem;
  }
`;
