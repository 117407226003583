import React, { useEffect, memo, FC } from 'react';
import { StyledButton } from './CreateGameButton.styles';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import {
  useGetCaseBattleType,
  useGetGameId,
  useGetIdsForCreateBattle,
} from 'games/CaseBattle/hooks';
import { useUser } from 'core/User';
import { useDispatch } from 'react-redux';
import { addNotification } from 'modules/Notifications/duck';
import { useHistory } from 'react-router-dom';
import { useDialog } from 'core/hooks';
import { AuthDialogs } from 'core/Auth';
import { CaseBattleType } from 'games/CaseBattle/ducks/create-battle-duck/create-battle.interfaces';
import { RequestBody } from 'games/CaseBattle/interfaces/case-battle.interfaces';
import { actionCreateGame, actionResponseGamesId, removeAllCases } from 'games/CaseBattle/ducks';
import { AddCasesConfig } from 'games/CaseBattle/dialog-config';

const BATTLE_TYPE: Record<CaseBattleType, RequestBody> = {
  2: {
    caseIds: [],
    isPrivate: false,
    double: false,
    playersCount: 2,
    winnersSplit: false,
    winnersCount: 1,
    jokerMode: false,
  },
  3: {
    caseIds: [],
    isPrivate: false,
    double: false,
    playersCount: 3,
    winnersSplit: false,
    winnersCount: 1,
    jokerMode: false,
  },
  4: {
    caseIds: [],
    isPrivate: false,
    double: false,
    playersCount: 4,
    winnersSplit: false,
    winnersCount: 1,
    jokerMode: false,
  },
  '2 x 2': {
    caseIds: [],
    isPrivate: false,
    double: true,
    playersCount: 4,
    winnersSplit: false,
    winnersCount: 1,
    jokerMode: false,
  },
  '2 x 2 TEAM': {
    caseIds: [],
    isPrivate: false,
    double: true,
    playersCount: 4,
    winnersSplit: true,
    winnersCount: 1,
    jokerMode: false,
  },
};

interface CreateGameButtonProps {
  isJoker?: boolean;
}

export const CreateGameButton: FC<CreateGameButtonProps> = memo(({ isJoker }) => {
  const { t } = useTranslation();
  const { price, casesForBattle } = useGetIdsForCreateBattle();
  const gameId = useGetGameId();
  const { user } = useUser();
  const { battleType } = useGetCaseBattleType();
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleOpenDialog, handleCloseByName } = useDialog(AuthDialogs.SIGN_IN);

  const redirectRefillHandler = () => {
    history.push('../refill');
  };

  const notificationHandler = () => {
    dispatch(
      addNotification({
        type: 'error',
        body: 'You need a silver account to open case',
      })
    );
  };

  useEffect(() => {
    if (gameId) {
      history.replace(`../case-battle/${gameId}`);
      dispatch(actionResponseGamesId(0));
    }
  }, [gameId, history, dispatch]);

  const createGame = () => {
    dispatch(
      actionCreateGame({
        ...BATTLE_TYPE[battleType],
        caseIds: casesForBattle.map(({ id }) => id),
        jokerMode: isJoker,
      })
    );
    handleCloseByName(AddCasesConfig.ADD_CASE);
    dispatch(removeAllCases());
  };
  const onClickHandler = () => {
    if (user.id) {
      if (user.coins < price) {
        return redirectRefillHandler();
      } else {
        if (user.profileType === 'bronze') {
          return notificationHandler();
        }
        return createGame();
      }
    } else {
      return handleOpenDialog();
    }
  };

  const getText = (isJoker?: boolean) => {
    if (user.id) {
      if (user.coins < price) {
        return (
          <>
            {t('refill')}
            <CurrencyText value={price - user.coins} />
          </>
        );
      } else {
        return (
          <>
            {' '}
            {isJoker ? t('createJokerGameFor') : t('createGameFor')}
            <span>
              <CurrencyText value={price} />
            </span>
          </>
        );
      }
    } else {
      return <>{t('login')}</>;
    }
  };

  return (
    <StyledButton disabled={!price} onClick={onClickHandler} isJoker={isJoker}>
      {getText(isJoker)}
    </StyledButton>
  );
});

//TODO: add request for game creation
