import { CheckBoxWrap as DefaultCheckBoxWrap } from 'dialogs/profile-email-dialog/profile-email-dialog/profile-email-dialog.styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--color-dark-50);
  padding-bottom: 60px;
`;

export const EmailWrap = styled.div`
  margin-top: 56px;
  padding: 0 18px;
`;

export const ConfirmEmailWrap = styled.div`
  padding: 8px 16px 0;
`;

export const CheckBoxWrap = styled(DefaultCheckBoxWrap)`
  padding: 32px 0 0;
`;
