export enum ExchangeActionTypes {
  EXCHANGE_ATTACH_LISTENERS = 'app/exchange/EXCHANGE_ATTACH_LISTENERS',
  EXCHANGE_DETACH_LISTENERS = 'app/exchange/EXCHANGE_DETACH_LISTENERS',
}

export interface IAttachListener {
  type: typeof ExchangeActionTypes.EXCHANGE_ATTACH_LISTENERS;
}

export interface IDetachListener {
  type: typeof ExchangeActionTypes.EXCHANGE_DETACH_LISTENERS;
}

export type marketplaceActions = IAttachListener | IDetachListener;
