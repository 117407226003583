import { IFiltersRecord } from './filters.reducer.interface';

export enum FiltersActionTypes {
  CHANGE_QUERY_FILTER = 'app/marketplace/CHANGE_QUERY_FILTER',
  CHANGE_PRICE_RANGE_FILTER = 'app/marketplace/CHANGE_PRICE_RANGE_FILTER',
  CHANGE_SINGLE_FILTERS = 'app/marketplace/CHANGE_SINGLE_FILTERS',
  CHANGE_MULTI_FILTERS = 'app/marketplace/CHANGE_MULTI_FILTERS',
  UPDATE_FILTERS = 'app/marketplace/UPDATE_FILTERS',
  RESET_FILTERS = 'app/marketplace/RESET_FILTERS',
  CLEANUP_FILTERS = 'app/marketplace/CLEANUP_FILTERS',
}

export interface IChangeQueryFilters {
  type: typeof FiltersActionTypes.CHANGE_QUERY_FILTER;
  payload: {
    query: string;
  };
}

export interface IChangeSingleFilters {
  type: typeof FiltersActionTypes.CHANGE_SINGLE_FILTERS;
  payload: {
    key: string;
    value: string;
    type?: string;
  };
}

export interface IChangeMultiFilters {
  type: typeof FiltersActionTypes.CHANGE_MULTI_FILTERS;
  payload: {
    key: string;
    value: string;
    selected: boolean;
  };
}

export interface IChangePriceRangeFilters {
  type: typeof FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER;
  payload: {
    range: number[];
  };
}

export interface IResetFilters {
  type: typeof FiltersActionTypes.RESET_FILTERS;
  payload: Partial<IFiltersRecord>;
}

export interface IUpdateFilters {
  type: typeof FiltersActionTypes.UPDATE_FILTERS;
  payload: Partial<IFiltersRecord>;
}

export interface ICleanupFilters {
  type: typeof FiltersActionTypes.CLEANUP_FILTERS;
}

export type filtersActions =
  | IChangeQueryFilters
  | IChangeSingleFilters
  | IChangeMultiFilters
  | IChangePriceRangeFilters
  // | IUpdateFilters
  | IResetFilters
  | ICleanupFilters;
