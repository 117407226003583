import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
// @ts-ignore
import o9n from 'o9n';
import * as screenfull from 'screenfull';
import { SignIn } from 'core/Auth';

import { cleanUpModule, getAuthToken } from '../duck';
import { addNotification } from 'modules/Notifications/duck';

import { getToken } from '../selectors';
import { getUser } from 'core/User/selectors';

import { IInitStateToken } from '../interfaces/poggi.reducer.interfaces';
import { IUserState } from 'core/User/interfaces/user.reducer.interface';

interface IGameProps extends WithTranslation {
  token: IInitStateToken;
  user: IUserState;

  getAuthToken: () => void;
  cleanUpModule: () => void;
  addNotification: (message: object) => void;
}

const { innerHeight, innerWidth } = window;

class _Game extends React.PureComponent<IGameProps, { w: number; h: number }> {
  private iframe = React.createRef<HTMLIFrameElement>();
  state = {
    w: innerWidth,
    h: innerHeight,
  };
  private timeoutId: number = null;

  componentDidMount() {
    window.addEventListener('message', this.gameListener);
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps: Readonly<IGameProps>): void {
    const { token } = this.props;
    if (token !== prevProps.token) {
      //@ts-ignore
      this.iframe.current.contentWindow.postMessage({ type: 'TOKEN', token: token.token }, '*');
    }
  }

  componentWillUnmount() {
    this.props.cleanUpModule();
    window.removeEventListener('message', this.gameListener);
    window.removeEventListener('resize', this.onResize);
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  render() {
    const { user } = this.props;
    const { w, h } = this.state;

    const portrait = w < h;
    return (
      <Container>
        {!user.id && (
          <AuthLayout>
            <SignIn className="primary full" />
          </AuthLayout>
        )}
        <iframe
          title="poggi"
          width={portrait ? h - 106 : w}
          height={portrait ? w : h}
          allowFullScreen
          name="poggy"
          allow="autoplay"
          scrolling="no"
          ref={this.iframe}
          src="https://d1phhoo4f618zo.cloudfront.net/"
        />
      </Container>
    );
  }

  enterFullscreenFunction = () => {
    if (screenfull && screenfull.enabled) {
      // @ts-ignore
      screenfull.request(this.iframe.current);
      o9n.orientation.lock('landscape').catch(console.log);
      screenfull.on('change', this.onFullscreenStatusChange);
    } else {
      this.props.addNotification({
        type: 'error',
        header: 'Error',
        body: 'Unfortunately full screen is not supported in iOS',
      });
    }
  };

  exitFullscreenFunction = () => {
    if (screenfull && screenfull.isFullscreen) {
      screenfull.exit().then(_ => {
        o9n.orientation.unlock();
      });
      screenfull.off('change', this.onFullscreenStatusChange);
    }
  };

  onFullscreenStatusChange = () => {
    if (!this.iframe.current) return;
    if (screenfull && screenfull.enabled) {
      // @ts-ignore
      this.iframe.current.contentWindow.postMessage(
        { type: 'SET_FULLSCREEN', fullscreen: true },
        '*'
      );
    } else {
      // @ts-ignore
      this.iframe.current.contentWindow.postMessage(
        { type: 'SET_FULLSCREEN', fullscreen: false },
        '*'
      );
    }
  };

  gameListener = (message: any) => {
    switch (message.data.type) {
      case 'TOKEN_REQUEST': {
        this.props.getAuthToken();
        break;
      }

      case 'LCID_STRING_REQUEST': {
        // @ts-ignore
        this.iframe.current.contentWindow.postMessage(
          { type: 'LCID_STRING', lcid: this.props.i18n.language },
          '*'
        );
        break;
      }

      case 'FULLSCREEN_CLICK': {
        message.data.fullscreen ? this.enterFullscreenFunction() : this.exitFullscreenFunction();
        break;
      }
      default:
        break;
    }
  };

  private asyncResize(): number {
    return setTimeout(() => {
      const { screen, orientation, innerWidth, innerHeight } = window;
      const angle = screen?.orientation?.angle ?? orientation;
      if ((typeof angle === 'number' && Math.abs(angle) === 90) || innerWidth > innerHeight) {
        this.setState({
          w: Math.max(innerWidth, innerHeight),
          h: Math.min(innerWidth, innerHeight),
        });
      } else {
        this.setState({
          w: Math.min(innerWidth, innerHeight),
          h: Math.max(innerWidth, innerHeight),
        });
      }
    }, 150);
  }

  private onResize = (): void => {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.timeoutId = this.asyncResize();
  };
}

export const Game = connect(
  (state: any) => ({
    token: getToken(state),
    user: getUser(state),
  }),
  { getAuthToken, cleanUpModule, addNotification }
)(withTranslation()(_Game));

const Container = styled.div`
  background: var(--color-dark-layout);

  @media screen and (orientation: landscape) {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
  }

  & > iframe {
    @media screen and (orientation: portrait) {
      transform: rotate(90deg);
      transform-origin: calc(100vw / 2);
    }
  }
`;

const AuthLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9996;
  background: rgba(24, 35, 40, 0.9);
  min-height: calc(100vh - var(--size-nav-bar));
  padding: 0 1rem;
`;
