import { IMessages } from './reducer.interface';
import { IPointer } from './services.interface';

export enum chatsActions {
  UPDATE_CHAT = 'app/chat/UPDATE_CHAT',
  REMOVE_CHAT_MESSAGE = 'app/chat/REMOVE_CHAT_MESSAGE',
  CHANGE_ROOM = 'app/chat/CHANGE_ROOM',
}

interface updateMessageAction {
  type: typeof chatsActions.UPDATE_CHAT;
  payload: IMessages;
}

interface removeMessageAction {
  type: typeof chatsActions.REMOVE_CHAT_MESSAGE;
  payload: IPointer;
}

interface changeRoomAction {
  type: typeof chatsActions.CHANGE_ROOM;
}

export type MessageActionTypes = updateMessageAction | removeMessageAction | changeRoomAction;
