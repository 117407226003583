import React from 'react';
import { CustomItemInfo } from 'games/CaseGame/interfaces/cases.interface';
import {
  Container,
  ContentFirstPart,
  ContentSecondPart,
  DropPercentage,
  ItemImg,
  ItemName,
  ItemPrice,
} from './CaseItemDefault.styles';
import { isEqual } from 'lodash';
import { colorDictionaryForCaseItem, skinItemImage } from 'core/utils';
import { currencyService } from 'services/currency';
import { useDialog } from 'core/hooks';
import { CaseItemDialog } from 'games/CaseGame';
import { CaseEnum } from 'games/CaseGame/constants';

interface CaseItemProps {
  icon: string;
  type: string;
  color: string | null;
  fullName: string | null;
  customItemInfo: CustomItemInfo[];
  isFree: boolean;
  caseType: CaseEnum;
  redirectHandler?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
//NOTE:this type was created to possibly extend component type

export const CaseItemDefault: React.FC<CaseItemProps> = React.memo(
  ({ customItemInfo, icon, type, color, fullName, redirectHandler, caseType, isFree }) => {
    const { handleOpenDialog } = useDialog(CaseItemDialog.COUNT_DIALOG);
    const prices = customItemInfo.map(({ price }) => price);
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    const count = customItemInfo.reduce((acc, curr) => acc + curr.count, 0) / customItemInfo.length;

    const clickHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      localStorage.setItem('itemName', fullName);
      handleOpenDialog();
    };

    return (
      <Container
        color={colorDictionaryForCaseItem[color.toLocaleUpperCase()]}
        onClick={redirectHandler}
      >
        <ContentFirstPart>
          {(!isFree || caseType !== CaseEnum.FREE) && (
            <DropPercentage onClick={clickHandler}>{(count / 1000).toFixed(3)}%</DropPercentage>
          )}
          <ItemImg src={skinItemImage(icon)} loading="lazy" />
        </ContentFirstPart>
        <ContentSecondPart>
          <ItemName>{fullName ?? type}</ItemName>
          {customItemInfo.length > 1 ? (
            <ItemPrice>
              {currencyService.display(min)} - {currencyService.display(max)}
            </ItemPrice>
          ) : (
            <ItemPrice>{currencyService.display(min)}</ItemPrice>
          )}
        </ContentSecondPart>
      </Container>
    );
  },
  isEqual
);
