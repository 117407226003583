import React from 'react';
import BonusBG from './bonusBG.svg';
import { ReactComponent as Case } from './case.svg';
import { ReactComponent as Steam } from './steam.svg';
import { ReactComponent as Google } from './google.svg';
import { ReactComponent as Telegram } from './telegram.svg';
import { ReactComponent as Twitch } from './twitch.svg';
// import { ReactComponent as Yandex } from './yandex.svg';
import { ReactComponent as VK } from './vk.svg';
import { ReactComponent as Meta } from './meta.svg';
import { AuthTypes } from 'core/Auth/interfaces';
import { discountObject } from './discounts';

interface ObjValue {
  keyWord: AuthTypes;
  component: JSX.Element;
}

const ReferralIconsComponent: Record<AuthTypes, ObjValue | undefined> = {
  steam: {
    component: <Steam />,
    keyWord: 'steam',
  },
  vkontakte: {
    component: <VK />,
    keyWord: 'vkontakte',
  },
  meta: {
    component: <Meta />,
    keyWord: 'meta',
  },
  // yandex: {
  //   component: <Yandex />,
  //   keyWord: 'yandex',
  // },
  twitch: {
    component: <Twitch />,
    keyWord: 'twitch',
  },
  telegram: {
    component: <Telegram />,
    keyWord: 'telegram',
  },
  google: {
    component: <Google />,
    keyWord: 'google',
  },
  twitter: undefined,
};

export const ReferralIcons = {
  BonusBG,
  Case,
  ReferralIconsComponent,
  discountObject,
};
