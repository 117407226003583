import { IGame } from '../core/AppShell';

export const gameSettingsAdapter = ({
  classic,
  fast,
  ...rest
}: Record<string, IGame>): Record<string, IGame> => {
  return {
    ...rest,
    classic: {
      ...classic,
      min_bet: classic.min_bet * 100,
    },
    fast: {
      ...fast,
      min_bet: fast.min_bet * 100,
      max_bet: fast.max_bet * 100,
    },
  };
};
