import React from 'react';
import { LotteryTabBar } from './lottery-tab-bar';
import { LotteryList } from './lottery-list';

export const Lottery = () => {
  return (
    <div className="content dark-layout">
      <LotteryTabBar />
      <LotteryList />
    </div>
  );
};
