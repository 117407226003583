import React, { FC } from 'react';

import { AppBar } from '@components/bars';
import { Top } from '@components/Top';

export const TopsPage: FC = () => (
  <>
    <AppBar deep />
    <Top type="fast" />
  </>
);
