export default {
  'Cases types': 'Виды кейсов',
  'Possible contents of the case': 'Возможное содержимое кейса',
  'Other cases': 'Другие кейсы',
  'Open for': 'Открыть за',

  //rules
  'How is this mode different from others?': 'В чём особенность данного режима?',
  'СASES – is a fun mode where you can spend coins to open cases and win great skins. Everyone loves it!':
    'СASES - всеми любимый и весёлый режим, в котором вы можете открывать кейсы за монеты и получать отличные скины!',
  'How do I open a case?': 'Как открывать кейсы?',
  'Opening a case is simple. Just select your preferred case of skins and press Open. When the animation stops, a red line will indicate which skin you have won.':
    'Всё очень просто! Вам необходимо выбрать понравившийся вам кейс со скинами и нажать кнопку “открыть”. После остановки анимации красная линия покажет какой скин вы выиграли.',
  'What can I do with the skin I won?': 'Что я могу делать с выигранным скином?',
  "Once you've opened the case and received your skin, you can add it to your Steam inventory by pressing the Claim button. Alternatively, you can sell the skin to get an immediate coin reward credited to your account on our website and continue playing.":
    'После того, как вы открыли кейс и получили скин - вы можете его вывести к себе в инвентарь Steam, нажав кнопку “забрать”, или продать и моментально получить монеты за него на свой баланс на нашем сайте и играть дальше!',
  sortBy: {
    label: 'Сортировать по',
    ASC_PRICE: 'По цене (сначала дешевые)',
    ASC_PRICE_short: 'По цене',
    DESC_PRICE: 'По цене (сначала дорогие)',
    DESC_PRICE_short: 'По цене',
    ASC_NAME: 'По имени A-Z',
    ASC_NAME_short: 'По имени',
    DESC_NAME: 'По имени Z-A',
    DESC_NAME_short: 'По имени',
    OVERPRICE: 'По наценке',
    UNDERPRICE: 'По уценке',
    price: '$t(sortBy.ASC_PRICE)',
    '-price': '$t(sortBy.DESC_PRICE)',
    name: '$t(sortBy.ASC_NAME)',
    '-name': '$t(sortBy.DESC_NAME)',
    popular: 'По популярности',
    button: 'Готово',
  },
  Close: 'Закрыть',
  priceRange: {
    label: 'Задать стоимость',
    button: 'Готово',
    setManually: 'Задать вручную',
  },
  search: {
    searchTitle: 'Найти кейс',
    placeholder: 'Найти кейс',
  },
  countsDialogHeader: {
    wear: 'Износ',
    price: 'Цена',
    odds: 'Шанс',
    close: 'ЗАКРЫТЬ',
  },
  revision: {
    period: 'Период',
    caseRevision: 'Ревизия Кейса',
    close: 'Закрыть',
    skinPrice: 'Скин и Цена',
    range: 'Диапазон',
    chance: 'Шанс',
  },
  itemInfo: {
    wear: 'Износ',
    steamPrice: 'Steam цена',
    statTrak: 'StatTrak™',
    collection: 'Коллекция:',
    description: 'Описание:',
    selectionCases: 'Подборка кейсов №1',
  },
  openCase: 'ОТКРЫТЬ КЕЙС',
  login: 'АВТОРИЗОВАТЬСЯ',
  refill: 'ПОПОЛНИТЬ',
  draw: 'ИДËТ РОЗЫГРЫШ',
  sellFor: 'ПРОДАТЬ ЗА',
  demo: 'Демо открытие',
  openFreeCase: 'ОТКРЫТЬ БЕСПЛАТНЫЙ КЕЙС',
  demoSpin: 'ПОВТОРИТЬ ДЕМО ОТКРЫТИЕ',
  soundsSettings: {
    sounds: 'Звуки',
    standard: 'Стандарт',
    jingle: 'Джунгли',
    danger: 'Опасность',
    random: 'Случайный',
    approach: 'Сближение',
    soundOff: 'Без звука',
  },
  caseItems: 'Предметы в кейсе',
  open: 'Развернуть',
  closeItems: 'Свернуть',
  caseRevision: 'Ревизия кейса',
  notFound: 'По вашему запросу ничего не найдено',
  searchTerms: 'Попробуйте уменьшить количество поисковых запросов',
  openCount: 'ОТКРЫТЬ БЕСПЛАТНО: ',
  okey: 'ХОРОШО',
  watchReplay: 'ПОСМОТРЕТЬ РЕПЛЕЙ',
  getItemFromCase: 'Получил предмет из кейсa',
  replay: 'ПОВТОР',
  all: 'Все',
  best: 'Лучшие',
  notAvailable: 'не доступен',
  history: 'История',
  case: 'Кейс',
  item: 'Предмет',
  status: 'Статус',
  sell: 'Продать',
  sold: 'Продано',
};
