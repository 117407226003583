import React from 'react';
import styled from 'styled-components';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { profileModals } from '../../../../modal.config';
import rlfg from '../../../../assets/referral-landing-frontground.png';
import rlbg from '../../../../assets/referral-landing-background.png';
import { useTranslation } from 'react-i18next';

export const ReferralPreview = () => {
  const { t } = useTranslation();
  const { handleOpenDialog } = useDialog(profileModals.CREATE_REFERRAL_COMPANY);
  return (
    <Container>
      <div className="preview-buffer" />
      <div className="preview-content">
        <span className="preview-content-title">{t('Boost your revenue!')}</span>
        <span className="preview-content-description">
          {t(
            'Create your own advertising campaign Share your referral link and get a percentage of deposits to your balance!'
          )}
        </span>
      </div>
      <div className="preview-values">
        <div className="preview-values-members">
          <span>{t('Referrals')}</span>
          {/*<span>1 275 394 </span>*/}
        </div>
        <div className="preview-values-paid">
          <span>{t('Paid')}</span>
          {/*<span>275 394 ₽ </span>*/}
        </div>
      </div>
      <div className="preview-controller">
        <Button className="primary" onClick={handleOpenDialog}>
          {t('Create a campaign')}
        </Button>
      </div>
      <div className="preview-subtitle">
        <span>{t('Still have questions? we are')}</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 0.6fr 2fr 0.5fr 0.9fr 2rem;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 39.375rem;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -22%;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }

  &:before {
    background-image: url(${rlfg});
    background-color: #110d27;
    z-index: -2;
  }
  &:after {
    background-image: url(${rlbg});
    z-index: -1;
  }

  & .preview-content {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    align-self: center;

    &-title,
    &-description {
      width: 75%;
      justify-self: center;
    }

    &-title {
      font-weight: bold;
      font-size: 1.875rem;
      line-height: 2.25rem;
      text-align: center;
      letter-spacing: 0.54px;
      color: var(--warning);
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
    }
    &-description {
      font-size: 0.875rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.24px;
      color: var(--color-white);
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.63);
      position: relative;
      top: 1rem;
    }
  }

  & .preview-values {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    padding: 0 1rem;
    height: 3rem;
    align-self: end;

    &-members,
    &-paid {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: min-content;

      & span {
        text-align: center;
        letter-spacing: 0.3px;
        &:first-of-type {
          font-weight: bold;
          font-size: 0.625rem;
          line-height: 1rem;
          height: 1.2rem;

          color: var(--warning);
        }

        &:last-of-type {
          font-size: 1.25rem;
          line-height: 1.5rem;
          color: var(--color-white);
        }
      }
    }
  }

  & .preview-controller {
    padding: 0 1rem;
    align-self: center;
  }

  & .preview-subtitle {
    display: flex;
    align-items: center;
    padding: 0 1rem;

    & span {
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
    }
  }
`;
