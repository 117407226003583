export const getDomains = (): string => {
  if (process.env.DOMAIN) {
    return process.env.DOMAIN;
  }

  const hostname = window.location.hostname.split('.').slice(-2).join('.');

  return `${hostname}/`;
};

export const getRefillDomain = (): string => 'https://pay.csgofastbackend.com';

export const getDomainName = (): string => 'CSGOFAST.COM';
