import { useState } from 'react';

export const useSortedPlayers = (players: string[], winnerId: number) => {
  const [sortedPlayers] = useState(() => {
    const withoutWinnerId = players.filter(item => Number(item) !== winnerId);
    return [withoutWinnerId[0], winnerId.toString(), withoutWinnerId[withoutWinnerId.length - 1]];
  });

  return { sortedPlayers };
};
