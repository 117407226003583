export default {
  //CryptoPage title
  crypto: 'Wypłata kryptowaluty',
  cryptocurrency: 'Kryptowaluta',
  from: 'od',
  //
  'Email is required': 'Potrzebny jest e-mail',
  Withdraw: 'wycofać',
  'Withdrawal sum': 'Suma wypłaty',
  'Amount in {{title}}:': 'Kwota w $t({{title}}):',
  'Receive in {{title}}': 'Otrzymuj w $t({{title}}):',
  'You will receive:': 'Otrzymasz:',
  'Select the network fee': 'Wybierz opłatę sieciową',
  'Commission:': 'Zamówienie:',
  'Ultra Low': 'Bardzo wolno',
  Low: 'Powolny',
  Medium: 'Standardowy',
  High: 'Szybki',
  'No commission': 'Brak prowizji',
  commission: 'Możliwa prowizja',
};
