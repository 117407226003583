export enum GameCondition {
  PENDING = 'PENDING',
  RAFFLING = 'RAFFLING',
  RESULT = 'RESULT',
}

export enum SectorColors {
  ZERO = 'zero',
  RED = 'red',
  BLACK = 'black',
}

export const getSectorColorByRand = (rand: number): SectorColors => {
  if (rand === 0) return SectorColors.ZERO;
  if (rand >= 1 && rand <= 7) return SectorColors.RED;
  return SectorColors.BLACK;
};

export const SECTORS = [1, 8, 2, 9, 3, 10, 4, 11, 5, 12, 6, 13, 7, 14, 0];

export const ROULETTE_DEGREES: Record<number, number> = {
  1: 0,
  0: 24,
  14: 48,
  7: 72,
  13: 96,
  6: 120,
  12: 144,
  5: 168,
  11: 192,
  4: 216,
  10: 240,
  3: 264,
  9: 288,
  2: 312,
  8: 336,
};

export const getSectorByRand = (rand: string): number => Math.floor(parseFloat(rand) * 15);
