import { createSelector } from 'reselect';

export const getClassicGameData = ({ classic }) => classic.data;

export const getClassicGameTrades = createSelector(
  ({ classic }) => classic.trades.allIDs,
  ({ classic }) => classic.trades.byID,
  (allIDs, byID) => allIDs.map(id => byID[id])
);

export const getClassicGameTradesDictionary = ({ classic }) => classic.trades.byID;

export const getClassicGameTapeVisibilityStatus = ({ classic }) => classic.tapeIsVisible;

export const getClassicGameOverStatus = ({ classic }) => classic.gameIsOver;

export const getClassicGameTimerValue = ({ classic }) => classic.timerValue;

export const getClassicGameTapeStatus = ({ classic }) => classic.tapeStatus;

export const getClassicGamePrize = ({ classic }) => classic.data.prize;

export const getClassicGameWinner = ({ classic }) => classic.winner;

export const getClassicGameUserChances = state => state.classic.trades.byID[state.user.base.id];

export const getClassicGameHistory = ({ classic }) => classic.history;

export const getClassicGameLadder = ({ classic }) => classic.ladder;

export const getClassicGameWinnerVisibleStatus = ({ classic }) => classic.gameWinnerVisibleStatus;
