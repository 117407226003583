import { Reducer } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  ignoreElements,
  map,
  pluck,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { getUser } from 'core/User';
import { addNotification } from 'modules/Notifications/duck';
import { getInputAmount, getPayoutOrder } from './selectors';
import type { withdrawalActions } from './interfaces/actions.withdrawal.interface';
import { withdrawalActionsTypes } from './interfaces/actions.withdrawal.interface';
import payoutRepository from './repositories/payout.repository';
import {
  ICreateOrderSuccess,
  IFeeItem,
  IOutputAmount,
  IWithdrawalCurrency,
  IWithdrawalMethod,
  IWithdrawalRecord,
  Rate,
  WithdrawalRecord,
} from './interfaces/reducers.withdrawal.interface';
import { dialogOn } from '../../core/ducks/dialog';
import { WithdrawalDialogs } from './withdrawal-dialogs.config';

export const withdrawal: Reducer<IWithdrawalRecord, withdrawalActions> = (
  state = new WithdrawalRecord(),
  action
) => {
  switch (action.type) {
    case withdrawalActionsTypes.UPDATE_PAYOUT_CURRENCY: {
      const { currency } = action.payload;
      return state.set('currency', currency);
    }

    case withdrawalActionsTypes.UPDATE_REDIRECT_URL: {
      const { url } = action.payload;
      return state.set('url', url);
    }

    case withdrawalActionsTypes.UPDATE_FEE: {
      const { fee, input } = action.payload;
      return state.set(
        'fee',
        Object.keys(fee).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              ...fee[key],
              percent: (100 - (fee[key].receiveInUSD / input) * 100).toFixed(2),
              payment: input - fee[key].receiveInUSD,
            },
          }),
          {}
        )
      );
    }

    case withdrawalActionsTypes.UPDATE_INPUT_AMOUNT: {
      const { input } = action.payload;
      return state.setIn(['amount', 'input'], input);
    }

    case withdrawalActionsTypes.UPDATE_OUTPUT_AMOUNT: {
      const {
        percent,
        receive,
        currency,
        reciveWithoutFee,
        coinsAmount2,
        needPlayCoinsAmount,
        payback,
        rate,
      } = action.payload;

      return state.mergeIn(['amount'], {
        ...state.get('amount'),
        output: coinsAmount2,
        receive: receive,
        percent: percent,
        currency: currency,
        receiveWithoutFee: reciveWithoutFee,
        needPlayCoinsAmount: needPlayCoinsAmount,
        payback: payback,
        rate: rate,
      });
      // state
      //   .setIn(['amount', 'output'], coinsAmount2)
      //   .setIn(['amount', 'receive'], receive)
      //   .setIn(['amount', 'percent'], percent)
      //   .setIn(['amount', 'currency'], currency)
      //   .setIn(['amount', 'receiveWithoutFee'], reciveWithoutFee)
      //   .setIn(['amount', 'needPlayCoinsAmount'], needPlayCoinsAmount)
      //   .setIn(['amount', 'payback'], payback)
      //   .setIn(['amount', 'rate'], rate)
    }

    case withdrawalActionsTypes.CREATE_PAYOUT_ORDER: {
      return state.mergeIn(['order_status'], {
        condition: 'pending',
      });
    }

    case withdrawalActionsTypes.UPDATE_PAYOUT_ORDER: {
      const { order } = action.payload;
      return state.mergeIn(['order'], order);
    }

    case withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_FEE: {
      const { feeIndex } = action.payload;
      return state.setIn(['order', 'feeIndex'], feeIndex);
    }

    case withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_STATUS: {
      const { status } = action.payload;
      return state.setIn(['order_status'], status);
    }

    case withdrawalActionsTypes.CLEANUP_PAYOUT_ORDER: {
      return state
        .setIn(['order'], {
          paymentSystem: 0,
          paymentWallet: null,
          recaptchaToken: null,
          feeIndex: 0,
        })
        .setIn(['order_status'], {
          condition: null,
          messages: null,
          params: null,
        })
        .set('fee', {})
        .set('amount', {
          input: 0,
          output: 0,
          receive: '',
          currency: '',
          percent: '',
          receiveWithoutFee: '',
          rate: 0,
          payback: 0,
          needPlayCoinsAmount: 0,
        });
    }

    case withdrawalActionsTypes.UPDATE_PAYOUT_METHODS: {
      const { methods } = action.payload;
      return state.setIn(['methods'], methods);
    }

    case withdrawalActionsTypes.REQUEST_RATE: {
      return state.setIn(['rate', 'isLoading'], true);
    }
    case withdrawalActionsTypes.ADD_RATE: {
      return state.setIn(['rate', 'data'], action.payload.rate).setIn(['rate', 'isLoading'], false);
    }
    default: {
      return state;
    }
  }
};

export const attachListener = () => ({
  type: withdrawalActionsTypes.WITHDRAWAL_ATTACH_LISTENERS,
});

export const detachListener = () => ({
  type: withdrawalActionsTypes.WITHDRAWAL_DETACH_LISTENERS,
});

const updateRedirectUrl = (url: string) => ({
  type: withdrawalActionsTypes.UPDATE_REDIRECT_URL,
  payload: {
    url,
  },
});

const updateFee = (fee: Record<string, IFeeItem>, input: number) => ({
  type: withdrawalActionsTypes.UPDATE_FEE,
  payload: { fee, input },
});

export const updateInputAmount = (input: number, method: string) => ({
  type: withdrawalActionsTypes.UPDATE_INPUT_AMOUNT,
  payload: {
    input,
    method,
  },
});

const updateOutputAmount = ({
  receive,
  percent,
  currency,
  reciveWithoutFee,
  coinsAmount2,
  rate,
  payback,
  needPlayCoinsAmount,
}: IOutputAmount) => ({
  type: withdrawalActionsTypes.UPDATE_OUTPUT_AMOUNT,
  payload: {
    receive,
    percent,
    currency,
    reciveWithoutFee,
    coinsAmount2,
    rate,
    payback,
    needPlayCoinsAmount,
  },
});

export const createPayoutOrder = (paymentSystem: string) => ({
  type: withdrawalActionsTypes.CREATE_PAYOUT_ORDER,
  payload: {
    paymentSystem,
  },
});

export const updatePayoutOrder = (order: Record<string, string | number>) => ({
  type: withdrawalActionsTypes.UPDATE_PAYOUT_ORDER,
  payload: {
    order,
  },
});

export const updatePayoutOrderFee = (feeIndex: number, method: string) => ({
  type: withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_FEE,
  payload: {
    feeIndex,
    method,
  },
});

// const updatePayoutOrderStatus = (status: Record<string, string | number | ICreateOrderSuccess>) => ({
//   type: withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_STATUS,
//   payload: {
//     status,
//   },
// })

export const cleanUpOrder = () => ({
  type: withdrawalActionsTypes.CLEANUP_PAYOUT_ORDER,
});

const updateMethods = (methods: Record<string, IWithdrawalMethod>) => ({
  type: withdrawalActionsTypes.UPDATE_PAYOUT_METHODS,
  payload: {
    methods,
  },
});

const updatePayoutCurrency = (currency: IWithdrawalCurrency[]) => ({
  type: withdrawalActionsTypes.UPDATE_PAYOUT_CURRENCY,
  payload: {
    currency,
  },
});
const addRate = (rate: string) => ({
  type: withdrawalActionsTypes.ADD_RATE,
  payload: {
    rate,
  },
});
export const requestRate = () => ({
  type: withdrawalActionsTypes.REQUEST_RATE,
});
const loaderEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(withdrawalActionsTypes.WITHDRAWAL_ATTACH_LISTENERS),
    switchMap(() =>
      payoutRepository
        .getPayoutConfig(getUser(store$.value).id)
        .pipe(map(({ response }) => updateMethods(response.methods)))
    )
  );

const orderEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(withdrawalActionsTypes.CREATE_PAYOUT_ORDER),
    debounceTime(700),
    pluck('payload', 'paymentSystem'),
    map((paymentSystem: string) => [
      paymentSystem,
      getPayoutOrder(store$.value),
      getInputAmount(store$.value),
    ]),
    tap(([paymentSystem, order, coinsAmount]) => {
      socket.io.emit('payout:new', {
        coinsAmount: coinsAmount,
        paymentSystem,
        paymentWallet: order.paymentWallet,
        // recaptchaToken: order.recaptchaToken,
        feeIndex: order.feeIndex,
      });
    }),
    ignoreElements()
  );

const outputAmountEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(withdrawalActionsTypes.UPDATE_INPUT_AMOUNT),
    pluck('payload'),
    debounceTime(700),
    tap((payload: Record<string, number | string>) => {
      socket.io.emit('payout:calc', {
        coinsAmount: payload.input as number,
        paymentSystem: payload.method,
      });
      // socket.io.emit('payout:rate')
    }),
    switchMap(payload =>
      merge(
        fromEvent<IOutputAmount>(socket.io, 'payout:calc:result').pipe(
          take(1),
          map(data => updateOutputAmount(data))
        ),
        // fromEvent<Rate>(socket.io, 'payout:rate:result').pipe(map(data => addRate(data.rate))),
        of(payload).pipe(
          filter(({ method }) => method === 'bitcoin'),
          switchMap(({ input }) =>
            payoutRepository.getPayoutFee(input as number).pipe(
              map(({ response }) => updateFee(response.fee, input as number)),
              catchError(() => of({}))
            )
          )
        )
      )
    )
  );
const RateEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(withdrawalActionsTypes.REQUEST_RATE),
    pluck('payload'),
    debounceTime(700),
    tap(() => {
      socket.io.emit('payout:rate');
    }),
    switchMap(() =>
      fromEvent<Rate>(socket.io, 'payout:rate:result').pipe(
        take(1),
        map(data => addRate(data.rate))
      )
    )
  );

const feeWatcherEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_FEE),
    debounceTime(700),
    pluck('payload'),
    tap(payload => {
      const coinsAmount = getInputAmount(store$.value);
      socket.io.emit('payout:calc', {
        coinsAmount: coinsAmount,
        paymentSystem: payload.method,
        feeIndex: payload.feeIndex,
      });
    }),
    switchMap(() =>
      fromEvent<IOutputAmount>(socket.io, 'payout:calc:result').pipe(
        take(1),
        map(data => updateOutputAmount(data))
      )
    )
  );

const listeners: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(withdrawalActionsTypes.WITHDRAWAL_ATTACH_LISTENERS),
    switchMap(() =>
      merge(
        fromEvent<Record<string, any>>(socket.io, 'payout:card:redirect').pipe(
          map(({ url }) => updateRedirectUrl(url))
        ),
        fromEvent<ICreateOrderSuccess>(socket.io, 'payout:order:success').pipe(
          concatMap(({ id, coinsAmount }) => [
            addNotification({
              type: 'success',
              body:
                // eslint-disable-next-line
                'Your request {{id}} for withdrawal ${currency(coinsAmount)} has been accepted.',
              params: { id, coinsAmount },
            }),
            dialogOn(WithdrawalDialogs.WITHDRAW_VERIFICATION),
          ])
        ),
        fromEvent<Record<string, any>>(socket.io, 'payout:order:error').pipe(
          map(({ id, error, message, params }) =>
            addNotification({
              type: 'error',
              body: id
                ? 'Withdrawal {{ id }} was canceled. Contact technical support'
                : error ?? message,
              params: { id, ...params },
            })
          )
        ),
        payoutRepository.getPayoutCurrency().pipe(
          map(({ response }) => updatePayoutCurrency(response?.rates ?? [])),
          catchError(() => of(updatePayoutCurrency([])))
        )
      ).pipe(takeUntil(action$.ofType(withdrawalActionsTypes.WITHDRAWAL_DETACH_LISTENERS)))
    )
  );

export const withdrawalEpics = combineEpics(
  orderEpic,
  outputAmountEpic,
  feeWatcherEpic,
  listeners,
  loaderEpic,
  RateEpic
);
