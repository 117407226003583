export default {
  GAME: 'JUEGO',
  Winner: 'Ganador',
  Won: 'Ganado',
  Chance: 'Probabilidad',
  Prize: 'Premio',
  'At stake': 'En el juego',
  'Sign in through STEAM to participate': 'Inicia sesión a través de STEAM para participar',
  'Steam trade link': 'Enlace de intercambio de Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Sea el primero en depositar',
  'Place a bet': 'Haga la apuesta',
  'Deposit more': 'Depositar más',
  'Min bet': 'Apuesta mín.',
  'Max bet': 'Apuesta máx.',
  'Bet amount': 'Monto de apuesta',
  'max. items': 'artículos máx.',
  hidden: 'oculto',
  Copy: 'Copiar',
  'Winning after commission': 'Premio después de comisión',
  Loading: 'Cargando',
};
