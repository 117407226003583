import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import { SignIn } from '../../../../core/Auth';
import ControlsRate from './ControlsRate';
import ControlBet from './ControlBet/ControlBet';
import ProgressBetWinner from './ProgressBetWinner';

class Controls extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.settings.min_bet / 100,
      coefficient: 0,
    };
    this.controlsAnimation = new Animated.Value(props.gameRun || props.userBet.isSet ? 1 : 0);
  }

  componentDidMount() {
    const { gameRun } = this.props;
    if (gameRun) this.changeAnimationControls(false);
  }

  componentDidUpdate(prevProps) {
    const { gameRun, userBet, gameRunTs } = this.props;
    if (
      (prevProps.gameRun !== gameRun && gameRun) ||
      (prevProps.userBet.isSet !== userBet.isSet && userBet.isSet) ||
      (!gameRun && userBet.isSet)
    ) {
      this.changeAnimationControls(false);
    }

    if (prevProps.gameRunTs !== gameRunTs && !gameRun) {
      this.changeAnimationControls(true);
    }
  }

  render() {
    const { amount, coefficient } = this.state;

    const {
      settings,
      user,
      gameRun,
      userBet,
      crashed,
      gameActions,
      appDiffTime,
      gameRunTs,
      coef,
    } = this.props;

    if (!user.id) {
      return (
        <Container>
          <SignIn className="primary full" />
        </Container>
      );
    }

    const translateYInterpolate = this.controlsAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 400],
    });

    const opacityInterpolate = this.controlsAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 0],
    });

    const alignInterpolate = this.controlsAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [120, 20],
    });

    const progressTranslateYInterpolate = this.controlsAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [-40, 25],
    });

    const heightInterpolate = this.controlsAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [200, userBet.isSet ? 200 : 110],
    });

    return (
      <Animated.div
        style={{
          minHeight: heightInterpolate,
          position: 'relative',
          marginBottom: 10,
        }}
        className="tablet"
      >
        <Animated.div
          style={{
            transform: [{ translateY: translateYInterpolate }],
            opacity: opacityInterpolate,
          }}
        >
          {!gameRun && !userBet.isSet && (
            <ControlsRate
              settings={settings}
              amount={amount}
              coefficient={coefficient}
              handleAmount={this.handleAmount}
              handleCoefficient={this.handleCoefficient}
            />
          )}
        </Animated.div>
        {userBet.isSet && (
          <Animated.div
            style={{
              transform: [{ translateY: progressTranslateYInterpolate }],
            }}
          >
            <ProgressBetWinner
              userBet={userBet}
              appDiffTime={appDiffTime}
              gameRunTs={gameRunTs}
              crashed={crashed}
              coef={coef}
              gameRun={gameRun}
            />
          </Animated.div>
        )}
        <Animated.div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 20,
            left: alignInterpolate,
          }}
        >
          <ControlBet
            amount={amount}
            appDiffTime={appDiffTime}
            coef={coef}
            coefficient={coefficient}
            crashed={crashed}
            gameActions={gameActions}
            gameRun={gameRun}
            gameRunTs={gameRunTs}
            userBet={userBet}
          />
        </Animated.div>
      </Animated.div>
    );
  }

  handleCoefficient = coefficient => {
    this.setState({ coefficient });
  };

  handleAmount = amount => {
    this.setState({ amount });
  };

  changeAnimationControls = isShow => {
    Animated.spring(this.controlsAnimation, {
      toValue: isShow ? 0 : 1,
      duration: 1000,
    }).start();
  };
}

export default Controls;

const Container = styled.div`
  margin: 1rem;
`;
