import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  backdrop-filter: blur(3px);
  background: rgba(19, 26, 29, 0.9);
  padding: 0 16px;

  &.teams {
    gap: 10px;
    grid-template-columns: repeat(2, 166px);

    @media screen and (max-width: 374px) {
      grid-template-columns: repeat(2, 138px);
    }
  }
`;

export const BlurComponent = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  height: 100%;
  top: 0;
  width: 30px;
  background: linear-gradient(-90deg, #131a1d 0%, rgba(19, 26, 29, 0) 100%);
  ${({ position }) => (position === 'right' ? 'right:0;' : 'left:0;transform: rotate(180deg);')};
`;

export const TotalWinnings = styled.div`
  color: var(--color-white);
  height: 14px;
  font-weight: 700;
  font-size: 12px;
`;

export const TotalPriceContainer = styled.div`
  font-weight: 700;
  font-size: 14px;
  height: 16px;
  font-style: normal;
  color: var(--yellow);
`;
