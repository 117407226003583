import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  max-height: 80vh;
  overflow: hidden scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & .filter-title {
    height: 3.75rem;
    padding: 2.125rem 1rem 0.5rem;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-white);

    &:not(:first-of-type) {
      padding-top: 1.5rem;
    }
  }

  & .filter-list {
    display: grid;
    grid-auto-flow: row;

    &-item {
      display: grid;
      grid-template-columns: 1.625rem 1fr;
      grid-template-rows: 3.75rem;
      grid-gap: 1.25rem;
      padding: 0 1.125rem;
      background: transparent;
      border: 0;

      & div {
        align-self: center;
        justify-self: self-start;
      }

      & .indicator {
        display: block;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background: var(--color-dark-100);
        border: 3px solid var(--gray-220);
        transition: all 70ms linear;

        &.selected {
          border-width: 4px;
          background: var(--warning);
        }
      }

      & .description {
        display: grid;
        grid-auto-rows: 1rem;
        grid-auto-flow: row;
        grid-gap: 2px;

        & span {
          font-size: 13px;
          line-height: 0.875rem;
          letter-spacing: 0.286px;
          color: var(--color-white);
        }
      }
    }

    &-item:hover ~ .filter-controller {
      opacity: 1;
      bottom: 0;
    }

    &-item:hover ~ .item-buffer {
      height: 6rem;
    }

    & .item-buffer {
      height: 0;
      transition: all 100ms ease-in 500ms;
    }
  }

  & .underprice,
  & .overprice {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    padding: 0.875rem 2.5rem 0.875rem 1.3rem;

    &:first-of-type {
      border-top: none;
    }

    &-title {
      font-size: 0.875rem;
      line-height: 25px;
      letter-spacing: 0.24px;
      color: var(--color-white);
    }

    &-selector {
      display: flex;
      flex-flow: row wrap;
      margin-right: -1.625rem;

      & button {
        margin: 0 0.75rem 0.5rem 0;
        padding: 0.5rem 0.75rem;
        background: var(--color-dark);
        border: 0.125rem solid var(--color-dark-200);
        border-radius: var(--size-radius-s);
        font-weight: bold;
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        color: var(--color-white);

        &:disabled {
          background: var(--color-dark-200);
        }
      }
    }
  }

  & .underprice {
    &-selector {
      & button {
        color: var(--green-100);
      }
    }
  }

  & .overprice {
    &-selector {
      & button {
        color: var(--color-red);
      }
    }
  }

  & .filter-controller {
    display: flex;
    padding: 0 1rem;
    position: fixed;
    left: 0;
    right: 0;
    bottom: -100%;
    opacity: 0;
    transition: all 100ms ease-in 100ms;
    overflow: hidden;
    height: 6rem;

    & button {
      margin: 1.25rem 0 1.75rem;
    }
  }

  & .split-container {
    display: grid;
    grid-template-columns: minmax(7.5rem, 0.75fr) 1fr;
    grid-gap: 0.5rem;
    padding: 1.25rem 1rem 1.75rem;
    height: 6rem;
  }
`;
