import { Reducer } from 'redux';
import Immutable from 'immutable';
import {
  filtersActions,
  FiltersActionTypes,
  FiltersRecord,
  IFiltersRecord,
  MergeType,
} from '../interfaces';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { map, pluck } from 'rxjs/operators';

export const filters: Reducer<Immutable.Record<IFiltersRecord>, filtersActions> = (
  state = new FiltersRecord(),
  action
) => {
  switch (action.type) {
    case FiltersActionTypes.CHANGE_FILTER: {
      const { key, value } = action.payload;
      return state.set(key, value).setIn(['page', 'number'], 1);
    }

    case FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER: {
      const {
        range: [min, max],
      } = action.payload;
      return state.set('minPrice', min).set('maxPrice', max).setIn(['page', 'number'], 1);
    }

    case FiltersActionTypes.CHANGE_PAGE_FILTER: {
      const { page } = action.payload;
      return state.setIn(['page', 'number'], page);
    }

    case FiltersActionTypes.RESET_FILTERS: {
      return state.setIn(['page', 'number'], 1);
    }

    default: {
      return state;
    }
  }
};

export const changeShopFilter = (key: string, value: any, merge: MergeType) => ({
  type: FiltersActionTypes.CHANGE_FILTER,
  payload: {
    key,
    value,
    merge,
  },
});

export const changePageFilter = (page: number, merge: MergeType) => ({
  type: FiltersActionTypes.CHANGE_PAGE_FILTER,
  payload: {
    page,
    merge,
  },
});

export const changePriceRangeFilter = (range: number[], merge: MergeType) => ({
  type: FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER,
  payload: {
    range,
    merge,
  },
});

export const updateFilters = (merge: MergeType) => ({
  type: FiltersActionTypes.UPDATE_FILTERS,
  payload: {
    merge,
  },
});

export const resetFilters = () => ({
  type: FiltersActionTypes.RESET_FILTERS,
  payload: {
    merge: MergeType.RESET,
  },
});

const filterWatcher: Epic = action$ =>
  action$.pipe(
    ofType(
      FiltersActionTypes.CHANGE_FILTER,
      FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER,
      FiltersActionTypes.RESET_FILTERS,
      FiltersActionTypes.CHANGE_PAGE_FILTER
    ),
    pluck('payload', 'merge'),
    map((merge: MergeType) => updateFilters(merge))
  );

export const filtersEpic = combineEpics(filterWatcher);
