import React, { FC } from 'react';
import styled from 'styled-components';

export interface TabBarProps {
  className?: string;
}

export const TabBar: FC<TabBarProps> = ({ children, className }) => (
  <Container className={className}>
    <div className="inner-tab-bar-container">{children}</div>
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.25rem minmax(min-content, 1fr) 1.25rem;
  min-height: 4.25rem;
  overflow: scroll hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before {
    content: '';
    display: block;
  }

  & .inner-tab-bar-container {
    align-self: center;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(min-content, 1fr);
    grid-template-rows: 1.875rem;
    min-height: 2.25rem;
    gap: 4px;

    border-radius: 0.375rem;
    padding: 0.125rem;

    /* div:nth-child(2) {
      background-color: #fda22f;
      color: #121b20;
      //NOTE: color temporary, for halloween event,
    } */

    & .tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 1rem;
      border: 1px solid var(--color-gray-900);
      background: var(--color-dark-200);
      border-radius: 8px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      /* identical to box height, or 140% */
      letter-spacing: 0.3px;
      text-transform: uppercase;
      white-space: nowrap;
      color: white;
      transition: all var(--transition-ease-out);

      &.active {
        color: var(--color-white);
        background: #47555f;
      }
    }
  }
`;
