import React, { FC } from 'react';
import styled from 'styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IDataNav {
  url: string;
  name: string;
  state: string | undefined;
}

const dataNav: IDataNav[] = [
  {
    url: '/top',
    name: 'Today',
    state: undefined,
  },
  {
    url: '/top',
    name: 'Yesterday',
    state: 'yesterday',
  },
  {
    url: '/top',
    name: 'Last year',
    state: 'all-the-time',
  },
];

export const Header: FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  return (
    <TopNav>
      {dataNav.map((x: IDataNav) => (
        <TopNavItem
          key={x.name}
          replace
          isActive={(_, location: any) => location?.state?.select === x.state}
          activeClassName="active"
          to={{
            pathname: match?.url || '',
            state: { select: x.state },
          }}
        >
          {t(x.name)}
        </TopNavItem>
      ))}
    </TopNav>
  );
};

const TopNav = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 12px;
`;

const TopNavItem = styled(NavLink)`
  font-size: 15px;
  padding: 10px 20px 10px 20px;
  border-radius: 29px;
  background-color: var(--color-dark-600);
  color: var(--blue);
  &:hover {
    color: var(--blue);
  }

  &.active {
    background: var(--blue);
    color: var(--dark);
  }
`;
