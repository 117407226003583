import React, { FC, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { CountryCard } from '../country-card/country-card';
import { useCountryPay } from 'modules/Refill/hooks';
import { countriesDictionary } from 'modules/Refill/refill.util';
import { SvgIcons } from '../assets';
import { getAutoRefillCountry } from 'modules/Refill/selectors';
import { refillLoading } from 'modules/Refill/duck';
import { IDialogComponent } from 'core/interfaces';
import { updateCountry } from 'core/User/ducks/duck';
import {
  BtnWrap,
  Container,
  ContainerList,
  Description,
  Header,
  Line,
  ListComp,
  Title,
  TitleWrap,
} from './refill-country-dialog.styles';

interface IDataDialog {
  userProfileCountry?: string;
}

interface IRefillCountryDialog extends IDialogComponent<IDataDialog> {}

export const RefillCountryDialog: FC<IRefillCountryDialog> = ({
  handleCloseModal,
  data: { userProfileCountry },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { refillCountry, handleChangeCountry } = useCountryPay();
  const autoRefillCountry = useSelector(getAutoRefillCountry, shallowEqual);
  const [country, setCountry] = useState(userProfileCountry || refillCountry || autoRefillCountry);

  const countryIndex = Object.keys(countriesDictionary).findIndex(i =>
    userProfileCountry ? i === userProfileCountry : i === refillCountry
  );

  const sortedCounties = Object.keys(countriesDictionary).filter(
    county => county !== autoRefillCountry
  );
  const options =
    userProfileCountry || refillCountry || !autoRefillCountry
      ? Object.keys(countriesDictionary)
      : [autoRefillCountry, ...sortedCounties];

  const handleConfirm = () => {
    if (userProfileCountry) {
      dispatch(updateCountry(country));
    } else {
      handleChangeCountry(country);
    }
    handleCloseModal();
  };

  const handleClick = (updatedCountry: string) => setCountry(updatedCountry);

  const handleClose = () => {
    if (refillCountry || userProfileCountry) {
      handleCloseModal();
    } else {
      handleChangeCountry(autoRefillCountry);
      handleCloseModal();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(refillLoading(false));
    }, 1000);

    return () => {
      dispatch(refillLoading(false));
      clearTimeout(timer);
    };
  }, [dispatch]);

  return (
    <Container>
      <Header>
        <TitleWrap>
          <Title>
            {t(userProfileCountry ? 'Choose your country' : 'Select the country of replenishment')}
          </Title>
          <SvgIcons.CrossIcon onClick={handleClose} />
        </TitleWrap>
        <Description>
          {t(
            userProfileCountry
              ? 'This flag will be displayed in your profile'
              : 'The available methods'
          )}
        </Description>
      </Header>
      <Line />
      <ContainerList>
        <ListComp
          width={window.innerWidth}
          height={window.innerHeight - 216}
          rowCount={options.length}
          rowHeight={48}
          overscanRowCount={3}
          scrollToIndex={countryIndex}
          scrollToAlignment="start"
          rowRenderer={({
            key,
            style,
            index,
          }: {
            key: string;
            style: React.CSSProperties;
            index: number;
          }) => (
            <CountryCard
              key={key}
              style={style}
              index={index}
              country={country}
              autoRefillCountry={autoRefillCountry || 'AF'}
              handleClick={handleClick}
              options={options}
            />
          )}
        />
      </ContainerList>
      <BtnWrap>
        <Button className="primary" onClick={handleConfirm}>
          {t('Confirm country')}
        </Button>
      </BtnWrap>
    </Container>
  );
};
