import { DIALOG } from 'dialogs/dialog-combiner';
import { CaseHistoryDialogConfig } from 'games/CaseGame';
import { CaseHistoryDialog } from './case-history-dialog';
import { transitions } from 'dialogs/transition.config';

DIALOG.add(CaseHistoryDialogConfig.DIALOG_HISTORY, {
  component: CaseHistoryDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});
