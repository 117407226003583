import { RouletteStatus } from '../ducks/roulette-duck';

export const checkStatus = (
  rouletteStatus: Record<number, RouletteStatus>,
  value: RouletteStatus,
  lineIndex: number,
  lineCount?: number
) => {
  let statusChecker: boolean[] = [];

  const rouletteStatusTemp = {
    ...rouletteStatus,
    [lineIndex]: value,
  };

  for (let key in rouletteStatusTemp) {
    if (rouletteStatusTemp[key] === value) {
      statusChecker.push(true);
    }
  }
  const isStatusValid = statusChecker.length === lineCount;
  return { isStatusValid, rouletteStatusTemp };
};
