export default {
  GAME: 'JOGO',
  Winner: 'Vencedora',
  Won: 'Ganhou',
  Chance: 'Chance',
  Prize: 'Prémio',
  'At stake': 'Estaca',
  'Sign in through STEAM to participate': 'Inicie sessão através do STEAM para participar',
  'Steam trade link': 'Link de comércio de vapor',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Seja o primeiro a depositar',
  'Place a bet': 'Lugar na aposta',
  'Deposit more': 'Deposite mais',
  'Min bet': 'Aposta mínima',
  'Max bet': 'aposta máxima',
  'Bet amount': 'Valor da aposta',
  'max. items': 'Itens máximos',
  hidden: 'escondido',
  Copy: 'Cópia de',
  'Winning after commission': 'Ganhando após a comissão',
  Loading: 'Carregando',
};
