import React, { useEffect, memo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  Container,
  StyledButton,
  WrapperCardBattle,
} from './CaseBattleList.styles';
import {
  actionRequestCaseBattleList,
  actionRequestCaseBattleListHistory,
  actionRequestCaseMyBattleListHistory,
  removeAllCases,
} from 'games/CaseBattle/ducks';
import {
  useCloseToolBar,
  useGetBattleList,
  useGetCaseBattleMPHistory,
  useGetCaseBattleSoundType,
  useGetCases,
  useGetMyBattleList,
  useGetSortHistoryParam,
} from 'games/CaseBattle/hooks';
import { CaseBattleCard } from './components';
import {
  SoundsSettingsDialogConfig,
  actionRemoveCasesData,
  actionRequestCaseList,
} from 'games/CaseGame';
import { useUser } from 'core/User';
import { SortMainPage } from './components/SortMainPage';
import { SortParam } from 'games/CaseBattle/constants';
import { AuthDialogs } from 'core/Auth';
import { useDialog, useToolBar } from 'core/hooks';
import { GetToolBarConfig, ToolBar } from '@components/bars';
import { Modules } from 'core/interfaces';
import { usePageVisibility } from 'core/AppShell';

export const CaseBattleList = memo(() => {
  const dispatch = useDispatch();
  const {
    caseBattle: { data, isLoading, dataMyHistory, isLoadingMyHistory },
  } = useGetBattleList();
  const { isLoadingHistory, idsOrderHistory, historyGames } = useGetCaseBattleMPHistory();
  const { isLoadingMyBattle, idsOrderMyGames, myGames } = useGetMyBattleList();
  const location = useLocation();
  const isHistoryPage = location.pathname.split('/')?.[2] === 'history';
  const [offset, setOffset] = useState(20);
  const { sortHistoryParam } = useGetSortHistoryParam();
  const isVisible = usePageVisibility();
  const isCalledBattleListInfo = useRef(false);

  const {
    user: { id: userId },
  } = useUser();
  const { handleToggleDialog } = useDialog();
  useCloseToolBar();
  const history = useHistory();
  const { t } = useTranslation();
  const { isLoaded: isLoadedCasesData } = useGetCases();
  const { handleHideToolBar } = useToolBar();
  const { soundType } = useGetCaseBattleSoundType();

  useEffect(() => {
    if (isVisible) {
      dispatch(actionRequestCaseBattleList(SortParam.newest));
      isCalledBattleListInfo.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (isHistoryPage) {
      dispatch(
        actionRequestCaseMyBattleListHistory({
          sortBy: '-created_at',
          user: true,
          limit: 20,
          offset: 0,
          force: true,
        })
      );
    } else {
      !isCalledBattleListInfo.current && dispatch(actionRequestCaseBattleList(SortParam.newest));
      dispatch(actionRequestCaseBattleListHistory());
    }
  }, [dispatch, isHistoryPage]);

  useEffect(() => {
    if (!isLoadedCasesData && history.location.pathname.includes(Modules.CASE_BATTLE)) {
      dispatch(
        actionRequestCaseList({
          id: 'all',
          filterParams: { sortBy: 'popular', battles: true },
          isBattle: true,
        })
      );
    }
  }, [dispatch, history.location.pathname, isLoadedCasesData]);

  useEffect(() => {
    const unListen = history.listen(location => {
      if (!location.pathname.includes(Modules.CASE_BATTLE)) {
        dispatch(removeAllCases());
        dispatch(actionRemoveCasesData());
      }
    });

    return () => {
      unListen();
    };
  }, [dispatch, history]);

  const array = Array.from(
    isHistoryPage
      ? dataMyHistory.idOrder
      : new Set([...idsOrderMyGames, ...data.idOrder, ...idsOrderHistory])
  );

  const redirectHandler = () => {
    if (userId) {
      history.push('../case-battle/create-battle');
    } else {
      handleToggleDialog(AuthDialogs.SIGN_IN, true)();
    }
  };
  const Loading = isHistoryPage
    ? isLoadingMyHistory
    : isLoading && isLoadingHistory && isLoadingMyBattle;

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (
      target.scrollHeight - (target.offsetHeight + target.scrollTop) < 120 &&
      !isLoadingMyHistory &&
      !!target.scrollTop &&
      !dataMyHistory.isLimit &&
      isHistoryPage
    ) {
      setOffset(prev => prev + 10);

      dispatch(
        actionRequestCaseMyBattleListHistory({
          sortBy: sortHistoryParam,
          user: true,
          limit: 10,
          offset,
          force: true,
          isLoadMore: true,
        })
      );
    }
  };

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: '',
      iconKey: soundType as 'standard',
      type: 'modal',
      isNested: false,
      isNeedAuth: false,
      handler: handleToggleDialog(SoundsSettingsDialogConfig.SOUNDS_DIALOG, true),
      text: t('sounds'),
    },
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: '',
      iconKey: 'history',
      type: 'modal',
      isNested: false,
      isNeedAuth: true,
      handler: () => {
        handleHideToolBar();
        history.push('../case-battle/history');
      },
      text: t('history'),
    },
  ];

  return (
    <Container onScroll={onScroll} className="content dark-layout">
      <ToolBar toolBarItemsConfig={toolBarConfig} />
      {Loading && !array.length ? null : (
        <>
          <div>
            <SortMainPage />
          </div>
          <WrapperCardBattle component="div" className="tablet">
            {array.map((id, index) => {
              const generalObject = {
                ...(!isHistoryPage
                  ? {
                      ...myGames,
                      ...data.games,
                      ...historyGames,
                    }
                  : dataMyHistory.games),
              };
              const info = generalObject[id];

              return (
                info?.isPossibleToShow && (
                  <CSSTransition
                    timeout={500}
                    key={id}
                    classNames="item"
                    mountOnEnter={false}
                    unmountOnExit={false}
                  >
                    <CaseBattleCard
                      index={index}
                      key={id}
                      userId={userId}
                      totalPrice={info?.total_price}
                      playersCount={info.players_count}
                      playersInTeam={info.players_in_team}
                      players={info.players}
                      casesIds={info.cases}
                      battleId={id}
                      round={info?.currentRound}
                      totalWin={info?.total_winning}
                      winnersCount={info?.winners_count}
                      winnersSplit={info?.settings?.winnersSplit}
                      isPrivate={info?.is_private}
                      double={info?.players_in_team > 1}
                      jokerMode={info?.settings?.jokerMode}
                      finishedAt={info?.finished_at}
                    />
                  </CSSTransition>
                )
              );
            })}
          </WrapperCardBattle>
          <ButtonContainer className="tablet">
            <StyledButton onClick={redirectHandler}>
              {userId ? t('createGame') : t('loginAndLuck')}
            </StyledButton>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
});
