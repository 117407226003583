import React, { memo } from 'react';
import { EventDescription } from './event-description';
import { CategoriesItems } from './categories-items';
import { SuperPrize } from './super-prize';
import { BonusInfo } from './bonus-info';
import { Motivator } from './motivator';

export const DefaultEvent = memo(() => {
  return (
    <>
      <EventDescription />
      <CategoriesItems />
      <SuperPrize />
      <BonusInfo />
      <Motivator />
    </>
  );
});
