import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { Container } from './PrivacyPolicy.styles';
import { Dropdown } from 'modules/TOS/components/Dropdown';
import { Slider } from './slider';

export const PrivacyPolicy: FC = () => {
  const address = window.location.href.split('//')[1].split('/')[0];

  return (
    <>
      <AppBar />
      <Container>
        <div className="header">
          <h2>Privacy policy.</h2>
          <h2>Gamevio ltd.</h2>
        </div>
        <div className="info">
          <Dropdown title={'1. What does this privacy policy cover?'}>
            <div>
              <p>
                This Privacy Policy applies to the personal data that We at Gamevio Ltd. collect and
                process acknowledging and understanding that Your privacy is important to You and
                that You care about how Your personal data is collected and processed.
              </p>
              <p>
                We are Gamevio Ltd. and We are a company incorporated in the Cyprus with
                registration number HE 404862 and registered address at Loutrakiou 5, Chara Benezia
                Bld, 1st floor, Office 101, Strovolos, 2027, Nicosia, Cyprus.
              </p>
              <p>
                We at Gamevio Ltd. are dedicated to safeguarding and preserving Your personal data
                and privacy when visiting Our Websites, utilising Our services, products or
                communicating electronically with Us (“collectively referred to as “the Services”).
              </p>
              <p>
                For the purposes of the data protection laws that apply to Us, including the GDPR
                which is the European General Data Protection Regulation and Cyprus Data Protection
                Law, We act as the Data Controller for the personal data that We collect and process
                to enable You to make use of Our Services.
              </p>
              <p>
                The purpose of this Privacy Policy is to transparently provide You with an
                explanation as to the legal basis for Us collecting and processing Your personal
                data, the categories of personal data that We may collect about you, what happens to
                the personal data that We may collect, where We process Your personal data, how long
                We are required to retain Your personal data, who We may share Your personal data
                with and to also explain Your rights as a data subject.
              </p>
              <p>
                We do update this Privacy Policy from time to time and will post all updates to Our
                Website as and when issued. Please do review this policy regularly on Our Website
                for any changes.
              </p>
            </div>
          </Dropdown>

          <Dropdown title={'2. Definitions and interpretations'}>
            <div>
              <p>In this Privacy Policy the following terms shall have the following meanings:</p>
              <p>
                <b>“Cookie”</b> means a small text file placed on Your computer or device by Our
                Websites or mobile applications when You visit certain parts of Websites and/or when
                You use certain features of Our mobile applications.
              </p>
              <p>
                <b>“GDPR”</b> means the General Data Protection Regulation (EU) 2016/679, of the
                European Parliament and of the Council of 27 April 2016 on the protection of natural
                persons with regard to the processing of Personal Data and on the free movement of
                such data, and repealing Directive 95/46/EC, as amended, replaced or superseded and
                in force from time to time and as transposed into member-state legislation.
              </p>
              <p>
                <b>“Privacy Policy”</b> means this Privacy Policy updated from time to time and made
                public on Our Websites and through Our mobile applications.
              </p>
              <p>
                <b>“Personal Data”</b> means any information which relates to an identified or
                identifiable natural person. An identifiable person is one who can be identified
                directly or indirectly in particular by reference to an identifier such as a name,
                an identification number, location data, an online identifier or to one or more
                factors specific to the physical, physiological, genetic, mental, economic, cultural
                or social identity of that natural person.
              </p>
              <p>
                <b>“You” (“Your”)</b> refers to You when You are visiting Our Websites, utilising
                Our services, products or communicating electronically with Us.
              </p>
              <p>
                <b>“We” (“Us” or “Our”)</b> refers to Gamevio Ltd.
              </p>
            </div>
          </Dropdown>

          <Dropdown title={'3. Your data protection rights'} mainTitle>
            <div>
              <p>
                Under the GDPR you, as a data subject, have certain rights over the personal data
                that We hold and process.
              </p>
              <p>
                At Gamevio Ltd., We are committed to make it easy for You to exercise these rights
                in the most transparent manner possible. You can exercise any of Your rights in
                relation to the data that Gamevio Ltd. holds about you, by contacting Our data
                protection officer through the email address provided at the end of this section or
                through Your personal User cabinet on Your mobile device via the personal data
                management and protection applications We support.
              </p>
              <p>
                The following are the specific rights You have over Your personal data We hold and
                process, namely:
                <br />
                <span>
                  a. <b>The right to be informed</b> about Our collection and processing of Your
                  personal data including without limitation the legal basis for the collection and
                  processing, the categories of data, the methods of data use, the envisaged period
                  for which the personal data will be retained. This Privacy Policy should tell You
                  everything You need to know, but You can always contact Us to find out more or to
                  ask any questions.
                </span>
                <br />
                <span>
                  b. <b>The right to access</b> Your personal data We collect about you, commonly
                  referred to as <b>“Subject Access Requests”</b>, which provides you, as the data
                  subject, the right to obtain a copy of Your personal data that We are processing
                  for You Pursuant to GDPR We are required to respond without undue delay to all
                  Subject Access Requests in practice this means We have to respond within 1 month
                  of receipt of the Subject Access Request.
                  <br />
                  <span>Ordinarily Subject Access Requests are free of charge.</span>
                  <br />
                  <span>
                    We may extend the time period for responding to a Subject Access Request by a
                    further two months and may charge a reasonable fee if We deem the request to be
                    manifestly unfounded or excessive, however, We are required to advise You of Our
                    intention to do this within 1 month of Your subject access request.
                  </span>
                  <br />
                  <span>
                    Should You wish to make a Subject Access Request this can be done by contacting
                    the Company using the contact details in Section 9.
                  </span>
                </span>
                <br />
                <span>
                  c. The right to have Your personal data rectified if any of Your personal data
                  that We collect and process is in any way inaccurate or incomplete.
                </span>
                <br />
                <span>
                  d. The right of erasure (otherwise known as “the right to be forgotten”), i.e. the
                  right to ask Us to delete or otherwise dispose of any of Your personal data that
                  We process. Please note that this is not an absolute right and We may not be able
                  to comply with Your request as We may be legally obliged to continue to retain
                  Your personal data for a specific period
                </span>
                <br />
                <span>
                  e. The right to restrict (i.e. prevent) the processing of Your personal data by
                  Us.
                </span>
                <br />
                <span>
                  f. The right to object to Us processing Your personal data for a particular
                  purpose or purposes. Please note that the right to object only applies in certain
                  circumstances and will depend on the purposes or legal basis for the processing.
                </span>
                <br />
                <span>
                  g. The right to data portability. This only applies where You have provided
                  personal data to Us directly, the legal basis for Us processing Your personal data
                  is i) consent or ii) for the performance of a contract and We are processing Your
                  data using automated means. In such instances You have the right to receive Your
                  personal data (or request that We transfer it to another Controller) in a
                  structured, commonly used and machine readable format.
                </span>
                <br />
                <span>
                  h. Rights relating to automated decision-making and profiling. We do not use Your
                  personal data in this way.
                </span>
                <br />
                <span>
                  i. The right to lodge a complaint with any relevant Data Protection Authority, in
                  particular in the member State of Your residence, place of work or the place of
                  processing Your personal data.
                </span>
              </p>
              <p>
                If You wish to exercise any of the aforementioned rights, please contact Us. We
                request that in the first instance You contact Our data protection officer at any
                time Our on <a href="mailto:support@csgofast.com">support@csgofast.com</a>. We
                promise to promptly consider Your request and to respond to You in accordance with
                the requirements of the Cyprus Data Protection Act and GDPR.
              </p>
            </div>
          </Dropdown>

          <Dropdown title={'4. Personal data we collect about you'} mainTitle>
            <Dropdown title={'4.1 Legal basis for collecting and processing personal data'}>
              <div className="variants">
                <p>
                  By law, Gamevio Ltd. is obligated to establish an Anti-Money Laundering/Countering
                  of Financing of Terrorism framework for the purpose of preventing, detecting, and
                  forestalling illegal activities such as money laundering and financing of
                  terrorism (<b>the AML/CFT framework</b>).
                </p>
                <p>
                  Pursuant to the AML/CFT framework, Gamevio Ltd. is legally obliged to:-
                  <br />
                  <span>
                    a. obtain basic personal data about all players (information such as full name,
                    residential address and date of birth) to enable it to identify all players; and
                  </span>
                  <br />
                  <span>
                    b. undertake ongoing monitoring of a player’s activity and transactions to
                    determine the ongoing potential risk of l money laundering or financing of
                    terrorism.
                  </span>
                  <br />
                  <span>
                    As part of the AML/CFT framework during Your lifecycle as a player You may also
                    be requested to provide additional personal data to enable Us to verify Your
                    identity (information such as Government issued ID card or passport, Driving
                    Licence etc.).
                  </span>
                </p>
                <p>
                  Gamevio Ltd. will also process Your personal data based on other legal bases such
                  as:-
                  <br />
                  <span>
                    a. the performance of Our contract with You (i.e. the provision of Our Services
                    to you);
                  </span>
                  <br />
                  <span>
                    b. our legitimate business interests (i.e. for fraud prevention, maintaining the
                    security of Our network and services, seeking to improve the Services that We
                    provide and Your interaction with us). Whenever We rely upon on this lawful
                    basis We assess Our business interests to make sure that they do not override
                    Your rights. Furthermore, in some cases You have the right to object to this
                    processing;
                  </span>
                  <br />
                  <span>
                    c. with Your consent .for direct marketing purposes so that We may keep You
                    fully up to date with other products and services that We supply and think may
                    be of interest to you. Where We rely upon consent, We will need Your explicit
                    consent, which may be removed at any time.
                  </span>
                </p>
              </div>
            </Dropdown>

            <Dropdown title={'4.2 Categories of the personal data we collect'}>
              <div>
                <p>
                  A summary of the Personal Data that We process, when We collect it, how We use it
                  and why We use it (i.e. the legal basis for processing) is listed below:
                </p>
                <Slider />
              </div>
            </Dropdown>
          </Dropdown>

          <Dropdown title={'5. Protecting your personal data and data retention'} mainTitle>
            <div>
              <p>
                We use, store and process Your personal data on Gamevio Ltd. servers which are
                located on the Cyprus.
              </p>
              <p>
                By filling a “consent application“ You agree to this conditions of collection,
                processing, transfer and storing Your personal data. When We process Your personal
                data for one of the legal bases specified in this Privacy Policy, We will take all
                steps reasonably necessary to ensure that Your Personal Data is treated securely and
                in accordance with this Policy.
              </p>
              <p>
                Gamevio Ltd. protect Your Personal Data under internationally acknowledged
                standards, using physical, technical, and administrative security measures to reduce
                the risks of loss, misuse, unauthorized access, disclosure, and alteration. Some of
                the safeguards Gamevio Ltd. use are firewalls and data encryption, physical access
                controls to Our data centers, and information access authorization controls. Gamevio
                Ltd. also authorize access to Personal Data only for those employees or contractors
                who require it to fulfill their job or service responsibilities. The Gamevio Ltd.’s
                staff is trained on procedures for the management of personal information, including
                limitations on the release of information. Access to personal information is limited
                to those members of Our staff and contractors whose work requires such access.
                Gamevio Ltd. conducts periodic reviews to ensure that proper information management
                policies and procedures are understood and followed. All of Our physical,
                electronic, and procedural safeguards are designed to comply with applicable laws
                and regulations.
              </p>
            </div>

            <Dropdown title={'5.1. How we protect your data'}>
              <div>
                <p>
                  When You provide Your personal data through Our Website, KYC portals, Our
                  applications, this information is transmitted across the internet securely using
                  industry standard encryption. Your personal data will be held encrypted on secure
                  servers.
                </p>
                <p>
                  Where any third parties process Your personal data on Our behalf, We require that
                  they have appropriate technical and organizational measures in place to protect
                  this personal data and We will also ensure that a GDPR compliant. Data Processing
                  Agreement is in place between Gamevio Ltd. and the third party so that both
                  parties understand their responsibilities and liabilities pursuant to GDPR.
                </p>
              </div>
            </Dropdown>

            <Dropdown title={'5.2. How you can protect your personal data'}>
              <div>
                <p>
                  To sign up on <Link to="/">{address}</Link> you use your account of STEAM, Google
                  +, VK, Twitter, Facebook, Yandex
                </p>
                <p>
                  When You create Your Network account, choose a strong password that is unique to
                  Network accounts. Do not share Your password with other people. Using the same
                  password across Your different accounts will increase the risk of Your data being
                  compromised if Your password is accidentally or unlawfully accessed by
                  unauthorized persons. If You suspect that someone else has got access to Your
                  Network account, make sure that You change password immediately.
                </p>
              </div>
            </Dropdown>

            <Dropdown title={'5.3. Data retention'}>
              <div>
                <p>
                  Your personal data will be retained for as long as necessary to satisfy the
                  purposes We received it for, this includes regulatory and business purposes.
                </p>
                <p>
                  In determining the necessary personal data retention period, the following factors
                  are considered:
                  <br />
                  <span>
                    a. The amount of personal data as We aim to minimize this amount to the extent
                    possible for each specific purpose specified in Section 4.1 of this Privacy
                    Policy.
                  </span>
                  <br />
                  <span>
                    b. The nature of the personal data depending on the exact purpose which this
                    specific personal data serves as detailed in Section 4.1 of this Privacy Policy.
                  </span>
                  <br />
                  <span>c. The sensitivity of the personal data by its nature and substance.</span>
                  <br />
                  <span>
                    d. The potential risk of harm from unauthorized use or disclosure of Your
                    personal data and We continue to run risk assessments and risk mitigation
                    activities to minimize this potential risk including engagement of third party
                    specialized data management and data protection providers.
                  </span>
                  <br />
                  <span>
                    e. The purposes for which We process Your personal data and whether We can
                    achieve those purposes through other means, and the applicable legal
                    requirements.
                  </span>
                </p>
              </div>
            </Dropdown>
          </Dropdown>

          <Dropdown title={'6. Third party links'}>
            <p>
              You may find links to third party Websites on Our Website or chats of users contained
              on Our Website or mobile applications. These Websites should have their own privacy
              policies which You should check. We do not accept any responsibility or liability for
              their policies whatsoever as We have no control over them.
            </p>
          </Dropdown>

          <Dropdown title={'7. Who do we share your personal data with?'} mainTitle>
            <p>
              We do not share Your Personal Data to third Parties, except cases that are mentioned
              in this Privacy Policy.
            </p>

            <Dropdown title={'7.1. With your consent'}>
              <div>
                <p>
                  We will share information with companies, organizations or individuals outside of
                  Gamevio Ltd. when We have Your explicit consent.
                </p>
                <p>
                  You can make Your profile public. In this case, all Users will be able to see Your
                  stats. You can change the privacy settings in your account at any time.
                </p>
                <p>You can specify the E-mail for receiving advertizing mailings from us.</p>
              </div>
            </Dropdown>

            <Dropdown title={'7.2. To comply with the law'}>
              <p>
                We may share information in response to a request for information if We believe
                disclosure is in accordance with, or required by, any applicable law, regulation,
                legal process or governmental request, including, but not limited to, meeting
                national security or law enforcement requirements. To the extent the law allows it,
                We will attempt to provide You with prior notice before disclosing Your information
                in response to such a request.
              </p>
            </Dropdown>

            <Dropdown title={'7.3. In an emergency'}>
              <p>
                We may share information if We believe that it's necessary to protect the vital
                interests of the data subject (i.e. to prevent imminent serious physical harm or
                death to a person.)
              </p>
            </Dropdown>

            <Dropdown title={'7.4. To enforce our policies and rights'}>
              <p>
                We may share information if We believe Your actions are inconsistent with Our user
                agreements, rules, or other Gamevio Ltd. policies, or to protect the rights,
                property, and safety of ourselves and others.
              </p>
            </Dropdown>

            <Dropdown title={'7.5. With our affiliates'}>
              <p>
                We may share information between and among Gamevio Ltd. and any of Our parents,
                affiliates, subsidiaries, and other companies under common control and ownership.
              </p>
            </Dropdown>

            <Dropdown title={'7.6. With our partners'}>
              <div>
                <p>
                  We may share information with vendors, consultants, and other service providers
                  (but not with advertisers and ad partners) who need access to such information to
                  carry out processing activities for us. The partner’s use of personal data will be
                  subject to appropriate confidentiality and security measures.
                </p>
                <p>
                  We engage service providers to perform functions and provide services to us. For
                  example, We use third-party services for KYC procedure. We may share Your private
                  personal data with such service providers subject to obligations consistent with
                  this Privacy Policy and any other appropriate confidentiality and security
                  measures, and on the condition that the third parties use Your private personal
                  data only on Our behalf and pursuant to Our instructions.
                </p>
                <p>
                  Where any third parties process Your personal data on Our behalf, We require that
                  they have appropriate technical and organizational measures in place to protect
                  this personal data and We will also ensure that a GDPR compliant .Data Processing
                  Agreement is in place between Gamevio Ltd. and the third party so that both
                  parties understand their responsibilities and liabilities pursuant to GDPR.
                </p>
                <p>
                  For transactions, We can also use the services of Our partners. In addition, for
                  marketing operations, We can use third-party services.
                </p>
              </div>
            </Dropdown>

            <Dropdown title={'7.7 International transfers of personal data'}>
              <div>
                <p>
                  GDPR applies to controllers and processors located in the European Economic Area
                  (“the EEA”) and countries that the EU has deemed to provide adequate protection to
                  data subjects from a data protection perspective.
                </p>
                <p>
                  Data Subjects risk losing the protection of GDPR if personal data is transferred
                  outside of i) the EEA or ii) countries holding adequacy status and accordingly
                  GDPR restricts such transfers, unless the rights of data subjects in respect of
                  their personal data is protected by appropriate safeguards or one of a limited
                  number of exceptions applies (such exceptions include Your explicit and informed
                  consent).
                </p>
                <p>
                  Gamevio Ltd. will not make any international transfers of Your personal data to
                  countries outside the EEA (or a country holding adequacy status) unless it has i)
                  Your explicit and informed consent or ii) it has put in place the appropriate
                  safeguards or iii) the international transfer is covered by an exception.
                </p>
              </div>
            </Dropdown>

            <Dropdown
              title={
                '7.8 Advertising, analytics and business partners (limited to non-personally identifiable information) '
              }
            >
              <div>
                <p>
                  We may share aggregated or pseudonymous information (including demographic
                  information) with partners, such as publishers, advertisers, measurement
                  analytics, apps, or other companies. For example, We may tell an advertiser how
                  its ads performed or report how many people installed an app after seeing a
                  promotion. We do not share information that personally identifies You (personally
                  identifiable information is information like name or email address) with these
                  partners, such as publishers, advertisers, measurement analytics, apps, or other
                  companies.
                </p>
              </div>
            </Dropdown>

            <Dropdown title={'7.9 Other information'}>
              <div>
                <p>
                  Other information, that does not personally identify You as an individual is
                  collected by Gamevio Ltd. (such as, by way of example, patterns of use) and is
                  exclusively owned by Gamevio Ltd. We can use this information in such manner that
                  Gamevio Ltd., in its sole discretion, deems appropriate.
                </p>
                <p>
                  We may share specific aggregated, non-personal information with third parties,
                  such as the number of users who have registered with us, the volume and pattern of
                  traffic to and within the site, etc. That information will not identify you, the
                  individual, in any way.
                </p>
                <p>
                  We shall not use Your email or other contact information for sending of commercial
                  proposal, other marketing needs, without Your prior consent.
                </p>
                <p>
                  In the light of the above, when You send Us messages, We can keep them for
                  administering of Your inquiries, for improving of Our services. We shall not
                  transfer information from such messages to third parties.
                </p>
              </div>
            </Dropdown>
          </Dropdown>

          <Dropdown title={'8. Use of cookies'} mainTitle>
            <div>
              <p>
                We use Google Analytics to count the number of visitors to Website and tell Us
                things like how long visitors spend on a page and the average number of pages
                viewed. This works through cookies, but they don’t tell Us who You are or send Us
                any Personal Data about You.
              </p>
              <p>
                We use Cookies to learn about Your traffic patterns and Website usage to help Us
                develop the design and layout of the Website in order to enhance Your experience
                browsing Our Website and applications. You can delete Cookies if You wish; while
                certain Cookies are necessary for viewing and navigating on Our Website or app, most
                of the features will be still accessible without Cookies.
              </p>
            </div>

            <Dropdown title={'8.1. What cookies do we use and how we can use them?'}>
              <div>
                <p>
                  Strictly Necessary Cookies - these are required for the operation of Our Website.
                  They include User session key. This is a temporary key issued to the User after
                  the successful logging in. This cookies are used to enable You to log into secure
                  areas of Our Website. It is stored, by default, in the browser history on the
                  User’s personal device. However, if due to the browser settings, this storage is
                  unavailable, We will use Cookies to store this information.
                </p>
                <p>
                  Functionality – cookies that are used to recognize You and remember Your settings
                  when You return to Our Website, so that We can provide you with a more
                  personalized experience.
                </p>
              </div>
            </Dropdown>

            <Dropdown title={'8.2. How do you change cookie preferences or block cookies?'}>
              <p>
                If You want to delete any cookies already stored on Your personal device or stop the
                cookies, You can do so by deleting Your existing cookies and/or altering Your
                browser's privacy settings. However, if, as mentioned above, due to Your personal
                device settings or browser settings, We are not able to use other technical
                solutions to store respective information, restriction/deletion of cookies may lead
                to your inability to access the content of Our Website.
              </p>
            </Dropdown>
          </Dropdown>

          <Dropdown title={'9. Contacting us'}>
            <div>
              <p>
                We welcome any queries, comments or requests You may have regarding this Privacy
                Policy. If You wish to make a subject access request
              </p>
              <p>Please do not hesitate to contact Us at</p>
              <p>
                E-mail:
                <a href="mailto:support@csgofast.com"> support@csgofast.com</a>
              </p>
            </div>
          </Dropdown>

          <Dropdown title={'10. Changes to this privacy policy'}>
            <div>
              <p>
                Any changes that We make to Our Privacy Policy in the future will be posted on Our
                app and Website.
              </p>
              <p>
                Where appropriate, We will notify You of the changes when You next visit Our
                Website.
              </p>
              <p>This Privacy Policy was last updated on 4 June 2019.</p>
            </div>
          </Dropdown>
        </div>
      </Container>
    </>
  );
};
