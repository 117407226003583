import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITeams } from 'games/CaseBattle/hooks';
import {
  EmptyUserAvatar,
  PlayerTotalWinnings,
  SinglePlayerInfoContainer,
  SinglePlayerInfoContainerBlur,
  SinglePlayersContainer,
  UserAvatar,
  UserStatusContainer,
} from './SingleUserFinalResult.styles';
import { calculateGap } from 'games/CaseBattle/utils';
import { TotalPriceContainer } from '../generalStyles';
import { BattlePlayerFinalStatus } from '../../BattlePlayerFinalStatus';
import { CaseBattleIcon } from 'games/CaseBattle/assets';

interface SingleUserFinalResultProps {
  teams: ITeams[];
  isJoker: boolean;
  winnerIds: number[];
}

export const SingleUserFinalResult: FC<SingleUserFinalResultProps> = ({
  teams,
  isJoker,
  winnerIds,
}) => {
  const { t } = useTranslation();

  const playersData = teams.map(({ players }) => players).flat();

  const maxTotalPrice = Math.max(...playersData.map(item => item.totalPrice));
  const minTotalPrice = Math.min(...playersData.map(item => item.totalPrice));
  const totalWin = playersData.reduce((acc, curr) => acc + curr.totalPrice, 0);

  return (
    <SinglePlayersContainer
      gap={calculateGap(playersData.length)}
      playersCount={playersData.length}
    >
      {playersData.map(({ totalPrice, playerInfo }) => {
        const { ava_link, user_id } = playerInfo;
        const isWinner = (isJoker ? minTotalPrice : maxTotalPrice) === totalPrice;

        return (
          <UserStatusContainer key={user_id}>
            {isWinner ? <UserAvatar src={ava_link} /> : <EmptyUserAvatar />}
            <SinglePlayerInfoContainer isJoker={isJoker} isWinner={isWinner}>
              {isWinner && <CaseBattleIcon.WinnerBackground />}
              {isWinner && <SinglePlayerInfoContainerBlur position="left" />}
              <BattlePlayerFinalStatus isWinner={isWinner} isSingleMode isJoker={isJoker} />
              <PlayerTotalWinnings>{isWinner && t('winnings')}</PlayerTotalWinnings>
              {isWinner && <SinglePlayerInfoContainerBlur position="right" />}
              <TotalPriceContainer>
                {isWinner && `◎ ${(totalWin / winnerIds.length / 100).toFixed(2)}`}
              </TotalPriceContainer>
            </SinglePlayerInfoContainer>
          </UserStatusContainer>
        );
      })}
    </SinglePlayersContainer>
  );
};
