import React, { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { PlayerComparisonHeader } from './PlayerComparisonHeader';
import { PlayerComparisonItem } from './PlayerComparisonItem';
import { durationFastRace } from '../../../account.utils';

import type { IAccountStatisticQuests } from '../../../interfaces';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';

interface IPlayerComparison {
  quests: IAccountStatisticQuests;
  profile: IUserInfo;
}

export const PlayerComparison: FC<IPlayerComparison> = memo(({ quests, profile }) => {
  const { t } = useTranslation();
  const [regularRace, setRegularRace] = useState<number>(0);

  const profileQuests = quests.get(profile.id.toString());

  const handleChangeRace = useCallback(
    (index: number) => () => {
      setRegularRace(index);
    },
    [setRegularRace]
  );

  return (
    <>
      <RegularRaceList>
        {durationFastRace.map((_: null, i) => (
          <div
            key={i}
            onClick={handleChangeRace(i)}
            className={i === regularRace ? 'selected' : ''}
          />
        ))}
      </RegularRaceList>
      <PlayerComparisonHeader date={profileQuests[regularRace]?.date} />
      <PlayerComparisonDetailsList>
        {quests.keySeq().map((key: string) => {
          const race = quests.getIn([key, regularRace]);
          return (
            <div key={key} className="column">
              {race?.tickets && (
                <div className="column-title">
                  <span>{key === profile.id.toString() ? t('Player') : t('Your')}</span>
                  <span>
                    <i className="icon-coupon" />
                    {race.tickets['green']}
                  </span>
                  <span>
                    <i className="icon-coupon" />
                    {race.tickets['blue']}
                  </span>
                  <span>
                    <i className="icon-coupon" />
                    {race.tickets['orange']}
                  </span>
                </div>
              )}
              {race?.items.map(
                (item: any, i: number) =>
                  !item?.type && <PlayerComparisonItem key={key + regularRace + i} {...item} />
              )}
            </div>
          );
        })}
      </PlayerComparisonDetailsList>
    </>
  );
}, isEqual);

const RegularRaceList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 0.7rem;
  grid-auto-rows: 0.7rem;
  justify-content: space-around;
  margin: 1rem 0.5rem 0.9rem;

  & > div {
    background: var(--color-gray-300);
    border-radius: 50%;
    transition: background var(--transition-ease-out);

    &.selected {
      background: var(--color-white);
    }
  }
`;

const PlayerComparisonDetailsList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 1.2rem;

  & .column {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 100px;
    grid-template-rows: 2.5rem repeat(auto-fit, 100px);
    grid-gap: 0.25rem;

    & .column-title {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      align-items: center;

      color: var(--color-white);
      font-size: 0.8rem;

      & span {
        &:nth-child(2) {
          color: var(--color-green-ticket);
        }
        &:nth-child(3) {
          color: var(--color-blue-ticket);
        }
        &:nth-child(4) {
          color: var(--color-orange-ticket);
        }
        & i {
          margin-right: 0.2rem;
        }
      }
    }
  }
`;
