import { useLayoutEffect, useRef, useState } from 'react';

export const useResizeWindow = (hasProgress?: boolean) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const containerElement = containerRef.current;

    if (!containerElement) return;

    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentRect.height !== containerWidth) {
          setContainerWidth(entry.target.clientWidth);
        }
      }
    });

    observer.observe(containerElement);

    const updateContainerWidth = () => {
      if (containerRef.current.offsetWidth !== containerWidth) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', updateContainerWidth);

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
      observer.disconnect();
    };
  }, [containerWidth, hasProgress]);

  return {
    containerRef,
    containerWidth,
  };
};
