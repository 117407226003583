import styled from 'styled-components';
import { Button } from '@components/Common';

export const Container = styled.div`
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0) 100%), rgb(24, 35, 40);
  display: flex;
  flex-direction: column;

  & > img {
    height: 148px;
    margin: 51px 0 80px;
    width: 127px;
  }
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 47px 30px 28px;
  width: 100%;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 16px;
  text-align: center;
`;

export const Description = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 160%;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  background-color: #6dad52;
  color: var(--color-white);
  margin-top: 51px;
`;
