import React, { memo, useEffect, useState } from 'react';
import {
  Container,
  ContainerLineAll,
  ContainerLineBest,
  ItemContainer,
  StyledButton,
} from './Motivator.styles';
import { useDispatch } from 'react-redux';
import { actionMotivator, actionRequestMotivator } from 'games/CaseGame/ducks';
import { useGetMotivatorData } from 'games/CaseGame/hooks';
import { MotivatorItem } from './components';
import { useTranslation } from 'react-i18next';
import { socketService } from 'services/socket.service';

export const Motivator = memo(() => {
  const [visible, setVisible] = useState('best');
  const dispatch = useDispatch();
  const { t } = useTranslation('cases2.0');
  const { allDropNormalizedArray, bestDropNormalizedArray } = useGetMotivatorData();

  useEffect(() => {
    dispatch(actionRequestMotivator());
    socketService.subscribeV2({
      event: 'motivator.last.won',
      action: event => {
        dispatch(actionMotivator(event));
      },
    });
    return () => socketService.removeListener('motivator.last.won');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickAllDropHandler = () => {
    setVisible('best');
  };
  const onClickBestDropHandler = () => {
    setVisible('all');
  };

  return (
    <Container className="tablet">
      <ContainerLineAll data-visible={visible}>
        <StyledButton onClick={onClickAllDropHandler}>{t('all')}</StyledButton>
        <ItemContainer>
          {allDropNormalizedArray.map((itemInfo, index) => (
            <MotivatorItem {...itemInfo} key={`${itemInfo.uniqId}${index}`} />
          ))}
        </ItemContainer>
      </ContainerLineAll>
      <ContainerLineBest data-visible={visible}>
        <StyledButton onClick={onClickBestDropHandler}>{t('best')}</StyledButton>
        <ItemContainer>
          {bestDropNormalizedArray.map((itemInfo, index) => (
            <MotivatorItem {...itemInfo} key={`${itemInfo.uniqId}${index}`} />
          ))}
        </ItemContainer>
      </ContainerLineBest>
    </Container>
  );
});
