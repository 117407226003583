import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  position: relative;

  & > .card-size {
    width: 100%;
    height: 100%;
  }
`;

export const IconContainer = styled.div`
  border-radius: 100%;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 4px;
  right: 4px;
  background: var(--dark-opacity-500);
`;
