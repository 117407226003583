import styled from 'styled-components';
import { NavigationLink } from '@components/Common';

export const Navigation = styled.div`
  padding-top: 38px;

  & > a {
    background-color: var(--color-dark-600);
  }

  & > :nth-last-child(2) {
    border-bottom: 1px solid var(--color-gray-900);
  }

  & > button:last-child {
    background-color: var(--color-dark-600);
    border-top: 1px solid var(--color-gray-900);
    margin-top: 30px;
    width: 100%;
  }
`;

export const StyledNavigationLink = styled(NavigationLink)`
  &[data-disable='available'] {
    opacity: 1;
  }

  &[data-disable='disable'] {
    pointer-events: none;

    & > div > span,
    & > div > svg {
      opacity: 0.2;
    }

    & .link-steam {
      color: var(--color-gray-200);
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
