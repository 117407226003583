import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

class LeadersStatisticRepository {
  fetchYearlyStatistics(): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/total-top-100`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
}

export default new LeadersStatisticRepository();
