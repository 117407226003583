import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MotivatorItem, ItemContainer } from '../motivator-item';
import { socketService } from 'services/socket.service';
import { useGetMotivatorData } from '../../../hooks';
import { actionMotivatorSocketEvent, actionRequestMotivatorData } from '../../../duck';
import { Container, Title, Subtitle, MotivatorItemContainer } from './motivator.styles';

const emptyArray = new Array(8).fill(0).map((_, index) => index);

export const Motivator = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, isLoading } = useGetMotivatorData();

  useEffect(() => {
    dispatch(actionRequestMotivatorData());
    socketService.subscribeV2({
      event: 'motivator.last.won',
      action: e => {
        dispatch(actionMotivatorSocketEvent(e));
      },
    });

    return () => socketService.unsubscribe('motivator.last.won');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title>{t('recentWinnings')}</Title>
      <Subtitle>{t('luckyWinners')}</Subtitle>
      <MotivatorItemContainer>
        {isLoading
          ? emptyArray.map(value => <ItemContainer className="skeleton loading" key={value} />)
          : data.map((data, index) => <MotivatorItem {...data} key={index} />)}
      </MotivatorItemContainer>
    </Container>
  );
});
