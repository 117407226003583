/* eslint-disable */
export default {
  Error: 'Erro',
  'Error placing a bet': 'Erro ao fazer uma aposta',
  'Error reserving an item': 'Erro ao reservar um item',
  'Link saved successfully': 'Link salvo com sucesso',
  "You've successfully updated a bet": 'Atualizou uma aposta com sucesso',
  "You've successfully sell winning item": 'Vendeu um item vencedor com sucesso',
  "You've successfully take winning item": 'Levou um item vencedor com sucesso',
  'Incorrect link. Enter the correct link and try again.':
    'Link errado. Introduza o link correcto e tente novamente.',
  'This is not your link. Enter your link and try again.':
    'Este não é o seu link. Digite seu link e tente novamente.',
  "Don't forget to make your inventory public to get your winnings!":
    'Não se esqueça de tornar seu inventário público para obter seus ganhos!',
  'min. number of items per trade not exceeded':
    'o número mínimo de itens por transação não foi excedido',
  'Too many requests, try again later': 'Demais pedidos, tente novamente mais tarde',
  'Your trade has been cancelled for the following reason':
    'Seu comércio foi cancelado pelo seguinte motivo',
  'cheating attempt': 'Traindo',
  'max. number of items per trade exceeded': 'Max. Número de itens por comércio excedido',
  'player not registered on website': 'Jogador não registrado no site',
  'souvenir items are not allowed': 'Artigos de lembrança não são permitidos',
  'some of the items cannot be appraised': 'Alguns dos itens não podem ser avaliados',
  'some of the items are below minimum price': 'Alguns dos itens estão abaixo do preço mínimo',
  'total price of a trade is below minimum': 'O preço total de um comércio é inferior ao mínimo',
  'Your trade has been accepted!': 'Seu comércio foi aceito!',
  'The items have been added to the queue.': 'Os itens foram adicionados à fila.',
  'Your trade has been checked out!': 'Seu comércio foi verificado!',
  'It is currently being accepted': 'Atualmente está sendo aceito',
  'Unable to send a trade': 'Não é possível enviar um comércio',
  'Update your trade link in the settings of the site':
    'Atualize seu link comercial nas configurações do site',
  'Enter correct trade link and make sure your inventory is not full.':
    'Digite o link comercial correto e verifique se o seu inventário não está cheio.',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Sua conta não está disponível para troca. Mais informações serão mostradas se você convidar alguém para negociar.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'O Steam não está disponível neste momento. Aguarde novamente.',
  'Not enough money on balance': 'Não há moedas suficientes no equilíbrio',
  'Not enough points on balance': 'Não há moedas suficientes no equilíbrio',
  'Game is ending, try again later': 'O jogo está terminando, tente novamente mais tarde',
  'Congratulations!': 'Parabéns!',
  "You've successfully placed a bet": 'Você colocou com sucesso uma aposta',
  "You've successfully placed a bet with </amount>": 'Fez uma aposta bem sucedida de {{</amount>}}',
  'Item has not been found, coins have been returned':
    'O item não foi encontrado, as moedas foram devolvidas',
  'Your order in the store has been cancelled': 'O seu pedido na loja foi cancelado',
  'The min bet is </minBet>': 'A aposta mínima é de {{</minBet>}}',
  'The max bet is </maxBet>': 'A aposta máxima é {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Você não pode colocar aposta em vermelho e preto ao mesmo tempo',
  "You don't have CSGOFAST.com in Steam nickname!":
    'Não possui CSGOFAST.com no nome de utilizador Steam!',
  'You have exceeded the limit of 150 free coins per account':
    'Você excedeu o limite de 150 moedas livres por conta',
  "You've bet </amount> on the crash game #</game>!":
    'Apostou {{</amount>}} no jogo de crash #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Atualizou o coeficiente para {{</coef>}} para jogo de crash #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'Ganhou {{</amount>}} no jogo de crash #{{</number>}}!',
  'Code for </amount> has been created': 'Código para {{</amount>}} foi criado',
  'You already have an item': 'Já possui um item',
  '</amount> refilled on your balance': '{{</amount>}} recarregados no seu saldo',
  'We will sent it to your Steam in few moments':
    'Vamos enviá-lo para o seu Steam dentro de momentos',
  'Your items has been sended to game!': 'Os seus itens foram enviados para o jogo!',
  '</amount> have been returned': 'Foi devolvido o montante de {{</amount>}}',
  'Item has not been found, retry in few minutes (</amount>)':
    'O item não foi encontrado, volte a tentar dentro de alguns minutos ({{</amount>}})',
  'It is currently being processing by game bots':
    'Está atualmente a ser processado por bots do jogo',
  '</amount> have been refilled': 'Foi recarregado o montante de {{</amount>}}',
  'Request successfully created': 'Pedido criado com sucesso',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'A sua ordem #{{</id>}} de {{</amount>}} foi aceite. Aguarde pela negociação no Steam.',
  'Reason of order rejection': 'Motivo da rejeição da ordem',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'A sua aposta de {{</amount>}} foi aceite e será adicionada ao jogo dentro de {{</time>}} segundos',
  'Your bet was added to queue, in few seconds will added to the game':
    'A sua aposta foi adicionada à fila. Dentro de poucos segundos, será adicionada ao jogo',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'O levantamento está bloqueado. Ganhar mais ${currency(amount)} para desbloquear',
    },
  },
};
