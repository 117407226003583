import { combineReducers, Reducer } from 'redux';
import dayjs from 'dayjs';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { dialogOff } from 'core/ducks/dialog';
import { ParticipateDialogs } from 'core/interfaces';
import {
  BaccaratActionTypes,
  baccaratBetsActions,
  baccaratGameActions,
  baccaratHistoryActions,
  BoardTypes,
  IBetRequest,
  IGame,
  IGameBets,
  IGameHistory,
  IMember,
} from '../interfaces';
import { GamePhase } from '../interfaces/game-phase.enum';
import { betsMapper, getBoardTypesByHandCards, isPair } from '../utils';
import SoundService from 'services/sound.service';

const sound = new SoundService();

const initStateGame: IGame = {
  gameId: null,
  hash: null,
  salt: null,
  started: 0,
  timeout: 0,
  phase: GamePhase.BET,
  cards: { playerHand: [], bankHand: [] },
  sectors: [],
  myBet: [],
};

const game: Reducer<IGame, baccaratGameActions> = (state = initStateGame, action) => {
  switch (action.type) {
    case BaccaratActionTypes.UPDATE_GAME: {
      return { ...state, ...action.payload };
    }

    case BaccaratActionTypes.UPDATE_PHASE_BET: {
      return {
        ...state,
        started: +dayjs(),
        timeout: 20000,
        phase: GamePhase.BET,
        cards: initStateGame.cards,
        salt: null,
        sectors: [],
        myBet: [],
      };
    }

    case BaccaratActionTypes.UPDATE_PHASE_GAME: {
      const { cards, salt } = action.payload;

      let sectors = [getBoardTypesByHandCards(cards)];

      if (isPair(cards.playerHand)) sectors = sectors.concat('PP');

      if (isPair(cards.bankHand)) sectors = sectors.concat('BP');

      return {
        ...state,
        timeout: 10000,
        phase: GamePhase.GAME,
        cards,
        salt,
        sectors,
      };
    }

    case BaccaratActionTypes.UPDATE_PHASE_TIMEOUT: {
      return { ...state, timeout: 5000, phase: GamePhase.TIMEOUT };
    }

    case BaccaratActionTypes.SET_MY_BET: {
      return { ...state, myBet: state.myBet.concat(action.payload) };
    }

    default: {
      return state;
    }
  }
};

const history: Reducer<IGameHistory[], baccaratHistoryActions> = (state = [], action) => {
  switch (action.type) {
    case BaccaratActionTypes.UPDATE_HISTORY: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const initStateBets: IGameBets = {
  totals: {},
  members: {},
};

const bets: Reducer<IGameBets, baccaratBetsActions> = (state = initStateBets, action) => {
  switch (action.type) {
    case BaccaratActionTypes.UPDATE_BETS: {
      return action.payload;
    }

    case BaccaratActionTypes.UPDATE_PHASE_BET: {
      return initStateBets;
    }

    default: {
      return state;
    }
  }
};

export const reducer = combineReducers({
  history,
  game,
  bets,
});

export const fetchCreateBid = (bet: IBetRequest) => ({
  type: BaccaratActionTypes.FETCH_CREATE_PARTICIPATE,
  payload: {
    bet,
  },
});

export const setMyBet = (payload: BoardTypes[]) => ({
  type: BaccaratActionTypes.SET_MY_BET,
  payload,
});

export const participateEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(BaccaratActionTypes.FETCH_CREATE_PARTICIPATE),
    debounceTime(300),
    pluck('payload'),
    tap(({ bet }) => {
      sound.play('Baccarat', 'Bet');
      socket.io.emit('baccarat.bet.request', { bet });
    }),
    map(() => dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG))
  );

const updateGame = (payload: IGame) => ({
  type: BaccaratActionTypes.UPDATE_GAME,
  payload,
});

const updatePhaseBet = () => ({
  type: BaccaratActionTypes.UPDATE_PHASE_BET,
});

const updatePhaseWon = () => ({
  type: BaccaratActionTypes.UPDATE_PHASE_TIMEOUT,
});

const updatePhaseGame = (payload: IGame) => ({
  type: BaccaratActionTypes.UPDATE_PHASE_GAME,
  payload,
});

const updateHistory = (payload: IGameHistory[]) => ({
  type: BaccaratActionTypes.UPDATE_HISTORY,
  payload,
});

const updateBets = (payload: IGameBets) => ({
  type: BaccaratActionTypes.UPDATE_BETS,
  payload,
});

export const gameEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(BaccaratActionTypes.BACCARAT_ATTACH_LISTENERS),
    tap(() => {
      socket.io.emit('baccarat.bets:statistic.request');
      socket.io.emit('baccarat.game:state.request');
      socket.io.emit('baccarat.bets:statistic.request');
      socket.io.emit('baccarat.bets:best.request');
      socket.io.emit('baccarat.bets:my.request');
      socket.io.emit('baccarat.history.request', { size: 15 });
    }),
    switchMap(() =>
      merge(
        fromEvent<IGameHistory[]>(socket.io, 'baccarat.history.response').pipe(
          map(payload => updateHistory(payload))
        ),
        fromEvent<IGame & { state: GamePhase; multipliers: any }>(
          socket.io,
          'baccarat.game:state.response'
        ).pipe(map(({ state, multipliers, ...payload }) => updateGame(payload))),
        fromEvent(socket.io, 'baccarat:phase:bet').pipe(map(() => updatePhaseBet())),
        fromEvent<IGame>(socket.io, 'baccarat:phase:game').pipe(
          map(payload => updatePhaseGame(payload))
        ),
        fromEvent(socket.io, 'baccarat:phase:timeout').pipe(
          tap(() => socket.io.emit('baccarat.history.request', { size: 15 })),
          map(() => updatePhaseWon())
        ),
        fromEvent<{ lastBets: IMember[] }>(socket.io, 'baccarat:bet:statistic').pipe(
          map(({ lastBets }) => betsMapper(lastBets)),
          map(payload => updateBets(payload))
        )
        // fromEvent(io, 'baccarat.bets:statistic.response')
      )
    ),
    takeUntil(action$.ofType(BaccaratActionTypes.BACCARAT_DETACH_LISTENERS))
  );
