import React, { FC, useEffect, useState, memo } from 'react';
import { EmojiIcons } from '../../../../assets/index';
import { Container } from './Emoji.styles';
import { isEqual } from 'lodash';
import { EmojiForMap } from 'games/CaseBattle/utils';

interface EmojiProps {
  emoji: EmojiForMap;
  isBarEmoji: boolean;
}

export const Emoji: FC<EmojiProps> = memo(({ emoji, isBarEmoji }) => {
  const { type, ts, shift, isDateNow } = emoji;
  const [show, setShow] = useState(false);
  const Icon = EmojiIcons[type];

  useEffect(() => {
    let showTimer: null | number;
    let animationTimer: null | number;

    const handleShow = () => {
      setShow(true);
      animationTimer = setTimeout(() => {
        setShow(false);
      }, 1000);
    };

    if (isDateNow) {
      handleShow();
    } else {
      showTimer = setTimeout(handleShow, ts);
    }

    return () => {
      if (showTimer) {
        clearTimeout(showTimer);
      }
      if (animationTimer) {
        clearTimeout(animationTimer);
      }
    };
  }, [isDateNow, ts]);

  return show && 0 < ts ? (
    <Container randomShift={shift} data-is-show-in-bar={isBarEmoji}>
      <Icon />
    </Container>
  ) : null;
}, isEqual);
