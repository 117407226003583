import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSalesSelectedIdentifiers } from '../../../hooks';

export const PublicationMetadata = () => {
  const { t } = useTranslation('marketplace');
  const { selected } = useSalesSelectedIdentifiers();

  return (
    <Container>
      {selected.identifiers.length !== 0 && (
        <>
          <span>
            {t('{ count } skins for sale', {
              count: selected.identifiers.length,
            })}
          </span>
          <span>
            {t('in total for {amount}', {
              amount: selected.computed.amount,
              postProcess: 'with-template',
            })}
          </span>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 0.3125rem;
  align-content: end;
  padding: 1.125rem 1.125rem 1.25rem;

  & span {
    color: var(--color-white);

    &:first-of-type {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    &:last-of-type {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
    }
  }
`;
