import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 42px 16px 34px;
  gap: 30px;
`;

export const Header = styled.div`
  width: 100%;
  color: var(--color-white);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.54px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0 30px;
`;

export const StyledButtonOpen = styled(Button)`
  background: var(--yellow);
  color: var(--color-dark-600);
  margin-bottom: 7px;
`;

export const OpenLaterButton = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.29px;
  text-align: center;
  color: var(--color-white);
  width: 100%;
`;
