/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'SPEL',
  Chat: 'Chatt',
  'Email Support': 'E-postsupport',
  'Live Support Chat': 'Live supportchatt',
  History: 'Historik',
  Top: 'Toppen',
  Rules: 'Rules',
  'At stake': 'På spel',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Du måste logga in för att skriva ett meddelande',
  'Only players who have played the game are allowed to write':
    'Enbart spelare som spelar tillåts skriva',
  'You are banned': 'Du är avstängd',
  Reason: 'Anledning',
  'Time left': 'Återstående tid',
  'Time to summarizing': 'Dags att sammanfatta',
  spam: 'skräppost',
  'advertising (auto)': 'reklam (auto)',
  advertising: 'reklam',
  rudeness: 'oförskämdhet',
  begging: 'tiggeri',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Idag',
  Yesterday: 'Igår',
  'Last year': 'Förra året',
  'Show my wins': 'Visa mina vinster',
  'History is empty': 'Historiken är tom',
  Cancel: 'Avbryt',
  Delete: 'Radera',
  Close: 'Stäng',
  Confirm: 'Bekräfta',
  'Are you sure to delete all notifications?':
    'Är du säker på att du vill radera alla meddelanden?',
  'Log in': 'Logga in',
  'Log in to try your luck': 'Logga in och pröva lyckan',
  'try your luck!': 'pröva lyckan!',
  'Take your prize': 'Hämta ditt pris',
  'Sell for': 'sälj för',
  BACK: 'BACK',
  'Warning!': 'Varning!',
  'Congratulations!': 'Grattis!',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Grattis! Ett bytesförslag med ditt pris har skickats till ditt konto.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'Bytet avbryts efter en timme',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Efter det att din order behandlats kan du hämta din vinst på inlämningssidan på Steam exchange',

  'Round number': 'Rundans nummer',
  'Round hash': 'Hash-runda',
  'Hash salt': 'Hash salt',

  'Refill amount': 'Fyll på belopp',
  Bonus: 'Bonus',
  'Bonus experience': 'Bonusupplevelse',
  'Bonus wheel spins': 'Bonushjulsnurr',
  'Bonus cases': 'Bonuslådor',

  'Trick or treat?': 'Bus eller godis?',
  'Keep your halloween tip jars ready!': 'Håll dina halloween-tipsburkar redo!',
  'Bonuses on every balance refill': 'Bonusar på varje balanspåfyllning',

  '${currency(amount)} bonus is ready!': '${currency(amount)} bonus är klar!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Påfyll balans och få upp till ${currency(amount)} i gratissnurr och fall',

  'Get bonus': 'få nu',
};
