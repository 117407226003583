import styled from 'styled-components';

export const Container = styled.div`
  &.container {
    padding: 0;
    position: relative;

    &::before,
    &::after {
      display: none;
    }

    & > img {
      width: 24px;
      height: 20px;
      left: 14px;
      position: absolute;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding-left: 50px;
  position: relative;
  width: 155px;

  & > svg {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const Title = styled.span<{ hasIcon?: boolean }>`
  color: var(--color-white);
  font-size: 10px;
  font-weight: 700;
  left: ${({ hasIcon }) => (hasIcon ? '81px' : '62px')};
  letter-spacing: 0.3px;
  line-height: 14px;
  padding-right: 9px;
  position: ${({ hasIcon }) => (hasIcon ? 'absolute' : 'initial')};
  white-space: normal;
`;

export const Progress = styled.span`
  color: #9ea7b3;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 15px;
  text-align: left;
`;

export const Skeleton = styled.section`
  animation: 6s cubic-bezier(0.4, 0, 0.2, 1) infinite progress;
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
  background-size: 200% 100%;
  border-radius: var(--size-radius-m);
  height: 48px;
  width: 155px;

  @keyframes progress {
    0% {
      background-position: -200% 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 400% 0;
    }
  }
`;
