import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { NavigationLink } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { useFastInventoryInfo, useUser } from 'core/User';
import { useDispatch } from 'react-redux';
import { actionSetSelectedCategory } from 'games/CaseGame';
import { useGetFreeCaseInfo } from 'modules/inventory/+fast-inventory/hooks';
import { actionRequestFreeCaseInfo } from 'modules/inventory/+fast-inventory/ducks/free-case.duck';

export const InventoryNavigationPage: FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation('inventory');
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: { rewards },
  } = useUser();
  const { freeCaseInfo } = useGetFreeCaseInfo();
  const { info } = useFastInventoryInfo();

  useEffect(() => {
    if (!freeCaseInfo.loaded) dispatch(actionRequestFreeCaseInfo());
  }, [dispatch, freeCaseInfo.loaded]);

  const redirectHandler = () => {
    if (freeCaseInfo?.count) {
      dispatch(actionSetSelectedCategory('free'));
      history.push('../cases');
    }
  };

  return (
    <>
      <AppBar />
      <Container className="content dark-layout">
        <div className="fast-inventory">
          <span className="count">
            {t('{{ count }} item(s) worth', {
              postProcess: 'with-template',
              count: info.itemsCount,
            })}
          </span>
          <span className="amount">
            <CurrencyText value={info?.itemsSum ?? 0} />
          </span>
        </div>
        <div className="navigated-title">{t('Inventory')}</div>
        <div className="navigated-list">
          <NavigationLink to={`${match.url}/fast`} as={Link}>
            <div className="leading">
              <i className="icon-csgofast" />
            </div>
            <div>
              <span>CSGOFAST</span>
            </div>
          </NavigationLink>
        </div>
        <div className="navigated-title">{t('Other')}</div>
        <div className="navigated-list">
          <StyledNavigationLink
            data-disable={freeCaseInfo?.count > 0 ? 'available' : 'disable'}
            onClick={redirectHandler}
          >
            <div className="leading">
              <i className="icon-bonus-case" />
            </div>
            <div>
              <span>{t('Free cases')}</span>
              <span className="badge-light">
                {freeCaseInfo?.count ?? 0} × <CurrencyText value={freeCaseInfo?.price ?? 0} />
              </span>
            </div>
          </StyledNavigationLink>
          <NavigationLink to="/wheel" as={Link}>
            <div className="leading">
              <i className="icon-bonus-wheel" />
            </div>
            <div>
              <span>{t('Wheel Spin')}</span>
              <span className="badge-light">
                {rewards.freeSpins} × <CurrencyText value={rewards.freeSpins * 100} />
              </span>
            </div>
          </NavigationLink>
          {/* <NavigationLink to="/leaders-race-next" as={Link}>
            <div className="leading">
              <i className="icon-leaders" />
            </div>
            <div>
              <span>{t('Your tickets')}</span>
            </div>
          </NavigationLink> */}
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  & .fast-inventory {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: 0.3125rem;
    height: 8.875rem;
    align-content: center;

    & span {
      justify-self: center;
    }

    & .count {
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0.121875px;
      color: var(--color-white);
    }

    & .amount {
      font-size: 1.75rem;
      line-height: 2rem;
      color: var(--color-white);
    }
  }

  & .navigated-title {
    display: flex;
    align-items: flex-end;
    height: 3.625rem;
    padding: 0.875rem 1rem;

    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: 0.1px;
    color: var(--color-gray-200);
    text-transform: uppercase;
  }

  & .navigated-list {
    display: grid;
    grid-auto-flow: row;
    background-color: var(--color-dark-600);
  }
`;

const StyledNavigationLink = styled(NavigationLink)`
  &[data-disable='available'] {
    opacity: 1;
  }
  &[data-disable='disable'] {
    opacity: 0.5;
    pointer-events: none;
  }
`;
