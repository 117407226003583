/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'JOGO',
  Chat: 'Bate-papo',
  'Email Support': 'Suporte por e-mail',
  'Live Support Chat': 'Bate-papo de suporte ao vivo',
  History: 'História',
  Top: 'Melhor',
  Rules: 'Rules',
  'At stake': 'Estaca',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Para escrever uma mensagem, você precisa fazer login',
  'Only players who have played the game are allowed to write':
    'Somente os jogadores que jogaram o jogo podem escrever',
  'You are banned': 'Você está banido',
  Reason: 'Razão',
  'Time left': 'Tempo restante',
  'Time to summarizing': 'Tempo para resumir',
  spam: 'Spam',
  'advertising (auto)': 'Propaganda',
  advertising: 'Propaganda',
  rudeness: 'Grosseria',
  begging: 'Mendicando',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Hoje',
  Yesterday: 'Ontem',
  'Last year': 'Ano Passado',
  'Show my wins': 'Mostre-me vitórias',
  'History is empty': 'O histórico está vazio',
  Cancel: 'Cancelar',
  Delete: 'Apagar',
  Close: 'Fechar',
  Confirm: 'Confirmar',
  'Are you sure to delete all notifications?':
    'Tem a certeza de que quer apagar todas as notificações?',
  'Log in': 'Entrar',
  'Log in to try your luck': 'Faça o login para tentar sua sorte',
  'try your luck!': 'tenta a tua sorte!',
  'Take your prize': 'Pegue seu prêmio',
  'Sell for': 'vender para',
  BACK: 'BACK',
  'Warning!': 'Atenção!',
  'Congratulations!': 'Parabéns!',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Parabéns! Uma troca com seu prêmio foi enviada para sua conta.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'O comércio será cancelado após uma hora',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Após o seu pedido ser processado, pode receber os seus ganhos na página de envios da troca de Steam',

  'Round number': 'Número da rodada',
  'Round hash': 'Hash redondo',
  'Hash salt': 'Sal hash',

  'Refill amount': 'Valor da recarga',
  Bonus: 'Bônus',
  'Bonus experience': 'Bônus de experiência',
  'Bonus wheel spins': 'Bônus de giros de roleta',
  'Bonus cases': 'Bônus de caixas',
  'Trick or treat?': 'Doçura ou travessura?',
  'Keep your halloween tip jars ready!': 'Mantenha seus potes de dicas de halloween prontos!',
  'Bonuses on every balance refill': 'Bônus em cada recarga de saldo',

  '${currency(amount)} bonus is ready!': 'O bônus de ${currency(amount)} está pronto!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Reabasteça o saldo e ganhe até ${currency(amount)} em giros grátis e caixas',

  'Get bonus': 'pegue agora',
};
