import { getUserDetails } from '../selectors';
import { shallowEqual, useSelector } from 'react-redux';
import { IStore } from 'store/interface';
import { IVerification } from '../interfaces/user.reducer.interface';

export const useUserVerification = () => ({
  verification: useSelector<IStore, IVerification>(
    state => getUserDetails<'verification'>(state, 'verification'),
    shallowEqual
  ),
});
