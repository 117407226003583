import React from 'react';
import { AppBar } from '@components/bars';
import History from './history';

export const HistoryPage = () => (
  <>
    <AppBar deep />
    <History />
  </>
);
