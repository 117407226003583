import { ProfileSettingsDialog } from './profile-settings-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.PROFILE_SETTINGS, {
  component: ProfileSettingsDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});
