import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IBoard } from '../board.interfaces';
import { useGameBoard, useGameCondition, useHighlightSector } from '../../../../hooks';
import { factors } from '../../../../interfaces';
import { useDialog } from 'core/hooks';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';
import { GamePhase } from '../../../../interfaces/game-phase.enum';

interface IPairBoard extends IBoard {
  protectType: IBoard['type'];
}

export const PairBoard: FC<IPairBoard> = ({ type, protectType }) => {
  const { t } = useTranslation('baccarat');
  const { bet, opposite } = useGameBoard(type, protectType);
  const { handleToggleDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);
  const sectors = useHighlightSector();
  const phase = useGameCondition();

  return (
    <Container
      className={`${type} ${bet?.id && 'active'} ${
        sectors?.includes(type) && phase === GamePhase.TIMEOUT && 'pulse-sector'
      }`}
      onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
        module: ParticipateModule.BACCARAT,
        type,
        protectType,
      })}
      disabled={!opposite?.id}
    >
      <span>
        {t(type)} x<span>{factors[type]}</span>
      </span>
    </Container>
  );
};

const Container = styled.button`
  border: 0.125rem solid;
  border-radius: 0.5rem;
  transition: all 100ms linear;
  background: var(--color-fill);
  border-color: var(--color-stroke);

  &.pulse-sector {
    animation: baccarat-pulse-sector-animation 1s infinite;
  }

  &.PP {
    grid-area: pair-player;
    --color-fill: var(--bacarrat-player-fill);
    --color-stroke: var(--bacarrat-player-stroke);

    &:disabled {
      opacity: 0.5;
    }

    &:active:not(:disabled) {
      background: var(--bacarrat-player-stroke);
    }
  }

  &.BP {
    grid-area: pair-banker;
    --color-fill: var(--bacarrat-banker-fill);
    --color-stroke: var(--bacarrat-banker-stroke);

    &:disabled {
      opacity: 0.5;
    }

    &:active:not(:disabled) {
      background: var(--bacarrat-banker-stroke);
    }
  }

  &.active {
    background: var(--stroke-color);
  }

  & span {
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    letter-spacing: 0.13058px;
    color: var(--color-white);
    text-align: center;

    & span {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
`;
