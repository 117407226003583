export const textLimit = (text: string | null, limit: number): string => {
  text = typeof text === 'string' ? text.trim() : '';
  if (text.length <= limit) return text;
  text = text.slice(0, limit);

  return `${text}...`;
};

//  use:
//  declOfNum(count, [ 'День', 'Дня','Дней']);

export const declOfNum = (n: number, titles: string[]) =>
  titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];

export const abbreviateNumber = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}KK`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}KKK`;
};
