export default {
  'Your win': 'Твой выигрыш',
  'Select skins that you want to take': 'Выберите предметы, которые хотите забрать',
  'By price': 'По цене',
  '(Autotrade will receive after 10 minutes)': '(Автотрейд будет отменен через 10 минут)',

  'Select all': 'Выбрать все',
  Reset: 'Сбросить',
  'To balance': 'На баланс',
  'Withdraw to STEAM': 'Вывести в STEAM',
  'Receive winnings': 'Получить выигрыш',
  'Items delivery': 'Доставка предметов',
  'Open in browser': 'Открыть в браузере',
};
