import React from 'react';
import { CandyContainer, Container, Img } from './balance.styles';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { useGetLRBalance } from 'modules/event-module/comics-event/hooks';

export const Balance = () => {
  const { balanceData } = useGetLRBalance();

  const arrayBalance = [
    {
      icon: <Img src={eventImagesPath.bronzeDailyPath} width={24} height={24} />,
      balance: balanceData.daily,
    },
    {
      icon: <Img src={eventImagesPath.silverWeeklyPath} width={24} height={24} />,
      balance: balanceData.weekly,
    },
    {
      icon: <Img src={eventImagesPath.goldSpecialPath} width={24} height={24} />,
      balance: balanceData.special,
    },
  ];
  return (
    <Container>
      {arrayBalance.map(({ icon, balance }, id) => (
        <CandyContainer key={id}>
          {icon}
          <span>{balance}</span>
        </CandyContainer>
      ))}
    </Container>
  );
};
