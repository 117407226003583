export default {
  Language: 'Język',
  'Bets in current room': 'Zakład w bieżącym pokoju',
  Timers: 'Minutniki',
  'Game start': 'Początek gry',
  'Game ending': 'Koniec gry',
  'Roulette ticking': 'Tykanie ruletki',
  'Roulette start': 'Uruchomienie ruletki',
  'Item set': 'Item set',
  'My bet': 'Mój zakład',
  'Coins transfer': 'Przekazanie monet',
  'Start button': 'Przycisk rozpoczęcia',
  'Stop button': 'Przycisk zakończenia',
  Countdown: 'Odliczanie',
  Soundtrack: 'Ścieżka dźwiękowa',
  Crash: 'Awaria',
  'Picking item': 'Wybieranie pozycji',
  Crafting: 'Rzemiosło',
  Failure: 'Porażka',
  Win: 'Wygrana',
  Sell: 'Sprzedawaj',
  Take: 'Podejmij',
  'Rooms bets': 'Zakłady w pokojach',
  'Chat message sending': 'Wysyłanie wiadomości w czacie',
  'Chat message receiving': 'Otrzymywanie wiadomości w czacie',
  Sound: 'Dźwięk',
  'Chat sound': 'Dźwięk czatu',
  'Game sounds': 'Dźwięk gry',
  Volume: 'Głośność',
  steamApi: {
    noKey: 'BRAK KLUCZA API STEAM',
    need: 'Wymagane do sprzedaży i handlu skórkami z innymi graczami',
    addLink: 'DODAJ LINK',
    link: 'SKĄD GO WZIĄĆ?',
  },
};
