import * as React from 'react';
import styled from 'styled-components';

import RulerSvg from './RulerSvg';

const RateLine = ({ rulerCoef, rotate, userBet }) => (
  <RateStep
    style={{
      transform: `rotate(${40 * -rotate}deg)`,
    }}
  >
    <ProgressLine>
      {rulerCoef && (
        <React.Fragment>
          <RateValue dangerouslySetInnerHTML={{ __html: `${rulerCoef}x` }} />
        </React.Fragment>
      )}
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="198"
        height="82"
        viewBox="0 0 198 82"
      >
        <g>
          <g>
            <g>
              <rect y="67" fill="#FFFFFF" width="4" height="15" />
            </g>
            <g>
              <rect
                x="68.8"
                y="61.8"
                transform="matrix(0.973 -0.2306 0.2306 0.973 -13.8646 17.9456)"
                width="2"
                height="13"
                fill="#FFFFFF"
              />

              <rect
                x="133.1"
                y="39.1"
                transform="matrix(0.8936 -0.4488 0.4488 0.8936 -6.2115 65.0451)"
                width="2"
                height="13"
                fill="#FFFFFF"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M24.2,81.4c0-0.3-0.1-1.2-0.1-1.5L24,78.4l-0.1-1.5l-0.1-0.8c0.5,0,0.6,0,1-0.1c0.4,0,0.5,0,1-0.1l0.1,0.8
              l0.1,1.5l0.1,1.5c0,0.3,0.1,1.2,0.1,1.5"
              />
              <path
                fill="#FFFFFF"
                d="M47.4,79c0-0.3-0.2-1.2-0.2-1.5l-0.2-1.5l-0.2-1.5l-0.1-0.8c0.5-0.1,0.6-0.1,1-0.2c0.4-0.1,0.5-0.1,1-0.2
              l0.1,0.8l0.2,1.5l0.2,1.5c0,0.3,0.2,1.2,0.2,1.5"
              />
              <path
                fill="#FFFFFF"
                d="M92.8,68.9c-0.1-0.3-0.4-1.1-0.5-1.4l-0.5-1.4l-0.5-1.4l-0.2-0.8c0.5-0.2,0.6-0.2,1-0.3
              c0.4-0.1,0.5-0.2,1-0.3l0.2,0.8l0.5,1.4l0.5,1.4c0.1,0.3,0.4,1.1,0.5,1.4"
              />
              <path
                fill="#FFFFFF"
                d="M114.7,61.3c-0.1-0.3-0.5-1.1-0.6-1.4l-0.6-1.4l-0.6-1.4l-0.3-0.7c0.5-0.2,0.5-0.2,0.9-0.4
              c0.4-0.2,0.5-0.2,0.9-0.4l0.3,0.7l0.6,1.4l0.6,1.4c0.1,0.3,0.5,1.1,0.6,1.4"
              />
              <path
                fill="#FFFFFF"
                d="M156.6,41c-0.2-0.3-0.6-1-0.8-1.3l-0.8-1.3l-0.8-1.3l-0.4-0.7c0.4-0.3,0.5-0.3,0.9-0.5
              c0.4-0.2,0.4-0.3,0.9-0.5l0.4,0.7l0.8,1.3l0.8,1.3c0.2,0.3,0.6,1,0.8,1.3"
              />
              <path
                fill="#FFFFFF"
                d="M176.2,28.4c-0.2-0.2-0.7-1-0.9-1.2l-0.9-1.2l-0.9-1.2l-0.5-0.7c0.4-0.3,0.5-0.3,0.8-0.6
              c0.3-0.2,0.4-0.3,0.8-0.6l0.5,0.7l0.9,1.2l0.9,1.2c0.2,0.2,0.7,1,0.9,1.2"
              />
            </g>
          </g>
        </g>
      </svg>
      {rulerCoef && rulerCoef <= Math.floor(userBet.coefAutoStop) && (
        <AutoStopSector>
          <RulerSvg coefAutoStop={userBet.coefAutoStop} rulerCoef={rulerCoef} />
          {userBet.coefAutoStop - rulerCoef < 1 && (
            <AutoStopValue
              left={userBet.coefAutoStop - rulerCoef}
            >{`x${userBet.coefAutoStop}`}</AutoStopValue>
          )}
        </AutoStopSector>
      )}
    </ProgressLine>
  </RateStep>
);

export default RateLine;

const RateStep = styled.li`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 608px;
  height: 608px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const ProgressLine = styled.div`
  transform: translateX(calc(50% - 2px));
  position: relative;
`;

const AutoStopSector = styled.div`
  position: absolute;
  left: 0;
  bottom: -40px;
  transform: rotate(-2deg);
`;

const RateValue = styled.span`
  position: absolute;
  top: 41px;
  left: -7px;
  font-size: 14px;
  color: var(--color-white);
`;

const AutoStopValue = styled.span`
  position: absolute;
  bottom: ${props => `${80 * (props.left * props.left) + 12}px`};
  transform: rotate(${props => `-${24 * props.left * (props.left + 1)}deg`});
  left: ${props => `${205 * props.left + 15}px`};

  font-size: 13px;
  letter-spacing: -0.3px;
  color: var(--ochre);
`;
