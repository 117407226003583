import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Timer } from '@components/TimerEvent';
import { useTranslation } from 'react-i18next';
import layout from '../../assets/layout.png';
import { IRaffle, Phases } from '../../interfaces/reducer.leaders.interfaces';

interface IRafflePreview {
  raffle: IRaffle;
}

export const RafflePreview: React.FC<IRafflePreview> = ({ raffle }) => {
  const { t } = useTranslation();
  const bets = Object.keys(raffle.ownBets).length;

  return (
    <Container className={raffle.type} to={`/leaders-race-next/raffles/active/${raffle.type}`}>
      <RacesInfo>
        <span>
          {t('Raffle interval', {
            context: raffle.type,
          })}
        </span>
        {[Phases.raffling, Phases.step_raffling].includes(raffle.phase) && (
          <span>{t('Raffling')}</span>
        )}
        {Phases.waiting === raffle.phase && (
          <span>
            <Timer finishEvent={raffle.finishAt} />
          </span>
        )}
        {Phases.step_waiting === raffle.phase && (
          <span>
            <Timer key={raffle.phase} finishEvent={raffle.items[raffle.stage]?.raffleAt} />
          </span>
        )}
      </RacesInfo>
      <RacesActions>
        <ActionButton>{bets ? t('Participating') : t('To raffle')}</ActionButton>
        {!!bets && (
          <span>
            {t('Your bet')}
            {': '}
            {bets}
          </span>
        )}
      </RacesActions>
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 1rem 1rem 0;
  padding: 0.7rem;
  height: 100px;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  font-size: 0.9rem;
  font-weight: bold;
  color: var(--color-white);

  &.two-week {
    --race-type: var(--regular-layout);
  }
  &.two-month {
    --race-type: var(--fast-layout);
  }
  &.one-year {
    --race-type: var(--great-layout);
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--race-type);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${layout});
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  & > div {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    z-index: 3;
  }
`;

const RacesInfo = styled.div`
  flex-basis: 55%;
  & > span:first-child,
  & > span:last-child {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
  }
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;

  border-radius: 0.5rem;
  background-color: var(--yellow);
  border: 0;

  font-size: 0.95rem;
  font-weight: bold;
  color: var(--color-dark-600);
`;

const RacesActions = styled.div`
  flex-basis: 45%;
`;
