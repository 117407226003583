import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IReferralCampaign } from '../../../../interfaces';
import { Button } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import { profileModals } from '../../../../modal.config';
import { useTranslation } from 'react-i18next';

interface IReferralCampaignMetadata {
  campaign: IReferralCampaign;
}

export const ReferralCampaignMetadata: FC<IReferralCampaignMetadata> = ({ campaign }) => {
  const { t } = useTranslation();
  const { handleToggleDialog } = useDialog(profileModals.CUSTOMIZATION_REFERRAL_CAMPAIGN);
  const computed = useMemo<any>(() => {
    return campaign.referrals.reduce(
      (acc, item) => ({
        ...acc,
        [item.status]: acc[item.status] + 1,
      }),
      { active: 0, lost: 0, inactive: 0 }
    );
  }, [campaign.referrals]);

  const active = parseInt(`${(computed.active / campaign.referrals.length) * 100}`) || 0;
  const lost = parseInt(`${(computed.lost / campaign.referrals.length) * 100}`) || 0;
  const inactive = parseInt(`${(computed.inactive / campaign.referrals.length) * 100}`) || 0;

  return (
    <Container>
      <div className="campaign-status">
        <span>
          <i className="icon-turn-on" />
          {t('campaign statuses.status', { context: 'active' })}
        </span>
        <button
          onClick={handleToggleDialog(profileModals.CUSTOMIZATION_REFERRAL_CAMPAIGN, {
            id: campaign.id,
          })}
        >
          <i className="icon-settings" />
        </button>
      </div>
      <div className="campaign-name">
        <span>{campaign.name}</span>
      </div>
      <div className="campaign-income">
        <span className="campaign-income-title">{t('Payments from campaign')}</span>
        <span className="campaign-income-value">
          <CurrencyText value={campaign.totalIncome} />
        </span>
      </div>
      <div className="campaign-members">
        <span className="campaign-members-title">{t('Total referrals')}</span>
        <span className="campaign-members-value">{campaign.referralsCount?.toLocaleString()}</span>
      </div>
      <div className="campaign-statistics">
        <div className="campaign-statistics-title">
          {t('referral statuses.status', { context: 'active' })}
        </div>
        <div className="campaign-statistics-percent">
          {computed.active} | {active}%
        </div>
        <div className="campaign-statistics-progress">
          <div
            className="campaign-statistics-progress-indicator"
            style={{ transform: `translate3d(${active - 100}%, 0, 0)` }}
          />
        </div>
        <div className="campaign-statistics-title">
          {t('referral statuses.status', { context: 'lost' })}
        </div>
        <div className="campaign-statistics-percent">
          {computed.lost} | {lost}%
        </div>
        <div className="campaign-statistics-progress">
          <div
            className="campaign-statistics-progress-indicator"
            style={{ transform: `translate3d(${lost - 100}%, 0, 0)` }}
          />
        </div>
        <div className="campaign-statistics-title">
          {t('referral statuses.status', { context: 'inactive' })}
        </div>
        <div className="campaign-statistics-percent">
          {computed.inactive} | {inactive}%
        </div>
        <div className="campaign-statistics-progress">
          <div
            className="campaign-statistics-progress-indicator"
            style={{ transform: `translate3d(${inactive - 100}%, 0, 0)` }}
          />
        </div>
      </div>
      <div className="campaign-controllers">
        <CopyToClipboard text={`${window.location.host}?ref=${campaign.code}`}>
          <Button className="secondary">
            <span className="multi-line">
              <span>{t('Copy referral link')}</span>
              <span>{`${window.location.host}?ref=${campaign.code}`}</span>
            </span>
          </Button>
        </CopyToClipboard>
        <CopyToClipboard text={campaign.code}>
          <Button className="secondary">
            <span className="multi-line">
              <span>{t('Copy promocode')}</span>
              <span>{campaign.code}</span>
            </span>
          </Button>
        </CopyToClipboard>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(5, min-content) 1fr;
  padding: 1.5rem 0 0;
  min-height: 36.125rem;

  & .campaign-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 1.875rem;
    padding: 0 1rem;

    & span {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      color: var(--color-white);

      & i {
        font-size: 1.25rem;
        color: var(--success);
        margin-right: 0.5rem;
      }
    }

    & button {
      align-self: self-end;
      border: 0;
      background: transparent;
      padding: 0;

      & i {
        font-size: 1.25rem;
        color: var(--color-white-400);
      }
    }
  }

  & .campaign-name {
    display: flex;
    align-items: flex-end;
    padding: 0 1rem;
    height: 2.125rem;
    overflow: hidden;

    & span {
      max-width: 100%;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: var(--color-white);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  & .campaign-income,
  & .campaign-members {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    align-content: flex-end;
    grid-gap: 0.1875rem;
    min-height: 5.5rem;
    padding: 0 1rem;

    &-title {
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      color: var(--color-gray-200);
    }

    &-value {
      font-size: 1.75rem;
      line-height: 2.25rem;
      color: var(--color-white);
    }
  }

  & .campaign-statistics {
    display: grid;
    grid-template-columns: repeat(2, 0.65fr) 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-gap: 0.375rem 0.625rem;
    align-content: center;
    min-height: 5.5rem;
    padding: 0 1.25rem;

    &-title {
      font-size: 0.875rem;
      letter-spacing: 0.24px;
      color: var(--color-white);
    }

    &-percent {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.125rem;
      text-align: right;
      padding-right: 0.125rem;
      letter-spacing: 1px;
      color: #00bad9;

      &:nth-child(5) {
        color: var(--color-red-100);
      }

      &:nth-child(8) {
        color: #9ea7b3;
      }
    }

    &-progress {
      align-self: center;
      width: 100%;
      height: 0.5rem;
      position: relative;
      background-color: var(--color-gray-900);
      border-radius: 0.25rem;
      overflow: hidden;

      --progress-color: #00bad9;

      &:nth-child(6) {
        --progress-color: var(--color-red-100);
      }

      &:nth-child(9) {
        --progress-color: #9ea7b3;
      }

      &-indicator {
        background-color: var(--progress-color);
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  & .campaign-controllers {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.5rem;
    align-self: center;
    padding: 0 1rem;
  }
`;
