export const specialRates = ['TRX', 'ETH', 'DASH', 'EUR', 'BNB', 'LTC', 'TON', 'MATIC', 'DOGE'];
export const getPrice = (
  tokenName: string,
  amount: number,
  currencyRate: number,
  decimal: number
) => {
  const res = specialRates.some(el => el === tokenName)
    ? (amount / currencyRate / 100).toFixed(decimal)
    : ((amount * currencyRate) / 100).toFixed(decimal);
  return res;
};
