import { createSelector } from 'reselect';
import {
  IQuest,
  IRaceRecord,
  IRacesList,
  IRaffle,
  SteamAppId,
} from './interfaces/reducer.leaders.interfaces';
import { Map, Record } from 'immutable';
import { getGameSettings } from 'core/AppShell/selectors';
import { getCrossAppId } from 'core/CrossServices/selectors';
import { IStore } from '../../store/interface';

const questGetter = ({ leaders }: any) => leaders.quests;
const racesGetter = ({ leaders }: any) => leaders.races;
const rafflesGetter = ({ leaders }: any) => leaders.raffles;
const raffleHistoryGetter = ({ leaders }: any) => leaders.history.raffles;

const racePositions = ['regular', 'fast', 'great'];

export const getRaces = createSelector(racesGetter, (races: IRacesList) =>
  races.sort((l: IRaceRecord, r: IRaceRecord) =>
    racePositions.indexOf(l.raceType) > racePositions.indexOf(r.raceType) ? 1 : -1
  )
);

export const getGroupQuests = createSelector(questGetter, (quests: any) =>
  quests
    .groupBy((v: any) => v.type)
    .valueSeq()
    .map((v: any) =>
      v
        .valueSeq()
        .toArray()
        .sort((l: IQuest, r: IQuest) => (l.id > r.id ? 1 : -1))
    )
    .sort((l: IQuest[], r: IQuest[]) => (l[0].type === 'daily' ? -1 : 1))
);

export const getGroupQuestsByGameId = createSelector(
  questGetter,
  (_: any, gameTypeId: number) => gameTypeId,
  (quests: Map<number, IQuest>, gameTypeId: number) =>
    quests
      .filter((v: IQuest) => v.gameTypeId === gameTypeId && v.isOpened)
      .valueSeq()
      .toArray()
);

export const getFilteredQuestsByRoutes = createSelector(
  questGetter,
  getGameSettings,
  (quests: Map<number, IQuest>, settings: any) =>
    settings
      ? quests
          .sort((l: IQuest, r: IQuest) => {
            if (r.gameTypeId === settings.id && r.type === 'daily') return 1;
            if (l.gameTypeId === settings.id && l.type === 'daily') return -1;
            if (r.gameTypeId === settings.id && r.type === 'weekly') return 1;
            if (l.gameTypeId === settings.id && l.type === 'weekly') return -1;
            return l.type === 'daily' ? -1 : 1;
          })
          .valueSeq()
          .toArray()
      : quests
          .valueSeq()
          .toArray()
          .sort((l: IQuest, r: IQuest) => (l.priority < r.priority ? -1 : 1))
);

export const getUserCoupons = ({ leaders }: any) => leaders.coupons;

export const getRaceByParams = createSelector(
  racesGetter,
  (_: any, raceType: string) => raceType,
  (races: IRaceRecord[], raceType: string) => races.find((v: any) => v.raceType === raceType)
);

export const getAllRaffles = createSelector(
  rafflesGetter,
  (raffles: Map<number, Record<IRaffle>>) =>
    raffles
      .valueSeq()
      .toArray()
      .map((raffle: any) => raffle.toJS())
);

export const getRaffleByType = ({ leaders }: any, raffleType: string) =>
  leaders.raffles.find((raffle: IRaffle) => raffle.type === raffleType);

export const getTournamentHistoryByParams = ({ leaders }: any, raceType: string) =>
  leaders.history.tournaments.get(raceType);

export const getActiveTournamentHistoryByParams = (
  { leaders }: any,
  raceType: string,
  id?: number
) => {
  if (!leaders.history.tournaments.has(raceType) || !id) return;
  return leaders.history.tournaments
    .get(raceType)
    .entities.find((history: IRaffle) => history.id === id);
};

export const getInviteItem = ({ leaders }: IStore, path: boolean | (string | number)[]) =>
  path && leaders.raffles.hasIn(path) ? leaders.raffles.getIn(path) : null;

export const getBetConditions = ({ leaders }: IStore, raffleId: number, itemId: number) =>
  leaders.raffles.hasIn([raffleId, 'ownBets', itemId])
    ? leaders.raffles.getIn([raffleId, 'ownBets', itemId])
    : null;

export const getRaffleHistory = createSelector(raffleHistoryGetter, raffles => ({
  loading: raffles.get('loading'),
  loaded: raffles.get('loaded'),
  entities: raffles.get('entities').valueSeq().toArray(),
}));

export const getRaffleHistoryById = ({ leaders }: any, id: string) =>
  leaders.history.raffles.entities.get(id);

// store

const storeGetter = ({ leaders }: IStore) => leaders.store;

export const getStoreProducts = createSelector(
  storeGetter,
  (_: any, appId: SteamAppId | null) => appId,
  getCrossAppId,
  (store: any, appId: SteamAppId | null, crossAppId: any) => {
    const selectAppId = appId || crossAppId.steam_appid;
    return store.has(selectAppId) ? store.get(selectAppId) : store.get(730);
  }
);

export const getPurchaseItem = ({ leaders }: IStore, path: boolean | (string | number)[]) =>
  path && leaders.store.hasIn(path) ? leaders.store.getIn(path) : null;

export const getTotalStatistics = ({ leaders }: IStore) => leaders.statistics.get('total');

export const getTotalWinners = ({ leaders }: IStore) => leaders.statistics.get('topPlayers');

export const getTotalLeaderboards = ({ leaders }: IStore) => leaders.statistics.get('leaderboards');

export const getTotalLeaderboard = createSelector(
  getTotalLeaderboards,
  (_: any, raceType: string) => raceType,
  (leaderboards: IRaceRecord[], raceType: string) =>
    leaderboards.find(item => item.raceType === raceType)
);

export const getYearlyStatistics = ({ leaders }: IStore) => leaders.statistics.get('early');
