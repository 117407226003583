export enum FilterCaseBattleConfig {
  FILTER = 'FILTER_CASE_BATTLE',
}

export enum CaseDetailsConfig {
  CASE_DETAIL = 'CASE_BATTLE_DETAIL',
}

export enum AddCasesConfig {
  ADD_CASE = 'ADD_CASE',
  SORT = 'ADD_CASE_SORT',
  PRICE_RANGE = 'ADD_CASE_PRICE_RANGE',
  SEARCH = 'ADD_CASE_SEARCH',
}
