import React, { useEffect } from 'react';
import { Container, PriceContainer, StyledButton } from './case-open-button.styles';
import { useTranslation } from 'react-i18next';
import { TicketTypeEnum } from 'modules/event-module/comics-event/interfaces/leader-race.interfaces';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { useDispatch } from 'react-redux';
import { FastRollButton } from 'games/CaseGame/components/Roulette/components';
import { useGetRouletteStatus } from 'games/CaseGame/hooks';
import {
  RouletteStatus,
  openCaseActionRequest,
  rouletteStatusActionAllStartRoll,
} from 'games/CaseGame';
import { useUser } from 'core/User';
import { useGetLRBalance } from 'modules/event-module/comics-event/hooks';
import { actionRequestLeaderRaceBalance } from 'modules/event-module/comics-event/duck/leader-race-duck';

interface CaseOpenButtonProps {
  fruitType: TicketTypeEnum;
  price: number;
  caseId: string;
}

const coinTypeObject = {
  [TicketTypeEnum.DAILY]: (
    <img src={eventImagesPath.bronzeDailyPath} width={20} height={20} alt="" />
  ),
  [TicketTypeEnum.SPECIAL]: (
    <img src={eventImagesPath.goldSpecialPath} width={20} height={20} alt="" />
  ),
  [TicketTypeEnum.WEEKLY]: (
    <img src={eventImagesPath.silverWeeklyPath} width={20} height={20} alt="" />
  ),
};

export const CaseOpenButton: React.FC<CaseOpenButtonProps> = React.memo(
  ({ price, fruitType, caseId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { balanceData } = useGetLRBalance();
    const { rouletteStatus, isAllInRoll, isAllStartRoll } = useGetRouletteStatus();
    const { user } = useUser();

    useEffect(() => {
      isAllInRoll && dispatch(actionRequestLeaderRaceBalance());
    }, [isAllInRoll, dispatch]);

    const clickHandler = () => {
      if (rouletteStatus[0] !== RouletteStatus.IN_ROLL) {
        dispatch(rouletteStatusActionAllStartRoll());
        dispatch(
          openCaseActionRequest({
            caseId,
            type: 'payable',
            lineCount: 1,
            isFree: false,
            ticketColor: fruitType,
          })
        );
      }
    };

    const balanceType = {
      [TicketTypeEnum.DAILY]: balanceData.daily,
      [TicketTypeEnum.SPECIAL]: balanceData.special,
      [TicketTypeEnum.WEEKLY]: balanceData.weekly,
    };
    const isDisabled = isAllInRoll || isAllStartRoll || balanceType[fruitType] < price || !user.id;

    return (
      <Container>
        <FastRollButton />
        <StyledButton disabled={isDisabled} onClick={clickHandler}>
          {isAllInRoll || isAllStartRoll ? (
            t('draw')
          ) : (
            <>
              {t('open')}
              <PriceContainer>
                {coinTypeObject?.[fruitType as TicketTypeEnum] ??
                  coinTypeObject[TicketTypeEnum.DAILY]}
                {price}
              </PriceContainer>
            </>
          )}
        </StyledButton>
      </Container>
    );
  }
);
