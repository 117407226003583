import * as React from 'react';
import styled from 'styled-components';

import { IHistory, IMatches } from '../interfaces/poggi.reducer.interfaces';

export const HistoryContent: React.FC<IHistory> = props => (
  <ContentContainer>
    {props.matches &&
      props.matches.map((matche: IMatches) => (
        <BuildingWrap key={matche.id}>
          <BuildingIcon
            src={`//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/${matche.id}.png`}
            alt="building"
          />
          <BuildingCounter>{`x${matche.count}`}</BuildingCounter>
        </BuildingWrap>
      ))}
  </ContentContainer>
);

const ContentContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 11px repeat(auto-fill, 50px) 11px;
  grid-gap: 5px;
  height: 50px;
  overflow-y: hidden;
  overflow-x: scroll;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 11px;
    height: 100%;
  }
`;

const BuildingWrap = styled.div`
  justify-self: center;
  align-self: center;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  position: relative;
`;

const BuildingIcon = styled.img`
  width: 55px;
  height: 55px;
  object-fit: contain;
`;

const BuildingCounter = styled.span`
  position: absolute;
  top: -1px;
  right: -4px;
  font-size: 0.7rem;
  color: var(--primary);
`;
