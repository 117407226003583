import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header, Skeleton, StatisticsContainer } from './leader-boards.styles';

import { LeaderBoardItem } from '../leader-board-item';
import { useGetLeaderRaceInfo } from 'modules/event-module/comics-event/hooks';

const emptyArray = new Array(3).fill(0).map((_, index) => index);

export const LeaderBoards = () => {
  const { t } = useTranslation();
  const { leaderboards, isLoading } = useGetLeaderRaceInfo();

  return (
    <Container>
      <Header>{t('overallStatistics')}</Header>
      <StatisticsContainer>
        {isLoading ? (
          emptyArray.map(value => <Skeleton className="skeleton" key={value} />)
        ) : (
          <>
            {leaderboards
              ?.reverse()
              .map(({ totalTickets, color, myStats, players, firstTop, secondTop }, index) => (
                <LeaderBoardItem
                  key={index}
                  totalTickets={totalTickets}
                  color={color}
                  myStats={myStats}
                  players={players}
                  firstTop={firstTop}
                  secondTop={secondTop}
                />
              ))}
          </>
        )}
      </StatisticsContainer>
    </Container>
  );
};
