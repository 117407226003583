import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDescription } from './styles';
import { Timer } from '../TimerEvent';
// import { abbreviateNumber } from 'core/utils/text.utils';
import { IPremium, IUserExperience } from 'core/User';
// import { premiumConfig } from 'modules/PersonalArea/personalArea.utils';

interface IUserProfileDescription {
  profileType: string;
  levelProgress?: number;
  name: string;
  experience: Partial<IUserExperience>;
  premium: IPremium;
}

export const UserProfileDescription: FC<IUserProfileDescription> = ({
  profileType,
  levelProgress,
  name,
  experience,
  premium,
}) => {
  const { t } = useTranslation();
  // const { permissions } = premiumConfig[profileType] ?? {};

  //exp=levelProgress
  return (
    <UserDescription profileType={profileType} exp={0}>
      <span className="user-name">{name}</span>
      {/* <span>{experience.level} lvl</span> */}
      <span className="user-account-type">
        {t(profileType)} {premium.until && <Timer finishEvent={premium.until} />}
      </span>

      {/* <span className="user-experience">
        {experience.xp && experience.nextLevelXp && (
          <>
            {abbreviateNumber(experience.xp)}/{abbreviateNumber(experience.nextLevelXp)}
          </>
        )}
      </span>
      {!!experience.nextLevelXp && !permissions?.['additional_experience'].lock && (
        <>
          <span className="user-account-bonus">{t('Bonus experience')}</span>
          <span className="user-account-bonus">+{permissions?.['experience'].status}</span>
        </>
      )} */}
    </UserDescription>
  );
};
