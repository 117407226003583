export default {
  GAME: 'OYUN',
  Winner: 'Kazanan',
  Won: 'Kazandı',
  Chance: 'Şans',
  Prize: 'Ödül',
  'At stake': 'Tehlikede',
  'Sign in through STEAM to participate': 'Katılmak için STEAM ile oturum aç',
  'Steam trade link': 'Steam takas linki',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Önce yatırın',
  'Place a bet': 'Bahis koy',
  'Deposit more': 'Daha fazla yatır',
  'Min bet': 'Minimum bahis',
  'Max bet': 'Maksimum bahis',
  'Bet amount': 'Bahis miktari',
  'max. items': 'Yatırılacak en yüksek eşya',
  hidden: 'gizli',
  Copy: 'Kopyala',
  'Winning after commission': 'Kazanmanın ardındaki komisyon',
  Loading: 'Yükleniyor',
};
