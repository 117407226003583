import React from 'react';
import { Container } from './FastRoll.styles';
import { SvgIcons } from 'games/CaseGame/assets';
import { useGetRouletteStatus, useGetSpeedType } from 'games/CaseGame/hooks';
import { useDispatch } from 'react-redux';
import { actionChangeSpeedRoll } from 'games/CaseGame/ducks';

export const FastRollButton = React.memo(() => {
  const { speedType } = useGetSpeedType();
  const dispatch = useDispatch();
  const { isAllInRoll, isAllStartRoll } = useGetRouletteStatus();

  const clickHandler = () => {
    dispatch(actionChangeSpeedRoll(speedType === 'normal' ? 'fast' : 'normal'));
  };

  return (
    <Container
      onClick={clickHandler}
      isActive={speedType === 'fast'}
      data-disable={isAllStartRoll || isAllInRoll ? 'blocked' : 'enable'}
    >
      <div>
        <SvgIcons.LightingIcon className="svg" />
      </div>
    </Container>
  );
});
