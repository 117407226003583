import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto 1fr;

  & > svg:last-child {
    margin-left: auto;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-gap: 3px;
`;

export const Title = styled.span`
  color: var(--success);
  display: block;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
`;

export const SubTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 13px;
  letter-spacing: 0.286px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
