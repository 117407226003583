import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardWrapper, Container, Title } from './top-players.styles';

import { CardContainer, TopPlayersCard } from '../top-players-card';
import { useGetLeaderRaceInfo } from 'modules/event-module/comics-event/hooks';

export const TopPlayers = React.memo(() => {
  const { t } = useTranslation();
  const { isLoading, top } = useGetLeaderRaceInfo();

  return (
    <Container>
      <Title>{t('topPlayers')}</Title>
      <CardWrapper>
        {isLoading
          ? [1, 2, 3].map(value => <CardContainer key={value} className="skeleton" />)
          : top.map((value, index) => <TopPlayersCard {...value} key={index} position={index} />)}
      </CardWrapper>
    </Container>
  );
});
