import styled from 'styled-components';
import level from '../../../assets/level-background.svg';
import levelfill from '../../../assets/level-background-fill.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 25px 10px;
  min-width: 100vw;
  min-height: 44vw;
  position: relative;
`;

export const Rewards = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-self: center;
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

export const Level = styled.span`
  color: var(--color-white);
  font-size: 0.88rem;
  font-weight: 700;
  padding-top: 20px;
  text-align: center;
`;

export const RewardTypeCurrency = styled.span`
  color: var(--aqua-500);
  font-size: 1.25rem;
  padding-top: 5px;
  text-align: center;
`;

export const RewardTypeInfo = styled.span`
  color: var(--color-white);
  font-size: 1rem;
  width: 70%;
  text-align: center;
  padding: 5px 0;
`;

interface IProgressStyle {
  level: number;
  progress: number;
}

export const Progress = styled.div<IProgressStyle>`
  position: relative;
  width: 100%;
  height: 25px;

  &::before,
  &::after,
  & .append-line::before,
  & > .prepend-line::before {
    content: '';
    display: block;
    width: 100%;
    height: 25px;
    background-repeat: no-repeat;
    background-size: 100vw;
    left: 54%;
    position: absolute;
    top: 0;
  }

  &::before {
    background-image: url(${level});
  }

  &::after,
  & .append-line::before {
    background-image: url(${levelfill});
  }

  &::after {
    width: ${p => p.progress}%;
  }

  ${p =>
    p.level === 0 &&
    `
    & .append-line {
      display: block!important;
    }
  `};

  ${p =>
    p.level === 54 &&
    `
    & .prepend-line {
      display: block!important;
    }
  `};

  & > .append-line,
  & > .prepend-line {
    display: none;
    width: 100%;
    height: 100%;
  }

  & .append-line::before {
    left: -46%;
  }
`;

export const BtnWrap = styled.div`
  padding: 5px 0 10px;
`;
