import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)<{ size: string; isReadyToTaken?: boolean }>`
  align-items: center;
  background: #29343a;
  border-radius: 50%;
  display: flex;
  height: ${({ size }) => size};
  justify-content: center;
  position: relative;
  width: ${({ size }) => size};
  ${({ isReadyToTaken }) => isReadyToTaken && 'animation-name:shake; '}
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

  @keyframes shake {
    0% {
      transform: translate(0, 0);
    }

    25% {
      transform: translate(0, -5px);
    }
  }

  & > .coin {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: -9px;
  top: -9px;

  & svg {
    fill: none;
  }
`;
