export enum MarketplaceDialogs {
  MARKETPLACE_OVERPRICE_FILTER = 'MARKETPLACE_OVERPRICE_FILTER',
  MARKETPLACE_MULTI_FILTER = 'MARKETPLACE_MULTI_FILTER',
  MARKETPLACE_SINGLE_FILTER = 'MARKETPLACE_SINGLE_FILTER',

  INVENTORY_SORT_BY_FILTERS = 'INVENTORY_SORT_BY_FILTERS',
  INVENTORY_SALE_CONFIGURATION = 'INVENTORY_SALE_CONFIGURATION',
  INVENTORY_PENALTY_WARNING_DIALOG = 'INVENTORY_PENALTY_WARNING_DIALOG',

  INVENTORY_SELLER_TRADE_DIALOG = 'INVENTORY_SELLER_TRADE_DIALOG',
  INVENTORY_BUYER_TRADE_DIALOG = 'INVENTORY_BUYER_TRADE_DIALOG',

  MARKETPLACE_STORE_PURCHASE = 'MARKETPLACE_STORE_PURCHASE',
  MARKETPLACE_STORE_SALE = 'MARKETPLACE_STORE_SALE',
}
