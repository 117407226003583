export default {
  'Refill sum:': 'Refill sum:',
  'Popular methods:': 'Popular methods:',
  'Region methods:': 'region methods:',
  CSGOSkins: 'CSGO skins:',
  MoneyWithdrawal: 'Money withdrawal:',
  'Payment systems:': 'Payment systems:',
  'Select country:': 'Select country:',
  'Last successful method:': 'Last successful method:',
  Commission: 'Commission ',
  Promo: 'Payment of more than ◎15.4',
  //refill methods
  advcash: 'ADVcash',
  alfabank: 'Альфа-Банк',
  atf: 'ATF',
  atf24: 'АТФ Банк',
  ecopayz: 'EcoPayz',
  jeton: 'Jeton',
  'moneta.RU': 'Монета.ru',
  sberbank: 'Сбербанк',
  unionpay: 'Union Pay',
  'unionpay bank': 'Union Pay Bank',
  'unionpay p2p': 'UnionPay P2P',
  qiwi: 'QIWI',
  yandex: 'Yandex.Money',
  wmz: 'WebMoney (WMZ)',
  'astropay-cash': 'Astopay Cash',
  'astropay-online': 'Astopay Online',
  'astropay-offline': 'Astropay Offline',
  cash: 'Cash',
  promsviaz: 'Промсвязьбанк',
  kassa24: 'Касса 24',
  'ePay.bg': 'Epay',
  eps: 'Eps',
  giropay: 'GiroPay',
  ideal: 'Ideal',
  klarna: 'Klarna',
  'nordeo-solo': 'Nordeo Solo',
  paysafecard: 'PaySafeCard',
  transfer: 'RAPID transfer',
  mobile: 'Mobile payments',
  affinbankviamol: 'Affin Bank (via MOL)',
  fpxviamol: 'FPX (via MOL)',
  hongleongviamol: 'Hong Leong Connect (via MOL)',
  maybank2uviamol: 'Maybank2u (via MOL)',
  pbebankviamol: 'PBeBank (via MOL)',
  petronasviamol: 'Petronas (via MOL)',
  uobviamol: 'UOB (via MOL)',
  rhbviamol: 'RHB now (via MOL)',
  oncash: 'Oncash',
  eggmoney: 'eggmoney',
  tarjetaslocales: 'TarjetasLocales',
  psetransferenciabancaria: 'PSETransferencia',
  cashbee: 'Cashbee',
  jetonvoucher: 'Jeton Voucher',
  culturevoucher: 'Culture Voucher',
  smartculturevoucher: 'Smart Culture Voucher',
  bookvoucher: 'Book Voucher',
  happymoney: 'HappyMoney',
  g2awallet: 'G2A Wallet',
  poli: 'POLi',
  banktransfer: 'Bank Transfer',
  creditcards: 'Credit cards',
  vtcpay: 'VTC PAY',
  localcreditcards: 'Local Credit Cards',
  teencash: 'TeenCash',
  tmoney: 'Tmoney',
  bancontact: 'Bancontact',
  codes: 'Payment codes',
  visa: 'VISA/MasterCard',
  bitcoin: 'Bitcoin',
  steam: 'Steam',
  vgo: 'VGO',
  vmz: 'WebMoney (WMZ)',
  gamemoney: 'GameMoney',
  skrill: 'Skrill',
  neteller: 'Neteller',
  'gamemoney-aggregator': 'GameMoney',
  wechatpay: 'WeChat Pay',
  alipay: 'Alipay',
  card: 'Visa/MasterCard (RU)',
  card_eur: 'Visa/MasterCard (EU/US)',
  boleto: 'Boleto',
  banktransferviaskrill: 'Bank Transfer',
  connectum: 'Connectum',
  payop: 'Payop',
  enot: 'Enot',
  'apple pay': 'Apple Pay',
  'samsung pay': 'Samsung Pay',
  ethereum: 'Ethereum',
  oxxo: 'OXXO',
  'pago facil': 'Pago Facil',
  rapipago: 'Rapipago',
  'bitcoin cash': 'Bitcoin Cash',
  litecoin: 'Litecoin',
  dash: 'Dash',
  zcash: 'Zcash',
  payeer: 'Payeer',
  'perfect money': 'Perfect Money',
  jirapay: 'Jira Pay',
  'jira-pay': '$t(jirapay)',

  //methods
  bitbay: 'G2A',
  mts: 'Mts',
  beeline: 'Beeline',
  tele2: 'Tele2',
  megafon: 'Megafon',
  qiwi_payop: '$t(payop)',
  qiwi_jirapay: '$t(jirapay)',
  qiwi_enot: '$t(jirapay)',
  'qiwi_gm-direct': '$t(gamemoney)',
  'bitcoin_gm-direct': '$t(gamemoney)',
  bitcoin_jirapay: '$t(jirapay)',
  'cup_gm-direct': '$t(gamemoney)',
  gc_unionpay_g2a: 'G2A',
  card_payop: '$t(payop)',
  'card_gm-direct': '$t(gamemoney)',
  card_enot: '$t(jirapay)',
  card_jirapay: '$t(jirapay)',
  yandex_jirapay: '$t(jirapay)',
  'yandex_gm-direct': '$t(gamemoney)',

  'Input code:': 'Input code:',
  Activate: 'Activate',

  //input placeholder
  'pay input placeholder': '',
  'pay input placeholder_phone': 'Phone number (without +)',
  'pay input placeholder_email': 'Enter your email',
  'pay input placeholder_wallet': '',

  'pay input label': '',
  'pay input label_phone': 'Phone number (without +)',
  'pay input label_email': 'Email',
  'pay input label_wallet': '',

  'input type': 'text',
  'input type_gift': 'text',
  'input type_wallet': 'text',
  'input type_email': 'email',
  'input type_phone': 'number',

  'Bonus:': 'Bonus: ',

  'Temporarily unavailable': 'Temporarily unavailable',
  'Unfortunately, this payment method is temporarily unavailable.':
    'Unfortunately, this payment method is temporarily unavailable.',
  'Select the country of replenishment': 'Select the country of replenishment',
  'Choose your country': 'Choose your country',
  'The available methods':
    'The available methods and the rate of replenishment of the balance may depend on the country of replenishment.',
  'This flag will be displayed in your profile': 'This flag will be displayed in your profile',
  'Confirm country': 'Confirm country',

  'Please use other methods. We apologize for any inconvenience caused.':
    'Please use other methods. We apologize for any inconvenience caused.',
  'Unfortunately, this payment method is temporarily unavailable. Please use other methods. We apologize for any inconvenience caused.':
    'Unfortunately, this payment method is temporarily unavailable. Please use other methods. We apologize for any inconvenience caused.',
  bonus: 'YOUR BONUS',
  topUp: 'on top-up',
  receive: 'You will receive',
  name: 'Name:',
  surname: 'Surname:',
  required: 'Field is required',
  phone: 'Phone number',
  cpf: 'CPF Number',
  cpfFormatError: 'CPF entered incorrectly',
  'Via SIH account': 'Via SIH account',
  'Refill via SIH': 'Refill via SIH',
};
