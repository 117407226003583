import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { useMarketplaceElement } from '../../../../hooks';
import { IDialogComponent } from 'core/interfaces';
import { URLS } from 'services/constant-urls';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IBuyerTradeDialog extends IDialogComponent<string[]> {}

export const BuyerTradeDialog: FC<IBuyerTradeDialog> = ({ data, handleCloseModal }) => {
  const [id] = data;
  const { t } = useTranslation('marketplace');
  const { item } = useMarketplaceElement('purchases', id);

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <div className="details">
        <div className="lot">
          <div className="thumbnails">
            <img src={skinItemImage(item.items?.[0]?.baseItem.icon)} alt="" />
          </div>
          <div className="data">
            <span className="exterior">{item.items?.[0]?.baseItem.exterior}</span>
            <span className={`float ${getExteriorByFloat(item.items?.[0]?.float)}`}>
              {!!item.items?.[0]?.float && item.items?.[0]?.float?.toFixed(4)}
            </span>
            <span className="name">{item.items?.[0]?.baseItem.name}</span>
            <span className="price">
              <CurrencyText value={item.price} />
              {item.price !== item.recommendedPrice && (
                <span
                  className={`${item.price > item.recommendedPrice ? 'overprice' : 'underprice'}`}
                >
                  {' ('}
                  {item.price > item.recommendedPrice && '+'}
                  {Math.round((item.price / item.recommendedPrice) * 100) - 100}
                  {'%)'}
                </span>
              )}
            </span>
          </div>
        </div>
        <div className="warning-message">
          <i className="icon-warn" />
          <p>{t('trade-offer.alert')}</p>
          <p>{t('trade-offer.describe')}</p>
        </div>
      </div>
      <div className="controller">
        <Button
          className="primary with-progress"
          as="a"
          href={URLS.purchaseUrl}
          target="_blank"
          duration={+(Date.parse(item?.nextStatusAt) - Date.now()).toFixed(0)}
          current={dayjs(item?.statusAt).diff(Date.now())}
          finished={dayjs(item?.statusAt).diff(item?.nextStatusAt)}
        >
          <div className="link-inner">
            <i className="icon-steam-provider" />
            <span>{t('trade-offer.get trade')}</span>
            <i className="icon-redirect-link" />
          </div>
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  position: relative;

  & .close {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }

  & .details {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.75rem;
    padding: 1.75rem 1rem 0;

    & .lot {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 0.85fr 1fr;
      grid-template-rows: 5rem;
      grid-gap: 0.5rem;
      padding: 0.75rem;
      background: var(--color-dark-100);
      border-radius: var(--size-raduis-m);

      & .thumbnails {
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          width: 100%;
          height: 100%;
          max-height: 5rem;
          object-fit: contain;
        }
      }

      & .data {
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-auto-rows: min-content;
        grid-gap: 0.125rem 0.75rem;

        & .exterior,
        & .float {
          font-weight: bold;
          font-size: 0.625rem;
          line-height: 0.875rem;
          letter-spacing: 0.3px;
        }

        & .exterior {
          color: var(--color-gray-200);
          white-space: nowrap;
        }

        & .float {
          &.factory-new {
            color: var(--color-exterior-factory-new);
          }

          &.minimal-wear {
            color: var(--color-exterior-minimal-wear);
          }

          &.field-tested {
            color: var(--color-exterior-field-tested);
          }

          &.well-worn {
            color: var(--color-exterior-well-worn);
          }

          &.battle-scarred {
            color: var(--color-exterior-battle-scarred);
          }
        }

        & .price,
        & .name {
          grid-column: 1 / 3;
          font-size: 0.875rem;
          letter-spacing: 0.24px;
          color: var(--color-white);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        & .name {
          line-height: 2rem;
        }

        & .price {
          & span {
            font-size: inherit;
            letter-spacing: inherit;

            &.overprice {
              color: var(--color-red);
            }

            &.underprice {
              color: var(--color-green);
            }
          }
        }
      }
    }

    & .warning-message {
      display: grid;
      grid-template-columns: 1.75rem 1fr;
      grid-gap: 0.25rem 1.5rem;

      & i {
        font-size: 2rem;
        color: var(--yellow);
      }

      & p {
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0.570769px;
        color: var(--color-white);

        &:not(:first-of-type) {
          grid-column: 2 / 3;
        }
      }
    }
  }

  & .controller {
    padding: 1.25rem 1rem 1.75rem;

    & .link-inner {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 50px 1fr 50px;
      grid-gap: 0.25rem;
      width: 100%;
      height: 100%;

      & i,
      & span {
        justify-self: center;
        align-self: center;

        &.icon-steam-provider {
          font-size: 1.5rem;
        }

        &.icon-redirect-link {
          font-size: 0.65rem;
        }
      }

      & span {
        font-weight: bold;
        font-size: 0.8125rem;
        line-height: 1rem;
        text-align: center;
        letter-spacing: 0.286px;
        color: var(--color-dark-100);
      }
    }
  }
`;
