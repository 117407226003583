export enum CrossServiceActionsTypes {
  INIT_CROSS = 'app/cross/INIT_CROSS',
  INIT_GAME_ID = 'app/cross/INIT_GAME_ID',
  UPDATE_GAME_ID = 'app/cross/UPDATE_GAME_ID',
}

interface IInitCrossAction {
  type: typeof CrossServiceActionsTypes.INIT_CROSS;
  payload: any;
}

interface IInitGameIdAction {
  type: typeof CrossServiceActionsTypes.INIT_GAME_ID;
  payload: any;
}

interface IIpdateGameIdAction {
  type: typeof CrossServiceActionsTypes.UPDATE_GAME_ID;
  payload: any;
}

export type crossServiceActions = IInitCrossAction | IInitGameIdAction | IIpdateGameIdAction;
