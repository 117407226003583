/* eslint-disable */
export default {
  'Your tickets': 'Your tickets',
  'Your places': 'Your places:',
  More: 'More',
  Completed: 'Completed',
  'for raffle': 'for raffle',

  'Races type_regularPlace': 'Regular',
  'Races type_greatPlace': 'Great',
  'Races type_fastPlace': 'Fast',

  'Races type_regular': 'Regular',
  'Races type_great': 'Great',
  'Races type_fast': 'Fast',

  'Raffle interval_two-week': 'Covert raffle',
  'Raffle interval_two-month': 'Contraband raffle',
  'Raffle interval_one-year': 'Golden raffle',

  'Raffle appeal_two-week': 'Participate in the raffle of cool skins!',
  'Raffle faq_two-week': 'You will need green tickets to participate.',
  'Raffle info_two-week':
    'Tickets can be obtained by participating in tournaments and as well by completing quests.',

  'Raffle appeal_two-month': 'Participate in the raffle of cool skins!',
  'Raffle faq_two-month': 'You will need blue tickets to participate.',
  'Raffle info_two-month':
    'Tickets can be obtained by participating in tournaments and as well by completing quests.',

  'Raffle appeal_one-year': 'Participate in the raffle of cool skins!',
  'Raffle faq_one-year': 'You will need yellow tickets to participate.',
  'Raffle info_one-year': 'Get more tickets for refilling your balance!',

  'Top {{length}}:': 'Top {{length}}:',
  place: 'Your place:',
  points: 'Points scored:',
  pointsToNextPlace: 'Points to next place:',
  pointsToTop100: 'Points to top-100:',

  tickets: 'Tickets scored:',
  ticketsToNextPlace: 'Tickets to next place:',
  ticketsToTop100: 'Tickets to top-100:',

  position: '$t(place)',

  Quests: 'Quests',
  'Quests interval_daily': 'Daily quests',
  'Quests interval_weekly': 'Weekly quests',

  'Closest raffle': 'Closest raffle',
  Daily: 'Daily',
  Tournaments: 'Tournaments',
  'Prize pool': 'Prize pool',
  'Your place': 'Your place',
  'Tickets scored': 'Tickets scored',
  'Show all': 'Show all',
  // 'Points scored': 'Points scored',
  'Closes raffles': 'Closes raffles',
  Members: 'Members',
  Participants: 'Participants',
  Participating: 'Participating',
  'To raffle': 'To raffle >',
  'Items in raffle': 'Items in raffle - {{items}}',
  'Items in raffle_one-year': '{{items}} mini raffles. 30 minutes between each',
  items_interval: '(1){item};(2-inf){items};',
  Raffling: 'Raffling',
  Raffles: 'Raffles',
  Lot: 'Lot',
  purchased: 'purchased',
  // 'lots won': 'lots won',
  Participate: 'Participate',
  'Participate for': 'Participate for',

  //invite modal
  'Done!': 'Done!',
  'go to quests': 'go to quests',
  "You don't have enough tickets": "You don't have enough tickets.",
  "You don't have enough tickets, refill your balance and get tickets":
    "You don't have enough tickets, refill your balance and get tickets.",
  'You got a place in the raffle': 'You got a place in the raffle.',

  'Win ${count} games in a row with a bet not more than ${currency(amount)}':
    'Win {{ count }} games in a row with a bet not more than ${currency(amount)} in ',
  'Play ${count} games with a bet ${currency(amount)} or more':
    'Play {{ count }} games with a bet ${currency(amount)} or more in ',
  'Win ${count} games with a bet ${currency(amount)} or more on ZERO':
    'Win {{ count }} games with a bet ${currency(amount)} or more on ZERO in ',
  'Win ${count} games in a row with a bet ${currency(amount)} or more':
    'Win {{count}} games in a row with a bet ${currency(amount)} or more in ',

  'Win ${count} games with a bet more than ${currency(amount)}':
    'Win {{count}} games with a bet more than ${currency(amount)} in ',
  'Win ${count} games with coef more than ${coef} with a bet ${currency(amount)} or more':
    'Win {{count}} games with coef more than {{coef}} with a bet ${currency(amount)} or more in ',
  'Win ${count} games in a row with a bet ${currency(amount)} or more on x50':
    'Win {{count}} games in a row with a bet ${currency(amount)} or more on x50 in ',
  'Win ${count} games with a bet ${currency(amount)} or more':
    'Win {{count}} games with a bet ${currency(amount)} or more in ',

  'Win ${count} games with a bet ${currency(amount)} or more on x5':
    'Win {{count}} games with a bet ${currency(amount)} or more on x5 in ',

  'Special quest. Unlocked after completing all weekly quests':
    'Special quest. Unlocked after completing all weekly quests',

  // tower quests
  'Climb to the ${floorNumber} floor on easy mode with a bet ${currency(amount)} or more':
    'Climb to the {{floorNumber}} floor on easy mode with a bet ${currency(amount)} or more in ',

  'Climb to the ${count} floor on medium mode with a bet ${currency(amount)} or more':
    'Climb to the {{count}} floor on medium mode with a bet ${currency(amount)} or more in ',

  'Climb to the ${count} floor on hard mode with a bet ${currency(amount)} or more':
    'Climb to the {{count}} floor on hard mode with a bet ${currency(amount)} or more in ',
  // bonus quests
  'Refill your balance for ${currency(amount)}': 'Refill your balance for ${currency(amount)}',
  // craft quests
  'Create ${count} items with price ${currency(amount)} or more':
    'Craft {{count}} items worth ${currency(amount)} or more in ',
  'Create ${count} items in a row with price ${currency(amount)} or more':
    'Craft {{count}} items in a row worth ${currency(amount)} or more in ',
  // crash quests
  'Play ${count} games with x${x} or more with a bet ${currency(amount)} or more':
    'Play {{count}} games with a coef ${x}x or more with a bet of ${currency(amount)} or more in ',

  'Play ${count} games with a bet ${currency(amount)} or more on ${betName}':
    'Play {{count}} games with a bet ${currency(amount)} or more on {{betName}}  in ',
  'Win ${count} games with a bet ${currency(amount)} or more on ${betName}':
    'Win {{count}} games with a bet ${currency(amount)} or more on {{betName}}  in ',
  'Win ${count} games in a row with a bet ${currency(amount)} or more on ${betName}':
    'Win {{count}} games in a row with a bet ${currency(amount)} or more on {{betName}}  in ',
  'Craft ${count} items worth ${currency(amount)} or more':
    'Craft {{count}} items worth ${currency(amount)} or more  in ',
  'Craft ${count} items in a row worth ${currency(amount)} or more':
    'Craft {{count}} items in a row worth ${currency(amount)} or more  in ',
  'Play ${count} games with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'Play {{count}} games with coef ${coef}x or more with a bet ${currency(amount)} or more  in ',
  'Win ${count} games with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'Win {{count}} games with coef ${coef}x or more with a bet ${currency(amount)} or more  in ',
  'Win ${count} games in a row with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'Win {{count}} games in a row with coef ${coef}x or more with a bet ${currency(amount)} or more  in ',

  Take: 'Take',
  'Get reward': 'Get reward',

  day: 'day',
  day_3: 'days',
  days: 'days',

  active: 'active',

  'Raffle will be available again soon. Come back later!':
    'Raffle will be available again soon. Come back later!',

  //rules
  'To leaders race': 'To leaders race',
  'Leaders Race 2021 has opened!': 'Leaders Race 2021 has opened!',
  'Take part in tournaments with rewards of legendary prizes.':
    'Take part in tournaments with rewards of legendary prizes.',
  'CSGOFAST created three types of tournaments for users:':
    'CSGOFAST created three types of tournaments for users:',
  'Points are awarded for participating in any games on CSGOFAST.  The more points you earn, the higher your position in the list of participants in the daily tournament.':
    'Points are awarded for participating in any games on CSGOFAST.  The more points you earn, the higher your position in the list of participants in the daily tournament.',
  'Green tickets are also awarded for completing': 'Green tickets are also awarded for completing ',
  'daily quests.': 'daily quests.',
  'The more green tickets you earned, the higher your position in the list of participants in the Fast tournament.':
    'The more green tickets you earned, the higher your position in the list of participants in the Fast tournament.',
  'Blue tickets are also awarded for completing': 'Blue tickets are also awarded for completing ',
  'weekly quests.': 'weekly quests.',
  'The more blue tickets you earned, the higher your position in the list of participants in the Great tournament.':
    'The more blue tickets you earned, the higher your position in the list of participants in the Great tournament.',
  'Yellow tickets are also awarded for completing':
    'Yellow tickets are also awarded for completing',
  'a secret quest.': ' a secret quest.',
  'At the end of the year, we will organize a special, time-limited event that uses yellow tickets.':
    'At the end of the year, we will organize a special, time-limited event that uses yellow tickets.',
  'What are these "tickets"?': 'What are these "tickets"?',
  'Tickets are the main currency of the Leader Race.':
    'Tickets are the main currency of the Leader Race.',
  'Green and Blue tickets are used in the item raffles and in the bonus store. Yellow tickets are used in the special event at the end of the year.':
    'Green and Blue tickets are used in the item raffles and in the bonus store. Yellow tickets are used in the special event at the end of the year.',
  'Don’t worry! if you use tickets in the store or in the raffle, your place in the tournament will not change.':
    'Don’t worry! if you use tickets in the store or in the raffle, your place in the tournament will not change.',
  'Quests? Yeah, quests!': 'Quests? Yeah, quests!',
  'Quests are special tasks available to all type of accounts except demo. Prize for completing the quests - Green, Blue or Yellow tickets.':
    'Quests are special tasks available to all type of accounts except demo. Prize for completing the quests - Green, Blue or Yellow tickets.',
  'There are three types of quests:': 'There are three types of quests:',
  'Six simple quests that are updated once every 24 hours.':
    'Six simple quests that are updated once every 24 hours. ',
  'For completing one quest, you will receive 1 Green ticket.':
    'For completing one quest, you will receive 1 Green ticket. ',
  'Daily quests are performed in any order.': 'Daily quests are performed in any order',
  'Six more difficult quests that are updated once a week.':
    'Six more difficult quests that are updated once a week. ',
  'For completing one quest, you will receive 1 Blue ticket.':
    'For completing one quest, you will receive 1 Blue ticket. ',
  'Weekly quests are carried out in strict order. For example, to open the second quest, firstly complete the first':
    'Weekly quests are carried out in strict order. For example, to open the second quest, firstly complete the first',
  'Secret quest': 'Secret quest',
  'Yellow tickets are also awarded for completing a secret quest.':
    'Yellow tickets are also awarded for completing a secret quest. ',
  'Available after completing all weekly quests.': 'Available after completing all weekly quests.',
  'For completing the secret quest, you will receive 1 Yellow ticket.':
    'For completing the secret quest, you will receive 1 Yellow ticket.',
  'You won’t know what the secret quest is hiding until you complete all the weekly quests!':
    'You won’t know what the secret quest is hiding until you complete all the weekly quests!',
  'Remember! If you do not complete the secret quest before the end of the week, it will disappear.':
    'Remember! If you do not complete the secret quest before the end of the week, it will disappear.',
  '* We remind you that Yellow tickets can be used at the end of the year in a special, secret activity! Remember to save them':
    '* We remind you that Yellow tickets can be used at the end of the year in a special, secret activity! Remember to save them',
  'Raffles and Bonus shop': 'Raffles and Bonus shop',
  'To participate in the draws you will need tickets that you can get for participating in tournaments, as well as for completing quests.':
    'To participate in the draws you will need tickets that you can get for participating in tournaments, as well as for completing quests.',
  'To participate in the Green Raffle, you will need Green tickets, and to participate in the Blue Raffle - Blue ones.':
    'To participate in the Green Raffle, you will need Green tickets, and to participate in the Blue Raffle - Blue ones.',
  // notice
  'The item not found': 'The item is no longer available in the store.',

  // landing
  'Welcome to Golden Raffle 2020!': 'Welcome to Golden Raffle 2020!',
  'Thank you for joining Leaders Race 2020!': 'Thank you for joining Leaders Race 2020!',
  'Raffling prize:': 'Raffling prize:',
  'Best players in 2020': 'Best players in 2020',
  'All participants': 'All participants',
  'It is time to use your yellow tickets!': 'It is time to use your yellow tickets!',
  'Join to raffling or exchange your tickets to items in to our special shop!':
    'Join to raffling or exchange your tickets to items in to our special shop!',
  'YELLOW TICKET': 'YELLOW TICKET',
  'Refill your balance': 'Refill your balance',
  'Get your yellow ticket': 'Get your yellow ticket',
  'Win cool prizes': 'Win cool prizes',
  'Golden Shop': 'Golden Shop',
  'for the most dedicated ones!': 'for the most dedicated ones!',
  'Golden Shop - for the most dedicated ones!': 'Golden Shop - for the most dedicated ones!',
  'Refill balance and get yellow ticket!': 'Refill balance and get yellow ticket!',

  'Welcome to golden raffle': 'Welcome to golden raffle',
  Join: 'Join',
  'Yellow ticket': 'Yellow ticket',

  'LEADERS RACE 2021 FINAL': 'LEADERS RACE 2021 FINAL',
  'TOP 3 PLAYERS': 'TOP 3 PLAYERS',
  'Players statistics': 'Players statistics',

  'Total prize:': 'Total prize:',
  'Total quests passed:': 'Total quests passed:',
  'Total items:': 'Total items:',
  'on sum:': 'on sum:',
  'Tickets raffled:': 'Tickets raffled:',
  'Items obtained during the Golden Raffle and in the Golden Store will be credited to the user on their Steam account.':
    'Items obtained during the Golden Raffle and in the Golden Store will be credited to the user on their Steam account.',

  'Get cool items for yellow tickets.': 'Get cool items for yellow tickets.',
  'Get more tickets for refilling your balance!': 'Get more tickets for refilling your balance!',
  'There will be only 5 VIP items in the shop, in one single copy:':
    'There will be only 5 VIP items in the shop, in one single copy:',
  '24.12.2020 the Golden Shop will open.': '24.12.2020 the Golden Shop will open.',
  'You have': 'You have',
  'Items in raffle:': 'Items in raffle:',
  'Tickets won:': 'Tickets won:',
  'Skins won:': 'Skins won:',
  'Quests passed:': 'Quests passed:',
  'Money won:': 'Money won:',
  'Available at': 'Available at ',
  'Leaders race 2020 is finished': 'Leaders race 2020 is finished',
  'New Leaders Race 2021 is starting soon!': 'New Leaders Race 2021 is starting soon!',
  'Leaders Race 2020 results': 'Leaders Race 2020 results',
  'New race will start soon': 'New race will start soon',

  'Stock up your golden tickets and get ready for the final leaders race of 2021':
    'Stock up your golden tickets and get ready for the final leaders race of 2021',
  'Raffling items for the amount of': 'Raffling items for the amount of',
  'Go to the race': 'Go to the race',
  'How and when the raffles will proceed?': 'How and when the raffles will proceed?',
  'On the 13th of December, registration will be open for the raffles of golden tickets':
    'On the 13th of December, registration will be open for the raffles of golden tickets',
  'On the 31st of December, there will be 24 independent raffles with an interval of 30 minutes':
    'On the 31st of December, there will be 24 independent raffles with an interval of 30 minutes',
  'Everyone has a chance to win! The cost of participation varies from 2 to 50 gold tickets depending on the value of the prize':
    'Everyone has a chance to win! The cost of participation varies from 2 to 50 gold tickets depending on the value of the prize',
  'How to get more golden tickets and participate in few raffles at once?':
    'How to get more golden tickets and participate in few raffles at once?',
  'Starting December 13, get golden tickets by refilling your balance':
    'Starting December 13, get golden tickets by <0>refilling your balance</0> with money and prepaid cards',
  'Have time to complete the available weekly quests before December 31':
    'Have time to complete the available <1>weekly quests</1> before December 31',
  'The list of all raffles, time of event and prizes will be available on December 13':
    'The list of all raffles, time of event and prizes will be available on December 13',
  'Time left': 'Time left',
  Days: 'Days',
  Hours: 'Hours',
  Minutes: 'Minutes',
  Seconds: 'Seconds',
};
