import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { ParticipationList } from './participation-list';
import { chips } from 'core/configs';
import { ParticipateModule } from 'core/interfaces';
import { useParticipateSettings } from 'core/hooks';

interface IChipsPanel {
  module: ParticipateModule;
  participate: number[];
  handleOnPickChip: (idx: number, maxBet: number) => () => void;
  handleUnPickChip: (idx: number) => () => void;
}

export const ChipsPanel: FC<IChipsPanel> = ({
  module,
  participate,
  handleOnPickChip,
  handleUnPickChip,
}) => {
  const { settings } = useParticipateSettings(module);

  return (
    <Container className="chip-panel">
      <ParticipationList participate={participate} handleUnPickChip={handleUnPickChip} />
      <div className="chips-container">
        {chips.map((chip: number, idx) => (
          <div key={chip} onClick={handleOnPickChip(idx, settings.max_bet)}>
            <ChipItem item={chip} />
          </div>
        ))}
      </div>
    </Container>
  );
};

const ChipItem: FC<{ item: number }> = ({ item }) => (
  <ChipContainer>
    {typeof item === 'number' && (
      <img src={`//d2lomvz2jrw9ac.cloudfront.net/common/chips/${item}.svg`} alt="" />
    )}
    <div>
      <CurrencyText value={item} />
    </div>
  </ChipContainer>
);

const Container = styled.div`
  align-self: self-end;
  display: grid;
  grid-template-rows: 2.5rem 3.5rem;
  align-content: space-between;
  padding: 0.5rem 0;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;

  & .chips-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-template-rows: 3.5rem;
    grid-gap: 0.25rem;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 0.875rem;
    }
  }
`;

const ChipContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.8rem;
  grid-auto-flow: row;
  grid-gap: 0.3rem;
  padding: 0.3rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: var(--size-radius-s);
  background: var(--color-gray-700);

  & img {
    justify-self: center;
    align-self: center;
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }

  & > div {
    justify-self: center;
    align-self: center;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.69px;

    color: var(--color-green);
  }
`;
