import { useSelector } from 'react-redux';
import { getCaseBattleList } from '../ducks';

export const useGetMyBattleList = () => {
  const { dataMyCaseBattleList, isLoadingMyBattle } = useSelector(getCaseBattleList);
  return {
    idsOrderMyGames: dataMyCaseBattleList.idOrder,
    myGames: dataMyCaseBattleList.games,
    isLoadingMyBattle,
  };
};
