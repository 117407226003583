import React, { FC, useState } from 'react';
import { AccessDenied, Controllers, StickerTabBar } from '../components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sendMessage, sendSticker } from '../duck';
import { updateUser } from 'core/User/ducks/duck';
import { getUser } from 'core/User/selectors';
import { ISendMessage, ISendSticker } from '../interfaces/services.interface';
import styled from 'styled-components';
import { SignIn } from 'core/Auth';
import SoundService from 'services/sound.service';

const sound = new SoundService();

interface IControls {
  roomName: string;
}

export const Controls: FC<IControls> = ({ roomName }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const [room] = useState<string>(`chat:${i18n.language}:${roomName}`);
  const user = useSelector(getUser, shallowEqual);

  const dispatch = useDispatch();

  const sendStickerAction = (stickerPack: string, stickerId: number) => {
    const requestParams: ISendSticker = {
      room,
      stickerPack,
      stickerId,
    };
    sound.play('commonSounds', 'Chat message sending');
    dispatch(sendSticker(requestParams));
  };

  const sendMessageAction = (message: string) => {
    const requestParams: ISendMessage = { msg: message, room };
    dispatch(sendMessage(requestParams));
    sound.play('commonSounds', 'Chat message sending');
  };

  const updateUserAction = () => {
    dispatch(
      updateUser({
        chatInfo: { reasonBanned: false },
      })
    );
  };

  if (!user.id) {
    return (
      <Container>
        <SignIn className="primary full small" />
      </Container>
    );
  }

  if (!user.chatInfo.hasAccess || user.chatInfo.reasonBanned) {
    return <AccessDenied chatInfo={user.chatInfo} updateUser={updateUserAction} />;
  }

  return (
    <Container>
      <Controllers
        toggleOpen={() => setOpen(!isOpen)}
        isOpen={isOpen}
        sendMessage={sendMessageAction}
      />
      <StickerTabBar isOpen={isOpen} sendSticker={sendStickerAction} />
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 120;
  background: var(--color-light-dark-layout);
`;
