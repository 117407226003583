import React from 'react';
import { CaseContainer, Container, Title } from './CasesWhereLocated.styles';
import { useTranslation } from 'react-i18next';
import { CasesWhereLocatedItemNormalized } from 'games/CaseGame/interfaces';
import { CaseWhereLocatedItem } from './components';

interface CasesWhereLocatedProps {
  casesWhereLocated?: CasesWhereLocatedItemNormalized[];
}
export const CasesWhereLocated: React.FC<CasesWhereLocatedProps> = React.memo(
  ({ casesWhereLocated }) => {
    const { t } = useTranslation('cases2.0');
    return (
      <Container>
        <Title>{t('itemInfo.selectionCases')}</Title>
        <CaseContainer>
          {casesWhereLocated?.map(caseData => (
            <CaseWhereLocatedItem key={caseData.name} {...caseData} />
          ))}
        </CaseContainer>
      </Container>
    );
  }
);
