import React, { FC } from 'react';
import { ArrowContainer, Container, WinnerContainer } from './BattlePlayerFinalStatus.styles';
import { useTranslation } from 'react-i18next';
import { CaseBattleIcon } from 'games/CaseBattle/assets';

interface BattlePlayerFinalStatusProps {
  isWinner: boolean;
  isJoker: boolean;
  isSingleMode?: boolean;
}

export const BattlePlayerFinalStatus: FC<BattlePlayerFinalStatusProps> = ({
  isWinner,
  isSingleMode = false,
  isJoker,
}) => {
  const { t } = useTranslation();
  const color = isWinner ? (isJoker ? 'var(--pink-100)' : 'var(--green-300)') : 'var(--orange-400)';

  return (
    <Container isSingleMode={isSingleMode}>
      <ArrowContainer arrowColor={color}>
        <CaseBattleIcon.ArrowPrice className="arrow arrow-left arrow-with-margin-left" />
        <CaseBattleIcon.ArrowPrice className="arrow arrow-left" />
      </ArrowContainer>
      <WinnerContainer textColor={color}>{t(`${isWinner ? 'win' : 'unLuck'}`)}</WinnerContainer>
      <ArrowContainer arrowColor={color}>
        <CaseBattleIcon.ArrowPrice className="arrow arrow-right" />
        <CaseBattleIcon.ArrowPrice className="arrow arrow-right arrow-with-margin-right" />
      </ArrowContainer>
    </Container>
  );
};
