import {
  ICommonSounds,
  ICurrency,
  IGameSounds,
  ISettings,
} from './interfaces/appshell.reducer.interface';

// eslint-disable-next-line
export const filterRegexString =
  '(?:http(?:s)?://)?(?:www\\.)?($regexStart)(?!gofast)(?!fast)[\\w\\d-.]{0,15}[\\.,\\s_]($regexEnd)(?:/)?';

export const initialSettings: ISettings = {
  chatAdmins: [],
  dbTimeDiff: 0,
  exchangeRate: 0,
  gameTypes: {
    classic: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 250,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'classic',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    crash: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 0,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'crash',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    double: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 0,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'double',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    duel: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 0,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'duel',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    fast: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 0,
      min_item_price: 0,
      min_items_per_trade: 3,
      name: 'fast',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    x50: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 250,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'x50',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    tower: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 250,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'tower',
      roulette_ms: 0,
      timeout_ms: 0,
    },
    inventory: {
      endgame_ms: 0,
      id: 0,
      max_bet: 0,
      max_items: 0,
      max_items_per_trade: 11,
      max_players: 0,
      min_bet: 250,
      min_item_price: 0,
      min_items_per_trade: 0,
      name: 'classic',
      roulette_ms: 0,
      timeout_ms: 0,
    },
  },
  giveAwayLink: '',
  giveAwayLinks: [],
  locales: [],
  maxAttempts: [],
  regexEnd: [],
  regexStart: [],
  currencies: [],
};

export const soundDefaultStatus: Record<string, ICommonSounds | IGameSounds> = {
  gameSounds: {
    enabled: true,
    options: {
      Classic: {
        enabled: true,
        options: {
          'Bets in current room': true,
          Timers: true,
          'Game start': true,
          'Game ending': true,
          'Roulette ticking': true,
        },
      },
      Double: {
        enabled: true,
        options: {
          'My bet': true,
          'Roulette start': true,
          'Game ending': true,
          'Coins transfer': true,
        },
      },
      Baccarat: {
        enabled: true,
        options: {
          Bet: true,
          'Dispense the cards': true,
          'Flip the cards': true,
          Winning: true,
          Loosing: true,
          Timer: true,
          'Game end': true,
        },
      },
      Crash: {
        enabled: true,
        options: {
          Bet: true,
          'Crash button': true,
          'Stop button': true,
          'Game start': true,
          'Sound track': true,
          'Count down': true,
        },
      },
      Slot: {
        enabled: true,
        options: {
          'Game start': true,
          'Spin sounds': true,
          Win: true,
          'Bonus game': true,
          'Toggle lines': true,
        },
      },
      Tower: {
        enabled: true,
        options: {
          Winning: true,
          Loosing: true,
          Step: true,
          Play: true,
          'Play again': true,
          Take: true,
        },
      },
    },
  },
  commonSounds: {
    enabled: true,
    options: {
      'Chat message sending': true,
      'Chat message receiving': true,
      // 'Rooms bets': true,
      'Level up': true,
      'Next rank': true,
    },
  },
};

export const initStateCurrency: ICurrency = {
  key: 'usd',
  title: 'USD',
  rate: 0.01,
  icon: '$',
  prefix: '$',
  postfix: '',
  id: 1,
};

export const gamesToListen: string[] = [
  'classic',
  'fast',
  'double',
  'crash',
  'craft',
  'slot',
  'match-bets',
  'x50',
  'tower',
  'wheel',
  'cases',
  'hilo',
  // 'faucet',
];
