import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './EmptyTransactions.styles';

export const EmptyTransactions = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <span className="smile">:(</span>
      <span className="message">{t('History is empty')}</span>
    </Container>
  );
};
