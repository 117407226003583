export default {
  GAME: 'SPILL',
  Winner: 'Vinner',
  Win: 'Vinne',
  Chance: 'Sjanse',
  'Chance to win': 'Chance to win',
  'At stake': 'På spill',
  'Sign in through STEAM to participate': 'Logg inn gjennom STEAM for å delta',
  'Steam trade link': 'Steam trade link',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Bli den første som gjør et innskudd',
  'Place a bet': 'legg til et bet',
  'Deposit more': 'Legg til mer',
  'Min bet': 'minste innskudd',
  'Max bet': 'Maks bet',
  'Bet amount': 'Beløp',
  'max. items': 'Maks skins',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',
  'Tickets to from': 'Biletter: fra #{{ from  }} fra #{{ to }}',
  Skins: 'Skins',
  Coins: 'Mynter',
  'VIRTUAL SKINS': 'virtuelle skins',
  'Round number': 'Runde nummer',
  'Winning after commission': 'Vinner etter servicekommisjon',
  Loading: 'Laster inn',
};
