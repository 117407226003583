import React, { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import type { IControl, IOption } from '@components/Selects';
import { useTranslation } from 'react-i18next';

export const Control: FC<IControl> = ({ selectValue }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <span className="title">
        {t('Races type', {
          context: selectValue?.raceType || 'great',
        })}
      </span>
      <span className="sub-title">
        {t('from')} {dayjs(selectValue.startAt).format('DD.MM.YYYY')}
      </span>
    </Container>
  );
};

export const Option: FC<IOption> = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <span className="title">
        {t('Races type', {
          context: item?.raceType || 'great',
        })}
      </span>
      <span className="sub-title">
        {t('from')} {dayjs(item.startAt).format('DD.MM.YYYY')}
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  padding: 0.5rem;
  height: 100%;

  & .title {
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.4;
    color: var(--great-race);
  }

  & .sub-title {
    font-size: 13px;
    line-height: 15px;
    color: var(--color-white);
  }
`;
