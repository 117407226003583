import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, CurrencyWrapper } from './GameInfoComponent.styles';
import { useGetBattleGameStatusById } from 'games/CaseBattle/hooks';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { GameStatus } from 'games/CaseBattle/constants';
import { formatDate } from 'games/CaseBattle/utils';

interface GameInfoComponent {
  battleId: number;
  playersCount: number;
  gamePlayersMax: number;
  totalPrice: number;
  totalWin?: number;
  finishedAt?: Date;
}

const TextType: Record<GameStatus, string> = {
  [GameStatus.waiting]: 'waitingPeople',
  [GameStatus.start]: 'waitingForRoll',
  [GameStatus.roll]: 'battleRunning',
  [GameStatus.finish]: 'battleWinnings',
};
export const GameInfoComponent: React.FC<GameInfoComponent> = ({
  battleId,
  playersCount,
  gamePlayersMax,
  totalWin,
  finishedAt,
  totalPrice,
}) => {
  const { status } = useGetBattleGameStatusById(battleId);
  const { t } = useTranslation();
  const location = useLocation();
  const isHistoryPage = location.pathname.split('/')?.[2] === 'history';

  const additionalData: Record<GameStatus, JSX.Element | null> = {
    [GameStatus.waiting]: (
      <>
        <span>
          {playersCount}/{gamePlayersMax}
        </span>
        <CurrencyWrapper>
          <CurrencyText value={totalPrice} />
        </CurrencyWrapper>
      </>
    ),
    [GameStatus.start]: null,
    [GameStatus.roll]: null,
    [GameStatus.finish]: (
      <CurrencyWrapper>
        <CurrencyText value={totalWin} />
      </CurrencyWrapper>
    ),
  };
  return (
    <Container data-status={status}>
      {isHistoryPage && <span>{formatDate(finishedAt)}</span>}
      {t(TextType[status])}
      {additionalData[status]}
    </Container>
  );
};
