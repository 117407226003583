import React from 'react';
import { Container, StyledNavigationLink, Title } from '../styles';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link } from 'react-router-dom';
import { IconsPayoutMethods } from '../../assets';
import { useUser, useUserBalance } from 'core/User';
import { usePayoutMethods } from 'modules/withdrawal/hooks';
import { CurrencyText } from '@components/currency';
import { getMinimalMaxValue } from 'modules/withdrawal/components/utils';

interface LinkToCryptoMethodProps {
  rate: string;
}

export const LinkToCryptoMethod: React.FC<LinkToCryptoMethodProps> = React.memo(({ rate }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { coins } = useUserBalance();
  const { minCoinsForCrypto } = usePayoutMethods();
  const { user } = useUser();
  const { minimum } = getMinimalMaxValue(coins, minCoinsForCrypto, +rate);

  const isActive = coins > minimum;
  return (
    <>
      <StyledNavigationLink to={isActive ? `${match.url}/crypto` : '#'} as={Link}>
        <div className="leading">
          {isActive ? (
            <IconsPayoutMethods.BitcoinIcon className="iconCrypto" />
          ) : (
            <IconsPayoutMethods.BitcoinIconDisabled className="iconCrypto" />
          )}
        </div>
        <div>
          <Title isActive={isActive}>{t('cryptocurrency')}</Title>
          {isActive ? (
            <Container>
              <span>{t('commission')}</span>
              {user.email.status !== 'confirmed' && (
                <>
                  <span>{t('Email is required')}</span>
                  <IconsPayoutMethods.EmailIcon className="iconEmail" />
                </>
              )}
            </Container>
          ) : (
            <span className="badge-light">
              {t('from')} <CurrencyText value={minCoinsForCrypto} />
            </span>
          )}
        </div>
      </StyledNavigationLink>
    </>
  );
});

//TODO: add loader
