export default {
  Language: 'Limbă',
  'Bets in current room': 'Pariere în camera actuală',
  Timers: 'Cronometre',
  'Game start': 'Jocul începe',
  'Game ending': 'Jocul se termină',
  'Roulette ticking': 'Ruleta se învârte',
  'Roulette start': 'Start ruletă',
  'Item set': 'Item set',
  'My bet': 'Pariul meu',
  'Coins transfer': 'Transfer monede',
  'Start button': 'Butonul de pornire',
  'Stop button': 'Butonul de oprire',
  Countdown: 'Numărătoare inversă',
  Soundtrack: 'Coloană sonoră',
  Crash: 'Eroare',
  'Picking item': 'Alegere obiecte',
  Crafting: 'Creare',
  Failure: 'Eșec',
  Win: 'Premiu',
  Sell: 'Vindeți',
  Take: 'Take',
  'Rooms bets': 'Pariuri camere',
  'Chat message sending': 'Mesaj chat care se trimite',
  'Chat message receiving': 'Mesaj chat care sosește',
  Sound: 'Sunet',
  'Chat sound': 'Sunet chat',
  'Game sounds': 'Sunet joc',
  Volume: 'Volum',
  steamApi: {
    noKey: 'FĂRĂ CHEIE API STEAM',
    need: 'Necesară pentru vânzarea și tranzacționarea skinurilor cu alți jucători',
    addLink: 'ADAUGĂ LINK',
    link: 'UNDE SĂ OBȚII?',
  },
};
