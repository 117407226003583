import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ITransactionsItem } from '../../../interfaces/personalArea.reducer.interfaces';
import { getItemType } from '../../../personalArea.utils';
import { CurrencyText } from '@components/currency';
import { ModuleName } from 'modules/Lobby/components';
import { GamesIcons } from 'assets';
import { TransactionsIcons } from '../assets';
import {
  Container,
  Header,
  HeaderIcon,
  HeaderPreview,
  HeaderPrice,
  TransactionDate,
  TransactionName,
  TransactionNumber,
} from './TransactionItem.styles';

interface ITransactionItem {
  transaction: ITransactionsItem;
}

export const TransactionItem: FC<ITransactionItem> = ({ transaction }) => {
  const parseData = getItemType(transaction);

  type ModuleTypes =
    | ModuleName
    | 'cashback'
    | 'deposit'
    | 'payment'
    | 'free coins'
    | 'level up'
    | 'referral'
    | 'skins'
    | 'skins withdrawal'
    | 'withdrawal';

  const icons = {
    ...GamesIcons,
    ...TransactionsIcons,
  };
  const icon = icons[parseData?.name?.toLowerCase() as ModuleTypes]?.();

  if (!parseData) return null;

  return (
    <Container>
      <Header>
        {icon && <HeaderIcon>{icon}</HeaderIcon>}
        <HeaderPreview>
          <TransactionName>
            {parseData.name}{' '}
            {transaction.payload?.gameId && (
              <TransactionNumber>#{transaction.payload.gameId}</TransactionNumber>
            )}
          </TransactionName>
          <TransactionDate>
            {dayjs(transaction.createdAt).format('DD.MM.YYYY, HH:mm')}
          </TransactionDate>
        </HeaderPreview>
        <HeaderPrice hasProfit={transaction.amount > 0}>
          <CurrencyText value={transaction.amount} />
        </HeaderPrice>
      </Header>
    </Container>
  );
};
