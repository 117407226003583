import React, { FC } from 'react';
import type { SwitchTypeRenderProp } from '../orders-switch-type';
import { OrdersPurchaseTab } from './orders-purchase-tab';
import { OrdersSaleTab } from './orders-sale-tab';
import { OrderType } from '../orders-switch-type/orders-switch-type';

interface IOrdersTabs extends SwitchTypeRenderProp {}

export const OrdersTabs: FC<IOrdersTabs> = ({ type }) => (
  <>
    {type === OrderType.BUY && <OrdersPurchaseTab />}
    {type === OrderType.SELL && <OrdersSaleTab />}
  </>
);
