import { SortParam } from 'games/CaseBattle/constants';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import { ActionSetSortHistoryParam } from './sort-history-page.interfaces';

export const actionSetSortHistoryParam = (sortParam: SortParam) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_SET_SORT_HISTORY_PARAM,
  payload: sortParam,
});

export const sortHistoryPageReducer = (
  state: SortParam = SortParam.newest,
  action: ActionSetSortHistoryParam
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_SET_SORT_HISTORY_PARAM: {
      return action.payload;
    }
    default:
      return state;
  }
};
