import React, { ComponentClass, FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { animated, Transition } from 'react-spring/renderprops';

export interface ICollapseHeader {
  collapsible: boolean;
  handleCollapseToggle: () => void;
}

interface ICollapse {
  header: FC<ICollapseHeader> | ComponentClass<ICollapseHeader>;
}

export const Collapse: FC<ICollapse> = ({ header, children }) => {
  const [collapsible, setCollapsible] = useState(false);

  const handleCollapseToggle = useCallback(() => {
    setCollapsible(state => !state);
  }, []);

  return (
    <div className="collapse">
      {React.createElement(header, { collapsible, handleCollapseToggle })}
      <Transition
        items={collapsible}
        from={{ height: 0, opacity: 0 }}
        enter={{ height: 'auto', opacity: 1 }}
        leave={{ height: 0, opacity: 0 }}
      >
        {collapsible => props => collapsible && <AnimatedDiv style={props}>{children}</AnimatedDiv>}
      </Transition>
    </div>
  );
};

const AnimatedDiv = styled(animated.div)`
  overflow: hidden;
  will-change: height;
`;
