import { combineReducers, Reducer } from 'redux';
import { OrderedMap, Record } from 'immutable';
import { addNotification } from 'modules/Notifications/duck';
import { IDispatch } from 'core/rootInterfaces/root.interface';

import {
  hisotyRaffleActionsTypes,
  historyActions,
  historyActionsTypes,
  raffleHistoryActions,
} from '../interfaces/actions.leaders.interfaces';

import {
  IRaffleHistoryStateRecord,
  ITournamnetItemType,
  ITournamnetRecord,
  ITournamnetType,
  receiveTakenAtResponse,
} from '../interfaces/reducer.leaders.interfaces';
import { ISocketService } from '../../../services/interfaces/services.interface';

const TournamentHistoryRecord = Record({
  id: null,
  raceType: '',
  prizeCoinsAmount: null,
  prizeTicketsAmount: null,
  startAt: '',
  finishAt: '',
  leaderboard: [],
  myStats: null,
});

const TournamentTypeRecord = Record<ITournamnetType>({
  loading: false,
  loaded: false,
  entities: [],
});

const TournamentsRecord = Record({
  regular: new TournamentTypeRecord(),
  fast: new TournamentTypeRecord(),
  great: new TournamentTypeRecord(),
});

const tournaments: Reducer<ITournamnetRecord, historyActions> = (
  state = new TournamentsRecord(),
  action
) => {
  switch (action.type) {
    case historyActionsTypes.FETCH_All_HISTORY_START:
      return state.setIn([action.payload.raceType, 'loading'], true);

    case historyActionsTypes.RECEIVE_HISTORY_SUCCESS:
      return state
        .setIn([action.payload.raceType, 'loading'], false)
        .setIn([action.payload.raceType, 'loaded'], true)
        .setIn(
          [action.payload.raceType, 'entities'],
          action.payload.history.map((i: any) => new TournamentHistoryRecord(i))
        );

    case historyActionsTypes.UPDATE_HISTORY_BY_ID:
      const orignHistory = state.getIn([action.payload.raceType, 'entities']);
      const index = orignHistory.findIndex(
        (story: Record<ITournamnetItemType>) => story.get('id') === action.payload.id
      );
      return state.mergeIn([action.payload.raceType, 'entities', index], action.payload.tournament);

    default:
      return state;
  }
};

export const fetchRacesHistory = (raceType: string) => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  try {
    dispatch(fetchHistoryStart(raceType));
    const response = await fetch(
      `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/races?raceType=${raceType}`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      }
    );
    const data = await response.json();

    if ('error' in data) throw new Error(data.error);

    dispatch(updateTournamentsHistory(raceType, data));
  } catch (e) {
    console.log(e);
  }
};

export const fetchTournamentHistoryById = (raceType: string, id: number) => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  const response = await fetch(
    `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/races/${id}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    }
  );

  const data = await response.json();

  if ('error' in data) throw new Error(data.error);

  dispatch(updateTournamentsHistoryById(raceType, id, data));
};

const fetchHistoryStart = (raceType: string) => ({
  type: historyActionsTypes.FETCH_All_HISTORY_START,
  payload: {
    raceType,
  },
});

const updateTournamentsHistory = (raceType: string, history: any[]) => ({
  type: historyActionsTypes.RECEIVE_HISTORY_SUCCESS,
  payload: {
    raceType,
    history,
  },
});

const updateTournamentsHistoryById = (
  raceType: string,
  id: number,
  tournament: ITournamnetItemType
) => ({
  type: historyActionsTypes.UPDATE_HISTORY_BY_ID,
  payload: {
    raceType,
    id,
    tournament,
  },
});

interface IRacesStoryRecord {
  id: number;
  type: string;
  startAt: string;
  finishAt: string;
  isActive: boolean;
  items?: any[];
}

const RacesStoryRecord = Record<IRacesStoryRecord>({
  id: 0,
  type: '',
  startAt: '',
  finishAt: '',
  isActive: false,
  items: [],
});

const RaffleHistoryState = Record<IRaffleHistoryStateRecord>({
  loading: false,
  loaded: false,
  entities: OrderedMap(),
});

const raffles: Reducer<Record<IRaffleHistoryStateRecord>, raffleHistoryActions> = (
  state = new RaffleHistoryState(),
  action
) => {
  switch (action.type) {
    case hisotyRaffleActionsTypes.FETCH_ALL_RAFFLE_HISTORY_START:
      return state.setIn(['loading'], true).setIn(['loaded'], false);

    case hisotyRaffleActionsTypes.RECEIVE_RAFFLE_HISTORY_SUCCESS:
      const { history } = action.payload;
      return state
        .setIn(['loading'], false)
        .setIn(['loaded'], true)
        .updateIn(['entities'], (entities: OrderedMap<string, IRacesStoryRecord>) =>
          history.reduce(
            (acc: OrderedMap<string, IRacesStoryRecord>, story: IRacesStoryRecord) =>
              acc.setIn([story.id.toString()], new RacesStoryRecord(story)),
            entities
          )
        );

    case hisotyRaffleActionsTypes.RECEIVE_RAFFLE_HISTORY_WITH_ITEMS_SUCCESS:
      const formatHistory = action.payload.items.map(({ feed, ...rest }: any) => {
        const formatMember = rest.members.reduce((acc: any, member: any) => {
          acc[member.id.toString()] = member;
          return acc;
        }, {});
        return {
          ...rest,
          members: formatMember,
          membersIds: Object.keys(formatMember),
        };
      });
      return state.setIn(['entities', action.payload.raffleId.toString(), 'items'], formatHistory);

    case hisotyRaffleActionsTypes.RECEIVE_TAKEN_HISTORY_LOT_SUCCESS:
      const { raffleId, id, takenAt } = action.payload.lot;
      const orignRaffle = state.getIn(['entities', raffleId.toString()]);
      const pathIndex = orignRaffle.items.findIndex((item: any) => item.id === id);
      return state.hasIn(['entities', raffleId.toString(), 'items', pathIndex])
        ? state.setIn(['entities', raffleId.toString(), 'items', pathIndex, 'takenAt'], takenAt)
        : state;

    default:
      return state;
  }
};

export const fetchRafflesHistory = () => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  try {
    await dispatch(fetchRaffleHistoryInit());
    const response = await fetch(
      `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/raffles?isActive=false&sortBy=-finishAt`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      }
    );

    const data = await response.json();

    if ('error' in data) throw new Error(data.error);

    dispatch(receiveRaffleHistory(data.slice(0, 30)));

    for (const story in data.slice(0, 30)) {
      if (data[story].id) dispatch(fetchRafflesHistoryById(data[story].id));
    }
  } catch (e) {
    console.log(e);
  }
};

const fetchRafflesHistoryById = (id: number) => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  try {
    const response = await fetch(
      `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/raffles/${id}/items?extend=members&sortBy=-coinsAmount`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      }
    );
    const data = await response.json();
    if ('error' in data) throw new Error(data.error);

    dispatch(receiveRaffleHistoryWithItems(id, data));
  } catch (e) {
    console.log(e);
  }
};

export const fetchTakeWinnerLotWithHistory = (raffleId: number, itemId: number) => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  const response = await fetch(
    `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/raffles/${raffleId}/items/${itemId}/take`,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    }
  );

  const date = await response.json();

  if (date.raffleId && date.id) {
    dispatch(receiveTakenLot(date));
  } else if (date.error) {
    dispatch(
      addNotification({
        type: 'error',
        header: 'Error',
        body: date.error,
      })
    );
  }
};

const fetchRaffleHistoryInit = () => ({
  type: hisotyRaffleActionsTypes.FETCH_ALL_RAFFLE_HISTORY_START,
});

const receiveRaffleHistory = (history: IRacesStoryRecord[]) => ({
  type: hisotyRaffleActionsTypes.RECEIVE_RAFFLE_HISTORY_SUCCESS,
  payload: {
    history,
  },
});

const receiveRaffleHistoryWithItems = (raffleId: number, items: any[]) => ({
  type: hisotyRaffleActionsTypes.RECEIVE_RAFFLE_HISTORY_WITH_ITEMS_SUCCESS,
  payload: {
    raffleId,
    items,
  },
});

const receiveTakenLot = (lot: receiveTakenAtResponse) => ({
  type: hisotyRaffleActionsTypes.RECEIVE_TAKEN_HISTORY_LOT_SUCCESS,
  payload: {
    lot,
  },
});

export const history = combineReducers({
  tournaments,
  raffles,
});
