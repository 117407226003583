import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Application } from 'core/AppShell/containers';
import './assets/css/reset.css';
import './assets/css/main.css';
import './assets/css/icomoon.css';
import { unregister } from './registerServiceWorker';
import SoundService from './services/sound.service';

unregister();
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(relativeTime);
new SoundService();

ReactDOM.render(<Application />, document.getElementById('root'));
