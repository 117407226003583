import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IFreeSpinsLeft {
  count: number;
}

export const FreeSpinsLeft: FC<IFreeSpinsLeft> = ({ count }) => {
  const { t } = useTranslation();
  return <Container>{t('Bonus game xN', { count })}</Container>;
};

const Container = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--color-white);
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
