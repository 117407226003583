import React, { MouseEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';
import { Navigation, StyledNavigationLink } from './SettingsNavigate.styles';
import { SvgIcons as CaseGameIcons } from 'games/CaseGame/assets';
import { SvgIcons } from '../assets';
import { useUserProviders } from 'core/User';
import { authProviderConfig } from 'core/Auth';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';

export const SettingsNavigate = () => {
  const { t } = useTranslation();
  const { userProviders } = useUserProviders();
  const hasSteam = userProviders['steam'];
  const connectedSocial = Object.keys(userProviders).length;
  const allSocial = authProviderConfig.length;
  const { activeDialogs, handleToggleDialog, handleCloseByName } = useDialog();

  useEffect(() => {
    return () => {
      activeDialogs.forEach(item => handleCloseByName(item));
    };
  }, [activeDialogs, handleCloseByName]);

  const handleOpenLinkSteam = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    handleToggleDialog(userModals.STEAM_LINK_ACCOUNT, true)();
  };

  return (
    <Navigation>
      <StyledNavigationLink
        to="/personal/settings/trading"
        as={Link}
        data-disable={hasSteam ? 'available' : 'disable'}
      >
        <div className="leading">
          <SvgIcons.TradingIcon />
        </div>
        <div>
          <span>{t('Trading')}</span>
          {!hasSteam && (
            <span className="link-steam" onClick={handleOpenLinkSteam}>
              {t('Link Steam account')}
            </span>
          )}
        </div>
      </StyledNavigationLink>
      <NavigationLink to="/personal/settings/sounds" as={Link}>
        <div className="leading">
          <CaseGameIcons.SoundIcon />
        </div>
        <div>
          <span>{t('Sounds')}</span>
        </div>
      </NavigationLink>
      <NavigationLink to="/personal/settings/privacy" as={Link}>
        <div className="leading">
          <SvgIcons.PrivacyIcon />
        </div>
        <div>
          <span>{t('Privacy')}</span>
        </div>
      </NavigationLink>
      <NavigationLink onClick={handleToggleDialog(userModals.PROFILE_SOCIAL, true)}>
        <div className="leading">
          <SvgIcons.SocialIcon />
        </div>
        <div>
          <span>{t('Add social networks')}</span>
          <span className="badge-light">
            {t('Ready soc', {
              count: connectedSocial,
              postProcess: 'with-template',
            })}{' '}
            {allSocial}
          </span>
        </div>
      </NavigationLink>
    </Navigation>
  );
};
