import * as React from 'react';
import { TournamentListItem } from './TournamentListItem';
import { useTranslation } from 'react-i18next';
import { Container, TournamentHeader, UserList } from './Styles';
import { ILeaderBoardItem } from '../../interfaces/reducer.leaders.interfaces';

interface ITournamentList {
  leaderboard: ILeaderBoardItem[];
  raceType?: string;
}

export const TournamentList: React.FC<ITournamentList> = ({ leaderboard, raceType }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TournamentHeader>
        <span>
          {t('Top {{length}}:', {
            length: leaderboard.length,
          })}
        </span>
        <span>{t('winning')}</span>
      </TournamentHeader>
      <UserList>
        {leaderboard.map((user: ILeaderBoardItem, index) => (
          <TournamentListItem key={user.userId || index} {...user} raceType={raceType} />
        ))}
      </UserList>
    </Container>
  );
};
