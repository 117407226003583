import { IStore } from 'store/interface';

export const getMarketplaceElement = (entity: 'store' | 'purchases' | 'hotOffers', id: string) => ({
  market,
}: IStore) => market[entity].getIn(['kits', 'entities', id]);

/*
 * Store selectors
 * */

export const getStoreMeta = ({ market }: IStore) => market.store.get('meta');
export const getStoreLoading = ({ market }: IStore) => market.store.get('loading');
export const getStoreLoaded = ({ market }: IStore) => market.store.get('loaded');
export const getStoreFailure = ({ market }: IStore) => market.store.get('failure');

export const getStoreIdentifiers = ({ market }: IStore) => market.store.getIn(['kits', 'ids']);

export const getStoreFilters = ({ market }: IStore) => market.filters;
export const getStorePage = ({ market }: IStore) => market.filters.getIn(['page', 'number']);

export const getStoreSelectedFilters = ({ market: { filters } }: IStore) => {
  return (
    Number(!!filters.get('exterior').length) +
    Number(!!filters.get('type').length) +
    Number(!!filters.get('weapon').length) +
    Number(!!filters.get('phase').length) +
    (Number(filters.get('overprice').minOverprice !== null) ||
      Number(filters.get('overprice').maxOverprice !== null)) +
    Number(filters.get('minPrice') !== null && filters.get('maxPrice') !== null)
  );
};

export const getStoreDetailed = <T, K>(path: K) => ({ market }: IStore): T =>
  market.filters.get<any>(path);

/*
 * Hot-offers selectors
 * */

export const getHotOffersIdentifiers = ({ market }: IStore) =>
  market.hotOffers.getIn(['kits', 'ids']);

export const getHotOffersElement = (id: string) => ({ market }: IStore) =>
  market.hotOffers.getIn(['kits', 'entities', id]);

/*
 * Purchase selectors
 * */

export const getPurchaseMetaData = ({ market }: IStore) => market.purchases.get('meta');

export const getPurchaseComputed = ({ market }: IStore) => market.purchases.get('computed');

export const getPurchaseIdentifiers = ({ market }: IStore) =>
  market.purchases.getIn(['kits', 'ids']);

/*
 * Inventory selectors
 * */
export const getInventoryLoading = ({ market }: IStore) => market.inventory.get('loading');
export const getInventoryLoaded = ({ market }: IStore) => market.inventory.get('loaded');
export const getInventoryFailure = ({ market }: IStore) => market.inventory.get('failure');
export const getInventoryMetaData = ({ market }: IStore) => market.inventory.get('meta');
export const getInventoryFilters = ({ market }: IStore) => market.inventory.get('filters');
export const getInventoryIdentifiers = ({ market }: IStore) =>
  market.inventory.getIn(['kits', 'identifiers']);
export const getInventoryElement = (id: string) => ({ market }: IStore) =>
  market.inventory.getIn(['kits', 'entities', id]);
export const getErrorText = ({ market }: IStore) => market.inventory.get('errorText');

/*
 * Sales selectors
 * */
export const getSalesLoading = ({ market }: IStore) => market.sales.get('loading');
export const getSalesLoaded = ({ market }: IStore) => market.sales.get('loaded');
export const getSalesFailure = ({ market }: IStore) => market.sales.get('failure');
export const getSalesElement = (id: string) => ({ market }: IStore) =>
  market.sales.getIn(['entities', id]);

export const getSalesIsContain = (id: string) => ({ market }: IStore) =>
  market.sales.getIn(['selected', 'identifiers']).includes(id);
export const getSalesSelectedIdentifiers = ({ market }: IStore) => market.sales.getIn(['selected']);

export const getSellingComputed = ({ market }: IStore) =>
  market.sales.getIn(['selling', 'computed']);
export const getSellingIdentifiers = ({ market }: IStore) =>
  market.sales.getIn(['selling', 'identifiers']);

export const getSteamStatus = ({ market }: IStore) => market.steam;
