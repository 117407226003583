import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '../../../@components/animated-router';
import { GamePage, RulesPage } from './Pages';
import { LotteryPage } from '../../../@components/Histories';

export const GameRouter: React.FC<RouteComponentProps> = ({ match }) => (
  <div className="content">
    <AnimatedRouter>
      <Route path={`${match.url}/rules`} component={RulesPage} />
      <Route path={`${match.url}/lottery`} component={LotteryPage} />
      <Route path={match.url} component={GamePage} />
    </AnimatedRouter>
  </div>
);
