import * as React from 'react';

import { HistoryItem } from './HistoryItem';
import { HistorySwitch } from './HistorySwitch';
import { IUserState } from 'core/User/interfaces/user.reducer.interface';

export enum filterableSwitch {
  recent = 'recent',
  own = 'own',
}

interface ISlotsHistory {
  history: [];
  handleSwitch: () => void;
  filterable: filterableSwitch.own | filterableSwitch.recent;
  user: IUserState;
  content: (props: React.ComponentProps<typeof HistoryItem>) => JSX.Element;
}

export const SlotsHistory: React.FC<ISlotsHistory> = ({
  handleSwitch,
  filterable,
  user,
  history,
  content,
}) => {
  return (
    <>
      <div className="content light-layout">
        {history.map((item, i) => (
          <HistoryItem key={(i + filterable).toString()} {...item} content={content} />
        ))}
      </div>
      <HistorySwitch handleSwitch={handleSwitch} filterable={filterable} user={user} />
    </>
  );
};
