import React from 'react';
import { ReactComponent as ClassicIcon } from './classic.svg';
import { ReactComponent as CasesIcon } from './cases.svg';
import { ReactComponent as BaccaratIcon } from './baccarat.svg';
import { ReactComponent as CrashIcon } from './crash.svg';
import { ReactComponent as DoubleIcon } from './double.svg';
import { ReactComponent as FastIcon } from './fast.svg';
import { ReactComponent as HiloIcon } from './hilo.svg';
import { ReactComponent as PoggiIcon } from './poggi.svg';
import { ReactComponent as SlotIcon } from './slot.svg';
import { ReactComponent as TowerIcon } from './tower.svg';
import { ReactComponent as WheelIcon } from './wheel.svg';
import { ReactComponent as X50Icon } from './x50.svg';
import { ReactComponent as BattlesIcon } from './battles.svg';

export const GamesIcons = {
  classic: (width: number = 44, height: number = 44) => (
    <ClassicIcon width={width} height={height} />
  ),
  cases: (width: number = 44, height: number = 44) => <CasesIcon width={width} height={height} />,
  baccarat: (width: number = 44, height: number = 44) => (
    <BaccaratIcon width={width} height={height} />
  ),
  crash: (width: number = 44, height: number = 44) => <CrashIcon width={width} height={height} />,
  double: (width: number = 44, height: number = 44) => <DoubleIcon width={width} height={height} />,
  fast: (width: number = 44, height: number = 44) => <FastIcon width={width} height={height} />,
  hilo: (width: number = 44, height: number = 44) => <HiloIcon width={width} height={height} />,
  poggi: (width: number = 44, height: number = 44) => <PoggiIcon width={width} height={height} />,
  slot: (width: number = 44, height: number = 44) => <SlotIcon width={width} height={height} />,
  tower: (width: number = 44, height: number = 44) => <TowerIcon width={width} height={height} />,
  wheel: (width: number = 44, height: number = 44) => <WheelIcon width={width} height={height} />,
  x50: (width: number = 44, height: number = 44) => <X50Icon width={width} height={height} />,
  caseBattle: (width: number = 44, height: number = 44) => (
    <BattlesIcon width={width} height={height} />
  ),
  battles: (width: number = 44, height: number = 44) => (
    <BattlesIcon width={width} height={height} />
  ),
  'case battle': (width: number = 44, height: number = 44) => (
    <BattlesIcon width={width} height={height} />
  ),
};
