import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { IDialogComponent } from 'core/interfaces';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '@UIkit';
import { ReferralValidators } from 'core/utils/Validators';
import { Button } from '@components/Common';
import { socketService } from 'services/socket.service';
import { useReferralCampaign } from '../../../../hooks';
import { patchReferralCampaignSuccess } from '../../../../ducks/referral.duck';

interface IDialogData {
  id: number;
}

interface ICustomizationReferralCampaignDialog extends IDialogComponent<IDialogData> {}

export const CustomizationReferralCampaignDialog: FC<ICustomizationReferralCampaignDialog> = ({
  handleCloseModal,
  data: { id },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { campaign } = useReferralCampaign(id.toString());

  const nameControls = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: campaign.name,
    },
    shouldFocusError: false,
    criteriaMode: 'firstError',
  });

  const codeControls = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      code: campaign.code,
    },
    shouldFocusError: false,
    criteriaMode: 'firstError',
  });

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const req = await fetch(
          `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}/api/referral-campaigns/${id}`,
          {
            method: 'PATCH',
            credentials: 'include',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          }
        );

        const res = await req.json();
        dispatch(patchReferralCampaignSuccess(res));
      } catch (e) {
        console.log(e);
      }
    },
    [id, dispatch]
  );

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <div className="dialog-header">
        <span>{t('Manage campaign')}</span>
        <span>{campaign.name}</span>
      </div>
      <form className="customization-campaign-name" onBlur={nameControls.handleSubmit(onSubmit)}>
        <label>{t('Campaign name')}</label>
        <SearchInput
          name="name"
          autoComplete="off"
          className={nameControls.errors?.name?.type && 'error'}
          ref={nameControls.register({
            minLength: 3,
            required: true,
          })}
        />
        {nameControls.errors?.name?.type && (
          <span className="invalid-feedback">
            {t(`invalid-feedback.${nameControls.errors?.name?.type}`)}
          </span>
        )}
      </form>
      <form className="customization-campaign-code" onBlur={codeControls.handleSubmit(onSubmit)}>
        <label>{t('Campaign promocode')}</label>
        <SearchInput
          name="code"
          autoComplete="off"
          className={codeControls.errors?.code?.type && 'error'}
          ref={codeControls.register({
            required: true,
            validate: {
              checkCode: async (code: string) => await ReferralValidators.checkCode(code),
            },
          })}
        />
        {codeControls.errors?.code?.type && (
          <span className="invalid-feedback">
            {t(`invalid-feedback.${codeControls.errors?.code?.type}`)}
          </span>
        )}
      </form>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr repeat(3, min-content);
  grid-gap: 1rem;
  min-height: 21.875rem;
  border-top: 1px solid var(--color-gray-900);
  background: var(--color-dark-600);
  position: relative;

  & .close {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }

  & .dialog-header {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.25rem;
    justify-self: center;
    align-self: self-end;
    padding-bottom: 0.375rem;

    & span {
      color: var(--color-white);
      line-height: 1.5rem;
      text-align: center;

      &:first-of-type {
        font-size: 1.25rem;
      }

      &:last-of-type {
        font-size: 0.875rem;
        line-height: 25px;
      }
    }
  }

  & .customization-campaign-name,
  & .customization-campaign-code {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.75rem;
    padding: 0.5rem 1rem;
    position: relative;

    & label {
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
    }

    & .invalid-feedback {
      position: absolute;
      bottom: -0.875rem;
      left: 1.125rem;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      color: var(--color-red-100);
    }
  }

  & .customization-campaign-code {
    margin-bottom: 0.5rem;
  }

  & .customization-controller {
    background: #131a1d;
    padding: 1.75rem 1rem 1.75rem;
    border-top: 1px solid var(--color-gray-900);
    border-bottom: 1px solid var(--color-gray-900);
  }
`;
