import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { useComboGame, useCurrentBonusGame } from '../../../hooks';
import { ISlotComboLine } from '../../../interfaces';

interface IFreeSpinsPopup {
  display: number | null;
}

export const FreeSpinsPopup: FC<IFreeSpinsPopup> = ({ display }) => {
  const { t } = useTranslation();
  const { currentBonusGame } = useCurrentBonusGame();
  const { combo } = useComboGame();

  const bonuses = useMemo<number>(() => {
    return combo.reduce((acc: number, i: ISlotComboLine) => (!!i?.bonus ? acc + 10 : acc), 0);
  }, [combo]);

  const isVisible = combo[display]?.bonus && !!bonuses;

  const transition = useTransition(isVisible, null, {
    from: {
      transform: 'translate3d(-50%, -50%, 0) scale(0.6)',
      opacity: 0,
      background: 'rgba(19, 26, 29, 0)',
    },
    enter: {
      transform: 'translate3d(-50%, -50%, 0) scale(1)',
      opacity: 1,
      background: 'rgba(19, 26, 29, 0.7)',
    },
    leave: {
      transform: 'translate3d(-50%, -50%, 0) scale(0.9)',
      opacity: 0,
      background: 'rgba(19, 26, 29, 0)',
    },
    delay: !!isVisible ? 0 : 3000,
    config: {
      mass: 1,
      tension: 500,
      friction: 30,
    },
  });

  return (
    <>
      {transition.map(({ item, props: { background, ...props }, key }) => {
        return (
          item && (
            <Container key={key} style={{ background }}>
              <animated.div className="circle" style={{ ...props }}>
                <span>{t('You won')}</span>
                <span>
                  {!!currentBonusGame && '+'}
                  {bonuses}
                </span>
                <span>{t('bonus games')}</span>
              </animated.div>
            </Container>
          )
        );
      })}
    </>
  );
};

const Container = styled(animated.div)`
  position: absolute;
  top: -1rem;
  right: 0;
  bottom: -1rem;
  left: 0;
  z-index: 15;

  & .circle {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    width: 10.5rem;
    height: 10.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
    border: 4px solid var(--aqua-500);
    border-radius: 50%;

    & span {
      justify-self: center;

      &:nth-child(1),
      &:nth-child(3) {
        align-self: self-end;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--color-white);
      }

      &:nth-child(2) {
        align-self: center;
        font-weight: bold;
        font-size: 3.75rem;
        line-height: 4.375rem;
        letter-spacing: 0.54px;
        color: var(--warning);
      }

      &:nth-child(3) {
        align-self: self-start;
      }
    }
  }
`;
