import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  flex: 1 0 auto;
  gap: 4px;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(3, 1fr);
  height: 0;
  justify-content: flex-start;
  justify-content: center;
  margin-top: 16px;
  overflow: scroll;
  padding: 12px 16px;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 500px) {
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 650px) {
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
  }
`;
