import React, { PureComponent } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';

export default class SpoilerAnimate extends PureComponent {
  static defaultProps = {
    height: 20,
  };

  constructor() {
    super();

    this.headerHeightAnimation = new Animated.Value(0);
    this.headerOpacityAnimation = new Animated.Value(0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.startAnim(this.props.open);
    }
  }

  render() {
    const height = this.headerHeightAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, this.props.height],
    });

    const paddingVertical = this.headerHeightAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 10],
    });

    return (
      <Animated.div
        style={{
          maxHeight: height,
          paddingVertical: paddingVertical,
          overflow: 'hidden',
        }}
      >
        <Animated.div style={{ opacity: this.headerOpacityAnimation }}>
          {this.props.children}
        </Animated.div>
      </Animated.div>
    );
  }

  startAnim(open) {
    Animated.timing(this.headerHeightAnimation, {
      toValue: open ? 1 : 0,
      duration: open ? 500 : 300,
    }).start();
    Animated.timing(this.headerOpacityAnimation, {
      toValue: open ? 1 : 0,
      duration: open ? 150 : 1300,
    }).start();
  }
}
