import { Reducer } from 'redux';
import { Record } from 'immutable';
import { cashbackActions, cashbackActionTypes } from '../interfaces/cashback.actions.interface';
import { ICashback } from '../interfaces/cashback.reducer.interface';
import { IDispatch } from 'core/rootInterfaces/root.interface';
import { ISocketService } from 'services/interfaces/services.interface';

const CashbackRecord = Record<ICashback>({
  minCashbackSum: 0,
  amount: 0,
});

export const cashback: Reducer<Record<ICashback>, cashbackActions> = (
  state = new CashbackRecord(),
  action
) => {
  switch (action.type) {
    case cashbackActionTypes.RECEIVE_DATA:
      return state.merge(action.payload);

    default:
      return state;
  }
};

export const updateCashback = (data: any) => ({
  type: cashbackActionTypes.RECEIVE_DATA,
  payload: {
    ...data,
  },
});

export const fetchTakeCashback = () => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  socket.emit('account:cashback:get');
};

export const receiveChashbackStatus = (data: boolean | any) => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  if (data) {
    socket.emit('account:cashback:fetch');
  }
};
