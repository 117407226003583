import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, MyBtn, ProgressTitle } from './event-progress.styles';
import { actionRequestQuestInfo } from 'modules/event-module/comics-event/duck';
import {
  useGetCategory,
  useGetGeneralQuestInfo,
  useResizeWindow,
} from 'modules/event-module/comics-event/hooks';
import { CasesQuestStatusEnum } from 'modules/event-module/comics-event/interfaces';
import { actionPickUpPrize } from '../../../duck/default-event-duck';
import { Modules } from 'core/interfaces';
import { ProgressBar } from '../progress-bar';

interface IEventProgress {
  categoryId: number;
}

export const EventProgress: FC<IEventProgress> = memo(({ categoryId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { category, loader, progressCategory } = useGetCategory(categoryId);
  const progress = progressCategory > 100 ? 100 : progressCategory;
  const [hasProgress, setHasProgress] = useState(false);
  const [hasFreeCase, setHasFreeCase] = useState(false);
  const { progressEndAt } = useGetGeneralQuestInfo();
  const currentDate = new Date();
  const { containerRef, containerWidth } = useResizeWindow(hasProgress);
  useEffect(() => {
    if (category?.status !== CasesQuestStatusEnum.OPENED) {
      dispatch(actionRequestQuestInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (
      (category?.count - category?.progress > 0 && loader < 100) ||
      (progressCategory > loader && hasProgress)
    ) {
      setHasProgress(true);
      setHasFreeCase(false);
    } else {
      setHasProgress(false);
      setHasFreeCase(true);
    }
  }, [category, hasProgress, loader, progressCategory]);

  if (
    !category ||
    category?.status === CasesQuestStatusEnum.OPENED ||
    category?.status === CasesQuestStatusEnum.TAKEN
  )
    return null;

  const handleOpenFreeCase: Record<CasesQuestStatusEnum, () => void> = {
    [CasesQuestStatusEnum.AVAILABLE]: () => {
      dispatch(
        actionPickUpPrize({
          entityId: category.entityId,
          redirectHandler: () => {
            history.push(`..${Modules.CASES}/${category.case.id}`);
          },
        })
      );
    },
    [CasesQuestStatusEnum.OPENED]: undefined,
    [CasesQuestStatusEnum.BLOCKED]: undefined,
    [CasesQuestStatusEnum.TAKEN]: () => {
      if (category.case.id) {
        history.push(`..${Modules.CASES}/${category.case.id}`);
      }
    },
  };

  return (
    <>
      {hasProgress && new Date(progressEndAt) > currentDate && (
        <Container ref={containerRef}>
          <ProgressTitle>{t('progressBonusCase')}</ProgressTitle>
          <ProgressBar
            progressWidth={(containerWidth * progress) / 100}
            progress={progress}
            loader={loader}
            containerWidth={containerWidth}
          />
        </Container>
      )}
      {hasFreeCase && (
        <Container>
          <MyBtn onClick={handleOpenFreeCase[category.status]}>{t('openFreeCase')}</MyBtn>
        </Container>
      )}
    </>
  );
});
