export default {
  Language: 'Språk',
  'Bets in current room': 'Satsa i aktuellt rum',
  Timers: 'Timers',
  'Game start': 'Spelstart',
  'Game ending': 'Spelslut',
  'Roulette ticking': 'Roulettesnurr',
  'Roulette start': 'Roulettstart',
  'Item set': 'Item set',
  'My bet': 'Min insats',
  'Coins transfer': 'Myntöverföring',
  'Start button': 'Startknapp',
  'Stop button': 'Stoppknapp',
  Countdown: 'Nedräkning',
  Soundtrack: 'Soundtrack',
  Crash: 'Crash',
  'Picking item': 'Välj artikel',
  Crafting: 'Crafting',
  Failure: 'Misslyckande',
  Win: 'Pris',
  Sell: 'Sälj',
  Take: 'Ta',
  'Rooms bets': 'Rumssatsning',
  'Chat message sending': 'Skickar chattmeddelande',
  'Chat message receiving': 'Tar emot chattmeddelande',
  Sound: 'Ljud',
  'Chat sound': 'Chattljud',
  'Game sounds': 'Spelljud',
  Volume: 'Volym',
  steamApi: {
    noKey: 'INGEN STEAM API-NYCKEL',
    need: 'Krävs för att sälja och handla skins med andra spelare',
    addLink: 'LÄGG TILL LÄNK',
    link: 'VAR FÅR MAN DEN?',
  },
};
