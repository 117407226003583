import * as React from 'react';
import styled from 'styled-components';
import CircleProgress from './CircleProgress';
import GameProgress from './GameProgress/GameProgress';
import GamePing from './GamePing';

export default class Progress extends React.PureComponent {
  render() {
    const {
      gameRun,
      appDiffTime,
      gameRunTs,
      coef,
      crashed,
      userBet,
      ping,
      fetchPing,
      openModal,
    } = this.props;
    return (
      <ProgressContainer className="tablet">
        <GamePing ping={ping} fetchPing={fetchPing} openModal={openModal} />
        {gameRun ? (
          <GameProgress
            appDiffTime={appDiffTime}
            coef={coef}
            crashed={crashed}
            gameRun={gameRun}
            gameRunTs={gameRunTs}
            userBet={userBet}
          />
        ) : (
          <CircleProgress gameRunTs={gameRunTs} appDiffTime={appDiffTime} />
        )}
      </ProgressContainer>
    );
  }
}

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;

  position: absolute;
  left: 0;
  right: 0;
  z-index: 9998;
`;
