import { combineReducers } from 'redux';
import { sampleSize } from 'lodash';
import { getCrossGameId } from '../../core/CrossServices/selectors';
import { getFortuneWheelAllItems } from './selectors';
import { fetchExperience } from '../../core/User/ducks/duck';
import { addNotification } from '../../modules/Notifications/duck';
import { getUserBalance, getUserDetails } from '../../core/User/selectors';

// Actions

const prefix = 'app/fortune-wheel/';

const UPDATE_ALL_ITEMS = `${prefix}UPDATE_ALL_ITEMS`;
const UPDATE_WHEEL_ITEMS = `${prefix}UPDATE_WHEEL_ITEMS`;
const UPDATE_AVAILABILITY = `${prefix}UPDATE_AVAILABILITY`;
const UPDATE_RESULT = `${prefix}UPDATE_RESULT`;
const RESET_RESULT = `${prefix}RESET_RESULT`;
const TOGGLE_WINNER_MODAL = `${prefix}TOGGLE_WINNER_MODAL`;

const allItems = (state = [], action) => {
  switch (action.type) {
    case UPDATE_ALL_ITEMS: {
      return state.length ? state : action.payload;
    }
    default: {
      return state;
    }
  }
};

const wheelItems = (state = [], action) => {
  switch (action.type) {
    case UPDATE_WHEEL_ITEMS: {
      return sampleSize(action.payload, 18);
    }
    case UPDATE_RESULT: {
      const { result } = action.payload;
      if (!result) return state;
      const { itemName, itemPrice, itemImage } = result;
      const newState = [...state];
      newState[8] = { itemName, itemPrice: itemPrice * 100, itemImage };
      return newState;
    }
    default: {
      return state;
    }
  }
};

const result = (state = null, action) => {
  switch (action.type) {
    case UPDATE_RESULT: {
      const { result } = action.payload;
      if (!result) return state;
      return {
        ...result,
        canBuy: result.itemName === 'Coins' ? false : result.canBuy,
      };
    }
    case RESET_RESULT: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const winnerModal = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_WINNER_MODAL: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const availability = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AVAILABILITY: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  allItems,
  wheelItems,
  result,
  winnerModal,
  availability,
});

export default reducer;

// Action creators

export const getGame = () => (dispatch, getState, { socket }) => {
  const appId = getCrossGameId(getState());

  socket.emit('fortune-wheel:get-availability');
  socket.emit('fortune-wheel:items:fetch', { appId });
};

export const updateItems = array => dispatch => {
  const historyItems = array.slice(0, 49).map(item => ({
    ...item,
    itemPrice: item.itemPrice * 100,
  }));

  dispatch({ type: UPDATE_ALL_ITEMS, payload: historyItems });

  dispatch({ type: UPDATE_WHEEL_ITEMS, payload: historyItems });
};

export const refreshWheelItems = () => (dispatch, getState) => {
  dispatch({
    type: UPDATE_WHEEL_ITEMS,
    payload: getFortuneWheelAllItems(getState()),
  });
};

export const updateAvailability = availability => ({
  type: UPDATE_AVAILABILITY,
  payload: availability,
});

export const updateResult = result => ({
  type: UPDATE_RESULT,
  payload: {
    result,
  },
});

export const resetResult = () => ({
  type: RESET_RESULT,
});

export const getResult = () => (dispatch, getState, { socket }) => {
  const coins = getUserBalance(getState());
  const { freeSpins } = getUserDetails(getState(), 'rewards');
  const price = 100;
  if (price > coins && !freeSpins) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'Not enough money on balance',
      })
    );
  }

  const appId = getCrossGameId(getState());
  socket.emit('fortune-wheel:play', { type: 'costly', appId });
  dispatch(fetchExperience());
};

export const toggleWinnerModal = trigger => ({
  type: TOGGLE_WINNER_MODAL,
  payload: trigger,
});

export const prizeDecision = (gameId, action) => (_dispatch, _getState, { socket }) => {
  socket.emit('account:experience:fetch');
  socket.emit('fortune-wheel:decision', {
    action: action,
    gameId: gameId,
  });
};

export const eventsTypes = [
  { event: 'fortune-wheel:items:state', action: updateItems },
  { event: 'fortune-wheel:availability', action: updateAvailability },
  { event: 'fortune-wheel:result', action: updateResult },
];
