import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { getPercent } from 'core/utils/percent.util';
import { IBoard } from '../board.interfaces';
import { factors } from '../../../../interfaces';
import { useGameBoard, useGameCondition, useHighlightSector } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/hooks';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';
import { GamePhase } from '../../../../interfaces/game-phase.enum';

export const TieBoard: FC<IBoard> = ({ type }) => {
  const { t } = useTranslation('baccarat');
  const { members, totals, bet } = useGameBoard(type, type);
  const sectors = useHighlightSector();
  const phase = useGameCondition();
  const { handleToggleDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);

  return (
    <Container
      className={`${type} ${bet?.id && 'active'} ${
        sectors?.includes(type) && phase === GamePhase.TIMEOUT && 'pulse-sector'
      }`}
      onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
        module: ParticipateModule.BACCARAT,
        type,
      })}
    >
      <span>
        {bet?.amount && <CurrencyText value={bet.amount} />}
        {!bet?.amount && <>{t(type)}</>} х<span>{factors[type]}</span>
      </span>
      <span>{getPercent(totals[type], totals.total)}%</span>
      <span>
        <i className="icon-users" />
        {members ? Object.keys(members).length : 0}
      </span>
      <span>
        <CurrencyText value={totals[type] || 0} />
      </span>
    </Container>
  );
};

const Container = styled.button`
  grid-area: tie;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-content: center;
  grid-gap: 0.25rem;
  padding: 0.75rem 0.75rem 0.5rem;
  background: var(--bacarrat-tie-fill);
  border: 0.125rem solid var(--bacarrat-tie-stroke);
  border-radius: 0.5rem;
  transition: all 100ms linear;

  &:active,
  &.active {
    background: var(--bacarrat-tie-stroke);
  }

  & span {
    align-self: self-start;
    display: flex;
    align-items: center;
    color: var(--color-white);
    font-size: 0.75rem;
    white-space: nowrap;

    &:nth-child(1) {
      font-weight: bold;
      font-size: 0.9375rem;
      letter-spacing: 0.13058px;
      color: var(--color-white);

      & span {
        font-size: 1.5rem;
        line-height: 0.9375rem;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      justify-self: center;
    }

    &:last-of-type {
      justify-self: self-end;
    }

    & i {
      margin-right: 0.25rem;
      font-size: 0.875rem;
    }
  }
`;
