import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-white);
  border-radius: var(--size-radius-m);
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(2fr, 0.5fr) 1fr minmax(2fr, 0.5fr);
  grid-template-rows: 1fr;
  max-height: 44px;

  & > button {
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 0;

    & i {
      color: var(--color-gray-200);
      font-size: 1rem;

      &.icon-minus {
        font-size: 0.75rem;
      }
    }
  }

  & > span {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font-weight: 400;
    justify-content: center;
    text-align: center;
  }
`;
