import { Sort } from '@components/filters';
import { IDialogComponent } from 'core/interfaces';
import { caseBattleSortAction } from 'games/CaseBattle';
import { useGetFilterParams } from 'games/CaseBattle/hooks';
import { actionRequestCaseList } from 'games/CaseGame';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// import { Sort } from '@components/filters'

interface SortDialogProps extends IDialogComponent<boolean> {}

const sortBy = ['popular', 'price', '-price', 'name', '-name'];

export const SortAddCases: React.FC<SortDialogProps> = ({ handleCloseModal }) => {
  const { filterParams } = useGetFilterParams();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (value: string) => {
      dispatch(
        actionRequestCaseList({
          id: 'all',
          filterParams: {
            sortBy: value,
            maxPrice: filterParams.maxPrice,
            minPrice: filterParams.minPrice,
            query: filterParams.query,
            battles: true,
          },
        })
      );
      dispatch(caseBattleSortAction(value));
      handleCloseModal();
    },
    [dispatch, handleCloseModal, filterParams]
  );

  return (
    <Sort
      sortParams={sortBy}
      submitHandler={onSubmit}
      closeHandler={handleCloseModal}
      value={filterParams.sortBy}
    />
  );
};
