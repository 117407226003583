export default {
  Cart: 'Koszyk',
  Price: 'Cena',
  Type: 'Type',
  Categories: 'Kategorie',
  Rarity: 'Rzadkość',
  OK: 'OK',
  Clear: 'Wyczyść',
  Autoselect: 'Automatyczne wybieranie',
  Remove: 'Remove',
  Bet: 'Zakład',
  Min: 'Min.',
  Max: 'Maks.',
  'it.': '',
  Total: 'Suma',
  items: 'przedmiotów',
  Pistols: 'Pistolet',
  Rifles: 'Karabin',
  'Submachine guns': 'PM',
  Heavy: 'Ciężka',
  Knives: 'Nóż',
  Gloves: 'Rękawice',
  Keys: 'Klucz',
  Other: 'Inne',
  'Base Grade': 'Standardowej jakości',
  'Consumer Grade': 'Konsumenckiej jakości',
  'Industrial Grade': 'Przemysłowej jakości',
  Restricted: 'Zakazany',
  'Mil-Spec Grade': 'Wojskowej jakości',
  Classified: 'Poufny',
  'High Grade': 'Wysokiej jakości',
  Covert: 'Tajny',
  Remarkable: 'Niezwykły',
  Exotic: 'Egzotyczny',
  Contraband: 'Kontrabanda',
  Extraordinary: 'Nadzwyczajny',
  Buy: 'Kup',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Sklep służy jedynie do odbioru wygranych oraz niewykorzystanych monet. Wymiana skórek z myślą o wykorzystaniu różnicy cen między sklepami w celu osiągnięcia zysku jest surowo zabroniona. Złamanie tego zakazu skutkuje trwałym zablokowaniem konta.',
  'Your purchase will be delivered within 15 minutes.':
    'Twój zakup będzie dostarczony w ciągu 15 minut.',
  'Congratulations! You have successfully purchased': 'Gratulacje! Pomyślnie zakupiłeś',
  'Order confirmation': 'Potwierdź zamówienie',
  Filters: 'Filters',
  'By price': 'Według ceny',
  Filter: 'Filtr',
  'Not found': 'Nie znaleziono',
  'At present it is unable to find any items on your request. Try again later.':
    'Nie ma obecnie możliwości znalezienia żadnej pozycji z Twojego żądania. Prosimy spróbować później.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
