import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { Container, SoundsWrap, Title } from './sounds-dialog.styles';
import { IGameSoundsOptions, useSoundSettings } from 'core/AppShell';
import { SoundOptionDialog } from '../sound-option-dialog/sound-option-dialog';

interface ISoundsDialog extends IDialogComponent<{ game: keyof IGameSoundsOptions }> {}

export const SoundsDialog: FC<ISoundsDialog> = ({ handleCloseModal, data: { game } }) => {
  const { t } = useTranslation();
  const { soundSettings } = useSoundSettings();
  const [selected, setSelected] = useState('');

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>{game}</Title>
      <SoundsWrap>
        {Object.entries(soundSettings.gameSounds.options[game].options).map(([option, value]) => (
          <SoundOptionDialog
            game={game}
            key={option}
            option={option}
            value={value}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </SoundsWrap>
    </Container>
  );
};
