import { useSelector } from 'react-redux';
import { getPayoutGates } from '../selectors';
import { useParams } from 'react-router';
import { IStore } from 'store/interface';
import { IWithdrawalGate } from '../interfaces/reducers.withdrawal.interface';

export const usePayoutGates = () => {
  const { method } = useParams();

  const gates = useSelector<IStore, IWithdrawalGate[]>(state => getPayoutGates(state, method));

  return {
    gates,
  };
};
