import React, { useEffect } from 'react';
import { useState } from 'react';
import { Wrapper } from './range-slider.styles';

interface RangeSliderProps {
  step: number;
  minPrice: number;
  maxPrice: number;
  value: {
    minPrice: number;
    maxPrice: number;
  };
  onChange: ({ minPrice, maxPrice }: { minPrice: number; maxPrice: number }) => void;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  step,
  minPrice,
  maxPrice,
  value,
  onChange,
}) => {
  const [minValue, setMinValue] = useState(value ? value.minPrice : minPrice);
  const [maxValue, setMaxValue] = useState(value ? value.maxPrice : maxPrice);

  useEffect(() => {
    if (value) {
      setMinValue(value.minPrice);
      setMaxValue(value.maxPrice);
    }
  }, [value]);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value);
    if (!value) setMinValue(newMinVal);
    onChange({ minPrice: newMinVal, maxPrice: maxValue });
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value);
    if (!value) setMaxValue(newMaxVal);
    onChange({ minPrice: minValue, maxPrice: newMaxVal });
  };
  const minPos = ((Math.min(minValue, maxValue) - minPrice) / (maxPrice - minPrice)) * 100;
  const maxPos = ((Math.max(minValue, maxValue) - minPrice) / (maxPrice - minPrice)) * 100;
  return (
    <Wrapper>
      <div className="input-wrapper">
        <input
          className="input"
          type="range"
          value={minValue}
          min={minPrice}
          max={maxPrice}
          step={step}
          onChange={handleMinChange}
        />
        <input
          className="input"
          type="range"
          value={maxValue}
          min={minPrice}
          max={maxPrice}
          step={step}
          onChange={handleMaxChange}
        />
      </div>

      <div className="control-wrapper">
        <div className="control" style={{ left: `${minPos}%` }}>
          <div className="control-inner">
            <div className="control-3" />
          </div>
        </div>
        <div className="rail">
          <div className="inner-rail" style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }} />
        </div>
        <div className="control" style={{ left: `${maxPos}%` }}>
          <div className="control-inner">
            <div className="control-3" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
