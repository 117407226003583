import { IFastGame, ITrade, ITrades } from './interfaces/reducer.fast.interface';
import { Record } from 'immutable';

export const userColors = ['#4891ffB3', '#e41f4fB3', '#1fb714B3'];
export const iconColors = ['#4891ff', '#e41f4f', '#1fb714'];
export const layoutColors = [
  'rgba(72, 145, 255, .2)',
  'rgba(228, 31, 79, .2)',
  'rgba(31, 183, 20, .2)',
];

export const createRaffleTape = (players: string[]): string[] => {
  return Array.from({ length: 50 }, () => players[Math.floor(players.length * Math.random())]);
};

const { width } = window.screen;
export const slotHeight = (width - 40) / 3;
export const rafflingTime = 7 * 1000;

export const formatGame = (game: any) => ({
  ...game,
  trades: Array.isArray(game.trades)
    ? game.trades.reduce((acc: ITrades, trade: ITrade) => {
        return {
          ...acc,
          [trade.playerId]: trade,
        };
      }, {})
    : {},
});

export const mergeGame = (origin: Record<IFastGame>, updater: any) => {
  const formatData = formatGame(updater);
  return Object.keys(formatData).reduce((acc: Record<IFastGame>, key: string) => {
    return key === 'trades'
      ? acc.mergeIn([key], formatData[key])
      : acc.setIn([key], formatData[key]);
  }, origin);
};
