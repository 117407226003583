import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchDeleteBid } from '../../ducks/purchases.duck';

export const useDeleteBid = () => {
  const dispatch = useDispatch();

  const deleteBid = useCallback(
    (id: number) => () => {
      dispatch(fetchDeleteBid(id));
    },
    [dispatch]
  );

  return {
    deleteBid,
  };
};
