import { SkeletonElement } from '@UIkit';
import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  width: 100%;
  height: 80px;
  padding: 2px 4px;

  &[data-index='even'] {
    background: initial;
  }

  &[data-index='odd'] {
    background: var(--color-dark-100);
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;
export const CaseItemContainer = styled.div`
  max-height: 100%;
  border-radius: 8px;
  width: 70px;
`;
export const Icon = styled.img`
  max-height: 100%;
  width: 100%;
  border-radius: 8px;
`;
export const PlayIconContainer = styled.div`
  height: 100%;
  margin: 0 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
`;
export const ItemContainer = styled.div`
  display: flex;
  gap: 9px;
  height: 100%;
  align-items: center;
  flex: 2;
`;
export const ItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  min-height: 40px;
  min-width: 53px;
`;
export const ItemIcon = styled.img`
  height: 40px;
  width: 53px;
`;
export const Line = styled.div`
  background: var(--orange-100);
  height: 40px;
  width: 1px;
`;
export const PriceNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Name = styled.span`
  color: var(--orange-200);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.24px;
  white-space: nowrap;
`;
export const Skin = styled.span`
  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.24px;
  white-space: nowrap;
`;
export const Price = styled.span`
  display: flex;
  justify-content: flex-start;
  color: var(--yellow);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.24px;
  white-space: nowrap;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  justify-content: flex-start;
  height: 100%;
  flex: 2;
  padding: 10px;
  align-items: flex-end;
  gap: 5px;
`;
export const CreatedAt = styled.div`
  color: var(--color-gray-200);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  display: flex;
  flex-direction: column;
`;
export const StyledButton = styled(Button)`
  background: transparent;
  transition: color 0.3s ease, background 0.3s ease;

  &[data-item-status='sold'] {
    background: transparent;
    color: var(--color-white);
    pointer-events: none;
  }

  &[data-item-status='taken'] {
    background: var(--yellow);
    color: var(--color-dark-600);
  }

  text-transform: uppercase;
  height: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const InventorySpanContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;
