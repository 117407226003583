import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IUserPlaces {
  myStats: any;
}

const checkValues = [
  'ticketsToNextPlace',
  'ticketsToTop100',
  'pointsToNextPlace',
  'pointsToTop100',
  'position',
];

export const UserPlaces: React.FC<IUserPlaces> = ({ myStats }) => {
  const { t } = useTranslation();
  return (
    <Container>
      {Object.keys(myStats).map((key: string) =>
        checkValues.includes(key) && myStats[key] > 0 ? (
          <div key={key}>
            <span>{t(key)}</span>
            <span>{myStats[key] ? myStats[key] : 0}</span>
          </div>
        ) : null
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  padding: 1rem;
  background-color: var(--gray-400);

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      font-size: 0.9rem;
      line-height: 1.79;
      letter-spacing: 0.5px;
      color: var(--color-white);
    }

    & > span:last-child {
      opacity: 0.5;
    }
  }
`;
