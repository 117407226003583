import { ActionsObservable, ofType } from 'redux-observable';
import { SUMMERTIME_EVENT_ACTIONS_TYPES } from '../actionTypes';
import { catchError, map, switchMap } from 'rxjs/operators';
import eventRepository from '../../repository/event.repository';
import {
  ActionRequestLeaderRaceBalance,
  ActionRequestLeaderRaceCases,
  ActionRequestLeaderRaceQuests,
  ActionTakeReward,
  BalanceLR,
  CasesLRActions,
  CasesLRState,
  LeaderRaceQuestsState,
  LRBalanceActions,
  LRBalanceReducerState,
  QuestsActions,
} from './leader-race.interfaces';
import { of } from 'rxjs';
import { addNotification } from 'modules/Notifications/duck';
import { Reducer } from 'react';
import {
  CaseLRInfo,
  EventResponse,
  LRBalanceResponse,
  LRQuest,
  QuestType,
} from '../../interfaces/leader-race.interfaces';
import { IEventsTypes } from 'core/rootInterfaces/root.interface';

export const actionRequestLeaderRaceQuests = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_QUESTS,
});

export const actionRequestLeaderRaceQuestsLoading = (isLoading: boolean) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_QUESTS_LOADING,
  payload: isLoading,
});

export const eventActionLRQuestUpdate = (data: EventResponse) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.EVENT_ACTION_LR_QUEST,
  payload: data,
});

export const actionResponseLeaderQuests = (data: LRQuest[]) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_QUESTS,
  payload: data,
});

export const actionRequestLeaderRaceBalance = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_BALANCE,
});

export const actionRequestLeaderRaceBalanceLoading = (isLoading: boolean) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_BALANCE_LOADING,
  payload: isLoading,
});

const actionResponseLeaderRaceBalance = (data: LRBalanceResponse) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_BALANCE,
  payload: data,
});

export const actionTakeReward = (questId: number) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_TAKE_REWARD,
  payload: questId,
});

export const actionRequestLeaderRaceCases = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_CASES,
});

export const actionResponseLeaderRaceCases = (data: CaseLRInfo[]) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_CASES,
  payload: data,
});

export const leaderRaceCasesEpic = (action$: ActionsObservable<ActionRequestLeaderRaceCases>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_CASES),
    switchMap(() =>
      eventRepository.fetchLeaderRaceCases().pipe(
        map(({ response }: { response: CaseLRInfo[] }) => actionResponseLeaderRaceCases(response)),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

export const takeRewardEpic = (action$: ActionsObservable<ActionTakeReward>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_TAKE_REWARD),
    switchMap(({ payload }) =>
      eventRepository.takeReward(payload).pipe(
        map(() => actionRequestLeaderRaceQuests()),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

export const leaderRaceQuestsEpic = (action$: ActionsObservable<ActionRequestLeaderRaceQuests>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_QUESTS),
    switchMap(() =>
      eventRepository.getLRQuests().pipe(
        map(({ response }: { response: LRQuest[] }) => actionResponseLeaderQuests(response)),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

export const leaderRaceBalance = (action$: ActionsObservable<ActionRequestLeaderRaceBalance>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_BALANCE),
    switchMap(() =>
      eventRepository.getBalanceTickets().pipe(
        map(({ response }: { response: LRBalanceResponse }) =>
          actionResponseLeaderRaceBalance(response)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

const initStateLRBalance: LRBalanceReducerState = {
  isLoading: true,
  data: {
    daily: 0,
    weekly: 0,
    special: 0,
  },
};

export const leaderRaceBalanceReducer: Reducer<LRBalanceReducerState, LRBalanceActions> = (
  state = initStateLRBalance,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_BALANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_BALANCE_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_BALANCE: {
      const balance: BalanceLR = {
        daily: action.payload.orangeKeys,
        weekly: action.payload.greenKeys,
        special: action.payload.blueKeys,
      };
      return {
        data: balance,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initLeaderRaceQuestsState: LeaderRaceQuestsState = {
  data: {
    daily: [],
    weekly: [],
  },
  isLoading: true,
  isLoaded: false,
};

export const leaderRaceQuestsReducer: Reducer<LeaderRaceQuestsState, QuestsActions> = (
  state = initLeaderRaceQuestsState,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_QUESTS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_QUESTS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
        isLoaded: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_QUESTS: {
      const result: Record<QuestType, LRQuest[]> = action.payload.reduce(
        (acc: Record<QuestType, LRQuest[]>, curr: LRQuest) => {
          acc?.[curr.type]?.push(curr);
          return acc;
        },
        {
          daily: [],
          weekly: [],
        }
      );

      return {
        data: {
          daily: result.daily,
          weekly: result.weekly.sort((a, b) => a.priority - b.priority),
        },
        isLoading: false,
        isLoaded: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.EVENT_ACTION_LR_QUEST: {
      const questsArray = [...state.data.daily, ...state.data.weekly];
      /** поиск элемента который нужна обновить */
      const questById = questsArray.find(({ id }) => id === action.payload.questId);
      //NOTE: проверка нужна на случай если стор пустой или пришло событие эвента для того что нет в списке
      if (questById) {
        /** тут переопределяем значение прогресса и если вдруг предмет получен то тоже переопределяем  */
        const updatedQuest: LRQuest = {
          ...questById,
          progress: action.payload?.userCount ? action.payload.userCount : questById.progress,
          rewardTaken: action.payload?.rewardTaken
            ? action.payload.rewardTaken
            : questById.rewardTaken,
        };

        /** избавляемся от повторных значений и проводим нормализацию данных */
        const quests = [
          ...new Map([updatedQuest, ...questsArray].map(item => [item['id'], item])).values(),
        ].reduce(
          (acc: Record<QuestType, LRQuest[]>, curr: LRQuest) => {
            acc?.[curr.type]?.push(curr);
            return acc;
          },
          {
            daily: [],
            weekly: [],
          }
        );

        return {
          ...state,
          data: {
            daily: quests.daily,
            //NOTE: cортируем на случай если выпало обновление для недельного квеста и в случае верхней нормализации порядок будет нарушен поэтому приводим его в правльный вид
            weekly: quests.weekly.sort((a, b) => (a.priority > b.priority ? 1 : -1)),
          },
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const initCasesLRState: CasesLRState = {
  data: [],
  isLoading: true,
};

export const casesLRReducer: Reducer<CasesLRState, CasesLRActions> = (
  state = initCasesLRState,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_CASES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_CASES: {
      return {
        data: action.payload,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export const eventsTypes: IEventsTypes[] = [
  {
    action: eventActionLRQuestUpdate,
    event: 'leaders-race.quest:update',
  },
];
