export default {
  '/settings': 'Inställningar',
  '/marketplace': 'Marknadsplats',
  '/balance': 'Saldo',
  '/auth': 'Aukt',
  '/notifications': 'Meddelanden',

  RULES: 'REGLER',
  Chat: 'Chatt',
  GAME: 'SPEL',
  Top: 'Toppen',
  HISTORY: 'HISTORIK',
};
