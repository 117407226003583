import { IModule } from 'core/rootInterfaces/root.interface';
import './locales';
import { reducer } from './module-reducers/reducer';
import {
  comicsHeroesEventEpic,
  comicsHeroesEventModule,
  ComicsHeroesEventRouter,
} from './comics-event';

export const eventModule: IModule = {
  ...comicsHeroesEventModule,
  reducer,
};
export * from './comics-event';
export const eventEpic = comicsHeroesEventEpic;
export const EventRouter = ComicsHeroesEventRouter;
