import { combineReducers, Reducer } from 'redux';
import { OrderedMap, Record } from 'immutable';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, concatMap, debounceTime, map, pluck, switchMap } from 'rxjs/operators';
import { formatGame, mergeGame } from './fast.utils';
import { IFastInventoryElement, MergeType, updateFastInventoryFilter } from 'core/User';
import { socketService } from 'services/socket.service';
import { dialogOff } from 'core/ducks/dialog';
import { ParticipateDialogs } from 'core/interfaces';
import { fastActions, FastActionsTypes, IUpdateGame } from './interfaces/action.fast.interfaces';
import { IFastGame, IFastGames } from './interfaces/reducer.fast.interface';

const GameRecord = Record({
  md5: null,
  number: null,
  prize: null,
  rand: null,
  tape: null,
  timeOldEnds: null,
  trades: {},
  winner: null,
});

const GamesMap = OrderedMap<IFastGames>({});

const games: Reducer<OrderedMap<string, IFastGames>, fastActions> = (
  state = GamesMap,
  action: fastActions
) => {
  switch (action.type) {
    case FastActionsTypes.UPDATE_GAME: {
      return action.payload.games.reduce((acc: OrderedMap<string, IFastGame>, game: IFastGame) => {
        return acc.hasIn([game.number])
          ? acc.updateIn([game.number], (origin: Record<IFastGame>) => mergeGame(origin, game))
          : acc.setIn([game.number], new GameRecord(formatGame(game)));
      }, state);
    }

    default:
      return state;
  }
};

export const updateGames = (gameData: IFastGame[]): IUpdateGame => ({
  type: FastActionsTypes.UPDATE_GAME,
  payload: gameData,
});

export const attachFast = () => ({
  type: FastActionsTypes.ATTACH_LISTENERS,
});

export const detachFast = () => ({
  type: FastActionsTypes.DETACH_LISTENERS,
});

export const fetchCreateParticipate = (items: Set<IFastInventoryElement>) => ({
  type: FastActionsTypes.CREATE_PARTICIPATE,
  payload: {
    items,
  },
});

const participationEpic: Epic = action$ =>
  action$.pipe(
    ofType(FastActionsTypes.CREATE_PARTICIPATE),
    debounceTime(500),
    pluck('payload', 'items'),
    map((items: Set<IFastInventoryElement>) =>
      Array.from(items).reduce(
        (acc, { id, inventoryItem: { price } }) => [...acc, { type: 'skin', id, amount: price }],
        []
      )
    ),
    switchMap(items =>
      ajax({
        url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/fast/bet`,
        method: 'POST',
        withCredentials: true,
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appId: 730, items }),
      }).pipe(
        concatMap(() => [
          dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG),
          updateFastInventoryFilter(['page', 'number'], 1, MergeType.RESET),
        ]),
        catchError(() =>
          concat([
            dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG),
            updateFastInventoryFilter(['page', 'number'], 1, MergeType.RESET),
          ])
        )
      )
    )
  );

export const fastEpics = combineEpics(participationEpic);

const reducer = combineReducers({
  games,
});

export default reducer;

export const eventsTypes = [{ event: 'rooms:fast:update', action: updateGames }];
