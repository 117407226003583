export class ShopFiltersConfig {
  static get prices(): Map<string, number[]> {
    return new Map<string, number[]>([
      ['ALL', [null, null]],
      ['0-5', [0, 500]],
      ['5-25', [500, 2500]],
      ['25-100', [2500, 10000]],
      ['100-500', [10000, 50000]],
      ['500-1000', [50000, 100000]],
      ['1000+', [100000, null]],
    ]);
  }

  static price(key: string): number[] {
    return ShopFiltersConfig.prices.get(key);
  }

  static get sorts(): Map<string, string> {
    return new Map<string, string>([
      ['price', 'ASC_PRICE'],
      ['-price', 'DESC_PRICE'],
    ]);
  }

  static sortBy(key: string): string {
    return ShopFiltersConfig.sorts.get(key);
  }
}
