import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPermission } from '../ducks/duck';
import { getUserDetails } from '../selectors';
import { IStore } from 'store/interface';
import { IPermissions } from '../interfaces';
import { useCallback } from 'react';

export const useUserPermission = () => {
  const dispatch = useDispatch();
  const permissions = useSelector<IStore, IPermissions>(state =>
    getUserDetails<'permissions'>(state, 'permissions')
  );
  const handleUpdatePermission = useCallback(() => {
    dispatch(fetchUserPermission());
  }, [dispatch]);

  return {
    permissions,
    handleUpdatePermission,
  };
};
