import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { PluralizeTimer } from '@components/TimerEvent';
import { SteamErrorsEnum, useUserPermission } from 'core/User';
import { IDialogComponent } from 'core/interfaces';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { useCreatePurchase, useMarketplaceElement } from '../../../hooks';
import { BIDDING_STATUSES, OrderStatusEnum, WAITING_STATUSES } from '../../../configs';
import { URLS } from 'services/constant-urls';
import { Container } from './purchase-dialog.styles';
import { useDialog } from 'core/hooks';
import { MarketplaceDialogs } from 'modules/marketplace/marketplace-dialogs.config';
import { skinItemImage } from 'core/utils/skinItemImage.utils';
import { skinStickerImage } from 'core/utils/skinStickerImage.utils';

interface IPurchaseDialog extends IDialogComponent<['store' | 'purchases' | 'hotOffers', string]> {}

export const PurchaseDialog: FC<IPurchaseDialog> = ({ data, handleCloseModal }) => {
  const [entity, id] = data;
  const { t } = useTranslation('marketplace');
  const { item } = useMarketplaceElement(entity, id);
  const { createPurchase } = useCreatePurchase();
  const { handleCloseDialog } = useDialog(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE);

  const { permissions } = useUserPermission();

  useEffect(() => {
    if (!item) {
      handleCloseDialog();
    }
  }, [handleCloseDialog, item]);

  useEffect(() => {
    if (!item) {
      handleCloseDialog();
    }
  }, [handleCloseDialog, item]);

  const errorTypeButtons: Record<string, () => JSX.Element> = {
    'invalid tradelink': () => (
      <Button className="primary" as={Link} to="/personal/settings" onClick={handleCloseModal}>
        {t('steamErrors.addTradeLink')}
      </Button>
    ),
    'steam guard is not enabled': () => (
      <Button className="primary bid" as="a" href={URLS.mobileSteamStore} target="_blank">
        <div className="link-inner">
          <i className="icon-steam-provider" />
          <span>{t('steamErrors.addSteamGuard')}</span>
          <i className="icon-redirect-link" />
        </div>
      </Button>
    ),
    'steam trade ban': () => (
      <Button className="primary" disabled>
        {t('steamErrors.steamTradeBan')}
      </Button>
    ),
    'steam guard is in hold': () => (
      <Button className="primary" disabled>
        {t('steamErrors.steamGuardHold')}
      </Button>
    ),
    'private inventory': () => (
      <Button className="primary bid" as="a" href={URLS.steamSettings} target="_blank">
        <div className="link-inner">
          <i className="icon-steam-provider" />
          <span>{t('steamErrors.makeInventoryPublic')}</span>
          <i className="icon-redirect-link" />
        </div>
      </Button>
    ),
    'fake api key': () => (
      <Button className="primary" to="/personal/settings" as={Link} onClick={handleCloseModal}>
        {t('steamErrors.fakeAPIKey')}
      </Button>
    ),
    'fake trade link': () => (
      <Button className="primary" to="/personal/settings" as={Link} onClick={handleCloseModal}>
        {t('steamErrors.fakeTradeLink')}
      </Button>
    ),
  };

  if (!item) return null;

  return (
    <Container rarity={item.items?.[0]?.baseItem.color}>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <div className="stickers">
        {item.items?.[0]?.stickers?.map(({ icon }: { icon: string }, idx: number) => (
          <img key={idx} src={skinStickerImage(icon)} alt="" />
        ))}
      </div>
      <div className="header">{t('Place a bet')}</div>
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item.items?.[0]?.baseItem.icon, 112, 80)} 320w, ${skinItemImage(
            item.items?.[0]?.baseItem.icon,
            224,
            160
          )} 375w`}
          src={skinItemImage(item.items?.[0]?.baseItem.icon, 112, 80)}
          alt=""
        />
      </div>
      <div className="detailed">
        <div className="detailed-appearance">
          <span className="detailed-appearance-exterior">{item.items?.[0]?.baseItem.exterior}</span>
          <span
            className={`detailed-appearance-float ${getExteriorByFloat(item.items?.[0]?.float)}`}
          >
            {item.items?.[0]?.float?.toFixed(4)}
          </span>
        </div>
        <div className="detailed-display-name">{item.items?.[0]?.baseItem.shortName}</div>
        <div className="detailed-display-skin">
          {item.items?.[0]?.baseItem.skin || item.items?.[0]?.baseItem.name}
        </div>
        <div className="detailed-price">
          <CurrencyText value={item.price} />
          {item.price !== item.recommendedPrice && (
            <span className={`${item.price > item.recommendedPrice ? 'overprice' : 'underprice'}`}>
              {' ('}
              {item.price > item.recommendedPrice && '+'}
              {Math.round((item.price / item.recommendedPrice) * 100) - 100}
              {'%)'}
            </span>
          )}
        </div>
      </div>
      <div className="controllers">
        {permissions?.canTrade?.success && (
          <>
            {BIDDING_STATUSES.includes(item.status) && (
              <Button
                className="primary with-progress bid"
                onClick={() => createPurchase(item.id)}
                duration={+(Date.parse(item?.nextStatusAt) - Date.now()).toFixed(0)}
                current={dayjs(item?.statusAt).diff(Date.now())}
                finished={dayjs(item?.statusAt).diff(item?.nextStatusAt)}
                disabled={item.isLastBidByUser}
              >
                <div className="bid-inner">
                  <div>
                    {item.isLastBidByUser ? (
                      t('Your offer is the best')
                    ) : (
                      <>
                        {t('Bid')} <CurrencyText value={item.nextBidPrice} />
                      </>
                    )}
                  </div>
                  <div>
                    <PluralizeTimer finishEvent={item.nextStatusAt} /> {t('for auction to end')}
                  </div>
                </div>
              </Button>
            )}
            {WAITING_STATUSES.includes(item.status) && item.isLastBidByUser && (
              <Button
                className={`primary with-progress bid ${
                  item.status === OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT ? '' : 'disabled'
                }`}
                as="a"
                href={URLS.purchaseUrl}
                target="_blank"
                duration={+(Date.parse(item?.nextStatusAt) - Date.now()).toFixed(0)}
                current={dayjs(item?.statusAt).diff(Date.now())}
                finished={dayjs(item?.statusAt).diff(item?.nextStatusAt)}
              >
                {item.status === OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT ? (
                  <div className="link-inner">
                    <i className="icon-steam-provider" />
                    <span>{t('trade-offer.get trade')}</span>
                    <i className="icon-redirect-link" />
                  </div>
                ) : (
                  <div className="bid-inner">
                    <div>{t('purchase-statuses.wait-for-trade')}</div>
                    <div>
                      <PluralizeTimer finishEvent={item.nextStatusAt} />{' '}
                      {t('time to complete the trade')}
                    </div>
                  </div>
                )}
              </Button>
            )}
            {item.status === OrderStatusEnum.ORDER_CRATED && (
              <div className="statuses">
                <span>{t('purchase-statuses.order-crated')}</span>
              </div>
            )}
          </>
        )}
        {permissions?.error === SteamErrorsEnum.UNBINDING_STEAM && (
          <Button className="primary" as={Link} to="/personal/settings" onClick={handleCloseModal}>
            {t('Attach Steam')}
          </Button>
        )}
        {!permissions?.canTrade?.success && !permissions?.canTrade?.error && !permissions?.error && (
          <Button className="primary bid" as="a" href={URLS.steamSettings} target="_blank">
            <div className="link-inner">
              <i className="icon-steam-provider" />
              <span>{t('steamErrors.makeInventoryPublic')}</span>
              <i className="icon-redirect-link" />
            </div>
          </Button>
        )}
        {Boolean(permissions.canTrade?.error) &&
          (errorTypeButtons[permissions.canTrade?.error.toLocaleLowerCase()]() ?? (
            <Button
              className="primary"
              as={Link}
              to="/assistance#chatraChatExpanded"
              onClick={handleCloseModal}
            >
              {t('steamErrors.unexpectedError')}
            </Button>
          ))}
      </div>
    </Container>
  );
};
