import React, { FC, memo, ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { RateComboLine } from './rate-combo-line';
import { TotalComboLineProfit } from './total-combo-line-profit';
import { VisibleComboLineProfit } from './visible-combo-line-profit';
import { useBonusesGame, useComboGame, useGameTotalPrize } from '../../../../hooks';
import { FreeSpinsLeft } from './free-spins-left';

interface IComboLineOutcome {
  display: number | null;
}

export const ComboLineOutcome: FC<IComboLineOutcome> = memo(({ display }) => {
  const { combo } = useComboGame();
  const { prize } = useGameTotalPrize();
  const { gameLeft } = useBonusesGame();

  const items = useMemo<{ key: number; Component: ReactElement; top: string }[]>(() => {
    const idx = (combo.length + display) % combo.length;
    if (gameLeft > 0 && display === null) {
      return [
        {
          key: gameLeft,
          Component: <FreeSpinsLeft count={gameLeft} />,
          top: '25%',
        },
      ];
    }
    if (display === null || combo?.length === 0) {
      return [{ key: 1, Component: <RateComboLine />, top: '25%' }];
    }
    return [
      {
        key: 2,
        Component: <TotalComboLineProfit profit={prize} />,
        top: '10%',
      },
      {
        key: 3,
        Component: <VisibleComboLineProfit combo={combo[idx]} />,
        top: '40%',
      },
    ];
  }, [display, combo, prize, gameLeft]);

  const t = useTransition(items, ({ key }) => key, {
    initial: ({ top }) => ({
      transform: 'translate3d(0, 0, 0) rotateX(0deg)',
      opacity: 1,
      top,
    }),
    from: ({ top }) => ({
      transform: 'translate3d(0, 15%, 0) rotateX(15deg)',
      opacity: 0,
      top,
    }),
    update: ({ top }) => ({
      transform: 'translate3d(0, 0, 0) rotateX(0deg)',
      opacity: 1,
      top,
    }),
    leave: ({ top }) => ({
      transform: 'translate3d(0, -15%, 0) rotateX(-15deg)',
      opacity: 0,
      top,
    }),
    unique: true,
    config: { tension: 1200, friction: 40 },
  });

  return (
    <Container>
      {t.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          {item.Component}
        </animated.div>
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  & > div {
    display: flex;
    align-items: center;
    position: absolute;
    height: 3rem;
  }
`;
