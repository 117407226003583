import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';

interface ISellerTradeDialogStyle {
  immediate: boolean;
}

export const Container = styled.div<ISellerTradeDialogStyle>`
  display: grid;
  grid-auto-flow: row;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  position: relative;
  ${({ immediate }) =>
    !immediate &&
    `
    animation: selle-trade-slide 300ms ease-in-out 1000ms forwards;
    transform: translate3d(0, 100%, 0);
  `};

  @keyframes selle-trade-slide {
    from {
      transform: translate3d(0, 100%, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  & .close-dialog {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    text-transform: none;
    font-weight: normal;
  }

  & .header-dialog {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: 0.5rem;
    padding: 1.375rem 1rem 1rem;
    height: 4.125rem;
    border-bottom: 1px solid var(--color-gray-900);

    & > span {
      align-self: center;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: var(--color-white);
      white-space: nowrap;
    }
  }

  & .profile {
    display: grid;
    grid-template-areas: 'thumbnails nickname level' 'thumbnails registered date';
    grid-template-columns: 1.875rem 1fr min-content;
    grid-auto-rows: min-content;
    grid-gap: 0.125rem 0.5rem;
    align-content: center;
    padding: 0 1rem;
    height: 60px;
    border-top: 1px solid var(--color-gray-900);
    border-bottom: 1px solid var(--color-gray-900);

    &-thumbnails {
      width: 1.875rem;
      height: 1.875rem;
      grid-area: thumbnails;

      & img {
        width: 1.875rem;
        height: 1.875rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-nickname,
    &-level,
    &-registered {
      font-weight: bold;
      line-height: 1rem;
      color: var(--color-white);
    }

    &-nickname {
      font-size: 0.875rem;
      grid-area: nickname;
    }

    &-level {
      font-size: 0.875rem;
      grid-area: level;
    }

    &-registered {
      font-size: 0.625rem;
      grid-area: registered;
    }

    &-date {
      align-self: center;
      font-size: 0.625rem;
      color: var(--color-gray-200);
      grid-area: date;
    }

    &-level,
    &-date {
      white-space: nowrap;
      text-align: end;
    }
  }

  & .lot-status {
    display: grid;
    grid-template-areas: 'marketplace-icon type' 'marketplace-icon date';
    grid-template-columns: 1.875rem 1fr;
    grid-auto-rows: min-content;
    grid-gap: 0.125rem 0.5rem;
    align-content: center;
    padding: 1rem;
    max-height: 10rem;
    border-top: 2px solid var(--color-dark-600);
    opacity: 1;
    transition: all 150ms ease-in 300ms;
    background: var(--color-dark-100);
    position: relative;

    & i {
      align-self: center;
      color: var(--color-white);
      font-size: 1.25rem;
      grid-area: marketplace-icon;
    }

    & span {
      color: var(--color-white);
      font-weight: bold;

      &:first-of-type {
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
      }

      &:last-of-type {
        font-size: 0.625rem;
        line-height: 0.875rem;
      }
    }

    & svg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.25rem;
    }
  }

  & .details {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.75rem;
    padding: 1.75rem 0 0;

    &-wrapper {
      position: relative;
    }

    & .lot {
      display: grid;
      grid-auto-flow: column;
      grid-template: 'thumbnail data' 'stickers data';
      grid-auto-columns: 0.75fr 1fr;
      grid-template-rows: 1fr 2rem;
      min-height: 8rem;
      grid-gap: 0.5rem;
      padding: 0.75rem;
      background: var(--color-dark-100);

      & .thumbnails {
        grid-area: thumbnail;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          width: 100%;
          height: 100%;
          max-height: 4rem;
          object-fit: contain;
        }
      }

      & .stickers {
        grid-area: stickers;
        justify-self: center;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1.5rem;
        grid-template-rows: 1.5rem;
        grid-gap: 0.5rem;

        & img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
        }
      }

      & .data {
        grid-area: data;
        align-self: center;
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-auto-rows: min-content;
        grid-gap: 0.125rem 0.75rem;

        & .exterior,
        & .float {
          font-weight: bold;
          font-size: 0.625rem;
          line-height: 0.875rem;
          letter-spacing: 0.3px;
        }

        & .exterior {
          color: var(--color-gray-200);
          white-space: nowrap;
        }

        & .float {
          &.factory-new {
            color: var(--color-exterior-factory-new);
          }

          &.minimal-wear {
            color: var(--color-exterior-minimal-wear);
          }

          &.field-tested {
            color: var(--color-exterior-field-tested);
          }

          &.well-worn {
            color: var(--color-exterior-well-worn);
          }

          &.battle-scarred {
            color: var(--color-exterior-battle-scarred);
          }
        }

        & .price,
        & .name {
          grid-column: 1 / 3;
          font-size: 0.875rem;
          letter-spacing: 0.24px;
          color: var(--color-white);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        & .name {
          line-height: 2rem;
        }

        & .price {
          & span {
            font-size: inherit;
            letter-spacing: inherit;

            &.overprice {
              color: var(--color-red);
            }

            &.underprice {
              color: var(--color-green);
            }
          }
        }
      }
    }
  }

  &.wait-for-trade-confirm .lot-status,
  .wait-for-trade .lot-status {
    max-height: 0;
    opacity: 0;
  }

  & .warning-message {
    display: grid;
    grid-template-columns: 1.75rem 1fr;
    grid-gap: 0 1.25rem;
    padding: 0 0.375rem;
    margin: 0 1rem;
    max-height: 0;
    opacity: 0;
    transition: all 200ms ease-in;

    & i {
      font-size: 1.75rem;
      color: var(--yellow);
    }

    & p {
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.5708px;
      color: var(--color-white);
      padding-top: 0.125rem;

      &:not(:first-of-type) {
        grid-column: 2 / 3;
      }
    }
  }

  &.wait-for-trade-confirm .warning-message,
  .wait-for-trade .warning-message {
    opacity: 1;
    max-height: 300px;
  }

  & .controllers {
    padding: 0;
    max-height: 0;
    opacity: 0;
    transform: scale(0.9);
    transition: all 250ms ease-in;
    overflow: hidden;

    & .icon-steam-provider,
    & .icon-redirect-link {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 1px);
    }

    & .icon-steam-provider {
      font-size: 1.5rem;
      left: 1rem;
    }

    & .icon-redirect-link {
      font-size: 0.65rem;
      right: 1rem;
    }
  }

  &.wait-for-trade-confirm .controllers,
  &.wait-for-trade .controllers {
    padding: 1rem 1rem 1.75rem;
    max-height: 5.875rem;
    opacity: 1;
    transform: scale(1);
  }
`;

export const Skeleton = styled(SkeletonElement)`
  background: var(--color-dark-200);
  height: 60px;
`;
