import styled from 'styled-components';

export const RouletteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  margin-left: 2px;
`;
export const Line = styled.div`
  width: 2px;
  height: 4px;
  background: var(--color-white);
  border-radius: 1px;

  &[data-type='down'] {
    margin-bottom: 5px;
  }

  &[data-type='up'] {
    margin-top: 5px;
  }
`;
export const LineRoulette = styled.div<{ left: number }>`
  width: 2px;
  background: var(--color-white-gray);
  border-radius: 1px;
  height: 430px;
  position: absolute;
  left: ${p => p.left}px;
  top: 36px;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 1, 1);
  z-index: 20;

  &[data-roulette-status='afterRoll'] {
    opacity: 0;
    z-index: -1;
  }

  &[data-roulette-status='notAfterRoll'] {
    opacity: 1;
  }

  @media only screen and (max-height: 667px) {
    height: 320px;
  }

  @media only screen and (min-width: 500px) {
    height: 570px;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 430px;

  @media only screen and (max-height: 667px) {
    height: 320px;
  }

  @media only screen and (min-width: 500px) {
    height: 570px;
  }

  gap: 4px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: flex-start;
`;
export const ButtonContainer = styled.div<{ isFreeFieldExist: boolean }>`
  display: flex;
  position: relative;
  gap: 14px;
  height: 120px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 18px 16px 45px;

  @media screen and (min-width: 500px) {
    margin: 18px auto 45px;
    width: 500px;
  }

  ${({ isFreeFieldExist }) =>
    isFreeFieldExist &&
    `justify-content: center;
    height: fit-content;`}
`;
