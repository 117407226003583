import React from 'react';
import styled from 'styled-components';
import { useTimerEvent } from 'core/hooks';
import { useTranslation } from 'react-i18next';

const TIME_END = new Date('2021-12-13T14:00:00');

export const LandingTimeOfEvent = () => {
  const { t } = useTranslation();
  const [until] = useTimerEvent(TIME_END);
  return (
    <Container>
      <img
        src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/bullet-4.png"
        alt="bullet"
      />
      <div className="inner-container">
        <h2>
          {t('The list of all raffles, time of event and prizes will be available on December 13')}
        </h2>
        <div className="timer-of-event">
          <span>{t('Time left')}</span>
          <div className="clock">
            <div>{until.d}</div>
            <span />
            <div>{until.h}</div>
            <span />
            <div>{until.m}</div>
            <span />
            <div>{until.s}</div>
          </div>
          <div className="clock-labels">
            <div>{t('Days')}</div>
            <div>{t('Hours')}</div>
            <div>{t('Minutes')}</div>
            <div>{t('Seconds')}</div>
          </div>
        </div>
      </div>
      <img
        src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/mobile-chicken.png"
        alt="chicken"
      />
    </Container>
  );
};

const Container = styled.div`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  & .inner-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 2.125rem;
    padding: 3rem 1.25rem;
    height: 570px;
    clip-path: polygon(0 0, 100% 7.5%, 100% 100%, 0% 100%);
    background: var(--color-dark-200);

    & h2 {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.1875rem;
      letter-spacing: 0.54px;
      color: var(--color-white);
      text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);

      @media screen and (min-width: 375px) {
        font-size: 1.875rem;
      }

      &::before {
        content: '';
        float: right;
        height: 40px;
        width: 50%;
        shape-outside: polygon(0 0, 100% 100%, 100% 0);
        shape-margin: 7%;
      }
    }

    & .timer-of-event {
      display: grid;
      grid-auto-flow: row;
      padding: 0 0.25rem;

      & span {
        height: 1.5rem;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--color-white);
      }

      & .clock {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr min-content;
        position: relative;

        & span {
          align-self: center;
          width: 0.375rem;
          height: 0.5rem;
          background: #ffcc2b;
          border-radius: 0.125rem;
          padding: 0.125rem;
          margin: 0 -0.1875rem;
          border: 0.1875rem solid var(--color-dark-200);
          position: relative;
          z-index: 10;
        }

        & div {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--color-dark-600);
          border-radius: var(--size-radius-s);
          position: relative;
          font-weight: 900;
          font-size: 2.75rem;
          color: var(--color-white);

          @media screen and (min-width: 375px) {
            font-size: 3.375rem;
          }

          &:after {
            content: '';
            background: linear-gradient(0deg, rgba(12, 13, 17, 0.3) 0%, rgba(24, 35, 40, 0) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 50%;
            border-radius: var(--size-radius-s) var(--size-radius-s) 0 0;
          }

          &:before {
            content: '';
            float: left;
            padding-top: 100%;
          }
        }
      }

      & .clock-labels {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.5rem;

        & div {
          height: 2rem;
          font-size: 0.75rem;
          line-height: 1.4rem;
          letter-spacing: 0.1px;
          color: var(--color-white);
          text-align: center;
        }
      }
    }
  }

  & img {
    position: absolute;
    z-index: 10;

    &:first-of-type {
      width: 30%;
      top: -1.1875rem;
      right: 1rem;
    }

    &:last-of-type {
      width: 49%;
      bottom: -0.5rem;
      right: 0;
    }
  }
`;
