import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getActiveTournamentHistoryByParams } from '../selectors';
import { fetchTournamentHistoryById } from '../ducks/history.duck';

type RouteParams = {
  id: string;
  raceType: string;
};

export const useTournamentHistoryFacade = () => {
  const { raceType } = useParams<RouteParams>();
  const { push, location } = useHistory();
  const dispatch = useDispatch();

  const history = useSelector(state =>
    getActiveTournamentHistoryByParams(state, raceType, location.state && location.state.id)
  );

  useEffect(() => {
    if (!location.state || !raceType || !history) push('/leaders-race-next');

    if (history && !history.leaderboard.length)
      dispatch(fetchTournamentHistoryById(raceType, Number(location.state.id)));
  }, [location, raceType, history, dispatch, push]);

  return {
    history,
  };
};
