import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleDrawer } from 'core/AppShell/duck';

export const useDrawerNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePushTo = useCallback(
    (path: string) => () => {
      history.push(path);
      dispatch(toggleDrawer(false));
    },
    [dispatch, history]
  );

  const handleOpenDesktop = useCallback(() => {
    window.open(`//${window.location.hostname.substring(2)}?desktop=true`);
  }, []);

  // const handleModeInDesktop = useCallback((path: string) =>() => {
  //   window.open(`//${window.location.hostname.substring(2)}/game${path}?desktop=true`)
  // }, [])

  return {
    handlePushTo,
    handleOpenDesktop,
    // handleModeInDesktop
  };
};
