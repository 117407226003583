import styled from 'styled-components';

export const Country = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 1.5rem 1.25rem 1.5rem 0;
  transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:active {
    background: var(--color-gray-300);
  }
`;

export const Img = styled.img`
  border-radius: 50%;
  height: 24px;
  margin-right: 1rem;
  object-fit: cover;
  width: 24px;
`;

export const CountryName = styled.span<{ isCountry: boolean }>`
  color: var(${({ isCountry }) => (isCountry ? '--color-white' : '--color-white-gray-200')});
  font-weight: 500;
  overflow: hidden;
  padding-right: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
  position: relative;
`;

export const Input = styled.input`
  height: 0;
  opacity: 0;
  width: 0;
  z-index: -1;
`;

export const Indicator = styled.div`
  border-radius: 50%;
  border: 1px solid var(--aqua-200);
  height: 20px;
  left: -20px;
  position: absolute;
  top: 0;
  width: 20px;

  &::after {
    content: '';
    display: none;
    position: absolute;
  }

  ${Input}:checked + &::after {
    background-color: var(--aqua-200);
    border-radius: 50%;
    display: block;
    height: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
  }
`;
