export default {
  Games: 'Gry',
  'Refill balance': 'Zasil saldo',
  Settings: 'Ustawienia',
  Store: 'Sklep',
  'Get free money': 'Otrzymaj darmowe monety',
  Notifications: 'Powiadomienia',
  FAQ: 'FAQ',
  'Log out': 'Wyjdź',
  'Steam trade link': 'Link na wymianę w Steam',
  'Where can I get a link?': 'Gdzie wziąć link?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Nie jesteś w usłudze CSGOFAST. Dotknij ekranu, by połączyć się ponownie.',
  'App was offline. Check your internet connection to continue!':
    'Aplikacja była off-line. Sprawdź swoje połączenie internetowe, by kontynuować!',
  SAVE: 'SAVE',
};
