import React from 'react';
import { Container, IconContainer } from './case.styles';
import { useGetCaseById } from 'games/CaseBattle/hooks';
import { CaseListItemContainer, CaseListItemDefault } from '@components/CaseListItemDefault';
import { useDispatch } from 'react-redux';
import { addCase, removeCase } from 'games/CaseBattle';
import { CaseBattleIcon } from 'games/CaseBattle/assets';

interface CaseProps {
  id?: number;
  uniqId?: string;
}

export const Case: React.FC<CaseProps> = React.memo(({ id, uniqId }) => {
  const dispatch = useDispatch();
  const { caseData, isLoading } = useGetCaseById(id);

  const addCaseHandler = () => {
    dispatch(addCase(id, caseData.lastRevision.price));
    //TODO: need to add each case counter
  };

  const removeItemHandler = () => {
    dispatch(removeCase(uniqId));
  };

  if (!caseData || isLoading) {
    return (
      <Container>
        <CaseListItemContainer className="skeleton card-size-add-case" />
      </Container>
    );
  }
  return (
    <Container>
      <CaseListItemDefault
        handler={addCaseHandler}
        className="card-size-add-case"
        image={
          caseData?.free ? caseData?.image ?? caseData?.backgroundImage : caseData?.backgroundImage
        }
        caseType={caseData.type}
        price={caseData.lastRevision.price}
        free={!!caseData?.free}
      />
      <IconContainer onClick={removeItemHandler}>
        <CaseBattleIcon.RemoveIcon />
      </IconContainer>
    </Container>
  );
});
