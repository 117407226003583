import React, { useCallback, useRef } from 'react';
import games from 'games';
import { useHistory } from 'react-router-dom';
import { groupBy } from 'lodash';
import { LobbyGame } from '../../components';
import { HistoryNavigation } from 'core/Drawer/containers/HistoryNavigation/HistoryNavigation';
import { useTranslation } from 'react-i18next';
import { useScrollStickyView } from '@components/hooks';
import {
  LobbyContainer,
  ModulesContainer,
  ModulesTypeTitle,
  StickyHistoryNavigation,
} from './LobbyPage.styles';
import { ModuleName } from 'modules/Lobby/components/LobbyGame.config';
// import { EventBanner } from 'modules/event-module/dragon-event/components/default-event';

const sortedModules = groupBy(games, 'type');
const ORDER_MODULE_TYPES = ['pvp', 'pve', 'solo-pve'];

export const Lobby = () => {
  const scrollRefs = useRef(null);
  const { visible } = useScrollStickyView(scrollRefs);
  const { t } = useTranslation();
  const history = useHistory();

  const handlePushTo = useCallback(
    (path: string) => () => {
      history.push(path);
    },
    [history]
  );

  return (
    <>
      <div ref={scrollRefs} className="content dark-layout">
        {/* <EventBanner /> */}
        {ORDER_MODULE_TYPES.map((type: string) => (
          <LobbyContainer key={type} className="tablet">
            <ModulesTypeTitle>
              {type} {t('Games')}
            </ModulesTypeTitle>
            <ModulesContainer>
              {sortedModules[type].map(module => (
                <LobbyGame
                  key={module.moduleId}
                  moduleName={module.moduleName as ModuleName}
                  modulePath={module.path}
                />
              ))}
            </ModulesContainer>
          </LobbyContainer>
        ))}
      </div>
      <StickyHistoryNavigation showHistory={visible}>
        <HistoryNavigation handlePushTo={handlePushTo} />
      </StickyHistoryNavigation>
    </>
  );
};
