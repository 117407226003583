import { DIALOG } from 'dialogs/dialog-combiner';
import { userModals } from 'core/User/modal.config';
import { transitions } from 'core/Modals';
import { SteamApiKeyDialog } from './steam-apiKey-dialog';

DIALOG.add(userModals.STEAM_API_DIALOG, {
  component: SteamApiKeyDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
