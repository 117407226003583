import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ hasActiveDialog: boolean }>`
:root {
    --light-grayish-blue:#9ea7b3;
    --dark-opacity-500: #0C1A22;
    --white-opacity-200: #2d3840;
    --bonus-blue: #0D91ED;
    --blue: #35b6d3;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --pink-100: #E95BD2;
    --red: #f9140f;
    --orange: #fd7e14;
    --orange-100:#b14131;
    --orange-200: #D67C52;
    --orange-300: #fa9f04;
    --orange-400: #F95428;
    --yellow: #f6e444;
    --yellow-100: #ffd700;
    --yellow-500: #32341b;
    --ochre: #dfd042;
    --green: #28e033;
    --green-100: #1bff00;
    --green-200: #18FF00;
    --green-300: #9DFF7A;
    --teal: #20c997;
    --teal-100: #50e3c2;
    --cyan: #277486;
    --gray: #121a1e;
    --gray-150: #B6B6B8;
    --gray-dark: #0c0d11;
    --gray-100: #00bad9; 
    --gray-200: #266d7f;
    --gray-220: #344d56;
    --gray-250: #374951;
    --gray-300: #19343f;
    --gray-350: #212f35;
    --gray-400: #213137;
    --gray-450: #223137;
    --gray-500: #202f34;
    --gray-550: #757880;
    --gray-600: #121a1e;
    --gray-650: #091221;
    --gray-700: #0c1215;
    --gray-800: #0c0d11;
    --gray-900: #02020c;
    --aqua-100: #00b1ff;
    --aqua-200: #179be5;
    --aqua-300: #33ffff;
    --aqua-500: #00bad9;
    --aqua-600: #00b6d4;
    --aqua-800: #009999;
    --aqua-900: #cde0ff;
    --primary: #ffff33;
    --secondary: #35b6d3;
    --success: #00F900;
    --info: #277486;
    --warning: #F6E444;
    --danger: #f9140f;
    --light: #f2f3f6;
    --mGray-100: #E1E1E2;
    --mGray-200: #484C52;
    --mGray-300: #42464D;
    --mGray-500: #2F3136;
    --mGray-600: #27292D;
    --mGray-800: #202225;
    --gray-time: #90989B;
    --mBlack: #000000;
    --mBlue-05: #39636aba;
   
    // transitions
    --transition-ease-out: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    --bonus-code-modal-bg: #182228;
    // sizes
    --size-nav-bar: 42px;
    --size-radius-s: 4px;
    --size-radius-m: 8px;
    --size-radius-l: 12px;
    //colors
    --color-white: #ffffff;
    --color-white-400: rgba(230, 230, 230, 1);
    --color-white-600: rgba(255, 255, 255, 0.6);
    --color-white-700: rgba(255, 255, 255, 0.7);
    --color-red: #ee2c39;
    --color-red-100: #ff0123;
    --color-red-200: #df1840;
    --color-red-300: #f44542;
    --color-red-400: #ff382f;
    --color-green: #28e033;
    --color-green-100: #03dd5e;
    --color-green-200: #81be3b;
    --color-green-300: #3e8d22;
    --color-gray-50: #e6e6e6;
    --color-gray-80: #c4c4c4;
    --color-gray-90: #979797;
    --color-gray-100: #858585;
    --color-gray-150: #a7adaf;
    --color-gray-200: #9EA7B3;
    --color-gray-400: #4c5154;
    --color-gray-300: #303b42;
    --color-gray-400: #4c5154;
    --color-gray-500: #a9b1bc;
    --color-gray-700: #253138;
    --color-gray-900: #213137;
    --color-gray-950: #213138;
    --color-dark: #151e21;
    --color-dark-150: #182129;
    --color-white-gray:#47555F;
    --color-white-gray-50: #364850;
    --color-white-gray-100: #505265;
    --color-white-gray-200: #727488;
    --color-dark-350: #182228;
    --color-dark-30: #131a1d78;
    --color-dark-50: #131a1df2;
    --color-dark-60: #131a1d99;
    --color-dark-100: #131a1d;
    --color-dark-150: #00000033;
    --color-dark-200: #243038;
    --color-dark-250: #203137;
    --color-dark-300: #2f3a41;
    --color-dark-500: #1a262a;
    --color-dark-600: #182328;
    --color-dark-700: #060608;
    --color-dark-800: #101419;
    --color-dark-layout: rgba(19, 26, 29, 0.95);
    --color-light-dark-layout: var(--color-dark-500);
    // provider colors
    --color-provider-steam: #3E92EA;
    --color-provider-google: #d3493b;
    --color-provider-twitter: #2aa9ec;
    --color-provider-meta: #3a5a92;
    --color-provider-yandex: #d7143a;
    --color-provider-vkontakte: #507699;
    --color-provider-twitch: #6441a5;
    --color-provider-telegram: #57a8ea;
    // game colors
    --color-pvp: #5531a6;
    --color-pve: #00aace;
    --color-solo-pve: #6AE70E;
    //account-type
    --color-account-type-demo: #ee2c39;
    --color-account-type-bronze: #bd6428;
    --color-account-type-silver: #e2ffff;
    --color-account-type-gold: #ffcb5b;
    --color-account-type-platinum: #0d73cc;
    --color-account-type-diamond: #aa3af1;
    //leader race
    --regular-race: #6ae70e;
    --fast-race: #0cc4ff;
    --great-race: #ffdb0c;

    --regular-layout: #21ac06;
    --fast-layout: #0b5a93;
    --great-layout: #ffdb0c;

    --daily-quest: #25c30a;
    --weekly-quest: #00bad9;
    --special-quest: #ffdb0c; 

    --color-green-ticket: var(--daily-quest);
    --color-blue-ticket: var(--weekly-quest);
    --color-orange-ticket: var(--special-quest);

    //hilo
    --hilo-factor-hi: #f1ba17;
    --hilo-factor-lo: #3621c8;
    --hilo-factor-colorRed: #f91e32;
    --hilo-factor-colorBlack: #444444;
    --hilo-factor-joker: #0a92ff;
    --hilo-factor-ace: #21a31b;
    --hilo-factor-kingToAce: #21a31b;
    --hilo-factor-jackToAce: #21a31b;
    --hilo-factor-twoToNine: #21a31b;

    //double
    --double-zero-stroke: #21a31b;
    --double-zero-fill: #086906;
  
    --double-black-stroke: #444444;
    --double-black-fill: #1d1e1f;
  
    --double-red-stroke: #f91e32;
    --double-red-fill: #7f0511;

    // x50
    --x50-blue-stroke: #0a92ff;
    --x50-blue-fill: #044980;
    --x50-red-stroke: #f91e32;
    --x50-red-fill: #7f0511;
    --x50-green-stroke: #21a31b;
    --x50-green-fill: #086906;
    --x50-gold-stroke: #f1ba17;
    --x50-gold-fill: #886706;

    //baccarat
    --bacarrat-tie-stroke: #21a31b;
    --bacarrat-tie-fill: #086906;
    --bacarrat-player-stroke: #0a92ff;
    --bacarrat-player-fill: #044980;
    --bacarrat-banker-stroke: #de5d38;
    --bacarrat-banker-fill: #802b14;
  
    //exteriors
    --color-exterior-factory-new: #0cc4ff;
    --color-exterior-minimal-wear: #74ff0c;
    --color-exterior-field-tested: #ffdb0c;
    --color-exterior-well-worn: #fe9923;
    --color-exterior-battle-scarred: #ea2649;

    //lottery
    --color-free-lottery: #FDCB5B;
    --color-deposit-lottery: #00BAD9;
    --color-weekly-lottery: #00F900;
    --color-monthly-lottery: #DE5D38;
 }
 
html {
  font-size: 16px;
  font-family: icomoon, 'Roboto', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none; 
  min-height: 100vh;
  overflow:  ${({ hasActiveDialog }) => (hasActiveDialog ? 'hidden' : 'auto')};
}

body {
  background: #1a262a;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  position: fixed;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

#portal {
  position: relative;
  z-index: 9000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button, a {
  touch-action: manipulation;
  font-family: icomoon, 'Roboto', sans-serif;
}

.preloader {
  position: fixed;
  z-index: 999;
  height: 150px;
  width: 150px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.preloader_img {
    width: 150px;
}

li {
    list-style-type: none;
}
.content {
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: hidden scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 5;
  transform: translate3d(0,0,0);

  &::-webkit-scrollbar {
    display: none;
  }

}

.tablet {
  @media screen and (min-width: 500px) {
    margin: 0 auto;
    width: 500px;
  }
}

.light-layout {
  background: var(--color-light-dark-layout);
}

.dark-layout {
  background: var(--color-dark-layout);
}

.disable-layout {
  pointer-events: none;
}

a {
    text-decoration: none;
}

a:active,:hover {
  text-decoration: none;
  outline: none;
}

input {outline:none;}
button:focus {outline:0;}
textarea {outline:none;}

svg {
    fill: currentColor;
    vertical-align: initial;
}

img {
  pointer-events: none;
}

.VictoryContainer {
    pointer-events: painted !important;
    touch-action: auto !important;
}

.turn-off-default-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}
.checkboxBlue {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0px 0 0 2px;
}
.checkboxBlue + label {
    position: relative;
    padding: 1px 0 0 27px;
    cursor: pointer;
}

.checkboxBlue + label:before,
.checkboxBlue + label:after {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.checkboxBlue + label:before {
  content: '';
  position: absolute;
  background: #344D56;
  border-radius: var(--size-radius-s);  
}

.checkboxBlue + label:after {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--aqua-500);
  font-size: 0.8rem;
}
.checkboxBlue:checked + label:after {
  content: '\\e940';
  font-family: icomoon;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox + label {
    position: relative;
    padding: 5px 0px 0px 46px;
    cursor: pointer;
    margin-left: 6px;
}

.checkbox + label:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 50px;
    height: 26px;
    border-radius: 13px;
    background: #b0b0b0;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
    transition: .2s;
}

.checkbox + label:after {
    content: '';
    position: absolute;
    top: -2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 10px;
    background: #101619;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    transition: .2s;
}

.checkbox:checked + label:before {
    background: #f1e854;
}

.checkbox:checked + label:after {
    left: 26px;
}

.ripple .ripple-effect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-position: center;
  transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  border-radius: 50%;
}

.ripple:active .ripple-effect {
  background-size: 140%;
  transition: background 0s;
}

.ripple:active .ripple-dark-effect {
  background: var(--color-dark-600) radial-gradient(circle, transparent 1%, #213137 1%) center/15000%;
}

.ripple:active .ripple-light-effect {
  background: var(--color-gray-300) radial-gradient(circle, transparent 1%, #213137 1%) center/15000%;
}


/**
* Rangeslider
*/
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: var(--gray-300);
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
}
.rangeslider .rangeslider__handle {
  background-color: var(--primary);
  cursor: pointer;
  display: inline-block;
  position: absolute;
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 9px;
  border-radius: 2px;
  z-index: 4444;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: var(--primary);
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}
/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
}
.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: '';
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}


.overflowHidden {
  overflow: hidden;
}


html.modal-open {
  /* All of this stops the page from scrolling in the background, especially important on mobile devices. */
  -ms-overflow-style: scrollbar  !important;
  overflow: hidden  !important;
  height: 100%  !important;
}

@keyframes glide {
  to {
    transform: translate3d(-100%, 0, 1px);
  }
}

`;
