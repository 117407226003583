export class MailboxValidators {
  static get mailboxPattern(): RegExp {
    // eslint-disable-next-line
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  }

  static get maxLength(): number {
    return 36;
  }
}
