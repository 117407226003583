import { IStore } from 'store/interface';
import { getSectorByRand, SectorColors } from './utils';
import { createSelector } from 'reselect';
import { IMember } from './interfaces';

export const getMembers = ({ x50 }: IStore) => x50.members;
export const getX50Number = ({ x50 }: IStore) => x50.number;
export const getX50Hash = ({ x50 }: IStore) => x50.md5;
export const getX50Salt = ({ x50 }: IStore) => x50.salt;
export const getX50Rand = ({ x50 }: IStore) => x50.rand;
export const getX50UntilTime = ({ x50 }: IStore) => x50.timeOldEnds;
export const getX50History = ({ x50 }: IStore) => x50.history;
export const getX50TotalSum = ({ x50 }: IStore) => x50.totalSum;
export const getX50CurrentSector = ({ x50 }: IStore): number =>
  x50.rand ? getSectorByRand(x50.rand) : null;

export const getX50PreviousSector = ({ x50 }: IStore): number => x50.history[0].s;

const initialValue = {
  [SectorColors.BLUE]: 0,
  [SectorColors.RED]: 0,
  [SectorColors.GREEN]: 0,
  [SectorColors.GOLD]: 0,
};

export const getX50PlayerAmount = createSelector(
  getMembers,
  (_: IStore, color: SectorColors) => color,
  ({ user }: IStore) => user.base.get('id'),
  (members: Record<SectorColors, Record<string, IMember>>, color, id) => {
    const rates = (Object.keys(members) as SectorColors[]).reduce<Record<SectorColors, number>>(
      (acc, key) => ({
        ...acc,
        [key]: members[key]?.[id]?.sum || 0,
      }),
      initialValue
    );
    const bids = (Object.keys(rates) as SectorColors[]).filter(
      key => rates[key] > 0 && key !== color
    );

    return {
      amount: rates[color],
      disabled: bids.length >= 2,
    };
  }
);

export const getHighlightSectors = createSelector(
  getMembers,
  ({ user }: IStore) => user.base.get('id'),
  (members: Record<SectorColors, Record<string, IMember>>, id) => ({
    [SectorColors.BLUE]: !!members[SectorColors.BLUE][id],
    [SectorColors.RED]: !!members[SectorColors.RED][id],
    [SectorColors.GREEN]: !!members[SectorColors.GREEN][id],
    [SectorColors.GOLD]: !!members[SectorColors.GOLD][id],
  })
);
