import * as React from 'react';
import styled from 'styled-components';

import { iconColors, layoutColors } from '../../fast.utils';
import { getItemUri } from 'core/utils/image.utils';

import { usePlayerTrade } from '../../hooks';

import { ITrades } from '../../interfaces/reducer.fast.interface';
import { URLS } from 'services/constant-urls';

interface IUserTrades {
  trades: ITrades;
  gameId: number;
}

const { memo } = React;

export const UserTrades: React.FC<IUserTrades> = memo(({ trades, gameId }) => {
  const { sortedTradeId } = usePlayerTrade(trades);
  return (
    <Container>
      {sortedTradeId.map((tradeId, i) => (
        <TradeList key={tradeId || i} index={i}>
          {tradeId &&
            trades[tradeId].items[gameId].map((item: any, i: number) => (
              <TradeItem
                key={i}
                src={getItemUri(item.icon || URLS.moneyIconUrl, 30, 30)}
                alt="skin"
              />
            ))}
        </TradeList>
      ))}
    </Container>
  );
});

interface IUserTradesStyle {
  index: number;
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  min-height: 85px;
  margin: 10px 0;
`;

const TradeList = styled.div<IUserTradesStyle>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;
  height: 25px;
  border-left: 2px solid;
  border-left-color: ${p => iconColors[p.index]};
  background-color: ${p => layoutColors[p.index]};
  overflow-x: scroll;
  overflow-y: hidden;
`;

const TradeItem = styled.img`
  width: 30px;
  height: 20px;
  padding: 0 3px;
  object-fit: contain;
`;
