import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SoundsSettingsDialogConfig } from 'games/CaseGame';
import { useDialog, useToolBar } from 'core/hooks';

export const useCloseToolBar = () => {
  const history = useHistory();
  const { handleHideToolBar, toolBarState } = useToolBar();
  const { handleCloseByName, activeDialogs } = useDialog();

  useEffect(() => {
    return history.listen(() => {
      if (toolBarState) {
        handleHideToolBar();
      }

      if (activeDialogs.includes(SoundsSettingsDialogConfig.SOUNDS_DIALOG)) {
        handleCloseByName(SoundsSettingsDialogConfig.SOUNDS_DIALOG);
      }
    });
  }, [activeDialogs, handleCloseByName, handleHideToolBar, history, toolBarState]);
};
