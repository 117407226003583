import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button } from '@components/Common';
import { useUser, useUserEmail } from 'core/User';
import { PayoutAmount } from './payout-amount';
import { PayoutAggregator } from './payout-aggregator';
import { PayoutInput } from './payout-input';
import {
  useCreateOrder,
  useGetRate,
  usePayoutConfig,
  usePayoutGates,
  usePayoutReceive,
  usePayoutWallet,
} from '../../hooks';
import { cleanUpOrder, requestRate } from '../../duck';
import { CalculateAmount } from './calculate-amount';
import { PayoutFee } from './payout-fee';
import { PayoutMailbox } from './payout-mailbox';
import { useParams } from 'react-router';
import { WithDrawalIcons } from 'modules/withdrawal/assets';
import { useGatePay, useGetDataForWalletPix } from 'modules/Refill/hooks';

export const PayoutMethod = () => {
  const { t } = useTranslation();
  const { method }: { method?: string } = useParams();
  const { config } = usePayoutConfig(method);
  const { gates } = usePayoutGates();
  const { user } = useUser();
  const { amount } = usePayoutReceive();
  const { wallet } = usePayoutWallet();
  const dispatch = useDispatch();
  const { createOrderAction } = useCreateOrder();
  const { email } = useUserEmail();
  const { rate } = useGetRate();
  const { gate } = useGatePay();
  const walletType = gates?.[gate]?.type || '';

  const dataForWalletPix = useGetDataForWalletPix();
  const {
    document,
    taxAccount,
    fullName,
    emailAddress,
    documentFieldError,
    taxAccountFieldError,
    fullNameFieldError,
    emailAddressFieldError,
  } = dataForWalletPix;

  useEffect(() => {
    if (!rate.data) {
      dispatch(requestRate());
    }
  }, [dispatch, rate.data]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpOrder());
    };
  }, [dispatch]);

  const onSubmit = () => {
    switch (walletType) {
      case 'pix':
        !document && documentFieldError(true);
        !taxAccount && taxAccountFieldError(true);
        !fullName && fullNameFieldError(true);
        !emailAddress && emailAddressFieldError(true);

        if (document && taxAccount && fullName && emailAddress) {
          createOrderAction(config.name)();
        }
        break;

      default:
        createOrderAction(config.name)();
        break;
    }
  };

  if (!config) return null;

  const disabled =
    user.coins < amount.input || !wallet || ['empty', 'not confirmed'].includes(user.email.status);

  return (
    <Container>
      <div className="input-container">
        <PayoutInput
          name={config.name}
          regularExp={config.regularExp}
          walletType={walletType}
          dataForWalletPix={dataForWalletPix}
        />
      </div>
      {gates.length > 1 && <PayoutAggregator gates={gates} />}
      {<PayoutAmount minValue={config.minimum} />}
      {config?.feeSelector?.length && <PayoutFee feeSelectors={config.feeSelector} />}
      <div className="inner-container">
        <CalculateAmount
          amount={amount}
          gate={config}
          feeSelectors={config.feeSelector}
          name={config.name}
        />
        <WithDrawalIcons.LineIcon />
      </div>
      <PayoutMailbox />
      <div className="controllers-container">
        <Button className="primary full withdraw" onClick={onSubmit} disabled={disabled}>
          <div>
            <span>{t('Send an email with a link')}</span>
            {email.address && <span>{t('to {{ address }}', { address: email.address })}</span>}
          </div>
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  grid-gap: 0.875rem;
  padding-top: 2rem;

  & .input-container {
    padding: 0 1rem 14px;
  }

  & .inner-container {
    display: grid;
    grid-gap: 4.5rem;
    grid-auto-flow: row;
    margin-top: 1.75rem;
    padding: 1.5rem 0 2rem;
    background-color: var(--color-dark-600);
    position: relative;

    & svg {
      position: absolute;
      bottom: -0.25rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  & .controllers-container {
    padding: 0 1rem 1.75rem;

    & .withdraw {
      & div {
        display: grid;
        grid-auto-rows: min-content;
        grid-gap: 0.125rem;

        & span {
          font-size: 0.8125rem;
          line-height: 0.9375rem;
          text-align: center;
          letter-spacing: 0.286px;
          color: inherit;
          &:first-of-type {
            font-weight: bold;
          }

          &:last-of-type:not(:first-of-type) {
            text-transform: lowercase;
          }
        }
      }
    }
  }
`;
