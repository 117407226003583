import { Reducer } from 'redux';
import { Map } from 'immutable';
import { IDispatch } from 'core/rootInterfaces/root.interface';

import { questActions, questActionsTypes } from '../interfaces/actions.leaders.interfaces';

import { IQuest, IQuestsMap, updateParams } from '../interfaces/reducer.leaders.interfaces';
import { ISocketService } from '../../../services/interfaces/services.interface';
// import { fetchTickets } from './coupons.duck'

const QuestsMap = Map<number, IQuest>();

export const quests: Reducer<IQuestsMap, questActions> = (state = QuestsMap, action) => {
  switch (action.type) {
    case questActionsTypes.ADD_QUESTS:
      return action.payload.quests.reduce(
        (acc: any, quest: any) => acc.set(quest.id, quest),
        state
      );

    case questActionsTypes.UPDATE_QUESTS:
      const { questId, ...updates } = action.payload.params;
      return state.has(questId) ? state.mergeIn([questId], updates) : state;

    default:
      return state;
  }
};

export const fetchRacesQuest = () => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  try {
    const response = await fetch(
      `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/quests`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      }
    );

    const data = await response.json();

    if ('error' in data) throw new Error(data.error);

    dispatch(addQuests(data));
  } catch (e) {
    console.log(e);
  }
};

const addQuests = (quests: any[]) => ({
  type: questActionsTypes.ADD_QUESTS,
  payload: {
    quests,
  },
});

export const updateQuests = (params: updateParams) => ({
  type: questActionsTypes.UPDATE_QUESTS,
  payload: {
    params,
  },
});

type takeRewardResponse = {
  rewardTaken: string;
};

export const fetchTakeReward = (id: number) => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  const res = await fetch(
    `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/quests/${id}/take-reward`,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    }
  );
  const questStatus: takeRewardResponse = await res.json();

  if (!questStatus.rewardTaken) return;

  // dispatch(fetchRacesQuest())
  // dispatch(fetchTickets())
};
