import { IFiltersRecord } from './filters.reducers.interface';
import { MergeType } from './shop.reducers.interface';

export enum FiltersActionTypes {
  CHANGE_FILTER = 'app/exchange/CHANGE_FILTER',
  CHANGE_PRICE_RANGE_FILTER = 'app/exchange/CHANGE_PRICE_RANGE_FILTER',
  CHANGE_PAGE_FILTER = 'app/exchange/CHANGE_PAGE_FILTER',

  UPDATE_FILTERS = 'app/exchange/UPDATE_FILTERS',

  RESET_FILTERS = 'app/exchange/RESET_FILTERS',
}

export interface IChangeFilters {
  type: typeof FiltersActionTypes.CHANGE_FILTER;
  payload: {
    key: keyof IFiltersRecord;
    value: string | number;
  };
}

export interface IChangePriceRangeFilters {
  type: typeof FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER;
  payload: {
    range: number[];
  };
}

export interface IChangePageFilters {
  type: typeof FiltersActionTypes.CHANGE_PAGE_FILTER;
  payload: {
    page: number;
  };
}

export interface IUpdateFilters {
  type: typeof FiltersActionTypes.UPDATE_FILTERS;
  payload: {
    merge: MergeType;
  };
}

export interface IResetFilters {
  type: typeof FiltersActionTypes.RESET_FILTERS;
}

export type filtersActions =
  | IChangeFilters
  | IChangePriceRangeFilters
  | IChangePageFilters
  | IResetFilters;
