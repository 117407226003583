import React, { FC } from 'react';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';
import { useCreatePay, useWalletPay } from '../../../hooks';
import { IPayMethod, IPayMethodGates } from '../../../interfaces/reduces.refill.interfaces';
import { Container, Fields, NumberInput } from './pay-gift-codes.styles';

interface IPayGiftCodes {
  gate: number;
  gates: IPayMethodGates;
  method: IPayMethod;
}

export const PayGiftCodes: FC<IPayGiftCodes> = ({ gate, gates, method }) => {
  const { t } = useTranslation();
  const { wallet, changeWallet } = useWalletPay();
  const [handleCreatePay] = useCreatePay();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[^a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]/gi, '');
    changeWallet({
      target: { value: result, type: e.target.type },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Container>
      <span className="gift-card-step">{t('Step 2 Use the code from the purchased card')}</span>
      <div className="input-inner-container">
        <Fields>
          <NumberInput name="giftcards" value={wallet} onChange={onChangeHandler} type="text" />
        </Fields>
        <Button
          className="primary"
          onClick={handleCreatePay({
            name: method?.name,
            gate: gates[gate],
            wallet: wallet,
          })}
          disabled={!wallet}
        >
          {t('Activate')}
        </Button>
      </div>
    </Container>
  );
};
