import React, { FC, useCallback } from 'react';
import { CaseData } from 'games/CaseGame/interfaces/cases.interface';
import { useDialog } from 'core/hooks';
import { FilterDialog } from 'games/CaseGame/dialog-config/dialog-config';
import { useHistory } from 'react-router-dom';
import { CaseListItemDefault } from '@components/CaseListItemDefault';

interface CaseListItemProps {
  caseData: CaseData;
  className?: string;
}
export const CaseListItem: FC<CaseListItemProps> = ({ caseData, className }) => {
  const { handleCloseDialog } = useDialog(FilterDialog.SEARCH);
  const history = useHistory();

  const redirectHandler = useCallback(() => {
    if (caseData.enabled) {
      history.push(`cases/${caseData.id}`);
      handleCloseDialog();
    }
  }, [caseData.id, history, handleCloseDialog, caseData.enabled]);

  return (
    <CaseListItemDefault
      handler={redirectHandler}
      free={!!caseData?.free}
      enabled={caseData.enabled}
      image={caseData?.free ? caseData.image ?? caseData.backgroundImage : caseData.backgroundImage}
      price={caseData.lastRevision.price}
      className={className}
      caseType={caseData.type}
    />
  );
};
