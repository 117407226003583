import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { CurrencyText } from '@components/currency';
import { IStatistic } from './StatisticGames';
import { Currency, IconStar, Item, Title } from './GameInfo.styles';

interface IGameInfo {
  statistic: IStatistic;
}

export const GameInfo: FC<IGameInfo> = memo(
  ({ statistic: { title, value, isCurrency, isTop } }) => {
    const { t } = useTranslation();

    return (
      <Item key={title}>
        {isTop && <IconStar />}
        <Title>{t(title)}</Title>
        <Currency>{isCurrency ? <CurrencyText value={value} /> : value}</Currency>
      </Item>
    );
  },
  isEqual
);
