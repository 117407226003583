import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Container, Description, Title, StyledButton, Info } from './rare-case.styles';
import { IDialogComponent } from 'core/interfaces';
import { rouletteStatusActionBeforeRollAll } from 'games/CaseGame';
import { CaseLuckyWonEvent } from '../../interfaces';
import { eventImagesPath } from '../../assets';
import { actionClearRareCaseInfo } from '../../duck/default-event-duck';

export interface EventDialogProps extends IDialogComponent<CaseLuckyWonEvent> {}

export const EventDialog: React.FC<EventDialogProps> = ({ handleCloseModal, data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const redirect = () => {
    dispatch(actionClearRareCaseInfo());
    dispatch(rouletteStatusActionBeforeRollAll());
    history.push(`../cases/${data.caseId}`);
    handleCloseModal();
  };

  return (
    <Container>
      <img src={eventImagesPath.prizePath} alt="prize" />
      <Info>
        <Title>{t('rareCaseTitle')}</Title>
        <Description>{t('rareCaseDescription')}</Description>
        <StyledButton onClick={redirect}>{t('open')}</StyledButton>
      </Info>
    </Container>
  );
};
