import React, { FC } from 'react';
import { GameInfo } from './GameInfo';
import { Container, StatisticItem } from './StatisticGames.styles';
import { GamesIcons } from 'assets';
import { ModuleName } from 'modules/Lobby/components';

export interface IStatistic {
  isCurrency: boolean;
  isTop: boolean;
  key: string;
  title: string;
  value: string;
}

interface IStatisticGame {
  statistics: IStatistic[];
  mode: string;
  key: string;
}

interface IStatisticGames {
  games: IStatisticGame[];
}

export const StatisticGames: FC<IStatisticGames> = ({ games }) => (
  <Container>
    {games.map(game => (
      <StatisticItem key={game.key} gameType={game.mode}>
        <div className="game-type">
          {GamesIcons[game.key as ModuleName]?.(30, 30)}
          <span>{game.key}</span>
        </div>
        <div className="game-description">
          {game.statistics.map(statistic => (
            <GameInfo key={statistic.title} statistic={statistic} />
          ))}
        </div>
      </StatisticItem>
    ))}
  </Container>
);
