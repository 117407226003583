import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppBar } from '@components/bars';
import { Notification } from '../../components/Notification';

import { NoticeNavigateWidget } from '../../components/Notification/NoticeNavigateWidget';
import { getAppNotifications } from 'modules/Notifications/selectors';
import { closeDeleteNotices } from 'modules/Notifications/duck';

export const NotificationPage = () => {
  const notifications = useSelector(getAppNotifications, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeDeleteNotices());
    };
  }, [dispatch]);

  return (
    <>
      <AppBar deep prependWidget={notifications.length ? NoticeNavigateWidget : null} />
      <Notification />
    </>
  );
};
