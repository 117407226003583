import reducer, { eventsTypes } from './duck';
import './locales';

export { GameRouter as PoggiRouter } from './containers';
// export const PoggiRouter = lazy(() => import('./containers').then(module => ({ default: module.GameRouter })))
export const PoggiModule = {
  moduleName: 'poggi',
  moduleId: 19,
  path: '/poggi',
  type: 'solo-pve',
  reducer,
  eventsTypes,
};
