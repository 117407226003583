import styled from 'styled-components';

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: var(--color-white);
`;

export const CollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #223038;
  border-bottom: 1px solid #223038;
  padding: 11px 20px 10px 19px;
  width: 100%;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.24px;
  text-align: left;
  color: var(--color-gray-200);
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 23px 20px 30px 19px;
`;
