export default {
  'Your win:': 'Your win:',
  'Select skins that you want to take': 'Select skins that you want to take',
  'By price': 'By price',

  '(Autotrade will receive after 10 minutes)': '(Autotrade will receive after 10 minutes)',

  'Select all': 'Select all',
  Reset: 'Reset',
  'To balance': 'To balance',
  'Withdraw to STEAM': 'Withdraw to STEAM',
  'Receive winnings': 'Receive winnings',
  'Items delivery': 'Items delivery',
  'Open in browser': 'Open in browser',
};
