import {
  Container,
  Icon,
  IconContainer,
  Prize,
  ProfitBlock,
  SkinSpan,
  SoldIconContainer,
  StyledButton,
  TextContainer,
  TextSpan,
  Wrapper,
} from './RouletteItemBeforeRoll.styles';
import React from 'react';
import { CurrencyText } from '@components/currency';
import { useGetOpenCaseType, useGetWinItemsStatus } from 'games/CaseGame/hooks';
import {
  SoldStatus,
  actionSellItemChangeTypeByIndex,
  actionSellItemsRequest,
} from 'games/CaseGame/ducks';
import { useDispatch } from 'react-redux';
import { SvgIcons } from 'games/CaseGame/assets';
import { isEqual } from 'lodash';
import { colorDictionaryForCaseItem, skinItemImage } from 'core/utils';
import { useHistory } from 'react-router-dom';
import { useGetLuckyWonData } from 'modules/event-module/comics-event/hooks';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

interface RouletteItemBeforeRollProps {
  lineIndex: number;
  lineCount: number;
  isAllInRoll: boolean;
  isAllStartRoll: boolean;
  isAllAfterRoll: boolean;
  isInit: boolean;
  icon?: string;
  isProfit?: boolean;
  name?: string;
  color?: string;
  price?: number;
  shortName?: string;
  skin?: string;
  itemId?: number;
  UUID?: string;
  isNeedToShow?: boolean;
}

export const RouletteItemBeforeRoll = React.memo(
  React.forwardRef<HTMLDivElement, RouletteItemBeforeRollProps>(
    (
      {
        icon,
        name,
        color,
        skin,
        shortName,
        lineCount,
        isInit,
        price,
        lineIndex,
        itemId,
        UUID,
        isAllInRoll,
        isProfit,
        isAllStartRoll,
        isAllAfterRoll,
        isNeedToShow,
      },
      ref
    ) => {
      const dispatch = useDispatch();
      const { winItemsStatus } = useGetWinItemsStatus();
      const { openCaseType } = useGetOpenCaseType();
      const newName = name ? name.split('|').join('')[0] : '';
      const isBig = lineCount >= 3;
      const data = useGetLuckyWonData();
      //NOTE: event logic
      const history = useHistory();
      const search = new URLSearchParams(history.location.search).get('uuid');

      const sellItem = () => {
        if (UUID) {
          dispatch(actionSellItemsRequest([itemId]));
          dispatch(actionSellItemChangeTypeByIndex(lineIndex, lineCount));
        }
      };

      return (
        <Container
          isAnimationEnd={!price && isAllAfterRoll && !isAllStartRoll}
          lineCount={lineCount}
          ref={isInit ? ref : null}
          color={colorDictionaryForCaseItem[color?.toLocaleUpperCase()]}
        >
          {isProfit && !isAllInRoll && !isAllStartRoll && <ProfitBlock />}
          <IconContainer
            isBig={isBig}
            data-status={isAllStartRoll || isAllInRoll ? 'inRoll' : 'notRoll'}
          >
            {winItemsStatus[lineIndex] !== SoldStatus.SOLD &&
              isNeedToShow &&
              !!price &&
              !!data &&
              !isAllInRoll &&
              !isAllStartRoll && <Prize src={eventImagesPath.prizePath} alt="prize" />}
            <Icon lineCount={lineCount} src={skinItemImage(icon, 216, 216)} alt="" />

            {!!price && !search && (
              <SoldIconContainer
                data-visible={
                  winItemsStatus[lineIndex] === SoldStatus.SOLD && !isAllStartRoll
                    ? 'visible'
                    : 'hide'
                }
              >
                <SvgIcons.SoldIcon
                  className="svg"
                  data-visible={
                    winItemsStatus[lineIndex] === SoldStatus.SOLD && !isAllStartRoll
                      ? 'visible'
                      : 'hide'
                  }
                />
              </SoldIconContainer>
            )}
          </IconContainer>
          <TextContainer
            isBig={isBig}
            data-status={isAllStartRoll || isAllInRoll ? 'inRoll' : 'notRoll'}
          >
            {isBig ? (
              <Wrapper>
                <SkinSpan isBig={isBig} lineCount={lineCount}>
                  {shortName ?? newName}
                </SkinSpan>
                <TextSpan lineCount={lineCount}>{skin}</TextSpan>
              </Wrapper>
            ) : (
              <>
                <SkinSpan isBig={isBig} lineCount={lineCount}>
                  {shortName ?? newName}
                </SkinSpan>
                <TextSpan lineCount={lineCount}>{skin}</TextSpan>
              </>
            )}
            {openCaseType !== 'replay' && !!price && (
              <StyledButton
                onClick={sellItem}
                data-visible={winItemsStatus[lineIndex] !== SoldStatus.SOLD ? 'visible' : 'hide'}
                data-line-count-button={isBig ? 'BIG' : 'SMALL'}
                disabled={!UUID}
                lineCount={lineCount}
              >
                <CurrencyText value={price} />
              </StyledButton>
            )}
          </TextContainer>
        </Container>
      );
    }
  ),
  isEqual
);
