import React from 'react';
import { ReactComponent as SoundOne } from './sound-level 1.svg';
import { ReactComponent as SoundTwo } from './sound-level 2.svg';
import { ReactComponent as SoundThree } from './sound-level 3.svg';
import { ReactComponent as SoundFour } from './sound-level 4.svg';
import { ReactComponent as SoundMute } from './sound-muted.svg';
import { ReactComponent as MixSounds } from './mix-arrow.svg';
import { ReactComponent as HistoryIcon } from './history.svg';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as Rule } from './rules.svg';
import { ReactComponent as StatsIcon } from './stats.svg';
import { ReactComponent as Top } from './top.svg';
import { ReactComponent as Event } from './event.svg';

export type ToolBarConfigKeyType =
  | 'standard'
  | 'danger'
  | 'jingle'
  | 'random'
  | 'soundOff'
  | 'history'
  | 'chat'
  | 'rules'
  | 'stats'
  | 'top'
  | 'event';

export const ToolBarConfig = {
  standard: <SoundOne />,
  danger: <SoundTwo />,
  jingle: <SoundThree />,
  approach: <SoundFour />,
  random: <MixSounds />,
  soundOff: <SoundMute />,
  history: <HistoryIcon />,
  chat: <Chat />,
  rules: <Rule />,
  stats: <StatsIcon />,
  top: <Top />,
  event: <Event />,
};

export const ToolBarIcons = {
  SoundOne,
  SoundTwo,
  SoundThree,
  SoundFour,
  SoundMute,
  MixSounds,
  Chat,
  Rule,
  StatsIcon,
  Top,
  HistoryIcon,
  Event,
};
