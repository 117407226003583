import styled from 'styled-components';
import { NavigationLink } from '@components/Common';

export const NavigateTitle = styled.div`
  background: var(--dark);
  color: var(--color-white);
  font-size: 0.8rem;
  letter-spacing: 0.69px;
  opacity: 0.7;
  padding: 2rem 1rem 0.8rem;
  text-transform: uppercase;
`;

export const NavigateList = styled.div`
  background-color: var(--color-dark-600);
  border-bottom: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  position: relative;

  & a {
    border: none !important;

    & .leading {
      height: 1.375rem;
      width: 1.375rem;

      & img {
        height: 1.375rem;
        position: absolute;
        width: 1.375rem;
      }
    }

    &[href='/refill/regional/samsung-pay'],
    &[href='/refill/regional/apple-pay'] {
      order: -1;
    }

    & [class^='icon-'] {
      font-size: 1.25rem;
      color: var(--color-gray-200);
    }
  }

  ${NavigationLink} {
    &.disabled {
      & div:not(.leading) span:first-of-type:not(:last-of-type),
      & .leading {
        opacity: 0.2;
      }
    }

    & div:not(.leading) span:last-of-type:not(:first-of-type) {
      color: var(--color-gray-200);
      font-size: 0.8125rem;
      letter-spacing: 0.286px;
      line-height: 0.9375rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @supports (-webkit-touch-callout: none) {
    & > a[href='/refill/regional/samsung-pay'] {
      display: none;
    }
  }

  @supports not (-webkit-touch-callout: none) {
    & > a[href='/refill/regional/apple-pay'] {
      display: none;
    }
  }
`;

export const Bonus = styled.div`
  color: var(--color-exterior-minimal-wear);
  font-size: 0.8125rem;
  letter-spacing: 0.286px;
  line-height: 0.9375rem;
`;
