import React, { FC, useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnimatedRouter } from '@components/animated-router';
import { GamePage, RulesPage } from './Pages';
import { attachListener, detachListener } from '../duck';
import { LotteryPage } from '../../../@components/Histories';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(attachListener());
    return () => {
      dispatch(detachListener());
    };
  }, [dispatch]);

  return (
    <>
      <div className="content">
        <AnimatedRouter>
          <Route path={`${match.url}/rules`} component={RulesPage} />
          <Route path={`${match.url}/lottery`} component={LotteryPage} />
          <Route path={match.url} component={GamePage} />
        </AnimatedRouter>
      </div>
    </>
  );
};
