import React, { FC, useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { AnimatedContentLayout, Drawer } from 'core/Drawer';

import modules from 'modules';
import { PlayerProfileModal } from 'modules/Accounts';
import { PromoModal } from 'modules/promo';
import { TradeBot } from '@components/TradeBot';
import { BigPreloader } from '@components/Preloader';
import { RouterContext } from '../router-context';
import { Modules } from 'core/interfaces';
import { LayoutDrawer } from './Router.styles';
import { useUser } from 'core/User';
import SoundService from 'services/sound.service';
import { SuccessPayment } from 'modules/SuccessPayment';

const sound = new SoundService();

export const Router: FC = () => {
  const [routes] = useContext(RouterContext);

  const {
    user: { experience, id },
  } = useUser();

  useEffect(() => {
    if (experience.userCanUpdateLevel) {
      sound.play('commonSounds', 'Next rank');
    }
  }, [experience.userCanUpdateLevel]);
  return (
    <>
      <LayoutDrawer>
        <Drawer />
        <AnimatedContentLayout>
          <React.Suspense fallback={<BigPreloader />}>
            <AnimatedRouter deep={false}>
              <Route path={Modules.CLASSIC} component={routes.get(Modules.CLASSIC)} />

              <Route path={Modules.FAST} component={routes.get(Modules.FAST)} />
              <Route path={Modules.HILO} component={routes.get(Modules.HILO)} />
              <Route path={Modules.CRASH} component={routes.get(Modules.CRASH)} />
              <Route path={Modules.DOUBLE} component={routes.get(Modules.DOUBLE)} />
              <Route path={Modules.X50} component={routes.get(Modules.X50)} />
              <Route path={Modules.BACCARAT} component={routes.get(Modules.BACCARAT)} />
              <Route path={Modules.CHAT} component={routes.get(Modules.CHAT)} />
              <Route path={Modules.WHEEL} component={routes.get(Modules.WHEEL)} />
              <Route path={Modules.TOWER} component={routes.get(Modules.TOWER)} />
              <Route path={Modules.CASES} component={routes.get(Modules.CASES)} />
              <Route path={Modules.SLOT} component={routes.get(Modules.SLOT)} />
              {/*<Route path={Modules.CRAFT} component={routes.get(Modules.CRAFT)} />*/}
              <Route path={Modules.POGGI} component={routes.get(Modules.POGGI)} />
              <Route path="/accounts" component={modules['accounts'].component} />
              <Route path="/assistance" component={modules['assistance'].component} />
              <Route path={Modules.PRIVACY_POLICY} component={modules['privacyPolicy'].component} />
              <Route path={Modules.TOS} component={modules['tos'].component} />
              {/* <Route path="/leaders-race-next" component={modules['leaders'].component} /> */}
              <Route
                path={modules['marketplace'].path}
                component={modules['marketplace'].component}
              />
              <Route path={Modules.EVENT} component={routes.get(Modules.EVENT)} />
              <Route path="/notifications" component={modules['notifications'].component} />
              <Route path={Modules.PERSONAL} component={routes.get(Modules.PERSONAL)} />
              <Route path={Modules.REFILL} component={routes.get(Modules.REFILL)} />
              <Route path={Modules.WITHDRAWAL} component={routes.get(Modules.WITHDRAWAL)} />
              <Route path={Modules.MARKET} component={routes.get(Modules.MARKET)} />
              <Route path={Modules.INVENTORY} component={routes.get(Modules.INVENTORY)} />
              {/* <Route path={Modules.FAUCET} component={routes.get(Modules.FAUCET)} /> */}
              <Route path={`${Modules.SUCCESS_PAYMENT}/${id}`} render={() => <SuccessPayment />} />
              <Route path={Modules.CASE_BATTLE} component={routes.get(Modules.CASE_BATTLE)} />
              <Route path="/" component={modules['lobby'].component} />
            </AnimatedRouter>
          </React.Suspense>
        </AnimatedContentLayout>
      </LayoutDrawer>
      <PlayerProfileModal />
      <TradeBot />
      <PromoModal />
    </>
  );
};
