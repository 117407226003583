import { OverpriceDialog } from './overprice-dialog';
import { SingleFilterDialog } from './single-filter-dialog';
import { MultiFilterDialog } from './multi-filter-dialog';
import { DIALOG, transitions } from 'dialogs';
import { MarketplaceDialogs } from '../../../marketplace-dialogs.config';

DIALOG.add(MarketplaceDialogs.MARKETPLACE_OVERPRICE_FILTER, {
  component: OverpriceDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});

DIALOG.add(MarketplaceDialogs.MARKETPLACE_SINGLE_FILTER, {
  component: SingleFilterDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});

DIALOG.add(MarketplaceDialogs.MARKETPLACE_MULTI_FILTER, {
  component: MultiFilterDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});
