import * as React from 'react';
import styled from 'styled-components';
import { IRaffleTape } from './RaffleTape';
import { UserBlock } from '../../styles';
import { animated, ReactSpringHook, useSpring } from 'react-spring';
import { rafflingTime, slotHeight } from '../../../../fast.utils';
import { Icon, PlayerAvatar } from '@components/Common';

interface IWinnerPlayer extends IRaffleTape {
  refs: React.RefObject<ReactSpringHook>;
}

export const WinnerPlayer: React.FC<IWinnerPlayer> = ({ trades, winner, refs }) => {
  const colorProps = useSpring({
    to: {
      opacity: 1,
    },
    from: { opacity: 0 },
    ref: refs,
    delay: rafflingTime,
  });

  return (
    <Container>
      <AwardDiv style={colorProps}>
        <Icon name="award-symbol" />
      </AwardDiv>
      <UserBlock>
        <PlayerAvatar uri={trades[winner].playerImage} userId={trades[winner].playerId} />
      </UserBlock>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: ${slotHeight}px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const AwardDiv = styled(animated.div)`
  position: absolute;
  top: -12px;

  & > i {
    font-size: 100px;
    color: var(--color-white);
  }
`;
