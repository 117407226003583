import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShopFilter } from '../../selectors';
import { changeShopFilter } from '../../ducks';
import { IStore } from 'store/interface';
import { IFiltersRecord, MergeType } from '../../interfaces';

export const useShopSortFilter = () => {
  const dispatch = useDispatch();
  const sort = useSelector<IStore, IFiltersRecord['sortBy']>(getShopFilter('sortBy'));

  const changeSort = useCallback(() => {
    dispatch(changeShopFilter('sortBy', sort === 'price' ? '-price' : 'price', MergeType.RESET));
  }, [dispatch, sort]);

  return {
    sort,
    changeSort,
  };
};
