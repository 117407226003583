import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useUserVerification } from 'core/User';
import { useGetRate, usePayoutMethods } from '../../hooks';
import { PayoutPreviewUserVerification } from './payout-preview-user-verification';
import { CsGoMethod, WithDrawalMethods, LinkToCryptoMethod } from './components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { requestRate } from 'modules/withdrawal/duck';
import { Loader } from './components/loader/loader';

export const PayoutSystemsList: FC = () => {
  const { t } = useTranslation();
  const { verification } = useUserVerification();
  const { others } = usePayoutMethods();
  const { rate } = useGetRate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestRate());
  }, [dispatch]);

  return (
    <div className="content dark-layout">
      {rate.isLoading ? (
        <Loader />
      ) : (
        <>
          <NavigateTitle>{t('CSGOSkins')}</NavigateTitle>
          <CsGoMethod />
          <NavigateTitle>{t('MoneyWithdrawal')}</NavigateTitle>
          {others.map(({ title, name }) => (
            <WithDrawalMethods rate={rate.data} title={title} name={name} key={name} />
          ))}
          <LinkToCryptoMethod rate={rate.data} />
          {verification.requirement && verification.status !== 'success' && (
            <PayoutPreviewUserVerification />
          )}
        </>
      )}
    </div>
  );
};

const NavigateTitle = styled.div`
  opacity: 0.7;
  font-size: 0.8rem;
  letter-spacing: 0.69px;
  color: var(--color-white);
  padding: 2rem 1rem 0.8rem;
  text-transform: uppercase;
  background: var(--dark);
`;
