import React from 'react';
import { StyledSellButton } from './SellButton.styles';
import {
  actionSellItemsChangeTypeToSold,
  actionSellItemsRequest,
  rouletteStatusActionBeforeRollAll,
} from 'games/CaseGame/ducks/roulette-duck';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

interface SellButtonProps {
  winningItemsPrice: number;
  winningItemsIds: number[];
}

export const SellButton: React.FC<SellButtonProps> = React.memo(
  ({ winningItemsPrice, winningItemsIds }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('cases2.0');

    const sellAllItems = () => {
      winningItemsIds && dispatch(actionSellItemsRequest(winningItemsIds));
      dispatch(actionSellItemsChangeTypeToSold());
      dispatch(rouletteStatusActionBeforeRollAll());
    };

    return (
      <StyledSellButton onClick={sellAllItems}>
        {t('sellFor')}
        <CurrencyText value={winningItemsPrice} />
      </StyledSellButton>
    );
  },
  isEqual
);
