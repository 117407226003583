import styled from 'styled-components';

export const Container = styled.div<{ isSingleMode: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: ${({ isSingleMode }) => (isSingleMode ? '1px' : '6px')};
  z-index: 3;
`;

export const WinnerContainer = styled.span<{ textColor: string }>`
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: ${({ textColor }) => textColor};
  text-shadow: 0 0 8px ${({ textColor }) => textColor};

  @media screen and (max-width: 374px) {
    font-size: 10px;
    font-weight: 600;
  }
`;

export const ArrowContainer = styled.div<{ arrowColor: string }>`
  display: flex;

  .arrow {
    & > path {
      fill: ${({ arrowColor }) => arrowColor};
    }
  }

  .arrow-left {
    animation: opacity 900ms 300ms ease-in-out infinite;
    transform: rotate(90deg);
  }

  .arrow-right {
    animation: opacity 900ms 300ms ease-in-out infinite;
    transform: rotate(-90deg);
  }

  .arrow-with-margin-left {
    animation: opacity 900ms ease-in-out infinite;
    margin-right: -5px;
  }

  .arrow-with-margin-right {
    animation: opacity 900ms ease-in-out infinite;
    margin-left: -5px;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;
