import de from './de.json';
import en from './en.json';
import es from './es.json';
import no from './no.json';
import ru from './ru.json';
import pl from './pl.json';
import ro from './ro.json';
import pt from './pt.json';
import sv from './sv.json';
import th from './th.json';
import tr from './tr.json';
import zh_cn from './zh_cn.json';
import zh_tw from './zh_tw.json';
import { i18n } from 'locales';

i18n.addResourceBundle('de', 'double', de, true);
i18n.addResourceBundle('en', 'double', en, true);
i18n.addResourceBundle('es', 'double', es, true);
i18n.addResourceBundle('no', 'double', no, true);
i18n.addResourceBundle('ru', 'double', ru, true);
i18n.addResourceBundle('pl', 'double', pl, true);
i18n.addResourceBundle('ro', 'double', ro, true);
i18n.addResourceBundle('pt', 'double', pt, true);
i18n.addResourceBundle('sv', 'double', sv, true);
i18n.addResourceBundle('th', 'double', th, true);
i18n.addResourceBundle('tr', 'double', tr, true);
i18n.addResourceBundle('zh_cn', 'double', zh_cn, true);
i18n.addResourceBundle('zh_tw', 'double', zh_tw, true);
