import React, { FC, useEffect, useState, memo } from 'react';
import { isEqual } from 'lodash';
import { colorDictionaryForCaseItem } from 'core/utils';
import { Game } from 'games/CaseBattle/interfaces';
import { UserRoundsContainer } from './Rounds.styles';
import { PlayerItemCardSkeleton } from '../Skeletons/PlayerItemCardSkeleton';
import { IPlayer, useGetMaxPrice } from 'games/CaseBattle/hooks';
import { splitString } from 'games/CaseBattle/utils';
import { GameStatus } from 'games/CaseBattle/constants';
import { PlayerCaseItem } from '../PlayerCaseItem';

interface RoundsProps {
  game: Game;
  gameStatus: GameStatus;
  player: IPlayer;
}

export const Rounds: FC<RoundsProps> = memo(({ game, player, gameStatus }) => {
  const { cases_count, rounds } = game;
  const { maxPriceByRounds, maxPriceGame } = useGetMaxPrice(rounds);
  const [cardHeight, setCardHeight] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    return () => {
      setCardHeight(null);
    };
  }, [cardHeight, gameStatus]);

  const skeletonArray = new Array(cases_count - player.playerRounds?.length)
    .fill(0)
    .map((_, index) => index);

  return (
    <>
      <UserRoundsContainer key={player.playerInfo.user_id} rounds={cases_count}>
        {player.playerRounds.map(({ user_id, result, roundNumber, caseId }) => (
          <PlayerCaseItem
            key={`${user_id}${roundNumber}`}
            exterior={splitString(result.baseItem?.exterior ?? '')}
            shortName={result.baseItem.shortName}
            skin={result.baseItem.skin}
            image={result.baseItem.icon}
            color={colorDictionaryForCaseItem[result.baseItem.color.toLocaleUpperCase()]}
            isMaxExpensiveInRound={maxPriceByRounds[roundNumber] === result.price}
            price={result.price}
            caseId={caseId}
            maxPrice={maxPriceGame}
            name={result.baseItem.name}
            setCardHeight={height => setCardHeight(height)}
          />
        ))}
        {skeletonArray.map(value => (
          <PlayerItemCardSkeleton
            key={value}
            cardHeight={cardHeight}
            hasRounds={!!player.playerRounds.length}
            cardWidth={cardWidth}
            setCardWidth={width => setCardWidth(width)}
          />
        ))}
      </UserRoundsContainer>
    </>
  );
}, isEqual);
