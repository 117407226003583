import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStoreDetailed } from '../../selector';
import { IStore } from 'store/interface';
import { useCallback } from 'react';
import { changeSingleFilters } from '../../ducks/filters.duck';
import { StoreFiltersConfig } from 'modules/marketplace/configs';

const checkSortType = (value: string | number): string => {
  const stringValue = String(value);

  const ObjectTypeOfFilter = {
    [String(StoreFiltersConfig.overprices.get(stringValue))]: 'maxOverprice',
    [String(StoreFiltersConfig.underprices.get(stringValue))]: 'minOverprice',
    sortBy: 'sortBy',
    reset: 'reset',
  };

  return ObjectTypeOfFilter[stringValue];
};

export const useStoreSingleFilter = <T>(path: string) => {
  const values = useSelector<IStore, T>(getStoreDetailed<T, string>(path), shallowEqual);
  const dispatch = useDispatch();

  const changeDetailed = useCallback(
    (value: string | number) => () => {
      dispatch(changeSingleFilters(path, value, checkSortType(value)));
    },
    [path, dispatch]
  );

  return {
    changeDetailed,
    values,
  };
};
