import React, { FC } from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { useStoreRefresh, useStoreSelectedFilters, useStoreSingleFilter } from '../../../hooks';
import { MarketplaceDialogs } from '../../../marketplace-dialogs.config';
import { StoreFiltersConfig } from '../../../configs';
import { IFiltersRecord } from '../../../interfaces';

export const StoreFilters: FC = () => {
  const match = useRouteMatch();
  const { t } = useTranslation('marketplace');
  const { refreshStoreAction } = useStoreRefresh();
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.MARKETPLACE_SINGLE_FILTER);
  const { values } = useStoreSingleFilter<IFiltersRecord['sortBy']>('sortBy');
  const { selected, resetPage } = useStoreSelectedFilters();

  const handleClick = () => {
    refreshStoreAction();
    resetPage();
  };

  return (
    <>
      <Container>
        <Button className="secondary refresh" onClick={handleClick}>
          <i className="icon-refresh" />
        </Button>
        <Button className="secondary search" as={Link} to={`${match.url}/filters`}>
          <i className="icon-search" />
        </Button>
        <Button
          className="secondary"
          onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_SINGLE_FILTER, ['sortBy'])}
        >
          <div className={`sort-type ${StoreFiltersConfig.sortBy(values)}`}>
            <i className="icon-asc-sort-price" />
            <i className="icon-desc-sort-price" />
            <i className="icon-asc-sort-name" />
            <i className="icon-desc-sort-name" />
            <i className="icon-sort-overprice" />
            <i className="icon-sort-underprice" />
          </div>
          {t(`sortBy.${StoreFiltersConfig.sortBy(values)}`, {
            context: 'short',
          })}
          <div className="arrows" />
        </Button>
        <Button className="secondary" as={Link} to={`${match.url}/filters`}>
          {t('more filters')}

          {!!selected && <span className="badge">{selected}</span>}
        </Button>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.75rem 2.5rem 2.5rem 1fr 1fr 0.75rem;
  grid-template-rows: 2.75rem;
  grid-gap: 0.25rem;
  height: 2.75rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0.75rem;
  }

  & .search,
  & .refresh {
    width: 2.5rem;
    font-size: 1.25rem;
  }

  & .arrows {
    width: 10px;
    margin-left: 0.8rem;

    &:after,
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
    }

    &:before {
      transform: translate(0, 7px);
    }

    &:after {
      transform: rotate(180deg) translate(0, 7px);
    }
  }

  & a,
  & button {
    align-items: center;
    justify-content: space-around;
    height: 2.5rem;
    padding: 0 0.75rem;

    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    white-space: nowrap;
    text-transform: none;

    & .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      width: 0.75rem;
      height: 0.75rem;
      background: var(--primary);
      border-radius: 50%;

      font-weight: bold;
      font-size: 0.5625rem;
      line-height: 0.75rem;
      color: black;
    }
  }

  & .sort-type {
    width: 1.125rem;
    height: 0.75rem;
    margin-right: 0.8rem;
    position: relative;

    &.ASC_PRICE .icon-asc-sort-price,
    &.DESC_PRICE .icon-desc-sort-price,
    &.ASC_NAME .icon-asc-sort-name,
    &.DESC_NAME .icon-desc-sort-name,
    &.OVERPRICE .icon-sort-overprice,
    &.UNDERPRICE .icon-sort-underprice {
      opacity: 1;
    }

    & i {
      font-size: 1.2rem;
      color: var(--color-white);
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color 150ms ease-out;
    }
  }
`;
