import React, { memo } from 'react';
import { Container, Description, Title } from './event-description.styles';
import { useTranslation } from 'react-i18next';

export const EventDescription = memo(() => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('searchItems')}</Title>
      <Description>{t('descriptionComics')}</Description>
    </Container>
  );
});
