import { CaseListState } from 'games/CaseGame';
import { generateId } from 'games/CaseGame/utils';

export const getCasesByRandom = (value: number, caseList: CaseListState) => {
  let residues = 0;
  const cases = [];
  const casesForRandom = caseList.data.caseList

    .filter(
      ({ lastRevision, enabled }) =>
        !!enabled && lastRevision.price < value / 3 && lastRevision.price >= value / 10
    )
    .map(({ lastRevision, id }) => ({ id, price: lastRevision.price }));

  while (residues <= value && cases.length < 10) {
    const index = Math.floor(Math.abs(Math.random() * casesForRandom.length));

    cases.push({
      id: casesForRandom[index]?.id,
      uniqId: generateId(10),
      casePrice: casesForRandom[index]?.price,
    });

    residues += casesForRandom[index]?.price;
  }

  if (residues > value) {
    residues -= cases[cases.length - 1].casePrice;
    cases.pop();
  }

  return { cases, price: residues };
};
