import styled from 'styled-components';
//NOTE: for event all components
export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 7px;
  transform: translate3d(24px, 0, 0);
`;

export const BarInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BarName = styled.span`
  display: flex;
  flex-flow: row nowrap;
  font-weight: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BarProgress = styled.span`
  color: #9ea7b3;
  font-size: 12px;
  font-weight: inherit;
  letter-spacing: 0.1px;
  line-height: 15px;
`;

export const CategoryImage = styled.img`
  max-height: 30px;
`;
