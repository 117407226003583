import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <Container>
        <RulesP>
          <RulesSpan>Crash game </RulesSpan>
          {t(
            'is a mode, where players have to press Stop button before Crash comes Crash comes randomly but as long as you are in game the coefficient rises as your winning'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How to get the winning?')} </RulesSpan>
          {t(
            // eslint-disable-next-line
            "Bet ${currency(amount)} before round will start and choose the strategy: manual or automatic with the coefficient you want. With the game beginning the coef starts to grow up till the CRASH comes. Those who press Stop button in time before the Crash win the game. Winners' winning rise depends of the coefficient on which you stop the game.",
            { amount: 1, postProcess: 'with-template' }
          )}
        </RulesP>

        <RulesDistinguish>
          {t(
            'Using automatic strategy you can also press Stop button before the coefficient you put in the box.'
          )}
        </RulesDistinguish>

        <RulesP>
          <RulesSpan>{t('How is the winner slot determined (fair game)?')} </RulesSpan>
          {t(
            'At the beginning of each round, the service generates a random long random number from 0 to 1 (for example, 0.223088) and encrypts it through the sha224 algorithm. The result of the encryption can be seen in the early rounds. At the end of the round, the service uses this random number to calculate the coefficient using the following formula:'
          )}
        </RulesP>

        <RulesDistinguish>
          {'let coef = 0.01 + 0.99 / (1 - random);'} <br /> <br />
          {'if (Math.floor(coef * 100) % 33 === 0) {'} <br />
          &nbsp;&nbsp; {'  coef = 1; '} <br />
          {'} else { '} <br />
          &nbsp;&nbsp; {' coef = Math.round(coef * 100) / 100; '} <br />
          {'}'}
        </RulesDistinguish>

        <RulesP>
          {t(
            'Example: At the beginning of the round, the number 0.24412312303230728 was encrypted. The service uses the formula above to get the number 1.32.'
          )}
        </RulesP>

        <RulesP>
          {t(
            'You can independently verify the correct definition of the victorious sector. To do this, at the end of the round, take a number that has been encrypted and re-encode it using any of the online services, for example http://sha224.net. You will see the same value of hash as in the beginning of the round. This means that the result of the game was not adjusted.'
          )}
        </RulesP>

        <RulesP>
          {t(
            'Since the system determines the final coefficient even before the game starts and any player can control the absence of its changes - interference in the game makes no sense for us. Therefore, this system is the guarantor of the honesty of our games.'
          )}
        </RulesP>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding: 16px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 14px;
`;

const RulesSpan = styled.span`
  color: #33ffff;
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;

const RulesDistinguish = styled.div`
  border-radius: 15px;
  background: var(--gray-300);
  padding: 10px;
  color: var(--color-white);
  font-size: 14px;
`;
