import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-bottom: 56px;
`;

export const BtnCases = styled.div`
  color: var(--aqua-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;
`;

export const BalanceContainer = styled.div`
  max-width: 340px;
  min-width: 300px;
  margin: 0 auto;
`;
