import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  DroppedItemsResultsModified,
  useGetRouletteStatus,
  useGetSpeedType,
  useRouletteBuilder,
} from 'games/CaseGame/hooks';
import { RouletteItemBeforeRoll } from './components';
import { Container } from './RouletteBuilder.styles';
import { RouletteStatus, rouletteStatusActionAfterRoll } from 'games/CaseGame/ducks';
import { useDispatch } from 'react-redux';

interface RouletteBuilderProps {
  ids: number[];
  lineCount: number;
  lineIndex: number;
  size: number;
  isNeedToShowEventBackground?: boolean;
}

export const RouletteBuilder: React.FC<RouletteBuilderProps> = ({
  ids,
  lineCount,
  lineIndex,
  size,
  isNeedToShowEventBackground,
}) => {
  const [shiftLeft, setShiftLeft] = useState(0);
  const [items, setItems] = useState<Partial<DroppedItemsResultsModified>[] | null>(null);
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const { rouletteStatus, isAllInRoll, isAllStartRoll, isAllAfterRoll } = useGetRouletteStatus();
  const rouletteItems = useRouletteBuilder(ids, lineIndex);
  const { speedType } = useGetSpeedType();

  useLayoutEffect(() => {
    const element = ref.current;
    if (element && size) {
      const itemCoordinateX = element.offsetLeft;
      const itemWidth = element.clientWidth;
      const shift = size - itemCoordinateX - itemWidth / 2;
      setShiftLeft(shift);
      setWidth(element.clientWidth);
    }
  }, [size]);

  const inAnimationEndHandler = () => {
    setItems([...rouletteItems.slice(27, 30)]);
    dispatch(rouletteStatusActionAfterRoll(lineIndex, lineCount));
  };

  return (
    <Container
      lineIndex={lineIndex + 1}
      left={shiftLeft}
      data-speed-type={speedType}
      data-anima={rouletteStatus[lineIndex] === RouletteStatus.IN_ROLL ? 'anima' : ''}
      itemWidth={width}
      onAnimationEnd={inAnimationEndHandler}
    >
      {(items ?? rouletteItems)?.map(
        (item: Partial<DroppedItemsResultsModified>, rouletteItemBeforeRollIndex: number) => (
          <RouletteItemBeforeRoll
            isAllAfterRoll={isAllAfterRoll}
            isAllStartRoll={isAllStartRoll}
            isProfit={item?.isProfit}
            isAllInRoll={isAllInRoll}
            itemId={item?.userInventoryItemId}
            key={rouletteItemBeforeRollIndex}
            lineCount={lineCount}
            lineIndex={lineIndex}
            isInit={rouletteItemBeforeRollIndex === 1}
            shortName={item?.caseRevisionItem?.inventoryItem.baseItem.shortName}
            skin={item?.caseRevisionItem?.inventoryItem.baseItem.skin}
            color={item?.caseRevisionItem?.inventoryItem.baseItem.color}
            name={item?.caseRevisionItem?.inventoryItem.baseItem.name}
            icon={item?.caseRevisionItem?.inventoryItem.baseItem.icon}
            price={item?.winningPrice}
            UUID={item?.openUUID}
            isNeedToShow={isNeedToShowEventBackground}
            ref={ref}
          />
        )
      )}
    </Container>
  );
};
