import { sortParams } from './sort-duck';
import { caseList } from './case-list-duck';
import { category, selectedCategory } from './category-duck';
import { combineReducers } from 'redux';
import { caseData } from './case-items-duck';
import { revisionData } from './case-revision-duck';
import { caseItemInfoDetailed } from './case-item-info-duck';
import {
  rouletteReducer,
  rouletteStatusReducer,
  winItemStatusReducer,
  openCaseTypeReducer,
  soundTypeReducer,
  speedTypeReducer,
} from './roulette-duck';
import { motivatorDataReducer } from './motivator-duck';
import { caseHistoryReducer } from './case-history-duck';

const reducer = combineReducers({
  caseList,
  category,
  sortParams,
  caseData,
  revisionData,
  caseItemInfoDetailed,
  rouletteReducer,
  rouletteStatusReducer,
  winItemStatusReducer,
  openCaseTypeReducer,
  soundTypeReducer,
  speedTypeReducer,
  motivatorDataReducer,
  caseHistoryReducer,
  selectedCategory,
});

export default reducer;
