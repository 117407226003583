import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px 56px;
`;

export const CaseShopHeader = styled.span`
  color: #f7fbfc;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`;

export const CaseListItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;
