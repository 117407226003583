import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { loadProfile } from '../duck';

export const useLoadAccountProfile = () => {
  const dispatch = useDispatch();

  const handleOpenProfile = useCallback(
    (userId: number) => () => {
      dispatch(loadProfile(userId));
    },
    [dispatch]
  );

  return {
    handleOpenProfile,
  };
};
