import { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { requestHistory, cleanUpHistory } from '../duck';
import { getUser } from 'core/User/selectors';

export enum filterableSwitch {
  recent = 'recent',
  own = 'own',
}

export const useHistoryHandles = (gameType: number) => {
  const [filterable, setFilterable] = useState<filterableSwitch.own | filterableSwitch.recent>(
    filterableSwitch.recent
  );

  const user = useSelector(getUser, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    //set debounce value to send request
    dispatch(cleanUpHistory());
    const debounceId = setTimeout(() => {
      if (filterable === filterableSwitch.own) {
        dispatch(requestHistory(gameType, Number(user.id)));
      } else {
        dispatch(requestHistory(gameType));
      }
    }, 1000);

    return () => {
      clearTimeout(debounceId);
    };
  }, [gameType, filterable, dispatch, user.id]);

  //clean up history (CWU)
  useEffect(() => {
    return () => {
      dispatch(cleanUpHistory());
    };
  }, [dispatch]);

  const handleSwitch = useCallback(() => {
    setFilterable(
      filterable === filterableSwitch.own ? filterableSwitch.recent : filterableSwitch.own
    );
  }, [filterable]);

  return {
    filterable,
    handleSwitch,
    user,
  };
};
