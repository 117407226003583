import { shallowEqual, useSelector } from 'react-redux';
import { getFactorType, getPlayer, getPreviousFactorType, getProtecFactor } from '../selectors';
import type { IStore } from 'store/interface';

export const useFactorController = (type: string) => {
  const previousFactorType = useSelector<IStore, any>(
    state => getPreviousFactorType(state, type),
    shallowEqual
  );

  const factorType = useSelector<IStore, any>(state => getFactorType(state, type), shallowEqual);

  const player = useSelector<IStore, any>(state => getPlayer(state, type), shallowEqual);
  const protectFactor = useSelector<IStore, any>(
    state => getProtecFactor(state, type),
    shallowEqual
  );

  return {
    previousFactorType,
    protectFactor,
    factorType,
    player,
  };
};
