export default {
  'Fair game': '公平游戏',
  Bet: '赌注',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = 停用)',
  'Bet coins': '賭金幣',
  Stop: '停止',
  'Wait for next round...': '等待下一輪',
  Example: '示例',
  '!! Crash !!': '爆炸時刻',
  'You won': '您获胜了',
};
