import { IInventoryElement, IInventoryRecord } from './inventory.reducers.interface';

export enum SteamInventoryActionTypes {
  FETCH_STEAM_INVENTORY = 'app/marketplace/inventory/FETCH_STEAM_INVENTORY',
  FETCH_STEAM_INVENTORY_SUCCESS = 'app/marketplace/inventory/FETCH_STEAM_INVENTORY_SUCCESS',
  FETCH_STEAM_INVENTORY_FAILURE = 'app/marketplace/inventory/FETCH_STEAM_INVENTORY_FAILURE',

  CHANGE_STEAM_INVENTORY_FILTERS = 'app/marketplace/inventory/CHANGE_STEAM_INVENTORY_FILTERS',

  SORTED_STEAM_INVENTORY = 'app/marketplace/inventory/SORTED_STEAM_INVENTORY',
}

export interface IFetchSteamInventory {
  type: typeof SteamInventoryActionTypes.FETCH_STEAM_INVENTORY;
}

export interface IFetchSteamInventorySuccess {
  type: typeof SteamInventoryActionTypes.FETCH_STEAM_INVENTORY_SUCCESS;
  payload: {
    identifiers: string[];
    entities: Record<string, IInventoryElement>;
    amount: number;
  };
}

export interface IFetchSteamInventoryFailure {
  type: typeof SteamInventoryActionTypes.FETCH_STEAM_INVENTORY_FAILURE;
  payload: string;
}

export interface IChangeInventoryFilters {
  type: typeof SteamInventoryActionTypes.CHANGE_STEAM_INVENTORY_FILTERS;
  payload: {
    filters: Partial<IInventoryRecord['filters']>;
  };
}

export interface ISortedInventoryItems {
  type: typeof SteamInventoryActionTypes.SORTED_STEAM_INVENTORY;
  payload: {
    identifiers: string[];
  };
}

export type steamInventoryActions =
  | IFetchSteamInventory
  | IFetchSteamInventorySuccess
  | IFetchSteamInventoryFailure
  | IChangeInventoryFilters
  | ISortedInventoryItems;
