import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  height: fit-content;
  overflow: scroll;
  background: var(--color-gray-700);

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  border: 2px solid var(--color-white-gray);
  border-radius: 4px;
  justify-content: center;
  width: fit-content;

  &[data-is-active='selected'] {
    background: var(--color-white-gray);
  }

  &[data-is-active='notSelected'] {
    background: var(--color-dark-100);
  }

  &[data-disabled='disabled'] {
    opacity: 0.5;
    pointer-events: none;
  }

  &[data-disabled='notDisabled'] {
    opacity: 1;
  }
`;

export const Span = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.3px;
  color: var(--color-white);
  padding: 7px 14px;
  white-space: nowrap;
`;
export const ContainerRange = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 12px;
  padding: 0 18px;
`;

export const RangeSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const HeaderSlider = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 21px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-50);
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
`;
