export default {
  GAME: 'GRA',
  Winner: 'Zwycięzca',
  Won: 'Wygrał',
  Chance: 'Szansa',
  Prize: 'Nagroda',
  'At stake': 'Zakład o',
  'Sign in through STEAM to participate': 'Zarejestruj się na portalu STEAM, aby uczestniczyć',
  'Steam trade link': 'Link na wymianę w Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Zrób depozyt jako pierwszy',
  'Place a bet': 'Postaw zakład',
  'Deposit more': 'Wnieś jeszcze',
  'Min bet': 'Min. zakład',
  'Max bet': 'Maks. zakład',
  'Bet amount': 'Wysokość zakładu',
  'max. items': 'maksimum przedmiotów',
  hidden: 'ukryty',
  Copy: 'Kopiuj',
  'Winning after commission': 'Wygrana po prowizji',
  Loading: 'Ładowanie',
};
