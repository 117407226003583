import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { Timer } from '@components/TimerEvent';
import { getItemUri } from 'core/utils/image.utils';
import { useModal } from 'core/Modals';
import { useUser } from 'core/User/hooks';
import { usePurchaseAction, usePurchaseModal } from '../../../hooks';
import { leaderModals } from '../../../modal.config';
import { IModalComponent } from 'core/Modals/container';
import { SteamAppId } from '../../../interfaces/reducer.leaders.interfaces';

const availableAt = +new Date('2020-12-24T12:00:00');

export const Purchase: FC<IModalComponent> = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { handleCloseModal } = useModal(leaderModals.PURCHASE);
  const { item } = usePurchaseModal(data);
  const [handlePurchase] = usePurchaseAction();

  return (
    <Container className={`${item.ticketColor}`}>
      <div className="description">
        <i className={`icon-${item.appId}`} />
        <img src={getItemUri(item.icon, 350, 350)} alt="skin" />
        <span style={{ color: item.color }}>{item.name}</span>
        <div className="skin-info">
          <span className="skin-price">
            <i className="icon-coupon" />
            {item.ticketsAmount}
          </span>
        </div>
      </div>
      <div className="modal-actions">
        {Array.isArray(data) && data[0] === SteamAppId.GOLD && Date.now() < availableAt ? (
          <Button className="primary" disabled>
            <Timer finishEvent={availableAt} />
          </Button>
        ) : (
          <Button
            className="primary"
            onClick={handlePurchase(item.name, item.appId, item.ticketColor, item.id)}
            disabled={!user.id || !user.steamid_3}
          >
            {t('Buy')}
          </Button>
        )}

        <Button className="link-light" onClick={handleCloseModal}>
          {t('Close')}
        </Button>
      </div>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 100;
  border-top: solid 1px var(--gray-250);
  background-color: var(--color-gray-700);
  padding: 1rem;

  &.green {
    --ticket-color: var(--color-green-ticket);
  }
  &.blue {
    --ticket-color: var(--color-blue-ticket);
  }
  &.orange {
    --ticket-color: var(--color-orange-ticket);
  }

  & .description {
    display: grid;
    grid-template-rows: 130px 50px 50px;
    grid-gap: 0.25rem;

    position: relative;

    & > * {
      align-self: center;
    }

    & > i {
      position: absolute;
      left: 0;
      top: 65px;
      opacity: 0.3;
      color: var(--color-white);
    }

    & > img {
      width: 70%;
      height: 130px;
      object-fit: contain;
      justify-self: center;
    }

    & > span {
      width: 100%;
      align-self: center;

      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.44;
      letter-spacing: 0.76px;
      text-align: center;
      color: var(--color-white);
      text-transform: capitalize;
    }

    & .skin-info {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-evenly;

      & .skin-price {
        color: #748086;
        font-size: 1.1rem;
        font-weight: bold;

        & > i {
          color: var(--ticket-color);
          font-size: 1rem;
          font-weight: bold;
          margin: 0 0.25rem;
        }
      }
    }
  }

  & .modal-actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.5rem;
  }
`;
