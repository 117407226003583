import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 2.25rem);
`;

export const BtnWrap = styled.div`
  height: 2.25rem;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;
