import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from './arrow.svg';

export const CoefBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: space-between;
  max-height: 95px;
  max-width: 60%;
`;

export const CoefInfo = styled.span`
  color: var(--color-white);
  font-size: 40px;
  text-align: center;
`;

export const BetBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const BetTitle = styled.span`
  color: var(--color-gray-90);
  font-size: 12px;
  letter-spacing: 1.5px;
  padding: 0 3px;
`;

export const CrashTitle = styled.span`
  color: var(--color-red-200);
  font-size: 24px;
  font-weight: bold;
`;

export const BetValue = styled.span`
  color: var(--color-green-100);
  font-size: 15px;
  font-weight: bold;
  padding: 0 3px;
`;

export const AutoStopValue = styled.span`
  color: var(--ochre);
  font-size: 15px;
  padding: 0 3px;
  text-align: center;
`;

export const PointerArrow = styled.div<{ crashed: boolean }>`
  padding-top: 15px;
  transform-origin: center center;
  transform: rotate(${({ crashed }) => (crashed ? '180deg' : '0deg')});
  transition: transform linear 0.1s;
`;

export const ArrowSvg = styled(ArrowIcon)<{ color: string }>`
  fill: ${({ color }) => color};
`;
