import React, { FC, useEffect, useState } from 'react';
import { PlayerSlot } from './PlayerSlot';
import { RaffleTape } from './RaffleTape';
import { usePlayerTrade } from '../../../hooks';
import { PlayersArea } from '../styles';

import { IFastGame } from '../../../interfaces/reducer.fast.interface';

interface IActiveGame {
  gameData: IFastGame;
}

export const ActiveGame: FC<IActiveGame> = React.memo(({ gameData }) => {
  const [status, setStatus] = useState(!!gameData.winner);
  const { sortedTradeId } = usePlayerTrade(gameData.trades);

  useEffect(() => {
    setTimeout(() => setStatus(!!gameData.winner), 2000);
  }, [gameData.winner, setStatus]);

  return (
    <>
      {status ? (
        <RaffleTape trades={gameData.trades} winner={gameData.winner as number} />
      ) : (
        <PlayersArea>
          {sortedTradeId.map((userId, index) => (
            <PlayerSlot
              key={userId || index}
              player={userId ? gameData.trades[userId] : null}
              index={index}
              prize={gameData.prize}
            />
          ))}
        </PlayersArea>
      )}
    </>
  );
});
