import { currencyService } from 'services/currency';

export const getMinimalMaxValue = (userBalance: number, min: number, rate: number) => {
  const maximum = Math.min(userBalance, 50000);
  const fastCoinRate = (1 / currencyService.getRate('fastcoins_refill') / 100).toFixed(2);
  const minimum = rate ? min / (Number(rate) / +fastCoinRate) : 0;
  return {
    minimum,
    maximum,
  };
};
