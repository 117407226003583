import React, { FC } from 'react';
import { Button } from '@components/Common';
import {
  SteamErrorsEnum,
  useUserPermission,
  useUserProviders,
  useWatchSteamSettings,
} from 'core/User';
import { useBindingProvider } from 'core/Auth';
import { IDialogComponent } from 'core/interfaces';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../core/hooks';
import { userModals } from 'core/User/modal.config';
import { Container } from './steam-binding-dialog.styles';
import { URLS } from 'services/constant-urls';

interface ISteamSettingsDialog extends IDialogComponent<boolean> {}

const canWithdraw: SteamErrorsEnum[] = [
  SteamErrorsEnum.INVALID_TRADELINK,
  SteamErrorsEnum.PRIVATE_INVENTORY,
];

export const SteamBindingDialog: FC<ISteamSettingsDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation(['steam-settings', 'market', 'translations']);
  const { handleToggleDialog } = useDialog(userModals.STEAM_SETTINGS);
  const { userProviders } = useUserProviders();
  const { handleBindingProvider } = useBindingProvider();
  const { handleOpenSettings } = useWatchSteamSettings();
  const { permissions } = useUserPermission();

  const hasSteam = !!userProviders['steam'];
  const privetInventory = permissions?.canTrade?.error === SteamErrorsEnum.PRIVATE_INVENTORY;

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <div className="dialog-title">{t('Data for skins withdrawing')}</div>
      <div className="inner-container">
        <span>{t('Step {{ n }}', { n: 1 })}</span>
        <Button onClick={handleBindingProvider('steam')} disabled={hasSteam}>
          <i className="icon-steam-provider" />
          <span>{t('Add steam')}</span>
        </Button>
        <span>
          {t('Step {{ n }}', { n: 2 })} {t('(Available after completing of step 1)')}
        </span>
        <Button onClick={handleOpenSettings(URLS.steamSettings)} disabled={!privetInventory}>
          <i className="icon-steam-provider" />
          <span>{t('marketplace:::Make inventory public')}</span>
        </Button>
      </div>
      {canWithdraw.includes(permissions?.canTrade?.error) ? (
        <div onClick={handleCloseModal}>
          <Button
            className="primary"
            onClick={handleToggleDialog(userModals.STEAM_SETTINGS, true)}
            disabled={privetInventory || !hasSteam}
          >
            {t('Add Steam trade-link')}
          </Button>
        </div>
      ) : (
        <Button
          className="primary"
          onClick={handleCloseModal}
          disabled={!hasSteam || privetInventory}
        >
          {t('translations:::Confirm')}
        </Button>
      )}
    </Container>
  );
};
