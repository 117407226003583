import styled from 'styled-components';
import { animated } from 'react-spring';
import { authProviderConfig } from 'core/Auth';

export const Container = styled.div`
  display: block;
  height: 100vh;
  overflow: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  & .buffer-container {
    display: block;
    height: calc(100vh - 342px);
    width: 100%;
  }

  & .wrapper {
    background: var(--color-dark-600);
    border-top: 1px solid var(--color-white-gray-50);
    color: white;
    display: flex;
    flex-flow: column;
  }

  .auth-agree-container {
    align-items: center;
    backdrop-filter: blur(4px);
    background: rgba(24, 34, 40, 0.85);
    bottom: 0;
    display: flex;
    flex-flow: column;
    font-size: 0.625rem;
    justify-content: space-between;
    left: 0;
    letter-spacing: 0.5px;
    line-height: 1rem;
    padding: 0 1.875rem;
    position: sticky;
    right: 0;
    text-align: center;
    z-index: 10;

    & > div {
      max-width: 300px;
      padding: 17px 0;
      width: 100%;

      & .tos {
        color: var(--aqua-500);
        font-size: 0.625rem;
      }

      & > label {
        display: inline-block;
        text-align: left;
        width: 100%;
      }
    }
  }

  .auth-body-container {
    align-items: center;
    backdrop-filter: blur(4px);
    background: rgba(24, 34, 40, 0.85);
    display: flex;
    height: 5.4375rem;
    justify-content: center;
  }

  & .auth-title {
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.54px;
  }

  & .auth-providers-container {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.75rem;
    grid-template-rows: repeat(8, 3.125rem);
    justify-items: center;
  }

  & .link-info {
    font-size: 0.625rem;
    font-weight: bold;
    min-height: 2rem;
    text-transform: none;
    padding-bottom: 16px;
  }
`;

export const Info = styled.span`
  color: var(--aqua-500);
  display: block;

  &.hide {
    display: none;
  }
`;

export const ProviderButton = styled.button`
  align-items: center;
  background: var(--color-gray-700);
  border-radius: var(--size-radius-m);
  border: 0;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  position: relative;
  transition: all var(--transition-ease-out);
  width: 15.935rem;

  ${authProviderConfig.map(
    provider => `
    &.${provider} {
      background: var(--color-provider-${provider});
    }
  `
  )};

  & span {
    color: var(--color-white);
    font-size: 0.8125rem;
    font-weight: bold;
    left: 6.6875rem;
    letter-spacing: 0.286px;
    position: absolute;
    text-transform: uppercase;
  }

  &.yandex {
    background: var(--aqua-900);
    color: var(--color-provider-yandex);

    & span {
      color: inherit;
    }
  }

  .icon-container {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 1.1rem;
    justify-content: center;
    left: 4.5625rem;
    position: absolute;
    transform: translateX(-50%);
  }

  &.active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.2;
  }
`;

export const AnimatedContainer = styled(animated.div)`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
`;

export const InfoFull = styled.span`
  overflow: hidden;
`;
