import React, { Fragment, useCallback } from 'react';
import * as ReactDOM from 'react-dom';
import { useTransition } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { DIALOG } from './dialog-combiner';
import { transitionsConfig } from './transition.config';
import { dialogOff, getDialogs } from 'core/ducks/dialog';
import { AnimatedDiv, AnimatedLayout } from './dialog.styles';

export const Dialog = () => {
  const dialogs = useSelector(getDialogs);
  const dispatch = useDispatch();

  const onClose = useCallback(
    (name: string) => () => {
      dispatch(dialogOff(name));
    },
    [dispatch]
  );
  const t = useTransition<any, any>(dialogs, ([name]) => name, {
    from: ([name]) => transitionsConfig[DIALOG.get(name).transition].from,
    enter: ([name]) => transitionsConfig[DIALOG.get(name).transition].enter,
    leave: ([name]) => transitionsConfig[DIALOG.get(name).transition].leave,
    // config: ([name]) => transitionsConfig[DIALOG.get(name).transition].duration(),
  });

  return (
    <>
      {t.map(({ item: [name, data], props: { layout, zIndex, ...styles }, key }) => (
        <Fragment key={key}>
          {ReactDOM.createPortal(
            <>
              {React.createElement(AnimatedLayout, {
                style: { opacity: layout, zIndex },
                className: `${DIALOG.get(name).transition}${
                  DIALOG.get(name)?.class ? ` ${DIALOG.get(name).class}` : ''
                }`,
                onClick: onClose(name),
              })}
              <AnimatedDiv style={{ ...styles, zIndex }} className={DIALOG.get(name).transition}>
                {React.createElement(DIALOG.get(name).component, {
                  handleCloseModal: onClose(name),
                  data,
                })}
              </AnimatedDiv>
            </>,
            // @ts-ignore
            document.getElementById(DIALOG.get(name).anchor) as HTMLElement
          )}
        </Fragment>
      ))}
    </>
  );
};
