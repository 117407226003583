import styled from 'styled-components';

export const Title = styled.div`
  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
`;
