import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { StickySelect } from '@components/Selects';
import { Control, Option } from '../components';

import { saveLocale } from 'core/AppShell/duck';
import { getAppLocale } from 'core/AppShell/selectors';
import { i18n } from 'locales';

export const LanguageSelector = () => {
  const language = useSelector(getAppLocale, shallowEqual);
  const dispatch = useDispatch();

  const languages = Object.keys(i18n.services.resourceStore.data);
  const select = languages.findIndex((lng: string) => lng === language);

  const onChangeLng = React.useCallback(
    (index: number) => {
      dispatch(saveLocale(languages[index] || 'en'));
    },
    [dispatch, languages]
  );

  return (
    <StickySelect
      options={languages}
      select={select}
      onChange={onChangeLng}
      components={{
        Option,
        Control,
      }}
    />
  );
};
