import React from 'react';
import styled from 'styled-components';
import { Collapse } from '@components/Animates';
import { ReferralQuestion } from './referral-question';
import { ReferralAnswer } from './referral-answer';

const qa = [
  ['How it works?', 'work steps'],
  ['Referral statuses', 'The statistics show the status of your referrals'],
  [
    'What is the difference between code a and a link?',
    "Use whatever format you want Sharing a link is more convenient, but where you can't leave it, you can use a referral code In both cases, the invited player will become your referral",
  ],
  [
    'Where should my code be entered?',
    'The code entry field opens by clicking on the Bonuses tab in the menu',
  ],
  [
    'How much money will I get?',
    "You will get a part of each referral's bet Only bets from the users with Silver status or higher counts (more about statuses)",
  ],
  [
    'How can I get a reward?',
    'Rewards for the referral program can be received on the balance of the site Use it for games or withdraw it in a convenient way',
  ],
  [
    'What is the minimum amount for withdraw?',
    "There's no minimum amount for withdraw, you could withdraw any amount starting from 0.01$",
  ],
  [
    'Why do I need to create multiple campaigns?',
    'Use different codes and links for each advertising channel to understand which traffic source works better and attract more active players',
  ],
  ['What kind of restrictions are there?', 'Prohibited'],
];

export const ReferralQuestionsAndAnswers = () => (
  <Container>
    {qa.map(([question, answer], i) => (
      <Collapse key={i} header={props => <ReferralQuestion {...props} question={question} />}>
        <ReferralAnswer answer={answer} />
      </Collapse>
    ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  border-top: 1px solid #213137;
  border-bottom: 1px solid #213137;
  margin-bottom: 2rem;

  & > div {
    border-bottom: 1px solid transparent;
  }
`;
