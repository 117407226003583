export default {
  '100$ for the new players!': '¡$ 100 para los nuevos jugadores!',
  '100$ bonus is credited to a demo balance': 'Demo bakiyesine 100 $ bonus yatırılır',
  'Get 100$ now!': '¡Obtén 100 $ ahora!',

  'Top up your balance from 25$ and get bonuses': '25 $ `dan bakiyenizi doldurun ve bonus kazanın',
  'Exp bonus': 'Exp bonusu',
  'Wheel Of Fortune free spin': 'Wheel Of Fortune bedava dönüş',
  'Free case': 'Ücretsiz dava',
  'Access to account level-up': 'Hesap düzeyini yükseltmeye erişim',
};
