import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';
import { socketService } from '../services/socket.service';
import games from 'games';
import modules from 'modules';
import core from 'core';
import { epicMiddleware, rootEpics } from './epicMiddleware';
import { routerMiddleware } from 'connected-react-router';
import { _history } from '../core/AppShell/containers/async-browser-router';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    thunk.withExtraArgument({ socket: socketService }),
    epicMiddleware,
    routerMiddleware(_history)
  )
);

function createReducerManager(initialReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove = [];

  return {
    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state };
        for (let key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      // Delegate to the combined reducer
      return combinedReducer(state, action);
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: key => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },
  };
}

const configureStore = () => {
  const reducerManager = createReducerManager(rootReducer);
  const store = createStore(reducerManager.reduce, enhancer);
  store.reducerManager = reducerManager;

  const allModules = {
    ...games,
    ...modules,
    ...core,
  };
  // ToDo: eslint
  // eslint-disable-next-line
  for (const module in allModules) {
    //iterates all modules or submodule and initializes listeners

    if (allModules[module].childrenModule) {
      const { childrenModule } = allModules[module];
      // eslint-disable-next-line
      for (const key in childrenModule) {
        if (!!childrenModule[key].eventsTypes)
          store.dispatch(initListeners(childrenModule[key].eventsTypes));
      }
    }

    if (!!allModules[module].eventsTypes)
      store.dispatch(initListeners(allModules[module].eventsTypes));
  }

  epicMiddleware.run(rootEpics);

  return store;
};

const initListeners = (eventsTypes = []) => (dispatch, _getState, { socket }) => {
  eventsTypes.forEach(item => {
    socket.addListener({
      event: item.event,
      action: message => dispatch(item.action(message)),
    });
  });
};

export const store = configureStore();
