import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';

interface IRangeInputControl {
  field: any;
  min: number;
  max: number;
  step: number;
  initial: number | string;
}

export const RangeInputControl: FC<IRangeInputControl> = ({ field, min, max, step, initial }) => (
  <Container initial={Number(initial)} value={Number(field.value)} min={min} max={max}>
    <span>
      <CurrencyText value={min} />
    </span>
    <input {...field} min={min} max={max} type="range" step={step} />
    <span>
      <CurrencyText value={max} />
    </span>
  </Container>
);

interface IRangeInputControlStyle {
  initial: number;
  value: number;
  min: number;
  max: number;
}

const Container = styled.div.attrs(({ initial, value, min, max }: IRangeInputControlStyle) => ({
  from: ((initial - min) / (max - min)) * 100,
  to: ((value - min) / (max - min)) * 100,
}))<IRangeInputControlStyle>`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.5rem;

  & span {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-gray-200);
  }

  & input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: 8px 0;
    position: relative;
    top: -3px;
    border-radius: 6px;
    background: ${({ from, to }) =>
      from < to
        ? `linear-gradient(to right, #253138 0% ${from - 1}%, #9EA7B3 ${
            from - 1
          }% ${from}%, #47555F ${from}%, #47555F ${to}%, #253138 ${to}% 100%)`
        : `linear-gradient(to right, #253138 0% ${to}%, #47555F ${to}% ${from - 1}%, #9EA7B3 ${
            from - 1
          }% ${from}%,  #253138 ${from}% 100%)`};

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: 6px;
      border-radius: 6px;
      background: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      margin-top: -5px;
      background: var(--color-dark-100);
      border-radius: 50%;
      cursor: pointer;
      border: 4px solid white;
      //box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
    }
  }
`;
