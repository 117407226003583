import { FairGameDialog } from './fair-game-dialog';
import { FairGameDialogs } from 'core/interfaces';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';

DIALOG.add(FairGameDialogs.FAIR_GAME_DIALOG, {
  component: FairGameDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
