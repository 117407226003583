import {
  IFastInventoryElement,
  IFastInventoryRecord,
  MergeType,
} from './fast-inventory.reducers.interface';

export enum FastInventoryActionTypes {
  FETCH_FAST_INVENTORY = 'app/user/inventory/gofast/FETCH_FAST_INVENTORY',
  FETCH_FAST_INVENTORY_SUCCESS = 'app/user/inventory/gofast/FETCH_FAST_INVENTORY_SUCCESS',
  FETCH_FAST_INVENTORY_FAILURE = 'app/user/inventory/gofast/FETCH_FAST_INVENTORY_FAILURE',

  UPDATE_FAST_INVENTORY_FILTER = 'app/user/inventory/gofast/UPDATE_FAST_INVENTORY_FILTER',

  REFRESH_FAST_INVENTORY = 'app/user/inventory/gofast/REFRESH_FAST_INVENTORY',
}

export interface IFetchFastInventory {
  type: typeof FastInventoryActionTypes.FETCH_FAST_INVENTORY;
}

export interface IFetchFastInventorySuccess {
  type: typeof FastInventoryActionTypes.FETCH_FAST_INVENTORY_SUCCESS;
  payload: {
    info: IFastInventoryRecord['info'];
    items: IFastInventoryElement[];
    merge: MergeType;
  };
}

export interface IFetchFastInventoryFailure {
  type: typeof FastInventoryActionTypes.FETCH_FAST_INVENTORY_FAILURE;
}

export interface IUpdateFastInventoryFilter {
  type: typeof FastInventoryActionTypes.UPDATE_FAST_INVENTORY_FILTER;
  payload: {
    key: string[];
    value: any;
  };
}

export interface IRefreshFastInventory {
  type: typeof FastInventoryActionTypes.REFRESH_FAST_INVENTORY;
}

export type fastInventoryActions =
  | IFetchFastInventory
  | IFetchFastInventorySuccess
  | IFetchFastInventoryFailure
  | IUpdateFastInventoryFilter
  | IRefreshFastInventory;
