import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/logo-go.png';
import loading from 'assets/images/loading.gif';
// import { loader } from 'modules/event-module';

interface IAppStartProps {
  isReconnecting: boolean;
  appIsInitialized: boolean;
}

export const AppStart: FC<IAppStartProps> = ({ isReconnecting }) => {
  const { t } = useTranslation();
  return (
    <Preload>
      <Icon src={loading} />
      <Icon src={logo} />
      {isReconnecting && (
        <Text>
          {t('You were disconnected from CSGOFAST service. Please tap on screen to reconnect.')}
        </Text>
      )}
    </Preload>
  );
};

const Preload = styled.div`
  position: fixed;
  z-index: 999;
  height: 150px;
  width: 201px;
  margin: auto;
  inset: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

const Icon = styled.img`
  width: 150px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;

  color: var(--color-white);
  padding: 30px 0px 5px 0px;
`;
