import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CasesQuestStatusEnum, EntityIdType } from '../../../interfaces';
import { CategoryItemBlocked } from './category-item-blocked';
import { Modules } from 'core/interfaces';
import { actionSetSelectedCategory } from 'games/CaseGame';
import { CategoryItemAvailable } from './category-item-available';
import { actionPickUpPrize } from '../../../duck/default-event-duck';
import { CategoryItemCompleted } from './category-item-completed';

interface CategoryItemProps {
  caseId?: number;
  categoryName: string;
  entityId: EntityIdType;
  isFinal: boolean;
  image: string;
  itemImg?: string;
  openUUID?: string;
  percentProgress: number;
  status: CasesQuestStatusEnum;
}

export const CategoryItem: React.FC<CategoryItemProps> = ({
  caseId,
  categoryName,
  entityId,
  image,
  isFinal,
  itemImg,
  openUUID,
  percentProgress,
  status,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handlers: Record<CasesQuestStatusEnum, () => void> = {
    [CasesQuestStatusEnum.BLOCKED]: () => {
      if (!isFinal) {
        dispatch(actionSetSelectedCategory(entityId.toString()));
        history.push(`..${Modules.CASES}`);
      }
    },
    [CasesQuestStatusEnum.AVAILABLE]: () => {
      if (caseId) {
        dispatch(
          actionPickUpPrize({
            entityId,
            redirectHandler: () => {
              history.push(`..${Modules.CASES}/${caseId}`);
            },
          })
        );
      }
    },
    [CasesQuestStatusEnum.TAKEN]: () => {
      if (caseId) {
        history.push(`..${Modules.CASES}/${caseId}`);
      }
    },
    [CasesQuestStatusEnum.OPENED]: () => {
      if (caseId && openUUID) {
        history.push(`..${Modules.CASES}/${caseId}?uuid=${openUUID}`);
      }
    },
  };

  const componentStatus: Record<CasesQuestStatusEnum, JSX.Element> = {
    [CasesQuestStatusEnum.BLOCKED]: (
      <CategoryItemBlocked
        categoryName={categoryName}
        handler={handlers[status]}
        image={image}
        isFinal={isFinal}
        percentProgress={percentProgress}
      />
    ),
    [CasesQuestStatusEnum.AVAILABLE]: (
      <CategoryItemAvailable categoryName={categoryName} handler={handlers[status]} image={image} />
    ),
    [CasesQuestStatusEnum.TAKEN]: (
      <CategoryItemAvailable categoryName={categoryName} handler={handlers[status]} image={image} />
    ),
    [CasesQuestStatusEnum.OPENED]: (
      <CategoryItemCompleted
        categoryName={categoryName}
        handler={handlers[status]}
        itemImgPath={itemImg}
      />
    ),
  };

  return componentStatus[status];
};
