import styled from 'styled-components';

export const Container = styled.div`
  & .inner-container {
    position: relative;

    & .confirm-action {
      padding: 1.25rem 1rem 1.75rem;
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(19, 26, 29, 0.475) 0%, rgba(19, 26, 29, 0.95) 97.96%);
      backdrop-filter: blur(4px);
    }
  }
`;
