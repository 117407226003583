import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { GameInfo, GameTape, QueueAnimations } from '../components';
import { BigPreloader } from '@components/Preloader';
import { useRaffling } from '../hooks';
import { Balance } from './Balance';

export const RaffleGame: React.FC<RouteComponentProps> = () => {
  const { raffle } = useRaffling();

  if (!raffle) {
    return (
      <div>
        <BigPreloader />
      </div>
    );
  }

  return (
    <>
      <AppBar deep prependWidget={Balance} />
      <div className="content">
        <GameInfo
          type={raffle.type}
          finishEvent={raffle.finishAt}
          stageFinishEvent={raffle.items[raffle.stage]?.raffleAt}
          phase={raffle.phase}
          quantityItems={raffle.items.length}
        />
        <GameTape
          stage={raffle.stage}
          items={raffle.items}
          phase={raffle.phase}
          finishEvent={raffle.finishAt}
        />
        <QueueAnimations
          type={raffle.type}
          items={raffle.items}
          stage={raffle.stage}
          phase={raffle.phase}
        />
      </div>
    </>
  );
};
