import React, { useEffect } from 'react';
import { AppBar } from '@components/bars';
import { actionCaseItemInfoRequest } from '../../ducks';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetDetailedItemInfo } from 'games/CaseGame/hooks';
import { CasesWhereLocated, ItemInfo } from 'games/CaseGame/components';
import { Loader } from '@components/Loader';

export const CaseItemInfoPage = () => {
  const { data, isLoading, isLoadingSecond } = useGetDetailedItemInfo();
  const dispatch = useDispatch();
  const { name, id } = useLocation().state;
  useEffect(() => {
    if (name && id) {
      dispatch(actionCaseItemInfoRequest(id, name));
    }
  }, [name, id, dispatch]);

  return !isLoading && !isLoadingSecond ? (
    <>
      <AppBar deep></AppBar>
      <div className="content dark-layout">
        <ItemInfo
          similar={data?.detailedInfo?.similar}
          collection={data?.detailedInfo?.collection}
          description={data?.detailedInfo?.description}
          name={data?.detailedInfo?.name}
          rarity={data?.detailedInfo?.rarity}
        />
        <CasesWhereLocated casesWhereLocated={data.casesWhereLocated} />
      </div>
    </>
  ) : (
    <Loader />
  );
};
