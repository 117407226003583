export default {
  Withdraw: '提取',
  'Withdrawal sum': '提款金額',
  'Amount in {{title}}:': '金額在 $t({{title}}):',
  'Receive in {{title}}:': '接收 $t({{title}}):',
  'You will receive:': '您將收到:',
  'Select the network fee': '選擇網絡費用',
  'Commission:': '佣金',
  'Ultra Low': '非常慢',
  Low: '慢的',
  Medium: '標準',
  High: '快速地',
  'No commission': '無佣金',
};
