import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import BezierEasing from 'bezier-easing';
import { getTimeByCoef, msToCoef } from '../../crash-game.utils';
import RateLine from './RateLine';
import DottedCircle from './DottedCircle';
import AutoStopRuler from '../AutoStopRuler/AutoStopRuler';

const defaultStateWheelRate = [
  { coef: null, key: 0 },
  { coef: null, key: 1 },
  { coef: 1, key: 2 },
  { coef: 2, key: 3 },
  { coef: 3, key: 4 },
  { coef: 4, key: 5 },
  { coef: 5, key: 6 },
  { coef: 6, key: 7 },
  { coef: 7, key: 8 },
];

export default class RateWheel extends React.PureComponent {
  constructor() {
    super();

    this.wheelRotateAnimation = new Animated.Value(0);
    this.wheelRotateDotAnimation = new Animated.Value(0);
    this.coefs = new Map();
    this.isCrashGame = false;
    this.state = {
      linear: [],
    };
  }

  componentDidMount() {
    const { gameRun, gameRunTs, appDiffTime } = this.props;

    if (!gameRun) {
      defaultStateWheelRate.forEach((item, i) => {
        this.coefs.set(i, { ...item });
      });

      this.setState({ linear: Array.from(this.coefs.values()) });
    }

    if (gameRun) {
      const currentCoef = msToCoef(appDiffTime + Date.now() - gameRunTs);
      const from = Math.floor(currentCoef) - 2;

      defaultStateWheelRate.forEach((item, i) => {
        this.coefs.set(i, {
          coef: from + i < 1 ? null : from + i,
          key: i,
        });
      });

      this.setState(() => ({ linear: Array.from(this.coefs.values()) }));

      const toValue = Math.ceil(currentCoef) - currentCoef;

      this.wheelRotateAnimation.setValue((1 - toValue) / 9);
      this.wheelRotateDotAnimation.setValue(((1 - toValue) / 9) * 0.6);
      this.animation(
        Math.ceil(currentCoef),
        3,
        1 / 9,
        Math.floor(getTimeByCoef(Math.ceil(currentCoef)) - getTimeByCoef(currentCoef))
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { gameRun, crashed, coef, id } = this.props;
    if (prevProps.gameRun !== gameRun && gameRun) {
      this.animation(2, 3, 1 / 9, Math.floor(getTimeByCoef(2)));
    }

    if (prevProps.id !== id && !crashed && !gameRun) {
      this.coefs = new Map();
      this.wheelRotateAnimation.setValue(0);
      this.wheelRotateDotAnimation.setValue(0);

      defaultStateWheelRate.forEach((item, i) => {
        this.coefs.set(i, { ...item });
      });

      this.setState({ linear: Array.from(this.coefs.values()) });
      this.isCrashGame = false;
    }

    if (prevProps.id === id && crashed && !this.isCrashGame) {
      this.isCrashGame = true;
      this.cancelAnimation(coef);
    }
  }

  componentWillUnmount() {
    this.isCrashGame = true;
  }

  render() {
    const { crashed, gameRun, userBet } = this.props;
    const wheelRotate = this.wheelRotateAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: ['80deg', '440deg'],
    });

    const wheelRotateDotted = this.wheelRotateDotAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: ['80deg', '440deg'],
    });

    return (
      <WheelRateContainer className="tablet">
        <WheelRateInner crashed={crashed} gameRun={gameRun}>
          <Animated.div
            style={{
              zIndex: 5,
              transform: [{ rotate: wheelRotate }],
              filter: 'url("#blur")',
            }}
          >
            <RateLineContainer>
              {this.state.linear.map((element, index) => (
                <RateLine
                  key={element.key}
                  rotate={index}
                  rulerCoef={element.coef}
                  userBet={userBet}
                />
              ))}
            </RateLineContainer>
          </Animated.div>
          <Animated.div
            style={{
              zIndex: 10,
              transform: [{ rotate: wheelRotateDotted }],
            }}
          >
            <DottedCircle crashed={crashed} blurValue={this.wheelRotateDotAnimation._value} />
          </Animated.div>
          <AutoStopRuler isShow={userBet.coefAutoStop > 0} />
          <Shade />
        </WheelRateInner>
      </WheelRateContainer>
    );
  }

  animation = (step, indexRuler, indexValue, time) => {
    if (!(indexRuler % 3)) {
      const gap = indexRuler - 9 * Math.floor(indexRuler / 9);
      const from = gap === 6 ? 0 : gap + 3;
      const to = from + 3;
      const { linear } = this.state;
      const toChange = linear.slice(from, to);
      toChange.forEach((item, i) => {
        this.coefs.set(from + i, {
          coef: step + 3 + i,
          key: from + i,
        });
      });
      this.setState({ linear: Array.from(this.coefs.values()) });
    }

    Animated.parallel([
      Animated.timing(this.wheelRotateAnimation, {
        toValue: indexValue,
        easing: BezierEasing(0.5, 0.5, 0.5, 0.5),
        duration: time,
      }),
      Animated.timing(this.wheelRotateDotAnimation, {
        toValue: indexValue * 0.6,
        easing: BezierEasing(0.5, 0.5, 0.5, 0.5),
        duration: time,
      }),
    ]).start(() => {
      if (this.isCrashGame) return;
      const { gameRun, crashed } = this.props;
      if (gameRun && !crashed) {
        this.animation(
          step + 1,
          indexRuler < 9 ? indexRuler + 1 : 1,
          1 / 9 + indexValue,
          Math.floor(getTimeByCoef(step + 1)) - Math.floor(getTimeByCoef(step))
        );
      }
    });
  };

  cancelAnimation = coef => {
    const from = Math.floor(coef) - 2;
    defaultStateWheelRate.forEach((item, i) => {
      this.coefs.set(i, {
        coef: from + i < 1 ? null : from + i,
        key: i,
      });
    });
    this.setState(() => ({ linear: Array.from(this.coefs.values()) }));
    this.wheelRotateAnimation.stopAnimation(() => {
      Animated.timing(this.wheelRotateAnimation, {
        toValue: (coef - Math.floor(coef)) / 9,
        duration: 0,
      }).start();
    });
    this.wheelRotateDotAnimation.stopAnimation(value => {
      Animated.timing(this.wheelRotateDotAnimation, {
        toValue: value + 1 / 18,
        easing: BezierEasing(0.215, 0.61, 0.355, 1),
        duration: Math.floor(getTimeByCoef(coef) - getTimeByCoef(coef - 1)) / 3,
      }).start();
    });
  };
}

const Shade = styled.div`
  width: 230px;
  height: 150px;
  position: absolute;
  bottom: -166px;
  right: 0;
  margin-right: calc(50% - 9px);
  z-index: 5;
  background: var(--color-dark-500);
  clip-path: polygon(58% 62%, 100% 70%, 100% 100%, 0 100%, 0 14%, 21% 40%);
`;

const WheelRateContainer = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const WheelRateInner = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform linear 0.2s;
  transform: translateY(${props => (props.gameRun ? '-250px' : '-270px')});

  &::before {
    content: '';
    width: 630px;
    height: 630px;
    position: absolute;
    border-radius: 50%;
    background: var(--color-red-200);
    transition: opacity linear 0.2s;
    opacity: ${props => (props.crashed ? 0.2 : 0)};
  }
`;

const RateLineContainer = styled.ul`
  display: flex;
  flex-direction: row;

  width: 608px;
  height: 608px;

  position: absolute;
  transform: translate(-50%, -50%);
  transform-origin: center;
`;
