import * as React from 'react';
import styled from 'styled-components';
import BezierEasing from 'bezier-easing';
import { animated, useSpring } from 'react-spring';
import { getUserAvatarUrl } from 'core/utils/image.utils';
import { generateLent, ROULETTE_ANIMATION } from '../../common.utils';
import { IRafflingItem, Phases } from '../../interfaces/reducer.leaders.interfaces';

const { useState, useLayoutEffect, useEffect } = React;

interface IGameTape {
  items: IRafflingItem[];
  finishEvent: string;
  stage: number;
  phase: Phases;
}

type IGameTapeStyle = {
  phase: Phases;
};

export const GameTape: React.FC<IGameTape> = ({ stage, items, finishEvent, phase }) => {
  const [tape, setTape] = useState<any[]>([]);
  const [props, set] = useSpring(() => ({
    transform: 'translateX(220px)',
  }));

  useLayoutEffect(() => {
    if (stage === null && tape.length) {
      setTape([]);
      return;
    }

    if (stage === null || ![Phases.raffling, Phases.step_raffling].includes(phase)) return;

    if (stage >= 0) {
      const { members, feed } = items[stage];
      const appendTape = tape.slice(tape.length - 11, tape.length);
      const newTape = generateLent(members, feed);
      setTape([...appendTape, ...newTape]);
    }
    // eslint-disable-next-line
  }, [stage, setTape, items, phase]);

  useEffect(() => {
    if ([Phases.step_waiting, Phases.finished].includes(phase)) {
      setTape([]);
      set({
        transform: 'translateX(220px)',
      });
    }
  }, [phase, setTape, set]);

  useEffect(() => {
    if (stage === null || ![Phases.raffling, Phases.step_raffling].includes(phase)) return;

    let duration;
    if (items[stage]?.raffleAt) {
      const raffleAt =
        Date.parse(items[stage]?.raffleAt) || Date.parse(finishEvent) + stage * 15000;
      const diffTime = Date.now() - raffleAt;
      duration = Math.abs(diffTime) > 15000 ? 0 : ROULETTE_ANIMATION;
    } else {
      duration = ROULETTE_ANIMATION;
    }

    set({
      from: {
        transform: stage >= 0 ? 'translateX(-195px)' : 'translateX(220px)',
      },
      transform: `translateX(-${(tape.length - 6) * 61}px)`,
      config: {
        duration: duration,
        easing: BezierEasing(0.32, 0.64, 0.35, 1),
      },
      reset: true,
    });
  }, [stage, set, tape, items, finishEvent, phase]);

  return (
    <Container phase={phase}>
      <AnimatedContainer style={props}>
        {tape.map(({ id, avaLink }: any, i) => (
          <div key={avaLink + i} className={`${items[stage]?.members[id]?.type} tape-item`}>
            <img src={getUserAvatarUrl(avaLink, 40)} alt="" />
          </div>
        ))}
      </AnimatedContainer>
    </Container>
  );
};

const Container = styled.div.attrs((p: IGameTapeStyle) => ({
  raffling: [Phases.step_raffling, Phases.raffling].includes(p.phase),
}))<IGameTapeStyle>`
  flex-shrink: 0;
  padding: 0.8rem;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 1.625rem;
    height: 0.625rem;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #47555f;
    position: absolute;
    left: 50%;
    bottom: ${p => (p.raffling ? -1 : -11)}px;
    margin-left: -0.8125rem;
    transition: bottom 700ms ease-out;
  }
`;

const AnimatedContainer = styled(animated.div)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 65px;
  margin: 0 auto;
  width: 65px;
  will-change: transform;

  & .tape-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 55px;
    min-height: 55px;
    width: 55px;
    height: 55px;
    margin: 3px;
    position: relative;

    border-color: transparent;

    &.bronze {
      border-color: var(--color-account-type-bronze);
    }
    &.silver {
      border-color: var(--color-account-type-silver);
    }
    &.gold {
      border-color: var(--color-account-type-gold);
    }
    &.platinum {
      border-color: var(--color-account-type-platinum);
    }

    &.diamond {
      border-color: var(--color-account-type--diamond);
    }

    & img {
      width: 2.9rem;
      height: 2.9rem;
      border-radius: 50%;
    }

    &:before {
      content: '';
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
      border-color: inherit;
      position: absolute;
      min-width: inherit;
      min-height: inherit;
    }
  }
`;
