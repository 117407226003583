import { GameStatus, RouletteStatuses } from 'games/CaseBattle/constants';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import {
  Container,
  GameInfoContainer,
  GameStatusInfo,
  GrayColorSpan,
  HeaderInfoContainer,
  RoundSpan,
  Span,
  StartWrapper,
  TotalSpentContainer,
  WaitingWrapper,
} from './Header.styles';
import { useGetCaseBattleGameData, useGetRouletteAnimationStatuses } from 'games/CaseBattle/hooks';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { Round } from 'games/CaseBattle/interfaces';
import { Modules } from 'core/interfaces';

export const Header = React.memo(() => {
  const { linesStatus } = useGetRouletteAnimationStatuses();
  const { data, isReplay } = useGetCaseBattleGameData();
  const { rounds } = data.game;
  const roundsSum = (rounds as Round[])?.reduce((acc, item) => acc + item.result.price, 0);
  const [atStake, setAtStake] = useState(roundsSum);

  useEffect(() => {
    if (linesStatus === RouletteStatuses.afterRoll) {
      setAtStake(roundsSum);
    }
  }, [linesStatus, roundsSum]);

  useEffect(() => {
    if (isReplay) {
      setAtStake(0);
    }
  }, [isReplay]);

  const history = useHistory();
  const { t } = useTranslation();

  const redirect = () => {
    if (history.length <= 2) {
      history.push(`..${Modules.CASE_BATTLE}`);
    } else {
      history.goBack();
    }
  };

  const titleType: Record<GameStatus, JSX.Element> = {
    [GameStatus.waiting]: (
      <WaitingWrapper>
        <Span>{t('waitingPlayers')}</Span>
        <GrayColorSpan>
          {data.game.players.length} / {data.game.players_count}
        </GrayColorSpan>
      </WaitingWrapper>
    ),
    [GameStatus.start]: (
      <StartWrapper>
        <Span>{t('round')}</Span>
        <RoundSpan>
          <div>{data.game.rounds.length / data.game.players_count}</div>
          <div>{t('from')}</div>
          <div>{data.game.cases_count}</div>
        </RoundSpan>
      </StartWrapper>
    ),
    [GameStatus.roll]: (
      <StartWrapper>
        <Span>{t('round')}</Span>
        <RoundSpan>
          <div>{data.game.rounds.length / data.game.players_count}</div>
          <div>{t('from')}</div>
          <div>{data.game.cases_count}</div>
        </RoundSpan>
      </StartWrapper>
    ),
    [GameStatus.finish]: (
      <Span>{data.game.settings.jokerMode ? t('jokerBattleFinish') : t('battleFinish')}</Span>
    ),
  };
  return (
    <Container>
      <GameInfoContainer>
        <HeaderInfoContainer>
          <GameStatusInfo onClick={redirect}>
            <CaseBattleIcon.BackArrow />
            {titleType[data.gameStatus]}
          </GameStatusInfo>
          <TotalSpentContainer isJoker={data.game.settings.jokerMode}>
            <GrayColorSpan>{t('atStake')}</GrayColorSpan>
            <span>◎</span>
            <CountUp
              decimal="."
              decimals={atStake ? 2 : 0}
              duration={1.5}
              end={atStake / 100}
              delay={0}
              preserveValue={true}
            />
          </TotalSpentContainer>
        </HeaderInfoContainer>
      </GameInfoContainer>
    </Container>
  );
});
