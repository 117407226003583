import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { socketService } from '../../../services/socket.service';

class LeadersTicketsRepository {
  fetchTickets(): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/tickets-balance`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
}

export default new LeadersTicketsRepository();
