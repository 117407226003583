import { combineReducers, Reducer } from 'redux';
import i18n from 'i18next';
import { List } from 'immutable';
import { availableGameModules } from 'games';
import { AsyncAction } from 'store/interface';

import { IDispatch, IEventsTypes } from '../rootInterfaces/root.interface';
import { promoParamsHandler } from '../utils/params.utils';
import {
  filterRegexString,
  gamesToListen,
  initialSettings,
  initStateCurrency,
  soundDefaultStatus,
} from './app-shell.dictionary';

import {
  ICurrency,
  IExperiences,
  IInitAppShell,
  ISettings,
  ISounds,
} from './interfaces/appshell.reducer.interface';

import { ICrossGame } from '../CrossServices/interfaces/crossServices.reducer.inteface';

import {
  AppShellActionsTypes,
  connectActions,
  currencyActions,
  DrawerActions,
  experiencesActions,
  IChangeCurrentPageAction,
  IFilterRegexAction,
  initializedActions,
  IToggleLocaleAction,
  lastGamesActions,
  settingsActions,
  ISoundsAction,
} from './interfaces/appshell.actions.interface';

import { currencyService } from 'services/currency';
import { actionRequestReferralEpic, initProfile, login, updateUser } from 'core/User/ducks/duck';
import { init as initNotifications } from 'modules/Notifications/duck';
import { initGame as initClassicGame } from 'games/ClassicGame/duck';
import { updateGames as initFastGame } from 'games/Fast/duck';
import { updateGame as initCrashGame } from 'games/CrashGame/duck';
import { initCrossData, initGameIdWithAuth } from '../CrossServices/duck';
import { ISocketService } from '../../services/interfaces/services.interface';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { delay, filter, ignoreElements, switchMap, tap } from 'rxjs/operators';
import { userActionsTypes } from '../User/interfaces';
import { gameSettingsAdapter } from '../../adapters/game-settings.adapter';
import SoundService from 'services/sound.service';

const sound = new SoundService();

/** REDUCERS **/

const settings = (state = initialSettings, action: settingsActions): ISettings => {
  switch (action.type) {
    case AppShellActionsTypes.INIT_SETTINGS: {
      const { currency, ...rest } = action.payload;
      const gameTypes = gameSettingsAdapter(rest.gameTypes);
      const currencies = currency.sort((l: ICurrency, r: ICurrency) => l.id - r.id);
      return {
        ...rest,
        gameTypes,
        currencies,
      };
    }
    case AppShellActionsTypes.SET_DIFF_TIME: {
      return {
        ...state,
        dbTimeDiff: action.payload - Date.now(),
      };
    }
    default: {
      return state;
    }
  }
};

const drawerOpenStatus = (state: boolean = false, action: DrawerActions): boolean => {
  switch (action.type) {
    case AppShellActionsTypes.TOGGLE_DRAWER:
      const { status } = action.payload;
      return typeof status === 'boolean' ? status : !state;

    default: {
      return state;
    }
  }
};

const isInitialized = (state: boolean = false, action: initializedActions): boolean => {
  switch (action.type) {
    case AppShellActionsTypes.INITIALIZED: {
      return true;
    }
    case AppShellActionsTypes.DISCONNECT: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const filterRegex = (state: string = '', action: IFilterRegexAction): string => {
  switch (action.type) {
    case AppShellActionsTypes.INIT_SETTINGS: {
      const { regexStart, regexEnd } = action.payload;
      return filterRegexString
        .replace('$regexStart', regexStart.join('|'))
        .replace('$regexEnd', regexEnd.join('|'));
    }
    default: {
      return state;
    }
  }
};

const currentPage = (state: string = '/classic', action: IChangeCurrentPageAction): string => {
  switch (action.type) {
    case AppShellActionsTypes.CHANGE_CURRENT_PAGE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const lastGames: Reducer<string[], lastGamesActions> = (state = [], action) => {
  switch (action.type) {
    case AppShellActionsTypes.ADD_LAST_GAME:
      return action.payload.pathGames;

    default:
      return state;
  }
};

const reconnectingStatus = (state: boolean = false, action: connectActions): boolean => {
  switch (action.type) {
    case AppShellActionsTypes.RECONNECTING: {
      return true;
    }
    case AppShellActionsTypes.CONNECTED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const soundEnableStatus = (state = soundDefaultStatus, action: ISoundsAction) => {
  switch (action.type) {
    case AppShellActionsTypes.SET_SOUNDS: {
      localStorage.setItem('@csgofast:sound', JSON.stringify(state));
      sound.loadSounds(state);
      return state;
    }
    case AppShellActionsTypes.UPDATE_SOUNDS: {
      sound.loadSounds(action.payload);
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const locale = (state: string = 'en', action: IToggleLocaleAction): string => {
  switch (action.type) {
    case AppShellActionsTypes.TOGGLE_LOCALE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const currency = (state = initStateCurrency, action: currencyActions): ICurrency => {
  switch (action.type) {
    case AppShellActionsTypes.TOGGLE_CURRENCY: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const ExperiencesList = List<IExperiences>([
  {
    level: 0,
    xp: 0,
    rank: '',
    stars: 3,
    chevronSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/user-levels/rank-0.png',
    prize: { reward: null },
  },
]);

const experiences: Reducer<List<IExperiences>, experiencesActions> = (
  state = ExperiencesList,
  action
) => {
  switch (action.type) {
    case AppShellActionsTypes.RECEIVE_EXPERIENCE_MAP:
      return List<IExperiences>(ExperiencesList.toArray().concat(action.payload.experiences));

    default:
      return state;
  }
};

export const reducer = combineReducers({
  currentPage: currentPage,
  settings: settings,
  isInitialized: isInitialized,
  filterRegex: filterRegex,
  drawerOpenStatus: drawerOpenStatus,
  reconnectingStatus: reconnectingStatus,
  sound: soundEnableStatus,
  locale,
  currency,
  lastGames,
  experiences,
});

/** ACTION CREATORS **/

export const loadCurrency = () => (dispatch: IDispatch): void => {
  const currencyData = localStorage.getItem('@csgofast:currency');
  const currency = currencyData ? JSON.parse(currencyData) : null;
  if (currency !== null) {
    currencyService.setCurrency(currency.key);
    dispatch({ type: AppShellActionsTypes.TOGGLE_CURRENCY, payload: currency });
  }
};

export const setCurrency = (key: string) => (dispatch: IDispatch): void => {
  const currency: ICurrency | undefined = currencyService.getCurrencies().find(x => x.key === key);
  if (currency !== undefined) {
    localStorage.setItem('@csgofast:currency', JSON.stringify(currency));

    currencyService.setCurrency(currency.key);
    dispatch({ type: AppShellActionsTypes.TOGGLE_CURRENCY, payload: currency });
  }
};

export const init = (data: IInitAppShell) => (dispatch: IDispatch): void => {
  const {
    auth,
    dbTime,
    gamesList,
    mirrorConfig,
    state: { settings, rooms },
  } = data;
  currencyService.setCurrencyRates(settings.currency);
  const { gameId, ...user } = auth;
  dispatch(initGameIdWithAuth(gameId, gamesList, mirrorConfig));

  if (auth.auth) {
    dispatch(startApplication());
    dispatch(updateUser(user));
    dispatch(initGameList(gamesList));
    dispatch(initNotifications(user.steamid_3));
    dispatch(login());
    (window as any).Chatra('setIntegrationData', {
      /* main properties */
      name: user.name,
      email: null,
      phone: null,
      notes: null,
      USER_ID: user.userid,
    });
  }

  dispatch(initSettings(settings));
  dispatch({ type: AppShellActionsTypes.SET_DIFF_TIME, payload: dbTime });
  dispatch(initGames(rooms));
  dispatch(initCrossData({ gamesList, mirrorConfig }));
  dispatch(loadLocale());
  dispatch(loadCurrency());

  setTimeout((): void => {
    dispatch({ type: AppShellActionsTypes.INITIALIZED });
    dispatch(actionRequestReferralEpic());
  }, 2500);
};

export const reinitialize = () => (dispatch: IDispatch, _: any, { socket }: ISocketService): void =>
  socket.emit('re-init');

const initGameList = (data: ICrossGame[]) => ({
  type: AppShellActionsTypes.INIT_GAME_LIST,
  payload: data,
});

const initGames = (rooms: any) => (dispatch: IDispatch): void => {
  dispatch(initClassicGame(rooms.classic));
  dispatch(initFastGame(rooms.fast));
  dispatch(initCrashGame(rooms.crash));
};

const initSettings = (settings: any): settingsActions => ({
  type: AppShellActionsTypes.INIT_SETTINGS,
  payload: settings,
});

export const toggleDrawer = (status?: boolean): DrawerActions => ({
  type: AppShellActionsTypes.TOGGLE_DRAWER,
  payload: {
    status,
  },
});

export const loadLastPage = () => (dispatch: IDispatch) => {
  if (window.location.pathname !== '/') {
    return dispatch({
      type: AppShellActionsTypes.CHANGE_CURRENT_PAGE,
      payload: window.location.pathname,
    });
  }

  const lastPage = localStorage.getItem('@csgofast:page');
  if (lastPage === null) return;
  dispatch({
    type: AppShellActionsTypes.CHANGE_CURRENT_PAGE,
    payload: JSON.parse(lastPage).replace(/-game/g, ''),
  });
};

export const rememberCurrentPage = (type: string) => (dispatch: IDispatch): void => {
  dispatch({ type: AppShellActionsTypes.CHANGE_CURRENT_PAGE, payload: type });
  localStorage.setItem('@csgofast:page', JSON.stringify(type));
};

export const loadSoundSettings = () => (dispatch: IDispatch): void => {
  const localStorageData = JSON.parse(localStorage.getItem('@csgofast:sound') || 'null');

  if (localStorageData) {
    const isNewSounds = localStorageData.hasOwnProperty('commonSounds');

    if (isNewSounds) {
      dispatch(updateSound(localStorageData));
    } else {
      localStorage.removeItem('@csgofast:sound');
    }
  } else {
    dispatch({
      type: AppShellActionsTypes.SET_SOUNDS,
    });
  }
};

export const loadLocale = () => (dispatch: IDispatch): void => {
  const locale = localStorage.getItem('@csgofast:locale');
  if (locale !== null) {
    dispatch({ type: AppShellActionsTypes.TOGGLE_LOCALE, payload: locale });
    i18n.changeLanguage(locale).catch(error => console.error(error.message));
  }
};

export const saveLocale = (locale: string) => (dispatch: IDispatch): void => {
  i18n.changeLanguage(locale).catch(error => console.error(error.message));
  localStorage.setItem('@csgofast:locale', locale);
  dispatch({ type: AppShellActionsTypes.TOGGLE_LOCALE, payload: locale });
};

export const updateSound = (newState: ISounds) => (dispatch: IDispatch): void => {
  localStorage.setItem('@csgofast:sound', JSON.stringify(newState));

  dispatch({
    type: AppShellActionsTypes.UPDATE_SOUNDS,
    payload: newState,
  });
};

export const initLastGames = () => (dispatch: IDispatch) => {
  const originPath = localStorage.getItem('@csgofast:last-games');
  const pathGames = originPath ? JSON.parse(originPath) : [];
  if (!pathGames.length) return;

  dispatch({
    type: AppShellActionsTypes.ADD_LAST_GAME,
    payload: {
      pathGames,
    },
  });
};

export const addLastGame = (pathname: string) => (dispatch: IDispatch) => {
  const originPath = localStorage.getItem('@csgofast:last-games');
  const parsePath = originPath ? JSON.parse(originPath) : [];
  const moduleName = pathname.split('/')[1];

  if (moduleName !== parsePath[0] && availableGameModules.includes(moduleName)) {
    const pathGames = [moduleName, ...parsePath.splice(0, 3)];
    localStorage.setItem('@csgofast:last-games', JSON.stringify(pathGames));
    dispatch({
      type: AppShellActionsTypes.ADD_LAST_GAME,
      payload: {
        pathGames,
      },
    });
  }
};

export const receiveExperiences = (experiences: IExperiences[]) => ({
  type: AppShellActionsTypes.RECEIVE_EXPERIENCE_MAP,
  payload: {
    experiences,
  },
});

// steam status

const utmMetrics: Epic = (action$, _, { socket }) =>
  promoParamsHandler().pipe(
    switchMap((params: { clickId: string | null; utm_campaign: string | null }) =>
      action$.pipe(
        ofType(userActionsTypes.LOGIN),
        filter(() => !!params.clickId),
        delay(4000),
        tap(() => {
          socket.io.emit('affise.register', {
            utm_campaign: params.utm_campaign,
            clickId: params.clickId,
          });
          localStorage.removeItem('clickid');
          localStorage.removeItem('utm_campaign');
        }),
        ignoreElements()
      )
    )
  );

export const appEpics = combineEpics(utmMetrics);

const setAmountDeposit = (data: any) => (): void =>
  (window as any).dataLayer.push({
    event: 'event-deposit',
    'Category - deposit': 'deposit',
    'Action - Success': 'Success',
    'Lable - deposit': 'deposit',
    Amount: data.amount,
  });

export const startApplication = (): AsyncAction => (dispatch, getState, { socket }) => {
  dispatch(loadLastPage());
  dispatch(loadSoundSettings());
  dispatch(initProfile());
  socket.emit('account:experience-map:fetch');
};

const eventsTypeGameListen = gamesToListen.map((item: string) => {
  return {
    event: `game:${item}:bet:confirmed`,
    action: () => () => {
      // Сигнал Google Analytics о принятой ставке.
      (window as any).dataLayer.push({
        event: `event-${item}`,
        'Category - Bet': 'Bet',
        'Action - Success': 'Success',
        [`Lable - ${item}`]: item,
      });
    },
  };
});

export const eventsTypes: IEventsTypes[] = [
  { event: 'all:init', action: init },
  { event: 'deposit:confirmed', action: setAmountDeposit },
  { event: 'account:experience-map:state', action: receiveExperiences },
  ...eventsTypeGameListen,
];
