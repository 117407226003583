import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

class AccountsRepository {
  fetchRefillMethod(userId: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/${userId}?needDetailedInfo=true`,
      method: 'GET',
      withCredentials: true,
    });
  }
}

export default new AccountsRepository();
