import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';

import { cleanUpMarket, requestMarket, subscribe, unsubscribe, updateFilter } from '../duck';

import { getUser } from '../../../core/User/selectors';
import { getMarketplaceMode } from '../selectors';
import SearchImg from '../assets/search.png';

import { AppBar } from '../../../@components/bars';
// import Cart from './Cart'
import Bets from './Bets';
import Marketplace from './Marketplace';

import PrivateRoute from '../../../decorators/PrivateRoute.decorator';
import styled from 'styled-components';

class MarketplaceContainer extends PureComponent {
  constructor() {
    super();
    this.state = {
      search: '',
    };
    this._debouncedSearch = debounce(() => this.searchingAction(this.state.search), 1000);
  }

  componentDidMount() {
    const { mode, subscribe, requestMarket } = this.props;
    if (mode === 'market') {
      subscribe();
      requestMarket(true);
    }
  }

  componentWillUnmount() {
    const { unsubscribe, cleanUpMarket } = this.props;
    unsubscribe();
    cleanUpMarket();
  }

  render() {
    const { match, mode, location, t } = this.props;
    return (
      <>
        <AppBar>
          {mode === 'market' && (
            <SearchBlock>
              <Search type="text" placeholder={t('Search')} onChange={this.handleInput} />
              <SearchIcon />
            </SearchBlock>
          )}
        </AppBar>
        <div className="content">
          <Switch>
            <Route exact path={`${match.url}/:mode/:type`} component={Bets} />
            {/*<Route exact path={`${match.url}/cart`} component={Cart} />*/}
            <Route exact path={`${match.url}`} component={Marketplace} />

            <Redirect
              to={{
                pathname: mode ? `/${mode}` : match.url,
                state: { from: location },
              }}
            />
          </Switch>
        </div>
      </>
    );
  }

  searchingAction = name => {
    const { location, history } = this.props;
    this.props.updateFilter({ name });
    this.props.requestMarket(true);
    if (location.pathname !== '/marketplace') history.goBack(1);
  };

  handleInput = e => {
    this.setState({ search: e.target.value });
    this._debouncedSearch();
  };
}

export default connect(
  state => ({
    user: getUser(state),
    mode: getMarketplaceMode(state),
  }),
  { subscribe, unsubscribe, updateFilter, requestMarket, cleanUpMarket }
)(withTranslation()(PrivateRoute(MarketplaceContainer)));

const SearchIcon = styled.div`
  width: 13px;
  height: 13px;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  background-image:  url('${SearchImg}');
`;

const SearchBlock = styled.div`
  width: 100%;
  margin: 0 0.5rem;
  position: relative;
`;

const Search = styled.input`
  width: 100%;
  border-radius: 4px;
  background-color: var(--gray-400);
  border: 0;
  padding: 7px 40px 7px 3px;
  color: var(--gray-200);
  font-size: 0.85rem;
  position: relative;
  &::placeholder {
    color: var(--gray-200);
  }
`;
