import React, { Component } from 'react';
import styled from 'styled-components';
import { Roulette } from './Roulette';
import { ControlPanel } from './ControlPanel';
import PlayerList from './PlayerList';
import SoundService from './../../../../services/sound.service';
import { Prize } from '../../../../@components/Prize';
import { connect } from 'react-redux';
import {
  getClassicGameData,
  getClassicGameLadder,
  getClassicGameOverStatus,
  getClassicGamePrize,
  getClassicGameTapeStatus,
  getClassicGameTapeVisibilityStatus,
  getClassicGameTimerValue,
  getClassicGameTrades,
  getClassicGameUserChances,
  getClassicGameWinner,
  getClassicGameWinnerVisibleStatus,
} from '../../selectors';
import { getAppDiffTime, getClassicGameSettings } from '../../../../core/AppShell/selectors';
import { getUser } from '../../../../core/User';
import { bindActionCreators } from 'redux';
import * as gameActions from '../../duck';

/**
 * Компонент-оболочка для вьюхи с игрой. Можно конечно и отказаться,
 * но управлять состоянием, на мой взгляд, куда удобнее.
 * */

class _Game extends Component {
  constructor() {
    super();
    this.sound = new SoundService();
  }

  componentDidUpdate(prevProps) {
    if (this.props.gameIsOver && document.hidden) {
      this.props.gameActions.clearGame();
    }
    if (
      this.props.tapeStatus.tapeIsVisible === false &&
      prevProps.tapeStatus.tapeIsVisible === true
    ) {
      this.sound.play('Classic', 'Game start');
    }
  }

  render() {
    const {
      appDiffTime,
      game,
      gameActions,
      gameIsOver,
      isVisible,
      profile,
      settings,
      tapeStatus,
      totalUserPrize,
      trades,
      user,
      userChances,
    } = this.props;

    return (
      <Container>
        <div className="inner-container">
          <Prize />
          <Roulette
            animationTimeout={settings.roulette_ms}
            appDiffTime={appDiffTime}
            game={game}
            gameActions={gameActions}
            gameIsOver={gameIsOver}
            isVisible={isVisible}
            profile={profile}
            tapeStatus={tapeStatus}
            timerTimeout={settings.timeout_ms}
            totalUserPrize={totalUserPrize}
            trades={trades}
            user={user}
            userChances={userChances}
          />
          <PlayerList players={trades} tapeStatus={tapeStatus} totalUserPrize={totalUserPrize} />
        </div>
        <ControlPanel />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  height: 100%;

  & .inner-container {
    display: flex;
    flex-flow: column;
    flex: 1;
  }
`;

export const Game = connect(
  state => ({
    game: getClassicGameData(state),
    gameIsOver: getClassicGameOverStatus(state),
    trades: getClassicGameTrades(state),
    timerValue: getClassicGameTimerValue(state),
    tapeIsVisible: getClassicGameTapeVisibilityStatus(state),
    tapeStatus: getClassicGameTapeStatus(state),
    settings: getClassicGameSettings(state),
    totalUserPrize: getClassicGamePrize(state),
    profile: getClassicGameWinner(state),
    isVisible: getClassicGameWinnerVisibleStatus(state),
    user: getUser(state),
    gameLadder: getClassicGameLadder(state),
    userChances: getClassicGameUserChances(state),
    appDiffTime: getAppDiffTime(state),
  }),
  dispatch => ({
    gameActions: bindActionCreators(gameActions, dispatch),
  })
)(_Game);
