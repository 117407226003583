import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Timer } from '@components/TimerEvent';
import { Phases } from '../../interfaces/reducer.leaders.interfaces';

interface IGameInfo {
  type: string;
  finishEvent: string;
  stageFinishEvent?: string;
  phase: Phases;
  quantityItems: number;
}

interface IGameInfoStyle {
  ruffling: boolean;
  type: string;
}

export const GameInfo: FC<IGameInfo> = ({
  type,
  finishEvent,
  stageFinishEvent,
  phase,
  quantityItems,
}) => {
  const ruffling = [Phases.step_raffling, Phases.raffling].includes(phase);
  const { t } = useTranslation();
  return (
    <Container className={`race-info ${type}`} ruffling={ruffling} type={type}>
      <span className="type">
        {t('Raffle interval', {
          context: type,
        })}
      </span>
      <span className="description">
        {t('Raffle appeal', {
          context: type,
        })}
      </span>
      <span className="description">
        {t('Raffle faq', {
          context: type,
        })}
      </span>
      <span className="description">
        {t('Raffle info', {
          context: type,
        })}
      </span>
      <span className="things">
        {t('Items in raffle', { items: quantityItems, context: type })}
      </span>
      <span className="event-timer">
        {ruffling && <span>{t('Raffling')}</span>}
        {[Phases.waiting].includes(phase) && <Timer finishEvent={finishEvent} />}
        {[Phases.step_waiting].includes(phase) && (
          <Timer key={phase} finishEvent={stageFinishEvent} />
        )}
      </span>
    </Container>
  );
};

const Container = styled.div<IGameInfoStyle>`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  position: relative;

  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  line-height: 1.4;
  &.two-week {
    --race-type: var(--regular-race);
  }

  &.two-month {
    --race-type: var(--fast-race);
  }

  &.one-year {
    --race-type: var(--great-race);
  }

  & .type {
    font-size: 1.8em;
    font-weight: bold;
    line-height: 1.5;
    color: var(--race-type);
  }

  & .description,
  & .things,
  & .event-timer {
    color: white;
  }

  & .description {
    font-size: 0.75em;
    letter-spacing: 0.53px;
    text-transform: none;
    opacity: 0.9;
  }

  & .things {
    font-size: 0.7em;
    opacity: 0.5;
    line-height: 1.8;
    font-weight: normal;
    margin-top: 0.3rem;
  }

  & .event-timer {
    font-size: ${p => (p.ruffling ? '1rem' : '1.875rem')};
    font-weight: bold;
    position: absolute;
    top: ${p => (p.ruffling ? '90%' : '100%')};
    transition: all 120ms linear;

    & > span {
      color: var(--color-green);
    }
  }
`;
