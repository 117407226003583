import * as React from 'react';
import styled from 'styled-components';
// import { CrossSelector } from 'core/CrossServices'
import { LanguageSelector } from 'locales';

export const DrawerSettings = () => (
  <Container>
    {/*<CrossSelector />*/}
    <LanguageSelector />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
