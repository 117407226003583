import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { useAccountRouter } from '../hooks';
import { AccountProfilesPage, AccountRaceActivityPage, AccountStatistics } from './Pages';

export const AccountsRouter: FC<RouteComponentProps> = ({ match }) => {
  useAccountRouter();
  return (
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/statistics`} component={AccountStatistics} />
        <Route path={`${match.url}/race-activity`} component={AccountRaceActivityPage} />
        <Route path={match.url} component={AccountProfilesPage} />
      </AnimatedRouter>
    </div>
  );
};
