export default {
  Cart: 'Carrinho',
  Price: 'Preço',
  Type: 'Type',
  Categories: 'Categorias',
  Rarity: 'Raridade',
  OK: 'OK',
  Clear: 'Claro',
  Autoselect: 'Selecionar automaticamente',
  Remove: 'Remove',
  Bet: 'Aposta',
  Min: 'Mín.',
  Max: 'Máx.',
  'it.': '',
  Total: 'Total',
  items: 'Unid',
  Pistols: 'Pistolas',
  Rifles: 'Rifles',
  'Submachine guns': 'Submachine guns',
  Heavy: 'Pesado',
  Knives: 'Facas',
  Gloves: 'Luvas',
  Keys: 'Chaves',
  Other: 'De outros',
  'Base Grade': 'Grau Base',
  'Consumer Grade': 'Classificação do Consumidor',
  'Industrial Grade': 'Classe industrial',
  Restricted: 'Restrito',
  'Mil-Spec Grade': 'Mil-Spec Grade',
  Classified: 'Classificado',
  'High Grade': 'Nota alta',
  Covert: 'Covert',
  Remarkable: 'Notável',
  Exotic: 'Exótico',
  Contraband: 'Contrabando',
  Extraordinary: 'Extraordinário',
  Buy: 'Comprar',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Armazene apenas para a retirada de ganhos ou moedas não utilizadas. É estritamente proibida a troca de skins com o objectivo de lucrar com a diferença de preços com outros mercados. Os infractores serão proibidos permanentemente.',
  'Your purchase will be delivered within 15 minutes.':
    'Sua compra será entregue dentro de 15 minutos.',
  'Congratulations! You have successfully purchased': 'Parabéns! Você comprou com sucesso',
  'Order confirmation': 'Confirmação da encomenda',
  Filters: 'Filters',
  'By price': 'Por preço',
  Filter: 'Filtro',
  'Not found': 'Não encontrado',
  'At present it is unable to find any items on your request. Try again later.':
    'No momento, não é possível encontrar nenhum item no seu pedido. Tente mais tarde.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
