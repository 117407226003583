import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { FastInventoryItems } from './fast-inventory-items';
import { FastInventoryFilters } from './fast-inventory-filters';
import { FastInventoryController } from './fast-inventory-controllers';
import { useStockMode } from '../hooks';

export const FastInventory: FC = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { mode, setMode, reset } = useStockMode();

  return (
    <>
      <div ref={parentRef} className="content dark-layout">
        <Container>
          <FastInventoryFilters />
          <FastInventoryItems setMode={setMode} parentRef={parentRef} />
          <FastInventoryController mode={mode} reset={reset} />
        </Container>
      </div>
      <div id="stock" />
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content 1fr;
  min-height: 100%;
  position: relative;
`;
