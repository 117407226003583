import React, { useState } from 'react';
import { actionRequestCaseList, priceRangeAction } from 'games/CaseGame/ducks';
import { useDispatch } from 'react-redux';
import { PriceRange } from '@components/filters/price-range/price-range';
import { IDialogComponent } from 'core/interfaces';
import { useGetCaseListData, useGetSortParams } from 'games/CaseGame/hooks';

interface PriceRangeDialogProps extends IDialogComponent<boolean> {}

export const PriceRangeDialog: React.FC<PriceRangeDialogProps> = ({ handleCloseModal }) => {
  const { casesData } = useGetCaseListData();
  const sortParams = useGetSortParams();
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const prices = casesData.data.caseList.map(value => value.lastRevision.price);
  const minValue = Math.min(...prices) ?? 20;
  const maxValue = Math.max(...prices) ?? 22000;

  const [range, setRange] = useState<{
    minPrice: number;
    maxPrice: number;
  }>({
    maxPrice: maxValue,
    minPrice: minValue,
  });

  const onSubmit = () => {
    const currentMaxPrice = range.maxPrice > range.minPrice ? range.maxPrice : range.minPrice;
    const currentMinPrice = range.maxPrice > range.minPrice ? range.minPrice : range.maxPrice;
    dispatch(
      actionRequestCaseList({
        id: 'all',
        filterParams: {
          sortBy: sortParams.sortBy,
          maxPrice: currentMaxPrice,
          minPrice: currentMinPrice,
          query: sortParams.query,
        },
      })
    );
    dispatch(priceRangeAction(currentMinPrice, currentMaxPrice));
    handleCloseModal();
  };

  const onClick = (text: string, minPrice: number, maxPrice: number) => {
    setSelected(text);
    const min = minPrice > maxValue ? minValue : minPrice < minValue ? minValue : minPrice;

    const max = Number(maxPrice) > maxValue ? maxValue : maxPrice ?? maxValue;

    const finalMax = max > min ? max : maxValue;
    setRange(prev => ({
      ...prev,
      minPrice: min,
      maxPrice: finalMax,
    }));
  };

  const changeHandler = ({ minPrice, maxPrice }: { minPrice: number; maxPrice: number }) => {
    setRange({
      minPrice,
      maxPrice,
    });
  };

  return (
    <PriceRange
      range={range}
      selected={selected}
      i18nextKey="cases2.0"
      setData={onClick}
      onSubmit={onSubmit}
      maxValue={maxValue}
      minValue={minValue}
      handleCloseDialog={handleCloseModal}
      changeHandler={changeHandler}
    />
  );
};
