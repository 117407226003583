import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  padding: 3rem 1rem 0;

  & .gift-card-step {
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.571px;
    color: var(--color-white);
  }

  & .input-inner-container {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2.125rem;

    & .event-description {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 3rem;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.54px;
      color: white;

      & i {
        font-size: 1rem;
        color: var(--color-orange-ticket);
        margin-right: 0.25rem;
      }
    }
  }
`;

export const NumberInput = styled.input`
  width: 100%;
  height: 40px;
  background-color: var(--color-white);
  font-size: 1rem;
  letter-spacing: 0.47px;
  color: var(--dark);
  border: 0;
  border-radius: 5px;
  padding: 0 0.7rem;

  &::placeholder {
    opacity: 0.5;
    font-size: 1rem;
    letter-spacing: 0.47px;
    color: #161e21;
  }
`;

export const Fields = styled.div`
  & > div {
    font-size: 0.8rem;
    letter-spacing: 0.03px;
    color: var(--color-white);
    margin: 0 0 0.5rem;
  }
`;
