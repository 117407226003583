import React, { FC, useEffect, useRef, useState } from 'react';
import { Container, SliderContainer, SliderItemContainer } from './slider.styles';
import { Pagination } from '../pagination';
import { Slide } from '../slide';
import { usePreventVerticalScroll } from 'core/hooks/slider';

export const Slider: FC = () => {
  const ref = useRef<HTMLDivElement>();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasAnimation, setHasAnimation] = useState(true);
  const [disableScreen, setDisableScreen] = useState(false);
  usePreventVerticalScroll(ref);

  const [start, setStart] = useState({
    x: null,
    y: null,
  });

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setHasAnimation(true);

    setStart({
      y: e.touches[0].clientY,
      x: e.touches[0].clientX,
    });
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    setStart({ x: null, y: null });
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!start.x) return;
    const currentX = e.touches[0].clientX;
    const differenceY = Math.abs(e.touches[0].clientY - start.y);
    const differenceX = start.x - currentX;

    if (differenceX > 50 && differenceY < 25) {
      if (currentPage === 13) {
        setCurrentPage(0);
      } else {
        setCurrentPage(prev => prev + 1);
      }

      if (currentSlide < 15) {
        setDisableScreen(true);
        setCurrentSlide(prev => prev + 1);
      }

      setStart({ x: null, y: null });
      return;
    } else if (differenceX < -50 && differenceY < 25) {
      if (currentPage === 0) {
        setCurrentPage(13);
      } else {
        setCurrentPage(prev => prev - 1);
      }

      if (currentSlide > 0) {
        setDisableScreen(true);
        setCurrentSlide(prev => prev - 1);
      }

      setStart({ x: null, y: null });
      return;
    }
  };

  useEffect(() => {
    let timer: null | number = null;

    if (disableScreen) {
      timer = setTimeout(() => {
        setDisableScreen(false);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [disableScreen]);

  useEffect(() => {
    let timer: null | number = null;

    if (currentSlide === 15) {
      timer = setTimeout(() => {
        setHasAnimation(false);
        setCurrentSlide(1);
      }, 500);
    } else if (currentSlide === 0) {
      timer = setTimeout(() => {
        setHasAnimation(false);
        setCurrentSlide(14);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide]);

  interface Data {
    [key: number]: {
      personalData: string;
      whenWeCollect: string;
      howWeUse: string[];
      whyWeUse: string[];
    };
  }

  const data: Data = {
    0: {
      personalData: 'All personal data',
      whenWeCollect: 'When You use Our services',
      howWeUse: [
        'We will use all of Your personal data to carry out Our ongoing monitoring process for the purposes of identifying and dealing with potential money laundering, financing of terrorism and fraudulent activities. This is also necessary to assist in making Your transactions and Games secure, enable Us to protect Your digital assets.',
      ],
      whyWeUse: ['Necessary to comply with a legal obligation'],
    },
    1: {
      personalData: 'Information about the permitted age',
      whenWeCollect: 'In registration procedure',
      howWeUse: ['We need to make sure that Your use of Our services is legal'],
      whyWeUse: ['Necessary to comply with a legal obligation'],
    },
    2: {
      personalData: 'Email',
      whenWeCollect: 'In Your account settings',
      howWeUse: [
        'We use it to notify You about changes to Our Terms or Privacy Policy; to communicate with you',
        'We use Your e-mail address to inform You about the latest updates of Our Services and other products You may like. If You have subscribed to Our newsletter, We may send You marketing communications and information that will create an excellent customer experience',
      ],
      whyWeUse: ['Necessary to comply with a legal obligation', 'Only with Your explicit consent'],
    },
    3: {
      personalData: 'Name, surname, patronymic, residential address',
      whenWeCollect: 'In KYC procedure',
      howWeUse: [
        'We use Your personal data as part of Our due diligence / KYC procedure on Our “Know Your Customer” KYC portals and to identify You in Our Services',
      ],
      whyWeUse: ['Necessary to comply with a legal obligation'],
    },
    4: {
      personalData: 'Social Network ID',
      whenWeCollect: 'In registration procedure or linking the social network to the account',
      howWeUse: ['We use it to identify You in Our Services'],
      whyWeUse: ['Necessary for the performance of a contract with you'],
    },
    5: {
      personalData: 'Passport ID',
      whenWeCollect: 'KYC',
      howWeUse: [
        'We need to make sure that Your use of Our services is legal. We have to check whether You are permitted user of Our services subject to applicable legislation',
        'Your location, include: IP address, time zone, from which You access Our app or Website, Country',
      ],
      whyWeUse: ['Necessary to comply with a legal obligation', 'KYC'],
    },
    6: {
      personalData: 'Copies of “proof of identity” and “proof of residence” documents',
      whenWeCollect: 'KYC',
      howWeUse: [
        'Data may be requested additionally during the KYC procedure if the minimum data is insufficient or when You make a payment of 10 000 € or over',
      ],
      whyWeUse: ['Necessary to comply with a legal obligation'],
    },
    7: {
      personalData: 'Data on the use of Our services, links, session id, socked id',
      whenWeCollect: 'When You use Our services',
      howWeUse: [
        'We use it to improve Your customer experience and provide bespoke services that satisfy Your needs and preferences',
      ],
      whyWeUse: ['Necessary for Our legitimate interests'],
    },
    8: {
      personalData:
        'Information about Your Games (participation in all games, game results, game statistics, number of attempts, number of victories in a row)',
      whenWeCollect: 'When You do the Games',
      howWeUse: ['We use it to show you results and progress in your account, pay of winnings'],
      whyWeUse: ['Necessary for the performance of a contract with you'],
    },
    9: {
      personalData: 'Information about Your stats',
      whenWeCollect: 'When Your stats change',
      howWeUse: ['We use it to show You Your progress'],
      whyWeUse: ['Necessary for the performance of a contract with you'],
    },
    10: {
      personalData:
        'Unique identifiers, browser type and settings, device type and settings, operating system, mobile network information. We also collect information about the interaction of Your browsers and devices with Our services, including OS, IP address, URL of Your request',
      whenWeCollect: 'When You use Our services',
      howWeUse: [
        'We use it to optimise Our service and to improve Our app, Website, games, services, direct marketing, player relationships, behaviour profiling and experiences',
      ],
      whyWeUse: ['Necessary for Our legitimate interests'],
    },
    11: {
      personalData: 'Steam/vgo trade id',
      whenWeCollect: 'When you add it in Your account',
      howWeUse: ['We use it to send winnings and replenish the balance of skins'],
      whyWeUse: ['Necessary for the performance of a contract with you'],
    },
    12: {
      personalData: 'Your wallet address',
      whenWeCollect: 'When you make transactions',
      howWeUse: [
        'We use it to enable you to complete a transaction and receive funds. We use it to prevent illegal use of Your wallet by others.',
      ],
      whyWeUse: ['Necessary for the performance of a contract with you'],
    },
    13: {
      personalData: 'Referral information (Your referral link, your referrals)',
      whenWeCollect: 'When you do a referral link',
      howWeUse: [
        'We use it that You could participate in the referral system and receive bonuses for it.',
      ],
      whyWeUse: ['Necessary for the performance of a contract with you'],
    },
    14: {
      personalData: 'All personal data',
      whenWeCollect: 'When You use Our services',
      howWeUse: [
        'We will use all of Your personal data to carry out Our ongoing monitoring process for the purposes of identifying and dealing with potential money laundering, financing of terrorism and fraudulent activities. This is also necessary to assist in making Your transactions and Games secure, enable Us to protect Your digital assets.',
      ],
      whyWeUse: ['Necessary to comply with a legal obligation'],
    },
    15: {
      personalData: 'Information about the permitted age',
      whenWeCollect: 'In registration procedure',
      howWeUse: ['We need to make sure that Your use of Our services is legal'],
      whyWeUse: ['Necessary to comply with a legal obligation'],
    },
  };

  const countCategories = new Array(16).fill(0).map((_, index) => index);

  return (
    <Container ref={ref}>
      <SliderContainer
        currentSlide={currentSlide}
        hasAnimation={hasAnimation}
        disableScreen={disableScreen}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {countCategories.map(index => (
          <SliderItemContainer key={index}>
            <Slide subtitles={data[index]} />
          </SliderItemContainer>
        ))}
      </SliderContainer>
      <Pagination currentSlide={currentPage} />
    </Container>
  );
};
