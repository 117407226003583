import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 56px;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  padding: 0 16px;
  text-align: center;
`;

export const Subtitle = styled.span`
  color: #a3b4bc;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  padding: 0 16px;
  text-align: center;
`;

export const MotivatorItemContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  .motivator-item-appear {
    transform: translateY(50px);
  }

  .motivator-item-appear-active {
    transform: translateY(0);
    transition: transform 1s ease;
  }

  .motivator-item-enter-done {
    transform: translateY(0);
  }
`;
