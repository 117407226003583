import React, { FC } from 'react';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { Container, SkeletonAvatar, SkeletonName, SkeletonPrice } from './PlayerSkeleton.styles';

export const PlayerSkeleton: FC = () => (
  <Container>
    <SkeletonAvatar className="skeleton">
      <CaseBattleIcon.EmptyIcon />
    </SkeletonAvatar>
    <SkeletonName className="skeleton" />
    <SkeletonPrice className="skeleton" />
  </Container>
);
