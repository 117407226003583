import { InventoryItem } from 'games/CaseGame/interfaces';

export enum OrderActionTypes {
  ADD_TO_ORDER = 'app/exchange/ADD_TO_ORDER',
  DELETED_FROM_ORDER = 'app/exchange/DELETED_ORDER',
  RESET_ALL_ORDER = 'app/exchange/RESET_ORDER',

  FETCH_PURCHASE_ORDER = 'app/exchange/FETCH_PURCHASE_ORDER',
}

export interface IAddToOrder {
  type: typeof OrderActionTypes.ADD_TO_ORDER;
  payload: {
    item: InventoryItem;
  };
}

export interface IDeletedFromOrder {
  type: typeof OrderActionTypes.DELETED_FROM_ORDER;
  payload: {
    item: InventoryItem;
  };
}

export interface IResetAllOrder {
  type: typeof OrderActionTypes.RESET_ALL_ORDER;
}

export interface IFetchPurchaseOrder {
  type: typeof OrderActionTypes.FETCH_PURCHASE_ORDER;
}

export type orderActions = IAddToOrder | IDeletedFromOrder | IResetAllOrder;
