export default {
  '/settings': 'Configurações',
  '/marketplace': 'Mercado',
  '/balance': 'Saldo',
  '/auth': 'Aut',
  '/notifications': 'Notificações',

  RULES: 'REGRAS',
  Chat: 'Bate-papo',
  GAME: 'JOGO',
  Top: 'Melhor',
  HISTORY: 'HISTÓRICO',
};
