import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import {
  AnimatedTournamentList,
  TournamentHeader,
  TournamentHistoryLink,
  UserPlaces,
} from '../../components/Tournaments';
import { BigPreloader } from '@components/Preloader';
import { useTournamentFacade } from '../../hooks';
import couponsprite from '../../assets/couponsprite.svg';
import { useTranslation } from 'react-i18next';

interface ITournamentPage {
  raceType: string;
}

export const TournamentPage: React.FC<ITournamentPage> = memo(({ raceType }) => {
  const { race, tournamentHistory, pushToHistory } = useTournamentFacade(raceType);
  const { t } = useTranslation();

  if (!race) return <BigPreloader />;

  return (
    <>
      {/*<div className="content">*/}
      <TournamentWrapper isLock={Date.now() > +new Date(race.finishAt)}>
        <TournamentHeader
          raceType={race.raceType}
          prizeTicketsAmount={race.prizeTicketsAmount}
          startAt={race.startAt}
          finishAt={race.finishAt}
          myStats={race.myStats}
          prizeCoinsAmount={race.prizeCoinsAmount}
        />
        {!!race.myStats && <UserPlaces myStats={race.myStats} />}
        <AnimatedTournamentList leaderboard={race.leaderboard} raceType={raceType} />
        <div className="tournament-placeholder">
          <div className="tournament-placeholder__thumbnail">
            <svg className="icon">
              <use xlinkHref={`${couponsprite}#coupon-${race.raceType}`} />
            </svg>
          </div>
          <span className={race.raceType}>
            {t('Races type', {
              context: raceType,
            })}
          </span>
          <span>{t('New race will start soon')}</span>
        </div>
      </TournamentWrapper>
      {tournamentHistory.entities.map((history: any) => {
        return (
          <TournamentHistoryLink
            key={history.id}
            history={history}
            pushToHistory={pushToHistory(history.id, history.raceType)}
          />
        );
      })}
      {/*</div>*/}
    </>
  );
});

interface ITournamentWrapper {
  isLock: boolean;
}

const TournamentWrapper = styled.div<ITournamentWrapper>`
  & .tournament-placeholder {
    display: none;
    grid-template-rows: repeat(3, min-content);
    grid-gap: 0.85rem;
    justify-self: center;
    align-self: center;
    justify-content: center;
    padding: 5.125rem 0 5.125rem;
    height: calc(100vh - var(--size-nav-bar));
    background-color: var(--color-dark-200);

    position: sticky;
    bottom: 0;

    &__thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: var(--color-dark-500);

      & svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    & span {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.3125rem;
      text-align: center;
      color: var(--color-white);

      &.great {
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.85rem;
        color: var(--great-race);
      }
    }
  }

  ${p =>
    p.isLock &&
    `
    & .tournament-placeholder {
      display: grid;
    }
  `}
`;
