import { IModule } from 'core/rootInterfaces/root.interface';
import { caseBattleReducer, eventsTypes } from './ducks';
import './locales';
export * from './ducks';

export const caseBattle: IModule = {
  moduleName: 'caseBattle',
  moduleId: 10,
  path: '/case-battle',
  type: 'pvp',
  reducer: caseBattleReducer,
  eventsTypes,
};

export { CaseBattleRouter } from './CaseBattleRouter';
