import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface ContainerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  left?: number;
}

const gapValue = 4;

export const Container = styled.div.attrs<ContainerProps>(props => ({
  style: {
    marginLeft: `${props.left}px`,
  },
}))<{
  lineIndex: number;
  itemWidth: number;
  left?: number;
}>(({ lineIndex, itemWidth }) => {
  return css`
    display: flex;
    flex-direction: row;
    gap: ${gapValue}px;
    
  @keyframes roulette${lineIndex} {
  0% {
      transform: translateX(0);
    }

  5% {
      transform: translateX(${Math.random() * (itemWidth / 4)}px);
    }

  90% {
      transform: translateX(${-(
        (itemWidth + gapValue) * 27 +
        Math.random() * (itemWidth / 2) * getrandomsign()
      )}px);
    }
  
  100% {
      transform: translateX(${-((itemWidth + gapValue) * 27)}px);
    }
  }

&[data-anima='anima'] {
  animation: roulette${lineIndex} cubic-bezier(0, 0.2, 0.1, 1);
  animation-delay:${lineIndex * 40}ms;   
  -webkit-animation-fill-mode: forwards;
}

&[data-speed-type='fast']{
  animation-duration: 1450ms;
}

&[data-speed-type='normal']{
  animation-duration: 4450ms;
}
  `;
});

const getrandomsign = () => {
  return Math.random() >= 0.5 ? -1 : 1;
};
