import { combineEpics } from 'redux-observable';
import { listenersEpic, loaderEpic, participateEpic, watcherEpic } from './duck';
// import { LotteryActionTypes } from 'core/interfaces'
// import { store } from 'store/configureStore'
// import { rootEpics$ } from 'store/epicMiddleware'

export const lotteryEpics = combineEpics(listenersEpic, watcherEpic, loaderEpic, participateEpic);

// export const registerLottery = () => {
//   rootEpics$.next([lotteryEpics, LotteryActionTypes.LOTTERY_DETACH_LISTENERS])
//   store.reducerManager.add('lottery', lottery)
// }
//
// export const unregisterLottery = () => {
//   store.reducerManager.remove('lottery')
// }
