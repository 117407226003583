import React, { FC } from 'react';
import CountUp from 'react-countup';
import { ProgressContainer } from './progress-bar.styles';
import { ProgressBg } from '../progress-bar/progress-bg';
import { Progress } from '../progress-bar/progress';

interface IProgressBar {
  progress: number;
  progressWidth: number;
  loader: number;
  containerWidth: number;
}

export const ProgressBar: FC<IProgressBar> = ({
  progress,
  progressWidth,
  loader,
  containerWidth,
}) => {
  return (
    <ProgressContainer>
      {!!containerWidth && <ProgressBg containerWidth={containerWidth} />}
      {!!progressWidth && <Progress progressWidth={progressWidth} />}
      <CountUp
        decimal="."
        decimals={Number.isInteger(progress) ? 0 : 2}
        duration={1}
        start={loader}
        end={progress}
        suffix="%"
        delay={0}
      />
    </ProgressContainer>
  );
};
