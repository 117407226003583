import prizePath from './prize.png';
import balanceBannerPath from './balance-banner.webp';
import bronzeDailyPath from './bronze-daily.webp';
import silverWeeklyPath from './silver-weekly.webp';
import goldSpecialPath from './gold-special.webp';
import comicsPath from './comics.webp';
import superPricePath from './super-prize.webp';
import mainBannerPath from './main-banner.webp';
import rarePrizePath from './prize-rare-1.svg';
import tabBgPath from './tab-bg.svg';
import winnerPath from './winner.webp';
import slider1Path from './slide1.png';
import resultBgPath from './result-bg.webp';
import badgePath from './badge.webp';
import menuBgPath from './menu-bg.webp';

import { ReactComponent as Replay } from './replay.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as AppBarEventIcon } from './app-bar.svg';
import { ReactComponent as Slide2 } from './slide2.svg';
import { ReactComponent as Completed } from './completed.svg';
import { ReactComponent as Slide3 } from './slide3.svg';
import { ReactComponent as Special } from './special.svg';

import { ReactComponent as FirstPlace } from './first-place.svg';
import { ReactComponent as SecondPlace } from './second-place.svg';
import { ReactComponent as ThirdPlace } from './third-place.svg';
import { ReactComponent as Next } from './next.svg';

export const eventImagesPath = {
  prizePath,
  balanceBannerPath,
  bronzeDailyPath,
  silverWeeklyPath,
  goldSpecialPath,
  comicsPath,
  superPricePath,
  mainBannerPath,
  rarePrizePath,
  tabBgPath,
  winnerPath,
  slider1Path,
  resultBgPath,
  badgePath,
  menuBgPath,
};

export const EventIcons = {
  Replay,
  Lock,
  AppBarEventIcon,
  Slide2,
  Slide3,
  Completed,
  SecondPlace,
  ThirdPlace,
  Next,
  FirstPlace,
  Special,
};
