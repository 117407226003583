import * as React from 'react';

const { useState, useEffect } = React;

export const useSwitchGame = (timeOldEnds: number | null | undefined) => {
  const [status, setStatus] = useState<boolean>(!!(timeOldEnds && timeOldEnds + 9000 < Date.now()));

  useEffect(() => {
    if (timeOldEnds && !status) {
      const animationTimer = setTimeout(() => {
        setStatus(!!(timeOldEnds && timeOldEnds + 9000 < Date.now()));
      }, 10000);
      return () => clearTimeout(animationTimer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOldEnds]);

  return {
    status,
  };
};
