export default {
  '100$ for the new players!': '$ 100 สำหรับผู้เล่นใหม่!',
  '100$ bonus is credited to a demo balance': 'รับ 100 $ ทันที!',
  'Get 100$ now!': 'โบนัส 100 $ จะถูกโอนไปยังยอดเงินเดโม',

  'Top up your balance from 25$ and get bonuses': 'เติมยอดเงินของคุณจาก 25 $ และรับโบนัส',
  'Exp bonus': 'โบนัสประสบการณ์',
  'Wheel Of Fortune free spin': 'Wheel of Fortune หมุนฟรี',
  'Free case': 'กรณีฟรี',
  'Access to account level-up': 'เข้าถึงระดับบัญชี',
};
