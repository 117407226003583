import styled from 'styled-components';

interface IPurchaseStyle {
  rarity?: string;
}

export const Container = styled.div<IPurchaseStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1.5rem 5.5rem 1fr 3.125rem;
  grid-gap: 1.875rem;
  height: 26rem;
  padding: 1.75rem 1rem;
  background: var(--color-dark-600);
  border-top: 1px solid ${({ rarity }) => rarity || 'transparent'};
  position: relative;

  & .close {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }

  & .stickers {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1.875rem;
    grid-auto-rows: 1.875rem;
    grid-gap: 1.75rem;
    position: absolute;
    left: 1rem;
    top: 5.6rem;

    & img {
      width: 1.875rem;
      height: 1.875rem;
      object-fit: contain;
    }
  }

  & .header {
    justify-self: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--color-white);
  }

  & .thumbnail {
    justify-self: center;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .detailed {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-rows: min-content;
    grid-gap: 0.125rem;
    justify-self: center;

    &-appearance {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1rem;

      &-exterior,
      &-float {
        white-space: nowrap;
        font-size: 0.75rem;
        letter-spacing: 0.1px;
      }

      &-exterior {
        line-height: 1.125rem;
        color: var(--color-gray-200);
        text-transform: uppercase;
      }

      &-float {
        line-height: 1.125rem;
        color: transparent;

        &.factory-new {
          color: var(--color-exterior-factory-new);
        }

        &.minimal-wear {
          color: var(--color-exterior-minimal-wear);
        }

        &.field-tested {
          color: var(--color-exterior-field-tested);
        }

        &.well-worn {
          color: var(--color-exterior-well-worn);
        }

        &.battle-scarred {
          color: var(--color-exterior-battle-scarred);
        }
      }
    }

    &-display {
      white-space: nowrap;
      font-size: 0.75rem;
      letter-spacing: 0.1px;

      &-skin,
      &-name {
        justify-self: center;
        white-space: nowrap;
        color: var(--color-white);
      }

      &-name {
        line-height: 1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-skin {
        font-size: 1.125rem;
        letter-spacing: 0.764px;
        line-height: 1.375rem;
        text-align: center;
        text-wrap: wrap;
      }
    }

    &-price {
      justify-self: center;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 2.75rem;
      letter-spacing: 0.69px;
      white-space: nowrap;
      color: var(--color-white);

      & span {
        font-size: inherit;
        letter-spacing: inherit;

        &.overprice {
          color: var(--color-red);
        }

        &.underprice {
          color: var(--color-green);
        }
      }
    }
  }

  & .statuses {
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    display: flex;

    & span {
      color: var(--color-white);
      display: block;
      font-size: 0.75rem;
      letter-spacing: 0.3px;
      line-height: 0.9375rem;
      margin-top: 0.25rem;
    }
  }

  & .bid {
    padding: 0;
    overflow: hidden;
    mask-image: radial-gradient(white, black);
    transition: all 150ms ease-in;

    & div {
      transition: all 150ms ease-in;
    }

    & div:first-of-type {
      font-weight: bold;
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0.286px;
      color: var(--color-dark-600);
    }

    & div:last-of-type:not(:first-of-type) {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-dark-500);
    }

    &.disabled,
    &:disabled {
      pointer-events: none;
      background: var(--yellow-500);

      &::after {
        content: none;
      }

      & div:first-of-type {
        color: var(--color-dark-100);
      }

      & div:last-of-type:not(:first-of-type) {
        color: var(--color-dark-100);
      }
    }

    & .link-inner {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 50px 1fr 50px;
      grid-gap: 0.25rem;
      width: 100%;
      height: 100%;

      & i,
      & span {
        justify-self: center;
        align-self: center;

        &.icon-steam-provider {
          font-size: 1.5rem;
        }

        &.icon-redirect-link {
          font-size: 0.65rem;
        }
      }

      & span {
        font-weight: bold;
        font-size: 0.8125rem;
        line-height: 1rem;
        text-align: center;
        letter-spacing: 0.286px;
        color: var(--color-dark-100);
      }
    }

    &-inner {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.125rem;
      width: 100%;
      padding: 0.5rem;
      position: relative;
      z-index: 20;
      transform: translate3d(0, 0, 1px);
      transition: all 150ms ease-in;
    }
  }
`;
