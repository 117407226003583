import styled from 'styled-components';
import { BlurComponent, Container, TotalWinnings } from '../generalStyles';
import { TeamsContainer } from '../TeamsFinalResults/TeamsFinalResults.styles';

export const UserStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  align-items: center;
`;

export const UserAvatar = styled.img`
  height: 68px;
  width: 68px;
  border-radius: 50%;
  object-fit: cover;
`;

export const EmptyUserAvatar = styled.div`
  height: 68px;
`;

export const SinglePlayersContainer = styled(Container)<{ gap: number; playersCount: number }>`
  display: grid;
  justify-content: center;
  gap: ${({ gap }) => gap}px;
  ${({ playersCount }) => `grid-template-columns: repeat(${playersCount}, ${80}px)`};

  @media screen and (max-width: 374px) {
    ${({ playersCount }) => `grid-template-columns: repeat(${playersCount}, ${66}px)`};
  }
`;

export const SinglePlayerInfoContainer = styled(TeamsContainer)`
  max-width: none;
  flex: 1;
  width: 100%;
`;

export const PlayerTotalWinnings = styled(TotalWinnings)`
  font-size: 12px;

  @media screen and (max-width: 374px) {
    font-size: 10px;
  }
`;

export const SinglePlayerInfoContainerBlur = styled(BlurComponent)`
  width: 10px;

  @media screen and (max-width: 374px) {
    ${({ position }) =>
      position === 'right' ? 'right:-5px;' : 'left:-5px;transform: rotate(180deg);'};
    width: 20px;
  }
`;
