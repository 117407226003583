import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { changePageFilter } from '../../ducks/filters.duck';
import { MergeType } from '../../interfaces';

export const useShopPagination = () => {
  const dispatch = useDispatch();

  const loadMore = useCallback(
    (page: number) => {
      dispatch(changePageFilter(page, MergeType.APPEND));
    },
    [dispatch]
  );

  return {
    loadMore,
  };
};
