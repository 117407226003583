import { createSelector } from 'reselect';
import { getUser } from 'core/User/selectors';

const fastGameGetter = (state: any) => state.fast.games;
const fastGameIDGetter = (state: any, gameId: number) => gameId;

// need to animate an array of objects
export const getFastGameIds = createSelector(fastGameGetter, getUser, games => {
  return games
    .valueSeq()
    .toArray()
    .sort((a: any, b: any) => {
      if (!a.getIn(['rand'])) {
        return -1;
      }
      return a.getIn(['number']) > b.getIn(['number']) ? -1 : 1;
    })
    .map((game: any) => {
      return { number: game.getIn(['number']) };
    })
    .slice(0, 10);
});

export const getFastGameById = createSelector(fastGameGetter, fastGameIDGetter, (games, gameId) =>
  games.get(gameId)
);

export const getGameTradesPrize = ({ fast }: any) => fast.games.last().get('prize');

export const getFastGameLastPool = ({ fast, user }: any) => {
  const last = fast.games.last();
  return last.trades[user.base?.id] ? last.prize : 0;
};
