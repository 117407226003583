import * as React from 'react';
import { AppBar } from '@components/bars';
import { TournamentHeader, TournamentList, UserPlaces } from '../../components/Tournaments';
import { Balance } from '../Balance';
import { BigPreloader } from '@components/Preloader';
import { useTournamentHistoryFacade } from '../../hooks';

export const TournamentHistoryPage: React.FC = () => {
  const { history } = useTournamentHistoryFacade();

  if (!history || !history.leaderboard.length) return <BigPreloader />;
  return (
    <>
      <AppBar deep prependWidget={Balance} />
      <div className="content">
        <TournamentHeader
          raceType={history.raceType}
          prizeTicketsAmount={history.prizeTicketsAmount}
          startAt={history.startAt}
          finishAt={history.finishAt}
          myStats={history.myStats}
          prizeCoinsAmount={history.prizeCoinsAmount}
        />
        {!!history.myStats && <UserPlaces myStats={history.myStats} />}
        <TournamentList leaderboard={history.leaderboard} raceType={history.raceType} />
      </div>
    </>
  );
};
