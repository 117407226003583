import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useVirtual, VirtualItem } from 'react-virtual';
import { CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import { useOrder, useOrderPurchase, useResetOrder } from '../../../hooks';
import { ShopOrderElement } from './shop-order-element';

interface IShopOrderStyle {
  visible: boolean;
}

export const ShopOrder = () => {
  const { t } = useTranslation(['exchange', 'translations']);
  const { order, amount } = useOrder();
  const { createPurchase } = useOrderPurchase();
  const { handleResetOrder } = useResetOrder();
  const parentRef = useRef<HTMLDivElement>(null);

  const elements = useVirtual({
    size: order.length,
    parentRef,
    estimateSize: useCallback(() => 60, []),
    overscan: 2,
    horizontal: true,
  });

  return (
    <Container visible={!!order.length}>
      <div className="header">
        <span>{t('Skins for exchange')}</span>
        <Button className="link-light" onClick={handleResetOrder}>
          {t('translations:::Cancel')}
        </Button>
      </div>
      <div ref={parentRef} className="order-elements">
        <div className="order-list" style={{ width: elements.totalSize }}>
          {elements.virtualItems.map(({ index, start }: VirtualItem) => (
            <div
              key={order[index].id}
              className="order-list-item"
              style={{ transform: `translateX(${start + 16}px)` }}
            >
              <ShopOrderElement item={order[index]} />
            </div>
          ))}
        </div>
      </div>
      <div className="controllers">
        <Button className="primary" onClick={createPurchase}>
          {t('Exchange')} {` (${order.length})`} {t('on sum:')} <CurrencyText value={amount} />
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div<IShopOrderStyle>`
  display: grid;
  grid-template-rows: 2.25rem 6.5rem 4.5rem;
  background: var(--color-dark-600);
  position: sticky;
  left: 0;
  right: 0;
  bottom: ${({ visible }) => (visible ? '0%' : '-13.25rem')};
  height: ${({ visible }) => (visible ? '13.25rem' : '0rem')};
  transition: bottom, height 150ms var(--transition-ease-out);

  & .header {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-gap: 0.25rem;
    border-bottom: 1px solid var(--color-gray-900);
    position: relative;
    padding: 0 1rem;

    &:before {
      content: '';
    }

    & span {
      justify-self: center;
      align-self: center;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-white);
    }

    & button {
      justify-self: self-end;
      position: relative;
      text-align: right;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
      text-transform: none;
    }
  }

  & .order-elements {
    overflow: scroll hidden;
    align-self: center;

    &::-webkit-scrollbar {
      display: none;
    }

    & .order-list {
      width: 100%;
      height: 100%;
      position: relative;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 0.75rem;
        height: 3.5rem;
      }

      &-item {
        width: 3.5rem;
        height: 3.5rem;
        position: absolute;
        top: 1.5rem;
        left: 0;
        transition: transform 70ms ease-out;
      }
    }
  }

  & .controllers {
    padding: 0 1rem 1.5rem;
  }
`;
