import React from 'react';
import { Helmet } from 'react-helmet';
import { getDomainName } from '../../utils/get-domains.utils';
import { useTranslation } from 'react-i18next';

export const Seo = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {getDomainName()} - {t('TRY YOUR LUCK!')}
      </title>

      <meta
        name="keywords"
        content={t(
          'counter strike, cs go betting, luck bets, csgo skins, cs go fast, csgofast, gofast, dota 2 betting,csgo gambling,dota2 gambling, match bets'
        )}
      />

      <meta
        name="description"
        content={t(
          'Service where players can try their luck and get awesome skins! Just deposit your skins to the raffle, become a winner and sweep the board!'
        )}
      />
    </Helmet>
  );
};
