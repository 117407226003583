import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  overflow-x: scroll;
  padding: 1rem 0;

  &::before,
  &::after {
    content: '';
    display: block;
    min-height: 100%;
    min-width: 1.25rem;
  }
`;

interface IStatisticItemStyle {
  gameType: string;
}

export const StatisticItem = styled.div<IStatisticItemStyle>`
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  color: var(--color-white);
  font-size: 1rem;
  margin-right: 1rem;
  min-width: 70vw;
  padding: 1.5rem 1.13rem;

  &:last-of-type {
    margin-right: 0;
  }

  & .game-type {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: flex-start;
    margin-bottom: 1.2rem;

    & > span {
      letter-spacing: 1.33px;
      text-transform: capitalize;
    }
  }

  & .game-description {
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    margin-bottom: 0.5rem;

    &__item {
      align-items: center;
      color: var(--color-white);
      display: flex;
      flex-basis: 50%;
      flex-flow: row nowrap;
      flex-shrink: 0;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      position: relative;

      & > i {
        color: var(--yellow);
        font-size: 0.75rem;
        left: -0.9rem;
        position: absolute;
      }

      &_key {
        font-size: 0.75rem;
        letter-spacing: 0.1px;
        line-height: 15px;
      }

      &_currency {
        font-size: 0.75rem;
        letter-spacing: 0.1px;
        line-height: 15px;
      }
    }
  }
`;
