import React from 'react';
import Quest from './Quest';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ReferralProgram = () => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <Quest height={400} title={t('What is it?')}>
        {t(
          'Referral Program - an encouragement system of users, who invite new players (referrals) to our project You will get Coins for each referral and game he/she plays'
        )}
      </Quest>
      <Quest height={400} title={t('Who is recognized as my referral?')}>
        {t(
          'Referral is a new project user, who follows your referral link or enters your personal code If the invited user had been signed in previously, the Referral Program will not accept him'
        )}
      </Quest>
      <Quest height={400} title={t('How many coins can it be received?')}>
        {t(
          'You get Coins for each referral, who is signed in following your referral link or entering your personal code The number of Coins depends on your level in Referral System Also, you get a certain percent of referral`s bets in any game of our project'
        )}
        <br />
        {t(
          'Referral should have a public Steam account and Counter-Strike: Global Offensive game to get the bonus'
        )}
      </Quest>
      <Quest
        height={400}
        title={t('How to determine the percent and what is a "Level" in Referral Program?')}
      >
        {t(
          'Percent, that you get of your referrals` bets, depends on your level in Referral System - the higher your level, the higher per cent you get'
        )}
        <br />
        <br />
        {t(
          'You can raise your level in Referral System increasing the number of your referrals - more referrals you have, the higher level you get'
        )}
      </Quest>
      <Quest height={400} title={t('What can I exchange my Coins for?')}>
        {t(
          'Collected Coins can be exchanged for skins in the Store Also, they can be used in order to play in Double Game'
        )}
      </Quest>
      <Quest height={400} title={t('When will Coins for referrals be sent to me?')}>
        {t(
          'The Coins are sent to you after your referral has been signed in following your link or entering your referral code The page information is updated every 15 minutes'
        )}
      </Quest>
      <Quest height={400} title={t('Where should my code be entered?')}>
        {t('Code entering for new users is available on the page of Referral System')}
        <br />
        <br />
        {t(
          'Referral should enter the code within 15 minutes since the moment of the first authorization'
        )}
      </Quest>
      <Quest height={400} title={t('What bonuses does the referral get?')}>
        {t(
          'Everyone, who becomes a referral, gets the number of Coins, which depends on the Referral level of user who invites, to his/her account'
        )}
      </Quest>

      <br />
      <Paragraph>
        <Header>{t('Forbidden to use:')}</Header>
      </Paragraph>

      <Paragraph>
        {t(
          'It is not allowed to use official CSGOFASTCOM sources: groups, social networking pages, forums, etc'
        )}
      </Paragraph>

      <Paragraph>
        {t('Use invalid/unacceptable sources: PPC and SEO advertising for the CSGOFASTCOM brand')}
      </Paragraph>

      <Paragraph>
        {t('Use sources that violate legislation, including legislation on intellectual property')}
      </Paragraph>

      <Paragraph>
        {t(
          'Complete the achievement of the goals of registration by forcing other users as a result of a request, compensation, deception or misrepresentation'
        )}
      </Paragraph>

      <Paragraph>
        {t(
          "Change the IP address within the same subnet or using a proxy server and anonymizers, hide or modify the 'referer'"
        )}
      </Paragraph>

      <Paragraph>
        {t(
          "Use invalid/unacceptable sources: clichunder / popander including clichunder / popander via prelanding; Popup format; PPC advertising for the CSGOFASTCOM brand; Motivated traffic by BUX's(Active advertising system); Doorway traffic"
        )}
      </Paragraph>

      <Paragraph>{t('Abusing spam to attract users or other webmasters')}</Paragraph>

      <Paragraph>
        {t('Initiate the automatic actions of users by using scripts, bots, and any other means')}
      </Paragraph>

      <Paragraph>
        {t('Use the toolbars and any extensions for browsers to force traffic')}
      </Paragraph>

      <Paragraph>{t('Referral accounts of violators will be blocked')}</Paragraph>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;

const Paragraph = styled.div`
  padding: 8px 6px 8px 6px;
  color: var(--color-white);
  font-size: 14px;

  line-height: 20px;
  letter-spacing: 0.2px;
`;

const Header = styled.div`
  font-size: 19px;
`;
