import { IModule } from 'core/rootInterfaces/root.interface';
import './locales';

export type { IStockReducer } from './+fast-inventory';
export type { IExchangeReducer } from './+exchange';

export { InventoryRouter } from './inventory-router';
// export const InventoryRouter = lazy(() =>
//   import('./inventory-router').then(module => ({ default: module.InventoryRouter }))
// )

export const inventory: IModule = {
  moduleName: 'inventory',
  path: '/inventory',
  reducer: null,
};
