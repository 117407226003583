import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 3rem;
  justify-content: center;
  padding: 0 16px;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 1.25rem;
  text-align: center;
`;
