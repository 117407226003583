import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { useTotalStatistics } from '../../../hooks/statistics';
import { YourYearlyStatistics } from '../../../interfaces/reducer.statistics.interfaces';

interface IYearlyStatisticHeader {
  myStat: YourYearlyStatistics;
}

export const YearlyStatisticHeader: FC<IYearlyStatisticHeader> = ({ myStat }) => {
  const { t } = useTranslation();
  const { total } = useTotalStatistics();
  return (
    <Container>
      <div className="yearly-title">{t('Leaders Race 2020 results')}</div>
      {myStat?.position && (
        <div className="your-place">
          <span>{t('place')}</span>
          <span>{myStat.position}</span>
        </div>
      )}
      {total?.prizeCoinsAmount && (
        <div className="prize-pool">
          <span>{t('Prize pool')}</span>
          <span>
            <CurrencyText value={total.prizeCoinsAmount} />
          </span>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  padding: 1rem;
  background-color: var(--gray-400);

  & .yearly-title {
    display: flex;
    align-items: center;
    height: 3rem;
    font-size: 1rem;
    color: white;
  }

  & .your-place {
    display: flex;
    justify-content: space-between;

    & span {
      color: var(--color-white-600);
      font-size: 0.8125rem;
    }
  }

  & .prize-pool {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 5.625rem;
    background-color: var(--color-dark-600);

    & > span {
      opacity: 0.5;
      font-size: 0.8rem;
      color: var(--color-white);
      line-height: 2;
      text-transform: uppercase;

      &:last-of-type {
        opacity: 1;
      }
    }
  }
`;
