import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  border-top: 1px solid var(--gray-250);
  padding: 1.75rem 1rem;
  position: relative;

  & .close {
    font-weight: normal;
    position: absolute;
    right: 1rem;
    text-transform: none;
    top: 0.625rem;
  }

  & .round-number {
    color: var(--color-white);
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
  }

  & .hash-list {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.5rem;
    margin: 1.5rem 0 2.25rem;
  }
`;
