import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import { getRewards } from '../../utils';
import { CurrencyText } from '../../../../@components/currency';

import field from '../../assets/cell.png';

export class FieldGame extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fieldAnimate = new Animated.Value(props.ticketType.columns === 2 ? 1 : 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ticketType.columns !== this.props.ticketType.columns) {
      Animated.timing(this.fieldAnimate, {
        toValue: this.props.ticketType.columns === 2 ? 1 : 0,
        duration: 300,
      }).start();
    }
  }
  render() {
    const { bet, ticketType } = this.props;

    const rowRewards = getRewards(ticketType, bet * 100);
    return (
      <FieldContainer className="tablet">
        {rowRewards.reverse().map((reward, index) => (
          <Section key={index}>
            <Field image={field}>
              <Prize>
                <CurrencyText value={reward[0]} />
              </Prize>
            </Field>
            <Field image={field}>
              <Prize>
                <CurrencyText value={reward[0]} />
              </Prize>
            </Field>
            <Animated.div
              style={{
                transform: [
                  {
                    translateX: this.fieldAnimate.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, 300],
                    }),
                  },
                ],
                display: 'flex',
                flexGrow: this.fieldAnimate.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0],
                }),
              }}
            >
              <Field image={field}>
                <Prize>
                  <CurrencyText value={reward[0]} />
                </Prize>
              </Field>
            </Animated.div>
          </Section>
        ))}
      </FieldContainer>
    );
  }
}

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  min-height: calc(75vh - var(--size-nav-bar));
  padding: 9px 20px 11px;
  overflow: hidden;
`;

const Field = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 3px;
  border-radius: 5px;
  background-color: #19d3f9;
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  opacity: 0.8;
`;

const Prize = styled.span`
  width: auto;
  padding: 2px 5px;
  border-radius: 14px;
  background-color: #027c91;

  color: #19d3f9;
  font-weight: bold;
  font-size: 10px;
  line-height: 1.4;
  position: absolute;

  @media (min-width: 350px) {
    font-size: 13px;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 15px;
  flex-grow: 1;
`;
