import { userModals } from 'core/User/modal.config';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { BonusCodeByLinkDecorator } from './decorators';
import { BonusCodeNotAuth } from './decorators/bonuc-code-not-auth.decorator';
import { FreeCaseDialog } from './free-case-dialog/free-case-dialog';

DIALOG.add(userModals.REFERRAL_BONUS_MODAL, {
  component: BonusCodeByLinkDecorator,
  transition: transitions.SLIDE_UP_CENTER,
  anchor: 'portal',
});

DIALOG.add(userModals.REFERRAL_BONUS_MODAL_NOT_AUTH, {
  component: BonusCodeNotAuth,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

DIALOG.add(userModals.FREE_CASE_BONUS, {
  component: FreeCaseDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
