import React from 'react';
import { useGetCaseBattleGameData, useGetRouletteData } from 'games/CaseBattle/hooks';
import { BlurComponent, Container } from './Roulette.styles';
import { useSelector } from 'react-redux';
import { getRouletteLine } from 'games/CaseBattle/utils';
import { getCasesForBattleByIds } from 'games/CaseBattle/ducks';
import { RouletteLinesContainer } from '../RouletteLineContainer';

interface RouletteProps {
  playerId: number;
}

export const Roulette: React.FC<RouletteProps> = React.memo(({ playerId }) => {
  const { data } = useGetRouletteData();
  const { data: casesData } = useSelector(getCasesForBattleByIds);
  const { currentRound, cases, strips } = data;
  const { data: generalData } = useGetCaseBattleGameData();
  const userRouletteLine = strips.find(({ userId }) => userId === playerId)?.strip;

  const rouletteLineItems = getRouletteLine(userRouletteLine, currentRound, cases, casesData);

  return (
    <Container>
      <BlurComponent position="top" />
      <RouletteLinesContainer
        rouletteLineItems={rouletteLineItems}
        playerId={playerId}
        isJoker={generalData.game.settings.jokerMode}
      />
      <BlurComponent position="bottom" />
    </Container>
  );
});
