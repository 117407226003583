import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IPlayerComparisonHeader {
  date?: string;
}

export const PlayerComparisonHeader: FC<IPlayerComparisonHeader> = ({ date }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <span className="title">
        {!!date &&
          t('Races type', {
            context: 'regular',
          })}
      </span>
      <span className="date">{date}</span>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.7rem;
  margin: 0.9rem 0;
  font-size: 0.9rem;
  line-height: 1.4;

  & .title {
    justify-self: self-end;
    color: var(--color-white);
  }

  & .date {
    justify-self: self-start;
    color: var(--color-gray-100);
  }
`;
