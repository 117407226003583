import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 2.7rem;
  grid-gap: 2rem 1rem;
  padding: 4.5rem 1rem 3.875rem;

  & img {
    height: 2.7rem;
    object-fit: contain;
    grid-column: span 2;
    justify-self: center;

    &:nth-child(4),
    &:nth-child(5) {
      grid-column: span 3;
    }
  }
`;
