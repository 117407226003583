import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common/Navigations';
import dayjs from 'dayjs';

export const LandingSteps: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="inner-container">
        <div className="step">
          <div className="step__circle">1</div>
          <span>{t('Refill your balance')}</span>
          <i className="icon-arrow-up" />
        </div>
        <div className="step">
          <div className="step__circle">2</div>
          <span>{t('Get your yellow ticket')}</span>
          <i className="icon-arrow-up" />
        </div>
        <div className="step">
          <div className="step__circle">3</div>
          <span>{t('Win cool prizes')}</span>
          <i className="icon-arrow-up" />
        </div>
        <div />
        <AppealNavigationLink to="/refill" as={Link}>
          <div>
            <span>{t('Refill balance and get yellow ticket!')}</span>
            <span className="badge-light">
              {t('Available at')}
              {'28.12.2020 - '}
              {dayjs('2021-1-1T10:00:00').format('DD.MM.YYYY:HH:mm')}
            </span>
          </div>
        </AppealNavigationLink>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 41.25rem;
  background-color: var(--great-race);
  position: relative;

  &:before,
  &:after,
  & .inner-container:before {
    content: '';

    width: 433px;
    height: 326px;
    background-color: var(--color-dark-100);
    opacity: 0.03;
    clip-path: polygon(50% 60%, 100% 0, 100% 40%, 50% 100%, 0 40%, 0 0);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:before {
    top: -30%;
  }

  &:after {
    top: 10%;
  }

  & .inner-container:before {
    top: 50%;
  }

  & .inner-container {
    display: grid;
    grid-template-rows: repeat(3, 1fr) 5.5rem 3.75rem;
    grid-gap: 1rem;
    height: 41.25rem;
    padding: 3.5rem 0 0;

    & .step {
      display: flex;
      justify-content: space-around;
      flex-flow: column;
      align-items: center;

      &__circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.375rem;
        height: 3.375rem;
        border-radius: 50%;
        background-color: var(--color-dark-100);

        font-weight: bold;
        font-size: 1.875rem;
        line-height: 2.1875rem;
        letter-spacing: 0.54px;

        color: white;
      }
      & span,
      & i {
        color: var(--color-dark-100);
      }

      & span {
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        letter-spacing: 0.54px;
        color: var(--color-dark-100);
      }
      & i {
        font-size: 1.4rem;
        transform: rotate(180deg);
      }
    }
  }
`;

const AppealNavigationLink = styled(NavigationLink)`
  background-color: var(--color-dark-100);
  position: sticky;
  bottom: 0;

  & div:not(.leading) > span {
    font-weight: bold;
  }

  & div:not(.leading) > span:not(.badge-light),
  & div:not(.leading):after {
    color: var(--great-race);
  }

  & .badge-light {
    font-weight: normal;
  }
`;
