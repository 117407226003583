import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
  background-color: var(--color-dark-350);
`;
export const Header = styled.div`
  background: var(--color-dark-100);
  display: flex;
  padding: 11px 20px;
`;
export const HeaderSpan = styled.span`
  display: flex;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-white-gray);

  &:first-child {
    flex: 2;
  }

  &:nth-child(2) {
    flex: 1;
  }

  &:nth-child(3) {
    flex: 0.5;
    width: 100%;
    justify-self: center;
  }
`;
export const SpanCount = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
  text-align: center;
  display: flex;
  flex: 0.5;
  color: var(--color-gray-200);
  align-items: center;
`;
export const Price = styled(SpanCount)`
  color: var(--yellow);
  flex: 1;
`;
export const Name = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: var(--color-white);
  display: flex;
  flex: 2;
`;
export const Button = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.29px;
  text-align: center;
  color: var(--color-gray-200);
  margin-top: 56px;
  margin-bottom: 18px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  gap: 11px;
`;
