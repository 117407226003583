import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-350);
  padding: 0 16px 38px;
`;

export const HeaderContainer = styled.div`
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
  margin-bottom: 34px;
`;

export const LevelSpan = styled.div`
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const UserInfoContainer = styled.div`
  display: grid;
  grid-template-areas: 'b a a' 'b c c' 'b c c';
  gap: 2px;
`;

export const UserAvatar = styled.img`
  border-radius: 50px;
  width: 30px;
  height: 30px;
  grid-area: b;
  margin-right: 5px;
`;
export const NickName = styled.span`
  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  grid-area: a;
`;

export const StatusRank = styled.span`
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 140% */
  letter-spacing: 0.3px;
  grid-area: c;

  &[data-color='bronze'] {
    color: var(--color-account-type-bronze);
  }

  &[data-color='silver'] {
    color: var(--color-account-type-silver);
  }

  &[data-color='gold'] {
    color: var(--color-account-type-gold);
  }

  &[data-color='platinum'] {
    color: var(--color-account-type-platinum);
  }

  &[data-color='diamond'] {
    color: var(--color-account-type-diamond);
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  color: var(--color-white);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 17px;
`;
export const ImgContainer = styled.div`
  position: relative;
  margin-bottom: 36px;
`;
export const CaseImg = styled.img`
  width: 154px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 8px;
`;
export const Price = styled.span`
  color: var(--color-gray-200);
  background: var(--mBlack);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.3px;
  border-radius: 10px;
  padding: 2px 6px;
`;
export const PriceContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 7px;
  right: 0;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  background: var(--yellow);
  text-transform: uppercase;
  color: var(--color-dark-600);
`;
export const CloseButton = styled.div`
  color: var(--color-gray-200);
  margin-top: 28px;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.286px;
`;
