import Immutable from 'immutable';
import { IFastInventoryElement } from 'core/User';

export const ClearanceRecord = Immutable.Record<IClearanceRecord>({
  amount: 0,
  items: [],
});

export interface IClearanceRecord {
  amount: number;
  items: IFastInventoryElement[];
}
