import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxControl, SearchInput } from '@UIkit';
import { Button } from '@components/Common';
import { useUserMailboxForm } from 'core/User';
import { IDialogComponent } from 'core/interfaces';
import { MailboxValidators } from 'core/utils/Validators';
import { Container } from './mailbox-binding-dialog.styles';

interface IMailboxBindingDialog extends IDialogComponent<boolean> {}

export const MailboxBindingDialog: FC<IMailboxBindingDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation('mailbox-settings');
  const {
    form: { register, formState, errors, handleSubmit, getValues },
    onSubmit,
    onReset,
    onUpdate,
    onRefresh,
  } = useUserMailboxForm();
  const status = getValues('status');

  return (
    <Container className={`${errors?.email?.type || ''} ${status}`}>
      <div className="dialog-header">
        {['not confirmed', 'empty'].includes(status) ? (
          <span className="dialog-title">{t('Add an email')}</span>
        ) : (
          <span className="dialog-title">{t('Change email')}</span>
        )}
        <Button className="dialog-close link-light" onClick={handleCloseModal}>
          {t('Close')}
        </Button>
      </div>
      <div className="dialog-description">
        <div className="empty">
          <span className="description">{t('Enter contact email')}</span>
          <span className="sub-description">
            {t('Please enter an email address to withdraw funds')}
          </span>
        </div>
        <div className="not-confirmed">
          <span className="description">{t('Activation link has been sent to your email')}</span>
          <span className="sub-description">{t('May be delivered into the spam folder')}</span>
        </div>
        <div className="confirmed">
          <span className="description">{t('You have already confirmed email address')}</span>
          <span className="sub-description">
            {t('To change it, click $t(Change email)')}{' '}
            {t('An email will be sent to the specified address with a link to confirm the action')}
          </span>
        </div>
        <div className="unsubscribe">
          <span className="description">
            {t('We sent a mail with a confirmation link to the specified email address')}
          </span>
          <span className="sub-description">{t('May be delivered into the spam folder')}</span>
        </div>
      </div>
      <div className="mailbox-container">
        <SearchInput
          {...register('email')}
          autoComplete="off"
          name="email"
          type="email"
          placeholder="example@mail.com"
          disabled={['not confirmed', 'confirmed', 'unsubscribe'].includes(status)}
          ref={register({ pattern: MailboxValidators.mailboxPattern })}
        />
      </div>
      <div className="dialog-subcontroller">
        <div className="mailbox-status-container">
          {status === 'not confirmed' ? (
            <>
              <Button className="status link-light" onClick={onReset}>
                {t('Change email')}
              </Button>
              <Button type="submit" className="refresh-status link-light" onClick={onUpdate}>
                {t('Resend')}
              </Button>
            </>
          ) : (
            <Button className="status link-light" onClick={onRefresh}>
              {t('Change email')}
            </Button>
          )}
        </div>
        <div className="agree-container">
          <CheckboxControl>
            <input
              type="checkbox"
              name="isSubscribed"
              id="isSubscribed"
              {...register('isSubscribed')}
            />
            <label htmlFor="isSubscribed">
              <span>{t('Subscribe to email notifications')}</span>
            </label>
          </CheckboxControl>
        </div>
      </div>
      <div className="dialog-controller">
        {status === 'empty' && (
          <Button
            type="submit"
            className="primary full"
            onClick={handleSubmit(onSubmit)}
            disabled={!!errors?.email?.type || !formState.isDirty}
          >
            {t('Confirm!')}
          </Button>
        )}
        {['not confirmed', 'unsubscribe'].includes(status) && (
          <Button className="secondary full" onClick={onUpdate}>
            {t('Waiting for confirmation')}
          </Button>
        )}
        {'confirmed' === status && (
          <Button className="primary full" onClick={handleCloseModal}>
            {t('Close unchanged')}
          </Button>
        )}
      </div>
    </Container>
  );
};
