export default {
  GAME: '遊戲',
  Chat: '聊天',
  'Email Support': '电子邮件支持',
  'Live Support Chat': '实时支持聊天',
  History: '游戏历史',
  Top: '頂端',
  Rules: 'Rules',
  'At stake': '赌注赌注',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': '要留言，您需要登录',
  'Only players who have played the game are allowed to write': '只有游戏玩家才可以留言',
  'You are banned': '您已被禁止0',
  Reason: '原因',
  'Time left': '剩余时间',
  'Time to summarizing': '總結時間',
  spam: '垃圾邮件',
  'advertising (auto)': '营销（自动）',
  advertising: '营销',
  rudeness: '无礼',
  begging: '乞讨',
  Search: 'Search',
  Sound: 'Sound',
  Today: '今天',
  Yesterday: '昨天',
  'Last year': '去年',
  'Show my wins': '显示我的获胜记录',
  'History is empty': '無紀錄',
  Cancel: '取消',
  Delete: '刪除',
  Close: '关闭',
  Confirm: '確認',
  'Are you sure to delete all notifications?': '你確定要刪除所有通知嗎?',
  'Log in': '登入',
  'Log in to try your luck': '登录来试试运气',
  'try your luck!': '试试运气！',
  'Take your prize': '领取奖品',
  'Sell for': '賣...',
  BACK: 'BACK',
  'Warning!': '警告！',
  'Congratulations!': '恭喜！',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    '恭喜！奖品的交易报价已发送到您的帐户。',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': '交易将在一小时后取消',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    '你的訂單處理完成後，您可以從遞交頁的 Steam 交易中取得你贏得的部分',

  'Round number': '回合数字',
  'Round hash': '回合哈希',
  'Refill amount': '充值数额',
  Bonus: '奖金',
  'Bonus experience': '奖励经验',
  'Bonus wheel spins': '奖励免费旋转',
  'Bonus cases': '奖励盒子',
};
