import React from 'react';
import { Category, Container, Image, Info, Percent } from './category-item.styles';

interface CategoryItemBlockedProps {
  categoryName: string;
  handler: () => void;
  image: string;
  isFinal: boolean;
  percentProgress: number;
}

export const CategoryItemBlocked: React.FC<CategoryItemBlockedProps> = ({
  categoryName,
  handler,
  image,
  isFinal,
  percentProgress,
}) => {
  return (
    <Container onClick={handler}>
      <Image src={image} alt={categoryName} isBlock={isFinal} className="colored" />
      <Info isBlock={isFinal} className="colored">
        <Category>{categoryName}</Category>
        <Percent>
          {Number.isInteger(percentProgress > 100 ? 100 : percentProgress)
            ? percentProgress
            : percentProgress.toFixed(2)}
          %
        </Percent>
      </Info>
    </Container>
  );
};
