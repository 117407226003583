import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addLastGame, initLastGames, loadLocale } from '../duck';
import { getAppInitializedStatus, getAppReconnectingStatus } from '../selectors';
import { getUser } from '../../User';
import NotificationsPopup from '../../../modules/Notifications/components/NotificationsPopup';
import { startAnalytics } from '../components/Analytics';
import { AppStart } from '../components/AppStart';
import { Router } from './Router/Router';
import { Seo } from '../components/Seo';
import { Overlay } from '../components/Overlay';
import { GlobalStyle } from '../assets/styles/GlobalStyle';
import { IUserState } from 'core/User/interfaces';
import { getActiveDialogs } from 'dialogs/selectors';
import { userModals } from 'core/User/modal.config';
import { getDomains } from 'core/utils';

interface IShellProps extends RouteComponentProps {
  user: IUserState;
  isReconnecting: boolean;
  appIsInitialized: boolean;
  dialog: string[];

  loadLocale: () => void;
  initLastGames: () => void;
  addLastGame: (pathname: string) => void;
}

interface IShellState {
  orientation: boolean;
}

class _Shell extends React.Component<IShellProps, IShellState> {
  private unlistener: () => void = () => {};

  componentDidMount() {
    const isTablet =
      (window.screen.width > 700 &&
        window.screen.orientation.type === 'portrait-primary') ||
      (window.screen.width > 800 &&
        window.screen.height > 500 &&
        window.screen.orientation.type === 'landscape-primary');

    if (isTablet && process.env.NODE_ENV === 'production') {
      window.location.href = `//${getDomains()}`;
    }

    if (process.env.NODE_ENV === 'production') {
      startAnalytics();
    }

    this.props.initLastGames();
    this.unlistener = this.props.history.listen(({ pathname }) => {
      this.props.addLastGame(pathname);
    });

    window.addEventListener('click', function onNoSleep() {
      (navigator as any)?.wakeLock?.request('screen').then(console.log);
      window.removeEventListener('click', onNoSleep, false);
    });
  }

  componentDidUpdate(prevProps: IShellProps) {
    if (prevProps.appIsInitialized !== this.props.appIsInitialized) this.props.loadLocale();

    if (this.props.user.id !== prevProps.user.id && this.props.user.id) {
      window.dataLayer.push({ userID: this.props.user.id });
    }
  }

  componentWillUnmount() {
    this.unlistener();
  }

  render() {
    const { appIsInitialized, isReconnecting, dialog } = this.props;
    const dialogsName = [userModals.USER_PROFILE, userModals.PROFILE_FREEZE];
    const hasActiveDialog = dialogsName.some(name => dialog.includes(name));

    return (
      <>
        <GlobalStyle hasActiveDialog={hasActiveDialog} />
        <Seo />
        <Overlay />
        <>
          {!appIsInitialized ? (
            <AppStart isReconnecting={isReconnecting} appIsInitialized={appIsInitialized} />
          ) : (
            <Router />
          )}
          <NotificationsPopup />
        </>
      </>
    );
  }
}

export const Shell = withRouter(
  connect(
    (state: any) => ({
      user: getUser(state),
      appIsInitialized: getAppInitializedStatus(state),
      isReconnecting: getAppReconnectingStatus(state),
      dialog: getActiveDialogs(state),
    }),
    {
      loadLocale,
      initLastGames,
      addLastGame,
    }
  )(_Shell)
);
