import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, EmojiImg, Shadow, Title, Wrapper } from './JokerInfo.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { Button } from '@components/Common';
import { animated, useSpring } from 'react-spring';

export const JokerInfo = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [heightElement, setHeightElement] = useState(ref?.current?.offsetHeight);

  useEffect(() => {
    if (!heightElement) return;

    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setHeightElement(entry.contentRect.height);
      }
    });

    observer.observe(ref?.current);

    return () => {
      observer.disconnect();
    };
  }, [heightElement]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDropdown = () => {
    if (!open) {
      setOpen(true);
      localStorage.setItem('jokerInfo', 'true');
    }
  };

  const jokerProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    maxHeight: open ? 300 : heightElement ?? 52,
    overflow: 'hidden',
  });

  const shadowProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    height: open ? '100vh' : '0vh',
  });

  return (
    <>
      <Shadow style={shadowProps} onClick={handleClose} />
      <animated.div style={jokerProps}>
        <Container onClick={handleOpenDropdown} ref={ref}>
          <CaseBattleIcon.Joker className="joker" />
          <EmojiImg src={CaseBattleIcon.emojiHmmPath} alt="" />
          <Title>{t('titleJoker')}</Title>
          {open && (
            <Button className="link-light close" onClick={handleClose}>
              {t('Close')}
            </Button>
          )}
        </Container>
        <Wrapper open={open} status={open ? 'show' : 'hide'}>
          <div className={`description ${open && 'show'}`}>
            <span>
              <Trans i18nKey="descriptionJoker">
                Introducing a new Case Battle mode - <span className="jokerName">Joker</span>! This
                is the reverse of the usual Case Battle, in this mode the player with the lowest
                amount at the end of all rounds wins.
              </Trans>
            </span>
          </div>
        </Wrapper>
      </animated.div>
    </>
  );
};
