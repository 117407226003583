import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { HistoryItem } from './HistoryItem';
import { MiniPreloader } from '../../Preloader/index';

class _MinimalHistory extends React.PureComponent {
  render() {
    const { isLoading, history, t } = this.props;

    if (isLoading) {
      return (
        <Container>
          <MiniPreloader />
        </Container>
      );
    }

    if (!isLoading && !history.length) {
      return (
        <Container>
          <NotLent> {t('History is empty')} </NotLent>
        </Container>
      );
    }

    return (
      <div className="content light-layout">
        {history.map(story => (
          <HistoryItem key={story.gameId || story.itemInfoId} {...story} />
        ))}
      </div>
    );
  }
}

export const MinimalHistory = withTranslation()(_MinimalHistory);

MinimalHistory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.array.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
  z-index: 1;
  background: var(--color-dark-500);
  position: relative;
  min-height: 100%;
`;

const NotLent = styled.div`
  text-align: center;
  padding: 5px;
  font-size: 17px;
  color: var(--color-white);
`;
