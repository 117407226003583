import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from 'core/AppShell/duck';

export const useDrawer = () => {
  const dispatch = useDispatch();

  const handleToggleDrawer = useCallback(
    (value: boolean) => () => {
      dispatch(toggleDrawer(value));
    },
    [dispatch]
  );

  return {
    handleToggleDrawer,
  };
};
