export default {
  'Fair game': 'Fairplay',
  Bet: 'Einsatz',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = Gesperrt)',
  'Bet coins': 'Münzen einsetzen',
  Stop: 'Stopp',
  'Wait for next round...': 'Warte auf nächste Runde...',
  Example: 'Beispiel',
  '!! Crash !!': '!! Crash !!',
  'You won': 'Gewonnen',
};
