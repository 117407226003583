import { combineEpics } from 'redux-observable';
import { categoryEpic } from './category-duck';
import { caseListEpic } from './case-list-duck';
import { suitCaseEpic } from './case-items-duck';
import { revisionEpic } from './case-revision-duck';
import { caseInfo } from './case-item-info-duck';
import {
  rouletteLineEpic,
  openCaseEpic,
  sellWinningItemsEpic,
  rouletteReplayEpic,
} from './roulette-duck/roulette.duck';
import { MotivatorEpic } from './motivator-duck';
import { addNextCaseHistory, caseHistoryEpic, sellItemInHistory } from './case-history-duck';

export const casesEpic = combineEpics(
  categoryEpic,
  caseListEpic,
  suitCaseEpic,
  revisionEpic,
  caseInfo,
  rouletteLineEpic,
  openCaseEpic,
  sellWinningItemsEpic,
  MotivatorEpic,
  rouletteReplayEpic,
  caseHistoryEpic,
  addNextCaseHistory,
  sellItemInHistory
);
