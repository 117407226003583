import React from 'react';
import { Container, Wrapper } from './CaseListPage.styles';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { useGetCasesSoundType } from 'games/CaseGame/hooks';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/hooks';
import {
  CaseHistoryDialogConfig,
  SoundsSettingsDialogConfig,
} from 'games/CaseGame/dialog-config/dialog-config';
import { CaseFilters, CaseList, CategoryList, Motivator } from 'games/CaseGame/components';

export const CaseListPage: React.FC = () => {
  const { soundType } = useGetCasesSoundType();
  const { handleToggleDialog } = useDialog();
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: '',
      iconKey: soundType as 'standard',
      type: 'modal',
      isNested: false,
      isNeedAuth: false,
      handler: handleToggleDialog(SoundsSettingsDialogConfig.SOUNDS_DIALOG, true),
      text: t('sounds'),
    },
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: '',
      iconKey: 'history',
      type: 'modal',
      isNested: false,
      isNeedAuth: true,
      handler: handleToggleDialog(CaseHistoryDialogConfig.DIALOG_HISTORY, true),
      text: t('history'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];

  return (
    <Wrapper className="content dark-layout">
      <AppBar isGame>
        <span>Cases</span>
      </AppBar>
      <div>
        <Container>
          <ToolBar toolBarItemsConfig={toolBarConfig} />
        </Container>
      </div>
      <Motivator />
      <CategoryList />
      <CaseFilters />
      <CaseList />
    </Wrapper>
  );
};
