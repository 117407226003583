import React, { useEffect } from 'react';
import { Description, TextButton, Title, Container } from './header.styles';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/hooks';
import { DIALOG_EVENT_CONFIG } from 'modules/event-module/comics-event/dialog-config';

export const HeaderComponent = () => {
  const { t } = useTranslation();
  const { handleOpenDialog, handleCloseDialog } = useDialog(DIALOG_EVENT_CONFIG.GUIDE);

  useEffect(() => {
    return () => handleCloseDialog();
  }, [handleCloseDialog]);

  const clickHandler = () => {
    handleOpenDialog();
  };

  return (
    <>
      <Container>
        <Title>{t('searchGifts')}</Title>
        <Description>{t('descriptionLRDragons')}</Description>
        <TextButton onClick={clickHandler}>{t('moreDetails')}</TextButton>
      </Container>
    </>
  );
};
