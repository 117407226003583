import { IStore } from 'store/interface';

export const getGeneralQuestInfo = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.eventGeneralQuestInfoReducer;

export const getMotivatorData = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.motivatorEventReducer;

export const getCategoryEvent = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.eventCategoryReducer;

export const getLuckyWonData = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.rareCaseInfoReducer;

export const getLRBalance = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.leaderRaceBalanceReducer;

export const getLRQuests = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.leaderRaceQuestsReducer;

export const getCasesLR = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.casesLRReducer;

export const getLRInfo = ({ eventModule }: IStore) =>
  eventModule.comicsHeroesEventReducer.leaderRaceInfoReducer;
