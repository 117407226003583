import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStoreDetailed } from '../../selector';
import { IStore } from 'store/interface';
import { useCallback } from 'react';
import { changeMultiFilters } from '../../ducks/filters.duck';

export const useStoreMultiFilter = <T>(path: string) => {
  const values = useSelector<IStore, T>(getStoreDetailed(path), shallowEqual);
  const dispatch = useDispatch();

  const changeDetailed = useCallback(
    (value: string, selected: boolean) => () => {
      dispatch(changeMultiFilters(path, value, selected));
    },
    [path, dispatch]
  );

  return {
    changeDetailed,
    values,
  };
};
