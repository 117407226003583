import React, { useEffect } from 'react';
import { CategoriesItems, SuperPrize } from '../default-event';
import { TopPlayers } from './top-players';
import { ResultsTimer } from './results-timer';
import { LeaderBoards } from './leader-boards';
import { Container, BtnCases, BalanceContainer } from './event-results.styles';
import { Balance } from '../default-event/balance';
import { useDialog } from 'core/hooks';
import { DIALOG_EVENT_CONFIG } from '../../dialog-config';
import { useTranslation } from 'react-i18next';

export const EventResults = () => {
  const { t } = useTranslation();
  const { handleOpenDialog, handleCloseByName, activeDialogs } = useDialog(
    DIALOG_EVENT_CONFIG.CASE_SHOP
  );

  const handleOpenModalCases = () => {
    handleOpenDialog();
  };

  useEffect(() => {
    return () => {
      activeDialogs.forEach(item => handleCloseByName(item));
    };
  }, [activeDialogs, handleCloseByName]);

  return (
    <Container>
      <ResultsTimer />
      <BalanceContainer>
        <Balance />
        <BtnCases onClick={handleOpenModalCases}>{t('spendCoins')}</BtnCases>
      </BalanceContainer>
      <TopPlayers />
      <LeaderBoards />
      <CategoriesItems isFinal />
      <SuperPrize isFinal />
    </Container>
  );
};
