import { URLS } from 'services/constant-urls';

const ultimateChip = {
  icon: URLS.moneyIconUrl,
  ultimate: true,
};

export const dropItems = (items = [], maximum = 3000, minimumItems = 1, limitItems = 15) => {
  const thousandRest = maximum % 1000;
  const confusingRest = [];
  if (thousandRest > 50 && thousandRest !== maximum) {
    confusingRest.push({ value: Math.ceil(thousandRest), ...ultimateChip });
    maximum -= thousandRest;
  }
  const fillUntilValue = (array, item, maximum) => {
    if (item.value > maximum) {
      return [array, maximum];
    }

    if (maximum - item.value === 0) {
      if (minimumItems > 1 && array.length < minimumItems) {
        return [array, maximum];
      }
    }
    return fillUntilValue([item, ...array], item, maximum - item.value);
  };
  const result = [...items]
    .sort((i1, i2) => i2.value - i1.value)
    .filter(item => item.value <= maximum)
    .reduce((result, item) => {
      if (maximum < item.value) return result;
      const respond = fillUntilValue(result, item, maximum);
      maximum = respond[1];
      return respond[0];
    }, [])
    .reverse();
  return [...result, ...confusingRest].slice(0, limitItems);
};
