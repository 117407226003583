import React from 'react';
import * as CsGoMethodStyles from '../styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { URLS } from 'services/constant-urls';

export const CsGoMethod = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <CsGoMethodStyles.StyledNavigationLink to="/market" as={Link}>
        <div className="leading">
          <img src={`${URLS.refill.icon}/refill-csgo.svg`} alt="csgo" />
        </div>
        <div>
          <span>CS:GO</span>
          <span className="badge-light">{t('No commission')}</span>
        </div>
      </CsGoMethodStyles.StyledNavigationLink>
    </>
  );
});
