import { FC } from 'react';
import { ParticipateModule } from 'core/interfaces';

const controllersMemory = () => {
  const controllers = new Map<ParticipateModule, FC<any>>();

  return {
    add: (name: ParticipateModule, component: FC<any>) => controllers.set(name, component),
    has: (name: ParticipateModule): boolean => controllers.has(name),
    get: (name: ParticipateModule): FC<any> => controllers.get(name),
  };
};

export const CONTROLLERS_MEMORY = controllersMemory();
