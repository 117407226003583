import * as React from 'react';
import games from 'games';
import { useTranslation } from 'react-i18next';
import { useDrawerHistoryNavigation } from '../../hooks';
import { GamesIcons } from 'assets/games-icon';
import { ModuleName } from 'modules/Lobby/components';
import { Container, HistoryContainer, HistoryItem, HistoryTitle } from './HistoryNavigation.styles';
interface IHistoryNavigation {
  handlePushTo: (path: string) => () => void;
}

export const HistoryNavigation: React.FC<IHistoryNavigation> = React.memo(({ handlePushTo }) => {
  const { lastGames } = useDrawerHistoryNavigation();
  const { t } = useTranslation();

  const handleClick = (moduleName: string) => {
    return handlePushTo(`/${moduleName}`);
  };

  return lastGames.length ? (
    <Container>
      <HistoryTitle>{t('recent games')}</HistoryTitle>
      <HistoryContainer>
        {lastGames.map((moduleName: ModuleName, i: number) => (
          <HistoryItem
            key={moduleName + i}
            onClick={handleClick(moduleName)}
            className="ripple"
            as="a"
          >
            <div className="ripple-effect ripple-dark-effect">{GamesIcons[moduleName]?.()}</div>

            {t(
              //@ts-ignore
              games[moduleName].path
            )}
          </HistoryItem>
        ))}
      </HistoryContainer>
    </Container>
  ) : null;
});
