import * as React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';
import { Icon } from '@components/Common';

const { useState } = React;

interface IControllers {
  toggleOpen: () => void;
  sendMessage: (message: string) => void;
  isOpen: boolean;
}

export const Controllers: React.FC<IControllers> = props => {
  const [message, setMessage] = useState<string>('');
  const handleSendMessage = (message: string) => {
    if (!message.trim()) return;
    props.sendMessage(message);
    setMessage('');
  };
  return (
    <Container>
      <MessageInput
        value={message}
        maxRows={6}
        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setMessage(e.currentTarget.value)}
        data-automation-id="chat_textarea"
      />
      <ShowSticker
        onClick={props.toggleOpen}
        active={props.isOpen}
        data-automation-id="chat_toggle-sticker-bar"
      >
        <Icon name="stickers" />
      </ShowSticker>
      <SendButton
        onClick={() => handleSendMessage(message)}
        disabled={!message.length}
        data-automation-id="chat_send-message"
      >
        <Icon name="arrow-up" />
      </SendButton>
    </Container>
  );
};

interface IButtonStyle {
  active?: boolean;
  disabled?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 9px;
  background: var(--dark);
`;

const MessageInput = styled(TextareaAutosize)`
  width: 100%;
  min-height: 30px;
  padding: 3px 8px;
  border-radius: 15px;
  border: solid 2px var(--mGray-100);
  background-color: var(--light);
`;

const MessageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 50%;
  border: 0;
  & > i {
    font-size: 18px;
  }
`;

const ShowSticker = styled(MessageButton)<IButtonStyle>`
  background-color: transparent;
  & > i {
    font-size: 24px;
    color: ${p => (p.active ? 'var(--warning)' : 'var(--secondary)')};
  }
`;

const SendButton = styled(MessageButton)<IButtonStyle>`
  background-color: var(--secondary);

  & > i {
    color: var(--dark);
  }
  ${p =>
    p.disabled &&
    `
    opacity: 0.5;
  `};
`;
