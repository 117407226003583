import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRaffleCondition } from '../../hooks';
import { RaffleLot } from '../@shared';
import { IRafflingItem } from '../../interfaces/reducer.leaders.interfaces';

export const LandingGoldLots: FC = () => {
  const { t } = useTranslation();
  const { raffle } = useRaffleCondition('one-year');
  if (!raffle) return null;

  return (
    <Container>
      <span className="lot-title">{t('Items in raffle:')}</span>
      <div className="inner">
        {raffle.items?.slice(0, 3).map((item: IRafflingItem, i) => (
          <RaffleLot
            key={item.id}
            {...item}
            // order={(raffle.items.length + i - raffle.stage) % raffle.items.length}
            order={i}
            stage={raffle.stage}
            phase={raffle.phase}
            idx={i}
            type={'one-year'}
          />
        ))}
      </div>
      <Link className="show-more" to="/leaders-race-next/raffles/active/one-year">
        {t('Show all')}
        <i className="icon-arrow" />
      </Link>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 2.3rem 24.5rem 2.3rem;
  grid-gap: 0.5rem;
  padding: 3rem 1.125rem 1rem;

  & .lot-title,
  & .show-more {
    font-size: 1.25rem;
    line-height: 1.5625rem;
    letter-spacing: 0.54px;
    color: white;
  }

  & .inner {
    position: relative;
  }

  & .show-more {
    align-self: self-end;
    justify-self: center;
    text-transform: uppercase;
    & i {
      display: inline-block;
      transform: rotate(-90deg);
      color: var(--yellow);
    }
  }
`;
