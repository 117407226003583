export default {
  Cart: 'Coș',
  Price: 'Preț',
  Type: 'Type',
  Categories: 'Categorii',
  Rarity: 'Raritate',
  OK: 'OK',
  Clear: 'Golire',
  Autoselect: 'Selectare automată',
  Remove: 'Remove',
  Bet: 'Pariu',
  Min: 'Min.',
  Max: 'Max.',
  'it.': '',
  Total: 'Total',
  items: 'articole',
  Pistols: 'Pistol',
  Rifles: 'Puşcă',
  'Submachine guns': 'Pistol mitralieră',
  Heavy: 'Grele',
  Knives: 'Cuţit',
  Gloves: 'Mănuși',
  Keys: 'Cheie',
  Other: 'Altele',
  'Base Grade': 'Calitate de Bază',
  'Consumer Grade': 'Calitate obișnuită',
  'Industrial Grade': 'Calitate industrială',
  Restricted: 'Restricţionat',
  'Mil-Spec Grade': 'Calitate Militară',
  Classified: 'Classified',
  'High Grade': 'Calitate Bună',
  Covert: 'Ascuns',
  Remarkable: 'Remarcabil',
  Exotic: 'Exotică',
  Contraband: 'Contrabandă',
  Extraordinary: 'Extraordinar',
  Buy: 'Cumpără',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Magazinul este numai pentru retragerea câștigurilor sau a monedelor nefolosite. Schimbul de skin-uri cu scopul de a face profit datorită diferenței de preț față de alte piețe este strict interzis. Contravenienții vor fi blocați definitiv.',
  'Your purchase will be delivered within 15 minutes.':
    'Articolul achiziţionat va fi livrat în 15 minute.',
  'Congratulations! You have successfully purchased': 'Felicitări! Ați achiziționat cu succes',
  'Order confirmation': 'Confirmați comanda',
  Filters: 'Filters',
  'By price': 'După preț',
  Filter: 'Filtru',
  'Not found': 'Nu a fost găsit',
  'At present it is unable to find any items on your request. Try again later.':
    'În momentul de față nu găsește nici un obiect care să corespundă cererii tale. Încearcă din nou mai târziu.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
