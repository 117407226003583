export enum ACTION_TYPE {
  UPDATE_CASE_LIST = 'app/cases-game/UPDATE_CASE_LIST',
  UPDATE_HISTORY = 'app/cases-game/UPDATE_HISTORY',
  CLEAN_WIN_CASES = 'app/cases-game/CLEAN_OPEN_CASES',
  UPDATE_WIN_CASES = 'app/cases-game/UPDATE_OPEN_CASES',
  CHOOSE_CASE = 'app/cases-game/CHOOSE_CASE',
  GET_CASES = 'app/cases-game/GET_CASES',
  LOADING_PERSONAL_STORY = 'app/cases-game/LOADING_PERSONAL_STORY',
  UPDATE_PERSONAL_STORY = 'app/cases-game/UPDATE_PERSONAL_STORY',

  CATEGORY_ACTION_REQUEST = 'app/cases-game/ACTION_REQUEST_CATEGORY',
  CATEGORY_ACTION_RESPONSE = 'app/cases-game/ACTION_RESPONSE_CATEGORY',
  CATEGORY_ACTION_ERROR = 'app/cases-game/ACTION_ERROR_CATEGORY',
  SET_SELECTED_CATEGORY = 'app/cases-game/SET_SELECTED_CATEGORY',

  CASE_LIST_ACTION_REQUEST = 'app/cases-game/ACTION_REQUEST_CASE_LIST',
  CASE_LIST_ACTION_RESPONSE = 'app/cases-game/ACTION_RESPONSE_CASE_LIST',
  CASE_LIST_ACTION_ERROR = 'app/cases-game/ACTION_ERROR_CASE_LIST',
  CASE_LIST_ACTION_RESPONSE_STATIC_CATEGORY = 'app/cases-game/ACTION_RESPONSE_STATIC_CATEGORY',
  CASE_LIST_ACTION_REMOVE_CASES_DATA = 'app/cases-game/ACTION_REMOVE_CASES_DATA',

  SORT_SORT_TYPE = 'app/cases-game/SORT_TYPE',
  SORT_PRICE_RANGE = 'app/cases-game/PRICE_RANGE',
  SORT_CLEAN_SORT_PARAMS = 'app/cases-game/CLEAN_SORT_PARAMS',
  SORT_SEARCH = 'app/cases-game/SEARCH',

  SUIT_CASE_ACTION_REQUEST = 'app/cases-game/ACTION_REQUEST_SUIT_CASE',
  SUIT_CASE_ACTION_RESPONSE = 'app/cases-game/ACTION_RESPONSE_SUIT_CASE',

  CASE_REVISION_ACTION_REQUEST = 'app/cases-game/ACTION_REQUEST_CASE_REVISION',
  CASE_REVISION_ACTION_RESPONSE = 'app/cases-game/ACTION_RESPONSE_CASE_REVISION',
  CASE_REVISION_ACTION_ERROR = 'app/cases-game/ACTION_RESPONSE_ACTION_ERROR',

  CASE_ITEM_INFO_ACTION_REQUEST = 'app/cases-game/ACTION_REQUEST_CASE_ITEM_INFO',
  CASE_ITEM_INFO_ACTION_RESPONSE = 'app/cases-game/ACTION_RESPONSE_CASE_ITEM_INFO',
  CASE_ITEM_INFO_ACTION_ERROR = 'app/cases-game/ACTION_RESPONSE_CASE_ITEM_INFO',
  CASE_ITEM_INFO_ACTION_RESPONSE_DETAILED = 'app/cases-game/ACTION_RESPONSE_CASE_ITEM_INFO_DETAILED',

  ROULETTE_ACTION_REQUEST = 'app/cases-game/ROULETTE_ACTION_REQUEST',
  ROULETTE_ACTION_RESPONSE = 'app/cases-game/ROULETTE_ACTION_RESPONSE',
  ROULETTE_ACTION_CLEAR = 'app/cases-game/ROULETTE_ACTION_CLEAR',

  OPEN_CASE_ACTION_REQUEST = 'app/cases-game/OPEN_CASE_ACTION_REQUEST',
  OPEN_CASE_ACTION_RESPONSE = 'app/cases-game/OPEN_CASE_ACTION_RESPONSE',
  INIT_ROULETTE_LINE = 'app/cases-game/INIT_ROULETTE_LINE',
  BEFORE_ROLL = 'app/cases-game/BEFORE_ROLL',
  BEFORE_ROLL_ALL = 'app/cases-game/BEFORE_ROLL_ALL',

  AFTER_ROLL = 'app/cases-game/AFTER_ROLL',
  IN_ROLL = 'app/cases-game/IN_ROLL',
  IN_ROLL_ALL = 'app/cases-game/IN_ROLL_ALL',
  START_ROLL_ALL = 'app/cases-game/START_ROLL_ALL',

  SELL_ITEMS_BY_ID = 'app/cases-game/SELL_ITEMS_BY_ID',
  CHANGE_ITEMS_SELL_TYPE = 'app/cases-game/CHANGE_ITEMS_SELL_TYPE',
  CHANGE_ITEMS_SELL_TYPE_TO_INIT = 'app/cases-game/CHANGE_ITEMS_SELL_TYPE_TO_INIT',
  CHANGE_ITEM_SELL_TYPE_BY_INDEX = 'app/cases-game/CHANGE_ITEM_SELL_TYPE_BY_INDEX',
  OPEN_CASE_TYPE_ACTION = 'app/cases-game/OPEN_CASE_TYPE_ACTION',

  SET_SOUND_TYPE = 'app/cases-game/SET_SOUND_TYPE',

  ACTION_CHANGE_SPEED_TYPE = 'app/cases-game/ACTION_CHANGE_SPEED_TYPE',

  GET_MOTIVATOR_DATA = 'app/cases-game/GET_MOTIVATOR_DATA',
  ACTION_REQUEST_MOTIVATOR = 'app/cases-game/ACTION_REQUEST_MOTIVATOR',
  ACTION_RESPONSE_MOTIVATOR = 'app/cases-game/ACTION_RESPONSE_MOTIVATOR',

  ACTION_REQUEST_REPLAY = 'app/cases-game/ACTION_REQUEST_REPLAY',
  ACTION_RESPONSE_REPLAY = 'app/cases-game/ACTION_RESPONSE_REPLAY',

  ACTION_DETECT_GAME_TYPE = 'app/cases-game/ACTION_DETECT_GAME_TYPE',

  ACTION_REQUEST_CASE_HISTORY = 'app/cases-game/ACTION_REQUEST_CASE_HISTORY',
  ACTION_REQUEST_CASE_HISTORY_ADD_NEXT = 'app/cases-game/ACTION_REQUEST_CASE_HISTORY_ADD_NEXT',
  ACTION_RESPONSE_CASE_HISTORY_ADD_NEXT = 'app/cases-game/ACTION_RESPONSE_CASE_HISTORY_ADD_NEXT',
  ACTION_RESPONSE_CASE_HISTORY = 'app/cases-game/ACTION_RESPONSE_CASE_HISTORY',
  ACTION_REQUEST_SELL_ITEM_IN_HISTORY = 'app/cases-game/ACTION_REQUEST_SELL_ITEM_IN_HISTORY',
}
