import useSound from 'use-sound';
import { sounds } from '../assets';
import { useCallback } from 'react';
import { SpeedType } from '../ducks/roulette-duck';

export const useGetSounds = (soundType: string, speedType: SpeedType) => {
  const startSound = getSound(soundType, 'start', speedType);
  const raffleSound = getSound(soundType, 'raffle', speedType);
  const endSound = getSound(soundType, 'end', speedType);

  const [pStart] = useSound(startSound);
  const [playRaffle, { stop }] = useSound(raffleSound);
  const [pEnd] = useSound(endSound);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const playStart = useCallback(() => pStart(), [pStart]);
  const play = useCallback(() => playRaffle(), [playRaffle]);
  const playEnd = useCallback(() => pEnd(), [pEnd]);
  return { play, playStart, playEnd, stop };
};

const getSound = (soundType: string, stateSound: 'start' | 'end' | 'raffle', speed: SpeedType) => {
  if (soundType === 'soundOff') {
    return '';
  }

  if (soundType && stateSound && speed) {
    return sounds[soundType][stateSound][speed];
  }
  return '';
};
