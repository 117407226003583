import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  border-radius: var(--size-radius-l);
  border: 1px solid var(--color-dark-200);
  display: flex;
  gap: 8px;
  justify-content: center;
  min-width: 99px;
  padding: 0 12px;
`;

export const Arrows = styled.div<{ color: string; isWinner: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 10px;

  & > .arrow_top {
    animation: opacity 900ms ease-in-out infinite;
    position: absolute;
    top: ${({ isWinner }) => (isWinner ? '6px' : '2px')};
    transform: ${({ isWinner }) => (isWinner ? 'rotate(0deg)' : 'rotate(180deg)')};

    & > path {
      fill: ${({ color }) => color};
    }
  }

  & > .arrow_bottom {
    animation: opacity 900ms 300ms ease-in-out infinite;
    position: absolute;
    top: ${({ isWinner }) => (isWinner ? '11px' : '7px')};
    transform: ${({ isWinner }) => (isWinner ? 'rotate(0deg)' : 'rotate(180deg)')};

    & > path {
      fill: ${({ color }) => color};
    }
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const ItemPrice = styled.p<{ color?: string }>`
  color: ${({ color }) => color ?? 'var(--color-gray-200)'};
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.24px;
  line-height: 24px;
`;
