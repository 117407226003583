import React from 'react';
import {
  ButtonContainer,
  CaseSelectionContainer,
  Close,
  Container,
  ListContainer,
  RemoveAll,
  Title,
  TitleContainer,
} from './add-cases.styles';
import { IDialogComponent } from 'core/interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeAllCases } from 'games/CaseBattle';
import { Case } from './components';
import { useGetIdsForCreateBattle } from 'games/CaseBattle/hooks';
import { CaseList } from './components/case-list';
import { Filters } from './components/filters';
import { CreateGameButton } from 'games/CaseBattle/components/CreateGame/components/CreateGameButton';

const casesEmpty = new Array(10).fill(0).map((_, index) => index);

interface AddCasesDialogProps extends IDialogComponent<boolean> {}

export const AddCasesDialog: React.FC<AddCasesDialogProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { caseAmount, casesForBattle } = useGetIdsForCreateBattle();

  const removeHandler = () => {
    dispatch(removeAllCases());
  };

  return (
    <Container>
      <Close onClick={handleCloseModal}>{t('Close')}</Close>
      <CaseSelectionContainer>
        <TitleContainer>
          <Title>
            {t('caseSelection')} {caseAmount}/10
          </Title>
          <RemoveAll onClick={removeHandler}>{t('remove')}</RemoveAll>
        </TitleContainer>
        <ListContainer className="turn-off-default-scroll">
          {casesEmpty.map(value => {
            const caseId = casesForBattle[value];
            return <Case key={value} id={caseId?.id} uniqId={caseId?.uniqId} />;
          })}
        </ListContainer>
      </CaseSelectionContainer>
      <Filters />
      <CaseList />
      <ButtonContainer className="tablet">
        <CreateGameButton />
      </ButtonContainer>
    </Container>
  );
};
