import { combineReducers } from 'redux';
import { ICrossData } from './interfaces/crossServices.reducer.inteface';
import { IDispatch } from '../rootInterfaces/root.interface';

import { ISocketService } from '../../services/interfaces/services.interface';

import {
  crossServiceActions,
  CrossServiceActionsTypes,
} from './interfaces/crossServices.actions.interface';

const defaultCrossData: ICrossData = {
  isLoaded: false,
  gameId: process.env.NODE_ENV === 'production' ? null : 2, // Production server gameId in URL.
  mirrorConfig: {},
  gamesList: [],
};

const crossData = (state = defaultCrossData, action: crossServiceActions): ICrossData => {
  const { type, payload } = action;

  switch (type) {
    case CrossServiceActionsTypes.INIT_CROSS:
      return {
        ...state,
        ...payload,
        isLoaded: true,
      };

    case CrossServiceActionsTypes.INIT_GAME_ID:
      return {
        ...state,
        gameId: payload.gameId,
      };

    case CrossServiceActionsTypes.UPDATE_GAME_ID:
      return {
        ...state,
        gameId: payload,
      };

    default:
      return state;
  }
};

export const reducer = combineReducers({
  crossData,
});

export const initCrossData = (data: ICrossData): crossServiceActions => ({
  type: CrossServiceActionsTypes.INIT_CROSS,
  payload: data,
});

export const initGameIdWithAuth = (gameId: any, gamesList: any, mirrorConfig: any) => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  dispatch({
    type: CrossServiceActionsTypes.INIT_GAME_ID,
    payload: { gameId: 2 },
  });
  // if (window.location.hostname === 'd1zi9q96rsh4iw.cloudfront.net') {
  //   dispatch({
  //     type: CrossServiceActionsTypes.INIT_GAME_ID,
  //     payload: { gameId: 2 },
  //   })
  // } else if (process.env.NODE_ENV === 'production') {
  //   const url = window.location.hostname.split('.')
  //   const subDomain = url[1]
  //   const domain = url[url.length - 2]
  //   const type = url[url.length - 1]
  //
  //   const service = mirrorConfig[`${domain}.${type}`]
  //   if (subDomain === service) {
  //     return (window.location.href = `//m.${domain}.${type}`)
  //   } else {
  //     const searchingService = subDomain === domain ? service : subDomain
  //
  //     const originService = gamesList.find((game: any) => game.short_name === searchingService)
  //
  //     if (!!gameId && originService?.id !== gameId) {
  //       socket.emit('item-service.set-game.request', {
  //         gameId: originService?.id || 2,
  //       })
  //     }
  //
  //     dispatch({
  //       type: CrossServiceActionsTypes.INIT_GAME_ID,
  //       payload: { gameId: originService?.id || 2 },
  //     })
  //   }
  // }
};

export const changeGameId = (gameId: number): crossServiceActions => ({
  type: CrossServiceActionsTypes.UPDATE_GAME_ID,
  payload: gameId,
});

export const fetchGameIdService = (gameId: number) => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
): void => {
  dispatch({ type: CrossServiceActionsTypes.UPDATE_GAME_ID, payload: gameId });
  socket.emit('item-service.set-game.request', { gameId });
};
