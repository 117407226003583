import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PayoutSystemsList } from '../payout-system-list';
import { AppBar } from '@components/bars';

export const PayoutSystemsPage: FC<RouteComponentProps> = () => (
  <>
    <AppBar deep />
    <PayoutSystemsList />
  </>
);
