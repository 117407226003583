import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

export class UserReferral {
  static referralClick(code: string) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-codes/${code}/click`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
    });
  }
  static referralActivate(code: string) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-codes/${code}/activate`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
    });
  }
}
