import * as React from 'react';
import styled from 'styled-components';
import { useSwipeSlide } from '@components/hooks';
import { animated } from 'react-spring';
import {
  ILeaderBoardItem,
  IRaceRecord,
  IRacesList,
} from '../../interfaces/reducer.leaders.interfaces';

const { innerWidth } = window;

export type ISlideRenderProp = {
  leaderboard: ILeaderBoardItem[];
  raceType: string;
};

interface ITournamentSlider {
  races: IRacesList;
  children: (renderProp: ISlideRenderProp) => JSX.Element;
}

export const TournamentSlider: React.FC<ITournamentSlider> = ({ races, children }) => {
  const { animatedProps, bind } = useSwipeSlide(races.size, innerWidth);

  return (
    <>
      <Container>
        {animatedProps.map(({ x, display }, i) => (
          <animated.div
            {...bind()}
            key={i}
            style={{
              display,
              transform: x.interpolate(x => `translate3d(${x}px,0,0)`),
              zIndex: i,
            }}
          >
            {children({
              leaderboard: (races.get(i) as IRaceRecord).leaderboard,
              raceType: (races.get(i) as IRaceRecord).raceType,
            })}
          </animated.div>
        ))}
      </Container>
      <CircleContainer>
        {animatedProps.map(({ opacity }: any, i) => (
          <Circle key={i} raceType={(races.get(i) as IRaceRecord).raceType}>
            <animated.div style={{ opacity }} />
          </Circle>
        ))}
      </CircleContainer>
    </>
  );
};

type ICircle = {
  raceType: string;
};

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-height: 230px;

  & > div {
    position: absolute;
    width: ${innerWidth}px;
    will-change: transform;
  }
`;

const CircleContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--gray-400);
`;

const Circle = styled.div<ICircle>`
  margin: 0 0.3rem;
  border: 2px solid ${p => `var(--${p.raceType}-race)`};
  border-radius: 50%;
  & > div {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${p => `var(--${p.raceType}-race)`};
    will-change: opacity;
  }
`;
