import { useSelector } from 'react-redux';
import {
  getUserInventoryFailure,
  getUserInventoryIdentifiers,
  getUserInventoryInfo,
  getUserInventoryLoaded,
  getUserInventoryLoading,
} from '../../selectors';

export const useFastInventoryInfo = () => ({
  info: useSelector(getUserInventoryInfo),
  loading: useSelector(getUserInventoryLoading),
  loaded: useSelector(getUserInventoryLoaded),
  failure: useSelector(getUserInventoryFailure),
  identifiers: useSelector(getUserInventoryIdentifiers),
});
