import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTradeAction } from '../../ducks/sales.duck';
import { TradeActions } from '../../interfaces';

export const useSellingControllers = () => {
  const dispatch = useDispatch();

  const handleTradeAction = useCallback(
    (id: number, tradeAction: TradeActions) => (ev: any) => {
      ev.stopPropagation();
      dispatch(fetchTradeAction(id, tradeAction));
    },
    [dispatch]
  );

  return {
    handleTradeAction,
  };
};
