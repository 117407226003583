import React from 'react';
import { AppBar } from '@components/bars';
import { ActivitiesGame, ChartSemi, StatisticGames } from '../../components/Statistics';
import { UserStatisticChartDoughnut } from '@components/UserProfile';
import { useProfileStatistics } from '../../hooks';
import { useUserLastWins } from 'core/User/hooks/useUserLastWins';
import { IStatisticProfit } from '@components/UserProfile/Charts/UserStatisticChartDoughnut';
import { Container } from './LevelPage.styles';

export const StatisticsPage = () => {
  const { statistics, charts } = useProfileStatistics();
  const { lastWins } = useUserLastWins();

  return (
    <>
      <AppBar deep />
      <Container className="content">
        <ActivitiesGame lastWins={lastWins} />
        <UserStatisticChartDoughnut
          totalGameSum={charts.totalGameSum}
          totalGamePlayed={charts.totalGamePlayed as IStatisticProfit[]}
        />
        <ChartSemi
          totalWon={charts.totalWon as IStatisticProfit[]}
          totalWonSum={charts.totalWonSum}
        />
        <StatisticGames games={statistics} />
      </Container>
    </>
  );
};
