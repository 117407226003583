/* eslint-disable */
export default {
  'Your tickets': 'Ваши билеты',
  'Your places': 'Ваши места:',
  More: 'Подробнее',
  Completed: 'Выполнено',
  'for raffle': 'до розыгрыша',

  'Raffle appeal': 'Участвуй в розыгрыше крутых скинов!',
  'Raffle faq': 'Для участия, тебе понадобятся зеленые билеты.',
  'Raffle info': 'Билеты можно получить за участие в турнирах, а так же, за выполнение квестов.',

  'Top {{length}}:': 'Топ {{length}}:',
  place: 'Ваше место',
  points: 'Набрано очков:',
  pointsToNextPlace: 'Очков до след. места:',
  pointsToTop100: 'Очков до 100 места:',

  tickets: 'Набрано билетов:',
  ticketsToNextPlace: 'Билетов до след. места:',
  ticketsToTop100: 'Билетов до 100 места:',

  position: '$t(place)',

  Quests: 'Квесты',
  'Quests interval_daily': 'Ежедневные квесты',
  'Quests interval_weekly': 'Еженедельные квесты',

  'Raffle interval_two-week': 'Тайный розыгрыш',
  'Raffle interval_two-month': 'Контрабандный розыгрыш',
  'Raffle interval_one-year': 'Золотой Розыгрыш',

  'Raffle appeal_two-week': 'Участвуй в розыгрыше крутых скинов!',
  'Raffle faq_two-week': 'Для участия, тебе понадобятся зеленые билеты.',
  'Raffle info_two-week':
    'Билеты можно получить за участие в турнирах, а так же, за выполнение квестов.',

  'Raffle appeal_two-month': 'Участвуй в розыгрыше крутых скинов!',
  'Raffle faq_two-month': 'Для участия, тебе понадобятся синие билеты.',
  'Raffle info_two-month':
    'Билеты можно получить за участие в турнирах, а так же, за выполнение квестов.',

  'Raffle appeal_one-year': 'Участвуй в розыгрыше крутых скинов!',
  'Raffle faq_one-year': 'Для участия, тебе понадобятся желтые билеты.',
  'Raffle info_one-year': 'Получай больше билетов за пополнение баланса!',

  'Closest raffle': 'Closest raffle',
  Daily: 'Daily',
  Tournaments: 'Турниры',
  'Prize pool': 'Призовой фонд',
  'Your place': 'Your place',
  'Tickets scored': 'Tickets scored',
  'Show all': 'Show all',
  'Points scored': 'Points scored',
  'Closes raffles': 'Closes raffles',
  Members: 'Members',
  Participants: 'Participants',
  Participating: 'Участвуете',
  'To raffle': 'В розыгрыш >',
  'Items in raffle': 'Предметов в розыгрыше - {{items}}',
  'Items in raffle_one-year': '{{items}} мини розыгрышей. 30 минут между каждым',
  items_interval: '(1){предмет};(2-4){предмета};(5-20){предмеов};',
  Raffling: 'Розыгрыш',
  Raffles: 'Розыгрыши',
  Lot: 'Лот',
  purchased: 'куплено',
  won: 'выиграл',
  bets: 'Ставок',
  Participate: 'Участвовать',
  'Participate for': 'Участвовать за',

  //invite modal
  'Done!': 'Готово!',
  'go to quests': 'перейти к квестам',
  "You don't have enough tickets": 'У вас недостаточно билетов.',
  "You don't have enough tickets, refill your balance and get tickets":
    'У вас недостаточно билетов, пополните баланс и получите билеты.',
  'You got a place in the raffle': 'Вы получили место в розыгрыше.',

  'Win ${count} games in a row with a bet not more than ${currency(amount)}':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} подряд со ставкой не более ${currency(amount)} в ',
  'Play ${count} games with a bet ${currency(amount)} or more':
    'Сыграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше в ',
  'Win ${count} games with a bet ${currency(amount)} or more on ZERO':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше на ZERO в ',
  'Win ${count} games in a row with a bet ${currency(amount)} or more':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} подряд со ставкой ${currency(amount)} и больше в ',

  'Win ${count} games with a bet more than ${currency(amount)}':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой больше ${currency(amount)} в ',
  'Win ${count} games with coef more than ${coef} with a bet ${currency(amount)} or more':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} с коэффициентом больше чем {{coef}} со ставкой ${currency(amount)} и больше в ',

  'Win ${count} games with a bet ${currency(amount)} or more':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше в ',

  'Win ${count} games with a bet ${currency(amount)} or more on x5':
    'Выиграй ${count} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше на x5 в ',

  'Play ${count} games with a bet ${currency(amount)} or more on ${betName}':
    'Сыграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше на {{betName}}  в ',
  'Win ${count} games with a bet ${currency(amount)} or more on ${betName}':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше на {{betName}}  в ',
  'Win ${count} games in a row with a bet ${currency(amount)} or more on ${betName}':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} подряд со ставкой ${currency(amount)} и больше на {{betName}}  в ',
  'Craft ${count} items worth ${currency(amount)} or more':
    'Создай {{count}} ${pluralize(count, ["предмет", "предмета", "предметов"])} стоимостью ${currency(amount)} и больше в ',
  'Craft ${count} items in a row worth ${currency(amount)} or more':
    'Создай {{count}} ${pluralize(count, ["предмет", "предмета", "предметов"])} подряд стоимостью ${currency(amount)} и больше в ',
  'Play ${count} games with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'Сыграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} с коэффициентом {{coef}}х и больше со ставкой ${currency(amount)} и больше в ',
  'Win ${count} games with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} с коэффициентом {{coef}}х и больше со ставкой ${currency(amount)} и больше в ',
  'Win ${count} games in a row with coef ${coef}x or more with a bet ${currency(amount)} or more':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} подряд с коэффициентом {{coef}}х и больше со ставкой ${currency(amount)} и больше в ',

  'Special quest. Unlocked after completing all weekly quests':
    'Особый квест. Будет доступен после выполнения всех недельных квестов.',
  // tower quests
  'Climb to the ${floorNumber} floor on easy mode with a bet ${currency(amount)} or more':
    'Заберись на {{floorNumber}} этаж в лёгком режиме со ставкой ${currency(amount)} и больше в ',

  'Climb to the ${floorNumber} floor on medium mode with a bet ${currency(amount)} or more':
    'Заберись на {{floorNumber}} этаж в среднем режиме со ставкой ${currency(amount)} и больше в ',

  'Climb to the ${floorNumber} floor on hard mode with a bet ${currency(amount)} or more':
    'Заберись на {{floorNumber}} этаж в тяжелом режиме со ставкой ${currency(amount)} и больше в ',
  //bonus quests
  'Refill your balance for ${currency(amount)}': 'Пополни баланс на ${currency(amount)}',
  //craft quests
  'Create ${count} items with price ${currency(amount)} or more':
    'Создай {{count}} предметов стоимостью ${currency(amount)} и больше в ',
  'Create ${count} items in a row with price ${currency(amount)} or more':
    'Создай {{count}} предмета подряд стоимостью ${currency(amount)} и больше в ',
  //crash quests
  'Play ${count} games with x${x} or more with a bet ${currency(amount)} or more':
    'Сыграй {{count}} игр с умножением ${x}х и больше со ставкой ${currency(amount)} и больше',

  //x50
  'Win ${count} games with a bet ${currency(amount)} or more on x50':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше на x50 в ',
  'Win ${count} games in a row with a bet ${currency(amount)} or more on x50':
    'Выиграй {{count}} ${pluralize(count, ["игру", "игры", "игр"])} со ставкой ${currency(amount)} и больше на x50 в ',

  Take: 'Забрать',
  'Get reward': 'Get reward',

  day: 'День',
  day_once: 'Дня',
  days: 'Дней',

  active: 'Aктивные',

  'Raffle will be available again soon. Come back later!':
    'Розыгрыш скоро снова будет доступен. Возвращайся позже!',

  //rules
  'To leaders race': 'Перейти в гонку',
  'Leaders Race 2021 has opened!': 'Гонка Лидеров 2021 открыта!',
  'Take part in tournaments with rewards of legendary prizes.':
    'Участвуй в турнирах, награда в которых - легендарные призы.',
  'CSGOFAST created three types of tournaments for users:':
    'CSGOFAST создал для пользователей три вида турниров:',
  'Points are awarded for participating in any games on CSGOFAST.  The more points you earn, the higher your position in the list of participants in the daily tournament.':
    'Очки начисляют за участие в любых играх на CSGOFAST . Чем больше очков ты заработал, тем выше твоя позиция в списке участников ежедневного турнира.',
  'Green tickets are also awarded for completing': 'Также, зеленые билеты начисляют за выполнение ',
  'daily quests.': 'ежедневных квестов.',
  'The more green tickets you earned, the higher your position in the list of participants in the Fast tournament.':
    'Чем больше зеленых билетов ты заработал, тем выше твоя позиция в списке участников двухнедельного турнира.',
  'Blue tickets are also awarded for completing': 'Также, синие билеты начисляют за выполнение ',
  'weekly quests.': 'еженедельных квестов.',
  'The more blue tickets you earned, the higher your position in the list of participants in the Great tournament.':
    'Чем больше синих билетов ты заработал, тем выше твоя позиция в списке участников двухмесячного турнира.',
  'Yellow tickets are also awarded for completing': 'Также, желтые билеты начисляют за выполнение',
  'a secret quest.': 'секретного квеста.',
  'At the end of the year, we will organize a special, time-limited event that uses yellow tickets.':
    'В конце года мы организуем специальное, ограниченное по времени событие, в котором используются желтые билеты.',
  'What are these "tickets"?': 'Что за "билеты"?',
  'Tickets are the main currency of the Leader Race.': 'Билеты - главная валюта Гонки Лидеров.',
  'Green and Blue tickets are used in the item raffles and in the bonus store. Yellow tickets are used in the special event at the end of the year.':
    'Зеленые и Синие билеты используются в розыгрыше предметов и бонусном магазине. Жёлтые билеты используются в специальном событие в конце года.',
  'Don’t worry! if you use tickets in the store or in the raffle, your place in the tournament will not change.':
    'Не стоит переживать! Если ты используешь билеты в магазине или розыгрыше, твое место в турнирной таблице не меняется.',
  'Quests? Yeah, quests!': 'Квесты? Да-да, квесты!',
  'Quests are special tasks available to all type of accounts except demo. Prize for completing the quests - Green, Blue or Yellow tickets.':
    'Квесты - это специальные задания доступные всем типам аккаунтов, кроме демо. Награда за выполнение - Зеленые, Синие или Желтые билеты.',
  'There are three types of quests:': 'Квесты бывают трёх типов:',
  'Six simple quests that are updated once every 24 hours.':
    'Шесть простых квестов, которые обновляются раз в 24 часа. ',
  'For completing one quest, you will receive 1 Green ticket.':
    'За выполнение одного квеста, ты получишь 1 Зеленый билет. ',
  'Daily quests are performed in any order.':
    'Ежедневные квесты выполняются в любой последовательности.',
  'Six more difficult quests that are updated once a week.':
    'Шесть более сложных квестов, которые обновляются раз в неделю. ',
  'For completing one quest, you will receive 1 Blue ticket.':
    'За выполнение одного квеста, ты получишь 1 Синий билет. ',
  'Weekly quests are carried out in strict order. For example, to open the second quest, firstly complete the first':
    'Еженедельные квесты выполняются в строгой последовательности. Например, чтобы открыть второй квест, сначала выполни первый',
  'Secret quest': 'Секретный квест',
  'Yellow tickets are also awarded for completing a secret quest.':
    'Доступен после выполнения всех еженедельных квестов. ',
  'Available after completing all weekly quests.':
    'Доступен после выполнения всех еженедельных квестов.',
  'For completing the secret quest, you will receive 1 Yellow ticket.':
    'За выполнение секретного квеста, ты получишь 1 Желтый билет.',
  'You won’t know what the secret quest is hiding until you complete all the weekly quests!':
    'Ты не узнаешь, что скрывает за собой секретный квест, пока не выполнишь все еженедельные квесты!',
  'Remember! If you do not complete the secret quest before the end of the week, it will disappear.':
    'Помни! Если не выполнить секретный квест до конца недели - он пропадёт.',
  '* We remind you that Yellow tickets can be used at the end of the year in a special, secret activity! Remember to save them':
    '* Напоминаем, что желтые билеты могут быть использованы в конце года в специальной, секретной активности! Не забудьте сохранить их',
  'Raffles and Bonus shop': 'Розыгрыши и бонусный магазин',
  'To participate in the draws you will need tickets that you can get for participating in tournaments, as well as for completing quests.':
    'Для участия в розыгрышах тебе понадобятся билеты, которые можно получить за участие в турнирах, а так же, за выполнение квестов.',
  'To participate in the Green Raffle, you will need Green tickets, and to participate in the Blue Raffle - Blue ones.':
    'Чтобы участвовать в Зелёной лотерее, тебе понадобятся зелёные билеты, а для участия в Синей лотерее - синие.',

  // store
  'You have Steam ban': 'У вас Steam бан',
  'You have invalid tradelink': 'У вас недействительная trade ссылка',
  'Steam is not available at the moment. Please try again later':
    'Steam недоступен в данный момент. Пожалуйста, попробуйте позже',
  'Your Steam inventory is private': 'Ваш Steam инвентарь приватный',
  'You have a problem with Steam Guard settings': 'У вас проблема с настройками Steam Guard',

  // notice
  'Item ${itemName} has not been purchased. ${ticketsAmount} ${translate(ticketColor)} tickets were returned to your account':
    'Предмет ${itemName} не был куплен. ${ticketsAmount} ${translate(ticketColor)} билетов были возвращены на ваш аккаунт',
  'Item ${itemName} purchased for ${ticketsAmount} ${translate(ticketColor)} tickets.': {
    'Please wait for the trade offer':
      'Item ${itemName} purchased for ${ticketsAmount} ${translate(ticketColor)} tickets. Please wait for the trade offer: Куплен предмет ${itemName} за ${ticketsAmount} ${translate(ticketColor)} билетов. Пожалуйста, ожидайте трейд-оффер',
  },
  green: 'зеленых',
  blue: 'синих',
  orange: 'желтых',
  'Purchase error': 'Ошибка покупки',
  'Item purchase': 'Покупка предмета',
  'The item not found': 'Предмет больше недоступен в магазине',

  // landing
  'Welcome to Golden Raffle 2020!': 'Добро пожаловать в Золотой Розыгрыш 2020!',
  'Thank you for joining Leaders Race 2020!': 'Благодарим вас за участие в Гонке Лидеров 2020!',
  'Raffling prize:': 'Разыгрываем:',
  'Best players in 2020': 'Топ Лидеров Гонки',
  'All participants': 'Все участники',
  'It is time to use your yellow tickets!': 'Пришло время тратить золотые билеты!',
  'Join to raffling or exchange your tickets to items in to our special shop!':
    'Участвуй в розыгрышах или покупай предметы в золотом магазине!',
  'YELLOW TICKET': 'ЖЕЛТЫЙ БИЛЕТ',
  'Refill your balance': 'Пополните свой баланс',
  'Get your yellow ticket': 'Получите желтый билет',
  'Win cool prizes': 'Выигрывайте крутые призы',
  'Golden Shop': 'Золотой Магазин',
  'for the most dedicated ones!': 'для самых преданных!',
  'Refill balance and get yellow ticket!': 'Пополнить баланс и получить желтый билет',

  'Welcome to golden raffle': 'Добро пожаловать в Золотой Розыгрыш',
  Join: 'Присоединиться',
  'Yellow ticket': 'Желтый билет',

  'LEADERS RACE 2021 FINAL': 'ФИНАЛ ГОНКИ 2021',
  'TOP 3 PLAYERS': 'ТОП-3 ИГРОКОВ',
  'Players statistics': 'Общая статистика всех игроков',

  'Total prize:': 'Призовой фонд:',
  'Total quests passed:': 'Квестов пройдено:',
  'Total items:': 'Разыграно предметов:',
  'on sum:': 'на сумму:',
  'Tickets raffled:': 'Билетов разыграно:',
  'Items obtained during the Golden Raffle and in the Golden Store will be credited to the user on their Steam account.':
    'Предметы, полученные во время Золотого Розыгрыша и в Золотом Магазине пользователь получит на свой Steam аккаунт.',

  'Get cool items for yellow tickets.': 'Получай крутые предметы, за желтые билеты.',
  'Get more tickets for refilling your balance!': 'Получай больше билетов за пополнение баланса!',
  'There will be only 5 VIP items in the shop, in one single copy:':
    'В магазине будет всего 5 VIP-предметов, в одном, единственном экземпляре:',
  '24.12.2020 the Golden Shop will open.': '24.12.2020 откроется Золотой Магазин.',
  'You have': 'У вас',
  'Items in raffle:': 'Предметы в розыгрыше:',
  'Tickets won:': 'Получено билетов:',
  'Skins won:': 'Получено скинов:',
  'Quests passed:': 'Пройдено квестов:',
  'Money won:': 'Выиграл денег:',
  'Available at': 'Доступно с ',
  'Leaders race 2020 is finished': ' Гонка лидеров 2020 завершена',
  'New Leaders Race 2021 is starting soon!': 'Новая Гонка Лидеров 2021 года скоро начнется!',
  'Leaders Race 2020 results': 'Результаты Гонки Лидеров 2020',
  'New race will start soon': 'Новая гонка скоро начнется',

  'Stock up your golden tickets and get ready for the final leaders race of 2021':
    'Запасайся золотыми билетами и готовься к итоговой гонке лидеров 2021',
  'Raffling items for the amount of': 'Разыгрываем предметы на сумму',
  'Go to the race': 'Перейти в гонку',
  'How and when the raffles will proceed?': 'Как и когда будут проходить розыгрыши?',
  'On the 13th of December, registration will be open for the raffles of golden tickets':
    '13 декабря будет открыта регистрация в розыгрышах призов за золотые билеты',
  'On the 31st of December, there will be 24 independent raffles with an interval of 30 minutes':
    '31 декабря пройдет 24 независимых розыгрыша с интервалом 30 минут',
  'Everyone has a chance to win! The cost of participation varies from 2 to 50 gold tickets depending on the value of the prize':
    'Шанс победить есть у каждого! Стоимость участия от 2 до 50 золотых билетов в зависимости от ценности приза',
  'How to get more golden tickets and participate in few raffles at once?':
    'Как получить больше золотых билетов и участвовать сразу в нескольких розыгрышах?',
  'Starting December 13, get golden tickets by refilling your balance':
    'Начиная с 13 декабря получайте золотые билеты за  <0>пополнение баланса</0> деньгами и prepaid картами',
  'Have time to complete the available weekly quests before December 31':
    'Успейте завершить доступные <1>недельные квесты</1> до 31 декабря',
  'The list of all raffles, time of event and prizes will be available on December 13':
    'Список всех розыгрышей, время проведения и награды будут доступны 13 декабря',
  'Time left': 'Осталось подождать:',
  Days: 'Дней',
  Hours: 'Часов',
  Minutes: 'Минут',
  Seconds: 'Секунд',
};
