import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  CardContainer,
  CaseListContainer,
  CasesContainer,
  GameInfo,
  ShadowLine,
  Wrapper,
} from './CaseBattleCard.styles';
import { CaseListItem } from '../CaseListItem';
import { UserComponents } from '../UserComponent';
import { ButtonVariants } from '../ButtonVariants';
import { GameInfoComponent } from '../GameInfoComponent';
import { isEqual } from 'lodash';
import { GameMember } from 'games/CaseBattle/interfaces';

interface CaseBattleCardProps {
  casesIds: number[];
  players: GameMember[];
  playersCount: number;
  index: number;
  playersInTeam: number;
  userId: number;
  battleId: number;
  round?: number | null;
  totalPrice?: number | null;
  totalWin?: number;
  winnersCount: number;
  winnersSplit: boolean;
  isPrivate: boolean;
  double: boolean;
  jokerMode: boolean;
  finishedAt?: Date;
}

export const CaseBattleCard: React.FC<CaseBattleCardProps> = React.memo(
  ({
    casesIds,
    players,
    playersCount,
    playersInTeam,
    userId,
    round,
    battleId,
    totalPrice,
    totalWin,
    index,
    winnersCount,
    winnersSplit,
    isPrivate,
    double,
    jokerMode,
    finishedAt,
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    const isMyGame = players.map(({ user_id }) => user_id).includes(userId);
    const [currIndex, setCurrIndex] = useState(0);
    const [offset, setOffset] = useState(0);

    useLayoutEffect(() => {
      const offset = index - currIndex;
      let timer = setTimeout(() => {
        setCurrIndex(index);
      }, 100);
      setOffset(offset);

      return () => {
        clearTimeout(timer);
      };
    }, [index, currIndex]);

    useEffect(() => {
      ref.current.scrollTo({
        left: (round - 1) * 48,
        behavior: 'smooth',
      });
    }, [round]);

    return (
      <CardContainer offset={offset} isMyGame={isMyGame} isJoker={jokerMode}>
        <Wrapper>
          <CaseListContainer>
            <CasesContainer ref={ref}>
              {casesIds.map((id, index) => (
                <CaseListItem
                  key={index}
                  id={id}
                  battleId={battleId}
                  isActive={round === index + 1}
                />
              ))}
              <ShadowLine />
            </CasesContainer>
          </CaseListContainer>
          <GameInfo>
            <GameInfoComponent
              battleId={battleId}
              playersCount={players.length}
              gamePlayersMax={playersCount}
              totalWin={totalWin}
              finishedAt={finishedAt}
              totalPrice={totalPrice}
            />
          </GameInfo>
        </Wrapper>
        <Wrapper>
          <UserComponents
            battleId={battleId}
            playersInTeam={playersInTeam}
            playersCount={playersCount}
            players={players}
            isJoker={jokerMode}
          />
          <ButtonVariants
            battleId={battleId}
            isMyGame={isMyGame}
            totalPrice={totalPrice}
            casesIds={casesIds}
            playersCount={playersCount}
            winnersCount={winnersCount}
            winnersSplit={winnersSplit}
            isPrivate={isPrivate}
            double={double}
            jokerMode={jokerMode}
          />
        </Wrapper>
      </CardContainer>
    );
  },
  isEqual
);

//TODO: add possibility to update code structure;
