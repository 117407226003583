import { Reducer } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { Record } from 'immutable';
import type { IAccount } from './interfaces';
import { AccountsRecord } from './interfaces';
import { accountActions, accountsActionsTypes } from './interfaces/actions.accounts.interface';
import accountsRepository from './repositories/accounts.repository';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';
import { dialogOn } from 'core/ducks/dialog';
import { userModals } from 'core/User/modal.config';

// const accounts: Reducer<Record<IAccount>, accountActions> = (state = new AccountsRecord(), action) => {
//   switch (action.type) {
//     case accountsActionsTypes.RECEIVE_SHORT_ACCOUNT:
//       return state
//         .setIn(['profile'], action.payload.profile)
//         .setIn(['statistics', 'leader'], action.payload.leaders_statistics)

//     case accountsActionsTypes.RECEIVE_QUEST_STATISTICS_ACCOUNT:
//       return state.setIn(['statistics', 'quest'], action.payload.quest_statistics)

//     case accountsActionsTypes.RECEIVE_STATISTICS_ACCOUNT:
//       return state.setIn(['statistics', 'profile'], action.payload.statistics)

//     default:
//       return state
//   }
// }

const accounts: Reducer<Record<IAccount>, accountActions> = (
  state = new AccountsRecord(),
  action
) => {
  switch (action.type) {
    case accountsActionsTypes.FETCH_PROFILE:
      return state.deleteIn(['profile']);

    case accountsActionsTypes.FETCH_PROFILE_RESPONSE:
      return state.setIn(['profile'], action.payload.user);

    default:
      return state;
  }
};

export default accounts;

export const loadProfile = (userId: number) => ({
  type: accountsActionsTypes.FETCH_PROFILE,
  payload: {
    userId,
  },
});

export const loadProfileResponse = (user: IUserInfo) => ({
  type: accountsActionsTypes.FETCH_PROFILE_RESPONSE,
  payload: {
    user,
  },
});

// const receiveAccountProfile = (profile: IAccountProfile, leaders_statistics: IAccountStatisticLeader) => ({
//   type: accountsActionsTypes.RECEIVE_SHORT_ACCOUNT,
//   payload: {
//     profile,
//     leaders_statistics,
//   },
// })

export const fetchRaceStatistic = (accountId: number, race: number | string = 'latest') => ({
  type: accountsActionsTypes.FETCH_RACE_STATISTICS,
  payload: {
    accountId,
    race,
  },
});

// const receiveQuestStatistics = (quest_statistics: any) => ({
//   type: accountsActionsTypes.RECEIVE_QUEST_STATISTICS_ACCOUNT,
//   payload: {
//     quest_statistics,
//   },
// })

// const receiveAccountStatistics = (statistics: any) => ({
//   type: accountsActionsTypes.RECEIVE_STATISTICS_ACCOUNT,
//   payload: {
//     statistics,
//   },
// })

// const profileLoaderEpic: Epic = (action$, store$, { socket }) =>
//   action$.pipe(
//     ofType(accountsActionsTypes.FETCH_PROFILE),
//     throttleTime(5000),
//     pluck('payload', 'userId'),
//     tap((userId: number) => {
//       socket.io.emit('user:profile:fetch', { userId })
//       socket.io.emit('user:stats:fetch', { userId })
//     }),
//     switchMap((accountId: number) =>
//       merge(
//         of(fetchRaceStatistic(accountId)).pipe(
//           concatMap(action =>
//             getTournamentHistoryByParams(store$.value, 'great').loaded
//               ? [action]
//               : [action, fetchRacesHistory('great'), fetchRacesHistory('fast')]
//           )
//         ),
//         fromEvent(socket.io, 'user:profile:state').pipe(
//           take(1),
//           map(data => accountAdapter(data, getAppFilterRegex(store$.value))),
//           map(({ profile, leaders_statistics }: any) => receiveAccountProfile(profile, leaders_statistics))
//         ),
//         fromEvent(socket.io, 'user:profile:stats').pipe(
//           take(1),
//           map((statistics: any) => receiveAccountStatistics(statistics))
//         )
//       ).pipe(endWith(modalChangeState(accountsModals.PLAYER_PROFILE, true)))
//     )
//   )

export const profileLoaderEpic: Epic = action$ =>
  action$.pipe(
    ofType(accountsActionsTypes.FETCH_PROFILE),
    pluck('payload', 'userId'),
    switchMap((accountId: number) =>
      merge(
        of(dialogOn(userModals.USER_PROFILE)),
        accountsRepository
          .fetchRefillMethod(accountId)
          .pipe(map(({ response }: { response: IUserInfo }) => loadProfileResponse(response)))
      )
    )
  );

// const raceStatisticLoaderEpic: Epic = (action$, store$) =>
//   action$.pipe(
//     ofType(accountsActionsTypes.FETCH_RACE_STATISTICS),
//     pluck('payload'),
//     switchMap(({ accountId, race }) => {
//       const user = getUserDetails<'id'>(store$.value, 'id')
//       const userIds = `${accountId},${user || ''}`

//       return ajax({
//         url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/tickets/history?userIds=${userIds}&race=${race}&detailed=true&formatted=true`,
//         method: 'GET',
//       }).pipe(
//         map(({ response }: any) => receiveQuestStatistics(questStatisticsAdapter(response, accountId.toString())))
//       )
//     })
//   )
