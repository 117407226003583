export default {
  GAME: 'JOC',
  Winner: 'Câştigător',
  Win: 'Premiu',
  Chance: 'Şansă',
  'Chance to win': 'Chance to win',
  'At stake': 'În joc',
  'Sign in through STEAM to participate': 'Conectați-vă prin intermediul STEAM pentru a participa',
  'Steam trade link': 'Link către tranzacţii Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Fii primul care depune',
  'Place a bet': 'Lansează un pariu',
  'Deposit more': 'Depune mai mult',
  'Min bet': 'Nr. min. pariu',
  'Max bet': 'Pariu max.',
  'Bet amount': 'Valoare pariu',
  'max. items': 'Nr. max. articole',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',
  'Tickets to from': 'Tichete: de lav #{{ from  }} până la #{{ to }}',
  Skins: 'Fațete',
  Coins: 'Puncte',
  'VIRTUAL SKINS': 'virtuale skins',
  'Round number': 'Număr rundă',
  'Winning after commission': 'Câștig după comision',
  Loading: 'Încărcare',
};
