import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
`;
