import React, { FC, useEffect, useMemo } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { GamePage } from './game-page';
import { RulesPage } from './rules-page';
import { LotteryPage } from '@components/Histories';
import { registerX50Module, unregisterX50Module } from '../duck';
import '../locales';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => {
  useMemo(() => {
    registerX50Module();
  }, []);

  useEffect(() => {
    return () => unregisterX50Module();
  }, []);

  return (
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/lottery`} component={LotteryPage} />
        <Route path={`${match.url}/rules`} component={RulesPage} />
        <Route path={match.url} component={GamePage} />
      </AnimatedRouter>
    </div>
  );
};
