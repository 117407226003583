import * as React from 'react';
import styled from 'styled-components';
import { IRaceRecord, IRacesList } from '../../interfaces/reducer.leaders.interfaces';
import { useTranslation } from 'react-i18next';

interface ITournamentPlaces {
  races: IRacesList;
}

export const TournamentPlaces: React.FC<ITournamentPlaces> = ({ races }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <span>{t('Your places')}</span>
      <PlacesList>
        {races.map((race: IRaceRecord) => (
          <PlaceItem key={race.id} raceType={race.raceType}>
            <span>
              {t('Races type', {
                context: race.raceType,
              })}
            </span>
            <span>{race?.myStats?.position || '1000+'}</span>
          </PlaceItem>
        ))}
      </PlacesList>
    </Container>
  );
};

type IPlaceItemStyle = {
  raceType: string;
};

const Container = styled.div`
  padding: 1rem;
  background-color: var(--gray-400);

  & > span {
    font-size: 0.9rem;
    line-height: 1.79;
    color: var(--color-white);
  }
`;

const PlacesList = styled.div`
  display: flex;
  flex-flow: column;
`;

const PlaceItem = styled.div<IPlaceItemStyle>`
  display: flex;
  justify-content: space-between;

  font-size: 0.9rem;
  line-height: 2;
  color: ${p => `var(--${p.raceType}-race)`};

  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    border-bottom: 1px dashed ${p => `var(--${p.raceType}-race)`};
    position: absolute;
    bottom: 10px;
  }

  & > span {
    background-color: var(--gray-400);
    position: relative;

    &:first-child {
      padding-right: 0.3rem;
    }

    &:last-child {
      padding-left: 0.3rem;
    }
  }
`;
