export default {
  winning: 'Winning',
  line: 'Linia',
  lines: 'Linie',
  total: 'Suma',
  Autoplay: 'autoodtwarzanie',
  Start: 'Start',
  Paytable: 'Tabela wygranych',
  'Recent wins': 'Niedawne wygrane',
  'Your games': 'Twoje gry',
  'Bonus game': 'Gra bonusowa',
  'Slot works regardless of location': 'Slot działa niezależnie od położenia',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Każdy slot w linii może być zamieniony (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    'Zbierz wszystkie trzy sloty, aby otrzymać 10 bonusowych gier!',
  'Total won': 'Całkowita wygrana',
  'Bonus games': 'Gra bonusowa',
};
