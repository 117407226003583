import { useCallback, useEffect } from 'react';
import { modalOn, modalOff, modalChangeState } from '../duck';
import { getModalState } from '../selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/interface';

export const useCreateModal = (modalName: string) => {
  const modalState = useSelector<IStore, boolean | (string | number)[]>(
    state => getModalState(state, modalName),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(modalOn(modalName));
    return () => {
      dispatch(modalOff(modalName));
    };
  }, [dispatch, modalName]);

  const handleCloseModal = useCallback(() => {
    dispatch(modalChangeState(modalName, false));
  }, [dispatch, modalName]);

  return {
    modalState,
    handleCloseModal,
  };
};
