import React, { useEffect } from 'react';
import { Container, EmptyComponent, StyledButton } from './ButtonVariants.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useUser } from 'core/User';
import { useGetBattleGameStatusById, useGetCreatorId, useGetGameId } from 'games/CaseBattle/hooks';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { GameStatus } from 'games/CaseBattle/constants';
import { useDispatch } from 'react-redux';
import { addBotAction } from 'games/CaseBattle/ducks/case-battle-game-duck';
import { useHistory } from 'react-router-dom';
import { actionCreateGame, actionResponseGamesId, removeAllCases } from 'games/CaseBattle/ducks';

interface ButtonVariantsProps {
  battleId: number;
  isMyGame: boolean;
  totalPrice?: number;
  casesIds: number[];
  playersCount: number;
  winnersCount: number;
  winnersSplit: boolean;
  isPrivate: boolean;
  double: boolean;
  jokerMode: boolean;
}

export const ButtonVariants: React.FC<ButtonVariantsProps> = React.memo(
  ({
    battleId,
    isMyGame,
    totalPrice,
    casesIds,
    playersCount,
    winnersCount,
    winnersSplit,
    isPrivate,
    double,
    jokerMode,
  }) => {
    const { user } = useUser();
    const { status } = useGetBattleGameStatusById(battleId);
    const { creatorId } = useGetCreatorId(battleId);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const isCreator = creatorId === user.id;
    const isNeedText = status === GameStatus.waiting || status === GameStatus.finish;
    const gameId = useGetGameId();

    useEffect(() => {
      if (gameId && !history.location.pathname.includes(String(gameId))) {
        history.push(`../case-battle/${gameId}`);
        dispatch(actionResponseGamesId(0));
      }
    }, [dispatch, gameId, history]);

    const redirect = () => {
      history.push(`../case-battle/${battleId}`);
    };

    const replay = () => {
      history.push({
        pathname: `../case-battle/${battleId}`,
        state: { isReplay: true },
      });
    };

    const recreate = () => {
      dispatch(
        actionCreateGame({
          caseIds: casesIds,
          double,
          isPrivate,
          playersCount,
          winnersCount,
          winnersSplit,
          jokerMode,
        })
      );
      dispatch(removeAllCases());
    };

    const getText = () => {
      if (isCreator) {
        if (status === GameStatus.waiting) {
          return t('addBot');
        }
        if (status === GameStatus.finish) {
          return (
            <>
              {t('recreate')}
              <CurrencyText value={totalPrice} />
            </>
          );
        }
      }
      if (user.id) {
        if (status === GameStatus.finish) {
          return (
            <>
              {t('recreate')}
              <CurrencyText value={totalPrice} />
            </>
          );
        }
      }
      return <>{t('open')}</>;
    };

    const isVisible = () => {
      if (isCreator) {
        if (isNeedText) {
          return true;
        } else {
          return false;
        }
      }
      if (isMyGame) {
        if (status === GameStatus.finish) {
          return true;
        }
        return false;
      }
      if (user.id) {
        if (isNeedText) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };

    const getClickEvent = () => {
      if (isCreator) {
        if (status === GameStatus.waiting) {
          return dispatch(addBotAction({ battleId }));
        }
        if (status === GameStatus.finish) {
          return recreate();
        }
      }
      if (user.id) {
        if (status === GameStatus.finish) {
          return recreate();
        }
      }
      return redirect();
    };

    //TODO: try to optimize conditions
    return (
      <Container>
        {(status !== GameStatus.waiting || isMyGame) && (
          <>
            {status === GameStatus.finish ? (
              <CaseBattleIcon.ReplayIcon onClick={replay} />
            ) : (
              <CaseBattleIcon.WatchIcon onClick={redirect} />
            )}
          </>
        )}
        <StyledButton
          onClick={getClickEvent}
          isNeedToChangeVariant={status === GameStatus.finish}
          data-visible={isVisible() ? 'visible' : 'hide'}
          isJoker={jokerMode}
        >
          {getText()}
        </StyledButton>
        <EmptyComponent isVisible={isVisible()}>{getText()}</EmptyComponent>
      </Container>
    );
  }
);
