import { DroppedItemsResults } from 'games/CaseGame';
import { ProfileEnum } from 'games/CaseGame/constants';
import { SuitCaseData } from 'games/CaseGame/interfaces';

export interface GeneralQuestInfoResponse {
  categories: CategoryItem[];
  endAt: Date;
  progressEndAt: Date;
  /** Финальная награда если доступна. entityId всегда -1 */
  reward?: Reward;
}

export enum CasesQuestStatusEnum {
  TAKEN = 'taken',
  OPENED = 'opened',
  AVAILABLE = 'available',
  BLOCKED = 'blocked',
}

export interface CaseRevisionResult extends DroppedItemsResults {
  case: Omit<SuitCaseData, 'category'>;
  profit: number;
  createdAt: Date;
  isFree: boolean;
  user: { id: number };
  winningPrice: number;
}

export interface CategoryItem {
  /** модель возможно не точно такая, приходит если приз забран или доступен для забора. Если кейс открыт то не придет*/
  case?: SuitCaseData;
  /** id категории кейса */
  entityId: EntityIdType;
  /** сколько всего очков в категории*/
  count: number;
  /** сколько набрал очков в категории */
  progress: number;
  questId: number;
  /** название кейса где содержится приз */
  name: string;
  /** картинка основная (для сетки) */
  image: null | string;
  /** картинка (доп)*/
  externalImage?: null | string;
  updatedAt?: string | null;
  /** статус награды в категории */
  status: CasesQuestStatusEnum;
  /** инфа об открытом подарке openUUID где-то тут должен быть.*/
  caseRevisionResult?: CaseRevisionResult;
}

export interface Reward {
  /** инфа об открытом подарке openUUID где-то тут должен быть.*/
  caseRevisionResult?: CaseRevisionResult;
  /** модель возможно не точно такая, приходит если приз забран или доступен для забора. Если кейс открыт то не придет*/
  case?: SuitCaseData;
  entityId: number;
  questId: number;
  status: CasesQuestStatusEnum;
}

export type EntityIdType = 3 | 5 | 6 | 11 | 14 | 13 | 12 | 10 | 9;
export interface CaseQuestMotivatorLast {
  date: Date;
  image: string;
  user: {
    id: number;
    avatar: string;
    name: string;
    profileType: ProfileEnum;
    level: number;
  };
  passed: number;
  total: number;
}

/** Из сокета */
export interface CaseQuestProgress {
  /** для внутреннего пользования бэка, на фронте не нужно.*/
  id: number;
  quest: {
    /** id квеста */
    id: number;
    /** пока хз */
    params: {
      count: number;
    };
    /** true только если набрано достаточное кол-во очков и приз еще не был забран. в остальных случаях false */
    rewardAvailable: boolean;
  };
  /** id категории кейса */
  entityId: number;
  /** баллов всего набрано в категории */
  progress: number;
  /** баллов добавилось за открытие в категории */
  count: number;
}

/** Бонусный приз приходит так в сокеты (можно расширить при необходимости)*/
export interface CaseLuckyWonEvent {
  /** id категории кейса */
  caseId: number;
  entityId: number;
  questId: number;
}
