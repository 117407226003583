import { SortData } from 'games/CaseGame';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import { CaseBattleSortActions } from './create-battle-filters.interfaces';

export const caseBattleSortAction = (sortData: string) => ({
  type: CASE_BATTLE_ACTION_TYPE.SORT_TYPE,
  payload: {
    sortBy: sortData,
  },
});

export const caseBattlePriceRangeAction = (minPrice: number, maxPrice: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.SORT_PRICE_RANGE,
  payload: {
    minPrice,
    maxPrice,
  },
});
export const caseBattleSearchParamsAction = (query: string) => ({
  type: CASE_BATTLE_ACTION_TYPE.SORT_SEARCH,
  payload: {
    query,
  },
});

export const caseBattleCleanSortParamsAction = () => ({
  type: CASE_BATTLE_ACTION_TYPE.SORT_CLEAN_SORT_PARAMS,
});

const initialState: Record<string, SortData[keyof SortData] | null> = {
  sortBy: 'popular',
  minPrice: null,
  maxPrice: null,
  query: null,
};

export const sortParams = (state = initialState, action: CaseBattleSortActions) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.SORT_TYPE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case CASE_BATTLE_ACTION_TYPE.SORT_PRICE_RANGE: {
      return {
        ...state,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
      };
    }
    case CASE_BATTLE_ACTION_TYPE.SORT_CLEAN_SORT_PARAMS: {
      return initialState;
    }
    case CASE_BATTLE_ACTION_TYPE.SORT_SEARCH: {
      return {
        ...state,
        query: action.payload.query,
      };
    }
    default:
      return state;
  }
};
