export default {
  Games: '游戏',
  'Refill balance': '為餘額充值',
  Settings: '設定',
  Store: '商店',
  'Get free money': '获得免费金币',
  Notifications: '通知',
  FAQ: 'FAQ',
  'Log out': '登出',
  'Steam trade link': 'Steam 交易連結',
  'Where can I get a link?': '從哪裡可以獲得連結？',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    '您与CSGOFAST服务断开连接。 请点击屏幕重新连接。',
  'App was offline. Check your internet connection to continue!':
    '应用程序已掉线。 请检查您的互联网连接后继续！',
  SAVE: 'SAVE',
};
