import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAccountProfile } from './useAccountProfile';

export const useAccountRouter = () => {
  const { profile } = useAccountProfile();
  const { push } = useHistory();

  useEffect(() => {
    !profile.id && push('/');
  }, [profile, push]);
};
