export default {
  'Fair game': 'Fair game',
  BET: 'BET ',
  AUTOSTOP: 'AUTOSTOP ',
  min: 'min',
  max: 'max',
  '(0 = Disabled)': '(0 = Disabled)',
  Stop: 'Stop',
  Wait: 'Wait',
  'Pick up winnings': 'Pick up winnings',
  'Wait for next round...': 'Wait for next round...',
  'History:': 'History:',
  '!! Crash !!': '!! Crash !!',
  'You won!': 'YOU WON!',
};
