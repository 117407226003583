import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from './SortMainPage.styles';
import { useGetSortParam, useGetSortHistoryParam } from 'games/CaseBattle/hooks';
import { useDialog, useToolBar } from 'core/hooks';
import { FilterCaseBattleConfig } from 'games/CaseBattle/dialog-config/dialog-config';

export const SortMainPage = () => {
  const { t } = useTranslation();
  const { sortParam } = useGetSortParam();
  const { sortHistoryParam } = useGetSortHistoryParam();
  const { toolBarState } = useToolBar();
  const { handleOpenDialog } = useDialog(FilterCaseBattleConfig.FILTER);
  const location = useLocation();
  const isHistoryPage = location.pathname.split('/')?.[2] === 'history';

  return (
    <Container onClick={handleOpenDialog} isToolBarOpen={toolBarState}>
      <span>{t(`sortBy.${isHistoryPage ? sortHistoryParam : sortParam}`)}</span>
    </Container>
  );
};
