export default {
  GAME: 'SPILL',
  Winner: 'Vinner',
  Won: 'Vunnet',
  Chance: 'Sjanse',
  Prize: 'Gevinst',
  'At stake': 'På spill',
  'Sign in through STEAM to participate': 'Logg inn gjennom STEAM for å delta',
  'Steam trade link': 'Steam trade link',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Bli den første som gjør et innskudd',
  'Place a bet': 'legg til et bet',
  'Deposit more': 'Legg til mer',
  'Min bet': 'minste innskudd',
  'Max bet': 'Maks bet',
  'Bet amount': 'Beløp',
  'max. items': 'Maks skins',
  hidden: 'Skjult',
  Copy: 'Kopier',
  'Winning after commission': 'Vinner etter servicekommisjon',
  Loading: 'Laster inn',
};
