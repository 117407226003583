import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from 'core/hooks/debounce/useDebounce';
import { actionRequestCaseList, searchParamsAction } from 'games/CaseGame/ducks';
import { CaseList } from 'games/CaseGame/components/CaseList/CaseList';
import { IDialogComponent } from 'core/interfaces';
import { Search } from '@components/filters';
import { useGetCaseListData, useGetSortParams } from 'games/CaseGame/hooks';

interface SearchDialogProps extends IDialogComponent<boolean> {}

export const SearchDialog: React.FC<SearchDialogProps> = ({ handleCloseModal }) => {
  const sortParams = useGetSortParams();
  const { casesData } = useGetCaseListData();
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  const debouncedValue = useDebounce(query, 300);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const cleanSearchHandler = () => {
    setQuery('');
    dispatch(searchParamsAction(''));
    dispatch(
      actionRequestCaseList({
        id: casesData.data.idCategory,
        filterParams: {
          sortBy: sortParams.sortBy,
          maxPrice: sortParams.maxPrice,
          minPrice: sortParams.minPrice,
          query: null,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(searchParamsAction(debouncedValue ? debouncedValue.trim() : null));
    dispatch(
      actionRequestCaseList({
        id: casesData.data.idCategory,
        filterParams: {
          sortBy: sortParams.sortBy,
          maxPrice: sortParams.maxPrice,
          minPrice: sortParams.minPrice,
          query: debouncedValue ? debouncedValue.trim() : null,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Search
      query={query}
      cleanSearchHandler={cleanSearchHandler}
      handleCloseModal={handleCloseModal}
      changeHandler={changeHandler}
      i18nextKey="cases2.0"
    >
      <CaseList />
    </Search>
  );
};
