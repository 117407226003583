import React from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';

export const SlotGameNavigation = () => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  return (
    <Container>
      <NavigationLink to={`${match.url}/rules`} as={Link}>
        <div className="leading">
          <i className="icon-paytable" />
        </div>
        <div>
          <span>{t('Paytable')}</span>
        </div>
      </NavigationLink>
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem 0 1.75rem;
  background: var(--color-dark-600);
  position: relative;
  z-index: 31;
`;
