import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SoundService from 'services/sound.service';
import { useTimerEvent } from 'core/hooks';
import { useGameCondition, useGameHandsCard, useGameMyBet, useUntilTime } from '../../../hooks';
import { GamePhase } from '../../../interfaces/game-phase.enum';
import { getBoardTypesByHandCards } from '../../../utils';

const sound = new SoundService();

export const GamePhases = () => {
  const { t } = useTranslation('baccarat');
  const handCards = useGameHandsCard();
  const phase = useGameCondition();
  const { untilTime } = useUntilTime();
  const [until] = useTimerEvent(untilTime);
  const myBet = useGameMyBet() as string[];

  useEffect(() => {
    if (until.s >= 1 && until.s <= 5 && until.m === 0) sound.play('Baccarat', 'Timer');
  }, [until]);

  useEffect(() => {
    if (phase === GamePhase.GAME) sound.play('baccarat', 'game_start');
    if (phase === GamePhase.BET) sound.play('Baccarat', 'Game end');
  }, [phase]);

  const sectors = useMemo<string>(() => {
    return getBoardTypesByHandCards(handCards);
  }, [handCards]);

  useEffect(() => {
    if (phase === GamePhase.TIMEOUT && myBet.length && sectors) {
      if (myBet.includes(sectors)) {
        sound.play('Baccarat', 'Winning');
      } else {
        sound.play('Baccarat', 'Loosing');
      }
    }
  }, [myBet, phase, sectors]);

  return (
    <Container>
      <span
        className="pick-color"
        style={{ opacity: +[GamePhase.GAME, GamePhase.BET].includes(phase) }}
      >
        {t('Pick a color')}
      </span>
      <span className={`board-wins ${sectors}`} style={{ opacity: +(phase === GamePhase.TIMEOUT) }}>
        {t('won board', {
          context: sectors,
        })}
      </span>
      <span
        className="raffling"
        style={{
          opacity: +[GamePhase.GAME, GamePhase.TIMEOUT].includes(phase),
        }}
      >
        {t('Raffling')}
      </span>
      <span className="timeline" style={{ opacity: +(until.s && phase === GamePhase.BET) }}>
        {t('Bets are being accepted')} (
        {until.m.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
        :
        {until.s.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
        )
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  height: 2.75rem;
  position: relative;

  transition: all 100ms ease-in;

  & span {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    transition: all 100ms ease-in;

    &.board-wins,
    &.pick-color {
      top: 0;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    &.pick-color {
      color: var(--color-white);
    }

    &.board-wins {
      color: var(--stroke-color);
    }

    &.raffling,
    &.timeline {
      bottom: 0;
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0.286px;
      color: var(--color-gray-200);
    }
  }
`;
