import { CurrencyText } from '@components/currency';
import { AuthDialogs } from 'core/Auth';
import { useDialog } from 'core/hooks';
import { Modules } from 'core/interfaces';
import { addBotAction, joinToGameAction } from 'games/CaseBattle/ducks/case-battle-game-duck';
import { ProfileEnum } from 'games/CaseGame/constants';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

interface GetButtonParamsProps {
  isReady: boolean;
  isMember: boolean;
  isCreator: boolean;
  gamePrice: number;
  userId?: number;
  balance?: number;
  accountType?: ProfileEnum;
  teamNumber: number;
}

interface GetButtonParamsResult {
  text: string;
  additionalText?: ReactNode;
  className?: string;
  clickHandler?: () => void;
}

export const useGetButtonParams = ({
  isCreator,
  isMember,
  isReady,
  balance,
  gamePrice,
  userId,
  accountType,
  teamNumber,
}: GetButtonParamsProps): GetButtonParamsResult => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleOpenDialog } = useDialog(AuthDialogs.SIGN_IN);
  const { battleId }: { battleId?: string } = useParams();

  if (!userId) {
    return {
      clickHandler: () => {
        handleOpenDialog();
      },
      className: 'primary',
      text: 'login',
    };
  }

  if (!isReady && isCreator) {
    return {
      clickHandler: () => {
        dispatch(
          addBotAction({
            battleId: +battleId,
            team: teamNumber,
          })
        );
      },
      className: 'add-bot',
      text: 'addBot',
    };
  }

  if (!isReady && !isMember && !isCreator && gamePrice < balance) {
    return {
      clickHandler: () => {
        dispatch(joinToGameAction(+battleId, teamNumber));
      },
      className: 'primary',
      text: 'join',
      additionalText: <CurrencyText value={gamePrice} />,
    };
  }

  if (gamePrice > balance || accountType === ProfileEnum.BRONZE) {
    return {
      clickHandler: () => {
        history.push(`../${Modules.REFILL}`);
      },
      className: 'primary refill',
      text: 'refillOn',
      additionalText: accountType !== ProfileEnum.BRONZE && (
        <CurrencyText value={gamePrice - balance} />
      ),
      //NOTE: on code higher if account  type is equal Bronze, we need to notify player to refill some sum on account to move to Silver type
    };
  }

  return { text: 'waiting', clickHandler: undefined, className: 'add-bot' };
};
