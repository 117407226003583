import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Bubble } from './Bubble';
import styled from 'styled-components';
import { IMessage } from '../interfaces/reducer.interface';

const { useLayoutEffect, useRef } = React;

interface IList {
  messages: IMessage[];
}

export const MessageList: React.FC<IList> = props => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, offsetHeight } = containerRef.current;

    if (
      !containerRef.current.scrollTop ||
      scrollHeight - (scrollTop + offsetHeight) < offsetHeight
    ) {
      (ReactDOM.findDOMNode(containerRef.current) as HTMLDivElement).scrollTo(
        0,
        containerRef.current.scrollHeight || 0
      );
    }
  }, [props.messages]);

  return (
    <Container ref={containerRef} id="containerElement">
      {props.messages.map((message: IMessage, i, arr) => (
        <Bubble
          key={message.messageId}
          message={message}
          nextUserId={arr[i + 1] ? arr[i + 1].userId : null}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 10px 10px;
  min-height: 100%;
`;
