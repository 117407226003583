import React, { FC, useState, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Close,
  Container,
  ContainerEmoji,
  ContainerIcon,
  ContainerIcons,
  Icons,
} from './EmojiButton.styles';
import { EmojiIcons } from '../../../../assets/index';
import { Emoji } from '../Emoji';
import {
  actionResponseEmojiAwaiting,
  addEmojiAction,
} from 'games/CaseBattle/ducks/case-battle-game-duck';
import { useGetCaseBattleGameData, useGetCaseBattleGameReplayData } from 'games/CaseBattle/hooks';
import { GameStatus } from 'games/CaseBattle/constants';
import { getArrayOfEmojis } from 'games/CaseBattle/utils';
import { useUser } from 'core/User';

interface EmojiButtonProps {
  isUpIcons: boolean;
  handleIconsPosition: (isUp?: boolean) => void;
}

export const EmojiButton: FC<EmojiButtonProps> = memo(({ isUpIcons, handleIconsPosition }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { battleId }: { battleId?: string } = useParams();
  const {
    user: { id: userId },
  } = useUser();
  const [showIcons, setShowIcons] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [activeEmoji, setActiveEmoji] = useState<number>();
  const [isUpIcon, setIsUpIcon] = useState(true);
  const { isReplay } = useGetCaseBattleGameReplayData();
  const {
    data: { game, gameStatus, isEmojiAwaiting },
  } = useGetCaseBattleGameData();
  const playersIds = game.players.map(player => player.user_id);

  useEffect(() => {
    if (gameStatus === GameStatus.finish) {
      setShowIcons(false);
      setShowIcon(false);
      setIsUpIcon(false);
      handleIconsPosition(false);
    } else if (gameStatus === GameStatus.waiting) {
      setShowIcon(true);
      setIsUpIcon(true);
    }
  }, [gameStatus, handleIconsPosition]);

  useEffect(() => {
    if (isReplay && gameStatus === GameStatus.roll) {
      setIsUpIcon(true);
      setShowIcon(true);
    }
  }, [gameStatus, isReplay]);

  const handleOpenEmojis = () => {
    setActiveEmoji(null);
    setShowIcons(prev => !prev);
    handleIconsPosition();
    setIsUpIcon(prev => !prev);

    setTimeout(() => {
      setShowIcon(prev => !prev);
    }, 300);
  };

  const handleCloseEmojis = () => {
    setShowIcon(prev => !prev);
    setIsUpIcon(prev => !prev);
    handleIconsPosition();

    setTimeout(() => {
      setShowIcons(prev => !prev);
    }, 300);
  };

  const handleChooseEmoji = (emojiId: number) => {
    if (!isEmojiAwaiting) {
      setActiveEmoji(emojiId);
      dispatch(addEmojiAction(Number(battleId), emojiId));
      dispatch(actionResponseEmojiAwaiting(true));
    }
  };

  const handleShowEmojis = () => {
    if (!isReplay) {
      handleOpenEmojis();
    }
  };

  const IconHappy = EmojiIcons[1];

  return (
    <>
      {!!userId && (
        <>
          {showIcons && (
            <Container isUp={isUpIcons}>
              <ContainerIcons className="tablet">
                <Close onClick={handleCloseEmojis}>{t('Close')}</Close>
                <Icons>
                  {Object.entries(EmojiIcons).map(([emojiId, Emoji]) => (
                    <ContainerEmoji
                      key={emojiId}
                      className={Number(emojiId) === activeEmoji ? 'active' : ''}
                      onClick={() => handleChooseEmoji(Number(emojiId))}
                      isEmojiAwaiting={isEmojiAwaiting}
                    >
                      <Emoji />
                    </ContainerEmoji>
                  ))}
                </Icons>
              </ContainerIcons>
            </Container>
          )}
          {showIcon && !isReplay && (
            <ContainerIcon onClick={handleShowEmojis} isUp={isUpIcon}>
              <IconHappy />
            </ContainerIcon>
          )}
        </>
      )}
      {((gameStatus !== GameStatus.finish && !isReplay) || isReplay) &&
        getArrayOfEmojis(game?.emoji)?.map(emoji => {
          if (!playersIds.includes(emoji.userId)) {
            return <Emoji key={emoji.id} emoji={emoji} isBarEmoji />;
          }
        })}
    </>
  );
});
