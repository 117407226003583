import { shallowEqual, useSelector } from 'react-redux';
import { getReferralCampaigns } from '../../selectors';
import { IReferralCampaign } from '../../interfaces';
import { IStore } from 'store/interface';

export const useReferralCampaigns = () => ({
  campaigns: useSelector<IStore, Record<string, IReferralCampaign>>(
    getReferralCampaigns,
    shallowEqual
  ),
});
