import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ChatRules = () => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <Paragraph>
        <Header>{t('In the chat it is forbidden:')}</Header>
      </Paragraph>

      <Paragraph>
        {t('1 To offend, humiliate and threaten other participants of the project;')}
      </Paragraph>

      <Paragraph>{t('2 To ask skins, to be engaged in begging;')}</Paragraph>

      <Paragraph>{t("3 To place any links which don't belong to service work;")}</Paragraph>

      <Paragraph>
        {t(
          '4 To place advertizing links, to advertize and mention third-party services and communities on Counter-Strike: Global Offensive;'
        )}
      </Paragraph>

      <Paragraph>{t('5 To send spam and flood;')}</Paragraph>

      <Paragraph>{t('6 To post referral links and codes;')}</Paragraph>

      <Paragraph>{t('7 To be fake service administrator or moderator;')}</Paragraph>

      <Paragraph>{t('8 To imitate a nickname or an avatar of system messages;')}</Paragraph>

      <Paragraph>
        {t('9 To offer purchase, sale of skins and game values bypassing service Store;')}
      </Paragraph>

      <Paragraph>{t('10 To offer purchase, sale and boost accounts;')}</Paragraph>

      <Paragraph>{t('11 To send to a chat phone numbers, messengers, e-wallets;')}</Paragraph>

      <Paragraph>{t('12 To arrange conflict situations in the other language chat;')}</Paragraph>

      <Paragraph>{t('13 To communicate on political or religious subjects;')}</Paragraph>

      <Paragraph>{t('14 To show signs of racism and nationalism;')}</Paragraph>

      <Paragraph>{t('15 To propagandize drugs, alcohol, violence;')}</Paragraph>

      <Paragraph>{t('16 To offend and threaten service administration')}</Paragraph>

      <Paragraph>
        {t(
          'Violators will be blocked on all chat channels for the period from 10 minutes to a permanent block'
        )}
      </Paragraph>

      <Paragraph>{t('Relocks duration increases on time automatically')}</Paragraph>

      <Paragraph>
        {t('The appeal of the received one week period Ban is carried out through tech support')}
      </Paragraph>

      <Paragraph>
        {t(
          'Moderators have the right to delete messages or ban the chat participant without explanation'
        )}
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;

const Paragraph = styled.div`
  padding: 8px 6px 8px 6px;
  color: var(--color-white);
  font-size: 14px;

  line-height: 20px;
`;

const Header = styled.div`
  font-size: 19px;
`;
