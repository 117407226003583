export default {
  '100$ for the new players!': '100$ новым игрокам',
  '100$ bonus is credited to a demo balance': 'Бонус 100$ начисляется на демосчет',
  'Get 100$ now!': 'Получить 100$',

  // refill modal
  'Top up your balance from 25$ and get bonuses': 'Пополни баланс от 25$ и забирай бонусы',
  'Exp bonus': 'Бонус к опыту',
  'Wheel Of Fortune free spin': 'Бесплатное вращение колеса фортуны',
  'Free case': 'Бесплатный кейс',
  'Access to account level-up': 'Прокачка аккаунта',
  Cashback: 'Кэшбэк',
  'Refill balance': 'Пополнить баланс',
};
