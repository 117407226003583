import { IStore } from 'store/interface';

export const getCaseBattleList = ({ caseBattle }: IStore) => caseBattle.caseBattleList;

export const getGameStatusById = (battleId: number) => ({ caseBattle }: IStore) => {
  const generalObject = {
    ...caseBattle.caseBattleList.data.games,
    ...caseBattle.caseBattleList.dataHistory.games,
    ...caseBattle.caseBattleList.dataMyHistory.games,
    ...caseBattle.caseBattleList.dataMyCaseBattleList.games,
  };
  return generalObject[battleId]?.gameStatus;
};

export const getWinnersInfo = (battleId: number) => ({ caseBattle }: IStore) => {
  const generalObject = {
    ...caseBattle.caseBattleList.data.games,
    ...caseBattle.caseBattleList.dataHistory.games,
    ...caseBattle.caseBattleList.dataMyHistory.games,
    ...caseBattle.caseBattleList.dataMyCaseBattleList.games,
  };
  return generalObject[battleId]?.winner_ids ?? [];
};
export const getCreatorId = (battleId: number) => ({ caseBattle }: IStore) => {
  const generalObject = {
    ...caseBattle.caseBattleList.data.games,
    ...caseBattle.caseBattleList.dataHistory.games,
    ...caseBattle.caseBattleList.dataMyHistory.games,
    ...caseBattle.caseBattleList.dataMyCaseBattleList.games,
  };
  return generalObject[battleId]?.creator_id;
};
export const getSettings = (battleId: number) => ({ caseBattle }: IStore) => {
  const generalObject = {
    ...caseBattle.caseBattleList.data.games,
    ...caseBattle.caseBattleList.dataHistory.games,
    ...caseBattle.caseBattleList.dataMyHistory.games,
    ...caseBattle.caseBattleList.dataMyCaseBattleList.games,
  };
  return generalObject[battleId]?.settings?.winnersSplit;
};
export const getSortParamForMainPage = ({ caseBattle }: IStore) => caseBattle.sortMainPageReducer;

export const getSortParamForHistoryPage = ({ caseBattle }: IStore) =>
  caseBattle.sortHistoryPageReducer;

export const getCaseBattleHistory = (store: IStore) => getCaseBattleList(store);

export const getIdsForCreateBattle = ({ caseBattle }: IStore) => caseBattle.createBattleReducer;

export const getTotalPrice = ({ caseBattle }: IStore) => caseBattle.totalPriceReducer;

export const getChosenCaseAmount = ({ caseBattle }: IStore) =>
  caseBattle.createBattleReducer.chosenCases;

export const getFilterParams = ({ caseBattle }: IStore) => caseBattle.sortParams;

export const getCases = ({ caseBattle }: IStore) => caseBattle.casesForCasesBattle;

export const getCaseBattleType = ({ caseBattle }: IStore) => caseBattle.caseBattleTypeReducer;

export const getGameId = ({ caseBattle }: IStore) => caseBattle.currentGameIdReducer;

export const getCaseBattleGameData = ({ caseBattle }: IStore) => caseBattle.caseBattleGameReducer;

export const getCaseBattleGameReplayData = ({ caseBattle }: IStore) =>
  caseBattle.caseBattleGameReplayReducer;

export const getCasesForBattleByIds = ({ caseBattle }: IStore) => caseBattle.casesByIdsReducer;

export const getRouletteData = ({ caseBattle }: IStore) => caseBattle.rouletteReducer;

export const getRouletteAnimationStatuses = ({ caseBattle }: IStore) =>
  caseBattle.animationRouletteStatusesReducer;

export const getCaseBattleSoundType = ({ caseBattle }: IStore) => caseBattle.soundTypeReducer;
