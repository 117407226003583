import styled from 'styled-components';

export const Item = styled.div`
  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-basis: 50%;
  flex-flow: row nowrap;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  position: relative;
`;

export const IconStar = styled.i`
  color: var(--yellow);
  font-size: 0.75rem;
  left: -0.9rem;
  position: absolute;
`;

export const Title = styled.span`
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  line-height: 15px;
`;

export const Currency = styled.span`
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  line-height: 15px;
`;
