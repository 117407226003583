import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useCouponsFacade } from '../../hooks';

export const LandingAppeal: FC = () => {
  const { t } = useTranslation();
  const coupons = useCouponsFacade();
  return (
    <Container>
      <h2>{t('It is time to use your yellow tickets!')}</h2>
      <p>{t('Join to raffling or exchange your tickets to items in to our special shop!')}</p>
      <div className="coupon-container">
        <span>
          {t('You have')}
          <i className="icon-coupon" />
          {coupons?.orange || 0}
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 3.6875rem 1.4375rem 2.8rem;
  letter-spacing: 0.54px;

  & h2,
  & p {
    width: max(75%, 16.25rem);
    margin-bottom: 0.75rem;
  }

  & h2 {
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    letter-spacing: 0.54px;
    color: var(--great-race);
  }

  & .coupon-container,
  & p {
    font-size: 0.875rem;
    color: white;
  }

  & p {
    line-height: 1.5625rem;
  }

  & .coupon-container {
    line-height: 0.9375rem;
    letter-spacing: 0.5px;

    & i {
      color: var(--great-race);
      font-size: 1.25rem;
      margin: 0 0.25rem 0 1rem;
    }
  }
`;
