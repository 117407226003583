/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'OYUN',
  Chat: 'Sohbet',
  'Email Support': 'E-posta desteği',
  'Live Support Chat': 'Canlı Destek Sohbeti',
  History: 'Geçmiş',
  Top: 'En İyi',
  Rules: 'Rules',
  'At stake': 'Tehlikede',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Mesaj yazmak için giriş yapmalısın',
  'Only players who have played the game are allowed to write':
    'Sadece oyuna giren oyuncuların yazmasına izin verilir.',
  'You are banned': 'Banlandınız',
  Reason: 'Sebep',
  'Time left': 'Süre kaldı',
  'Time to summarizing': 'Özetleme zamanı',
  spam: 'spam',
  'advertising (auto)': 'Reklam (auto)',
  advertising: 'reklam',
  rudeness: 'Kabalık',
  begging: 'Dilenmek',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Bugün',
  Yesterday: 'Dün',
  'Last year': 'Geçen yıl',
  'Show my wins': 'Benim kazanmalarımı göster',
  'History is empty': 'Geçmiş boş',
  Cancel: 'İptal et',
  Delete: 'Sil',
  Close: 'Kapat',
  Confirm: 'Onayla',
  'Are you sure to delete all notifications?': 'Tüm bildirimleri silmek istediğinize emin misiniz?',
  'Log in': 'Giriş yap',
  'Log in to try your luck': 'Giriş yap ve şansını dene',
  'try your luck!': 'Şansını dene!',
  'Take your prize': 'Kazancını al',
  'Sell for': 'şu fiyata sat:',
  BACK: 'BACK',
  'Warning!': 'Dikkat!',
  'Congratulations!': 'Tebrikler!',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Tebrikler! Hesabınıza takas teklifi gönderildi.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'Takas teklifi bir saat sonra sonlandırılacaktır',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Siparişiniz işleme alındıktan sonra Steam değiş tokuşun gönderimler sayfasındaki kazancınızı toplayabilirsiniz',

  'Round number': 'Tur numarası',
  'Round hash': 'Tur kazancı',
  'Hash salt': 'Hash salt',

  'Refill amount': 'Doldurma miktarı',
  Bonus: 'Bonus',
  'Bonus experience': 'Bonus deneyim',
  'Bonus wheel spins': 'Bonus çark çevirmeleri',
  'Bonus cases': 'Bonus kasalar',

  '${currency(amount)} bonus is ready!': '${currency(amount)} bonus hazır!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    "Bakiyeyi yeniden doldurun ve ücretsiz döndürme ve kasalarda ${currency(amount)} 'a kadar kazanın",

  'Get bonus': 'şimdi al',
};
