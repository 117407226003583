import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { SectorColors } from '../../../../utils';
import { useFactorControl } from '../../../../hooks';

interface IFactorControl {
  color: SectorColors;
  factor: string;
  currentAmount?: number;
  onClick?: () => void;
}

export const FactorControl: FC<IFactorControl> = ({ onClick, color, factor, currentAmount }) => {
  const { amount, disabled } = useFactorControl(color);

  return (
    <Container
      type="button"
      disabled={disabled}
      className={`${amount > 0 ? 'active' : ''} ${color}`}
      onClick={onClick}
    >
      <span className="amount">
        {!!amount && (
          <span>
            <CurrencyText value={amount} />
          </span>
        )}
        {!!currentAmount && (
          <span>
            {!!amount && '+ '}
            <CurrencyText value={currentAmount} />
          </span>
        )}
      </span>
      <span className="bid-coefficient">
        x<span>{factor}</span>
      </span>
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.375rem 0.75rem;
  border-radius: var(--size-radius-m);
  transition: all 300ms var(--transition-ease-out);

  &:disabled {
    opacity: 0.3;
  }

  &.zero {
    background: var(--double-zero-fill);
    border: 0.125rem solid var(--double-zero-stroke);

    &.active {
      background: var(--double-zero-stroke);
    }
  }

  &.red {
    background: var(--double-red-fill);
    border: 0.125rem solid var(--double-red-stroke);

    &.active {
      background: var(--double-red-stroke);
    }
  }
  &.black {
    background: var(--double-black-fill);
    border: 0.125rem solid var(--double-black-stroke);

    &.active {
      background: var(--double-black-stroke);
    }
  }

  & > span {
    font-weight: bold;
    letter-spacing: 0.084px;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: var(--color-white);
  }

  & .amount {
    & span {
      font-weight: inherit;
      letter-spacing: inherit;
      font-size: inherit;
      line-height: inherit;

      &:not(:first-of-type) {
        margin-left: 0.25rem;
      }
    }
  }

  & .bid-coefficient {
    letter-spacing: 2px;

    & span {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }
`;
