import { useState, useCallback } from 'react';

export const useAmountPay = () => {
  const [amount, setAmount] = useState<number>(100);

  const handleChangeAmount = useCallback((value: number) => {
    setAmount(value);
  }, []);

  return {
    amount,
    handleChangeAmount,
  };
};
