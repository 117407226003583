import * as React from 'react';
import styled from 'styled-components';

import { getDrawerOpenStatus } from 'core/AppShell/selectors';
import { shallowEqual, useSelector } from 'react-redux';

interface IAnimatedDrawerLayout {
  handleToggleDrawer: (value: boolean) => () => void;
  children: any;
}

export const AnimatedDrawerLayout: React.FC<IAnimatedDrawerLayout> = ({
  handleToggleDrawer,
  children,
}) => {
  const active = useSelector(getDrawerOpenStatus, shallowEqual);

  return (
    <>
      <AnimatedDiv isOpen={active}>
        <Overlay isOpen={active} onClick={handleToggleDrawer(false)} />
        <DrawerContent>{children}</DrawerContent>
      </AnimatedDiv>
    </>
  );
};

interface AnimatedDivStyle {
  isOpen: boolean;
}

const AnimatedDiv = styled.div<AnimatedDivStyle>`
  display: flex;
  flex: 0 0 100%;
  max-width: 90vw;
  width: 100%;
  height: 100%;
  transform: ${p => (p.isOpen ? 'none' : 'translate3d(calc(-90vw), 0, 0)')};
  transition: transform 0.4s cubic-bezier(0.37, 0, 0.63, 1);
  position: relative;
  z-index: 1000;
`;

type OverlayStyle = {
  isOpen: boolean;
};

const Overlay = styled.div<OverlayStyle>`
  position: absolute;
  width: 100vh;
  height: 100%;
  top: 0;
  left: 100%;
  background: #0d1417;
  z-index: 3000;
  opacity: ${p => (p.isOpen ? 1 : 0)};
  transform: ${p => (p.isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)')};
  transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);
`;

const DrawerContent = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  flex-shrink: 0;
  background-color: var(--color-dark-600);
  z-index: 3001;
  border-right: 1px solid var(--gray-400);
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;
