import { DIALOG } from 'dialogs/dialog-combiner';
import { CaseMotivatorDialogConfig } from 'games/CaseGame';
import { CaseMotivatorDialog } from './case-motivator-dialog';
import { transitions } from 'dialogs/transition.config';

DIALOG.add(CaseMotivatorDialogConfig.MOTIVATOR_DIALOG, {
  component: CaseMotivatorDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
