export default {
  Games: 'Игры',
  'recent games': 'Недавние игры',
  'Refill balance': 'Пополнение баланса',
  Settings: 'Настройки',
  Store: 'Магазин',
  'Get free money': 'Получить',
  Notifications: 'Уведомления',
  FAQ: 'FAQ',
  'Log out': 'Выйти',
  'Steam trade link': 'Ссылка на обмен в Steam',
  'Where can I get a link?': 'Где взять ссылку?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Вы были отключены от сервиса CSGOFAST. Коснитесь экрана, чтобы подключиться повторно.',
  'App was offline. Check your internet connection to continue!':
    'Проверьте состояние интернет-соединения! Приложение не может подключиться серверу.',
  SAVE: 'SAVE',
  'PC version': 'ПК-версия сайта',
  'Unfortunately screen rotation is not supported. For the correct display, rotate your device to a vertical position and refresh the page.':
    'К сожалению, поворот экрана не поддерживается. Для корректного отображения поверните устройство в вертикальное положение и обновите страницу.',
  'Not Found': 'Страница не найдена',
  'TRY YOUR LUCK!': 'Испытай свою удачу!',
  'counter strike, cs go betting, luck bets, csgo skins, cs go fast, csgofast, gofast, dota 2 betting,csgo gambling,dota2 gambling, match bets':
    'counter strike, cs go betting, luck bets, csgo skins, cs go fast, csgofast, gofast, dota 2 betting,csgo gambling,dota2 gambling, match bets,ставки CS:GO, ставки ксго,ставки дота2, ставки на киберспорт',
  'Service where players can try their luck and get awesome skins! Just deposit your skins to the raffle, become a winner and sweep the board!':
    'Сервис, на котором игроки испытывают удачу и выигрывают по-крупному! Вноси депозит, не упускай свой шанс!',
};
