import React, { FC, useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrivateRoute from 'decorators/PrivateRoute.decorator';
import { AnimatedRouter } from '@components/animated-router';
import { CryptoMethodsPage, PayoutMethodPage, PayoutSystemsPage } from './pages';
import { PayoutUserVerificationPage } from './payout-user-verification';
import { attachListener, detachListener } from '../duck';

const _WithdrawalRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(attachListener());
    return () => {
      dispatch(detachListener());
    };
  }, [dispatch]);

  return (
    <>
      <div className="content dark-layout">
        <AnimatedRouter>
          <Route path={`${match.url}/verify`} component={PayoutUserVerificationPage} />
          <Route path={`${match.url}/crypto`} component={CryptoMethodsPage} />
          <Route path={`${match.url}/:method`} component={PayoutMethodPage} />
          <Route path={match.url} component={PayoutSystemsPage} />
        </AnimatedRouter>
      </div>
      <div id="payout-portal" />
    </>
  );
};

export const WithdrawalRouter = PrivateRoute(_WithdrawalRouter);
