import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Header = styled.p`
  padding: 0 16px;
  text-align: center;
`;

export const Title = styled.span`
  color: #f7fbfc;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

export const Time = styled.span`
  color: #b4c5cd;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

export const TasksContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemContainer = styled(SkeletonElement)<{ height: number }>`
  background: var(--color-dark-200);
  border-radius: 16px;
  min-height: ${({ height }) => height}px;
  min-width: 176px;
`;
