import React, { FC, MutableRefObject, useCallback, useEffect } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import { EmptyLayout } from '@UIkit';
import { ShopElement } from './shop-element';
import { useShopIdentifiers, useShopMeta, useShopPagination } from '../../../hooks';
import { Container } from './shop-elements.styles';

interface IShopElements {
  parentRef: MutableRefObject<HTMLDivElement>;
}

export const ShopElements: FC<IShopElements> = ({ parentRef }) => {
  const { meta, loading, loaded, failure } = useShopMeta();
  const { identifiers } = useShopIdentifiers();
  const { loadMore } = useShopPagination();

  const elements = useVirtual({
    size: Math.ceil((loading ? identifiers + 10 : identifiers) / 2),
    parentRef,
    estimateSize: useCallback(() => (document.querySelector('body').offsetWidth - 24) / 2, []),
    overscan: 3,
  });

  useEffect(() => {
    const last = elements.virtualItems[elements.virtualItems.length - 1];
    if (!last) return;
    const size = identifiers / 2;
    if (last.index > size - 10 && meta.amount > identifiers && !loading && loaded) {
      loadMore((meta.offset + 100) / meta.limit + 1);
    }
  }, [elements.virtualItems, identifiers, meta, loading, loaded, loadMore]);

  if (failure) {
    return <EmptyLayout ctx="failure" namespace="exchange" />;
  }

  if (!identifiers && loaded) {
    return <EmptyLayout ctx="found" namespace="exchange" />;
  }

  return (
    <Container>
      <div style={{ height: elements.totalSize + 8 }}>
        {elements.virtualItems.map(({ index, start, size }: VirtualItem) => (
          <div
            key={index}
            className="row"
            style={{
              transform: `translateY(${start}px)`,
              height: size - 8 || 0,
            }}
          >
            <ShopElement identifier={index + index} />
            <ShopElement identifier={index + index + 1} />
          </div>
        ))}
      </div>
    </Container>
  );
};
