import { IStore } from 'store/interface';
import { getSectorByRand, SectorColors } from './utils';
import { createSelector } from 'reselect';
import { IMember } from './interfaces';

export const getMembers = ({ double }: IStore) => double.members;
export const getDoubleNumber = ({ double }: IStore) => double.number;
export const getDoubleHash = ({ double }: IStore) => double.md5;
export const getDoubleSalt = ({ double }: IStore) => double.salt;
export const getDoubleRand = ({ double }: IStore) => double.rand;
export const getDoubleUntilTime = ({ double }: IStore) => double.timeOldEnds;
export const getDoubleHistory = ({ double }: IStore) => double.history;
export const getDoubleTotalSum = ({ double }: IStore) => double.totalSum;
export const getDoubleCurrentSector = ({ double }: IStore): number =>
  double.rand ? getSectorByRand(double.rand) : null;

export const getDoublePreviousSector = ({ double }: IStore): number => double.history[0].sector;

export const getDoublePlayerAmount = createSelector(
  getMembers,
  (_: IStore, color: SectorColors) => color,
  ({ user }: IStore) => user.base.get('id'),
  (members: Record<SectorColors, Record<string, IMember>>, color, id) => {
    const amount = members[color]?.[id]?.sum || 0;

    if (color === SectorColors.ZERO) {
      return { amount, disabled: false };
    }

    const opposite = color === SectorColors.RED ? SectorColors.BLACK : SectorColors.RED;
    const execute = members[opposite]?.[id]?.sum || 0;

    return {
      amount,
      disabled: execute > 0,
    };
  }
);

export const getHighlightSectors = createSelector(
  getMembers,
  ({ user }: IStore) => user.base.get('id'),
  (members: Record<SectorColors, Record<string, IMember>>, id) => ({
    [SectorColors.ZERO]: !!members[SectorColors.ZERO][id],
    [SectorColors.RED]: !!members[SectorColors.RED][id],
    [SectorColors.BLACK]: !!members[SectorColors.BLACK][id],
  })
);
