import { createSelector } from 'reselect';
import { statisticsAdapter } from '../../adapters/statistics.adapter';
import { getChartStatistics } from './statistics.utils';
import { IStore } from 'store/interface';
import { IReferralCampaignMember } from './interfaces';

export const getDataUploadAvatar = ({ personalArea }: IStore) => personalArea.avatarUpload;

export const getFileAvatar = ({ personalArea }: IStore) => personalArea.fileAvatar;

export const getExperienceLog = ({ personalArea }: IStore) => personalArea.experienceLog;
export const getExperienceHistoryLog = ({ personalArea }: IStore) =>
  personalArea.experienceHistoryLog;

export const getProfileStatistic = createSelector(
  ({ personalArea }: IStore) => personalArea.statistics,
  stats => {
    const statistics = statisticsAdapter(stats);
    const charts = getChartStatistics(statistics);

    return {
      statistics,
      charts,
    };
  }
);

export const getTransactions = ({ personalArea }: IStore) => personalArea.transaction;

export const getUserCashback = ({ personalArea }: IStore) => personalArea.cashback;

export const getReferralIncomes = ({ personalArea }: IStore) => personalArea.referrals.incomes;

export const getReferralCampaigns = ({ personalArea }: IStore) => personalArea.referrals.campaigns;
export const getReferralCampaign = (id: string) => ({ personalArea }: IStore) =>
  personalArea.referrals.campaigns[id];
export const getReferralMembers = (id?: string) => ({
  personalArea,
}: IStore): IReferralCampaignMember[] =>
  personalArea.referrals.campaigns[id]?.referrals || personalArea.referrals.members;

export const getBonusCodeData = ({ personalArea }: IStore) => personalArea.bonusCodeReducer;
