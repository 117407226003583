import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { transactionsTabConfig } from '../../../transaction.utils';
import { Nav, TransactionTab } from './TransactionsTab.styles';

interface ITransactionsTab {
  changeFilter: (index: number) => () => void;
  filterId: number;
}

export const TransactionsTab: FC<ITransactionsTab> = ({ changeFilter, filterId }) => {
  const { t } = useTranslation();

  return (
    <Nav>
      {transactionsTabConfig.map((tab, index) => (
        <TransactionTab
          key={tab.name}
          active={transactionsTabConfig[filterId].name === tab.name}
          onClick={changeFilter(index)}
        >
          {t(tab.name)}
        </TransactionTab>
      ))}
    </Nav>
  );
};
