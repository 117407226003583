import React from 'react';
import styled from 'styled-components';
import { GameMembersList } from './game-members-list';
import { BoardTypes, factors } from '../../../interfaces';

export const GameMembers = () => {
  return (
    <Container>
      {(Object.keys(factors) as BoardTypes[]).map((type: BoardTypes) => (
        <GameMembersList key={type} type={type} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 22%;
  grid-gap: 0.25rem;
  margin: 1rem 0 4.875rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
