import { modalChangeState, modals } from './duck';

export { Modal } from './container';
export { useModal } from './hooks';
export { modalChangeState };
export { transitions } from './transition.config';
export { modalOff, modalOn } from './duck';
export type { IModalMap } from './duck';
export type { IModalComponent } from './container';

export const modalsModule = {
  reducer: modals,
};
