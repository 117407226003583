import React, { FC } from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { ActiveGame } from './ActiveGame';
import { PassedGame } from './PassedGame';
import { useSwitchGame } from '../../hooks';

import { IFastGame } from '../../interfaces/reducer.fast.interface';
import { ISwitchGameRenderProp } from '../../containers/Game';

interface ISwitchGame {
  gameData: IFastGame;
  children: (renderProp: ISwitchGameRenderProp) => JSX.Element;
}

export const SwitchGame: FC<ISwitchGame> = props => {
  const { status } = useSwitchGame(props.gameData.timeOldEnds);
  const switchTransition = useTransition(status, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {props.children({
        completed: status,
        children: () => (
          <Container>
            {switchTransition.map(style =>
              style.item ? (
                <AnimatedDiv key={style.key} style={style.props}>
                  <PassedGame gameData={props.gameData} />
                </AnimatedDiv>
              ) : (
                <AnimatedDiv key={style.key} style={style.props}>
                  <ActiveGame gameData={props.gameData} />
                </AnimatedDiv>
              )
            )}
          </Container>
        ),
      })}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 120px;
  position: relative;
`;

const AnimatedDiv = styled(animated.div)`
  width: 100%;
  min-height: 120px;
  position: absolute;
`;
