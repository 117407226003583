export default {
  'Cases types': 'Cases types',
  'Possible contents of the case': 'Possible contents of the case',
  'Other cases': 'Other cases',
  'Open for': 'Open for',
  //rules
  'How is this mode different from others?': 'How is this mode different from others?',
  'СASES – is a fun mode where you can spend coins to open cases and win great skins. Everyone loves it!':
    'СASES – is a fun mode where you can spend coins to open cases and win great skins. Everyone loves it!',
  'How do I open a case?': 'How do I open a case?',
  'Opening a case is simple. Just select your preferred case of skins and press Open. When the animation stops, a red line will indicate which skin you have won.':
    'Opening a case is simple. Just select your preferred case of skins and press Open. When the animation stops, a red line will indicate which skin you have won.',
  'What can I do with the skin I won?': 'What can I do with the skin I won?',
  "Once you've opened the case and received your skin, you can add it to your Steam inventory by pressing the Claim button. Alternatively, you can sell the skin to get an immediate coin reward credited to your account on our website and continue playing.":
    "Once you've opened the case and received your skin, you can add it to your Steam inventory by pressing the Claim button. Alternatively, you can sell the skin to get an immediate coin reward credited to your account on our website and continue playing.",
  sortBy: {
    label: 'Sort by',
    ASC_PRICE: 'By price (lowest price first)',
    ASC_PRICE_short: 'By price',
    DESC_PRICE: 'By price (highest price first)',
    DESC_PRICE_short: 'By price',
    ASC_NAME: 'By name A-Z',
    ASC_NAME_short: 'By name',
    DESC_NAME: 'By name Z-A',
    DESC_NAME_short: 'By name',
    OVERPRICE: 'By overprice',
    UNDERPRICE: 'By underprice',
    price: '$t(sortBy.ASC_PRICE)',
    '-price': '$t(sortBy.DESC_PRICE)',
    name: '$t(sortBy.ASC_NAME)',
    '-name': '$t(sortBy.DESC_NAME)',
    popular: 'Popular first',
    button: 'Ready',
  },
  Close: 'Close',
  priceRange: {
    label: 'Price range',
    button: 'Ready',
    setManually: 'Set manually',
  },
  search: {
    searchTitle: 'Search skin',
    placeholder: 'Search skin',
    empty: 'No results',
  },

  countsDialogHeader: {
    wear: 'Wear',
    price: 'Price',
    odds: 'Odds',
    close: 'CLOSE',
  },
  revision: {
    period: 'Period',
    caseRevision: 'Case Revision',
    close: 'Close',
    skinPrice: 'Skin and Price',
    range: 'Range',
    chance: 'Chance',
  },
  itemInfo: {
    wear: 'Wear',
    steamPrice: 'Steam Price',
    statTrak: 'StatTrak™',
    collection: 'Collection:',
    description: 'Description:',
    selectionCases: 'A selection of cases №1',
  },
  openCase: 'OPEN CASE',
  login: 'LOGIN',
  refill: 'REFILL',
  draw: 'DRAW IN PROGRESS',
  openAgain: 'OPEN AGAIN FOR',
  sellFor: 'SELL FOR',
  demo: 'Demo open',
  openFreeCase: 'OPEN A FREE CASE',
  demoSpin: 'RETRY DEMO SPIN',
  soundsSettings: {
    sounds: 'Sounds',
    standard: 'Standard',
    jingle: 'Jingle',
    danger: 'Danger',
    random: 'Random',
    approach: 'Approach',
    soundOff: 'Sound Off',
  },
  caseItems: 'Items in case',
  open: 'Open',
  closeItems: 'Close',
  caseRevision: 'Case revision',
  notFound: 'Nothing was found for your request',
  searchTerms: 'Try to lower the search terms',
  openCount: 'OPEN FOR FREE',
  okey: 'OKAY',
  watchReplay: 'Watch replay',
  getItemFromCase: 'Got item from case',
  replay: 'REPLAY',
  best: 'Best',
  all: 'All',
  notAvailable: 'Not available',
  history: 'History',
  case: 'Case',
  item: 'Item',
  status: 'Status',
  sell: 'Sell',
  sold: 'Sold',
};
