import React, { FC } from 'react';
import styled from 'styled-components';
import { DefaultContainer } from './combo-line.styles';
import { IComboLineComponent } from './combo-line.interface';

export const FourOrFive: FC<IComboLineComponent> = ({ className, children }) => (
  <Container className={className}>
    <div className="left-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="right-indicator" />
    {children}
  </Container>
);

const Container = styled(DefaultContainer)`
  &.line-4 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 3;
    }

    & .line-indicator {
      &:nth-child(5) {
        transform: rotate(45deg);
      }

      &:nth-child(5),
      &:nth-child(6) {
        grid-row: 3 / 6;
        border-radius: 0.125rem;
      }
      &:nth-child(6) {
        transform: rotate(-45deg);
      }
    }
  }

  &.line-5 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 7;
    }
    & .line-indicator {
      &:nth-child(5) {
        transform: rotate(-45deg) translate(1px, -1px);
      }

      &:nth-child(5),
      &:nth-child(6) {
        grid-row: 6 / 7;
        border-radius: 0.125rem;
      }
      &:nth-child(6) {
        transform: rotate(45deg) translate(0px, -1px);
      }
    }
  }
  & > .line-indicator {
    &:nth-child(5),
    &:nth-child(6) {
      width: 142%;
      justify-self: center;
    }
  }
`;
