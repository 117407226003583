import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { usePurchaseComputed, useSellingComputed } from '../../../hooks';
import { OrderType } from '../orders-switch-type/orders-switch-type';

interface IOrderDetails {
  type: OrderType;
}

export const OrderDetails: FC<IOrderDetails> = ({ type }) => {
  const { t } = useTranslation('marketplace');
  const purchase = usePurchaseComputed();
  const selling = useSellingComputed();
  return (
    <Container className={type}>
      <div>
        <span>
          {t('{{ count }} active lots for', {
            count: purchase.computed.active,
          })}
        </span>
        <span>
          {purchase.computed.cost > 0 ? <CurrencyText value={purchase.computed.cost} /> : <>-</>}
        </span>
      </div>
      <div>
        <span>
          {t('{{ count }} active lots for', {
            count: selling.computed.active,
          })}
        </span>
        <span>
          {selling.computed.amount > 0 ? <CurrencyText value={selling.computed.amount} /> : <>-</>}
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  padding: 0.75rem 1rem;
  min-height: 4.125rem;

  &.buy div:first-of-type span {
    color: var(--color-white);
  }

  &.sell div:last-of-type:not(:first-of-type) span {
    color: var(--color-white);
  }

  & div {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content 1fr;
    grid-gap: 0.125rem;
    color: var(--color-white-600);

    &:last-of-type span {
      text-align: end;
    }

    & span {
      font-size: 0.75rem;
      line-height: 1.75rem;
      letter-spacing: 0.1px;
      color: var(--color-white-600);
      transition: all 0.3s ease-in;

      &:last-of-type {
        font-size: 1.75rem;
        line-height: 1.625rem;
      }
    }
  }
`;
