import React, { createElement, FC, Fragment } from 'react';
import styled from 'styled-components';
import { FastInventoryPanel } from './fast-inventory-panel';
import { ChipsPanel } from './chips-panel';
import { CashPanel } from './cash-panel';
import { ParticipateModule, ParticipateType } from 'core/interfaces';

interface IParticipateTypePanels {
  module: ParticipateModule;
  types: ParticipateType[];
  modifier: any;
  controllers: any;
}

const PANELS = new Map<ParticipateType, FC<any>>([
  [ParticipateType.INVENTORY, FastInventoryPanel],
  [ParticipateType.MONEY, CashPanel],
  [ParticipateType.CHIPS, ChipsPanel],
]);

export const ParticipateTypePanels: FC<IParticipateTypePanels> = ({
  types,
  controllers,
  module,
  modifier,
}) => (
  <Container className={modifier.type}>
    {types.map((v: ParticipateType) => (
      <Fragment key={v}>
        {createElement(PANELS.get(v), {
          ...controllers[v],
          module,
          participate: modifier.participate[v],
          amount: modifier.participate.amount,
        })}
      </Fragment>
    ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  position: relative;
  transition: height 140ms ease-in;
  will-change: height;

  &.CHIPS,
  &.INVENTORY {
    height: 100px;
  }

  &.MONEY {
    height: 60px;
  }

  & .inventory-panel,
  & .chip-panel,
  & .cash-panel {
    transition: all 120ms ease-in;
    will-change: transform, opacity;
  }

  &.CHIPS .chip-panel,
  &.INVENTORY .inventory-panel,
  &.MONEY .cash-panel {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.INVENTORY .chip-panel {
  }

  &.INVENTORY .chip-panel,
  & .cash-panel {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  &.MONEY .chip-panel,
  & .inventory-panel {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
`;
