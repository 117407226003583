import { useEffect, useMemo, useState } from 'react';
import { GameMember, Round } from '../interfaces';
import { useGetRouletteAnimationStatuses } from './useGetRouletteAnimationStatuses.hook';
import { RouletteStatuses } from '../constants';
import { useGetCaseBattleGameData } from './useGetCaseBattleGameData.hook';

export interface IPlayer {
  playerInfo: GameMember;
  playerRounds: Round[];
  totalPrice: number;
}

export interface ITeams {
  players: IPlayer[];
  team_id: number;
  teamTotalPrice: number;
}

export const useGetTeams = () => {
  const {
    data: { game },
    isReplay,
  } = useGetCaseBattleGameData();
  const { players_count, rounds, players, players_in_team } = game;
  const { linesStatus } = useGetRouletteAnimationStatuses();

  const teams: ITeams[] = useMemo(() => {
    if (rounds.length === 0) {
      const teamsCount = Array.from(
        { length: players_count / players_in_team },
        (_, index) => index + 1
      );

      return teamsCount.map(team => {
        const playersInTeam = players.reduce((acc: IPlayer[], player) => {
          if (player.team === team) {
            acc.push({
              playerInfo: player,
              playerRounds: [],
              totalPrice: 0,
            });
          }
          return acc;
        }, []);

        const playersCountNeedAdd = Array.from(
          { length: players_in_team - playersInTeam.length },
          (_, index) => index + 1
        );

        const playersToAdd = playersCountNeedAdd.map(player => ({
          playerInfo: {
            ava_link: '',
            joined_at: null,
            name: '',
            team,
            type: null,
            user_id: Number(`${team}${player}`),
          },
          playerRounds: [],
          totalPrice: 0,
        }));

        return {
          team_id: team,
          players: [...playersInTeam, ...playersToAdd],
          teamTotalPrice: 0,
        } as ITeams;
      });
    }

    const usersRounds = (rounds as Round[])?.reduce((acc: IPlayer[], round) => {
      const existingUser = acc.find(({ playerInfo: { user_id } }) => user_id === round.user_id);

      if (existingUser) {
        existingUser.playerRounds.unshift(round);
        existingUser.totalPrice += round.result.price;
      } else {
        acc.unshift({
          playerInfo: {
            ava_link: '',
            joined_at: null,
            name: '',
            team: null,
            type: null,
            user_id: round.user_id,
          },
          playerRounds: [round],
          totalPrice: round.result.price,
        });
      }

      return acc;
    }, []);

    const usersCommands = players.reduce((acc: ITeams[], player) => {
      const existingTeam = acc.find(({ team_id }) => team_id === player.team);
      const userRounds = usersRounds.filter(
        ({ playerInfo: { user_id } }) => user_id === player.user_id
      );

      const playersToAdd = userRounds.map(({ playerRounds, totalPrice }) => ({
        playerInfo: player,
        playerRounds: playerRounds,
        totalPrice: totalPrice,
      }));

      if (existingTeam) {
        existingTeam.players = existingTeam.players.concat(playersToAdd);
        existingTeam.teamTotalPrice =
          existingTeam.teamTotalPrice +
          userRounds.reduce((sum, { totalPrice }) => sum + totalPrice, 0);
      } else {
        const teamTotalPrice = userRounds.reduce((sum, { totalPrice }) => sum + totalPrice, 0);

        acc = acc.concat({
          players: playersToAdd,
          team_id: player.team,
          teamTotalPrice,
        });
      }

      return [...acc].sort((a, b) => a.team_id - b.team_id);
    }, []);

    return usersCommands;
  }, [players, players_count, players_in_team, rounds]);

  const [delayedTeams, setDelayedTeams] = useState<ITeams[]>(teams);

  useEffect(() => {
    if (linesStatus === RouletteStatuses.afterRoll) {
      setDelayedTeams(teams);
    }
  }, [linesStatus, teams, rounds]);

  useEffect(() => {
    setDelayedTeams(teams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);

  useEffect(() => {
    if (isReplay) {
      setDelayedTeams(teams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReplay]);

  return {
    teams,
    delayedTeams,
  };
};
