import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import errorImg from '../assets/img/error-notice.png';
import successImg from '../assets/img/successful-notice.png';
import { connect } from 'react-redux';
import { getAppNotificationVisibleStatus, getLastAppNotification } from '../selectors';
import { hideNotification, makeNotificationSeen } from '../duck';

class Notifications extends Component {
  constructor() {
    super();
    this.animatedValue = new Animated.Value(1);
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible !== prevProps.isVisible) {
      this.animatedValue.setValue(this.props.isVisible ? 1 : 0);

      Animated.timing(this.animatedValue, {
        toValue: this.props.isVisible ? 0 : 1,
      }).start();
    }

    if (this.props.isVisible) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => prevProps.hideNotification(), 2000);
    } else {
      clearTimeout(this.timer);
    }
  }

  render() {
    const { message, t } = this.props;
    const position = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, -90],
    });

    return (
      <Animated.div style={{ top: position, ...Notify }}>
        {message.type && (
          <NotifyPopup onClick={this.pressAction}>
            <NotifyImg src={message.type === 'error' ? errorImg : successImg} />
            <NotifyContent>
              {message.header && (
                <NotifyText>
                  {t(message.header, {
                    ...message.params,
                    defaultValue: message.header,
                    postProcess: 'with-template',
                  })}
                </NotifyText>
              )}
              {message.body && (
                <NotifyText>
                  {t(message.body, {
                    ...message.params,
                    defaultValue: message.body,
                    postProcess: 'with-template',
                  })}
                </NotifyText>
              )}
            </NotifyContent>
          </NotifyPopup>
        )}
      </Animated.div>
    );
  }

  pressAction = () => {
    const { hideNotification, makeNotificationSeen, message } = this.props;
    hideNotification();
    makeNotificationSeen(message);
  };
}

const NotifyContent = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const NotifyText = styled.div`
  line-height: 1.29;
  color: var(--color-white);
  font-size: 13px;
  width: 100%;
`;

const NotifyImg = styled.img`
  width: 35px;
  height: 35px;
  margin: 5px;
`;

const Notify = {
  display: 'flex',
  backgroundColor: 'var(--gray-300)',
  padding: '5px',
  borderBottom: '1px solid #212f36',
  position: 'fixed',
  width: '100%',
  zIndex: 9999,
};

const NotifyPopup = styled.div`
  display: flex;
  background-color: var(--gray-300);
  padding: 5px;
  border-bottom: 1px solid #212f36;
`;

export default connect(
  state => ({
    message: getLastAppNotification(state),
    isVisible: getAppNotificationVisibleStatus(state),
  }),
  {
    hideNotification,
    makeNotificationSeen,
  }
)(withTranslation()(Notifications));
