import React, { FC } from 'react';
import { getUserAvatarUrl } from 'core/utils/image.utils';
import { URLS } from 'services/constant-urls';
import { onErrorHandler } from 'core/utils';
import { useUserSelfLock } from 'core/User/hooks/useUserSelfLock';
import { Image } from './UserAvatar.styles';

interface IUserAvatar {
  uri: string;
}

export const UserAvatar: FC<IUserAvatar> = ({ uri }) => {
  const { selfLock } = useUserSelfLock();

  return (
    <Image
      hasFilter={selfLock}
      src={getUserAvatarUrl(uri)}
      alt=""
      onError={e => onErrorHandler(e, URLS.avatarUrl)}
    />
  );
};
