export default {
  GAME: 'SPEL',
  Winner: 'Vinnare',
  Win: 'Pris',
  Chance: 'Chans',
  'Chance to win': 'Chance to win',
  'At stake': 'På spel',
  'Sign in through STEAM to participate': 'Logga in genom STEAM för att delta',
  'Steam trade link': 'Steam-byteslänk',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Var först med att sätta in',
  'Place a bet': 'Placera en insats',
  'Deposit more': 'Sätt in mer',
  'Min bet': 'Minsta insats',
  'Max bet': 'HÖGSTA MÖJLIGA INSATS',
  'Bet amount': 'Insatssumma',
  'max. items': 'maximalt antal föremål',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',
  'Tickets to from: from to': 'Biljetter: från #{{from}} till #{{to}}',
  Skins: 'Skins',
  Coins: 'Poäng',
  'VIRTUAL SKINS': 'virt. skins',
  'Round number': 'Rundans nummer',
  'Winning after commission': 'Vinst efter provision',
  Loading: 'Läser in',
};
