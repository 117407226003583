import { List, OrderedMap, Record } from 'immutable';
import { IReferrals } from './referral.reducers.interfaces';

// experience
export interface IExperienceLog {
  gameTypeId: number;
  amount: number;
  timestamp: string;
}

export const ExperienceLogList = List<IExperienceLog>([]);
export const ExperienceHistoryLogList = List<IExperienceLog>([]);

export type IExperienceLogList = List<IExperienceLog>;
export type IExperienceHistoryLogList = List<IExperienceLog>;

//statistics

export interface IStatistics {
  [key: string]: string | number | any[];
}

export const statisticsState: IStatistics = {
  game_played: [],
  money_spent: [],
};

//transactions

export interface ITransaction {
  loading: boolean;
  loaded: boolean;
  hasMore: boolean;
  entities: ITransactionOrderedMap;
}

export interface ITransactionsItem {
  id: number;
  createdAt: string;
  amount: number;
  type: string;
  payload: any;
}

export const TransactionRecord = Record<ITransaction>({
  loading: false,
  loaded: false,
  hasMore: true,
  entities: OrderedMap(),
});

export type ITransactionOrderedMap = OrderedMap<number, ITransactionsItem>;

export type ITransactionRecord = Record<ITransaction>;

// upload avatar
export interface IUploadAvatar {
  signedRequest: string;
  imageToken: string;
}

export const UploadAvatarRecord = Record<IUploadAvatar>({
  signedRequest: '',
  imageToken: '',
});

export type IUploadAvatarRecord = Record<IUploadAvatar>;

export interface IFileAvatar {
  file: string;
}

export const FileAvatarRecord = Record<IFileAvatar>({
  file: '',
});

export type IFileAvatarRecord = Record<IFileAvatar>;

// cashback
export interface ICashback {
  minCashbackSum: number;
  amount: number;
}

export interface IPersonalArea {
  experienceLog: IExperienceLogList;
  experienceHistoryLog: IExperienceHistoryLogList;
  statistics: IStatistics;
  transaction: ITransaction;
  avatarUpload: IUploadAvatar;
  fileAvatar: IFileAvatar;
  cashback: ICashback;
  referrals: IReferrals;
  bonusCodeReducer: boolean;
}
