export default {
  Withdraw: '提取',
  'Withdrawal sum': '提款金额',
  'Amount in {{title}}:': '金额在 $t({{title}}):',
  'Receive in {{title}}:': '接收 $t({{title}}):',
  'You will receive:': '您将收到:',
  'Select the network fee': '选择网络费用',
  'Commission:': '佣金:',
  'Ultra Low': '非常慢',
  Low: '慢的',
  Medium: '标准',
  High: '快速地',
  'No commission': '无佣金',
};
