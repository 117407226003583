export default {
  line: 'Linie',
  lines: 'Linii',
  Autoplay: 'Redare automată',
  Paytable: 'Lista câștigurilor',
  'Recent wins': 'Victorii recente',
  'Your games': 'Jocurile tale',
  'Bonus game': 'Joc bonus',
  'Slot works regardless of location': 'Slotul se potrivește oriunde s-ar afla',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Poate să înlocuiască orice slot din linie (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    'Adună toate cele trei sloturi pentru a luat cele 10 jocuri bonus!',
  'Total won': 'Câștigul total',
  'Bonus games': 'Jocuri bonus',
};
