import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  border-bottom: 2px solid var(--color-dark-100);
  display: flex;
  padding: 14px 12px;
`;

export const Header = styled.div`
  align-items: center;
  color: var(--light);
  display: flex;
  flex-flow: row;
  flex: 1;
  justify-content: space-between;
`;

export const HeaderIcon = styled.div`
  align-items: start;
  display: flex;
  flex-basis: 52px;
  justify-content: start;
`;

export const HeaderPreview = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export const TransactionName = styled.div`
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

export const TransactionNumber = styled.span`
  color: var(--color-white-600);
  font-size: 0.8em;
  font-weight: normal;
`;

export const TransactionDate = styled.span`
  color: var(--color-gray-200);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

type IHeaderPriceStyle = {
  hasProfit: boolean;
};

export const HeaderPrice = styled.div<IHeaderPriceStyle>`
  color: ${p => (p.hasProfit ? 'var(--green-100)' : 'var(--danger)')};
  font-size: 14px;
  font-weight: 700;
  text-align: right;
`;
