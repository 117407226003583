import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { Sounds } from 'modules/PersonalArea/components/Settings/Sounds';

export const SoundsPage: FC = () => (
  <>
    <AppBar deep />
    <Sounds />
  </>
);
