import styled from 'styled-components';

export const RulesContainer = styled.div`
  padding: 0 10px 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
`;

export const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0 8px;
  font-size: 12px;

  & span {
    color: var(--aqua);
    font-weight: 700;
  }
`;

export const RulesSpan = styled.span`
  color: var(--aqua);
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;
