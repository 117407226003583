import reducer from './ducks/reducers';
import './locales';
export * from './ducks';
export { GameRouter as CasesRouter } from './CaseRouter';
// export const CasesRouter = lazy(() => import('./containers'))
export * from './dialog-config/dialog-config';
export const caseModule = {
  moduleName: 'cases',
  moduleId: 10,
  path: '/cases',
  type: 'solo-pve',
  reducer,
};
