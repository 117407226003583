import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  display: flex;
  gap: 8px;
  overflow: scroll;
  padding: 56px 16px 0;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;
