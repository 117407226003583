import { combineEpics, Epic, ofType } from 'redux-observable';
import { delay, filter, map, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { fromEvent, interval, merge, of } from 'rxjs';
import { AppShellActionsTypes } from '../AppShell/interfaces/appshell.actions.interface';
import { authActionsTypes, AuthType } from './interfaces';
import { startApplication } from 'core/AppShell/duck';
import { dialogChangeState, dialogOff } from 'core/ducks/dialog';
import { userModals } from 'core/User/modal.config';
import { AuthDialogs } from './auth-dialog.config';
import { socketService } from 'services/socket.service';
import isWebview from 'is-ua-webview';
import { reloadPage } from 'core/utils/reloadPage';
import { addNotification } from 'modules/Notifications/duck';

export const bindIn = (provider: string) => ({
  type: authActionsTypes.SIGN_IN,
  payload: {
    provider,
  },
});

export const signInClose = () => ({
  type: authActionsTypes.SIGN_IN_CLOSED,
});

const webViewParams: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(AppShellActionsTypes.INITIALIZED),
    take(1),
    map(() => new URLSearchParams(window.location.href.split('?')[1])),
    filter(params => {
      const successParam = params.get('success');
      const eventParam = params.get('event');
      return (
        eventParam &&
        (eventParam === AuthType.Auth ||
          eventParam === AuthType.Reg ||
          eventParam === AuthType.Bind) &&
        successParam &&
        successParam === 'true'
      );
    }),
    tap(() => {
      socketService.connect();
    }),
    switchMap(() =>
      merge(
        of(startApplication()),
        of(dialogChangeState(userModals.SIGN_IN_FULFILLED, true)).pipe(delay(2500)),
        of(dialogOff(userModals.SIGN_IN_FULFILLED)).pipe(delay(5000))
      )
    )
  );
const bindingProviderEpic: Epic = action$ =>
  action$.pipe(
    ofType(authActionsTypes.SIGN_IN),
    pluck('payload', 'provider'),
    delay(800),
    map((provider: string) =>
      window.open(
        `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}social-auth/provider/${provider}${
          isWebview(navigator.userAgent) ? `?from=${window.location.host}` : ''
        }`,
        'socialAuth'
      )
    ),
    switchMap((providerWindow: Window) =>
      merge(
        interval(1000).pipe(
          filter(() => providerWindow.closed),
          map(() => signInClose()),
          takeUntil(action$.ofType(authActionsTypes.SIGN_IN_CLOSED))
        ),
        fromEvent(window, 'message').pipe(
          filter(({ data }: any) => {
            return data.source === 'auth-server';
          }),
          tap(({ data }) => {
            providerWindow.close();
            socketService.connect();
            return data;
          }),
          switchMap(({ data }) => {
            if (data.success) {
              return merge(
                of(reloadPage()),
                of(startApplication()),
                of(dialogOff(AuthDialogs.SIGN_IN)),
                of(dialogChangeState(userModals.SIGN_IN_FULFILLED, true)).pipe(delay(2500)),
                of(dialogOff(userModals.SIGN_IN_FULFILLED)).pipe(delay(5000))
              );
            } else {
              return of(addNotification({ type: 'error', body: data.error.message }));
            }
          })
        )
      )
    )
  );

export const authEpics = combineEpics(webViewParams, bindingProviderEpic);
