import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';

import { MiniPreloader } from '../../../../@components/Preloader';
import { NotFound } from './NotFound';
import { SkinItem } from '../SkinItem';

export class List extends PureComponent {
  state = {
    hasMore: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.items.length !== this.props.items.length) {
      this.setState({ hasMore: true });
    }
  }

  render() {
    const { mode, isFetching, items } = this.props;

    if (isFetching && !items.length) {
      return (
        <NotLent>
          <MiniPreloader />
        </NotLent>
      );
    }

    if (!isFetching && !items.length) return <NotFound />;

    return (
      <ListItems
        pageStart={0}
        loadMore={this.handleLoading}
        hasMore={this.state.hasMore}
        loader={<Fragment key={0} />}
        useWindow={false}
      >
        {this.props.items.map((item, i) => (
          <SkinItem key={item.id} {...item} mode={mode} addAction={this.selectActionByMode(item)} />
        ))}
      </ListItems>
    );
  }

  selectActionByMode = item => () => {
    const { mode, history, addItemToCart, filterGameId, gameTypes } = this.props;

    if (mode === 'refill' && filterGameId !== 1912) {
      return window.open(gameTypes[filterGameId].link);
    }

    if (mode === 'craft') {
      const { selectItem } = this.props;
      selectItem(item);
      return history.push('/craft');
    }

    addItemToCart(item.id);
    history.push('/marketplace/cart');
  };

  handleLoading = () => {
    this.setState({ hasMore: false });
    const { pagination, requestMarket, updatePaginationPage } = this.props;
    if (pagination.current + 1 <= pagination.count) {
      const page = pagination.current + 1;
      updatePaginationPage(page);
      requestMarket(false);
    }
  };
}

const ListItems = styled(InfiniteScroll)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-gap: 5px;
  grid-auto-rows: 1fr;
  width: 100%;
  padding: 10px;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`;

const NotLent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 91px);
`;
