import menuFruitsPath from './menu-fruits.png';
import balanceBannerPath from './balance-banner.jpg';
import prizePath from './prize.png';
import topBgPath from './top-bg.png';

import { ReactComponent as DailyFruit } from './daily-fruit.svg';
import { ReactComponent as WeeklyFruit } from './weekly-fruit.svg';
import { ReactComponent as SpecialFruit } from './special-fruit.svg';
import { ReactComponent as Slide1 } from './slide1.svg';
import { ReactComponent as Slide2 } from './slide2.svg';
import { ReactComponent as Slide3 } from './slide3.svg';
import { ReactComponent as Replay } from './replay.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Special } from './special.svg';
import { ReactComponent as Drink } from './drink.svg';
import { ReactComponent as HeaderSignboard } from './header-signboard.svg';
import { ReactComponent as TimeBg } from './time-bg.svg';
import { ReactComponent as ButtonLeft } from './button-left.svg';
import { ReactComponent as ButtonRight } from './button-right.svg';
import { ReactComponent as Leaves } from './leaves.svg';
import { ReactComponent as MotivatorBg } from './motivator-bg.svg';
import { ReactComponent as Box } from './box.svg';
import { ReactComponent as Completed } from './completed.svg';
import { ReactComponent as FirstPlace } from './first-place.svg';
import { ReactComponent as SecondPlace } from './second-place.svg';
import { ReactComponent as ThirdPlace } from './third-place.svg';
import { ReactComponent as Next } from './next.svg';

import loaderSummertime from './loader.gif';

export const eventImagesPath = {
  menuFruitsPath,
  balanceBannerPath,
  prizePath,
  topBgPath,
};

export const EventIcons = {
  DailyFruit,
  WeeklyFruit,
  SpecialFruit,
  Slide1,
  Slide2,
  Slide3,
  Replay,
  Lock,
  Special,
  Drink,
  HeaderSignboard,
  TimeBg,
  ButtonLeft,
  ButtonRight,
  Leaves,
  MotivatorBg,
  Box,
  Completed,
  FirstPlace,
  SecondPlace,
  ThirdPlace,
  Next,
};

export const loader = {
  loaderSummertime,
};
