import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useVirtual, VirtualItem } from 'react-virtual';
import { useHotOffersIdentifiers } from '../../../hooks';
import { StoreHotOffersElement } from './store-hot-offers-element';

export const StoreHotOffersList: FC = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { identifiers } = useHotOffersIdentifiers();

  const elements = useVirtual({
    size: identifiers.length,
    parentRef,
    estimateSize: useCallback(() => (document.querySelector('body').offsetWidth - 40) / 2, []),
    overscan: 2,
    horizontal: true,
  });

  return (
    <Container ref={parentRef}>
      <div className="inner-container" style={{ width: elements.totalSize + 32 }}>
        {elements.virtualItems.map(({ index, start, size }: VirtualItem) => (
          <div
            key={index}
            className="item"
            style={{
              transform: `translateX(${start + 16}px)`,
              width: size,
              height: size,
            }}
          >
            <StoreHotOffersElement id={identifiers[index]} />
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: calc(((100vw - 40px) / 2) + 12px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & .inner-container {
    position: relative;

    & .item {
      position: absolute;
      top: 0;
      left: 0;
      padding-right: 0.125rem;
    }
  }
`;
