export default {
  '/settings': 'Ustawienia',
  '/balance': 'Saldo',
  '/marketplace': 'Rynek',
  '/notifications': 'Powiadomienia',
  '/auth': 'Aut',

  RULES: 'ZASADY',
  Chat: 'Czat',
  GAME: 'GRA',
  Top: 'Najlepsi',
  HISTORY: 'HISTORIA',
};
