import React, { FC, MutableRefObject, useCallback } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import { EmptyLayout } from '@UIkit';
import { InventoryElement } from './inventory-element';
import {
  useInventoryIdentifiers,
  useInventoryMetaData,
  useSalesSelectedIdentifiers,
} from '../../../hooks';
import { Container } from './inventory-items.styles';

interface IInventoryItems {
  parentRef: MutableRefObject<HTMLDivElement>;
}

export const InventoryItems: FC<IInventoryItems> = ({ parentRef }) => {
  const { identifiers } = useInventoryIdentifiers();
  const { selected } = useSalesSelectedIdentifiers();
  const { meta, loaded, loading, failure, errorText } = useInventoryMetaData();

  const elements = useVirtual({
    size: loaded ? Math.ceil(meta.count / 2) : 10,
    parentRef,
    estimateSize: useCallback(() => (document.querySelector('body').offsetWidth - 24) / 2, []),
    overscan: 4,
  });

  if (failure) {
    return <EmptyLayout subMessage={errorText} ctx="failure" fieldKey="marketplace" />;
  }

  return (
    <Container
      style={{
        height: selected.identifiers.length ? elements.totalSize + 214 : elements.totalSize,
      }}
    >
      {elements.virtualItems.map(({ index, start, size }: VirtualItem) => (
        <div
          key={index}
          className="row"
          style={{ transform: `translateY(${start}px)`, height: size - 8 || 0 }}
        >
          <InventoryElement key={identifiers?.[index + index]} id={identifiers?.[index + index]} />
          {(!!identifiers?.[index + index + 1] || loading) && (
            <InventoryElement
              key={identifiers?.[index + index + 1]}
              id={identifiers?.[index + index + 1]}
            />
          )}
        </div>
      ))}
    </Container>
  );
};
