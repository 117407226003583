import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { chipDictionary, depositBonus } from '../../../refill.util';
import { useBonusRefillEvent, useTicketsRefillEvent } from '../../../hooks';
import { IPayMethodGates } from '../../../interfaces/reduces.refill.interfaces';
import { useParams } from 'react-router';
import { currencyService } from '../../../../../services/currency';

interface IChipList {
  amount: number;
  gate: number;
  gates: IPayMethodGates;
  handleChangeAmount: (value: number) => void;
}

export const ChipList: FC<IChipList> = ({ amount, gate, gates, handleChangeAmount }) => {
  const { name }: { name?: string } = useParams();
  const { tickets } = useTicketsRefillEvent();
  const { bonus } = useBonusRefillEvent();
  const boost = bonus / 100;

  const minPayment = gates[gate]?.minPayment ?? 0;
  const min = +currencyService.convertBySelectedRateKey(minPayment, 'fastcoins_refill') * 100;

  const handlePayIn = useCallback(
    price => () => {
      handleChangeAmount(price);
    },
    [handleChangeAmount]
  );

  const rate = currencyService.getRate('fastcoins_refill');

  return (
    <Container className={name}>
      {chipDictionary.map(({ price, img }) => {
        const v = price / rate / 100;
        return (
          <ChipItem
            key={price}
            onClick={handlePayIn(price)}
            disabled={v < min}
            className={`${amount === price ? 'active' : ''} chip-${price}`}
          >
            {depositBonus[price] && tickets && 7 - tickets - depositBonus[price] < 0 && (
              <div className="event-boost">
                <span>+{Math.abs(7 - tickets - depositBonus[price])}</span>
                <i className="icon-coupon" />
                <i className="icon-coupon" />
              </div>
            )}
            <img className="chip-thumbnail" src={img} alt="chip" />
            <span className="chip-price">
              ${Number(currencyService.convertBySelectedRateKey(v, 'usd')).toFixed(2)}
            </span>
            {!!boost && (
              <span className="chip-bonuses">
                +<CurrencyText value={price * boost} />
              </span>
            )}
          </ChipItem>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-gap: 0.3125rem;
  grid-auto-rows: 1fr;
  justify-content: center;
  padding: 1.1875rem;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  &.giftcards {
    & .chip-100,
    & .chip-50000,
    & .chip-100000 {
      pointer-events: none;
      opacity: 0.3;
      background-color: var(--color-dark-200);
    }
  }
`;

const ChipItem = styled.button`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--color-dark-200);
  border-radius: var(--size-radius-s);
  border: 0;
  transition: all var(--transition-ease-out);
  position: relative;
  overflow: hidden;

  &:disabled {
    opacity: 0.3;
  }

  &:active {
    background: var(--color-dark-600);
  }

  &.active {
    background: #47555f;
  }

  & .chip-thumbnail {
    width: 3.375rem;
    height: 2.5rem;
    object-fit: contain;
  }

  & .chip-price {
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 0.9375rem;
    letter-spacing: 0.69px;
    color: white;
  }

  & .chip-bonuses {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.3px;
    color: var(--green);
  }

  & .event-boost {
    position: absolute;
    top: -1.25rem;
    right: -1.25rem;

    & span {
      font-weight: bold;
      font-size: 0.8125rem;
      line-height: 0.9375rem;
      letter-spacing: 0.69px;
      color: black;
      position: absolute;
      left: 0.75rem;
      bottom: 0.75rem;
    }

    & .icon-coupon {
      font-size: 3.2rem;
      color: var(--color-orange-ticket);

      &:last-of-type {
        color: rgba(255, 219, 12, 0.3);
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        z-index: 1;
        transform: rotate(-5deg);
      }
    }
  }
`;
