import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { getTypeImageHistory } from '../utils';
import { CurrencyText } from '../../../@components/currency';
import { getUser } from 'core/User';
import { connect } from 'react-redux';
import { getTowerHistory, getTowerTypes } from '../selectors';
import { addNotification } from 'modules/Notifications/duck';
import { getHistory, getTypes } from '../duck';
class HistoryClass extends Component {
  state = {
    modalOpen: false,
    id: 0,
    hash: '',
    salt: 0,
    secret: '',
  };

  componentDidMount() {
    this.props.user && this.props.getHistory();
  }

  render() {
    const { items, t } = this.props;

    if (items.length === 0) {
      return (
        <div className="content">
          <HistoryEmpty>{t('History is empty')}</HistoryEmpty>
        </div>
      );
    }

    return (
      <div className="content">
        <Tower>
          <table border="1" width="100%">
            <thead>
              <TheadTr>
                <TheadTdDate>{t('Date')}</TheadTdDate>
                <TheadTd>{t('Bet')}</TheadTd>
                <TheadTd>{t('Profit')}</TheadTd>
                <TheadTd>{t('Step')}</TheadTd>
              </TheadTr>
            </thead>
            <tbody>
              {items.map(item => {
                const type = this.props.types.find(t => t.id === item.ticket_type_id);
                const typeName = type && type.title;
                const { prize } = item;
                return (
                  <TbodyTr key={item.id}>
                    <TbodyTd>
                      <DateDiv>
                        {' '}
                        <Icon src={getTypeImageHistory(typeName)} />
                        {dayjs(item.created_at).format('DD:MM:YY HH:mm')}
                      </DateDiv>
                    </TbodyTd>
                    <TbodyTd>
                      <CurrencyText value={item.price} />
                    </TbodyTd>
                    <TbodyTd>
                      <Prize theme={{ color: prize ? '#00d330' : '#6c757d' }}>
                        {prize ? '+' : ''}
                        <CurrencyText value={prize} />
                      </Prize>
                    </TbodyTd>
                    <TbodyTd>{item.path.length}</TbodyTd>
                  </TbodyTr>
                );
              })}
            </tbody>
          </table>
        </Tower>
      </div>
    );
  }
}

const Tower = styled.div`
  color: #fff;
  padding-top: 3px;
  background: var(--color-light-dark-layout);
`;

const TheadTr = styled.tr`
  font-weight: 700;
  background: #000;
  text-align: center;
  font-size: 13px;
  color: #a9a7a7;
`;

const TheadTd = styled.td`
  padding: 20px 0px 13px 0px;
`;

const TheadTdDate = styled.td`
  padding: 20px 0px 13px 11px;
  text-align: left;
`;

const TbodyTr = styled.tr`
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #000;
`;

const TbodyTd = styled.td`
  padding: 15px 0px 5px 0px;
`;

const DateDiv = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  margin-top: -6px;
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: 0px 8px 0px 8px;
`;

const HistoryEmpty = styled.div`
  color: #ffff;
  text-align: center;
  font-size: 17px;
  padding: 22px;
`;

const Prize = styled.div`
  color: ${props => props.theme.color};
`;

export const History = connect(
  state => ({
    user: getUser(state),
    types: getTowerTypes(state),
    items: getTowerHistory(state),
  }),
  {
    addNotification,
    getHistory,
    getTypes,
  }
)(withTranslation()(HistoryClass));
