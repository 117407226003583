export default {
  Cart: 'Handlevogn',
  Price: 'Pris',
  Type: 'Type',
  Categories: 'Kategori',
  Rarity: 'Sjeldenhet',
  OK: 'OK',
  Clear: 'Fjern',
  Autoselect: 'Autovelg',
  Remove: 'Remove',
  Bet: 'Bet',
  Min: 'Minst',
  Max: 'Maks',
  'it.': '',
  Total: 'Totalt',
  items: 'skins',
  Pistols: 'Pistoler',
  Rifles: 'Rifler',
  'Submachine guns': 'Maskinpistoler',
  Heavy: 'Tung',
  Knives: 'Kniver',
  Gloves: 'Hansker',
  Keys: 'Nøkler',
  Other: 'Andre',
  'Base Grade': 'Base Grade',
  'Consumer Grade': 'Consumer Grade',
  'Industrial Grade': 'Industrial Grade',
  Restricted: 'Restricted',
  'Mil-Spec Grade': 'Mil-Spec Grade',
  Classified: 'Classified',
  'High Grade': 'High Grade',
  Covert: 'Covert',
  Remarkable: 'Remarkable',
  Exotic: 'Exotic',
  Contraband: 'Contraband',
  Extraordinary: 'Extraordinary',
  Buy: 'Kjøp',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Butikken er bare for uttak av mynter man har vunnet eller ubrukte mynter. Bytting av skins med et mål om å tjene på det på grunn av prisforskjellene i de forskjellige markedene er strengt forbudt. Misbrukere vil ble permanent utestengt.',
  'Your purchase will be delivered within 15 minutes.': 'Ditt skin vil bli levert om 15 minutter',
  'Congratulations! You have successfully purchased': 'Gratulerer! Kjøpet er vellykket',
  'Order confirmation': 'Ordre bekreftelse',
  Filters: 'Filters',
  'By price': 'By price',
  Filter: 'Filter',
  'Not found': 'Ikke funnet',
  'At present it is unable to find any items on your request. Try again later.':
    'Akkurat nå så er det ikke mulig å finne skinnet du spurte om. Vennligst prøv igjen senere.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
