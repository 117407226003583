import { useSelector } from 'react-redux';
import { getWinItemsStatus } from '../ducks';

export const useGetWinItemsStatus = () => {
  const data = useSelector(getWinItemsStatus);
  return {
    winItemsStatus: data.itemsStatus,
    isAllSold: data.isAllSold,
  };
};
