import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import { withTranslation } from 'react-i18next';

import cell from '../../assets/cell.png';
import handCuffsImg from '../../assets/handcuffs.png';
import { currencyService } from '../../../../services/currency';
import { getTypeImage } from '../../utils';

class _FieldPlayItem extends React.PureComponent {
  state = {
    isBehind: false,
    isOpen: false,
  };
  transformPlayItem = new Animated.Value(0);

  static getDerivedStateFromProps(
    { ticket, rowIndex, itemIndex, secret, isActiveRow },
    { isOpen, isBehind }
  ) {
    return {
      isBehind: ticket.path.length >= rowIndex,
      isOpen:
        Number(ticket.path[rowIndex - 1]) === itemIndex ||
        (secret &&
          !isOpen &&
          !isBehind &&
          !isActiveRow &&
          Number(secret[rowIndex - 1][itemIndex]) === 1),
      isLose:
        Number(ticket.path[ticket.position - 1]) === itemIndex &&
        ticket.status === 'failed' &&
        rowIndex === ticket.position,
    };
  }

  componentDidUpdate(prevProps) {
    const prevTicket = prevProps.ticket;
    const { ticket, itemIndex } = this.props;

    if (['failed', 'finished'].includes(prevTicket.status) && ticket.status === 'active') {
      this.transformPlayItem.setValue(0);
    }

    if (Number(ticket.path[this.props.rowIndex - 1]) === itemIndex && prevProps.isActiveRow) {
      Animated.timing(this.transformPlayItem, {
        toValue: 1,
        duration: 300,
      }).start();
    }
  }

  render() {
    const { itemIndex, isActiveRow, ticketType, reward, ticket, t } = this.props;
    const { isOpen, isBehind, isLose } = this.state;
    return (
      <Animated.div
        style={{
          transform: [
            {
              rotateX: this.transformPlayItem.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: ['0deg', '90deg', '0deg'],
              }),
            },
          ],
          display: 'flex',
          flex: 1,
        }}
      >
        <ItemContainer
          disabled={!isActiveRow}
          image={cell}
          isActiveRow={isActiveRow}
          isBehind={isBehind}
          isOpen={isOpen}
          isLose={isLose}
          onClick={this.handleClickStep(itemIndex)}
        >
          <RewardItem>
            {((!isOpen && ticket.status !== 'finished') ||
              (ticket.status === 'finished' && !isOpen && !isLose) ||
              (ticket.status === 'finished' && isActiveRow)) && (
              <FieldPlayText
                isActiveRow={isActiveRow}
                isOpen={isOpen}
                isBehind={isBehind}
                isLose={isLose}
              >
                {this.getRoundingAmount(reward)}
              </FieldPlayText>
            )}
            {((isOpen && ticket.status !== 'finished') ||
              (ticket.status === 'finished' && !isActiveRow && isOpen)) && (
              <ItemIcon src={isLose ? handCuffsImg : getTypeImage(ticketType.title)} alt="Field" />
            )}

            {isLose && <RewardItemText>{t('Try again')}</RewardItemText>}
          </RewardItem>
        </ItemContainer>
      </Animated.div>
    );
  }

  getRoundingAmount = amount => {
    let value = amount;
    if (value > 1000) {
      value = currencyService.convert(value).toString();
      if (value > 1000) {
        return `${(value / 1000).toFixed(0)}K`;
      }
    }
    return currencyService.display(amount);
  };

  handleClickStep = cellIndex => () => {
    const { doStep } = this.props;
    doStep(cellIndex);
  };
}

export const FieldPlayItem = withTranslation()(_FieldPlayItem);

// prettier-ignore
const getBackgroundByState = ({ isActiveRow, isBehind, isOpen, isLose }) => {
  return (!isActiveRow && isBehind ) ||
    (isActiveRow && isBehind && !isOpen) ||
    (!isBehind && isOpen)
    ? '#027c91'
    : (isBehind && isLose)
      ? '#f24628'
      : (isBehind && !isLose) || (isActiveRow && !isBehind && !isOpen)
          ? ' var(--yellow)': '#19d3f9'
}

const ItemContainer = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  padding: 4px;
  border-radius: 5px;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  opacity: ${({ isBehind, isOpen, isLose }) => (isBehind && !isOpen && !isLose ? 0.35 : 0.8)};
  background: ${props => getBackgroundByState(props)};

  background-image: ${({ isActiveRow, isOpen, isBehind, image }) =>
    (isActiveRow && isOpen) || (!isActiveRow && isOpen) || (isActiveRow && !isOpen && !isBehind)
      ? 'none'
      : `url(${image})`};
  background-size: 80%;
  background-repeat: repeat;
  transition-delay: 0.5s;
  transition: background 0.1s linear;
`;

const RewardItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

const FieldPlayText = styled.div`
  padding: 2px 5px;
  border-radius: 14px;

  background-color: ${({ isActiveRow, isOpen, isBehind }) =>
    (isActiveRow && isOpen && isBehind) || (isActiveRow && !isOpen && !isBehind)
      ? '#d6c634'
      : '#027c91'};

  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.6px;
  line-height: 1.4;
  color: ${({ isActiveRow, isOpen, isBehind }) =>
    (isActiveRow && isOpen) || (isActiveRow && !isBehind) ? '#313131' : '#19d3f9'};

  @media (min-width: 350px) {
    font-size: 13px;
  }
`;

const RewardItemText = styled.span`
  font-size: 7px;
  font-weight: 700;
  text-align: center;
  font-style: italic;
  color: var(--color-white);
  max-width: 73%;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media (min-width: 375px) {
    font-size: 9px;
  }
`;

const ItemIcon = styled.img`
  max-width: 18px;
`;
