/* eslint-disable */

export default {
  Error: 'ข้อผิดพลาด',
  'Error placing a bet': 'เกิดข้อผิดพลาดในการวางเดิมพัน',
  'Error reserving an item': 'เกิดข้อผิดพลาดในการจองไอเทม',
  'Link saved successfully': 'ลิงค์ได้ถูกบันทึกแล้ว',
  "You've successfully updated a bet": 'คุณได้อัพเดทการเดิมพันแล้ว',
  "You've successfully sell winning item": 'คุณขายไอเทมการชนะเรียบร้อยแล้ว',
  "You've successfully take winning item": 'คุณได้รับไอเทมการชนะเรียบร้อยแล้ว',
  'Incorrect link. Enter the correct link and try again.':
    'ลิงก์ไม่ถูกต้อง โปรดป้อนลิงก์ที่ถูกต้องและลองอีกครั้ง',
  'This is not your link. Enter your link and try again.':
    'ลิงค์ตัวนี้ไม้ใช่ลิงค์ของคุณ กรุณาป้อนลิงค์ของคุณแล้วลองใหม่',
  "Don't forget to make your inventory public to get your winnings!":
    'อย่าลืมเปิดคลังเพื่อรับรางวัล',
  'min. number of items per trade not exceeded': 'จำนวนไอเทมขั้นต่ำต่อการแลกต้องห้ามเกิน',
  'Too many requests, try again later': 'มีคำขอมากเกินไปโปรดลองใหม่ในภายหลัง',
  'cheating attempt': 'การพยายามที่จะหลอกลวง',
  'max. number of items per trade exceeded': 'มีไอเทมในเทรดมากเกินไป',
  'player not registered on website': 'ผู้เล่นไม่ได้ลงทะเบียนบนเว็บไซต์',
  'souvenir items are not allowed': 'ของที่ระลึกเป็นสิ่งต้องห้าม',
  'some of the items cannot be appraised': 'ไอเทมบางตัวไม่สามารถประเมินราคาได้',
  'some of the items are below minimum price': 'ไอเทมบางตัวราคาต่ำกว่าราคาขั้นต่ำ',
  'total price of a trade is below minimum': 'ราคาเทรดต่ำกว่าราคาขั้นต่ำ',
  'Your trade has been accepted!': 'เทรดของคุุณถูกรับแล้ว',
  'The items have been added to the queue.': 'ไอเทมที่ถูกเพิ่มเข้าไปในคิวแล้ว',
  'Your trade has been checked out!': 'เทรดของคุณผ่านการตรวจสอบแล้ว',
  'It is currently being accepted': 'ในขณะนี้กำลังผ่านขั้นตอนการรับอยู่',
  'Unable to send a trade': 'ไม่สามารถส่งการแลกเปลี่ยนให้คุณได้',
  'Update your trade link in the settings of the site': 'อัปเดตลิงค์เทรดของคุณในการตั้งค่า',
  'Enter correct trade link and make sure your inventory is not full.':
    'ป้อนเทรดลิงค์ที่ถูกต้องและจงตรวจสอบให้แน่ใจว่าคลังของคุณไม่เต็ม',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'บัญชีของคุณไม่สามารถใช้ในการรับเทรดได้ รายละเอียดเพิ่มเติมจะปรากฏหากคุณเปิดการเทรดกับใครสักคน',
  'Steam is unavailable at this moment. Please wait for retry.':
    'ในขณะนี้ Steam ไม่พร้อมใช้งาน โปรดรอเพื่อส่งซ้ำ',
  'Not enough money on balance': 'มีเหรียญไม่พอในบัญชี',
  'Not enough points on balance': 'มีเหรียญไม่พอในบัญชี',
  'Game is ending, try again later': 'เกมไกล้จะจบแล้ว โปรดลองอีกครั้งในภายหลัง',
  'Congratulations!': 'ขอแสดงความยินดีด้วย',
  "You've successfully placed a bet": 'วางเดิมพันสำเร็จแล้ว',
  "You've successfully placed a bet with </amount>":
    'คุณวางเดิมพันด้วยเงินจำนวน {{</amount>}} สำเร็จแล้ว',
  'Item has not been found, coins have been returned':
    'ไม่พบไอเทมดังกล่าวในคลังสิ้นค้า เหรียญได้ถูกคืนให้คุณแล้ว',
  'Your order in the store has been cancelled': 'คำสั่งซื้อของคุณในร้านค้าถูกยกเลิกแล้ว',
  'The min bet is </minBet>': 'เดิมพันต่ำสุด {{</minBet>}}',
  'The max bet is </maxBet>': 'เดิมพันสูงสุด {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'คุณไม่สามารถทำการลงเดิมพันทั้งสีแดงและสีดำพ้อมกันได้',
  "You don't have CSGOFAST.com in Steam nickname!": 'คุณไม่มี CSGOFAST.com ในชื่อเล่น Steam!',
  'You have exceeded the limit of 150 free coins per account':
    'คุณได้เกินขีดจำกัด 150 เหรียญฟรีสำหรับบัญชีเดียวแล้ว',
  "You've bet </amount> on the crash game #</game>!":
    'คุณได้วาง {{</amount>}} crash game #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    'คุณได้อัพเดทค่าสัมประสิทธิ์ {{</coef>}} บน crash game #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'คุณได้ชนะ {{</amount>}} crash game #{{</number>}}!',
  'Code for </amount> has been created': 'สร้างรหัสสำหรับ ${{</ amount>}} เหรียญแล้ว',
  'You already have an item': 'คุณมีไอเทมอยู่แล้ว',
  '</amount> refilled on your balance': '{{</amount>}} ได้รับการเติมเข้าบัญชีของคุณ',
  'We will sent it to your Steam in few moments': 'เราจะส่งเข้า Steam ของคุณในอีกไม่กี่นาที',
  'Your items has been sended to game!': 'ไอเทมของคุณถูกส่งเข้าเกมแล้ว!',
  '</amount> have been returned': '{{</amount>}} ได้รับการคืนกลับไปแล้ว',
  'Item has not been found, retry in few minutes (</amount>)':
    'ยังไม่พบไอเทม โปรดลองอีกครั้งในอีกสักครู่ ({{</ amount>}})',
  'It is currently being processing by game bots': 'ขณะนี้กำลังดำเนินการโดยบอทเกม',
  '</amount> have been refilled': 'ได้รับการเติมเงิน {{</amount>}} เรียบร้อยแล้ว',
  'Request successfully created': 'คำร้องขอได้รับการสร้างสำเร็จ',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'คำสั่ง #{{</id>}} ของคุณสำหรับ {{</amount>}} ได้รับการยอมรับ รอการแลกเปลี่ยนใน Steam',
  'Reason of order rejection': 'เหตุผลสำหรับการปฏิเสธคำสั่ง',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'การวางเดิมพันของคุณสำหรับ {{</amount>}} ได้รับการยอมรับ และจะเพิ่มเข้าเกมใน {{</time>}} วินาที',
  'Your bet was added to queue, in few seconds will added to the game':
    'การวางเดิมพันของคุณได้รับการเพิ่มเข้าไปในคิวแล้ว อีกไม่กี่วินาทีก็จะเพิ่มเข้าไปในเกม',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE: 'การถอนเงินถูกล็อก ชนะอีก ${currency(amount)} เพื่อปลดล็อก',
    },
  },
};
