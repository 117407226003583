import React from 'react';
import { Container, Icon, NameSpan, SkinSpan } from './Header.styles';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface HeaderProps {
  img: string;
  shortName: string;
  skin: string;
  name?: string;
}

export const Header: React.FC<HeaderProps> = ({ img, shortName, skin, name }) => {
  const newName = name ? name.split('|').join('')[0] : '';
  return (
    <Container>
      <Icon src={skinItemImage(img)} />
      <SkinSpan>{skin}</SkinSpan>
      <NameSpan>{shortName ? shortName : newName}</NameSpan>
    </Container>
  );
};
