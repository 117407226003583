import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';

export const AssistanceNavigator: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation();

  const openChat = () => {
    (window as any).Chatra('openChat', true);
  };

  return (
    <>
      <NavigationLink to={`${match.url}/faq`} as={Link}>
        <div>
          <span>{t('FAQ')}</span>
        </div>
      </NavigationLink>
      <NavigationLink as="a" href="mailto:support@csgofast.com" target="_blank">
        <div>
          <span>{t('Email Support')}</span>
        </div>
      </NavigationLink>
      <NavigationLink onClick={openChat} className="custom-chat-button">
        <div>
          <span>{t('Live Support Chat')}</span>
        </div>
      </NavigationLink>
      <NavigationLink to={'./privacy-policy'} as={Link}>
        <div>
          <span>{t('Privacy policy')}</span>
        </div>
      </NavigationLink>
      <NavigationLink to={'./tos'} as={Link}>
        <div>
          <span>{t('Terms and conditions')}</span>
        </div>
      </NavigationLink>
    </>
  );
};
