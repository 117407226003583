import { DIALOG } from 'dialogs/dialog-combiner';
import { SoundsSettings } from './sounds-settings-dialog';
import { SoundsSettingsDialogConfig } from 'games/CaseGame';
import { transitions } from 'dialogs/transition.config';

DIALOG.add(SoundsSettingsDialogConfig.SOUNDS_DIALOG, {
  component: SoundsSettings,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
