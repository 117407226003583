import fast_bet_first from '../../sound/fast/fast_bet_first.mp3';
import fast_bet_second from '../../sound/fast/fast_bet_second.mp3';
import fast_bet_third from '../../sound/fast/fast_bet_third.mp3';
import fast_roulette from '../../sound/fast/fast_roulette.mp3';
import fast_roulette_end from '../../sound/fast/fast_roulette_end.wav';

import double_bet_first from '../../sound/double/double_bet_first.mp3';
import double_roulette from '../../sound/double/double_roulette.wav';

import craft_start_first from '../../sound/craft/craft_start_first.mp3';
import craft_start_second from '../../sound/craft/craft_start_second.mp3';
import craft_start_third from '../../sound/craft/craft_start_third.mp3';
import craft_failure_first from '../../sound/craft/craft_failure_first.mp3';
import craft_failure_second from '../../sound/craft/craft_failure_second.mp3';
import craft_success_first from '../../sound/craft/craft_success_first.mp3';
import craft_success_second from '../../sound/craft/craft_success_second.mp3';
import craft_item_set from '../../sound/craft/craft_item_set.mp3';
import craft_keep_item_first from '../../sound/craft/craft_keep_item_first.mp3';
import craft_keep_item_second from '../../sound/craft/craft_keep_item_second.mp3';
import craft_loading from '../../sound/craft/craft_loading.mp3';
import craft_sold_item_first from '../../sound/craft/craft_sold_item_first.mp3';
import craft_sold_item_second from '../../sound/craft/craft_sold_item_second.mp3';

import complete_shooting_gallery_1 from '../../modules/event-module/event-patrick/assets/sounds/complete_shooting_gallery_1.mp3';
import move_from_shooting_to_shooting_1 from '../../modules/event-module/event-patrick/assets/sounds/move_from_shooting_to_shooting_1.mp3';
import bursting_ball_1 from '../../modules/event-module/event-patrick/assets/sounds/first/bursting_ball_1.mp3';
import slingshot_cocking_1 from '../../modules/event-module/event-patrick/assets/sounds/first/slingshot_cocking_1.mp3';
import cocking_1 from '../../modules/event-module/event-patrick/assets/sounds/second/cocking_1.mp3';
import shot_1 from '../../modules/event-module/event-patrick/assets/sounds/second/shot_1.mp3';
import sniper_rifle_1 from '../../modules/event-module/event-patrick/assets/sounds/third/sniper_rifle_1.mp3';

export const soundMap = {
  fast: {
    bet_first: fast_bet_first,
    bet_second: fast_bet_second.mp3,
    bet_third: fast_bet_third,
    roulette: fast_roulette.mp3,
    end: fast_roulette_end,
  },
  baccarat: {
    game_start: 'https://d2lomvz2jrw9ac.cloudfront.net/audio/baccarat/baccarat-game-start.mp3',
  },
  double: {
    bet_first: double_bet_first,
    roulette: double_roulette,
  },
  craft: {
    start_first: craft_start_first,
    start_second: craft_start_second,
    start_third: craft_start_third,
    failure_first: craft_failure_first,
    failure_second: craft_failure_second,
    success_first: craft_success_first,
    success_second: craft_success_second,
    item_set: craft_item_set,
    keep_item_first: craft_keep_item_first,
    keep_item_second: craft_keep_item_second,
    loading: craft_loading,
    sold_item_first: craft_sold_item_first,
    sold_item_second: craft_sold_item_second,
  },
  x50: {
    bet_first: double_bet_first,
    roulette: double_roulette,
  },
  patrick: {
    complete_shooting_gallery_1,
    move_from_shooting_to_shooting_1,
    bursting_ball_1,
    slingshot_cocking_1,
    cocking_1,
    shot_1,
    sniper_rifle_1,
  },
};
