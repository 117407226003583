export default {
  GAME: 'SPEL',
  Winner: 'Vinnare',
  Won: 'Vinst',
  Chance: 'Chans',
  Prize: 'Pris',
  'At stake': 'På spel',
  'Sign in through STEAM to participate': 'Logga in genom STEAM för att delta',
  'Steam trade link': 'Steam-byteslänk',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Var först med att sätta in',
  'Place a bet': 'Placera en insats',
  'Deposit more': 'Sätt in mer',
  'Min bet': 'Minsta insats',
  'Max bet': 'HÖGSTA MÖJLIGA INSATS',
  'Bet amount': 'Insatssumma',
  'max. items': 'maximalt antal föremål',
  hidden: 'dold',
  Copy: 'Kopiera',
  'Winning after commission': 'Vinst efter provision',
  Loading: 'Läser in',
};
