import { shallowEqual, useSelector } from 'react-redux';
import { getCasesSoundType } from '../ducks';
import { getRandomNumber } from '../utils';

const randomType = () => ['danger', 'approach', 'standard', 'jingle'][getRandomNumber(3)];

export const useGetSoundTypeForRoulettes = () => {
  const soundType = useSelector(getCasesSoundType, shallowEqual);
  if (soundType === 'random') {
    return randomType();
  } else {
    return soundType;
  }
};
