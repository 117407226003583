import * as React from 'react';

class RulerSvg extends React.PureComponent {
  render() {
    const { coefAutoStop, rulerCoef } = this.props;
    return (
      <React.Fragment>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0"
          y="0"
          width="225"
          height="82"
          viewBox="0 0 210 70"
          xmlSpace="preserve"
        >
          {!Math.floor(coefAutoStop - rulerCoef) && (
            <defs>
              <clipPath id={`coefId${rulerCoef}`}>
                <rect x="0" y="0" width={this.calcClipPathSize()} height="64" />
              </clipPath>
            </defs>
          )}
          <g>
            <path
              clipPath={`url(#coefId${rulerCoef})`}
              fill="#DFD042"
              d="M6-588c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0
			c-1.2,0-2.3,0-3.5,0l0,2c2,0,4-0.1,6-0.1V-588L6-588L6-588z M-6-587.8c-1,0-2,0.1-3,0.1l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.8,0-1.5,0.1-2.3,0.1
			l0.1,2c2-0.1,4-0.2,6-0.3L-6-587.8L-6-587.8z M-18-587.1c-0.6,0-1.3,0.1-1.9,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0c-0.1,0-0.1,0-0.2,0l0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0l0,0c-0.1,0-0.2,0-0.3,0l0,0c0,0-0.1,0-0.1,0l0,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0l0,0l0.2,2c2-0.2,4-0.3,5.9-0.5L-18-587.1L-18-587.1z M-29.9-586
			c-1.1,0.1-2.2,0.3-3.3,0.4l0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c-0.7,0.1-1.3,0.2-2,0.3l0.3,2c2-0.3,4-0.5,5.9-0.7L-29.9-586L-29.9-586z M-41.8-584.5c-2,0.3-4,0.6-5.9,0.9l0.3,2
			c2-0.3,3.9-0.6,5.9-0.9L-41.8-584.5L-41.8-584.5z M-53.7-582.5c-0.4,0.1-0.8,0.1-1.2,0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0.1
			c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0.1c0,0,0,0,0,0c-0.9,0.2-1.9,0.4-2.8,0.6l0.4,2c1.9-0.4,3.9-0.8,5.9-1.1L-53.7-582.5L-53.7-582.5z
			 M-65.4-580.1C-65.4-580.1-65.5-580.1-65.4-580.1c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-1.8,0.4-3.6,0.8-5.3,1.3l0.5,1.9c1.9-0.5,3.9-0.9,5.8-1.4L-65.4-580.1L-65.4-580.1z
			 M-77.1-577.3c-1.8,0.5-3.6,1-5.4,1.5c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0l0.5,1.9c1.9-0.5,3.8-1.1,5.8-1.6L-77.1-577.3L-77.1-577.3z M-88.6-574c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-1.6,0.5-3.2,1-4.8,1.5c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c-0.1,0-0.2,0.1-0.4,0.1l0.6,1.9c1.9-0.6,3.8-1.2,5.7-1.8L-88.6-574L-88.6-574z M-100-570.3C-100.1-570.3-100.1-570.3-100-570.3
			c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0l0,0c0,0-0.1,0-0.1,0.1
			c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1l0,0c-0.8,0.3-1.6,0.6-2.5,0.9l0.7,1.9c1.9-0.7,3.7-1.4,5.6-2
			L-100-570.3L-100-570.3z M-111.3-566.2c-1.9,0.7-3.7,1.5-5.6,2.2l0.8,1.9c1.8-0.7,3.7-1.5,5.5-2.2L-111.3-566.2L-111.3-566.2z
			 M-122.4-561.6c-1.8,0.8-3.7,1.6-5.5,2.4l0.8,1.8c1.8-0.8,3.6-1.6,5.5-2.4L-122.4-561.6L-122.4-561.6z M-133.3-556.7
			c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.6,0.3-1.2,0.6-1.8,0.8l0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-1,0.5-2,1-3,1.5l0.9,1.8c1.8-0.9,3.6-1.8,5.4-2.6
			L-133.3-556.7L-133.3-556.7z M-144.1-551.3c-1.8,0.9-3.5,1.9-5.3,2.8l1,1.8c1.7-0.9,3.5-1.9,5.3-2.8L-144.1-551.3L-144.1-551.3z
			 M-154.6-545.6c-1.7,1-3.4,1.9-5.1,2.9c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0l0,0l1,1.7c1.7-1,3.4-2,5.2-3L-154.6-545.6
			L-154.6-545.6z M-164.9-539.5C-165-539.4-165-539.4-164.9-539.5c-0.1,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0-0.1,0l0,0c0,0,0,0-0.1,0l0,0c-0.1,0-0.2,0.1-0.2,0.1l0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c-1.3,0.8-2.5,1.6-3.8,2.4l1.1,1.7c1.7-1.1,3.4-2.1,5-3.2L-164.9-539.5L-164.9-539.5z M-175-533c-1.7,1.1-3.3,2.2-4.9,3.4l1.1,1.6
			c1.6-1.1,3.3-2.3,4.9-3.4L-175-533L-175-533z M-184.9-526.1c-1.4,1-2.7,2-4.1,3c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.6,0.4l1.2,1.6c1.6-1.2,3.2-2.4,4.8-3.5L-184.9-526.1L-184.9-526.1z M-194.4-518.9
			c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0c-1,0.8-2,1.6-2.9,2.3c0,0,0,0,0,0
			c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1l0,0l1.3,1.6
			c1.5-1.2,3.1-2.5,4.7-3.7L-194.4-518.9L-194.4-518.9z M-203.7-511.3C-203.8-511.2-203.8-511.2-203.7-511.3
			C-203.8-511.2-203.8-511.2-203.7-511.3c-0.8,0.7-1.5,1.3-2.2,1.9c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.4,0.4-0.8,0.7-1.3,1.1l1.3,1.5c1.5-1.3,3-2.6,4.5-3.9L-203.7-511.3
			L-203.7-511.3z M-212.8-503.4C-212.8-503.4-212.8-503.4-212.8-503.4c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1
			c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1l0,0c-0.1,0.1-0.3,0.3-0.4,0.4l1.4,1.5
			c1.4-1.4,2.9-2.7,4.4-4.1L-212.8-503.4L-212.8-503.4z M-221.5-495.1c-0.4,0.3-0.7,0.7-1.1,1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1
			c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1
			c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1l0,0c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.3l0,0c-0.1,0.1-0.3,0.3-0.4,0.4l1.4,1.4c1.4-1.4,2.8-2.8,4.2-4.2L-221.5-495.1L-221.5-495.1z
			 M-229.9-486.6c-1.4,1.5-2.7,2.9-4.1,4.4l1.5,1.3c1.3-1.5,2.7-2.9,4.1-4.4L-229.9-486.6L-229.9-486.6z M-238-477.7
			c-0.3,0.4-0.7,0.8-1,1.2c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.3
			c0,0,0,0,0,0c-0.7,0.8-1.3,1.5-1.9,2.3l1.5,1.3c1.3-1.5,2.6-3,3.9-4.5L-238-477.7L-238-477.7z M-245.7-468.6
			c-1.3,1.6-2.5,3.1-3.8,4.7l1.6,1.2c1.2-1.6,2.5-3.1,3.7-4.7L-245.7-468.6L-245.7-468.6z M-253.2-459.1c-0.5,0.6-1,1.3-1.5,1.9l0,0
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.7,0.9-1.3,1.8-2,2.6l1.6,1.2c1.2-1.6,2.4-3.2,3.6-4.8
			L-253.2-459.1L-253.2-459.1z M-260.2-449.4c-1.2,1.6-2.3,3.3-3.4,4.9l1.7,1.1c1.1-1.6,2.2-3.3,3.4-4.9L-260.2-449.4L-260.2-449.4z
			 M-267-439.5c-1.1,1.7-2.2,3.4-3.2,5.1l1.7,1.1c1-1.7,2.1-3.4,3.2-5L-267-439.5L-267-439.5z M-273.3-429.3
			c-0.2,0.4-0.5,0.8-0.7,1.2l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1
			c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.3,0.6-0.7,1.1-1,1.7c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0
			c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0
			c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1l1.7,1c1-1.7,2-3.4,3-5.1L-273.3-429.3L-273.3-429.3z M-279.2-418.9
			c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0c-0.7,1.2-1.3,2.4-2,3.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0.3-0.3,0.6-0.4,0.8l1.8,0.9
			c0.9-1.8,1.9-3.5,2.8-5.3L-279.2-418.9L-279.2-418.9z M-284.8-408.3c-0.7,1.4-1.4,2.9-2.1,4.3l0,0c0,0.1-0.1,0.2-0.1,0.2l0,0
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1l1.8,0.9c0.9-1.8,1.7-3.6,2.6-5.4L-284.8-408.3
			L-284.8-408.3z M-290-397.4c-0.7,1.5-1.3,3-2,4.4c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c-0.1,0.2-0.2,0.5-0.3,0.7l1.8,0.8c0.8-1.8,1.6-3.6,2.4-5.5L-290-397.4L-290-397.4z M-294.8-386.4
			C-294.8-386.4-294.8-386.3-294.8-386.4c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0
			c-0.1,0.2-0.2,0.5-0.3,0.7c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			l0,0c-0.4,1-0.8,2.1-1.2,3.1l1.9,0.7c0.7-1.8,1.5-3.7,2.2-5.5L-294.8-386.4L-294.8-386.4z M-299.1-375.2c-0.7,1.9-1.4,3.8-2,5.7
			l1.9,0.7c0.6-1.9,1.3-3.8,2-5.6L-299.1-375.2L-299.1-375.2z M-303-363.9c-0.5,1.5-1,3.1-1.5,4.6c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1l0,0c0,0.1-0.1,0.2-0.1,0.4l1.9,0.6c0.6-1.9,1.2-3.8,1.8-5.7L-303-363.9L-303-363.9z M-306.5-352.4
			c-0.6,1.9-1.1,3.8-1.6,5.8l1.9,0.5c0.5-1.9,1-3.8,1.6-5.7L-306.5-352.4L-306.5-352.4z M-309.6-340.8c0,0.1-0.1,0.3-0.1,0.4
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.2l0,0c-0.2,1-0.5,1.9-0.7,2.9c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0.5-0.2,0.9-0.3,1.4l2,0.4c0.4-1.9,0.9-3.9,1.4-5.8L-309.6-340.8L-309.6-340.8z M-312.3-329.1
			c-0.3,1.3-0.5,2.7-0.8,4c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3l0,0c0,0,0,0.1,0,0.1l2,0.4c0.4-1.9,0.8-3.9,1.2-5.8L-312.3-329.1L-312.3-329.1z
			 M-314.5-317.3c-0.1,0.5-0.2,1-0.2,1.5l0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.4c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.4c0,0,0,0,0,0
			c0,0.1,0,0.2-0.1,0.4c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1l0,0
			c0,0.1,0,0.2,0,0.3l2,0.3c0.3-2,0.6-3.9,0.9-5.9L-314.5-317.3L-314.5-317.3z M-316.3-305.5c0,0.2-0.1,0.5-0.1,0.7c0,0,0,0,0,0
			c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.2,1.6-0.4,3.2-0.6,4.9l2,0.2c0.2-2,0.5-4,0.7-5.9
			L-316.3-305.5L-316.3-305.5z M-317.6-293.5c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1l0,0c0,0.1,0,0.2,0,0.3
			l0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c-0.1,1.1-0.2,2.3-0.3,3.4l2,0.1c0.1-2,0.3-4,0.5-5.9L-317.6-293.5L-317.6-293.5z M-318.5-281.6c0,0.2,0,0.4,0,0.7
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c-0.1,1.7-0.2,3.4-0.2,5.1l2,0.1c0.1-2,0.2-4,0.3-6L-318.5-281.6
			L-318.5-281.6z M-318.9-269.6c0,1.6-0.1,3.2-0.1,4.7c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.2,0,0.4,0,0.5l2,0c0-2,0-4,0.1-6L-318.9-269.6
			L-318.9-269.6z M-317-257.6l-2,0c0,0.7,0,1.3,0,2l0,0c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3l0,0c0,0.2,0,0.5,0,0.7l2-0.1
			C-316.9-253.6-316.9-255.6-317-257.6L-317-257.6z M-316.5-245.7l-2,0.1c0,0.2,0,0.4,0,0.6l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,1.5,0.2,2.9,0.3,4.4c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2l2-0.1C-316.3-241.7-316.4-243.7-316.5-245.7L-316.5-245.7z M-315.7-233.8l-2,0.2
			c0.2,2,0.4,4,0.6,6l2-0.2C-315.3-229.8-315.5-231.8-315.7-233.8L-315.7-233.8z M-314.4-221.9l-2,0.3c0.3,2,0.5,4,0.8,5.9l2-0.3
			C-313.9-218-314.2-220-314.4-221.9L-314.4-221.9z M-312.7-210.1l-2,0.3c0.2,1,0.3,2.1,0.5,3.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0.4,0.2,0.8,0.2,1.3c0,0,0,0,0,0
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1l2-0.4C-312-206.2-312.4-208.2-312.7-210.1
			L-312.7-210.1z M-310.5-198.4l-2,0.4c0.4,2,0.8,3.9,1.2,5.9l2-0.4C-309.7-194.5-310.1-196.5-310.5-198.4L-310.5-198.4z
			 M-307.9-186.8l-1.9,0.5c0.1,0.6,0.3,1.1,0.4,1.7c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0
			c0.3,1.3,0.6,2.5,1,3.8l1.9-0.5C-307-182.9-307.5-184.8-307.9-186.8L-307.9-186.8z M-304.9-175.2l-1.9,0.5
			c0.4,1.3,0.7,2.6,1.1,3.9c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0.2,0.6,0.3,1.1,0.5,1.7l1.9-0.6
			C-303.8-171.4-304.4-173.3-304.9-175.2L-304.9-175.2z M-301.5-163.8l-1.9,0.6c0.4,1.1,0.7,2.2,1.1,3.3l0,0c0,0.1,0.1,0.2,0.1,0.2
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0l1.9-0.6C-300.2-160-300.9-161.9-301.5-163.8
			L-301.5-163.8z M-297.6-152.5l-1.9,0.7c0.3,0.9,0.6,1.8,1,2.6c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.2,0.6,0.5,1.3,0.7,1.9l1.9-0.7
			C-296.2-148.8-296.9-150.7-297.6-152.5L-297.6-152.5z M-293.3-141.4l-1.9,0.8c0.2,0.5,0.4,1,0.6,1.5c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3l0,0c0.2,0.5,0.4,0.9,0.6,1.4l0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0.4,0.3,0.7,0.4,1l1.8-0.8
			C-291.8-137.7-292.6-139.6-293.3-141.4L-293.3-141.4z M-288.6-130.4l-1.8,0.8c0.8,1.8,1.7,3.6,2.5,5.4l1.8-0.9
			C-287-126.8-287.8-128.6-288.6-130.4L-288.6-130.4z M-283.5-119.7l-1.8,0.9c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
			c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
			c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.5,1,1,2,1.5,3l1.8-0.9C-281.7-116.1-282.7-117.9-283.5-119.7L-283.5-119.7z M-278-109.1l-1.8,1
			c0.2,0.4,0.4,0.7,0.6,1.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.6,1.2,1.3,2.3,1.9,3.5l1.7-1
			C-276.1-105.6-277.1-107.3-278-109.1L-278-109.1z M-272.1-98.7l-1.7,1c0.9,1.6,1.9,3.1,2.8,4.7c0,0,0,0,0,0c0,0,0,0.1,0,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.3l1.7-1.1C-270.1-95.3-271.1-97-272.1-98.7L-272.1-98.7z M-265.9-88.6l-1.7,1.1
			c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
			c0.4,0.6,0.7,1.1,1.1,1.7l1.7-1.1C-263.7-85.2-264.8-86.9-265.9-88.6L-265.9-88.6z M-259.3-78.6l-1.6,1.1c1.1,1.6,2.3,3.3,3.5,4.9
			l1.6-1.2C-257-75.4-258.1-77-259.3-78.6L-259.3-78.6z M-252.3-69l-1.6,1.2c0.3,0.3,0.5,0.7,0.8,1c0,0,0,0,0,0c0,0,0,0,0,0.1
			c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1
			c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
			c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.5,0.6,0.9,1.2,1.4,1.8l1.6-1.2C-249.8-65.8-251.1-67.4-252.3-69L-252.3-69z M-244.9-59.6
			l-1.6,1.3c1.3,1.6,2.5,3.1,3.8,4.6l1.5-1.3C-242.4-56.5-243.7-58-244.9-59.6L-244.9-59.6z M-237.2-50.5l-1.5,1.3l0,0
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0.1,0.1,0.1
			c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1
			c0,0,0,0,0.1,0.1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
			c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0,0c0.3,0.4,0.7,0.7,1,1.1l1.5-1.3C-234.6-47.5-235.9-49-237.2-50.5L-237.2-50.5z
			 M-229.2-41.6l-1.5,1.4c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1l0,0c0.6,0.7,1.3,1.3,1.9,2c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2l1.4-1.4
			C-226.5-38.7-227.9-40.2-229.2-41.6L-229.2-41.6z M-220.9-33.1l-1.4,1.4c1.4,1.4,2.9,2.8,4.3,4.2l1.4-1.4
			C-218-30.3-219.5-31.7-220.9-33.1L-220.9-33.1z M-212.2-24.9l-1.4,1.5c0.6,0.6,1.3,1.2,1.9,1.8c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
			l0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1
			c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1l0,0c0.2,0.2,0.4,0.3,0.6,0.5l1.3-1.5
			C-209.3-22.2-210.8-23.5-212.2-24.9L-212.2-24.9z M-203.3-17l-1.3,1.5c1.5,1.3,3.1,2.6,4.6,3.8l1.3-1.5
			C-200.3-14.4-201.8-15.7-203.3-17L-203.3-17z M-194.1-9.4l-1.2,1.6c0.7,0.5,1.3,1.1,2,1.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
			c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
			c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0
			c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1
			c0,0,0,0,0,0c0.3,0.2,0.5,0.4,0.8,0.6l1.2-1.6C-190.9-7-192.5-8.2-194.1-9.4L-194.1-9.4z M-184.6-2.2l-1.2,1.6
			c1.2,0.8,2.3,1.7,3.5,2.5c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.2,0.2,0.5,0.3,0.7,0.5l1.2-1.6C-181.4,0.1-183-1-184.6-2.2L-184.6-2.2z
			 M-174.8,4.7l-1.1,1.7c1.2,0.8,2.5,1.7,3.7,2.5c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0,0,0,0,0,0c0.3,0.2,0.6,0.4,0.9,0.6l1.1-1.7C-171.5,6.9-173.2,5.8-174.8,4.7L-174.8,4.7z M-164.8,11.2l-1.1,1.7
			c1.7,1.1,3.4,2.1,5.1,3.1l1-1.7C-161.5,13.3-163.2,12.2-164.8,11.2L-164.8,11.2z M-154.6,17.3l-1,1.7c0.5,0.3,0.9,0.5,1.4,0.8l0,0
			c0.2,0.1,0.4,0.2,0.6,0.4c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.1l0,0c0,0,0.1,0,0.1,0l0,0
			c0.1,0,0.2,0.1,0.2,0.1l0,0c0,0,0,0,0.1,0l0,0c0.1,0.1,0.2,0.1,0.3,0.2l0,0c0.1,0.1,0.2,0.1,0.3,0.2l0,0c0.6,0.3,1.1,0.6,1.7,1
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0l1-1.8C-151.1,19.3-152.9,18.3-154.6,17.3L-154.6,17.3z M-144.2,23l-0.9,1.8
			c0.4,0.2,0.9,0.4,1.3,0.7c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
			c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.7,0.4,1.5,0.7,2.2,1.1l0.9-1.8C-140.6,24.9-142.4,24-144.2,23L-144.2,23z M-133.5,28.4l-0.9,1.8
			c0.6,0.3,1.2,0.6,1.8,0.9c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
			c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c1,0.5,2.1,1,3.1,1.5l0.8-1.8C-129.9,30.1-131.7,29.3-133.5,28.4L-133.5,28.4z
			 M-122.7,33.4l-0.8,1.8c0.8,0.3,1.5,0.7,2.3,1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c1,0.4,1.9,0.8,2.9,1.2l0.8-1.8C-119,34.9-120.8,34.1-122.7,33.4L-122.7,33.4z M-111.6,37.9l-0.7,1.9
			c1.6,0.6,3.2,1.3,4.9,1.9c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.2,0.1,0.3,0.1,0.5,0.2l0.7-1.9
			C-107.9,39.3-109.8,38.6-111.6,37.9L-111.6,37.9z M-100.5,42l-0.7,1.9l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c1.8,0.6,3.6,1.2,5.4,1.8l0.6-1.9C-96.7,43.3-98.6,42.7-100.5,42L-100.5,42z M-89.1,45.8l-0.6,1.9
			c1.9,0.6,3.8,1.2,5.8,1.7l0.6-1.9C-85.3,46.9-87.2,46.3-89.1,45.8L-89.1,45.8z M-77.7,49.1l-0.5,1.9c0.7,0.2,1.4,0.4,2.2,0.6
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0l0,0c0.1,0,0.1,0,0.2,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0.8,0.2,1.6,0.4,2.5,0.6l0.5-1.9C-73.8,50.1-75.8,49.6-77.7,49.1L-77.7,49.1z M-66.1,51.9l-0.4,2c0.3,0.1,0.6,0.1,0.8,0.2
			c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0l0,0c1.6,0.4,3.2,0.7,4.8,1l0.4-2C-62.2,52.8-64.2,52.4-66.1,51.9L-66.1,51.9z
			 M-54.4,54.4l-0.4,2c0.5,0.1,1,0.2,1.5,0.3c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0,0,0,0,0.1,0c0,0,0,0,0,0c1.3,0.2,2.6,0.5,4,0.7l0.3-2C-50.5,55.1-52.5,54.7-54.4,54.4L-54.4,54.4z M-42.7,56.4l-0.3,2
			c2,0.3,4,0.6,5.9,0.8l0.3-2C-38.7,56.9-40.7,56.7-42.7,56.4L-42.7,56.4z M-30.8,57.9l-0.2,2c0.7,0.1,1.5,0.2,2.2,0.2l0,0
			c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.8,0.1,1.5,0.2,2.3,0.2l0.2-2C-26.9,58.4-28.9,58.1-30.8,57.9L-30.8,57.9z M-19,59l-0.2,2
			c0.8,0.1,1.7,0.1,2.6,0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0l0,0c0.9,0.1,1.8,0.1,2.7,0.2l0.1-2C-15,59.3-17,59.2-19,59L-19,59z
			 M-7.1,59.7l-0.1,2c1.6,0.1,3.1,0.1,4.7,0.2l0,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0.3,0,0.6,0,0.9,0l0-2C-3.1,59.9-5.1,59.8-7.1,59.7L-7.1,59.7z M10.8,60c-1.6,0-3.2,0-4.8,0l-1.1,0l0,2c0.1,0,0.2,0,0.3,0
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
			c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.4,0,0.8,0,1.2,0L10.8,60L10.8,60z M22.8,59.6
			c-2,0.1-4,0.2-6,0.2l0.1,2c0.2,0,0.5,0,0.7,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c1.5-0.1,3-0.1,4.5-0.2L22.8,59.6L22.8,59.6z M34.7,58.7
			c-2,0.2-4,0.3-5.9,0.5l0.1,2c0.8-0.1,1.5-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
			c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.6-0.1,1.3-0.1,1.9-0.2
			L34.7,58.7L34.7,58.7z M46.5,57.5c-2,0.2-4,0.5-5.9,0.7l0.2,2c2-0.2,4-0.4,6-0.7L46.5,57.5L46.5,57.5z M58.3,55.8
			c-2,0.3-3.9,0.6-5.9,0.9l0.3,2c0.5-0.1,1-0.1,1.5-0.2c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c1.2-0.2,2.4-0.4,3.6-0.6L58.3,55.8L58.3,55.8z M70,53.7c-1.9,0.4-3.9,0.8-5.9,1.1l0.4,2c0.8-0.1,1.6-0.3,2.4-0.4c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c1.1-0.2,2.1-0.4,3.2-0.6L70,53.7L70,53.7z M81.7,51.1
			c-1.9,0.5-3.9,0.9-5.8,1.3l0.4,2c1.8-0.4,3.7-0.8,5.5-1.3l0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0L81.7,51.1L81.7,51.1z M93.2,48.1
			c-1.9,0.5-3.8,1.1-5.8,1.6l0.5,1.9c1.9-0.5,3.9-1,5.8-1.6L93.2,48.1L93.2,48.1z M104.7,44.7c-1.9,0.6-3.8,1.2-5.7,1.8l0.6,1.9
			c1.9-0.6,3.8-1.2,5.7-1.8L104.7,44.7L104.7,44.7z M115.9,40.8c-1.9,0.7-3.8,1.3-5.6,2l0.6,1.9c0.6-0.2,1.2-0.4,1.8-0.6l0,0
			c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c1.1-0.4,2.2-0.8,3.3-1.2L115.9,40.8L115.9,40.8z M127.1,36.5c-1.8,0.7-3.7,1.5-5.5,2.2l0.7,1.9
			c1.9-0.7,3.7-1.4,5.6-2.2L127.1,36.5L127.1,36.5z M138.1,31.9c-1.8,0.8-3.6,1.6-5.5,2.4l0.8,1.8c1.8-0.8,3.7-1.6,5.5-2.4
			L138.1,31.9L138.1,31.9z M148.8,26.8c-1.8,0.9-3.6,1.7-5.4,2.6l0.9,1.8c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0l0,0c0.6-0.3,1.1-0.5,1.7-0.8c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1
			c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0l0,0
			c0.2-0.1,0.4-0.2,0.7-0.3L148.8,26.8L148.8,26.8z M159.4,21.3c-1.7,0.9-3.5,1.9-5.3,2.8l0.9,1.8c1.7-0.9,3.4-1.8,5.1-2.7l0,0
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0L159.4,21.3L159.4,21.3z M169.8,15.4c-1.7,1-3.4,2-5.2,3l1,1.7c0.5-0.3,1-0.6,1.5-0.8
			c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1l0,0c0,0,0,0,0.1,0l0,0c1.1-0.6,2.2-1.3,3.3-1.9
			L169.8,15.4L169.8,15.4z M180,9.2c-1.7,1.1-3.4,2.1-5.1,3.2l1,1.7c1.1-0.6,2.1-1.3,3.1-2c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.2
			c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.5-0.3,1-0.6,1.5-1L180,9.2L180,9.2z M189.9,2.6c-1.6,1.1-3.3,2.3-4.9,3.4l1.1,1.7
			c0.5-0.3,1-0.7,1.5-1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4-0.3,0.9-0.6,1.3-0.9l0,0c0.1-0.1,0.1-0.1,0.2-0.2
			c0,0,0,0,0,0c0,0,0,0,0.1,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0
			c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1L189.9,2.6L189.9,2.6z M199.6-4.4
			c-1.6,1.2-3.2,2.4-4.8,3.5l1.2,1.6c0.2-0.1,0.4-0.3,0.6-0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0.5-0.4,1.1-0.8,1.6-1.2l0,0
			c0.1-0.1,0.1-0.1,0.2-0.2l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
			c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.5-0.3,0.9-0.7,1.4-1L199.6-4.4L199.6-4.4z M209-11.7c-1.5,1.2-3.1,2.5-4.7,3.7l1.2,1.6
			c0.3-0.2,0.5-0.4,0.8-0.6c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1,0
			c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1
			c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
			c0.1-0.1,0.2-0.1,0.3-0.2l0,0c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2l0,0c0.5-0.4,1.1-0.9,1.6-1.3L209-11.7
			L209-11.7z M218.1-19.4c-1.5,1.3-3,2.6-4.5,3.9l1.3,1.5c0.5-0.4,1.1-0.9,1.6-1.3l0,0c0,0,0.1-0.1,0.1-0.1l0,0c0,0,0.1-0.1,0.1-0.1
			l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2
			c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0,0,0,0c0.5-0.4,0.9-0.8,1.4-1.2L218.1-19.4
			L218.1-19.4z M227-27.4c-1.4,1.4-2.9,2.7-4.4,4l1.3,1.5l0,0c0.8-0.7,1.6-1.4,2.3-2.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1l0,0
			c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1
			c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0,0,0,0c0.3-0.3,0.7-0.6,1-1L227-27.4L227-27.4z M235.5-35.7
			c-1.4,1.4-2.8,2.8-4.2,4.2l1.4,1.4c0.3-0.3,0.6-0.6,0.9-0.9l0,0c0.6-0.6,1.2-1.1,1.7-1.7l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0
			c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1
			c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.2-0.2,0.4-0.4,0.6-0.6L235.5-35.7L235.5-35.7z
			 M243.7-44.3c-1.3,1.5-2.7,2.9-4.1,4.4l1.4,1.4c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1
			c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1l0,0
			c0.7-0.8,1.5-1.5,2.2-2.3l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1
			c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1c0.2-0.2,0.4-0.5,0.7-0.7L243.7-44.3L243.7-44.3z M251.7-53.3c-1.3,1.5-2.6,3-3.9,4.5l1.5,1.3
			c0.9-1,1.8-2.1,2.7-3.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1
			c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.3-0.3,0.5-0.6,0.8-0.9L251.7-53.3L251.7-53.3z M259.2-62.5c-1.2,1.6-2.5,3.1-3.7,4.6l1.5,1.3
			c1.3-1.5,2.5-3.1,3.8-4.7L259.2-62.5L259.2-62.5z M266.5-72c-1.2,1.6-2.4,3.2-3.6,4.8l1.6,1.2c0.4-0.5,0.8-1,1.1-1.5c0,0,0,0,0,0
			c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0
			c0.1-0.1,0.1-0.2,0.2-0.3l0,0c0.1-0.2,0.3-0.4,0.4-0.6l0,0c0,0,0,0,0.1-0.1l0,0c0.1-0.1,0.1-0.1,0.2-0.2l0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2
			c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0.1-0.2,0.3-0.4,0.4-0.6L266.5-72L266.5-72z M273.4-81.7c-1.1,1.6-2.3,3.3-3.4,4.9
			l1.6,1.2c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0.6-0.8,1.1-1.7,1.7-2.5L273.4-81.7
			L273.4-81.7z M279.9-91.7c-1.1,1.7-2.1,3.4-3.2,5l1.7,1.1c0.3-0.4,0.5-0.8,0.8-1.2c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0
			c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1
			c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0
			c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0-0.1,0.1-0.1,0.1-0.2l0,0c0.1-0.2,0.2-0.4,0.3-0.6L279.9-91.7L279.9-91.7z M286-101.9c-1,1.7-2,3.4-3,5.1l1.7,1
			c0.6-1.1,1.3-2.1,1.9-3.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.4-0.6,0.7-1.2,1.1-1.8L286-101.9L286-101.9z
			 M291.8-112.3c-0.9,1.7-1.9,3.5-2.8,5.2l1.8,1c1-1.7,1.9-3.5,2.9-5.3L291.8-112.3L291.8-112.3z M297.2-123
			c-0.9,1.8-1.7,3.6-2.6,5.3l1.8,0.9c0.3-0.6,0.6-1.1,0.8-1.7c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.6-1.1,1.1-2.2,1.6-3.3L297.2-123L297.2-123z M302.1-133.8c-0.8,1.8-1.6,3.6-2.4,5.4
			l1.8,0.8c0.8-1.8,1.7-3.6,2.5-5.5L302.1-133.8L302.1-133.8z M306.7-144.8c-0.7,1.8-1.5,3.7-2.2,5.5l1.8,0.8
			c0.1-0.2,0.1-0.3,0.2-0.5l0,0c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2
			c0,0,0,0,0,0c0.6-1.5,1.2-2.9,1.8-4.4L306.7-144.8L306.7-144.8z M310.9-156c-0.7,1.9-1.3,3.8-2,5.6l1.9,0.7
			c0.4-1.1,0.8-2.2,1.2-3.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0.2-0.6,0.5-1.3,0.7-1.9L310.9-156L310.9-156z M314.6-167.3c-0.6,1.9-1.2,3.8-1.8,5.7l1.9,0.6
			c0.6-1.9,1.2-3.8,1.8-5.7L314.6-167.3L314.6-167.3z M317.9-178.8c-0.5,1.9-1.1,3.8-1.6,5.7l1.9,0.6c0.1-0.3,0.2-0.6,0.3-0.9
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1l0,0c0.3-1.2,0.7-2.3,1-3.5
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0-0.2,0.1-0.3,0.1-0.5L317.9-178.8L317.9-178.8z M320.8-190.4c-0.4,1.9-0.9,3.9-1.4,5.8l1.9,0.5c0.1-0.4,0.2-0.7,0.3-1.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0.2-0.9,0.4-1.8,0.6-2.7c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.5,0.2-1,0.4-1.5L320.8-190.4L320.8-190.4z M323.3-202c-0.4,1.9-0.8,3.9-1.2,5.8
			l2,0.4c0-0.2,0.1-0.4,0.1-0.5l0,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0.3-1.6,0.7-3.2,1-4.9L323.3-202L323.3-202z M325.3-213.8c-0.3,2-0.6,3.9-1,5.9l2,0.3c0.3-1.7,0.6-3.5,0.8-5.2c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.2,0.1-0.4,0.1-0.5L325.3-213.8L325.3-213.8z M326.9-225.6c-0.2,2-0.5,4-0.7,5.9
			l2,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3l0,0c0-0.1,0-0.2,0-0.4l0,0c0-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0c0,0,0-0.1,0-0.1l0,0
			c0-0.1,0-0.1,0-0.2l0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.4,0.1-0.9,0.2-1.3L326.9-225.6
			L326.9-225.6z M328-237.5c-0.2,2-0.3,4-0.5,5.9l2,0.2c0.1-0.6,0.1-1.3,0.2-1.9l0,0c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2l0,0c0,0,0-0.1,0-0.1l0,0
			c0-0.1,0-0.2,0-0.4l0,0c0.1-0.9,0.1-1.7,0.2-2.6L328-237.5L328-237.5z M328.7-249.4c-0.1,2-0.2,4-0.3,6l2,0.1c0.1-2,0.2-4,0.3-6
			L328.7-249.4L328.7-249.4z M329-261.3c0,2,0,4-0.1,6l2,0c0-2,0.1-4,0.1-6L329-261.3L329-261.3z M330.8-273.3l-2,0.1
			c0.1,2,0.1,4,0.1,6l2,0c0-0.9,0-1.8,0-2.6c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1l0,0C330.9-271.4,330.9-272.3,330.8-273.3L330.8-273.3z M330.2-285.3l-2,0.1c0.1,2,0.3,4,0.3,6l2-0.1
			C330.5-281.3,330.4-283.3,330.2-285.3L330.2-285.3z M329.2-297.2l-2,0.2c0.2,2,0.4,4,0.6,5.9l2-0.2c-0.1-1.4-0.2-2.7-0.4-4.1
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0C329.3-296.4,329.3-296.8,329.2-297.2L329.2-297.2z M327.7-309.1l-2,0.3
			c0.3,2,0.5,3.9,0.8,5.9l2-0.2c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0C328.2-305.7,328-307.4,327.7-309.1L327.7-309.1z M325.8-321l-2,0.4c0.4,1.9,0.7,3.9,1,5.9l2-0.3c0-0.1,0-0.2,0-0.3
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
			c0,0,0,0,0-0.1l0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3l0,0c0-0.1,0-0.2-0.1-0.4l0,0
			c0-0.1,0-0.2-0.1-0.4l0,0c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.4c0,0,0,0,0,0
			c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0C325.9-320.9,325.9-320.9,325.8-321L325.8-321z M323.5-332.8l-2,0.4c0.4,1.9,0.8,3.9,1.2,5.8l2-0.4
			c-0.1-0.3-0.1-0.6-0.2-0.9c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
			c0,0,0,0,0,0c-0.2-0.9-0.4-1.8-0.6-2.7c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0C323.7-331.8,323.6-332.3,323.5-332.8L323.5-332.8z M320.7-344.4l-1.9,0.5c0.5,1.9,1,3.9,1.4,5.8
			l1.9-0.5c0-0.1,0-0.1,0-0.2l0,0c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.2
			c0,0,0,0,0,0C321.6-341.1,321.1-342.8,320.7-344.4L320.7-344.4z M317.5-356l-1.9,0.6c0.6,1.9,1.1,3.8,1.7,5.7l1.9-0.5
			c-0.1-0.4-0.3-0.9-0.4-1.3l0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0C318-354.1,317.8-355.1,317.5-356L317.5-356z M313.9-367.4l-1.9,0.6
			c0.6,1.9,1.3,3.8,1.9,5.7l1.9-0.6c0-0.1-0.1-0.3-0.1-0.4l0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c-0.2-0.7-0.5-1.4-0.7-2.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.2l0,0C314.3-366,314.1-366.7,313.9-367.4L313.9-367.4z M309.8-378.7l-1.9,0.7
			c0.7,1.8,1.4,3.7,2.1,5.6l1.9-0.7c-0.5-1.3-0.9-2.5-1.4-3.8c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1C309.8-378.6,309.8-378.6,309.8-378.7
			C309.8-378.7,309.8-378.7,309.8-378.7C309.8-378.7,309.8-378.7,309.8-378.7L309.8-378.7z M305.3-389.8l-1.8,0.8
			c0.8,1.8,1.5,3.7,2.3,5.5l1.9-0.7c-0.4-1.1-0.9-2.2-1.3-3.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			C305.4-389.5,305.4-389.7,305.3-389.8L305.3-389.8z M300.4-400.8l-1.8,0.8c0.8,1.8,1.7,3.6,2.5,5.4l1.8-0.8
			c-0.7-1.7-1.5-3.3-2.3-4.9c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0C300.5-400.7,300.4-400.8,300.4-400.8L300.4-400.8z M295.1-411.6l-1.8,0.9c0.9,1.8,1.8,3.6,2.7,5.3l1.8-0.9
			c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2l0,0c0,0,0-0.1,0-0.1l0,0c-0.3-0.7-0.7-1.3-1-2l0,0c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0C295.6-410.7,295.3-411.1,295.1-411.6L295.1-411.6z M289.4-422.1l-1.7,1
			c1,1.7,1.9,3.5,2.9,5.2l1.8-0.9c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0c0-0.1-0.1-0.2-0.1-0.2l0,0c-0.8-1.5-1.6-3-2.5-4.5l0,0
			C289.6-421.8,289.5-421.9,289.4-422.1L289.4-422.1C289.5-422.1,289.5-422.1,289.4-422.1L289.4-422.1L289.4-422.1z M283.4-432.5
			l-1.7,1c1,1.7,2.1,3.4,3.1,5.1l1.7-1c-0.2-0.4-0.5-0.8-0.7-1.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
			c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0C284.1-431.3,283.7-431.9,283.4-432.5L283.4-432.5z M276.9-442.6l-1.7,1.1
			c1.1,1.6,2.2,3.3,3.3,5l1.7-1.1C279.1-439.3,278-440.9,276.9-442.6L276.9-442.6z M270.1-452.5l-1.6,1.2c1.2,1.6,2.3,3.2,3.4,4.9
			l1.6-1.1c-0.2-0.3-0.4-0.6-0.7-1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0-0.1-0.1l0,0c-0.4-0.6-0.9-1.3-1.3-1.9c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
			C270.5-451.9,270.3-452.2,270.1-452.5L270.1-452.5z M262.9-462.1l-1.6,1.2c1.2,1.6,2.4,3.2,3.6,4.7l1.6-1.2
			c-0.6-0.9-1.3-1.7-1.9-2.5l0,0c0,0,0,0-0.1-0.1l0,0c0,0,0,0-0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
			c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1l0,0c0,0-0.1-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
			c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0
			C263.2-461.7,263-461.9,262.9-462.1L262.9-462.1z M255.4-471.4l-1.5,1.3c1.3,1.5,2.5,3.1,3.8,4.6l1.6-1.3c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
			c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.3
			l0,0c-0.4-0.5-0.9-1.1-1.3-1.6c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1C255.5-471.3,255.4-471.4,255.4-471.4L255.4-471.4z M247.5-480.5l-1.5,1.3c1.3,1.5,2.7,3,4,4.5
			l1.5-1.3c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0
			c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c0,0,0,0-0.1-0.1
			c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0
			c0,0-0.1-0.1-0.1-0.1l0,0C247.7-480.3,247.6-480.4,247.5-480.5L247.5-480.5z M239.3-489.3l-1.4,1.4c1.4,1.4,2.8,2.9,4.1,4.3
			l1.5-1.4c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0c0,0-0.1-0.1-0.1-0.1l0,0
			c0,0-0.1-0.1-0.1-0.1l0,0c-0.1-0.1-0.2-0.2-0.3-0.3l0,0c-0.6-0.6-1.1-1.2-1.7-1.8c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3
			c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0C239.4-489.2,239.4-489.2,239.3-489.3L239.3-489.3L239.3-489.3z M230.8-497.7
			l-1.4,1.4c1.4,1.4,2.9,2.8,4.3,4.2l1.4-1.4c-0.8-0.8-1.7-1.6-2.5-2.5l0,0c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0
			c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0
			C231.3-497.2,231-497.5,230.8-497.7L230.8-497.7z M222-505.9l-1.3,1.5c1.5,1.3,3,2.7,4.4,4l1.4-1.5c0,0-0.1-0.1-0.1-0.1
			c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c-1-0.9-2-1.8-3-2.8c0,0,0,0,0,0
			c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0C222.4-505.5,222.2-505.7,222-505.9L222-505.9z M212.9-513.7l-1.3,1.5
			c1.5,1.3,3.1,2.6,4.6,3.8l1.3-1.5c-0.2-0.1-0.3-0.3-0.5-0.4c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2l0,0c-0.3-0.3-0.7-0.6-1-0.8l0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1
			c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1l0,0
			c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0
			c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0C213-513.5,213-513.6,212.9-513.7C212.9-513.6,212.9-513.6,212.9-513.7
			C212.9-513.7,212.9-513.7,212.9-513.7L212.9-513.7z M203.5-521.1l-1.2,1.6c1.6,1.2,3.2,2.4,4.7,3.7l1.2-1.6
			c-1-0.8-1.9-1.5-2.9-2.3c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0-0.1,0l0,0C204.3-520.5,203.9-520.8,203.5-521.1
			L203.5-521.1z M193.8-528.3l-1.2,1.6c1.6,1.1,3.2,2.3,4.8,3.5l1.2-1.6c-0.6-0.4-1.2-0.9-1.8-1.3l0,0c-1-0.7-1.9-1.4-2.9-2.1
			c0,0,0,0,0,0C194-528.2,193.9-528.2,193.8-528.3C193.9-528.2,193.9-528.2,193.8-528.3L193.8-528.3z M183.9-535l-1.1,1.7
			c1.7,1.1,3.3,2.2,5,3.3l1.1-1.7c-1.4-1-2.8-1.9-4.2-2.8c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1
			c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0C184-535,184-535,183.9-535C183.9-535,183.9-535,183.9-535
			C183.9-535,183.9-535,183.9-535L183.9-535z M173.8-541.4l-1,1.7c1.7,1,3.4,2.1,5.1,3.1l1.1-1.7c-1.1-0.7-2.2-1.4-3.3-2.1l0,0
			c-0.1-0.1-0.2-0.1-0.3-0.2l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
			c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			C173.8-541.4,173.8-541.4,173.8-541.4C173.8-541.4,173.8-541.4,173.8-541.4L173.8-541.4L173.8-541.4z M163.4-547.4l-1,1.7
			c1.7,1,3.5,1.9,5.2,2.9l1-1.7c-0.3-0.2-0.6-0.4-1-0.6c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1
			c0,0,0,0,0,0c-1.1-0.7-2.3-1.3-3.4-1.9c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
			C163.6-547.3,163.5-547.4,163.4-547.4L163.4-547.4z M152.8-553l-0.9,1.8c1.8,0.9,3.5,1.8,5.3,2.7l0.9-1.8c-0.5-0.3-1-0.5-1.5-0.8
			c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1l0,0c-0.8-0.4-1.6-0.8-2.4-1.2c0,0,0,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C153.3-552.8,153.1-552.9,152.8-553L152.8-553z M142-558.3l-0.8,1.8
			c1.8,0.8,3.6,1.7,5.4,2.5l0.9-1.8c-0.7-0.3-1.4-0.7-2.2-1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0l0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0l0,0C143.2-557.7,142.6-558,142-558.3L142-558.3z M131-563.1l-0.8,1.8c1.8,0.8,3.7,1.6,5.5,2.3l0.8-1.8
			c-0.1-0.1-0.2-0.1-0.4-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0
			C133.9-561.9,132.4-562.5,131-563.1L131-563.1z M119.8-567.5l-0.7,1.9c1.9,0.7,3.7,1.4,5.6,2.1l0.7-1.9c-1.6-0.6-3.2-1.2-4.8-1.8
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0
			C120.1-567.4,120-567.5,119.8-567.5L119.8-567.5z M108.5-571.5l-0.6,1.9c1.9,0.6,3.8,1.3,5.6,1.9l0.7-1.9
			C112.3-570.2,110.4-570.9,108.5-571.5L108.5-571.5z M97.1-575.1l-0.6,1.9c1.9,0.6,3.8,1.1,5.7,1.7l0.6-1.9c-1-0.3-2-0.6-3-0.9
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0C98.7-574.6,97.9-574.8,97.1-575.1
			L97.1-575.1z M85.5-578.2l-0.5,1.9c1.9,0.5,3.9,1,5.8,1.5l0.5-1.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0C87.6-577.7,86.5-577.9,85.5-578.2L85.5-578.2z M73.8-580.9l-0.4,2c1.9,0.4,3.9,0.8,5.8,1.3l0.5-1.9
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C77.5-580.1,75.7-580.5,73.8-580.9L73.8-580.9z M62-583.2l-0.3,2
			c2,0.3,3.9,0.7,5.9,1.1l0.4-2c-0.2,0-0.3-0.1-0.5-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
			c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.2,0-0.5-0.1-0.7-0.1c0,0,0,0,0,0
			c-0.1,0-0.2,0-0.4-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
			C63.2-583,62.6-583.1,62-583.2L62-583.2z M50.1-585l-0.3,2c2,0.3,3.9,0.6,5.9,0.9l0.3-2c-0.3,0-0.6-0.1-0.9-0.1c0,0,0,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C53.4-584.6,51.8-584.8,50.1-585L50.1-585z M38.2-586.4l-0.2,2c2,0.2,4,0.4,5.9,0.6
			l0.2-2c-0.5-0.1-1-0.1-1.5-0.2l0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C40.9-586.2,39.5-586.3,38.2-586.4L38.2-586.4z M26.3-587.4l-0.1,2c2,0.1,4,0.3,5.9,0.4
			l0.2-2c-0.8-0.1-1.5-0.1-2.3-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			C28.5-587.2,27.4-587.3,26.3-587.4L26.3-587.4z M14.3-587.9l0,2c2,0,4,0.1,6,0.2l0.1-2c-1.2,0-2.3-0.1-3.5-0.1l0,0
			c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
			c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0C14.3-587.9,14.3-587.9,14.3-587.9L14.3-587.9z M6-588v2c0.8,0,1.5,0,2.3,0l0-2c-0.1,0-0.2,0-0.3,0
			l0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
			C6.1-588,6-588,6-588L6-588L6-588z"
            />
          </g>
        </svg>
      </React.Fragment>
    );
  }

  calcClipPathSize = () => {
    const { coefAutoStop, rulerCoef } = this.props;
    return Math.min(205 * (coefAutoStop - rulerCoef));
  };
}

export default RulerSvg;
