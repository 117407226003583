import React from 'react';
import { Container, IconContainer } from './CaseListItem.styles';
import { CaseListItemContainer, CaseListItemDefault } from '@components/CaseListItemDefault';
import { useGetCaseById } from 'games/CaseBattle/hooks';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useDispatch } from 'react-redux';
import { removeCase } from 'games/CaseBattle/ducks/create-battle-duck/create-battle-duck';
import { actionRequestSuitCase } from 'games/CaseGame';
import { useDialog } from 'core/hooks';
import { CaseDetailsConfig } from 'games/CaseBattle/dialog-config/dialog-config';

interface CaseListItemProps {
  id: number;
  uniqId: string;
}

export const CaseListItem: React.FC<CaseListItemProps> = React.memo(({ id, uniqId }) => {
  const { caseData, isLoading } = useGetCaseById(id);
  const dispatch = useDispatch();
  const { handleOpenDialog } = useDialog(CaseDetailsConfig.CASE_DETAIL);

  const redirectHandler = () => {
    dispatch(actionRequestSuitCase(id));
    handleOpenDialog();
  };

  const removeItemHandler = () => {
    dispatch(removeCase(uniqId));
  };

  return isLoading || !caseData ? (
    <CaseListItemContainer className="skeleton" />
  ) : (
    <Container>
      <CaseListItemDefault
        className="card-size"
        caseType={caseData.type}
        handler={redirectHandler}
        image={
          caseData?.free ? caseData.image ?? caseData?.backgroundImage : caseData?.backgroundImage
        }
        free={!!caseData?.free}
      />
      <IconContainer onClick={removeItemHandler}>
        <CaseBattleIcon.RemoveIcon />
      </IconContainer>
    </Container>
  );
});
