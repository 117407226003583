import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 8px;
`;

export const SkeletonAvatar = styled(SkeletonElement)`
  align-items: center;
  background-color: var(--color-dark-200);
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;

  @media screen and (max-width: 374px) {
    height: 66px;
    width: 66px;
  }

  & > svg {
    height: 54px;
    width: 54px;

    @media screen and (max-width: 374px) {
      height: 36px;
      width: 36px;
    }
  }
`;

export const SkeletonName = styled(SkeletonElement)`
  background-color: var(--color-dark-600);
  border-radius: 2px;
  height: 4px;
  margin-top: 12px;
  width: 40px;
`;

export const SkeletonPrice = styled(SkeletonElement)`
  background-color: var(--color-dark-600);
  border-radius: 2px;
  height: 4px;
  margin-top: 16px;
  width: 18px;
`;
