export class StoreFiltersConfig {
  static get prices(): Map<string, number[]> {
    return new Map<string, number[]>([
      ['ALL', [null, null]],
      ['0-5', [0, 500]],
      ['5-25', [500, 2500]],
      ['25-100', [2500, 10000]],
      ['100-500', [10000, 50000]],
      ['500-1000', [50000, 100000]],
      ['1000+', [100000, null]],
    ]);
  }

  static price(key: string): number[] {
    return StoreFiltersConfig.prices.get(key);
  }

  static get sorts(): Map<string, string> {
    return new Map<string, string>([
      ['price', 'ASC_PRICE'],
      ['-price', 'DESC_PRICE'],
      ['name', 'ASC_NAME'],
      ['-name', 'DESC_NAME'],
      ['overprice', 'UNDERPRICE'],
      ['-overprice', 'OVERPRICE'],
    ]);
  }

  static sortBy(key: string): string {
    return StoreFiltersConfig.sorts.get(key);
  }

  static get exteriors(): Map<string, string> {
    return new Map<string, string>([
      ['Not Painted', 'NOT_PAINTED'],
      ['Factory New', 'FACTORY_NEW'],
      ['Minimal Wear', 'MINIMAL_WEAR'],
      ['Field-Tested', 'FIELD_TESTED'],
      ['Well-Worn', 'WELL_WORN'],
      ['Battle-Scarred', 'BATTLE_SCARRED'],
    ]);
  }

  static exterior(key: string): string {
    return StoreFiltersConfig.exteriors.get(key);
  }

  static get phases(): Map<string, string> {
    return new Map<string, string>([
      ['Emerald', 'EMERALD'],
      ['Sapphire', 'SAPPHIRE'],
      ['Ruby', 'RUBY'],
      ['Black Pearl', 'BLACK_PEARL'],
    ]);
  }

  static phase(key: string): string {
    return StoreFiltersConfig.phases.get(key);
  }

  static get weapons(): Map<string, string> {
    return new Map<string, string>([
      ['Knife', 'KNIFE'],
      ['Machinegun', 'MACHINEGUN'],
      ['Pistol', 'PISTOL'],
      ['Rifle', 'RIFLE'],
      ['SMG', 'SMG'],
      ['Shotgun', 'SHOTGUN'],
      ['Sniper Rifle', 'SNIPER_RIFLE'],
    ]);
  }

  static weapon(key: string): string {
    return StoreFiltersConfig.weapons.get(key);
  }

  static get types(): Map<string, string> {
    return new Map<string, string>([
      ['', 'ALL'],
      ['collectible', 'COLLECTIBLE'],
      ['container', 'CONTAINER'],
      ['gift', 'GIFT'],
      ['gloves', 'GLOVES'],
      ['graffiti', 'GRAFFITI'],
      ['key', 'KEY'],
      ['music kit', 'MUSIC_KIT'],
      ['pass', 'PASS'],
      ['weapon', 'WEAPON'],
    ]);
  }

  static type(key: string): string {
    return StoreFiltersConfig.types.get(key);
  }

  static get overprices(): Map<string, number> {
    return new Map<string, number>([
      ['1', 1],
      ['2', 2],
      ['3', 3],
      ['5', 5],
      ['7', 7],
      ['15', 15],
      ['30', 30],
      ['100', 100],
      ['120', 120],
    ]);
  }

  static overprice(key: string): number {
    return StoreFiltersConfig.overprices.get(key);
  }

  static get underprices(): Map<string, number> {
    return new Map<string, number>([
      ['0', 0],
      ['-1', -1],
      ['-2', -2],
      ['-3', -3],
      ['-5', -5],
      ['-7', -7],
      ['-15', -15],
      ['-30', -30],
    ]);
  }

  static underprice(key: string): number {
    return StoreFiltersConfig.underprices.get(key);
  }

  static detailed(v: string): Map<string, any> {
    switch (v) {
      case 'exterior':
        return StoreFiltersConfig.exteriors;

      case 'phase':
        return StoreFiltersConfig.phases;

      case 'weapon':
        return StoreFiltersConfig.weapons;

      case 'type':
        return StoreFiltersConfig.types;

      case 'sortBy':
        return StoreFiltersConfig.sorts;

      default:
        return new Map();
    }
  }
}
