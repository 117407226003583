import { Button } from '@components/Common';
import styled from 'styled-components';

export const StyledSellButton = styled(Button)`
  color: var(--color-white);
  background: var(--color-dark-200);
  width: 0;
  display: flex;
  height: 48px;
  flex: 2;
`;
