import { reducer } from './duck';

export { GameRouter as X50Router } from './components';
// export const X50Router = lazy(() => import('./components').then(module => ({ default: module.GameRouter })))

export const x50Module = {
  moduleName: 'x50',
  moduleId: 13,
  path: '/x50',
  type: 'pve',
  reducer,
};
