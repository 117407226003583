import { IBatchRequest, IFetchSellingSuccessResponse } from './sales.reducer.interfaces';
import { IInventoryElement } from './inventory.reducers.interface';
import {
  IMarketplaceParticipantUpdate,
  IMarketplaceStoreItemsUpdate,
} from './marketplace.actions.interface';
import { IMarketplaceElement } from './marketplace.reducer.interfaces';

export enum SalesActionTypes {
  FETCH_SELLING_ITEMS = 'app/marketplace/inventory/FETCH_SELLING_ITEMS',
  FETCH_SELLING_ITEMS_SUCCESS = 'app/marketplace/inventory/FETCH_SELLING_ITEMS_SUCCESS',

  ADD_TO_SELECTED = 'app/marketplace/inventory/ADD_TO_SELECTED',
  UPDATE_IN_SELECTED = 'app/marketplace/inventory/UPDATE_IN_SELECTED',
  REMOVE_FROM_SELECTED = 'app/marketplace/inventory/REMOVE_FROM_SELECTED',
  RESET_SELECTED = 'app/marketplace/inventory/RESET_SELECTED',

  FETCH_CREATE_PUBLICATION = 'app/marketplace/inventory/FETCH_CREATE_PUBLICATION',
  FETCH_CREATE_PUBLICATION_SUCCESS = 'app/marketplace/inventory/FETCH_CREATE_PUBLICATION_SUCCESS',
  FETCH_CREATE_PUBLICATION_FAILURE = 'app/marketplace/inventory/FETCH_CREATE_PUBLICATION_FAILURE',

  FETCH_PAUSE_SELLING = 'app/marketplace/inventory/FETCH_PAUSE_SELLING',
  FETCH_PAUSE_SELLING_SUCCESS = 'app/marketplace/inventory/FETCH_PAUSE_SELLING_SUCCESS',

  FETCH_RESUME_SELLING = 'app/marketplace/inventory/FETCH_RESUME_SELLING',
  FETCH_RESUME_SELLING_SUCCESS = 'app/marketplace/inventory/FETCH_RESUME_SELLING_SUCCESS',

  FETCH_DELETE_SELLING_ITEM = 'app/marketplace/inventory/FETCH_DELETE_SELLING_ITEM',
  FETCH_DELETE_SELLING_ITEM_SUCCESS = 'app/marketplace/inventory/FETCH_DELETE_SELLING_ITEM_SUCCESS',

  FETCH_TRADE_ACTION = 'app/marketplace/inventory/FETCH_TRADE_ACTION',
  FETCH_TRADE_ACTION_SUCCESS = 'app/marketplace/inventory/FETCH_TRADE_ACTION_SUCCESS',
}

export interface IAddedToSelected {
  type: typeof SalesActionTypes.ADD_TO_SELECTED;
  payload: {
    item: IInventoryElement;
  };
}

export interface IUpdateInSelected {
  type: typeof SalesActionTypes.UPDATE_IN_SELECTED;
  payload: Partial<IBatchRequest>;
}

export interface IRemoveFromSelected {
  type: typeof SalesActionTypes.REMOVE_FROM_SELECTED;
  payload: {
    id: string;
  };
}

export interface IResetSelected {
  type: typeof SalesActionTypes.RESET_SELECTED;
}

export interface IFetchPublication {
  type: typeof SalesActionTypes.FETCH_CREATE_PUBLICATION;
}

export interface IFetchPublicationSuccess {
  type: typeof SalesActionTypes.FETCH_CREATE_PUBLICATION_SUCCESS;
}

export interface IFetchPublicationFailure {
  type: typeof SalesActionTypes.FETCH_CREATE_PUBLICATION_FAILURE;
}

export interface IFetchSellingItems {
  type: typeof SalesActionTypes.FETCH_SELLING_ITEMS;
}

export interface IFetchSellingItemsSuccess {
  type: typeof SalesActionTypes.FETCH_SELLING_ITEMS_SUCCESS;
  payload: IFetchSellingSuccessResponse;
}

export interface IFetchPauseSelling {
  type: typeof SalesActionTypes.FETCH_PAUSE_SELLING;
  payload: {
    id: number;
  };
}

export interface IFetchPauseSellingSuccess {
  type: typeof SalesActionTypes.FETCH_PAUSE_SELLING_SUCCESS;
  payload: IMarketplaceElement;
}

export interface IFetchResumeSelling {
  type: typeof SalesActionTypes.FETCH_RESUME_SELLING;
  payload: {
    id: number;
  };
}

export interface IFetchResumeSellingSuccess {
  type: typeof SalesActionTypes.FETCH_RESUME_SELLING_SUCCESS;
  payload: IMarketplaceElement;
}

export interface IFetchDeleteSellingItem {
  type: typeof SalesActionTypes.FETCH_DELETE_SELLING_ITEM;
  payload: { id: number };
}

export interface IFetchDeleteSellingItemSuccess {
  type: typeof SalesActionTypes.FETCH_DELETE_SELLING_ITEM_SUCCESS;
  payload: { id: string };
}

export interface IFetchTradeAction {
  type: typeof SalesActionTypes.FETCH_TRADE_ACTION;
  payload: { id: number };
}

export interface IFetchTradeActionSuccess {
  type: typeof SalesActionTypes.FETCH_TRADE_ACTION_SUCCESS;
}

export type salesActions =
  | IMarketplaceStoreItemsUpdate
  | IMarketplaceParticipantUpdate
  | IAddedToSelected
  | IUpdateInSelected
  | IRemoveFromSelected
  | IResetSelected
  | IFetchPublication
  | IFetchPublicationSuccess
  | IFetchPublicationFailure
  | IFetchSellingItems
  | IFetchSellingItemsSuccess
  | IFetchPauseSelling
  | IFetchPauseSellingSuccess
  | IFetchResumeSelling
  | IFetchResumeSellingSuccess
  | IFetchDeleteSellingItem
  | IFetchDeleteSellingItemSuccess;
