import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--color-dark-50);
  padding: 38px 0 60px;
`;

export const CheckboxContainer = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  color: var(--color-white);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 22px 17px;

  &:first-child {
    border-top: 1px solid var(--color-gray-900);
  }

  &:last-child {
    border-bottom: 1px solid var(--color-gray-900);
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  & + label {
    cursor: pointer;
    margin-left: 6px;
    padding-right: 52px;
    position: relative;
  }

  & + label::before {
    background: var(--color-white-gray);
    border-radius: 20px;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    content: '';
    height: 32px;
    left: 0;
    position: absolute;
    top: -15px;
    transition: 0.2s;
    width: 52px;
  }

  & + label::after {
    background: var(--color-white);
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 28px;
    left: 3px;
    position: absolute;
    top: -13px;
    transition: 0.2s;
    width: 28px;
  }

  &:checked + label::before {
    background: var(--yellow);
  }

  &:checked + label::after {
    background: var(--color-dark-50);
    left: 22px;
  }
`;
