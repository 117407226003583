import styled from 'styled-components';
import { Button } from '@components/Common';

export const Container = styled.div`
  background-color: var(--color-dark-600);
  padding: 40px 18px;
  text-align: center;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  text-align: left;
`;

export const SubTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 18px;
  padding: 9px 0 39px;
  text-align: left;
`;

export const MyButton = styled(Button)`
  background: var(--color-provider-steam);
  color: var(--color-white);
  display: flex;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  text-align: center;
`;

export const SocialIconWrap = styled.div`
  left: 20px;
  position: absolute;
`;
