import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addToSelected, removeFromSelected } from '../../ducks/sales.duck';
import { IInventoryElement } from '../../interfaces';
import { getSalesIsContain } from '../../selector';

export const useSalesSelectedControllers = (id: string) => {
  const contain = useSelector(getSalesIsContain(id), shallowEqual);
  const dispatch = useDispatch();

  const handleAddToSelected = useCallback(
    (item: IInventoryElement) => {
      dispatch(addToSelected(item));
    },
    [dispatch]
  );

  const handleRemoveFromSelected = useCallback(
    (id: string) => () => {
      dispatch(removeFromSelected(id));
    },
    [dispatch]
  );

  const toggleSelected = useCallback(
    (item: IInventoryElement) => () => {
      if (!!item.tradeLockEndAt || item?.inTrade || item.price < 1 || !item?.tradable) return;
      else if (contain) dispatch(removeFromSelected(item.steamInventoryId.toString()));
      else if (!item?.inTrade) dispatch(addToSelected(item));
    },
    [contain, dispatch]
  );

  return {
    contain,
    handleAddToSelected,
    handleRemoveFromSelected,
    toggleSelected,
  };
};
