import { Reducer } from 'redux';
import { Record } from 'immutable';
import { Epic, StateObservable, ofType } from 'redux-observable';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { from, fromEvent, iif, interval, merge, of } from 'rxjs';
import { promoParamsHandler } from '../../utils/params.utils';
import { experienceAdapter, userAdapter, userDetailsAdapter } from '../user.adapters';
import { addNotification } from 'modules/Notifications/duck';
import { AuthDialogs } from 'core/Auth/auth-dialog.config';
import { dialogChangeState, dialogOn } from 'core/ducks/dialog';
import { socketService } from 'services/socket.service';
import {
  IEmail,
  IPermissions,
  IReferralInfo,
  IUserState,
  UserRecord,
} from '../interfaces/user.reducer.interface';
import { IEventsTypes } from '../../rootInterfaces/root.interface';
import { AuthTypes, authActionsTypes } from 'core/Auth/interfaces';
import { userActions, userActionsTypes } from '../interfaces/user.actions.interface';
import { AsyncAction, IStore } from 'store/interface';
import { getUser } from '../selectors';
import { UserPermissionsRepository, UserReferral } from '../repositories';
import {
  UserSettings,
  userSettingActionTypes,
  userSettingsActions,
  userSettingsRecord,
} from '../interfaces';
import { UserSettingRepository } from '../repositories/user-settings.repository';
import { userModals } from '../modal.config';
import { updateAvatarBackend } from 'modules/PersonalArea/duck';
import { IUploadAvatar } from 'modules/PersonalArea/interfaces';
import { UserSelfExclusionRepository } from '../repositories/user-self-exclusion.repository';
import { INotification } from 'modules/PersonalArea/components/Notification/Notification';

/** REDUCERS **/

export const user: Reducer<Record<IUserState>, userActions> = (
  state = new UserRecord(),
  action
) => {
  switch (action.type) {
    case userActionsTypes.UPDATE_USER: {
      return Object.keys(action.payload).reduce((acc, i: string) => {
        if (typeof action.payload[i] === 'undefined') return acc;
        if (typeof action.payload[i] === 'object' && action.payload[i]) {
          if (i === 'providers') {
            return Object.keys(action.payload[i]).reduce((acc, k) => {
              if (!action.payload[i][k]) return acc;
              if (k === 'yandex') return acc;
              return acc.setIn([i, k], action.payload[i][k]);
            }, acc.delete(i));
          }
          return Object.keys(action.payload[i]).reduce((acc, k) => {
            if (typeof action.payload[i][k] === 'undefined') return acc;
            return acc.setIn([i, k], action.payload[i][k]);
          }, acc);
        }
        return acc.setIn([i], action.payload[i]);
      }, state);
    }

    case userActionsTypes.UPDATE_USER_EMAILS: {
      return action.payload.email ? state.setIn(['email'], action.payload.email) : state;
    }

    case userActionsTypes.UPDATE_USER_STATS_IS_HIDDEN: {
      return action.payload ? state.set('statsIsHidden', action.payload.isHidden) : state;
    }

    case userActionsTypes.UPDATE_STEAM_HIDDEN: {
      return action.payload
        ? state.setIn(['providers', 'steam', 'is_hidden'], action.payload.isHidden)
        : state;
    }

    case userActionsTypes.UPDATE_EMAIL_SUBSCRIPTION: {
      const isSubscribed = state.getIn(['email', 'isSubscribed']);

      return state.setIn(['email', 'isSubscribed'], !isSubscribed);
    }

    case userActionsTypes.UPDATE_USER_BAN_TIME: {
      return action.payload ? state.set('banTime', action.payload) : state;
    }

    case userActionsTypes.UPDATE_USER_EXPERIENCE: {
      return state
        .mergeIn(['experience'], action.payload.experience)
        .setIn(['rewards'], action.payload.rewards);
    }

    case userActionsTypes.UPDATE_USER_LEVEL: {
      const userCanUpdateLevel = state.getIn(['experience', 'userCanUpdateLevel']);

      return state.setIn(['experience', 'userCanUpdateLevel'], !userCanUpdateLevel);
    }

    case userActionsTypes.UPDATE_USER_BONUSES: {
      const { bonuses } = action.payload;
      return state.mergeIn(['bonuses'], bonuses);
    }

    case userActionsTypes.UPDATE_USER_WALLET: {
      return state.setIn(['walletType'], action.payload.walletType);
    }

    case userActionsTypes.CLEAN_UP_BINDING_USER_PROVIDER: {
      const { provider } = action.payload;
      return state.deleteIn(['providers', provider]);
    }

    case userActionsTypes.FETCH_BINDING_USER_PROVIDER_FAILURE: {
      const { status } = action.payload;
      return state.setIn(['providers', 'telegram', 'status'], status);
    }

    case userActionsTypes.UPDATE_USER_VERIFICATION_STATUS: {
      return state.mergeIn(['verification'], action.payload);
    }

    case userActionsTypes.UPDATE_USER_PERMISSIONS: {
      return state.set('permissions', action.payload);
    }
    case userActionsTypes.LOGOUT:
      return new UserRecord();
    case userActionsTypes.ACTION_REFERRAL_USER_INFO_RESPONSE: {
      return state.set('referralInfo', action.payload);
    }
    default:
      return state;
  }
};

export const userSettings: Reducer<Record<UserSettings>, userSettingsActions> = (
  state = new userSettingsRecord(),
  action
) => {
  switch (action.type) {
    case userSettingActionTypes.ACTION_RESPONSE_USER_SETTINGS: {
      return state.mergeDeep({ ...action.payload });
    }
    case userSettingActionTypes.ACTION_LOADING: {
      const loading = state.get('loading');
      return state.set('loading', !loading);
    }
    default:
      return state;
  }
};

/** ACTION CREATORS **/

export const initProfile = (): AsyncAction => (dispatch, _getState, { socket }) => {
  socket.emit('profile-request');
  socket.emit('account:profile:fetch');
  socket.emit('account:email:fetch');
  socket.emit('account:socials:fetch');
  socket.emit('referral:request');
  socket.emit('steam:nickname:check', {
    skipSteamCheck: false,
  });
  dispatch(fetchExperience());
  dispatch(fetchUserPermission());
};

export const login = () => ({
  type: userActionsTypes.LOGIN,
});

export const updateUser = (data: any) => ({
  type: userActionsTypes.UPDATE_USER,
  payload: userAdapter(data),
});

export const updateEmailSubscription = () => ({
  type: userActionsTypes.UPDATE_EMAIL_SUBSCRIPTION,
});

const updateUserDetails = (data: any) => ({
  type: userActionsTypes.UPDATE_USER,
  payload: userDetailsAdapter(data),
});

/*
 * user balance
 * */

const receiveWalletType = (data: any): AsyncAction => dispatch => {
  if (data.success) {
    dispatch(updateWalletType(data.walletType));
  }
};

const updateWalletType = (walletType: number) => ({
  type: userActionsTypes.UPDATE_USER_WALLET,
  payload: {
    walletType,
  },
});

export const fetchChangeWallet = (): AsyncAction => (_dispatch, _getState, { socket }) => {
  socket.emit('wallet-new.switch', {});
};

/**
 * Emails
 * **/

export const fetchChangeEmail = (address: string, isSubscriptionAllowed: boolean): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  socket.emit('account:email:add', {
    address,
    isSubscriptionAllowed,
  });
};

export const fetchChangeSubscribe = (isSubscribed: boolean): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  socket.emit('account:email-subscription:change', {
    isSubscribed,
  });
};

export const fetchUpdateUserEmail = (email: IEmail): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  socket.emit('account:email:fetch', { email });
};

const updateUserEmail = (email: IEmail | boolean): AsyncAction => (
  dispatch,
  _getState,
  { socket }
) => {
  //Boolean value comes when unsubscribe mail
  if (typeof email === 'boolean' && email) {
    socket.emit('account:email:fetch');
  } else {
    dispatch({
      type: userActionsTypes.UPDATE_USER_EMAILS,
      payload: {
        email,
      },
    });
  }
};

/*
 *  Links
 * */

export const fetchUpdateSteamLink = (link: string): AsyncAction => (
  dispatch,
  _getState,
  { socket }
) => {
  socket.emit('token-link', { link });
  dispatch({ type: userActionsTypes.FETCH_USER_PERMISSIONS });
};

export const fetchUpdateVgoLink = (link: string): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  socket.emit('user:update-opskins-trade-link', { link });
};

/*
 * experience
 * */

export const fetchExperience = (): AsyncAction => (_dispatch, _getState, { socket }) => {
  socket.emit('account:experience:fetch');
};

export const fetchUserLevelUp = (): AsyncAction => (_dispatch, _getState, { socket }) => {
  socket.emit('account:experience:levelup-get');
};

const receiveUserLevel = (data: boolean): AsyncAction => dispatch => {
  data && dispatch(fetchExperience());
};

const updateUserExperience = (experience: any) => ({
  type: userActionsTypes.UPDATE_USER_EXPERIENCE,
  payload: experienceAdapter(experience),
});

/*
 * socials
 * */

export const fetchBindingUserProvider = (code: string) => ({
  type: userActionsTypes.FETCH_BINDING_USER_PROVIDER,
  payload: {
    code,
  },
});

const bindingUserProviderFailure = (status: string) => ({
  type: userActionsTypes.FETCH_BINDING_USER_PROVIDER_FAILURE,
  payload: {
    status,
  },
});

const cleanUpBindingUserProvider = (provider: string) => ({
  type: userActionsTypes.CLEAN_UP_BINDING_USER_PROVIDER,
  payload: {
    provider,
  },
});

export const unbindSocial = (name: AuthTypes): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  socket.emit('social-auth:providers:remove', name);
  socket.emit('account:social:toggle', { hidden: false });
};

export const updateSocial = (): AsyncAction => (_dispatch, _getState, { socket }) => {
  socket.emit('account:socials:fetch');
};

/*
 * user name
 * */

export const updateName = (name: string): AsyncAction => (dispatch, getState, { socket }) => {
  socket.emit('user:update-name', name);
};

export const updateCountry = (country: string): AsyncAction => (dispatch, getState, { socket }) => {
  socket.emit('account:country:set', { country });
};

/*
 * user bonuses
 * */

const updateUserBonus = (bonuses: any) => ({
  type: userActionsTypes.UPDATE_USER_BONUSES,
  payload: {
    bonuses,
  },
});

export const takePromoBonus = () => ({
  type: userActionsTypes.TAKE_USER_PROMO_BONUSES,
});

/*
 * verification
 * */
export const fetchUserVerification = (file: string) => ({
  type: userActionsTypes.FETCH_USER_VERIFICATION,
  payload: {
    file,
  },
});

const updateUserVerificationStatus = ({ status }: any) => ({
  type: userActionsTypes.UPDATE_USER_VERIFICATION_STATUS,
  payload: {
    status,
  },
});

/*
 * permissions
 * */

export const fetchUserPermission = () => ({
  type: userActionsTypes.FETCH_USER_PERMISSIONS,
});
const updateUserPermission = (statuses: IPermissions) => ({
  type: userActionsTypes.UPDATE_USER_PERMISSIONS,
  payload: statuses,
});

/*
 * logout
 * */
export const logout = () => (): void => {
  window.location.href = `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}auth/logout/${window.location.host}`;
};

export const updateUserBanTime = (banTime: number) => ({
  type: userActionsTypes.UPDATE_USER_BAN_TIME,
  payload: banTime,
});

export const updateUserBanTimeRequest = (banTime: string) => ({
  type: userActionsTypes.UPDATE_USER_BAN_TIME_REQUEST,
  payload: banTime,
});

export const updateEmailInfo = (data: INotification): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  if (data.body === 'Email successfully added to your account') {
    socket.emit('account:email:fetch');
  }
};

/*
 * privacy
 * */

export const updateStatsIsHidden = (): AsyncAction => (_dispatch, _getState, { socket }) => {
  socket.emit('account:statistic:toggle');
};

export const fetchStatsIsHidden = (data: string) => ({
  type: userActionsTypes.UPDATE_USER_STATS_IS_HIDDEN,
  payload: { isHidden: data === 'hide' },
});

export const updateProvidersSteam = (isHidden: boolean): AsyncAction => (
  _dispatch,
  _getState,
  { socket }
) => {
  socket.emit('account:social:toggle', {
    socialNetwork: 'steam',
    hidden: isHidden,
  });
};

export const fetchSteamHidden = (data: { socialNetwork: string; state: string }) => ({
  type: userActionsTypes.UPDATE_STEAM_HIDDEN,
  payload: { isHidden: data.state === 'hide' },
});

export const receiveUserCanLevelUP = (): AsyncAction => dispatch => {
  dispatch({ type: userActionsTypes.UPDATE_USER_LEVEL });
  dispatch(fetchExperience());
};

export const eventsTypes: IEventsTypes[] = [
  { event: 'user:update', action: updateUser },
  { event: 'account:country:status', action: updateUser },
  {
    event: 'account:email-subscription:state',
    action: updateEmailSubscription,
  },
  { event: 'notify:add', action: updateEmailInfo },
  { event: 'account:profile:state', action: updateUserDetails },
  { event: 'account:email:state', action: updateUserEmail },
  { event: 'account:socials:state', action: updateUserDetails },
  { event: 'account:statistic:status', action: fetchStatsIsHidden },
  { event: 'account:social:status', action: fetchSteamHidden },
  { event: 'social-auth:providers:list', action: updateSocial },
  // { event: 'referral:reset', action: updateUserReferral },
  //exp
  { event: 'account:experience:state', action: updateUserExperience },
  {
    event: 'account:experience:levelup-result',
    action: receiveUserLevel,
  },
  { event: 'wallet-new.switch', action: receiveWalletType },
  { event: 'userCanLevelUP', action: receiveUserCanLevelUP },
];

// const userPromoEpic: Epic = action$ =>
//   action$.pipe(
//     ofType(userActionsTypes.TAKE_USER_PROMO_BONUSES),
//     debounceTime(1000),
//     switchMap(() =>
//       promoUserRepository.fetchTakePromoBonus().pipe(
//         map(() =>
//           updateUserBonus({
//             promo: true,
//           })
//         ),
//         catchError(() =>
//           of(
//             updateUserBonus({
//               promo: false,
//             })
//           )
//         )
//       )
//     )
//   )

export const telegramEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(authActionsTypes.SIGN_IN),
    switchMap(() =>
      merge(
        fromEvent(socket.io, 'telegram.connect.response').pipe(
          mergeMap((data: any) =>
            iif(
              () => data.success,
              of(dialogChangeState(AuthDialogs.SIGN_IN_TELEGRAM, false)).pipe(
                tap(() => socket.io.emit('account:socials:fetch'))
              ),
              of(cleanUpBindingUserProvider('telegram')).pipe(
                delay(2000),
                startWith(bindingUserProviderFailure(data))
              )
            )
          )
        ),
        action$.pipe(
          ofType(userActionsTypes.FETCH_BINDING_USER_PROVIDER),
          debounceTime(1000),
          pluck('payload', 'code'),
          tap((code: string) => socket.io.emit('telegram.connect.request', { code })),
          ignoreElements()
        )
      ).pipe(catchError(() => of(addNotification({ type: 'error' }))))
    )
  );

export const loaderEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(userActionsTypes.LOGIN),
    tap(() => {
      const user = getUser(store$.value);
      user.verification.requirement && socket.io.emit('verification.getStatus');
    }),
    switchMap(() =>
      merge(
        of(fetchUserPermission()),
        fromEvent<number>(socket.io, 'steam:nickname:check').pipe(
          map(nickname => updateUserBonus({ nickname }))
        ),
        fromEvent<any>(socket.io, 'verification.getStatus').pipe(
          map(({ status }) =>
            updateUserVerificationStatus({
              status: status.split(' ')[1],
            })
          )
        ),
        fromEvent<any>(socket.io, 'verification.sendPhoto').pipe(
          map(({ status }) =>
            updateUserVerificationStatus({
              status: status.split(' ')[1],
            })
          )
        )
        // fromEvent(io, 'user:update').pipe(map(payload => updateUser(payload))),
        // fromEvent(io, 'account:profile:state').pipe(map(payload => updateUserDetails(payload))),
        // fromEvent(io, 'account:email:state').pipe(map((payload: any) => updateUserEmail(payload))),
        // fromEvent(io, 'account:socials:state').pipe(map(payload => updateUserDetails(payload))),
        // fromEvent(io, 'account:experience:state').pipe(map(payload => updateUserExperience(payload))),
        // fromEvent(io, 'account:experience:levelup-result').pipe(map((payload: any) => receiveUserLevel(payload))),
        // fromEvent(io, 'wallet-new.switch').pipe(map(payload => receiveWalletType(payload)))
      )
    )
  );

export const sendVerificationEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(userActionsTypes.FETCH_USER_VERIFICATION),
    pluck('payload', 'file'),
    tap(file => {
      socket.io.emit('verification.sendPhoto', {
        file,
      });
    }),
    ignoreElements()
  );

export const verificationListenerEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(userActionsTypes.UPDATE_USER_VERIFICATION_STATUS),
    pluck('payload', 'status'),
    filter(status => status === 'pending'),
    switchMap(() =>
      of(null).pipe(
        delay(3 * 60 * 1000),
        tap(() => {
          socket.io.emit('verification.getStatus');
        })
      )
    ),
    ignoreElements()
  );

export const permissionEpic: Epic = action$ =>
  action$.pipe(
    ofType(userActionsTypes.FETCH_USER_PERMISSIONS),
    debounceTime(700),
    switchMap(() =>
      UserPermissionsRepository.fetchCheckUser().pipe(
        map(({ response }) => updateUserPermission(response)),
        catchError(() =>
          of(
            updateUserPermission({
              success: false,
              canTrade: { success: false },
              canSteamAPI: { success: false },
            })
          )
        )
      )
    )
  );

export const watchSteamSettings = (url: string) => ({
  type: userActionsTypes.WATCH_STEAM_SETTINGS,
  payload: {
    url,
  },
});

export const watchSettingsEpic: Epic = action$ =>
  action$.pipe(
    ofType(userActionsTypes.WATCH_STEAM_SETTINGS),
    debounceTime(700),
    pluck('payload'),
    map(({ url }) => window.open(url, '_blank')),
    switchMap((settings: Window) =>
      interval(2000).pipe(
        filter(() => settings.closed),
        map(() => ({ type: userActionsTypes.FETCH_USER_PERMISSIONS })),
        take(1)
      )
    )
  );

/// STEAM API KEY LOGIC
export const fetchUpdateApikey = (key: string) => ({
  type: userActionsTypes.FETCH_PATCH_API_KEY,
  payload: {
    key,
  },
});

interface ResponseSteamApiKey {
  success: boolean;
  error?: string;
}
export const apikeyEpic: Epic = action$ =>
  action$.pipe(
    ofType(userActionsTypes.FETCH_PATCH_API_KEY),
    pluck('payload', 'key'),
    switchMap((key: string) =>
      UserPermissionsRepository.fetchUpdateApiKey(key).pipe(
        switchMap(({ response }: { response: ResponseSteamApiKey }) =>
          of(
            fetchUserPermission(),
            actionRequestApiKey(),
            addNotification({
              type: response.success ? 'success' : 'error',
              body: response.success ? 'Valid API key added' : response.error,
            })
          )
        )
      )
    )
  );

/// GET Api key actions

export const actionRequestApiKey = () => ({
  type: userSettingActionTypes.ACTION_REQUEST_API_KEY,
});

export const actionUserSettingsLoading = () => ({
  type: userSettingActionTypes.ACTION_LOADING,
});

export const actionResponseUserSettings = (response: UserSettings) => ({
  type: userSettingActionTypes.ACTION_RESPONSE_USER_SETTINGS,
  payload: response,
});

export const userSettingsEpic: Epic = action$ =>
  action$.pipe(
    ofType(userSettingActionTypes.ACTION_REQUEST_API_KEY),
    switchMap(() =>
      UserSettingRepository.fetchSteamSettings().pipe(
        switchMap(({ response }: { response: UserSettings }) =>
          of(actionResponseUserSettings(response), actionUserSettingsLoading())
        ),
        catchError(() => of(actionUserSettingsLoading()))
      )
    )
  );

interface IFetchUserAvatar extends IUploadAvatar {
  blob: Blob;
}

export const fetchUserAvatar = (response: IFetchUserAvatar) => ({
  type: userActionsTypes.USER_AVATAR,
  payload: response,
});

export const userAvatarEpic: Epic = action$ =>
  action$.pipe(
    ofType(userActionsTypes.USER_AVATAR),
    pluck('payload'),
    switchMap((props: IFetchUserAvatar) =>
      from(
        UserSettingRepository.fetchUserAvatar({
          signedRequest: props.signedRequest,
          imageToken: props.blob,
        })
      ).pipe(
        map(() => updateAvatarBackend(props.imageToken)),
        catchError(({ response }) =>
          of(addNotification({ type: 'error', body: response?.message }))
        )
      )
    )
  );

export const userUserBan: Epic = action$ =>
  action$.pipe(
    ofType(userActionsTypes.UPDATE_USER_BAN_TIME_REQUEST),
    pluck('payload'),
    switchMap((props: string) =>
      UserSelfExclusionRepository.fetchSelfExclusion(props).pipe(
        catchError(({ response }) =>
          of(addNotification({ type: 'error', body: response?.message }))
        )
      )
    )
  );

//referral action

export const actionReferrerUserInfoResponse = (data: IReferralInfo) => ({
  type: userActionsTypes.ACTION_REFERRAL_USER_INFO_RESPONSE,
  payload: data,
});

// Referral epic
export const actionRequestReferralEpic = () => ({
  type: userActionsTypes.ACTION_REFERRAL_USER_INFO_REQUEST,
});

export const referralEpic: Epic = (action$, store$: StateObservable<IStore>) =>
  action$.pipe(
    ofType(userActionsTypes.ACTION_REFERRAL_USER_INFO_REQUEST),
    take(1),
    switchMap(() => {
      const includesR = window.location.href.includes('/r/');
      const includesHash = window.location.href.includes('/#r/');
      const includesSearchParams = window.location.href.includes('/?ref=');
      return includesSearchParams || includesR || includesHash ? promoParamsHandler() : [];
    }),
    map(() => {
      const link = window.location.href;
      const includesHash = window.location.href.includes('/#r/');
      const includesSearchParams = window.location.href.includes('/?ref=');
      return includesSearchParams
        ? link.split('?ref=')
        : includesHash
        ? link.split('/#r/')
        : link.split('/r/');
    }),
    map(([, code]) => {
      if (!!code) {
        const index = code.search(/[^a-zA-Z-_\d]/g);
        return index > 0 ? code.slice(0, index) : code;
      } else {
        return localStorage.getItem('refCode');
      }
    }),
    filter(code => !!code),
    map(code => encodeURIComponent(code)),
    tap(value => localStorage.setItem('refCode', value)),
    switchMap((code: string) =>
      merge(
        UserReferral.referralClick(code).pipe(
          switchMap(({ response }: { response: IReferralInfo }) =>
            iif(
              () => !!store$.value.user.base.get('id'),
              of(actionReferrerUserInfoResponse(response)),
              of(
                actionReferrerUserInfoResponse(response),
                dialogOn(userModals.REFERRAL_BONUS_MODAL_NOT_AUTH)
              )
            )
          ),
          catchError(({ response }) =>
            of(addNotification({ type: 'error', body: response.message }))
          )
        ),
        iif(
          () => !!store$.value.user.base.get('id'),
          UserReferral.referralActivate(code).pipe(
            map(() => dialogOn(userModals.REFERRAL_BONUS_MODAL)),
            catchError(({ response }) =>
              of(addNotification({ type: 'error', body: response.message }))
            ),
            finalize(() => localStorage.removeItem('refCode'))
          )
        )
      ).pipe(catchError(() => of(addNotification({ type: 'error' }))))
    )
  );

//547: if value will come from localStorage it will return empty array
//541: -1 , > 0 =>code
