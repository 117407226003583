import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Toast } from '@components/Animates';
import { AppBar } from '@components/bars';
import { logout } from 'core/User/ducks/duck';
import { useUser } from 'core/User';
import { useExperiences } from 'core/AppShell/hooks';
import {
  ProfileAvatar,
  ProfileDescription,
  ProfileNavigation,
  ProfileLogout,
  ProfileTypeToast,
} from '../../components/Profile';
import { premiumOrders } from '../../personalArea.utils';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';

export const ProfilePage = () => {
  const { user } = useUser();
  const { experiences } = useExperiences();

  /* ToDo
   * relocate handleLogout in auth hook
   * */
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    localStorage.clear();
    dispatch(logout());
  }, [dispatch]);

  const { handleCloseDialog } = useDialog(userModals.PROFILE_PREMIUM);

  useEffect(() => {
    return () => handleCloseDialog();
  }, [handleCloseDialog]);

  return (
    <>
      <AppBar />
      <div className="content dark-layout">
        <ProfileAvatar avatar={user.avatar} profileType={user.profileType} country={user.country} />
        <ProfileDescription user={user} experiences={experiences} />
        <Toast
          initState={premiumOrders.indexOf(user.profileType) < premiumOrders.indexOf('diamond')}
          component={() => <ProfileTypeToast profileType={user.profileType} />}
        />
        <ProfileNavigation />
        <ProfileLogout handleLogout={handleLogout} />
      </div>
    </>
  );
};
