import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { currencyService } from '../../services/currency';
import { Container } from './coin-input.styles';

interface ICoinInput {
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
  register?: (e: HTMLInputElement, params: { required: boolean }) => void;
  required?: boolean;
}

const pattern = new RegExp('^[0-9]*.?[0-9]{0,8}');

export const CoinInput: FC<ICoinInput> = ({ value, setValue, min, max, register, required }) => {
  const inputRef = useRef(null);
  const throttle = useRef(null);
  const [numeric, set] = useState<string>(currencyService.convert(value));

  useEffect(() => {
    if (value === 0) return;
    set(currencyService.convert(value));
  }, [value, set]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const values = e.target.value.match(pattern);
      const v = Array.isArray(values) ? values[0] : '';

      set(v);
      clearTimeout(throttle.current);

      if (e.target.value === '') {
        setValue(0);
        return;
      }

      throttle.current = setTimeout(() => {
        const coins = currencyService.revert(inputRef.current.value || 0);
        if (Number(coins) > max) {
          setValue(max);
        } else if (Number(coins) < min) {
          setValue(min);
        } else {
          setValue(Number(coins));
        }
      }, 800);
    },
    [setValue, max, min]
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const coins = currencyService.revert(e.target.value || 0);
      if (Number(coins) > max) {
        setValue(max);
      } else if (Number(coins) < min) {
        setValue(min);
      } else {
        setValue(Number(coins));
      }
    },
    [setValue, max, min]
  );

  return (
    <Container>
      <input
        type="number"
        inputMode="decimal"
        min={min}
        max={max}
        readOnly={min > max}
        value={numeric}
        onChange={onChange}
        onBlur={onBlur}
        name="coinInput"
        ref={e => {
          inputRef.current = e;

          if (register) register(e, { required: required && !numeric });
        }}
      />
      <span>{currencyService.getCurrencySybmol()}</span>
    </Container>
  );
};
