import React from 'react';
import { Container } from './ChosenCases.styles';
import { CaseListItem } from '../CaseListItem/CaseListItem';

interface ChosenCasesProps {
  caseIds?: {
    uniqId: string;
    id: number;
  }[];
}

export const ChosenCases: React.FC<ChosenCasesProps> = ({ caseIds }) => {
  return (
    <Container>
      {caseIds?.map(({ id, uniqId }) => {
        return <CaseListItem key={uniqId} id={id} uniqId={uniqId} />;
      })}
    </Container>
  );
};
