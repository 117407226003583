export default {
  GAME: 'JOGO',
  Winner: 'Vencedora',
  Win: 'Ganhar',
  Chance: 'Chance',
  'Chance to win': 'Chance to win',
  'At stake': 'Estaca',
  'Sign in through STEAM to participate': 'Inicie sessão através do STEAM para participar',
  'Steam trade link': 'Link de comércio de vapor',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Seja o primeiro a depositar',
  'Place a bet': 'Lugar na aposta',
  'Deposit more': 'Deposite mais',
  'Min bet': 'Aposta mínima',
  'Max bet': 'aposta máxima',
  'Bet amount': 'Valor da aposta',
  'max. items': 'Itens máximos',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',
  'Tickets to from': 'Ingressos: de #{{ from  }} para #{{ to }}',
  Skins: 'Skins',
  Coins: 'Moedas',
  'VIRTUAL SKINS': 'virtuais skins',
  'Round number': 'Número da rodada',
  'Winning after commission': 'Ganhando após a comissão',
  Loading: 'Carregando',
};
