import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { getUserAvatarUrl } from '../../../core/utils/image.utils';

import { getSteamId64 } from '../../../core/User/user.utils';

class _TradeInfo extends PureComponent {
  render() {
    const { bot, code, t } = this.props;
    return (
      <Container>
        <TextTitle>
          {t('You can accept trade offer using steam mobile app, browser or steam client')}
        </TextTitle>
        <TradeInfoBody>
          <AvaWrapper>
            <TradeBotAva src={getUserAvatarUrl(bot.ava_link)} />
          </AvaWrapper>
          {(bot.name || code) && (
            <TradeBotInfo>
              {bot.name && (
                <Fragment>
                  <TextInfo>{t('Trade bot')}:</TextInfo>
                  <TextWarning>{bot.name}</TextWarning>
                </Fragment>
              )}
              {code && (
                <Fragment>
                  <TextInfo>{t('Verification code')}:</TextInfo>
                  <TextWarning>{code}</TextWarning>
                </Fragment>
              )}
            </TradeBotInfo>
          )}
        </TradeInfoBody>
        <ButtonToSteam onClick={this.openTradeOffer}>{t('Open in browser')}</ButtonToSteam>
        <ButtonClose onClick={this.closeModal}>{t('Close')}</ButtonClose>
      </Container>
    );
  }

  closeModal = () => {
    const { removeTradeBot, tradeId } = this.props;
    removeTradeBot(tradeId);
  };

  openTradeOffer = () => {
    const { steamid_3, type, tradeId, removeTradeBot } = this.props;
    if (type === 'vgo') {
      window.open(`//trade.opskins.com/trade-offers/${tradeId}`);
      return removeTradeBot(tradeId);
    }
    window.open(`//steamcommunity.com/profiles/${getSteamId64(steamid_3)}`);
  };
}

export const TradeInfo = withTranslation()(_TradeInfo);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1b262a;

  position: fixed;
  z-index: 9999;
  padding: 26px 15px;
`;

const TextTitle = styled.span`
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--color-white);
`;

const TextInfo = styled.span`
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.5);
`;

const TextWarning = styled.span`
  font-size: 14px;
  letter-spacing: 0.4px;
  color: var(--yellow);
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  border: 0;
  margin: 10px 0;

  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
`;

const ButtonToSteam = styled(Button)`
  background-color: var(--yellow);
  color: var(--color-gray-950);
`;

const ButtonClose = styled(Button)`
  background-color: #225359;
  color: var(--color-white);
`;

const TradeInfoBody = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
`;

const AvaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
`;

const TradeBotAva = styled.img`
  width: 100px;
  height: 100px;
  max-width: none;
`;

const TradeBotInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  & > :nth-child(2n) {
    margin-bottom: 10px;
  }
`;
