import React from 'react';
import str from '../assets/img/str.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const List = ({ match }) => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <FaqQuest>
        <Link to={`${match.url}/common`}>
          <Title>
            {t('General rules and questions')}
            <Icon src={str} />
          </Title>
        </Link>
      </FaqQuest>

      <FaqQuest>
        <Link to={`${match.url}/bet-questions`}>
          <Title>
            {t('Bet questions')}
            <Icon src={str} />
          </Title>
        </Link>
      </FaqQuest>

      <FaqQuest>
        <Link to={`${match.url}/getting-your-win`}>
          <Title>
            {t('Getting your winning')}
            <Icon src={str} />
          </Title>
        </Link>
      </FaqQuest>

      <FaqQuest>
        <Link to={`${match.url}/referral-program`}>
          <Title>
            {t('Referral program')}
            <Icon src={str} />
          </Title>
        </Link>
      </FaqQuest>

      <FaqQuest>
        <Link to={`${match.url}/service-commission`}>
          <Title>
            {t('Service commission')}
            <Icon src={str} />
          </Title>
        </Link>
      </FaqQuest>

      <FaqQuest>
        <Link to={`${match.url}/chat-rules`}>
          <Title>
            {t('Chat rules')}
            <Icon src={str} />
          </Title>
        </Link>
      </FaqQuest>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;

const FaqQuest = styled.div`
  padding: 8px 8px 8px 0px;
  border-bottom: 1px solid #386369;
  margin-left: 10px;
`;

const Title = styled.div`
  padding: 8px 8px 8px 0;
  color: var(--color-white);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.img`
  width: 8px;
  height: 14px;
  margin-top: 5px;
`;
