import React from 'react';
import {
  Container,
  IconContainer,
  SearchInputContainer,
  Title,
  SearchInput,
} from './search.styles';
import { Header } from '../general.styles';
import { SvgIcons } from 'games/CaseGame/assets';
import { useTranslation } from 'react-i18next';

interface SearchProps {
  i18nextKey?: string;
  handleCloseModal: () => void;
  query: string;
  cleanSearchHandler: () => void;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

export const Search: React.FC<SearchProps> = ({
  i18nextKey,
  handleCloseModal,
  changeHandler,
  query,
  cleanSearchHandler,
  children,
}) => {
  const { t } = useTranslation(i18nextKey);
  return (
    <Container className="content dark-layout">
      <Header>
        <SvgIcons.BackIcon onClick={handleCloseModal} />
        <Title>{t('search.searchTitle')}</Title>
      </Header>
      <SearchInputContainer>
        {query && (
          <IconContainer>
            <SvgIcons.CancelIconBLack onClick={cleanSearchHandler} />
          </IconContainer>
        )}
        <SearchInput
          value={query}
          onChange={changeHandler}
          type="text"
          placeholder={t('search.placeholder')}
        />
      </SearchInputContainer>
      {children}
    </Container>
  );
};
