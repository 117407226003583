import React, { useMemo, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { useForm } from 'react-hook-form'
import dayjs from 'dayjs';
// import { TabBar } from '@UIkit'
import { CurrencyText } from '@components/currency';
import { IReferralCampaign, IReferralCampaignMember } from '../../../../interfaces';

interface IReferralCampaignRating {
  campaign: IReferralCampaign;
}
//
// const STATUSES = ['active', 'lost']
//
// type IStatuses = 'active' | 'lost'

const TOP_FIVE_MEMBERS = Array.from({ length: 5 }, (_, i) => i + 1);

export const ReferralCampaignRating: FC<IReferralCampaignRating> = ({ campaign }) => {
  const { t } = useTranslation();
  // const { register, watch } = useForm<{ status: IStatuses }>({
  //   mode: 'onChange',
  //   defaultValues: {
  //     status: 'active',
  //   },
  // })

  const ratings = useMemo<IReferralCampaignMember[]>(() => {
    const filtered = campaign.referrals.filter(v => !!v.rewardCoins);
    return filtered.sort((l, r) => (l.rewardCoins - r.rewardCoins > 0 ? -1 : 1));
    // return sorted.reduce(
    //   (acc, item) => ({
    //     ...acc,
    //     [item.status]: [...acc[item.status], item],
    //   }),
    //   {
    //     active: [],
    //     inactive: [],
    //     lost: [],
    //   }
    // )
  }, [campaign.referrals]);

  // const status: IStatuses = watch('status')

  return (
    <Container>
      {/*<TabBar>*/}
      {/*  {STATUSES.map((key: string) => (*/}
      {/*    <Fragment key={key}>*/}
      {/*      <label className={`tab-item ${status === key ? 'active' : ''}`}>*/}
      {/*        <input type="radio" name="status" value={key} ref={register} />*/}
      {/*        {t('ratings.rating', { context: key })}*/}
      {/*      </label>*/}
      {/*    </Fragment>*/}
      {/*  ))}*/}
      {/*</TabBar>*/}
      <div className="rating-labels">
        <span>{t('Top 5')}</span>
        <span>{t('Payments')}</span>
      </div>
      <div className="rating-list">
        <>
          {ratings.length ? (
            <>
              {TOP_FIVE_MEMBERS.map(
                (_, i) =>
                  !!ratings[i] && (
                    <div key={ratings[i].id} className="rating-list-item">
                      <div className="rating-list-item-position">{i + 1}</div>
                      <div
                        className="rating-list-item-thumbnail"
                        style={{
                          borderColor: `var(--color-account-type-${ratings[i].user.profileType})`,
                        }}
                      >
                        <img src={ratings[i].user.avatar} alt="" />
                      </div>
                      <div className="rating-list-item-detailed">
                        <span>{ratings[i].user.name}</span>
                        <span className={ratings[i].status}>
                          {t('member statuses.status', {
                            context: ratings[i].status,
                          })}
                          {ratings[i].deletedAt && (
                            <>
                              {' '}
                              {t('from')} {dayjs(ratings[i].deletedAt).format('DD.MM.YY')}
                            </>
                          )}
                        </span>
                      </div>
                      <div className="rating-list-item-profit">
                        <CurrencyText
                          value={ratings[i].rewardCoins + ratings[i].rewardCoinsTaken}
                        />
                      </div>
                    </div>
                  )
              )}
            </>
          ) : (
            <div className="rating-list-empty">
              {/*{status === 'active' && (*/}
              <>
                <span>{t('There no referrals yet')}</span>
                <span>{t('But they will be soon')}</span>
              </>
              {/*)}*/}
              {/*{status === 'lost' && <span>{t('There no lost referrals')}</span>}*/}
            </div>
          )}
        </>
      </div>
    </Container>
  );
};

const Container = styled.div`
  min-height: 360px;
  background: var(--color-dark-600);
  margin-bottom: 2.5rem;

  & > div:first-of-type:not(:last-of-type) {
    min-height: 3rem;
    align-content: end;
    align-self: self-end;

    & .inner-tab-bar-container {
      grid-template-rows: 1.5rem;
      min-height: 1.75rem;
    }

    & input {
      display: none;
    }
  }

  & .rating-labels {
    display: grid;
    grid-auto-flow: column;
    height: 2rem;
    align-content: end;
    padding: 0 1.25rem;

    & span {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);

      &:last-of-type {
        justify-self: self-end;
      }
    }
  }

  & .rating-list {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 3.25rem;

    &-empty {
      grid-row: 1 / 5;
      display: grid;
      grid-auto-rows: min-content;
      grid-auto-flow: row;
      grid-gap: 1rem;
      align-self: center;
      justify-content: center;
      transform: translate3d(0, -0.625rem, 0);

      & span {
        text-align: center;

        &:first-of-type {
          font-size: 1.25rem;
          line-height: 1.5rem;
          color: var(--color-white);
        }

        &:last-of-type:not(:first-of-type) {
          font-size: 0.875rem;
          letter-spacing: 0.24px;
          color: var(--color-white-600);
        }
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 2rem min-content 1fr min-content;
      height: 3.25rem;

      &-position {
        justify-self: center;
        align-self: center;

        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        color: var(--color-gray-200);
      }

      &-thumbnail {
        justify-self: center;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        border: 2px solid;

        & img {
          width: 2rem;
          height: 2rem;
          border-radius: inherit;
        }
      }

      &-detailed {
        display: grid;
        grid-auto-flow: row;
        align-self: center;
        grid-gap: 0.25rem;
        margin-left: 0.5rem;

        & span {
          font-size: 0.625rem;

          &:first-of-type {
            color: var(--color-white);
          }

          &:last-of-type {
            font-weight: bold;
            color: var(--status-color);

            &.active {
              --status-color: #00bad9;
            }

            &.lost {
              --status-color: #9ea7b3;
            }

            &.inactive {
              --status-color: #9ea7b3;
            }
          }
        }
      }

      &-profit {
        align-self: center;
        margin-right: 1.125rem;
        font-size: 0.625rem;
        line-height: 0.875rem;
        color: var(--color-white);
      }
    }
  }
`;
