import * as React from 'react';
import styled from 'styled-components';

const DottedCircle = ({ crashed, blurValue }) => (
  <DottedContainer>
    <svg
      version="1.1"
      id="Objects"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="740"
      height="740"
      viewBox="0 0 704 704"
      xmlSpace="preserve"
    >
      <defs>
        <filter id="blur">
          <feGaussianBlur
            in="SourceGraphic"
            stdDeviation={crashed ? 0 : Math.min(blurValue / 9, 3)}
          />
        </filter>
      </defs>
      <path
        fill="none"
        stroke={crashed ? 'var(--color-red-200)' : 'rgba(255,255,255, .3)'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,24.9807"
        d="M699.3,327.5C686.5,134.5,519.6-11.4,326.8,1.6C133.9,14.3-12,181.2,0.9,374c12.9,193,179.7,338.9,372.5,326
			C566.3,687.2,712.2,520.3,699.3,327.5z"
      />
    </svg>
  </DottedContainer>
);

const DottedContainer = styled.div`
  width: 740px;
  height: 740px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% + 2px), calc(-50% + 2px));
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DottedCircle;
