import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 40px;
`;

export const HistoryHead = styled.span`
  color: var(--color-gray-200);
  display: block;
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  line-height: 15px;
  padding: 33px 16px 12px;
  text-transform: uppercase;
`;

export const Item = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  border-bottom: 1px solid var(--color-dark-100);
  display: flex;
  gap: 10px;
  padding: 16px 14px;
`;

export const IconWrap = styled.div`
  flex-shrink: 0;
  height: 40px;
  width: 40px;
`;

export const ItemInfo = styled.div`
  width: 100%;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`;

export const ItemName = styled.div`
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.24px;
`;

export const Xp = styled.div`
  color: var(--green-100);
  font-size: 14px;
  font-weight: 700;
`;

export const Date = styled.div`
  color: var(--color-gray-200);
  font-size: 14px;
  letter-spacing: 0.24px;
`;
