import {
  useGetRouletteStatus,
  useGetWinItemsStatus,
  useGetWinningItem,
} from 'games/CaseGame/hooks';
import React from 'react';
import { Container } from './SellButtonDecorator.styles';
import { SellButton } from '../../../SellButton';

export const SellButtonDecorator = () => {
  const { isAllAfterRoll, isAllStartRoll } = useGetRouletteStatus();
  const { isAllSold } = useGetWinItemsStatus();
  const { winningItems } = useGetWinningItem();
  return (
    <Container
      data-roulette-status={!isAllAfterRoll || isAllSold || isAllStartRoll ? 'hide' : 'show'}
    >
      {!!winningItems && (
        <SellButton
          winningItemsPrice={winningItems[0]?.winningPrice}
          winningItemsIds={[winningItems[0]?.userInventoryItemId]}
        />
      )}
    </Container>
  );
};
