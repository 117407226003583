import React, { createElement, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SteamErrorsEnum } from 'core/User';
import * as Icons from '../icons';

interface ISettingUpStepperHeader {
  idx: number;
}

interface IISettingUpStepperHeaderStyle {
  idx: number;
}

const STEPS_ITEMS = new Map([
  [SteamErrorsEnum.UNBINDING_STEAM, Icons.SteamBinding],
  [SteamErrorsEnum.STEAM_GUARD, Icons.SteamGuard],
  [SteamErrorsEnum.PRIVATE_INVENTORY, Icons.SteamGuard],
  [SteamErrorsEnum.INVALID_TRADELINK, Icons.SteamTradeLink],
  [SteamErrorsEnum.INVALID_API_KEY, Icons.SteamApiKey],
]);

const STEPS = Array.from(STEPS_ITEMS.entries());

export const SettingUpStepperHeader: FC<ISettingUpStepperHeader> = ({ idx }) => {
  const { t } = useTranslation('steam-settings');

  return (
    <Container idx={idx}>
      <div className="step-header">
        <span>{t('Your Steam data')}</span>
      </div>
      <div className="step-list">
        {STEPS.map(([key, component], i) => (
          <div key={key} className={`${key} ${idx > i && 'passed'} step-list-item`}>
            {createElement(component)}
            <span className="step-list-item-text">{t(`stepper-dialog.steps.${key}`)}</span>
            <div className="step-list-item-status">{idx <= i ? '!' : 'ok'}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div<IISettingUpStepperHeaderStyle>`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-gap: 1rem;
  height: 10rem;
  background: var(--color-dark-100);

  & .step-header {
    display: flex;
    align-items: flex-end;
    padding: 0 1.25rem;
    height: 3.125rem;

    & span {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-gray-50);
    }
  }

  & .step-list {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    border-bottom: 1px solid var(--color-gray-900);
    position: relative;

    &:before {
      content: '';
      display: block;
      width: ${100 / STEPS.length}%;
      height: 0.125rem;
      position: absolute;
      bottom: 0.125rem;
      left: ${({ idx }) => (100 / STEPS.length) * idx}%;
      background: var(--color-white);
    }

    &-item {
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: 1.75rem 1fr min-content;
      justify-content: center;
      grid-gap: 0.375rem;
      align-self: stretch;
      padding: 0 0.125rem 0.875rem;

      &.passed .step-list-item-status {
        color: var(--color-green);
      }

      & svg,
      &-text,
      &-status {
        justify-self: center;
      }

      &-text {
        font-size: 0.635rem;
        line-height: 0.875rem;
        text-align: center;
        letter-spacing: 0.1px;
        color: var(--color-white);

        @media screen and (min-width: 375px) {
          font-size: 0.7rem;
        }
      }

      &-status {
        color: var(--primary);
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 14px;
        letter-spacing: 0.3px;
      }
    }
  }
`;
