import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CoefficientDecorator from '../../../decorators/Coefficient';
import { CurrencyText } from '../../../../../@components/currency';
import SoundService from 'services/sound.service';
import {
  ArrowSvg,
  AutoStopValue,
  BetBlock,
  BetTitle,
  BetValue,
  CoefBlock,
  CoefInfo,
  CrashTitle,
  PointerArrow,
} from './GameProgress.styles';

const sound = new SoundService();

interface ITimer {
  appDiffTime: number;
  coef: number | null;
  crashed: boolean;
  currentCoef: number;
  gameRun: boolean;
  gameRunTs: number;
  userBet: {
    amount: number;
    coef: number;
    coefAutoStop: number;
    isSet: boolean;
  };
}

const Timer: FC<ITimer> = ({ userBet, crashed, currentCoef }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (currentCoef === 1) {
      sound.play('Crash', 'Game start');
    }
  }, [currentCoef]);

  useEffect(() => {
    sound.play('Crash', 'Sound track', {
      repeat: true,
    });

    if (crashed) {
      sound.play('Crash', 'Crash button');
      sound.play('Crash', 'Sound track', {
        repeat: false,
      });
    }

    return () => {
      sound.play('Crash', 'Sound track', {
        repeat: false,
      });
    };
  }, [crashed]);

  return (
    <CoefBlock>
      <BetBlock>
        {crashed ? (
          <CrashTitle>{t('!! CRASH !!')}</CrashTitle>
        ) : (
          <>
            <BetTitle>{t('BET')}</BetTitle>
            <BetValue>
              <CurrencyText value={userBet.amount * 100} />
            </BetValue>
            {userBet.coef > 0 && <AutoStopValue>{`x${userBet.coef}`}</AutoStopValue>}
          </>
        )}
      </BetBlock>
      <CoefInfo>{`x${currentCoef.toFixed(2)}`}</CoefInfo>
      <PointerArrow crashed={crashed}>
        <ArrowSvg
          color={
            crashed ? 'var(--color-red-200)' : userBet.coef ? 'var(--ochre)' : 'var(--aqua-500)'
          }
        />
      </PointerArrow>
    </CoefBlock>
  );
};

export default CoefficientDecorator(Timer);
