import React, { FC, useLayoutEffect, useMemo } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { FastInventory } from './components';
import { useDispatch } from 'react-redux';
import {
  attachExchange,
  detachExchange,
  registerStockModule,
  unregisterStockModule,
} from './ducks';

export const FastInventoryRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  useMemo(() => {
    registerStockModule();
    dispatch(attachExchange());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch(detachExchange());
      unregisterStockModule();
    };
  }, [dispatch]);

  return (
    <>
      <AppBar deep />
      <Route path={match.url} component={FastInventory} />
    </>
  );
};
