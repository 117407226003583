import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { createReferralCampaigns } from '../../ducks/referral.duck';

export const useCreateReferralCampaigns = () => {
  const dispatch = useDispatch();

  const handleCreateCampaigns = useCallback(
    ({ name, code }) => dispatch(createReferralCampaigns(name, code)),
    [dispatch]
  );

  return {
    handleCreateCampaigns,
  };
};
