export default {
  'Fair game': 'Jogo Justo',
  Bet: 'Aposta',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = desativado)',
  'Bet coins': 'Moedas de aposta',
  Stop: 'Pare',
  'Wait for next round...': 'Espera pela próxima ronda...',
  Example: 'Exemplo',
  'You won': 'Você ganhou',
};
