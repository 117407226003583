import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfilePanel } from '@components/UserProfile';
import { getUserAvatarUrl } from 'core/utils/image.utils';
import { Timer } from '@components/TimerEvent';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';

interface IAccountProfilePanel extends IUserInfo {
  handlePushToAccount: () => void;
}

export const AccountProfilePanel: FC<IAccountProfilePanel> = ({
  avatar,
  name,
  profileType,
  experience,
  premium,
  handlePushToAccount,
}) => {
  const { t } = useTranslation();
  return (
    <UserProfilePanel profileType={profileType} exp={0} as="button" onClick={handlePushToAccount}>
      <div className="user-avatar">
        <img src={getUserAvatarUrl(avatar, 50)} alt="" />
      </div>
      <div className="user-info">
        <span className="user-name">{name} </span>
        <span className="user-lvl">{experience.level} lvl</span>
        <span className="user-account-type">
          {t(profileType)} {premium.until && <Timer finishEvent={premium.until} />}
        </span>
      </div>
    </UserProfilePanel>
  );
};
