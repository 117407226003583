import { gif } from 'games/CaseGame/assets';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styled from 'styled-components';

const styleObjForRoulette: Record<number, string> = {
  1: 'height: 168px; width: 168px; @media only screen and (min-width: 500px) {height: 270px; width: 270px;}',
  2: 'height: 168px; width: 168px; gap: 10px; @media only screen and (max-height: 667px) and (max-width: 500px) {height: 154px; width: 154px;} @media only screen and (min-width: 500px) {height: 270px; width: 270px;}',
  3: 'height: 135px;  width: 260px; flex-direction: row; @media only screen and (max-height: 667px) and (max-width: 500px) {height: 85px; width: 168px;} @media only screen and (min-width: 500px) {height: 150px; width: 316px;}',
  4: 'height: 99px;  width: 260px; flex-direction: row; @media only screen and (max-height: 667px) and (max-width: 500px) {height: 65px; width: 168px;} @media only screen and (min-width: 500px) {height: 110px; width: 276px;}',
  5: 'height: 78px;  width: 260px; flex-direction: row; @media only screen and (max-height: 667px) and (max-width: 500px) {height: 53px; width: 168px;} @media only screen and (min-width: 500px) {height: 110px; width: 276px;}',
};

const styleObjForRouletteImgSize: Record<number, string> = {
  1: 'width: 112px; height: 74px;',
  2: 'width: 112px; height: 74px; @media only screen and (max-height: 667px) {width: 80px; height: 64px;}',
  3: 'width: 112px; height: 74px; @media only screen and (max-height: 667px) {height: 60px; width: 74px;}',
  4: 'width: 98px; height: 76px; @media only screen and (max-height: 667px) {height: 60px; width: 74px;}',
  5: 'width: 98px; height: 76px; @media only screen and (max-height: 667px) {height: 46px; width: 60px;}',
};

const styleObjForFontSize: Record<number, string> = {
  1: 'font-size: 13px; line-height: 17px; @media only screen and (min-width: 500px) {font-size: 16px; line-height: 22px}',
  2: 'font-size: 13px; line-height: 17px; @media only screen and (min-width: 500px) {font-size: 16px; line-height: 22px}',
  3: 'font-size: 12px; line-height: 14px; @media only screen and (max-height: 667px) {font-size: 9px;} @media only screen and (min-width: 500px) {font-size: 16px; line-height: 22px}',
  4: 'font-size: 10px; line-height: 12px; @media only screen and (max-height: 667px) {font-size: 7px;} @media only screen and (min-width: 500px) {font-size: 15px; line-height: 20px}',
  5: 'font-size: 10px; line-height: 12px; @media only screen and (max-height: 667px) {font-size: 7px;} @media only screen and (min-width: 500px) {font-size: 15px; line-height: 20px}',
};

interface ContainerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  lineCount: number;
  bgColor: string;
  isAnimationEnd: boolean;
}

export const Container = styled.div.attrs<ContainerProps>(props => ({
  style: {
    background: `linear-gradient(180deg, var(--color-dark-200) 0%, ${props.color} 57.81%, ${props.color} 100%)`,
  },
}))<{ lineCount: number; isAnimationEnd: boolean }>`
  align-items: center;
  background: var(--color-dark-200);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: ${p => (p.lineCount > 3 ? '108px' : '168px')};
  justify-content: center;
  position: relative;
  transition: opacity 0.3s ease-in-out;
  ${({ isAnimationEnd }) => isAnimationEnd && 'opacity:0.5;'}
  ${p => styleObjForRoulette[p.lineCount]}
`;

//NOTE: for event image
export const Prize = styled.img`
  height: 90%;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
`;

export const Icon = styled.img<{ lineCount: number }>`
  z-index: 2;
  ${p => styleObjForRouletteImgSize[p.lineCount]}
`;

export const IconContainer = styled.div<{ isBig: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  transition: margin-bottom 0.3s ease, margin-right 0.3s ease;

  &[data-status='inRoll'] {
    ${({ isBig }) => (isBig ? 'margin-right: 0px;' : 'margin-bottom: 0;')}
  }

  &[data-status='notRoll'] {
    ${({ isBig }) => (isBig ? 'margin-right: 50%;' : 'margin-bottom: 50px;')}
  }
`;

export const SoldIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 3;

  &[data-visible='visible'] {
    backdrop-filter: blur(5px);
  }

  &[data-visible='hide'] {
    backdrop-filter: none;
  }

  & > .svg {
    &[data-visible='visible'] {
      opacity: 1;
    }

    &[data-visible='hide'] {
      opacity: 0;
    }

    transition: opacity 0.3s ease;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  width: 100%;
`;

export const TextSpan = styled.span<{ lineCount: number }>`
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.64px;
  overflow: hidden;
  text-align: ${({ lineCount }) => (lineCount >= 3 ? 'left' : 'center')};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  ${p => styleObjForFontSize[p.lineCount]};
`;

export const SkinSpan = styled(TextSpan)<{ isBig: boolean }>`
  color: var(--gray-550);
`;

export const TextContainer = styled.div<{ isBig: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 35%;
  justify-content: center;
  position: absolute;
  transition: opacity 0.25s ease, bottom 0.3s ease-in-out, right 0.3s ease;
  transition-delay: 0.125s;

  &[data-status='inRoll'] {
    pointer-events: none;
    opacity: 0;
    ${({ isBig }) =>
      isBig
        ? 'width: 50%; right: -20px;  height: 55%;  align-items:flex-start; padding: 15px;'
        : 'width: 80%;  bottom: -10px; '}
  }

  &[data-status='notRoll'] {
    opacity: 1;
    ${({ isBig }) =>
      isBig
        ? 'width: 50%;right: 0px; height: 55%; align-items:flex-start; padding: 15px;'
        : 'width: 80%; bottom: 5px; '}
  }
`;

export const StyledButton = styled.button<{
  lineCount: number;
}>`
  -webkit-box-pack: center;
  align-items: center;
  background: var(--yellow);
  border-radius: 4px;
  border: 0;
  color: var(--color-dark-600);
  display: flex;
  height: 20px;
  justify-content: center;
  min-width: 60px;
  transition: opacity 0.3s ease, margin-top 0.3s ease, margin-right ease;
  transition-delay: 0.1s;
  width: fit-content;

  &[data-line-count-button='BIG'] {
    margin-top: 12px;
    padding: 5px;

    @media only screen and (max-width: 400px) {
      margin-top: 6px;
      padding: 0;
    }
  }

  &[data-line-count-button='SMALL'] {
    margin-top: 4px;
  }

  &[data-visible='visible'] {
    opacity: 1;
  }

  &[data-visible='hide'] {
    display: none;
    opacity: 0;
    pointer-events: none;
  }

  &:disabled {
    background: transparent;
    color: var(--yellow);
  }
  ${p => styleObjForFontSize[p.lineCount]}
`;

export const ProfitBlock = styled.div`
  background: url(${gif.profit}) center;
  height: 100%;
  position: absolute;
  width: 100%;
`;

// export const LuckyWonCase = styled.div`
//   width: 100%;
//   height: 100%;
//   opacity: 0.6;
//   background: url(${eventImagesPath.rareGiftImagePath}) center;
//   background-size: contain;
//   background-repeat: no-repeat;
//   position: absolute;
// `;
