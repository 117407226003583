import React from 'react';
import { StyledDemoButton } from './ButtonDemoRollAgain.styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openCaseActionRequest, rouletteStatusActionAllStartRoll } from 'games/CaseGame/ducks';

interface ButtonDemoRollAgainProps {
  lineCount: number;
}

export const ButtonDemoRollAgain: React.FC<ButtonDemoRollAgainProps> = React.memo(
  ({ lineCount }) => {
    const { t } = useTranslation('cases2.0');
    const { caseId } = useParams<Record<string, string>>();
    const dispatch = useDispatch();

    const clickHandler = () => {
      if (lineCount) {
        dispatch(rouletteStatusActionAllStartRoll());
        dispatch(
          openCaseActionRequest({
            caseId,
            type: 'demo',
            lineCount,
            isFree: false,
          })
        );
      }
    };

    return <StyledDemoButton onClick={clickHandler}>{t('demoSpin')}</StyledDemoButton>;
  }
);
