/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'เกม',
  Chat: 'แชท',
  'Email Support': 'การสนับสนุนทางอีเมล',
  'Live Support Chat': 'แชทสนับสนุนสด',
  History: 'ประวัติ',
  Top: 'บนสุด',
  Rules: 'Rules',
  'At stake': 'รวมเดิมพัน',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'เพื่อเขียนข้อความ คุณจำเป็นต้องเข้าสู่ระบบ',
  'Only players who have played the game are allowed to write':
    'เฉพาะผู้เล่นที่เล่นเกมตั้งแต่หนึ่งเกมขึ้นไปบนเว็บไซต์เท่านั้นที่สามารถแชทได้',
  'You are banned': 'คุณถูกแบน',
  Reason: 'เหตุผล',
  'Time left': 'คงเหลือ',
  'Time to summarizing': 'เวลาสู่บทสรุป',
  spam: 'สแปม',
  'advertising (auto)': 'โฆษณา (อัตโนมัติ)',
  advertising: 'โฆษณา',
  rudeness: 'ความก้าวร้าว',
  begging: 'การขอทาน',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'วันนี้',
  Yesterday: 'เมื่อวานนี้',
  'Last year': 'ปีที่แล้ว',
  'Show my wins': 'แสดงชัยชนะของฉัน',
  'History is empty': 'ไม่มีประวัติ',
  Cancel: 'ยกเลิก',
  Delete: 'ลบ',
  Close: 'ปิด',
  Confirm: 'ยืนยัน',
  'Are you sure to delete all notifications?': 'คุณแน่ใจหรือว่าจะลบการแจ้งเตือนทั้งหมด?',
  'Log in': 'เข้าสู่ระบบ',
  'Log in to try your luck': 'เข้าสู่ระบบเพื่อลองเสี่ยงโชค',
  'try your luck!': 'ลองเสี่ยงโชคดู',
  'Take your prize': 'รับรางวัล',
  'Sell for': 'ขายราคา',
  BACK: 'BACK',
  'Warning!': 'โปรดทราบ',
  'Congratulations!': 'ขอแสดงความยินดีด้วย',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'ขอแสดงความยินดีกับชัยชนะของคุณ ข้อเสนอในการเทรดพร้อมกับรางวัลถูกส่งไปยังบัญชีของคุณแล้ว',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'การเทรดจะถูกยกเลิกหลังจาก 1 ชั่วโมง',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'หลังจากที่รายการถูกดำเนินการ คุณสามารถรวบรวมชัยชนะของคุณที่หน้าส่งข้อมูลของการแลกเปลี่ยนสตีม',

  'Round number': 'จำนวนรอบ',
  'Round hash': 'แคชของรอบ',
  'Hash salt': 'ซอล แคช',

  'Refill amount': 'จำนวนเงินที่เติม',
  Bonus: 'โบนัส',
  'Bonus experience': 'โบนัสเอ็กซ์พีเรียนซ์',
  'Bonus wheel spins': 'โบนัสหมุนวงล้อ',
  'Bonus cases': 'โบนัสเคส',
};
