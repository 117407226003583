import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: var(--color-dark-600);
  border: 1px solid var(--color-gray-900);
  padding: 28px 16px;
  border-radius: 3px;
  ${p => !p.isOpen && 'opacity: 0;'}
  transition: opacity 0.4s;
  position: relative;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 0 0 13px;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const Span = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: var(--color-white);
`;
export const Title = styled(Span)`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.24px;
  text-align: center;
`;

export const ConfirmButton = styled(Button)`
  background: var(--color-dark-200);
  width: 100%;
  height: 48px;
  margin-bottom: 11px;
  color: var(--color-white);
`;
export const DeclineButton = styled(Button)`
  background: var(--yellow);
  color: var(--color-dark-600);
  width: 100%;
  height: 48px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 11px;
  right: 13px;
  color: var(--color-gray-200);
  font-size: 12px;
`;
