import { appShellModule as app } from './AppShell/index';
import { crossModule as cross } from './CrossServices/index';
import { modalsModule as modals } from './Modals';
import { userModule as user } from './User/index';
import { chatModule as chat } from '@components/Chat';
import { historyModule as history } from '@components/History';
import { prizeModule as prize } from '@components/Prize';
import { topModule as top } from '@components/Top';
import { tradeBotsModule as tradeBots } from '@components/TradeBot';

const core = {
  chat,
  app,
  prize,
  cross,
  top,
  history,
  tradeBots,
  modals,
  user,
};

export default core;
