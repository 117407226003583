import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #182328;
  border-radius: var(--size-radius-l);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 16px;
  padding: 64px 46px 32px;
  position: relative;
`;

export const Image = styled.img`
  width: 143px;
  left: 50%;
  position: absolute;
  top: -45px;
  transform: translateX(-50%);
  height: 93px;
`;

export const Header = styled.span`
  color: #ebf3ff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

export const Description = styled.span`
  color: #f7fbfc;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: center;
`;
