import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getAppCurrency, getAppCurrencySettings } from '../selectors';
import { setCurrency } from '../duck';

export const useCurrency = () => {
  const currency = useSelector(getAppCurrency, shallowEqual);
  const currencies = useSelector(getAppCurrencySettings, shallowEqual);
  const dispatch = useDispatch();

  const handleChangeCurrency = useCallback(
    (index: number) => {
      dispatch(setCurrency(currencies[index].key));
    },
    [dispatch, currencies]
  );

  return {
    currency,
    currencies,
    handleChangeCurrency,
  };
};
