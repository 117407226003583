import styled from 'styled-components';

export const SoundItem = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  display: flex;
  height: 60px;
  padding: 18px 14px;
  position: relative;
`;

export const CheckBox = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  & + label {
    cursor: pointer;
    padding: 2px 0 0 66px;
    position: relative;
    z-index: 2;
  }

  & + label::before {
    background: var(--color-white-gray);
    border-radius: 20px;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    content: '';
    height: 32px;
    left: 0;
    position: absolute;
    top: -15px;
    transition: 0.2s;
    width: 52px;
  }

  & + label::after {
    background: var(--color-white);
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 28px;
    left: 3px;
    position: absolute;
    top: -13px;
    transition: 0.2s;
    width: 28px;
  }

  &:checked + label::before {
    background: var(--yellow);
  }

  &:checked + label::after {
    background: var(--color-dark-50);
    left: 22px;
  }
`;

export const CheckBoxTitle = styled.span`
  align-items: center;
  color: var(--color-white);
  display: flex;
  font-size: 14px;
  z-index: 2;
`;

export const SoundIconsContainer = styled.div`
  display: flex;
  margin-left: auto;
  z-index: 2;
`;
