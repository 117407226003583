import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

class _SortDelivery extends Component {
  render() {
    const { checked, t } = this.props;
    return (
      <Container>
        <input
          className="checkboxBlue"
          id="checkbox10"
          type="checkbox"
          checked={checked}
          onChange={this.changeFilter}
        />
        <Label htmlFor="checkbox10">
          <span>{t('Show only items with fast delivery')}</span>
        </Label>
      </Container>
    );
  }

  changeFilter = () => {
    const { checked, updateFilter, requestAction } = this.props;
    updateFilter({ skinpay: !checked });
    requestAction(true);
  };
}

export const SortDelivery = withTranslation()(_SortDelivery);

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  padding: 0 10px;
  position: relative;
`;

const Label = styled.label`
  & > span {
    color: #fff;
    font-size: 0.75rem;
    line-height: 1.9;
  }
`;
