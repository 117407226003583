import { animated } from 'react-spring';
import styled from 'styled-components';

export const AnimatedContainer = styled(animated.div)`
  flex-shrink: 0;
  position: relative;
`;

export const Wrapper = styled.div<{ open: boolean; status: string }>`
  overflow: ${({ open }) => (open ? 'visible' : 'hidden')};
  animation: ${({ status }) => (status === 'hide' ? 'hide-slider 1500ms ease forwards' : '')};

  @keyframes hide-slider {
    0% {
      overflow: visible;
    }

    100% {
      overflow: hidden;
    }
  }
`;

export const ToggleIcon = styled.div<{
  open: boolean;
}>`
  align-items: center;
  color: var(--color-gray-200);
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform-origin: center;
  transform: ${({ open }) => `translateY(-50%) rotate(${open ? 360 : 180}deg)`};
  transition: transform 0.3s linear;
`;
