import { CaseList } from 'games/CaseGame/interfaces/cases.interface';

export const enum FreeCaseAction {
  ACTION_REQUEST_FREE_CASE_INFO = 'app/freeCaseInfo/sale-out/ACTION_REQUEST_FREE_CASE',
  ACTION_RESPONSE_FREE_CASE_INFO = 'app/freeCaseInfo/sale-out/ACTION_RESPONSE_FREE_CASE_INFO',

  FREE_CASE_INFO_ATTACH_LISTENERS = 'app/freeCaseInfo/STOCK_ATTACH_LISTENERS',
  FREE_CASE_INFO_DETACH_LISTENERS = 'app/freeCaseInfo/STOCK_DETACH_LISTENERS',
}

export interface ActionRequestFreeCaseInfo {
  type: FreeCaseAction.ACTION_REQUEST_FREE_CASE_INFO;
}

export interface ActionResponseFreeCaseInfo {
  type: FreeCaseAction.ACTION_RESPONSE_FREE_CASE_INFO;
  payload: CaseList;
}

export interface IFreeCaseInfo {
  count: number;
  price: number;
  loaded: boolean;
}
