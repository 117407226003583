import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, EmptyBlock, TabBtn, TabContainer, TabImg, TabSpan } from './tab-bar.styles';
import { Tab } from './tab';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { Balance } from '../balance';

const Tabs = [
  {
    id: 0,
    name: 'comicsCollection',
    imgPath: eventImagesPath.comicsPath,
  },
  {
    id: 1,
    name: 'missions',
    imgPath: eventImagesPath.winnerPath,
  },
];

export const TabBar: FC = () => {
  const { t } = useTranslation();
  const currentTab: number = +JSON.parse(JSON.stringify(localStorage.getItem('activeTab'))); //by default 0

  const [activeTab, setActiveTab] = useState(currentTab);

  const handleClick = (tab: number) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      localStorage.setItem('activeTab', tab.toString());
    }
  };

  return (
    <>
      <Container>
        {Tabs.map(({ id, name, imgPath }) => {
          return (
            <TabBtn key={id} onClick={() => handleClick(id)} isActive={id === activeTab}>
              <TabContainer>
                <TabSpan>{t(name)}</TabSpan>
                {id === 1 && <Balance />}
                {id === 2 && <EmptyBlock />}
              </TabContainer>
              <TabImg src={imgPath} data-name={name} alt="" />
            </TabBtn>
          );
        })}
      </Container>
      <Tab activeTab={activeTab} />
    </>
  );
};
