import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 90.39px 19px 39px;
  width: 100%;

  & > svg {
    fill: var(--color-dark-200);
    margin: 70px 0 62px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.span`
  color: #fac106;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

export const Rules = styled.div`
  & > span {
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;

    & > span.accent-first {
      color: #599aff;
    }

    & > span.accent-second {
      color: #fac106;
    }
  }
`;
