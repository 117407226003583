import { List, Map, OrderedMap, Record } from 'immutable';

export const RacesList = List();

export const RaceRecord = Record({
  id: '',
  raceType: '',
  prizeCoinsAmount: 0,
  prizeTicketsAmount: 0,
  startAt: '',
  finishAt: '',
  leaderboard: [],
  myStats: null,
});

export const LeaderBoardRecord = Record({
  userId: 0,
  name: '',
  avaLink: '',
  points: 0,
  type: 'bronze',
  place: 0,
  prizeTicketsAmount: 0,
  prizeCoinsAmount: 0,
});

export type IRacesList = List<IRaceRecord>;

export interface IRaceRecord {
  id: string;
  raceType: string;
  prizeCoinsAmount: number;
  prizeTicketsAmount: number;
  startAt: string;
  finishAt: string;
  leaderboard: ILeaderBoardItem[];
  myStats: null | IMyStats;
}

export interface IMyStats {
  position?: string;
  place?: number;
  tickets?: number;
  ticketsToNextPlace?: null | number;
  ticketsToTop100?: number;
  points?: number;
  pointsToNextPlace?: null | number;
  pointsToTop100?: number;
}

export interface ILeaderBoardItem {
  userId: number;
  name: string;
  avaLink: string;
  points: number;
  type: string;
  place: number;
  position?: string;
  prizeTicketsAmount: number;
  prizeCoinsAmount: number;
  tickets?: ITickets;
}

// quests

export type updateParams = {
  questId: number;
  userCount: number;
};

export type IQuestsMap = Map<number, IQuest>;

export interface IQuest {
  id: number;
  name: string;
  gameTypeId: number;
  type: string;
  params: IQuestParams;
  isHidden: boolean;
  isOpened: boolean;
  raceId: number;
  startAt: string;
  finishAt: string;
  rewardTaken: boolean;
  userCount?: number;
  targetCount: number;
  priority: number;
}

export interface IQuestParams {
  count: number;
  amount: number;
}

export interface ITickets {
  green: number | string;
  blue: number | string;
  orange: number | string;
}

export interface IRaffle {
  id: number;
  type: string;
  raceType?: string;
  startAt: string;
  finishAt: string;
  isActive: boolean;
  items: IRafflingItem[];
  ownBets: IOwnBets;
  phase: Phases;
  stage: number;
}

export type IOwnBets = {
  [k: string]: IOwnBet;
};

export type IOwnBet = {
  status: BetConditions;
  error?: string;
};

export enum BetConditions {
  pending = 'pending',
  temporary = 'temporary',
  success = 'success',
  error = 'error',
}

export enum Phases {
  waiting = 'waiting',
  raffling = 'raffling',
  step_raffling = 'step_raffling',
  step_waiting = 'step_waiting',
  finished = 'finished',
}

export enum IRaffleType {
  TWO_WEEK = 'two-week',
  TWO_MONTH = 'two-month',
}

export interface IRafflingItem {
  id: number;
  name: string;
  icon: string;
  color: string;
  appId: number;
  raffleId: number;
  coinsAmount: number;
  ticketsAmount: number;
  ticketColor: string;
  winnerId: number;
  membersIds: number[];
  feed: number[];
  members: IItemMembers;
  takenAt: null | string;
  membersLimit?: number;
  raffleAt?: string;
  items?: any[];
}

export interface IItemMembers {
  [k: string]: IItemMember;
}

export interface IItemMember {
  id: number;
  name: string;
  avaLink: string;
  type: string;
}

//respons data

export interface responseBetConfirm {
  raffleId: number;
  raffleItemId: number;
  memberId: number;
}

//history

export type ITournamnetRecord = Record<{
  regular: Record<ITournamnetType>;
  fast: Record<ITournamnetType>;
  great: Record<ITournamnetType>;
}>;

export interface ITournamnetType {
  loading: boolean;
  loaded: boolean;
  entities: ITournamnetItemType[];
}

export interface ITournamnetItemType {
  id: number;
  raceType: string;
  prizeCoinsAmount: number;
  prizeTicketsAmount: number;
  startAt: string;
  finishAt: string;
  leaderboard: ILeaderBoardItem[];
  myStats: null | IMyStats;
}

export interface IRacesStoryRecord {
  id: number;
  type: string;
  startAt: string;
  finishAt: string;
  isActive: boolean;
  items: IRafflingItem[];
}

export interface IRaffleHistoryStateRecord {
  loading: boolean;
  loaded: boolean;
  entities: OrderedMap<string, IRacesStoryRecord>;
}

export type receiveTakenAtResponse = {
  id: number;
  name: string;
  icon: null | string;
  color: string;
  appId: number;
  raffleId: number;
  coinsAmount: number;
  ticketsAmount: number;
  ticketColor: string;
  winnerId: number;
  takenAt: string;
};

// store

export enum SteamAppId {
  DOTA2 = 570,
  CSGO = 730,
  GOLD = 'GOLD',
}

export interface IStoreRecord {
  // [SteamAppId.DOTA2]: Record<IStoreTypeRecord>
  [SteamAppId.CSGO]: Record<IStoreTypeRecord>;
  // [SteamAppId.GOLD]: Record<IStoreTypeRecord>
}

export interface IStoreTypeRecord {
  loading: boolean;
  loaded: boolean;
  items: IStoreProduct[];
}

export interface IStoreProduct {
  name: string;
  icon: string;
  color: string;
  appId: number;
  coinsAmount: number;
  ticketsAmount: number;
  ticketColor: string;
  available?: boolean;
  id?: number;
}
