import React, { FC, useCallback } from 'react';
import { useDrag } from 'react-use-gesture';
import { config, useSpring, animated } from 'react-spring';
import { Shade } from '@components/Selects/StickySelect';

interface ISwipeModal {
  children?: React.ReactNode;
  handleCloseModal: () => void;
  listHeight?: number;
}

export const SwipeModal: FC<ISwipeModal> = ({
  handleCloseModal,
  children,
  listHeight = window.innerHeight,
}) => {
  const closeModalHeight = listHeight * 0.4;
  const [{ y }, set] = useSpring(() => ({
    y: 0,
  }));

  const changeModal = useCallback(
    (state: boolean) => {
      set({
        y: state ? listHeight : 0,
        config: state ? { duration: 200 } : config.default,
        onRest: () => (state ? handleCloseModal() : null),
      });
    },
    [set, listHeight, handleCloseModal]
  );

  // last is true when the user releases from dragging
  // vxvy - the velocity of the drag, important to track to prevent jank after user releases
  // movement - coordinates
  // cancel - cancel drag
  const bind = useDrag(({ last, vxvy: [, vy], movement: [, y], cancel }) => {
    if (y < -70) cancel();
    if (last) y > closeModalHeight || vy > 0.5 ? changeModal(true) : changeModal(false);
    else
      set({
        y,
        immediate: false,
        config: config.stiff,
      });
  });

  return (
    <animated.div
      style={{
        height: listHeight,
        transform: y.interpolate(y => `translateY(${y}px)`),
      }}
    >
      <Shade {...bind()} />
      {children}
    </animated.div>
  );
};
