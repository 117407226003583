import { Reducer } from 'redux';
import Immutable from 'immutable';
import { filtersActions, FiltersActionTypes, FiltersRecord, IFiltersRecord } from '../interfaces';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

export const filters: Reducer<Immutable.Record<IFiltersRecord>, filtersActions> = (
  state = new FiltersRecord(),
  action
) => {
  switch (action.type) {
    case FiltersActionTypes.CHANGE_QUERY_FILTER: {
      const { query } = action.payload;
      return state.set('query', query).setIn(['page', 'number'], 1);
    }

    case FiltersActionTypes.CHANGE_SINGLE_FILTERS: {
      const { key, value, type } = action.payload;
      if (type === 'maxOverprice' || type === 'minOverprice') {
        return state
          .setIn([key], { ...state.getIn([key]), [type]: value })
          .setIn(['page', 'number'], 1);
      } else if (type === 'reset') {
        return state
          .setIn([key], { minOverprice: null, maxOverprice: null })
          .setIn(['page', 'number'], 1);
      } else if (key === 'page') {
        return state.setIn([key], { number: value });
      }
      return state.setIn([key], value).setIn(['page', 'number'], 1);
    }

    case FiltersActionTypes.CHANGE_MULTI_FILTERS: {
      const { key, value, selected } = action.payload;
      if (selected) {
        return state
          .updateIn([key], (origin: string[]) => origin.filter((k: string) => k !== value))
          .setIn(['page', 'number'], 1);
      }
      return state
        .updateIn([key], (origin: string[]) => [...(origin ?? []), value])
        .setIn(['page', 'number'], 1);
    }

    case FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER: {
      const {
        range: [min, max],
      } = action.payload;
      return state.set('minPrice', min).set('maxPrice', max).setIn(['page', 'number'], 1);
    }

    case FiltersActionTypes.RESET_FILTERS: {
      return state.merge(action.payload);
    }

    case FiltersActionTypes.CLEANUP_FILTERS: {
      return new FiltersRecord();
    }

    default: {
      return state;
    }
  }
};

export const changeQueryFilter = (query: string) => ({
  type: FiltersActionTypes.CHANGE_QUERY_FILTER,
  payload: {
    query,
  },
});

export const changeSingleFilters = (key: string, value: string | number, type?: string) => ({
  type: FiltersActionTypes.CHANGE_SINGLE_FILTERS,
  payload: {
    key,
    value,
    type,
  },
});

export const changeMultiFilters = (key: string, value: string, selected: boolean) => ({
  type: FiltersActionTypes.CHANGE_MULTI_FILTERS,
  payload: {
    key,
    value,
    selected,
  },
});

export const changePriceRangeFilter = (range: number[]) => ({
  type: FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER,
  payload: {
    range,
  },
});

export const resetFilters = (data: Partial<IFiltersRecord>) => ({
  type: FiltersActionTypes.RESET_FILTERS,
  payload: data,
});

export const updateFilters = () => ({
  type: FiltersActionTypes.UPDATE_FILTERS,
});

const filterWatcher: Epic = action$ =>
  action$.pipe(
    ofType(
      FiltersActionTypes.CHANGE_QUERY_FILTER,
      FiltersActionTypes.CHANGE_PRICE_RANGE_FILTER,
      FiltersActionTypes.CHANGE_MULTI_FILTERS,
      FiltersActionTypes.CHANGE_SINGLE_FILTERS,
      FiltersActionTypes.RESET_FILTERS
    ),
    map(() => updateFilters())
  );

export const filtersEpic = combineEpics(filterWatcher);
