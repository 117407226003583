import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 8px;
  position: relative;
  justify-content: center;
  z-index: 5;
  font-size: 1rem;
  color: var(--color-white);
  padding: 0 16px;
  overflow: hidden scroll;

  @media (max-width: 374px) {
    grid-template-columns: repeat(2, auto);
  }

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(4, auto);
  }

  @media screen and (min-width: 650px) {
    grid-template-columns: repeat(5, auto);
  }
`;
