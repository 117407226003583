import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import CoefficientDecorator from '../../../decorators/Coefficient';
import SoundService from 'services/sound.service';
import { BetContainer, Btn, BtnInfo } from './ControlBet.styles';

const sound = new SoundService();

interface IControlBet {
  amount: number;
  appDiffTime: number;
  coef: number | null;
  coefficient: number;
  crashed: boolean;
  currentCoef: number;
  gameActions: {
    setBet: (amount: number, coefficient: number) => void;
    stopBet: (currentCoef: number) => void;
  };
  gameRun: boolean;
  gameRunTs: number;
  userBet: {
    amount: number;
    coef: number;
    coefAutoStop: number;
    isSet: boolean;
  };
}

const ControlBet: FC<IControlBet> = ({
  amount,
  coefficient,
  crashed,
  currentCoef,
  gameActions: { setBet, stopBet },
  gameRun,
  userBet,
}) => {
  const { t } = useTranslation();

  const handleSetBet = () => {
    setBet(amount * 100, coefficient);
  };

  const handleStopBet = () => {
    stopBet(currentCoef);
    sound.play('Crash', 'Stop button');
  };

  const handleCreateBet = debounce(handleSetBet, 3000, {
    leading: true,
    trailing: false,
    maxWait: 3000,
  });

  useEffect(() => {
    if (userBet.isSet) {
      sound.play('Crash', 'Bet');
    }
  }, [userBet.isSet]);

  if (!gameRun) {
    return (
      <BetContainer>
        <Btn onClick={handleCreateBet} disabled={!!userBet.isSet}>
          {userBet.isSet ? t('Wait') : t('BET')}
        </Btn>
      </BetContainer>
    );
  }

  if (userBet.isSet && !crashed) {
    const userCoefNotZero = userBet?.coef !== 0;
    const userAutoStopCoefNotZero = userBet?.coefAutoStop !== 0;

    const isMoreCurrentCoefOfCoef = currentCoef > userBet?.coef;
    const isMoreCurrentCoefOfAutoStopCoef = currentCoef > userBet?.coefAutoStop;

    const isDisabledBtn =
      (userCoefNotZero && isMoreCurrentCoefOfCoef) ||
      (userAutoStopCoefNotZero && isMoreCurrentCoefOfAutoStopCoef);

    const stateBtnByCurrentCoef = isDisabledBtn ? t('You won!') : t('Pick up winnings');

    return (
      <BetContainer>
        <Btn onClick={handleStopBet} disabled={isDisabledBtn}>
          {stateBtnByCurrentCoef}
        </Btn>
      </BetContainer>
    );
  }

  return (
    <BetContainer>
      <BtnInfo>{t('Wait for next round...')}</BtnInfo>
    </BetContainer>
  );
};

export default CoefficientDecorator(ControlBet);
