import React, { FC, memo } from 'react';
import { SlotGameGridViewItem } from '../../slot-game-grid-view-item';
import { IComboLineGridViewComponent } from './combo-line.interface';

export const ComboLineGridView: FC<IComboLineGridViewComponent> = memo(({ positions, slots }) => (
  <div className={`combo-line-grid-view ${positions?.length && 'visible'}`}>
    {positions?.map(position => (
      <SlotGameGridViewItem
        key={position}
        idx={slots[position]}
        style={{
          gridColumn: `${Math.ceil((position + 1) / 3)}`,
          gridRow: `${(3 + position + 1) % 3 || 3}`,
        }}
      />
    ))}
  </div>
));
