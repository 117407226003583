export default {
  '/settings': 'Ajustes',
  '/balance': 'Saldo',
  '/marketplace': 'Mercado',
  '/notifications': 'Notificaciones',
  '/auth': 'Autoriz.',

  RULES: 'REGLAS',
  GAME: 'JUEGO',
  Top: 'Arriba',
  HISTORY: 'HISTORIAL',
};
