import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';
import { usePurchaseComputed } from '../../../hooks';

export const StoreNavigation = () => {
  const match = useRouteMatch();
  const { t } = useTranslation('marketplace');
  const { computed } = usePurchaseComputed();

  return (
    <Container as={Link} to={`${match.url}/orders`}>
      <div className="leading">
        <i className="icon-marketplace" />
      </div>
      <div>
        <span>{t('Trade orders and history')}</span>
        {!!computed.active && (
          <span className="badge-light">
            {t('{{ active }} active {{ wait }} waiting for your action', {
              ...computed,
            })}
          </span>
        )}
      </div>
    </Container>
  );
};

const Container = styled(NavigationLink)`
  background: var(--color-dark-600);
`;
