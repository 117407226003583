import React, { FC } from 'react';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { Game } from '../../Game';
import { useTranslation } from 'react-i18next';
import { Container } from './GamePage.styles';

export const GamePage: FC = () => {
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'history',
      iconKey: 'history',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('history'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];
  return (
    <>
      <AppBar isGame />
      <Container className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <Game />
      </Container>
    </>
  );
};
