import React, { Component, Fragment } from 'react';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { Button } from '../../../@components/Common';
import { CurrencyText } from '../../../@components/currency/';
import { SignIn } from '../../../core/Auth';

class Control extends Component {
  constructor() {
    super();
    this.state = {
      duration: '',
    };
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.access.nextgame_timestamp) {
      this._calculateDuration(this.props.access, this.props.appDiffTime);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.access.nextgame_timestamp !== prevProps.access.nextgame_timestamp) {
      if (this.props.access.nextgame_timestamp && this.timer === null) {
        this._calculateDuration(this.props.access, this.props.appDiffTime);
      }
    }

    if (prevProps.access.nextgame_timestamp !== this.props.access.nextgame_timestamp) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { user, access, t } = this.props;

    if (!user.id) return <SignIn className="primary full" />;

    if (access.nextgame_timestamp) {
      return (
        <Button className="primary" onClick={this.props.onPress} disabled={this.props.disabled}>
          {this.state.duration}
        </Button>
      );
    }

    return (
      <Button className="primary" onClick={this.props.onPress} disabled={this.props.disabled}>
        {this.props.userRewards.freeSpins > 0 ? (
          <Fragment>
            {t('FREE')}: {this.props.userRewards.freeSpins}
          </Fragment>
        ) : (
          <Fragment>
            {t('SPIN')}: <CurrencyText value={100} />
          </Fragment>
        )}
      </Button>
    );
  }

  _calculateDuration(access, appDiffTime) {
    if (this.timer !== null) return;
    const to = new dayjs(Date.now() + access.next_timestamp + appDiffTime);
    this.timer = setInterval(() => {
      const now = new dayjs();
      const duration = dayjs.duration(to.diff(now));

      if (duration.milliseconds() < 0) {
        clearInterval(this.timer);
        return;
      }

      const time = [duration.hours(), duration.minutes(), duration.seconds()]
        .map(value => (value < 10 ? `0${value}` : value))
        .join(':');
      this.setState({ duration: time });
    }, 1000);
  }
}

export default withTranslation()(Control);
