import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPayoutWallet } from '../selectors';
import { updatePayoutOrder } from '../duck';
import { IStore } from 'store/interface';

export const usePayoutWallet = () => {
  const wallet = useSelector<IStore, string>(getPayoutWallet, shallowEqual);
  const dispatch = useDispatch();

  const handleChangeWallet = useCallback(
    (paymentWallet: string) => () => {
      dispatch(updatePayoutOrder({ paymentWallet }));
    },
    [dispatch]
  );

  const updateWallet = useCallback(
    (paymentWallet: string) => {
      dispatch(updatePayoutOrder({ paymentWallet }));
    },
    [dispatch]
  );

  return { wallet, handleChangeWallet, updateWallet };
};
