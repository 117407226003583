import React from 'react';
import { Container, Header, HeaderTitle } from './revision-items.styles';
import { RevisionItem } from './revision-item.component';
import { RevisionCaseItem } from 'games/CaseGame';

interface RevisionItemProps {
  revisionItemHeader: string[];
  revisionItems: RevisionCaseItem[];
}

export const RevisionItems: React.FC<RevisionItemProps> = ({
  revisionItemHeader,
  revisionItems,
}) => {
  return (
    <Container>
      <Header>
        {revisionItemHeader.map(title => (
          <HeaderTitle key={title}>{title}</HeaderTitle>
        ))}
      </Header>
      <div>
        {revisionItems?.map(({ inventoryItem, count, rangeFrom, rangeTo }, index) => (
          <RevisionItem
            key={index}
            index={index}
            skin={inventoryItem.baseItem.skin}
            name={inventoryItem.baseItem.name.split('|')[0]}
            img={inventoryItem.baseItem.icon}
            rangeFrom={rangeFrom}
            rangeTo={rangeTo}
            price={inventoryItem.price}
            chance={`${count / 1000}%`}
          />
        ))}
      </div>
    </Container>
  );
};
