import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ImageWrap, PrizeTitle, Replay, ReplayWrap } from './super-prize.styles';
import { useGetGeneralQuestInfo } from '../../../hooks';
import { EventIcons } from 'modules/event-module/comics-event/assets';
import { Modules } from 'core/interfaces';
import { skinItemImage } from 'core/utils';

export const SuperPrizeCompleted = () => {
  const { t } = useTranslation();
  const { reward } = useGetGeneralQuestInfo();
  const history = useHistory();

  const handler = () => {
    history.push(
      `..${Modules.CASES}/${reward?.caseRevisionResult?.case?.id}?uuid=${reward?.caseRevisionResult?.openUUID}`
    );
  };

  return (
    <>
      <PrizeTitle>{t('yourPrize')}</PrizeTitle>
      <ImageWrap>
        <img
          src={skinItemImage(
            reward?.caseRevisionResult?.caseRevisionItem?.inventoryItem?.baseItem?.icon
          )}
          alt="prize"
        />
      </ImageWrap>
      <ReplayWrap onClick={handler}>
        <Replay>{t('watchReplay')}</Replay>
        <EventIcons.Replay />
      </ReplayWrap>
    </>
  );
};
