import reducer, { eventsTypes } from './duck';
import './locales';

export { GameRouter as CrashRouter } from './containers';
// export const CrashRouter = lazy(() => import('./containers').then(module => ({ default: module.GameRouter })))
export const crashModule = {
  moduleName: 'crash',
  moduleId: 7,
  path: '/crash',
  type: 'pve',
  reducer,
  eventsTypes,
};
