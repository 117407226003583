import { IStore } from 'store/interface';
import { LotteryType } from 'core/interfaces';

export const getLotteryStatus = ({ lottery }: IStore) => lottery?.get('status');

export const getLotteryIdentifiers = (type: LotteryType) => ({ lottery }: IStore) =>
  lottery?.get(type);
export const getLotteryElement = (identifier: number) => ({ lottery }: IStore) =>
  lottery?.getIn(['entities', identifier]);

export const getActingLotteryElement = (type: LotteryType) => ({ lottery }: IStore) =>
  lottery?.getIn(['entities', lottery?.get(type)[0]]);
