import React from 'react';
import styled from 'styled-components';

export const MiniPreloader = () => {
  return (
    <Bg>
      <Preloader>
        <Loader />
      </Preloader>
    </Bg>
  );
};

const Bg = styled.div`
  position: relative;
  margin-top: 2px;
`;

const Preloader = styled.div`
  height: 91px;
`;

const Loader = styled.div`
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -31px 0 0 -30px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--color-white);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color-white);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color-white);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
`;
