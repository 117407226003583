export default {
  '/settings': '設定',
  '/marketplace': '市場',
  '/balance': '餘額',
  '/auth': '核准',
  '/notifications': '通知',

  RULES: '規則',
  Chat: '聊天',
  GAME: '遊戲',
  Top: '頂端',
  HISTORY: '紀錄',
};
