import React from 'react';
import { AppBar } from '../../../../@components/bars';
import { Rules } from './rules';

export const RulesPage = () => (
  <>
    <AppBar deep />
    <Rules />
  </>
);
