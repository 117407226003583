import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { data } from './line-chart.config';

export const LineLegends = () => {
  const { t } = useTranslation();
  return (
    <Legends>
      {data.datasets.map(({ label }) => (
        <li key={label} style={{ borderColor: `var(--color-${label})` }}>
          {t(label)}
        </li>
      ))}
    </Legends>
  );
};

const Legends = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 2rem;
  padding: 0 9px;

  --color-your: #fff;
  --color-player: #74ff0c;

  & li {
    margin-right: 5rem;
    padding-right: 0.4rem;
    position: relative;

    color: var(--color-white);
    font-size: 0.8rem;
    text-transform: capitalize;

    &:after {
      content: '';
      width: 2rem;
      height: 1px;
      display: block;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: inherit;

      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-of-type {
      margin-right: 2rem;
    }
  }
`;
