import { rootEpics$ } from 'store/epicMiddleware';
import { store as configure } from 'store/configureStore';
import { combineEpics } from 'redux-observable';
import { participateEpic } from './duck';
import { X50ActionsTypes } from '../interfaces';

const gameEpics = combineEpics(participateEpic);

export const registerX50Module = () => {
  rootEpics$.next([gameEpics, X50ActionsTypes.DETACH_LISTENERS]);
  configure.dispatch({ type: X50ActionsTypes.ATTACH_LISTENERS });
};

export const unregisterX50Module = () => {
  configure.dispatch({ type: X50ActionsTypes.DETACH_LISTENERS });
};
