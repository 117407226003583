import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 11px;
  min-height: 100px;
  width: 100%;
`;

export const Header = styled.p`
  padding: 0 16px;
  text-align: center;
`;

export const Title = styled.span`
  color: #f7fbfc;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`;

export const Time = styled.span`
  color: #b4c5cd;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

export const WeeklyContainer = styled.div`
  display: flex;
  gap: 25px;
  overflow-x: scroll;
  padding: 5px 16px 0;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemContainer = styled(SkeletonElement)<{ height?: number }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: ${({ height }) => (height ? height : '120')}px;
  min-width: 100px;
  width: 100px;
  z-index: 2;
`;

export const StatusContainer = styled.div`
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: center;
  width: 72px;
`;

export const DescriptionContainer = styled.p<{ isTaskReady: boolean }>`
  filter: ${({ isTaskReady }) => (isTaskReady ? 'grayscale(1)' : 'none')};
  opacity: ${({ isTaskReady }) => (isTaskReady ? '0.7' : '1')};
  text-align: center;

  & svg {
    vertical-align: top;
  }
`;

export const Description = styled.span<{ isTaskReady: boolean }>`
  color: ${({ isTaskReady }) => (isTaskReady ? '#728188' : '#f7fbfc')};
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
`;

export const Line = styled.div`
  background-image: linear-gradient(to right, #253138 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 17px 2px;
  height: 2px;
  margin: 0 70px;
  position: absolute;
  top: 40px;
  width: 730px;
  z-index: 1;
`;
