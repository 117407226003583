import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SliderContainer = styled.div<{
  currentSlide: number;
  disableScreen: boolean;
  hasAnimation: boolean;
}>`
  display: flex;
  height: 100%;
  pointer-events: ${({ disableScreen }) => (disableScreen ? 'none' : 'auto')};
  scroll-behavior: smooth;
  transform: translateX(-${({ currentSlide }) => currentSlide * 100}%);
  transition: ${({ hasAnimation }) => (hasAnimation ? 'transform 0.5s ease-in-out' : 'none')};
  width: 100%;
`;

export const SliderItemContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  width: 100%;
`;
