import styled from 'styled-components';

export const TextInput = styled.input<{ isError: boolean; isPhone?: boolean }>`
  background-color: var(--color-white);
  border-radius: var(--size-radius-m);
  border: 2px solid rgba(24, 35, 40, 0.4);
  color: ${({ isPhone }) => (isPhone ? 'var(--danger)' : 'var(--color-dark)')};
  font-size: 1rem;
  height: 3rem;
  line-height: 1.125rem;
  padding: 0.875rem;
  width: 100%;

  ${({ isError }) => isError && 'border: 1px solid var(--danger);'}
`;
