import React, { useCallback, useEffect, useState } from 'react';

export const useGetDataForWalletTypeFullName = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [wallet, setWallet] = useState('');
  const [errorNameField, setErrorNameField] = useState(false);
  const [errorSurnameField, setErrorSurnameField] = useState(false);

  useEffect(() => {
    setWallet(`${name}_${surname}`);
  }, [name, surname]);

  const onChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>, offTrim?: boolean) => {
    let result = e.target.value.replace(/[\d]/g, '');
    if (!offTrim) {
      result = result.trim();
    } else {
      if (!result.trim().length) {
        setErrorNameField(true);
      } else {
        setErrorNameField(false);
      }
    }
    setName(result);
  }, []);

  const onChangeSurname = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, offTrim?: boolean) => {
      let result = e.target.value.replace(/[\d]/g, '');
      if (!offTrim) {
        result = result.trim();
      } else {
        if (!result.trim().length) {
          setErrorSurnameField(true);
        } else {
          setErrorSurnameField(false);
        }
      }
      setSurname(result);
    },
    []
  );

  const nameFieldError = useCallback((value: boolean) => {
    setErrorNameField(value);
  }, []);

  const surnameFieldError = useCallback((value: boolean) => {
    setErrorSurnameField(value);
  }, []);

  return {
    wallet,
    onChangeName,
    onChangeSurname,
    name,
    surname,
    nameFieldError,
    surnameFieldError,
    errorNameField,
    errorSurnameField,
  };
};
