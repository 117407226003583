export default {
  'Fair game': 'Rettferdig spill',
  Bet: 'Bet',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = Disabled)',
  'Bet coins': 'Legg til mynter',
  Stop: 'Stop',
  'Wait for next round...': 'Vent til neste runde',
  Example: 'Eksempel',
  '!! Crash !!': '!! Kræsj !!',
};
