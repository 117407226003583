import {
  ICommonSounds,
  ICurrency,
  IExperiences,
  IGameSounds,
  ISettings,
} from './appshell.reducer.interface';

export enum AppShellActionsTypes {
  INITIALIZED = 'app/INITIALIZED',
  INIT_SETTINGS = 'app/INIT_SETTINGS',
  SET_DIFF_TIME = 'app/SET_DIFF_TIME',
  TOGGLE_DRAWER = 'app/TOGGLE_DRAWER',
  CHANGE_CURRENT_PAGE = 'app/CHANGE_CURRENT_PAGE',
  UPDATE_SOUNDS = 'app/UPDATE_SOUNDS',
  SET_SOUNDS = 'app/SET_SOUNDS',
  TOGGLE_LOCALE = 'app/TOGGLE_LOCALE',
  TOGGLE_CURRENCY = 'app/TOGGLE_CURRENCY',
  INIT_GAME_LIST = 'app/INIT_GAME_LIST',
  DISCONNECT = 'socket/DISCONNECT',
  CONNECTED = 'socket/CONNECTED',
  RECONNECTING = 'socket/RECONNECTING',

  ADD_LAST_GAME = 'app/core/AppShell/ADD_LAST_GAME',
  RECEIVE_EXPERIENCE_MAP = 'INIT_EXPERIENCE_MAP',
}

interface IInitSettingsAction {
  type: typeof AppShellActionsTypes.INIT_SETTINGS;
  payload: ISettings & { currency: ICurrency[] };
}

interface ISetDiffTimeAction {
  type: typeof AppShellActionsTypes.SET_DIFF_TIME;
  payload: number;
}

export type settingsActions = IInitSettingsAction | ISetDiffTimeAction;

interface IToggleDrawerAction {
  type: typeof AppShellActionsTypes.TOGGLE_DRAWER;
  payload: {
    status: boolean;
  };
}

export type DrawerActions = IToggleDrawerAction;

export interface IUpdateSoundsAction {
  type: typeof AppShellActionsTypes.UPDATE_SOUNDS;
  payload: Record<string, ICommonSounds | IGameSounds>;
}

export interface ISetSoundsAction {
  type: typeof AppShellActionsTypes.SET_SOUNDS;
}
export type ISoundsAction = IUpdateSoundsAction | ISetSoundsAction;

interface IToggleCurrencyAction {
  type: typeof AppShellActionsTypes.TOGGLE_CURRENCY;
  payload: ICurrency;
}

export type currencyActions = IToggleCurrencyAction;

export interface IChangeCurrentPageAction {
  type: typeof AppShellActionsTypes.CHANGE_CURRENT_PAGE;
  payload: string;
}

export interface IFilterRegexAction {
  type: typeof AppShellActionsTypes.INIT_SETTINGS;
  payload: ISettings;
}

export interface IFilterRegexAction {
  type: typeof AppShellActionsTypes.INIT_SETTINGS;
  payload: ISettings;
}

interface IReconnectingAction {
  type: typeof AppShellActionsTypes.RECONNECTING;
}

interface IConnectedAction {
  type: typeof AppShellActionsTypes.CONNECTED;
}

export type connectActions = IConnectedAction | IReconnectingAction;

export interface IToggleLocaleAction {
  type: typeof AppShellActionsTypes.TOGGLE_LOCALE;
  payload: string;
}

interface IInitializedAction {
  type: typeof AppShellActionsTypes.INITIALIZED;
}

interface IDisconnectedAction {
  type: typeof AppShellActionsTypes.DISCONNECT;
}

export type initializedActions = IDisconnectedAction | IInitializedAction;

interface IAddLastGame {
  type: typeof AppShellActionsTypes.ADD_LAST_GAME;
  payload: {
    pathGames: string[];
  };
}

export type lastGamesActions = IAddLastGame;

interface IAddExperienceMap {
  type: AppShellActionsTypes.RECEIVE_EXPERIENCE_MAP;
  payload: {
    experiences: IExperiences[];
  };
}

export type experiencesActions = IAddExperienceMap;
