import { List } from 'react-virtualized';
import styled from 'styled-components';

export const Container = styled.div`
  color: var(--color-white);
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  background: var(--color-dark-350);
`;

export const HeaderContainer = styled.div`
  padding: 10px 15px;
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Close = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-gray-200);
  align-self: flex-end;
  justify-self: flex-start;
`;

export const Header = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: var(--color-white);
`;

export const TableHeader = styled.div`
  padding: 11px 20px;
  width: 100%;
  background: var(--color-dark-100);
  color: var(--color-white-gray);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TableColumn = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
`;

export const ListComp = styled(List)`
  width: 100% !important;

  &::-webkit-scrollbar {
    display: none;
  }
`;
