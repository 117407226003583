import { DIALOG } from 'dialogs/dialog-combiner';
import { userModals } from 'core/User/modal.config';
import { transitions } from 'core/Modals';
import { SteamTradeLinkDialog } from './steam-trade-link-dialog';

DIALOG.add(userModals.STEAM_TRADE_LINK, {
  component: SteamTradeLinkDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
