import { createSelector } from 'reselect';

export const getLastAppNotification = ({ notifications }) => notifications.notification;

export const getAppNotificationVisibleStatus = ({ notifications }) =>
  notifications.notificationVisibleStatus;

export const getAppNotifications = createSelector(
  ({ notifications }) => notifications.notificationsIDs,
  ({ notifications }) => notifications.notificationsByID,
  (ids, notices) => ids.map(id => notices[id]).sort((n1, n2) => n2.addDate - n1.addDate)
);

export const getAppUnseenNotificationsAmount = ({ notifications }) =>
  notifications.notificationsIDs
    .map(id => notifications.notificationsByID[id])
    .filter(notification => !notification.seen);

export const getStatusDeleteNotices = ({ notifications }) => notifications.statusDeleteNotices;
