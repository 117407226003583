export default {
  Cart: 'Kart',
  Price: 'Fiyat',
  Type: 'Type',
  Categories: 'Katogori',
  Rarity: 'Nadir',
  OK: 'OK',
  Clear: 'Temizle',
  Autoselect: 'Otomatik seçim',
  Remove: 'Remove',
  Bet: 'Bahis',
  Min: 'Min',
  Max: 'Maks',
  'it.': '',
  Total: 'Total',
  items: 'Eşyalar',
  Pistols: 'Tabancalar',
  Rifles: 'Tüfekler',
  'Submachine guns': 'Makineli',
  Heavy: 'Ağır makineli',
  Knives: 'Bıçaklar',
  Gloves: 'Eldiven',
  Keys: 'Anahtarlar',
  Other: 'Diğerleri',
  'Base Grade': 'Giriş seviyesi',
  'Consumer Grade': 'Tüketici sınıfı',
  'Industrial Grade': 'Endüstriyel Dönem',
  Restricted: 'Kısıtlı',
  'Mil-Spec Grade': 'Mil-Spec grade',
  Classified: 'Sınıflandırılmış',
  'High Grade': 'Yüksek sınıf',
  Covert: 'Gizli',
  Remarkable: 'Dikkat çekici',
  Exotic: 'Ekzotik',
  Contraband: 'Kaçak Mal',
  Extraordinary: 'Olağanüstü',
  Buy: 'Satın al',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Sadece kazançların veya kullanılmayan bozuk paraların geri çekilmesi için saklayın. Diğer pazarlarla olan fiyat farkı nedeniyle kâr amaçlı ticari dergilerin değiştirilmesi kesinlikle yasaktır. Vialators kalıcı olarak yasaklanacak.',
  'Your purchase will be delivered within 15 minutes.':
    'Siparişiniz 15 dakika içerisinde teslim edilecektir.',
  'Congratulations! You have successfully purchased': 'Tebrikler! Başarıyla satın aldınız.',
  'Order confirmation': 'Sipariş bilgileri',
  Filters: 'Filters',
  'By price': 'Fiyata göre',
  Filter: 'Filtre',
  'Not found': 'Bulunamadı',
  'At present it is unable to find any items on your request. Try again later.':
    'Talep ettiğiniz gibi bir ürün bulunamıyor. Sonra tekrar deneyin.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
