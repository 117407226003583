import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '@UIkit';
import { Button } from '@components/Common';
import { useUserTradeLinkForm } from 'core/User';
import { SteamValidators } from 'core/utils/Validators';
import { Container } from './steam-trade-link-step.styles';

export const SteamTradeLinkStep = () => {
  const { t } = useTranslation('steam-settings');
  const { form, tradeLinks, onSubmit } = useUserTradeLinkForm();

  return (
    <Container className="invalid-tradelink">
      <div className="step-header">
        <h5 className="step-header-title">{t('Add a trade link')}</h5>
        <div className="step-header-warning-message">
          <i className="icon-warn" />
          <p>
            {t('Trade link is not entered or is invalid Please enter the current one')}{' '}
            <Button
              className="link-info"
              as="a"
              href="https://steamcommunity.com/my/tradeoffers/privacy"
              target="_blank"
            >
              {t('Where to get the link?')}
            </Button>
          </p>
        </div>
      </div>
      <div className={form.errors?.link?.type}>
        <SearchInput
          {...form.register('link')}
          name="link"
          type="text"
          placeholder={t('Enter your trade link')}
          ref={form.register({
            pattern: SteamValidators.tradeLinkPattern,
            validate: (v: string) => v !== tradeLinks?.steam,
          })}
        />
      </div>
      <Button className="steam-provider" onClick={form.handleSubmit(onSubmit)}>
        {t('Add trade link')}
      </Button>
    </Container>
  );
};
