export const URLS = {
  urlInventoryExchange: 'https://steamcommunity-a.akamaihd.net/economy/image/',
  urlStickers: 'https://steamcdn-a.akamaihd.net/apps/730/icons/econ/',
  urlAvatars: 'https://avatars.steamstatic.com/',
  linkSteamApiKey: 'https://steamcommunity.com/dev/apikey?',
  urlTradeLink: 'https://steamcommunity.com/profiles/',
  purchaseUrl: 'https://steamcommunity.com/id/me/tradeoffers',
  mobileSteamStore: 'https://store.steampowered.com/mobile',
  steamSettings: 'https://steamcommunity.com/my/edit/settings',
  refill: {
    bonus: '//d2lomvz2jrw9ac.cloudfront.net/common/bonus-systems/refill-bonus/gift.svg',
    icon: '//d2lomvz2jrw9ac.cloudfront.net/common/refill-icons',
    error: '//d2lomvz2jrw9ac.cloudfront.net/common/refill-icons/refill-empty.svg',
  },
  giftCard: '//www.kinguin.net/category',
  g2GiftCard: '//www.g2a.com/csgofast',
  avatarUrl: '//d2lomvz2jrw9ac.cloudfront.net/avatars/avatar-fallback.png',
  countryFlags: '//d2lomvz2jrw9ac.cloudfront.net/country-flags',
  paymentURL: '//pay.csgofastbackend.com/new_pay',
  moneyIconUrl: '//d2lomvz2jrw9ac.cloudfront.net/common/currency/money-bag-pngrepo-com.png',
};
