import * as Immutable from 'immutable';

export const FiltersRecord = Immutable.Record({
  appId: 730,
  query: '',
  sortBy: 'price',
  minPrice: null,
  maxPrice: null,
  page: {
    size: 100,
    number: 1,
  },
});

export interface IFiltersRecord {
  appId: number;
  query: string;
  sortBy: string;
  minPrice: number;
  maxPrice: number;
  page: {
    size: number;
    number: number;
  };
}
