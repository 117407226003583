import { useCallback, useState } from 'react';
import { IStore } from 'store/interface';
import { shallowEqual, useSelector } from 'react-redux';
import { getStatistics } from '../selectors';

export const rangeSelect = [20, 50, 100];

export const useStatistic = () => {
  const [select, setSelect] = useState(rangeSelect.length - 1);
  const statistic = useSelector(
    (state: IStore) => getStatistics(state, rangeSelect[select] || 0),
    shallowEqual
  );

  const handleChangeRange = useCallback(
    (index: number) => () => {
      setSelect(index);
    },
    [setSelect]
  );

  return {
    select,
    handleChangeRange,
    statistic,
  };
};
