import { combineReducers } from 'redux';
import { getAppFilterRegex } from '../../core/AppShell/selectors';
import { getUser } from '../../core/User/selectors';

import { IDispatch, IEventsTypes } from '../../core/rootInterfaces/root.interface';

import { IUpdateData, TopActions, TopAllActions } from './interfaces/top.actions.interface';

import { IPlayer } from './interfaces/top.reduce.interface';

import { ISocketService } from '../../services/interfaces/services.interface';

const listTop = (state: IPlayer[] = [], action: TopAllActions): IPlayer[] => {
  switch (action.type) {
    case TopActions.UPDATE: {
      return action.payload.players;
    }
    case TopActions.CLEAR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  listTop: listTop,
});

export default reducer;

export const changeTopType = (gameName: string, period: string) => (
  dispatch: IDispatch,
  getState: () => any,
  { socket }: ISocketService
) => {
  socket.emit('top-request', { gameName, period });
  dispatch({ type: TopActions.CLEAR });
};

export const updateTop = (updates: IUpdateData) => (dispatch: IDispatch, getState: () => any) => {
  const state = getState();

  const user = getUser(state);

  let { players } = updates;

  const filterRegex = getAppFilterRegex(state);
  const regex = new RegExp(filterRegex, 'gi');

  players = players
    .filter((x: IPlayer) => x.name !== null)
    .map((player: IPlayer) => ({
      ...player,
      me: player.id === user.id,
      name: player.name.replace(regex, '***'),
    }));

  dispatch({ type: TopActions.UPDATE, payload: { ...updates, players } });
};

export const eventsTypes: IEventsTypes[] = [{ event: 'top:update', action: updateTop }];
