import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <RulesContainer>
        <RulesP>
          <span>Fast game </span>-{' '}
          {t(
            'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How is the winner determined?')} </RulesSpan>
          {t(
            'The winner is determined randomly, but the higher the bet, the greater the chance to win.'
          )}
        </RulesP>

        <RulesDistinguish>
          <RulesSpan>{t('How many bets can I place per one round?')} </RulesSpan>
          {t('One player can place one bet per one game round in this game.')}
        </RulesDistinguish>

        <RulesP>
          <RulesSpan>{t('When is the winner determined?')} </RulesSpan>
          {t('The winner is determined once 3 players place their bets in the current game round.')}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Where can I see my chances of the winning?')} </RulesSpan>
          {t(
            'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('What Service Commission is there in this game?')} </RulesSpan>
          {t(
            'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.'
          )}
        </RulesP>
      </RulesContainer>
    </div>
  );
};

const RulesContainer = styled.div`
  padding: 0px 10px 10px 10px;

  line-height: 14px;
  letter-spacing: 0.2px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 12px;
  & span {
    color: var(--aqua-300);
    font-weight: 700;
  }
`;

const RulesSpan = styled.span`
  color: var(--aqua-300);
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;

const RulesDistinguish = styled.div`
  border-radius: 15px;
  background: var(--gray-300);
  padding: 15px 10px 15px 10px;
  color: var(--color-white);
  font-size: 12px;
  margin-bottom: 4px;
  & span {
    color: var(--color-white);
    font-weight: 700;
    padding-bottom: 5px;
    display: block;
  }
`;
