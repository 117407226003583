import styled from 'styled-components';

export const UserRoundsContainer = styled.div<{ rounds: number }>`
  align-items: stretch;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(1, 80px);

  @media screen and (max-width: 374px) {
    grid-template-columns: repeat(1, 66px);
  }

  grid-template-rows: repeat(${({ rounds }) => rounds}, minmax(0, 1fr));
  justify-content: center;
`;
