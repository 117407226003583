export default {
  Language: 'Língua',
  'Bets in current room': 'Apostas na sala atual',
  Timers: 'Temporizadores',
  'Game start': 'Começo do jogo',
  'Game ending': 'Final do jogo',
  'Roulette ticking': 'Corrida de corrida',
  'Roulette start': 'Início da roleta',
  'Item set': 'Item set',
  'My bet': 'Minha aposta',
  'Coins transfer': 'Transferência de moedas',
  'Start button': 'Botão de começar',
  'Stop button': 'Botão de parar',
  Countdown: 'Contagem Decrescente',
  Soundtrack: 'Banda sonora',
  Crash: 'Crash',
  'Picking item': 'Escolher item',
  Crafting: 'Elaborar',
  Failure: 'Falha',
  Win: 'Ganhar',
  Sell: 'Vender',
  Take: 'Levar',
  'Rooms bets': 'Apostas dos quartos',
  'Chat message sending': 'Envio de mensagem de chat',
  'Chat message receiving': 'Recepção de mensagem',
  Sound: 'Som',
  'Chat sound': 'Som do chat',
  'Game sounds': 'Som do jogo',
  Volume: 'Volume',
  steamApi: {
    noKey: 'SEM CHAVE DE API DA STEAM',
    need: 'Necessário para vender e trocar skins com outros jogadores',
    addLink: 'ADICIONAR LINK',
    link: 'ONDE OBTER?',
  },
};
