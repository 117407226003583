export default {
  Cart: 'Einkaufswagen',
  Price: 'Preis',
  Type: 'Type',
  Categories: 'Kategorien',
  Rarity: 'Rarität',
  OK: 'OK',
  Clear: 'Löschen',
  Autoselect: 'Auto-Auswahl',
  Remove: 'Remove',
  Bet: 'Einsatz',
  Min: 'Min',
  Max: 'Max',
  'it.': '',
  Total: 'Gesamt',
  items: 'Elemente',
  Pistols: 'Pistole',
  Rifles: 'Gewehr',
  'Submachine guns': 'MP',
  Heavy: 'Schwer',
  Knives: 'Messer',
  Gloves: 'Handschuhe',
  Keys: 'Schlüssel',
  Other: 'Sonstige',
  'Base Grade': 'Standardqualität',
  'Consumer Grade': 'Verbraucherklasse',
  'Industrial Grade': 'Industriequalität',
  Restricted: 'Limitiert',
  'Mil-Spec Grade': 'Militärstandard',
  Classified: 'Vertraulich',
  'High Grade': 'Hohe Qualität',
  Covert: 'Verdeckt',
  Remarkable: 'Bemerkenswert',
  Exotic: 'Exotisch',
  Contraband: 'Schmuggelware',
  Extraordinary: 'Außergewöhnlich',
  Buy: 'Kaufen',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Speicherung nur für das Abheben von Gewinnen oder ungenutzte Münzen. Ein Austausch von PageNavigation, mit dem Ziel vom Preisunterschied zu anderen Märkten zu profitieren, ist ausdrücklich untersagt. Zuwiderhandlungen werden mit einem dauerhaften Ausschluss bestraft.',
  'Your purchase will be delivered within 15 minutes.':
    'Ihr Einkauf wird innerhalb von 15 Minuten geliefert.',
  'Congratulations! You have successfully purchased':
    'Gratulation! Sie haben erfolgreich eingekauft',
  'Order confirmation': 'Bestellung bestätigen',
  Filters: 'Filters',
  'By price': 'Nach Preis',
  Filter: 'Filter',
  'Not found': 'Nicht gefunden',
  'At present it is unable to find any items on your request. Try again later.':
    'Gegenwärtig ist es nicht in der Lage, jedweden Artikel auf Ihrer Anfrage zu finden. Versuchen Sie es später noch einmal.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
