import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import SoundService from 'services/sound.service';

const sound = new SoundService();

interface ITotalComboLineProfit {
  profit: number;
}

export const TotalComboLineProfit: FC<ITotalComboLineProfit> = ({ profit }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (profit) sound.play('Slot', 'Win');
  }, [profit]);

  return (
    !!profit && (
      <Container>
        {t('Total won:')} <CurrencyText value={profit} />
      </Container>
    )
  );
};

const Container = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--color-white);
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
