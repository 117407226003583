import { getImageFromSteamWithCustomSize } from '../../../core/utils/getImageFromSteamWithCustomSize.utils';
import { getMotivatorData } from '../ducks';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGetMotivatorData = () => {
  const { allDrop, bestDrop } = useSelector(getMotivatorData, isEqual);
  const allDropNormalizedArray = useMemo(
    () =>
      allDrop.map(
        ({
          caseRevisionItem,
          user,
          case: { backgroundImage, id, lastRevision, ...caseRest },
          openUUID,
          ...rest
        }) => ({
          imgPath: getImageFromSteamWithCustomSize(caseRevisionItem.inventoryItem.baseItem.icon),
          user,
          caseImg: backgroundImage ?? caseRest.image,
          caseId: id,
          casePrice: lastRevision?.price,
          uuid: openUUID,
          isBattle: rest?.isBattle,
          uniqId: caseRevisionItem.id + openUUID + user.id + rest.id,
          id: rest.id,
        })
      ),
    [allDrop]
  );
  const bestDropNormalizedArray = useMemo(
    () =>
      bestDrop.map(
        ({
          caseRevisionItem,
          user,
          case: { backgroundImage, id, lastRevision, ...caseRest },
          openUUID,
          ...rest
        }) => ({
          imgPath: getImageFromSteamWithCustomSize(caseRevisionItem.inventoryItem.baseItem.icon),
          user,
          caseImg: backgroundImage ?? caseRest.image,
          caseId: id,
          casePrice: lastRevision.price,
          uuid: openUUID,
          isBattle: rest?.isBattle,
          uniqId: caseRevisionItem.id + openUUID + user.id + rest.id,
          id: rest.id,
        })
      ),
    [bestDrop]
  );
  return { allDrop, bestDrop, allDropNormalizedArray, bestDropNormalizedArray };
};
