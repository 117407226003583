const MAX_X = 133000;
const MIN_X = 1733;
const DELTA_X = MAX_X - MIN_X;
const X_MULTIPLIER = 4e-4;

const msToX = current => Math.min(Math.floor(current / 10), DELTA_X);
const multiple = current => Math.pow(Math.E, current * X_MULTIPLIER) - 1;
export const msToCoef = current => Math.max(multiple(MIN_X + msToX(current)), 1);

export const getCoefficients = (max = 50) => {
  const coefficients = [];

  const getNextCoef = coef => {
    if (coef < 3) return 0.05;
    else if (coef < 5) return 0.1;
    else if (coef < 10) return 0.5;
    else return 1;
  };

  for (let coef = 1; coef < max; ) {
    coef = +(coef + getNextCoef(coef)).toFixed(2);
    coefficients.push({ key: coef, label: coef, value: coef });
  }

  return coefficients;
};

export const getTimeByCoef = coef => {
  return Math.min((Math.log(coef + 1) - 0.6932) / 0.0004, DELTA_X) * 10;
};
