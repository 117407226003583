import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '../../../@components/currency';

import StarImg from '../../../assets/images/star.png';
import Tower from '../assets/tower.svg';
import Sticker from '../assets/sticker.png';

export const Modal = ({ reward, clearTicket }) => {
  const { t } = useTranslation();

  return (
    <Container onClick={clearTicket}>
      <Image src={Tower} />
      <ModalTitle>{t('Your win')}</ModalTitle>
      <ModalTitle>{t('You conquered the tower!')}</ModalTitle>
      <ModalInfo>
        {t('Your prize')} <CurrencyText value={reward} bold />
      </ModalInfo>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  z-index: 1000;
  position: fixed;
  top: 76px;
  padding-bottom: 75px;
  
  &:before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  background-color: #161e21;
  background-image: url("${StarImg}");
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  }
`;

const Image = styled.img`
  max-width: 60px;
  height: auto;
  margin-bottom: 1.5rem;
`;

const ModalTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--color-white);
  line-height: 1.4;
`;

const ModalInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  margin-top: 1.7rem;
  background-image: url("${Sticker}");
  background-size: cover;

  font-size: 15px;
  letter-spacing: 0.2px;
  color: var(--color-white);
`;
