import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getRaffleHistoryById } from '../selectors';
import { getUser } from 'core/User/selectors';

export const useSelectRaffleHistoryFacade = () => {
  const { push } = useHistory();
  const { params } = useRouteMatch<{ raffleId: string }>();

  const history = useSelector(
    (state: any) => getRaffleHistoryById(state, params.raffleId),
    shallowEqual
  );

  const user = useSelector(getUser, shallowEqual);

  useEffect(() => {
    if (!history) push('/leaders-race-next/raffles/history');
  }, [history, push]);

  return {
    history,
    userid: user.id,
  };
};
