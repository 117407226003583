import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { CurrencyText } from '@components/currency';
import { useTranslation } from 'react-i18next';

export const RateComboLine = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const values = watch(['betPerLine', 'selectedLines']);

  return (
    <Container>
      {t('Your bet')}
      {': '}
      <CurrencyText value={values.selectedLines * values.betPerLine * 100} />
    </Container>
  );
};

const Container = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--color-white);
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
