import * as React from 'react';
import styled from 'styled-components';
import CoefficientDecorator from '../../decorators/Coefficient';
import { CurrencyText } from '../../../../@components/currency';
import { withTranslation } from 'react-i18next';

class ProgressBetWinner extends React.PureComponent {
  state = {
    isWinBet: true,
  };

  static getDerivedStateFromProps({ userBet, coef }) {
    if (coef) {
      return {
        isWinBet:
          (!!userBet.coef && userBet.coef < coef) ||
          (!!userBet.coefAutoStop && userBet.coefAutoStop < coef),
      };
    }
    if (!coef) {
      return {
        isWinBet: true,
      };
    }

    return null;
  }

  componentDidUpdate() {
    const { userBet, currentCoef, crashed } = this.props;
    if (
      ((currentCoef > userBet.coefAutoStop && !!userBet.coefAutoStop) ||
        (currentCoef > userBet.coef && !!userBet.coef)) &&
      !crashed
    ) {
      this.props.cancelInterval();
    }
  }
  render() {
    const { isWinBet } = this.state;
    const { t } = this.props;
    return (
      <ProgressContainer>
        <ProgressValue isWinBet={isWinBet}>
          <CurrencyText value={this.calcAmount()} />
        </ProgressValue>
        <ProgressTitle>{t('Win')}</ProgressTitle>
      </ProgressContainer>
    );
  }

  calcAmount = () => {
    const { userBet, currentCoef } = this.props;

    if (userBet.coef === 0) {
      return userBet.amount * currentCoef * 100;
    }

    const betValue =
      currentCoef - userBet.coef < 0 ? userBet.amount * currentCoef : userBet.amount * userBet.coef;

    return betValue * 100;
  };
}

export default CoefficientDecorator(withTranslation()(ProgressBetWinner));

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  max-height: 300px;
`;

const ProgressTitle = styled.span`
  font-size: 0.9rem;
  letter-spacing: 1.5px;
  color: var(--color-gray-90);
  margin-top: 5px;
  text-transform: uppercase;
`;

const ProgressValue = styled.span`
  font-size: 2rem;
  color: ${({ isWinBet }) => (isWinBet ? 'var(--ochre)' : 'rgba(223, 24, 64, 0.5)')};
`;
