import React, { ChangeEvent, FC, FocusEventHandler } from 'react';
import { TextInput } from './custom-input.styles';

interface CustomInputProps {
  isError: boolean;
  isPhone?: boolean;
  maxLength?: number;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  params?: { required: boolean };
  placeholder?: string;
  register?: (e: HTMLInputElement, params?: { required: boolean }) => void;
  type: string;
  value: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const CustomInput: FC<CustomInputProps> = ({
  isError,
  isPhone,
  maxLength,
  name,
  onChange,
  onFocus,
  params,
  placeholder,
  register,
  type,
  value,
  onBlur,
}) => {
  return (
    <TextInput
      isError={isError}
      isPhone={isPhone}
      maxLength={maxLength}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      ref={e => {
        if (register) {
          register(e, params);
        }
      }}
      type={type}
      value={value}
      onBlur={onBlur}
    />
  );
};
