import en from './en';
import ru from './ru';
import de from './de';
import pl from './pl';
import ro from './ro';
import es from './es';
import no from './no';
import pt from './pt';
import sv from './sv';
import th from './th';
import tr from './tr';
import zh_cn from './zh_cn';
import zh_tw from './zh_tw';
import { addResources } from '../../../locales';

addResources({ en, ru, de, pl, ro, es, no, pt, sv, th, tr, zh_cn, zh_tw });
