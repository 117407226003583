import React, { FC, memo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AppBar, NavBar } from '@components/bars';
import { TournamentPage } from '../Pages';
import { Balance } from '../Balance';
// import { YearlyStatistic } from '../../components/Tournaments'

const routes = [
  // { link: '', text: 'All race' },
  { link: '/regular', text: 'Regular', routeParams: 'regular' },
  { link: '/fast', text: 'Fast', routeParams: 'fast' },
  { link: '/great', text: 'Great', routeParams: 'great' },
];

export const TournamentStack: FC<RouteComponentProps> = memo(({ match, location }) => (
  <>
    <AppBar deep={true} prependWidget={Balance} />
    <NavBar tabs={['/regular', '/fast', '/great']} />
    <div className="content">
      <Switch>
        {routes.map((route: any) => (
          <Route
            key={route.link}
            exact
            path={`${match.url}/${route.routeParams}`}
            render={() => <TournamentPage raceType={route.routeParams} />}
          />
        ))}
        {/*<Route path={`${match.url}`} component={YearlyStatistic} />*/}

        <Redirect
          to={{
            pathname: '/leaders-race-next',
            state: { from: location },
          }}
        />
      </Switch>
    </div>
  </>
));
