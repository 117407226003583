import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { resetFilters } from '../../ducks';

export const useShopResetFilter = () => {
  const dispatch = useDispatch();

  const refresh = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return {
    refresh,
  };
};
