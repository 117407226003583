import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { InventoryMetadata } from './inventory-metadata';
// import { InventorySales } from './inventory-sales'
import { InventoryItems } from './inventory-items';
import { InventoryFilters } from './inventory-filters';
import { InventorySelectedItems } from './inventory-selected-items';
import { fetchSteamInventory } from '../../ducks/inventory.duck';
import { actionCheckSteamStatus } from 'modules/marketplace/ducks/marketplace.duck';
import { InventoryNavigation } from '../store-page/inventory-navigation';

export const InventoryPage = () => {
  const parentRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSteamInventory(true));
    dispatch(actionCheckSteamStatus());
  }, [dispatch]);

  return (
    <>
      <div className="content dark-layout">
        <Container ref={parentRef}>
          <InventoryMetadata />
          {/*<InventorySales />*/}
          <InventoryFilters />
          <InventoryItems parentRef={parentRef} />
          <InventoryNavigation hideAuction />
        </Container>
      </div>
      <InventorySelectedItems />
      <div id="inventory-portal" />
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(2, min-content) 1fr;
  min-height: 100%;
  overflow: auto;
`;
