import styled from 'styled-components';

type MethodItemStyle = {
  selected: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  margin: 0.5rem 0 1rem;
  overflow-x: scroll;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    min-width: 1rem;
    min-height: 100%;
  }
`;

export const Wrap = styled.div`
  margin-right: 0.45rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const Gate = styled.button.attrs((p: MethodItemStyle) => ({
  highlight: p.selected ? '#47555F' : 'var(--color-dark-200)',
}))<MethodItemStyle>`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.125rem 0.75rem;
  min-width: 10rem;
  height: 3.5rem;
  border-radius: var(--size-radius-s);
  padding: 0.4375rem 1rem;
  position: relative;
  background: ${p => p.highlight};
  transition: background 0.3s linear;
  border: 0;

  &:disabled {
    opacity: 0.3;
  }

  & .method-thumbnail {
    align-self: center;
    justify-self: center;
    grid-row: 1 / span 2;

    & img {
      width: 2rem;
      height: 2rem;
    }
  }

  & .method-name {
    font-size: 0.875rem;
    color: var(--color-white);
    white-space: nowrap;
    align-self: center;
    justify-self: flex-start;
  }
`;

export const TitleSpan = styled.span`
  justify-self: flex-start;
  color: var(--color-white);
`;
