import React from 'react';
import { PayGateList } from './pay-gate-list';
import { PayControls } from './pay-controls';
import { LockScreen } from './lock-screen';
import { ChipList } from './chip-list';
import { useAmountPay, useGatePay, useMethodPay, useTrustUser } from '../../hooks';
import { AppBar } from '@components/bars';
import { Container } from './refill-method.styles';
import { Loader } from '@components/Loader';

export const RefillMethodPage = () => {
  const { method } = useMethodPay();
  const { amount, handleChangeAmount } = useAmountPay();
  const { gate, handleChangeGate } = useGatePay();
  const { trust } = useTrustUser();
  const gates = method?.gates || [];

  if (gates.length === 0) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <>
      <AppBar deep />
      <Container className="content dark-layout">
        {method?.gates.length > 1 && (
          <PayGateList gate={gate} handleChangeGate={handleChangeGate} gates={gates} />
        )}
        <div className="inner-container">
          <PayControls
            method={method}
            amount={amount}
            handleChangeAmount={handleChangeAmount}
            gate={gate}
            gates={gates}
          />
          <ChipList
            amount={amount}
            gate={gate}
            gates={gates}
            handleChangeAmount={handleChangeAmount}
          />
          <LockScreen trust={trust} gate={gate} gates={gates} />
        </div>
      </Container>
    </>
  );
};
