import { shallowEqual, useSelector } from 'react-redux';
import { getProfileStatistic } from '../selectors';

export const useProfileStatistics = () => {
  const { statistics, charts } = useSelector(getProfileStatistic, shallowEqual);
  return {
    statistics,
    charts,
  };
};
