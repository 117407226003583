import React, { useEffect, useState } from 'react';
import {
  Close,
  Container,
  Header,
  HeaderContainer,
  ListComp,
  TableColumn,
  TableHeader,
} from './case-history.styles';
import { IDialogComponent } from 'core/interfaces';
import { useGetCaseHistory } from 'games/CaseGame/hooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionRequestAddNextCaseHistory, actionRequestCaseHistory } from 'games/CaseGame/ducks';
import { CaseHistoryItem, Container as ItemContainer } from './components';

interface CaseHistoryDialogProps extends IDialogComponent<boolean> {}

const empty = new Array(10).fill(0).map((_, index) => index);

export const CaseHistoryDialog: React.FC<CaseHistoryDialogProps> = ({ handleCloseModal }) => {
  const { data, isLoading } = useGetCaseHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('cases2.0');
  const [indexItem, setIndex] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    dispatch(actionRequestCaseHistory(pageNum, 20));
    setPageNum(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const tableConfig = [t('case'), t('item'), t('status')];

  const addMore = (index: number) => {
    if (index !== indexItem) {
      if (data && index === data?.items?.length - 1) {
        dispatch(actionRequestAddNextCaseHistory(pageNum, 20));
        setPageNum(prev => prev + 1);
      }
    }
    setIndex(index);
  };

  return (
    <Container>
      <HeaderContainer>
        <Close onClick={handleCloseModal}>{t('Close')}</Close>
        <Header>{t('history')}</Header>
      </HeaderContainer>
      <div>
        <TableHeader>
          {tableConfig.map(itemName => (
            <TableColumn key={itemName}>{itemName}</TableColumn>
          ))}
        </TableHeader>
        {isLoading ? (
          empty.map(value => (
            <ItemContainer
              data-index={value % 2 === 0 ? 'even' : 'odd'}
              key={value}
              className="skeleton"
            />
          ))
        ) : (
          <ListComp
            rowCount={data?.items?.length ?? 0}
            width={window.innerWidth}
            height={window.innerHeight - 140}
            rowHeight={80}
            scrollToAlignment="start"
            onRowsRendered={({ stopIndex }: { stopIndex: number }) => addMore(stopIndex)}
            rowRenderer={({
              key,
              style,
              index,
            }: {
              key: string;
              style: React.CSSProperties;
              index: number;
            }) => {
              return (
                <CaseHistoryItem
                  key={key}
                  handleCloseModal={handleCloseModal}
                  caseImg={data?.items[index].case.backgroundImage ?? data?.items[index].case.image}
                  casePrice={data?.items[index].caseRevisionPrice}
                  caseItemImage={data?.items[index].userInventoryItem.inventoryItem.baseItem.icon}
                  openUUID={data?.items[index].openUUID}
                  itemPrice={data?.items[index].userInventoryItem.inventoryItem.price}
                  itemSkin={data?.items[index].userInventoryItem.inventoryItem.baseItem.skin}
                  itemName={data?.items[index].userInventoryItem.inventoryItem.baseItem.shortName}
                  itemId={data?.items[index].userInventoryItem.inventoryItem.id}
                  createdAt={data?.items[index].case.publishedAt}
                  status={data?.items[index].status}
                  caseId={data?.items[index].case.id}
                  fullName={data?.items[index].userInventoryItem.inventoryItem.baseItem.name}
                  userInventoryItemId={data?.items[index].userInventoryItem.id}
                  style={style}
                  index={index}
                />
              );
            }}
          ></ListComp>
        )}
      </div>
    </Container>
  );
};
