import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 17px;

  &.tablet {
    @media screen and (min-width: 500px) {
      margin: 0;
      width: 100%;
    }
  }
`;
export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 3px;
  gap: 6px;
  align-items: flex-start;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: var(--color-white);
`;
export const StyledLink = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
  color: var(--aqua-500);
  text-decoration: none;
`;
export const StyledButton = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
  color: var(--aqua-500);
  text-decoration: none;
`;
export const ListContainer = styled.div<{ isOpen: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  justify-content: center;
  height: ${p => (p.isOpen ? '100%' : '0%')};
  transition: height 0.5s cubic-bezier(0.2, 0, 0.38, 0.9);
  overflow: hidden;

  &.tablet {
    @media screen and (min-width: 500px) {
      margin: 0;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 20px;
    }

    @media screen and (min-width: 650px) {
      margin: 0;
      width: 100%;
      gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: max-content;
    }
  }
`;
