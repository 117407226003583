import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { AuthDialogs } from '../auth-dialog.config';
import { useDialog } from 'core/hooks';

interface ISignIn {
  className: string;
}

export const SignIn: FC<ISignIn> = ({ className }) => {
  const { t } = useTranslation();
  const { handleToggleDialog } = useDialog(AuthDialogs.SIGN_IN);

  return (
    <Button className={className} onClick={handleToggleDialog(AuthDialogs.SIGN_IN, true)}>
      {t('Log in to try your luck')}
    </Button>
  );
};
