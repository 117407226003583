import * as React from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { TournamentListItem } from './TournamentListItem';
import { useTranslation } from 'react-i18next';
import { Container, TournamentHeader, UserList } from './Styles';
import { ILeaderBoardItem } from '../../interfaces/reducer.leaders.interfaces';

const { useState } = React;

interface ITournamentList {
  leaderboard: ILeaderBoardItem[];
  raceType: string;
}

export const AnimatedTournamentList: React.FC<ITournamentList> = ({ leaderboard, raceType }) => {
  const [isShow, setShow] = useState<boolean>(false);
  const animatedProps = useTransition(isShow, null, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 90 * 36.4 },
    leave: { opacity: 0, height: 0 },
  });

  const { t } = useTranslation();

  return (
    <Container>
      <TournamentHeader>
        <span>
          {t('Top {{length}}:', {
            length: leaderboard.length,
          })}
        </span>
        <span>{t('winning')}</span>
      </TournamentHeader>
      <UserList>
        {leaderboard.slice(0, 10).map((user: any, index) => (
          <TournamentListItem key={user.id || index} {...user} raceType={raceType} />
        ))}
        {animatedProps.map(
          ({ item, props, key }) =>
            item && (
              <AnimatedDiv key={key} style={props}>
                {leaderboard.slice(10, 100).map((user: any, index) => (
                  <TournamentListItem key={user.id || index} {...user} raceType={raceType} />
                ))}
              </AnimatedDiv>
            )
        )}
      </UserList>
      <ShowMoreButton isShow={isShow} onClick={() => setShow(!isShow)}>
        {isShow ? t('Hide') : t('Show all')}
        <i className="icon-arrow" />
      </ShowMoreButton>
    </Container>
  );
};

type IShowMoreButtonStyle = {
  isShow: boolean;
};

const ShowMoreButton = styled.button<IShowMoreButtonStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  background-color: var(--gray-400);
  position: relative;
  border: 0;
  border-top: 1px solid var(--color-dark-600);

  color: var(--light);
  text-transform: uppercase;

  & > i[class='icon-arrow'] {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) ${p => (p.isShow ? 'rotate(-180deg)' : 'rotate(0)')};
    transform-origin: center;
    translate: transform 0.2s linear;
  }
`;

const AnimatedDiv = styled(animated.div)`
  overflow: hidden;
  will-change: opacity, height;
`;
