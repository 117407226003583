import React, { FC } from 'react';

import { AppBar } from '@components/bars';
import { Rules } from '../../components/Rules';

export const RulesPage: FC = () => (
  <>
    <AppBar deep />
    <Rules />
  </>
);
