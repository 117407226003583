import React from 'react';
import { PayItems } from './pay-items';
import { AppBar } from '@components/bars';

export const ItemPayPage = () => {
  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <PayItems />
      </div>
    </>
  );
};
