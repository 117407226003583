export default {
  line: '連線',
  lines: '多條連線',
  total: '總額',
  Autoplay: '自動播放',
  Paytable: '中獎表',
  'Recent wins': '近期贏錢紀錄',
  'Your games': '您的遊戲',
  'Bonus game': '紅利遊戲',
  'Slot works regardless of location': '不論哪個位置皆有效',
  'Can substitute any slot in the line (Except for bonus slots)':
    '可代替連線中的任何一格 (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!': '達成所有的三格連線將贏得十次紅利遊戲！',
  'Total won': '共贏',
  'Bonus games': '紅利遊戲',
};
