import { IStore } from 'store/interface';
import { ParticipateModule } from 'core/interfaces';

export const getAppInitializedStatus = ({ app }: IStore): boolean => app.isInitialized;

export const getAppFilterRegex = ({ app }: IStore): string => app.filterRegex;

export const getDrawerOpenStatus = ({ app }: IStore): boolean => app.drawerOpenStatus;

export const getAppReconnectingStatus = ({ app }: IStore): boolean => app.reconnectingStatus;

export const getAppCurrentPage = ({ app }: IStore): string => app.currentPage;

export const getAppSound = ({ app }: IStore) => app.sound;

export const getAppDiffTime = ({ app }: IStore): number => app.settings.dbTimeDiff;

export const getAppGameTypes = ({ app }: IStore) => app.settings.gameTypes;

export const getClassicGameSettings = ({ app }: IStore) => app.settings.gameTypes.classic;

export const getGameSettings = (state: IStore, game: string) => state.app.settings.gameTypes[game];

export const getParticipateSettings = (module: ParticipateModule) => ({ app }: IStore) =>
  app.settings.gameTypes[module];

export const getFastGameSettings = ({ app }: IStore) => app.settings.gameTypes.fast;

export const getDoubleGameSettings = ({ app }: IStore) => app.settings.gameTypes.double;

export const getX50GameSettings = ({ app }: IStore) => app.settings.gameTypes.x50;

export const getCrashGameSettings = ({ app }: IStore) => app.settings.gameTypes.crash;

export const getTowerGameSettings = ({ app }: IStore) => app.settings.gameTypes.tower;

export const getInventorySettings = ({ app }: IStore) => app.settings.gameTypes.inventory;

export const getAppLocale = ({ app }: IStore) => app.locale;

export const getAppCurrency = ({ app }: IStore) => app.currency;

export const getAppCurrencySettings = ({ app }: IStore) => app.settings.currencies;

export const getExperiences = ({ app }: IStore) => app.experiences;
