import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { Container, MyButton, SocialIconWrap, SubTitle, Title } from './steam-link-account.styles';
import { Icons, useBindingProvider } from 'core/Auth';

interface ISteamLinkAccountDialog extends IDialogComponent<boolean> {}

export const SteamLinkAccountDialog: FC<ISteamLinkAccountDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { handleBindingProvider } = useBindingProvider();

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>{t('Add Steam account')}</Title>
      <SubTitle>
        {t('You need to attach your Steam account to be interact with marketplace')}
      </SubTitle>
      <MyButton onClick={handleBindingProvider('steam')}>
        <SocialIconWrap>{Icons['steam'](24, 24)}</SocialIconWrap>
        {t('Attach Steam', {
          ns: 'marketplace',
        })}
      </MyButton>
    </Container>
  );
};
