export default {
  'Difficulty level': 'Difficulty level',
  easy: 'Easy',
  medium: 'Medium',
  hard: 'Hard',
  'Your bet': 'Your bet',
  'Start game': 'Start game',
  'Take prize': 'Take',
  'Try again': 'Try again',
  Date: 'Date',
  Bet: 'Bet',
  Profit: 'Profit',
  Step: 'Step',
  Player: 'Player',
  'You climbed to the </floor> floor of the tower':
    'You climbed to the {{</floor>}} floor of the tower',
  'And found there </prize>': 'And found there {{</prize>}}',
  'Verify ticket': 'Verify ticket',
  'You conquered the tower!': 'You conquered the tower!',
};
