/* eslint-disable */
export default {
  Error: 'Error',
  'Error placing a bet': 'Error al hacer una apuesta',
  'Error reserving an item': 'Error al recibir un artículo',
  'Link saved successfully': 'Enlace guardado con éxito',
  "You've successfully updated a bet": 'Has actualizado una apuesta con éxito',
  "You've successfully sell winning item": 'Has vendido con éxito el objeto ganador',
  "You've successfully take winning item": 'Has tomado con éxito el objeto ganador',
  'Incorrect link. Enter the correct link and try again.':
    'Enlace incorrecto. Introduzca el enlace correcto e inténtelo de nuevo.',
  'This is not your link. Enter your link and try again.':
    'No es su enlace. Introduzca su enlace y vuelva a intentar.',
  "Don't forget to make your inventory public to get your winnings!":
    '¡No se olvide de hacer público su inventario para recibir sus premios!',
  'min. number of items per trade not exceeded':
    'número mínimo de objetos por intercambio no superado',
  'Too many requests, try again later': 'Demasiadas solicitudes, vuelva a intentar más tarde',
  'Your trade has been cancelled for the following reason': 'Su operación ha sido cancelada por',
  'cheating attempt': 'intento de estafa',
  'max. number of items per trade exceeded': 'cantidad máx. de artículos de operación superada',
  'player not registered on website': 'jugador no registrado en el sitio web',
  'souvenir items are not allowed': 'los artículos de recuerdo no se aceptan',
  'some of the items cannot be appraised':
    'imposible calcular el precio de algunos de los artículos',
  'some of the items are below minimum price':
    'el precio de algunos artículos es inferior al mínimo',
  'total price of a trade is below minimum': 'el precio total de operación es inferior al mínimo',
  'Your trade has been accepted!': '¡Su oferta ha sido aceptada!',
  'The items have been added to the queue.':
    'Los artículos han sido añadidos a la lista de espera.',
  'Your trade has been checked out!': '¡Su oferta ha sido comprobada!',
  'It is currently being accepted': 'Se está aceptando',
  'Unable to send a trade': 'No se puede enviar ofertas',
  'Update your trade link in the settings of the site':
    'Actualice su enlace de intercambio en la configuración del sitio',
  'Enter correct trade link and make sure your inventory is not full.':
    'Introduzca el enlace de intercambio correcto y asegúrese de que su inventario no esté lleno.',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Su cuenta no puede participar en los intercambios. Obtendrá más información, si invita a alguien a comerciar.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam no está disponible de momento. Por favor, espere y luego vuelva a intentar.',
  'Not enough money on balance': 'Pocas monedas en el balance',
  'Not enough points on balance': 'Pocas monedas en el balance',
  'Game is ending, try again later': 'El juego está terminando, vuelva a intentar más tarde',
  'Congratulations!': '¡Felicitaciones!',
  "You've successfully placed a bet": 'Ha hecho apuesta con éxito',
  "You've successfully placed a bet with </amount>":
    'Realizaste con éxito una apuesta de {{</amount>}} para el juego',
  'Item has not been found, coins have been returned':
    'El artículo no fue encontrado, las monedas han sido devueltas',
  'Your order in the store has been cancelled': 'Su encargo en la tienda ha sido cancelado',
  'The min bet is </minBet>': 'La apuesta mínima es de {{</minBet>}}',
  'The max bet is </maxBet>': 'La apuesta máxima es de {{</maxBet>}}',
  'You cannot place bet on red and black at same time': 'No puede apostar en rojo y negro a la vez',
  "You don't have CSGOFAST.com in Steam nickname!": '¡No tienes CSGOFAST.com en el apodo de Steam!',
  'You have exceeded the limit of 150 free coins per account':
    'Ha superado el límite de 150 monedas gratuitas por cuenta',
  "You've bet </amount> on the crash game #</game>!":
    '¡Has apostado {{</amount>}} en el juego de choque #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    '¡Has actualizado el coef a {{</coef>}} para el juego de choque #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    '¡Has ganado {{</amount>}} en el juego de choque #{{</number>}}!',
  'Code for </amount> has been created': 'Se ha creado un código para {{</amount>}}',
  'You already have an item': 'Ya tienes un objeto',
  '</amount> refilled on your balance': '{{</amount>}} sumados en tu saldo',
  'We will sent it to your Steam in few moments': 'Lo enviaremos a tu Steam en un momento',
  'Your items has been sended to game!': '¡Tus objetos se han enviado al juego!',
  '</amount> have been returned': 'Se han devuelto {{</amount>}}',
  'Item has not been found, retry in few minutes (</amount>)':
    'No se ha encontrado el objeto, vuelve a intentarlo en unos minutos ({{</amount>}})',
  'It is currently being processing by game bots':
    'Actualmente los bots del juego lo están procesando',
  '</amount> have been refilled': 'Se te han sumado {{</amount>}}',
  'Request successfully created': 'Se creó con éxito la solicitud',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Se aceptó tu pedido #{{</id>}} por {{</amount>}}. Espera en Steam para realizar el intercambio.',
  'Reason of order rejection': 'Motivo de rechazo del pedido',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Se aceptó tu apuesta por {{</amount>}} y se añadirá al juego en {{</time>}} segundos',
  'Your bet was added to queue, in few seconds will added to the game':
    'Tu apuesta se añadió a la cola y en pocos segundos se añadirá al juego',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'El retiro está bloqueado. Gana ${currency(amount)} más para desbloquear',
    },
  },
};
