import React from 'react';
import { Modal, transitions } from 'core/Modals';
import { InviteRaffle } from './invite-raffle';
import { leaderModals } from '../../../modal.config';

export const InviteModal = () => (
  <Modal
    modalComponent={InviteRaffle}
    modalName={leaderModals.INVITE_RAFFLE}
    anchor="portal-leaders"
    transition={transitions.SLIDE_UP}
  />
);
