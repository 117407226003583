import { Reducer } from 'redux';
import { Map } from 'immutable';

enum modalActionsTypes {
  MODAL_ON = 'core/modals/MODAL_ON',
  MODAL_OFF = 'core/modals/MODAL_OFF',
  MODAL_CHANGE_STATE = 'core/modals/MODAL_CHANGE_STATE',
}

interface IModalOnAction {
  type: typeof modalActionsTypes.MODAL_ON;
  payload: {
    modalName: string;
  };
}

interface IModalOffAction {
  type: typeof modalActionsTypes.MODAL_OFF;
  payload: {
    modalName: string;
  };
}

interface IModalChangeStateAction {
  type: typeof modalActionsTypes.MODAL_CHANGE_STATE;
  payload: {
    modalName: string;
    modalState: IModalState;
  };
}

export type modalActions = IModalOnAction | IModalOffAction | IModalChangeStateAction;
export type IModalState = boolean | (string | number)[];
export type IModalMap = Map<string, IModalState>;

const ModalMap = Map<string, IModalState>();

export const modals: Reducer<Map<string, IModalState>, modalActions> = (
  state = ModalMap,
  action
) => {
  switch (action.type) {
    case modalActionsTypes.MODAL_ON:
      return state.setIn([action.payload.modalName], false);

    case modalActionsTypes.MODAL_OFF:
      return state.delete(action.payload.modalName);

    case modalActionsTypes.MODAL_CHANGE_STATE:
      return state.setIn([action.payload.modalName], action.payload.modalState);

    default:
      return state;
  }
};

export const modalOn = (modalName: string) => ({
  type: modalActionsTypes.MODAL_ON,
  payload: {
    modalName,
  },
});

export const modalOff = (modalName: string) => ({
  type: modalActionsTypes.MODAL_OFF,
  payload: {
    modalName,
  },
});

export const modalChangeState = (modalName: string, modalState: IModalState) => ({
  type: modalActionsTypes.MODAL_CHANGE_STATE,
  payload: {
    modalName,
    modalState,
  },
});
