import { combineReducers } from 'redux';
import {
  apikeyEpic,
  loaderEpic,
  permissionEpic,
  referralEpic,
  sendVerificationEpic,
  telegramEpic,
  user as base,
  verificationListenerEpic,
  watchSettingsEpic,
  userSettingsEpic,
  userSettings as settings,
  userAvatarEpic,
  userUserBan,
} from './duck';
import { fast, gofastEpic } from './fast-inventory.duck';
import { combineEpics } from 'redux-observable';

const inventory = combineReducers({ fast });

export const user = combineReducers({ base, inventory, settings });
export const userEpics = combineEpics(
  telegramEpic,
  loaderEpic,
  sendVerificationEpic,
  verificationListenerEpic,
  permissionEpic,
  gofastEpic,
  watchSettingsEpic,
  apikeyEpic,
  referralEpic,
  userSettingsEpic,
  userAvatarEpic,
  userUserBan
);
