import React from 'react';
import { openCaseActionRequest, rouletteStatusActionAllStartRoll } from 'games/CaseGame/ducks';
import { useGetRouletteStatus } from 'games/CaseGame/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Container } from './DemoOpen.styles';

interface ButtonDemoOpenProps {
  caseId: string;
  lineCount: number;
}

export const DemoOpen: React.FC<ButtonDemoOpenProps> = React.memo(({ caseId, lineCount }) => {
  const dispatch = useDispatch();
  const { isAllBeforeRoll } = useGetRouletteStatus();
  const { t } = useTranslation('cases2.0');

  const clickHandler = () => {
    if (isAllBeforeRoll && lineCount) {
      dispatch(rouletteStatusActionAllStartRoll());
      dispatch(openCaseActionRequest({ caseId, type: 'demo', lineCount }));
    }
  };

  return (
    <Container data-status={isAllBeforeRoll ? 'show' : 'hide'} onClick={clickHandler}>
      {t('demo')}
    </Container>
  );
});
