import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ICollapseHeader } from '@components/Animates';

interface IReferralQuestion extends ICollapseHeader {
  question: string;
}

export const ReferralQuestion: FC<IReferralQuestion> = ({ handleCollapseToggle, question }) => {
  const { t } = useTranslation();

  return (
    <Container onClick={handleCollapseToggle}>
      <span>{t(question)}</span>
      <i className="icon-arrow" />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr min-content;
  grid-gap: 1.625rem;
  padding: 0 1rem;
  height: 3.75rem;
  background: var(--color-dark-600);

  & span {
    align-self: center;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-white);
  }

  & i {
    align-self: center;
    justify-self: center;
    font-size: 1rem;
    color: var(--color-gray-200);
  }
`;
