import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 16px;
`;

export const ContainerItems = styled.div`
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`;

export const Subtitle = styled.span`
  color: #a3b4bc;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
`;
