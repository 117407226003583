import styled from 'styled-components';
import { Button } from '@components/Common';
import { AuthTypes } from 'core/Auth/interfaces';

export const Container = styled.div`
  background: var(--color-dark-600);
  padding: 35px 20px 24px;
  text-align: center;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;

export const Title = styled.span`
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 700;
  text-align: left;
`;

export const SocialsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 20px;
`;

export const MyButton = styled(Button)<{
  provider: AuthTypes;
  isLinked: boolean;
}>`
  background: var(
    ${({ provider, isLinked }) => (isLinked ? '--color-dark-200' : `--color-provider-${provider}`)}
  );
  color: var(--color-white);
  display: flex;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  text-align: center;
`;

export const SocialIconWrap = styled.div`
  left: 20px;
  position: absolute;
`;

export const SocialIconCross = styled.div`
  position: absolute;
  right: 14px;
`;
