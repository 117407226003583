export default {
  Cart: '购物车',
  Price: '价格',
  Type: 'Type',
  Categories: '类别',
  Rarity: '稀缺性',
  OK: 'OK',
  Clear: '清除',
  Autoselect: '自動選取',
  Remove: 'Remove',
  Bet: '赌注',
  Min: '最低',
  Max: '最高',
  'it.': '',
  Total: '總額',
  items: '道具',
  Pistols: '手槍',
  Rifles: '步槍',
  'Submachine guns': '衝鋒槍',
  Heavy: '重武器',
  Knives: '軍刀',
  Gloves: '手套',
  Keys: '鑰匙',
  Other: '其他',
  'Base Grade': '初級',
  'Consumer Grade': '消費級',
  'Industrial Grade': '工業級',
  Restricted: '管制',
  'Mil-Spec Grade': '軍規級',
  Classified: '機密',
  'High Grade': '高級',
  Covert: '隱蔽',
  Remarkable: '卓越的',
  Exotic: '特製',
  Contraband: '違禁品',
  Extraordinary: '非凡的',
  Buy: '购买',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    '商店僅供退回贏取或無用之錢幣。嚴格禁止玩家與其他市場交易面板並透過價差從中獲利。違反者將被永久禁用。',
  'Your purchase will be delivered within 15 minutes.': '您的购买将在 15 分钟内交付',
  'Congratulations! You have successfully purchased': '恭喜！您已成功购买',
  'Order confirmation': '确认订单',
  Filters: 'Filters',
  'By price': '依價格',
  Filter: '過濾條件',
  'Not found': '沒有找到',
  'At present it is unable to find any items on your request. Try again later.':
    '目前無法找到您請求的任何項目。請稍後再試。',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
