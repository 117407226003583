import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #1d2529;
  border-radius: var(--size-radius-l);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 8px 8px 10px;

  &.available {
    border: 1px solid rgb(255, 209, 25);
  }
`;

export const Image = styled.img<{ isBlock?: boolean }>`
  height: 48px;
  width: 38px;

  &.colored {
    opacity: ${({ isBlock }) => (isBlock ? '0.25' : '1')};
  }
`;

export const Info = styled.div<{ isBlock?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.colored {
    opacity: ${({ isBlock }) => (isBlock ? '0.25' : '1')};
  }
`;

export const Category = styled.span`
  color: var(--color-white);
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  text-transform: uppercase;
`;

export const Percent = styled.span`
  color: var(--color-white);
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
`;

export const OpenWrap = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

export const Open = styled.span`
  color: #ffd11a;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
`;

export const Replay = styled.span`
  color: #b4c5cd;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
`;

export const ImageWrap = styled.div`
  align-items: center;
  background-color: #29343a;
  border-radius: var(--size-radius-m);
  display: flex;
  height: 48px;
  justify-content: center;
  width: 38.4px;

  & > img {
    padding: 3px;
  }
`;
