export const csgofastFixedRates = [
  {
    key: 'usd',
    title: 'USD',
    rate: 0.01,
    icon: '$',
    prefix: '$',
    postfix: '',
    isDefault: true,
    id: 1,
  },
  {
    key: 'rub',
    title: 'RUB',
    rate: 0.64,
    icon: '₽',
    prefix: '',
    postfix: '₽',
    id: 2,
  },
  {
    dec_num: null,
    gm_rate: null,
    icon: 'f',
    id: 8,
    isDefault: false,
    key: 'fastcoins_refill',
    postfix: null,
    prefix: null,
    rate: 0.0153846,
    title: 'FastCoin',
  },
  {
    dec_num: null,
    gm_rate: null,
    icon: 'f',
    id: 7,
    isDefault: false,
    key: 'fastcoins',
    postfix: null,
    prefix: null,
    rate: 0.01,
    title: 'FastCoin',
  },
];
