export enum Modules {
  MARKET = '/market',
  REFILL = '/refill',
  INVENTORY = '/inventory',
  // FAUCET = '/faucet',
  WITHDRAWAL = '/withdrawal/method',
  PERSONAL = '/personal',
  CLASSIC = '/classic',
  FAST = '/fast',
  HILO = '/hilo',
  CRASH = '/crash',
  DOUBLE = '/double',
  X50 = '/x50',
  BACCARAT = '/baccarat',
  WHEEL = '/wheel',
  TOWER = '/tower',
  CASES = '/cases',
  SLOT = '/slot',
  CRAFT = '/craft',
  POGGI = '/poggi',
  CHAT = '/chat',
  CASE_BATTLE = '/case-battle',
  EVENT = '/event',
  SUCCESS_PAYMENT = '/success-payment',
  PRIVACY_POLICY = '/privacy-policy',
  TOS = '/tos',
}
