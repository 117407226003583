import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@components/Common';
import { FINISH_STATUSES, OrderStatusEnum } from '../../../../../../configs';
import { useSellingDelete, useSellingPause, useSellingResume } from '../../../../../../hooks';

interface IOrdersSaleElementSideMenu {
  status: OrderStatusEnum;
  id: number;
  disabled: boolean;
}

export const OrdersSaleElementSideMenu: FC<IOrdersSaleElementSideMenu> = ({
  status,
  id,
  disabled,
}) => {
  const { handleSellingResume } = useSellingResume();
  const { handleSellingDelete } = useSellingDelete();
  const { handleTakePause } = useSellingPause();
  return (
    <Container>
      {status === OrderStatusEnum.PAUSED && (
        <Button onClick={handleSellingResume(id)} disabled={disabled}>
          <i className="icon-marketplace-play" />
        </Button>
      )}
      {OrderStatusEnum.NEW === status && (
        <Button onClick={handleTakePause(id)}>
          <i className="icon-marketplace-pause" />
        </Button>
      )}
      {(FINISH_STATUSES.includes(status) || OrderStatusEnum.NEW === status) && (
        <Button onClick={handleSellingDelete(id)}>
          <i className="icon-marketplace-delete" />
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  align-content: center;
  width: 3rem;
  border-radius: var(--size-radius-m) 0 0 var(--size-radius-m);
  padding: 0.125rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  & button {
    justify-self: center;
    align-self: center;
    background: transparent;

    & i {
      font-size: 1.375rem;
      color: var(--color-white);
    }
  }
`;
