import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getUser } from '../../../core/User/selectors';
import { getTradeBots } from '../selectors';
import { removeTradeBot } from '../duck';

import { TradeInfo } from '../components/TradeInfo';

class TradeBotContainer extends PureComponent {
  render() {
    const { tradeBot, user, removeTradeBot } = this.props;
    if (!tradeBot) return null;
    return <TradeInfo {...tradeBot} removeTradeBot={removeTradeBot} steamid_3={user.steamid_3} />;
  }
}

export const TradeBot = connect(
  state => ({
    user: getUser(state),
    tradeBot: getTradeBots(state),
  }),
  { removeTradeBot }
)(TradeBotContainer);
