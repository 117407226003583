import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { PluralizeTimer } from '@components/TimerEvent';
import { useHotOffersElement } from '../../../hooks';
import { useDialog } from 'core/hooks';
import { MarketplaceDialogs } from '../../../marketplace-dialogs.config';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IStoreHotOffersElement {
  id: string;
}

interface IStoreHotOffersElementStyle {
  rarity?: string;
}

export const StoreHotOffersElement: FC<IStoreHotOffersElement> = ({ id }) => {
  const { item } = useHotOffersElement(id);
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE);

  if (!item) return <Container className="preload" />;

  return (
    <Container
      rarity={item.items?.[0]?.baseItem.color}
      onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE, [
        'hotOffers',
        item.id,
      ])}
    >
      <div className="indicators">{item.fastDelivery && <i className="icon-fast-delivery" />}</div>
      <div className="until-time">
        <PluralizeTimer finishEvent={item.nextStatusAt} />
      </div>
      <div className="thumbnail">
        <img src={skinItemImage(item.items?.[0]?.baseItem.icon)} alt="" />
      </div>
      <div className={`float ${item.items?.[0]?.baseItem.exterior?.toLowerCase()}`}>
        {!!item.items?.[0]?.float && item.items?.[0]?.float?.toFixed(4)}
      </div>
      <div className="name">{item.items?.[0]?.baseItem.name}</div>
      <div className="price">
        <CurrencyText value={item.recommendedPrice} />
      </div>
    </Container>
  );
};

const Container = styled.div<IStoreHotOffersElementStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 0.875rem 1fr 0.875rem 1rem 1rem;
  grid-gap: 0.125rem;
  width: 100%;
  height: 100%;
  border-radius: var(--size-radius-m);
  background-color: var(--color-dark-200);
  padding: 0.75rem;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0.125rem 0 ${({ rarity }) => rarity || 'transparent'};

  &.preload {
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
    animation: 3s cubic-bezier(0.4, 0, 0.2, 1) infinite progress;
    background-size: 200% 100%;

    @keyframes progress {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: calc(200% + 100%) 0;
      }
    }
  }

  & .indicators {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1rem;
    grid-auto-rows: 1rem;
    grid-gap: 0.25rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    & .icon-fast-delivery {
      font-size: 1rem;
      color: #ffdb0c;
    }
  }

  & .until-time {
    align-self: center;
    justify-self: center;

    & span {
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      color: var(--color-white);
      margin-right: 0.25rem;

      &:last-of-type {
        margin-right: 0;
      }

      &.minutes {
        color: #ed9100;
      }

      &.seconds {
        color: var(--color-red);
      }
    }
  }

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .float {
    justify-self: center;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.3px;

    &.factory.new {
      color: var(--color-exterior-factory-new);
    }

    &.minimal.wear {
      color: var(--color-exterior-minimal-wear);
    }

    &.field-tested {
      color: var(--color-exterior-field-tested);
    }

    &.well-worn {
      color: var(--color-exterior-well-worn);
    }

    &.battle-scarred {
      color: var(--color-exterior-battle-scarred);
    }
  }

  & .name {
    width: 100%;
    justify-self: center;

    font-size: 0.8125rem;
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.63px;
    color: var(--color-white);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .price {
    justify-self: center;

    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: var(--color-white);
  }
`;
