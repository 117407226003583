import { FilterDialog as FilterDialogConfig } from 'games/CaseGame';
import { DIALOG } from 'dialogs/dialog-combiner';
import { transitions } from 'core/Modals';
import { PriceRangeDialog } from './price-range';

DIALOG.add(FilterDialogConfig.PRICE_RANGE, {
  component: PriceRangeDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
