import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getRefillCountry } from '../selectors';
import { updateSelectCountry } from '../duck';

export const useCountryPay = () => {
  const refillCountry = useSelector(getRefillCountry, shallowEqual);
  const dispatch = useDispatch();

  const handleChangeCountry = useCallback(
    (country: string) => {
      dispatch(updateSelectCountry(country));
    },
    [dispatch]
  );

  return {
    refillCountry,
    handleChangeCountry,
  };
};
