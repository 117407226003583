import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  line-height: 2.5rem;
  white-space: wrap;
  text-align: center;
  grid-gap: 1.25rem;
  padding-top: 20px;
`;
export const Span = styled.span`
  font-size: 1rem;
  color: rgba(255, 255, 255, 50%);
`;
export const Title = styled.span`
  font-size: 1.375rem;
  color: var(--color-white);
`;
export const IconContainer = styled.div`
  width: 4rem;
  height: auto;
  color: var(--color-white);
`;
