import { Button } from '@components/Common';
import styled from 'styled-components';

export const FreeButton = styled(Button)<{
  isAllAfterRoll: boolean;
  isAllStartRoll: boolean;
}>`
  background: var(--yellow);
  height: 48px;
  color: var(--color-dark-600);
  display: flex;
  align-self: center;
  width: 50%;

  &:disabled {
    pointer-events: none;
    background: var(--color-dark-600);
    color: var(--color-white);
  }

  margin-left: 0;
  text-transform: uppercase;
  ${({ isAllAfterRoll, isAllStartRoll }) =>
    isAllAfterRoll && !isAllStartRoll && 'margin-left: 50%;'};
`;
