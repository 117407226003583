export default {
  Cart: 'ตะกรา',
  Price: 'ราคา',
  Type: 'Type',
  Categories: 'หมวดหมู่',
  Rarity: 'ความหายาก',
  OK: 'OK',
  Clear: 'ล้าง',
  Autoselect: 'เลือกอัตโนมัติ',
  Remove: 'Remove',
  Bet: 'เดิมพัน',
  Min: 'ต่ำสุด',
  Max: 'สูงสุด',
  'it.': '',
  Total: 'รวม',
  items: 'ไอเทม',
  Pistols: 'ปืน',
  Rifles: 'ปืนไรเฟิล',
  'Submachine guns': 'ปืนกล',
  Heavy: 'ของหนัก',
  Knives: 'มีด',
  Gloves: 'ถุงมือ',
  Keys: 'กุญแจ',
  Other: 'แตกต่าง',
  'Base Grade': 'ระดับพื้นฐาน',
  'Consumer Grade': 'ไอเทมที่เป็นที่ต้องการสูง',
  'Industrial Grade': 'คุณภาพอุตสาหกรรม',
  Restricted: 'สิ่งต้องห้าม',
  'Mil-Spec Grade': 'คุณภาพกองทัพ',
  Classified: 'ถูกปิดเป็นความลับ',
  'High Grade': 'ระดับสูง',
  Covert: 'เป็นความลับ',
  Remarkable: 'ชนิดที่น่าสนใจ',
  Exotic: 'ชนิดที่แปลกใหม่',
  Contraband: 'การลักลอบนำเข้า',
  Extraordinary: 'ชนิดพิเศษ',
  Buy: 'ซื้อ',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'ร้านค้าทำงานเฉพาะสำหรับถอนรางวัลหรือเหรียญที่ไม่ได้ใช้ ห้ามแลกเปลี่ยนสกินเพื่อให้ได้กำไรจากความแตกต่างในราคากับตลาดอื่น ๆ โดยเด็ดขาด ผู้ฝ่าฝืนจะถูกแบนตลอดไป',
  'Your purchase will be delivered within 15 minutes.':
    'รายการที่คุณสั่งซื้อจะถูกจัดส่งภายใน 15 นาที',
  'Congratulations! You have successfully purchased':
    'ขอแสดงความยินดีด้วย คำสั่งซื้อ...........ของคุณสำเร็จแล้ว',
  'Order confirmation': 'ยืนยันการสั่งซื้อ',
  Filters: 'Filters',
  'By price': 'โดยราคา',
  Filter: 'ฟิลเตอร์',
  'Not found': 'ไม่พบอะไรเลย',
  'At present it is unable to find any items on your request. Try again later.':
    'ไม่พบรายการตามคำขอของคุณ โปรดลองใหม่ในภายหลัง',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
