import React, { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useLotteryElement } from 'core/hooks';
import { getItemUri } from 'core/utils/image.utils';
import { UserAvatar } from '@components/Common';
import { SkeletonElement } from '@UIkit';

interface ILotteryListElement {
  identifier: number;
}

export const LotteryListElement: FC<ILotteryListElement> = ({ identifier }) => {
  const { data } = useLotteryElement(identifier);

  if (!data) return <Container className="skeleton" />;
  if (!data.winnerInfo?.id) return <div />;

  return (
    <Container className={data.type}>
      <div className="line" />
      <div className="winner-avatar">
        <UserAvatar uri={data.winnerInfo.ava_link} />
      </div>
      <div className="winner-info">
        <span className="winner-info-name">{data.winnerInfo.userName}</span>
        <span className="winner-info-date">
          {dayjs(data.gameEndTimestamp).format('DD.MM.YYYY, HH:mm')}
        </span>
      </div>
      <div className="winner-lot">
        <img src={getItemUri(data.gameInfo.prizeImage, 50, 50)} alt="" />
      </div>
    </Container>
  );
};

const Container = styled(SkeletonElement)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.125rem 2.5rem 1fr 4.125rem;
  grid-gap: 0.75rem;
  height: 72px;
  background: var(--color-dark-600);

  &.free {
    --color-type: var(--color-free-lottery);
  }

  &.deposit {
    --color-type: var(--color-deposit-lottery);
  }

  &.weekly {
    --color-type: var(--color-weekly-lottery);
  }

  &.monthly {
    --color-type: var(--color-monthly-lottery);
  }

  & .line {
    width: 0.125rem;
    background: var(--color-type);
  }

  & .winner-avatar {
    justify-self: self-end;
    align-self: center;

    & img {
      border-radius: 50%;
    }
  }

  & .winner-info {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    grid-gap: 0.125rem;
    align-self: center;

    &-name,
    &-date {
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.24px;
    }

    &-name {
      color: var(--color-white);
    }

    &-date {
      color: var(--color-gray-200);
    }
  }

  & .winner-lot {
    justify-self: self-start;
    align-self: center;

    & img {
      width: 3rem;
      height: 3rem;
      object-fit: contain;
      overflow: hidden;
    }
  }
`;
