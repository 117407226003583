import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Animated from 'animated/lib/targets/react-dom';
import { withTranslation } from 'react-i18next';

import { CurrencyText } from '../../currency/index';

import { getItemUri } from '../../../core/utils/image.utils';
import { textLimit } from '../../../core/utils/text.utils';

import BgText from '../../../assets/images/substrate.png';
import StarImg from '../../../assets/images/star.png';

class _Single extends Component {
  animatedValue = new Animated.Value(0);

  componentDidMount() {
    Animated.timing(this.animatedValue, {
      toValue: 1,
      duration: 500,
    }).start();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      result: { gameId },
      t,
    } = this.props;

    return (
      <Overlay craft={this.props.craft}>
        <Animated.div
          style={{
            opacity: this.animatedValue.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Bg bgImage={this.props.bg || StarImg} />
          <Content>
            <ItemImg src={getItemUri(this.props.result.itemImage)} />
            <ItemText>{textLimit(this.props.result.itemName, 26)}</ItemText>

            {/* {user.steamid_3 !== null && canBuy === true && (
              <Button onClick={() => this.sellItem(gameId, 'get')}>{t('Take')}</Button>
            )} */}
            <Button onClick={() => this.sellItem(gameId, 'sell')}>
              {t('Sell for')} <CurrencyText value={this.props.result.itemPrice} />
            </Button>
          </Content>
        </Animated.div>
      </Overlay>
    );
  }

  sellItem = (id, action) => {
    const { closeModal, fetchDecision } = this.props;
    fetchDecision(id, action);

    Animated.timing(this.animatedValue, {
      toValue: 0,
      duration: 400,
    }).start(() => {
      closeModal(false);
    });
  };
}

export const Single = withTranslation()(_Single);

Single.propTypes = {
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  fetchDecision: PropTypes.func.isRequired,
};

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 100%;
`;
const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 38, 42, 0.9);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9995;
`;

const ItemText = styled.div`
  text-align: center;
  width: 266px;
  height: 46px;
  padding: 14px 13px 10px 13px;
  margin: 0 auto;
  background: url(${BgText}) no-repeat contain;
  color: var(--color-white);
  font-weight: 700;
  font-size: 15px;
  margin-top: 5px;
`;

const ItemImg = styled.img`
  width: 160px;
  margin: 0 auto;
  display: block;
  min-height: 130px;
`;

const Bg = styled.div`
  margin-top: -20%;
  background: url(${({ bgImage }) => bgImage}) no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  position: absolute;
  overflow: hidden;
  background-position: center;

  animation: spin 60s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = styled.button`
  padding: 6px 3px 3px 3px;
  border-radius: 4px;
  font-weight: bold;
  background: var(--primary);

  text-align: center;
  font-size: 16px;
  color: black;
  min-height: 50px;
  width: 50%;
  margin: 0 auto;
  display: block;
  border: 0;
  margin-top: 11px;
`;
