import styled from 'styled-components';

export const ModalFilterStyle = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  max-height: 80vh;
  overflow: hidden scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  & .close-button {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }

  & .filter-title {
    height: 3.75rem;
    padding: 2.125rem 1rem 0.5rem;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-white);
  }

  & .filter-list {
    display: grid;
    grid-auto-flow: row;
  }

  & .underprice,
  & .overprice {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    padding: 0.875rem 2.5rem 0.875rem 1.3rem;

    &:first-of-type {
      border-top: none;
    }

    &-title {
      font-size: 0.875rem;
      line-height: 25px;
      letter-spacing: 0.24px;
      color: var(--color-white);
    }

    &-selector {
      display: flex;
      flex-flow: row wrap;
      margin-right: -1.625rem;

      & button {
        margin: 0 0.75rem 0.5rem 0;
        padding: 0.5rem 0.75rem;
        background: var(--color-dark);
        border: 0.125rem solid var(--color-dark-200);
        border-radius: var(--size-radius-s);
        font-weight: bold;
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        color: var(--color-white);

        &:disabled {
          background: var(--color-dark-200);
        }
      }
    }
  }

  & .underprice {
    &-selector {
      & button {
        color: var(--green-100);
      }
    }
  }

  & .overprice {
    &-selector {
      & button {
        color: var(--color-red);
      }
    }
  }

  & .filter-controller {
    display: flex;
    padding: 0 1rem;
    position: sticky;
    left: 0;
    right: 0;
    bottom: -100%;
    opacity: 0;
    transition: all 100ms ease-in 100ms;
    overflow: hidden;
    height: 0;

    &.visible {
      opacity: 1;
      bottom: 0;
      height: 6rem;
    }

    & button {
      margin: 1.25rem 0 1.75rem;
    }
  }
`;
