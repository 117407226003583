import React, { FC } from 'react';
import styled from 'styled-components';
import { EmptyLayout, SkeletonElement } from '@UIkit';
import { useMetadata, useSellingIdentifiers } from '../../../../hooks';
import { OrdersSaleElement } from './orders-sale-element';

interface IOrdersSaleTab {}

const empty: string[] = Array.from({ length: 5 }, (_, i) => i.toString());

export const OrdersSaleTab: FC<IOrdersSaleTab> = () => {
  const { loading, loaded, failure } = useMetadata();
  const { identifiers } = useSellingIdentifiers();

  if (!identifiers.length && loaded) {
    return (
      <Container className="empty-sale-layout">
        <EmptyLayout ctx="publication-found" namespace="marketplace" />
      </Container>
    );
  }

  if (failure && !loaded) {
    return (
      <Container className="empty-sale-layout">
        <EmptyLayout ctx="failure" namespace="marketplace" />
      </Container>
    );
  }

  if (loading && !loaded) {
    return (
      <Container>
        {empty.map(id => (
          <EmptyElement key={id} className="skeleton" />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      {identifiers.map(id => (
        <OrdersSaleElement key={id} id={id} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  min-height: 100%;
  position: relative;
  padding: 0 1rem 0.5rem;

  &:not(.empty-sale-layout) {
    grid-auto-rows: min-content;
  }
`;

const EmptyElement = styled(SkeletonElement)`
  height: 8.75rem;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
`;
