export default {
  //CryptoPage title
  crypto: 'ถอนเงินสกุลเงินดิจิตอล',
  cryptocurrency: 'เงินดิจิตอล',
  from: 'ตั้งแต่',
  //
  'Email is required': 'ต้องการอีเมล',
  Withdraw: 'ถอน',
  'Withdrawal sum': 'ยอดถอนเงิน',
  'Amount in {{title}}': 'จำนวนเงินใน $t({{title}}):',
  'Receive in {{title}}': 'รับเป็น $t({{title}}):',
  'You will receive:': 'คุณจะได้รับ:',
  'Select the network fee': 'เลือกค่าธรรมเนียมเครือข่าย',
  'Commission:': 'ค่าคอมมิชั่น:',
  'Ultra Low': 'ช้ามาก',
  Low: 'ช้า',
  Medium: 'มาตรฐาน',
  High: 'เร็ว',
  'No commission': 'ไม่มีค่าคอมมิชชั่น',
  commission: 'อาจมีค่าธรรมเนียม',
};
