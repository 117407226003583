import { combineReducers } from 'redux';
import { refreshFastInventory } from '../../core/User/ducks/fast-inventory.duck';

const prefix = 'app/prize/';

const TRADES_RECEIVED = `${prefix}TRADES_RECEIVED`;
const UPDATE_INVENTORY_WINNING_TRADES = `${prefix}UPDATE_INVENTORY_WINNING_TRADES`;
const CLEAN_UP_TRADES = `${prefix}CLEAN_UP_TRADES`;

const trades = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case TRADES_RECEIVED:
      const { gameTypeId, itemsList } = payload;
      return {
        [gameTypeId]: itemsList.reduce((dictionary, item) => {
          return {
            ...dictionary,
            [item.id]: { ...item },
          };
        }, {}),
      };

    case UPDATE_INVENTORY_WINNING_TRADES: {
      const { items } = payload;
      return {
        inventory: items.reduce((dictionary, item, i) => {
          return {
            ...dictionary,
            [i]: { ...item },
          };
        }, {}),
      };
    }

    case CLEAN_UP_TRADES:
      return Object.keys(state).reduce((dictionary, tradeId) => {
        if (tradeId === payload) return dictionary;
        return {
          ...dictionary,
          ...state[tradeId],
        };
      }, {});

    default:
      return state;
  }
};

const reducer = combineReducers({
  trades: trades,
});

export default reducer;

export const fetchUserWinningItems = () => (dispatch, getState, { socket }) => {
  socket.emit('prize-picker:request');
};

const updateUserWinningItems = data => ({
  type: TRADES_RECEIVED,
  payload: data,
});

const updateTrades = ({ items }) => ({
  type: UPDATE_INVENTORY_WINNING_TRADES,
  payload: {
    items,
  },
});

export const selectItemsToBuy = data => (dispatch, getState, { socket }) => {
  dispatch(refreshFastInventory());
  if (data.itemsList.length === 0) return;
  socket.emit('prize-picker:decide', data);
  dispatch({ type: CLEAN_UP_TRADES, payload: data.gameTypeId });
};

export const eventsTypes = [
  { event: 'prize-picker:items', action: updateUserWinningItems },
  { event: 'trade-info-sell', action: updateUserWinningItems },
  { event: 'game.classic.inventory', action: updateTrades },
];
