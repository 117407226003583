import { ParticipateDialog } from './participate-dialog';
import { ParticipateDialogs } from 'core/interfaces';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';

export { RadialProgressBar } from './radial-progress-bar';

DIALOG.add(ParticipateDialogs.PARTICIPATE_DIALOG, {
  component: ParticipateDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
