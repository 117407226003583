import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { TabBar } from '@UIkit';
import { LotteryType } from 'core/interfaces';

const tabs: LotteryType[] = [
  LotteryType.FREE,
  LotteryType.DEPOSIT,
  LotteryType.WEEKLY,
  LotteryType.MONTHLY,
];

export const LotteryTabBar = () => {
  const match = useRouteMatch();
  const local = useLocation();
  const { t } = useTranslation('lottery');

  return (
    <TabBar>
      {tabs.map(type => (
        <NavLink
          replace
          key={type}
          to={`${match.url}?type=${type}`}
          isActive={() => local.search === `?type=${type}`}
          className="tab-item"
          activeClassName="active"
        >
          {t('lottery', {
            context: type,
            amount: 500,
            postProcess: 'with-template',
          })}
        </NavLink>
      ))}
    </TabBar>
  );
};
