import React, { FC } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from './NavBar.styles';

interface INavBar {
  tabs: string[];
}

export const NavBar: FC<INavBar> = ({ tabs }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <Container>
      {tabs.map((route: string) => (
        <Link
          key={route}
          className={`${match?.url}${route}` === location.pathname ? 'active' : ''}
          replace
          to={`${match?.url}${route}`}
        >
          {t(`${match?.url || ''}${route}`, {
            context: 'tabs',
          })}
        </Link>
      ))}
    </Container>
  );
};
