import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { FullTime } from '../duck/default-event-duck';
import { useGetQuestsInfo } from './use-get-quests-info.hook';
import { actionRequestLeaderRaceQuests } from '../duck/leader-race-duck';
import { useUser } from 'core/User';

export enum PeriodTime {
  'WEEKLY' = 'weekly',
  'DAILY' = 'daily',
}

export const useGetCategoryTime = (period: PeriodTime) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { weekly, daily, isLoaded } = useGetQuestsInfo();
  const { finishAt } = (period === PeriodTime.DAILY ? daily : weekly)?.[0] ?? {};

  const [time, setTime] = useState<FullTime>({
    days: isLoaded ? Math.ceil(dayjs.duration(dayjs(finishAt).diff(Date.now())).asDays()) : null,
    hours: isLoaded ? dayjs.duration(dayjs(finishAt).diff(Date.now())).hours() : null,
    minutes: isLoaded ? dayjs.duration(dayjs(finishAt).diff(Date.now())).minutes() : null,
  });

  useEffect(() => {
    const duration = dayjs.duration(dayjs(finishAt).diff(Date.now()));

    if (duration.milliseconds() < 0) {
      setTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      return;
    }

    const timerId = setInterval(() => {
      const d = dayjs.duration(dayjs(finishAt).diff(Date.now()));

      if (d.milliseconds() < 0) {
        if (user.id) {
          dispatch(actionRequestLeaderRaceQuests());
        }
        clearInterval(timerId);
      } else {
        setTime({
          days: Math.ceil(d.asDays()),
          hours: d.hours(),
          minutes: d.minutes(),
          seconds: d.seconds(),
        });
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [dispatch, finishAt, user.id]);

  return {
    time,
  };
};
