import styled from 'styled-components';
import { slotHeight } from '../../fast.utils';

export const UserBlock = styled.div`
  width: ${slotHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;

  & > .player-avatar {
    width: 58px;
    height: 58px;
  }
`;

export const PlayersArea = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.2rem;
  padding: 0.4rem;
`;

interface IPlayerStyle {
  color?: string;
}

export const Player = styled.div<IPlayerStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 3px;
  grid-template-rows: 1fr 0.8rem 0.8rem;

  &.player-reward:nth-child(2) {
    & div:first-of-type::after {
      content: '\\e910';
    }
  }

  & > div:first-of-type {
    align-self: center;
    justify-self: center;
    width: 58px;
    height: 58px;
    position: relative;
    margin-bottom: 0.35rem;

    & .player-avatar {
      width: 58px;
      height: 58px;
    }

    &::after {
      font-family: 'icomoon';
      font-size: 100px;
      color: var(--color-white);
      position: absolute;
      left: -21px;
      top: -19px;
      -webkit-font-smoothing: antialiased;
    }
  }

  & span {
    align-self: baseline;
    font-size: 0.6rem;
    letter-spacing: 0.44px;
    color: var(--color-white);
    line-height: 1.3;
    text-align: center;

    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .player-bet {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;

    font-size: 0.5rem;
    color: var(--color-white);

    @media screen and (min-width: 350px) {
      font-size: 0.6rem;
    }

    & span {
      font-weight: bold;
      font-size: inherit;
      margin: 0 0.2rem;
    }

    & span:first-of-type {
      color: ${p => p.color};
    }
  }
`;
