import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';

interface IUserVerificationDialog extends IDialogComponent<boolean> {}

export const UserVerificationDialog: FC<IUserVerificationDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { replace } = useHistory();

  const handleClose = useCallback(() => {
    replace('/withdrawal');
    handleCloseModal();
  }, [replace, handleCloseModal]);

  return (
    <Container>
      <div className="verification-header">
        <div className="verification-header__status">
          <i className="icon-decagram" />
          <i className="icon-hourglass-outline" />
        </div>
      </div>
      <div className="verification-body">
        <span>{t('Identification request sent')}</span>
        <span>
          {t('Your data is being verified. Please wait for our support to verify your data')}
        </span>
      </div>
      <div className="verification-footer">
        <Button className="primary" onClick={handleClose}>
          {t('ACCEPT')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 2.25rem;
  padding: 2.875rem 1rem 2.75rem;
  background-color: var(--color-gray-700);

  opacity: 0.98;
  border-radius: var(--size-radius-m);

  & .verification-header {
    justify-self: center;

    &__status {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      & .icon-decagram {
        font-size: 3.2rem;
        color: var(--yellow);
      }

      & .icon-hourglass-outline {
        color: black;
        position: absolute;
      }
    }
  }

  & .verification-body {
    display: grid;
    grid-gap: 1rem;

    & span {
      text-align: center;
      letter-spacing: 0.286px;
      color: var(--color-white);
      font-size: 0.875rem;
      line-height: 1.125rem;

      &:first-of-type {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }
`;
