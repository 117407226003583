import React from 'react';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as CancelIcon } from './cancel.svg';
import { ReactComponent as EmptyIcon } from './empty.svg';
import { ReactComponent as BackIcon } from './back.svg';
import { ReactComponent as CancelIconBLack } from './cancelBlack.svg';
import { ReactComponent as RouletteArrowDown } from './roulette-arrow-down.svg';
import { ReactComponent as RouletteArrowUp } from './roulette-arrow-up.svg';
import { ReactComponent as LightingIcon } from './lighting.svg';
import { ReactComponent as ArrowBack } from './back-arrow.svg';
import { ReactComponent as SoldIcon } from './sold-icon.svg';
import { ReactComponent as SoundIcon } from 'assets/tool-bac-assets/sound-setting.svg';
import { ReactComponent as SoundOne } from 'assets/tool-bac-assets/sound-level 1.svg';
import { ReactComponent as SoundTwo } from 'assets/tool-bac-assets/sound-level 2.svg';
import { ReactComponent as SoundThree } from 'assets/tool-bac-assets/sound-level 3.svg';
import { ReactComponent as SoundFour } from 'assets/tool-bac-assets/sound-level 4.svg';
import { ReactComponent as SoundMute } from 'assets/tool-bac-assets/sound-muted.svg';
import { ReactComponent as MixSounds } from 'assets/tool-bac-assets/mix-arrow.svg';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as PauseIcon } from './stop.svg';
import { ReactComponent as PlayIconHistoryCase } from './playIcon.svg';
import { ReactComponent as Arrow } from './arrow.svg';

import raffleStandard from './cases/raffle/standard/STANDARD_RAFFLE.mp3';
import raffleStandardFast from './cases/raffle/standard/STANDARD_RAFFLE_FAST.mp3';
import raffleDanger from './cases/raffle/danger/DANGER_RAFFLE.mp3';
import raffleDangerFast from './cases/raffle/danger/DANGER_RAFFLE_FAST.mp3';
import raffleJingle from './cases/raffle/jingle/JINGLE_RAFFLE.mp3';
import raffleJingleFast from './cases/raffle/jingle/JINGLE_RAFFLE_FAST.mp3';
import raffleApproach from './cases/raffle/mix/MIX_RAFFLE.mp3';
import raffleApproachFast from './cases/raffle/mix/MIX_RAFFLE_FAST.mp3';
import endStandardFast from './cases/end/standard/STANDARD_END_FAST.mp3';
import endStandard from './cases/end/standard/STANDARD_END.mp3';
import startStandardFast from './cases/start/standard/STANDARD_START_FAST.mp3';
import startStandard from './cases/start/standard/STANDARD_START.mp3';
import endApproachFast from './cases/end/mix/MIX_END_FAST.mp3';
import endApproach from './cases/end/mix/MIX_END.mp3';
import startApproachFast from './cases/start/mix/MIX_START_FAST.mp3';
import startApproach from './cases/start/mix/MIX_START.mp3';
import endJingleFast from './cases/end/jingle/JINGLE_END_FAST.mp3';
import endJingle from './cases/end/jingle/JINGLE_END.mp3';
import startJingleFast from './cases/start/jingle/JINGLE_START_FAST.mp3';
import startJingle from './cases/start/jingle/JINGLE_START.mp3';
import endDangerFast from './cases/end/danger/DANGER_END_FAST.mp3';
import endDanger from './cases/end/danger/DANGER_END_FAST.mp3';
import startDangerFast from './cases/start/danger/DANGER_START_FAST.mp3';
import startDanger from './cases/start/danger/DANGER_START.mp3';
import profit from './profit-item.gif';

export const SvgIcons = {
  SearchIcon,
  CancelIcon,
  EmptyIcon,
  BackIcon,
  CancelIconBLack,
  RouletteArrowDown,
  RouletteArrowUp,
  LightingIcon,
  ArrowBack,
  SoldIcon,
  SoundIcon,
  SoundOne,
  SoundTwo,
  SoundThree,
  SoundFour,
  SoundMute,
  MixSounds,
  PlayIcon,
  PauseIcon,
  PlayIconHistoryCase,
  Arrow,
};

export const gif = {
  profit,
};

interface SoundSpeedType {
  fast: string;
  normal: string;
}

interface StateTypes {
  start: SoundSpeedType;
  raffle: SoundSpeedType;
  end: SoundSpeedType;
}

export const sounds: Record<string, StateTypes> = {
  standard: {
    raffle: {
      fast: raffleStandardFast,
      normal: raffleStandard,
    },
    end: {
      fast: endStandardFast,
      normal: endStandard,
    },
    start: {
      fast: startStandardFast,
      normal: startStandard,
    },
  },
  approach: {
    raffle: {
      fast: raffleApproachFast,
      normal: raffleApproach,
    },
    end: {
      fast: endApproachFast,
      normal: endApproach,
    },
    start: {
      fast: startApproachFast,
      normal: startApproach,
    },
  },
  jingle: {
    raffle: {
      fast: raffleJingleFast,
      normal: raffleJingle,
    },
    end: {
      fast: endJingleFast,
      normal: endJingle,
    },
    start: {
      fast: startJingleFast,
      normal: startJingle,
    },
  },
  danger: {
    raffle: {
      fast: raffleDangerFast,
      normal: raffleDanger,
    },
    end: {
      fast: endDangerFast,
      normal: endDanger,
    },
    start: {
      fast: startDangerFast,
      normal: startDanger,
    },
  },
  soundOff: {
    raffle: {
      fast: '',
      normal: '',
    },
    end: {
      fast: '',
      normal: '',
    },
    start: {
      fast: '',
      normal: '',
    },
  },
};

interface SoundsConfig {
  type: string;
  Icon: JSX.Element;
  sound: string;
}

export const soundsConfig: Record<string, SoundsConfig> = {
  standard: {
    type: 'standard',
    Icon: <SoundOne />,
    sound: sounds.standard.raffle.normal,
  },

  danger: {
    type: 'danger',
    Icon: <SoundTwo />,
    sound: sounds.danger.raffle.normal,
  },
  jingle: {
    type: 'jingle',
    Icon: <SoundThree />,
    sound: sounds.jingle.raffle.normal,
  },
  approach: {
    type: 'approach',
    Icon: <SoundFour />,
    sound: sounds.approach.raffle.normal,
  },
  random: {
    type: 'random',
    Icon: <MixSounds />,
    sound: '',
  },
  soundoff: {
    type: 'soundOff',
    Icon: <SoundMute />,
    sound: '',
  },
};
