import React, { FC } from 'react';
import { QuestList } from '../index';
import { useQuestFacade } from '../../hooks';
import { IQuest } from '../../interfaces/reducer.leaders.interfaces';

export const LobbyQuests: FC = () => {
  const { questTypes, takeRewarHandle } = useQuestFacade();
  return (
    <>
      {questTypes.map((quests: IQuest[], i: number) => (
        <QuestList key={i} quests={quests} takeRewarHandle={takeRewarHandle} />
      ))}
    </>
  );
};
