import { Search } from '@components/filters';
import useDebounce from 'core/hooks/debounce/useDebounce';
import { IDialogComponent } from 'core/interfaces';
import { caseBattleSearchParamsAction } from 'games/CaseBattle';
import { useGetFilterParams } from 'games/CaseBattle/hooks';
import { actionRequestCaseList } from 'games/CaseGame';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CaseList } from '../add-cases-dialog/components/case-list';

interface SearchDialogProps extends IDialogComponent<boolean> {}

export const SearchAddCasesDialog: React.FC<SearchDialogProps> = ({ handleCloseModal }) => {
  const [query, setQuery] = useState('');
  const { filterParams } = useGetFilterParams();
  const dispatch = useDispatch();

  const debouncedValue = useDebounce(query, 300);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const cleanSearchHandler = () => {
    setQuery('');
    dispatch(caseBattleSearchParamsAction(''));
    dispatch(
      actionRequestCaseList({
        id: 'all',
        filterParams: {
          sortBy: filterParams.sortBy,
          maxPrice: filterParams.maxPrice,
          minPrice: filterParams.minPrice,
          query: null,
          battles: true,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(caseBattleSearchParamsAction(debouncedValue ? debouncedValue.trim() : null));
    dispatch(
      actionRequestCaseList({
        id: 'all',
        filterParams: {
          sortBy: filterParams.sortBy,
          maxPrice: filterParams.maxPrice,
          minPrice: filterParams.minPrice,
          query: debouncedValue ? debouncedValue.trim() : null,
          battles: true,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Search
      query={query}
      cleanSearchHandler={cleanSearchHandler}
      handleCloseModal={handleCloseModal}
      changeHandler={changeHandler}
      i18nextKey="cases2.0"
    >
      <CaseList />
    </Search>
  );
};
