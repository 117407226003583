export default {
  line: 'บรรทัด',
  lines: 'บรรทัด/เส้น',
  total: 'รวม',
  Autoplay: 'การเล่นอัตโนมัติ',
  Paytable: 'ตารางการชำระเงิน',
  'Recent wins': 'การชนะล่าสุด',
  'Your games': 'เกมของคุณ',
  'Bonus game': 'เกมโบนัส',
  'Slot works regardless of location': 'สล็อตทำงานได้ไม่ว่าจะอยู่ที่ใด',
  'Can substitute any slot in the line (Except for bonus slots)':
    'สามารถแทนช่องใด ๆ ก็ได้บนบรรทัด (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!': 'สะสมให้ครบ 3 สล็อตแล้วรับไปเลย 10 เกมโบนัส',
  'Total won': 'รางวัลรวม',
  'Bonus games': 'เกมโบนัส',
};
