import styled from 'styled-components';

export const Container = styled.div`
  align-content: center;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
  height: 100%;
  justify-content: center;
  padding: 0 3.125rem;
  width: 100%;

  & .smile,
  & .message,
  & .sub-message {
    color: var(--color-white);
    text-align: center;
  }

  & .smile {
    color: var(--yellow);
    font-size: 1.875rem;
    font-weight: bold;
    letter-spacing: 1.54px;
    line-height: 2.1875rem;
    transform: rotate(90deg);
  }

  & .message {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  & .sub-message {
    font-size: 0.875rem;
    letter-spacing: 0.24px;
    line-height: 1.5rem;
  }
`;
