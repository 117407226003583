import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';

import leadersLogo from '../assets/leaders-logo.png';
import coupons from '../assets/coupons.png';
import daily from '../assets/daily-quests.png';
import weekly from '../assets/weekly-quests.png';
import secret from '../assets/secret-quests.png';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <RulesPreview>
        <img src={leadersLogo} alt="leader-race-logo" />

        <h2>{t('Leaders Race 2021 has opened!')}</h2>
        <span>{t('Take part in tournaments with rewards of legendary prizes.')}</span>
        <RuleButton to="/leaders-race-next/raffles">{t('To leaders race')}</RuleButton>
      </RulesPreview>

      <TournamentScreen>
        <h2>{t('CSGOFAST created three types of tournaments for users:')}</h2>
        <TournamentFiled type={'regular'}>
          <div className="tournament-description_game">
            <h2>{t('Regular')}</h2>
            <span>
              {t(
                'Points are awarded for participating in any games on CSGOFAST.  The more points you earn, the higher your position in the list of participants in the daily tournament.'
              )}
            </span>
          </div>

          <div className="tournament-description_prize">
            <h3>{t('Prize pool')}</h3>
            <p>
              <i className="icon-coupon" />
              <span> 363</span>
            </p>
          </div>

          <div className="tournament-description_tickets">
            {t('Green tickets are also awarded for completing')}
            <span>{t('daily quests.')}</span>
          </div>
        </TournamentFiled>

        <TournamentFiled type={'fast'}>
          <div className="tournament-description_game">
            <h2>{t('Fast')}</h2>
            <span>
              {t(
                'The more green tickets you earned, the higher your position in the list of participants in the Fast tournament.'
              )}
            </span>
          </div>

          <div className="tournament-description_prize">
            <h3>{t('Prize pool')}</h3>
            <p>
              <i className="icon-coupon" />
              <span> 726</span>
              {' + '}
              <CurrencyText value={140000} />
            </p>
          </div>

          <div className="tournament-description_tickets">
            {t('Blue tickets are also awarded for completing')}
            <span>{t('weekly quests.')}</span>
          </div>
        </TournamentFiled>

        <TournamentFiled type={'great'}>
          <div className="tournament-description_game">
            <h2>{t('Great')}</h2>
            <span>
              {t(
                'The more blue tickets you earned, the higher your position in the list of participants in the Great tournament.'
              )}
            </span>
          </div>

          <div className="tournament-description_prize">
            <h3>{t('Prize pool')}</h3>
            <p>
              <i className="icon-coupon" />
              <span> 1089</span>
              {' + '}
              <CurrencyText value={1400000} />
            </p>
          </div>

          <div className="tournament-description_tickets">
            {t('Yellow tickets are also awarded for completing a secret quest.')}
            {t(
              'At the end of the year, we will organize a special, time-limited event that uses yellow tickets.'
            )}
          </div>
        </TournamentFiled>
      </TournamentScreen>
      <RewardScreen>
        <img src={coupons} alt="leader-race-coupons" />
        <h2>{t('What are these "tickets"?')}</h2>
        <span>{t('Tickets are the main currency of the Leader Race.')}</span>
        <span>
          {t(
            'Green and Blue tickets are used in the item raffles and in the bonus store. Yellow tickets are used in the special event at the end of the year.'
          )}
        </span>
        <span>
          {t(
            'Don’t worry! if you use tickets in the store or in the raffle, your place in the tournament will not change.'
          )}
        </span>

        <h2>{t('Quests? Yeah, quests!')}</h2>
        <span>
          {t(
            'Quests are special tasks available to all type of accounts except demo. Prize for completing the quests - Green, Blue or Yellow tickets.'
          )}
        </span>
      </RewardScreen>

      <RewardScreen>
        <img src={daily} alt="daily-quests" />
        <h2>
          {t('Quests interval_daily', {
            context: 'daily',
          })}
        </h2>
        <span>
          {t('Six simple quests that are updated once every 24 hours.')}
          {t('For completing one quest, you will receive 1 Green ticket.')}
          {t('Daily quests are performed in any order.')}
        </span>
      </RewardScreen>

      <RewardScreen>
        <img src={weekly} alt="weekly-quests" />
        <h2>
          {t('Quests interval_daily', {
            context: 'weekly',
          })}
        </h2>
        <span>
          {t('Six more difficult quests that are updated once a week.')}
          {t('For completing one quest, you will receive 1 Blue ticket.')}
          {t(
            'Weekly quests are carried out in strict order. For example, to open the second quest, firstly complete the first'
          )}
        </span>
      </RewardScreen>

      <RewardScreen>
        <img src={secret} alt="secret-quests" />
        <h2>{t('Secret quest')}</h2>
        <span>{t('Available after completing all weekly quests.')}</span>
        <span>{t('For completing the secret quest, you will receive 1 Yellow ticket.')}</span>
        <span>
          {t(
            'You won’t know what the secret quest is hiding until you complete all the weekly quests!'
          )}
        </span>
        <span>
          {t(
            'Remember! If you do not complete the secret quest before the end of the week, it will disappear.'
          )}
        </span>
        <span style={{ fontStyle: 'italic' }}>
          {t(
            '* We remind you that Yellow tickets can be used at the end of the year in a special, secret activity! Remember to save them'
          )}
        </span>
      </RewardScreen>

      <RewardScreen>
        <h2>{t('Raffles and Bonus shop')}</h2>
        <span>
          {t(
            'To participate in the draws you will need tickets that you can get for participating in tournaments, as well as for completing quests.'
          )}
        </span>
        <span>
          {t(
            'To participate in the Green Raffle, you will need Green tickets, and to participate in the Blue Raffle - Blue ones.'
          )}
        </span>
      </RewardScreen>
    </Container>
  );
};

const Container = styled.div`
  color: var(--color-white);
  line-height: 1.4;

  h2 {
    font-size: 1.25rem;
    letter-spacing: 0.54px;
  }
`;

const RuleButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;

  border-radius: 5px;
  background-color: var(--yellow);

  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.29px;
  color: var(--color-dark-600);
`;

const RulesPreview = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: calc(100vh - 78px);
  text-align: center;
  padding: 1rem;

  img {
    width: 100px;
    height: 75px;
    object-fit: contain;
  }

  h2 {
    width: 70%;
    margin-bottom: 1rem;
  }
  span {
    width: 70%;
    margin-bottom: 1.7rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
`;

const TournamentScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 1.1rem;

  & > h2 {
    margin-left: 1.2rem;
    margin-bottom: 1.2rem;
  }
`;

type ITournamentFiledStyle = {
  type: string;
};

const TournamentFiled = styled.div<ITournamentFiledStyle>`
  border-radius: 8px;
  background-color: #253138;
  margin-bottom: 1rem;
  font-size: 0.9rem;

  span {
    line-height: 1.4;
  }

  .tournament-description_game {
    padding: 3rem 1.3rem 2rem;

    h2 {
      margin-bottom: 1rem;
      color: ${p => `var(--${p.type}-race)`};
    }
  }

  .tournament-description_prize {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 88px;
    padding: 1.2rem;
    background-color: #111b1e;

    h3 {
      color: var(--color-white-600);
      text-transform: uppercase;
    }

    p {
      font-weight: bold;
      i {
        color: ${p => `var(--${p.type}-race)`};
      }

      span {
        color: ${p => `var(--${p.type}-race)`};
        font-weight: inherit;
      }
    }
  }

  .tournament-description_tickets {
    padding: 2rem 1.2rem;
  }
`;

const RewardScreen = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  padding: 2.3rem 1rem 2.3rem 2.3rem;

  img {
    width: 45%;
    height: 100px;
    object-fit: contain;
  }

  h2 {
    margin: 2rem 0;
  }

  span {
    line-height: 2.29;
    letter-spacing: 0.54px;
  }
`;
