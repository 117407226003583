import Immutable from 'immutable';
import { Reducer } from 'redux';
import { orderActions, OrderActionTypes } from '../interfaces';
import { IOrderRecord, OrderRecord } from '../interfaces/order.reducer.interface';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, concatMap, debounceTime, map, switchMap } from 'rxjs/operators';
import { ExchangeShopRepository } from '../repositories';
import { getOrder } from '../selectors';
import { of } from 'rxjs';
import { MergeType, updateFastInventoryFilter } from 'core/User';
import { InventoryItem } from 'games/CaseGame/interfaces';

export const order: Reducer<Immutable.Record<IOrderRecord>, orderActions> = (
  state = new OrderRecord(),
  action
) => {
  switch (action.type) {
    case OrderActionTypes.ADD_TO_ORDER: {
      const { item } = action.payload;
      return state
        .update('items', origin => [item, ...origin])
        .update('amount', origin => origin + item.price);
    }

    case OrderActionTypes.DELETED_FROM_ORDER: {
      const { item } = action.payload;
      return state
        .update('items', origin => origin.filter(({ id }) => id !== item.id))
        .update('amount', origin => origin - item.price);
    }

    case OrderActionTypes.RESET_ALL_ORDER: {
      return new OrderRecord();
    }

    default: {
      return state;
    }
  }
};

export const addToOrder = (item: InventoryItem) => ({
  type: OrderActionTypes.ADD_TO_ORDER,
  payload: {
    item,
  },
});

export const deleteFromOrder = (item: InventoryItem) => ({
  type: OrderActionTypes.DELETED_FROM_ORDER,
  payload: {
    item,
  },
});

export const resetOrder = () => ({
  type: OrderActionTypes.RESET_ALL_ORDER,
});

export const fetchPurchaseOrder = () => ({
  type: OrderActionTypes.FETCH_PURCHASE_ORDER,
});

const purchaseEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(OrderActionTypes.FETCH_PURCHASE_ORDER),
    debounceTime(700),
    map(() => getOrder(state$.value).map(({ id }) => id)),
    switchMap(ids =>
      ExchangeShopRepository.fetchPurchase({ ids, userInventoryIds: [] }).pipe(
        concatMap(() => [
          resetOrder(),
          updateFastInventoryFilter(['page', 'number'], 1, MergeType.RESET),
        ]),
        catchError(() => of(resetOrder()))
      )
    )
  );

export const orderEpic = combineEpics(purchaseEpic);
