import React, { useLayoutEffect, useRef, useState } from 'react';
import { Container, CountItemContainer, Span, StyledButton } from './RouletteCountButton.styles';
import { useDispatch } from 'react-redux';
import { rouletteActionRequest, rouletteStatusActionBeforeRollAll } from 'games/CaseGame/ducks';
import { useGetRouletteStatus, useGetWinItemsStatus } from 'games/CaseGame/hooks';

export const RouletteCountConfig = [1, 2, 3, 4, 5];

interface RouletteCountButtonProps {
  caseId: string;
}
export const RouletteCountButton: React.FC<RouletteCountButtonProps> = React.memo(({ caseId }) => {
  const [currentCount, setCurrentCount] = useState(1);
  const { isAllSold } = useGetWinItemsStatus();
  const { isAllBeforeRoll, isAllInRoll, isAllStartRoll } = useGetRouletteStatus();
  const [shift, setShift] = useState(0);
  const [width, setWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setWidth(ref.current.clientWidth);
    setShift(ref.current.offsetLeft);
  }, [currentCount]);

  const clickHandler = (lines: number) => {
    setCurrentCount(lines);
    dispatch(rouletteActionRequest({ caseId, lines: lines.toString() }));
    dispatch(rouletteStatusActionBeforeRollAll());
  };

  return (
    <Container
      data-roulette-status={isAllBeforeRoll || isAllSold ? 'show' : 'hide'}
      data-roulette-disable={isAllInRoll || isAllStartRoll}
    >
      {RouletteCountConfig.map(count => (
        <CountItemContainer
          ref={isActive(currentCount, count) ? ref : null}
          onClick={() => clickHandler(count)}
          key={count}
        >
          <Span>{count}</Span>
        </CountItemContainer>
      ))}
      <StyledButton
        data-roulette-status={isAllBeforeRoll || isAllSold ? 'show' : 'hide'}
        width={width}
        left={shift}
      >
        {currentCount}
      </StyledButton>
    </Container>
  );
});

const isActive = (currentCount: number, count: number) => currentCount === count;
