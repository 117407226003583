import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 16px;
  position: relative;
`;

export const Image = styled.img`
  border-radius: var(--size-radius-l);
  height: auto;
  width: 100%;
`;

export const Description = styled.div`
  background-color: #ffd11a;
  border-radius: var(--size-radius-m);
  bottom: 30%;
  display: flex;
  padding: 8px 16px;
  position: absolute;
  transform: translateY(50%);

  & > span {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.286px;
    line-height: 15.23px;
    text-align: center;

    @media (min-width: 600px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;
