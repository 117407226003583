import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { dialogChangeState, dialogOff } from 'core/ducks/dialog';
import { IDialogState } from 'core/interfaces';
import { IStore } from 'store/interface';
import { getDialogState, getActiveDialogs } from 'dialogs/selectors';

export const useDialog = (name?: string) => {
  const dialogState = useSelector<IStore, boolean | (string | number)[]>(
    state => getDialogState(state, name),
    shallowEqual
  );

  const activeDialogs = useSelector<IStore, string[]>(
    state => getActiveDialogs(state),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleToggleDialog = useCallback(
    (name: string, state: IDialogState) => (ev?: any) => {
      ev?.stopPropagation();
      dispatch(dialogChangeState(name, state));
    },
    [dispatch]
  );

  const handleCloseDialog = useCallback(() => dispatch(dialogOff(name)), [dispatch, name]);

  const handleCloseByName = useCallback((name: string) => dispatch(dialogOff(name)), [dispatch]);

  const handleOpenDialog = useCallback(handleToggleDialog(name, true), []);

  return {
    dialogState,
    activeDialogs,
    handleToggleDialog,
    handleCloseDialog,
    handleCloseByName,
    handleOpenDialog,
  };
};
