import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';
import { Link } from 'react-router-dom';
import { useReferralCampaigns } from '../../hooks';
import { SvgIcons } from './assets';
import { SvgIcons as ProfileSvgIcons } from '../Profile/assets';
import { Container } from './BalanceNavigate.styles';
// import { useUserSelfLockUntill } from 'core/User/hooks/useUserSelfLockUntill';

export const BalanceNavigate = () => {
  const { t } = useTranslation();
  const { campaigns } = useReferralCampaigns();
  // const { selfLockUntill } = useUserSelfLockUntill();

  return (
    <Container>
      {/* <NavigationLink to="/faucet" as={Link}>
        <div className="leading">
          <SvgIcons.GiftIcon />
        </div>
        <div>
          <span>{t('/faucet')}</span>
        </div>
      </NavigationLink> */}
      <NavigationLink to="/personal/balance/transaction" as={Link}>
        <div className="leading">
          <SvgIcons.TimeIcon />
        </div>
        <div>
          <span>{t('Transaction history')}</span>
        </div>
      </NavigationLink>
      {!!Object.keys(campaigns) ? (
        <NavigationLink to={'/personal/referral/campaigns'} as={Link}>
          <div className="leading">
            <ProfileSvgIcons.ReferralsIcon />
          </div>
          <div>
            <span>{t('Referral program account')}</span>
          </div>
        </NavigationLink>
      ) : (
        <NavigationLink to={'/personal/referral'} as={Link}>
          <div className="leading">
            <ProfileSvgIcons.ReferralsIcon />
          </div>
          <div>
            <span>{t('Referral program account')}</span>
          </div>
        </NavigationLink>
      )}
      {/* <NavigationLink to="/personal/balance/cashback" as={Link}>
        <div className="leading">
          <SvgIcons.CashBackIcon />
        </div>
        <div>
          <span>{t('Cashback')}</span>
        </div>
      </NavigationLink> */}
      <NavigationLink to="/personal/balance/bonus-code" as={Link}>
        <div className="leading">
          <SvgIcons.BonusesIcon />
        </div>
        <div>
          <span>{t('/personal/balance/bonus-code')}</span>
          <span className="badge-light">{t('Enter a promo code')}</span>
        </div>
      </NavigationLink>
      {/* <NavigationLink to="/personal/self-exclusion" as={Link}>
        <div className="leading">
          <ProfileSvgIcons.FreezeIcon />
        </div>
        <div>
          {selfLockUntill ? (
            <>
              <span>{t('Frozen input/output funds')}</span>
              <span className="badge-light">{selfLockUntill}</span>
            </>
          ) : (
            <span>{t('Freeze input/output funds')}</span>
          )}
        </div>
      </NavigationLink> */}
    </Container>
  );
};
