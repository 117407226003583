import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { replace } from 'connected-react-router';
import { dialogOff } from 'core/ducks/dialog';
import { referralActions, ReferralActionsTypes } from '../interfaces/referral.actions.interface';
import { ReferralRepository } from '../repository';
import { combineReducers, Reducer } from 'redux';
import {
  IReferralCampaign,
  IReferralCampaignMember,
  IReferralIncomes,
  ProfileActionsType,
} from '../interfaces';
import { PeriodTransform } from '../utils';
import { profileModals } from '../modal.config';
import { addNotification } from 'modules/Notifications/duck';
import { AjaxError } from 'rxjs/ajax';
import { Periods } from '../components/referral-next/referral-campaign-page/referral-campaign-reports/referral-campaign-reports';

//NOTE: delete additional request "new-referrals" related to task FAST-5128

const initialIncomesState = {
  totalIncome: 0,
  takenIncome: 0,
  notTakenIncome: 0,
  isLoaded: false,
};

const incomes: Reducer<IReferralIncomes, referralActions> = (
  state = initialIncomesState,
  action
) => {
  switch (action.type) {
    case ReferralActionsTypes.FETCH_REFERRAL_REPORT_INCOMES_SUCCESS: {
      return { ...action.payload, isLoaded: true };
    }
    default: {
      return state;
    }
  }
};

const campaigns: Reducer<Record<string, IReferralCampaign>, referralActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS_SUCCESS: {
      const { campaigns } = action.payload;
      return campaigns.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
    }

    // case ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_NEW_MEMBERS_SUCCESS: {
    //   const { id, report } = action.payload
    //   return {
    //     ...state,
    //     [id]: {
    //       ...state[id.toString()],
    //       reports: {
    //         ...state[id.toString()].reports,
    //         referrals: report.reduce((acc, i) => acc + i.count, 0),
    //       },
    //     },
    //   }
    // }

    case ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_INCOMES_SUCCESS: {
      const { id, report } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id.toString()],
          reports: {
            ...state[id.toString()].reports,
            incomes: report.reduce((acc, i) => acc + i.amount, 0),
            referrals: report.reduce((acc, i) => acc + i.count, 0),
            incomesByTime: {
              ...state[id.toString()].reports?.incomesByTime,
              [Periods.TODAY]: report,
            },
          },
        },
      };
    }

    case ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_SUCCESS: {
      const { id, report, period } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id.toString()],
          reports: {
            ...state[id.toString()].reports,
            incomesByTime: {
              ...state[id.toString()].reports?.incomesByTime,
              [period]: report,
            },
          },
        },
      };
    }

    case ReferralActionsTypes.PATCH_REFERRAL_CAMPAIGN_SUCCESS: {
      const { id, ...rest } = action.payload;
      return { ...state, [id]: { ...state[id], ...rest } };
    }

    default: {
      return state;
    }
  }
};

const members: Reducer<IReferralCampaignMember[], referralActions> = (state = [], action) => {
  switch (action.type) {
    case ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS_SUCCESS: {
      const { campaigns } = action.payload;
      return campaigns.reduce((acc, item) => [...item.referrals, ...acc], []);
    }

    default: {
      return state;
    }
  }
};

export const referrals = combineReducers({ incomes, campaigns, members });

const fetchReferralIncomesSuccess = (response: IReferralIncomes) => ({
  type: ReferralActionsTypes.FETCH_REFERRAL_REPORT_INCOMES_SUCCESS,
  payload: response,
});

const fetchReferralCampaignsSuccess = (campaigns: any[]) => ({
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS_SUCCESS,
  payload: {
    campaigns,
  },
});

// const updateCampaignReportNewMember = (id: number, report: any[]) => ({
//   type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_NEW_MEMBERS_SUCCESS,
//   payload: {
//     id,
//     report,
//   },
// })

const updateCampaignReportIncomes = (id: number, report: any[]) => ({
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_INCOMES_SUCCESS,
  payload: {
    id,
    report,
  },
});

export const updateReferralCampaignReport = (id: number, period: Periods) => ({
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT,
  payload: {
    id,
    period,
  },
});

const updateReferralCampaignReportSuccess = (id: number, period: Periods, report?: any[]) => ({
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_SUCCESS,
  payload: {
    id,
    period,
    report,
  },
});

export const patchReferralCampaignSuccess = (payload: Partial<IReferralCampaign>) => ({
  type: ReferralActionsTypes.PATCH_REFERRAL_CAMPAIGN_SUCCESS,
  payload,
});

export const fetchTakeReferralIncome = () => ({
  type: ReferralActionsTypes.FETCH_TAKE_REFERRAL_INCOME,
});

const loaderEpic: Epic = action$ =>
  action$.pipe(
    ofType(ProfileActionsType.PROFILE_ATTACH_LISTENERS),
    startWith(''),
    switchMap(() =>
      merge(
        action$.pipe(
          ofType(ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS_SUCCESS),
          debounceTime(1000),
          startWith({}),
          switchMap(() =>
            ReferralRepository.fetchReferralCampaigns().pipe(
              map(({ response }) => fetchReferralCampaignsSuccess(response))
            )
          )
        ),
        action$.pipe(
          ofType(ReferralActionsTypes.FETCH_REFERRAL_REPORT_INCOMES),
          startWith({}),
          switchMap(() =>
            ReferralRepository.fetchReferralIncomes().pipe(
              map(({ response }) => fetchReferralIncomesSuccess(response)),
              catchError(() => of(fetchReferralIncomesSuccess(initialIncomesState)))
            )
          )
        ),
        action$.pipe(
          ofType(ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS_SUCCESS),
          pluck('payload', 'campaigns'),
          take(1),
          switchMap((campaigns: IReferralCampaign[]) =>
            from(campaigns).pipe(
              map(({ id }) => ({
                referralCampaignId: id,
                ...PeriodTransform.getDatesByPeriod('today'),
              })),
              mergeMap(p =>
                merge(
                  // ReferralRepository.fetchReferralReportsNewMembers(p).pipe(
                  //   map(({ response }) => updateCampaignReportNewMember(p.referralCampaignId, response)),
                  //   catchError(() => of(updateCampaignReportNewMember(p.referralCampaignId, [])))
                  // ),
                  ReferralRepository.fetchReferralReportsIncomes(p).pipe(
                    map(({ response }) =>
                      updateCampaignReportIncomes(p.referralCampaignId, response)
                    ),
                    catchError(() => of(updateCampaignReportIncomes(p.referralCampaignId, [])))
                  )
                )
              )
            )
          )
        ),
        action$.pipe(
          ofType(ReferralActionsTypes.FETCH_TAKE_REFERRAL_INCOME),
          switchMap(() =>
            ReferralRepository.fetchTakeReferralIncomes().pipe(
              concatMap(() => [{ type: ReferralActionsTypes.FETCH_REFERRAL_REPORT_INCOMES }]),
              catchError((error: AjaxError) =>
                of(
                  addNotification({
                    type: 'error',
                    body: error.response.message,
                  })
                )
              )
            )
          )
        )
      )
    )
  );

export const createReferralCampaigns = (name: string, code: string) => ({
  type: ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS,
  payload: { name, code },
});

const createReferralCampaignsSuccess = () => ({
  type: ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS_SUCCESS,
});

const createReferralCampaignsEpic: Epic = action$ =>
  action$.pipe(
    ofType(ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS),
    debounceTime(700),
    pluck('payload'),
    switchMap(params =>
      ReferralRepository.fetchCreateCampaigns({ ...params, appId: 2 }).pipe(
        mergeMap(() =>
          merge(
            of(createReferralCampaignsSuccess()),
            of(replace('/personal/referral/campaigns')),
            of(dialogOff(profileModals.CREATE_REFERRAL_COMPANY)).pipe(delay(1000))
          )
        ),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

const updateReferralCampaignsEpic: Epic = action$ =>
  action$.pipe(
    ofType(ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT),
    pluck('payload'),
    switchMap(params => {
      const p = {
        referralCampaignId: params?.id,
        ...PeriodTransform.getDatesByPeriod(params?.period),
      };

      return ReferralRepository.fetchReferralReportsIncomes(p).pipe(
        map(({ response }) =>
          updateReferralCampaignReportSuccess(p.referralCampaignId, params?.period, response)
        ),
        catchError(() =>
          of(updateReferralCampaignReportSuccess(p.referralCampaignId, params?.period, []))
        )
      );
    })
  );

export const profileEpics = combineEpics(
  loaderEpic,
  updateReferralCampaignsEpic,
  createReferralCampaignsEpic
);
