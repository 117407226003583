import { useSelector } from 'react-redux';
import { getRouletteData } from '../ducks';

export const useGetWinningItem = () => {
  const { data, isLoading } = useSelector(getRouletteData);
  return {
    isLoading,
    winningItems: data.droppedItems,
  };
};
