import { combineReducers } from 'redux';
import { caseBattleListReducer } from './case-battle-duck';
import { sortMainPageReducer } from './sort-main-page-duck';
import { sortHistoryPageReducer } from './sort-history-page-duck';
import {
  createBattleReducer,
  totalPriceReducer,
  caseBattleTypeReducer,
  currentGameIdReducer,
} from './create-battle-duck';
import { sortParams } from './create-battle-filters-duck';
import { casesForCasesBattle } from './cases-duck';
import {
  caseBattleGameReducer,
  casesByIdsReducer,
  caseBattleGameReplayReducer,
} from './case-battle-game-duck';
import {
  rouletteReducer,
  animationRouletteStatusesReducer,
  soundTypeReducer,
} from './roulette-duck';

export const caseBattleReducer = combineReducers({
  caseBattleList: caseBattleListReducer,
  sortMainPageReducer,
  sortHistoryPageReducer,
  createBattleReducer,
  totalPriceReducer,
  sortParams,
  casesForCasesBattle,
  caseBattleTypeReducer,
  currentGameIdReducer,
  caseBattleGameReducer,
  caseBattleGameReplayReducer,
  rouletteReducer,
  casesByIdsReducer,
  animationRouletteStatusesReducer,
  soundTypeReducer,
});
