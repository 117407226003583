import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { PluralizeTimer } from '@components/TimerEvent';
import { useDialog } from 'core/hooks';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { useMarketplaceElement } from '../../../../hooks';
import { MarketplaceDialogs } from '../../../../marketplace-dialogs.config';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IStoreElement {
  id: string;
}

interface IStoreElementStyle {
  rarity?: string;
}

export const StoreElement: FC<IStoreElement> = ({ id }) => {
  const { item } = useMarketplaceElement('store', id);
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE);

  if (!item) return <Container className="preload" />;

  return (
    <Container
      rarity={item.items?.[0]?.baseItem.color}
      onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE, [
        'store',
        item.id,
      ])}
    >
      <div className="indicators">{item.fastDelivery && <i className="icon-fast-delivery" />}</div>
      <div className="until-time">
        <PluralizeTimer finishEvent={item.nextStatusAt} />
      </div>
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item.items?.[0]?.baseItem.icon, 112, 80)} 320w, ${skinItemImage(
            item.items?.[0]?.baseItem.icon,
            224,
            160
          )} 375w`}
          src={skinItemImage(item.items?.[0]?.baseItem.icon, 112, 80)}
          alt=""
        />
      </div>
      <div className={`float ${getExteriorByFloat(item.items?.[0]?.float)}`}>
        {!!item.items?.[0]?.float && item.items?.[0]?.float?.toFixed(4)}
      </div>
      <div className="name">{item.items?.[0]?.baseItem.name}</div>
      <div className="price">
        <CurrencyText value={item.price} />
        {item.price !== item.recommendedPrice && (
          <span>
            {' ('}
            {item.price > item.recommendedPrice && '+'}
            {Math.round((item.price / item.recommendedPrice) * 100) - 100}
            {'%)'}
          </span>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div<IStoreElementStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 0.875rem 1fr 0.875rem 1rem 1rem;
  grid-gap: 0.125rem;
  width: 100%;
  height: 100%;
  border-radius: var(--size-radius-m);
  background-color: var(--color-dark-200);
  padding: 0.75rem;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0.125rem 0 ${({ rarity }) => rarity || 'transparent'};

  & .indicators {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1rem;
    grid-auto-rows: 1rem;
    grid-gap: 0.25rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    & .icon-fast-delivery {
      font-size: 1rem;
      color: #ffdb0c;
    }
  }

  & .until-time {
    justify-self: center;

    & span {
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      color: var(--color-white);
      margin-right: 0.25rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .float {
    justify-self: center;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.3px;
    color: transparent;

    &.factory-new {
      color: var(--color-exterior-factory-new);
    }

    &.minimal-wear {
      color: var(--color-exterior-minimal-wear);
    }

    &.field-tested {
      color: var(--color-exterior-field-tested);
    }

    &.well-worn {
      color: var(--color-exterior-well-worn);
    }

    &.battle-scarred {
      color: var(--color-exterior-battle-scarred);
    }
  }

  & .name {
    width: 100%;
    justify-self: center;

    font-size: 0.8125rem;
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.63px;
    color: var(--color-white);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .price {
    justify-self: center;

    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: var(--color-white);

    & span {
      font-size: inherit;
      letter-spacing: inherit;
    }
  }
`;
