import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

export class UserSelfExclusionRepository {
  static fetchSelfExclusion(period: string): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/me/ban`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        period,
        domain: 'csgofast.com',
      }),
    });
  }
}
