import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';
import { useOrderFee, usePayoutConfig, usePayoutFee } from '../../../../hooks';
import { CurrencyText } from '@components/currency';
import { IDialogComponent } from 'core/interfaces';

interface IPayoutFeeDialog extends IDialogComponent<{ method: string }> {}

export const PayoutFeeDialog: FC<IPayoutFeeDialog> = ({ handleCloseModal, data: { method } }) => {
  const { t } = useTranslation();
  const { fee } = usePayoutFee();
  const { config } = usePayoutConfig(method);
  const { feeIndex, changeFeeAction } = useOrderFee();

  return (
    <Container>
      <div className="title">{t('Select the network fee')}</div>
      <div className="fees-list">
        {config.feeSelector.map(
          (key, i) =>
            fee[key] && (
              <div key={key} className="fees-list-item" onClick={changeFeeAction(i, method)}>
                <div>
                  <span className={`indicator ${i === feeIndex ? 'selected' : ''}`} />
                </div>
                <div className="description">
                  <span className="fee-payout">
                    {t(key)}
                    {' - '}
                    <CurrencyText value={fee[key]?.payment} />
                    {' ('}
                    {fee[key]?.percent}
                    {'%)'}
                  </span>
                  <span className="fee-time">
                    {t('Estimated transfer time:')} {fee[key]?.waitingTime}
                  </span>
                </div>
              </div>
            )
        )}
      </div>
      <div className="action">
        <Button className="primary full" onClick={handleCloseModal}>
          {t('Confirm')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1rem min-content 48px;
  padding: 2.125rem 1rem 1.75rem;
  background: var(--color-dark-600);

  & .title {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: white;
  }

  & .fees-list {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 3.75rem;
    margin: 0.5rem 0 1.25rem;

    &-item {
      display: grid;
      grid-template-columns: 1.625rem 1fr;
      grid-gap: 1.25rem;

      & div {
        align-self: center;
      }

      & .indicator {
        display: block;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background: var(--color-dark-100);
        border: 3px solid var(--gray-220);
        transition: all 70ms linear;

        &.selected {
          border-width: 4px;
          background: var(--warning);
        }
      }

      & .description {
        display: grid;
        grid-auto-rows: 1rem;
        grid-auto-flow: row;
        grid-gap: 2px;

        & .fee-payout {
          font-size: 0.875rem;
          line-height: 1rem;
          color: white;
        }

        & .fee-time {
          font-size: 13px;
          line-height: 0.875rem;
          letter-spacing: 0.286px;
          color: var(--color-gray-200);
        }
      }
    }
  }
`;
