import { IStatisticsUser } from '../duck';

export const filterStatistics = (statistic: IStatisticsUser) => {
  const updatedStatistic: IStatisticsUser = {};

  for (let key in statistic) {
    if (key.split('_')[0] !== 'craft') {
      updatedStatistic[key] = statistic[key];
    }
  }

  return updatedStatistic;
};
