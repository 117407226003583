import { GameIcon } from 'modules/Lobby/components/LobbyGame.styles';
import styled from 'styled-components';

export const Achievement = styled.div`
  background: var(--color-dark-200);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  &[data-ishidden='true'] {
    filter: blur(5px);
  }

  &[data-ispointer='true'] {
    cursor: pointer;
  }
`;

export const AchievementInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const AchievementName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const AchievementTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
`;

export const GameName = styled(AchievementTitle)`
  font-size: 0.88rem;

  &::first-letter {
    text-transform: capitalize;
  }
`;
interface IGameIconStyle {
  gameType: string;
}
//NOTE: delete unnessasury color from GlobalStyles after modifying (--color-${gameType})
export const MyGameIcon = styled(GameIcon)<IGameIconStyle>`
  color: var(--color-white);
  font-size: 0.8rem;
  height: 1.75rem;
  min-width: 1.75rem;
  width: 1.75rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export const Score = styled.span`
  color: var(--warning);
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
`;

//-------------------------------------

// const DetailInfoWrap = styled.div`
//   background: #47555f;
//   border-radius: 8px;
//   cursor: pointer;
//   display: flex;
//   display: flex;
//   gap: 1.13rem;
//   height: 140px;
//   margin-bottom: 1rem;
//   padding: 1rem 1.25rem;

//   &:last-child {
//     margin-bottom: 0;
//   }
// `

// const BetInfo = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding-top: 7px;
//   width: 100%;
// `

// const Bet = styled(Score)`
//   color: var(--color-white);
//   font-size: 0.88rem;
// `
