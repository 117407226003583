import React, { FC, MutableRefObject, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { ICollapseHeader } from '@components/Animates';
import { useTranslation } from 'react-i18next';
import { useHotOffersIdentifiers } from '../../../hooks';
import { ScrollContext } from '../scroll-context';

export const StoreHotOfferHeader: FC<ICollapseHeader> = props => (
  <ScrollContext.Consumer>
    {value => <OfferHeader parentRef={value} {...props} />}
  </ScrollContext.Consumer>
);

interface OfferHeader extends ICollapseHeader {
  parentRef: MutableRefObject<HTMLDivElement>;
}

const OfferHeader: FC<OfferHeader> = ({ handleCollapseToggle, collapsible, parentRef }) => {
  const { t } = useTranslation('marketplace');
  const { identifiers } = useHotOffersIdentifiers();

  const onScroll = useCallback(
    (ev: any) => {
      if (ev.target.scrollTop >= 300) handleCollapseToggle();
    },
    [handleCollapseToggle]
  );

  useEffect(() => {
    const element = parentRef.current;
    if (collapsible) {
      element.addEventListener('scroll', onScroll);
    } else {
      element.removeEventListener('scroll', onScroll);
    }

    return () => {
      element.removeEventListener('scroll', onScroll);
    };
  }, [parentRef, collapsible, onScroll]);

  return (
    <Container onClick={handleCollapseToggle} disabled={!identifiers.length}>
      <span>{t('Almost finished')}</span>
      <i className={`icon-arrow ${collapsible ? 'open' : ''}`} />
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem 0.75rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--color-white);
  background-color: transparent;
  border: 0;

  & i {
    display: inline-block;
    transition: transform linear 0.3s;
    &.open {
      transform: rotateX(180deg);
    }
  }
`;
