import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';
import { eventImagesPath } from 'modules/event-module/event-summertime/assets';

export const Container = styled(SkeletonElement)`
  align-items: center;
  background-color: #1d2529;
  background-image: url(${eventImagesPath.topBgPath});
  background-position: center;
  border-radius: var(--size-radius-m);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 16px;
  height: 332px;
  padding: 48px 16px 24px;
  position: relative;
  width: 312px;
`;

export const ImageContainer = styled.div`
  left: 50%;
  position: absolute;
  top: -30px;
  transform: translateX(-50%);
`;

export const AvatarImage = styled.div<{ profileType: string }>`
  background-color: #181a27;
  border-radius: 50%;
  height: 64px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 64px;

  &::before {
    border-radius: 50%;
    border: 2px solid ${({ profileType }) => `var(--color-account-type-${profileType})`};
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

export const UserIcon = styled.img`
  border-radius: 50%;
  height: 53.33px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 53.33px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const UserName = styled.span`
  color: #f7fbfc;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Position = styled.span`
  color: #a3b4bc;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

export const BalanceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Text = styled.span`
  color: #a3b4bc;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

export const Balance = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  & > span {
    color: #f7fbfc;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
  }
`;

export const BalanceList = styled.div`
  display: flex;
  gap: 16px;
`;

export const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const PriceInfo = styled.span`
  color: #f7fbfc;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

export const SkinCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const SkinResults = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const SkinCount = styled.span`
  color: #a3b4bc;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

export const SkinTotal = styled.p`
  color: #ffd11a;

  & > span {
    color: #f7fbfc;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: left;
  }
`;
