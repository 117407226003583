import * as React from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import { ICashback } from '../../interfaces/personalArea.reducer.interfaces';
import { IUserState } from 'core/User/interfaces/user.reducer.interface';
import { useTranslation } from 'react-i18next';

interface ICashbackBalance {
  cashback: ICashback;
  user: IUserState;
  handleTakeCashback: () => void;
}

export const CashbackBalance: React.FC<ICashbackBalance> = ({
  user,
  cashback,
  handleTakeCashback,
}) => {
  const { t } = useTranslation();
  const accessCashback = cashback.minCashbackSum > cashback.amount;
  const cashbackProgress = cashback.minCashbackSum - cashback.amount;

  return (
    <Container>
      <div>
        <SelectContainer>
          <span className="select-control">
            <CurrencyText value={cashback.amount} />
          </span>
        </SelectContainer>
        <CashbackDescription profileType={user.profileType}>
          <span>
            {t(user.profileType, { context: 'adjective' })} {t('account')}
          </span>
          {' | '}
          {t('Cashback')} {t('from')} <CurrencyText value={cashback.minCashbackSum} />
        </CashbackDescription>
      </div>
      <ProgressButton
        className="primary full"
        onClick={handleTakeCashback}
        disabled={accessCashback}
        progress={(cashback.amount * 100) / cashback.minCashbackSum}
      >
        <span>
          {accessCashback ? (
            <>
              {t('Left to accumulate')} <CurrencyText value={cashbackProgress} />
            </>
          ) : (
            <>{t('Get cashback')}</>
          )}
        </span>
      </ProgressButton>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 2.7rem;
  padding: 2.7rem 1rem;
  background: var(--dark);
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & .select-control {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    border: 0;

    font-size: 1.8rem;
    color: var(--light);

    & i {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
`;

interface ICashbackDescriptionStyle {
  profileType: string;
}

const CashbackDescription = styled.div<ICashbackDescriptionStyle>`
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: var(--color-white);
  text-transform: uppercase;
  line-height: 1.7;
  text-align: center;

  & span {
    font-weight: inherit;
    color: ${p => `var(--color-account-type-${p.profileType})`};
  }
`;

interface IProgressButtonStyle {
  progress: number;
}

const ProgressButton = styled(Button)<IProgressButtonStyle>`
  position: relative;
  overflow: hidden;

  &:disabled {
    background: transparent;
  }

  & > span {
    color: inherit;
    font: inherit;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: inherit;
    z-index: -2;
  }

  &:not(:disabled):before {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: ${p => Math.min(p.progress, 100)}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-gray-900);
  }
`;
