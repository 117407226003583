/*eslint no-template-curly-in-string: "off"*/
export default {
  Language: 'Язык',
  'Bets in current room': 'Ставки в текущей комнате',
  Timers: 'Таймеры',
  'Game start': 'Начало игры',
  'Game ending': 'Окончание игры',
  'Roulette ticking': 'Звук движения рулетки',
  'Roulette start': 'Старт рулетки',
  'Item set': 'Item set',
  'My bet': 'Моя ставка',
  'Coins transfer': 'Передача монет',
  'Start button': 'Кнопка старт',
  'Stop button': 'Кнопка стоп',
  'Crash button': 'Краш',
  'Count down': 'Обратный отсчёт',
  'Sound track': 'Музыкальное сопровождение',
  Crash: 'Краш',
  'Picking item': 'Выбор предмета',
  Crafting: 'Создание',
  Failure: 'Поломка',
  Win: 'Выигрыш',
  Sell: 'Продать',
  Take: 'Забрать',
  //baccarat
  'Dispense the cards': 'Раздать карты',
  'Flip the cards': 'Перевернуть карты',
  Winning: 'Выигрыш',
  Loosing: 'Проигрыш',
  Timer: 'Таймер',
  'Game end': 'Окончание игры',
  // slot
  'Spin sounds': 'Звук вращения',
  'Toggle lines': 'Переключатель линий',
  //
  'Rooms bets': 'Ставки в других комнатах',
  'Chat message sending': 'Отправка сообщения чата',
  'Chat message receiving': 'Получение сообщения чата',
  'Level up': 'Уровень повышен',
  'Next rank': 'Следующий ранг',
  Sound: 'Звук',
  'Chat sound': 'Звук чата',
  'Game sounds': 'Звуки в играх',
  Volume: 'Громкость',
  Done: 'Готово',
  'Change account': 'Изменить аккаунт',
  Avatar: 'Аватар',
  'Crop warning': 'Выбранная область будет использоваться в качестве аватара',
  'Max size file': 'Неверный тип файла или большой размер, используйте изображения не более 5мб.',

  'Balance account': 'Баланс',
  'Transaction history': 'История транзакций',
  'Statistics account': 'Статистика',
  'Referral program account': 'Реферальная программа',
  'Settings account': 'Настройки',
  'Freeze input/output funds': 'Заморозить ввод/вывод средств',
  'Frozen input/output funds': 'Заморожен ввод/вывод средств',
  'Levels account': 'Уровни',
  'To learn more': 'Узнать больше',
  'Enter a promo code': 'Ввести промокод',
  Trading: 'Настройки маркета',
  'Link Steam account': 'Привязать Steam аккаунт',
  'You need to attach your Steam account to be interact with marketplace':
    'Вам необходимо подключить свой стим аккаунт для возможности взаимодействия с торговой площадкой',
  'Add Steam account': 'Добавить Steam аккаунт',
  Sounds: 'Звуки',
  Privacy: 'Приватность',
  'Add social networks': 'Добавить соц. сети',
  'Ready soc': 'Готово ${count} из',
  'Count of': '${count} из',
  Add: 'Добавить',
  Linked: 'Привязано',
  Unbind: 'Отвязать',
  'Global sound settings': 'Глобальные настройки звука',

  'Enter contact email': 'Введите ваш email',
  'not confirmed': 'не подтверждена',
  confirmed: 'подтверждена',
  'Change email': 'ИЗМЕНИТЬ EMAIL',
  'Waiting for confirm': 'Ожидание подтверждения',
  'Subscribe to email': 'Подписаться на рассылку',
  'Sound notifications settings': 'Настройки звуковых уведомлений',
  'Add social accounts': 'Привязать аккаунты',
  Email: 'Почта',
  Change: 'ИЗМЕНИТЬ',
  'To withdraw funds through payment systems, enter your e-mail':
    'Для вывода средств через платежные системы, укажите ваш e-mail',
  'SEND REQUEST': 'Отправить запрос',
  'General sounds': 'Общие звуки',
  'Sound settings:': 'Настройки звука:',
  'Rates in other rooms': 'Ставки в других комнатах',
  'Message sent': 'Сообщение отправлено',
  'A new message': 'Новое сообщение',
  'In-game sounds': 'Внутриигровые звуки',
  Difficulty: 'Сложность',
  Bet: 'Ставка',
  Balance: 'Баланс',
  Deposit: 'Депозит',
  Withdrawal: 'Вывод',
  'Refill coins': 'Пополнить',
  Step: 'Шаг',
  'Item:': 'Предмет:',
  Coefficient: 'Коэффициент',
  'Item price': 'Стоимость предмета',
  'Lines Selected:': 'Выбранно линий:',
  'Bet per line:': 'Ставка на линию:',
  'Item price:': 'Стоимость предмета',
  'Your price:': 'Твоя цена:',
  Refill: 'Пополнение',
  'History is empty': 'История пуста',
  All: 'Все',
  'Payment in process': 'Оплата в процессе',
  'Transaction is currently in process, it may take from few seconds up to 72 hours':
    'В настоящее время транзакция находится в процессе, это может занять от нескольких секунд до 72 часов',

  'Recent wins:': 'Последние победы:',
  'Total statistics from 09.11.2018': 'Общая статистика с 09.11.2018',
  'Total games played': 'Игр сыграно',
  'Total profit': 'Общий доход',
  'TOTAL GAMES PLAYED': 'ИГР СЫГРАНО',
  'Max bet': 'Макс. ставка',
  'Average bet': 'Средняя ставка',
  'Total skins was betted': 'Поставлено скинов',
  'Max profit': 'Макс. доход',
  'Total profit on black': 'Доход за ставки на "чёрное"',
  'Total profit on red': 'Доход за ставки на "красное"',
  'Total profit on joker': 'Доход за ставки на "Joker"',
  'Total catched jokers': 'Успешных ставок на "Joker"',
  'Max win': 'Макс. выигрыш',
  'Max coef': 'Макс. коэффициент',
  'Total bets on zero': 'Депозитов на "zero"',
  'Total bets on red': 'Депозитов на "красное"',
  'Total bets on black': 'Депозитов на "чёрное"',
  'Total bets on gold': 'Депозитов на "золотое"',
  'Total bets on green': 'Депозитов на "зелёное"',
  'Total bets on blue': 'Депозитов на "синее"',
  'Total bets on Player Pairs': 'Всего ставок на пары игрока',
  'Total bets on Bank Pairs': 'Всего ставок на пары банка',
  'Total bets on Tie': 'Всего ставок на ничью',
  'Total bets on Player': 'Всего ставок на игрока',
  'Total bets on Bank': 'Всего ставок на банк',
  'Biggest drop': 'Наибольший дроп',
  'Minimal chance': 'Минимальный шанс',
  'Bonus winning': 'Выиграно бонусных игр',
  'Lucky spins': 'Счастливые вращения',
  PROFIT: 'ДОХОД',
  'MAX BET': 'МАКС. ДЕПОЗИТ',
  'AVERAGE BET': 'СРЕДНИЙ ДЕПОЗИТ',
  'TOTAL SKINS WAS BETTED': 'ПОСТАВЛЕНО СКИНОВ',
  'TOTAL PROFIT ON BLACK': 'ДОХОД ЗА СТАВКИ НА "ЧЕРНОЕ"',
  'TOTAL CATCHED JOKERS': 'УСПЕШНЫХ СТАВОК НА "JOKER"',
  'TOTAL PROFIT ON JOKER': 'ДОХОД ЗА СТАВКИ НА "JOKER"',
  'TOTAL PROFIT ON RED': 'ДОХОД ЗА СТАВКИ НА "КРАСНОЕ"',
  'MAX WIN': 'МАКС. ДЕПОЗИТ',
  'MINIMAL CHANCE': 'НАИМЕНЬШИЙ ШАНС',
  'BIGGEST DROP': 'НАИБОЛЬШИЙ ДРОП',
  'OPENED SILVER CASES': 'ОТКРЫТО СЕРЕБРЯНЫХ КЕЙСОВ',
  'OPENED ORANGE CASES': 'ОТКРЫТО ОРАНЖЕВЫХ КЕЙСОВ',
  'OPENED BLUE CASES': 'ОТКРЫТО СИНИХ КЕЙСОВ',
  'BONUS WINNING': 'ВЫИГРАНО БОНУСНЫХ ИГР',
  'Game statistics': 'Статистика по играм:',
  Statistics: 'Статистика',
  'TOTAL BETS ON ZERO': 'ДЕПОЗИТОВ НА "ZERO"',
  'TOTAL BETS ON RED': 'ДЕПОЗИТОВ НА "КРАСНОЕ"',
  'TOTAL BETS ON BLACK': 'ДЕПОЗИТОВ НА "ЧЕРНОЕ"',
  'TOTAL BETS ON GOLD': 'ДЕПОЗИТОВ НА "ЗОЛОТОЕ"',
  'TOTAL BETS ON GREEN': 'ДЕПОЗИТОВ НА "ЗЕЛЕНОЕ"',
  'TOTAL BETS ON BLUE': 'ДЕПОЗИТОВ НА "СИНЕЕ"',
  'MAX PROFIT': 'МАКС. ДОХОД',
  'First game': 'Первая игра',
  'Go to the steam profile': 'Перейти в профиль steam',
  PROFIT_STAT: 'Самый прибыльный режим',
  TOTAL_GAMES_PLAYED_STAT: 'Самый популярный режим',
  WIN_STREAK_STAT: 'Самая длинная победная серия',
  MAX_BET_STAT: 'Самая крупная ставка',
  MAX_BET_DATE_STAT: 'Ставка сделана',
  MAX_PROFIT_STAT: 'Самая удачная ставка',
  MAX_PROFIT_BET_STAT: 'Ставка, сделанная на',
  MAX_WIN_STAT: 'Самый крупный выигрыш',
  MAX_WIN_DATE_STAT: 'Ставка сделана',

  'Deposit/Withdrawal': 'Пополнение/Вывод',
  balance_games: 'Игры',
  level: 'уровень',

  'Referral system': 'Реферальная система',
  'Boost your revenue!': 'Прокачай свой профит!',
  // 'Earn with us': 'Зарабатывай с нами',
  'Create your own advertising campaign Share your referral link and get a percentage of deposits to your balance!':
    'Создай свою рекламную кампанию. Поделись своей реферальной ссылкой и получай процент от пополнений на свой счёт!',
  Referrals: 'Рефералов',
  Payments: 'Выплаты',
  Paid: 'Выплачено',
  Profit: 'Доход',
  'Create a campaign': 'Создать кампанию',
  'Still have questions? we are': 'Остались вопросы? отвечаем!',
  'Current profit': 'Current profit',
  'Total profit {currency}': 'Общий доход ${currency(amount)}',
  'campaign-tab-bar': {
    status_active: 'Активные',
    status_deleted: 'Архив',
  },
  'campaign statuses': {
    status_active: 'Активно',
  },
  'member statuses': {
    status_active: 'Активный',
    status_lost: 'Утраченный',
    status_inactive: 'Неактивный',
  },
  'referral statuses': {
    status_active: 'Активных',
    status_lost: 'Утраченных',
    status_inactive: 'Неактивных',
  },
  'Payments from campaign': 'Выплаты с кампании',
  withdraw: 'Вывод',
  'Total referrals': 'Всего рефералов',
  'All campaign referrals': 'Все рефералы кампании',
  Help: 'Помощь',
  'Copy referral link': 'Скопировать реферальную ссылку',
  'Copy promocode': 'Скопировать промокод',
  Statistic: 'Статистика',
  Transfers: 'Переходы',
  periods: {
    period_today: 'День',
    period_week: 'Неделя',
    period_month: 'Месяц',
    period_year: 'Год',
  },
  ratings: {
    rating_active: 'Топ 5 рефералов',
    rating_lost: 'Утраченные',
  },
  'Top 5': 'Топ 5',
  'New referrals': 'Новые',
  Clicks: 'Переходы',
  'Lost referrals': 'Ушедшие',
  referrals: 'Рефералы',
  'Campaign name': 'Название кампании',
  'Campaign promocode': 'Промокод кампании',
  'Manage campaign': 'Управление кампанией',
  'Delete a campaign': 'Удалить рекламную кампанию',
  Continue: 'Продолжить',
  'My promotions ( My advertising campaigns)': 'Мои рекламные кампании',

  Reward: 'Награда',
  'Pick up': 'Забрать',
  'Take your prize!': 'Забери свой приз!',
  'Raise your level and get prizes!': 'Повышай свой уровень и получай призы!',
  'History experience': 'История опыта',
  'Experience is awarded for any bets in the game.': 'Опыт начисляется за любые ставки в игры.',
  'There no lost referrals': 'Нет утраченных рефералов',
  'There no referrals yet': 'Рефералов пока нет',
  'But they will be soon': 'Но очень скоро они придут',
  'invalid-feedback': {
    minLength: 'Название слишком короткое',
    required: 'Не может быть пустым',
    checkCode: 'Этот код нельзя использовать',
  },
  Success: 'Успешно',
  'Enter the code and get bonuses': 'Введите код и получите бонусы',
  'Referral or promo code': 'Реферальный или промо код',
  'Activate code': 'Активировать код',
  'How it works?': 'Как это работает?',
  'work steps':
    '<strong>Создай код и ссылку</strong><p>Создай реферальную кампанию, придумай короткий уникальный код или используй код по умолчанию. Вместе с кодом будет создана реферальная ссылка.<p/><strong>Приглашай игроков на проект</strong><p>Приглашай игроков на проект с помощью кода или ссылки. Каждый приглашенный, становится твоим рефералом. Если пользователь регистрируется на сайте впервые, вы оба получите по 10 центов приветственного бонуса.</p><strong>Получай вознаграждение</strong><p>Ты будешь получать процент от каждой ставки, которую сделают приглашенные тобой игроки. Больше приглашенных - больше доход!</p>',
  'Referral statuses': 'Статусы рефералов',
  'The statistics show the status of your referrals':
    '<strong>В статистике отображены статусы твоих рефералов:</strong><p>Active - приглашенный пользователь уже пополнял баланс и играет на проекте. Ты получаешь часть от каждой его ставки.</p><p>Lost - игрок стал рефералом другого пользователя, ты больше не получаешь вознаграждение за его ставки.</p><p>Inactive - приглашенный игрок зарегистрировался, но ещё не пополнял баланс и не делал ставок с реального счета.</p>',
  'What is the difference between code a and a link?': 'Чем отличается код и ссылка?',
  'Use whatever format you want Sharing a link is more convenient, but where you can`t leave it, you can use a referral code In both cases, the invited player will become your referral':
    'Используй любой формат. Делиться ссылкой удобней, но там, где её нельзя оставить, можно использовать реферальный код. В обоих случаях приглашенный игрок станет твоим рефералом.',
  'Where should my code be entered?': 'Куда должны вводить мой код?',
  'The code entry field opens by clicking on the Bonuses tab in the menu':
    'Поле для ввода кода открывается по клику на вкладку Бонусы в меню.',
  'How much money will I get?': 'Сколько денег я буду получать?',
  'You will get a part of each referral`s bet Only bets from the users with Silver status or higher counts (more about statuses)':
    'Ты будешь получать часть от каждой ставки реферала. Считаются ставки только от пользователей со статусом <1>Серебряный и выше.</1>',
  'How can I get a reward?': 'Как я могу получить вознаграждение?',
  'Rewards for the referral program can be received on the balance of the site Use it for games or withdraw it in a convenient way':
    'Вознаграждение по реферальной программе можно получить на баланс сайта. Используй его для игр или выведи <0>удобным способом.</0>',
  'What is the minimum amount for withdraw?': 'Какая минимальная сумма для вывода?',
  'There`s no minimum amount for withdraw, you could withdraw any amount starting from 0.01$':
    'Минимальной суммы для вывода нет, можно выводить любую сумму от 0.01$.',
  'Why do I need to create multiple campaigns?': 'Для чего создавать несколько кампаний?',
  'Use different codes and links for each advertising channel to understand which traffic source works better and attract more active players':
    'Используй разные коды и ссылки для каждого рекламного канала, чтобы понимать, какой источник трафика работает лучше и привлекает больше активных игроков.',
  'What kind of restrictions are there?': 'Какие есть ограничения?',
  Prohibited:
    'Используйте реферальную программу только для привлечения новых и возврата старых игроков. Подключать активных игроков на свой реферальный код для получения вознаграждения запрещено. Так же запрещается использовать коды, содержащие названия брендов, имена или ники популярных лиц (если вы никак не аффилированы с ними), создавать коды с названиями игр, валют и другими ключевыми словами, с целью предугадать возможные варианты ввода игроками, у которых нет реального кода. Сервис оставляет за собой право запросить источники привлечения трафика или места размещения кода и отказать в выплате, если игроки привлекались с нарушением правил.',

  // premium
  Subscription: 'Подписка',
  'New horizons of your opportunities': 'Новые горизонты ваших возможностей!',
  'account is:': ' аккаунт это:',
  account: 'аккаунт',
  Trial: 'Демо',
  LIMIT: 'НЕ БОЛЕЕ',
  UNLIMITED: 'НЕ ОГРАНИЧЕНО',
  demo: 'Демо',
  demo_adjective: 'Демо',
  demo_adjective_two: 'Демо',
  bronze: 'Бронза',
  bronze_adjective: 'Бронзовый',
  bronze_adjective_two: 'Бронзовым',
  silver: 'Серебро',
  silver_adjective: 'Серебряный',
  silver_adjective_two: 'Серебряным',
  gold: 'Золото',
  gold_adjective: 'Золотой',
  gold_adjective_two: 'Золотым',
  platinum: 'Платиновый',
  platinum_adjective: 'Платиновый',
  platinum_adjective_two: 'Платиновым',
  diamond: 'Бриллиантовый',
  diamond_adjective: 'Бриллиантовый',
  diamond_adjective_two: 'Бриллиантовым',

  'Max bet amount': 'Макс. ставка',
  'Unlimited balance': 'Безлимитный баланс',
  'Access to all games': 'Доступ ко всем играм',
  'Experience set bonus': 'Бонус набора опыта',
  'Access to Leaders Race': 'Доступ к Гонке Лидеров',
  Giveaways: 'Доступ к Розыгрышам',
  Cashback: 'Кэшбэк',
  'Additional experience': 'Дополнительный опыт',
  'Experience gain': 'Накопление опыта',
  'Free case each month': 'Бесплатный кейс каждый месяц',
  'Free spin (4 per month)': 'Спины колеса (4 в месяц)',
  'Ability to take cashback': 'Получение кешбэка',
  'Ability to save cashback': 'Накопление кешбэка',
  'Additional points in Leaders Race': 'Дополнительные очки в Гонке Лидеров',

  'Left to accumulate': 'Осталось накопить',
  'Get cashback': 'Получить кешбэк',
  'We partially offsetting bets by cashback. Only players with a $t({{premiumType}}, { "context": "adjective"}) account and above can receive cashback.':
    'Мы частично компенсируем ставки кешбэком. Получить кешбэк могут только игроки с $t({{premiumType}}, { "context": "adjective"}) аккаунтом и выше.',
  'You have $t({{premiumType}}, { "context": "adjective"}) account':
    'У вас $t({{premiumType}}, { "context": "adjective"}) аккаунт',
  'Improve account': 'Улучшить аккаунт',

  '30 days': '30 дней',
  'To get bronze account you`ve got to refill for ${currency(amount)} at once':
    'Чтобы получить бронзовый аккаунт, необходимо разово пополнить счет на ${currency(amount)}',
  'To get gold account you need to complete subscription. Monthly price ${currency(amount)}':
    'Чтобы получить золотой аккаунт, необходимо оформить подписку. Месячная стоимость подписки составляет ${currency(amount)}',
  'To obtain a Silver account, you need to make a deposit using any available method with any amount':
    'Чтобы получить серебряный аккаунт, вам необходимо внести депозит любым доступным способом на любую сумму. Ваш аккаунт остается серебряным до тех пор, пока на нем поддерживается баланс выше 0.',
  'To get a platinum account, you need to refill your balance by a total of ${currency(amount)} per month':
    'Чтобы получить платиновый аккаунт, необходимо пополнить баланс суммарно на ${currency(amount)} в месяц. (с помощью скинов или платежных систем)',
  'To get a diamond account, you need to refill your balance by a total of ${currency(amount)} per month':
    'Чтобы получить бриллиантовый аккаунт, необходимо пополнить баланс суммарно на ${currency(amount)} в месяц. (с помощью скинов или платежных систем)',
  'Nickname:': 'Никнейм:',
  'Become top player with $t({{premiumType}}, { "context": "adjective"}) premium account':
    'Стань Топом с $t({{premiumType}}, { "context": "adjective_two"}) аккаунтом',

  'Choose a photo': 'Выберите фото',
  Save: 'Сохранить',
  Choose: 'Выбрать',
  Cancel: 'Отменить',
  'Delete all': 'Очистить все',
  Select: 'Выбрано',
  'RESEND EMAIL': 'ОТПРАВИТЬ ЕЩЕ РАЗ',
  Attached: 'Привязана',
  'Not attached': 'Не привязана',
  'Waiting for confirm...': 'Ожидание подтверждения...',
  'Where to get?': 'Где взять?',
  'Enter trade link to attach': 'Введите трейд-ссылку чтобы привязать',
  'Do not make your Steam inventory private':
    'Не делайте свой <0>Steam инвентарь приватным</0> и не меняйте свой Steam API-ключ как минимум час после завершения сделки, если мы обнаружим Вашу попытку злоупотребления, Вы потеряете всю ставку. Соблюдайте правила торговли, за их нарушение будут взиматься штрафы.',
  'No notifications': 'Уведомления отсутствуют',
  'Username change!': 'Имя пользователя изменено!',

  'Level Up reward added: ${prizeAmount} ${translate(prizeType)}':
    'Level Up вознаграждение добавлено: ${prizeAmount} ${translate(prizeType)}',

  //settings
  'Link sent to': 'Ссылка отправлена на {{address}}',
  'email-form-status_confirmed': 'подтверждена',
  'email-form-status_not confirmed': 'ожидает подтверждения',
  'email-form-status_empty': 'не подтверждена',
  'email-form-status_unsubscribe': 'ожидает подтверждения',
  'Waiting for confirmation': 'Ожидаем подтверждения',

  // nickname bonus
  'Steam Experience Bonus': 'Steam Бонус к опыту',
  'Steam experience Bonus up to 15%': 'Получить Steam Бонус к опыту до 15%',
  'Add {{domain}} to your Steam nickname and receive up to 15% bonus to XP':
    'Добавь "{{domain}}" в свой Steam никнейм и получай 15% бонус к опыту, ',
  'Bet everyday': 'делая ставки каждый день.',
  'Take bonus': 'Получить',
  Close: 'Закрыть',
  Submit: 'Подтвердить',

  steamApi: {
    noTradeLink: 'НЕТ STEAM TRADE ССЫЛКИ',
    noKey: 'НЕТ STEAM API КЛЮЧА',
    need: 'Необходима для продажи и обмена скинами с другими игроками',
    addLink: 'ДОБАВИТЬ ССЫЛКУ',
    addKey: 'ВВЕСТИ КЛЮЧ',
    link: 'ГДЕ ВЗЯТЬ?',
    addApiKey: 'Добавить Steam API-ключ',
    changeApiKey: 'Редактировать Steam API-ключ',
    addSteamTradeLink: 'Добавить Steam trade ссылку',
    editSteamTradeLink: 'Редактировать Steam trade ссылку',
    steamApiKey: 'Steam API ключ',
    steamTradeLink: 'Steam trade ссылка',
    steamLink: 'Steam ссылка',
    subTitle: 'При создании API-ключа можно использовать любое доменное имя',
    enterLink: 'Введите Steam trade ссылку',
    enterApiKey: 'Введите API-ключ',
  },

  // self-exclusion
  'Self-exclusion': 'Самоблокировка',
  'CSGOFAST offers':
    'CSGOFAST предоставляет пользователям возможность самостоятельно заблокировать свой доступ к сайту на определенный период времени. Во время этой блокировки вы не сможете играть, пополнять баланс или взаимодействовать со своим инвентарём.',
  'If you feel': 'Если вам необходима небольшая пауза, воспользуйтесь этой функцией.',
  Duration: 'Продолжительность',
  'To activate self-exclusion, a confirmation by mail is required':
    'Для активации самоизоляции необходимо подтверждение по почте',
  'If necessary, you can contact specialized organizations for help with gambling addiction:':
    ' В случае необходимости, вы можете обратиться за помощью с игроманией в специализированные организации:',
  'Add email': 'Добавить email',
  'Email to activate self-exclusion': 'Email для активации самоизоляции',
  'It is necessary to send you reports, activate self-isolation and much more':
    'Необходим для отправки вам отчетов, активации самоизоляции и многого другого',
  'To change your email':
    'Чтобы изменить еmail, перейдите по ссылке из письма, которое мы отправили на ваш подтверждённый почтовый ящик.',
  'Send confirmation': 'Отправить подтверждение',
  'Freeze the deposit/withdrawal of funds': 'Заморозить ввод/вывод средств',
  'At the time of freezing, the operations of depositing/withdrawing funds are suspended':
    'На время заморозки приостанавливаются операции ввода/вывода средств',
  'Duration of freezing:': 'Длительность заморозки:',
  'Freeze for': 'Заморозить на',
  'Email confirmed': 'Email подтверждён',
  'Email not confirmed': 'Email не подтверждён',
  'Email not added': 'Email не добавлен',
  'We will send notifications':
    'Мы будем отправлять на него уведомления при выводе средств, обещаем не спамить',
  'Confirm email': 'Подтвердить email',
  'Subscrube to email notifications': 'Подписаться на уведомления по электронной почте',
  'Confirmation has been sent, check your email': 'Подверждение отправлено, проверьте ваш email',
  'Send an email change request': 'Отправить запрос на изменение email',
  'Change my email': 'Изменить email',
  'Confirmation waiting': 'Ожидание подтверждения...',

  // privacy
  'Hide game statistics from other users': 'Скрывать игровую статистику от других пользователей ',
  'Hide Steam link in profile': 'Скрывать Steam link в профиле',
};
