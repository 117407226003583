import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  background: var(--color-dark-200);
  border-radius: 8px;
  height: 48px;
  margin-bottom: 1.88rem;
  padding: 0.81rem 1.25rem;
`;

export const Button = styled.button`
  align-items: center;
  background: var(--color-dark-200);
  border-radius: 8px;
  border: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.88rem;
  padding: 0.81rem 1.25rem;
  position: relative;
  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  width: 100%;

  &:active {
    background: var(--color-dark-600);
    color: var(--color-gray-100);
  }

  &:disabled {
    background: var(--color-dark-600);
    color: var(--color-dark-200);
  }

  svg {
    left: 1.25rem;
    position: absolute;
  }

  span {
    align-items: center;
    color: var(--color-white);
    display: inline-grid;
    font-size: 0.81rem;
    font-weight: 700;
    height: 1.5rem;
    text-transform: uppercase;
  }
`;
