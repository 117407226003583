import React, { FC, memo, MutableRefObject, useEffect } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import styled from 'styled-components';
import {
  useStoreIdentifiers,
  useStoreMetaData,
  useStorePage,
  useStorePageFilter,
} from '../../../hooks';
import { StoreElement } from './store-element';
import { EmptyLayout } from '@UIkit';

interface IStoreItems {
  parentRef: MutableRefObject<HTMLDivElement>;
}

export const StoreItems: FC<IStoreItems> = memo(({ parentRef }) => {
  const { page } = useStorePage();
  const { identifiers } = useStoreIdentifiers();
  const { meta, loading, loaded, failure } = useStoreMetaData();
  const { updatePageAction } = useStorePageFilter();

  const { virtualItems, totalSize } = useVirtual({
    size: parentRef.current ? Math.round(meta.amount / 2) : 10,
    parentRef,
    estimateSize: React.useCallback(() => (parentRef.current?.offsetWidth - 24) / 2, [parentRef]),
    overscan: 5,
  });

  useEffect(() => {
    if (virtualItems.length < 2 || loading) return;

    const halfway = virtualItems[Math.round(virtualItems.length / 2)].index * 2;
    const next = Math.round((halfway + (100 - (halfway % 100))) / meta.limit);

    if (
      halfway >= meta.offset + 98 &&
      loaded &&
      meta.amount > identifiers.length &&
      page !== next
    ) {
      updatePageAction(next);
    }
  }, [updatePageAction, meta, virtualItems, loading, loaded, page, identifiers.length]);

  if (failure) {
    return <EmptyLayout ctx="failure" namespace="marketplace" />;
  }

  if (!identifiers.length && loaded) {
    return <EmptyLayout ctx="found" namespace="marketplace" />;
  }

  return (
    <Container style={{ height: totalSize }}>
      {virtualItems.map(({ index, start, size }: VirtualItem) => (
        <div
          key={index}
          className="row"
          style={{ transform: `translateY(${start}px)`, height: size - 8 || 0 }}
        >
          <StoreElement id={identifiers[index + index]} />
          <StoreElement id={identifiers[index + index + 1]} />
        </div>
      ))}
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & .row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;

    @media only screen and (min-width: 500px) {
      margin: 0 3rem;
    }

    & .preload {
      border-radius: var(--size-radius-m);
      background-color: var(--color-dark-200);
      background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
      animation: 3s cubic-bezier(0.4, 0, 0.2, 1) infinite progress;
      background-size: 200% 100%;

      @keyframes progress {
        0% {
          background-position: -200% 0;
        }
        100% {
          background-position: calc(200% + 100%) 0;
        }
      }
    }
  }
`;
