import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { ProfilePremiumTabs } from '../profile-premium-tabs';
import { useUser } from 'core/User/hooks';
import { IDialogComponent } from 'core/interfaces';
import { BtnWrap, Container } from './profile-premium-dialog.styles';

interface IProfilePremiumDialog extends IDialogComponent<boolean> {}

export const ProfilePremiumDialog: FC<IProfilePremiumDialog> = ({ handleCloseModal }) => {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <>
      <BtnWrap className="light-layout">
        <Button className="link-light close" onClick={handleCloseModal}>
          {t('Close')}
        </Button>
      </BtnWrap>
      <Container className="content light-layout">
        <ProfilePremiumTabs user={user} />
      </Container>
    </>
  );
};
