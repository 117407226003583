import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { StoreFiltersConfig } from '../../configs';
import { changePriceRangeFilter } from '../../ducks/filters.duck';

export const useStorePriceFilter = () => {
  const dispatch = useDispatch();

  const changePrice = useCallback(
    (value: string) => () => {
      dispatch(changePriceRangeFilter(StoreFiltersConfig.price(value)));
    },
    [dispatch]
  );

  return {
    changePrice,
  };
};
