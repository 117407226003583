import React from 'react';
import { useTranslation } from 'react-i18next';
import { Category, Container, Image, Info, Open, OpenWrap } from './category-item.styles';
import { EventIcons } from 'modules/event-module/event-summertime/assets';

interface CategoryItemAvailableProps {
  categoryName: string;
  handler: () => void;
  image: string;
}

export const CategoryItemAvailable: React.FC<CategoryItemAvailableProps> = ({
  categoryName,
  handler,
  image,
}) => {
  const { t } = useTranslation();

  return (
    <Container onClick={handler} className="available">
      <Image src={image} alt={categoryName} />
      <Info>
        <Category>{categoryName}</Category>
        <OpenWrap>
          <Open>{t('open')}</Open>
          <EventIcons.Box />
        </OpenWrap>
      </Info>
    </Container>
  );
};
