import { FilterCaseBattleConfig } from 'games/CaseBattle/dialog-config';

import { transitions } from 'dialogs/transition.config';
import { DIALOG } from 'dialogs/dialog-combiner';
import { SortDialogCaseBattle } from './case-battle-filter';

DIALOG.add(FilterCaseBattleConfig.FILTER, {
  component: SortDialogCaseBattle,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
