import { combineReducers } from 'redux';

import { getUserBalance } from '../../core/User/selectors';

import { getTowerTypes } from './selectors';

import { addNotification } from '../../modules/Notifications/duck';
// import { LOGOUT } from '../../core/User/duck'

// Actions

const prefix = 'app/tower-game/';
const UPDATE_TYPES = `${prefix}UPDATE_TYPES`;
const UPDATE_TICKET = `${prefix}UPDATE_TICKET`;
const CLEAR_TICKET = `${prefix}CLEAR_TICKET`;
const UPDATE_HISTORY = `${prefix}UPDATE_HISTORY`;
const UPDATE_LEADERS = `${prefix}UPDATE_LEADERS`;

// Reducers

const types = (state = [], action) => {
  switch (action.type) {
    case UPDATE_TYPES: {
      return action.payload.sort((t1, t2) => t1.id - t2.id);
    }
    default: {
      return state;
    }
  }
};

const ticket = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TICKET: {
      return action.payload;
    }
    case CLEAR_TICKET: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const history = (state = [], action) => {
  switch (action.type) {
    case UPDATE_HISTORY: {
      return action.payload;
    }
    // case LOGOUT: {
    //   return {}
    // }
    default: {
      return state;
    }
  }
};

const leaders = (state = [], action) => {
  switch (action.type) {
    case UPDATE_LEADERS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  types: types,
  ticket: ticket,
  history: history,
  leaders: leaders,
});

export default reducer;

// Action creators

export const getTypes = () => (dispatch, getState, { socket }) => {
  socket.emit('tower:ticket-types:fetch');
};

export const getTicket = () => (dispatch, getState, { socket }) => {
  socket.emit('tower:ticket:fetch');
};

export const getHistory = () => (dispatch, getState, { socket }) => {
  socket.emit('tower:history:fetch');
};

export const getLeaders = () => (dispatch, getState, { socket }) => {
  socket.emit('tower:leaders:fetch');
};

export const updateTypes = types => ({
  type: UPDATE_TYPES,
  payload: types,
});

export const updateTicket = ticket => ({
  type: UPDATE_TICKET,
  payload: ticket,
});

export const updateHistory = history => ({
  type: UPDATE_HISTORY,
  payload: history,
});

export const updateLeaders = leaders => ({
  type: UPDATE_LEADERS,
  payload: leaders,
});

export const buyTicket = (ticketTypeId, price) => (dispatch, getState, { socket }) => {
  const coins = getUserBalance(getState());

  if (price > coins) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'Not enough money on balance',
      })
    );
  }

  const types = getTowerTypes(getState());
  const type = types.find(type => type.id === ticketTypeId);

  if (price < type.min_price) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'The min bet is </minBet>',
        params: { '</minBet>': +(type.min_price / 100).toFixed(2) },
      })
    );
  }

  if (price > type.max_price) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'The max bet is </maxBet>',
        params: { '</maxBet>': +(type.max_price / 100).toFixed(2) },
      })
    );
  }

  socket.emit('tower:ticket:buy', { ticketTypeId, price });
};

export const doStep = point => (dispatch, getState, { socket }) => {
  socket.emit('tower:ticket:step', { point });
};

export const sellTicket = () => (dispatch, getState, { socket }) => {
  socket.emit('tower:ticket:sell');
};

export const clearTicket = () => ({
  type: CLEAR_TICKET,
});

export const eventsTypes = [
  { event: 'tower:ticket-types:state', action: updateTypes },
  { event: 'tower:ticket:state', action: updateTicket },
  { event: 'tower:history:state', action: updateHistory },
  { event: 'tower:leaders:state', action: updateLeaders },
];
