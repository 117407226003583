import { rootEpics$ } from 'store/epicMiddleware';
import { store as configure } from 'store/configureStore';
import { combineEpics } from 'redux-observable';
import { gameEpic, participateEpic, reducer } from './duck';
import { BaccaratActionTypes } from '../interfaces';

const baccaratEpics = combineEpics(gameEpic, participateEpic);

export const registerBaccaratModule = () => {
  rootEpics$.next([baccaratEpics, BaccaratActionTypes.BACCARAT_DETACH_LISTENERS]);
  configure.reducerManager.add('baccarat', reducer);
  configure.dispatch({ type: BaccaratActionTypes.BACCARAT_ATTACH_LISTENERS });
};

export const unregisterBaccaratModule = () => {
  configure.reducerManager.remove('baccarat');
  configure.dispatch({ type: BaccaratActionTypes.BACCARAT_DETACH_LISTENERS });
};
