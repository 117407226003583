import styled from 'styled-components';

export const LayoutDrawer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  height: 100%;
  position: relative;
`;
