import { CurrencyText } from '@components/currency';
import {
  CaseImage,
  CaseName,
  CasePrice,
  CasePriceNameContainer,
  Container,
  Text,
  TimeContainer,
  TimeInfoWrapper,
  TimeWrapper,
} from './case-info.styles';
import React from 'react';
import { convertGMTtoFullDate } from 'games/CaseGame/utils/getTime';
import { useTranslation } from 'react-i18next';

interface CaseInfoProps {
  img: string;
  caseName: string;
  casePrice: number;
  createdAt: Date;
  updatedAt: Date;
}
export const CaseInfo: React.FC<CaseInfoProps> = React.memo(
  ({ img, caseName, casePrice, createdAt, updatedAt }) => {
    const { t } = useTranslation('cases2.0');
    const { DDMMYYY, HHMMSS } = convertGMTtoFullDate(createdAt);
    const { DDMMYYY: updatedAtDDMMYYY, HHMMSS: updatedAtHHMMSS } = convertGMTtoFullDate(updatedAt);

    return (
      <Container>
        <CaseImage src={img} />
        <CasePriceNameContainer>
          <CaseName>{caseName}</CaseName>
          <CasePrice>
            <CurrencyText value={casePrice} />
          </CasePrice>
        </CasePriceNameContainer>
        <TimeInfoWrapper>
          <Text>{t('revision.period')}:</Text>
          <TimeContainer>
            <Text>{DDMMYYY}</Text>
            <TimeWrapper>{HHMMSS}</TimeWrapper>
          </TimeContainer>
          <TimeContainer>
            <Text>
              {'/ '}
              {updatedAtDDMMYYY}
            </Text>
            <TimeWrapper>{updatedAtHHMMSS}</TimeWrapper>
          </TimeContainer>
        </TimeInfoWrapper>
      </Container>
    );
  }
);
