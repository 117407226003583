import React, { FC } from 'react';
import { IDialogComponent } from 'core/interfaces';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';
import { useStoreSingleFilter } from '../../../hooks';
import { Container } from './filter-modal.style';
import { IFiltersRecord } from '../../../interfaces';
import { StoreFiltersConfig } from '../../../configs';

interface IOverpriceDialog extends IDialogComponent<boolean> {}

export const OverpriceDialog: FC<IOverpriceDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation('marketplace');
  const { changeDetailed, values } = useStoreSingleFilter<IFiltersRecord['overprice']>('overprice');

  return (
    <Container>
      <div className="filter-title">{t('overprice')}</div>
      <div className="overprice">
        <div className="overprice-selector">
          {Array.from(StoreFiltersConfig.overprices.entries()).map(([key, val]) => (
            <button key={key} onClick={changeDetailed(val)} disabled={val === values.maxOverprice}>
              {key}%
            </button>
          ))}
        </div>
      </div>
      <div className="filter-title">{t('underprice')}</div>
      <div className="underprice">
        <div className="underprice-selector">
          {Array.from(StoreFiltersConfig.underprices.entries()).map(([key, val]) => (
            <button key={key} onClick={changeDetailed(val)} disabled={val === values.minOverprice}>
              {key}%
            </button>
          ))}
        </div>
      </div>
      <div className="split-container">
        <Button className="link-light" onClick={changeDetailed('reset')}>
          {t('Reset')}
        </Button>
        <Button className="primary" onClick={handleCloseModal}>
          {t('Accept')}
        </Button>
      </div>
    </Container>
  );
};
