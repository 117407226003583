import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { updateInventoryFilters } from '../../ducks/inventory.duck';
import { IInventoryRecord } from '../../interfaces';
import { getInventoryFilters } from '../../selector';
import { IStore } from 'store/interface';

export const useInventoryFilterController = () => {
  const filters = useSelector<IStore, IInventoryRecord['filters']>(getInventoryFilters);
  const dispatch = useDispatch();

  const handleChangeFilters = useCallback(
    (data: Partial<IInventoryRecord['filters']>) => {
      dispatch(updateInventoryFilters(data));
    },
    [dispatch]
  );

  return { filters, handleChangeFilters };
};
