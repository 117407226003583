import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Title, Container, Info, Skeleton, Progress } from './event-gifts-widget.styles';
import { EventIcons, eventImagesPath } from 'modules/event-module/comics-event/assets';
import { CasesQuestStatusEnum } from 'modules/event-module/comics-event/interfaces';
import { Modules } from 'core/interfaces';
import { actionPickUpPrize } from 'modules/event-module/comics-event/duck/default-event-duck';
import { useGetGeneralQuestInfo } from 'modules/event-module/comics-event/hooks';

export const EventGiftsWidget: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { categoriesData, reward, isLoading } = useGetGeneralQuestInfo();

  const available = categoriesData?.filter(
    ({ status }) => status === CasesQuestStatusEnum.TAKEN || status === CasesQuestStatusEnum.OPENED
  ).length;

  const redirect = () => {
    if (available !== categoriesData?.length) {
      history.push(Modules.EVENT);
      localStorage.setItem('activeTab', '0');
      localStorage.setItem('scrollpos', '0');
    } else if (reward) {
      reward.status === CasesQuestStatusEnum.AVAILABLE &&
        dispatch(
          actionPickUpPrize({
            entityId: -1,
            redirectHandler: () => {
              history.push(`..${Modules.CASES}/${reward.case.id}`);
            },
          })
        );
      reward.status === CasesQuestStatusEnum.TAKEN &&
        history.push(`..${Modules.CASES}/${reward.case.id}`);
      reward.status === CasesQuestStatusEnum.OPENED &&
        history.push(
          `..${Modules.CASES}/${reward.caseRevisionResult?.case?.id}?uuid=${reward.caseRevisionResult?.openUUID}`
        );
    }
  };

  return (
    <Container onClick={redirect} className="container">
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <img src={eventImagesPath.comicsPath} alt="comics" />
          <Info>
            {available === categoriesData.length ? (
              <>
                {reward?.status === CasesQuestStatusEnum.AVAILABLE && (
                  <Title>{t('receiveSurprize')}</Title>
                )}
                {reward?.status === CasesQuestStatusEnum.TAKEN && (
                  <Title>{t('openSurprize')}</Title>
                )}
                {reward?.status === CasesQuestStatusEnum.OPENED && (
                  <>
                    <EventIcons.Replay />
                    <Title hasIcon>{t('surprizeReceived')}</Title>
                  </>
                )}
              </>
            ) : (
              <>
                <Title>{t('searchGifts')}</Title>
                <Progress>
                  {available}/{categoriesData?.length}
                </Progress>
              </>
            )}
          </Info>
        </>
      )}
    </Container>
  );
};
