import React, { FC, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';
import { authProviderConfig } from 'core/Auth/utils';
import { AuthDialogs, useBindingProvider } from 'core/Auth';
import { Button } from '@components/Common';
import { Icons } from 'core/Auth/assets';
import {
  AnimatedContainer,
  Container,
  Info,
  ProviderButton,
  InfoFull,
} from './signin-dialog.styles';
import { IDialogComponent, ParticipateModule } from 'core/interfaces';
import { useDialog } from 'core/hooks';
import { useDrawer } from 'core/Drawer';

interface IDataDialog {
  data: boolean | ParticipateModule;
  handleCloseModal: () => void;
}

interface ISigninDialog extends IDialogComponent<IDataDialog> {}

export const SigninDialog: FC<ISigninDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { provider, handleBindingProvider } = useBindingProvider();
  const [agree, setAgree] = useState(true);
  const [showFullTOS, setShowFullTOS] = useState(false);
  const { handleCloseDialog } = useDialog(AuthDialogs.SIGN_IN);
  const { handleToggleDrawer } = useDrawer();
  const ref = useRef<HTMLDivElement>(null);
  const heightElement = ref?.current?.offsetHeight ?? 16;

  const handleShowTOS = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    setShowFullTOS(prev => !prev);
  };

  const handleTOS = () => {
    handleCloseDialog();
    handleToggleDrawer(false)();
  };

  const springProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    maxHeight: showFullTOS ? 70 : heightElement,
    minHeight: 16,
  });

  return (
    <Container>
      <div className="buffer-container" onClick={handleCloseModal} />
      <div className="wrapper">
        <div className="auth-body-container">
          <div className="auth-title">
            {t('Log in')} {'Csgofast'}
          </div>
        </div>
        <div className="auth-providers-container">
          {authProviderConfig.map(authProvider => (
            <ProviderButton
              key={authProvider}
              className={`${authProvider} ${authProvider === provider ? 'active' : ''}`}
              onClick={handleBindingProvider(authProvider)}
              disabled={!agree}
            >
              <div className="icon-container">{Icons[authProvider](17, 20)} </div>
              <span>{authProvider}</span>
            </ProviderButton>
          ))}
        </div>
        <div className="auth-agree-container">
          <div>
            <input
              type="checkbox"
              className="checkboxBlue"
              id="checkbox10"
              checked={agree}
              onChange={() => setAgree(!agree)}
            />
            <label htmlFor="checkbox10">
              <Trans i18nKey="iam18" t={t}>
                I'm 18+ and I agree to the
                <Link to="/tos" className="tos" onClick={handleTOS}>
                  Terms and Conditions.
                </Link>
              </Trans>{' '}
              <AnimatedContainer className="dropdown" style={springProps} onClick={handleShowTOS}>
                <Info className={`${showFullTOS ? 'hide' : ''}`} ref={ref}>
                  {t('inAddition')}
                </Info>
                <InfoFull>{t('inAdditionFull')}</InfoFull>
              </AnimatedContainer>
            </label>
          </div>
          <Button className="link-info" as="a" href="mailto:support@csgofast.com">
            {t('Trouble logging in?')}
          </Button>
        </div>
      </div>
    </Container>
  );
};
