import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { ReactComponent as SteamIcon } from 'core/Auth/assets/steam.svg';
import { URLS } from 'services/constant-urls';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';
import { Button, Container } from './button.styles';

interface IButtonComp {
  profile: IUserInfo;
}

export const ButtonComp: FC<IButtonComp> = React.memo(({ profile }) => {
  const { t } = useTranslation();
  const hasProfile = !!profile?.id;

  const steam = profile?.providers.find(item => item.name === 'steam');
  const handleOpenSteam = () => window.open(`${URLS.urlTradeLink}${steam?.id}`);

  return hasProfile ? (
    !!steam?.id && (
      <Button onClick={handleOpenSteam}>
        <SteamIcon width={'1.5rem'} height={'1.5rem'} />
        <span>{t('Go to the steam profile')}</span>
      </Button>
    )
  ) : (
    <Container className="skeleton" />
  );
}, isEqual);
