import React, { FC } from 'react';
import styled from 'styled-components';
import { StickySelect } from '@components/Selects';
import { Control, Option } from './RaceTypeController';
import type { ITournamnetItemType } from 'modules/LeadersRace/interfaces/reducer.leaders.interfaces';

interface IRaceTypeSwitch {
  options: ITournamnetItemType[];
  handleChangeRaceType: (index: number) => void;
  greatType: number;
}

export const RaceTypeSwitch: FC<IRaceTypeSwitch> = ({
  options,
  handleChangeRaceType,
  greatType,
}) => {
  return (
    <Container>
      <StickySelect
        options={options}
        select={greatType}
        listHeight={248}
        onChange={handleChangeRaceType}
        components={{
          Option,
          Control,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  & .select-control {
    grid-template-columns: 1fr 15px;
    background: var(--color-dark-600);
  }
`;
