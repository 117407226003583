import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { LobbyWrapper } from './lobby-wrapper';
import { LobbyGoldRaffle } from './lobby-gold-raffle';
import { LobbyCoupons } from './lobby-coupons';
import { LobbyQuests } from './lobby-quests';
import { Raffles } from '../../containers/Raffles';
import { TournamentBoard } from '../../containers/TournamentBoard';
import { LobbyShop } from './lobby-shop';
// import { LobbyTotalStatistics } from './lobby-total-statistics'
// import { LobbyPedestal } from '../@shared'
import { Balance } from '../../containers/Balance';

export const LobbyPage: FC = () => (
  <>
    <AppBar prependWidget={Balance} />
    <div className="content dark-layout">
      <LobbyGoldRaffle />
      {/*<LobbyTotalStatistics />*/}
      {/*<LobbyPedestal />*/}
      <LobbyCoupons />
      <LobbyWrapper
        title="Tournaments"
        layoutColor="var(--gray-400)"
        moreAt="/tournament/regular"
        children={TournamentBoard}
      />
      <LobbyWrapper
        title="Quests"
        layoutColor="transperent"
        // moreAt="/quest"
        children={LobbyQuests}
      />
      <LobbyWrapper
        title="Raffles"
        layoutColor="var(--gray-400)"
        moreAt="/raffles/active"
        children={Raffles}
      />
      <LobbyWrapper title="Store" layoutColor="transparent" children={LobbyShop} moreAt="/store" />
    </div>
  </>
);
