import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  position: relative;
  border-radius: 0.5rem;
  width: 110px;
  height: 120px;
  display: flex;
  text-align: center;
  background: var(--color-dark-200);
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 360px) {
    width: 90px;
    height: 100px;
  }
`;

export const CaseIcon = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  &[data-status='finish inactive'] {
    filter: saturate(36%);
  }

  &[data-status='roll active'] {
    filter: none;
  }

  &[data-status='roll inactive'] {
    filter: saturate(36%);
  }
`;

export const CaseIconFree = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.5rem;

  &[data-status='finish inactive'] {
    filter: saturate(36%);
  }

  &[data-status='roll active'] {
    filter: none;
  }

  &[data-status='roll inactive'] {
    filter: saturate(36%);
  }
`;

export const GrayContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  position: absolute;
  inset: 0;
  backdrop-filter: grayscale(100%);
`;

export const CasePrice = styled.span`
  padding: 0.1rem 0.25rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.6rem;
  text-align: center;
  line-height: 1rem;
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 78%);
  border-radius: 0.5rem;

  &[data-status='finish inactive'] {
    color: var(--color-gray-200);
  }

  &[data-status='waiting inactive'] {
    color: var(--color-gray-200);
  }

  &[data-status='roll active'] {
    color: var(--color-white);
  }

  &[data-status='roll inactive'] {
    color: var(--color-gray-200);
  }
`;

export const PriceContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 7px;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
`;
