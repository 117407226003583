import { RefillCountryDialog } from './refill-country-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.REFILL_COUNTRY, {
  component: RefillCountryDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
