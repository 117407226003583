import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useModal } from 'core/Modals/hooks';
import { promoModals } from '../../modal.config';

export const PromoContent: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleCloseModal } = useModal(promoModals.REFILL_PROMO);
  const handlePushToRefill = useCallback(() => {
    history.push('/refill');
    handleCloseModal();
  }, [handleCloseModal, history]);
  return (
    <Container>
      <div className="modal-title">{t('Top up your balance from 25$ and get bonuses')}</div>
      <div className="refill-bonuses">
        <div>
          <i className="icon-bonus-experience" />
        </div>
        <div>{t('Exp bonus')}</div>
        <div>
          <i className="icon-checked" />
        </div>
        <div>
          <i className="icon-bonus-wheel" />
        </div>
        <div>{t('Wheel Of Fortune free spin')}</div>
        <div>
          <i className="icon-checked" />
        </div>
        <div>
          <i className="icon-bonus-case" />
        </div>
        <div>{t('Free case')}</div>
        <div>
          <i className="icon-checked" />
        </div>
        <div>
          <i className="icon-bonus-lvl-up" />
        </div>
        <div>{t('Access to account level-up')}</div>
        <div>
          <i className="icon-checked" />
        </div>
        <div>
          <i className="icon-cashback" />
        </div>
        <div>{t('Cashback')}</div>
        <div>
          <i className="icon-checked" />
        </div>
      </div>
      <div className="modal-controls">
        <Button className="primary small" onClick={handlePushToRefill}>
          {t('Refill balance')}
        </Button>
        <Button className="link-light small" onClick={handleCloseModal}>
          {t('Close')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-gap: 1.5rem;
  background-color: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);

  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 4.375rem;
    background-repeat: no-repeat;
    background-position-x: center;
    background-image: url(//d2lomvz2jrw9ac.cloudfront.net/events-banners/promo/promo-cards.png);
    animation: slipping 700ms ease-in-out forwards;

    @keyframes slipping {
      from {
        background-position-y: -70px;
      }
      to {
        background-position-y: 70%;
      }
    }

    @media screen and (min-width: 375px) {
      height: 9.375rem;
    }
  }

  & .modal-title {
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: 0.54px;
    color: var(--color-white-600);
    padding: 0 15% 0 0.875rem;

    @media screen and (min-width: 375px) {
      padding: 0 15% 0 1.5rem;
    }
  }

  & .refill-bonuses {
    display: grid;
    grid-template-columns: 1.5rem 1fr 1.5rem;
    grid-auto-rows: 1.3rem;
    grid-gap: 0.5rem 0.40625rem;
    padding: 0 0.875rem;

    @media screen and (min-width: 375px) {
      padding: 0 1.5rem;
    }

    & div {
      font-size: 0.8125rem;
      letter-spacing: 0.265571px;
      color: var(--color-white);

      &:nth-child(3n + 3) {
        justify-self: center;
        align-self: center;
      }

      & i:not(.icon-checked) {
        color: var(--color-white);
      }

      & i {
        color: var(--yellow);
      }
    }
  }

  & .modal-controls {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: min-content;
    padding: 0 0.875rem 1.6875rem;

    @media screen and (min-width: 375px) {
      grid-template-columns: 1fr;
      grid-auto-rows: 2.25rem;
      grid-auto-flow: row;
      grid-gap: 1.5rem;
      padding: 0 1.5rem 1.6875rem;
    }

    & button,
    & a {
      width: auto;

      &.link-light {
        grid-row: 1;

        @media screen and (min-width: 375px) {
          grid-row: auto;
        }
      }
    }
  }
`;
