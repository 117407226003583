import styled from 'styled-components';

type TabProps = {
  active: boolean;
};

export const Nav = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  gap: 5px;
  justify-content: space-between;
  padding: 16px 16px 32px;
`;

export const TransactionTab = styled.div<TabProps>`
  align-items: center;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  color: var(--color-white);
  display: flex;
  flex-grow: 1;
  font-size: 10px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.3px;
  line-height: 14px;
  padding: 12px 10px 10px;
  text-transform: uppercase;

  ${p =>
    p.active &&
    `
    background: var(--color-white-gray);
  `};
`;
