import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  padding: 2.125rem 1rem 1.75rem;
  position: relative;

  & .close {
    font-weight: normal;
    position: absolute;
    right: 1rem;
    text-transform: none;
    top: 0.625rem;
  }

  & .dialog-title {
    color: var(--color-white);
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1rem;
  }

  & .inner-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 0.8125rem;
    padding: 1.5rem 0 2rem;

    & span {
      color: var(--color-gray-200);
      font-size: 0.75rem;
      letter-spacing: 0.1px;
      line-height: 1rem;
    }

    & a,
    & button {
      background: var(--color-provider-steam);
      color: var(--color-white);
      position: relative;
      transition: all var(--transition-ease-out);

      & i {
        color: inherit;
        font-size: 1.25rem;
        left: 1rem;
        position: absolute;
      }

      & span {
        color: inherit;
        font-size: 0.8125rem;
        font-weight: bold;
        letter-spacing: 0.286px;
        text-transform: uppercase;
      }

      &.active {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.2;
      }
    }
  }
`;
