import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  padding: 35px 0 24px;
  text-align: center;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 20px;
  padding-left: 20px;
  text-align: left;
`;

export const SoundsWrap = styled.div`
  border-bottom: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  display: flex;
  flex-flow: column;
  margin-top: 30px;
`;
