import { SettingUpSteamAccountDialog } from './setting-up-steam-account-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.USER_SETTING_UP_STEAM_ACCOUNT, {
  component: SettingUpSteamAccountDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
