import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, NavigationLink } from '@components/Common';
import { useDialog } from 'core/hooks';
import { useUser } from 'core/User';
import { IDialogComponent, LotteryDialogs, LotteryType } from 'core/interfaces';
import { ActingLottery } from '../@shared/acting-lottery';
import { FreeLotteryController } from './free-lottery-controller';

interface IDataDialog {
  type: LotteryType.FREE | LotteryType.DEPOSIT;
  pathname: string;
}

interface ISingleLotteryDialog extends IDialogComponent<IDataDialog> {}

export const SingleLotteryDialog: FC<ISingleLotteryDialog> = ({
  handleCloseModal,
  data: { type, pathname },
}) => {
  const { push } = useHistory();
  const { t } = useTranslation(['lottery', 'translations']);
  const { user } = useUser();
  const { handleOpenDialog } = useDialog(LotteryDialogs.RULES);

  const onOpenLotteryRules = useCallback(() => {
    handleCloseModal();
    setTimeout(() => {
      handleOpenDialog();
    }, 500);
  }, [handleOpenDialog, handleCloseModal]);

  const onOpenDeposit = useCallback(() => {
    handleCloseModal();
    setTimeout(() => {
      push('/refill');
    }, 1000);
  }, [push, handleCloseModal]);

  return (
    <Container>
      <div className="buffer-container" onClick={handleCloseModal} />
      <div className="dialog-wrapper">
        <Button className="link-light close" onClick={handleCloseModal}>
          {t('close')}
        </Button>
        <div className="lottery-header">
          <h2>{t('lottery-type', { context: type })}</h2>
          <p>
            {t('lottery-type-description', {
              context: type,
              amount: 500,
              postProcess: 'with-template',
            })}
          </p>
        </div>
        <div className="lottery-container">
          <ActingLottery type={type} />
        </div>
        <div className="navigation">
          <NavigationLink
            onClick={handleCloseModal}
            to={`${pathname}/lottery?type=${type}`}
            as={Link}
          >
            <div className="leading">
              <i className="icon-lottery-ticket-history" />
            </div>
            <div>
              <span>{t('translations:::/lottery')}</span>
            </div>
          </NavigationLink>
          <NavigationLink onClick={onOpenLotteryRules} as="button">
            <div className="leading">
              <i className="icon-faq" />
            </div>
            <div>
              <span>{t('Details')}</span>
            </div>
          </NavigationLink>
        </div>
        {user.id && (
          <div className="controller">
            {type === LotteryType.FREE && <FreeLotteryController />}
            {type === LotteryType.DEPOSIT && (
              <Button className="primary" onClick={onOpenDeposit}>
                {t('Refill balance')}
              </Button>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: auto !important;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & .buffer-container {
    display: block;
    width: 100%;
    height: max(calc(100vh - 400px));
  }

  & .dialog-wrapper {
    position: relative;
    background: var(--color-dark-600);
    border-top: 1px solid var(--color-gray-900);

    & .close {
      position: absolute;
      top: 0.625rem;
      right: 1rem;
      text-transform: none;
      font-weight: normal;
    }

    & .lottery-header {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.75rem;
      padding: 3rem 1.25rem 2.5rem;

      & h2,
      & p {
        color: var(--color-white);
      }

      & h2 {
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 1.625rem;
        letter-spacing: 0.54px;

        @media screen and (min-width: 375px) {
          font-size: 1.75rem;
          line-height: 2rem;
        }
      }

      & p {
        font-size: 0.75rem;
        line-height: 0.9375rem;
        letter-spacing: 0.1px;
      }
    }

    & .lottery-container {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 2.625rem;
      padding-bottom: 2.625rem;
    }

    & .navigation {
      border-top: 1px solid var(--color-gray-900);
      border-bottom: 1px solid var(--color-gray-900);

      & a,
      & button {
        width: 100%;
        border: 0;
      }
    }

    & .controller {
      padding: 1.75rem 1rem 1.5rem;
    }
  }
`;
