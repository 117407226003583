import styled from 'styled-components';

export const Container = styled.div<{
  gap: number;
  isTeam: boolean;
  isUpIcons: boolean;
  playersCount: number;
  isFinish: boolean;
}>`
  display: grid;
  gap: ${({ isTeam, gap }) => (isTeam ? '15' : `${gap}`)}px;
  ${({ playersCount, isTeam }) =>
    `grid-template-columns: repeat(${playersCount}, ${isTeam ? 160 : 80}px)`};

  @media screen and (max-width: 374px) {
    ${({ playersCount, isTeam }) =>
      `grid-template-columns: repeat(${playersCount}, ${isTeam ? 132 : 66}px)`};
  }

  padding: 20px 0
    ${({ isUpIcons, isFinish }) => (isFinish ? '144px' : isUpIcons ? '120px' : '20px')};
  transition: padding 300ms ease;
  justify-content: center;
`;

export const TeamsContainer = styled.div<{ playersCount: number }>`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(${({ playersCount }) => playersCount}, 80px);

  @media screen and (max-width: 374px) {
    grid-template-columns: repeat(${({ playersCount }) => playersCount}, 66px);
  }

  height: 100%;
  justify-content: space-around;
  width: 100%;
`;

export const UserRoundsContainer = styled.div<{ rounds: number }>`
  align-items: stretch;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(1, 80px);

  @media screen and (max-width: 374px) {
    grid-template-columns: repeat(1, 66px);
  }

  grid-template-rows: repeat(${({ rounds }) => rounds}, minmax(0, 1fr));
  height: 100%;
  justify-items: center;
  width: 100%;
`;
