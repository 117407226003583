import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

export class UserSettingRepository {
  static fetchSteamSettings(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/me/settings`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchUserAvatar({
    signedRequest,
    imageToken,
  }: {
    signedRequest: string;
    imageToken: Blob;
  }) {
    return fetch(signedRequest, {
      method: 'PUT',
      mode: 'cors',
      body: imageToken,
    });
  }
}
