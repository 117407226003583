import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CoinContainer,
  CaseIcon,
  CaseIconFree,
  CasePrice,
  ItemContainer,
} from './case-item.styles';
import { getSteamItemImageSrc } from 'core/utils';

import { Modules } from 'core/interfaces';
import { TicketTypeEnum } from 'modules/event-module/comics-event/interfaces';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

interface CaseItemProps {
  price: number;
  isFree: boolean;
  fruitType: TicketTypeEnum;
  id: number;
  backgroundImage?: string;
  image?: string;
}

const coinTypeObject = {
  [TicketTypeEnum.DAILY]: (
    <img src={eventImagesPath.bronzeDailyPath} width={16} height={16} alt="" />
  ),
  [TicketTypeEnum.SPECIAL]: (
    <img src={eventImagesPath.goldSpecialPath} width={16} height={16} alt="" />
  ),
  [TicketTypeEnum.WEEKLY]: (
    <img src={eventImagesPath.silverWeeklyPath} width={16} height={16} alt="" />
  ),
};

export const CaseItem: React.FC<CaseItemProps> = ({
  price,
  isFree,
  image,
  backgroundImage,
  fruitType,
  id,
}) => {
  const history = useHistory();

  const redirectHandler = () => {
    history.push(`${Modules.CASES}/${id}?ticketType=${fruitType}`);
  };

  return (
    <ItemContainer onClick={redirectHandler}>
      <CoinContainer>
        {coinTypeObject[fruitType]}
        <CasePrice>{price}</CasePrice>
      </CoinContainer>
      {isFree ? (
        <CaseIconFree src={image ?? backgroundImage} />
      ) : (
        <CaseIcon src={getSteamItemImageSrc(backgroundImage ?? '')} />
      )}
    </ItemContainer>
  );
};
