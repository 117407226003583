import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-150);
`;

export const ColumnHeader = styled.div`
  display: flex;
  padding: 11px 19px;
  background: var(--color-dark-100);
`;

export const ColumnSpan = styled.span`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-white-gray);
  display: flex;
  flex: 1;

  &:first-child {
    flex: 2;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;
