import { OneToThree } from './one-to-three';
import { FourOrFive } from './four-or-five';
import { SixToSeven } from './six-to-seven';
import { EightToNine } from './eight-to-nine';

export const COMBO_LINES = new Map([
  [1, OneToThree],
  [2, OneToThree],
  [3, OneToThree],
  [4, FourOrFive],
  [5, FourOrFive],
  [6, SixToSeven],
  [7, SixToSeven],
  [8, EightToNine],
  [9, EightToNine],
]);

export const COMBO_ENTRIES = Array.from(COMBO_LINES.entries());
