import React, { FC, memo, useEffect, useState } from 'react';
import { RouletteContainer } from './RouletteLineContainer.styles';
import { useGetRouletteAnimationStatuses } from 'games/CaseBattle/hooks';
import { useDispatch } from 'react-redux';
import { actionAnimationStatusAfterRoll } from 'games/CaseBattle/ducks/roulette-duck/roulette-duck';
import { isEqual } from 'lodash';
import { RouletteStatuses } from 'games/CaseBattle/constants';
import { RouletteItem } from '../RouletteItem';
import { RouletteBaseItem } from 'games/CaseBattle/utils';

interface RouletteLinesContainerProps {
  rouletteLineItems: RouletteBaseItem[];
  playerId: number;
  isJoker: boolean;
}

export const RouletteLinesContainer: FC<RouletteLinesContainerProps> = memo(
  ({ rouletteLineItems, playerId, isJoker }) => {
    const [items, setItems] = useState<Partial<RouletteBaseItem>[] | null>(null);
    const { linesStatus } = useGetRouletteAnimationStatuses();
    const dispatch = useDispatch();

    useEffect(() => {
      setItems(null);
    }, [rouletteLineItems]);

    const onAnimationEnd = () => {
      setItems([...rouletteLineItems.slice(8, 11)]);
      dispatch(actionAnimationStatusAfterRoll());
    };

    return (
      <RouletteContainer
        playerId={playerId}
        className={linesStatus === RouletteStatuses.roll && 'anima'}
        onAnimationEnd={onAnimationEnd}
      >
        {(items ?? rouletteLineItems)?.map(({ icon, color, isProfit }, index) => (
          <RouletteItem
            icon={icon}
            color={color}
            key={index}
            isJoker={isJoker}
            isDroppedItem={index === 1 && linesStatus === RouletteStatuses.afterRoll && isProfit}
          />
        ))}
      </RouletteContainer>
    );
  },
  isEqual
);
