import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useLotteryIdentifiers, useLotteryStatus } from 'core/hooks';
import { LotteryStatuses, LotteryType } from 'core/interfaces';
import { LotteryListElement } from './lottery-list-element';
import { EmptyLayout } from '@UIkit';

const empty = Array.from({ length: 10 }, (_, i) => i);

export const LotteryList = () => {
  const local = useLocation();
  const { status } = useLotteryStatus();
  const { identifiers } = useLotteryIdentifiers(
    (new URLSearchParams(local.search).get('type') as LotteryType) ?? LotteryType.FREE
  );

  if ((status === LotteryStatuses.LOADED && identifiers?.length < 2) || !identifiers) {
    return <EmptyLayout namespace="lottery" ctx="" />;
  }

  if (status === LotteryStatuses.LOADING) {
    return (
      <Container>
        {empty.map(identifier => (
          <LotteryListElement key={identifier} identifier={identifier} />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      {identifiers.map(identifier => (
        <LotteryListElement key={identifier} identifier={identifier} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.125rem;
`;
