import React, { FC, memo, useRef, useEffect } from 'react';
import { isEqual } from 'lodash';
import {
  ItemPrice,
  Container,
  ItemShortName,
  ItemSkin,
  Exterior,
  Image,
  Price,
  Item,
} from './PlayerCaseItem.styles';
import { getItemUri } from 'core/utils';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useGetCaseBattleGameData, useGetCasesByIds } from 'games/CaseBattle/hooks';
import { currencyService } from 'services/currency';

const findSpecialType = (text: string, type: string) => {
  return text
    .split(' ')
    .filter(word => word.includes(type))
    .map(word => `${word}\n`)
    .join('');
};

interface PlayerCaseItemProps {
  caseId: number;
  color: string;
  exterior: string;
  image: string;
  isMaxExpensiveInRound: boolean;
  maxPrice: number;
  name: string;
  price: number;
  shortName: string;
  skin: string;
  setCardHeight: (h: number) => void;
}

export const PlayerCaseItem: FC<PlayerCaseItemProps> = memo(
  ({
    caseId,
    color,
    exterior,
    image,
    isMaxExpensiveInRound,
    maxPrice,
    name,
    price,
    shortName,
    skin,
    setCardHeight,
  }) => {
    const containerRef = useRef<HTMLDivElement>();
    const { caseData } = useGetCasesByIds(caseId);
    const { data } = useGetCaseBattleGameData();
    const statTrak = findSpecialType(name, 'StatTrak™');
    const souvenir = findSpecialType(name, 'Souvenir');
    const specialType = statTrak || souvenir || '';

    useEffect(() => {
      const updateCardHeight = () => {
        if (containerRef.current) {
          setCardHeight(containerRef.current.offsetHeight);
        }
      };

      updateCardHeight();

      window.addEventListener('resize', updateCardHeight);
      return () => {
        window.removeEventListener('resize', updateCardHeight);
      };
    }, [setCardHeight]);

    return (
      <Container color={color} ref={containerRef}>
        <Item isJoker={data.game.settings.jokerMode}>
          {maxPrice === price && maxPrice > caseData?.lastRevision.price && (
            <CaseBattleIcon.Clover className="clover" />
          )}
          {isMaxExpensiveInRound && (
            <>
              <CaseBattleIcon.ArrowPrice className="arrow_top" />
              <CaseBattleIcon.ArrowPrice className="arrow_bottom" />
            </>
          )}
          {!!exterior && <Exterior>{exterior}</Exterior>}
          <Image src={getItemUri(image)} alt={skin} />
          <ItemShortName
            className={`${statTrak ? 'hasStatTrak' : ''} ${souvenir ? 'hasSouvenir' : ''}`}
          >{`${specialType}${shortName}`}</ItemShortName>
          <ItemSkin>{skin}</ItemSkin>
        </Item>
        <Price>
          <ItemPrice>
            ◎ <span>{currencyService.display(price)?.substring(3)}</span>
          </ItemPrice>
        </Price>
      </Container>
    );
  },
  isEqual
);
