// const validateCard = cardNumber => {
//   const string = cardNumber.toString()
//   if (string.length < 6) return false

//   const arr = []
//   for (let i = 0; i < string.length; i++) {
//     if (i % 2 === 0) {
//       const m = parseInt(string[i] * 2)
//       if (m > 9) {
//         arr.push(m - 9)
//       } else {
//         arr.push(m)
//       }
//     } else {
//       const n = parseInt(string[i])
//       arr.push(n)
//     }
//   }
//   const sum = arr.reduce((sum, val) => sum + val, 0)
//   return sum % 10 === 0
// }

function accountNumberRedundancy(N) {
  function elongation(N, size) {
    while (N.length < size) N = `0${N}`;
    return N;
  }

  if (N.length < 3 || N.length > 33) return false;
  const B = N.substring(1, Number(N.charAt(0)) + 1);
  const C = N.substring(1 + B.length, N.length - 2);
  const BC = elongation(B, 10) + elongation(C, 20);
  let Sum = 0;
  for (let i = 0; i < BC.length; ++i) {
    let K = Number(BC.charAt(i));
    if (K === 0) K = 10;
    Sum = (Sum * 13 + K) % 99;
  }
  return ((Sum * 13 * 13) % 99) + 1 === Number(N.substring(N.length - 2));
}

export function yandexPurseValidation(N) {
  return N.substring(0, 5) === '41001' && N.length > 10 && accountNumberRedundancy(N);
}

// eslint-disable-next-line
const emailRegExp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

export const phoneRegExp = new RegExp(/^[7][(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im);

export const isEmailAddress = wallet => emailRegExp.test(wallet);
