import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import BezierEasing from 'bezier-easing';
import SoundService from 'services/sound.service';
import { GameRouletteReel } from './game-roulette-reel';
import { GameRoulettePointer } from './game-roulette-pointer';
import { useGameRoundNumbers, useGameUntilTime, useHighlightGameSectors } from '../../../hooks';
import {
  GameCondition,
  getSectorColorByRand,
  ROULETTE_DEGREES,
  SectorColors,
} from '../../../utils';

const sound = new SoundService();
const easing = BezierEasing(0.32, 0.95, 0.45, 1);

export const GameRoulette = () => {
  const sectors = useHighlightGameSectors();
  const [current, previous] = useGameRoundNumbers();
  const [condition, setCondition] = useState(GameCondition.PENDING);
  const untilTime = useGameUntilTime();

  const classes = useMemo(() => {
    return (Object.keys(sectors) as SectorColors[])
      .map(v => (sectors[v] ? `highlight-${v}` : ''))
      .join(' ');
  }, [sectors]);

  const [styles, set] = useSpring(() => ({
    transform: 'rotate(0deg)',
  }));

  useEffect(() => {
    return () => sound.stop({ close: true });
  }, []);

  useLayoutEffect(() => {
    if (current !== null) {
      const duration = Math.max(untilTime + 15000 - Date.now(), 0);
      const rotationCount = Math.trunc((duration / 15000) * 10);
      setCondition(() => GameCondition.RAFFLING);

      sound.play('Double', 'Roulette start');
      sound.play('double', 'roulette', { from: 19 - duration / 1000 });

      set({
        from: { transform: `rotate(${ROULETTE_DEGREES[previous]}deg)` },
        transform: `rotate(${rotationCount * 360 + ROULETTE_DEGREES[current]}deg)`,
        config: { duration, easing },
        immediate: false,
        onStart: null,
        onRest: () => {
          setCondition(() => GameCondition.RESULT);
          sound.play('Double', 'Game ending');
          sound.play('Double', 'Coins transfer');
        },
      });
    } else {
      set({
        transform: `rotate(${ROULETTE_DEGREES[previous]}deg)`,
        immediate: true,
        onStart: () => setCondition(() => GameCondition.PENDING),
        onRest: null,
      });
    }
  }, [current, previous, set, setCondition, untilTime]);

  const winnerSector = current ? getSectorColorByRand(current) : null;

  return (
    <>
      <Container className={`${condition} next-sector-${winnerSector} ${classes}`}>
        <animated.div style={styles}>
          <GameRouletteReel />
        </animated.div>
        <div>
          <GameRoulettePointer condition={condition} rand={current} />
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.75rem;
  transform: translate3d(0, -27%, 0);

  &.PENDING,
  &.RAFFLING,
  &.RESULT {
    &.highlight-zero .roulette-gradient-reel.sector-0,
    &.highlight-red .roulette-gradient-reel.sector-1,
    &.highlight-red .roulette-gradient-reel.sector-2,
    &.highlight-red .roulette-gradient-reel.sector-3,
    &.highlight-red .roulette-gradient-reel.sector-4,
    &.highlight-red .roulette-gradient-reel.sector-5,
    &.highlight-red .roulette-gradient-reel.sector-6,
    &.highlight-red .roulette-gradient-reel.sector-7,
    &.highlight-black .roulette-gradient-reel.sector-8,
    &.highlight-black .roulette-gradient-reel.sector-9,
    &.highlight-black .roulette-gradient-reel.sector-10,
    &.highlight-black .roulette-gradient-reel.sector-11,
    &.highlight-black .roulette-gradient-reel.sector-12,
    &.highlight-black .roulette-gradient-reel.sector-13,
    &.highlight-black .roulette-gradient-reel.sector-14,
    &.highlight-black .roulette-gradient-reel.sector-15 {
      opacity: 1;
    }

    &.highlight-zero .sector-0:not(.roulette-gradient-reel) path {
      fill: url(#gradient-0);
    }

    &.highlight-red .sector-1:not(.roulette-gradient-reel) path,
    &.highlight-red .sector-2:not(.roulette-gradient-reel) path,
    &.highlight-red .sector-3:not(.roulette-gradient-reel) path,
    &.highlight-red .sector-4:not(.roulette-gradient-reel) path,
    &.highlight-red .sector-5:not(.roulette-gradient-reel) path,
    &.highlight-red .sector-6:not(.roulette-gradient-reel) path,
    &.highlight-red .sector-7:not(.roulette-gradient-reel) path {
      fill: url(#gradient-1);
    }

    &.highlight-black .sector-8:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-9:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-10:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-11:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-12:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-13:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-14:not(.roulette-gradient-reel) path,
    &.highlight-black .sector-15:not(.roulette-gradient-reel) path {
      fill: url(#gradient-8);
    }
  }

  &.RESULT {
    &.next-sector-green .roulette-gradient-reel.sector-0,
    &.next-sector-red .roulette-gradient-reel.sector-1,
    &.next-sector-red .roulette-gradient-reel.sector-2,
    &.next-sector-red .roulette-gradient-reel.sector-3,
    &.next-sector-red .roulette-gradient-reel.sector-4,
    &.next-sector-red .roulette-gradient-reel.sector-5,
    &.next-sector-red .roulette-gradient-reel.sector-6,
    &.next-sector-red .roulette-gradient-reel.sector-7,
    &.next-sector-black .roulette-gradient-reel.sector-8,
    &.next-sector-black .roulette-gradient-reel.sector-9,
    &.next-sector-black .roulette-gradient-reel.sector-10,
    &.next-sector-black .roulette-gradient-reel.sector-11,
    &.next-sector-black .roulette-gradient-reel.sector-12,
    &.next-sector-black .roulette-gradient-reel.sector-13,
    &.next-sector-black .roulette-gradient-reel.sector-14,
    &.next-sector-black .roulette-gradient-reel.sector-15 {
      animation: double-pulse-sector-animation 1s infinite;
    }
  }

  & > div {
    position: absolute;
    width: 0;
    height: 0;

    & .roulette-gradient-reel {
      min-width: var(--roulette-gradient-reel-size);
      min-height: var(--roulette-gradient-reel-size);
      opacity: 0;
      transition: all 300ms linear;
    }

    & svg {
      min-width: var(--roulette-reel-size);
      min-height: var(--roulette-reel-size);
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  @keyframes double-pulse-sector-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
