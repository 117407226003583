import { IGameModules } from 'core/rootInterfaces/root.interface';
import { classicModule as classic } from './ClassicGame';
import { fastModule as fast } from './Fast';
import { towerModule as tower } from './TowerGame';
import { fortuneWheelModule as wheel } from './FortuneWheel';
import { x50Module as x50 } from './x50';
import { crashModule as crash } from './CrashGame';
import { doubleModule as double } from './double';
import { Module as baccarat } from './Baccarat';
import { caseModule as cases } from './CaseGame';
import { slotModule as slot } from './slots';
// import { craftModule as craft } from './CraftGame'
import { PoggiModule as poggi } from './Poggi';
import { hiloNewModule as hilo } from './Hilo';
import { caseBattle } from './CaseBattle';
const games: IGameModules = {
  cases,
  classic,
  caseBattle,
  fast,
  hilo,
  crash,
  double,
  x50,
  wheel,
  tower,
  baccarat,
  slot,
  // craft,
  poggi,
};

export default games;

export const availableGameModules = Object.keys(games);
