import { getCaseBattleList } from '../ducks';
import { useSelector } from 'react-redux';

export const useGetCaseBattleMPHistory = () => {
  const { dataHistory, isLoadingHistory } = useSelector(getCaseBattleList);
  return {
    idsOrderHistory: dataHistory.idOrder,
    historyGames: dataHistory.games,
    isLoadingHistory,
  };
};
