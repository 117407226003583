import React, { FC } from 'react';
import styled from 'styled-components';
import { BoardTypes, factors } from '../../../../interfaces';
import { CurrencyText } from '@components/currency';
import { useTranslation } from 'react-i18next';

interface IFactorControl {
  type: BoardTypes;
  currentAmount: number;
  amount?: number;
}

export const FactorControl: FC<IFactorControl> = ({ type, currentAmount, amount }) => {
  const { t } = useTranslation('baccarat');

  return (
    <Container className={type}>
      <span className="amount">
        {!!amount && (
          <span>
            <CurrencyText value={amount} />
          </span>
        )}
        {!!currentAmount && (
          <span>
            {!!amount && '+ '}
            <CurrencyText value={currentAmount} />
          </span>
        )}
      </span>
      <span className="factor">
        <span>{t(type)}</span>
        <span>x{factors[type]}</span>
      </span>
    </Container>
  );
};

const Container = styled.div`
  &.P,
  &.PP {
    --color-fill: var(--bacarrat-player-fill);
    --color-stroke: var(--bacarrat-player-stroke);
  }

  &.B,
  &.BP {
    --color-fill: var(--bacarrat-banker-fill);
    --color-stroke: var(--bacarrat-banker-stroke);
  }

  &.T {
    --color-fill: var(--bacarrat-tie-fill);
    --color-stroke: var(--bacarrat-tie-stroke);
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.375rem 0.75rem;
  border-radius: var(--size-radius-m);
  transition: all 300ms var(--transition-ease-out);
  background: var(--color-fill);
  border: 0.125rem solid var(--color-stroke);

  & > span {
    font-weight: bold;
    letter-spacing: 0.084px;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--color-white);

    & > span:not(:first-of-type) {
      margin-left: 0.25rem;
    }
  }

  & .amount {
    & span {
      font-weight: inherit;
      letter-spacing: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  & .factor {
    font-size: 1rem;
    line-height: 1.125rem;

    & span {
      font-weight: bold;
      font-size: inherit;
      line-height: inherit;

      &:last-of-type {
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }
`;
