import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { withTemplate } from './post-processor';

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .use(withTemplate)
  .init({
    fallbackLng: ['en'],
    debug: false,
    lng: 'en',

    ns: ['translations'],
    defaultNS: 'translations',
    returnNull: true,
    keySeparator: '.',
    nsSeparator: ':::',

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      transSupportBasicHtmlNodes: true,
      useSuspense: false,
    },
  });

const addResources = (translations: any) => {
  for (const language in translations) {
    i18n.addResourceBundle(language, 'translations', translations[language]);
  }
};

export { i18n, addResources };
