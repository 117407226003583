import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { IMember } from '../../../../../interfaces';
import { useLoadAccountProfile } from 'modules/Accounts';

export const MembersListItem: FC<IMember> = ({ ava_link, amount, name, user_id }) => {
  const { handleOpenProfile } = useLoadAccountProfile();

  return (
    <Container className="" style={{ order: -amount }}>
      <div className="thumbnail">
        <img src={ava_link} alt="" onClick={handleOpenProfile(user_id)} />
      </div>
      <div className="user-info">
        <span>{name}</span>
        <span>
          <CurrencyText value={amount} />
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row: auto;
  grid-gap: 0.125rem;
  background: var(--color-dark-200);
  padding: 0.25rem;
  height: 100%;

  @media screen and (min-width: 375px) {
    grid-gap: 0.25rem;
  }

  & .thumbnail {
    width: 1.875rem;
    height: 1.875rem;
    align-self: center;
    justify-self: center;

    & img {
      width: 1.875rem;
      height: 1.875rem;
      object-fit: contain;
      overflow: hidden;
      border-radius: 50%;
      pointer-events: auto;
    }
  }

  & .user-info {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.125rem;
    align-content: center;

    & span {
      width: 100%;
      height: 0.5rem;
      font-size: 0.5rem;
      line-height: 0.75rem;
      letter-spacing: 0.1px;
      text-align: center;
      color: var(--color-gray-200);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:last-of-type {
        color: var(--color-white);
      }

      @media screen and (min-width: 375px) {
        font-size: 0.75rem;
        line-height: 0.875rem;
        height: 0.875rem;
      }
    }
  }
`;
