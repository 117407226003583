import React from 'react';
import {
  BonusReward,
  Container,
  DollarPrice,
  FastCoinPriceBeforeBonus,
  Wrapper,
  ResultFillCount,
  ResultRefill,
  FastCoinContainer,
  Span,
} from './refill-with-bonus.styles';
import { ReferralCampaignTypes } from 'core/User/interfaces';
import { RefillIcons } from 'modules/Refill/assets';
import { currencyService } from 'services/currency';
import { useTranslation } from 'react-i18next';
import { getAmountWithBonus } from 'modules/Refill/refill.util';

interface RefillWithBonusProps {
  reward: number;
  type: ReferralCampaignTypes;
  rewarded: boolean;
  currentPrice: number;
  bonus?: number;
}

export const RefillWithBonus: React.FC<RefillWithBonusProps> = ({
  reward,
  rewarded,
  type,
  currentPrice = 0,
  bonus,
}) => {
  const { t } = useTranslation();
  const rate = currencyService.getRate('fastcoins_refill');
  const refillCountDollar = Number(
    currencyService.convertBySelectedRateKey(currentPrice / rate ?? 0, 'usd')
  );

  const finalRefillCountFastCoin =
    getAmountWithBonus(currentPrice * 100, reward, type, rewarded) / 100;

  const finalRefillCountDollar = Number(
    currencyService.convertBySelectedRateKey(finalRefillCountFastCoin / rate ?? 0, 'usd')
  );

  const fastCoinCountWithAllBonuses = finalRefillCountFastCoin + (currentPrice * bonus) / 100;
  const dollarCountWithAllBonuses = finalRefillCountDollar + (refillCountDollar * bonus) / 100;

  return (
    <Container>
      <Wrapper>
        <FastCoinPriceBeforeBonus>
          <RefillIcons.FastCoin width={16} height={16} />
          {currentPrice.toFixed(2)}
        </FastCoinPriceBeforeBonus>
        <DollarPrice>${refillCountDollar.toFixed(2)}</DollarPrice>
      </Wrapper>
      <Wrapper>
        <Span>{t('Promo')}</Span>
        {type === ReferralCampaignTypes.MONEY_NEW || type === ReferralCampaignTypes.MONEY_OLD ? (
          <BonusReward>
            {reward / 100} <RefillIcons.FastCoinGreen width={16} height={16} />
          </BonusReward>
        ) : (
          <BonusReward>
            {!rewarded
              ? `${type === ReferralCampaignTypes.PROMO_PERCENT ? '+' : ''}${reward}%`
              : '-'}
          </BonusReward>
        )}
      </Wrapper>
      <Wrapper>
        <Span>{t('Bonus')}</Span>
        <BonusReward>{!!bonus ? `+${bonus}%` : '-'}</BonusReward>
      </Wrapper>
      <ResultRefill>
        <Span>{t('receive')}</Span>
        <ResultFillCount>
          <FastCoinContainer>
            <RefillIcons.FastCoinWhite color="white" width={16} height={16} />
            {fastCoinCountWithAllBonuses.toFixed(2)}
          </FastCoinContainer>
          (${dollarCountWithAllBonuses.toFixed(2)})
        </ResultFillCount>
      </ResultRefill>
    </Container>
  );
};
