import { ToolBarActionType } from '@components/bars';
import { ToolBarActions, ToolBarReducer } from './tool-bar.interfaces';

export const toolBarHide = () => ({
  type: ToolBarActionType.ACTION_HIDE,
});

export const toolBarShow = () => ({
  type: ToolBarActionType.ACTION_SHOW,
});

export const toolBarChangeState = () => ({
  type: ToolBarActionType.ACTION_TOGGLE,
});
export const toolBarReducer = (state: ToolBarReducer = false, action: ToolBarActions) => {
  switch (action.type) {
    case ToolBarActionType.ACTION_HIDE: {
      return false;
    }
    case ToolBarActionType.ACTION_SHOW: {
      return true;
    }
    case ToolBarActionType.ACTION_TOGGLE: {
      return !state;
    }
    default: {
      return state;
    }
  }
};
