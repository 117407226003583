import * as React from 'react';
import { getDrawerOpenStatus } from 'core/AppShell/selectors';
import { shallowEqual, useSelector } from 'react-redux';
import { AnimatedContent } from './AnimatedContentLayout.styles';

interface IAnimatedContentLayout {
  children: React.ReactNode;
}

export const AnimatedContentLayout: React.FC<IAnimatedContentLayout> = React.memo(
  ({ children }) => {
    const active = useSelector(getDrawerOpenStatus, shallowEqual);
    return <AnimatedContent isOpen={active}>{children}</AnimatedContent>;
  }
);
