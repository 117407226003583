import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AppBar } from '@components/bars';
import { useReferralCampaign } from '../../../hooks';
import { ReferralCampaignMetadata } from './referral-campaign-metadata';
import { ReferralNavigator } from '../@shared';
import { ReferralCampaignReports } from './referral-campaign-reports';
import { ReferralCampaignRating } from './referral-campaign-rating';

interface RouteParams {
  id: string;
}

export const ReferralCampaignPage: FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { campaign } = useReferralCampaign(match.params.id);
  const { t } = useTranslation();

  return (
    <>
      <AppBar deep>
        <span>{t(campaign?.name || '/personal/referral')}</span>
      </AppBar>
      <div className="content dark-layout">
        {campaign && (
          <>
            <ReferralCampaignMetadata campaign={campaign} />
            <ReferralCampaignReports id={campaign.id} />
            <ReferralCampaignRating campaign={campaign} />
            <ReferralNavigator />
          </>
        )}
      </div>
    </>
  );
};
