import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcons } from 'modules/PersonalArea/components/Settings/Sounds/assets';
import { IGameOptionsKeys, IGameSoundsOptions, useSoundSettings } from 'core/AppShell';
import {
  CheckBoxTitle,
  SoundItem,
  SoundIconsContainer,
  CheckBox,
  AnimationBlock,
} from './sound-option-dialog.styles';
import SoundService from 'services/sound.service';

const sound = new SoundService();

interface ISoundOptionDialog {
  game: keyof IGameSoundsOptions;
  option: string;
  value: boolean;
  selected: string;
  setSelected: (option: string) => void;
}

export const SoundOptionDialog: FC<ISoundOptionDialog> = ({
  game,
  option,
  value,
  selected,
  setSelected,
}) => {
  const { t } = useTranslation();
  const { handleToggleSound } = useSoundSettings();
  const [isPlayed, setIsPlayed] = useState(false);

  useEffect(() => {
    if (selected !== option) {
      setIsPlayed(false);
      sound.stop({ name: option });
    }
  }, [game, option, selected]);

  useEffect(() => {
    return () => sound.stop({ close: true });
  }, []);

  const playHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setSelected(option);
    setIsPlayed(true);
    sound.stop({ close: true });
    sound.play(game, option, { isSettings: true });
  };

  const pauseHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setIsPlayed(false);
    sound.stop({ close: true });
  };

  const animationEndHandler = () => {
    setIsPlayed(false);
    sound.stop({ name: option });
  };

  return (
    <SoundItem>
      <AnimationBlock
        onAnimationEnd={animationEndHandler}
        isPlayed={isPlayed}
        duration={sound.getDuration()}
      />
      <CheckBox
        id={option}
        checked={value}
        onChange={handleToggleSound({
          moduleName: 'gameSounds',
          game,
          option: option as IGameOptionsKeys,
        })}
        name={option}
        type="checkbox"
      />
      <label htmlFor={option} />
      <CheckBoxTitle>{t(option)}</CheckBoxTitle>
      <SoundIconsContainer>
        {!isPlayed ? (
          <SvgIcons.PlayIcon onClick={playHandler} />
        ) : (
          <SvgIcons.PauseIcon onClick={pauseHandler} />
        )}
      </SoundIconsContainer>
    </SoundItem>
  );
};
