import { IFastInventoryElement } from 'core/User';

export enum ClearanceActionTypes {
  ADD_TO_CLEARANCE_OUT = 'app/stock/sale-out/ADD_TO_CLEARANCE_OUT',
  DELETED_FROM_CLEARANCE_OUT = 'app/stock/sale-out/DELETED_FROM_CLEARANCE_OUT',
  RESET_ALL_CLEARANCE_OUT = 'app/stock/sale-out/RESET_ALL_CLEARANCE_OUT',

  FETCH_CLEARANCE_OUT = 'app/stock/sale-out/FETCH_CLEARANCE_OUT',

  SELL_OUT = 'app/stock/sale-out/SELL_OUT',
}

export interface IAddToClearanceOut {
  type: typeof ClearanceActionTypes.ADD_TO_CLEARANCE_OUT;
  payload: {
    item: IFastInventoryElement;
  };
}

export interface IDeletedFromClearanceOut {
  type: typeof ClearanceActionTypes.DELETED_FROM_CLEARANCE_OUT;
  payload: {
    item: IFastInventoryElement;
  };
}

export interface IResetAllClearanceOut {
  type: typeof ClearanceActionTypes.RESET_ALL_CLEARANCE_OUT;
}

export type clearanceActions =
  | IAddToClearanceOut
  | IDeletedFromClearanceOut
  | IResetAllClearanceOut;
