import { UserSettings } from './userSettings.reducer.interface';

const prefix = 'app/user/';

export const userSettingActionTypes = {
  ACTION_REQUEST_API_KEY: `${prefix}ACTION_REQUEST_API_KEY`,
  ACTION_RESPONSE_USER_SETTINGS: `${prefix}ACTION_RESPONSE_API_KEY`,
  ACTION_LOADING: `${prefix}ACTION_LOADING`,
};

interface ActionResponseApiKey {
  type: typeof userSettingActionTypes.ACTION_RESPONSE_USER_SETTINGS;
  payload: UserSettings;
}

export type userSettingsActions = ActionResponseApiKey;
