import { IHistoryGame, IPlayingBet } from './reducer.hilo.interface';

export enum hiloActionsTypes {
  // INITIALIZING
  HILO_ATTACH_LISTENERS = 'app/hilo/HILO_ATTACH_LISTENERS',
  HILO_DETACH_LISTENERS = 'app/hilo/HILO_ATTACH_LISTENERS',
  // CLEANS
  // CLEAN_UP_FULL_STATE = 'app/hilo/CLEAN_UP_FULL_STATE',
  // GAME
  UPDATE_GAME_STATE = 'app/hilo/UPDATE_GAME_STATE',
  CLEAN_GAME_TYPES = 'app/hilo/CLEAN_GAME_TYPES',
  // HISTORY
  UPDATE_HISTORY_STATE = 'app/hilo/UPDATE_HISTORY_STATE',
  // PLAYES
  INITIALIZING_PLAYES = 'app/hilo/INITIALIZING_PLAYES',
  UPDATE_PLAYES = 'app/hilo/UPDATE_PLAYES',
  // BETS
  FETCH_CREATE_PARTICIPATE = 'app/hilo/FETCH_CREATE_PARTICIPATE',
}

// CLEANS

export interface ICleanUp {
  type: typeof hiloActionsTypes.HILO_DETACH_LISTENERS;
}

// GAME

export interface IUpdateGame {
  type: typeof hiloActionsTypes.UPDATE_GAME_STATE;
  payload: {
    game: any;
  };
}

export interface ICleanGameTypes {
  type: typeof hiloActionsTypes.CLEAN_GAME_TYPES;
}

export type hiloGameActions = IUpdateGame | ICleanGameTypes | ICleanUp;

// HISTORY

export interface IUpdateHiatory {
  type: typeof hiloActionsTypes.UPDATE_HISTORY_STATE;
  payload: {
    history: IHistoryGame[];
  };
}

export type hiloHistoryActions = IUpdateHiatory | IUpdateGame | ICleanUp;

// PLAYES

export interface IInitializingPlayes {
  type: typeof hiloActionsTypes.INITIALIZING_PLAYES;
  payload: {
    players: IPlayingBet[];
  };
}

export interface IUpdatePlayer {
  type: typeof hiloActionsTypes.UPDATE_PLAYES;
  payload: {
    player: IPlayingBet;
  };
}

export type hiloPlayersActions = IInitializingPlayes | IUpdatePlayer | IUpdateGame | ICleanUp;
