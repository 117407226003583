import * as React from 'react';
import styles from 'styled-components';
import { MiniPreloader } from '../../Preloader';
import { useTranslation } from 'react-i18next';

export const LoadingHistory = () => (
  <FullPage>
    <MiniPreloader />
  </FullPage>
);

export const EmptyHistory = () => {
  const { t } = useTranslation();
  return <FullPage>{t('History is empty')}</FullPage>;
};

const FullPage = styles.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  font-size: 1rem;
  color: var(--color-white);
`;
