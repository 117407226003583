/* eslint-disable no-template-curly-in-string */
export default {
  'amount on type': '${currency(amount)} на $t({{type}})',
  'Stats for': 'Статистика за',
  'Make your bet': 'Сделай ставку',

  'In this mode, the card is opened repeatedly. You bet on the appearance of the next card. You can make bets on the following parameters:':
    'В этом режиме открывается карта за картой. Вы делаете ставки на появление следующей карты. Ставки могут быть поставлены на следующие параметры:',
  'Next card will be higher or lower by seniority.':
    'Следующая карта будет выше или ниже по старшинству.',
  'In this category, all bets are summed up and coefficients are calculated simultaneously. Winners will get the sum of their bet multiplied by the result coefficient. You can bet on one of the options. When the joker falls out, next card will be compared to the one that fell before the joker.':
    'В этой категории суммируются все ставки и рассчитываются коэффициенты. Выигравшие получат сумму своей ставки, умноженную на получившийся коэффициент. Поставить можно на один из вариантов. При выпадении джокера следующая карта будет сравниваться с той, которая выпала перед джокером.',
  'Color of the next card.': 'Цвет следующей карты.',
  'In this category, if you win, your bet doubles.':
    'В этой категории при выигрыше ваша ставка удваивается. Поставить можно на один из вариантов.',
  'The precedence of the next card.': 'Старшинство следующей карты.',
  "In this category, if you win, your bet will increase by a multiplier of the card's seniority (from 2 to 9 – x1.5, from jack to ace – x3, from king to ace – x6b only ace – x12.) If you guessed the appearance of a joker, your bet will multiply by 24 ). You can put on each of the 5 options.":
    'В этой категории в случае выигрыша ваша ставка увеличится на множитель старшинства карты (с 2 до 9 - х1.5, с валета до туза - х3, с короля до туза - х6 только туз - х12. Если вы угадали появление джокера, ваша ставка умножится на 24). Поставить можно на каждый из 5 вариантов.\n',
  'Stats for the last {{count}} rounds': 'Статистика за {{count}} раундов',

  RED: 'КРАСНОЕ',
  BLACK: 'ЧЕРНОЕ',
  'Max bet has been reached': 'Достигнута максимальная ставка',
};
