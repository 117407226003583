/* eslint-disable */
export default {
  Error: 'Fel',
  'Error placing a bet': 'Ett fel inträffade vid satsning',
  'Error reserving an item': 'Fel i att reservera ett objekt',
  'Link saved successfully': 'Länken har sparats',
  "You've successfully updated a bet": 'Din insats har uppdaterats',
  "You've successfully sell winning item": 'Du har sålt en vinnande artikel',
  "You've successfully take winning item": 'Du har tagit en vinnande artikel',
  'Incorrect link. Enter the correct link and try again.':
    'Felaktig länk. Ange den korrekta länken och försök igen.',
  'This is not your link. Enter your link and try again.':
    'Det här är inte din länk. Ange din länk och försök igen.',
  "Don't forget to make your inventory public to get your winnings!":
    'Glöm inte att göra dina inventarier offentliga för att få vinsterna!',
  'min. number of items per trade not exceeded': 'Minsta antal artiklar per köp har inte uppnåtts',
  'Too many requests, try again later': 'För många förfrågningar, försök igen senare',
  'cheating attempt': 'fuskförsök',
  'max. number of items per trade exceeded': 'maximalt antal föremål per byte har överskridits',
  'player not registered on website': 'spelaren är inte registrerad på webbsidan',
  'souvenir items are not allowed': 'souvenirer tillåts inte',
  'some of the items cannot be appraised': 'en del av föremålen kan inte värderas',
  'some of the items are below minimum price':
    'en del av föremålen är värda mindre än minsta möjliga pris',
  'total price of a trade is below minimum': 'totalt pris för bytet är under mimimum',
  'Your trade has been accepted!': 'Ditt byte har accepterats!',
  'The items have been added to the queue.': 'Föremålen har lagts till kön.',
  'Your trade has been checked out!': 'Ditt byte checkades ut!',
  'It is currently being accepted': 'Det håller på att accepteras',
  'Unable to send a trade': 'Det gick inte att skicka ett byte',
  'Update your trade link in the settings of the site':
    'Uppdatera din byteslänk i inställningarna på sidan',
  'Enter correct trade link and make sure your inventory is not full.':
    'Ange korrekt byteslänk och se till att dina inventarier inte är fulla',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Ditt konto är inte tillgängligt för handel. Mer information visas om du bjuder in någon till handel.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam är ej tillgängligt för närvarande. Vänta och försök igen.',
  'Not enough money on balance': 'Inte tillräckligt med poäng på saldot',
  'Not enough points on balance': 'Inte tillräckligt med poäng på saldot',
  'Game is ending, try again later': 'Spelet håller på att avslutas, försök igen senare',
  'Congratulations!': 'Grattis!',
  "You've successfully placed a bet": 'Du har placerat en insats',
  'Item has not been found, coins have been returned': 'Föremålet saknas. Returnerar mynt',
  'Your order in the store has been cancelled': 'Din beställning i butiken har avbrutits',
  'The min bet is </minBet>': 'Minsta insats är {{</minBet>}}',
  'The max bet is </maxBet>': 'Max insats är {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Du kan inte satsa på röd och svart samtidigt',
  "You don't have CSGOFAST.com in Steam nickname!":
    'Du har inte CSGOFAST.com i ditt användarnamn på Steam!',
  'You have exceeded the limit of 150 free coins per account':
    'Du har överskridit antalet på 150 gratismynt per konto',
  "You've bet </amount> on the crash game #</game>!": 'Du har satsat {{</amount>}} på crashspel #!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Du har uppdaterat koefficienten till {{</coef>}} för crashspel #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'Du har vunnit {{</amount>}} på crashspel # {{</number>}}!',
  'Code for </amount> has been created': 'Kod för {{</amount>}} har skapats',
  'You already have an item': 'Du har redan en artikel',
  '</amount> refilled on your balance': '{{</amount>}} har fyllts på till ditt saldo',
  'We will sent it to your Steam in few moments': 'Vi skickar den strax till din Steam',
  'Your items has been sended to game!': 'Dina artiklar har skickats till spelet!',
  '</amount> have been returned': '{{</amount>}} har återbetalats',
  'Item has not been found, retry in few minutes (</amount>)':
    'Artikeln hittas inte, försök igen om några minuter ({{</amount>}})',
  'It is currently being processing by game bots': 'Det bearbetas för närvarande av bottar',
  '</amount> have been refilled': '{{</amount>}} har fyllts på',
  'Request successfully created': 'Förfrågan har skapats',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Din order #{{</id>}} för {{</amount>}} har godkänts, vänta på trade i Steam.',
  'Reason of order rejection': 'Orsak till att ordern nekats',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Ditt vad på {{</amount>}} har godkänts och kommer att läggas till spelet om {{</time>}} sekunder',
  'Your bet was added to queue, in few seconds will added to the game':
    'Ditt vad har lagts till i kö, om några sekunder läggs det till spelet',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Stängt för uttag. Vinn ytterligare ${currency(amount)} för att låsa upp.',
    },
  },
};
