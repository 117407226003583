import styled from 'styled-components';

export const Container = styled.div<{
  gap: number;
  isTeam: boolean;
  playersCount: number;
}>`
  display: grid;
  margin-top: ${({ isTeam }) => (isTeam ? '5px' : '40px')};
  width: 100%;
  position: relative;
  justify-content: center;
  gap: ${({ isTeam, gap }) => (isTeam ? '15' : `${gap}`)}px;
  ${({ playersCount, isTeam }) =>
    `grid-template-columns: repeat(${playersCount}, ${isTeam ? 160 : 80}px)`};

  @media screen and (max-width: 374px) {
    ${({ playersCount, isTeam }) =>
      `grid-template-columns: repeat(${playersCount}, ${isTeam ? 132 : 66}px)`};
  }
`;

export const TeamsContainer = styled.div<{ playersCount: number }>`
  display: grid;
  gap: 4px;
  justify-content: space-around;
  width: 100%;
  grid-template-columns: repeat(${({ playersCount }) => playersCount}, 80px);

  @media screen and (max-width: 374px) {
    grid-template-columns: repeat(${({ playersCount }) => playersCount}, 66px);
  }
`;
