import { ReactComponent as BalanceIcon } from './balance.svg';
import { ReactComponent as FreezeIcon } from './freeze.svg';
import { ReactComponent as LevelsIcon } from './levels.svg';
import { ReactComponent as NotificationsIcon } from './notifications.svg';
import { ReactComponent as ReferralsIcon } from './referrals.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import { ReactComponent as StatisticsIcon } from './statistics.svg';

export const SvgIcons = {
  BalanceIcon,
  FreezeIcon,
  LevelsIcon,
  NotificationsIcon,
  ReferralsIcon,
  SettingsIcon,
  StatisticsIcon,
};
