import * as React from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { AccountAvatar } from '@components/Common/Avatars';
import { ILeaderBoardItem } from '../../interfaces/reducer.leaders.interfaces';

interface ITournamentListItem extends ILeaderBoardItem {
  raceType?: string;
  position?: string;
}

export const TournamentListItem: React.FC<ITournamentListItem> = ({
  name,
  place,
  prizeTicketsAmount,
  prizeCoinsAmount,
  raceType,
  avaLink,
  userId,
  position,
  tickets,
}) => (
  <Container>
    <span className="user-place">{place || position}</span>
    <AccountAvatar uri={avaLink} userId={userId} />
    <div className="user-name">{name}</div>
    <div className="prize-container">
      {!!tickets && (
        <>
          {tickets?.green && (
            <span className="ticket green">
              <i className="icon-coupon" />
              {tickets.green}
            </span>
          )}
          {tickets?.blue && (
            <span className="ticket blue">
              <i className="icon-coupon" />
              {tickets.blue}
            </span>
          )}
          {tickets?.orange && (
            <span className="ticket orange">
              <i className="icon-coupon" />
              {tickets.orange}
            </span>
          )}
        </>
      )}
      {prizeCoinsAmount > 0 && (
        <span>
          <CurrencyText value={prizeCoinsAmount} />
        </span>
      )}
      {raceType && (
        <span className={`ticket ${raceType}`}>
          <i className="icon-coupon" />
          {prizeTicketsAmount}
        </span>
      )}
    </div>
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: 1rem 1.875rem 1fr min-content;
  grid-gap: 0.4rem;
  padding: 0.2rem 0.9375rem;

  font-size: 0.625rem;
  line-height: 2;
  color: var(--color-white);

  img {
    width: 30px;
    height: 30px;
    pointer-events: auto;
  }

  & > div {
    align-self: center;
  }

  & .user-place {
    opacity: 0.5;
  }

  & .user-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .prize-container {
    display: grid;
    grid-auto-columns: 2.5rem;
    grid-gap: 0.4rem;
    grid-auto-flow: column;
    justify-self: self-end;

    & .ticket {
      justify-self: self-start;

      &:last-of-type {
        justify-self: self-end;
      }

      &.green,
      &.regular {
        color: var(--regular-race);
      }
      &.blue,
      &.fast {
        color: var(--fast-race);
      }
      &.orange,
      &.great {
        color: var(--great-race);
      }

      & .icon-coupon {
        color: inherit;
        margin-right: 0.25rem;
      }
    }
  }
`;
