export const payTableConfig = [
  {
    slot: [6],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 5000,
        bgColor: '#ff0000',
        color: ' var(--yellow)',
      },
      {
        prizeFactor: 4,
        prizeValue: 1500,
        bgColor: '#cf0100',
      },
      {
        prizeFactor: 3,
        prizeValue: 100,
        bgColor: '#850803',
      },
    ],
  },
  {
    slot: [5],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 1000,
        bgColor: '#ff0000',
        color: ' var(--yellow)',
      },
      {
        prizeFactor: 4,
        prizeValue: 500,
        bgColor: '#cf0100',
      },
      {
        prizeFactor: 3,
        prizeValue: 50,
        bgColor: '#850803',
      },
    ],
  },
  {
    slot: [4],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 500,
        bgColor: '#ff0000',
        color: ' var(--yellow)',
      },
      {
        prizeFactor: 4,
        prizeValue: 200,
        bgColor: '#cf0100',
      },
      {
        prizeFactor: 3,
        prizeValue: 25,
        bgColor: '#850803',
      },
    ],
  },
  {
    slot: [3],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 200,
      },
      {
        prizeFactor: 4,
        prizeValue: 100,
      },
      {
        prizeFactor: 3,
        prizeValue: 10,
      },
    ],
  },
  {
    slot: [2],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 100,
      },
      {
        prizeFactor: 4,
        prizeValue: 50,
      },
      {
        prizeFactor: 3,
        prizeValue: 7,
      },
    ],
  },
  {
    slot: [1],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 50,
      },
      {
        prizeFactor: 4,
        prizeValue: 25,
      },
      {
        prizeFactor: 3,
        prizeValue: 5,
      },
    ],
  },
  {
    slot: [0],
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 25,
      },
      {
        prizeFactor: 4,
        prizeValue: 10,
      },
      {
        prizeFactor: 3,
        prizeValue: 3,
      },
    ],
  },
  {
    slot: [7, 8, 9],
    description: 'Collect all three slots and get 10 bonus games!',
  },
  {
    slot: [11],
    description: 'Can substitute any slot in the line (Except for bonus slots)',
  },
  {
    slot: [10],
    description: 'Slot works regardless of location',
    prize: [
      {
        prizeFactor: 5,
        prizeValue: 25,
      },
      {
        prizeFactor: 4,
        prizeValue: 10,
      },
      {
        prizeFactor: 3,
        prizeValue: 3,
      },
    ],
  },
];
