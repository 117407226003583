import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import * as Animated from 'animated/lib/targets/react-dom';

import { withTranslation } from 'react-i18next';
import { getSteamItemImageSrc } from '../../../core/utils/image.utils';
import { CurrencyText } from '../../currency/index';

import arrow from '../assets/arrow.svg';
import star from '../../../assets/images/star-low.png';

const casesIdColor = ['#00adcc', '#ffa257', '#fafafa'];
const casesIdTitle = ['blue', 'orange', 'silver'];

class _HistoryItem extends React.PureComponent {
  state = {
    isOpen: false,
  };
  toggleAnimate = new Animated.Value(0);
  heightInterpolate = this.toggleAnimate.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 200],
  });

  opacityInterpolate = this.toggleAnimate.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      Animated.timing(this.toggleAnimate, {
        toValue: this.state.isOpen ? 1 : 0,
        duration: 300,
      }).start();
    }
  }

  render() {
    const {
      gameId,
      timestamp,
      itemImage,
      itemName,
      caseId,
      chance,
      rarity,
      itemPrice,
      userPrice,
      action,
      t,
    } = this.props;

    return (
      <Container onClick={this.toggleOpen}>
        <HistoryHeader>
          <TitleContainer>
            <HistoryTitle>
              {gameId ? (
                <span>
                  {t('GAME')} №{gameId}
                </span>
              ) : (
                t('game from:')
              )}
            </HistoryTitle>
            <HistoryDate>{dayjs(timestamp).format('DD.MM.YYYY, HH:mm')}</HistoryDate>
          </TitleContainer>
          <ImageContainer>
            <Chance>
              {chance && (
                <Rarity color={rarity}>
                  {chance}
                  <span>{' %'}</span>
                </Rarity>
              )}
            </Chance>
            <ImageInner showlayout={!!chance}>
              <HistoryImage src={getSteamItemImageSrc(itemImage)} />
            </ImageInner>
          </ImageContainer>
        </HistoryHeader>
        <Animated.div
          style={{
            maxHeight: this.heightInterpolate,
            opacity: this.opacityInterpolate,
            overflow: 'hidden',
          }}
        >
          <HistoryBody>
            {caseId && (
              <CaseTitle caseId={caseId - 1}>
                {casesIdTitle[caseId - 1]}
                <CaseTextShade caseId={caseId - 1}>Case</CaseTextShade>
              </CaseTitle>
            )}

            <HistoryText>
              {t('Item:')}
              {` ${itemName}`}
            </HistoryText>
            <HistoryText>
              {t('Item price')}
              {': '}
              <CurrencyText value={itemPrice} />
            </HistoryText>

            {userPrice && (
              <HistoryText>
                {t('Your current income')}
                {': '}
                <span style={{ color: '#1bff00' }}>
                  <CurrencyText value={itemPrice - userPrice} />
                </span>
              </HistoryText>
            )}

            {(action === null && <ItemStatus state={action}>{t('Sent')}</ItemStatus>) ||
              (action === false && <ItemStatus state={action}>{t('Taken')}</ItemStatus>) ||
              (action === true && <ItemStatus state={action}>{t('Sold')}</ItemStatus>)}
          </HistoryBody>
        </Animated.div>

        <ArrowIcon src={arrow} isOpen={this.state.isOpen} />
      </Container>
    );
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
}

export const HistoryItem = withTranslation()(_HistoryItem);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 15px 10px;
  margin: 10px 17px;
  background-color: #212f35;

  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HistoryTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.7px;
  line-height: 1.8;
  color: var(--color-white);
  text-transform: uppercase;
`;

const HistoryText = styled.span`
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.8px;
  color: var(--color-white);
  margin-bottom: 3px;
`;

const HistoryDate = styled.span`
  font-size: 14px;
  letter-spacing: 0.8px;
  color: #8d9498;
`;

const HistoryHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: 10px;
`;

const HistoryImage = styled.img`
  max-width: 60%;
  object-fit: contain;
`;

const HistoryBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const ArrowIcon = styled.img`
  width: 18px;
  transform: rotate(${({ isOpen }) => (isOpen ? -90 : 90)}deg);
  transition: rotate linear 0.4s;
  position: absolute;
  right: 10px;
  bottom: 5px;
`;

const ItemStatus = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.77px;
  color: ${({ state }) => (state === null && 'var(--yellow)') || '#1bff00'};
  ${'' /* (state === false && '#f6e444') ||
    (state === true && '#1bff00')}; */}
`;

// Cases style

const CaseTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.4px;
  color: ${({ caseId }) => casesIdColor[caseId]};
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const CaseTextShade = styled.span`
  display: inline-block;
  margin-left: 7px;
  padding: 3px 5px;
  color: #212f35;
  background-color: ${({ caseId }) => casesIdColor[caseId]};
  text-transform: uppercase;
`;

// Craft style

const Chance = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Rarity = styled.span`
  color: ${({ color }) => (color && color !== 'Fun' ? color : 'var(--color-white)')};
  font-size: 16px;
  font-weight: bold;

  & > span {
    font-size: 14px;
  }
`;

const ImageInner = styled.div`
  width: ${({ showlayout }) => (showlayout ? '50px' : '100px')};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: ${({ showlayout }) => (showlayout ? 'center' : 'flex-end')};
  border-radius: 50%;

  position: relative;
  background-image: url(${({ showlayout }) => (showlayout ? star : 'none')});
  background-size: 160% 160%;
  background-repeat: no-repeat;
  background-position: center;
`;
