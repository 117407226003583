import React, { FC } from 'react';
import { depositBonus, prepaidChips } from '../../../refill.util';
import {
  // useBonusRefillEvent,
  // useCreatePay,
  useTicketsRefillEvent,
} from '../../../hooks';
import { IPayMethod, IPayMethodGates } from '../../../interfaces/reduces.refill.interfaces';
import { useTranslation } from 'react-i18next';
import { URLS } from 'services/constant-urls';
import { ChipItem, Container } from './pay-gift-chip-list.styles';

interface IPayGiftChipList {
  gate: number;
  gates: IPayMethodGates;
  method: IPayMethod;
}

export const PayGiftChipList: FC<IPayGiftChipList> = ({ gate, gates, method }) => {
  const { t } = useTranslation();
  const { tickets } = useTicketsRefillEvent();
  // const [handleCreatePay] = useCreatePay()
  // const { bonus } = useBonusRefillEvent()
  // const boost = bonus / 100
  // const minPayment = gates[gate]?.minPayment

  return (
    <Container>
      <div className="gift-card-description">
        <span>{t('Refill via')} Prepaid Cards</span>
        <span>{t('Step 1 Buy a card from our partners:')}</span>
        <img src={`${URLS.refill.icon}/prepaid-kinguin.svg`} alt="kinguin" height="16" />
      </div>
      <div className="inner-container">
        {prepaidChips.map(({ price, img, url }) => {
          return (
            <ChipItem key={price} href={url} className={`chip-${price}`} target="_blank">
              {depositBonus[price] && tickets && 7 - tickets - depositBonus[price] < 0 && (
                <div className="event-boost">
                  <span>+{Math.abs(7 - tickets - depositBonus[price])}</span>
                  <i className="icon-coupon" />
                  <i className="icon-coupon" />
                </div>
              )}
              <img className="chip-thumbnail" src={img} alt="chip" />
            </ChipItem>
          );
        })}
      </div>
    </Container>
  );
};
