import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, GameIcon, GameTitle, NewGameTitle } from './LobbyGame.styles';
import { ModuleName } from './LobbyGame.config';
import { GamesIcons } from 'assets';

interface ILobbyGame {
  moduleName: ModuleName;
  modulePath: string;
}

export const LobbyGame: React.FC<ILobbyGame> = ({ moduleName, modulePath }) => {
  const { t } = useTranslation();

  const params = window.innerHeight > 375 ? 60 : 40;

  return (
    <Container gamename={moduleName} to={modulePath}>
      {(moduleName === 'cases' || moduleName === 'caseBattle') && <NewGameTitle>NEW</NewGameTitle>}
      <GameIcon>{GamesIcons[moduleName]?.(params, params)}</GameIcon>
      <GameTitle>{t(modulePath)}</GameTitle>
    </Container>
  );
};
