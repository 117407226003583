import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavigationLink } from '@components/Common';
import { useConfigMethods } from '../../../hooks';
import { IPayMethod } from '../../../interfaces/reduces.refill.interfaces';
import { URLS } from 'services/constant-urls';
import { onErrorHandler } from 'core/utils';
import { Bonus, NavigateList, NavigateTitle } from './refill-systems-list.styles';

export const RefillSystemsList: FC = () => {
  const { t } = useTranslation();
  const { regional, systems, popular } = useConfigMethods();

  return (
    <>
      <NavigateTitle>{t('Popular methods:')}</NavigateTitle>
      <NavigateList>
        <NavigationLink to={'/market/inventory'} as={Link}>
          <div className="leading">
            <i className="icon-csgo" />
          </div>
          <div>
            <span>CS:GO</span>
          </div>
        </NavigationLink>
        {popular?.map((item: IPayMethod) => (
          <NavigationLink
            key={item.name}
            to={`/refill/popular/${item.name}`}
            as={Link}
            className={item.is_disabled ? 'disabled' : ''}
          >
            <div className="leading">
              {item.icon ? (
                <img src={item.icon} alt="" onError={e => onErrorHandler(e, URLS.refill.error)} />
              ) : (
                <img
                  src={`${URLS.refill.icon}/refill-${item.name}.svg`}
                  alt=""
                  onError={e => onErrorHandler(e, URLS.refill.error)}
                />
              )}
            </div>
            <div>
              <span>{t(item.title)}</span>
              {item.is_disabled && <span>{t('Temporarily unavailable')}</span>}
              {!item.is_disabled && (
                <>
                  {item.commission ? <span>{item.commission}</span> : <span>{item?.problems}</span>}
                </>
              )}
            </div>
            {item.is_disabled && <i className="icon-lock-temporarily" />}
            {!item.is_disabled && !!item?.problems && <i className="icon-warn" />}
          </NavigationLink>
        ))}
      </NavigateList>
      <NavigateTitle>{t('Payment systems:')}</NavigateTitle>
      <NavigateList>
        {systems?.map((item: IPayMethod) => {
          const maxBonus = Math.max(...item?.bonus);
          const hasSomeBonuses = item?.bonus?.length > 1;
          const isFromRegionalPayment = item.title === 'Apple Pay' || item.title === 'Google Pay';

          return (
            <NavigationLink
              key={item.name}
              to={`/refill/${isFromRegionalPayment ? 'regional' : 'systems'}/${item.name}`}
              as={Link}
              className={item.is_disabled ? 'disabled' : ''}
            >
              <div className="leading">
                {item.icon ? (
                  <img src={item.icon} alt="" onError={e => onErrorHandler(e, URLS.refill.error)} />
                ) : (
                  <img
                    src={`${URLS.refill.icon}/refill-${item.name}.svg`}
                    alt=""
                    onError={e => onErrorHandler(e, URLS.refill.error)}
                  />
                )}
              </div>
              <div>
                <span>{item.title}</span>
                {item.is_disabled && <span>{t('Temporarily unavailable')}</span>}
                {!!maxBonus && (
                  <Bonus>
                    {hasSomeBonuses ? t('Bonus up to') : t('Bonus')}:{` ${maxBonus}%`}
                  </Bonus>
                )}
                {!item.is_disabled && item?.problems && <span>{item.problems}</span>}
              </div>
              {item.is_disabled && <i className="icon-lock-temporarily" />}
              {!item.is_disabled && !!item?.problems && <i className="icon-warn" />}
            </NavigationLink>
          );
        })}
      </NavigateList>
      <NavigateTitle>{t('Region methods:')}</NavigateTitle>
      <NavigateList>
        {regional?.map((item: IPayMethod) => {
          const maxBonus = Math.max(...item?.bonus);
          const hasSomeBonuses = item?.bonus?.length > 1;

          return (
            <NavigationLink
              key={item.name}
              to={`/refill/regional/${item.name}`}
              as={Link}
              className={item.is_disabled ? 'disabled' : ''}
            >
              <div className="leading">
                {item.icon ? (
                  <img src={item.icon} alt="" onError={e => onErrorHandler(e, URLS.refill.error)} />
                ) : (
                  <img
                    src={`${URLS.refill.icon}/refill-${item.name}.svg`}
                    alt=""
                    onError={e => onErrorHandler(e, URLS.refill.error)}
                  />
                )}
              </div>
              <div>
                <span>{item.title}</span>
                {item.is_disabled && <span>{t('Temporarily unavailable')}</span>}
                {!!maxBonus && (
                  <Bonus>
                    {hasSomeBonuses ? t('Bonus up to') : t('Bonus')}:{` ${maxBonus}%`}
                  </Bonus>
                )}
                {!item.is_disabled && item?.problems && <span>{item.problems}</span>}
              </div>
              {item.is_disabled && <i className="icon-lock-temporarily" />}
              {!item.is_disabled && !!item?.problems && <i className="icon-warn" />}
            </NavigationLink>
          );
        })}
      </NavigateList>
    </>
  );
};
