import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyInput } from '@components/currency';
import { useParticipateSettings } from 'core/hooks';
import { ParticipateModule } from 'core/interfaces';

interface ICashPanel {
  module: ParticipateModule;
  amount: number;
  handleChangeAmount: (amount: number) => void;
}

export const CashPanel: FC<ICashPanel> = ({ amount, handleChangeAmount, module }) => {
  const { settings } = useParticipateSettings(module);

  return (
    <Container className="cash-panel">
      <CurrencyInput
        value={amount / 100}
        setValue={handleChangeAmount}
        minValue={settings.min_bet / 100}
        maxValue={settings.max_bet / 100}
      />
    </Container>
  );
};

const Container = styled.div`
  align-self: self-end;
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 0 1rem;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
`;
