import { AuthTypes } from './interfaces/auth-type.enum';

export const authProviderConfig: Partial<AuthTypes>[] = [
  'steam',
  'google',
  'vkontakte',
  // 'twitter',
  'meta',
  // 'yandex',
  'twitch',
  'telegram',
];
