import React, { useEffect, useRef, useState } from 'react';
import { useUser } from 'core/User';
import {
  actionRequestLeaderRaceQuests,
  actionRequestLeaderRaceQuestsLoading,
  actionTakeReward,
} from 'modules/event-module/comics-event/duck/leader-race-duck';
import { useGetQuestsInfo } from 'modules/event-module/comics-event/hooks';
import {
  PeriodTime,
  useGetCategoryTime,
} from 'modules/event-module/comics-event/hooks/use-get-category-time.hook';
import { GameTypes, TicketTypeEnum } from 'modules/event-module/comics-event/interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Description,
  DescriptionContainer,
  Header,
  ItemContainer,
  Line,
  StatusContainer,
  Time,
  Title,
  WeeklyContainer,
} from './weekly-tasks.styles';
import { timeFormat } from 'core/utils';
import { Container as WeeklyStatusContainer } from '../weekly-statuses/weekly-statuses.styles';
import { WeeklyStatuses } from '../weekly-statuses/weekly-statuses';
import { GamesIcons } from 'assets';
import { ModuleName } from 'modules/Lobby/components';
import { EventIcons } from 'modules/event-module/comics-event/assets';

export const GamesName: Record<GameTypes, string> = {
  1: 'Classic',
  2: 'Fast',
  3: 'Double',
  4: 'Duel',
  5: 'Slot',
  6: 'Csgo_bots',
  7: 'Crash',
  9: 'Craft',
  10: 'Cases',
  13: 'X50',
  14: 'Tower',
  15: 'Wheel',
  16: 'Hilo',
  19: 'Poggi',
  18: 'Baccarat',
  33: 'Shop',
  34: 'Inventory',
};

export const WeeklyTasks = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { weekly } = useGetQuestsInfo();
  const ref = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { time } = useGetCategoryTime(PeriodTime.WEEKLY);
  const [skeletonHeight, setSkeletonHeight] = useState(40);

  useEffect(() => {
    if (user.id) {
      dispatch(actionRequestLeaderRaceQuests());
    } else {
      dispatch(actionRequestLeaderRaceQuestsLoading(true));
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    const element = ref.current;
    const itemListElement = itemRef.current;
    if (element && itemListElement) {
      element.scrollLeft = itemListElement.offsetLeft - window.innerWidth / 2;
      setSkeletonHeight(element.clientHeight);
    }
  }, [weekly]);

  const takeRewardHandler = (questId: number) => {
    dispatch(actionTakeReward(questId));
  };

  const redirectHandler = (gameTypeId: GameTypes) => {
    if (GamesName[gameTypeId]) {
      history.push(`../${GamesName[gameTypeId].toLocaleLowerCase()}`);
    }
  };

  return (
    <Container>
      <Header>
        <Title>{t('weeklyTasks')}</Title>{' '}
        {typeof time.hours === 'number' && (
          <Time>
            (
            {time.days > 1 ? (
              <>
                {time.days}{' '}
                {t('pluralize time.days', {
                  ns: 'marketplace',
                  count: time.days,
                  postProcess: 'interval',
                })}
              </>
            ) : (
              <>
                {timeFormat(time.hours?.toString())}:{timeFormat(time.minutes?.toString())}:
                {timeFormat(time.seconds?.toString())}
              </>
            )}
            )
          </Time>
        )}
      </Header>
      <WeeklyContainer ref={ref}>
        {!weekly.length
          ? [1, 2, 3, 4, 5, 6, 7].map(value => (
              <ItemContainer key={value} height={skeletonHeight - 10}>
                <StatusContainer>
                  <WeeklyStatusContainer size={'32px'} className="skeleton">
                    <EventIcons.Lock />
                  </WeeklyStatusContainer>
                </StatusContainer>
              </ItemContainer>
            ))
          : weekly.map(
              ({
                isHidden,
                isOpened,
                id,
                name,
                params,
                priority,
                progress,
                rewardTaken,
                gameTypeId,
              }) => (
                <ItemContainer key={id}>
                  <StatusContainer>
                    <WeeklyStatuses
                      takeRewardHandler={takeRewardHandler}
                      isHidden={isHidden}
                      isOpen={isOpened}
                      isLast={priority === 7}
                      progress={
                        (100 * progress) / params.count > 100
                          ? 100
                          : (100 * progress) / params.count
                      }
                      isRewardTaken={rewardTaken}
                      questId={id}
                      ref={itemRef}
                      type={priority === 7 ? TicketTypeEnum.SPECIAL : TicketTypeEnum.WEEKLY}
                    />
                  </StatusContainer>
                  {isOpened && (
                    <DescriptionContainer isTaskReady={rewardTaken}>
                      <Description isTaskReady={rewardTaken}>
                        {t(name, {
                          ...params,
                          postProcess: 'with-template',
                        })}
                      </Description>
                      {GamesName?.[gameTypeId] && (
                        <>
                          {' '}
                          <Description
                            isTaskReady={rewardTaken}
                            onClick={() => redirectHandler(gameTypeId)}
                          >
                            {GamesIcons[GamesName?.[gameTypeId]?.toLowerCase() as ModuleName]?.(
                              20,
                              20
                            )}{' '}
                            {GamesName?.[gameTypeId]}
                          </Description>
                        </>
                      )}
                    </DescriptionContainer>
                  )}
                </ItemContainer>
              )
            )}
        <Line />
      </WeeklyContainer>
    </Container>
  );
};
