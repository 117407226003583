import { IStore } from 'store/interface';
import { IFastInventoryElement } from 'core/User';

export const getClearance = ({ stock }: IStore) => stock.clearance.get('items');
export const getClearanceAmount = ({ stock }: IStore) => stock.clearance.get('amount');
export const hasInClearance = (id?: number) => ({ stock }: IStore) =>
  stock.clearance.get('items').some((item: IFastInventoryElement) => item.id === id);

export const getFreeCaseInfo = ({ freeCaseInfo }: IStore) => {
  return freeCaseInfo;
};
