/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'ИГРА',
  Chat: 'Чат',
  'Email Support': 'Связаться с тех. поддержкой по email',
  'Live Support Chat': 'Онлайн-чат поддержки',
  'Privacy policy': 'Политика конфиденциальности',
  'Terms and conditions': 'Условия и положения',
  History: 'История',
  Top: 'ТОП',
  Rules: 'Rules',
  'At stake': 'На кону',
  Amount: 'Сумма',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Чтобы писать сообщения необходимо авторизоваться',
  'Only players who have played the game are allowed to write':
    'В чате могут общаться только те игроки, которые сыграли на сайте одну или более игр',
  'You are banned': 'Вы забанены',
  Reason: 'Причина',
  'Time left': 'Осталось',
  'Time to summarizing': 'До подведения итогов осталось',
  spam: 'спам',
  'advertising (auto)': 'реклама (авто)',
  advertising: 'реклама',
  rudeness: 'грубость',
  begging: 'попрошайничество',
  Search: 'Поиск',
  Sound: 'Sound',
  Today: 'Сегодня',
  Yesterday: 'Вчера',
  'Last year': 'За год',
  'Show my wins': 'Показать мои победы',
  'History is empty': 'История пуста',
  Cancel: 'Отмена',
  Delete: 'Удалить',
  Close: 'Закрыть',
  Confirm: 'Подтвердить',
  'Are you sure to delete all notifications?': 'Вы действительно хотите удалить все нотификации?',
  'Log in': 'Войти',
  'Log in to try your luck': 'Войти, чтобы испытать удачу',
  'try your luck!': 'и проверить свою удачу!',
  'Take your prize': 'Забрать выигрыш',
  'Sell for': 'Продать за',
  BACK: 'BACK',
  'Warning!': 'Внимание!',
  'Congratulations!': 'Поздравляем!',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Поздравляем с победой, трейд оффер с призом выслан на ваш аккаунт.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'Трейд будет отменен через 1 час.',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'После обработки заявки вы сможете забрать ваш выигрыш на странице предложений обмена в Steam',
  'For betting skins you need to put steam Trade Offer link into a Settings':
    'Для ставки скинами надо ввести ссылку для обмена в настройках',

  'Round number': 'Число раунда',
  'Round hash': 'Хэш раунда',
  'Hash salt': 'Соль хеша',
  'Round secret': 'Секрет раунда',
  SKINS: 'СКИНЫ',
  'Loading...': 'Загрузка...',
  'pluralize-days': '${pluralize(count, ["день", "дня", "дней"])}',
  'pluralize-left': '${pluralize(count, ["Остался", "Осталось", "Осталось"])}',

  'Refill amount': 'Сумма пополнения',
  Bonus: 'Бонус',
  'Bonus up to': 'Бонус до',
  'Bonus experience': 'Бонус опыта',
  'Bonus wheel spins': 'Спины колеса в подарок',
  'Bonus cases': 'Кейсы в подарок',

  'Trick or treat?': 'Кошелек или жизнь?',
  'Keep your halloween tip jars ready!': 'Подарки за каждое пополнение баланса!',
  'Bonuses on every balance refill': 'Забери свой бонус!',

  '${currency(amount)} bonus is ready!': 'Бонус до ${currency(amount)}',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Пополняй баланс и забирай до ${currency(amount)} бонуса в виде опыта, спинов и кейсов!',

  'Get bonus': 'Получить',

  'Refill bonus': 'Бонус за пополнение',
};
