import React from 'react';
import { countriesDictionary } from 'modules/Refill/refill.util';
import { URLS } from 'services/constant-urls';
import { Country, CountryName, Img, Indicator, Input, Label } from './country-card.styles';

interface ICountryCard {
  autoRefillCountry: string;
  country: string;
  handleClick: (country: string) => void;
  index: number;
  options: string[];
  style: React.CSSProperties;
}

export const CountryCard: React.FC<ICountryCard> = ({
  autoRefillCountry,
  country,
  handleClick,
  index,
  options,
  style,
}) => (
  <Country style={style} onClick={() => handleClick(options[index])}>
    <Img src={`${URLS.countryFlags}/${options[index]?.toLowerCase()}.png`} alt={options[index]} />
    <CountryName isCountry={options[index] === (country || autoRefillCountry)}>
      {countriesDictionary[options[index]]}
    </CountryName>
    <Label htmlFor={index.toString()}>
      <Input
        id={index.toString()}
        type="radio"
        checked={options[index] === (country || autoRefillCountry)}
        readOnly
      />
      <Indicator />
    </Label>
  </Country>
);
