import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--color-dark-600);
  padding: 40px 18px;
  text-align: center;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  text-align: left;
`;

export const SubTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 18px;
  padding: 9px 0 20px;
  text-align: left;
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  gap: 8px;
  padding: 32px 0;

  & > label {
    display: block;
    height: 24px;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    &::before {
      background: var(--color-dark-100);
      border-radius: var(--size-radius-s);
      border: 3px solid var(--gray-220);
      content: '';
      position: absolute;
    }

    &::after {
      align-items: center;
      border-radius: var(--size-radius-s);
      color: var(--yellow);
      content: '';
      display: flex;
      font-size: 0.9rem;
      justify-content: center;
    }
  }

  & > input {
    display: none;

    &:checked + label::after {
      background: var(--gray-220);
      content: '\\e940';
    }
  }
`;

export const CheckBoxTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 12px;
  letter-spacing: 0.571px;
  padding-left: 32px;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
`;
