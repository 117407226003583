/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'GAME',
  Chat: 'Chat',
  'Email Support': 'Email Support',
  'Live Support Chat': 'Live Support Chat',
  'Privacy policy': 'Privacy policy',
  'Terms and conditions': 'Terms and conditions',
  History: 'History',
  Top: 'Top',
  Rules: 'Rules',
  'At stake': 'At stake',

  Amount: 'Amount',
  Accept: 'Accept',

  'To write a message you need to sign in': 'To write a message you need to sign in',
  'Only players who have played the game are allowed to write':
    'Only players who have played the game are allowed to write',
  'You are banned': 'You are banned ',
  'Time left': 'Time left ',

  'Time to summarizing': 'Time to summarizing:',

  spam: 'spam',
  'advertising (auto)': 'advertising (auto)',
  advertising: 'advertising',
  rudeness: 'rudeness',
  begging: 'begging',

  Search: 'Search',
  Sound: 'Sound',

  Today: 'Today',
  Yesterday: 'Yesterday',
  'Last year': 'Last year',

  'Show my wins': 'Show my wins',

  'History is empty': 'History is empty',

  Cancel: 'Cancel',
  Delete: 'Delete',
  Close: 'Close',
  Confirm: 'Confirm',

  'Are you sure to delete all notifications?': 'Are you sure to delete all notifications?',
  'Log in': 'Log in',
  'Log in to try your luck': 'Log in to try your luck',
  'try your luck!': 'to try your luck!',

  'Take your prize': 'Take your prize',
  'Sell for': 'Sell for: ',
  BACK: 'BACK',
  'Warning!': 'Warning!',

  'Congratulations!': 'Congratulations!',
  'You have won:': 'You have won:',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Congratulations! A trade offer with your prize has been sent to your account.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour.': 'The trade will be cancelled after one hour',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'After your order is processed you can collect your winnings on the submissions page of the Steam exchange',
  'For betting skins you need to put steam Trade Offer link into a Settings':
    'For betting skins you need to put steam Trade Offer link into a Settings',

  'Round number': 'Round number',
  'Round hash': 'Round hash',
  'Hash salt': 'Hash salt',
  'Round secret': 'Round secret',

  Skins: 'Skins',
  'Loading...': 'Loading...',
  'pluralize-days': '${pluralize(count, ["day", "days", "days"])}',
  'pluralize-left': '${pluralize(count, ["left", "left", "left"])}',
  'wallet-demo': 'Balance',
  'wallet-cash': 'Demo',

  'Refill amount': 'Refill amount',
  Bonus: 'Bonus',
  'Bonus up to': 'Bonus up to',
  'Bonus experience': 'Bonus experience',
  'Bonus wheel spins': 'Bonus wheel spins',
  'Bonus cases': 'Bonus cases',
  'Trick or treat?': 'Trick or treat?',
  'Keep your halloween tip jars ready!': 'Keep your halloween tip jars ready!',
  'Bonuses on every balance refill': 'Bonuses on every balance refill',

  '${currency(amount)} bonus is ready!': '${currency(amount)} bonus is ready!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Refill balance & get up to ${currency(amount)} in free spins and cases',

  'Get bonus': 'Get now',

  'Refill bonus': 'Refill bonus',
};
