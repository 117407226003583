import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  padding: 18px 16px 18px 19px;
`;

export const WearSpan = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: var(--color-white);
  display: flex;
  flex: 2;
  align-items: center;
`;

export const PriceSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
  text-align: center;
  color: var(--yellow);
  display: flex;
  flex: 1;
  align-items: center;
`;
export const StatTrakPrice = styled(PriceSpan)<{ isExist: boolean }>`
  color: var(--color-gray-200);
  justify-content: ${p => (p.isExist ? 'flex-end' : 'center')};
`;
