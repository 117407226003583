export default {
  Language: 'Dil',
  'Bets in current room': 'Kumarlar bu odada',
  Timers: 'Zamanlayıcı',
  'Game start': 'Oyun başladı',
  'Game ending': 'Oyun sona erdi',
  'Roulette ticking': 'Rulet başladı',
  'Roulette start': 'Rulet başladı',
  'Item set': 'Item set',
  'My bet': 'Benim betlerim',
  'Coins transfer': 'Coin gönder',
  'Start button': 'Başlat butonu',
  'Stop button': 'Durdur butonu',
  Countdown: 'Geri sayım',
  Soundtrack: 'Soundtrack',
  Crash: 'Crash',
  'Picking item': 'Toplama eşya',
  Crafting: 'Zanaatkarlık',
  Failure: 'Hata',
  Win: 'Kazanmak',
  Sell: 'Sat',
  Take: 'Al',
  'Rooms bets': 'Kumar odaları',
  'Chat message sending': 'Chate mesajınız gönderildi',
  'Chat message receiving': 'Chat mesajı aldınız',
  Sound: 'Ses',
  'Chat sound': 'Sohbet sesi',
  'Game sounds': 'Oyun sesi',
  Volume: 'Ses düzeyi',
  steamApi: {
    noKey: 'STEAM API ANAHTARI YOK',
    need: 'Diğer oyuncularla skin satmak ve takas yapmak için gereklidir',
    addLink: 'BAĞLANTI EKLE',
    link: 'NEREDEN ALINI',
  },
};
