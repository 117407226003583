import * as React from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addNotification } from 'modules/Notifications/duck';

interface ISecretField {
  value: string | null;
  placeholder: string;
}

const { useCallback } = React;

const useCopy = (text: string | null, placeholder: string) => {
  const dispatch = useDispatch();

  const handleCopy = useCallback(() => {
    if (!text) {
      dispatch(
        addNotification({
          type: 'error',
          header: 'Error',
        })
      );
      return;
    }

    copy(text);
    dispatch(
      addNotification({
        type: 'success',
        header: 'Copy',
        body: placeholder,
      })
    );
  }, [text, placeholder, dispatch]);

  return {
    handleCopy,
  };
};

export const SecretField: React.FC<ISecretField> = ({ value, placeholder }) => {
  const { handleCopy } = useCopy(value, placeholder);
  const { t } = useTranslation();
  return (
    <Container>
      <input value={value || 'hidden'} disabled placeholder={placeholder} />
      <button disabled={!value} onClick={handleCopy}>
        {t('Copy')}
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-flow: row nowrap;
  margin: 6px 12px;
  padding: 0 3px;
  border-radius: 4px;

  font-size: 14px;
  letter-spacing: 0.86px;
  background-color: var(--gray-600);
  color: var(--color-white);

  & > input {
    flex-basis: 65%;
    height: 40px;
    padding: 5px;
    border: 0;
    background-color: var(--gray-600);
    text-overflow: ellipsis;
  }

  & > button {
    flex-basis: 35%;
    height: 34px;
    padding-right: 5px;
    border-radius: 2px;
    border: 0;
    background-color: var(--yellow);

    font-size: 12px;
    letter-spacing: 0.03px;
    color: var(--gray-600);
    text-transform: uppercase;
  }
`;
