import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

// import steamIcon from '../../assets/steam.svg'
// import moneyIcon from '../../assets/money.svg'
import headerImage from '../../assets/header.png';

import { CurrencyText } from '../../../currency/index';

class _Controls extends PureComponent {
  state = {
    sellPrize: 0,
    takePrize: 0,
  };

  static getDerivedStateFromProps(props) {
    const { cartItems, onlySellItems, canTake, items } = props;

    const sellPrize = [...onlySellItems, ...canTake].reduce((prize, itemId) => {
      if (cartItems.includes(itemId)) return prize;
      return prize + items[itemId].price;
    }, 0);

    const takePrize = cartItems.reduce((prize, itemId) => {
      return prize + items[itemId].price;
    }, 0);

    return {
      sellPrize,
      takePrize,
    };
  }

  render() {
    const { sellPrize, takePrize } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Header>
          <HeaderImage src={headerImage} />
          <HeaderTitle>{t('Your win')}</HeaderTitle>
          <HeaderPrize>
            <CurrencyText value={sellPrize + takePrize} />!
          </HeaderPrize>
        </Header>
        {this.props.children}
        <Container>
          {/*<ControlsTitle icon={moneyIcon}>*/}
          {/*  {t('To balance')}*/}
          {/*  <ControlsPrize>*/}
          {/*    <CurrencyText value={sellPrize} />*/}
          {/*  </ControlsPrize>*/}
          {/*</ControlsTitle>*/}
          {/*<ControlsTitle icon={steamIcon}>*/}
          {/*  {t('Withdraw to STEAM')}*/}
          {/*  <ControlsPrize>*/}
          {/*    <CurrencyText value={takePrize} />*/}
          {/*  </ControlsPrize>*/}
          {/*</ControlsTitle>*/}
          <ControlsBtn onClick={this.props.handleTakeItems}>{t('Receive winnings')}</ControlsBtn>
        </Container>
      </Fragment>
    );
  }
}

export const Controls = withTranslation()(_Controls);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 18px 18px;
`;

// const ControlsTitle = styled.div`
//   display: flex;
//   justify-content: space-between;
//   font-size: 14px;
//   line-height: 1.14;
//   letter-spacing: 0px;
//   color: var(--color-white);
//
//   padding: 10px 0 10px 25px;
//   position: relative;
//
//   &:before {
//     content: '';
//     width: 20px;
//     height: 20px;
//     background-image: ${({ icon }) => `url(${icon})`};
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     position: absolute;
//     left: 0;
//     top: 7px;
//   }
// `

// const ControlsPrize = styled.span`
//   font-weight: bold;
//   color: #2ab6d5;
// `

const ControlsBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 0;
  border-radius: 5px;
  background-color: var(--yellow);

  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-dark-500);
  text-transform: uppercase;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: calc(100vw - 30%);
`;

const HeaderImage = styled.img`
  width: 60%;
  position: absolute;
  z-index: -1;
`;

const HeaderTitle = styled.span`
  font-size: 19px;
  line-height: 1.42;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--color-white);

  margin-top: 20px;
`;

const HeaderPrize = styled.span`
  font-size: 19px;
  line-height: 1.42;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: var(--yellow);
`;
