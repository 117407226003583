import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  BackBtn,
  ButtonContainer,
  Container,
  ReplayButton,
  ReplayWrapper,
  StyledButton,
} from './RecreateButton.styles';
import { useGetCaseBattleGameData, useGetCaseBattleGameReplayData } from 'games/CaseBattle/hooks';
import { GameStatus } from 'games/CaseBattle/constants';
import { useUser } from 'core/User';
import { AuthDialogs } from 'core/Auth';
import { useDialog } from 'core/hooks';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { CurrencyText } from '@components/currency';
import {
  actionBattleRollReplay,
  actionCreateGame,
  actionBattleFinishReplay,
  removeAllCases,
} from 'games/CaseBattle/ducks';
import { actionCaseBattleReplay } from 'games/CaseBattle/ducks/case-battle-game-duck';
import { getCurrentRounds } from 'games/CaseBattle/utils';
import { Modules } from 'core/interfaces';

export const RecreateButton: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [showRecreate, setShowRecreate] = useState(false);
  const { handleOpenDialog } = useDialog(AuthDialogs.SIGN_IN);
  const { isReplay } = useGetCaseBattleGameReplayData();
  const {
    user: { id: userId },
  } = useUser();
  const { data, dataFinish } = useGetCaseBattleGameData();
  const { game, gameStatus, currentRound } = data;
  const {
    total_price,
    is_private,
    players_count,
    winners_count,
    settings,
    players_in_team,
    cases,
  } = game;
  const {
    game: { id, rounds: finishRounds },
  } = dataFinish;

  const handleRedirect = () => {
    if (userId) {
      dispatch(
        actionCreateGame({
          caseIds: cases,
          double: players_in_team > 1,
          isPrivate: is_private,
          playersCount: players_count,
          winnersCount: winners_count,
          winnersSplit: settings.winnersSplit,
          jokerMode: settings.jokerMode,
        })
      );
      dispatch(removeAllCases());
    } else {
      handleOpenDialog();
    }
  };

  const handleReplay = () => {
    dispatch(actionCaseBattleReplay({ isReplay: true }));
  };

  useEffect(() => {
    setShowRecreate(gameStatus === GameStatus.finish && !isReplay);
  }, [gameStatus, isReplay]);

  useEffect(() => {
    let timerRounds: null | number;
    let timerFinish: null | number;

    if (cases.length === currentRound) {
      clearInterval(timerRounds);

      timerFinish = setTimeout(() => {
        dispatch(actionBattleFinishReplay(id));
      }, 4000);
    }

    const nextRound = currentRound + 1;

    timerRounds = setInterval(() => {
      if (isReplay && cases.length >= nextRound) {
        const rounds = getCurrentRounds(finishRounds, nextRound);
        dispatch(actionBattleRollReplay(rounds));
      }
    }, 4000);

    return () => {
      clearInterval(timerRounds);
      clearTimeout(timerFinish);
    };
  }, [cases.length, currentRound, dispatch, finishRounds, id, isReplay]);

  const redirect = () => {
    if (history.length <= 2) {
      history.push(`..${Modules.CASE_BATTLE}`);
    } else {
      history.goBack();
    }
  };

  return (
    showRecreate && (
      <Container isUp={showRecreate}>
        <ButtonContainer className="tablet">
          <ReplayWrapper>
            <ReplayButton>
              <CaseBattleIcon.ReplayIcon onClick={handleReplay} />
            </ReplayButton>
            <StyledButton onClick={handleRedirect} isJoker={settings.jokerMode}>
              {userId ? (
                <>
                  {t('recreateGame')}
                  <CurrencyText value={total_price} />
                </>
              ) : (
                t('loginAndLuck')
              )}
            </StyledButton>
          </ReplayWrapper>
          <BackBtn className="secondary" onClick={redirect}>
            {t('back')}
          </BackBtn>
        </ButtonContainer>
      </Container>
    )
  );
};
