import { IShopResponse, MergeType } from './shop.reducers.interface';
import { IResetFilters, IUpdateFilters } from './filters.actions.interface';

export enum ShopActionTypes {
  FETCH_SHOP_ITEMS = 'app/exchange/FETCH_SHOP_ITEMS',
  FETCH_SHOP_ITEMS_SUCCESS = 'app/exchange/FETCH_SHOP_ITEMS_SUCCESS',
  FETCH_SHOP_ITEMS_FAILURE = 'app/exchange/FETCH_SHOP_ITEMS_FAILURE',
}

export interface IFetchShop {
  type: typeof ShopActionTypes.FETCH_SHOP_ITEMS;
}

export interface IFetchSuccess {
  type: typeof ShopActionTypes.FETCH_SHOP_ITEMS_SUCCESS;
  payload: IShopResponse & { merge: MergeType };
}

export interface IFetchShopFailure {
  type: typeof ShopActionTypes.FETCH_SHOP_ITEMS_FAILURE;
}

export type shopActions =
  | IFetchShop
  | IFetchSuccess
  | IFetchShopFailure
  | IUpdateFilters
  | IResetFilters;
