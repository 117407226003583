import React, { FC, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { RaffleHistoryPage, TournamentHistoryPage } from './Pages';
import { RaffleStack, StoreStack, TournamentStack } from './StackNavigator';
import { RaffleGame } from './RaffleGame';
import { LobbyPage } from '../components/lobby';
import { fetchAllRaffles } from '../ducks/raffles.duck';
import { fetchRaces } from '../ducks/tournaments.duck';
import { InviteModal, PurchaseModal } from '../components/modals';
import { LandingNext } from '../components/landing-next';

export const LeadersRouter: FC<RouteComponentProps> = React.memo(({ match, location }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRaces());
    dispatch(fetchAllRaffles());
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route
          path={`${match.url}/tournament/history/:raceType`}
          component={TournamentHistoryPage}
        />
        <Route path={`${match.url}/tournament`} component={TournamentStack} />
        <Route
          path={`${match.url}/raffles/history/:raffleType/:raffleId`}
          component={RaffleHistoryPage}
        />
        <Route path={`${match.url}/raffles/active/:raffleType`} component={RaffleGame} />
        <Route path={`${match.url}/raffles`} component={RaffleStack} />
        <Route path={`${match.url}/store`} component={StoreStack} />
        <Route path={`${match.url}/landing`} component={LandingNext} />
        <Route path={match.url} component={LobbyPage} />
        <Redirect to={{ pathname: match.url, state: { from: location } }} />
      </Switch>
      <InviteModal />
      <PurchaseModal />
      <div id="portal-leaders" />
    </>
  );
});
