import * as React from 'react';
import HistoryItem from './HistoryItem';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

class History extends React.PureComponent {
  render() {
    const { history, t } = this.props;
    return (
      <HistoryContainer className="tablet">
        <HistoryTitle>
          {t('History')}
          {':'}
        </HistoryTitle>
        {history.map((item, i) => (
          <HistoryItem key={`${item.coef}-${i}`} coef={item.coef} />
        ))}
      </HistoryContainer>
    );
  }
}

export default withTranslation()(History);

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  padding: 12px 0 10px 20px;

  background-color: #161e21;

  overflow: hidden;
`;

const HistoryTitle = styled.span`
  font-size: 14px;
  color: var(--color-white);
  text-transform: uppercase;
  letter-spacing: 0.04px;
  line-height: 1.3;
`;
