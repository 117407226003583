export type ModuleName =
  | 'hilo'
  | 'classic'
  | 'fast'
  | 'double'
  | 'poggi'
  | 'baccarat'
  | 'x50'
  | 'crash'
  | 'slot'
  | 'tower'
  | 'wheel'
  | 'cases'
  | 'caseBattle';

export const LobbyGameColorDictionary: Record<ModuleName, string> = {
  hilo: 'background: linear-gradient(162deg, #3D2B0E 4.29%, #1D194A 87.72%);',
  crash: 'background: linear-gradient(135deg, #312757 0%, #112850 100%);',
  classic: 'background: linear-gradient(180deg, #554919 0%, #554919 100%);',
  baccarat: 'background: linear-gradient(135deg, #0C2F31 0%, #152E11 48.92%, #331E15 100%);',
  cases: 'background: linear-gradient(180deg, #554919 0%, #554919 100%);',
  double: 'background: linear-gradient(162deg, #340B54 4.29%, #201A63 87.72%);',
  fast: 'background: #541C15;',
  poggi: 'background: linear-gradient(142deg, #302C25 0%, #282520 80.19%);',
  slot: 'background: linear-gradient(61deg, #4E4223 7.25%, #272729 91.4%);',
  tower: 'background: linear-gradient(98deg, #0B2641 6.2%, #0B2641 95.56%);',
  wheel: 'background: linear-gradient(137deg, #381E26 5.45%, #11373C 96.35%);',
  x50: 'background: linear-gradient(142deg, #3D290F 0%, #3D1C0E 80.19%);',
  caseBattle: 'background: linear-gradient(98deg, #3C3113 6.2%, #341617 95.56%);',
};
