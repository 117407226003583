import React from 'react';
import { ArrowContainer, Container } from './CaseLineItem.styles';
import { CaseListItemDefault } from '@components/CaseListItemDefault';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { GameStatus } from 'games/CaseBattle/constants';
import { useGetCasesByIds } from 'games/CaseBattle/hooks';
import { useDispatch } from 'react-redux';
import { CaseDetailsConfig } from 'games/CaseBattle/dialog-config';
import { useDialog } from 'core/hooks';
import { actionRequestSuitCase } from 'games/CaseGame';

interface CaseLineItem {
  id: number;
  isActive: boolean;
  width?: number;
  height?: number;
  status: GameStatus;
}

export const CaseLineItem: React.FC<CaseLineItem> = ({
  id,
  isActive,
  width = 44,
  height = 48,
  status,
}) => {
  const { isLoading, caseData } = useGetCasesByIds(id);
  const dispatch = useDispatch();
  const { handleOpenDialog } = useDialog(CaseDetailsConfig.CASE_DETAIL);

  const redirectHandler = () => {
    dispatch(actionRequestSuitCase(id));
    handleOpenDialog();
  };

  if (isLoading || !caseData) {
    return <Container className="skeleton" />;
  }

  return (
    <Container onClick={redirectHandler}>
      <CaseListItemDefault
        caseType={caseData.type}
        style={{ width: `${width}px`, height: `${height}px` }}
        free={!!caseData?.free}
        image={
          caseData?.free ? caseData.image ?? caseData?.backgroundImage : caseData?.backgroundImage
        }
        price={caseData.lastRevision.price}
        isActive={isActive}
        status={status}
      />
      {status === GameStatus.roll && (
        <ArrowContainer
          data-status={isActive ? 'active' : 'inactive'}
          width={width}
          height={height}
        >
          <CaseBattleIcon.ArrowIcon className="arrow" />
          <CaseBattleIcon.ArrowIcon className="arrow arrow-down-yellow" />
        </ArrowContainer>
      )}
    </Container>
  );
};
