export default {
  //CryptoPage title
  crypto: 'Withdrawal Cryptocurrency',
  cryptocurrency: 'Cryptocurrency',
  from: 'from',
  //
  'Email is required': 'Email is required',
  'Add an email': 'Add an email',
  'email-form-status_confirmed': 'Confirmed',
  'email-form-status_not confirmed': 'Waiting for confirm',
  'email-form-status_empty': 'Not confirmed',
  Withdraw: 'Withdraw',
  'Add a card': 'Add a card',
  'Choose a card:': 'Choose a card:',
  'Withdrawal amount:': 'Withdrawal amount:',
  'I confirm the accuracy of the details and agree with the amount of payment':
    'I confirm the accuracy of the details and agree with the amount of payment',
  'Activation link was sent to your email': 'Activation link was sent to your email',
  'Send an email with a link': 'Send an email with a link',
  'To complete the withdrawal, you have to add an email to which you will receive a confirmation link':
    'To complete the withdrawal, you have to add an email to which you will receive a confirmation link',
  'Almost done!': 'Almost done!',
  'Email with a link to complete the withdrawal has been sent to {{ address }}':
    'Email with a link to complete the withdrawal has been sent to {{ address }}',
  'Please note that the amount required for withdrawal should remain on your balance until the withdrawal is confirmed':
    'Please note that the amount required for withdrawal should remain on your balance until the withdrawal is confirmed.',
  'If at the time of confirmation you do not have the required amount on the balance - the withdrawal will be canceled':
    'If at the time of confirmation you do not have the required amount on the balance - the withdrawal will be canceled.',
  'You will receive:': 'You`ll get:',
  'not confirmed': 'Waiting for confirm...',
  'Select payment provider:': 'Select payment provider:',
  confirmed: 'Attached',
  Refresh: 'Refresh',
  'Withdrawal sum': 'Withdrawal sum',
  'Select the network fee': 'Select the network fee',
  'Estimated transfer time:': 'Estimated transfer time:',
  'Amount in {{title}}:': 'Amount in {{title}}:',
  'Commission:': 'Commission:',
  'Receive in {{title}}:': 'Receive in {{title}}:',
  'No commission': 'No commission',
  commission: 'Possible fee',
  'Enter your wallet': '{{wallet}} wallet',
  'wallet description': 'Enter your {{wallet}} wallet',
  'wallet incorrect': 'Incorrect wallet number',
  // credit-card
  card_title: 'Card number',
  card_type: 'number',
  card_description: '',
  card_incorrect: 'Incorrect card number',
  //qiwi
  qiwi_title: 'QIWI wallet number',
  qiwi_type: 'number',
  qiwi_description: 'Enter your QIWI wallet without the + sign',
  qiwi_incorrect: 'Incorrect QIWI wallet number',
  //wmz
  wmz_title: 'Wallet Number (WMZ)',
  wmz_type: 'string',
  wmz_description: 'Without the letter Z',
  wmz_incorrect: 'Incorrect WMZ wallet number',

  //yandex
  yandex_title: 'Yandex.Money Wallet Number',
  yandex_type: 'number',
  yandex_description: '',
  yandex_incorrect: 'Incorrect Yandex.Money wallet number',

  // skrill
  skrill_eur_title: 'Email related to Skrill account',
  skrill_eur_type: 'text',
  skrill_eur_description: 'Enter your email related to skrill',
  skrill_eur_incorrect: 'Incorrect email.',

  skrill_usd_title: 'Email related to Skrill account',
  skrill_usd_type: 'text',
  skrill_usd_description: 'Enter your email related to skrill',
  skrill_usd_incorrect: 'Incorrect email.',

  // steam
  steam_title: 'Steam username',
  steam_type: 'text',
  steam_description: 'The one you enter on Steam website to log in',
  steam_incorrect: 'Incorrect Steam username.',

  // neteller
  neteller_title: 'Email related to Neteller account',
  neteller_type: 'text',
  neteller_description: 'Enter your email related to neteller',
  neteller_incorrect: 'Incorrect email.',

  // btc
  btc_title: 'Bitcoin wallet address',
  btc_type: 'text',
  btc_description: '',
  btc_incorrect: 'Incorrect btc wallet number',

  bitcoin_title: 'Bitcoin wallet address',
  bitcoin_type: 'text',
  bitcoin_description: '',
  bitcoin_incorrect: 'Incorrect Bitcoin wallet address',

  doge_title: 'Doge wallet address',
  doge_type: 'text',
  doge_description: '',
  doge_incorrect: 'Incorrect Doge wallet address',

  eth_title: 'Ethereum wallet address',
  eth_type: 'text',
  eth_description: '',
  eth_incorrect: 'Incorrect Ethereum wallet address',

  // btc
  phone_title: 'Phone number',
  phone_type: 'number',
  phone_description: '',
  phone_incorrect: 'Phone number not correct',

  // ethereum
  ethereum_title: 'Ethereum wallet',
  ethereum_type: 'text',
  ethereum_description: '',
  ethereum_incorrect: 'Incorrect ethereum wallet number',

  // pix
  accountType: 'Account type',
  documentType: 'Document type',
  document: 'Document',
  taxAccount: 'Tax account',
  fullName: 'Full name',
  emailAddress: 'Email address',
  incorrectEmail: 'Incorrect email',

  'identification title_success': 'Identification required',
  'identification title_verified': 'Identification required',
  'identification title_pending': 'Identification request sent',
  'identification title_failed': 'Identification failed',

  'identification description_success': 'To withdraw funds you need to confirm your identity',
  'identification description_verified': 'To withdraw funds you need to confirm your identity',
  'identification description_pending':
    'Your data is being verified. Please wait for our support to verify your data',
  'identification description_failed': 'Reasons for refusal has been sent to your email',
};
