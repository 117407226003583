import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { FactorTypes, profitFactorType } from '../../interfaces';
import { useFactorController } from '../../hooks';

interface IFactorControl {
  type: FactorTypes;
  factor: string;
  currentAmount?: number;
  onClick?: () => void;
}

export const FactorControl: FC<IFactorControl> = ({
  type,
  factor,
  currentAmount,
  onClick = () => undefined,
}) => {
  const { factorType, player, previousFactorType, protectFactor } = useFactorController(type);

  return (
    <FactorButton
      onClick={onClick}
      className={`factor-${factor}`}
      factorType={type}
      highlight={!!player}
      loseFactor={previousFactorType?.isWin}
      disabled={typeof factorType?.probability === 'boolean' || protectFactor}
    >
      <span className="factor-type">{factor}</span>
      {factorType?.probability && (
        <span className="factor-probability">{factorType?.probability}%</span>
      )}
      <span className="factor-amount">
        {player?.amount && <CurrencyText value={player.amount} />}
        {!!currentAmount && (
          <span className="factor-amount_current">
            <CurrencyText value={currentAmount} />
          </span>
        )}
      </span>
      <span className="factor-profit">x{factorType?.factor || profitFactorType[factor]}</span>
    </FactorButton>
  );
};

interface IFactorButton {
  factorType: string;
  loseFactor: boolean;
  highlight: boolean;
}

const FactorButton = styled.button<IFactorButton>`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.45rem;

  border: 2px solid ${p => `var(--hilo-factor-${p.factorType})`};
  border-radius: var(--size-radius-s);

  position: relative;
  z-index: 10;

  overflow: hidden;
  background: transparent;
  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  & .factor-type {
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.084px;
    color: var(--color-white);
    text-transform: uppercase;
  }

  & .factor-probability {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.4;
    color: var(--color-white);
    opacity: 0.5;
  }

  & .factor-amount {
    min-height: 1rem;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1.4;
    letter-spacing: 0.084px;
    color: var(--color-white);

    &_current {
      font-weight: inherit;
      color: var(--color-green);
      margin-left: 0.25rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  & .factor-profit {
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: 0.084px;
    color: var(--color-white);
  }

  &:before {
    content: '';
    border-radius: inherit;
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    right: -2px;
    backdrop-filter: blur(4px);
    z-index: -3;
    background: ${p => `var(--hilo-factor-${p.factorType})`};
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.2;
  }

  ${p =>
    p.highlight &&
    `
    &:before {
      opacity: 1;
    }
  `}

  ${p =>
    p.loseFactor === false &&
    `
      opacity: 0.2; 
  `};
`;
