import { ActionsObservable, Epic, StateObservable, ofType } from 'redux-observable';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import {
  ActionGetMyCaseBattleList,
  ActionRequestCaseBattleList,
  ActionRequestCaseBattleListHistory,
  ActionRequestCaseMyBattleListHistory,
  BattleJoinEventData,
  BattleNewEventData,
  CaseBattleListActions,
  CaseBattleListReducer,
  GameData,
  ResponseBattleFinish,
  ResponseCaseBattleList,
  EmojiSocket,
} from './case-battle.interfaces';
import { catchError, map, switchMap } from 'rxjs/operators';
import CaseBattleRepository from 'games/CaseBattle/repository/caseBattleRepository';
import { iif, of } from 'rxjs';
import { addNotification } from 'modules/Notifications/duck';
import { AsyncAction, IStore } from 'store/interface';
import { Record } from 'immutable';
import { Reducer } from 'redux';
import { GameStatus, SortParam } from 'games/CaseBattle/constants';
import { IDispatch } from 'core/rootInterfaces/root.interface';
import { getUser } from 'core/User';
import { Round } from 'games/CaseBattle/interfaces';
import { EmojisFinish } from '../case-battle-game-duck/case-battle-game.interfaces';

export const actionRequestCaseBattleList = (params: SortParam) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE_LIST,
  payload: params,
});

const actionResponseCaseBattleList = (response: ResponseCaseBattleList, userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE_LIST,
  payload: { data: response, userId },
});

const actionAddNewBattle = (data: BattleNewEventData, userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ADD_NEW_BATTLE,
  payload: { data, userId },
});

const asyncActionAddNewBattle = (data: BattleNewEventData): AsyncAction => (
  dispatch: IDispatch,
  getState: () => IStore
) => {
  const user = getUser(getState());
  dispatch(actionAddNewBattle(data, user.id));
  dispatch(actionRemoveElement());
};

const actionResponseBattleRoll = (data: Round[], userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL,
  payload: {
    data,
    userId,
  },
});

const actionBattleRoll = (data: Round[]) => (dispatch: IDispatch, getState: () => IStore) => {
  const user = getUser(getState());

  dispatch(actionResponseBattleRoll(data, user.id));
};

const actionResponseBattleRollReplay = (data: Round[], userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL_REPLAY,
  payload: {
    data,
    userId,
  },
});

export const actionBattleRollReplay = (data: Round[]) => (
  dispatch: IDispatch,
  getState: () => IStore
) => {
  const user = getUser(getState());
  dispatch(actionResponseBattleRollReplay(data, user.id));
};

const actionResponseBattleFinish = (data: ResponseBattleFinish, userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_FINISH,
  payload: {
    data,
    userId,
  },
});

const actionResponseBattleFinishReplay = (battleId: number, userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_FINISH_REPLAY,
  payload: {
    battleId,
    userId,
  },
});

const actionJoinBattle = (data: BattleJoinEventData) => (
  dispatch: IDispatch,
  getState: () => IStore
) => {
  const user = getUser(getState());
  dispatch(actionResponseJoinBattle(data, user.id));
};

const actionResponseJoinBattle = (data: BattleJoinEventData, userId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_JOIN_NEW_BATTLE,
  payload: {
    data,
    userId,
  },
});

const actionBattleFinish = (data: ResponseBattleFinish) => (
  dispatch: IDispatch,
  getState: () => IStore
) => {
  const user = getUser(getState());
  dispatch(actionResponseBattleFinish(data, user.id));
};

export const actionBattleFinishReplay = (battleId: number) => (
  dispatch: IDispatch,
  getState: () => IStore
) => {
  const user = getUser(getState());
  dispatch(actionResponseBattleFinishReplay(battleId, user.id));
};

const actionResponseEmojiSocket = (data: EmojiSocket) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJI_SOCKET,
  payload: data,
});

const actionEmojiSocket = (data: EmojiSocket) => (dispatch: IDispatch) => {
  dispatch(actionResponseEmojiSocket(data));
};

const actionResponseEmojisSocket = (data: EmojisFinish) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJIS_SOCKET,
  payload: data,
});

const actionEmojisSocket = (data: EmojisFinish) => (dispatch: IDispatch) => {
  dispatch(actionResponseEmojisSocket(data));
};

export const actionRequestCaseBattleListHistory = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE_LIST_HISTORY_MP,
});

export const actionRequestCaseMyBattleListHistory = ({
  force,
  limit,
  offset,
  sortBy,
  user,
  isLoadMore,
}: {
  force: boolean;
  limit: number;
  offset: number;
  sortBy: string;
  user: boolean;
  isLoadMore?: boolean;
}) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_MP,
  payload: {
    force,
    limit,
    offset,
    sortBy,
    user,
    isLoadMore,
  },
});

const actionResponseCaseBattleListHistory = (data: ResponseCaseBattleList) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE_LIST_HISTORY_MP,
  payload: data,
});

const actionResponseCaseMyBattleListHistory = (data: ResponseCaseBattleList) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_MP,
  payload: data,
});

const actionResponseCaseMyBattleListHistoryNew = (data: ResponseCaseBattleList) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_NEW_MP,
  payload: data,
});

const actionRemoveElement = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ELEMENT,
});

export const actionGetMyCaseBattleList = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_MY_CASE_BATTLE_LIST,
});
export const actionResponseMyCaseBattleList = (data: ResponseCaseBattleList) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_MY_CASE_BATTLE_LIST,
  payload: data,
});
//NOTE: need to check is this actions is useful (actionGetMyCaseBattleList, actionResponseMyCaseBattleList)

export const caseBattleListEpic: Epic = (
  action$: ActionsObservable<ActionRequestCaseBattleList>,
  state$: StateObservable<IStore>
) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE_LIST),
    switchMap(({ payload }) =>
      CaseBattleRepository.getCaseBattleList(payload).pipe(
        map(({ response }: { response: ResponseCaseBattleList }) =>
          actionResponseCaseBattleList(response, state$.value.user.base.get('id'))
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );

export const caseBattleListHistoryMPEpic: Epic = (
  $action: ActionsObservable<ActionRequestCaseBattleListHistory>
) =>
  $action.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE_LIST_HISTORY_MP),
    switchMap(() =>
      CaseBattleRepository.getCaseBattleHistory().pipe(
        map(({ response }: { response: ResponseCaseBattleList }) =>
          actionResponseCaseBattleListHistory(response)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );

export const caseMyBattleListHistoryMPEpic: Epic = (
  $action: ActionsObservable<ActionRequestCaseMyBattleListHistory>
) =>
  $action.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_MP),
    switchMap(({ payload }) =>
      CaseBattleRepository.getCaseBattleHistory(payload).pipe(
        switchMap(({ response }: { response: ResponseCaseBattleList }) =>
          iif(
            () => payload.isLoadMore,
            of(actionResponseCaseMyBattleListHistoryNew(response)),
            of(actionResponseCaseMyBattleListHistory(response))
          )
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );

export const myCaseBattleList: Epic = ($action: ActionsObservable<ActionGetMyCaseBattleList>) =>
  $action.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_MY_CASE_BATTLE_LIST),
    switchMap(() =>
      CaseBattleRepository.getMyCaseBattleList().pipe(
        map(({ response }: { response: ResponseCaseBattleList }) =>
          actionResponseMyCaseBattleList(response)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );
//NOTE: need to check is this epic is useful
export const InitState = Record({
  data: {
    games: {},
    idOrder: [],
    success: false,
  },
  dataHistory: {
    games: {},
    idOrder: [],
    success: false,
  },
  dataMyHistory: {
    games: {},
    idOrder: [],
    success: false,
    isLimit: false,
  },
  dataMyCaseBattleList: {
    games: {},
    idOrder: [],
    success: false,
  },
  isLoadingMyBattle: true,
  isLoadingHistory: true,
  isLoadingMyHistory: true,
  isLoading: true,
});

export const caseBattleListReducer: Reducer<
  Record<CaseBattleListReducer>,
  CaseBattleListActions
> = (state = new InitState(), action) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE_LIST: {
      return state.set('isLoading', true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_MY_CASE_BATTLE_LIST: {
      return state.set('isLoadingMyBattle', true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE_LIST_HISTORY_MP: {
      return state.set('isLoadingHistory', true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_MP: {
      return state.set('isLoadingMyHistory', true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE_LIST: {
      const data: { [id: number]: GameData } = {};
      const myCaseBattleData: { [id: number]: GameData } = {};

      const order = action.payload.data.games
        .filter(({ id }) => id !== action.payload.userId)
        .map(({ id }) => id);

      const myCaseBattleIdOrder = action.payload.data.games
        .filter(({ id }) => id === action.payload.userId)
        .map(({ id }) => id);

      for (const value of action.payload.data.games) {
        if (value.creator_id !== action.payload.userId) {
          data[value.id] = {
            ...value,
            isPossibleToShow: true,
            currentRound: null,
            gameStatus: GameStatus.waiting,
          };
        } else {
          myCaseBattleData[value.id] = {
            ...value,
            isPossibleToShow: true,
            currentRound: null,
            gameStatus: GameStatus.waiting,
          };
        }
      }

      //NOTE: don`t touch for .. of , it works faster than reduce at 3,5 times

      return state
        .setIn(['data', 'idOrder'], order)
        .setIn(['dataMyCaseBattleList', 'games'], myCaseBattleData)
        .setIn(['dataMyCaseBattleList', 'idOrder'], myCaseBattleIdOrder)
        .setIn(['data', 'games'], data)
        .setIn(['data', 'success'], action.payload.data.success)
        .set('isLoading', false)
        .set('isLoadingMyBattle', false);
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE_LIST_HISTORY_MP: {
      const necessaryLengthOfHistoryItem = 10 - state.getIn(['data', 'idOrder'])?.length ?? 0;
      if (necessaryLengthOfHistoryItem > 0) {
        const data: { [id: number]: GameData } = {};
        const finishedGames = action.payload.games.slice(0, necessaryLengthOfHistoryItem);
        //NOTE: slice only needed history games

        const order = finishedGames.map(({ id }) => id);
        //NOTE: add ids order, for correct sort working
        for (const value of finishedGames) {
          data[value.id] = {
            ...value,
            isPossibleToShow: true,
            currentRound: null,
            gameStatus: GameStatus.finish,
          };
        }
        //NOTE: don`t touch for .. of , it works faster than reduce at 3,5 times

        return state
          .setIn(['dataHistory', 'idOrder'], order)
          .set('isLoadingHistory', false)
          .setIn(['dataHistory', 'games'], data);
      }
      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_MP: {
      const REQUEST_LIMIT = 20;
      const data: {
        [id: number]: GameData;
      } = {};
      const games = action.payload.games;

      const order = games.map(({ id }) => id);

      for (const value of games) {
        data[value.id] = {
          ...value,
          isPossibleToShow: true,
          currentRound: null,
          gameStatus: GameStatus.finish,
        };
      }

      return state
        .setIn(['dataMyHistory', 'idOrder'], order)
        .set('isLoadingMyHistory', false)
        .setIn(['dataMyHistory', 'games'], data)
        .setIn(['dataMyHistory', 'success'], action.payload.success)
        .setIn(['dataMyHistory', 'isLimit'], action.payload.games.length < REQUEST_LIMIT);
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_NEW_MP: {
      const REQUEST_LIMIT = 10;
      const data: {
        [id: number]: GameData;
      } = {};
      const games = action.payload.games;

      const order = games.map(({ id }) => id);

      for (const value of games) {
        data[value.id] = {
          ...value,
          isPossibleToShow: true,
          currentRound: null,
          gameStatus: GameStatus.finish,
        };
      }

      return state
        .mergeIn(['dataMyHistory', 'idOrder'], order)
        .set('isLoadingMyHistory', false)
        .mergeIn(['dataMyHistory', 'games'], data)
        .setIn(['dataMyHistory', 'success'], action.payload.success)
        .setIn(['dataMyHistory', 'isLimit'], action.payload.games.length < REQUEST_LIMIT);
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_MY_CASE_BATTLE_LIST: {
      const data: { [id: number]: GameData } = {};
      const order = action.payload.games.map(({ id }) => id);

      for (const value of action.payload.games) {
        data[value.id] = {
          ...value,
          isPossibleToShow: true,
          currentRound: null,
          gameStatus: GameStatus.waiting,
        };
      }
      //NOTE: don`t touch for .. of , it works faster than reduce at 3,5 times
      return state
        .setIn(['dataMyCaseBattleList', 'games'], data)
        .setIn(['dataMyCaseBattleList', 'idOrder'], order)
        .set('isLoadingMyBattle', false);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_ADD_NEW_BATTLE: {
      const dataObj: Partial<GameData> = {
        id: action.payload.data.battleId,
        cases_count: action.payload.data.casesCount,
        players_in_team: action.payload.data.playersInTeam,
        players_count: action.payload.data.playersCount,
        cases: action.payload.data.cases.map(({ id }) => id),
        settings: action.payload.data.settings,
        total_price: action.payload.data.totalPrice,
      };

      const keyType =
        action.payload.data.userId !== action.payload.userId ? 'data' : 'dataMyCaseBattleList';

      return state
        .mergeIn([keyType, 'idOrder'], [action.payload.data.battleId])
        .mergeIn([keyType, 'games', action.payload.data.battleId], dataObj)
        .setIn([keyType, 'games', action.payload.data.battleId, 'isPossibleToShow'], true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_JOIN_NEW_BATTLE: {
      if (
        state.hasIn(['data', 'games', action.payload.data.battleId]) ||
        state.hasIn(['dataMyCaseBattleList', 'games', action.payload.data.battleId])
      ) {
        const type = state.hasIn(['data', 'games', action.payload.data.battleId])
          ? 'data'
          : 'dataMyCaseBattleList';

        const countActivePlayer = state.get(type).games[action.payload.data.battleId]?.players
          .length;
        const maxPlayersInGame = state.getIn([
          type,
          'games',
          action.payload.data.battleId,
          'players_count',
        ]);

        return countActivePlayer > 0
          ? state
              .mergeDeepIn(
                [type, 'games', action.payload.data.battleId, 'players'],
                [action.payload.data.player]
              )
              .setIn(
                [type, 'games', action.payload.data.battleId, 'gameStatus'],
                maxPlayersInGame === countActivePlayer + 1 ? GameStatus.start : GameStatus.waiting
              )
          : state.setIn(
              [type, 'games', action.payload.data.battleId, 'players'],
              [action.payload.data.player]
            );

        //NOTE: there i set new players , and game status,
        //NOTE: countActivePlayer + 1  because we don`t set new user, but he is already in game
      } else {
        const data: { [id: number]: Partial<GameData> } = {
          [action.payload.data.battleId]: {
            id: action.payload.data.battleId,
            players: [action.payload.data.player],
            creator_id: action.payload.data.user_id,
            isPossibleToShow: false,
            currentRound: null,
            gameStatus: GameStatus.waiting,
          },
        };

        const keyType =
          action.payload.data.user_id !== action.payload.userId ? 'data' : 'dataMyCaseBattleList';

        return state.mergeDeepIn([keyType, 'games'], data);
      }
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL: {
      if (state.getIn(['data', 'games', action.payload.data[0].battleId])) {
        return state
          .setIn(
            ['data', 'games', action.payload.data[0].battleId, 'currentRound'],
            action.payload.data[0].roundNumber
          )
          .setIn(['data', 'games', action.payload.data[0].battleId, 'gameStatus'], GameStatus.roll);
      }
      if (state.getIn(['dataMyCaseBattleList', 'games', action.payload.data[0].battleId])) {
        return state
          .setIn(
            ['dataMyCaseBattleList', 'games', action.payload.data[0].battleId, 'currentRound'],
            action.payload.data[0].roundNumber
          )
          .setIn(
            ['dataMyCaseBattleList', 'games', action.payload.data[0].battleId, 'gameStatus'],
            GameStatus.roll
          );
      }
      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_FINISH: {
      if (
        state.get('data').games[action.payload.data.battleId] ||
        state.get('dataMyCaseBattleList').games[action.payload.data.battleId]
      ) {
        const keyType =
          action.payload.data.battle.creator_id !== action.payload.userId
            ? 'data'
            : 'dataMyCaseBattleList';

        const idOrderArrayForHistory = [
          action.payload.data.battleId,
          ...(state.getIn(['dataHistory', 'idOrder']) as number[]),
        ];

        const idOrderArray = (state.getIn([keyType, 'idOrder']) as number[]).filter(
          value => value !== action.payload.data.battleId
        );

        return state
          .setIn([keyType, 'idOrder'], idOrderArray)
          .setIn(['dataHistory', 'idOrder'], idOrderArrayForHistory)
          .mergeIn(['dataHistory', 'games'], {
            [action.payload.data.battleId]: state.getIn([
              keyType,
              'games',
              action.payload.data.battleId,
            ]),
          })
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'winner_ids'],
            action.payload.data.winnerIds
          )
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'total_winning'],
            action.payload.data.totalWinning
          )
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'gameStatus'],
            GameStatus.finish
          )
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'is_private'],
            action.payload.data.battle.is_private
          )
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'settings', 'jokerMode'],
            action.payload.data.battle.settings.jokerMode
          )
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'winners_count'],
            action.payload.data.battle.winners_count
          )
          .setIn(
            ['dataHistory', 'games', action.payload.data.battleId, 'settings', 'winnersSplit'],
            action.payload.data.battle.settings.winnersSplit
          )
          .removeIn([keyType, 'games', action.payload.data.battleId]);
        //NOTE: moving finish game to history
      }
      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ELEMENT: {
      const length = state.getIn(['dataHistory', 'idOrder'])?.length ?? 0;
      if (length > 0) {
        const deleteItemId = state.getIn(['dataHistory', 'idOrder'])[length - 1];
        return state
          .deleteIn(['dataHistory', 'idOrder', length - 1])
          .removeIn(['dataHistory', 'games', deleteItemId]);
        //NOTE: logic for deleting extra games
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export const eventsTypes = [
  { event: 'battle:new', action: asyncActionAddNewBattle },
  { event: 'battle:join', action: actionJoinBattle },
  { event: 'battle:roll', action: actionBattleRoll },
  { event: 'battle:finish', action: actionBattleFinish },
  { event: 'battle:new-emoji', action: actionEmojiSocket },
  { event: 'battle:emoji', action: actionEmojisSocket },
];

//NOTE: ask PM for notion link, if smth went wrong with events
