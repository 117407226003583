import { reducer } from './duck';

export { GameRouter as DoubleRouter } from './components';
// export const DoubleRouter = lazy(() => import('./components').then(module => ({ default: module.GameRouter })))

export const doubleModule = {
  moduleName: 'double',
  moduleId: 3,
  path: '/double',
  type: 'pve',
  reducer,
};
