import React from 'react';
import { ReactComponent as CashbackIcon } from './cashback.svg';
import { ReactComponent as DepositIcon } from './deposit.svg';
import { ReactComponent as FreeCoinsIcon } from './freeCoins.svg';
import { ReactComponent as LevelUpIcon } from './levelUp.svg';
import { ReactComponent as ReferralIcon } from './referral.svg';
import { ReactComponent as SkinsIcon } from './skins.svg';

export const TransactionsIcons = {
  cashback: (width: number = 44, height: number = 44) => (
    <CashbackIcon width={width} height={height} />
  ),
  deposit: (width: number = 44, height: number = 44) => (
    <DepositIcon width={width} height={height} />
  ),
  payment: (width: number = 44, height: number = 44) => (
    <DepositIcon width={width} height={height} />
  ),
  'free coins': (width: number = 44, height: number = 44) => (
    <FreeCoinsIcon width={width} height={height} />
  ),
  'level up': (width: number = 44, height: number = 44) => (
    <LevelUpIcon width={width} height={height} />
  ),
  referral: (width: number = 44, height: number = 44) => (
    <ReferralIcon width={width} height={height} />
  ),
  skins: (width: number = 44, height: number = 44) => <SkinsIcon width={width} height={height} />,
  'skins withdrawal': (width: number = 44, height: number = 44) => (
    <SkinsIcon width={width} height={height} />
  ),
  withdrawal: (width: number = 44, height: number = 44) => (
    <DepositIcon width={width} height={height} />
  ),
};
