/*eslint no-template-curly-in-string: "off"*/
export default {
  Language: 'Language',
  // classic
  'Bets in current room': 'Bets in current room',
  Timers: 'Timers',
  'Game start': 'Game start',
  'Game ending': 'Game ending',
  'Roulette ticking': 'Roulette ticking',
  // fast
  'Item set': 'Item set',
  // double
  'My bet': 'My bet',
  'Roulette start': 'Roulette start',
  'Coins transfer': 'Coins transfer',
  // crash
  'Start button': 'Start button',
  Bet: 'Bet',
  'Crash button': 'Crash',
  'Stop button': 'Stop button',
  Countdown: 'Countdown',
  'Sound track': 'Soundtrack',
  'Count down': 'Count down',
  // craft
  'Picking item': 'Picking item',
  Crafting: 'Crafting',
  Failure: 'Failure',
  Win: 'Win',
  Sell: 'Sell',
  Take: 'Take',
  //baccarat
  'Dispense the cards': 'Dispense the cards',
  'Flip the cards': 'Flip the cards',
  Winning: 'Winnings',
  Loosing: 'Loosing',
  Timer: 'Timer',
  'Game end': 'Game end',
  // slot
  'Spin sounds': 'Spin sounds',
  'Toggle lines': 'Toggle lines',
  // common
  'Rooms bets': 'Rooms bets',
  'Chat message sending': 'Chat message sending',
  'Chat message receiving': 'Chat message receiving',
  'Level up': 'Level up',
  'Next rank': 'Next rank',

  'Chat sound:': 'Chat sound',
  'Game sounds': 'Game sounds',
  Volume: 'Volume',

  en: 'English',
  ru: 'Русский',
  de: 'Deutsch',
  pl: 'Polski',
  ro: 'Română',
  es: 'Español',
  pt: 'Português',
  sv: 'Svenska',
  th: 'ไทย',
  tr: 'Türkçe',
  zh_cn: '汉语',
  zh_tw: '漢語',
  no: 'Norsk Bokmål',

  'Balance account': 'Balance',
  'Transaction history': 'Transaction history',
  'Statistics account': 'Statistics',
  'Referral program account': 'Referral program',
  'Settings account': 'Settings',
  'Freeze input/output funds': 'Freeze input/output funds',
  'Frozen input/output funds': 'Frozen input/output funds',
  'Levels account': 'Levels',
  'To learn more': 'To learn more',
  Balance: 'Balance',
  'Enter a promo code': 'Enter a promo code',
  Trading: 'Market settings',
  'Link Steam account': 'Link Steam account',
  'You need to attach your Steam account to be interact with marketplace':
    'You need to attach your Steam account to be interact with marketplace',
  'Add Steam account': 'Add Steam account',
  Sounds: 'Sounds',
  Privacy: 'Privacy',
  'Add social networks': 'Add social networks',
  'Ready soc': 'Ready ${count} of',
  'Count of': '${count} of',
  Add: 'Add',
  Linked: 'Linked',
  Unbind: 'Unbind',
  'Global sound settings': 'Global sound settings',

  Refill: 'Refill',
  'History is empty': 'History is empty',
  All: 'All',
  'Payment in process': 'Payment in process',
  'Transaction is currently in process, it may take from few seconds up to 72 hours':
    'Transaction is currently in process, it may take from few seconds up to 72 hours',

  'Recent wins:': 'Recent wins:',
  'Total statistics from 09.11.2018': 'Total statistics from 09.11.2018',
  'Total games played': 'Total games played',
  'Total profit': 'Total profit',
  'TOTAL GAMES PLAYED': 'TOTAL GAMES PLAYED',
  'Max bet': 'Max bet',
  'Average bet': 'Average bet',
  'Total skins was betted': 'Total skins was betted',
  'Max profit': 'Max profit',
  'Total profit on black': 'Total profit on "black"',
  'Total profit on red': 'Total profit on "red"',
  'Total profit on joker': 'Total profit on "Joker"',
  'Total catched jokers': 'Total catched "Jokers"',
  'Max win': 'Max win',
  'Max coef': 'Max coef',
  'Total bets on zero': 'Total bets on "zero"',
  'Total bets on red': 'Total bets on "red"',
  'Total bets on black': 'Total bets on "black"',
  'Total bets on gold': 'Total bets on "gold"',
  'Total bets on green': 'Total bets on "green"',
  'Total bets on blue': 'Total bets on "blue"',
  'Total bets on Player Pairs': 'Total bets on Player Pairs',
  'Total bets on Bank Pairs': 'Total bets on Bank Pairs',
  'Total bets on Tie': 'Total bets on Tie',
  'Total bets on Player': 'Total bets on Player',
  'Total bets on Bank': 'Total bets on Bank',
  'Biggest drop': 'Biggest drop',
  'Minimal chance': 'Minimal chance',
  'Bonus winning': 'Bonus winning',
  'Lucky spins': 'Lucky spins',
  PROFIT: 'PROFIT',
  'MAX BET': 'MAX BET',
  'AVERAGE BET': 'AVERAGE BET',
  'TOTAL SKINS WAS BETTED': 'TOTAL SKINS WAS BETTED',
  'TOTAL PROFIT ON BLACK': 'TOTAL PROFIT ON BLACK',
  'TOTAL CATCHED JOKERS': 'TOTAL CATCHED JOKERS',
  'TOTAL PROFIT ON JOKER': 'TOTAL PROFIT ON JOKER',
  'TOTAL PROFIT ON RED': 'TOTAL PROFIT ON RED',
  'MAX WIN': 'MAX WIN',
  'MINIMAL CHANCE': 'MINIMAL CHANCE',
  'BIGGEST DROP': 'BIGGEST DROP',
  'OPENED SILVER CASES': 'OPENED SILVER CASES',
  'OPENED ORANGE CASES': 'OPENED ORANGE CASES',
  'OPENED BLUE CASES': 'OPENED BLUE CASES',
  'BONUS WINNING': 'BONUS WINNING',
  'Game statistics': 'Game statistics',
  Statistics: 'Statistics',
  'TOTAL BETS ON ZERO': 'TOTAL BETS ON ZERO',
  'TOTAL BETS ON RED': 'TOTAL BETS ON RED',
  'TOTAL BETS ON BLACK': 'TOTAL BETS ON BLACK',
  'TOTAL BETS ON GOLD': 'TOTAL BETS ON GOLD',
  'TOTAL BETS ON GREEN': 'TOTAL BETS ON GREEN',
  'TOTAL BETS ON BLUE': 'TOTAL BETS ON BLUE',
  'MAX PROFIT': 'MAX PROFIT',
  'First game': 'First game',
  'Go to the steam profile': 'Go to the steam profile',
  PROFIT_STAT: 'The most profitable mode',
  TOTAL_GAMES_PLAYED_STAT: 'The most played mode',
  WIN_STREAK_STAT: 'Longest winning streak',
  MAX_BET_STAT: 'Largest bet',
  MAX_BET_DATE_STAT: 'Bet placed at',
  MAX_PROFIT_STAT: 'The most luck bet',
  MAX_PROFIT_BET_STAT: 'Bet placed at',
  MAX_WIN_STAT: 'Largest winning',
  MAX_WIN_DATE_STAT: 'Bet placed at',
  'Fast ID': 'Fast ID',
  Done: 'Done',
  'Change account': 'Change account',
  Avatar: 'Avatar',
  'Crop warning': 'The selected area will be used as an avatar',
  'Max size file': 'Invalid file type or large size, use images no more than 5mb',

  'Deposit/Withdrawal': 'Deposit/Withdrawal',
  balance_games: 'Games',
  level: 'level',

  'Referral system': 'Referral system',
  'Boost your revenue!': 'Boost your revenue!',
  // 'Earn with us': 'Earn with us',
  'Create your own advertising campaign Share your referral link and get a percentage of deposits to your balance!':
    'Create your own advertising campaign. Share your referral link and get a percentage of deposits to your balance!',
  Referrals: 'Referrals',
  Payments: 'Payments',
  Paid: 'Paid',
  Profit: 'Profit',
  'Create a campaign': 'Create a campaign',
  'Still have questions? we are': 'Still have questions? we are',
  'Current profit': 'Current profit',
  'Total profit {currency}': 'Total profit ${currency(amount)}',
  'campaign-tab-bar': {
    status_active: 'Active',
    status_deleted: 'Archive',
  },
  'campaign statuses': {
    status_active: 'Activated',
  },
  'member statuses': {
    status_active: 'Active',
    status_lost: 'Lost',
    status_inactive: 'Inactive',
  },
  'referral statuses': {
    status_active: 'Active',
    status_lost: 'Lost',
    status_inactive: 'Inactive',
  },
  'Payments from campaign': 'Payments from campaign',
  withdraw: 'withdraw',
  'Total referrals': 'Total referrals',
  'All campaign referrals': 'All campaign referrals',
  Help: 'Help',
  'Copy referral link': 'copy referral link',
  'Copy promocode': 'Copy promocode',
  Statistic: 'Statistic',
  Transfers: 'Transfers',
  periods: {
    period_today: 'Day',
    period_week: 'Week',
    period_month: 'Month',
    period_year: 'Year',
  },
  ratings: {
    rating_active: 'Top 5 referrals',
    rating_lost: 'Lost',
  },
  'Top 5': 'Top 5',
  'New referrals': 'New',
  Clicks: 'Clicks',
  'Lost referrals': 'Lost',
  referrals: 'Referrals',
  'Campaign name': 'Campaign name',
  'Campaign promocode': 'Campaign promocode',
  'Manage campaign': 'Manage campaign',
  'Delete a campaign': 'Delete a campaign',
  Continue: 'Continue',
  'My promotions ( My advertising campaigns)': 'My promotions ( My advertising campaigns)',
  'There no lost referrals': 'There no lost referrals',
  'There no referrals yet': 'There no referrals yet',
  'But they will be soon': 'But they will be soon',
  'invalid-feedback': {
    minLength: 'Name is too short',
    required: 'Can not be empty',
    checkCode: 'This code can not be used',
  },
  Success: 'Success',
  'Enter the code and get bonuses': 'Enter the code and get bonuses',
  'Referral or promo code': 'Referral or promo code',
  'Activate code': 'Activate code',
  'How it works?': 'How it works?',
  'work steps':
    '<strong>Create a code and link</strong><p>Create a referral campaign, make up a short unique code or use a default code. A referral link will be created together with the code<p/><strong>Invite players to the project</strong><p>Invite players to the project using code or link. Each invited player becomes your referral. If the user is registered on the site for the first time, you both get 10 cents of a welcoming bonus.</p><strong>Receive your reward</strong><p>You will receive a percentage of each bet made by your invited players. More invitees - more income!</p>',
  'Referral statuses': 'Referral statuses',
  'The statistics show the status of your referrals':
    "<strong>The statistics show the status of your referrals:</strong><p>Active - the invited user has already deposited and plays on the project. You get a part of each of his bets.</p><p>Lost - the player has become a referral of another user. You don't get a reward for his bets anymore.</p><p>Inactive - the invited player is registered, but he hasn't deposited his balance or made bets from his real balance yet</p>",
  'What is the difference between code a and a link?':
    'What is the difference between code a and a link?',
  "Use whatever format you want Sharing a link is more convenient, but where you can't leave it, you can use a referral code In both cases, the invited player will become your referral":
    "Use whatever format you want. Sharing a link is more convenient, but where you can't leave it, you can use a referral code. In both cases, the invited player will become your referral",
  'Where should my code be entered?': 'Where should my code be entered?',
  'The code entry field opens by clicking on the Bonuses tab in the menu':
    'The code entry field opens by clicking on the Bonuses tab in the menu',
  'How much money will I get?': 'How much money will I get?',
  "You will get a part of each referral's bet Only bets from the users with Silver status or higher counts (more about statuses)":
    "You will get a part of each referral's bet. Only bets from the users with <1>Silver status or higher counts</1>.",
  'How can I get a reward?': 'How can I get a reward?',
  'Rewards for the referral program can be received on the balance of the site Use it for games or withdraw it in a convenient way':
    'Rewards for the referral program can be received on the balance of the site. Use it for games or withdraw it in a <0> convenient way</0>.',
  'What is the minimum amount for withdraw?': 'What is the minimum amount for withdraw?',
  'There`s no minimum amount for withdraw, you could withdraw any amount starting from 0.01$':
    'There`s no minimum amount for withdraw, you could withdraw any amount starting from 0.01$',
  'Why do I need to create multiple campaigns?': 'Why do I need to create multiple campaigns?',
  'Use different codes and links for each advertising channel to understand which traffic source works better and attract more active players':
    'Use different codes and links for each advertising channel to understand which traffic source works better and attract more active players',
  'What kind of restrictions are there?': 'What kind of restrictions are there?',
  Prohibited:
    'Use the referral program only to attract new and return old players. It is forbidden to reffer active players to your referral code to receive rewards. It is also forbidden to use codes containing brand names, names or nicknames of popular persons (if you are not affiliated with them in any way), create codes with game names, currencies and other keywords in order to predict possible input variants by players who do not have a real code . The service reserves the right to request traffic sources or code placements and refuse to pay out if players were attracted in violation of the rules.',

  // premium types
  bronze: 'Bronze',
  bronze_adjective: 'Bronze',
  bronze_adjective_two: 'Bronze',
  silver: 'Silver',
  silver_adjective: 'Silver',
  silver_adjective_two: 'Silver',
  gold: 'Gold',
  gold_adjective: 'Gold',
  gold_adjective_two: 'Gold',
  demo: 'Demo',
  demo_adjective: 'Demo',
  demo_adjective_two: 'Demo',
  platinum: 'Platinum',
  platinum_adjective: 'Platinum',
  platinum_adjective_two: 'Platinum',
  diamond: 'Diamond',
  diamond_adjective: 'Diamond',
  diamond_adjective_two: 'Diamond',

  // premium
  Subscription: 'Subscription',
  'New horizons of your opportunities': 'New horizons of your opportunities!',
  'account is:': ' account is:',
  account: 'account',
  Trial: 'Trial',
  LIMIT: 'LIMIT',
  UNLIMITED: 'UNLIMITED',
  'Max bet amount': 'Max. bet amount',
  'Unlimited balance': 'Unlimited balance',
  'Access to all games': 'Access to all games',
  'Experience set bonus': 'Experience set bonus',
  'Access to jackpot': 'Access to jackpot',
  'Access to Leaders Race': 'Access to Leaders Race',
  Giveaways: 'Giveaways',
  Cashback: 'Cashback',
  'Additional experience': 'Additional experience',
  'Experience gain': 'Experience gain',
  'Free case each month': 'Free case each month',
  'Free spin (4 per month)': 'Free spin (4 per month)',
  'Ability to take cashback': 'Ability to take cashback',
  'Ability to save cashback': 'Ability to save cashback',
  'Additional points in Leaders Race': 'Additional points in Leaders Race',

  'Left to accumulate': 'Left to accumulate',
  'Get cashback': 'Get cashback',
  'We partially offsetting bets by cashback. Only players with a $t({{premiumType}}, { "context": "adjective"}) account and above can receive cashback.':
    'We partially offsetting bets by cashback. Only players with a $t({{premiumType}}, { "context": "adjective"}) account and above can receive cashback.',
  'You have $t({{premiumType}}, { "context": "adjective"}) account':
    'You have $t({{premiumType}}, { "context": "adjective"}) account',
  'Improve account': 'Improve account',

  '30 days': '30 days',
  'To get bronze account you`ve got to refill for ${currency(amount)} at once':
    'To get bronze account you`ve got to refill for ${currency(amount)} at once',
  'To get gold account you need to complete subscription. Monthly price ${currency(amount)}':
    'To get gold account you need to complete subscription. Monthly price ${currency(amount)}',
  'To obtain a Silver account, you need to make a deposit using any available method with any amount':
    'To obtain a Silver account, you need to make a deposit using any available method with any amount. Your account remains Silver as long as it maintains a balance above 0.',
  'To get a platinum account, you need to refill your balance by a total of ${currency(amount)} per month':
    'To get a platinum account, you need to refill your balance by a total of ${currency(amount)} per month. (using skins or payment systems)',
  'To get a diamond account, you need to refill your balance by a total of ${currency(amount)} per month':
    'To get a diamond account, you need to refill your balance by a total of ${currency(amount)} per month. (using skins or payment systems)',
  'Nickname:': 'Nickname:',
  'Become top player with $t({{premiumType}}, { "context": "adjective"}) premium account':
    'Become top player with $t({{premiumType}}, { "context": "adjective"}) premium account',

  'Choose a photo': 'Choose a photo',
  Save: 'Save',
  Choose: 'Choose',
  Cancel: 'Cancel',
  'Delete all': 'Delete all',
  Select: 'Select',
  'RESEND EMAIL': 'RESEND EMAIL',
  Change: 'Change',
  Attached: 'Attached',
  'Not attached': 'Not attached',
  'Waiting for confirm...': 'Waiting for confirm...',
  'Where to get?': 'Where to get?',
  'Enter trade link to attach': 'Enter trade link to attach',

  day_once: 'days',

  'Do not make your Steam inventory private':
    'Do not make your <0>Steam inventory private</0> and do not change your Steam API key for at least an hour after the transaction is completed, if we detect your abuse attempt, you will lose the entire bet. Follow the rules of trade, fines will be levied for their violation.',
  'Username change!': 'Username change!',
  'Level Up reward added: ${prizeAmount} ${translate(prizeType)}':
    'Level Up reward added: ${prizeAmount} ${translate(prizeType)}',

  // settings form
  'Link sent to': 'Link sent to {{address}}',
  '{{typeTrade}} trade link': '{{typeTrade}} trade link',
  'email-form-status_confirmed': 'Confirmed',
  'email-form-status_not confirmed': 'Waiting for confirm',
  'email-form-status_empty': 'Not confirmed',
  'email-form-status_unsubscribe': 'Waiting for confirm',
  'Waiting for confirmation': 'Waiting for confirmation',

  'trade-link-placeholder_steam':
    'https://steamcommunity.com/tradeoffer/new/?referral=226107091&amp;token=pWn1337k',
  'trade-link-placeholder_vgo': 'https://trade.opskins.com/t/1234567/Q1w2e3R4',

  // nickname bonus
  'Steam Experience Bonus': 'Steam experience bonus',
  'Steam experience Bonus up to 15%': 'Steam experience Bonus up to 15%',
  'Add {{domain}} to your Steam nickname and receive up to 15% bonus to XP':
    'Add {{domain}} to your Steam nickname and receive up to 15% bonus to XP, ',
  'Bet everyday': 'bet everyday.',
  'Take bonus': 'Take',
  Close: 'Close',
  Submit: 'Submit',

  steamApi: {
    noTradeLink: 'NO STEAM TRADE LINK',
    noKey: 'NO STEAM API KEY',
    need: 'Required for selling and trading skins with other players',
    addLink: 'ADD LINK',
    addKey: 'ADD KEY',
    link: 'WHERE TO GET?',
    addApiKey: 'Add Steam API Key',
    changeApiKey: 'Edit Steam API Key',
    addSteamTradeLink: 'Add Steam trade link',
    editSteamTradeLink: 'Edit Steam trade link',
    steamApiKey: 'Steam API Key',
    steamTradeLink: 'Steam trade link',
    steamLink: 'Steam link',
    subTitle: 'When creating an API key, you can use any domain name',
    enterLink: 'Enter Steam trade link',
    enterApiKey: 'Enter API-key',
  },

  // self-exclusion
  'Self-exclusion': 'Self-exclusion',
  'CSGOFAST offers':
    'CSGOFAST offers users the ability to self-exclude themselves from accessing the site for a specific period of time. During this self-exclusion period, you will be unable to play games, deposit funds, or interact with your inventory.',
  'If you feel': 'If you feel the need for a break, take your opportunity to get one.',
  Duration: 'Duration',
  'To activate self-exclusion, a confirmation by mail is required':
    'To activate self-exclusion, a confirmation by mail is required',
  'If necessary, you can contact specialized organizations for help with gambling addiction:':
    'If necessary, you can contact specialized organizations for help with gambling addiction:',
  'Begambleaware, Gamblers Anonymous, Gam Care, National Council on Problem Gambling (NCPG)':
    'Begambleaware, Gamblers Anonymous, Gam Care, National Council on Problem Gambling (NCPG)',
  'Add email': 'Add email',
  'Email to activate self-exclusion': 'Email to activate self-exclusion',
  'It is necessary to send you reports, activate self-isolation and much more':
    'It is necessary to send you reports, activate self-isolation and much more',
  'To change your email':
    'To change your email, follow the link from the email we sent to your verified mailbox.',
  'Send confirmation': 'Send confirmation',
  'Freeze the deposit/withdrawal of funds': 'Freeze the deposit/withdrawal of funds',
  'At the time of freezing, the operations of depositing/withdrawing funds are suspended':
    'At the time of freezing, the operations of depositing/withdrawing funds are suspended',
  'Duration of freezing:': 'Duration of freezing:',
  'Freeze for': 'Freeze for',
  'Email confirmed': 'Email confirmed',
  'Email not confirmed': 'Email not confirmed',
  'Email not added': 'Email not added',
  'We will send notifications':
    'We will send notifications to it when withdrawing funds, we promise not to spam',
  'Confirm email': 'Confirm email',
  'Subscribe to email notifications': 'Subscribe to email notifications',
  'Confirmation has been sent, check your email': 'Confirmation has been sent, check your email',
  'Send an email change request': 'Send an email change request',
  'Change my email': 'Change email',
  'Confirmation waiting': 'Waiting for confirmation',

  // privacy
  'Hide game statistics from other users': 'Hide game statistics from other users',
  'Hide Steam link in profile': 'Hide Steam link in profile',
};
