import React, { FC } from 'react';
import styled from 'styled-components';
import { usePurchaseIdentifiers } from '../../../../hooks';
import { OrdersPurchaseElement } from './orders-purchase-element';
import { EmptyLayout } from '@UIkit';

interface IOrdersPurchaseTab {}

export const OrdersPurchaseTab: FC<IOrdersPurchaseTab> = () => {
  const { identifiers } = usePurchaseIdentifiers();

  if (!identifiers.length) {
    return (
      <Container className="empty-purchase-layout">
        <EmptyLayout ctx="bids" namespace="marketplace" />
      </Container>
    );
  }

  return (
    <Container>
      {identifiers.map(identifier => (
        <OrdersPurchaseElement key={identifier} identifier={identifier} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  min-height: 100%;
  position: relative;
  padding: 0 1rem 0.5rem;

  &:not(.empty-purchase-layout) {
    grid-auto-rows: min-content;
  }
`;
