export default {
  GAME: 'SPIEL',
  Winner: 'Gewinner',
  Won: 'Gewonnen',
  Chance: 'Chance',
  Prize: 'Gewinn',
  'At stake': 'Alle Wetteinsätze',
  'Sign in through STEAM to participate': 'Bei STEAM anmelden um teilzunehmen',
  'Steam trade link': 'Steamlink für den Handel',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Setzen Sie als Erster',
  'Place a bet': 'Wetteinsatz',
  'Deposit more': 'Mehr setzen',
  'Min bet': 'Min. Einsatz',
  'Max bet': 'Max. Wette',
  'Bet amount': 'Höhe des Wetteinsatzes',
  'max. items': 'Max. Items',
  hidden: 'ausgeblendet',
  Copy: 'Kopieren',
  'Winning after commission': 'Gewinn nach Provision',
  Loading: 'Laden',
};
