export default {
  Language: 'ภาษา',
  'Bets in current room': 'เดิมพันในห้องที่เกมเดินอยู่',
  Timers: 'เครื่องจับเวลา',
  'Game start': 'ช่วงต้นเกม',
  'Game ending': 'ช่วงท้ายเกม',
  'Roulette ticking': 'เสียงการเคลื่อนไหวของรูเล็ต',
  'Roulette start': 'จุดที่รูเล็ตเริ่มหมุน',
  'Item set': 'Item set',
  'My bet': 'เดิมพันของฉัน',
  'Coins transfer': 'การส่งต่อเหรียญ',
  'Start button': 'ปุ่มกดสตาร์ท',
  'Stop button': 'ปุ่มกดสต๊อป',
  Countdown: 'นับถอยหลัง',
  Soundtrack: 'ดนตรีประกอบ',
  Crash: 'Crash',
  'Picking item': 'การเลือกไอเทม',
  Crafting: 'การประดิษฐ์',
  Failure: 'ล้มเหลว',
  Win: 'รางวัล/เดิมพัน',
  Sell: 'ขาย',
  Take: 'เอา',
  'Rooms bets': 'สิ่งเดิทพันในห้องอื่นๆ',
  'Chat message sending': 'ส่งข้อความในแชท',
  'Chat message receiving': 'รับข้อความในแชท',
  Sound: 'เสียง',
  'Chat sound': 'เสียงแชท',
  'Game sounds': 'เสียงเกม',
  Volume: 'ฟิลเตอร์',
  steamApi: {
    noKey: 'ไม่มีคีย์ API ของ Steam',
    need: 'จำเป็นสำหรับการขายและการแลกเปลี่ยนสกินกับผู้เล่นคนอื่น',
    addLink: 'เพิ่มลิงก์',
    link: 'จะได้จากที่ไหน?',
  },
};
