import { IStoreFailureResponse, IStoreResponse } from './store.reducer.interface';
import { ICleanupFilters, IUpdateFilters } from './filters.actions.interface';
import {
  IMarketplaceHotOffersUpdate,
  IMarketplaceParticipantUpdate,
  IMarketplaceStoreItemsDeleted,
  IMarketplaceStoreItemsUpdate,
} from './marketplace.actions.interface';

export enum StoreActionTypes {
  FETCH_STORE_ITEMS = 'app/marketplace/FETCH_STORE_ITEMS',
  FETCH_STORE_ITEMS_SUCCESS = 'app/marketplace/FETCH_STORE_ITEMS_SUCCESS',
  FETCH_STORE_ITEMS_FAILURE = 'app/marketplace/FETCH_STORE_ITEMS_FAILURE',
  FETCH_STORE_COUNT = 'app/marketplace/FETCH_STORE_COUNT',
  REFRESH_STORE_ITEMS = 'app/marketplace/REFRESH_STORE_ITEMS',
}

export interface IFetchStore {
  type: typeof StoreActionTypes.FETCH_STORE_ITEMS;
  payload: {
    page: number;
  };
}

export interface IRefreshStore {
  type: typeof StoreActionTypes.REFRESH_STORE_ITEMS;
}

export interface IUpdateStoreItems {
  type: typeof StoreActionTypes.FETCH_STORE_ITEMS_SUCCESS;
  payload: {
    meta: IStoreResponse['meta'];
    kits: IStoreResponse['kits'];
  };
}

export interface IUpdateStoreCount {
  type: typeof StoreActionTypes.FETCH_STORE_COUNT;
  payload: {
    count: number;
  };
}

export interface IUpdateStoreItemsFailure {
  type: typeof StoreActionTypes.FETCH_STORE_ITEMS_FAILURE;
  payload: IStoreFailureResponse;
}

export type storeActions =
  | IFetchStore
  | IRefreshStore
  | IUpdateStoreItems
  | IUpdateFilters
  | ICleanupFilters
  | IUpdateStoreItemsFailure
  | IMarketplaceParticipantUpdate
  | IMarketplaceStoreItemsUpdate
  | IMarketplaceHotOffersUpdate
  | IMarketplaceStoreItemsDeleted
  | IUpdateStoreCount;
