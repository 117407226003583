import styled from 'styled-components';

export const Container = styled.div`
  align-items: stretch;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 160px);
  justify-content: center;
  justify-items: center;
  margin-top: 12px;

  @media screen and (max-width: 374px) {
    grid-template-columns: repeat(2, 132px);
  }
`;
