import React, { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Timer } from '@components/TimerEvent';
import { PremiumContent } from '../profile-premium-content';
import { premiumOrders, premiumConfig } from '../../../modules/PersonalArea/personalArea.utils';
import premium_sprite from '../../../modules/PersonalArea/assets/premium-sprite.svg';

import { IUserState } from 'core/User';
import { Container, Tab, TabsHeader } from './profile-premium-tabs.styles';

interface IPremiumTabs {
  user: IUserState;
}

export const ProfilePremiumTabs: FC<IPremiumTabs> = ({ user }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>(user.profileType || 'demo');

  useEffect(() => {
    setSelected(user.profileType || 'demo');
  }, [user.profileType]);

  return (
    <>
      <TabsHeader>{t('New horizons of your opportunities')}</TabsHeader>
      <Container>
        {premiumOrders.map((premium: string) => (
          <Tab
            key={premium}
            isOpen={selected === premium}
            premiumType={premium}
            onClick={() => setSelected(premium)}
          >
            {premium === user.profileType && selected === premium && user.premium.until && (
              <div className="tab-event">
                <Timer finishEvent={user.premium.until} />
              </div>
            )}
            <svg viewBox="0 0 44 64" width="40px" height="64px">
              <use xlinkHref={`${premium_sprite}#${premium}`} />
            </svg>
          </Tab>
        ))}
      </Container>
      <PremiumContent selectPremium={premiumConfig[selected]} profileType={user.profileType} />
    </>
  );
};
