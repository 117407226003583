import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { ShopFiltersConfig } from '../../configs';
import { changePriceRangeFilter } from '../../ducks';
import { IStore } from '../../../../../store/interface';
import { IFiltersRecord, MergeType } from '../../interfaces';
import { getShopFilter } from '../../selectors';

export const useShopPriceFilter = () => {
  const dispatch = useDispatch();
  const min = useSelector<IStore, IFiltersRecord['minPrice']>(getShopFilter('minPrice'));
  const max = useSelector<IStore, IFiltersRecord['maxPrice']>(getShopFilter('maxPrice'));

  const changePrice = useCallback(
    (value: string) => () => {
      dispatch(changePriceRangeFilter(ShopFiltersConfig.price(value), MergeType.RESET));
    },
    [dispatch]
  );

  return {
    min,
    max,
    changePrice,
  };
};
