import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import {
  useReferralCampaigns,
  useReferralIncomeController,
  useReferralIncomes,
  useReferralMembers,
} from '../../../../hooks';

export const ReferralCampaignsMetadata = () => {
  const { t } = useTranslation();
  const { members } = useReferralMembers();
  const { incomes } = useReferralIncomes();
  const { campaigns } = useReferralCampaigns();
  const { handleTakeIncome } = useReferralIncomeController();

  const reports = useMemo(() => {
    return Object.keys(campaigns).reduce(
      (acc, key) => ({
        incomes: acc.incomes + campaigns[key]?.reports?.incomes || 0,
        referrals: acc.referrals + campaigns[key]?.reports?.referrals || 0,
      }),
      { referrals: 0, incomes: 0 }
    );
  }, [campaigns]);

  const computed = useMemo<any>(() => {
    return members.reduce(
      (acc, item) => ({
        ...acc,
        [item.status]: acc[item.status] + 1,
      }),
      { active: 0, lost: 0, inactive: 0 }
    );
  }, [members]);

  const active = parseInt(`${(computed.active / members.length) * 100}`) || 0;
  const lost = parseInt(`${(computed.lost / members.length) * 100}`) || 0;
  const inactive = parseInt(`${(computed.inactive / members.length) * 100}`) || 0;

  return (
    <Container>
      <div className="referral-amounts">
        <div className="referral-amounts-title">{t('Current profit')}</div>
        <div className="referral-amounts-value">
          <CurrencyText value={incomes.notTakenIncome} />
          {reports.incomes > 0 && (
            <span>
              +<CurrencyText value={reports.incomes} />
            </span>
          )}
        </div>
        <div className="referral-amounts-subtitle">
          {t('Total profit {currency}', {
            amount: incomes.totalIncome,
            postProcess: 'with-template',
          })}
        </div>
      </div>
      <div className="referral-controller">
        <Button className="secondary" onClick={handleTakeIncome} disabled={!incomes.notTakenIncome}>
          <i className="icon-minus-circle" />
          {t('withdraw')}
        </Button>
      </div>
      <div className="referral-statistics">
        <div className="referral-statistics-total">
          <span>{t('Total referrals')}</span>
          <span>
            {members.length?.toLocaleString()}
            {reports.referrals > 0 && <span>+{reports.referrals}</span>}
          </span>
        </div>
        <div className="referral-statistics-active">
          <span className="progress-bar">
            <span
              className="progress-bar-indicator"
              style={{ transform: `translate3d(${active - 100}%, 0, 0)` }}
            />
          </span>
          <span>{t('referral statuses.status', { context: 'active' })}</span>
          <span>
            {computed.active} | {active}%
          </span>
        </div>
        <div className="referral-statistics-lost">
          <span className="progress-bar">
            <span
              className="progress-bar-indicator"
              style={{ transform: `translate3d(${lost - 100}%, 0, 0)` }}
            />
          </span>
          <span>{t('referral statuses.status', { context: 'lost' })}</span>
          <span>
            {computed.lost} | {lost}%
          </span>
        </div>
        <div className="referral-statistics-inactive">
          <span className="progress-bar">
            <span
              className="progress-bar-indicator"
              style={{ transform: `translate3d(${inactive - 100}%, 0, 0)` }}
            />
          </span>
          <span>{t('referral statuses.status', { context: 'inactive' })}</span>
          <span>
            {computed.inactive} | {inactive}%
          </span>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;

  & .referral-amounts {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    align-content: center;
    grid-gap: 0.125rem;
    padding-top: 2.75rem;
    height: 11.375rem;

    &-title,
    &-subtitle {
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      text-align: center;
      letter-spacing: 0.3px;
    }

    &-title {
      color: var(--color-gray-200);
    }

    &-value {
      text-align: center;
      font-size: 1.75rem;
      line-height: 2rem;
      color: var(--color-white);

      & span {
        margin-left: 0.25rem;
        font-size: 0.75rem;
        line-height: 0.9375rem;
        letter-spacing: 0.1px;
        color: var(--success);
        vertical-align: text-top;
      }
    }

    &-subtitle {
      color: var(--color-gray-200);
    }
  }

  & .referral-controller {
    padding: 0 1rem;

    & button:disabled .icon-minus-circle {
      opacity: 0.7;
    }

    & .icon-minus-circle {
      color: var(--secondary) !important;
      font-size: 1rem;
      position: absolute;
      top: 50%;
      left: 1.125rem;
      transform: translate3d(0, -50%, 0);

      &:before {
        color: inherit;
      }
    }
  }

  & .referral-statistics {
    display: grid;
    grid-template: 'total total total' 'active lost inactive';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.85fr 1fr;
    grid-gap: 1.125rem 0.125rem;
    min-height: 16.875rem;
    padding: 0 1rem;

    &-total,
    &-active,
    &-lost,
    &-inactive {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: min-content;

      & .progress-bar {
        justify-self: center;
        width: calc(100vw * 0.128);
        height: 0.5rem;
        position: relative;
        background-color: var(--color-gray-900);
        border-radius: 0.25rem;
        overflow: hidden;

        &-indicator {
          background-color: var(--progress-color);
          border-radius: inherit;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    &-total {
      grid-area: total;
      align-self: self-end;
      justify-content: center;

      & > span {
        text-align: center;

        &:first-of-type {
          font-weight: bold;
          font-size: 0.625rem;
          line-height: 0.875rem;
          letter-spacing: 0.3px;
          color: var(--color-gray-200);
        }
        &:last-of-type {
          font-size: 1.75rem;
          line-height: 2rem;
          color: var(--color-white);

          & > span {
            margin-left: 0.25rem;
            font-size: 0.75rem;
            line-height: 0.9375rem;
            letter-spacing: 0.1px;
            color: var(--success);
            vertical-align: text-top;
          }
        }
      }
    }

    &-active,
    &-lost,
    &-inactive {
      justify-content: center;
      grid-gap: 0.3125rem;
      padding: 0.6125rem 0 0;

      & span {
        text-align: center;

        &:nth-child(2) {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 1.5rem;
          font-size: 0.875rem;
          color: var(--color-white);
        }

        &:nth-child(3) {
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 1rem;
          color: var(--progress-color);
        }
      }
    }

    &-active {
      --progress-color: #00bad9;
      grid-area: active;
    }

    &-lost {
      --progress-color: var(--color-red-100);
      grid-area: lost;
    }

    &-inactive {
      --progress-color: #9ea7b3;
      grid-area: inactive;
    }
  }
`;
