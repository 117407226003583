import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IDialogComponent } from 'core/interfaces';
import { Button } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { PluralizeTimer } from '@components/TimerEvent';
import { useDialog } from 'core/hooks';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { useSalesElement, useSellingControllers } from '../../../../hooks';
import { IMarketplaceElement } from '../../../../interfaces';
import { OrderStatusEnum } from '../../../../configs';
import { MarketplaceDialogs } from '../../../../marketplace-dialogs.config';
import { skinStickerImage } from 'core/utils/skinStickerImage.utils';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface ISaleDialog extends IDialogComponent<{ id: string }> {}

interface ISaleDialogStyle {
  rarity?: string;
}

export const SaleDialog: FC<ISaleDialog> = ({ handleCloseModal, data: { id } }) => {
  const timer = useRef(null);
  const { t } = useTranslation('marketplace');
  const { item } = useSalesElement<IMarketplaceElement>(id);
  const { handleTradeAction } = useSellingControllers();
  const { handleToggleDialog, handleCloseDialog } = useDialog(
    MarketplaceDialogs.MARKETPLACE_STORE_SALE
  );

  useEffect(() => {
    if (OrderStatusEnum.WAIT_FOR_TRADE !== item.status || !!timer.current) return;
    handleToggleDialog(MarketplaceDialogs.INVENTORY_SELLER_TRADE_DIALOG, {
      id: item.id,
      immediate: false,
    })();
    timer.current = setTimeout(() => {
      handleCloseDialog();
      clearTimeout(timer.current);
    }, 2000);
  }, [item, handleToggleDialog, handleCloseDialog]);

  return (
    <Container rarity={item.items?.[0]?.baseItem.color} className={item.status}>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <div className="stickers">
        {item.items?.[0]?.stickers?.map(({ icon }: { icon: string }, idx: number) => (
          <img key={idx} src={skinStickerImage(icon)} alt="" />
        ))}
      </div>
      <div className="header">{t('Item is on sale')}</div>
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item.items?.[0]?.baseItem.icon, 112, 80)} 320w, ${skinItemImage(
            item.items?.[0]?.baseItem.icon,
            224,
            160
          )} 375w`}
          src={skinItemImage(item.items?.[0]?.baseItem.icon, 112, 80)}
          alt=""
        />
      </div>
      <div className="detailed">
        <div className="detailed-appearance">
          <div className="detailed-appearance-status">
            <i className="icon-marketplace-play" />
            <PluralizeTimer finishEvent={item.nextStatusAt} />
          </div>
          <span className="detailed-appearance-exterior">
            {t(`exterior-short.${(item.items?.[0]?.baseItem.exterior ?? '').toLowerCase()}`)}
          </span>
          <span
            className={`detailed-appearance-float ${getExteriorByFloat(item.items?.[0]?.float)}`}
          >
            {!!item.items?.[0]?.float && item.items?.[0]?.float?.toFixed(4)}
          </span>
        </div>
        <div className="detailed-display-name">{item.items?.[0]?.baseItem.shortName}</div>
        <div className="detailed-display-skin">
          {item.items?.[0]?.baseItem.skin || item.items?.[0]?.baseItem.name}
        </div>
        <div className="detailed-price">
          <CurrencyText value={item.price} />
        </div>
      </div>
      <div className="warning-message">
        <i className="icon-warn" />
        <p>{t('seller-trade-offer.warn')}</p>
      </div>
      <div className="controllers">
        {[
          OrderStatusEnum.FIRST_BID,
          OrderStatusEnum.SECOND_BID,
          OrderStatusEnum.THIRD_PLUS_BID,
        ].includes(item.status) && (
          <Button className="primary" onClick={handleTradeAction(item.id, 'sell-now')}>
            <div className="multi-line">
              <span>
                {t('sell { amount }', {
                  amount: item.price,
                  postProcess: 'with-template',
                })}
              </span>
              <span>
                <PluralizeTimer finishEvent={item.nextStatusAt} />
              </span>
            </div>
          </Button>
        )}
        {OrderStatusEnum.WAIT_FOR_CONFIRM === item.status && (
          <>
            <Button className="secondary" onClick={handleTradeAction(item.id, 'decline')}>
              <i className="icon-marketplace-delete" />
            </Button>
            <Button className="primary" onClick={handleTradeAction(item.id, 'confirm')}>
              <div className="multi-line">
                <span>
                  {t('sell { amount }', {
                    amount: item.price,
                    postProcess: 'with-template',
                  })}
                </span>
                <span>
                  <PluralizeTimer finishEvent={item.nextStatusAt} />
                </span>
              </div>
            </Button>
          </>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div<ISaleDialogStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 3.375rem 7.375rem 1fr min-content min-content;
  padding: 1.75rem 1rem;
  background: var(--color-dark-600);
  border-top: 1px solid ${({ rarity }) => rarity || 'transparent'};
  position: relative;

  & .close {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }

  & .stickers {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1.875rem;
    grid-auto-rows: 1.875rem;
    grid-gap: 1.75rem;
    position: absolute;
    left: 1rem;
    top: 5.6rem;

    & img {
      width: 1.875rem;
      height: 1.875rem;
      object-fit: contain;
    }
  }

  & .header {
    justify-self: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--color-white);
  }

  & .thumbnail {
    justify-self: center;
    overflow: hidden;

    & img {
      width: 100%;
      height: calc(100% - 1.875rem);
      object-fit: contain;
    }
  }

  & .detailed {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-rows: min-content;
    grid-gap: 0.125rem;
    justify-self: center;

    &-appearance {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1rem;

      &-status,
      &-exterior,
      &-float {
        white-space: nowrap;
        font-size: 0.75rem;
        letter-spacing: 0.1px;
      }

      &-status {
        display: flex;
        align-items: center;
        color: var(--color-white);

        & i {
          font-size: 1rem;
          margin-right: 0.125rem;
        }

        & span {
          color: inherit;
          font-size: inherit;
          line-height: 1rem;
        }
      }

      &-exterior {
        line-height: 1.125rem;
        color: var(--color-gray-200);
        text-transform: uppercase;
      }

      &-float {
        line-height: 1.125rem;
        color: transparent;

        &.factory-new {
          color: var(--color-exterior-factory-new);
        }

        &.minimal-wear {
          color: var(--color-exterior-minimal-wear);
        }

        &.field-tested {
          color: var(--color-exterior-field-tested);
        }

        &.well-worn {
          color: var(--color-exterior-well-worn);
        }

        &.battle-scarred {
          color: var(--color-exterior-battle-scarred);
        }
      }
    }

    &-display-name {
      white-space: nowrap;
      font-size: 0.75rem;
      letter-spacing: 0.1px;
    }

    &-display {
      &-skin,
      &-name {
        justify-self: center;
        white-space: nowrap;
        color: var(--color-white);
      }

      &-name {
        line-height: 1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-skin {
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: 0.764308px;
      }
    }

    &-price {
      justify-self: center;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 2.75rem;
      letter-spacing: 0.69px;
      white-space: nowrap;
      color: var(--color-white);

      & span {
        font-size: inherit;
        letter-spacing: inherit;

        &.overprice {
          color: var(--color-red);
        }

        &.underprice {
          color: var(--color-green);
        }
      }
    }
  }

  &.wait-for-confirm .warning-message {
    opacity: 1;
    max-height: 300px;
  }

  & .warning-message {
    display: grid;
    grid-template-columns: 1.75rem 1fr;
    grid-gap: 0 1.25rem;
    padding: 0 0.375rem;
    max-height: 0;
    opacity: 0;
    transition: all 200ms ease-in;

    & i {
      font-size: 1.75rem;
      color: var(--yellow);
    }

    & p {
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.570769px;
      color: var(--color-white);
      padding-top: 0.125rem;

      &:not(:first-of-type) {
        grid-column: 2 / 3;
      }
    }
  }

  &.first-bid .controllers,
  &.second-bid .controllers,
  &.third-plus-bid .controllers,
  &.wait-for-confirm .controllers {
    max-height: 3.25rem;
    opacity: 1;
    transform: scale(1);
  }

  & .controllers {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto 1fr;
    grid-auto-rows: 1fr;
    max-height: 0;
    opacity: 0;
    transform: scale(0.9);
    transition: all 600ms ease-in;
    overflow: hidden;

    & .secondary {
      width: 3rem;
      margin-right: 1rem;
    }
  }
`;
