import { Observable } from 'rxjs';
import { socketService } from 'services/socket.service';
import { ajax } from 'rxjs/ajax';
import { SteamAppId } from '../interfaces/reducer.leaders.interfaces';

class LeadersStoreRepository {
  fetchStoreItems(appId: SteamAppId): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/shop/items?appId=${appId}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  fetchStoreGoldItems(): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/great-shop/items`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  fetchPurchaseProduct(itemName: string, appId: number, ticketColor: string): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/shop/items/buy`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemName,
        appId,
        ticketColor,
      }),
    });
  }

  fetchPurchaseGoldProduct(itemId: number): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/great-shop/items/buy`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemId,
      }),
    });
  }
}

export default new LeadersStoreRepository();
