export default {
  line: 'Sınır',
  lines: 'Sınırlar',
  Autoplay: 'Otomatik oyun',
  Paytable: 'Ödeme masası',
  'Recent wins': 'Son kazananlar',
  'Your games': 'Senin oyunların',
  'Bonus game': 'Bonus oyun',
  'Slot works regardless of location': 'Slotlar konumdan bağımsız olarak çalışır.',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Satırdaki herhangi bir yuvanın yerini alabilir (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!': 'Üç yuvayıda topla ve 10 bonus oyun kazan!',
  'Total won': 'Toplam kazanç',
  'Bonus games': 'Bonus oyun',
};
