export default {
  '100$ for the new players!': '100 $ pentru noii jucători!',
  '100$ bonus is credited to a demo balance': 'Bonusul de 100 $ este creditat într-un sold demo',
  'Get 100$ now!': 'Obține acum 100 $!',

  'Top up your balance from 25$ and get bonuses': 'Completați soldul de la 25$ și primiți bonusuri',
  'Exp bonus': 'Bonus de exp',
  'Wheel Of Fortune free spin': 'Roata norocului rotire gratuită',
  'Free case': 'Caz gratuit',
  'Access to account level-up': 'Acces la nivelul contului',
};
