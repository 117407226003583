import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { AuthControlPanel } from '@components/auth-control-panel';
import { GameBoards } from './game-boards';
import { GameHistory } from './game-history';
import { GameMembers } from './game-members';
import { GamePhases } from './game-phases';
import { GameNavigation } from './game-navigation';
import { useTranslation } from 'react-i18next';

export const GamePage = () => {
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'history',
      iconKey: 'history',
      type: 'page',
      isNested: true,
      isNeedAuth: true,
      text: t('history'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];

  return (
    <>
      <AppBar isGame />
      <div className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <Container className="tablet">
          <div
            className="inner-wrapper"
            style={
              {
                '--height': `${Math.max(window.innerHeight, window.innerWidth)}px`,
              } as CSSProperties
            }
          >
            <GameHistory />
            <GamePhases />
            <GameBoards />
          </div>
          <GameNavigation />
          <GameMembers />
        </Container>
      </div>
      <AuthControlPanel />
    </>
  );
};

const Container = styled.div`
  & .inner-wrapper {
    display: grid;
    grid-gap: 1rem;
    align-content: end;
    width: 100%;
    min-height: calc(var(--height) - var(--size-nav-bar) - 5rem);
  }

  & .P,
  & .PP {
    --fill-color: var(--bacarrat-player-fill);
    --stroke-color: var(--bacarrat-player-stroke);
  }

  & .B,
  & .BP {
    --fill-color: var(--bacarrat-banker-fill);
    --stroke-color: var(--bacarrat-banker-stroke);
  }

  & .T {
    --fill-color: var(--bacarrat-tie-fill);
    --stroke-color: var(--bacarrat-tie-stroke);
  }
`;
