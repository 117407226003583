import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)<{ color?: string | null }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background: var(--color-dark-200);
  background: ${p =>
    p.color
      ? `linear-gradient(180deg, var(--color-dark-200) 0%, ${p.color} 57.81%, ${p.color} 100%)`
      : 'var(--color-dark-200)'};
  box-shadow: 0 0.125rem 0.25rem #00000080;
  width: 100%;
  height: 185px;
`;

export const DropPercentage = styled.span`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-gray-200);
  width: fit-content;
`;
export const ContentFirstPart = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 8px;
  justify-content: center;
  gap: 5px;
  align-items: center;
`;
export const ItemImg = styled.img`
  width: 94px;
  height: 77px;
`;
export const ContentSecondPart = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 6px;
  box-sizing: border-box;
`;

export const ItemName = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.6px;
  text-align: center;
  color: var(--color-white);
`;
export const ItemPrice = styled.div`
  color: var(--yellow);
  background-color: transparent;
  display: flex;
  justify-self: flex-end;
  font-size: 14px;

  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
`;
