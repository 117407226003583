import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency/index';
import { Timer } from '@components/TimerEvent';
import { AccountAvatar } from '@components/Common/Avatars';
import { getItemUri } from 'core/utils/image.utils';
import { useUser } from 'core/User';
import { useModal } from 'core/Modals/hooks';
import { useTakeAction } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { leaderModals } from '../../../modal.config';
import type { IBaseLotStyle } from './styles';
import { LotContainer, ParticipationAction, WinnerUser } from './styles';
import { IRafflingItem, Phases } from '../../../interfaces/reducer.leaders.interfaces';

interface IRaffleLot extends IRafflingItem {
  idx: number;
  order: number;
  stage: number;
  phase: Phases;
  type: string;
}
interface IRaffleLotStyle extends IBaseLotStyle {
  order: IRaffleLot['order'];
  phase: Phases;
  raffled: boolean;
}

export const RaffleLot: FC<IRaffleLot> = memo(
  ({
    coinsAmount,
    ticketColor,
    ticketsAmount,
    items,
    icon,
    color,
    name,
    membersIds,
    members,
    membersLimit,
    id,
    raffleId,
    raffleAt,
    winnerId,
    takenAt,
    idx,
    order,
    stage,
    phase,
    type,
  }) => {
    const { t } = useTranslation();
    const { user } = useUser();
    const [handleTakeAt] = useTakeAction();
    const { handleToggleModal } = useModal(leaderModals.INVITE_RAFFLE);
    const combination = !!items?.length;
    const participant = membersIds.some(id => Number(id) === user?.id);
    const clamp = Math.floor(membersLimit - membersLimit * 0.1);

    return (
      <Container
        ticketColor={ticketColor}
        participant={participant}
        order={order}
        phase={phase}
        raffled={stage === idx}
        limit={membersLimit}
        members={membersIds.length}
        clamp={clamp}
      >
        <span className="lot-participant">{participant && <i className="icon-users-plus" />}</span>
        <ul className="lot-rarity-list">
          <li style={{ background: color || 'white' }} />
          {combination &&
            items.map((lot, idx) => <li key={idx} style={{ background: lot.color || 'white' }} />)}
        </ul>
        <div className="lot-thumbnail">
          <img src={getItemUri(icon)} alt="" />
          {raffleAt && <Timer finishEvent={raffleAt} />}
        </div>
        <div className="lot-info">
          <span className="lot-info__total-price">
            <CurrencyText value={coinsAmount} />
          </span>
          {!!membersLimit ? (
            <span className="lot-info__all-members">
              <i className="icon-users" />
              {membersIds?.length || 0}/{membersLimit}
            </span>
          ) : (
            <span className="lot-info__all-members-unlimit">
              <i className="icon-users" />
              {membersIds?.length || 0}
            </span>
          )}
        </div>
        <div className="lot-description">
          {combination ? (
            <span className="lot-description__lot-count">
              {items.length + 1}{' '}
              {t('items_interval', {
                count: items.length + 1,
                postProcess: 'interval',
              })}
            </span>
          ) : (
            <span className="lot-description__lot-name" style={{ color: color || 'white' }}>
              {name}
            </span>
          )}
        </div>
        <div className="lot-actions">
          {['two-week', 'two-month'].includes(type) && (
            <>
              {Phases.waiting === phase && (
                <ParticipationAction
                  className="lot-actions__success"
                  onClick={handleToggleModal(leaderModals.INVITE_RAFFLE, [raffleId, 'items', idx])}
                >
                  {participant ? (
                    <>
                      <i className="icon-checked" />
                      {t('Participating')}
                    </>
                  ) : (
                    <>
                      {t('Participate for')}
                      <i className="icon-coupon" />
                      {ticketsAmount}
                    </>
                  )}
                </ParticipationAction>
              )}
              {(Phases.finished === phase || (Phases.raffling && stage > idx)) && (
                <>
                  {winnerId === user?.id && !takenAt && (
                    <ParticipationAction
                      className="lot-actions__take"
                      onClick={handleTakeAt(raffleId, id)}
                    >
                      <i className="icon-checked" />
                      {t('Take')}
                    </ParticipationAction>
                  )}
                  {(winnerId !== user?.id || takenAt) && (
                    <WinnerUser className="lot-actions__winner">
                      <AccountAvatar
                        uri={members[winnerId]?.avaLink}
                        userId={members[winnerId]?.id}
                      />
                      <span>{members[winnerId]?.name}</span>
                    </WinnerUser>
                  )}
                </>
              )}
            </>
          )}
          {['one-year'].includes(type) && (
            <>
              {stage <= idx && (
                <ParticipationAction
                  className="lot-actions__success"
                  onClick={handleToggleModal(leaderModals.INVITE_RAFFLE, [raffleId, 'items', idx])}
                >
                  {participant ? (
                    <>
                      <i className="icon-checked" />
                      {t('Participating')}
                    </>
                  ) : (
                    <>
                      {t('Participate for')}
                      <i className="icon-coupon" />
                      {ticketsAmount}
                    </>
                  )}
                </ParticipationAction>
              )}
              {(([Phases.finished, Phases.step_waiting].includes(phase) &&
                stage > idx &&
                winnerId) ||
                ([Phases.step_raffling].includes(phase) && stage > idx && winnerId)) && (
                <>
                  {winnerId === user?.id && !takenAt && (
                    <ParticipationAction
                      className="lot-actions__take"
                      onClick={handleTakeAt(raffleId, id)}
                    >
                      <i className="icon-checked" />
                      {t('Take')}
                    </ParticipationAction>
                  )}
                  {(winnerId !== user?.id || takenAt) && (
                    <WinnerUser className="lot-actions__winner">
                      <AccountAvatar
                        uri={members[winnerId]?.avaLink}
                        userId={members[winnerId]?.id}
                      />
                      <span>{members[winnerId]?.name}</span>
                    </WinnerUser>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Container>
    );
  }
);

const Container = styled(LotContainer).attrs((p: IRaffleLotStyle) => ({
  y: p.order * 136,
  hide: [Phases.raffling, Phases.step_raffling].includes(p.phase) && p.raffled,
}))<IRaffleLotStyle>`
  --lot-color: ${p => p.color};
  position: absolute;
  transform: ${p => `translate3d(0, ${p.y}px, 0)`};
  z-index: ${p => p.order};
`;
