import styled from 'styled-components';

export const Container = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 8px;
  position: relative;
  justify-content: center;
  z-index: 5;
  font-size: 1rem;
  color: var(--color-white);
  padding: 0 16px;

  .item-little-screen {
    @media screen and (max-width: 360px) {
      width: 90px;
      height: 100px;
    }
  }

  &.tablet {
    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(4, auto);
    }

    @media screen and (min-width: 650px) {
      grid-template-columns: repeat(5, auto);
    }
  }
`;

export const CaseListTitle = styled.span`
  font-size: 20px;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--color-white);
  margin: 7px 0;
`;
