import { ChangeEvent, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAppCurrency, getAppCurrencySettings } from 'core/AppShell/selectors';
import { setCurrency } from 'core/AppShell/duck';

export const useChangeBalanceFacade = () => {
  const currencySettings = useSelector(getAppCurrencySettings, shallowEqual);
  const currentCurrency = useSelector(getAppCurrency, shallowEqual);
  const dispatch = useDispatch();

  const handleSetCurrency = useCallback(
    (ev: ChangeEvent<HTMLSelectElement>) => {
      dispatch(setCurrency(ev.target.value));
    },
    [dispatch]
  );

  return {
    handleSetCurrency,
    currencySettings,
    currentCurrency,
  };
};
