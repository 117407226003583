import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';

interface IVerifyLink {
  rand: string | null;
  salt?: string;
}

export const VerifyLink: React.FC<IVerifyLink> = ({ rand, salt }) => {
  const { t } = useTranslation();
  return (
    // <ShaLink href={`//sha224.net/?val=${rand || ''}${salt || ''}`} disabled={!rand} target="_blank">
    //   {t('Verify ticket')}
    //   <sup>
    //     <Icon name="shared" />
    //   </sup>
    // </ShaLink>
    <ShaLink href={'//emn178.github.io/online-tools/sha256.html'} disabled={!rand} target="_blank">
      {t('Verify ticket')}
      <sup>
        <Icon name="shared" />
      </sup>
    </ShaLink>
  );
};

interface IShaLink {
  disabled: boolean;
}

const ShaLink = styled.a<IShaLink>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 26px;
  height: 40px;
  border-radius: 5px;
  background: var(--primary);
  color: var(--mBlack);
  font-size: 15px;
  border: 0;
  font-weight: normal;

  & > sup {
    margin-left: 3px;
  }

  ${p =>
    p.disabled &&
    `
    pointer-events: none;
    opacity: 0.7;
  `}
`;
