import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StoreProducts } from '../../components';
import { useStoreFacade } from '../../hooks';

export const StorePage: FC<RouteComponentProps | any> = ({ appId }) => {
  const { entities } = useStoreFacade(appId);
  return (
    <>
      <div className="content">
        <StoreProducts
          // appId={appId}
          items={entities.items}
          // goldItems={golden.entities.items}
        />
      </div>
    </>
  );
};
