import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '@UIkit';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { useUserTradeLinks } from 'core/User';
import { Container } from './steam-settings-dialog.styles';

export interface ISteamSettingsDialog extends IDialogComponent<boolean> {}

const pattern = /http(?:s):\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=([0-9]+)&token=([a-zA-Z0-9_]+)/;

export const SteamSettingsDialog: FC<ISteamSettingsDialog> = ({ data, handleCloseModal }) => {
  const { t } = useTranslation(['steam-settings', 'translations']);
  const { tradeLinks, handleSaveSteamLink } = useUserTradeLinks();
  const { register, errors, handleSubmit } = useForm<{ link: string }>({
    mode: 'onChange',
    defaultValues: {
      link: tradeLinks?.steam ?? '',
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const onSubmit = useCallback(({ link }) => handleSaveSteamLink(link), [handleSaveSteamLink]);

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <div className="dialog-title">{t('Data for skins withdrawing')}</div>
      <div className="inner-container">
        <div className={`${errors?.link?.type} trade-link`}>
          <span>{t('Add Steam trade-link')}</span>
          <SearchInput
            {...register('link')}
            name="link"
            type="text"
            placeholder={t('Enter trade-link')}
            ref={register({
              pattern,
              validate: (v: string) => v !== tradeLinks?.steam,
            })}
          />
          <div>
            {!!errors?.link?.type && <span>{t('Outdated of wrong format')}</span>}
            <Button
              type="button"
              className="link-light"
              as="a"
              href="https://steamcommunity.com/my/tradeoffers/privacy"
              target="_blank"
            >
              {t('Where to get the link?')}
            </Button>
          </div>
        </div>
      </div>
      <Button type="submit" className="primary" onClick={handleSubmit(onSubmit)}>
        {t('translations:::Confirm')}
      </Button>
    </Container>
  );
};
