import React, { FC } from 'react';
import styled from 'styled-components';
import { EventRewardItem, types } from '@components/events';

interface IEventChipList {
  changeAmount: (value: number) => void;
  amount: number;
  settings: number[];
}

export const EventChipList: FC<IEventChipList> = ({ changeAmount, amount, settings }) => (
  <Container>
    {types.map(item => (
      <EventRewardItem
        key={item.amount}
        {...item}
        handleClick={() => changeAmount(item.amount / 100)}
        value={amount}
        disabled={settings[0] > item.amount / 100}
      />
    ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  margin: 1rem;
`;
