export default {
  SPIN: 'SPIN',
  'You are not eligible for free spins':
    'Only steam accounts with CSGO game and no VAC are eligible for free coins. Re-login to the site to update.',
  'It must be 24 hours since last roll': 'It must be 24 hours since last roll.',
  'You already played maximum times in this month. Wait for end of current month.':
    'You already played maximum times in this month. Wait for end of current month.',
  'Your last game spin prize was sold for </amount> coins':
    'Your last game spin prize was sold for {{</amount>}} coins',
  'game from:': 'game from:',
};
