import React, { FC } from 'react';
import { isEqual } from 'lodash';
import { AchievementComp } from '../achievement/achievement';
import { Container } from './achievements.styles';
import { IStatistic, IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';
import { useUser } from 'core/User';

const empty = Array.from({ length: 6 }, () => ({
  key: 'baccarat',
  mode: 'pve',
  top: {
    key: 'baccarat_max_bet',
    title: 'Max bet',
    value: '50000.00',
    metadata: [
      {
        key: 'baccarat_max_bet_date',
        title: 'Max bet date',
        value: '',
      },
    ],
    isCurrency: true,
    additional: {
      key: 'baccarat_max_bet_date',
      title: 'Max bet date',
      value: '',
    },
  },
})) as IStatistic[];

interface IAchievementsComp {
  profile: IUserInfo;
}

export const AchievementsComp: FC<IAchievementsComp> = React.memo(({ profile }) => {
  const { user } = useUser();
  const { statistics, privacy } = profile ?? {};
  const isHidden = !!privacy?.statisticsIsHidden && user.id !== profile.id;
  const hasProfile = !!profile?.id;

  if (!hasProfile) {
    return (
      <>
        {empty.map((_, index) => (
          <Container key={index} className="skeleton" />
        ))}
      </>
    );
  }

  return (
    <>
      {(statistics ?? empty)?.map((item, index) => {
        if (item?.key === 'slots') item = { ...item, key: item.key.slice(0, -1) };

        return <AchievementComp key={index} item={item} isHidden={isHidden} />;
      })}
    </>
  );
}, isEqual);
