/* eslint-disable */
export default {
  Error: 'Fehler',
  'Error placing a bet': 'Fehler beim Wetteinsatz',
  'Error reserving an item': 'Fehler bei der Artikelreservierung',
  'Link saved successfully': 'Link erfolgreich gespeichert',
  "You've successfully updated a bet": 'Du hast deine Wette erfolgreich verändert',
  "You've successfully sell winning item": 'Du hast das Sieges-Item erfolgreich verkauft',
  "You've successfully take winning item": 'Du hast das Sieges-item erfolgreich genommen',
  'Your bet has been added to the queue': 'Your bet has been added to the queue',
  'Incorrect link. Enter the correct link and try again.':
    'Falscher Link. Geben Sie den korrekten Link ein und versuchen Sie es erneut.',
  'This is not your link. Enter your link and try again.':
    'Das ist nicht Ihr Link. Geben Ihren Link ein und versuchen Sie es erneut',
  "Don't forget to make your inventory public to get your winnings!":
    'Vergessen Sie nicht, Ihr Inventar freizugeben, um Ihren Gewinn zu erhalten!',
  'min. number of items per trade not exceeded':
    'Min. Anzahl an Items pro Auftrag nicht überschritten',
  'Too many requests, try again later': 'Zu viele Anfragen. Versuchen Sie es später noch einmal',
  'cheating attempt': 'Cheatversuch',
  'max. number of items per trade exceeded':
    'max. zulässige Anzahl der handelbaren Items überschritten',
  'player not registered on website': 'Spieler auf der Website nicht registriert',
  'souvenir items are not allowed': 'Souvenir-Items sind nicht erlaubt',
  'some of the items cannot be appraised': 'Preis einiger Items kann nicht ermittelt werden',
  'some of the items are below minimum price': 'einige Items liegen unter dem Mindestpreis',
  'total price of a trade is below minimum':
    'Gesamtpreis der Items ist geringer als der Mindestgesamtpreis',
  'Your trade has been accepted!': 'Ihr Handel wurde akzeptiert!',
  'The items have been added to the queue.': 'Diese Items wurden in die Warteschlange aufgenommen.',
  'Your trade has been checked out!': 'Ihr Handel wurde überprüft!',
  'It is currently being accepted': 'Er wird angenommen',
  'Unable to send a trade': 'Handel kann nicht gesendet werden',
  'Update your trade link in the settings of the site':
    'Aktualisieren Sie Ihren Handelslink in den Einstellungen auf der Seite',
  'Enter correct trade link and make sure your inventory is not full.':
    'Geben Sie den richtigen Handelslink ein und prüfen Sie, ob Ihr Inventar voll ist',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Ihr Account ist nicht zum Handeln verfügbar. Mehr Informationen werden gezeigt, wenn Sie jemanden einladen, um zu handeln.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam ist momentan nicht verfügbar. Bitte warten Sie, um es nochmals zu versuchen.',
  'Not enough money on balance': 'Punkteguthaben nicht ausreichend',
  'Not enough points on balance': 'Punkteguthaben nicht ausreichend',
  'Game is ending, try again later': 'Das Spiel ist vorbei. Versuchen Sie es später noch einmal',
  'Congratulations!': 'Herzlichen Glückwunsch!',
  "You've successfully placed a bet": 'Erfolgreiche Platzierung des Wetteinsatzes',
  "You've successfully placed a bet with </amount>":
    'Du hast erfolgreich einen Einsatz über {{</amount>}}',
  'Item has not been found, coins have been returned':
    'Item ist nicht vorhanden, Rückgabe der Münzen',
  'Your order in the store has been cancelled': 'Ihre Bestellung im Store wurde storniert',
  'The min bet is </minBet>': 'Der Mindesteinsatz beträgt {{</minBet>}}',
  'The max bet is </maxBet>': 'Die maximale Wette ist {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Du kannst nicht auf Rot und Schwarz gleichzeitig wetten',
  "You don't have CSGOFAST.com in Steam nickname!":
    'Unter deinem STEAM-Nickname gibt es kein CSGOFAST.com',
  'You have exceeded the limit of 150 free coins per account':
    'Sie haben das Limit von 150 Gratis-Münzen pro Konto überschritten',
  "You've bet </amount> on the crash game #</game>!":
    'Du hast {{</amount>}} im Crash-Game #{{</game>}} gesetzt!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Du hast den Koeff. {{</coef>}} für das Crash-Game #{{</game>}} verändert!',
  "You've won </amount> in the crash game #</number>!":
    'Du hast {{</amount>}} im Crash-Game #{{</number>}} gewonnen!',
  'Code for </amount> has been created': 'Code für {{</amount>}} wurde erstellt',
  'You already have an item': 'Du besitzt bereits ein Item',
  '</amount> refilled on your balance': '{{</amount>}} wurden auf dein Konto aufgeladen',
  'We will sent it to your Steam in few moments':
    'In wenigen Augenblicken senden wir es in dein Steam',
  'Your items has been sended to game!': 'Deine Items wurden dem Spiel zugesendet!',
  '</amount> have been returned': '{{</amount>}} wurden erstattet',
  'Item has not been found, retry in few minutes (</amount>)':
    'Item nicht gefunden, versuche es später erneut ({{</amount>}})',
  'It is currently being processing by game bots': 'Es wird momentan von Game-Bots verarbeitet',
  '</amount> have been refilled': '{{</amount>}} wurde aufgeladen',
  'Request successfully created': 'Anforderung erfolgreich erstellt',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Deine Bestellung #{{</id>}} über {{</amount>}} wurde angenommen. Warte auf den Handel in Steam.',
  'Reason of order rejection': 'Grund für die Ablehnung der Bestellung',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Dein Einsatz von {{</amount>}} wurde angenommen und wird in {{</time>}} Sekunden zum Spiel hinzugefügt',
  'Your bet was added to queue, in few seconds will added to the game':
    'Dein Einsatz wurde zur Warteschlange hinzugefügt und wird in wenigen Sekunden zum Spiel hinzugefügt',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Die Auszahlung ist gesperrt. Gewinne weitere ${currency(amount)}, um sie freizuschalten.',
    },
  },
};
