import { combineReducers, Reducer } from 'redux';
import { fromEvent, merge, of } from 'rxjs';
import { combineEpics, Epic, ofType } from 'redux-observable';
import {
  debounceTime,
  delay,
  map,
  pluck,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import type {
  hiloGameActions,
  hiloHistoryActions,
  hiloPlayersActions,
  IGameRecord,
  IHistoryList,
  IPlayers,
  IPlayingBet,
} from './interfaces';
import {
  FactorTypes,
  FactorTypesRecord,
  GameRecord,
  hiloActionsTypes,
  HistoryGameRecord,
  HistoryList,
  PlayerMap,
} from './interfaces';
import { dialogOff } from 'core/ducks/dialog';
import { ParticipateDialogs } from 'core/interfaces';

const game: Reducer<IGameRecord, hiloGameActions> = (state = new GameRecord(), action) => {
  switch (action.type) {
    case hiloActionsTypes.UPDATE_GAME_STATE:
      const {
        game: { previousBetTypes, hiFactor, loFactor, loProbability, hiProbability, ...rest },
      } = action.payload;

      const updater = new GameRecord(rest)
        .setIn(['factors', 'hi'], {
          factor: hiFactor,
          probability: hiProbability,
        })
        .setIn(['factors', 'lo'], {
          factor: loFactor,
          probability: loProbability,
        });

      if (Object.keys(previousBetTypes).length) {
        return updater.setIn(['previousFactors'], previousBetTypes);
      }

      return updater;

    case hiloActionsTypes.CLEAN_GAME_TYPES:
      return state.setIn(['previousFactors'], new FactorTypesRecord());

    case hiloActionsTypes.HILO_DETACH_LISTENERS:
      return new GameRecord();

    default:
      return state;
  }
};

const history: Reducer<IHistoryList, hiloHistoryActions> = (state = HistoryList, action) => {
  switch (action.type) {
    case hiloActionsTypes.UPDATE_GAME_STATE:
      const { game } = action.payload;
      const item = new HistoryGameRecord(game).set('id', game.id - 1);

      if (state.size && state.getIn(['0', 'card', 'value']) === 0) {
        const [, previous] = state.findEntry((history: any) => history.card.value > 0);

        return state
          .unshift(item.set('previousCard', previous.get('card')))
          .setSize(Math.min(state.size + 1, 100));
      }
      return state
        .unshift(item.set('previousCard', state.getIn(['0', 'card'])))
        .setSize(Math.min(state.size + 1, 100));

    case hiloActionsTypes.UPDATE_HISTORY_STATE:
      const { history } = action.payload;

      const updater = history.slice(0, 99).map((item: any) => new HistoryGameRecord(item));
      return state.size
        ? state.setIn(['0', 'previousCard'], history[0].card).push(...updater)
        : state.push(...updater);

    case hiloActionsTypes.HILO_DETACH_LISTENERS:
      return HistoryList;

    default:
      return state;
  }
};
const players: Reducer<IPlayers, hiloPlayersActions> = (state = PlayerMap, action) => {
  switch (action.type) {
    case hiloActionsTypes.UPDATE_PLAYES:
      const { player } = action.payload;
      return state.setIn([player.user.id, player.type], {
        ...player,
        reward: state.hasIn([player.user.id, player.type, 'reward'])
          ? state.getIn([player.user.id, player.type, 'reward']) + player.reward
          : player.reward,
        amount: state.hasIn([player.user.id, player.type, 'amount'])
          ? state.getIn([player.user.id, player.type, 'amount']) + player.amount
          : player.amount,
      });

    case hiloActionsTypes.INITIALIZING_PLAYES:
      const { players } = action.payload;
      return players.reduce(
        (acc: IPlayers, player: IPlayingBet) => acc.setIn([player.user.id, player.type], player),
        state
      );

    case hiloActionsTypes.UPDATE_GAME_STATE:
    case hiloActionsTypes.HILO_DETACH_LISTENERS:
      return PlayerMap;

    default:
      return state;
  }
};

export const reducer = combineReducers({ game, history, players });

const updateGame = (game: any) => ({
  type: hiloActionsTypes.UPDATE_GAME_STATE,
  payload: { game },
});

const initializingPlayes = (players: IPlayingBet[]) => ({
  type: hiloActionsTypes.INITIALIZING_PLAYES,
  payload: { players },
});

const updatePlayer = (player: IPlayingBet) => ({
  type: hiloActionsTypes.UPDATE_PLAYES,
  payload: { player },
});

const updateHistory = (history: any) => ({
  type: hiloActionsTypes.UPDATE_HISTORY_STATE,
  payload: { history },
});

const cleanUpTypes = () => ({
  type: hiloActionsTypes.CLEAN_GAME_TYPES,
});

export const fetchCreateParticipate = (type: FactorTypes, amount: number) => ({
  type: hiloActionsTypes.FETCH_CREATE_PARTICIPATE,
  payload: { type, amount },
});

export const attachListener = () => ({
  type: hiloActionsTypes.HILO_ATTACH_LISTENERS,
});

export const detachListener = () => ({
  type: hiloActionsTypes.HILO_DETACH_LISTENERS,
});

const gameEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(hiloActionsTypes.HILO_ATTACH_LISTENERS),
    tap(() => {
      socket.io.emit('hilo:bet:fetch');
      socket.io.emit('hilo:game:fetch');
      socket.io.emit('hilo:history:fetch');
    }),
    switchMap(() =>
      merge(
        fromEvent(socket.io, 'hilo:bet:state').pipe(
          map((players: any) => initializingPlayes(players))
        ),
        fromEvent(socket.io, 'hilo:game:state').pipe(
          map((game: any) => updateGame(game)),
          switchMap(action => of(cleanUpTypes()).pipe(delay(1500), startWith(action)))
        ),
        fromEvent(socket.io, 'hilo:history:state').pipe(
          map((history: any) => updateHistory(history))
        ),
        fromEvent(socket.io, 'hilo:bet:add').pipe(map((player: any) => updatePlayer(player)))
      ).pipe(takeUntil(action$.ofType(hiloActionsTypes.HILO_DETACH_LISTENERS)))
    )
  );

const participateWatcherEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(hiloActionsTypes.HILO_ATTACH_LISTENERS),
    pluck('payload'),
    switchMap(() =>
      action$.pipe(
        ofType(hiloActionsTypes.FETCH_CREATE_PARTICIPATE),
        debounceTime(700),
        pluck('payload'),
        tap(({ type, amount }) => socket.io.emit('hilo:bet:create', { type, amount })),
        map(() => dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG)),
        takeUntil(action$.ofType(hiloActionsTypes.HILO_DETACH_LISTENERS))
      )
    )
  );

export const hiloEpic = combineEpics(participateWatcherEpic, gameEpic);
