import styled from 'styled-components';
import { Button } from '@components/Common';
import { getJokerColors } from 'games/CaseBattle/utils';

export const StyledButton = styled(Button)<{ isJoker?: boolean }>`
  align-items: center;
  display: flex;
  flex-shrink: 1;
  gap: 10px;
  justify-content: center;
  ${({ isJoker }) => getJokerColors(isJoker)};

  &:disabled {
    opacity: 0.2;
  }

  & > svg {
    path {
      fill: var(--gray-600);
    }
  }

  & > span {
    display: contents;
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 0.286px;
    line-height: 0.9375rem;
    text-transform: uppercase;
    white-space: nowrap;
    ${({ isJoker }) => getJokerColors(isJoker)};
  }
`;
