import React, { FC, memo, useEffect, useState } from 'react';
import {
  BlockContainer,
  ButtonsType,
  ContainerGame,
  GameTypesContainer,
  Title,
  Wrapper,
} from './GameSettings.styles';
import { useTranslation } from 'react-i18next';
import { PriceInput } from '../PriceInput';
import { CreateGameButton } from '../CreateGameButton';
import { CaseBattleType } from 'games/CaseBattle/ducks/create-battle-duck/create-battle.interfaces';
import { caseBattleTypeAction } from 'games/CaseBattle/ducks';
import { useGetCaseBattleType } from 'games/CaseBattle/hooks';
import { useDispatch } from 'react-redux';
import { useUser } from 'core/User';
import { JokerInfo } from '../JokerInfo';

interface GameSettingsProps {
  generateCasePackHandler: (price?: number) => void;
}

const gameTypes: CaseBattleType[] = ['2', '3', '4', '2 x 2', '2 x 2 TEAM'];
const JOKER_INFO = !!JSON.parse(localStorage.getItem('jokerInfo'));

export const GameSettings: FC<GameSettingsProps> = memo(({ generateCasePackHandler }) => {
  const [completeJokerGuide, setCompleteJokerGuide] = useState(true);
  const { t } = useTranslation();
  const { battleType } = useGetCaseBattleType();
  const dispatch = useDispatch();
  const { user } = useUser();

  useEffect(() => {
    if (!JOKER_INFO) {
      setCompleteJokerGuide(JOKER_INFO);
    }
  }, []);

  const clickHandler = (value: CaseBattleType) => {
    dispatch(caseBattleTypeAction(value));
  };

  return (
    <Wrapper>
      {!completeJokerGuide && <JokerInfo />}
      <ContainerGame>
        <Title>{t('players')}</Title>
        <GameTypesContainer>
          {gameTypes.map(value => {
            return (
              <BlockContainer
                key={value}
                isActive={battleType === value}
                onClick={() => clickHandler(value)}
              >
                {value}
              </BlockContainer>
            );
          })}
        </GameTypesContainer>
        <PriceInput generateCasePackHandler={generateCasePackHandler} />
        <ButtonsType>
          {user?.id && <CreateGameButton isJoker />}
          <CreateGameButton />
        </ButtonsType>
      </ContainerGame>
    </Wrapper>
  );
});
