import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcons } from '../assets';
import { Container, EmailText, Title, Header } from './EmailStatus.styles';
import { useUser } from 'core/User';

interface IEmailStatus {
  handleOpen?: () => void;
  isEditable?: boolean;
}

const STATUS: { [key: string]: string } = {
  confirmed: 'Email confirmed',
  empty: 'Email not added',
  'not confirmed': 'Email not confirmed',
};

export const EmailStatus: FC<IEmailStatus> = ({ handleOpen, isEditable }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const text = STATUS[user.email.status];

  return (
    <Container onClick={handleOpen}>
      {user.email.status === 'empty' ? <SvgIcons.ErrorEmailIcon /> : <SvgIcons.EmailIcon />}
      <Header>
        <Title className={user.email.status === 'confirmed' ? 'confirm' : ''}>{t(text)}</Title>
        {user.email.address && <EmailText>{user.email.address}</EmailText>}
      </Header>
      {isEditable && <SvgIcons.EditIcon />}
    </Container>
  );
};
