import { combineReducers, Reducer } from 'redux';
import { fromEvent, merge, of } from 'rxjs';
import { endWith, ignoreElements, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { getAppFilterRegex } from 'core/AppShell/selectors';
import { listToMatrix, randomSlotList } from './slot.utils';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { ISlotGame, SlotActionsTypes, slotGameActions } from './interfaces';
import { rootEpics$ } from 'store/epicMiddleware';
import { store as configure } from 'store/configureStore';
import { toggleSilenceStatus } from 'modules/Notifications/duck';

const defaultStateGame: any = {
  combs: [],
  slotsMatrix: Array.from({ length: 5 }, () => randomSlotList(30)),
};

const game: Reducer<ISlotGame, slotGameActions> = (state = defaultStateGame, action) => {
  switch (action.type) {
    case SlotActionsTypes.SLOT_UPDATE_GAME: {
      const { payload } = action;
      const matrix = listToMatrix(payload.slots, 3);

      const slotsMatrix = matrix.reduce<number[][]>((acc, items, index) => {
        return [
          ...acc,
          [...matrix[index], ...randomSlotList(24), ...state.slotsMatrix[index].slice(0, 3)],
        ];
      }, []);

      return {
        ...payload,
        slotsMatrix: slotsMatrix,
      };
    }

    case SlotActionsTypes.SLOT_RESET_GAME: {
      return {
        ...state,
        combs: [],
        number: null,
      };
    }

    default: {
      return state;
    }
  }
};

const history: Reducer<any, any> = (state = [], action) => {
  switch (action.type) {
    case SlotActionsTypes.SLOT_UPDATE_OWN_HISTORY:
    case SlotActionsTypes.SLOT_UPDATE_USER_HISTORY: {
      const { payload } = action;
      return [payload, ...state.slice(0, 19)];
    }

    default: {
      return state;
    }
  }
};

export const fetchCreateBid = (selectedLines: number, betPerLine: number) => ({
  type: SlotActionsTypes.FETCH_CREATE_BID,
  payload: {
    selectedLines,
    betPerLine,
  },
});

const updateGame = (payload: ISlotGame) => ({
  type: SlotActionsTypes.SLOT_UPDATE_GAME,
  payload,
});

export const resetGame = () => ({
  type: SlotActionsTypes.SLOT_RESET_GAME,
});

const gameWatcherEpic: Epic = (action$, store$, { socket }) =>
  action$.pipe(
    ofType(SlotActionsTypes.SLOT_ATTACH_LISTENERS),
    tap(() => socket.io.emit('game:slot:history')),
    map(() => new RegExp(getAppFilterRegex(store$.value), 'gi')),
    switchMap(reqExp =>
      merge(
        of(toggleSilenceStatus()),
        action$.pipe(
          ofType(SlotActionsTypes.FETCH_CREATE_BID),
          tap(({ payload: { selectedLines, betPerLine } }) =>
            socket.io.emit('game:slot:start', { selectedLines, betPerLine })
          ),
          ignoreElements()
        ),
        fromEvent(socket.io, 'rooms:slot:recent').pipe(
          map((history: any) => ({
            ...history,
            name: history?.name?.replace(reqExp, '***') || '',
          })),
          map(history => ({
            type: SlotActionsTypes.SLOT_UPDATE_USER_HISTORY,
            payload: history,
          }))
        ),
        fromEvent<ISlotGame>(socket.io, 'rooms:slot:update').pipe(
          map(payload => updateGame(payload))
        )
      )
    ),
    takeUntil(action$.ofType(SlotActionsTypes.SLOT_DETACH_LISTENERS)),
    endWith(toggleSilenceStatus())
  );

const gameEpics = combineEpics(gameWatcherEpic);
const reducer = combineReducers({ game, history });

export const registerSlotModule = () => {
  rootEpics$.next([gameEpics]);
  configure.reducerManager.add('slot', reducer);
  configure.dispatch({ type: SlotActionsTypes.SLOT_ATTACH_LISTENERS });
};

export const unregisterSlotModule = () => {
  configure.reducerManager.remove('slot');
  configure.dispatch({ type: SlotActionsTypes.SLOT_DETACH_LISTENERS });
};
