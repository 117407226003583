import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

export class PurchaseRepository {
  static fetchBids(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/my/bids`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchCreateBid(id: number): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/${id}/bid`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchDeleteBid(id: number): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/${id}/bid/delete`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
    });
  }
}
