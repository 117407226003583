import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { CurrencyText } from '@components/currency';
import { colorByTypes } from '../../../common.utils';

import { IRacesStoryRecord } from '../../../interfaces/reducer.leaders.interfaces';

interface IHistoryNavigateLink {
  match: any;
  userid?: number;
  story: IRacesStoryRecord;
}

const { useState, useEffect } = React;

export const HistoryNavigateLink: React.FC<IHistoryNavigateLink> = React.memo(
  ({ match, userid, story }) => {
    const [ownLots, setLots] = useState({
      allBet: 0,
      prize: 0,
    });

    const { t } = useTranslation();

    useEffect(() => {
      setLots(
        story.items.reduce(
          (acc: any, item: any) => ({
            allBet:
              userid && item.membersIds.includes(userid.toString()) ? acc.allBet + 1 : acc.allBet,
            prize: item.winnerId === userid ? acc.prize + item.coinsAmount : acc.prize,
          }),
          {
            allBet: 0,
            prize: 0,
          }
        )
      );
    }, [story.items, userid]);

    return (
      <NavigateLink to={`${match.url}/${story.type}/${story.id}`}>
        <div>
          <RaceType type={story.type}>
            {t('Raffle interval', {
              context: story.type,
            })}
          </RaceType>
          <RaceData>{dayjs(story.finishAt).format('DD.MM.YYYY')}</RaceData>
        </div>
        {!!ownLots.allBet && (
          <UserBetStatus>
            {
              <span>
                {t('bets')}
                {': '}
                {ownLots.allBet}
              </span>
            }
            {
              <span>
                {t('Profit')}
                {': '}
                <CurrencyText value={ownLots.prize} />
              </span>
            }
          </UserBetStatus>
        )}
      </NavigateLink>
    );
  }
);

type IReceTypeStyle = {
  type: string;
};

const NavigateLink = styled(Link)`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: space-evenly;
  width: 100%;
  height: 70px;
  padding-left: 1.5rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: calc(100vw - 1.5rem);
    height: 1px;
    background-color: var(--gray-250);
    position: absolute;
    bottom: 0;
  }

  &:before {
    content: '\\e901';
    display: block;

    position: absolute;
    bottom: 50%;
    right: 0.7rem;

    transform: rotate(-90deg) translateX(-50%);

    font-family: 'icomoon' !important;
    color: var(--yellow);
  }

  & > div {
    display: flex;
    flex-flow: row;

    & > span {
      margin-right: 0.5rem;
    }
  }
`;

const RaceType = styled.span<IReceTypeStyle>`
  font-size: 0.85rem;
  font-weight: bold;
  color: ${p => `var(--${colorByTypes[p.type]}-race)`};
`;

const RaceData = styled.div`
  font-size: 0.9rem;
  color: #858585;
`;

const UserBetStatus = styled.div`
  text-transform: uppercase;
  & > span {
    font-size: 0.65rem;
    font-weight: bold;
    letter-spacing: 0.12px;
    color: var(--color-white);

    &:last-child {
      color: var(--yellow);
    }
  }
`;
