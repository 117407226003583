import React, { FC } from 'react';

import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { GameTape } from '../GameTape';
import { useTranslation } from 'react-i18next';

export const GamePage: FC = () => {
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'history',
      iconKey: 'history',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('history'),
    },
    {
      name: 'top',
      iconKey: 'top',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('top'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];
  return (
    <>
      <AppBar isGame />
      <div className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <div>
          <GameTape />
        </div>
      </div>
    </>
  );
};
