import { combineReducers } from 'redux';

const prefix = 'app/tradeBot/';
const ADD_TRADE_BOT = `${prefix}ADD_TRADE_BOT`;
const CLEAN_TRADE_BOT = `${prefix}CLEAN_TRADE_BOT`;

const tradesBotById = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TRADE_BOT:
      return {
        ...state,
        [payload.tradeId]: payload,
      };

    case CLEAN_TRADE_BOT:
      return Object.keys(state).reduce((dictionary, tradeId) => {
        if (tradeId === payload.tradeId.toString()) return dictionary;
        return {
          ...dictionary,
          [tradeId]: state[tradeId],
        };
      }, {});

    default:
      return state;
  }
};

const reducer = combineReducers({
  tradesBotById,
});

export default reducer;

const updateTradeBot = data => ({
  type: ADD_TRADE_BOT,
  payload: data,
});

export const removeTradeBot = tradeId => ({
  type: CLEAN_TRADE_BOT,
  payload: { tradeId },
});

const updateLeaderTradeBot = data => ({
  type: ADD_TRADE_BOT,
  payload: {
    tradeId: data.tradeOfferId,
    code: data.securityCode,
    bot: {
      ...data.bot,
      ava_link: data.bot.avaLink,
    },
  },
});

export const eventsTypes = [
  { event: 'trade:incoming', action: updateTradeBot },
  { event: 'trade:created', action: updateTradeBot },
  { event: 'leaders-race.shop-order:sent', action: updateLeaderTradeBot },
];
