import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useVirtual, VirtualItem } from 'react-virtual';
import { useScrollContext } from '../../game-page/slot-game-scroll-provider';
import { useGameHistory } from '../../../hooks';
import { HistoryListElement } from './history-list-element';

export const HistoryList: FC<any> = ({ sortBy }) => {
  const parentRef = useScrollContext();
  const listRef = useRef<HTMLDivElement>(null);
  const { history } = useGameHistory(sortBy);

  const elements = useVirtual({
    size: history.length,
    parentRef,
    estimateSize: useCallback(() => 127, []),
    overscan: 3,
    scrollOffsetFn: (e: Event) =>
      ((e?.target as HTMLDivElement)?.scrollTop || 0) - listRef.current?.offsetTop,
  });

  return (
    <Container ref={listRef} style={{ height: elements.totalSize }}>
      {elements.virtualItems.map(({ start, size, index }: VirtualItem) => (
        <div
          key={history[index]?.number || index}
          className="row"
          style={{ transform: `translateY(${start}px)`, height: size }}
        >
          <HistoryListElement {...history[index]} />
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  transition: height 300ms ease-in-out;
  will-change: height;

  & .row {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 300ms ease-in-out;
    background: var(--color-dark-100);
  }
`;
