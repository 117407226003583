import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeEmail, Container, Text, WaitingText } from './EmailBlock.styles';
import { useUser } from 'core/User';
import { Button } from '@components/Common';
import { EmailStatus } from '../EmailStatus';

interface IEmailBlock {
  handleOpen: () => void;
}

export const EmailBlock: FC<IEmailBlock> = ({ handleOpen }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <Container>
      <EmailStatus handleOpen={handleOpen} />
      <Text>
        {user.email.status === 'empty'
          ? t('It is necessary to send you reports, activate self-isolation and much more')
          : t('Confirmation has been sent, check your email')}
      </Text>
      {user.email.status === 'empty' ? (
        <Button className="primary" onClick={handleOpen}>
          {t('Add an email')}
        </Button>
      ) : (
        <>
          <WaitingText>{t('Confirmation waiting')}</WaitingText>
          <ChangeEmail onClick={handleOpen}> {t('Change email')}</ChangeEmail>
        </>
      )}
    </Container>
  );
};
