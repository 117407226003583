import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Prize } from '@components/Prize/containers';
import { AnimatedRouter } from '@components/animated-router';

import { GamePage, HistoryPage, RulesPage, TopsPage } from './Pages';
import { attachFast, detachFast } from '../duck';
import { LotteryPage } from '../../../@components/Histories';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(attachFast());
    return () => {
      dispatch(detachFast());
    };
  }, [dispatch]);

  return (
    <>
      <Prize gameTypeId="inventory" />
      <div className="content">
        <AnimatedRouter>
          <Route path={`${match.url}/history`} component={HistoryPage} />
          <Route path={`${match.url}/rules`} component={RulesPage} />
          <Route path={`${match.url}/top`} component={TopsPage} />
          <Route path={`${match.url}/lottery`} component={LotteryPage} />
          <Route path={match.url} component={GamePage} />
        </AnimatedRouter>
      </div>
    </>
  );
};
