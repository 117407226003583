import { createSelector } from 'reselect';

export const getMarketplaceMode = ({ marketplace }) => marketplace.mode;

export const getMarketplaceType = ({ marketplace }) => marketplace.type;

const itemIdGetter = state => state.marketplace.itemsIDs;
const itemsDictionaryGetter = state => state.marketplace.itemsByID;
const sortFilterValueGetter = state => state.marketplace.filterValues.sortDirection;
const cartItemIdGetter = state => state.marketplace.cartByID;

export const getMarketplaceItemsByFilter = createSelector(
  itemIdGetter,
  itemsDictionaryGetter,
  sortFilterValueGetter,
  (itemsIDs, itemsByID, sort) =>
    itemsIDs
      .map(id => itemsByID[id])
      .sort((a, b) => (sort === 'up' ? a.value - b.value : b.value - a.value))
);

export const getMarketplaceItems = createSelector(
  itemIdGetter,
  itemsDictionaryGetter,
  (itemsIDs, itemsByID) => itemsIDs.map(id => itemsByID[id])
);

export const getMarketplaceCartItemByID = createSelector(
  cartItemIdGetter,
  itemsDictionaryGetter,
  (itemId, itemsByID) => itemsByID[itemId]
);

export const getMarketplacePagination = ({ marketplace }) => marketplace.pagination;

export const getMarketplaceFilterValues = ({ marketplace }) => marketplace.filterValues;

export const getMarketplaceFetchingStatus = ({ marketplace }) => marketplace.isFetching;

export const getMarketplaceGameTypes = ({ marketplace }) => marketplace.gameTypes;
