export class PeriodTransform {
  static getDatesByPeriod(period: string) {
    switch (period) {
      case 'today': {
        const { startAt } = this.transformTime();
        return {
          period: 'hour',
          utcOffset: new Date().getTimezoneOffset() * -1,
          startAt,
          endAt: new Date().toISOString(),
        };
      }
      case 'yesterday': {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const { startAt, endAt } = this.transformTime(yesterday, yesterday);
        return {
          period: 'hour',
          utcOffset: yesterday.getTimezoneOffset() * -1,
          startAt,
          endAt,
        };
      }
      case 'week': {
        const today = new Date();
        const weekend = new Date();
        weekend.setDate(today.getDate() - 7);
        const { startAt, endAt } = this.transformTime(weekend, today);
        return {
          period: 'day',
          utcOffset: today.getTimezoneOffset() * -1,
          startAt,
          endAt,
        };
      }
      case 'month': {
        const today = new Date();
        const lastDay = new Date();
        lastDay.setDate(today.getDate() - 30);
        const { startAt, endAt } = this.transformTime(lastDay, today);
        return {
          period: 'day',
          utcOffset: today.getTimezoneOffset() * -1,
          startAt,
          endAt,
        };
      }
      case 'quarter': {
        const today = new Date();
        const lastDay = new Date();
        const { startAt, endAt } = this.transformTime(lastDay, today);
        return {
          period: 'month',
          utcOffset: today.getTimezoneOffset() * -1,
          startAt,
          endAt,
        };
      }
      case 'year': {
        const today = new Date();
        const lastDay = new Date();
        lastDay.setDate(today.getDate() - 365);
        const { startAt, endAt } = this.transformTime(lastDay, today);
        return {
          period: 'month',
          utcOffset: today.getTimezoneOffset() * -1,
          startAt,
          endAt,
        };
      }
    }
    return null;
  }

  static transformTime(
    startAt: Date = new Date(),
    endAt: Date = new Date()
  ): { startAt: string; endAt: string } {
    return {
      startAt: new Date(startAt.setHours(0, 0, 0)).toISOString(),
      endAt: new Date(endAt.setHours(23, 59, 59, 999)).toISOString(),
    };
  }
}
