import { createSelector } from 'reselect';
import { IUserRecord, IUserState } from './interfaces/user.reducer.interface';
import { IStore } from 'store/interface';

const userGetter = (store: IStore): IUserRecord => store.user;

export const getUser = createSelector(userGetter, (user: IUserRecord) => user.base.toJS());

export function getUserDetails<P extends keyof IUserState>(
  { user }: IStore,
  property: P
): IUserState[P] {
  return user.base.getIn([property]);
}

export const getUserBalance = ({ user }: IStore) =>
  user.base.get('walletType') ? user.base.get('coins_demo') : user.base.get('coins');

export const getUserInventoryLoading = ({ user }: IStore) => user.inventory['fast'].get('loading');
export const getUserInventoryLoaded = ({ user }: IStore) => user.inventory['fast'].get('loaded');
export const getUserInventoryFailure = ({ user }: IStore) => user.inventory['fast'].get('failure');
export const getUserInventoryIdentifiers = ({ user }: IStore) =>
  user.inventory['fast'].get('items').length;
export const getUserInventoryElement = (idx: number) => ({ user }: IStore) =>
  user.inventory['fast'].getIn(['items', idx]);
export const getUserInventoryInfo = ({ user }: IStore) => user.inventory['fast'].get('info');
export const getUserInventoryFilters = ({ user }: IStore) => user.inventory['fast'].get('filters');
export const getUserInventoryFilterByKey = (k: string) => ({ user }: IStore) =>
  user.inventory['fast'].getIn(['filters', k]);

export const getUserSteamApiKey = ({ user }: IStore) => user.settings.get('market').steamApiKey;

export const getUserSettingsLoading = ({ user }: IStore) => user.settings.get('loading');

export const getReferrerUserInfo = ({ user }: IStore) => user.base.get('referralInfo');

export const getLastWins = ({ user }: IStore) => user.base.get('lastWins');

export const getBanTime = ({ user }: IStore) => user.base.get('banTime');

export const getSelfLock = ({ user }: IStore) => user.base.get('selfLock');

export const getSelfLockUntill = ({ user }: IStore) => user.base.get('selfLockUntill');

export const getStatsIsHidden = ({ user }: IStore) => user.base.get('statsIsHidden');

export const getProvidersSteam = ({ user }: IStore) => user.base.getIn(['providers', 'steam']);
