import React from 'react';
import styled from 'styled-components';
import { useUser } from 'core/User';
import { SignIn } from 'core/Auth';

export const AuthControlPanel = () => {
  const { user } = useUser();
  return (
    !user?.id && (
      <Container className="tablet">
        <SignIn className="primary full" />
      </Container>
    )
  );
};

const Container = styled.div`
  padding: 1.625rem 1rem 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7000;
  background: linear-gradient(0deg, rgba(19, 26, 29, 95%) 0%, rgba(19, 26, 29, 47.5%) 97.96%);
`;
