import React, { FC } from 'react';
import styled from 'styled-components';
import { DefaultContainer } from './combo-line.styles';
import { IComboLineComponent } from './combo-line.interface';

export const EightToNine: FC<IComboLineComponent> = ({ className, children }) => (
  <Container className={className}>
    <div className="left-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="right-indicator" />
    {children}
  </Container>
);

const Container = styled(DefaultContainer)`
  &.line-8 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 6;
    }

    & .line-indicator {
      &:nth-child(4) {
        transform: rotate(45deg);
      }

      &:nth-child(5),
      &:nth-child(6) {
        grid-row: 9;
      }

      &:nth-child(4),
      &:nth-child(7) {
        grid-row: 6 / 10;
      }

      &:nth-child(7) {
        transform: rotate(-45deg) translate(0, 1px);
      }
    }
  }

  &.line-9 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 4;
    }

    & .line-indicator {
      &:nth-child(4) {
        grid-row: 1 / 5;
        transform: rotate(-45deg);
      }

      &:nth-child(5),
      &:nth-child(6) {
        grid-row: 1;
      }

      &:nth-child(4),
      &:nth-child(7) {
        grid-row: 1 / 5;
      }

      &:nth-child(7) {
        transform: rotate(45deg);
      }
    }
  }
  & .line-indicator {
    &:nth-child(4),
    &:nth-child(7) {
      width: 142%;
      justify-self: center;
      border-radius: 0.125rem;
    }
  }
`;
