export default {
  sortBy: {
    label: 'Sort by',
    ASC_PRICE: 'By price (lowest price first)',
    ASC_PRICE_short: 'By price',
    DESC_PRICE: 'By price (highest price first)',
    DESC_PRICE_short: 'By price',
    ASC_NAME: 'By name A-Z',
    ASC_NAME_short: 'By name',
    DESC_NAME: 'By name Z-A',
    DESC_NAME_short: 'By name',
    OVERPRICE: 'By overprice',
    UNDERPRICE: 'By underprice',
    price: '$t(sortBy.ASC_PRICE)',
    '-price': '$t(sortBy.DESC_PRICE)',
    name: '$t(sortBy.ASC_NAME)',
    '-name': '$t(sortBy.DESC_NAME)',
    popular: 'Popular first',
    button: 'Show',
    '-created_at': 'Newest first',
    created_at: 'Oldest first',
    '-total_price': 'Expensive first',
    total_price: 'Cheap first',
  },
  priceRange: {
    label: 'Price range',
    button: 'Ready',
    setManually: 'Set manually',
  },
  search: {
    searchTitle: 'Search skin',
    placeholder: 'Search skin',
    empty: 'No results',
  },
  addBattle: 'add to battle',
  addBot: '+ Bot',
  join: 'Play',
  recreate: 'Recreate',
  open: 'Open',
  refillOn: 'refill On',
  waiting: 'waiting',
  waitingPeople: 'Waiting for players',
  battleRunning: 'The battle is running',
  waitingForRoll: 'Battle start awaiting',
  battleWinnings: 'Total winnings',
  createGame: 'Create game',
  recreateGame: 'Recreate game for',
  players: 'Players',
  randomCasesBefore: 'Random cases up to:',
  createGameFor: 'Create game for',
  createJokerGameFor: 'Create Joker game for',
  outOfRange: 'Total price out of range. Please set correct price!',
  caseSelection: 'Case selection',
  remove: 'Remove',
  waitingPlayers: 'Waiting for players',
  round: 'Round',
  from: 'from',
  battleFinish: 'Battle finish',
  jokerBattleFinish: 'Joker battle finish',
  atStake: 'At stake',
  ready: 'Ready',
  waitingGamer: 'Player waiting',
  awaiting: 'Awaiting',
  loginAndLuck: 'Login and try your luck',
  botAdded: 'Bot added',
  joinSuccess: 'You joined to the game',
  win: 'Win',
  unLuck: 'Failure',
  winnings: 'Winnings',
  random: 'Random',
  cases: 'Cases',
  titleJoker: 'What is this Joker mode?',
  descriptionJoker:
    'Introducing a new Case Battle mode - <1>Joker</1>! This is the reverse of the usual Case Battle, in this mode the player with the lowest amount at the end of all rounds wins.',
  addCases: 'Add cases',
  back: 'Back',
  chooseCases: 'Choose cases or use the auto-selector',
};
