export default {
  //CryptoPage title
  crypto: 'Kripto para çekme',
  cryptocurrency: 'Kripto para birimi',
  from: 'başlayan',
  //
  'Email is required': 'E-posta gerekiyor',
  Withdraw: 'Çekil',
  'Withdrawal sum': 'Para çekme toplamı',
  'Amount in {{title}}:': '$t({{title}}) cinsinden miktar:',
  'Receive in {{title}}:': '$t({{title}}) olarak al:',
  'You will receive:': 'Alacaksın:',
  'Select the network fee': 'Ağ ücretini seçin',
  'Commission:': 'Komisyon:',
  'Ultra Low': 'Çok yavaş',
  Low: 'Yavaş',
  Medium: 'Standart',
  High: 'Hızlı',
  'No commission': 'Komisyonsuz',
  commission: 'Komisyon olabili',
};
