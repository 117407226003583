import * as React from 'react';
import Circle from 'react-circle';
import SoundService from 'services/sound.service';
import styled from 'styled-components';

export default class CircleProgress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
    this.fullProgress = (props.gameRunTs + props.appDiffTime - Date.now()) / 1000;
    this.sound = new SoundService();
  }

  componentDidMount() {
    this.progressTimeId = setTimeout(() => {
      this.setState({ progress: 100 });
    }, 500);
    this.sound.play('Crash', 'Count down', { repeat: true });
  }

  componentWillUnmount() {
    this.sound.play('Crash', 'Count down', { repeat: false });
  }

  render() {
    const { progress } = this.state;
    return (
      <CircleBlock>
        <Circle
          animationDuration={`${this.fullProgress}s`}
          progress={progress}
          showPercentage={false}
          lineWidth={14}
          progressColor="#00bad9"
          bgColor="#151e21"
          animate={true}
          responsive={true}
        />
      </CircleBlock>
    );
  }
}

const CircleBlock = styled.div`
  max-width: 70px;
`;
