import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getGeneralQuestInfo } from '../duck';

export const useGetCategory = (categoryId: number) => {
  const { data } = useSelector(getGeneralQuestInfo);

  const category = useMemo(
    () => data?.categories.find(category => category.entityId === categoryId),
    [categoryId, data]
  );
  const progressCategory = useMemo(() => {
    return category ? (category.progress * 100) / category.count : 0;
  }, [category]);

  const [loader, setLoader] = useState(progressCategory);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(progressCategory);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [progressCategory]);

  return {
    category,
    progressCategory,
    loader,
  };
};
