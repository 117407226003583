export default {
  GAME: 'เกม',
  Winner: 'ผู้ชนะ',
  Won: 'ชนะ',
  Chance: 'โอกาษ',
  Prize: 'รางวัล',
  'At stake': 'รวมเดิมพัน',
  'Sign in through STEAM to participate': 'ลงชื่อเข้าใช้ผ่าน STEAM เพื่อเข้าร่วม',
  'Steam trade link': 'ลิงค์เพื่อแลกเปลี่ยนใน Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'วางมัดจำเป็นคนแรก',
  'Place a bet': 'เพิ่มเหรียญ',
  'Deposit more': 'ลงเพิ่ม',
  'Min bet': 'ต่ำสุด',
  'Max bet': 'สูงสุด',
  'Bet amount': 'ขนาดของเดิมพัน',
  'max. items': 'จำนวนไอเทมสูงสุด',
  hidden: 'เป็นความลับ',
  Copy: 'คัดลอก',
  'Winning after commission': 'รางวัลเมื่อหักค่าคอมมิชชั่นแล้ว',
  Loading: 'โหลด',
};
