import Immutable from 'immutable';

export const FiltersRecord = Immutable.Record<IFiltersRecord>({
  query: '',
  page: {
    number: 0,
  },
  sortBy: '-price',
  minPrice: null,
  maxPrice: null,
  overprice: {
    minOverprice: -30,
    maxOverprice: 120,
  },
  type: [],
  weapon: [],
  exterior: [],
  phase: [],
});

export interface IFiltersRecord {
  query: string;
  page: {
    number: number;
    // size: number
  };
  sortBy: string;
  minPrice: number;
  maxPrice: number;
  overprice: {
    minOverprice?: number;
    maxOverprice?: number;
  };
  type: string[];
  weapon: string[];
  exterior: string[];
  phase: string[];
}
