import styled from 'styled-components';

export const SkeletonElement = styled.div`
  &.skeleton {
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent);
    animation: 6s cubic-bezier(0.4, 0, 0.2, 1) infinite progress;
    background-size: 200% 100%;

    @keyframes progress {
      0% {
        background-position: 200% 0;
      }
      50% {
        background-position: -400% 0;
      }
      100% {
        background-position: -400% 0;
      }
    }
  }
`;
