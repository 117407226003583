import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  height: fit-content;
  overflow: scroll;
  background: var(--color-gray-700);

  &::-webkit-scrollbar {
    display: none;
  }
`;
