export default {
  '/settings': 'การตั้งค่า',
  '/marketplace': 'ตลาด',
  '/balance': 'ยอดสะสม',
  '/auth': 'การรับรอง',
  '/notifications': 'การแจ้งเตือน',

  RULES: 'กติกา',
  Chat: 'แชท',
  GAME: 'เกม',
  Top: 'บนสุด',
  HISTORY: 'ประวัติ',
};
