import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IUserState } from 'core/User/interfaces/user.reducer.interface';
import { filterableSwitch } from './SlotsHistory';

interface IHistorySwitch {
  filterable: filterableSwitch.own | filterableSwitch.recent;
  handleSwitch: () => void;
  user: IUserState;
}

export const HistorySwitch: React.FC<IHistorySwitch> = ({ handleSwitch, user, filterable }) => {
  const { t } = useTranslation();
  return (
    <SwitchContainer>
      <SwitchTab onClick={handleSwitch} disabled={filterableSwitch.recent === filterable}>
        {t('Recent wins')}
      </SwitchTab>
      {user.id && (
        <SwitchTab onClick={handleSwitch} disabled={filterableSwitch.own === filterable}>
          {t('Your games')}
        </SwitchTab>
      )}
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  height: 40px;
  padding: 7px 13px;

  background-color: var(--gray-400);

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
`;

const SwitchTab = styled.button`
  margin: 0 4px;
  padding: 4px 8px;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: center;
  vertical-align: middle;
  line-height: 1.1;
  transition: all linear 0.3s;
  border: 0;

  background-color: transparent;
  color: var(--light);

  &:disabled {
    color: var(--color-dark-600);
    background-color: var(--warning);
  }
`;
