import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { SvgIcons } from '../assets';
import { SvgIcons as SelfExclusionIcons } from '../../../SelfExclusion/assets';
import { ESteam } from '../constants';
import { Header, SubTitle, Title, Container } from './SteamLink.styles';

const STATUS = {
  TRADE_LINK: 'steamApi.steamTradeLink',
  API_KEY: 'steamApi.steamApiKey',
  LINK: 'steamApi.steamLink',
};

interface ISteamLink {
  steamData: string;
  steamCategory: ESteam;
}

export const SteamLink: FC<ISteamLink> = ({ steamData, steamCategory }) => {
  const { t } = useTranslation();
  const { activeDialogs, handleCloseByName, handleToggleDialog } = useDialog();
  const text = STATUS[steamCategory];

  const handleClick = () => {
    const OPEN_DIALOG = {
      API_KEY: () => handleToggleDialog(userModals.STEAM_API_DIALOG, true)(),
      TRADE_LINK: () => handleToggleDialog(userModals.STEAM_TRADE_LINK, true)(),
      LINK: () => window.open(steamData),
    };

    OPEN_DIALOG[steamCategory]();
  };

  useEffect(() => {
    return () => {
      activeDialogs.forEach(item => handleCloseByName(item));
    };
  }, [activeDialogs, handleCloseByName]);

  return (
    <Container onClick={handleClick}>
      {steamCategory !== ESteam.API_KEY ? (
        <SvgIcons.SteamTradeIcon />
      ) : (
        <SvgIcons.SteamApiKeyIcon />
      )}
      <Header>
        <Title>{t(text)}</Title>
        <SubTitle>{steamData}</SubTitle>
      </Header>
      {steamCategory !== ESteam.LINK && <SelfExclusionIcons.EditIcon />}
    </Container>
  );
};
