import * as React from 'react';
import styled from 'styled-components';
import { PlayerAvatar } from '@components/Common';
import { PlayerDescribe } from './PlayerDescribe';
import { iconColors, userColors } from '../../../fast.utils';
import { animated, Transition } from 'react-spring/renderprops';
import { Player } from '../styles';
import { Trans } from 'react-i18next';

import { ITrade } from '../../../interfaces/reducer.fast.interface';

interface IPlayerSlot {
  player: ITrade | null;
  index: number;
  prize: number;
}

export const PlayerSlot: React.FC<IPlayerSlot> = ({ player, index, prize }) => (
  <Player color={userColors[index]}>
    <div>
      <Transition
        native
        items={player}
        from={{ opacity: 0.8, transform: 'scale(0.8)' }}
        enter={{ opacity: 1, transform: 'scale(1)' }}
        leave={{ opacity: 0.8, transform: 'scale(0.8)' }}
      >
        {item => prop =>
          item ? (
            <AnimatedPlayerAvatar style={prop}>
              <PlayerAvatar uri={item.playerImage} userId={item.playerId} />
            </AnimatedPlayerAvatar>
          ) : (
            <AnimatedPlayerAvatar style={prop}>
              <AwaitAvatar index={index}>
                <div />
              </AwaitAvatar>
            </AnimatedPlayerAvatar>
          )}
      </Transition>
    </div>
    <>
      {player ? (
        <PlayerDescribe index={index} player={player} prize={prize} />
      ) : (
        <span>
          <Trans>Waiting players</Trans>
        </span>
      )}
    </>
  </Player>
);

interface IUserAvatar {
  index: number;
}

const AnimatedPlayerAvatar = styled(animated.div)`
  position: absolute;
`;

const AwaitAvatar = styled.div<IUserAvatar>`
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;

  &::before {
    content: '';
    width: 58px;
    height: 58px;
    border-radius: 50%;
    border: 2px solid ${p => userColors[p.index]};
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &::after {
    content: '\\e911';
    font-size: 1.2rem;
    font-family: 'icomoon';
    color: ${p => iconColors[p.index]};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${p => userColors[p.index]};
    position: relative;
  }
`;
