import { IFetchCreateBidRequest, IMember, IX50Game } from './reducer.x50.interfaces';

export enum X50ActionsTypes {
  ATTACH_LISTENERS = 'app/x50/ATTACH_LISTENERS',
  DETACH_LISTENERS = 'app/x50/DETACH_LISTENERS',

  UPDATE_GAME_STATE = 'app/x50/UPDATE_GAME_STATE',

  FETCH_CREATE_PARTICIPATE = 'app/x50/FETCH_CREATE_PARTICIPATE',
}

export interface IFetchCreateBid {
  type: X50ActionsTypes.FETCH_CREATE_PARTICIPATE;
  payload: IFetchCreateBidRequest;
}

export interface IUpdateGameState {
  type: X50ActionsTypes.UPDATE_GAME_STATE;
  payload: {
    data: IX50Game & { topPlayers: IMember[] };
  };
}

export type X50Actions = IUpdateGameState;
