import Immutable from 'immutable';
import { InventoryItem } from 'games/CaseGame/interfaces';

export const OrderRecord = Immutable.Record<IOrderRecord>({
  amount: 0,
  items: [],
});

export interface IOrderRecord {
  amount: number;
  items: InventoryItem[];
}
