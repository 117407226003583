import { useSelector } from 'react-redux';
import { getLRInfo } from '../duck';

export const useGetLeaderRaceInfo = () => {
  const { isLoading, data, isLoaded } = useSelector(getLRInfo);

  return {
    isLoading,
    isLoaded,
    top: data?.top,
    leaderboards: data?.leaderboards,
  };
};
