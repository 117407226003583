import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerItems, Title, Subtitle } from './categories-items.styles';
import { useGetGeneralQuestInfo } from '../../../hooks';
import { CategoryItem } from '../category-item';

export const CategoriesItems = React.memo(({ isFinal }: { isFinal?: boolean }) => {
  const { categoriesData } = useGetGeneralQuestInfo();
  const { t } = useTranslation();

  return (
    <Container>
      {isFinal ? (
        <Title>{t('giftsFound')}</Title>
      ) : (
        <>
          <Title>{t('getItem')}</Title>
          <Subtitle>{t('getItemDesc')}</Subtitle>
        </>
      )}
      <ContainerItems>
        {categoriesData.map(({ entityId, progress, count, image, status, ...rest }) => {
          const percentProgress = count ? (100 * progress) / count : 0;

          return (
            <CategoryItem
              key={entityId}
              entityId={entityId}
              percentProgress={percentProgress}
              status={status}
              caseId={rest?.case?.id || rest?.caseRevisionResult?.case?.id}
              openUUID={rest?.caseRevisionResult?.openUUID}
              itemImg={rest?.caseRevisionResult?.caseRevisionItem?.inventoryItem?.baseItem?.icon}
              image={image}
              isFinal={isFinal}
              categoryName={rest?.name}
            />
          );
        })}
      </ContainerItems>
    </Container>
  );
});
