import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;

  & > svg:last-child {
    margin-left: auto;
  }
`;

export const Header = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const Title = styled.span`
  color: var(--color-red-100);
  display: block;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  text-transform: uppercase;

  &.confirm {
    color: var(--success);
  }
`;

export const EmailText = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 13px;
  letter-spacing: 0.286px;
`;
