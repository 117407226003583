import React from 'react';
import { ReferralDialog } from '../referral-dialog/referral-dialog';
import { useUserReferrerInfo } from 'core/User';
import { IDialogComponent } from 'core/interfaces';

interface BonusCodeDecorator extends IDialogComponent<boolean> {}

export const BonusCodeByLinkDecorator: React.FC<BonusCodeDecorator> = ({ handleCloseModal }) => {
  const { referrerInfo } = useUserReferrerInfo();

  return (
    <ReferralDialog
      code={referrerInfo.code}
      userName={referrerInfo.referrer?.user?.name}
      avatar={referrerInfo.referrer?.user?.avatar}
      reward={referrerInfo.reward}
      type="authenticated"
      bonusType={referrerInfo.type}
      handleCloseModal={handleCloseModal}
    />
  );
};
