import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { useReferralMembers } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface IReferralMembersList {
  id: string;
}

export const ReferralMembersList: FC<IReferralMembersList> = ({ id }) => {
  const { t } = useTranslation();
  const { members } = useReferralMembers(id);
  return (
    <Container>
      {members.map((item, i) => (
        <div key={item.id} className="member">
          <div className="member-position">{i + 1}</div>
          <div
            className="member-thumbnail"
            style={{
              borderColor: `var(--color-account-type-${item.user.profileType})`,
            }}
          >
            <img src={item.user.avatar} alt="" />
          </div>
          <div className="member-detailed">
            <span>{item.user.name}</span>
            <span className={item.status}>
              {t('member statuses.status', { context: item.status })}
              {item.deletedAt && (
                <>
                  {' '}
                  {t('from')} {dayjs(item.deletedAt).format('DD.MM.YY')}
                </>
              )}
            </span>
          </div>
          <div className="member-profit">
            <CurrencyText value={item.rewardCoins} />
          </div>
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;

  & .member {
    display: grid;
    grid-template-columns: 2rem min-content 1fr min-content;
    height: 3.25rem;

    &-position {
      justify-self: center;
      align-self: center;

      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
    }

    &-thumbnail {
      justify-self: center;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border: 2px solid;

      & img {
        width: 2rem;
        height: 2rem;
        border-radius: inherit;
      }
    }

    &-detailed {
      display: grid;
      grid-auto-flow: row;
      align-self: center;
      grid-gap: 0.25rem;
      margin-left: 0.5rem;

      & span {
        font-size: 0.625rem;

        &:first-of-type {
          color: var(--color-white);
        }

        &:last-of-type {
          font-weight: bold;
          color: var(--status-color);

          &.active {
            --status-color: #00bad9;
          }

          &.lost {
            --status-color: var(--color-red-100);
          }

          &.inactive {
            --status-color: #9ea7b3;
          }
        }
      }
    }

    &-profit {
      align-self: center;
      margin-right: 1.125rem;
      font-size: 0.625rem;
      line-height: 0.875rem;
      color: var(--color-white);
    }
  }
`;
