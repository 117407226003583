import { InventoryItem } from 'games/CaseGame/interfaces';
import Immutable from 'immutable';

export enum MergeType {
  APPEND,
  RESET,
}

export const FastInventoryRecord = Immutable.Record<IFastInventoryRecord>({
  loading: false,
  loaded: false,
  failure: false,
  filters: {
    appId: 730,
    sortBy: 'price',
    page: {
      size: 100,
      number: 1,
    },
  },
  info: {
    itemsCount: 0,
    itemsSum: 0,
  },
  items: [],
});

export interface IFastInventoryRecord {
  loading: boolean;
  loaded: boolean;
  failure: boolean;
  filters: {
    appId: number;
    sortBy: string;
    page: {
      size: number;
      number: number;
    };
  };
  info: {
    itemsCount: number;
    itemsSum: number;
  };
  items: IFastInventoryElement[];
}

export interface IFastInventoryElement {
  gameId?: number;
  id: number;
  inventoryItem: InventoryItem;
  inventoryItemId: number;
  isAvailable: boolean;
  isFrozen?: boolean;
  shown: boolean;
  source: any[];
  transactions: boolean;
}
