import { AddCasesConfig } from 'games/CaseBattle/dialog-config';
import { transitions } from 'dialogs/transition.config';
import { DIALOG } from 'dialogs/dialog-combiner';
import { SortAddCases } from './sort-add-cases-dialog';
import { SearchAddCasesDialog } from './search-add-cases-dialog';
import { PriceRangeAddCasesDialog } from './price-range-add-cases-dialog';
import { AddCasesDialog } from './add-cases-dialog';

DIALOG.add(AddCasesConfig.SORT, {
  component: SortAddCases,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

DIALOG.add(AddCasesConfig.PRICE_RANGE, {
  component: PriceRangeAddCasesDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

DIALOG.add(AddCasesConfig.SEARCH, {
  component: SearchAddCasesDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});

DIALOG.add(AddCasesConfig.ADD_CASE, {
  component: AddCasesDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'user-portal',
});
