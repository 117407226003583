import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { BlockedTitle, ProgressInfo, StyledButton } from './super-prize.styles';
import { useGetGeneralQuestInfo } from '../../../hooks';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { CasesQuestStatusEnum } from 'modules/event-module/comics-event/interfaces';
import { Modules } from 'core/interfaces';
import { actionPickUpPrize } from 'modules/event-module/comics-event/duck/default-event-duck';

export const SuperPrizeAvailable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { reward } = useGetGeneralQuestInfo();

  const redirect = () => {
    if (reward) {
      reward.status === CasesQuestStatusEnum.AVAILABLE &&
        dispatch(
          actionPickUpPrize({
            entityId: -1,
            redirectHandler: () => {
              history.push(`..${Modules.CASES}/${reward.case?.id}`);
            },
          })
        );
      reward.status === CasesQuestStatusEnum.TAKEN &&
        history.push(`..${Modules.CASES}/${reward.case?.id}`);
    }
  };

  return (
    <>
      <img src={eventImagesPath.superPricePath} width={80} height={80} alt="" />
      <ProgressInfo>
        <BlockedTitle>
          <Trans i18nKey="superPrizeBonusAvailable" t={t}>
            <span>Superprize</span> is yours!
            <br />
            Open it faster!
          </Trans>
        </BlockedTitle>
        <StyledButton onClick={redirect}>{t('open')}</StyledButton>
      </ProgressInfo>
    </>
  );
};
