import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IStatistic } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';
import { CurrencyText } from '@components/currency';
import {
  Achievement,
  AchievementInfo,
  AchievementName,
  AchievementTitle,
  GameName,
  MyGameIcon,
  Score,
} from './achievement.styles';
import { GamesIcons } from 'assets';
import { ModuleName } from 'modules/Lobby/components';

const transformTitle = (value: string): string =>
  `${value.split(' ').join('_').toUpperCase()}_STAT`;
const transformGame = (value: string) => `${value} game`;

interface IAchievementComp {
  item: IStatistic;
  isHidden: boolean;
}

export const AchievementComp: FC<IAchievementComp> = ({ item, isHidden }) => {
  const { t } = useTranslation();

  const convertValue = () => {
    if (isHidden) {
      return <CurrencyText value={'9999'} />;
    } else if (item.top.isCurrency) {
      return <CurrencyText value={item.top.value} />;
    } else {
      return item.top.value;
    }
  };

  return (
    <Achievement data-ishidden={isHidden} data-ispointer={false}>
      <AchievementInfo>
        <AchievementName>
          <AchievementTitle>
            {isHidden ? 'Largest bet' : t(transformTitle(item.top.title))}
          </AchievementTitle>
          <GameName>{isHidden ? 'Baccarat' : transformGame(item.key)}</GameName>
        </AchievementName>
        <MyGameIcon gameType={item.mode}>
          {!isHidden && GamesIcons[item?.key as ModuleName]?.()}
        </MyGameIcon>
      </AchievementInfo>
      <Score>{convertValue()}</Score>
    </Achievement>
  );
};
// const [isOpen, setIsOpen] = useState(false)
// const isDate = item.top?.additional?.key?.split('_')?.some(word => word === 'date') || false

// TODO: need to know info about additional block
// const handleClick = () => {
//   if (item.top.additional && !isHidden) {
//     setIsOpen(prev => !prev)
//   }
// }
//  isOpen ? (
//   <DetailInfoWrap>
//     <MyGameIcon gameType={item.mode}>
//       <Icon className={`icon-${item?.key}-mini`} isIOS={isIOS} />
//     </MyGameIcon>
//     <BetInfo>
//       {isDate ? (
//         <>
//           <Bet>{t('MAX_WIN_DATE_STAT')}:</Bet>
//           <Bet>{dayjs.utc(item.top.additional.value).format('DD.MM.YYYY')}</Bet>
//         </>
//       ) : (
//         <>
//           {/* change text */}
//           <Bet>{t('MAX_PROFIT_STAT')}:</Bet>
//           <Bet>{`◎ ${(Number(item.top.additional.value) / 100).toLocaleString()}`}</Bet>
//         </>
//       )}
//     </BetInfo>
//   </DetailInfoWrap>
// ) :
