import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from './CurrencyText';

interface ICurrencyInputRange {
  minValue: number;
  maxValue: number;
  setValue: any;
  value: number;
}

export const CurrencyInputRange: FC<ICurrencyInputRange> = ({
  minValue,
  maxValue,
  setValue,
  value,
}) => (
  <Container percent={((value - minValue) / (maxValue - minValue)) * 100}>
    <span>
      <CurrencyText value={minValue} />
    </span>
    <input
      type="range"
      min={minValue}
      max={maxValue}
      value={value}
      step="1"
      onChange={e => setValue(e.target.value)}
      disabled={maxValue < minValue}
    />
    <span>
      <CurrencyText value={maxValue} />
    </span>
  </Container>
);

interface ICurrencyInputRangeStyle {
  percent: number;
}

const Container = styled.div<ICurrencyInputRangeStyle>`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.5rem;

  & span {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-gray-200);
  }

  & input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: 8px 0;
    position: relative;
    top: -3px;
    border-radius: 6px;
    transition: transform linear 0.2s;
    background: ${({ percent }) =>
      `linear-gradient(to right, #47555F 0%, #47555F ${percent}%, var(--color-gray-700) ${percent}%)`};

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: 6px;
      border-radius: 6px;
      background: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      margin-top: -5px;
      background: var(--color-dark-100);
      border-radius: 50%;
      cursor: pointer;
      border: 4px solid white;
      //box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
    }
  }
`;
