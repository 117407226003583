import React, { FC } from 'react';
import styled from 'styled-components';
import { StoreSkin } from '../index';
import { useStoreFacade } from '../../hooks';
import { useModal } from '../../../../core/Modals/hooks';
import { leaderModals } from '../../modal.config';

export const LobbyShop: FC = () => {
  const { entities } = useStoreFacade(null);
  const { handleToggleModal } = useModal(leaderModals.PURCHASE);

  return (
    <Container>
      {entities.items.map((item: any, index: number) => (
        <StoreSkin
          key={item.name + index}
          {...item}
          available={item?.available || true}
          handleShowModal={handleToggleModal(leaderModals.PURCHASE, [item.appId, 'items', index])}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 0.3125rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & > button {
    width: 33vw;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    min-width: 0.6875rem;
    min-height: 100%;
  }
`;
