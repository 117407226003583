import React from 'react';

export const SteamBinding = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C12.4445 0 13.8749 0.284523 15.2095 0.837325C16.5441 1.39013 17.7567 2.20038 18.7782 3.22183C19.7996 4.24327 20.6099 5.4559 21.1627 6.79048C21.7155 8.12506 22 9.55546 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C5.94 22 1.705 18.612 0.396 14.003L4.609 15.741C4.895 17.16 6.16 18.238 7.667 18.238C9.383 18.238 10.78 16.841 10.78 15.125V14.982L14.52 12.309H14.608C16.896 12.309 18.755 10.45 18.755 8.162C18.755 5.874 16.896 4.015 14.608 4.015C12.32 4.015 10.45 5.874 10.45 8.162V8.217L7.843 12.023L7.667 12.012C7.018 12.012 6.413 12.21 5.918 12.551L0 10.12C0.473 4.455 5.203 0 11 0ZM6.908 16.687C7.788 17.05 8.8 16.643 9.163 15.763C9.526 14.883 9.108 13.882 8.25 13.519L6.842 12.936C7.381 12.738 7.986 12.727 8.558 12.969C9.141 13.2 9.592 13.651 9.823 14.234C10.065 14.806 10.065 15.444 9.823 16.016C9.35 17.204 7.953 17.776 6.765 17.281C6.215 17.05 5.797 16.632 5.566 16.137L6.908 16.687ZM17.38 8.162C17.38 9.691 16.137 10.934 14.608 10.934C13.09 10.934 11.847 9.691 11.847 8.162C11.8455 7.79901 11.916 7.43933 12.0542 7.10369C12.1924 6.76806 12.3958 6.46311 12.6524 6.20643C12.9091 5.94976 13.2141 5.74644 13.5497 5.60821C13.8853 5.46997 14.245 5.39955 14.608 5.401C16.137 5.401 17.38 6.644 17.38 8.162ZM12.54 8.162C12.54 9.306 13.464 10.241 14.619 10.241C15.763 10.241 16.687 9.306 16.687 8.162C16.687 7.018 15.763 6.083 14.619 6.083C13.464 6.083 12.54 7.018 12.54 8.162Z"
      fill="white"
    />
  </svg>
);
