import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as gameActions from '../duck';

import {
  getCrashGameBets,
  getCrashGameBetStatus,
  getCrashGameData,
  getCrashGameHistory,
  getCrashGamePing,
} from '../selectors';

import { getAppDiffTime, getCrashGameSettings } from '../../../core/AppShell/selectors';

import { getUser } from '../../../core/User/selectors';

import RateWheel from '../components/WheelGame/RateWheel';
import Progress from '../components/Progress/Progress';
import Controls from '../components/Control/Controls';
import History from '../components/History/History';
import UsersBet from '../components/UsersBets/UsersBet';
import { withTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { addNotification } from '../../../modules/Notifications/duck';

class Game extends React.PureComponent {
  state = {
    modalOpen: false,
    textToCopy: '',
  };

  render() {
    const {
      game,
      appDiffTime,
      userBet,
      gameActions,
      user,
      settings,
      bets,
      ping,
      history,
      t,
    } = this.props;

    return (
      <Fragment>
        {this.state.modalOpen && (
          <Modal>
            <ModalContent>
              <ModalContentText>
                <ModalGameId>{`${t('GAME').toLowerCase()}: #${game.number}`}</ModalGameId>
                <HistoryRound>
                  <HistoryRoundText>{t('Round hash')} sha224:</HistoryRoundText>
                  {game.md5}
                </HistoryRound>
                <HistoryRound>
                  <HistoryRoundText>{t('Round number')}:</HistoryRoundText>
                  {game.rand !== null ? game.rand : 'hide'}
                </HistoryRound>
                <HistoryRound>
                  <HistoryRoundText>{t('Round salt')}:</HistoryRoundText>
                  {game.salt !== null ? game.salt : 'hide'}
                </HistoryRound>
                {game.rand !== null && (
                  <VerifyHash
                    href={`http://sha224.net/?val=${game.rand + game.salt}`}
                    target="_blank"
                  >
                    {t('Verify')}
                  </VerifyHash>
                )}
              </ModalContentText>
              <ModalFooter>
                <CopyToClipboard text={game.rand}>
                  <ModalBtnLeft onClick={() => this.handleCopy(game.rand)}>
                    {t('Copy')}
                  </ModalBtnLeft>
                </CopyToClipboard>

                <ModalBtnRight onClick={this.closeModal}>{t('Close')}</ModalBtnRight>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        <Bg>
          <Progress
            gameRunTs={game.gameRunTs}
            gameRun={game.gameRun}
            crashed={game.crashed}
            appDiffTime={appDiffTime}
            userBet={userBet}
            coef={game.coef}
            ping={ping}
            fetchPing={gameActions.fetchPing}
            openModal={this.openModal}
          />
          <RateWheel {...game} appDiffTime={appDiffTime} userBet={userBet} />
          <Controls
            gameRun={game.gameRun}
            gameRunTs={game.gameRunTs}
            coef={game.coef}
            appDiffTime={appDiffTime}
            crashed={game.crashed}
            userBet={userBet}
            gameActions={gameActions}
            user={user}
            settings={settings}
          />
          <History history={history} />
          <UsersBet bets={bets} gameRun={game.gameRun} crashed={game.crashed} />
        </Bg>
      </Fragment>
    );
  }

  handleCopy = rand => {
    if (rand === null) {
      this._copyLinkCB('error', 'Round number is Null');
      return;
    }
    this.setState({ textToCopy: rand }, () =>
      this._copyLinkCB('success', 'Round number is copied')
    );
  };

  _copyLinkCB = (type, header) => {
    const { addNotification } = this.props;
    addNotification({ type, header });
  };

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => this.setState({ modalOpen: false });
}

const VerifyHash = styled.a`
  display: block;
  width: 100%;
  padding: 12px;
  background: #205358;
  border-radius: 5px;
  text-align: center;
  color: #e0e73b;
  font-weight: bold;
  font-size: 14px;
  margin-top: 9px;

  &:hover {
    color: #e0e73b;
  }
`;

const Bg = styled.div`
  position: relative;
  background: var(--color-dark-500);
`;

const ModalGameId = styled.div`
  color: #ffff32;
  text-align: center;
`;

const HistoryRound = styled.div`
  color: #009999;
  font-size: 11px;
  word-break: break-all;

  padding-top: 15px;
`;

const HistoryRoundText = styled.div`
  color: var(--color-white);

  padding-bottom: 8px;
`;

const ModalContentText = styled.div`
  padding: 0px 25px 46px 25px;
  font-size: 16px;

  color: #fff;
  line-height: 14px;
  letter-spacing: 0.8px;
  height: auto;
`;

const ModalContent = styled.div`
  background: var(--gray-300);
  width: 80%;
  padding: 10px 0 0 0;
  min-height: 30%;
  height: auto;
  border-radius: 25px;
  position: relative;
`;

const ModalFooter = styled.div`
  width: 100%;
  position: absolute;
  height: 29px;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const ModalBtnRight = styled.button`
  color: #ffff32;
  font-size: 12px;
  padding: 9px 5px 9px 5px;

  width: 100%;
  border: 0;
  border-radius: 0 0 25px 0;
  background: #205358;
  margin-left: 1px;
  font-weight: bold;
`;

const ModalBtnLeft = styled.button`
  color: #ffff32;
  font-size: 12px;
  padding: 9px 5px 9px 5px;

  width: 100%;
  border: 0;
  border-radius: 0 0 0 25px;
  background: #205358;
  margin-right: 1px;
  font-weight: bold;
`;

const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(21, 30, 33, 0.9);
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default connect(
  state => ({
    game: getCrashGameData(state),
    history: getCrashGameHistory(state),
    appDiffTime: getAppDiffTime(state),
    ping: getCrashGamePing(state),

    settings: getCrashGameSettings(state),
    userBet: getCrashGameBetStatus(state),
    bets: getCrashGameBets(state),
    user: getUser(state),
  }),
  dispatch => ({
    gameActions: bindActionCreators(gameActions, dispatch),
    addNotification: bindActionCreators(addNotification, dispatch),
  })
)(withTranslation()(Game));
