/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'GRA',
  Chat: 'Czat',
  'Email Support': 'Wsparcie emailowe',
  'Live Support Chat': 'Czat wsparcia na żywo',
  History: 'Historia',
  Top: 'Najlepsi',
  Rules: 'Rules',
  'At stake': 'Zakład o',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Autoryzuj się, aby pisać wiadomości',
  'Only players who have played the game are allowed to write':
    'Pisać mogą tylko gracze, którzy uczestniczyli w grze.',
  'You are banned': 'Jesteś zbanowany',
  Reason: 'Przyczyna',
  'Time left': 'Pozostało',
  'Time to summarizing': 'Czas na podsumowanie',
  spam: 'spam',
  'advertising (auto)': 'reklama (auto)',
  advertising: 'reklama',
  rudeness: 'niegrzeczność',
  begging: 'żebranie',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Dzisiaj',
  Yesterday: 'Wczoraj',
  'Last year': 'W ubiegłym roku',
  'Show my wins': 'Pokaż moje zwycięstwa',
  'History is empty': 'Historia jest pusta',
  Cancel: 'Anuluj',
  Delete: 'Skasuj',
  Close: 'Zamknij',
  Confirm: 'Potwierdź',
  'Are you sure to delete all notifications?': 'Na pewno chcesz skasować wszystkie powiadomienia?',
  'Log in': 'Zaloguj się',
  'Log in to try your luck': 'Autoryzuj się, aby spróbować szczęścia',
  'try your luck!': 'spróbuj swojego szczęścia!',
  'Take your prize': 'Odbierz nagrodę',
  'Sell for': 'sprzedaj za',
  BACK: 'BACK',
  'Warning!': 'Uwaga!',
  'Congratulations!': 'Gratulacje!',
  'You have won': 'Twoja wygrana',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Gratulujemy zwycięstwa, oferta handlowa z nagrodą została wysłana na twoje konto.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'Oferta zostanie anulowana za 1 godzinę',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Po przetworzeniu zamówienia możesz zebrać wygrane na stronie ze zgłoszeniami giełdy platformy Steam',

  'Round number': 'Liczba rundy',
  'Round hash': 'Hash rundy',
  'Hash salt': 'Hashowanie (hasła) z solą',

  'Refill amount': 'Wpłać kwotę',
  Bonus: 'Bonus',
  'Bonus experience': 'Bonusowe punkty doświadczenia',
  'Bonus wheel spins': 'Bonusowe zakręcenia kołem',
  'Bonus cases': 'Bonusowe skrzynie',
  'Trick or treat?': 'Cukierek albo psikus?',
  'Keep your halloween tip jars ready!': 'Przygotuj słoiki z napiwkami na Halloween!',
  'Bonuses on every balance refill': 'Bonusy za każde uzupełnienie salda',

  '${currency(amount)} bonus is ready!': 'Bonus ${currency(amount)} jest gotowy!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Uzupełnij saldo i uzyskaj do ${currency(amount)} w darmowych spinach i skrzyniach',

  'Get bonus': 'dostać się teraz',
};
