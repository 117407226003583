import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { dataCards } from '../../utils';

const CardsList = props => {
  const { t } = useTranslation('baccarat');
  return (
    <Fragment>
      {props.data.map((category, i) => (
        <Row key={i}>
          {category.cards.map((x, i) => {
            const data = dataCards[x.type];
            return <Card key={i} color={data.color} value={x.value} img={data.img} />;
          })}
          <NameRow> = {t(category.name)}</NameRow>
        </Row>
      ))}
    </Fragment>
  );
};

const Card = props => {
  return (
    <CardBg>
      <CardType color={props.color}>{props.value}</CardType>
      <CardImg src={props.img} />
    </CardBg>
  );
};

const CardImg = styled.img`
  width: 13px;
  height: 11px;
`;

const CardType = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: ${p => p.color};
`;

const CardBg = styled.div`
  width: 22px;
  height: 37px;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 4px var(--color-white);
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1px;
`;

const NameRow = styled.div`
  font-size: 11px;
  color: var(--color-white);
  font-weight: bold;
  padding-left: 5px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 0px;
`;

export { CardsList, Card };
