import { Reducer } from 'redux';
import * as Immutable from 'immutable';
import { of } from 'rxjs';
import {
  FiltersActionTypes,
  IFiltersRecord,
  IShopRecord,
  IShopResponse,
  IUpdateFilters,
  MergeType,
  shopActions,
  ShopActionTypes,
  ShopRecord,
} from '../interfaces';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, debounceTime, map, pluck, switchMap } from 'rxjs/operators';
import { ExchangeShopRepository } from '../repositories';
import { getShopFilters } from '../selectors';

export const shop: Reducer<Immutable.Record<IShopRecord>, shopActions> = (
  state = new ShopRecord(),
  action
) => {
  switch (action.type) {
    case ShopActionTypes.FETCH_SHOP_ITEMS: {
      return state.set('loading', true).set('loaded', false).set('failure', false);
    }

    case ShopActionTypes.FETCH_SHOP_ITEMS_SUCCESS: {
      const { items, meta, merge } = action.payload;
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('failure', false)
        .update('items', origin => (merge === MergeType.RESET ? items : [...origin, ...items]))
        .set('meta', meta);
    }

    case ShopActionTypes.FETCH_SHOP_ITEMS_FAILURE: {
      return state.set('loading', false).set('loaded', true).set('failure', true);
    }

    case FiltersActionTypes.UPDATE_FILTERS: {
      const { merge } = action.payload;

      if (merge === MergeType.RESET) {
        return new ShopRecord({ loading: true, loaded: false, failure: false });
      }

      return state.set('loading', true).set('loaded', false).set('failure', false);
    }

    case FiltersActionTypes.RESET_FILTERS: {
      return new ShopRecord({
        loading: true,
        loaded: false,
        failure: false,
      });
    }

    default: {
      return state;
    }
  }
};

export const fetchShop = () => ({
  type: ShopActionTypes.FETCH_SHOP_ITEMS,
});

const fetchShopSuccess = (response: IShopResponse, merge: MergeType) => ({
  type: ShopActionTypes.FETCH_SHOP_ITEMS_SUCCESS,
  payload: { ...response, merge },
});

const fetchShopFailure = () => ({
  type: ShopActionTypes.FETCH_SHOP_ITEMS_FAILURE,
});

const shopLoaderWatcher: Epic = (action$, store$) =>
  action$.pipe(
    ofType<IUpdateFilters>(FiltersActionTypes.UPDATE_FILTERS),
    debounceTime(700),
    pluck('payload', 'merge'),
    map((merge: MergeType) => ({
      params: getShopFilters(store$.value).toObject(),
      merge,
    })),
    switchMap(({ params, merge }: { params: IFiltersRecord; merge: MergeType }) =>
      ExchangeShopRepository.fetchItems(params).pipe(
        map(({ response }) => fetchShopSuccess(response, merge)),
        catchError(() => of(fetchShopFailure()))
      )
    )
  );

export const shopEpics = combineEpics(shopLoaderWatcher);
