import { socketService } from 'services/socket.service';

export class ReferralValidators {
  static checkCode(code: string): Promise<boolean> {
    return fetch(
      `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-campaigns/code`,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: decodeURI(code) }),
      }
    )
      .then(r => r.json())
      .then(r => r?.success ?? false)
      .catch(() => false);
  }
}
