import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';

export const Container = styled(SkeletonElement)`
  align-items: center;
  background-color: #1d2529;
  border-radius: var(--size-radius-l);
  display: flex;
  gap: 8px;
  height: 64px;
  justify-content: space-between;
  min-width: 200px;
  overflow: hidden;
  padding: 12px;
  position: relative;

  & > .bg {
    fill: transparent;
    height: 64px;
    left: 50%;
    min-width: 200px;
    position: absolute;
    transform: translateX(-50%);
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  width: 100%;
  z-index: 20;
`;

export const UserName = styled.span`
  color: #f7fbfc;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AvatarImage = styled.div<{ profileType: string }>`
  flex: 1 0 auto;
  height: 32px;
  pointer-events: auto;
  position: relative;
  width: 32px;
  z-index: 20;

  &::before {
    border-radius: 50%;
    border: 2px solid ${({ profileType }) => `var(--color-account-type-${profileType})`};
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }

  & > svg {
    bottom: -8px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
`;

export const UserIcon = styled.img`
  border-radius: 50%;
  height: 26.67px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 26.67px;
`;

export const CaseNumber = styled.span`
  color: #ffd11a;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
`;

export const CaseImg = styled.img`
  flex: 1 0 auto;
  height: 34px;
  width: 24px;
`;
