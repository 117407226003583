import * as React from 'react';
import styled from 'styled-components';
import couponsprite from '../../assets/couponsprite.svg';
import { useCouponsFacade } from '../../hooks';
import { colorScheme, couponType } from '../../common.utils';
import { useTranslation } from 'react-i18next';

const tickets = ['green', 'blue', 'orange'];

export const LobbyCoupons = () => {
  const coupons = useCouponsFacade();
  const { t } = useTranslation();
  return (
    <Container>
      <CouponHeading>{t('Your tickets')}</CouponHeading>
      <CouponList>
        {tickets.map((key: string) => (
          <Coupon key={key} type={key}>
            <svg className="icon">
              <use xlinkHref={`${couponsprite}#coupon-${couponType[key]}`} />
            </svg>
            <span>{coupons.get(key)}</span>
          </Coupon>
        ))}
      </CouponList>
    </Container>
  );
};

type ICouponStyle = {
  type: string;
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: stretch;
  min-height: 170px;
`;

const CouponHeading = styled.span`
  font-size: 1.3rem;
  letter-spacing: 0.54px;
  text-align: center;
  color: var(--color-white);
`;

const CouponList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
`;

const Coupon = styled.li<ICouponStyle>`
  display: flex;
  flex-flow: column;
  align-items: center;

  & > svg {
    width: 2rem;
    height: 2rem;
  }

  & > span {
    font-size: 1.3rem;
    line-height: 1.8;
    font-weight: bold;
    color: ${p => colorScheme[p.type]};
    text-align: center;
  }
`;
