import styled from 'styled-components';

export const Container = styled.div`
  border-top: solid 2px var(--color-dark-600);
  background-color: var(--gray-400);
`;

export const UserList = styled.div`
  display: flex;
  flex-flow: column;
`;

export const TournamentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  opacity: 0.5;
  font-size: 0.8rem;
  text-align: center;
  color: var(--color-white);
`;
