export default {
  Cart: '購物車',
  Price: '價格',
  Type: 'Type',
  Categories: '類別',
  Rarity: '稀有程度',
  OK: 'OK',
  Clear: '清除',
  Autoselect: '自动选择',
  Remove: 'Remove',
  Bet: '賭注',
  Min: '最小',
  Max: '最大',
  'it.': '',
  Total: '合计',
  items: '道具',
  Pistols: '手枪',
  Rifles: '步枪',
  'Submachine guns': '微型冲锋枪',
  Heavy: '重型',
  Knives: '匕首',
  Gloves: '手套',
  Keys: '钥匙',
  Other: '其他',
  'Base Grade': '普通级',
  'Consumer Grade': '消费级',
  'Industrial Grade': '工业级',
  Restricted: '受限',
  'Mil-Spec Grade': '军规级',
  Classified: '保密',
  'High Grade': '高级',
  Covert: '隐秘',
  Remarkable: '显著的',
  Exotic: '奇异',
  Contraband: '禁运品',
  Extraordinary: '卓越的',
  Buy: '購買',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    '仅储存提取奖金或未使用的硬币。由于与其他市场的价格差异，旨在获利的皮肤交换被严格禁止。违禁者将被永久禁止。',
  'Your purchase will be delivered within 15 minutes.': '您的購買將在 15 分鐘內交付',
  'Congratulations! You have successfully purchased': '恭喜！您已成功購買',
  'Order confirmation': '確認訂單',
  Filters: 'Filters',
  'By price': '按价格',
  Filter: '过滤',
  'Not found': '未找到',
  'At present it is unable to find any items on your request. Try again later.':
    '目前无法找到您要求的任何项目。请稍后再试。',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
