import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  flex-shrink: 0;
  grid-auto-flow: column;
  justify-content: space-between;
  overflow-x: scroll;
  padding-top: 20px;
`;

interface ITabStyle {
  isOpen: boolean;
  premiumType: string;
}

export const Tab = styled.div<ITabStyle>`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 75px;

  ${p =>
    p.premiumType === 'demo' &&
    `
    display: none;
  `};

  ${p =>
    p.isOpen &&
    `
    &::before, &::after {
      content: '';
    }
  `};

  &::before,
  &::after {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &::before {
    background: ${p => `var(--color-account-type-${p.premiumType})`};
    height: 100%;
    opacity: 0.2;
    top: 0;
  }

  &::after {
    bottom: 0;
    border-bottom: 2px solid ${p => `var(--color-account-type-${p.premiumType})`};
  }

  & .tab-event {
    background: ${p => `var(--color-account-type-${p.premiumType})`};
    border-radius: 0.6rem;
    font-size: 0.75rem;
    height: 1.1rem;
    letter-spacing: 0.32px;
    line-height: 1.4;
    position: absolute;
    text-align: center;
    top: -20px;
    width: 100%;
  }
`;

export const TabsHeader = styled.div`
  align-items: center;
  color: var(--color-white);
  display: flex;
  flex-shrink: 0;
  font-size: 0.9rem;
  height: 3rem;
  justify-content: center;
  letter-spacing: 0.5px;
`;
