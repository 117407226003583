import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import premium_sprite from '../../assets/premium-sprite.svg';
import { premiumOrders } from '../../personalArea.utils';
import { userModals } from 'core/User/modal.config';
import { useDialog } from 'core/hooks';

interface IProfileTypeToast {
  profileType: string;
}

export const ProfileTypeToast: FC<IProfileTypeToast> = ({ profileType }) => {
  const { t } = useTranslation();
  const index = Math.min(premiumOrders.indexOf(profileType) + 1, premiumOrders.length);
  const type = premiumOrders[index];
  const { handleOpenDialog } = useDialog(userModals.PROFILE_PREMIUM);

  return (
    <ToastsContainer>
      <div className="wrap">
        <div className="toast-leading">
          <svg className="modal-content_image" viewBox="0 0 44 64">
            <use xlinkHref={`${premium_sprite}#${type}`} />
          </svg>
        </div>
        <div className="toast-body">
          <span>
            {t(
              'Become top player with $t({{premiumType}}, { "context": "adjective"}) premium account',
              {
                premiumType: type,
              }
            )}
          </span>
          <button onClick={handleOpenDialog}>
            {t('Improve account')}
            <i className="icon-arrow-up" />
          </button>
        </div>
      </div>
    </ToastsContainer>
  );
};

const ToastsContainer = styled.div`
  width: 100%;
  background-color: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  padding-right: 2.5rem;

  & .wrap {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-gap: 0.8rem;
    padding: 0.85rem;

    & .toast-leading {
      justify-self: center;
      align-self: center;

      & svg {
        width: 100%;
        height: auto;
      }
    }

    & .toast-body {
      display: grid;
      grid-template-rows: 1fr 20px;
      grid-auto-flow: row;
      grid-gap: 0.4rem;

      & span {
        color: var(--color-white);
        font-size: 0.9rem;
        line-height: 1.2;
        letter-spacing: 0.54px;
      }

      & button {
        background: transparent;
        border: 0;
        color: var(--color-white);
        font-size: 0.9rem;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 1.4;
        padding: 0;
        text-align: start;

        & i {
          display: inline-block;

          margin-left: 0.25rem;
          transform: rotate(90deg) translate(1px, -0.5rem);
        }
      }
    }
  }
`;
