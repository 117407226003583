import * as React from 'react';
import styled from 'styled-components';

import { circumference, gameIconConfig, R, X, Y } from '../../quests.utils';

interface IQuestProgress {
  dashed: boolean;
  userCount?: number;
  targetCount: number;
  type: string;
  isOpened: boolean;
  isHidden: boolean;
  gameTypeId: number;
  rewardTaken: boolean;
}

export const QuestProgress: React.FC<IQuestProgress> = ({
  dashed,
  userCount,
  targetCount,
  type,
  isOpened,
  isHidden,
  gameTypeId,
  rewardTaken,
}) => (
  <Container type={type}>
    <svg width="60px" height="60px">
      <circle
        stroke="#4d5051"
        strokeWidth="2px"
        fill="none"
        cx="30px"
        cy="30px"
        r={R}
        strokeDasharray={`${circumference}, ${circumference}`}
      />
      {userCount && isFinite(userCount) && (
        <circle
          className="progress-circle"
          strokeWidth="2px"
          fill="none"
          cx="30px"
          cy="30px"
          r={R}
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={`${
            (1 - Math.min(userCount, targetCount) / targetCount) * circumference
          }`}
        />
      )}
      {dashed && (
        <circle
          stroke="#253138"
          strokeWidth="2px"
          fill="none"
          cx="30px"
          cy="30px"
          r={R}
          strokeDasharray={`${Y}, ${X}`}
        />
      )}
    </svg>
    {!isHidden && gameIconConfig[gameTypeId.toString()] && (
      <i className={gameIconConfig[gameTypeId.toString()].icon} />
    )}

    {typeof userCount === 'number' && !rewardTaken && isOpened && (
      <span>
        {userCount} / {targetCount}
      </span>
    )}
    {rewardTaken && <i className="icon-checked" />}
    {!isOpened && <i className="icon-lock" />}
  </Container>
);

type IQuestProgressStyle = {
  type?: string;
};

const Container = styled.div<IQuestProgressStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  height: 100%;
  position: relative;

  & > i {
    position: absolute;
    font-size: 2rem;
    color: var(--color-dark-600);
  }

  & > span {
    color: var(--light);
    position: relative;
    font-size: 0.7rem;
    line-height: 1.8;
  }

  & i[class='icon-checked'] {
    font-size: 1.5rem;
    color: ${p => `var(--${p.type}-quest)`};
  }

  & i[class='icon-checked'] {
    font-size: 1.5rem;
    color: ${p => `var(--${p.type}-quest)`};
  }

  & i[class='icon-lock'] {
    font-size: 1.2rem;
    color: var(--color-white);
  }

  & > svg {
    position: absolute;

    & > circle {
      transform: rotate(-90deg);
      transform-origin: center;
    }
    .progress-circle {
      stroke: ${p => `var(--${p.type}-quest)`};
    }
  }
`;
