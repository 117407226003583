import * as React from 'react';
import styled from 'styled-components';
import couponsprite from '../../assets/couponsprite.svg';
import { Timer } from '@components/TimerEvent';
import { CurrencyText } from '@components/currency';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { IMyStats } from '../../interfaces/reducer.leaders.interfaces';

interface ITournamentHeader {
  startAt: string;
  finishAt: string;
  myStats: IMyStats | null;
  prizeTicketsAmount: number;
  raceType: string;
  prizeCoinsAmount: number;
}

export const TournamentHeader: React.FC<ITournamentHeader> = ({
  startAt,
  finishAt,
  prizeTicketsAmount,
  raceType,
  prizeCoinsAmount,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <AlignContainer>
        <TournamentTicket>
          <svg className="icon">
            <use xlinkHref={`${couponsprite}#coupon-${raceType}`} />
          </svg>
        </TournamentTicket>
        <div>
          <TournamentTitle raceType={raceType}>
            {t('Races type', {
              context: raceType,
            })}
          </TournamentTitle>
          <TournamentTiker raceType={raceType}>
            {Date.parse(finishAt) > Date.now() ? (
              <Timer key={finishAt} finishEvent={finishAt} />
            ) : (
              <span>
                {t('from')} {dayjs(startAt).format('DD.MM.YYYY')}
              </span>
            )}
          </TournamentTiker>
        </div>
      </AlignContainer>
      <PlacesField>
        <span>{t('Prize pool')}</span>
        <PlacesTicket raceType={raceType}>
          <svg className="icon">
            <use xlinkHref={`${couponsprite}#coupon-${raceType}`} />
          </svg>
          <span>{prizeTicketsAmount}</span>
          {!!prizeCoinsAmount && (
            <span>
              {'+ '}
              <CurrencyText value={prizeCoinsAmount} />
            </span>
          )}
        </PlacesTicket>
      </PlacesField>
    </Container>
  );
};

type ITournamentHeaderStyle = {
  raceType: string;
};

const Container = styled.div`
  padding: 1rem;
  background-color: var(--gray-400);
  position: relative;
`;

const AlignContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const TournamentTicket = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--color-dark-500);
  padding: 0.5rem;
  margin-right: 0.95rem;
`;

const TournamentTitle = styled.div<ITournamentHeaderStyle>`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${p => `var(--${p.raceType}-race)`};
`;

const TournamentTiker = styled.div<ITournamentHeaderStyle>`
  font-size: 0.9rem;
  color: ${p => `var(--${p.raceType}-race)`};
  line-height: 2;
  min-height: 26px;
`;

const PlacesField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 90px;
  margin: 1rem 0;
  background-color: var(--color-dark-600);

  & > span {
    opacity: 0.5;
    font-size: 0.8rem;
    color: var(--color-white);
    line-height: 2;
    text-transform: uppercase;
  }
`;

const PlacesTicket = styled.div<ITournamentHeaderStyle>`
  display: flex;
  align-items: center;
  flex-flow: row;
  line-height: 1.4;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & > span {
    color: var(--color-white);
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 0.5rem;
  }

  & > span:first-of-type {
    color: ${p => `var(--${p.raceType}-race)`};
  }
`;
