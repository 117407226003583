export default {
  'Unfortunately full screen is not supported in iOS':
    'Unfortunately full screen is not supported in iOS',

  'Pick a team': 'Pick a team',
  'Before the game begins choose your team: Terrorists or Counter-Terrorists.':
    'Before the game begins choose your team: Terrorists or Counter-Terrorists.',
  'Symbols that belong to your team will earn you more winnings!':
    'Symbols that belong to your team will earn you more winnings!',
  'You can change your team at any time by clicking the soldier on the left side of the screen.':
    'You can change your team at any time by clicking the soldier on the left side of the screen.',
  'Scatter Symbols and Rounds': 'Scatter Symbols and Rounds',

  Scatter: 'Scatter',
  'Scatter Symbols of your and enemy teams will show up on the screen. Watch them closely, they can influence the game in major ways!':
    'Scatter Symbols of your and enemy teams will show up on the screen. Watch them closely, they can influence the game in major ways!',

  Victory: 'Victory',
  'Three Scatters of your team appearing anywhere on the screen win the round, you get Loss Bonus and an opportunity to open a Crate with an additional reward inside.':
    'Three Scatters of your team appearing anywhere on the screen win the round, you get Loss Bonus and an opportunity to open a Crate with an additional reward inside.',

  'Crate opening': 'Crate opening',
  'All symbols on the screen that have reward value get placed in the crate, along with Jackpot Symbol (constitutes the sum of all rewards * 10). Opening the crate wins you one of those rewards.':
    'All symbols on the screen that have reward value get placed in the crate, along with Jackpot Symbol (constitutes the sum of all rewards * 10). Opening the crate wins you one of those rewards.',

  'Free Spins': 'Free Spins',
  "Three consecutive round victories grant you 30 Free Spins. During Free Spins Scatter Elements don't appear on the screen, thus increasing Free Spins win rate.":
    "Three consecutive round victories grant you 30 Free Spins. During Free Spins Scatter Elements don't appear on the screen, thus increasing Free Spins win rate.",

  'Round Loss': 'Round Loss',
  "Three enemy Scatter Symbols mean that you lose the round. But don't worry! Every lost round increases your Loss Bonus, that gets paid to you after round win or a draw.":
    "Three enemy Scatter Symbols mean that you lose the round. But don't worry! Every lost round increases your Loss Bonus, that gets paid to you after round win or a draw.",

  'Round Draw': 'Round Draw',
  'Three Scatter Symbols of both teams on the screen mean that the round is a draw. Congrats, you get your Loss Bonus.':
    'Three Scatter Symbols of both teams on the screen mean that the round is a draw. Congrats, you get your Loss Bonus.',

  Grenades: 'Grenades',

  'Assault (neutral)': 'Assault (neutral)',
  'Destroys all non-allied symbols on the screen, new symbols drop from above.':
    'Destroys all non-allied symbols on the screen, new symbols drop from above.',

  'Flashbang (neutral)': 'Flashbang (neutral)',
  'All enemy symbols switch team, the flashbang itself turns into a Flash Wild.':
    'All enemy symbols switch team, the flashbang itself turns into a Flash Wild.',

  'Molotov/Incendiary': 'Molotov/Incendiary',
  "Only activates if you're on a Terrorist or Counter-Terrorist team respectively. Horizontally and vertically adjacent symbols get replaced with Burning Wilds.":
    "Only activates if you're on a Terrorist or Counter-Terrorist team respectively. Horizontally and vertically adjacent symbols get replaced with Burning Wilds.",

  'Special Symbols': 'Special Symbols',
  'Burning Wild, Flash Wild': 'Burning Wild, Flash Wild',
  'Substitutes for the most beneficial for the player symbol in that position.':
    'Substitutes for the most beneficial for the player symbol in that position.',

  'Symbol Payout Chart': 'Symbol Payout Chart',

  'Bet Lines': 'Bet Lines',
  'Three or more matching symbols on one or several of the lines win. Winning combinations must start at the leftmost reel.':
    'Three or more matching symbols on one or several of the lines win. Winning combinations must start at the leftmost reel.',
};
