import React, { FC, memo } from 'react';
import { Container } from './TotalPriceCounter.styles';
import { TeamTotalPrice } from '../TeamTotalPrice';
import { useGetCaseBattleGameData, useGetTeams } from 'games/CaseBattle/hooks';

const TotalPriceCounterContainer: FC = memo(() => {
  const { delayedTeams } = useGetTeams();
  const { data } = useGetCaseBattleGameData();
  const maxTeamTotalPrice = Math.max(...delayedTeams.map(({ teamTotalPrice }) => teamTotalPrice));
  const minTeamTotalPrice = Math.min(...delayedTeams.map(({ teamTotalPrice }) => teamTotalPrice));
  const isJoker = data.game.settings.jokerMode;

  return (
    <Container>
      {delayedTeams.map(({ team_id, teamTotalPrice }) => {
        const isWinner = (isJoker ? minTeamTotalPrice : maxTeamTotalPrice) === teamTotalPrice;

        return (
          <TeamTotalPrice
            key={team_id}
            teamTotalPrice={teamTotalPrice}
            isWinner={isWinner}
            isJoker={isJoker}
          />
        );
      })}
    </Container>
  );
});

export const TotalPriceCounter = TotalPriceCounterContainer;
