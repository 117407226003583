import { combineEpics } from 'redux-observable';
import {
  caseBattleListHistoryMPEpic,
  caseMyBattleListHistoryMPEpic,
  caseBattleListEpic,
  myCaseBattleList,
} from './case-battle-duck';
import { createGameEpic } from './create-battle-duck';
import {
  addBotEpic,
  caseBattleGameEpic,
  getCasesByIdsEpic,
  joinToGameEpic,
  addEmojiEpic,
} from './case-battle-game-duck';

export const caseBattleEpic = combineEpics(
  caseBattleListEpic,
  caseBattleListHistoryMPEpic,
  caseMyBattleListHistoryMPEpic,
  myCaseBattleList,
  createGameEpic,
  caseBattleGameEpic,
  addBotEpic,
  joinToGameEpic,
  getCasesByIdsEpic,
  addEmojiEpic
);
