import styled from 'styled-components';

export const Container = styled.div`
  flex-shrink: 0;

  & > a {
    background-color: var(--color-dark-600);
  }
`;

// & > :nth-last-child(2) {
// border-bottom: 1px solid var(--color-gray-900);
// }

// & > a:last-child {
//   background-color: var(--color-dark-600);
//   border-top: 1px solid var(--color-gray-900);
//   margin-top: 30px;
// }
