import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MergeType } from '../../interfaces';
import { updateFastInventoryFilter } from '../../ducks/fast-inventory.duck';

export const useFastInventoryPagination = () => {
  const dispatch = useDispatch();

  const loadMore = useCallback(
    (number: number) => {
      dispatch(updateFastInventoryFilter(['page', 'number'], number, MergeType.APPEND));
    },
    [dispatch]
  );

  return {
    loadMore,
  };
};
