export default {
  Games: 'Jocuri',
  'Refill balance': 'Umplere sold',
  Settings: 'Setări',
  Store: 'Magazin',
  'Get free money': 'Obține monede gratis',
  Notifications: 'Notificări',
  FAQ: 'FAQ',
  'Log out': 'Deconectare',
  'Steam trade link': 'Link către tranzacţii Steam',
  'Where can I get a link?': 'De unde pot obţine un link?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Ai fost deconectat de la serviciul CSGOFAST. Atinge ecranul pentru a te reconecta.',
  'App was offline. Check your internet connection to continue!':
    'Aplicația a fost offline. Verifică legătura ta la internet pentru a continua!',
  SAVE: 'SAVE',
};
