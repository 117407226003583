import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { TransactionItem } from '../TransactionItem';

import {
  ITransaction,
  ITransactionsItem,
} from '../../../interfaces/personalArea.reducer.interfaces';

interface ITransactionList {
  handleLoadingMore: (page: number) => void;
  transactions: ITransaction;
}

export const TransactionList: React.FC<ITransactionList> = ({
  transactions,
  handleLoadingMore,
}) => {
  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={handleLoadingMore}
      initialLoad={false}
      hasMore={transactions.hasMore}
    >
      {transactions.entities
        .valueSeq()
        .toArray()
        .map((transaction: ITransactionsItem) => (
          <TransactionItem key={transaction.id} transaction={transaction} />
        ))}
    </InfiniteScroll>
  );
};
