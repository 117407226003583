/* eslint-disable */

export default {
  'Fair game': 'Честная игра',
  BET: 'Ставка',
  AUTOSTOP: 'AUTOSTOP',
  min: 'мин.',
  max: 'макс.',
  '(min </minbet>, max </maxbet>)': '(мин. {{</minbet>}}, макс. {{</maxbet>}})',
  '(0 = Disabled)': '(0 = Выключено)',
  Stop: 'Стоп',
  'Wait for next round...': 'Подождите следующей игры...',
  Example: 'Например',
  'You won': 'Вы выиграли',
  "You've won ${currency(amount)} in the crash game #${number}!":
    'Вы выиграли ${currency(amount)} в краш-игре #{{ number  }}!',
  "You've bet ${currency(amount)} on the crash game #${game}!":
    'Вы поставили ${currency(amount)} в краш-игре #{{  game  }}!',
  Wait: 'Подождите',
  'You won!': 'Вы выиграли!',
  'Pick up winnings': 'Забрать выигрыш',
  '!! Crash !!': '!! Краш !!',
};
