export default {
  winning: 'Winning',
  line: 'Linha',
  lines: 'Linhas',
  total: 'Total',
  Autoplay: 'Reprodução automática',
  Start: 'Start',
  Paytable: 'Paytable',
  'Recent wins': 'Vitórias recentes',
  'Your games': 'Seus jogos',
  'Bonus game': 'Jogo de bônus',
  'Slot works regardless of location': 'Slot funciona independentemente da localização',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Pode substituir qualquer slot na linha (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    'Colecione os três slots e obtenha 10 jogos de bônus!',
  'Total won': 'Total ganho',
  'Bonus games': 'Jogos de bônus',
};
