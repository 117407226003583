import { useSelector } from 'react-redux';
import { getBaccaratHandsCard, getGameHash, getGameNumber, getGameSalt } from '../selectors';
import { IStore } from 'store/interface';
import { IGameHands } from '../interfaces';

export const useFairGame = () => ({
  number: useSelector<IStore, number>(getGameNumber),
  hash: useSelector<IStore, string>(getGameHash),
  salt: useSelector<IStore, string | null>(getGameSalt),
  cards: useSelector<IStore, IGameHands>(getBaccaratHandsCard),
});
