/* eslint-disable no-template-curly-in-string */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Title } from './header.styles';
import { useGetEventTime } from '../../../hooks';
import { Button } from '@components/Common/Buttons';

interface HeaderProps {
  handleCloseModal: () => void;
}

export const Header: FC<HeaderProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { time } = useGetEventTime();

  return (
    <Container>
      {typeof time.days === 'number' && (
        <Title>
          Dragons!{' '}
          {t('${count} ${left} ${days}', {
            count: time.days,
            left: t('pluralize-left', {
              count: time.days,
              postProcess: 'with-template',
            }),
            days: t('pluralize-days', {
              count: time.days,
              postProcess: 'with-template',
            }),
            postProcess: 'with-template',
          })}
        </Title>
      )}
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
    </Container>
  );
};
