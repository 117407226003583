import React, { Fragment } from 'react';
import { Container, TeamSpan } from './UserComponent.styles';
import { UserIcon } from './components';
import { getPlayersObjectFromArray } from 'games/CaseBattle/utils';
import { useGetSettings } from 'games/CaseBattle/hooks';
import { GameMember } from 'games/CaseBattle/interfaces';

interface UserComponentProps {
  players: GameMember[];
  playersInTeam: number;
  playersCount: number;
  battleId: number;
  isJoker: boolean;
}

export const UserComponents: React.FC<UserComponentProps> = React.memo(
  ({ players, playersCount, playersInTeam, battleId, isJoker }) => {
    const { winnerSplit } = useGetSettings(battleId);

    const playersArray = new Array(playersCount / playersInTeam)
      .fill(0)
      .map((_, index) => index + 1);

    const playersInTeamArray = new Array(playersInTeam)
      .fill(0)
      .map((_, index) => ({ count: index }));

    const playersObj = getPlayersObjectFromArray(players);

    return (
      <Container>
        {playersArray.map(value => {
          return playersInTeamArray.map(({ count }, index) => {
            return (
              <Fragment key={`${value}${count}${index}`}>
                {playersObj[value] && playersObj[value][count] ? (
                  <UserIcon
                    battleId={battleId}
                    userIcon={playersObj[value][count].ava_link}
                    userId={playersObj[value][count].user_id}
                    userName={playersObj[value][count].name}
                    isJoker={isJoker}
                  />
                ) : (
                  <UserIcon battleId={battleId} isJoker={isJoker} />
                )}
                {playersInTeam === 2 && count === 1 && value === 1 && (
                  <TeamSpan>
                    {winnerSplit ? (
                      <>
                        VS <br />
                        TEAM
                      </>
                    ) : (
                      'VS'
                    )}
                  </TeamSpan>
                )}
              </Fragment>
            );
          });
        })}
      </Container>
    );
  }
);
