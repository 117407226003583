import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import logo from 'assets/images/logo-go.png';
import loading from 'assets/images/loading.gif';
import { useHistory } from 'react-router-dom';
import { useDrawerNavigation } from '../../Drawer/hooks';
// import { loader } from 'modules/event-module';

export const Overlay: FC = () => {
  const { t } = useTranslation();
  const { handleOpenDesktop } = useDrawerNavigation();
  const history = useHistory();

  return (
    <Container ignore={history.location.pathname === '/poggi'}>
      <img src={loading} alt="" width="150px" height="71px" />
      <img src={logo} alt="" width="150px" height="41px" />
      <Button className="primary small" as="a" onClick={handleOpenDesktop}>
        {t('Go to site')}
      </Button>
      <span>
        {t(
          'Unfortunately screen rotation is not supported. For the correct display, rotate your device to a vertical position and refresh the page.'
        )}
      </span>
    </Container>
  );
};

type IOverlayStyle = {
  ignore: boolean;
};

const Container = styled.div<IOverlayStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 8000;
  background: #1a272b;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 10ms ease-in;

  ${p =>
    !p.ignore &&
    `
    @media screen and (orientation: portrait) and (min-width: 700px) {
      opacity: 1;
      pointer-events: auto;
    } 
    @media screen and (orientation: landscape) and (min-width: 800px), (max-height: 500px) {
      opacity: 1;
      pointer-events: auto;
    }
  `}

  & a,
  & span,
  & img {
    justify-self: center;
  }

  & img {
    width: 150px;
  }

  & span {
    display: none;
    text-align: center;
    max-width: 12.5rem;
    color: var(--color-white);
    font-size: 0.8125rem;

    @media screen and (orientation: landscape) {
      display: inline-block;
      max-width: 19.5rem;
    }
  }

  & a {
    display: none;
    @media screen and (min-width: 700px) and (orientation: portrait) {
      display: flex;
    }
  }
`;
