import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(min-content, 1fr);
  grid-template-rows: 40px;
  background: var(--color-dark-layout);
  overflow-x: scroll;
  border-bottom: 1px solid var(--color-gray-900);

  &::-webkit-scrollbar {
    display: none;
  }

  & > a {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    color: var(--color-white-600);
    white-space: nowrap;
    padding: 0 0.5rem;
    position: relative;

    &::before {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: var(--color-white);
    }

    &.active {
      color: var(--color-white);

      &::before {
        content: '';
      }
    }
  }
`;
