import { durationFastRace } from '../../account.utils';

export const data = {
  labels: Array.from({ length: 14 }, (_, i) => i + 1),
  datasets: [
    {
      label: 'Your',
      pointRadius: 2,
      pointBorderWidth: 1,
      pointBorderColor: '#74FF0C',
      pointBackgroundColor: '#182328',
      backgroundColor: '#74FF0C',
      borderColor: '#74FF0C',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
      order: 1,
    },
    {
      label: 'Player',
      pointRadius: 2,
      pointBorderWidth: 1,
      pointBorderColor: '#FFFFFF',
      pointBackgroundColor: '#182328',
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF',
      borderWidth: 1,
      fill: false,
      lineTension: 0,
    },
  ],
};

export const options = {
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          max: 72,
          min: 0,
          stepSize: 1,
          backdropColor: 'transparent',
        },
        beginAtZero: true,
        gridLines: {
          drawTicks: false,
          drawBorder: false,
          color: 'transparent',
          zeroLineColor: '#3A454B',
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        ticks: {
          display: true,
          drawBorder: false,
        },
        gridLines: {
          display: false,
          drawOnChartArea: false,
          zeroLineColor: '#3A454B',
        },
      },
    ],
  },
  layout: {
    padding: {
      top: 10,
      bottom: 10,
    },
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    duration: 1000,
  },
};

export const chartData = (statistics: any, accountId: string, userId: string | null) => {
  const accDataset = statistics.get(accountId).reduce(
    (acc: (number | null)[], item: any, i: number) => {
      acc[i] = acc[i - 1] ? acc[i - 1] + item.tickets.total : item.tickets.total;

      return acc;
    },
    [...durationFastRace]
  );

  const userDataset =
    statistics.get(userId)?.reduce(
      (acc: (number | null)[], item: any, i: number) => {
        acc[i] = acc[i - 1] ? acc[i - 1] + item.tickets.total : item.tickets.total;
        return acc;
      },
      [...durationFastRace]
    ) || [];

  return {
    data: {
      datasets: [
        {
          data: accDataset,
        },
        {
          data: userDataset,
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              max: Math.max(...accDataset, ...userDataset) + 1,
            },
          },
        ],
      },
    },
  };
};
