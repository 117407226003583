import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from '@components/Common';
import { SearchInput } from '@UIkit';
import { IDialogComponent } from 'core/interfaces';
import { ReferralValidators } from 'core/utils/Validators';
import { useCreateReferralCampaigns } from '../../../../hooks';

interface ICreateReferralCompanyDialog extends IDialogComponent<boolean> {}

export const CreateReferralCompanyDialog: FC<ICreateReferralCompanyDialog> = ({
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  const { handleCreateCampaigns } = useCreateReferralCampaigns();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
    },
    criteriaMode: 'firstError',
  });

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <div className="dialog-header">{t('Create a campaign')}</div>
      <div className="referral-name-field">
        <label>{t('Campaign name')}</label>
        <SearchInput
          name="name"
          autoComplete="off"
          className={errors?.name?.type && 'error'}
          ref={register({
            minLength: 3,
            required: true,
          })}
        />
        {errors?.name?.type && (
          <span className="invalid-feedback">{t(`invalid-feedback.${errors?.name?.type}`)}</span>
        )}
      </div>
      <div className="referral-promo-field">
        <label>{t('Campaign promocode')}</label>
        <SearchInput
          name="code"
          autoComplete="off"
          className={errors?.code?.type && 'error'}
          ref={register({
            required: true,
            validate: {
              checkCode: async (code: string) => await ReferralValidators.checkCode(code),
            },
          })}
        />
        {errors?.code?.type && (
          <span className="invalid-feedback">{t(`invalid-feedback.${errors?.code?.type}`)}</span>
        )}
      </div>
      <div className="referral-controller">
        <Button type="submit" className="primary" onClick={handleSubmit(handleCreateCampaigns)}>
          {t('Continue')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 1.75rem;
  border-top: 1px solid var(--color-gray-900);
  background: var(--color-dark-600);
  position: relative;

  & .close {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }

  & .dialog-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 3.25rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    color: var(--color-white);
  }

  & .referral-name-field,
  & .referral-promo-field {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.9375rem;
    padding: 0 1rem 0.25rem;
    position: relative;

    & label {
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
    }

    & .invalid-feedback {
      position: absolute;
      bottom: -0.875rem;
      left: 1.125rem;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      color: var(--color-red-100);
    }
  }

  & .referral-controller {
    padding: 1.25rem 1rem 1.75rem;
  }
`;
