import React, { memo } from 'react';
import { SECTORS } from '../../../../utils';
import { GameRouletteGradientReel } from './game-roulette-gradient-reel';

export const GameRouletteReel = memo(() => (
  <GameRouletteGradientReel>
    {SECTORS.map((sector, idx) => (
      <svg
        key={sector}
        className={`sector-${sector}`}
        viewBox="0 0 287 287"
        style={{
          transform: `translate3d(-50%, -50%, 0) rotate(${idx * 24}deg)`,
        }}
      >
        <linearGradient
          id={`gradient-${sector}`}
          gradientUnits="userSpaceOnUse"
          x1="143.7"
          y1="231.1505"
          x2="143.7"
          y2="286.525"
        >
          <stop offset="0" stopColor="var(--stop-color-0)" />
          <stop offset="1" stopColor="var(--stop-color-1)" />
        </linearGradient>
        <path
          fill="var(--fill-color)"
          stroke="var(--stroke-color)"
          d="M153.5,231.2L153.5,231.2c1.8-0.2,3.6,0.2,5.1,1.3c1.5,1,2.5,2.6,2.9,4.4l8.4,39.6l0,0  c0.2,1,0.2,2,0,2.9c-0.2,1-0.6,1.9-1.1,2.7c-0.6,0.8-1.3,1.5-2.1,2s-1.8,0.9-2.7,1c-13.5,1.9-27.1,1.9-40.6,0c-1-0.1-1.9-0.5-2.7-1  c-0.8-0.5-1.6-1.2-2.1-2c-0.6-0.8-1-1.7-1.1-2.7c-0.2-1-0.2-2,0-2.9l0,0l8.4-39.6c0.4-1.8,1.4-3.3,2.9-4.4c1.5-1,3.3-1.5,5.1-1.3  l0,0l0,0C140.2,232,146.8,232,153.5,231.2z"
        />
        <text
          fontSize="24"
          fill="white"
          fontWeight="bold"
          textAnchor="middle"
          alignmentBaseline="auto"
          x="50%"
          y="97%"
        >
          {sector}
        </text>
      </svg>
    ))}
  </GameRouletteGradientReel>
));
