type IRequestOptions = {
  [key: string]: number[];
};

export const requestOptions: IRequestOptions = {
  games: [2, 4, 6, 8, 9, 10, 18, 19, 20, 21, 28, 29, 30, 31, 35, 36, 37, 38, 41, 42, 46, 47],
  skins: [1, 3, 7, 11, 39, 40],
  bonuses: [49, 51, 16, 17, 5],
};

export const transactionsTabConfig = [
  { name: 'All', options: 'all' },
  { name: 'balance_games', options: 'games' },
  { name: 'Deposit/Withdrawal', options: 'skins' },
  { name: 'Free Coins', options: 'bonuses' },
];
