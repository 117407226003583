import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RouterLink } from '@components/Common';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';

interface ICashbackLock {
  profileType: string;
}

export const CashbackLock: FC<ICashbackLock> = ({ profileType }) => {
  const { t } = useTranslation();
  const { handleOpenDialog, handleCloseDialog } = useDialog(userModals.PROFILE_PREMIUM);

  useEffect(() => {
    return () => handleCloseDialog();
  }, [handleCloseDialog]);

  return (
    <Container profileType={profileType}>
      <span className="smile">:(</span>
      <div className="account-type">
        {t('You have $t({{premiumType}}, { "context": "adjective"}) account', {
          premiumType: profileType,
        })}
      </div>
      <div className="description">
        {t(
          'We partially offsetting bets by cashback. Only players with a $t({{premiumType}}, { "context": "adjective"}) account and above can receive cashback.',
          {
            premiumType: 'silver',
          }
        )}
      </div>
      <RouterLink className="primary prepend-icon" onClick={handleOpenDialog}>
        {t('Improve account')}
      </RouterLink>
    </Container>
  );
};

interface ICashbackLockStyle {
  profileType: string;
}

const Container = styled.div<ICashbackLockStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.5rem;
  padding: 3rem 1.5rem;

  text-align: center;
  color: var(--color-white);

  & > * {
    justify-self: stretch;
  }

  & .smile {
    font-size: 1.8rem;
  }

  & .account-type {
    font-size: 0.7rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: ${p => `var(--color-account-type-${p.profileType})`};
  }

  & .description {
    font-size: 0.9rem;
    line-height: 1.43;
    letter-spacing: 0.5px;
  }
`;
