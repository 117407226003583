import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useGameNumber } from '../../../hooks';

export const GameNumber = () => {
  const number = useGameNumber();
  const { t } = useTranslation('x50');
  return (
    <Container>
      {t('Game')} #{number}
    </Container>
  );
};

const Container = styled.span`
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 0.875rem;
  letter-spacing: 0.3px;
  color: var(--color-gray-300);
  position: absolute;
  left: 50%;
  top: 3.875rem;
  z-index: 10;
  transform: translate3d(-50%, 0, 0);
`;
