import React from 'react';
import styled from 'styled-components';
import { useGameHistory } from '../../../hooks';
import { animated, useTransition } from 'react-spring';

export const GameHistory = () => {
  const { history } = useGameHistory();
  const items = useTransition(
    history.map(({ sector, id }, i) => ({ sector, x: i * 36, id })),
    d => d.id,
    {
      initial: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      from: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(0)`,
        opacity: 0,
      }),
      enter: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      update: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      leave: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(0)`,
        opacity: 0,
      }),
      unique: true,
    }
  );

  return (
    <Container>
      {items.map(({ item, props, key }) => (
        <animated.div key={key} style={props} className={`sector-${item?.sector}`}>
          {item?.sector}
        </animated.div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  height: 3.25rem;
  background: rgba(19, 26, 29, 0.9);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 100;
  border-top: 1px solid var(--color-gray-900);
  border-bottom: 1px solid var(--color-gray-900);

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    position: absolute;
    left: 0.875rem;
    background: var(--fill-color);

    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--color-white);
  }
`;
