import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { premiumOrders, IPremiumConfig } from '../../../modules/PersonalArea/personalArea.utils';
import { CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import { Container } from './profile-premium-content.styles';

interface IPremiumContent {
  selectPremium: IPremiumConfig;
  profileType: string;
}

export const PremiumContent: FC<IPremiumContent> = ({
  selectPremium: { type, permissions, get },
  profileType,
}) => {
  const { t } = useTranslation();

  return (
    <Container premiumType={type}>
      <div className="premium-heading">
        <span>
          {t(type, {
            context: 'adjective',
          })}
        </span>
        {t('account is:')}
      </div>
      {Object.keys(permissions).map((key: string) => (
        <div
          key={key}
          style={{
            color: permissions[key].lock ? 'var(--color-gray-100)' : 'var(--color-white)',
          }}
        >
          {t(permissions[key].text)}
          <span>
            {permissions[key].lock ? (
              <i className="icon-lock" />
            ) : (
              <>
                {permissions[key].status && t(permissions[key].status)}
                {permissions[key].currency && (
                  <CurrencyText value={permissions[key].currency} />
                )}{' '}
                {!(permissions[key].status || permissions[key].currency) && (
                  <i className="icon-checked-fill" />
                )}
              </>
            )}
          </span>
        </div>
      ))}
      {get && premiumOrders.indexOf(type) > premiumOrders.indexOf(profileType) && (
        <>
          <span className="premium-advice">
            {t(get.text, {
              postProcess: 'with-template',
              amount: get.currency,
            })}
          </span>
          <Button className="primary small full" to={'/refill'} as={Link}>
            {t(get.textBtn)}
          </Button>
        </>
      )}
    </Container>
  );
};
