import { combineReducers } from 'redux';
import { getAppNotifications } from './selectors';
import { getUser } from '../../core/User/selectors';

// Actions
const prefix = 'notifications/';
const SHOW_NOTIFICATION = `${prefix}SHOW_NOTIFICATION`;
const HIDE_NOTIFICATION = `${prefix}HIDE_NOTIFICATION`;
const ADD_NOTIFICATION = `${prefix}ADD_NOTIFICATION`;
const NOTIFICATION_WAS_SEEN = `${prefix}NOTIFICATION_WAS_SEEN`;
const REMOVE_NOTIFICATION = `${prefix}REMOVE_NOTIFICATION`;
const REMOVE_ALL_NOTIFICATIONS = `${prefix}REMOVE_ALL_NOTIFICATIONS`;
const SET_INITIAL_NOTIFICATIONS = `${prefix}SET_INITIAL_NOTIFICATIONS`;
const TOGGLE_DELETE_NOTICES = `${prefix}TOGGLE_DELETE_NOTICES`;
const CLOSE_DELETE_NOTICES = `${prefix}CLOSE_DELETE_NOTICES`;
const TOGGLE_CONFIRM_MODAL = `${prefix}TOGGLE_CONFIRM_MODAL`;
const TOGGLE_SILENCE_STATUS = `${prefix}TOGGLE_SILENCE_STATUS`;

// reducers

const notification = (state = {}, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return action.payload;
    }
    case HIDE_NOTIFICATION: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const notificationVisibleStatus = (state = false, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return true;
    }
    case HIDE_NOTIFICATION: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const notificationsByID = (state = {}, action) => {
  switch (action.type) {
    case SET_INITIAL_NOTIFICATIONS: {
      const notifications = action.payload;
      return notifications.reduce(
        (dictionary, value) => ({ ...dictionary, [value.addDate]: value }),
        state
      );
    }
    case ADD_NOTIFICATION: {
      const notification = action.payload;
      return {
        ...state,
        [notification.addDate]: { ...notification, seen: false },
      };
    }
    case NOTIFICATION_WAS_SEEN: {
      const notification = action.payload;
      return {
        ...state,
        [notification.addDate]: { ...notification, seen: true },
      };
    }
    case REMOVE_NOTIFICATION: {
      return Object.keys(state)
        .filter(key => key !== action.payload)
        .reduce((dictionary, key) => ({ ...dictionary, [key]: state[key] }), {});
    }
    case REMOVE_ALL_NOTIFICATIONS: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const notificationsIDs = (state = [], action) => {
  switch (action.type) {
    case SET_INITIAL_NOTIFICATIONS: {
      const notifications = action.payload;
      return notifications.map(value => value.addDate);
    }
    case ADD_NOTIFICATION: {
      const { addDate } = action.payload;
      return [...state, addDate];
    }
    case REMOVE_NOTIFICATION: {
      return state.filter(time => time !== action.payload);
    }
    case REMOVE_ALL_NOTIFICATIONS: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const confirmModalIsVisible = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CONFIRM_MODAL: {
      return !state;
    }
    case REMOVE_ALL_NOTIFICATIONS: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const silence = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SILENCE_STATUS: {
      return !state;
    }
    default: {
      return state;
    }
  }
};

const statusDeleteNotices = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_DELETE_NOTICES: {
      return !state;
    }
    case CLOSE_DELETE_NOTICES: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  confirmModalIsVisible,
  notification,
  notificationVisibleStatus,
  notificationsByID,
  notificationsIDs,
  statusDeleteNotices,
  silence,
});

export default reducer;

// action creators

export const toggleDeleteNotices = () => ({
  type: TOGGLE_DELETE_NOTICES,
});

export const closeDeleteNotices = () => ({
  type: CLOSE_DELETE_NOTICES,
});

export const init = steamid_3 => async dispatch => {
  const notifications = await localStorage.getItem(`@csgofastNotifications:${steamid_3}`);
  if (notifications !== null) {
    const notices = JSON.parse(notifications).slice(0, 40);
    dispatch({
      type: SET_INITIAL_NOTIFICATIONS,
      payload: notices,
    });
  }
};

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});

export const addNotification = notification => (dispatch, getStore) => {
  const addDate = Date.now();
  const { silence } = getStore().notifications;
  dispatch({
    type: ADD_NOTIFICATION,
    payload: { ...notification, addDate: addDate },
  });

  if (!silence) {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: { ...notification, addDate: addDate },
    });
  }
  dispatch(saveNotifications());
};

export const makeNotificationSeen = notification => dispatch => {
  dispatch({ type: NOTIFICATION_WAS_SEEN, payload: notification });
  dispatch(saveNotifications());
};

export const removeNotification = addDate => dispatch => {
  dispatch({ type: REMOVE_NOTIFICATION, payload: addDate });
  dispatch(saveNotifications());
};

export const removeAllNotifications = () => async (dispatch, getState) => {
  dispatch({ type: REMOVE_ALL_NOTIFICATIONS });
  try {
    const { steamid_3 } = getUser(getState());
    await localStorage.removeItem(`@csgofastNotifications:${steamid_3}`);
  } catch (e) {
    console.log(e);
  }
};

export const toggleConfirmModal = () => ({
  type: TOGGLE_CONFIRM_MODAL,
});

const saveNotifications = () => async (dispatch, getState) => {
  try {
    const notifications = getAppNotifications(getState());
    const { steamid_3 } = getUser(getState());
    await localStorage.setItem(
      `@csgofastNotifications:${steamid_3}`,
      JSON.stringify(notifications.slice(0, 40))
    );
  } catch (e) {
    console.log(e);
  }
};

export const toggleSilenceStatus = () => ({
  type: TOGGLE_SILENCE_STATUS,
});

export const eventsTypes = [{ event: 'notify:add', action: addNotification }];
