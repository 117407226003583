import { SaleConfigurationDialog } from './sale-configuration-dialog';
import { PurchaseDialog } from './purchase-dialog';
import { DIALOG, transitions } from 'dialogs';
import { MarketplaceDialogs } from '../../marketplace-dialogs.config';

DIALOG.add(MarketplaceDialogs.INVENTORY_SALE_CONFIGURATION, {
  component: SaleConfigurationDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});

DIALOG.add(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE, {
  component: PurchaseDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
