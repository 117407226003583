import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import betRedImg from '../../assets/betRed.png';
import betBlueImg from '../../assets/betBlue.png';
import winImg from '../../assets/win.png';

import { CardsList } from './CardsList';

const dataCardsValues = [
  {
    name: 'Face value',
    cards: [
      { value: 2, type: '♥' },
      { value: 3, type: '♦' },
      { value: 4, type: '♠' },
      { value: 5, type: '♣' },
      { value: 6, type: '♦' },
      { value: 7, type: '♥' },
      { value: 8, type: '♣' },
      { value: 9, type: '♠' },
    ],
  },
  {
    name: '0',
    cards: [
      { value: 10, type: '♦' },
      { value: 'J', type: '♣' },
      { value: 'Q', type: '♥' },
      { value: 'K', type: '♦' },
    ],
  },
  {
    name: '1',
    cards: [{ value: 'A', type: '♣' }],
  },
];

const dataCardsExample = [
  {
    name: '4',
    cards: [
      { value: 9, type: '♥' },
      { value: 5, type: '♠' },
    ],
  },
  {
    name: '2',
    cards: [
      { value: 'Q', type: '♣' },
      { value: 'K', type: '♦' },
      { value: 2, type: '♣' },
    ],
  },
  {
    name: '7',
    cards: [
      { value: 3, type: '♥' },
      { value: 4, type: '♣' },
    ],
  },
];

export const Rules = () => {
  const { t } = useTranslation('baccarat');
  return (
    <div className="content dark-layout">
      <RulesContainer>
        <Content>
          <Head>
            <Title>{t('Guess whi will win?')}</Title>
            <Title>{t('PLAYER or BANKER?')}</Title>
          </Head>

          <Name>
            <span>1.</span> {t('Bet')}
          </Name>
          <Flex>
            <Text>
              {t('The main bet could be placed on three fields -')}{' '}
              <TextColor className="bold" color="#00bad9">
                {' '}
                {t('PLAYER')}
              </TextColor>
              ,
              <TextColor className="bold" color="#f05228">
                {' '}
                {t('BANKER')}
              </TextColor>{' '}
              {t('or')}
              <TextColor className="bold" color="#39b701">
                {' '}
                {t('TIE')}
              </TextColor>
              .{' '}
              {t(
                'You can bet on Pair only if you have a bet on the main color corresponding to the selected pairs'
              )}
              <br />
              <br />
              {t('You can place bets on up to 3 fields maximum at the same time:')}
            </Text>
            <Image src={betRedImg} />
            <Desc>
              <TextColor color="#f05228"> {t('BANKER')}</TextColor>,{' '}
              <TextColor color="#f05228"> {t('BANKER PAIRS')}</TextColor> {t('and')}{' '}
              <TextColor color="#39b701"> {t('TIE')}</TextColor>
            </Desc>
          </Flex>

          <OR>{t('or').toUpperCase()}</OR>

          <Flex>
            <Image src={betBlueImg} />
            <Text>
              <TextColor color="var(--aqua-500)"> {t('PLAYER')}</TextColor>,{' '}
              <TextColor color="var(--aqua-500)"> {t('PLAYER PAIRS')}</TextColor> {t('and')}{' '}
              <TextColor color="#39b701"> {t('TIE')}</TextColor>
            </Text>
          </Flex>

          <Name>
            <span>2.</span> {t('Winner choosing')}
          </Name>
          <Flex>
            <Text>
              {t(
                'The goal of the game is to score points equal to 9 or as close as possible to this value The amount of points is calculated by adding the amount cards on the table'
              )}
            </Text>
            <Image src={winImg} />
            <Desc>
              <TextColor color="var(--aqua-500)"> {t('PLAYER')}</TextColor>{' '}
              <TextColor color="var(--yellow)">{t('WIN')}</TextColor>
            </Desc>
          </Flex>

          <Name>
            <span>3.</span> {t('Faces and values of cards')}
          </Name>
          <Flex>
            <Category>
              <CardsList data={dataCardsValues} />
            </Category>

            <Category>
              <Text>{t('Example')}</Text>

              <CardsList data={dataCardsExample} />
            </Category>
            {/*<Btn>{t('Detailed Rules')}</Btn>*/}
          </Flex>
        </Content>
      </RulesContainer>
    </div>
  );
};

const Category = styled.div`
  padding: 10px 0 10px 0;
  width: 100%;
`;

const Content = styled.div`
  max-width: 300px;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 1.85;
  color: var(--color-white);
  width: 100%;
  text-align: center;
`;

const Head = styled.div`
  padding-top: 10px;
`;

const TextColor = styled.span`
  color: ${p => p.color};
  text-transform: uppercase;

  &.bold {
    font-weight: bold;
  }
`;

const Image = styled.img`
  height: 236px;
  margin-top: 10px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: var(--color-white);
  max-width: 255px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const Name = styled.div`
  font-size: 20px;
  color: var(--color-white);
  width: 100%;
  padding-top: 20px;

  & span {
    color: var(--yellow);
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  flex-wrap: wrap;
`;

const Title = styled.div`
  color: var(--color-white);
  font-size: 20px;
  text-align: center;
`;

const OR = styled.div`
  color: var(--color-white);
  font-size: 20px;
  text-align: center;
  padding: 10px 0 20px 0;
`;

const RulesContainer = styled.div`
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
