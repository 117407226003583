import React, { FC, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { Icons } from 'modules/TOS/assets';
import { AnimatedContainer, ToggleIcon, Wrapper } from './Dropdown.styles';

interface DropdownProps {
  children: React.ReactNode;
  title: string;
  mainTitle?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({ children, title, mainTitle }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const heightElement = ref?.current?.offsetHeight ?? 26;
  const [hasRendered, setHasRendered] = useState(false);

  const handleOpenDropdown = () => {
    setHasRendered(true);
    setOpen(prev => !prev);
  };

  const springProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    maxHeight: open ? 5000 : heightElement,
  });

  return (
    <AnimatedContainer className="dropdown" style={springProps}>
      <h3 onClick={handleOpenDropdown} className={`${mainTitle ? 'mainTitle' : ''}`} ref={ref}>
        {title}
        <ToggleIcon open={open}>
          <Icons.ArrowIcon />
        </ToggleIcon>
      </h3>
      <Wrapper open={open} status={hasRendered ? (open ? 'show' : 'hide') : ''}>
        <div className={`details ${open ? 'show' : ''}`}>{children}</div>
      </Wrapper>
    </AnimatedContainer>
  );
};
