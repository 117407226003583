import React, { Fragment } from 'react';

import { currencyService } from '../../../services/currency/index';
import connect from 'react-redux/es/connect/connect';
import { getAppCurrency } from '../../../core/AppShell/selectors';

const mapStateToProps = state => ({
  currency: getAppCurrency(state),
});

const CurrencyValue = ({ value }) => {
  return <Fragment>{currencyService.display(value)}</Fragment>;
};

export const CurrencyText = connect(mapStateToProps)(CurrencyValue);
