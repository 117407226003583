import styled from 'styled-components';

export const Wrapper = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  width: 100%;
`;

export const ContainerGame = styled.div`
  background: var(--color-dark-600);
  border-top: 1px solid var(--gray-400);
  padding: 24px 16px;
`;

export const Title = styled.div`
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const BlockContainer = styled.div<{ isActive: boolean }>`
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: var(--color-white);
  padding: 10px 12px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--color-dark-200);
  transition: background-color 300ms ease;

  ${({ isActive }) => isActive && 'background-color:  var(--color-white-gray);'}
`;

export const GameTypesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  padding: 14px 0 28px;
`;

export const ButtonsType = styled.div`
  display: flex;
  gap: 11px;
  margin-top: 20px;
`;
