/* eslint-disable */
export default {
  Error: 'Błąd',
  'Error placing a bet': 'Podczas składania zakładu wystąpił błąd',
  'Error reserving an item': 'Błąd podczas rezerwacji pozycji',
  'Link saved successfully': 'Link pomyślnie zapisano',
  "You've successfully updated a bet": 'Twój zakład został pomyślnie zaktualizowany',
  "You've successfully sell winning item": 'Zwycięska pozycja została pomyślnie sprzedana',
  "You've successfully take winning item": 'Zwycięska pozycja została pomyślnie przyjęta',
  'Incorrect link. Enter the correct link and try again.':
    'Nieprawidłowy link. Wprowadź poprawny link i spróbuj ponownie.',
  'This is not your link. Enter your link and try again.':
    'To nie jest Twój link. Wprowadź swój i spróbuj jeszcze raz',
  "Don't forget to make your inventory public to get your winnings!":
    'Nie zapomnij o udostępnienie ekwipunku, aby otrzymać wygraną!',
  'min. number of items per trade not exceeded':
    'min. liczba pozycji w jednym zakładzie nie została przekroczona',
  'Too many requests, try again later': 'Zbyt dużo żądań, spróbuj później',
  'cheating attempt': 'próba oszustwa',
  'max. number of items per trade exceeded': 'zbyt dużo przedmiotów w ofercie',
  'player not registered on website': 'gracz nie jest zarejestrowany na stronie',
  'souvenir items are not allowed': 'przedmioty upominkowe są zabronione',
  'some of the items cannot be appraised': 'nie można wycenić niektórych przedmiotów',
  'some of the items are below minimum price': 'niektóre przedmioty są poniżej minimalnej ceny',
  'total price of a trade is below minimum': 'cena oferty jest poniżej minimalnej',
  'Your trade has been accepted!': 'Twoją ofertę zaakceptowano!',
  'The items have been added to the queue.': 'Przedmioty dodano do kolejki.',
  'Your trade has been checked out!': 'Twoją ofertę sprawdzono!',
  'It is currently being accepted': 'W tej chwili jest akceptowana.',
  'Unable to send a trade': 'Nie można wysłać oferty',
  'Update your trade link in the settings of the site':
    'Aktualizuj link do swojej oferty w ustawieniach strony',
  'Enter correct trade link and make sure your inventory is not full.':
    'Wprowadź prawidłowy link do oferty i upewnij się, że Twój ekwipunek nie jest przepełniony',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Wymiana jest niedostępna na Twoim koncie. Więcej informacji pojawi się, gdy zaprosisz kogoś do wymiany.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam jest w tym momencie niedostępny. Prosimy spróbować ponownie później.',
  'Not enough money on balance': 'Brakuje punktów na saldzie',
  'Not enough points on balance': 'Brakuje punktów na saldzie',
  'Game is ending, try again later': 'Gra się kończy, spróbuj później',
  'Congratulations!': 'Gratulacje!',
  "You've successfully placed a bet": 'Pomyślnie zrobiłeś zakład',
  "You've successfully placed a bet with </amount>":
    'Pomyślnie utworzyłeś zakład na kwotę {{</amount>}}',
  'Item has not been found, coins have been returned': 'Brak przedmiotu, monety zostaną zwrócone',
  'Your order in the store has been cancelled': 'Twoje zamówienie w sklepie zostało anulowane',
  'The min bet is </minBet>': 'Minimalny zakład wynosi {{</minBet>}}',
  'The max bet is </maxBet>': 'Maksymalny zakład jest {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Nie możesz obstawić jednocześnie czerwonego i czarnego',
  "You don't have CSGOFAST.com in Steam nickname!":
    'Nie posiadasz CSGOFAST.com w nazwie użytkownika Steam!',
  'You have exceeded the limit of 150 free coins per account':
    'Limit przydzielanych 150 darmowych monet na konto został przez Ciebie przekroczony',
  "You've bet </amount> on the crash game #</game>!":
    'Obstawiono {{</amount>}} na grę awaryjną #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Współczynnik został zaktualizowany do wartości {{</coef>}} dla gry awaryjnej #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'Wygrano {{</amount>}} w grze awaryjnej #{{</number>}}!',
  'Code for </amount> has been created': 'Kod dla {{</amount>}} został utworzony',
  'You already have an item': 'Posiadasz już tę pozycję',
  '</amount> refilled on your balance': 'Twój rachunek został doładowany kwotą {{</amount>}}',
  'We will sent it to your Steam in few moments': 'Za chwilę wyślemy ją na Twoje konto Steam',
  'Your items has been sended to game!': 'Twoje pozycje zostały wysłane do gry!',
  '</amount> have been returned': 'Zostało zwrócone {{</amount>}}',
  'Item has not been found, retry in few minutes (</amount>)':
    'Pozycja nie została znaleziona; spróbuj ponownie za kilka minut ({{</amount>}})',
  'It is currently being processing by game bots': 'Są aktualnie przetwarzane przez boty',
  '</amount> have been refilled': 'Doładowano kwotą {{</amount>}}',
  'Request successfully created': 'Prośba skutecznie utworzona',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Twoje zamówienie #{{</id>}} na kwotę {{</amount>}} zostało zaakceptowane, czekaj na transakcje na Steamie.',
  'Reason of order rejection': 'Powód odrzucenia zamówienia',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Twój zakład za {{</amount>}} został zaakceptowany i zostanie dodany do gry w ciągu {{</time>}} sekund',
  'Your bet was added to queue, in few seconds will added to the game':
    'Twój zakład został dodany do kolejki, w ciągu kilku sekund zostanie dodany do gry',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Wypłacanie jest zablokowane. Wygraj jeszcze ${currency(amount)}, by je odblokować',
    },
  },
};
