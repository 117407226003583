import React from 'react';
import { useTranslation } from 'react-i18next';
import { RulesContainer, RulesP, RulesSpan } from './Rules.styles';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <RulesContainer>
        <RulesP>
          <RulesSpan>{t('How is this mode different from others?')} </RulesSpan>
          <RulesP>
            {t(
              'СASES – is a fun mode where you can spend coins to open cases and win great skins. Everyone loves it!'
            )}
          </RulesP>
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How do I open a case?')}</RulesSpan>
          {t(
            'Opening a case is simple. Just select your preferred case of skins and press Open. When the animation stops, a red line will indicate which skin you have won.'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('What can I do with the skin I won?')} </RulesSpan>
          {t(
            "Once you've opened the case and received your skin, you can add it to your Steam inventory by pressing the Claim button. Alternatively, you can sell the skin to get an immediate coin reward credited to your account on our website and continue playing."
          )}
        </RulesP>
      </RulesContainer>
    </div>
  );
};
