import styled from 'styled-components';

interface IPayoutInputStyle {
  error: boolean;
}

export const Container = styled.div<IPayoutInputStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1.1875rem min-content 1rem;
  grid-gap: 0.9375rem;

  & .input-subtitle,
  & .input-error {
    color: var(--color-gray-100);
    display: block;
    font-size: 0.625rem;
    font-weight: bold;
    left: 0;
    letter-spacing: 0.4px;
    line-height: 12px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
  }

  & .input-title {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1.375rem 1fr;
    grid-template-rows: 1.375rem;
    grid-gap: 0.5rem;

    & img {
      width: 1.375rem;
      height: 1.375rem;
      margin-right: 0.5rem;
    }

    & span {
      color: var(--color-white-600);
      font-size: 0.75rem;
      line-height: 1.375rem;
      text-transform: uppercase;
      letter-spacing: 0.4px;
    }
  }

  & input {
    width: 100%;
    padding: 0.825rem;
    height: 2.75rem;
    background: white;
    border: 2px solid rgba(24, 35, 40, 0.4);
    box-sizing: border-box;
    border-radius: var(--size-radius-m);
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--color-dark-100);

    &.validated {
      border-color: var(--green-200);
    }

    &::placeholder {
      color: var(--color-gray-200);
    }
  }

  & div {
    position: relative;
  }

  & .input-error {
    color: var(--color-red);
    transition: opacity 70ms cubic-bezier(0.33, 1, 0.68, 1);
    opacity: ${p => (p.error ? 1 : 0)};
  }

  & .input-subtitle {
    opacity: ${p => (p.error ? 0 : 1)};
  }
`;

export const Types = styled.div`
  display: flex;
  gap: 4px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Type = styled.div<{ isActive: boolean }>`
  align-items: center;
  background: ${({ isActive }) => (isActive ? 'var(--color-white-gray)' : 'var(--color-dark-200)')};
  border-radius: var(--size-radius-m);
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  height: 48px;
  justify-content: center;
  padding: 10px 40px;
  width: 100px;
`;
