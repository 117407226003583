import React from 'react';
import { Container } from './MotivatorItem.styles';
import { useDialog } from 'core/hooks';
import { CaseMotivatorDialogConfig } from 'games/CaseGame/dialog-config';
import { ProfileEnum } from 'games/CaseGame/constants';
import { CSSTransition } from 'react-transition-group';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface MotivatorItemProps {
  imgPath: string;
  user: {
    id: number;
    avatar: string;
    name: string;
    profileType: ProfileEnum;
    level: number;
  };
  caseImg: string;
  caseId: number;
  uuid: string;
  casePrice: number;
  isBattle?: boolean;
  id: number;
}

export const MotivatorItem: React.FC<MotivatorItemProps> = React.memo(
  ({ imgPath, user, caseId, caseImg, casePrice, uuid, isBattle, id }) => {
    const { handleToggleDialog } = useDialog(CaseMotivatorDialogConfig.MOTIVATOR_DIALOG);
    return (
      <CSSTransition
        onClick={handleToggleDialog(CaseMotivatorDialogConfig.MOTIVATOR_DIALOG, {
          user,
          caseId,
          caseImg: caseImg,
          casePrice,
          uuid,
          isBattle,
          id,
        })}
        in={!!caseImg}
        classNames={'motivator-item'}
        timeout={1500}
        appear
      >
        <Container>
          <img src={skinItemImage(imgPath)} alt="Item" />
        </Container>
      </CSSTransition>
    );
  }
);
