import styled from 'styled-components';
import { animated } from 'react-spring';

export const AnimatedDiv = styled(animated.div)`
  bottom: 0;
  position: fixed;
  transform-origin: center;
  width: 100%;
  will-change: transform, opacity;
  z-index: 2400;

  &.FULL_SCREEN {
    height: 100%;
  }

  &.SHOW_NAV {
    background: var(--color-light-dark-layout);
    height: calc(100% - 42px);
  }

  &.SLIDE_UP_CENTER,
  &.SCALABLE {
    left: 1.5rem;
    right: 1.5rem;
    width: auto;
  }

  & + div {
    z-index: 2500;

    & + div {
      z-index: 3500;
    }
  }
`;

export const AnimatedLayout = styled(animated.div)`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100%;
  padding-top: var(--size-nav-bar);
  position: fixed;
  width: 100%;
  z-index: 2000;

  &.SHOW_NAV {
    height: calc(100% - 42px);

    &.close {
      height: 100%;
    }
  }
`;
