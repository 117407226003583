import React, { useMemo } from 'react';
import { SoldStatus, rouletteStatusActionBeforeRollAll } from 'games/CaseGame/ducks';
import {
  useGetOpenCaseType,
  useGetRouletteStatus,
  useGetWinItemsStatus,
  useGetWinningItem,
} from 'games/CaseGame/hooks';
import { useDispatch } from 'react-redux';
import { Container, IconContainer } from './ButtonDecoratorSellDemo.styles';
import { SvgIcons } from 'games/CaseGame/assets';
import { SellButton } from '../SellButton';
import { ButtonDemoRollAgain } from '../ButtonDemoRollAgain';

interface ButtonDecoratorProps {
  lineCount: number;
}

export const ButtonDecorator: React.FC<ButtonDecoratorProps> = ({ lineCount }) => {
  const { isAllAfterRoll, isAllStartRoll } = useGetRouletteStatus();
  const { winItemsStatus, isAllSold } = useGetWinItemsStatus();
  const { openCaseType } = useGetOpenCaseType();
  const { winningItems } = useGetWinningItem();
  const dispatch = useDispatch();

  const winningItemsPrice = winningItems
    ?.map(winningItem => winningItem.winningPrice)
    .filter((_, index) => winItemsStatus[index] === SoldStatus.INIT)
    ?.reduce((acc, curr) => acc + curr, 0);
  const winningItemsIds = useMemo(
    () =>
      winningItems
        ?.map(winningItem => winningItem.userInventoryItemId)
        .filter((_, index) => winItemsStatus[index] === SoldStatus.INIT),
    [winningItems, winItemsStatus]
  );

  const clickHandler = () => {
    dispatch(rouletteStatusActionBeforeRollAll());
  };

  return (
    <Container
      data-roulette-status={
        !isAllAfterRoll || isAllSold || isAllStartRoll || openCaseType === 'replay'
          ? 'hide'
          : 'show'
      }
    >
      <IconContainer>
        <SvgIcons.ArrowBack onClick={clickHandler} />
      </IconContainer>
      {openCaseType === 'payable' ? (
        <SellButton winningItemsPrice={winningItemsPrice} winningItemsIds={winningItemsIds} />
      ) : (
        <ButtonDemoRollAgain lineCount={lineCount} />
      )}
    </Container>
  );
};
