/* eslint-disable */

export default {
  Error: '错误',
  'Error placing a bet': '錯誤下注',
  'Error reserving an item': '預定商品出現錯誤',
  'Link saved successfully': '链接已成功保存',
  "You've successfully updated a bet": '成功更新賭注',
  "You've successfully sell winning item": '成功售出贏家道具',
  "You've successfully take winning item": '成功取得贏家道具',
  'Incorrect link. Enter the correct link and try again.': '不正確的連結。輸入正確連結以再試一次。',
  'This is not your link. Enter your link and try again.': '这不是您的链接。请输入您的链接并重试。',
  "Don't forget to make your inventory public to get your winnings!":
    '不要忘记将您的库存设为公开以接收奖品！',
  'min. number of items per trade not exceeded': '未超過每筆交易最低道具數量',
  'Too many requests, try again later': '请求过多，请稍后再试',
  'cheating attempt': '企图作弊',
  'max. number of items per trade exceeded': '超出每笔交易的最大道具数',
  'player not registered on website': '玩家未在网站上注册玩家未在网站上注册',
  'souvenir items are not allowed': '不允许使用纪念道具',
  'some of the items cannot be appraised': '部分道具无法估价部分道具无法估价',
  'some of the items are below minimum price': '部分道具低于最低价格部分道具低于最低价格',
  'total price of a trade is below minimum': '交易总价低于最小值交易总价低于最小值',
  'Your trade has been accepted!': '您的交易已被接受',
  'The items have been added to the queue.': '道具已添加到队列中。',
  'Your trade has been checked out!': '您的交易已结账！',
  'It is currently being accepted': '交易正被接受',
  'Unable to send a trade': '无法发送交易',
  'Update your trade link in the settings of the site': '在网站的设置中更新您的交易链接',
  'Enter correct trade link and make sure your inventory is not full.':
    '输入正确的交易链接并确保您的库存未满',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    '您的帳號無法進行交易。若您邀請某人進行交易將會顯示更多資訊。',
  'Steam is unavailable at this moment. Please wait for retry.': 'Steam 目前無法使用。請稍後再試。',
  'Not enough money on balance': '剩余快点不足',
  'Not enough points on balance': '剩余快点不足',
  'Game is ending, try again later': '游戏即将结束，请稍后再试',
  'Congratulations!': '恭喜！',
  "You've successfully placed a bet": '您已成功下注',
  "You've successfully placed a bet with </amount>": '您已成功下注 {{</amount>}}',
  'Item has not been found, coins have been returned': '缺少道具，正返回硬币',
  'Your order in the store has been cancelled': '您在商店中的订单已取消',
  'The min bet is </minBet>': '最小赌注为 {{</minBet>}} 个硬币。',
  'The max bet is </maxBet>': '最大下注是 {{</maxBet>}}硬幣。',
  'You cannot place bet on red and black at same time': '不能同時於紅區及黑區下注',
  "You don't have CSGOFAST.com in Steam nickname!": '您的Steam暱稱中沒有CSGOFAST.com！',
  'You have exceeded the limit of 150 free coins per account':
    '您已經超出每個賬戶 150 免費幣的限制',
  "You've bet </amount> on the crash game #</game>!":
    '您在爆炸時刻 #{{</game>}}下注了 ${{</amount>}} 枚金幣！',
  "You've updated coef to </coef> for crash game #</game>!":
    '已更新爆炸時刻 #{{</game>}} 係數為{{</coef>}}！',
  "You've won </amount> in the crash game #</number>!":
    '您在爆炸時刻#{{</number>}} 贏得了 ${{</amount>}} 枚金幣！',
  'Code for </amount> has been created': '已建立 {{</amount>}} 枚金幣代碼',
  'You already have an item': '您已有一件道具',
  '</amount> refilled on your balance': '{{</amount>}} 儲值至你的餘額',
  'We will sent it to your Steam in few moments': '稍待片刻，我們將傳至您的Steam',
  'Your items has been sended to game!': '您的道具已送至遊戲！',
  '</amount> have been returned': '{{</amount>}} 已被退回',
  'Item has not been found, retry in few minutes (</amount>)':
    '查無道具，請稍待幾分鐘後重試({{</amount>}})',
  'It is currently being processing by game bots': '目前正由遊戲機器人處理中',
  '</amount> have been refilled': '已儲值 {{</amount>}}',
  'Request successfully created': '要求成功產生',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    '你的訂單 #{{</id>}} 金額 {{</amount>}} 被接受，在 Steam 中等候交易。',
  'Reason of order rejection': '訂單遭拒絕的理由',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    '你投注的 {currency(amount)} 被接受並且會在 </time> 秒內加至遊戲中',
  'Your bet was added to queue, in few seconds will added to the game':
    '你的投注被加至佇列，會在幾秒鐘內被加至遊戲',
};
