export default {
  '/settings': 'Ayarlar',
  '/marketplace': 'Pazar',
  '/balance': 'Bakiye',
  '/auth': 'Yetki',
  '/notifications': 'Bildirimler',

  RULES: 'KURALLAR',
  Chat: 'Sohbet',
  GAME: 'OYUN',
  Top: 'En İyi',
  HISTORY: 'GEÇMİŞ',
};
