import {
  IMarketplaceElement,
  IParticipantResponse,
  IStoreDeletedResponse,
  IStoreUpdateResponse,
} from './marketplace.reducer.interfaces';

export enum MarketplaceActionTypes {
  MARKETPLACE_ATTACH_LISTENERS = 'app/marketplace/MARKETPLACE_ATTACH_LISTENERS',
  MARKETPLACE_DETACH_LISTENERS = 'app/marketplace/MARKETPLACE_DETACH_LISTENERS',

  MARKETPLACE_PARTICIPANT_UPDATE = 'app/marketplace/MARKETPLACE_PARTICIPANT_UPDATE',

  MARKETPLACE_STORE_ITEM_UPDATE = 'app/marketplace/MARKETPLACE_STORE_ITEM_UPDATE',
  MARKETPLACE_STORE_ITEMS_UPDATE = 'app/marketplace/MARKETPLACE_STORE_ITEMS_UPDATE',

  MARKETPLACE_STORE_ITEM_DELETED = 'app/marketplace/MARKETPLACE_STORE_ITEM_DELETED',
  MARKETPLACE_STORE_ITEMS_DELETED = 'app/marketplace/MARKETPLACE_STORE_ITEMS_DELETED',

  MARKETPLACE_HOT_OFFERS_UPDATE = 'app/marketplace/MARKETPLACE_HOT_OFFERS_UPDATE',

  CHECK_STATUS_STEAM = 'app/marketplace/CHECK_STATUS_STEAM',
  UPDATE_STEAM_STATUS = 'UPDATE_STEAM_STATUS',
}

export interface IAttachListener {
  type: typeof MarketplaceActionTypes.MARKETPLACE_ATTACH_LISTENERS;
}

export interface IDetachListener {
  type: typeof MarketplaceActionTypes.MARKETPLACE_DETACH_LISTENERS;
}

export interface IMarketplaceParticipantUpdate {
  type: typeof MarketplaceActionTypes.MARKETPLACE_PARTICIPANT_UPDATE;
  payload: IParticipantResponse;
}

export interface IMarketplaceStoreItemUpdate {
  type: typeof MarketplaceActionTypes.MARKETPLACE_STORE_ITEM_UPDATE;
  payload: IStoreUpdateResponse;
}

export interface IMarketplaceStoreItemsUpdate {
  type: typeof MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_UPDATE;
  payload: {
    items: IStoreUpdateResponse[];
  };
}

export interface IMarketplaceStoreItemDeleted {
  type: typeof MarketplaceActionTypes.MARKETPLACE_STORE_ITEM_DELETED;
  payload: IStoreDeletedResponse;
}

export interface IMarketplaceStoreItemsDeleted {
  type: typeof MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_DELETED;
  payload: {
    items: IStoreDeletedResponse[];
  };
}

export interface IMarketplaceHotOffersUpdate {
  type: typeof MarketplaceActionTypes.MARKETPLACE_HOT_OFFERS_UPDATE;
  payload: {
    kits: IMarketplaceElement[];
    count: number;
  };
}

export type marketplaceActions = IAttachListener | IDetachListener;

export type steamStatus = 'normal' | 'delayed' | 'surge' | 'offline';
interface IUpdateSteamStatus {
  type: MarketplaceActionTypes.UPDATE_STEAM_STATUS;
  payload: {
    status: steamStatus;
  };
}

export type steamActions = IUpdateSteamStatus;
