import { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShopFilter } from '../../selectors';
import { changeShopFilter } from '../../ducks';
import { IStore } from 'store/interface';
import { IFiltersRecord, MergeType } from '../../interfaces';

export const useShopQueryFilter = () => {
  const dispatch = useDispatch();
  const query = useSelector<IStore, IFiltersRecord['query']>(getShopFilter('query'));

  const changeQuery = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      dispatch(changeShopFilter('query', ev.target.value, MergeType.RESET));
    },
    [dispatch]
  );

  return {
    query,
    changeQuery,
  };
};
