import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { refreshStore } from '../../ducks/store.duck';

export const useStoreRefresh = () => {
  const dispatch = useDispatch();

  const refreshStoreAction = useCallback(() => {
    dispatch(refreshStore());
  }, [dispatch]);

  return {
    refreshStoreAction,
  };
};
