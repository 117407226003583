import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { SearchInput } from '@UIkit';
import { Button, NavigationLink } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import {
  useStoreFilters,
  useStoreMetaData,
  useStorePriceFilter,
  useStoreQueryFilter,
  useStoreSelectedFilters,
} from '../../hooks';
import { StoreFiltersConfig } from '../../configs';
import { MarketplaceDialogs } from '../../marketplace-dialogs.config';

export const FilterPage: FC<RouteComponentProps> = () => {
  const { t } = useTranslation('marketplace');
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.MARKETPLACE_OVERPRICE_FILTER);
  const { filters } = useStoreFilters();
  const { changeQuery, resetQuery } = useStoreQueryFilter();
  const { changePrice } = useStorePriceFilter();
  const { meta, loading } = useStoreMetaData();
  const { selected, resetSelected } = useStoreSelectedFilters();

  return (
    <>
      <Container className="content dark-layout">
        <div className="search-container">
          <SearchInput
            type="text"
            value={filters.get('query')}
            onChange={changeQuery}
            placeholder={t('Search items...')}
          />
          <button className="close" onClick={resetQuery}>
            <i className="icon-close" />
          </button>
        </div>
        <div className="clean-up-filters">
          <span>
            {t('Filters selected')}
            {' : '}
            {selected}
          </span>
          <span className={`${selected ? '' : 'hide'}`} onClick={resetSelected}>
            {t('Reset')}
          </span>
        </div>
        <div className="detailed-filters">
          <div className="price">
            <div className="price-title">{t('Price range')}</div>
            <div className="price-selector">
              {Array.from(StoreFiltersConfig.prices.entries()).map(([key, [from, to]]) => (
                <button
                  key={key}
                  onClick={changePrice(key)}
                  disabled={from === filters.get('minPrice') && to === filters.get('maxPrice')}
                >
                  {from === null && to === null && <>{t(key)}</>}
                  {!!from && !to && <>{t('from')} </>}
                  {from !== null && <CurrencyText value={from} />}
                  {!!to && ' — '}
                  {to !== null && <CurrencyText value={to} />}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="detailed-filters">
          <NavigationLink
            onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_OVERPRICE_FILTER, true)}
          >
            <div>
              <span>{t('underoverprice')}</span>
              <span className="selected-list">
                {filters.get('overprice').maxOverprice &&
                  `+${filters.get('overprice').maxOverprice}% `}
                {filters.get('overprice').minOverprice &&
                  `${filters.get('overprice').minOverprice}%`}
              </span>
            </div>
          </NavigationLink>
        </div>
        <div className="detailed-filters">
          <NavigationLink
            onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_MULTI_FILTER, ['type'])}
          >
            <div>
              <span>{t('type.label')}</span>
              <span className="selected-list">
                {filters.get('type')?.map((k: string, idx: number, arr: string[]) => (
                  <Fragment key={k}>
                    {t(`type.${StoreFiltersConfig.type(k)}`)}
                    {idx !== arr.length - 1 && <>, </>}
                  </Fragment>
                ))}
              </span>
            </div>
          </NavigationLink>
          <NavigationLink
            onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_MULTI_FILTER, ['exterior'])}
          >
            <div>
              <span>{t('exterior.label')}</span>
              <span className="selected-list">
                {filters.get('exterior')?.map((k: string, idx: number, arr: string[]) => (
                  <Fragment key={k}>
                    {t(`exterior.${StoreFiltersConfig.exterior(k)}`)}
                    {idx !== arr.length - 1 && <>, </>}
                  </Fragment>
                ))}
              </span>
            </div>
          </NavigationLink>
          <NavigationLink
            onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_MULTI_FILTER, ['phase'])}
          >
            <div>
              <span>{t('phase.label')}</span>
              <span className="selected-list">
                {filters.get('phase')?.map((k: string, idx: number, arr: string[]) => (
                  <Fragment key={k}>
                    {t(`phase.${StoreFiltersConfig.phase(k)}`)}
                    {idx !== arr.length - 1 && <>, </>}
                  </Fragment>
                ))}
              </span>
            </div>
          </NavigationLink>
          <NavigationLink
            onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_MULTI_FILTER, ['weapon'])}
          >
            <div>
              <span>{t('weapon.label')}</span>
              <span className="selected-list">
                {filters.get('weapon')?.map((k: string, idx: number, arr: string[]) => (
                  <Fragment key={k}>
                    {t(`weapon.${StoreFiltersConfig.weapon(k)}`)}
                    {idx !== arr.length - 1 && <>, </>}
                  </Fragment>
                ))}
              </span>
            </div>
          </NavigationLink>
        </div>
        <div className="filter-controller">
          <Button className={`primary full ${loading ? 'disabled' : ''}`} as={Link} to="/market">
            {t('show selected')}
            {' ('}
            {meta.amount}
            {') '}
          </Button>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 1.75rem;

  & .search-container {
    display: flex;
    min-height: 4rem;
    padding: 0.5rem 1rem;
    position: relative;

    & input {
      &:not([value='']) {
        padding-right: 3rem;
      }
    }

    & input:not([value='']) + button {
      opacity: 1;
    }

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border: 0;
      padding: 0;
      background: transparent;
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      bottom: 0.5rem;
      opacity: 0;
      transition: opacity 100ms ease-in;

      & i {
        font-size: 1.5rem;
        color: var(--color-gray-200);
      }
    }
  }

  & .clean-up-filters {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    padding: 0 1rem;
    height: 2rem;
    background: var(--color-dark-600);

    & span {
      align-self: center;
      font-weight: bold;
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0.286px;
      color: var(--color-white);

      &:last-of-type:not(:first-of-type) {
        justify-self: self-end;
        color: var(--aqua-500);

        &.hide {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  & .detailed-filters {
    display: grid;
    grid-auto-flow: row;
    background: var(--color-dark-600);

    ${NavigationLink} {
      & .selected-list {
        margin-right: 0.125rem;
        color: var(--color-gray-200);
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.286px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    & .price {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;
      padding: 0.875rem 2.5rem 0.875rem 1.3rem;
      border-top: 1px solid var(--color-gray-900);

      &:first-of-type {
        border-top: none;
      }

      &-title {
        font-size: 0.875rem;
        line-height: 25px;
        letter-spacing: 0.24px;
        color: var(--color-white);
      }

      &-selector {
        display: flex;
        flex-flow: row wrap;
        margin-right: -1.625rem;

        & button {
          margin: 0 0.75rem 0.5rem 0;
          padding: 0.5rem 0.75rem;
          background: var(--color-dark);
          border: 0.125rem solid var(--color-dark-200);
          border-radius: var(--size-radius-s);
          font-weight: bold;
          font-size: 0.625rem;
          letter-spacing: 0.3px;
          color: var(--color-white);

          &:disabled {
            background: #47555f;
            border-color: #47555f;
          }
        }
      }
    }
  }

  & .filter-controller {
    margin-top: -1.75rem;
    padding: 1.25rem 1rem 1.75rem;
    background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
    backdrop-filter: blur(4px);
    position: sticky;
    bottom: 0;
  }
`;
