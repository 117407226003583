import { SuitCaseData } from 'games/CaseGame/interfaces';

export enum TicketTypeEnum {
  WEEKLY = 'green-key',
  SPECIAL = 'blue-key',
  DAILY = 'orange-key',
}
export type QuestType = 'weekly' | 'daily';

export enum GameTypes {
  CLASSIC = 1,
  FAST = 2,
  DOUBLE = 3,
  DUEL = 4,
  SLOT = 5,
  CSGO_BOTS = 6,
  CRASH = 7,
  CRAFT = 9,
  CASES = 10,
  X50 = 13,
  TOWER = 14,
  WHEEL = 15,
  HILO = 16,
  POGGI = 19,
  BACCARAT = 18,
  SHOP = 33,
  INVENTORY = 34,
}
export interface LRBalanceResponse {
  green: number;
  blue: number;
  orange: number;
  // для новогоднего евента нижние ключики
  /** weekly */
  greenKeys: number;
  /** special */
  blueKeys: number;
  /** daily */
  orangeKeys: number;
}
export interface LRQuestParams {
  count: number;
  amount?: number;
  betName?: string;
  floorNumber?: number;
  coef?: number;
}
export interface LRQuest {
  id: number;
  name: string;
  gameTypeId: GameTypes;
  /** только викли и дейли*/
  type: QuestType;
  params: LRQuestParams;
  isHidden: boolean;
  startAt: Date;
  /** у дейликов сутки у викли неделя*/
  finishAt: Date;
  /** Важно для викли последовательность, для дейликов всегда 0 , эта последовательность определеяет то как вы их будете отрисовывать на экране от 0 до 7 каждый следующий заблокирован пока в прогрессе предыдущий*/
  priority: number;
  progress: number;
  rewardTaken: boolean;
  isOpened: boolean;
}

export interface CaseLRInfo extends SuitCaseData {
  ticketQuest: {
    ticketsAmount: number;
    ticketColor: TicketTypeEnum;
  };
}
export interface ILRPlayer {
  tickets: number;
  place: number;
  prizeTicketsAmount: number | null;
  prizeCoinsAmount: number | null;
  //NOTE: add type
  user: any;
}
export interface ILRLeaderboards {
  color: TicketTypeEnum;
  players: ILRPlayer[];
  totalTickets: number;
  firstTop: number;
  secondTop: number;
  myStats?: {
    place: number;
    tickets: number;
  };
}
export interface ILRTop {
  count: number;
  total: number;
  prizes: number;
  superPrizes: number;
  //NOTE: add type
  user: any;
  tickets: LRBalanceResponse;
}
export interface ILRLeaders {
  startAt: Date;
  finishAt: Date;
  leaderboards: ILRLeaderboards[];
  top: ILRTop[];
}

export interface EventResponse {
  /** id  квеста  */
  questId: number;
  /** прогресс по данному квесту */
  userCount?: number;
  rewardTaken?: boolean;
}
