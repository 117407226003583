import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { URLS } from 'services/constant-urls';
import { SvgIcons } from './assets';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { SteamLink } from './SteamLink';
import { actionRequestApiKey, actionUserSettingsLoading } from 'core/User/ducks/duck';
import { useGetApiKey, useUserProviders, useUserTradeLinks } from 'core/User';
import {
  AccountWarning,
  AccountWarningLink,
  AccountWarningWrap,
  Block,
  Bottom,
  Container,
  Header,
  MyBtn,
  SteamButtons,
  Text,
  Title,
  Wrapper,
} from './Trading.styles';
import { ESteam } from './constants';
import { useUserSettingsLoading } from 'core/User/hooks/useUserSettingsLoading';

export const Trading = () => {
  const { userProviders } = useUserProviders();
  const { activeDialogs, handleToggleDialog, handleCloseByName } = useDialog();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { steamApiKey } = useGetApiKey();
  const { tradeLinks } = useUserTradeLinks();
  const { userSettingsLoading } = useUserSettingsLoading();

  useEffect(() => {
    dispatch(actionRequestApiKey());
    dispatch(actionUserSettingsLoading());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      activeDialogs.forEach(item => handleCloseByName(item));
    };
  }, [activeDialogs, handleCloseByName]);

  return (
    <Wrapper className="content">
      {userSettingsLoading && <Container className="skeleton" />}
      {userProviders?.['steam'] && !userSettingsLoading ? (
        <>
          {!tradeLinks?.['steam'] && (
            <Block>
              <Header>
                <SvgIcons.SteamTradeIcon />
                <Title>{t('steamApi.noTradeLink')}</Title>
              </Header>
              <Text>{t('steamApi.need')}</Text>
              <Bottom>
                <Button
                  className="primary"
                  onClick={handleToggleDialog(userModals.STEAM_TRADE_LINK, true)}
                >
                  {t('steamApi.addLink')}
                </Button>
                <MyBtn
                  as="a"
                  target="_blank"
                  className="primary"
                  href={`${URLS.urlTradeLink}${userProviders['steam']?.profileId}/tradeoffers/privacy`}
                >
                  {t('steamApi.link')}
                </MyBtn>
              </Bottom>
            </Block>
          )}
          {!steamApiKey && (
            <Block>
              <Header>
                <SvgIcons.SteamApiKeyIcon />
                <Title>{t('steamApi.noKey')}</Title>
              </Header>
              <Text>{t('steamApi.subTitle')}</Text>
              <Bottom>
                <Button
                  className="primary"
                  onClick={handleToggleDialog(userModals.STEAM_API_DIALOG, true)}
                >
                  {t('steamApi.addKey')}
                </Button>
                <MyBtn target="_blank" href={URLS.linkSteamApiKey}>
                  {t('steamApi.link')}
                </MyBtn>
              </Bottom>
            </Block>
          )}
          <SteamButtons>
            {userProviders['steam']?.profileId && (
              <SteamLink
                steamData={`${URLS.urlTradeLink}${userProviders['steam']?.profileId}`}
                steamCategory={ESteam.LINK}
              />
            )}
            {!!tradeLinks?.['steam'] && (
              <SteamLink steamData={tradeLinks['steam']} steamCategory={ESteam.TRADE_LINK} />
            )}
            {!!steamApiKey && (
              <>
                <SteamLink steamData={steamApiKey} steamCategory={ESteam.API_KEY} />
                <AccountWarningWrap>
                  <SvgIcons.WarningIcon />
                  <AccountWarning>
                    <Trans
                      t={t}
                      components={[
                        <AccountWarningLink href={URLS.steamSettings} target="_blank" />,
                      ]}
                    >
                      {t('Do not make your Steam inventory private')}
                    </Trans>
                  </AccountWarning>
                </AccountWarningWrap>
              </>
            )}
          </SteamButtons>
        </>
      ) : null}
    </Wrapper>
  );
};
