import styled from 'styled-components';

export const Container = styled.div<{ isSelected: boolean }>`
  ${({ isSelected }) => isSelected && 'background: var(--color-dark-100);'}
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 18px 18px 18px 20px;
  position: relative;
  width: 100%;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  z-index: 2;
`;
export const Text = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`;

export const AnimationBlock = styled.div<{
  isPlayed: boolean;
  duration: number;
}>`
  background: var(--color-dark-250);
  height: 100%;
  position: absolute;
  z-index: 1;

  @keyframes fill-color {
    from {
      width: 0;
    }

    to {
      width: calc(100% + 20px);
    }
  }

  ${({ isPlayed, duration }) => isPlayed && `animation: fill-color ${duration}ms;`}
  left: -20px;
`;
export const SoundIconsContainer = styled.div`
  z-index: 2;
`;
