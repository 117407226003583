import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTakeWinnerLotWithHistory } from '../../ducks/history.duck';

export const useFinishedTakeAction = () => {
  const dispatch = useDispatch();

  const handleTakeAt = useCallback(
    (raffleId: number, itemId: number) => () => {
      dispatch(fetchTakeWinnerLotWithHistory(raffleId, itemId));
    },
    [dispatch]
  );

  return [handleTakeAt];
};
