import React, { useEffect } from 'react';
import { CaseListItems, CaseShopHeader, Container } from './case-shop.styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CaseItem, ItemContainer } from '../case-item';
import { useGetCasesLr } from 'modules/event-module/comics-event/hooks';
import { actionRequestLeaderRaceCases } from 'modules/event-module/comics-event/duck/leader-race-duck';

export const CaseShop = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, data } = useGetCasesLr();

  useEffect(() => {
    dispatch(actionRequestLeaderRaceCases());
  }, [dispatch]);

  return (
    <Container>
      <CaseShopHeader>{t('caseShop')}</CaseShopHeader>
      <CaseListItems>
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map(item => <ItemContainer key={item} className="skeleton" />)
          : data.map(value => (
              <CaseItem
                id={value?.id}
                key={value?.id}
                price={value?.ticketQuest?.ticketsAmount}
                isFree={!!value?.free}
                image={value?.image}
                fruitType={value?.ticketQuest?.ticketColor}
                backgroundImage={value?.backgroundImage}
              />
            ))}
      </CaseListItems>
    </Container>
  );
});
