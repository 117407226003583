import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--color-dark-600);
  border-top: 1px solid var(--gray-250);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 24px 16px 38px;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
`;

export const BalanceContainer = styled.div`
  background-color: var(--color-dark-100);
  border-radius: var(--size-radius-m);
  display: flex;
  gap: 8px;
  padding: 4px 10px;
`;

export const Balance = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  & > span {
    color: #f5f5f6;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 36px;
  max-height: 480px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CloseContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CloseButton = styled.span`
  color: var(--color-gray-200);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  line-height: 15.23px;
  text-align: center;
  text-transform: uppercase;
`;
