import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createPayout } from '../duck';
import type { ICreatePayout } from '../duck';

export const useCreatePay = () => {
  const dispatch = useDispatch();
  const handleCreatePay = useCallback(
    (config: ICreatePayout) => () => {
      dispatch(createPayout(config));
    },
    [dispatch]
  );

  return [handleCreatePay];
};
