import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { IMember } from '../../../../interfaces';
import { useLoadAccountProfile } from 'modules/Accounts';

export const MembersGridItem: FC<IMember> = ({
  betType,
  playerName,
  sum,
  playerImage,
  playerId,
}) => {
  const { handleOpenProfile } = useLoadAccountProfile();

  return (
    <Container className={betType} style={{ order: -sum }}>
      <div className="thumbnail">
        <img src={playerImage} alt="" onClick={handleOpenProfile(playerId)} />
      </div>
      <div className="user-info">
        <span>{playerName}</span>
        <span>
          <CurrencyText value={sum} />
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row: auto;
  grid-gap: 0.25rem;
  background: var(--color-dark-200);
  padding: 0.25rem;

  & .thumbnail {
    width: 1.875rem;
    height: 1.875rem;
    align-self: center;

    & img {
      width: 1.875rem;
      height: 1.875rem;
      object-fit: contain;
      overflow: hidden;
      border-radius: 50%;
      pointer-events: auto;
    }
  }
  & .user-info {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.125rem;
    align-content: center;

    & span {
      width: 100%;
      height: 0.875rem;
      font-size: 0.75rem;
      line-height: 0.875rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
