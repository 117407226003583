import React, { FC, useEffect, useLayoutEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { ShopPage } from './components';
import { useShopPagination } from './hooks';
import { useDispatch } from 'react-redux';
import {
  attachExchange,
  detachExchange,
  registerExchangeModule,
  unregisterExchangeModule,
} from './ducks';

export const ExchangeRouter: FC<RouteComponentProps> = () => {
  const { loadMore } = useShopPagination();
  const dispatch = useDispatch();
  useMemo(() => {
    registerExchangeModule();
    dispatch(attachExchange());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch(detachExchange());
      unregisterExchangeModule();
    };
  }, [dispatch]);

  useEffect(() => {
    loadMore(1);
  }, [loadMore]);

  return (
    <>
      <AppBar deep />
      <ShopPage />
      <div id="exchange" />
    </>
  );
};
