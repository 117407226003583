import Marketplace from './containers';
import reducer, { eventsTypes } from './duck';
import './locales';

export const marketplaceModule = {
  moduleName: 'marketplace',
  moduleId: 33,
  path: '/marketplace',
  component: Marketplace,
  reducer: reducer,
  eventsTypes: eventsTypes,
};
