import { useCallback } from 'react';
import { IModalState, modalChangeState } from '../duck';
import { getModalState } from '../selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/interface';

export const useModal = (modalName: string) => {
  const modalState = useSelector<IStore, boolean | (string | number)[]>(
    state => getModalState(state, modalName),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleToggleModal = useCallback(
    (modalName: string, state: IModalState) => () => {
      dispatch(modalChangeState(modalName, state));
    },
    [dispatch]
  );

  const handleCloseModal = useCallback(handleToggleModal(modalName, false), []);
  const handleOpenModal = useCallback(handleToggleModal(modalName, true), []);

  return {
    modalState,
    handleToggleModal,
    handleCloseModal,
    handleOpenModal,
  };
};
