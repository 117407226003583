import styled from 'styled-components';

export const CasesContainer = styled.div`
  gap: 8px;
  max-height: 94px;
  max-width: 100%;
  padding: 0 16px;
  overflow-x: scroll;
  position: relative;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
`;
