import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getProvidersSteam, getStatsIsHidden } from 'core/User';
import { updateProvidersSteam, updateStatsIsHidden } from 'core/User/ducks/duck';

export const usePrivacySettings = () => {
  const statsIsHidden = useSelector(getStatsIsHidden, shallowEqual);
  const providersSteam = useSelector(getProvidersSteam, shallowEqual);

  const dispatch = useDispatch();

  const handleToggleStats = useCallback(() => {
    dispatch(updateStatsIsHidden());
  }, [dispatch]);

  const handleToggleSteam = useCallback(
    (isHidden: boolean) => () => {
      dispatch(updateProvidersSteam(isHidden));
    },
    [dispatch]
  );

  return {
    statsIsHidden,
    providersSteam,
    handleToggleStats,
    handleToggleSteam,
  };
};
