import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchRafflesHistory } from '../ducks/history.duck';
import { getRaffleHistory } from '../selectors';
import { getUser } from 'core/User/selectors';

export const useRaffleHistoryFacade = () => {
  const raffleHistory = useSelector(getRaffleHistory, shallowEqual);
  const user = useSelector(getUser, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!raffleHistory.loading && !raffleHistory.loaded) dispatch(fetchRafflesHistory());
  }, [raffleHistory, dispatch]);

  return {
    raffleHistory,
    userid: user.id,
  };
};
