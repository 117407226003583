import styled from 'styled-components';

export const Container = styled.div<{ isGame: boolean }>`
  display: grid;
  ${({ isGame }) =>
    isGame
      ? `grid-auto-columns: 60px 2fr 66px minmax(0px, min-content);
    grid-template:
    'app-bar-drawer app-bar-title c app-bar-actions' var(--size-nav-bar)
    'collapse collapse collapse collapse';
      `
      : `grid-auto-columns: 60px 1fr minmax(90px, min-content);
    grid-template:
    'app-bar-drawer app-bar-title app-bar-actions' var(--size-nav-bar)
    'collapse collapse collapse';
      `}
  background: var(--color-dark-50);
  color: var(--color-white);
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.5px;

  & .app-bar-drawer,
  & .app-bar-title,
  & .app-bar-actions {
    position: relative;
    z-index: 10;
  }

  & .app-bar-drawer {
    align-self: stretch;
    display: flex;
    align-items: center;
    grid-area: app-bar-drawer;
    padding-left: 1rem;

    & button {
      width: 30px;
      height: 30px;
      background: transparent;
      border: 0;
      color: inherit;
      padding-left: 0;

      & > i[class^='icon-'] {
        display: block;
        font-size: 1rem;
      }

      & > i[class='icon-arrow'] {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
  }

  & .app-bar-title {
    justify-self: stretch;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    padding: 0 0.2rem;
    max-width: 100%;
    grid-area: app-bar-title;
    overflow: hidden;
    font-weight: inherit;

    & .icon-arrow {
      margin-left: 0.4rem;
      transition: transform var(--transition-ease-out);
    }

    &.open {
      & .icon-arrow {
        transform: translate3d(0, 0.3rem, 0);
      }
    }

    & > span {
      color: var(--color-white);
      font-weight: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_badge {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 1.8rem;
      height: 1rem;
      border-radius: var(--size-radius-s);
      background: var(--color-gray-300);
      margin-left: 0.4rem;
      font-weight: inherit;
      font-size: 0.65rem;
    }
  }

  & .app-bar-actions {
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-area: app-bar-actions;
    padding-right: 1rem;
    z-index: 15;
    overflow: hidden;
    font-weight: inherit;

    & .balance-action {
      width: 100%;
      color: var(--color-white);
      text-align: end;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: inherit;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  margin-right: 16px;
  grid-area: c;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 6px;

  & > svg:not(.event) > path {
    fill: var(--color-gray-200);
  }
`;

export const Progress = styled.div<{ percent: number }>`
  background: #ffffff0d;
  height: 42px;
  position: absolute;
  transition: width 1s;
  width: ${({ percent }) => `${percent}%`};
`;
