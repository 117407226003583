import React, { useMemo, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ParticipateControllerChildren, ParticipateModule, ParticipateType } from 'core/interfaces';
import { useParticipateController, useParticipateSettings } from 'core/hooks';
import { FactorControl } from './factor-control';
import { BoardTypes } from '../../../interfaces';
import { useBaccaratParticipateController, useGameBoard, useUntilTime } from '../../../hooks';
import { RadialProgressBar } from 'dialogs/participate-dialog';
import { useGameCondition } from '../../../hooks/useGameCondition.hook';

const types = [ParticipateType.CHIPS, ParticipateType.MONEY];

interface IParticipateController {
  type: BoardTypes;
  protectType: BoardTypes;
  module: ParticipateModule;
  children: (props: ParticipateControllerChildren) => JSX.Element;
}

export const ParticipateController: FC<IParticipateController> = ({
  children,
  module,
  type,
  protectType,
}) => {
  const { t } = useTranslation('baccarat');
  const { settings } = useParticipateSettings(ParticipateModule.DOUBLE);
  const [modifier, controllers] = useParticipateController(ParticipateType.CHIPS);
  const { bet } = useGameBoard(type, protectType);
  const { untilTime, timeout } = useUntilTime();
  const condition = useGameCondition();
  const { handleCreateParticipate } = useBaccaratParticipateController();
  const amount = modifier.participate.amount;

  const disabled = useMemo<boolean>(() => {
    return condition !== 'bet' || amount < settings.min_bet || amount > settings.max_bet || !!bet;
  }, [condition, amount, settings, bet]);

  return (
    <Container>
      <div className="participate-title">{t('Place a bet')}</div>
      <div className="participate-wrapper">
        <button
          className="participate-clean-up"
          onClick={() => controllers[modifier.type].handleCleanupParticipate(modifier.type)}
        >
          <i className="icon-close" />
        </button>
        <FactorControl type={type} amount={bet?.amount} currentAmount={amount} />
        <div className="participate-confirm">
          <button onClick={handleCreateParticipate(type, amount)} disabled={disabled}>
            <i className="icon-checked" />
          </button>
          {condition === 'bet' && (
            <RadialProgressBar key={untilTime} finishAt={untilTime} duration={timeout} />
          )}
        </div>
      </div>
      {children({ types, module, modifier, controllers })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
  background: var(--color-dark-600);
  position: relative;
  z-index: 10;
  border-top: 1px solid var(--color-gray-900);

  & .participate-title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-align: center;
    color: var(--color-white);
    height: 2.25rem;
  }

  & .participate-wrapper {
    display: grid;
    grid-template-columns: 3rem 1fr 3rem;
    align-self: stretch;
    grid-gap: 1.25rem;
    padding: 0 2.125rem;
    height: 5.75rem;

    & .participate-clean-up {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      justify-items: center;
      align-items: center;
      align-self: center;
      justify-self: center;
      padding: 0;
      background: transparent;
      border: 2px solid var(--color-gray-300);
      color: var(--color-gray-300);
      transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

      &:disabled {
        border-color: var(--color-dark-100);
        color: var(--color-dark-100);
      }

      & i {
        font-size: 1.5rem;
      }
    }

    & .participate-confirm {
      align-self: center;
      justify-self: center;
      width: 3rem;
      height: 3rem;
      position: relative;

      & > button {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        justify-items: center;
        align-items: center;
        padding: 0;
        border: transparent;
        background: var(--color-green);
        transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
        position: relative;
        z-index: 10;

        &:disabled {
          background: var(--color-dark-100);
          color: var(--color-dark-600);
        }

        & i {
          font-size: 1rem;
        }
      }
    }
  }
`;
