import Immutable from 'immutable';
import { Reducer } from 'redux';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  filter,
  map,
  pluck,
  switchMap,
} from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import {
  clearanceActions,
  ClearanceActionTypes,
  ClearanceRecord,
  IClearanceRecord,
  ModeType,
} from '../interfaces';
import { IFastInventoryElement } from 'core/User';
import { ExchangeShopRepository } from '../../+exchange/repositories';
import { getClearance } from '../selector';
// import { UserGofastInventory } from 'core/User/repositories'
import { refreshFastInventory } from 'core/User/ducks/fast-inventory.duck';
import { addNotification } from 'modules/Notifications/duck';

export const clearance: Reducer<Immutable.Record<IClearanceRecord>, clearanceActions> = (
  state = new ClearanceRecord(),
  action
) => {
  switch (action.type) {
    case ClearanceActionTypes.ADD_TO_CLEARANCE_OUT: {
      const { item } = action.payload;
      return state
        .update('items', origin => [item, ...origin])
        .update('amount', origin => origin + item.inventoryItem.price);
    }

    case ClearanceActionTypes.DELETED_FROM_CLEARANCE_OUT: {
      const { item } = action.payload;
      return state
        .update('items', origin => origin.filter(({ id }) => id !== item.id))
        .update('amount', origin => origin - item.inventoryItem.price);
    }

    case ClearanceActionTypes.RESET_ALL_CLEARANCE_OUT: {
      return new ClearanceRecord();
    }

    default: {
      return state;
    }
  }
};

export const addToClearance = (item: IFastInventoryElement) => ({
  type: ClearanceActionTypes.ADD_TO_CLEARANCE_OUT,
  payload: {
    item,
  },
});

export const deleteFromClearance = (item: IFastInventoryElement) => ({
  type: ClearanceActionTypes.DELETED_FROM_CLEARANCE_OUT,
  payload: {
    item,
  },
});

export const resetClearance = () => ({
  type: ClearanceActionTypes.RESET_ALL_CLEARANCE_OUT,
});

export const fetchClearance = (mode: ModeType) => ({
  type: ClearanceActionTypes.FETCH_CLEARANCE_OUT,
  payload: {
    mode,
  },
});

export const clearanceEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ClearanceActionTypes.FETCH_CLEARANCE_OUT),
    debounceTime(700),
    pluck('payload', 'mode'),
    filter(mode => !!mode),
    switchMap((mode: ModeType) => {
      if (mode === ModeType.SELLOUT) {
        return of(getClearance(state$.value).map(({ id }: IFastInventoryElement) => id)).pipe(
          switchMap(userInventoryIds =>
            ExchangeShopRepository.fetchPurchase({
              ids: [],
              userInventoryIds,
            }).pipe(
              switchMap(() =>
                of(null).pipe(
                  delay(1000),
                  concatMap(() => [resetClearance(), refreshFastInventory()])
                )
              ),
              catchError(({ response }) =>
                of(
                  addNotification({
                    type: 'error',
                    body: response.message,
                    params: response.params,
                  })
                )
              )
            )
          )
        );
      }

      // if (mode === ModeType.WITHDRAWAL) {
      //   return of(
      //     getClearance(state$.value).map(({ id, price }: IFastInventoryElement) => ({ userInventoryItemId: id, price }))
      //   ).pipe(
      //     switchMap(params =>
      //       UserGofastInventory.fetchWithdraw(params).pipe(
      //         switchMap(() =>
      //           merge(
      //             of(dialogOff(FastInventoryModal.CLEARANCE)),
      //             of(null).pipe(
      //               delay(1000),
      //               concatMap(() => [resetClearance(), refreshFastInventory()])
      //             )
      //           )
      //         ),
      //         catchError(({ response }) =>
      //           of(addNotification({ type: 'error', body: response.message, params: response.params }))
      //         )
      //       )
      //     )
      //   )
      // }

      return of(resetClearance());
    })
  );

export const sellAllAction = () => ({
  type: ClearanceActionTypes.SELL_OUT,
});

export const sellAllEpic: Epic = action$ =>
  action$.pipe(
    ofType(ClearanceActionTypes.SELL_OUT),
    switchMap(() =>
      ExchangeShopRepository.fetchSellAll().pipe(
        switchMap(() =>
          of(null).pipe(
            delay(1000),
            map(() => refreshFastInventory())
          )
        ),
        catchError(({ response }) =>
          of(
            addNotification({
              type: 'error',
              body: response.message,
              params: response.params,
            })
          )
        )
      )
    )
  );
