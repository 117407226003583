import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { rootEpics$ } from 'store/epicMiddleware';
import { store } from 'store/configureStore';
import { shop, shopEpics } from './shop.duck';
import { filters, filtersEpic } from './filters.duck';
import { order, orderEpic } from './order.duck';
import { ExchangeActionTypes } from '../interfaces';

const reducer = combineReducers({ shop, filters, order });
export const exchangeEpics: any = combineEpics(shopEpics, filtersEpic, orderEpic);

export const registerExchangeModule = () => {
  rootEpics$.next([exchangeEpics, ExchangeActionTypes.EXCHANGE_DETACH_LISTENERS]);
  store.reducerManager.add('exchange', reducer);
};

export const unregisterExchangeModule = () => {
  store.reducerManager.remove('exchange');
};
