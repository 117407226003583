import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import { Header } from '../components/Bets/Header';
import { List } from '../components/Bets/List';
import { Control } from '../components/Bets/Control';

import { getGameSettings } from '../../../core/AppShell/selectors';

import {
  confirmBet,
  confirmInventoryBet,
  loadMarket,
  requestUserInventory,
  updateFilter,
} from '../duck';
import {
  getMarketplaceFetchingStatus,
  getMarketplaceFilterValues,
  getMarketplaceGameTypes,
  getMarketplaceItemsByFilter,
} from '../selectors';

class Bets extends PureComponent {
  static defaultProps = {
    defaultMode: ['classic', 'fast'],
    defaultType: ['virtual', 'inventory'],
  };

  componentDidMount() {
    const { match, history, defaultMode, defaultType } = this.props;
    const { mode, type } = match.params;
    if (!(defaultMode.includes(mode) && defaultType.includes(type))) {
      return history.goBack(1);
    }
    this.props.loadMarket(type, mode);
  }

  render() {
    const {
      match,
      history,
      items,
      gameTypes,
      updateFilter,
      filterValue,
      filterValue: { currentGameTypes },
      requestUserInventory,
      confirmBet,
      gameSettings,
      isFetching,
      confirmInventoryBet,
    } = this.props;
    const { type, mode } = match.params;

    return (
      <Header
        mode={mode}
        type={type}
        items={items}
        gameTypes={gameTypes}
        updateFilter={updateFilter}
        currentGameTypes={currentGameTypes}
        filterValue={filterValue}
        requestUserInventory={requestUserInventory}
        gameSettings={gameSettings}
      >
        {({ addItemToCart, removeItemToCart, cartItems }) => (
          <Fragment>
            <List
              type={type}
              items={items}
              isFetching={isFetching}
              addItemToCart={addItemToCart}
              removeItemToCart={removeItemToCart}
              gameType={gameTypes[currentGameTypes]}
            />
            {(type === 'virtual' || currentGameTypes === 1912) && (
              <Control
                type={type}
                history={history}
                cartItems={cartItems}
                confirmBet={confirmBet}
                confirmInventoryBet={confirmInventoryBet}
                gameSettings={gameSettings}
                currentGameTypes={currentGameTypes}
              />
            )}
          </Fragment>
        )}
      </Header>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    items: getMarketplaceItemsByFilter(state),
    gameTypes: getMarketplaceGameTypes(state),
    filterValue: getMarketplaceFilterValues(state),
    gameSettings: getGameSettings(state, ownProps.match.params.mode),
    isFetching: getMarketplaceFetchingStatus(state),
  }),
  {
    loadMarket,
    updateFilter,
    requestUserInventory,
    confirmBet,
    confirmInventoryBet,
  }
)(Bets);
