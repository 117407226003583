import styled from 'styled-components';

export const ProgressContainer = styled.div`
  position: relative;

  & > svg.progress {
    left: 4px;
    position: absolute;
    top: 3.9px;
    transition: width 1s;
  }

  & > span {
    color: #f5e5c4;
    font-size: 12px;
    left: 50%;
    line-height: 16.94px;
    position: absolute;
    text-align: center;
    top: 0.7px;
    transform: translateX(-50%);
    z-index: 20;
  }
`;
