import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserVerification } from 'core/User/ducks/duck';
import { modalChangeState } from '../../../core/Modals';
import { withdrawalModals } from '../modal.config';

export const usePayoutVerification = () => {
  const [file, setFiles] = useState(null);
  const dispatch = useDispatch();

  const onFileChange = useCallback(
    ev => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFiles(reader.result);
      };
      reader.readAsDataURL(ev.target.files[0]);
    },
    [setFiles]
  );

  const verifyAction = useCallback(() => {
    dispatch(fetchUserVerification(file));
    dispatch(modalChangeState(withdrawalModals.VERIFICATION, true));
  }, [dispatch, file]);

  return {
    file,
    onFileChange,
    verifyAction,
  };
};
