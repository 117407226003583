import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { ChatPage } from './components/chat-page';

export const ChatRouter: FC<RouteComponentProps> = ({ match }) => (
  <div className="content">
    <AnimatedRouter>
      <Route path={match.url} component={ChatPage} />
    </AnimatedRouter>
  </div>
);
