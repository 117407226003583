export default {
  'Fair game': 'Adil oyun',
  Bet: 'Bahis',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = devre dışı)',
  'Bet coins': 'Coin ile bahis yap',
  Stop: 'Durdur',
  'Wait for next round...': 'Bir sonraki turu bekle...',
  Example: 'Örnek',
  'You won': 'Sen kazandın',
};
