import {
  IParticipantResponse,
  IStoreDeletedResponse,
  IStoreUpdateResponse,
  MarketplaceActionTypes,
  steamActions,
  steamStatus,
} from '../interfaces';
import { Epic, ofType } from 'redux-observable';
import { fromEvent, merge, of } from 'rxjs';
import { buffer, catchError, debounceTime, map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { PurchaseRepository, StoreRepository, UserSteamInventoryRepository } from '../repositories';
import { updateBids } from './purchases.duck';
import { Reducer } from 'react';

export const attachMarketplace = () => ({
  type: MarketplaceActionTypes.MARKETPLACE_ATTACH_LISTENERS,
});

export const detachMarketplace = () => ({
  type: MarketplaceActionTypes.MARKETPLACE_DETACH_LISTENERS,
});

const participantUpdate = (entity: IParticipantResponse) => ({
  type: MarketplaceActionTypes.MARKETPLACE_PARTICIPANT_UPDATE,
  payload: entity,
});

// const storeItemUpdate = (entity: IStoreUpdateResponse) => ({
//   type: MarketplaceActionTypes.MARKETPLACE_STORE_ITEM_UPDATE,
//   payload: entity,
// })

const storeItemsUpdate = (items: IStoreUpdateResponse[]) => ({
  type: MarketplaceActionTypes.MARKETPLACE_STORE_ITEM_UPDATE,
  payload: {
    items,
  },
});

// const storeItemDeleted = (entity: IStoreDeletedResponse) => ({
//   type: MarketplaceActionTypes.MARKETPLACE_STORE_ITEM_DELETED,
//   payload: entity,
// })

const storeItemsDeleted = (items: IStoreDeletedResponse[]) => ({
  type: MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_DELETED,
  payload: {
    items,
  },
});

const hotOffersUpdate = (entities: any) => ({
  type: MarketplaceActionTypes.MARKETPLACE_HOT_OFFERS_UPDATE,
  payload: entities,
});

export const marketplaceWatcher: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(MarketplaceActionTypes.MARKETPLACE_ATTACH_LISTENERS),
    switchMap(() => {
      const deleted$ = fromEvent<IStoreDeletedResponse>(socket.io, 'market.item.deleted');
      const update$ = fromEvent<IStoreUpdateResponse>(socket.io, 'market.item.updated');

      return merge(
        deleted$.pipe(
          buffer(deleted$.pipe(debounceTime(500))),
          map(items => storeItemsDeleted(items))
        ),
        update$.pipe(
          buffer(update$.pipe(debounceTime(500))),
          map(items => storeItemsUpdate(items))
        ),
        StoreRepository.fetchStoreHotOffers().pipe(
          map(({ response }) => hotOffersUpdate(response)),
          catchError(() => of(hotOffersUpdate({})))
        ),
        PurchaseRepository.fetchBids().pipe(
          map(({ response }) => updateBids(response)),
          catchError(() =>
            of(
              updateBids({
                kits: [],
                meta: { amount: 0, limit: 0, offset: 0 },
              })
            )
          )
        ),
        fromEvent<IParticipantResponse>(socket.io, 'market.participant.item.updated').pipe(
          map(v => participantUpdate(v))
        ),
        fromEvent(socket.io, 'market.items.hot').pipe(map(v => hotOffersUpdate(v)))
      ).pipe(takeUntil(action$.ofType(MarketplaceActionTypes.MARKETPLACE_DETACH_LISTENERS)));
    })
  );

export const steamStatusEpic: Epic = () =>
  of(actionCheckSteamStatus).pipe(
    switchMap(() =>
      UserSteamInventoryRepository.fetchSteamStatus().pipe(
        pluck('response', 'status'),
        map(status => updateSteamStatus(status)),
        catchError(() => of(updateSteamStatus('offline')))
      )
    )
  );

export const actionCheckSteamStatus = () => ({
  type: MarketplaceActionTypes.CHECK_STATUS_STEAM,
});

const updateSteamStatus = (status: steamStatus) => ({
  type: MarketplaceActionTypes.UPDATE_STEAM_STATUS,
  payload: {
    status,
  },
});
export const steam: Reducer<steamStatus, steamActions> = (state = 'offline', action) => {
  switch (action.type) {
    case MarketplaceActionTypes.UPDATE_STEAM_STATUS: {
      return action.payload.status;
    }

    default: {
      return state;
    }
  }
};
