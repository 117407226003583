import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { ACTION_TYPE } from '../actionType';
import {
  ActionChangeSpeedRoll,
  ActionRequestReplay,
  ActionSellItem,
  ActionSetSoundType,
  DroppedItems,
  OpenCaseActionRequest,
  OpenCaseType,
  OpenCaseTypeAction,
  ReplayDroppedITems,
  RouletteActionRequest,
  RouletteActions,
  RouletteStatusActions,
  SpeedType,
  WinItemStatusActions,
} from './roulette.interfaces';
import { catchError, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import casesRepository from 'games/CaseGame/repository/casesRepository';
import { iif, of } from 'rxjs';
import {
  DroppedItemsResults,
  RouletteState,
  RouletteStatusState,
  WinItemStatusReducerState,
} from '../interfaces';
import { addNotification } from 'modules/Notifications/duck';
import { checkStatus, getSounds, getLine, getRandomLine } from 'games/CaseGame/utils';
import { TicketTypeEnum } from 'modules/event-module/event-summertime';

export enum RouletteStatus {
  BEFORE_ROLL = 'BEFORE_ROLL',
  AFTER_ROLL = 'AFTER_ROLL',
  IN_ROLL = 'IN_ROLL',
  ROLL_START = 'ROLL_START',
}
export enum SoldStatus {
  SOLD = 'SOLD',
  INIT = 'INIT',
}

export const actionSellItemsRequest = (ids: number[]) => ({
  type: ACTION_TYPE.SELL_ITEMS_BY_ID,
  payload: {
    ids,
  },
});

export const actionSellItemsChangeTypeToSold = () => ({
  type: ACTION_TYPE.CHANGE_ITEMS_SELL_TYPE,
});
const actionSellItemsChangeTypeToInit = () => ({
  type: ACTION_TYPE.CHANGE_ITEMS_SELL_TYPE_TO_INIT,
});
export const actionSellItemChangeTypeByIndex = (index: number, lineCount: number) => ({
  type: ACTION_TYPE.CHANGE_ITEM_SELL_TYPE_BY_INDEX,
  payload: {
    lineCount,
    index,
  },
});

export const sellWinningItemsEpic: Epic = ($action: ActionsObservable<ActionSellItem>) =>
  $action.pipe(
    ofType(ACTION_TYPE.SELL_ITEMS_BY_ID),
    pluck('payload'),
    switchMap(({ ids }) => {
      return casesRepository.sellItems(ids).pipe(
        mergeMap(() => of()),
        catchError(() =>
          of(
            addNotification({ type: 'error', body: 'Something was wrong(' }),
            actionSellItemsChangeTypeToInit()
          )
        )
      );
    })
  );

export const rouletteActionRequest = ({ caseId, lines }: { caseId: string; lines: string }) => ({
  type: ACTION_TYPE.ROULETTE_ACTION_REQUEST,
  payload: {
    caseId,
    lines,
  },
});

const rouletteActionResponse = (ids: number[][]) => ({
  type: ACTION_TYPE.ROULETTE_ACTION_RESPONSE,
  payload: ids,
});

export const openCaseActionRequest = ({
  caseId,
  type,
  lineCount,
  isFree,
  ticketColor,
  setCount,
}: {
  caseId: string;
  type: OpenCaseType;
  lineCount: number;
  isFree?: boolean;
  ticketColor?: TicketTypeEnum;
  setCount?: () => void;
}) => ({
  type: ACTION_TYPE.OPEN_CASE_ACTION_REQUEST,
  payload: {
    id: caseId,
    type,
    lineCount,
    isFree,
    ticketColor,
    setCount,
  },
});

export const openCaseTypeAction = (type: OpenCaseType) => ({
  type: ACTION_TYPE.OPEN_CASE_TYPE_ACTION,
  payload: type,
});
const openCaseActionResponse = (data: DroppedItems) => ({
  type: ACTION_TYPE.OPEN_CASE_ACTION_RESPONSE,
  payload: data,
});

export const actionRequestReplay = (uuid: string, activate: boolean = false) => ({
  type: ACTION_TYPE.ACTION_REQUEST_REPLAY,
  payload: { uuid, activate },
});
const actionResponseReplay = (data: ReplayDroppedITems) => ({
  type: ACTION_TYPE.ACTION_RESPONSE_REPLAY,
  payload: data,
});

export const rouletteReplayEpic: Epic = ($action: ActionsObservable<ActionRequestReplay>) =>
  $action.pipe(
    ofType(ACTION_TYPE.ACTION_REQUEST_REPLAY),
    switchMap(({ payload }) =>
      casesRepository.getReplay(payload.uuid).pipe(
        switchMap(({ response }: { response: ReplayDroppedITems }) =>
          iif(
            () => payload.activate,
            of(
              openCaseTypeAction('replay'),
              actionResponseReplay(response),
              rouletteStatusActionInRollAll()
            ),
            of(openCaseTypeAction('replay'), actionResponseReplay(response))
          )
        ),
        catchError(({ response }) =>
          of(
            addNotification({ type: 'error', body: response?.message }),
            rouletteStatusActionBeforeRollAll()
          )
        )
      )
    )
  );

export const rouletteLineEpic: Epic = ($action: ActionsObservable<RouletteActionRequest>) =>
  $action.pipe(
    ofType(ACTION_TYPE.ROULETTE_ACTION_REQUEST),
    switchMap(({ payload }) =>
      casesRepository
        .fetchRouletteItemsIds(payload)
        .pipe(map(({ response }: { response: number[][] }) => rouletteActionResponse(response)))
    )
  );

export const openCaseEpic: Epic = ($action: ActionsObservable<OpenCaseActionRequest>) =>
  $action.pipe(
    ofType(ACTION_TYPE.OPEN_CASE_ACTION_REQUEST),
    switchMap(({ payload }) =>
      iif(
        () => payload.type === 'demo',
        casesRepository.openCaseDemo(payload.id, payload.lineCount),
        casesRepository.openCase(
          payload.id,
          payload.lineCount,
          !!payload?.isFree,
          payload?.ticketColor
        )
      ).pipe(
        switchMap(({ response }: { response: DroppedItems }) => {
          payload?.setCount?.();

          return of(
            openCaseActionResponse(response),
            rouletteStatusActionInRollAll(),
            actionSellItemsChangeTypeToInit(),
            openCaseTypeAction(payload.type)
          );
        }),
        catchError(({ response }) =>
          of(
            addNotification({ type: 'error', body: response.message }),
            rouletteStatusActionBeforeRollAll()
          )
        )
      )
    )
  );

export const rouletteStatusActionBeforeRollAll = () => ({
  type: ACTION_TYPE.BEFORE_ROLL_ALL,
});
const rouletteStatusActionInRollAll = () => ({
  type: ACTION_TYPE.IN_ROLL_ALL,
});
export const rouletteStatusActionBeforeRoll = (lineIndex: number, lineCount: number) => ({
  type: ACTION_TYPE.BEFORE_ROLL,
  payload: {
    lineIndex,
    lineCount,
  },
});

export const rouletteStatusActionAllStartRoll = () => ({
  type: ACTION_TYPE.START_ROLL_ALL,
});

export const rouletteStatusActionInRoll = (lineIndex: number, lineCount: number) => ({
  type: ACTION_TYPE.IN_ROLL,
  payload: { lineIndex, lineCount },
});
export const rouletteStatusActionAfterRoll = (lineIndex: number, lineCount: number) => ({
  type: ACTION_TYPE.AFTER_ROLL,
  payload: { lineIndex, lineCount },
});

export const InitRouletteLine = (array: number[]) => ({
  type: ACTION_TYPE.INIT_ROULETTE_LINE,
  payload: array,
});

export const rouletteReducer = (
  state: RouletteState = { data: {}, isLoading: true },
  action: RouletteActions
) => {
  switch (action.type) {
    case ACTION_TYPE.ROULETTE_ACTION_REQUEST:
      return {
        data: {},
        isLoading: true,
      };
    case ACTION_TYPE.ROULETTE_ACTION_RESPONSE: {
      return {
        data: {
          ids: action.payload,
          droppedItems: [] as DroppedItemsResults[],
        },
        isLoading: false,
      };
    }
    case ACTION_TYPE.OPEN_CASE_ACTION_RESPONSE: {
      const demoWinCase: DroppedItemsResults[] = action.payload.results.map(winItem => ({
        caseRevisionItem: winItem.caseRevisionItem,
        caseRevisionPrice: undefined,
        winningPrice: winItem.caseRevisionItem.inventoryItem.price,
        id: 0,
        openUUID: '',
        rollId: '',
        userInventoryItemId: null,
      }));

      return {
        ...state,
        data: {
          ...state.data,
          droppedItems: action.payload.strip.length === 0 ? demoWinCase : action.payload.results,
          ids:
            action.payload.strip.length === 0
              ? getRandomLine(state.data.ids, state.data.droppedItems)
              : getLine(state.data.ids, action.payload.strip, state.data.droppedItems),
        },
      };
    }
    case ACTION_TYPE.ACTION_RESPONSE_REPLAY: {
      const resultsIds = action.payload.results.map(
        ({ caseRevisionItem: { inventoryItem } }) => inventoryItem.id
      );
      const ids = action.payload.strip.map((stripLine, index) => [
        ...stripLine.slice(27, 28),
        resultsIds[index],
        ...stripLine.slice(29, 30),
        ...stripLine.slice(3),
      ]);
      return {
        ...state,
        data: {
          droppedItems: action.payload.results,
          ids,
        },
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initStateAnimationStatus: RouletteStatusState = {
  rouletteStatus: {
    0: RouletteStatus.BEFORE_ROLL,
    1: RouletteStatus.BEFORE_ROLL,
    2: RouletteStatus.BEFORE_ROLL,
    3: RouletteStatus.BEFORE_ROLL,
    4: RouletteStatus.BEFORE_ROLL,
  },
  isAllInRoll: false,
  isAllAfterRoll: false,
  isAllBeforeRoll: true,
  isAllStartRoll: false,
};

export const rouletteStatusReducer = (
  state: RouletteStatusState = initStateAnimationStatus,
  action: RouletteStatusActions
) => {
  switch (action.type) {
    case ACTION_TYPE.START_ROLL_ALL: {
      return {
        ...state,
        rouletteStatus: {
          0: RouletteStatus.ROLL_START,
          1: RouletteStatus.ROLL_START,
          2: RouletteStatus.ROLL_START,
          3: RouletteStatus.ROLL_START,
          4: RouletteStatus.ROLL_START,
        },
        isAllBeforeRoll: false,
        isAllStartRoll: true,
      };
    }
    case ACTION_TYPE.BEFORE_ROLL_ALL: {
      return initStateAnimationStatus;
    }
    case ACTION_TYPE.IN_ROLL_ALL: {
      return {
        ...state,
        rouletteStatus: {
          0: RouletteStatus.IN_ROLL,
          1: RouletteStatus.IN_ROLL,
          2: RouletteStatus.IN_ROLL,
          3: RouletteStatus.IN_ROLL,
          4: RouletteStatus.IN_ROLL,
        },
        isAllInRoll: true,
        isAllAfterRoll: false,
        isAllBeforeRoll: false,
        isAllStartRoll: false,
      };
    }
    case ACTION_TYPE.AFTER_ROLL: {
      const { rouletteStatusTemp, isStatusValid } = checkStatus(
        state.rouletteStatus,
        RouletteStatus.AFTER_ROLL,
        action.payload.lineIndex,
        action.payload.lineCount
      );

      return {
        rouletteStatus: rouletteStatusTemp,
        isAllAfterRoll: isStatusValid,
        isAllInRoll: !isStatusValid,
        isAllBeforeRoll: false,
        isAllStartRoll: false,
      };
    }
    case ACTION_TYPE.IN_ROLL: {
      if (action.payload.lineCount - 1 === action.payload.lineIndex) {
        const { rouletteStatusTemp, isStatusValid } = checkStatus(
          state.rouletteStatus,
          RouletteStatus.IN_ROLL,
          action.payload.lineIndex,
          action.payload.lineCount
        );

        return {
          rouletteStatus: rouletteStatusTemp,
          isAllAfterRoll: false,
          isAllInRoll: isStatusValid,
          isAllBeforeRoll: false,
          isAllStartRoll: false,
        };
      } else {
        return {
          isAllInRoll: false,
          isAllAfterRoll: false,
          isAllBeforeRoll: false,
          isAllStartRoll: false,
          rouletteStatus: {
            ...state.rouletteStatus,
            [action.payload.lineIndex]: RouletteStatus.IN_ROLL,
          },
        };
      }
    }
    case ACTION_TYPE.BEFORE_ROLL: {
      if (action.payload.lineCount - 1 === action.payload.lineIndex) {
        const { rouletteStatusTemp, isStatusValid } = checkStatus(
          state.rouletteStatus,
          RouletteStatus.BEFORE_ROLL,
          action.payload.lineIndex,
          action.payload.lineCount
        );
        return {
          rouletteStatus: rouletteStatusTemp,
          isAllAfterRoll: false,
          isAllInRoll: false,
          isAllStartRoll: false,
          isAllBeforeRoll: isStatusValid,
        };
      } else {
        return {
          isAllInRoll: false,
          isAllAfterRoll: false,
          isAllStartRoll: false,
          isAllBeforeRoll: false,
          rouletteStatus: {
            ...state.rouletteStatus,
            [action.payload.lineIndex]: RouletteStatus.BEFORE_ROLL,
          },
        };
      }
    }
    default: {
      return state;
    }
  }
};

const InitWinItemStatus: WinItemStatusReducerState = {
  itemsStatus: {
    0: SoldStatus.INIT,
    1: SoldStatus.INIT,
    2: SoldStatus.INIT,
    3: SoldStatus.INIT,
    4: SoldStatus.INIT,
  },
  isAllSold: true,
};
export const winItemStatusReducer = (
  state: WinItemStatusReducerState = InitWinItemStatus,
  action: WinItemStatusActions
) => {
  switch (action.type) {
    case ACTION_TYPE.CHANGE_ITEMS_SELL_TYPE: {
      return {
        itemsStatus: {
          0: SoldStatus.SOLD,
          1: SoldStatus.SOLD,
          2: SoldStatus.SOLD,
          3: SoldStatus.SOLD,
          4: SoldStatus.SOLD,
        },
        isAllSold: true,
      };
    }
    case ACTION_TYPE.CHANGE_ITEMS_SELL_TYPE_TO_INIT: {
      return { ...InitWinItemStatus, isAllSold: false };
    }
    case ACTION_TYPE.CHANGE_ITEM_SELL_TYPE_BY_INDEX: {
      let isAllSoldArray: boolean[] = [];

      const itemStatusTemp = {
        ...state.itemsStatus,
        [action.payload.index]: SoldStatus.SOLD,
      };

      for (let key in itemStatusTemp) {
        if (itemStatusTemp[key] === SoldStatus.SOLD) {
          isAllSoldArray.push(true);
        }
      }

      return {
        itemsStatus: itemStatusTemp,
        isAllSold:
          isAllSoldArray.every(value => value) &&
          action.payload.lineCount === isAllSoldArray.length,
      };
    }
    default:
      return state;
  }
};

export const openCaseTypeReducer = (state: OpenCaseType = 'demo', action: OpenCaseTypeAction) => {
  switch (action.type) {
    case ACTION_TYPE.OPEN_CASE_TYPE_ACTION: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
export const actionSetCasesSoundType = (type: string) => ({
  type: ACTION_TYPE.SET_SOUND_TYPE,
  payload: type,
});

export const soundTypeReducer = (state: string = getSounds().cases, action: ActionSetSoundType) => {
  switch (action.type) {
    case ACTION_TYPE.SET_SOUND_TYPE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const actionChangeSpeedRoll = (speedType: SpeedType) => ({
  type: ACTION_TYPE.ACTION_CHANGE_SPEED_TYPE,
  payload: speedType,
});

export const speedTypeReducer = (state: SpeedType = 'normal', action: ActionChangeSpeedRoll) => {
  switch (action.type) {
    case ACTION_TYPE.ACTION_CHANGE_SPEED_TYPE: {
      return action.payload;
    }
    default:
      return state;
  }
};

//TODO: add logic for type opening {replay, free, demo ,payable}
//TODO add new case for roulette building
