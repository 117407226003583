import styled from 'styled-components';
import { couponType } from '../../../common.utils';

export interface IBaseLotStyle {
  ticketColor: string;
  participant: boolean;
  limit?: number;
  members?: number;
  clamp?: number;
}

export const LotContainer = styled.div.attrs(
  ({ limit, clamp, members, ticketColor, participant }: IBaseLotStyle) => ({
    clamp: !!clamp && members >= clamp && 'var(--yellow)',
    limit: limit === members && 'var(--color-red)',
    color: `var(--${couponType[ticketColor]}-race)`,
    background: participant ? 'rgba(36, 48, 56, 0.5);' : 'var(--color-dark-200)',
  })
)<IBaseLotStyle>`
  --lot-color: ${p => p.color};
  display: grid;
  grid-template-columns: 0.25rem 0.85fr 1fr;
  grid-template-rows: 1rem 1fr 2rem;
  grid-gap: 0.5rem 0.25rem;
  height: 7.5rem;
  padding: 0.9375rem 0.75rem 0.75rem;
  background-color: ${p => p.background};
  border-radius: var(--size-radius-m);
  position: relative;

  & .lot-participant {
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;

    & .icon-users-plus {
      color: var(--yellow);
      font-size: 1.1rem;
    }
  }

  & .lot-rarity-list {
    display: flex;
    flex-flow: column;
    justify-content: center;
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: span 3;

    & li {
      width: 0.25rem;
      height: 0.25rem;
      margin-bottom: 0.125rem;
      border-radius: 1px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .lot-thumbnail {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: span 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: column;

    font-size: 0.75rem;
    line-height: 0.9375rem;
    letter-spacing: 0.5px;
    color: var(--color-white-600);

    & img {
      width: 5.625rem;
      height: 4.375rem;
      object-fit: contain;
    }
  }

  & .lot-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    font-size: 0.8125rem;
    line-height: 0.9375rem;

    &__total-price {
      font-weight: bold;
      color: white;
    }

    &__all-members-unlimit,
    &__all-members {
      & .icon-users {
        font-size: 0.9rem;
        vertical-align: bottom;
        margin-right: 0.125rem;
        line-height: 1rem;
      }
    }

    &__all-members-unlimit {
      color: var(--color-gray-200);
    }

    &__all-members {
      color: ${p => p.limit || p.clamp || 'var(--color-gray-200)'};
    }
  }

  & .lot-description {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: white;
    overflow: hidden;

    &__lot-count {
      font-weight: inherit;
    }

    &__lot-name {
      display: block;
      width: 100%;
      font-weight: inherit;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  & .lot-actions {
    position: relative;

    &__participant,
    &__success {
      opacity: ${p => (p.hide ? 0 : 1)};
    }
  }
`;

export const ParticipationAction = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--lot-color);
  border-radius: var(--size-radius-m);
  background: transparent;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 150ms cubic-bezier(0.37, 0, 0.63, 1);

  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: white;
  & > i {
    margin: 0 0.4rem 0.125rem;
    color: var(--lot-color);
  }
`;

export const WinnerUser = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 150ms cubic-bezier(0.37, 0, 0.63, 1);
  border-color: transparent;

  &.bronze {
    border-color: var(--color-account-type-bronze);
  }
  &.silver {
    border-color: var(--color-account-type-silver);
  }
  &.gold {
    border-color: var(--color-account-type-gold);
  }
  &.platinum {
    border-color: var(--color-account-type-platinum);
  }

  &.diamond {
    border-color: var(--color-account-type--diamond);
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    margin-right: 0.3rem;
    pointer-events: auto;
    border-radius: 50%;
    border: 2px solid;
    border-color: inherit;
    overflow: hidden;

    & > img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }
  }

  & > span {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--color-white);

    width: 100%;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
