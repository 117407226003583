import React, { memo } from 'react';
import { Container } from './ParticipantInfo.styles';
import { ParticipantInfoStatus } from '../ParticipantInfoStatus';
import { GameStatus } from 'games/CaseBattle/constants';
import { Roulette } from '../Roulette';
import { isEqual } from 'lodash';

interface ParticipantInfoProps {
  isReady: boolean;
  isMember: boolean;
  isCreator: boolean;
  gamePrice: number;
  gameStatus: GameStatus;
  playerId: number;
  showAwaiting: boolean;
  teamNumber: number;
  isJoker: boolean;
}

export const ParticipantInfo: React.FC<ParticipantInfoProps> = memo(
  ({
    isReady,
    isCreator,
    isMember,
    gamePrice,
    gameStatus,
    playerId,
    showAwaiting,
    teamNumber,
    isJoker,
  }) => {
    return (
      <Container>
        {gameStatus === GameStatus.waiting ? (
          <ParticipantInfoStatus
            gamePrice={gamePrice}
            isMember={isMember}
            isCreator={isCreator}
            isReady={isReady}
            showAwaiting={showAwaiting}
            teamNumber={teamNumber}
            isJoker={isJoker}
          />
        ) : (
          <Roulette playerId={playerId} />
        )}
      </Container>
    );
  },
  isEqual
);
