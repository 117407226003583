import React, { FC, memo } from 'react';
import {
  Container,
  Status,
  StyledButton,
  Circle,
  IconContainer,
  CurrencyTextContainer,
} from './ParticipantInfoStatus.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useTranslation } from 'react-i18next';
import { useUser } from 'core/User';
import { useGetButtonParams } from './use-participant-info.hook';

interface ParticipantInfoStatusProps {
  isReady: boolean;
  isMember: boolean;
  isCreator: boolean;
  gamePrice: number;
  showAwaiting: boolean;
  teamNumber: number;
  isJoker: boolean;
}

export const ParticipantInfoStatus: FC<ParticipantInfoStatusProps> = memo(
  ({ isReady, isMember, isCreator, gamePrice, teamNumber, showAwaiting, isJoker }) => {
    const { t } = useTranslation();
    const { user } = useUser();
    const { clickHandler, className, text, additionalText = null } = useGetButtonParams({
      accountType: user?.profileType,
      isCreator,
      isMember,
      isReady,
      balance: user?.coins,
      gamePrice,
      userId: user?.id,
      teamNumber,
    });

    return (
      <Container>
        {isReady ? (
          <IconContainer isJoker={isJoker}>
            {' '}
            <CaseBattleIcon.CheckMark />
          </IconContainer>
        ) : (
          <IconContainer>
            {' '}
            <Circle className="skeleton" />
          </IconContainer>
        )}
        <Status isReady={isReady}>
          {isReady && t('ready')}
          {!isReady && showAwaiting && t('awaiting')}
          {!isReady && !showAwaiting && t('waitingGamer')}
        </Status>
        {!isReady && !showAwaiting && (
          <StyledButton onClick={clickHandler} className={className} isJoker={isJoker}>
            {t(text)}
            {additionalText && (
              <CurrencyTextContainer isJoker={isJoker}>{additionalText}</CurrencyTextContainer>
            )}
          </StyledButton>
        )}
      </Container>
    );
  }
);
//TODO: add handlers , add correct text
