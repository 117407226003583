import React from 'react';

export const ProgressBg = ({ containerWidth }: { containerWidth: number }) => {
  return (
    <svg
      width={containerWidth}
      height="20"
      viewBox={`0 0 ${containerWidth} 20`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_728_55528)">
        <rect
          x="4"
          y="3"
          width={containerWidth - 7}
          height="12"
          rx="4"
          fill="url(#paint0_linear_728_55528)"
        />
        <rect
          x="2.5"
          y="1.5"
          width={containerWidth - 4}
          height="15"
          rx="5.5"
          fill="url(#paint0_linear_728_55528)"
          stroke="url(#paint1_linear_728_55528)"
          strokeWidth="3"
        />
        <rect
          x="4"
          y="4"
          width={containerWidth - 8.717}
          height="10"
          rx="4"
          fill="url(#paint3_linear_728_55528)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_728_55528"
          x="0"
          y="0"
          width={containerWidth}
          height="19.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.34902 0 0 0 0 0.0901961 0 0 0 0 0.243137 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_728_55528" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_728_55528"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_728_55528"
          x1="102.507"
          y1="3"
          x2="102.507"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#863893" />
          <stop offset="1" stopColor="#650F74" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_728_55528"
          x1="87.2105"
          y1="1.5"
          x2="87.2105"
          y2="19.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#863893" />
          <stop offset="1" stopColor="#650F74" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_728_55528"
          x1="101.827"
          y1="4"
          x2="101.827"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#863893" />
          <stop offset="1" stopColor="#650F74" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_728_55528"
          x1="4"
          y1="9"
          x2="188.717"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#572C5F" />
          <stop offset="1" stopColor="#650F74" />
        </linearGradient>
      </defs>
    </svg>
  );
};
