import styled from 'styled-components';

export const AnimatedRoute = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform, opacity;
`;
