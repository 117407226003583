export type SoundGames = 'cases' | 'case-battle';

interface ISounds {
  cases: string;
  'case-battle': string;
}

export const getSounds = () => {
  const sounds = localStorage.getItem('sounds');

  let result: ISounds;

  if (sounds) {
    try {
      result = {
        cases: JSON.parse(sounds).cases,
        'case-battle': JSON.parse(sounds)['case-battle'],
      };
    } catch (error) {
      result = {
        cases: sounds,
        'case-battle': sounds,
      };

      localStorage.setItem('sounds', JSON.stringify(result));
    }
  } else {
    result = {
      cases: 'standard',
      'case-battle': 'standard',
    };

    localStorage.setItem('sounds', JSON.stringify(result));
  }

  return result;
};
