import React, { FC, memo } from 'react';
import { CurrencyText } from '@components/currency';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { useInventoryElements, useSalesSelectedControllers } from '../../../../hooks';
import { PluralizeTimer } from '../../../../../../@components/TimerEvent';
import { MarketplaceIcons } from 'modules/marketplace/components/@shared/assets';
import { useTranslation } from 'react-i18next';
import { Container, Locker } from './inventory-element.styles';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IInventoryElement {
  id: string;
}

export const InventoryElement: FC<IInventoryElement> = memo(({ id }) => {
  const { item } = useInventoryElements(id);
  const { contain, toggleSelected } = useSalesSelectedControllers(id);
  const { t } = useTranslation('marketplace');
  if (!item) return <Container className="skeleton" />;

  return (
    <Container rarity={item?.baseItem.color} contain={contain} onClick={toggleSelected(item)}>
      <div className="left-indicators">
        {item?.inTrade && (
          <span className="in-trade">
            <i className="icon-marketplace-play" />
          </span>
        )}
        <span className="selected">
          <i className="icon-checked-fill" />
        </span>
      </div>
      <div className="right-indicators">
        {!!item.stickers.length && (
          <div className="stickers">
            <i className="icon-bonus-lvl-up" />
            <span>{item.stickers.length}</span>
          </div>
        )}
      </div>
      <div className="steam-hold">
        {!!item.tradeLockEndAt && (
          <>
            <i className="icon-lock-fill" />
            <span>
              <PluralizeTimer finishEvent={item.tradeLockEndAt} />
            </span>
          </>
        )}
      </div>
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item?.baseItem.icon, 112, 80)} 320w, ${skinItemImage(
            item?.baseItem.icon,
            224,
            160
          )} 375w`}
          src={skinItemImage(item?.baseItem.icon, 112, 80)}
          alt=""
        />
      </div>
      <div className={`float ${getExteriorByFloat(item.float)}`}>
        {!!item.float && item.float?.toFixed(4)}
      </div>
      <div className="name">{item?.baseItem.name} </div>
      <div className="price">
        <CurrencyText value={item.price} />
      </div>

      {!item.tradable ? (
        <Locker>
          <MarketplaceIcons.LockDateIcon />
          {t('locked.lockedByDate')}
        </Locker>
      ) : item.price < 1 ? (
        <Locker>
          <MarketplaceIcons.LockPriceIcon />
        </Locker>
      ) : null}
    </Container>
  );
});
