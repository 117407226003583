import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-content: flex-end;
  grid-gap: 0.25rem;
  padding: 0 1rem 0.375rem;
  height: 4.375rem;

  & .info,
  & .sub-info {
    color: var(--color-white);
  }

  & .info {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  & .sub-info {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
  }
`;
