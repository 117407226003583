import React from 'react';
import styled from 'styled-components';
import Quest from './Quest';
import { useTranslation } from 'react-i18next';

export const ServiceCommission = () => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <Quest height={200} title={t('What is Service Commission?')}>
        {t(
          'Commission depends on a selected game mode and total deposit of a round Have a look at Service Commission for Classic Game and Fast Game There is no commission in the Double Game'
        )}
      </Quest>
      <Quest
        height={200}
        title={t(
          'My skins were taken as a commission How to get them back or exchange for others?'
        )}
      >
        {t('Random skins from a general deposit are taken as a Service Commission')}
        <br />
        <br />
        {t(
          'There is no opportunity to return you taken as a Service Commission skins or exchange them for others'
        )}
      </Quest>
      <Quest height={200} title={t('Service has deducted a big Service Commission')}>
        {t(
          'If a Service Commission is bigger than it is indicated by our rules, contact our tech support: support@csgofastcom'
        )}
      </Quest>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;
