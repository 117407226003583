import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../duck';

export const useCreateNotification = () => {
  const dispatch = useDispatch();

  const handlePushNotification = useCallback(
    (type, header = null, body = null) => {
      dispatch(
        addNotification({
          type,
          header,
          body,
        })
      );
    },
    [dispatch]
  );

  return {
    handlePushNotification,
  };
};
