import React, { useCallback, useEffect, useState } from 'react';

export interface IGetDataForWalletPix {
  wallet: string;
  onChangeAccountType: (type: string) => void;
  onChangeDocumentType: (type: string) => void;
  onChangeDocument: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTaxAccount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFullName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEmailAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accountType: string;
  documentType: string;
  document: string;
  taxAccount: string;
  fullName: string;
  emailAddress: string;
  documentFieldError: (value: boolean) => void;
  taxAccountFieldError: (value: boolean) => void;
  fullNameFieldError: (value: boolean) => void;
  emailAddressFieldError: (value: boolean) => void;
  errorDocumentField: boolean;
  errorTaxAccountField: boolean;
  errorFullNameField: boolean;
  errorEmailAddressField: boolean;
  incorrectEmailAddressField: boolean;
  emailAddressFieldIncorrect: (value: boolean) => void;
}

export const useGetDataForWalletPix = (): IGetDataForWalletPix => {
  const [wallet, setWallet] = useState('');
  const [accountType, setAccountType] = useState('CPF');
  const [documentType, setDocumentType] = useState('CPF');
  const [document, setDocument] = useState('');
  const [taxAccount, setTaxAccount] = useState('');
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [errorDocumentField, setErrorDocumentField] = useState(false);
  const [errorTaxAccountField, setErrorTaxAccountField] = useState(false);
  const [errorFullNameField, setErrorFullNameField] = useState(false);
  const [errorEmailAddressField, setErrorEmailAddressField] = useState(false);
  const [incorrectEmailAddressField, setIncorrectEmailAddressField] = useState(false);

  useEffect(() => {
    setWallet(
      JSON.stringify({
        accountType,
        documentType,
        document,
        taxAccount,
        fullName,
        emailAddress,
      })
    );
  }, [accountType, document, documentType, emailAddress, fullName, taxAccount]);

  const onChangeAccountType = useCallback(type => {
    setAccountType(type);
  }, []);

  const onChangeDocumentType = useCallback(type => {
    setDocumentType(type);
  }, []);

  const onChangeDocument = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDocument(e.target.value);
  }, []);

  const onChangeTaxAccount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxAccount(e.target.value);
  }, []);

  const onChangeFullName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[\d]/g, '');
    setFullName(result);
  }, []);

  const onChangeEmailAddress = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  }, []);

  const documentFieldError = useCallback((value: boolean) => {
    setErrorDocumentField(value);
  }, []);

  const taxAccountFieldError = useCallback((value: boolean) => {
    setErrorTaxAccountField(value);
  }, []);

  const fullNameFieldError = useCallback((value: boolean) => {
    setErrorFullNameField(value);
  }, []);

  const emailAddressFieldError = useCallback((value: boolean) => {
    setErrorEmailAddressField(value);
  }, []);

  const emailAddressFieldIncorrect = useCallback((value: boolean) => {
    setIncorrectEmailAddressField(value);
  }, []);

  return {
    wallet,
    onChangeAccountType,
    onChangeDocumentType,
    onChangeDocument,
    onChangeTaxAccount,
    onChangeFullName,
    onChangeEmailAddress,
    accountType,
    documentType,
    document,
    taxAccount,
    fullName,
    emailAddress,
    documentFieldError,
    taxAccountFieldError,
    fullNameFieldError,
    emailAddressFieldError,
    errorDocumentField,
    errorTaxAccountField,
    errorFullNameField,
    errorEmailAddressField,
    incorrectEmailAddressField,
    emailAddressFieldIncorrect,
  };
};
