import React, { FC } from 'react';
import styled from 'styled-components';
import { ChartDoughnut } from './ChartDoughnut';
import { ChartProfit } from './styles';
import { useTranslation } from 'react-i18next';

export interface IStatisticProfit {
  key: string;
  value: number;
}

interface IStatisticProfitUpdated extends IStatisticProfit {
  name: string;
}

export interface IUserStatisticChartDoughnut {
  totalGamePlayed: IStatisticProfit[];
  totalGameSum: number;
}

const types: { [key: string]: string } = {
  pvp: 'PvP',
  pve: 'PvE',
  'solo-pve': 'Solo PvE',
};

export const UserStatisticChartDoughnut: FC<IUserStatisticChartDoughnut> = ({
  totalGamePlayed,
  totalGameSum,
}) => {
  const { t } = useTranslation();

  const convertName = (item: IStatisticProfit): IStatisticProfitUpdated => ({
    ...item,
    name: types[item?.key],
  });

  return (
    <>
      <TitleCategory>{t('Statistic')}</TitleCategory>
      <Container>
        <ChartDoughnut
          padding={40}
          data={convertName(totalGamePlayed[2])}
          fullProgress={totalGameSum}
        />
        <ChartDoughnut
          padding={90}
          data={convertName(totalGamePlayed[1])}
          fullProgress={totalGameSum}
        />
        <ChartDoughnut
          padding={140}
          data={convertName(totalGamePlayed[0])}
          fullProgress={totalGameSum}
        />
        <ChartProfit>
          <span className="profit-title">{t('Total games played')}</span>
          <span className="profit-total">{totalGameSum}</span>
          <div className="profit-description">
            {totalGamePlayed.map((item: IStatisticProfit) => (
              <span key={item.key} style={{ color: `var(--color-${item.key})` }}>
                {item.value}
              </span>
            ))}
          </div>
        </ChartProfit>
      </Container>
    </>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100vw;
  justify-content: center;
  padding: 0 1.25rem 3rem;
  position: relative;
`;
const TitleCategory = styled.span`
  color: var(--color-gray-200);
  font-size: 0.86rem;
  letter-spacing: 0.53px;
  padding: 30px 0 30px 1.25rem;
  text-transform: uppercase;
`;
