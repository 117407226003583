import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';

export const Wrapper = styled.div`
  background: var(--color-dark-50);
  gap: 12px;
  padding: 38px 16px 60px;
`;

export const Container = styled(SkeletonElement)`
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  height: 212px;
`;

export const Block = styled.div`
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 16px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  gap: 13px;
`;

export const Title = styled.span`
  color: var(--color-red-100);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
`;

export const Text = styled.p`
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

export const Bottom = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  & > button:first-child {
    flex-grow: 1;
    width: auto;
  }
`;

export const MyBtn = styled.a`
  background-color: transparent;
  color: var(--color-white);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  border: 0;
  padding: 0;

  &:active {
    color: var(--color-gray-200);
  }
`;

export const SteamButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const AccountWarningWrap = styled.div`
  display: flex;
  gap: 15px;
`;

export const AccountWarningLink = styled.a`
  color: var(--aqua-500);
`;

export const AccountWarning = styled.p`
  color: var(--color-white);
  font-size: 12px;
  letter-spacing: 0.571px;
  line-height: 18px;
  width: fit-content;
`;
