import React from 'react';
import { Icon, Preload } from './loader.styles';
import logo from 'assets/images/logo-go.png';
import loading from 'assets/images/loading.gif';
// import { loader } from 'modules/event-module';

export const Loader = () => {
  return (
    <Preload>
      <Icon src={loading} />
      <img src={logo} alt="" width="150px" height="41px" />
    </Preload>
  );
};
