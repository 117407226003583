import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTournamentHistoryByParams } from 'modules/LeadersRace/selectors';
import { fetchRaceStatistic } from '../duck';
import { useAccountProfile } from './useAccountProfile';

export const useRaceActivity = () => {
  const { profile } = useAccountProfile();
  const [greatType, setGreatType] = useState<number>(0);
  const [fastType, setFastType] = useState<number>(0);

  const dispatch = useDispatch();

  const greatRace = useSelector(
    state => getTournamentHistoryByParams(state, 'great'),
    shallowEqual
  );
  const fastRace = useSelector(state => getTournamentHistoryByParams(state, 'fast'), shallowEqual);

  const handleChangeGreatType = useCallback(
    (index: number) => {
      setGreatType(index);
      setFastType(0);
    },
    [setGreatType]
  );

  const handleChangeFastType = useCallback(
    (index: number) => () => {
      setFastType(index);
    },
    [setFastType]
  );

  useEffect(() => {
    const lng = fastRace.entities.length;
    const begin = greatType * 4 ? greatType * 4 - (lng % 4) : greatType * 4;
    if (fastRace.entities[begin + fastType]) {
      dispatch(fetchRaceStatistic(profile.id, fastRace.entities[begin + fastType].id));
    }
  }, [dispatch, greatType, fastType, fastRace.entities, profile.id]);

  return {
    greatType,
    fastType,

    greatRace,
    fastRace,

    handleChangeGreatType,
    handleChangeFastType,
  };
};
