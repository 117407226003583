import { Reducer } from 'redux';
import { Record } from 'immutable';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import ticketsRepository from '../repositories/leaders.tickets.repository';
import { addNotification } from '../../Notifications/duck';
import { couponActions, couponsActionsTypes } from '../interfaces/actions.leaders.interfaces';
import { ITickets } from '../interfaces/reducer.leaders.interfaces';

const CouponRecord = Record({
  green: 0,
  blue: 0,
  orange: 0,
});

export const coupons: Reducer<Record<ITickets>, couponActions> = (
  state = new CouponRecord(),
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case couponsActionsTypes.UPDATE_TICKETS:
      const { coupons } = payload;
      return Object.keys(coupons).reduce(
        (acc: Record<ITickets>, key: string) =>
          acc.has(key) ? acc.setIn([key], coupons[key]) : state,
        state
      );

    default:
      return state;
  }
};

export const fetchTickets = () => ({
  type: couponsActionsTypes.FETCH_TICKETS,
});

const updateUserCoupon = (coupons: ITickets): couponActions => ({
  type: couponsActionsTypes.UPDATE_TICKETS,
  payload: {
    coupons,
  },
});

export const ticketsEpic: Epic = action$ =>
  action$.pipe(
    ofType(couponsActionsTypes.FETCH_TICKETS, 'INITIALIZE_LEADER_RACE'),
    debounceTime(700),
    mergeMap(() =>
      ticketsRepository.fetchTickets().pipe(
        map(({ response }) => updateUserCoupon(response)),
        catchError(({ error }) =>
          of(
            addNotification({
              type: 'error',
              body: error,
            })
          )
        )
      )
    )
  );
