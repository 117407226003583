import React, { FC, MutableRefObject, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from '@components/Common';
import { OrderDetails } from '../order-details';
import { Link, useLocation } from 'react-router-dom';

export enum OrderType {
  BUY = 'buy',
  SELL = 'sell',
}

export type SwitchTypeRenderProp = {
  type: OrderType;
  parentRef: MutableRefObject<HTMLDivElement>;
};

interface IOrdersSwitchType {
  children(props: SwitchTypeRenderProp): JSX.Element;
}

export const OrdersSwitchType: FC<IOrdersSwitchType> = ({ children }) => {
  const local = useLocation();
  const initial = useMemo<OrderType>(() => {
    const v: OrderType = new URLSearchParams(local.search).get('type') as OrderType;
    if ([OrderType.BUY, OrderType.SELL].includes(v)) return v;
    else return OrderType.BUY;
  }, [local]);

  const parentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('marketplace');
  const { register, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: initial,
      mode: false,
    },
  });

  const type = watch('type');
  const mode = watch('mode');

  return (
    <Container ref={parentRef} className={mode && 'edit'}>
      <OrderDetails type={type} />
      <div className="switch-container">
        <div className="switch">
          <label>
            <input type="radio" name="type" value={OrderType.BUY} ref={register} />
            <span>{t('Buy')}</span>
          </label>
          <label>
            <input type="radio" name="type" value={OrderType.SELL} ref={register} />
            <span>{t('Sell')}</span>
          </label>
        </div>
      </div>
      {children({ type, parentRef })}
      <div className="controller">
        <Button className="secondary controller-mode" as="label">
          <input type="checkbox" name="mode" ref={register} />
          {t('Trade control')}
        </Button>
        <Button className="primary controller-inventory" as={Link} to="/market/inventory">
          {t('To inventory')}
        </Button>
        <Button className="primary controller-store" as={Link} to="/market">
          {t('To marketplace')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content) minmax(min-content, 1fr);
  grid-template-columns: 1fr;
  padding: 0.625rem 0 0;
  overflow: hidden scroll;
  min-height: 100%;
  background: var(--color-dark-layout);

  & .switch-container {
    padding: 0.75rem 1rem 1.25rem;
    height: 4.125rem;
    position: sticky;
    top: -0.625rem;
    z-index: 100;
    background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
    backdrop-filter: blur(4px);

    & .switch {
      align-self: self-start;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1.625rem;
      background: var(--color-dark-200);
      border-radius: 0.375rem;
      border: 2px solid transparent;

      & label {
        & input {
          display: none;

          &:checked + span {
            background: #47555f;
            color: var(--color-white);
          }
        }

        & span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: var(--size-radius-s);
          font-weight: bold;
          font-size: 0.6875rem;
          line-height: 0.875rem;
          letter-spacing: 0.3px;
          color: var(--color-gray-200);
          text-transform: uppercase;
          transition: all 100ms ease-in;
        }
      }
    }
  }

  &.edit .wrapper {
    transform: translate3d(3rem, 0, 0);
  }

  & > div:not(.empty-sale-layout) ~ .controller label,
  & > div:not(.empty-purchase-layout) ~ .controller label,
  & .empty-purchase-layout ~ .controller .controller-store,
  & .empty-sale-layout ~ .controller .controller-inventory {
    opacity: 1;
    pointer-events: auto;
  }

  & > .controller {
    padding: 1.5rem 1rem;
    height: 6rem;
    background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
    position: sticky;
    bottom: 0;

    & label,
    & a,
    & button {
      opacity: 0;
      left: 1rem;
      right: 1rem;
      bottom: 1.5rem;
      position: absolute;
      pointer-events: none;
      width: auto;
    }

    & label {
      & input {
        display: none;
      }
    }
  }
`;
