import styled from 'styled-components';

export const Header = styled.div`
  align-items: center;
  background: var(--color-dark-60);
  color: var(--color-white);
  display: flex;
  height: var(--size-nav-bar);
  justify-content: space-between;
  padding: 0 1rem;
`;

export const BackBtn = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  height: 30px;
  padding-left: 0;
  width: 30px;

  & > i[class^='icon-'] {
    display: block;
    font-size: 1rem;
  }

  & > i[class='icon-arrow'] {
    transform-origin: center;
    transform: rotate(90deg);
  }
`;

export const Title = styled.span`
  font-size: 0.94rem;
  font-weight: 700;
`;

export const ReadyBtn = styled.button`
  background-color: inherit;
  border: 0;
  color: var(--aqua-600);
  font-size: 0.88rem;
  font-weight: 700;
  padding: 0;
`;

//----------------------------------------

export const Container = styled.div`
  background: var(--color-dark-60);
  height: calc(100% - var(--size-nav-bar));
`;

export const Input = styled.input`
  background: var(--gray-500);
  border: 0;
  color: var(--color-white);
  font-size: 16px;
  height: 100%;
  letter-spacing: 0.7px;
  padding: 10px 0;
  width: 100%;
`;

export const Username = styled.div`
  background-color: var(--gray-400);
  border: 2px solid transparent;
  padding: 0 15px;
  position: relative;

  &.error {
    border: 2px solid var(--color-red-100);
  }
`;

export const Text = styled.div`
  color: var(--color-white);
  font-size: 13px;
  letter-spacing: 0.9px;
  opacity: 0.6;
  padding-top: 15px;
`;

export const UploadFile = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;

  & input[type='file'] {
    display: none;
  }

  & label {
    margin: 18px 30vw 1rem;
    padding: 15vw;
  }
`;

export const Content = styled.div`
  padding: 15px 15px 10px;
`;
