import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPayoutAggregator } from '../selectors';
import { updatePayoutOrder } from '../duck';
import { IStore } from 'store/interface';

export const usePayoutAggregator = () => {
  const aggregator = useSelector<IStore, number>(getPayoutAggregator, shallowEqual);
  const dispatch = useDispatch();

  const handleChangeAggregator = useCallback(
    (idx: number) => () => {
      dispatch(updatePayoutOrder({ paymentSystem: idx, paymentWallet: null }));
    },
    [dispatch]
  );

  return {
    aggregator,
    handleChangeAggregator,
  };
};
