import React, { FC } from 'react';
import { RafflePreview, WaitingScreen } from '../components';
import { useRaffleFacade } from '../hooks';
import { IRaffle } from '../interfaces/reducer.leaders.interfaces';

export const Raffles: FC = (prop: any) => {
  const { raffles } = useRaffleFacade();
  if (!raffles.length && prop) {
    return <WaitingScreen />;
  }

  return (
    <>
      {raffles.map((raffle: IRaffle) => (
        <RafflePreview key={raffle.id} raffle={raffle} />
      ))}
    </>
  );
};
