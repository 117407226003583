import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { LineLegends } from './LineLegends';
import { chartData, data, options } from './line-chart.config';
import { merge } from 'lodash';
import type { IAccountStatisticQuests } from '../../interfaces';
import type { IUserState } from 'core/User/interfaces/user.reducer.interface';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';

interface ILineChart {
  profile: IUserInfo;
  quests: IAccountStatisticQuests;
  user: IUserState;
}

export const LineChart: FC<ILineChart> = memo(({ quests, profile, user }) => {
  const [config, setConfig] = useState<any>({ data, options });

  useEffect(() => {
    const mergeData = merge(
      {},
      {
        data,
        options,
      },
      chartData(quests, profile.id.toString(), user.id?.toString() || null)
    );
    setConfig(mergeData);
  }, [quests, profile.id, user.id]);

  return (
    <Container>
      <LineLegends />
      <Line data={config.data} options={config.options} />
    </Container>
  );
});

const Container = styled.div`
  background: var(--color-dark-600);
`;
