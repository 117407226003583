/* eslint-disable */
export default {
  winning: 'Winning',
  line: 'Line',
  lines: 'Lines',
  total: 'Total',
  Autoplay: 'Autoplay',
  Start: 'Start',
  Paytable: 'Paytable',
  'Recent wins': 'Recent wins',
  'Your games': 'Your games',
  'Bonus game': 'Bonus game',
  'Bonus game xN': 'Bonus game х{{count}}',
  'Bet per line': 'Bet per line',
  'Slot works regardless of location': 'Slot works regardless of location',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Can substitute any slot in the line (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    'Collect all three slots and get 10 bonus games!',
  'Total won:': 'Total won:',
  'Bonus games': 'Bonus games',
  'Bid amount': 'Bid ${currency(amount)}',
};
