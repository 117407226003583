import styled from 'styled-components';

interface AnimatedDivStyle {
  isOpen: boolean;
}

export const AnimatedContent = styled.div<AnimatedDivStyle>`
  background: var(--color-dark-layout);
  display: flex;
  flex-flow: column;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  position: relative;
  transform: ${p => (p.isOpen ? 'none' : 'translate3d(calc(10vw - 100vw), 0, 0)')};
  transition: transform 0.4s cubic-bezier(0.37, 0, 0.63, 1);

  .my-class-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .my-class-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform 300ms ease, opacity 300ms ease;
  }

  .my-class-exit {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }

  .my-class-exit-active {
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
    transition: all 300ms ease;
  }
`;
