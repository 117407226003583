import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { useTranslation } from 'react-i18next';

import type { IEventRewardType } from '../interfaces';

interface IEventRewardItem extends IEventRewardType {
  handleClick: () => void;
  value?: number;
  disabled: boolean;
}

export const EventRewardItem: FC<IEventRewardItem> = ({
  amount,
  experience,
  freeSpins,
  freeCases,
  handleClick,
  value,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      onClick={handleClick}
      className={`${amount / 100 === value ? 'active' : ''}`}
      disabled={disabled}
    >
      <div>{t('Refill amount')}</div>
      <div>
        <CurrencyText value={amount} />
      </div>
      <div>{t('Bonus experience')}</div>
      <div>
        {experience} {t('exp.')}
      </div>
      {freeSpins && (
        <>
          <div>{t('Bonus wheel spins')}</div>
          <div>
            <img
              src="//d2lomvz2jrw9ac.cloudfront.net/user-levels/rewards/free-spins.png"
              alt="free-spins"
            />
            {freeSpins}
          </div>
        </>
      )}
      {freeCases &&
        freeCases.map((freecase, index) => (
          <Fragment key={index}>
            <div key={`freecase-title-${index}`}>{t('Bonus cases')}</div>
            <div key={`freecase-image-${index}`}>
              <img src={freecase.image} alt="" />
              {freecase.count}
            </div>
          </Fragment>
        ))}
    </Container>
  );
};

export const Container = styled.button`
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  grid-gap: 0.5rem;
  padding: 1.25rem;
  background: var(--color-dark-500);
  border-radius: var(--size-radius-s);
  border: 0;
  transition: background var(--transition-ease-out);

  &.active {
    background: #47555f;
  }

  & div {
    display: flex;
    align-items: center;
    min-height: 1.5rem;
    font-size: 0.65rem;
    letter-spacing: 0.54px;
  }

  & div:not(:nth-child(1)) {
    text-transform: uppercase;
  }

  & div:nth-child(odd) {
    color: var(--color-white-600);
  }

  & div:nth-child(even) {
    color: var(--color-white);
  }

  & div:nth-child(2),
  & div:nth-child(1) {
    align-items: flex-start;
    font-size: 0.9rem;
    min-height: 2rem;
  }

  & div:nth-child(1) {
    color: var(--color-white);
  }
  & div:nth-child(2) {
    color: var(--color-green);
  }

  & img {
    width: 1.35rem;
    height: 1rem;
    object-fit: contain;
    margin-right: 0.25rem;
  }
`;
