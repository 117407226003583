import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  padding: 12px 16px;
  width: 100%;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
`;
export const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: var(--color-white);
`;
export const Close = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-gray-200);
  display: flex;
  justify-content: flex-end;
`;
