import { Reducer } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { delay, map, pluck, tap, throttleTime } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import {
  X50ActionsTypes,
  IX50Game,
  IFetchCreateBidRequest,
  IMember,
  X50Actions,
} from '../interfaces';
import { SectorColors } from '../utils';
import { dialogOff } from 'core/ducks/dialog';
import { ParticipateDialogs } from 'core/interfaces';
import SoundService from 'services/sound.service';

const sound = new SoundService();

const defaultGameState: IX50Game = {
  md5: null,
  number: null,
  rand: null,
  salt: null,
  timeOldEnds: null,
  history: [],
  members: {
    [SectorColors.BLUE]: {},
    [SectorColors.RED]: {},
    [SectorColors.GREEN]: {},
    [SectorColors.GOLD]: {},
  },
  totalSum: {
    [SectorColors.BLUE]: 0,
    [SectorColors.RED]: 0,
    [SectorColors.GREEN]: 0,
    [SectorColors.GOLD]: 0,
  },
};

export const reducer: Reducer<IX50Game, X50Actions> = (state = defaultGameState, action) => {
  switch (action.type) {
    case X50ActionsTypes.UPDATE_GAME_STATE: {
      const { topPlayers, ...data } = action.payload.data;

      const history = [...(data?.history ?? []), ...state.history].slice(0, 50);

      const members = (topPlayers ?? []).reduce(
        (acc: any, item: IMember) => ({
          ...acc,
          [item.betType]: {
            ...acc[item.betType],
            [item.playerId]: item,
          },
        }),
        state.number !== data.number && data.number ? defaultGameState.members : state.members
      );

      if (state.number !== data.number && data.number && state.number) {
        return {
          ...defaultGameState,
          ...data,
          history,
          members,
        };
      }

      return {
        ...state,
        ...data,
        members,
        history,
      };
    }

    default: {
      return state;
    }
  }
};

const updateGameState = (data: IX50Game) => ({
  type: X50ActionsTypes.UPDATE_GAME_STATE,
  payload: {
    data,
  },
});

const listenerEic: Epic = (action$, _, { socket }) => {
  return merge(
    fromEvent<IX50Game>(socket.io, 'all:init').pipe(pluck('state', 'rooms', 'x50')),
    fromEvent<IX50Game>(socket.io, 'rooms:x50:update')
  ).pipe(map(data => updateGameState(data)));
};
export const fetchCreateBid = (payload: IFetchCreateBidRequest) => ({
  type: X50ActionsTypes.FETCH_CREATE_PARTICIPATE,
  payload,
});

export const participateEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(X50ActionsTypes.FETCH_CREATE_PARTICIPATE),
    throttleTime(500),
    pluck('payload'),
    tap(({ color, amount }) => {
      sound.play('x50', 'bet_first');
      socket.io.emit('game:x50:bet', { color, amount });
    }),
    delay(500),
    map(() => dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG))
  );

export const x50Epics = combineEpics(listenerEic);
