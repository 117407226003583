import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getRaffleByType } from '../selectors';

type RaceTypeParams = {
  raffleType: string;
};

export const useRaffling = () => {
  const params = useParams<RaceTypeParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const raffle = useSelector(
    (state: any) => getRaffleByType(state, params.raffleType),
    shallowEqual
  );

  useEffect(() => {
    if (!raffle) {
      history.replace('/leaders-race-next/raffles/active');
      return;
    }
  }, [dispatch, history, raffle, params]);

  return {
    raffle,
  };
};
