import { refreshFastInventory } from '../../ducks/fast-inventory.duck';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useFastInventoryRefresh = () => {
  const dispatch = useDispatch();
  const refreshAction = useCallback(() => {
    dispatch(refreshFastInventory());
  }, [dispatch]);

  return {
    refreshAction,
  };
};
