import * as React from 'react';
import styled from 'styled-components';
import { QuestWidgetItem } from '../../components';
import { shallowEqual, useSelector } from 'react-redux';
import { getFilteredQuestsByRoutes } from '../../selectors';
import { IQuest } from '../../interfaces/reducer.leaders.interfaces';
import { useLocation } from 'react-router';
// import { fetchRacesQuest } from '../../ducks/quests.duck'
import { useQuestRewardFacade } from '../../hooks';

const useQuestWidgetFacade = () => {
  const { pathname } = useLocation();
  const quests = useSelector(
    (state: any) => getFilteredQuestsByRoutes(state, pathname.split('/')[1]),
    shallowEqual
  );
  // const dispatch = useDispatch()

  // React.useEffect(() => {
  //   dispatch(fetchRacesQuest())
  // }, [dispatch])

  return {
    quests,
  };
};

export const QuestWidget = React.memo(() => {
  const { quests } = useQuestWidgetFacade();
  const { takeRewarHandle } = useQuestRewardFacade();
  return (
    <Container>
      {quests.map((quests: IQuest) => (
        <QuestWidgetItem key={quests.id} {...quests} takeRewarHandle={takeRewarHandle(quests.id)} />
      ))}
    </Container>
  );
});

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex-shrink: 0;
  padding: 1.1rem 0;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    min-width: 3.2rem;
    min-height: 100%;
  }
`;
