import { DIALOG } from 'dialogs/dialog-combiner';
import { transitions } from 'core/Modals';

import { CasesCountsDialog } from './cases-counts-dialog';
import { CaseItemDialog } from 'games/CaseGame';

DIALOG.add(CaseItemDialog.COUNT_DIALOG, {
  component: CasesCountsDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
