import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUser } from '../core/User/selectors';
import { dialogChangeState } from '../core/ducks/dialog';
import { AuthDialogs } from '../core/Auth';

const PrivateRoute = Module =>
  connect(
    state => ({
      user: getUser(state),
    }),
    {
      dialogChangeState,
    }
  )(
    class PrivateModule extends React.PureComponent {
      static defaultProps = {
        user: {},
      };

      componentDidMount() {
        if (!this.props.user.id) {
          this.props.dialogChangeState(AuthDialogs.SIGN_IN, true);
        }
      }

      render() {
        if (this.props.user.id) return <Module {...this.props} />;

        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: this.props.location,
                skipDuration: this.props.location === '/',
              },
            }}
          />
        );
      }
    }
  );

export default PrivateRoute;
