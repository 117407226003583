import React from 'react';
import { ContentContainer, PriceSpan, StatTrakPrice, WearSpan } from './Wear.styles';
import { CurrencyText } from '@components/currency';

interface WearProps {
  exterior: string;
  steamPrice: number;
  statTrakPrice: number;
  img: string;
  onClick: (img: string) => void;
}
export const Wear: React.FC<WearProps> = React.memo(
  ({ exterior, steamPrice, statTrakPrice, onClick, img }) => {
    return (
      <ContentContainer onClick={() => onClick(img)}>
        <WearSpan>{exterior}</WearSpan>
        <PriceSpan>
          <CurrencyText value={steamPrice} />
        </PriceSpan>
        <StatTrakPrice isExist={!!statTrakPrice}>
          {statTrakPrice ? <CurrencyText value={statTrakPrice} /> : '--'}
        </StatTrakPrice>
      </ContentContainer>
    );
  }
);
