import { Reducer } from 'redux';
import Immutable from 'immutable';
import { combineEpics, Epic, ofType } from 'redux-observable';
import {
  IInventoryRecord,
  InventoryRecord,
  steamInventoryActions,
  SteamInventoryActionTypes,
} from '../interfaces';
import { catchError, concatMap, debounceTime, map, mergeMap, pluck } from 'rxjs/operators';
import { UserSteamInventoryRepository } from '../repositories';
import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

export const inventory: Reducer<Immutable.Record<IInventoryRecord>, steamInventoryActions> = (
  state = new InventoryRecord(),
  action
) => {
  switch (action.type) {
    case SteamInventoryActionTypes.FETCH_STEAM_INVENTORY: {
      return new InventoryRecord({
        loading: true,
        loaded: false,
        failure: false,
      });
    }

    case SteamInventoryActionTypes.FETCH_STEAM_INVENTORY_SUCCESS: {
      const { identifiers, entities, amount } = action.payload;
      return state
        .setIn(['meta'], { amount, count: identifiers.length })
        .setIn(['kits'], { entities });
    }

    case SteamInventoryActionTypes.FETCH_STEAM_INVENTORY_FAILURE: {
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('failure', true)
        .set('errorText', action.payload);
    }

    case SteamInventoryActionTypes.CHANGE_STEAM_INVENTORY_FILTERS: {
      const { filters } = action.payload;
      return state.update('filters', origin => ({
        ...origin,
        ...(filters ?? {}),
      }));
    }

    case SteamInventoryActionTypes.SORTED_STEAM_INVENTORY: {
      const { identifiers } = action.payload;
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('failure', false)
        .setIn(['kits', 'identifiers'], identifiers);
    }

    default: {
      return state;
    }
  }
};

export const fetchSteamInventory = (ignoreCache: boolean) => ({
  type: SteamInventoryActionTypes.FETCH_STEAM_INVENTORY,
  payload: {
    ignoreCache,
  },
});

const updateSteamInventorySuccess = (response: any) => ({
  type: SteamInventoryActionTypes.FETCH_STEAM_INVENTORY_SUCCESS,
  payload: response,
});

const updateSteamInventoryFailure = (error: string) => ({
  type: SteamInventoryActionTypes.FETCH_STEAM_INVENTORY_FAILURE,
  payload: error,
});

export const updateInventoryFilters = (filters?: Partial<IInventoryRecord['filters']>) => ({
  type: SteamInventoryActionTypes.CHANGE_STEAM_INVENTORY_FILTERS,
  payload: {
    filters,
  },
});

export const sortedInventoryItems = (identifiers: string[]) => ({
  type: SteamInventoryActionTypes.SORTED_STEAM_INVENTORY,
  payload: {
    identifiers,
  },
});

const loaderEpic: Epic = action$ =>
  action$.pipe(
    ofType(SteamInventoryActionTypes.FETCH_STEAM_INVENTORY),
    debounceTime(700),
    pluck('payload'),
    mergeMap(q =>
      UserSteamInventoryRepository.fetchInventory(q).pipe(
        pluck('response', 'items'),
        map(items =>
          items.reduce(
            (acc: any, elem: any) => {
              return elem?.passed
                ? {
                    identifiers: [...acc.identifiers, elem.steamInventoryId.toString()],
                    entities: {
                      ...acc.entities,
                      [elem.steamInventoryId]: elem,
                    },
                    amount: acc.amount + elem?.price || 0,
                  }
                : acc;
            },
            { identifiers: [], entities: {}, amount: 0 }
          )
        ),
        concatMap(r => {
          return [updateSteamInventorySuccess(r), updateInventoryFilters()];
        }),
        catchError(({ response }: AjaxError) => of(updateSteamInventoryFailure(response.message)))
      )
    )
  );

const filterEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(SteamInventoryActionTypes.CHANGE_STEAM_INVENTORY_FILTERS),
    debounceTime(1000),
    map(() => store$.value.market.inventory),
    map(({ filters, kits }) => ({ filters, entities: kits?.entities ?? {} })),
    map(({ filters: { query, sortBy }, entities }) => {
      return Object.keys(entities)
        .filter(id => entities[id].baseItem?.name.includes(query?.trim() ?? ''))
        .sort((l: any, r: any) => {
          if (['price', '-price'].includes(sortBy)) {
            return sortBy === 'price'
              ? entities[l].price - entities[r].price
              : entities[r].price - entities[l].price;
          }
          return sortBy === 'name'
            ? entities[l].baseItem.shortName
                ?.toString()
                .localeCompare(entities[r].baseItem.shortName?.toString())
            : entities[r].baseItem.shortName
                ?.toString()
                .localeCompare(entities[l].baseItem.shortName?.toString());
        });
    }),
    map(identifiers => sortedInventoryItems(identifiers))
  );

export const inventoryEpics = combineEpics(loaderEpic, filterEpic);
