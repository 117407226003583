import styled from 'styled-components';
import { NavigationLink } from '@components/Common/Navigations';

export const StyledNavigationLink = styled(NavigationLink)`
  background: var(--color-dark-600);
  & .leading {
    width: 1.375rem;
    height: 1.375rem;
    & svg {
      transform: none;
    }
    & img {
      width: 1.375rem;
      height: 1.375rem;
      position: absolute;
    }
    & .iconCrypto {
      width: 1.375rem;
      height: 1.375rem;
      position: absolute;
    }
  }
  & .disabled {
    color: #344d56;
  }
`;

export const Title = styled.div<{ isActive?: boolean }>`
  color: ${p => `${p.isActive ? 'var(--color-white)' : '#344D56'}`};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  & > span {
    font-size: 0.8rem;
    color: #9ea7b3;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
  }
  .iconEmail {
    width: 1.375rem;
    height: 1.375rem;
    position: absolute;
    right: 0;
    bottom: 8px;
  }
`;
