import React from 'react';
import { ProgressChart } from '../progress-chart';
import { Container, IconContainer } from './weekly-statuses.styles';
import { TicketTypeEnum } from 'modules/event-module/comics-event/interfaces';
import { EventIcons, eventImagesPath } from 'modules/event-module/comics-event/assets';

const coinTypeObject = {
  [TicketTypeEnum.DAILY]: (
    <img src={eventImagesPath.bronzeDailyPath} width={32} height={32} className="coin" alt="" />
  ),
  [TicketTypeEnum.SPECIAL]: (
    <img src={eventImagesPath.goldSpecialPath} width={32} height={32} className="coin" alt="" />
  ),
  [TicketTypeEnum.WEEKLY]: (
    <img src={eventImagesPath.silverWeeklyPath} width={32} height={32} className="coin" alt="" />
  ),
};

interface WeeklyStatusesProps {
  isHidden: boolean;
  isOpen: boolean;
  isLast: boolean;
  isRewardTaken: boolean;
  progress: number;
  takeRewardHandler: (questId: number) => void;
  questId: number;
  type: TicketTypeEnum;
}

export const WeeklyStatuses = React.memo(
  React.forwardRef<HTMLDivElement, WeeklyStatusesProps>(
    (
      { isHidden, isLast, isOpen, isRewardTaken, progress, questId, takeRewardHandler, type },
      ref
    ) => {
      const clickHandler = () => {
        if (progress >= 100 && !isRewardTaken) {
          takeRewardHandler(questId);
        }
      };

      const getProgressSize = () => {
        if (!isOpen && !isHidden) {
          return '32px';
        } else if (isRewardTaken) {
          return '54px';
        } else {
          return '100%';
        }
      };

      return (
        <Container
          onClick={clickHandler}
          size={getProgressSize()}
          isReadyToTaken={progress >= 100 && !isRewardTaken}
          ref={isOpen && !isRewardTaken ? ref : null}
        >
          {isOpen && progress < 100 && (
            <ProgressChart progress={progress} type={type} color={isLast ? '#FAC106' : '#599AFF'} />
          )}
          {!isOpen && !isHidden && <EventIcons.Lock />}
          {isLast && isHidden && !isOpen && <EventIcons.Special />}
          {!isRewardTaken && isOpen && progress >= 100 && (
            <ProgressChart progress={progress} type={type} color={isLast ? '#FAC106' : '#599AFF'} />
          )}
          {isRewardTaken && (
            <>
              {coinTypeObject[type]}
              <IconContainer>
                <EventIcons.Completed width={32} height={32} />
              </IconContainer>
            </>
          )}
        </Container>
      );
    }
  )
);
