import { createSelector } from 'reselect';
import { IStore } from 'store/interface';
import { ISlotHistory } from './interfaces';

export const getHistory = createSelector(
  ({ slot }: IStore, _: string): ISlotHistory[] => slot.history,
  (_: IStore, sortBy: string): number => Number(sortBy),
  (history: any[], sortBy: number) =>
    history.filter(({ userId }) => (!!sortBy ? sortBy === userId : true))
);

export const getSlotMatrix = ({ slot }: IStore) => slot.game.slotsMatrix;
export const getGamesLeft = ({ slot }: IStore) => slot.game.gamesLeft;
export const getCurrentBonusGame = ({ slot }: IStore) => slot.game.currentBonusGame;
export const getCombs = ({ slot }: IStore) => slot.game.combs;
export const getSlots = ({ slot }: IStore) => slot.game.slots;
export const getNumber = ({ slot }: IStore) => slot.game.number;
export const getTotalPrize = ({ slot }: IStore) => slot.game.totalPrize;
