export enum historyActionsTypes {
  REQUEST_HISTORY = 'app/history/REQUEST_HISTORY',
  RESPONSE_HISTORY = 'app/history/RESPONSE_HISTORY',
  CLEAN_UP = 'app/history/CLEAN_UP',
}

export interface IResponseHistory {
  type: typeof historyActionsTypes.RESPONSE_HISTORY;
  payload: {
    history: any[];
    gameTypeId: number;
  };
}

export interface IRequestHistory {
  type: typeof historyActionsTypes.REQUEST_HISTORY;
}

export interface ICleanUpHistory {
  type: typeof historyActionsTypes.CLEAN_UP;
}

export type historyActions = IResponseHistory | IRequestHistory | ICleanUpHistory;
