import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getInputAmount } from '../selectors';
import { updateInputAmount } from '../duck';
import { useParams } from 'react-router';

export const usePayoutAmount = () => {
  const amount = useSelector(getInputAmount, shallowEqual);
  const { method }: { method?: string } = useParams();
  const dispatch = useDispatch();

  const setAmount = useCallback(
    (value: number) => {
      dispatch(updateInputAmount(value, method));
    },
    [dispatch, method]
  );

  return [amount, setAmount];
};
