import { useSelector } from 'react-redux';
import { getWinnersInfo } from '../ducks';

export const useGetWinner = (battleId: number, userId?: number) => {
  const winnerIds = useSelector(getWinnersInfo(battleId));

  if (userId) {
    return { isWinner: winnerIds.includes(userId) };
  }
  return { isWinner: false };
};
