export default {
  '/settings': 'Настройки',
  '/marketplace': 'Магазин',
  '/balance': 'Баланс',
  '/auth': 'Авторизация',
  '/notifications': 'Уведомления',
  '/refill': 'Пополнение',
  '/refill/:type/giftcards': 'Пополнение',
  '/refill/:type/:name': 'Пополнение',
  '/withdrawal': 'Вывод',
  '/withdrawal/:method': 'Вывод',
  '/withdrawal/verify': 'Идентификация',
  '/leaders-race-next': 'Гонка лидеров',

  '/lottery': 'История розыгрышей',
  '/chat': 'Чат',

  //caseBattle
  '/case-battle': 'Case Battle',
  '/case-battle/:battleId': 'Case Battle',
  '/case-battle/create-battle': 'Создать игру',
  '/case-battle/history': 'История',
  //classic
  '/classic': 'Classic',
  '/classic/history': 'История Classic',
  '/classic/top': 'Топ Classic',
  '/classic/rules': 'Правила Classic',
  '/classic/lottery': '$t(/lottery)',

  //fast
  '/fast': 'Fast',
  '/fast/history': 'История Fast',
  '/fast/top': 'Топ Fast',
  '/fast/rules': 'Правила Fast',
  '/fast/lottery': '$t(/lottery)',

  //hilo
  '/hilo': 'Hi Lo',
  '/hilo/rules': 'Правила Hi Lo',
  '/hilo/lottery': '$t(/lottery)',

  //crash
  '/crash': 'Crash',
  '/crash/history': 'История Crash',
  '/crash/top': 'Топ Crash',
  '/crash/rules': 'Правила Crash',
  '/crash/lottery': '$t(/lottery)',

  //double
  '/double': 'Double',
  '/double/history': 'История Double',
  '/double/top': 'Топ Double',
  '/double/rules': 'Правила Double',
  '/double/lottery': '$t(/lottery)',

  //x50
  '/x50': 'X50',
  '/x50/rules': 'Правила X50',
  '/x50/lottery': '$t(/lottery)',

  //Baccarat
  '/baccarat': 'Baccarat',
  '/baccarat/history': 'История Baccarat',
  '/baccarat/rules': 'Правила Baccarat',
  '/baccarat/lottery': '$t(/lottery)',

  //Wheel
  '/wheel': 'Wheel',
  '/wheel/rules': 'Правила Wheel',
  '/wheel/lottery': '$t(/lottery)',

  //tower
  '/tower': 'Tower',
  '/tower/history': 'История Tower',
  '/tower/top': 'Топ Tower',
  '/tower/rules': 'Правила Tower',
  '/tower/lottery': '$t(/lottery)',

  //cases
  '/cases': 'Cases',
  '/cases/history': 'История Cases',
  '/cases/rules': 'Правила Cases',
  '/cases/lottery': '$t(/lottery)',

  //slot
  '/slot': 'Slot',
  '/slot/history': 'История Slot',
  '/slot/rules': 'Правила Slot',
  '/slot/lottery': '$t(/lottery)',

  //craft
  '/craft': 'Craft',
  '/craft/history': 'История Craft',
  '/craft/rules': 'Правила Craft',
  '/craft/lottery': '$t(/lottery)',

  //poggi
  '/poggi': 'Poggi',
  '/poggi/history': 'История Poggi',
  '/poggi/rules': 'Правила Poggi',
  '/poggi/lottery': '$t(/lottery)',

  // accounts
  '/accounts': 'Профиль игрока',
  '/accounts/statistics': 'Статистика',
  '/accounts/race-activity': 'Гонка Лидеров',

  //profile
  '/personal/referral/campaigns': 'Реферальная система',
  '/personal/referral': 'Реферальная система',
  '/personal/balance/cashback': 'Кэшбэк',
  '/personal/balance/bonus-code': 'Бонусы',
  '/personal/balance/transaction': 'История транзакций',
  '/personal/balance': 'Баланс',
  '/personal/notification': 'Уведомления',
  '/personal/levels': 'Уровни',
  '/personal/statistics': 'Статистика',
  '/personal/settings': 'Настройки',
  '/personal/settings/trading': 'Настройки маркета',
  '/personal/settings/sounds': 'Звуки',
  '/personal/settings/privacy': 'Приватность',
  '/personal/settings/social': 'Соц. сети',
  '/personal': 'Аккаунт',
  '/personal/self-exclusion': 'Самоблокировка',

  // inventory
  '/inventory': 'Инвентарь',
  '/inventory/fast': 'Fast инвентарь',
  '/inventory/exchange': 'Обменять',

  '/': 'Игры',

  '/privacy-policy': 'Политика конфиденциальности',
  '/tos': 'Условия и положения',

  RULES: 'Правила',
  Chat: 'Чат',
  GAME: 'ИГРА',
  Top: 'ТОП',
  HISTORY: 'ИСТОРИЯ',

  // game tabs
  'game-tab_rules': 'Как играть <br/>в $t({{routeName}})?',

  //Toolbar locales
  chat: 'Чат',
  sounds: 'Звуки',
  history: 'История',
  rules: 'Как играть?',
  stats: 'Статистика',
  top: 'Топ',
};
