import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';
import { useDialog } from 'core/hooks';
import { FairGameDialogs } from 'core/interfaces';
import { useFairGame } from '../../../hooks';

export const GameNavigation = () => {
  const { number, hash, salt, rand } = useFairGame();
  const { t } = useTranslation('double');
  const { handleToggleDialog } = useDialog(FairGameDialogs.FAIR_GAME_DIALOG);
  return (
    <Container>
      <NavigationLink
        onClick={handleToggleDialog(FairGameDialogs.FAIR_GAME_DIALOG, {
          number,
          hash,
          salt,
          rand,
          ssh: '256',
        })}
      >
        <div className="leading">
          <i className="icon-referrals" />
        </div>
        <div>
          <span>
            {t('Fair game')} #{number}
          </span>
        </div>
      </NavigationLink>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  background: var(--color-dark-600);
`;
