import * as React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { declOfNum } from 'core/utils/text.utils';

interface ITimer {
  finishEvent: string | number;
  format?: string;
}

const { useState, useEffect } = React;

export const Timer: React.FC<ITimer> = ({ finishEvent, format = 'HH:mm:ss' }) => {
  const [fullDate, setDate] = useState(() => {
    const duration = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
    const days = Math.floor(duration.asDays());

    return {
      days,
      milliseconds: '',
    };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (fullDate.days) return;
    const timerId = setInterval(() => {
      const duration = dayjs.duration(dayjs(finishEvent).diff(Date.now()));

      if (duration.asMilliseconds() < 0) {
        setDate({
          days: 0,
          milliseconds: '',
        });
        clearInterval(timerId);
      } else {
        const milliseconds = dayjs.utc(duration.asMilliseconds()).format(format);

        setDate((state: any) => ({ ...state, milliseconds }));
      }
    }, 1000);

    return () => clearInterval(timerId);

    // eslint-disable-next-line
  }, [finishEvent]);

  return (
    <>
      {fullDate.days > 0 ? (
        <>
          {fullDate.days} {t(declOfNum(fullDate.days, ['day', 'day_once', 'days']))}
        </>
      ) : (
        fullDate.milliseconds
      )}
    </>
  );
};
