import React from 'react';
import { AppBar } from '@components/bars';
import { Chat } from '@components/Chat';

export const ChatPage = () => (
  <>
    <AppBar deep />
    <Chat chatRoomName="lobby" />
  </>
);
