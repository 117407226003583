export default {
  //CryptoPage title
  crypto: 'Kryptoabhebung',
  cryptocurrency: 'Kryptowährung',
  //
  Withdraw: 'Abheben',
  'Amount in {{title}}:': 'Betrag in $t({{title}}):',
  'Receive in {{title}}:': 'Empfangen in $t({{title}}):',
  'You will receive:': 'Du wirst erhalten:',
  'Select the network fee': 'Wählen Sie die Netzgebühr',
  'Ultra Low': 'Sehr langsam',
  Low: 'Schleppend',
  Medium: 'Standard',
  High: 'Schnell',
  'Withdrawal sum': 'Auszahlungssumme',
  'Commission:': 'Provision:',
  'No commission': 'Keine Kommission',
  commission: 'Mögliche Gebühr',
  from: 'ab',
  'Email is Required': 'Eine E-Mail wird benötigt',
};
