import React, { FC } from 'react';
import { IDialogComponent } from 'core/interfaces';
import { Header } from './header';
import { Slider } from './slider';
import { Container } from './guide-dialog.styles';

export interface GuideDialogProps extends IDialogComponent<boolean> {}

export const GuideDialog: FC<GuideDialogProps> = ({ handleCloseModal }) => {
  return (
    <Container>
      <Header handleCloseModal={handleCloseModal} />
      <Slider />
    </Container>
  );
};
