import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';

export const LandingRefill: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <span>{t('Refill balance and get yellow ticket!')}</span>
      <Button className="primary" as={Link} to="/refill">
        {t('Refill balance')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 3.9375rem 1rem 3.8125rem;

  & span {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    letter-spacing: 0.54px;
    color: white;
    margin-bottom: 1.375rem;
  }
`;
