import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITeams } from 'games/CaseBattle/hooks';
import { TeamsContainer } from './TeamsFinalResults.styles';
import { BattlePlayerFinalStatus } from '../../BattlePlayerFinalStatus';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { BlurComponent, Container, TotalPriceContainer, TotalWinnings } from '../generalStyles';

interface TeamsFinalResultsProps {
  teams: ITeams[];
  isJoker: boolean;
  winnerIds: number[];
}

export const TeamsFinalResults: FC<TeamsFinalResultsProps> = ({ teams, isJoker, winnerIds }) => {
  const { t } = useTranslation();
  const maxTeamTotalPrice = Math.max(...teams.map(({ teamTotalPrice }) => teamTotalPrice));
  const minTeamTotalPrice = Math.min(...teams.map(({ teamTotalPrice }) => teamTotalPrice));
  const totalWin = teams.reduce((acc, curr) => acc + curr.teamTotalPrice, 0);

  return (
    <Container className="teams">
      {teams.map(({ teamTotalPrice, team_id }) => {
        const isWinner = (isJoker ? minTeamTotalPrice : maxTeamTotalPrice) === teamTotalPrice;
        const teamsCount = winnerIds.length / 2;

        return (
          <TeamsContainer key={team_id} isJoker={isJoker} isWinner={isWinner}>
            {isWinner && <CaseBattleIcon.WinnerBackground className={isJoker ? 'joker' : ''} />}
            {isWinner && <BlurComponent position="left" />}
            <BattlePlayerFinalStatus isWinner={isWinner} isJoker={isJoker} />
            <TotalWinnings>{isWinner && t('battleWinnings')}</TotalWinnings>
            {isWinner && <BlurComponent position="right" />}
            <TotalPriceContainer>
              {isWinner && `◎ ${(totalWin / teamsCount / 100).toFixed(2)}`}
            </TotalPriceContainer>
          </TeamsContainer>
        );
      })}
    </Container>
  );
};
