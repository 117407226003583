import styled from 'styled-components';

export const SearchInput = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0.875rem;
  background: var(--color-white);
  border: 0.125rem solid var(--color-white-600);
  border-radius: var(--size-radius-m);
  box-sizing: border-box;

  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--color-dark-600);
  text-overflow: ellipsis;

  &.error {
    border: 2px solid var(--color-red);
  }

  &.success {
    border: 2px solid var(--green-200);
  }

  &::placeholder {
    color: var(--color-gray-200);
  }

  &:disabled {
    color: var(--color-white-600);
    background: var(--color-gray-300);
    border-color: transparent;
  }
`;
