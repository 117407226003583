import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { useOrderControllers } from '../../../../hooks';
import { InventoryItem } from 'games/CaseGame/interfaces';
import { skinItemImage } from 'core/utils';

interface IShopOrderElement {
  item: InventoryItem;
}

export const ShopOrderElement: FC<IShopOrderElement> = ({ item }) => {
  const { toggleElement } = useOrderControllers(item.id);

  return (
    <Container onClick={toggleElement(item)}>
      <i className="icon-close" />
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item?.baseItem.icon, 140, 90)} 1x, ${skinItemImage(
            item?.baseItem.icon,
            280,
            170
          )} 2x`}
          src={`${skinItemImage(item?.baseItem.icon, 140, 90)}`}
          alt=""
        />
      </div>
      <div className="price">
        <CurrencyText value={item.price} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content;
  grid-gap: 0.375rem;
  width: 100%;
  height: 100%;
  padding: 0.375rem;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-s);
  position: relative;

  & .icon-close {
    font-size: 0.5625rem;
    color: var(--color-white);
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .price {
    text-align: center;
    font-size: 0.5625rem;
    line-height: 0.6875rem;
    color: var(--color-white);
  }
`;
