import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { useUserMailboxForm } from 'core/User';
import {
  Container,
  SubTitle,
  Title,
  CheckBoxTitle,
  CheckBoxWrap,
} from './profile-email-dialog.styles';
import { SearchInput } from '@UIkit';
import { MailboxValidators } from 'core/utils';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';

interface IProfileEmailDialog extends IDialogComponent<boolean> {}

export const ProfileEmailDialog: FC<IProfileEmailDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { handleCloseDialog } = useDialog(userModals.PROFILE_EMAIL);
  const {
    form: { register, errors, handleSubmit },
    onSubmit,
  } = useUserMailboxForm();

  const handleConfirm = () => {
    handleSubmit(onSubmit)();
    handleCloseDialog();
  };

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>{t('Add an email')}</Title>
      <SubTitle>
        {t('It is necessary to send you reports, activate self-isolation and much more')}
      </SubTitle>
      <SearchInput
        {...register('email')}
        name="email"
        placeholder="example@mail.com"
        ref={register({
          pattern: MailboxValidators.mailboxPattern,
          validate: {
            length: (value: string) => !!value.length,
          },
        })}
        type="email"
      />
      <CheckBoxWrap>
        <input
          {...register('isSubscribed')}
          id="isSubscribed"
          name="isSubscribed"
          ref={register()}
          type="checkbox"
        />
        <label htmlFor="isSubscribed">
          <CheckBoxTitle>{t('Subscribe to email notifications')}</CheckBoxTitle>
        </label>
      </CheckBoxWrap>
      <Button className="primary" onClick={handleConfirm} disabled={!!errors?.email?.type}>
        {t('Send confirmation')}
      </Button>
    </Container>
  );
};
