import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPauseSelling } from '../../ducks/sales.duck';

export const useSellingPause = () => {
  const dispatch = useDispatch();

  const handleTakePause = useCallback(
    (id: number) => () => {
      dispatch(fetchPauseSelling(id));
    },
    [dispatch]
  );

  return {
    handleTakePause,
  };
};
