import styled from 'styled-components';

export const Container = styled.div<{ isTaskReady: boolean; isProgressReady: boolean }>`
  background: ${({ isTaskReady }) => (isTaskReady ? '#181E21' : '#1D2529')};
  border-radius: var(--size-radius-m);
  border: ${({ isProgressReady }) => (isProgressReady ? '1px solid #ffd11a' : 'none')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  min-height: 162px;
  min-width: 176px;
  padding: 12px;
  position: relative;
  width: 100%;
`;

export const DescriptionContainer = styled.div<{ isTaskReady: boolean }>`
  filter: ${({ isTaskReady }) => (isTaskReady ? 'grayscale(1)' : 'none')};
  opacity: ${({ isTaskReady }) => (isTaskReady ? '0.7' : '1')};

  & svg {
    vertical-align: top;
  }
`;

export const Description = styled.span<{ isTaskReady: boolean }>`
  color: ${({ isTaskReady }) => (isTaskReady ? '#728188' : '#f7fbfc')};
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const ProgressBar = styled.div`
  background: #2f3c42;
  border-radius: 4px;
  display: flex;
  height: 16px;
  overflow: hidden;
  width: 100%;
`;

export const ProgressReady = styled.div<{ percent: number }>`
  background: #fa8200;
  height: 100%;
  transition: width 1s;
  width: ${({ percent }) => `${percent}%`};
`;

export const StateText = styled.span`
  color: #f7fbfc;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;

  &.accent {
    color: #ffd11a;
  }
`;

export const StateContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  &.ready {
    justify-content: space-between;
  }

  & svg {
    fill: none;
  }
`;
