import { combineReducers } from 'redux';
// import { cashback, updateCashback, receiveChashbackStatus } from './ducks/cashback.duck';
import { AsyncAction } from 'store/interface';
import { Reducer } from 'redux';
import { IDispatch, IEventsTypes } from 'core/rootInterfaces/root.interface';
import {
  experienceLogActions,
  experienceLogActionTypes,
  statisticsActions,
  statisticActionTypes,
  transactionActions,
  transactionActionsTypes,
  avatarUploadActionTypes,
  avatarUploadActions,
  fileAvatarActionTypes,
  fileAvatarAction,
  ExperienceHistoryLogActions,
} from './interfaces/personalArea.action.interfaces';
import {
  IExperienceLogList,
  ExperienceLogList,
  IStatistics,
  statisticsState,
  ITransactionOrderedMap,
  ITransactionRecord,
  ITransactionsItem,
  TransactionRecord,
  IUploadAvatarRecord,
  UploadAvatarRecord,
  FileAvatarRecord,
  IFileAvatarRecord,
  // IExperienceLog,
  ExperienceHistoryLogList,
} from './interfaces/personalArea.reducer.interfaces';
import { ISocketService } from '../../services/interfaces/services.interface';
import { requestOptions } from './transaction.utils';
import { referrals } from './ducks/referral.duck';
import { Epic, ofType } from 'redux-observable';
import { BonusCodeAction, bonusCodeTypes, IBonusCode } from './interfaces';
import { catchError, switchMap } from 'rxjs/operators';
import { UserReferral } from 'core/User/repositories';
import { of } from 'rxjs';
import { dialogOn } from 'core/ducks/dialog';
import { addNotification } from 'modules/Notifications/duck';
import { actionReferrerUserInfoResponse } from 'core/User/ducks/duck';
import { userModals } from 'core/User/modal.config';
import { filterStatistics } from './utils/filterStatistics';

// reducers

const avatarUpload: Reducer<IUploadAvatarRecord, avatarUploadActions> = (
  state = new UploadAvatarRecord(),
  action
) => {
  switch (action.type) {
    case avatarUploadActionTypes.PROFILE_UPLOAD_AVATAR:
      return new UploadAvatarRecord(action.payload);

    case avatarUploadActionTypes.PROFILE_CLEANUP_UPLOAD_AVATAR:
      return new UploadAvatarRecord();

    default:
      return state;
  }
};

const fileAvatar: Reducer<IFileAvatarRecord, fileAvatarAction> = (
  state = new FileAvatarRecord(),
  action
) => {
  switch (action.type) {
    case fileAvatarActionTypes.FILE_AVATAR:
      return state.setIn(['file'], action.payload);

    default:
      return state;
  }
};

const statistics: Reducer<IStatistics, statisticsActions> = (state = statisticsState, action) => {
  switch (action.type) {
    case statisticActionTypes.PROFILE_RECEIVE_STATISTICS:
      return action.payload.statistic;

    default:
      return state;
  }
};

const experienceLog: Reducer<IExperienceLogList, experienceLogActions> = (
  state = ExperienceLogList,
  action
) => {
  switch (action.type) {
    case experienceLogActionTypes.PROFILE_RECEIVE_EXP:
      return ExperienceLogList.unshift(...action.payload.log);

    default:
      return state;
  }
};

const experienceHistoryLog: Reducer<IExperienceLogList, ExperienceHistoryLogActions> = (
  state = ExperienceHistoryLogList,
  action
) => {
  switch (action.type) {
    case experienceLogActionTypes.PROFILE_RECEIVE_EXP_HISTORY:
      return ExperienceHistoryLogList.unshift(...action.payload);

    default:
      return state;
  }
};

const transaction: Reducer<ITransactionRecord, transactionActions> = (
  state = new TransactionRecord(),
  action
) => {
  switch (action.type) {
    case transactionActionsTypes.FETCH_TRANSACTIONS:
      return state.set('loading', true).update('hasMore', () => state.get('entities').size < 150);

    case transactionActionsTypes.UPDATE_TRANSACTIONS:
      const { data } = action.payload;
      return data.length
        ? state
            .set('loaded', true)
            .set('loading', false)
            .update('entities', (v: ITransactionOrderedMap) => {
              return data.reduce((acc: ITransactionOrderedMap, i: ITransactionsItem) => {
                return acc.set(i.id, i);
              }, v);
            })
        : state.set('hasMore', false).set('loaded', true).set('loading', false);

    case transactionActionsTypes.RESET_TRANSACTIONS:
      return new TransactionRecord();

    default:
      return state;
  }
};

/// bonus code actions
export const actionRequestBonusCode = (code: string) => ({
  type: bonusCodeTypes.ACTION_REQUEST,
  payload: code,
});
const actionResponseBonusCode = () => ({
  type: bonusCodeTypes.ACTION_RESPONSE,
});
const actionErrorBonusCode = () => ({
  type: bonusCodeTypes.ACTION_ERROR,
});

// bonus code epic

export const bonusCodeEpic: Epic = action$ =>
  action$.pipe(
    ofType(bonusCodeTypes.ACTION_REQUEST),
    switchMap(({ payload }) =>
      UserReferral.referralActivate(payload).pipe(
        switchMap(({ response }: { response: IBonusCode }) =>
          of(
            actionResponseBonusCode(),
            actionReferrerUserInfoResponse(response),
            dialogOn(userModals.REFERRAL_BONUS_MODAL)
          )
        ),
        catchError(({ response }) =>
          of(addNotification({ type: 'error', body: response.message }), actionErrorBonusCode())
        )
      )
    )
  );

const bonusCodeReducer: Reducer<boolean, BonusCodeAction> = (state = false, action) => {
  switch (action.type) {
    case bonusCodeTypes?.ACTION_RESPONSE: {
      return false;
    }
    case bonusCodeTypes?.ACTION_REQUEST: {
      return true;
    }
    case bonusCodeTypes?.ACTION_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

export const reducer = combineReducers({
  avatarUpload,
  fileAvatar,
  transaction,
  experienceLog,
  experienceHistoryLog,
  statistics,
  // cashback,
  referrals,
  bonusCodeReducer,
});

// const receiveUserExp = ({ log }: any) => ({
//   type: experienceLogActionTypes.PROFILE_RECEIVE_EXP,
//   payload: {
//     log,
//   },
// });

// const receiveUserExpHistory = (log: IExperienceLog[]) => ({
//   type: experienceLogActionTypes.PROFILE_RECEIVE_EXP_HISTORY,
//   payload: log,
// });

export interface IStatisticsUser {
  [key: string]: string | number | null | Array<{ [key: string]: string }>;
}

const receiveProfileStatistic = (statistic: IStatisticsUser) => ({
  type: statisticActionTypes.PROFILE_RECEIVE_STATISTICS,
  payload: {
    statistic: filterStatistics(statistic),
  },
});

//transactions
const changeTransactionStatus = () => ({
  type: transactionActionsTypes.FETCH_TRANSACTIONS,
});

export const updateTransactions = (data: []) => ({
  type: transactionActionsTypes.UPDATE_TRANSACTIONS,
  payload: { data },
});

export const resetTransactions = () => ({
  type: transactionActionsTypes.RESET_TRANSACTIONS,
});

export const fetchTransactions = (page: number, options: string) => (
  dispatch: IDispatch,
  _getState: void,
  { socket }: ISocketService
) => {
  if (page === 1) dispatch(resetTransactions());
  dispatch(changeTransactionStatus());
  if (options === 'all') {
    socket.emit('account:balance:fetch', { page: page });
  } else {
    socket.emit('account:balance:fetch-filter', {
      typeIds: requestOptions[options],
      page,
    });
  }
};

// update user avatar

export const getDataUploadRequest = (): AsyncAction => (dispatch, getState, { socket }) => {
  socket.emit('user:get-upload-credentials');
};

export const updateAvatarBackend = (imageToken: string): AsyncAction => (
  dispatch,
  getState,
  { socket }
) => {
  socket.emit('user:update-avatar', imageToken);
};

export const credentialsUploadAvatar = (data: any) => ({
  type: avatarUploadActionTypes.PROFILE_UPLOAD_AVATAR,
  payload: data,
});

export const fileAvatarUpload = (file: string) => {
  return {
    type: fileAvatarActionTypes.FILE_AVATAR,
    payload: file,
  };
};

export const startSocket = (): AsyncAction => (dispatch, getState, { socket }) => {
  socket.emit('account:balance:fetch', { page: 1 });
  socket.emit('account:stats:fetch');
  // socket.emit('account:cashback:fetch');
  // socket.emit('account:experience:fetchLog');
};

export const eventsTypes: IEventsTypes[] = [
  { event: 'account:profile:stats', action: receiveProfileStatistic },
  // { event: 'account:experience:state', action: receiveUserExp },
  // { event: 'account:experience:log', action: receiveUserExpHistory },
  { event: 'account:balance:state', action: updateTransactions },
  { event: 'user:upload-credentials', action: credentialsUploadAvatar },
  // { event: 'account:cashback:state', action: updateCashback },
  // { event: 'account:cashback:get-result', action: receiveChashbackStatus },
];
