import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { getUser } from '../../../core/User/selectors';
import {
  getMarketplaceFetchingStatus,
  getMarketplaceFilterValues,
  getMarketplaceGameTypes,
  getMarketplaceItems,
  getMarketplaceMode,
  getMarketplacePagination,
} from '../selectors';

import {
  addItemToCart,
  requestMarket,
  requestUserInventory,
  updateFilter,
  updatePaginationPage,
} from '../duck';
import { selectItem } from '../../../games/CraftGame/duck';

import { List } from '../components/Marketplace/List';
import { GameFilter } from '../components/GameFilter';
import { SortDelivery } from '../components/SortDelivery';

class Marketplace extends Component {
  render() {
    const {
      pagination,
      cartItems,
      selectItem,
      user,
      match,
      history,
      isFetching,
      filterValues,
      mode,
      items,
      addItemToCart,
      updateFilter,
      gameTypes,
      requestMarket,
      requestUserInventory,
      updatePaginationPage,
    } = this.props;

    return (
      <Fragment>
        <GameFilter
          mode={mode}
          gameTypes={gameTypes}
          filterValues={filterValues}
          updateFilter={updateFilter}
          requestAction={requestMarket}
          requestUserInventory={requestUserInventory}
        />
        {mode !== 'craft' && (
          <SortDelivery
            updateFilter={updateFilter}
            requestAction={requestMarket}
            checked={filterValues.skinpay}
          />
        )}

        <List
          mode={mode}
          items={items}
          isFetching={isFetching}
          cartItems={cartItems}
          pagination={pagination}
          gameTypes={gameTypes}
          filterGameId={filterValues.currentGameTypes}
          user={user}
          match={match}
          history={history}
          selectItem={selectItem}
          addItemToCart={addItemToCart}
          requestMarket={requestMarket}
          updatePaginationPage={updatePaginationPage}
        />
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    user: getUser(state),
    mode: getMarketplaceMode(state),
    gameTypes: getMarketplaceGameTypes(state),
    isFetching: getMarketplaceFetchingStatus(state),
    items: getMarketplaceItems(state),
    filterValues: getMarketplaceFilterValues(state),
    pagination: getMarketplacePagination(state),
  }),
  {
    selectItem,
    addItemToCart,
    updateFilter,
    requestMarket,
    requestUserInventory,
    updatePaginationPage,
  }
)(Marketplace);
