import React, { FC } from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import dayjs from 'dayjs';
import { Timer } from '@components/TimerEvent';
import { IGameRecord, IHistoryGame } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface IGame {
  history: IHistoryGame[];
  game: IGameRecord;
}

export const Game: FC<IGame> = React.memo(({ game, history }) => {
  const { t } = useTranslation();
  const carts = useTransition(
    history.length > 1
      ? [
          {
            card: {
              sign: '?',
              color: '',
            },
            id: game.get('id'),
            offset: 0,
            finishAt: dayjs.duration(dayjs(game.get('startTime')).diff(Date.now())).asSeconds(),
          },
          ...history,
        ]
      : [],
    (item: any) => item.id,
    {
      initial: ({ offset }) => ({
        transform: 'translate3d(0px,0,0)',
        zoom: 1,
        opacity: 3,
        zIndex: offset,
      }),
      from: ({ offset }) => ({
        transform: 'translate3d(0px,0,0)',
        opacity: 1,
        zoom: 1,
        zIndex: offset,
      }),
      enter: ({ offset }) => {
        const x = offset ? offset * 38 + 22 : 0;
        return {
          transform: `translate3d(-${x}px,0,0)`,
          opacity: 1,
          zoom: offset ? 1 : 1.6,
          zIndex: offset,
        };
      },
      update: ({ offset }) => {
        const x = offset ? offset * 38 + 22 : 0;
        return {
          transform: `translate3d(-${x}px,0,0)`,
          opacity: 1,
          zoom: offset ? 1 : 1.6,
          zIndex: offset,
        };
      },
      leave: ({ offset }) => {
        const x = offset ? (offset + 1) * 38 + 22 : 33;
        return {
          transform: `translate3d(-${x}px,0,0)`,
          opacity: 0,
          zoom: 1,
          zIndex: offset,
        };
      },
    }
  );

  return (
    <>
      <Container>
        <div className="until-time">{t('Reception of bets')}</div>
        <div className="feed-card">
          {carts.map(({ item, props, key }: any) =>
            'finishAt' in item ? (
              <Cart key={key} style={props} color={''}>
                <div className="empty-sign">
                  ?
                  <svg width="24px" height="24px">
                    <circle
                      stroke="#C4C4C4"
                      strokeWidth="1px"
                      fill="none"
                      cx="12px"
                      cy="12px"
                      r="11px"
                      strokeDasharray="69, 69"
                    />
                    <circle
                      style={{ animationDuration: `${item.finishAt + 1}s` }}
                      className="circle"
                      stroke="var(--color-gray-300)"
                      strokeWidth="1px"
                      fill="none"
                      cx="12px"
                      cy="12px"
                      r="11px"
                      strokeDasharray="69, 69"
                    />
                  </svg>
                </div>
                <span className="cart-timer">
                  {game.get('startTime') && (
                    <Timer finishEvent={game.get('startTime')} format={'mm:ss'} />
                  )}
                </span>
              </Cart>
            ) : (
              <Cart
                key={key}
                style={props}
                color={item.card.color}
                className={item.card.value === 0 ? 'cart-joker' : ''}
              >
                {item.card.value === 0 ? (
                  <span className="cart-sign">J</span>
                ) : (
                  <span className="cart-sign">{item.card.sign}</span>
                )}
                {!!item.card.value && (
                  <span className="cart-label">
                    {item.card.value > item.previousCard.value && 'HI'}
                    {item.card.value < item.previousCard.value && 'LO'}
                    {item.card.value === item.previousCard.value && '='}
                  </span>
                )}
              </Cart>
            )
          )}
        </div>
      </Container>
    </>
  );
});

interface ICartStyle {
  color: string;
}

const Cart = styled(animated.div)<ICartStyle>`
  --hilo-sign-red: #f91e32;
  --hilo-sign-black: #000000;
  --hilo-sign-joker-red: #ffffff;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 52px;
  padding: 0.25rem;

  will-change: transform, opacity, zoom;
  transform-origin: right bottom;
  position: absolute;
  bottom: 0;

  background: linear-gradient(149.68deg, var(--color-gray-80) 1.97%, var(--color-gray-50) 1.98%);
  border-radius: var(--size-radius-s);

  &.cart-joker {
    background: #0a92ff;
    justify-content: center;
    color: var(--color-white);
  }

  & .cart-sign,
  & .cart-label {
    font-weight: bold;
  }

  & .cart-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.4rem;
    color: ${p => `var(--hilo-sign-${p.color})`};
  }

  & .cart-label {
    font-size: 0.65rem;
    color: #000000;
  }

  & .cart-timer {
    font-size: 0.5rem;
    color: var(--color-gray-300);
    font-weight: 700;
    @supports (-webkit-touch-callout: none) {
      font-size: 1rem;
    }
  }

  & .empty-sign {
    flex-basis: 50%;
    position: relative;
    top: 0.2rem;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > .circle {
      animation: circle-progress 14s linear;
    }
  }

  @keyframes circle-progress {
    from {
      stroke-dashoffset: 69;
    }
    to {
      stroke-dashoffset: 1;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1.375rem 3.25rem;
  grid-template-columns: 3.25rem repeat(9, 2rem);
  grid-gap: 0.75rem 0.4rem;
  min-width: calc(100vw - 0.5rem);
  padding-right: 0.5rem;
  direction: rtl;
  overflow-x: hidden;

  & .until-time {
    grid-column-end: span 10;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(100vw - 1rem);
    height: 1.375rem;
    padding-left: 1rem;
    position: relative;
    font-weight: bold;
    font-size: 0.7rem;
    line-height: 1.4;
    color: var(--color-gray-200);
  }

  & .feed-card {
    position: relative;
    grid-column-end: span 10;
    height: 100%;
  }
`;
