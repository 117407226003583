export const userAdapter = (data: any) => {
  const {
    userid: id,
    name,
    avaLink: avatar,
    steamid_3,
    chatInfo,
    coins,
    country,
    token,
    vgoLink: vgo,
    walletType,
    coins_demo,
    verifyRequirement,
    verifyRequirementResult,
  } = data;
  const steam =
    token && steamid_3
      ? `https://steamcommunity.com/tradeoffer/new/?partner=${data.steamid_3}&token=${data.token}`
      : undefined;

  return {
    id,
    name,
    avatar,
    steamid_3,
    chatInfo,
    coins,
    country,
    walletType,
    coins_demo,
    links: {
      steam,
      vgo,
    },
    verification: {
      requirement: verifyRequirement,
      message: verifyRequirementResult?.comment,
    },
  };
};

export const userDetailsAdapter = (data: any) => {
  const {
    premiumType: profileType,
    goldUrl: goldLink,
    premiumUntil: until,
    socials,
    user,
    lastWins,
    statsIsHidden,
  } = data;

  const providers = socials
    ? socials.reduce((acc: any, i: any) => ({ ...acc, [i.name]: i }), {})
    : undefined;

  return {
    chevron: user ? user.chevron : undefined,
    chevronImage: user ? user.chevronImage : undefined,
    date_registred: user ? user.date_registred : undefined,
    profileType,
    premium: {
      until,
      goldLink,
    },
    providers,
    lastWins,
    statsIsHidden,
  };
};

/*
 * experience
 * */
export const experienceAdapter = (experience: any) => {
  const {
    userLevel: level,
    userXp: xp,
    nextLvlXp: nextLevelXp,
    userRewards: {
      free_blue_case: blueCases,
      free_orange_case: orangeCases,
      free_silver_case: silverCases,
      free_spin: freeSpins,
    },
  } = experience;

  return {
    experience: {
      level,
      nextLevelXp,
      xp,
    },
    rewards: {
      blueCases,
      orangeCases,
      silverCases,
      freeSpins,
    },
  };
};
