import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { ACTION_TYPE } from '../actionType';
import {
  MotivatorActions,
  Motivator,
  MotivatorState,
  ResponseData,
  ActionRequestMotivator,
} from './motivator.interfaces';
import { catchError, map, switchMap } from 'rxjs/operators';
import casesRepository from 'games/CaseGame/repository/casesRepository';
import { of } from 'rxjs';

export const actionMotivator = (motivatorData: Motivator) => {
  return {
    type: ACTION_TYPE.GET_MOTIVATOR_DATA,
    payload: motivatorData,
  };
};

export const actionRequestMotivator = () => ({
  type: ACTION_TYPE.ACTION_REQUEST_MOTIVATOR,
});

const actionResponseMotivator = (data: ResponseData) => ({
  type: ACTION_TYPE.ACTION_RESPONSE_MOTIVATOR,
  payload: data,
});

export const MotivatorEpic: Epic = ($action: ActionsObservable<ActionRequestMotivator>) =>
  $action.pipe(
    ofType(ACTION_TYPE.ACTION_REQUEST_MOTIVATOR),
    switchMap(() =>
      casesRepository.fetchMotivatorData().pipe(
        map(({ response }: { response: ResponseData }) => actionResponseMotivator(response)),
        catchError(() => of())
      )
    )
  );

const initState: MotivatorState = {
  allDrop: [],
  bestDrop: [],
};

export const motivatorDataReducer = (
  state: MotivatorState = initState,
  action: MotivatorActions
) => {
  switch (action.type) {
    case ACTION_TYPE.GET_MOTIVATOR_DATA: {
      if (action.payload.agentKey === 'cases') {
        const isDropBest = action.payload.data.profit > 2500;
        const allDrop = [action.payload.data].concat(state.allDrop.slice(0, 10));
        return {
          allDrop,
          bestDrop: isDropBest
            ? [action.payload.data].concat(state.bestDrop.slice(0, 10))
            : state.bestDrop,
        };
      }
      return state;
    }
    case ACTION_TYPE.ACTION_RESPONSE_MOTIVATOR: {
      return {
        allDrop: action.payload.last.map(value => value.data),
        bestDrop: action.payload.bestLast.map(value => value.data),
      };
    }
    default:
      return state;
  }
};
