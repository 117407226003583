import styled from 'styled-components';

interface CardContainerProps {
  isMyGame: boolean;
  offset?: number;
  isJoker: boolean;
}

export const CardContainer = styled.div.attrs<CardContainerProps>(
  ({ offset, isMyGame, isJoker }) => ({
    style: {
      transform: `translateY(${offset * -100}%)`,
      transition: `transform ${
        Math.abs(offset) * 300 || 500
      }ms cubic-bezier(0.47, 0.42, 0.49, 0.99)`,
      borderLeft: isMyGame
        ? `2px solid ${isJoker ? 'var(--pink-100) ' : 'var(--gray-100)'}`
        : 'initial',
    },
  })
)<CardContainerProps>`
  width: 100%;
  min-height: 120px;
  max-height: 120px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px 10px;
  background: var(--color-dark-600);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
`;

export const CaseListContainer = styled.div`
  display: flex;
  width: 65%;
`;

export const CasesContainer = styled.div`
  gap: 4px;
  max-height: 48px;
  max-width: 210px;
  overflow-x: scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  display: -webkit-box;
`;

export const GameInfo = styled.div`
  display: flex;
  width: 35%;
  justify-content: flex-end;
`;

export const ShadowLine = styled.div`
  position: sticky;
  width: 10px;
  height: 100%;
  right: -10px;
  background: var(--color-dark-600);
  box-shadow: -5px 3px 20px 7px var(--color-dark-600);
`;
