import React, { FC } from 'react';
import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';
import { CurrencyText } from '@components/currency';
import { IFastInventoryElement, useFastInventoryElement } from 'core/User';
import { getItemUri } from 'core/utils/image.utils';
import { useClearanceControllers } from '../../../hooks';
import { ModeType } from '../../../interfaces';

interface IFastInventoryElementProps {
  identifier: number;
  setMode: (v: ModeType) => () => void;
}

interface IFastInventoryElementStyle {
  rarity?: string;
  selected?: boolean;
}

export const FastInventoryElement: FC<IFastInventoryElementProps> = ({ identifier, setMode }) => {
  const { item } = useFastInventoryElement(identifier);
  const { has, toggleElement } = useClearanceControllers(item?.id);

  const onClick = (currentItem: IFastInventoryElement) => {
    setMode(ModeType.SELLOUT)();
    toggleElement(currentItem)();
  };
  if (!item) return <Container className="skeleton" />;

  return (
    <Container
      rarity={item.inventoryItem?.baseItem.color}
      selected={has}
      onClick={() => onClick(item)}
    >
      <div className="inventory-inner">
        <div className="indicators">
          <span className="selected">
            <i className="icon-checked-fill" />
          </span>
        </div>
        <div />
        <div className="thumbnail">
          <img
            srcSet={`${getItemUri(item.inventoryItem?.baseItem.icon, 140, 90)} 1x, ${getItemUri(
              item.inventoryItem?.baseItem.icon,
              280,
              170
            )} 2x`}
            src={getItemUri(item.inventoryItem?.baseItem.icon, 140, 90)}
            alt=""
          />
        </div>
        <span className="display-name">{item.inventoryItem?.baseItem.name}</span>
        <span className="display-price">
          <CurrencyText value={item.inventoryItem.price} />
        </span>
      </div>
    </Container>
  );
};

const Container = styled(SkeletonElement)<IFastInventoryElementStyle>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: var(--size-radius-m);
  background: ${({ selected }) => (selected ? 'var(--color-white-gray)' : 'var(--color-dark-200)')};
  box-shadow: inset 0 0.125rem 0 ${({ rarity }) => rarity || 'transparent'};
  transition: background 100ms linear;

  & .inventory-inner {
    display: grid;
    grid-template-rows: 1rem 1fr min-content min-content;
    grid-gap: 0.125rem;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0.75rem;

    & .thumbnail {
      overflow: hidden;

      & img {
        width: 100%;
        height: 80%;
        object-fit: contain;
      }
    }

    & .display-name,
    & .display-price {
      line-height: 1rem;
      color: var(--color-white);
      text-align: center;
    }

    & .display-name {
      font-size: 0.8125rem;
      letter-spacing: 0.636923px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .display-price {
      font-size: 0.75rem;
      letter-spacing: 0.1px;
    }

    & .indicators {
      grid-template-columns: 1rem;
      grid-auto-rows: 1rem;
      grid-gap: 0.5rem;
      grid-auto-flow: row;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      z-index: 2;

      & .selected {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        background: var(--primary);
        transform: ${({ selected }) => (selected ? 'scale(1)' : 'scale(0)')};
        transition: transform 150ms ease-in-out;
        border-radius: 50%;

        & i {
          font-size: 0.5rem;
          color: var(--color-dark-100);
        }
      }
    }
  }
`;
