import { Emojis } from '../interfaces';

export interface EmojiForMap {
  id: string;
  shift: number;
  ts: number;
  type: number;
  userId: number;
  isDateNow?: boolean;
}

export const getArrayOfEmojis = (emojis: Emojis = {}): EmojiForMap[] =>
  Object.values(emojis)
    .flatMap(emoji =>
      emoji?.ts.map((ts: number, index: number) => ({
        id: `${ts}-${emoji.id}-${emoji.shift[index]}`,
        type: emoji.id,
        ts,
        shift: emoji.shift[index],
        userId: emoji.userIds?.[index],
        isDateNow: !!emoji.isDateNow,
      }))
    )
    .sort((a, b) => a.ts - b.ts);
