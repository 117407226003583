import * as React from 'react';

const TriangleSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#DFD042"
      strokeWidth="2"
      d="M8.5 1.927L1.777 13h13.446L8.5 1.927z"
    />
  </svg>
);

export default TriangleSvg;
