import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { TradeList } from './Multiple/TradeList';
import { Controls } from './Multiple/Controls';

import starImage from '../assets/star.png';

export class Multiple extends PureComponent {
  static defaultProps = {
    items: {},
  };

  state = {
    canTake: [],
    onlySellItems: [],
    cartItems: [],
  };

  componentDidMount() {
    document.body.classList.add('overflowHidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflowHidden');
  }

  static getDerivedStateFromProps(prop) {
    const { items } = prop;
    return {
      canTake: Object.keys(items).filter(id => !items[id].isVirtual),
      onlySellItems: Object.keys(items).filter(id => items[id].isVirtual),
    };
  }

  render() {
    const { items } = this.props;
    const { onlySellItems, canTake, cartItems } = this.state;

    return (
      <Overlay>
        <Controls
          items={items}
          onlySellItems={onlySellItems}
          cartItems={cartItems}
          canTake={canTake}
          handleTakeItems={this.handleTakeItems}
        >
          <OverlayImage />
          <div>
            {/*<TitleContainer>*/}
            {/*  <TradeTitle>{t('Withdraw')}</TradeTitle>*/}

            {/*  <ChangeTrades onClick={this.handleSelectToCart}>*/}
            {/*    {cartItems.length === canTake.length ? t('Reset') : t('Select all')}*/}
            {/*  </ChangeTrades>*/}
            {/*</TitleContainer>*/}
            <TradeList
              items={items}
              showItems={canTake}
              cartItems={cartItems}
              addToCart={this.addToCart}
              removeToCart={this.removeToCart}
            />
            <Hr />
          </div>
          {/*{!!onlySellItems.length && (*/}
          {/*  <Fragment>*/}
          {/*    <TitleContainer>*/}
          {/*      <TradeTitle>{t('To balance')} </TradeTitle>*/}
          {/*    </TitleContainer>*/}
          {/*    <TradeList items={items} showItems={onlySellItems} onlySell />*/}
          {/*  </Fragment>*/}
          {/*)}*/}
        </Controls>
      </Overlay>
    );
  }

  addToCart = id => {
    this.setState(state => ({ cartItems: [...state.cartItems, id] }));
  };

  removeToCart = id => {
    this.setState({
      cartItems: this.state.cartItems.filter(cartId => cartId !== id),
    });
  };

  handleSelectToCart = () => {
    const { cartItems, canTake } = this.state;
    if (cartItems.length === canTake.length) {
      return this.setState({ cartItems: [] });
    }

    return this.setState({ cartItems: [...canTake] });
  };

  handleTakeItems = () => {
    const { selectItemsToBuy, gameTypeId, items } = this.props;
    const { cartItems } = this.state;
    const requestModel = {
      gameTypeId,
      itemsList: Object.keys(items),
      itemsToTake: cartItems,
      itemsToSell: Object.keys(items).filter(item => !cartItems.includes(item)),
    };
    selectItemsToBuy(requestModel);
  };
}

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 38, 42, 0.99);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9995;
  overflow: hidden;
`;

const OverlayImage = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${starImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  position: absolute;
  top: -50px;
  z-index: -2;
  opacity: 0.7;
`;

// const TradeTitle = styled.span`
//   font-size: 14px;
//   line-height: 1.29;
//   letter-spacing: normal;
//   color: var(--color-white);
// `

// const ChangeTrades = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//
//   border: 0;
//   background-color: transparent;
//
//   font-size: 14px;
//   font-weight: bold;
//   color: var(--yellow);
// `

// const TitleContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 18px 18px 9px;
// `

const Hr = styled.hr`
  margin: 0 18px;
  border-top-width: 1px;
  border-top-color: var(--color-gray-950);
`;
