import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';

export const Container = styled(SkeletonElement)<{
  cardHeight: number;
  cardWidth: number;
  hasRounds: boolean;
}>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: ${({ cardHeight, cardWidth, hasRounds }) =>
    hasRounds ? `${cardHeight}px` : `${cardWidth * 1.5}px`};
  justify-content: center;
  padding: 8px;

  &.skeleton {
    background-image: linear-gradient(90deg, transparent, var(--color-dark-600), transparent);
  }

  & > svg {
    height: 100%;
    width: 100%;
  }
`;
