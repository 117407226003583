import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Description } from './case-list-event-banner.styles';
import { Modules } from 'core/interfaces';
import { useDrawerNavigation } from 'core/Drawer/hooks';

export const CaseListEventBanner = () => {
  const { t } = useTranslation();
  const { handlePushTo } = useDrawerNavigation();

  return (
    <Container>
      <Description onClick={handlePushTo(Modules.EVENT)}>
        <span>{t('bannerInfo')}</span>
      </Description>
    </Container>
  );
};
