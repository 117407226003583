import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { BlockedTitle, Find, ProgressInfo } from './super-prize.styles';
import { useGetGeneralQuestInfo, useResizeWindow } from '../../../hooks';
import { CasesQuestStatusEnum } from '../../../interfaces';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { ProgressBar } from '../progress-bar';

export const SuperPrizeBlocked = ({ isFinal }: { isFinal: boolean }) => {
  const { t } = useTranslation();
  const { categoriesData } = useGetGeneralQuestInfo();
  const { containerRef, containerWidth } = useResizeWindow();
  const available = categoriesData.filter(
    ({ status }) => status === CasesQuestStatusEnum.TAKEN || status === CasesQuestStatusEnum.OPENED
  ).length;

  const progress = (available * 100) / categoriesData?.length;

  return (
    <>
      <img
        src={eventImagesPath.superPricePath}
        width={80}
        height={80}
        className={isFinal ? 'colored' : ''}
        alt=""
      />
      <ProgressInfo ref={containerRef} className={isFinal ? 'colored' : ''}>
        <BlockedTitle>
          <Trans i18nKey="superPrizeBonusBlocked" t={t}>
            Collect all the items and get the <span>Superprize</span>
          </Trans>
        </BlockedTitle>
        <ProgressBar
          progressWidth={(containerWidth * progress) / 100}
          progress={progress}
          loader={progress}
          containerWidth={containerWidth}
        />
        <Find>
          {t('findItems')}: {available}/{categoriesData?.length}
        </Find>
      </ProgressInfo>
    </>
  );
};
