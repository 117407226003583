import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { Container } from './fair-game-dialog.styles';

interface IDataDialog {
  number: number;
  hash?: string;
  salt?: string;
  rand?: string;
  ssh: string;
}

interface IFairGameDialog extends IDialogComponent<IDataDialog> {}

export const FairGameDialog: FC<IFairGameDialog> = ({
  handleCloseModal,
  data: { number, hash, salt, rand, ssh },
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <div className="round-number">
        {t('Game')} #{number}
      </div>
      <div className="hash-list">
        <CopyToClipboard text={hash}>
          <Button className="secondary">
            <div className="multi-line">
              <span>{t('Round hash')}</span>
              <span>{hash}</span>
            </div>
          </Button>
        </CopyToClipboard>
        <CopyToClipboard text={salt || ''}>
          <Button className="secondary">
            <div className="multi-line">
              <span>{t('Hash salt')}</span>
              <span>{salt || t('hidden')}</span>
            </div>
          </Button>
        </CopyToClipboard>
        <CopyToClipboard text={rand || ''}>
          <Button className="secondary">
            <div className="multi-line">
              <span>{t('Round number')}</span>
              <span>{rand || t('hidden')}</span>
            </div>
          </Button>
        </CopyToClipboard>
      </div>
      <Button
        className={`primary ${!salt ? 'disabled' : ''}`}
        as="a"
        // href={`//sha224.net/${ssh && ssh + '/'}?val=${rand || ''}${salt || ''}`}
        href={'//emn178.github.io/online-tools/sha256.html'}
        target="_blank"
      >
        {t('Verify ticket')}
      </Button>
    </Container>
  );
};
