import * as Immutable from 'immutable';
import { InventoryItem } from 'games/CaseGame/interfaces';

export enum MergeType {
  APPEND,
  RESET,
}

export const ShopRecord = Immutable.Record({
  loading: false,
  loaded: false,
  failure: false,
  meta: {
    amount: 0,
    limit: 0,
    offset: 0,
  },
  items: [],
});

export interface IShopRecord {
  loading: boolean;
  loaded: boolean;
  failure: boolean;
  meta: IShopMeta;
  items: InventoryItem[];
}

export interface IShopMeta {
  amount: number;
  limit: number;
  offset: number;
}

export interface IShopResponse {
  appId: number;
  items: InventoryItem[];
  meta: IShopMeta;
  success: true;
}
