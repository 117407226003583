import { SteamBindingDialog } from './steam-binding-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.STEAM_BINDING, {
  component: SteamBindingDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
