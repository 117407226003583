import React from 'react';
import { AppBar } from '@components/bars';
import { LobbyPreview } from './lobby-preview';
import { LobbyRules } from './lobby-rules';
import { LandingSteps } from './landing-steps';
import { LandingTimeOfEvent } from './landing-time-of-event';
import { LobbyPedestal } from '../@shared';

export const LandingNext = () => {
  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <LobbyPreview />
        <LobbyRules />
        <LandingSteps />
        <LobbyPedestal />
        <LandingTimeOfEvent />
      </div>
    </>
  );
};
