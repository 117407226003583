import { Reducer } from 'redux';
import Immutable from 'immutable';
import { Epic, ofType } from 'redux-observable';
import {
  concatMap,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { from, fromEvent, merge } from 'rxjs';
import {
  ILotteryEntity,
  ILotteryRecord,
  lotteryActions,
  LotteryActionTypes,
  LotteryRecord,
  LotteryStatuses,
} from 'core/interfaces';
import { AppShellActionsTypes } from '../../AppShell/interfaces/appshell.actions.interface';

export const lottery: Reducer<Immutable.Record<ILotteryRecord>, lotteryActions> = (
  state = new LotteryRecord(),
  action
) => {
  switch (action.type) {
    case LotteryActionTypes.UPDATE_LOTTERY_ELEMENTS: {
      const { items } = action.payload;
      return items
        .sort((l: any, r: any) => (l.gameEndTimestamp > r.gameEndTimestamp ? 1 : -1))
        .reduce((acc, item) => {
          if (!acc.has(item.type)) return acc;
          return acc
            .update(item.type, (o: any) => [item.id, ...o])
            .setIn(['entities', item.id], item);
        }, new LotteryRecord({ status: LotteryStatuses.LOADED }));
    }

    case LotteryActionTypes.UPDATE_LOTTERY_ELEMENT: {
      const { id, item } = action.payload;
      return state.mergeIn(['entities', id], item);
    }

    default: {
      return state;
    }
  }
};

export const attachLottery = () => ({
  type: LotteryActionTypes.LOTTERY_ATTACH_LISTENERS,
});

export const detachLottery = () => ({
  type: LotteryActionTypes.LOTTERY_DETACH_LISTENERS,
});

const updateElements = (items: ILotteryEntity[]) => ({
  type: LotteryActionTypes.UPDATE_LOTTERY_ELEMENTS,
  payload: {
    items,
  },
});

const updateElement = (id: number, item: Partial<ILotteryEntity>) => ({
  type: LotteryActionTypes.UPDATE_LOTTERY_ELEMENT,
  payload: {
    id,
    item,
  },
});

export const fetchParticipateLottery = () => ({
  type: LotteryActionTypes.PARTICIPATE_LOTTERY,
});

export const listenersEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType<any>(AppShellActionsTypes.INITIALIZED, LotteryActionTypes.LOTTERY_ATTACH_LISTENERS),
    switchMap(() =>
      fromEvent<ILotteryEntity[]>(socket.io, 'refill-game:games:state').pipe(
        concatMap(items => [
          { type: LotteryActionTypes.RESUBSCRIBE_LISTENERS },
          updateElements(items),
        ]),
        takeUntil(action$.ofType(LotteryActionTypes.LOTTERY_DETACH_LISTENERS))
      )
    )
  );

export const watcherEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(LotteryActionTypes.UPDATE_LOTTERY_ELEMENTS),
    pluck('payload', 'items'),
    map(items =>
      items.reduce(
        (acc: number[], item: ILotteryEntity) => (item.rand ? acc : [...acc, item.id]),
        []
      )
    ),
    switchMap((ids: number[]) =>
      from(ids).pipe(
        tap(gameNumber => socket.io.emit('refill-game:user:fetch', { gameNumber })),
        mergeMap(id =>
          merge(
            fromEvent<ILotteryEntity>(socket.io, `refill-game:games:${id}:update`).pipe(
              map(item => updateElement(id, item))
            ),
            fromEvent<ILotteryEntity & any>(socket.io, `refill-game:games:${id}:user:state`).pipe(
              map(({ deposit, userChance, userTickets }) => deposit ?? { userChance, userTickets }),
              map(deposit => updateElement(id, { deposit }))
            )
          )
        ),
        takeUntil(
          action$.ofType(
            LotteryActionTypes.RESUBSCRIBE_LISTENERS,
            LotteryActionTypes.LOTTERY_DETACH_LISTENERS
          )
        )
      )
    )
  );

export const loaderEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType<any>(AppShellActionsTypes.INITIALIZED, LotteryActionTypes.LOTTERY_ATTACH_LISTENERS),
    tap(() => socket.io.emit('refill-game:games:fetch')),
    ignoreElements()
  );

export const participateEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(LotteryActionTypes.PARTICIPATE_LOTTERY),
    tap(() => socket.io.emit('refill-game:user:play')),
    ignoreElements()
  );
