import * as React from 'react';
import styled from 'styled-components';
import { stickers } from '../stickers.utils';
import { animated, useSpring } from 'react-spring';

const { useState } = React;

interface IStickerTabBar {
  isOpen: boolean;
  sendSticker: (stickerPack: string, stickerId: number) => void;
}

export const StickerTabBar: React.FC<IStickerTabBar> = props => {
  const [stickerName, setStickerName] = useState<string>('ragefaces');

  const springProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    maxHeight: props.isOpen ? 275 : 0,
  });

  return (
    <Container style={springProps}>
      <StickerTabs>
        {Object.keys(stickers).map(key => (
          <StickerTabItem
            key={key}
            active={key === stickerName}
            onClick={() => setStickerName(key)}
          >
            <img src={stickers[key].logo} alt="sticker-logo" />
          </StickerTabItem>
        ))}
      </StickerTabs>
      <StickerContent>
        {Array.apply(0, Array(stickers[stickerName].size)).map((item, key) => (
          <StickerIcon
            key={`${stickerName}-${key}`}
            onClick={() => props.sendSticker(stickerName, key)}
            data-automation-id={`chat_sticker-bar_pack-${stickerName}-${key}`}
          >
            <img src={`${stickers[stickerName].path}/${key}.png`} alt={stickerName} />
          </StickerIcon>
        ))}
      </StickerContent>
    </Container>
  );
};

interface IStickerTabs {
  active?: boolean;
}

const Container = styled(animated.div)`
  display: flex;
  flex-flow: column;
  background: var(--dark);
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
`;

const StickerTabs = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: flex-start;
  border-bottom: 1px solid rgb(41, 53, 55);
  border-top: 1px solid rgb(41, 53, 55);
  margin: 0 10px;
  flex-shrink: 0;
`;

const StickerTabItem = styled.li<IStickerTabs>`
  padding: 4px 8px 0px;
  ${p => p.active && 'border-bottom: 4px solid var(--primary);'};
  & > img {
    width: 34px;
    height: 34px;
    object-fit: contain;
  }
`;

const StickerContent = styled.div`
  height: auto;
  overflow: auto;
`;

const StickerIcon = styled.button`
  width: 25%;
  padding: 5px;
  border: 0;
  background: transparent;

  & > img {
    width: 100%;
    height: calc(100vw / 4);
    object-fit: contain;
  }
`;
