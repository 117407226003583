import { useSelector } from 'react-redux';
import { getCaseBattleGameReplayData } from '../ducks';

export const useGetCaseBattleGameReplayData = () => {
  const data = useSelector(getCaseBattleGameReplayData);

  return {
    isLoading: data.get('isLoading'),
    isReplay: data.get('isReplay'),
    data: data.get('data'),
  };
};
