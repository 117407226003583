import React, { FC } from 'react';
import styled from 'styled-components';
import { getItemType } from 'modules/PersonalArea/personalArea.utils';
import { CurrencyText } from '@components/currency';

export const UserGameActivities: FC<any> = ({ activity }) => {
  return (
    <Container>
      {activity.map((item: any) => {
        const parseData = getItemType(item);
        return (
          <div key={item.id} className="activities-item">
            <div className="activities-item__icon">
              <i className={`${parseData.icon}`} />
            </div>
            <div className="activities-item__game">{parseData.name}</div>
            <div className="activities-item__currency">
              <CurrencyText value={item.amount} />
            </div>
          </div>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 180px;
  grid-gap: 0.5rem;
  padding: 1rem;
  flex-shrink: 0;
  overflow-x: scroll;
  overflow-y: hidden;

  & .activities-item {
    display: grid;
    grid-template-columns: 1.5rem 1fr 1fr;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    height: 52px;
    background: var(--color-gray-700);

    letter-spacing: 0.61px;
    color: var(--color-white);

    &:last-of-type {
      margin-right: 0;
    }

    &__icon {
      justify-self: center;

      & > i {
        font-size: 1.5rem;
      }
    }

    &__game {
      font-size: 0.9rem;
      font-weight: bold;
    }

    &__currency {
      font-size: 0.75rem;
      justify-self: flex-end;
      font-weight: bold;
    }

    & > * {
      align-self: center;
    }
  }
`;
