export default {
  //CryptoPage title
  crypto: 'Retragere criptovalută',
  cryptocurrency: 'Criptomonedă',
  from: 'de la',
  //
  'Email is required': 'Este nevoie de adresă de email',
  Withdraw: 'Retrage',
  'Withdrawal sum': 'Sumă de retragere',
  'Amount in {{title}}:': 'Suma în $t({{title}}):',
  'Receive in {{title}}:': 'Primiți în $t({{title}}):',
  'You will receive:': 'Vei primi:',
  'Select the network fee': 'Selectați taxa de rețea',
  'Commission:': 'Comision:',
  'Ultra Low': 'Foarte incet',
  Low: 'încet',
  Medium: 'Standart',
  High: 'Rapid',
  'No commission': 'Fără comision',
  commission: 'Posibilă comision',
};
