import { combineReducers } from 'redux';
// import { LOGOUT } from '../../core/User/duck'
import { getRandomInt } from '../../core/utils/common-game.utils';
import { getCrossGameId } from '../../core/CrossServices/selectors';

const prefix = 'app/craft-game/';

const SELECT_ITEM = `${prefix}SELECT_ITEM`;
const UPDATE_ITEM_INFO = `${prefix}UPDATE_ITEM_INFO`;
const UPDATE_RESULT = `${prefix}UPDATE_RESULT`;

const START_GAME = `${prefix}START_GAME`;
const RESET_GAME = `${prefix}RESET_GAME`;

const OPEN_WINNER_MODAL = `${prefix}OPEN_WINNER_MODAL`;
const CLOSE_WINNER_MODAL = `${prefix}CLOSE_WINNER_MODAL`;

const UPDATE_HISTORY = `${prefix}UPDATE_HISTORY`;

// actions

const selectedItem = (state = null, action) => {
  switch (action.type) {
    case SELECT_ITEM: {
      return action.payload;
    }
    case CLOSE_WINNER_MODAL: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const selectedItemInfo = (state = null, action) => {
  switch (action.type) {
    case UPDATE_ITEM_INFO: {
      return action.payload;
    }
    case CLOSE_WINNER_MODAL: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const selectedItemResult = (state = null, action) => {
  switch (action.type) {
    case UPDATE_RESULT: {
      return action.payload;
    }
    case RESET_GAME: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const runningStatus = (state = false, action) => {
  switch (action.type) {
    case UPDATE_RESULT: {
      return true;
    }
    case RESET_GAME: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const winnerModalStatus = (state = false, action) => {
  switch (action.type) {
    case OPEN_WINNER_MODAL: {
      return true;
    }
    case CLOSE_WINNER_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const historyItemsByID = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_HISTORY: {
      return action.payload.reduce(
        (dictionary, item, index) => ({
          ...dictionary,
          [`${item.gameId}-${index}`]: item,
        }),
        state
      );
    }
    // case LOGOUT: {
    //   return {}
    // }
    default: {
      return state;
    }
  }
};

const historyItemsIDs = (state = [], action) => {
  switch (action.type) {
    case UPDATE_HISTORY: {
      return action.payload.map((g, index) => `${g.gameId}-${index}`);
    }
    // case LOGOUT: {
    //   return []
    // }
    default: {
      return state;
    }
  }
};

// reducers

const reducer = combineReducers({
  selectedItem: selectedItem,
  selectedItemInfo: selectedItemInfo,
  selectedItemResult: selectedItemResult,
  runningStatus: runningStatus,
  windows: combineReducers({
    winnerModal: winnerModalStatus,
  }),
  history: combineReducers({
    byID: historyItemsByID,
    IDs: historyItemsIDs,
  }),
});

export default reducer;

// action-creators
export const selectItem = item => (dispatch, getState, { socket }) => {
  const id = getRandomInt(0, 10000);
  const appId = getCrossGameId(getState());
  socket.emit('craft:info', {
    appId: appId,
    itemName: item.itemName,
    requestId: id,
  });

  socket.on(`craft:response:${id}`, item => {
    dispatch(updateItemInfo(item));
    socket.removeListener(`craft:response:${id}`);
  });

  dispatch({ type: SELECT_ITEM, payload: item });
};

export const startGame = (item, value) => (dispatch, getState, { socket }) => {
  dispatch({ type: START_GAME });
  const price = Math.floor(item.itemPrice * value);
  const appId = getCrossGameId(getState());
  socket.emit('craft:play', { appId, itemName: item.itemName, price });
};

export const resetGame = item => ({
  type: RESET_GAME,
});

export const updateItemInfo = info => ({
  type: UPDATE_ITEM_INFO,
  payload: info,
});

export const updateResultAction = result => ({
  type: UPDATE_RESULT,
  payload: result,
});

export const updateResult = result => dispatch => {
  if (result.hasOwnProperty('status')) {
    dispatch(updateResultAction(result));
  } else {
    dispatch(resetGame());
  }
};

export const updateResultRemind = result => dispatch => {
  if (result === null) return;
  if (result.itemImage !== undefined) {
    dispatch(updateResultAction(result));
    dispatch(openWinnerModal());
  } else {
    dispatch(resetGame());
  }
};

export const prizeDecision = (id, type) => (dispatch, getState, { socket }) => {
  socket.emit('craft:prize', { gameId: id, action: type });
};

export const openWinnerModal = () => ({
  type: OPEN_WINNER_MODAL,
});

export const closeWinnerModal = () => ({
  type: CLOSE_WINNER_MODAL,
});

export const updateHistory = history => ({
  type: UPDATE_HISTORY,
  payload: history,
});

export const getWin = () => (dispatch, getState, { socket }) => {
  socket.emit('craft:get-win');
};

export const getHistory = () => (dispatch, getState, { socket }) => {
  const requestId = getRandomInt(10000, 20000);
  const appId = getCrossGameId(getState());
  socket.emit('craft:history', { appId, requestId });
  socket.on(`craft:response:${requestId}`, data => {
    socket.removeListener(`craft:response:${requestId}`);
    dispatch(updateHistory(data.history));
  });
};

export const eventsTypes = [
  { event: 'craft:decision', action: updateResult },
  { event: 'craft:remind', action: updateResultRemind },
];
