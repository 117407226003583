import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button } from '@components/Common';
import { useUserPermission } from 'core/User';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { useTranslation } from 'react-i18next';
import { Container } from './inventory-navigation.styles';

interface IInventoryNavigationProps {
  hideAuction?: boolean;
}

export const InventoryNavigation: FC<IInventoryNavigationProps> = ({ hideAuction }) => {
  const { t } = useTranslation('marketplace');
  const match = useRouteMatch();
  const { handleOpenDialog } = useDialog(userModals.USER_SETTING_UP_STEAM_ACCOUNT);

  const { permissions } = useUserPermission();

  if (!permissions?.canSteamAPI?.success || !permissions?.canTrade?.success) {
    return (
      <Container>
        <Button className="primary full" onClick={handleOpenDialog}>
          {t('Update settings')}
        </Button>
      </Container>
    );
  }

  if (hideAuction) {
    return null;
  }

  return (
    <Container>
      {/*<Button className="secondary">*/}
      {/*  <i className="icon-settings" />*/}
      {/*</Button>*/}
      <Button className="primary" to={`${match.url}/inventory`} as={Link}>
        {t('create an auction')}
      </Button>
    </Container>
  );
};
