import React, { memo } from 'react';
import { HeaderComponent } from './header';
import { WeeklyTasks } from './weekly-tasks';
import { CaseShop } from './case-shop';
import { DailyTasks } from './daily-tasks';

export const LeaderRace = memo(() => {
  return (
    <>
      <HeaderComponent />
      <DailyTasks />
      <WeeklyTasks />
      <CaseShop />
    </>
  );
});
