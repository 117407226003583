import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  height: 16px;
  margin: auto;
  align-items: center;
  z-index: 3;
`;

export const RouletteLine = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  width: 100%;

  .rotate {
    transform: rotate(180deg);
  }

  &[data-line-type='2'] {
    padding: 0 16px;
  }

  &[data-line-type='3'] {
    .line {
      flex: none;
      width: 98px;
    }

    @media screen and (max-width: 374px) {
      .line {
        flex: none;
        width: 80px;
      }

      .center-line {
        width: 85px;
      }
    }
  }

  &[data-line-type='4'] {
    max-width: 375px;

    .dote-circle {
      width: 2px;
    }
  }

  &[data-line-type='2 x 2'] {
    max-width: 375px;
    position: relative;

    .dote-absolute {
      position: absolute;
    }
  }
`;

export const Line = styled.div`
  opacity: 0.5;
  height: 2px;
  border-radius: 1px;
  background-color: var(--color-white-gray);
  flex: 1;
`;

export const Dote = styled.div`
  height: 2px;
  width: 4px;
  border-radius: 1px;
  background-color: var(--color-white);
`;
