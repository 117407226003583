import React from 'react';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { useGameHistory } from '../../../hooks';

export const GameHistory = () => {
  const history = useGameHistory();
  const items = useTransition(
    history.map(({ result, ...rest }: any, i: number) => ({
      ...rest,
      x: i * 36,
      result: result.split(','),
    })),
    d => d.id,
    {
      initial: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      from: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(0)`,
        opacity: 0,
      }),
      enter: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      update: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      leave: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(0)`,
        opacity: 0,
      }),
      unique: true,
    }
  );

  return (
    <Container>
      {items.map(({ item, props, key }) => (
        <animated.div key={key} style={props} className={item.result[0]}>
          {item.result[0]}
          {!!item.result[1] && <span />}
        </animated.div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 100%;
  height: 3.25rem;
  background: rgba(19, 26, 29, 0.9);
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 100;
  border-top: 1px solid var(--color-gray-900);
  border-bottom: 1px solid var(--color-gray-900);
  overflow: hidden;

  @media screen and (min-width: 500px) {
    width: 500px;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    position: absolute;
    left: 0.875rem;
    background: var(--fill-color);

    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.75rem;
    color: var(--color-white);

    & span {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      background: var(--fill-color);
      border: 1px solid rgba(19, 26, 29, 0.9);
    }
  }
`;
