import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

interface IIInventoryElementStyle {
  rarity?: string;
  contain?: boolean;
}

export const Locker = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-dark-600);
  opacity: 0.9;
  z-index: 3;
  color: var(--color-gray-200);
  pointer-events: none;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  gap: 25px;
  padding: 0 20px;
`;
export const Container = styled(SkeletonElement)<IIInventoryElementStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content 1fr 0.875rem 1rem 1rem;
  grid-gap: 0.125rem;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--size-radius-m);
  padding: 0.625rem;
  overflow: hidden;
  box-shadow: inset 0 0.125rem 0 ${({ rarity }) => rarity || 'transparent'};
  background: ${({ contain }) => (contain ? 'var(--color-white-gray)' : 'var(--color-dark-200)')};
  transition: background 150ms ease-in-out;

  @media screen and (min-width: 375px) {
    padding: 0.75rem;
  }

  & .steam-hold {
    display: grid;
    grid-auto-flow: column;
    justify-self: center;
    grid-gap: 0.25rem;
    color: var(--color-gray-200);

    & i {
      color: inherit;
      font-size: 0.75rem;

      @media screen and (min-width: 375px) {
        font-size: 0.875rem;
      }
    }

    & span {
      align-self: center;
      color: inherit;
      font-weight: bold;
      font-size: 0.5rem;
      line-height: 0.75rem;
      letter-spacing: 0.3px;

      @media screen and (min-width: 375px) {
        font-size: 0.625rem;
      }
    }
  }

  & .left-indicators,
  & .right-indicators {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1rem;
    grid-auto-rows: 1rem;
    grid-gap: 0.25rem;
    position: absolute;
    top: 0.5rem;
  }

  & .left-indicators {
    left: 0.5rem;

    & .in-trade,
    & .selected {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
    }

    & .selected {
      background: var(--primary);
      transform: ${({ contain }) => (contain ? 'scale(1)' : 'scale(0)')};
      transition: transform 150ms ease-in-out;
      border-radius: 50%;

      & i {
        font-size: 0.5rem;
        color: var(--color-dark-100);
      }
    }

    & .in-trade {
      & i {
        font-size: 1rem;
        color: var(--color-white);
      }
    }
  }

  & .right-indicators {
    right: 0.5rem;

    & .stickers {
      position: relative;

      & i {
        font-size: 1rem;
        color: var(--color-gray-200);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate3d(-50%, -50%, 0);
      }

      & span {
        font-weight: bold;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        color: var(--color-dark);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .float {
    justify-self: center;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.3px;
    color: transparent;

    &.factory-new {
      color: var(--color-exterior-factory-new);
    }

    &.minimal-wear {
      color: var(--color-exterior-minimal-wear);
    }

    &.field-tested {
      color: var(--color-exterior-field-tested);
    }

    &.well-worn {
      color: var(--color-exterior-well-worn);
    }

    &.battle-scarred {
      color: var(--color-exterior-battle-scarred);
    }
  }

  & .name {
    width: 100%;
    justify-self: center;
    font-size: 0.6875rem;
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.63px;
    color: var(--color-white);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (min-width: 375px) {
      font-size: 0.8125rem;
    }
  }

  & .price {
    justify-self: center;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: var(--color-white);

    @media screen and (min-width: 375px) {
      font-size: 0.875rem;
    }
  }
`;
