import { CaseItemsDialogConfig } from 'games/CaseGame';
import { DIALOG } from '../../dialog-combiner';
import { transitions } from '../../transition.config';
import { CasesLastRevisionDialog } from './cases-last-revision-dialog';

DIALOG.add(CaseItemsDialogConfig.LAST_REVISION, {
  component: CasesLastRevisionDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});
