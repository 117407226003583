import { CaseDetailsConfig } from 'games/CaseBattle/dialog-config';
import { CaseInfo } from './case-info';
import { transitions } from 'dialogs/transition.config';
import { DIALOG } from 'dialogs/dialog-combiner';

DIALOG.add(CaseDetailsConfig.CASE_DETAIL, {
  component: CaseInfo,
  transition: transitions.SHOW_NAV,
  anchor: 'user-portal',
  class: 'close',
});
