import React, { FC } from 'react';
import styled from 'styled-components';
import { IAccountStatisticLeader } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface ILeaderStatistic {
  leaders: IAccountStatisticLeader;
}

export const LeaderStatistic: FC<ILeaderStatistic> = ({ leaders }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <span>{t('Places in Leader race')}</span>
      <PlacesList>
        {Object.keys(leaders).map((raceType: string) => (
          <PlaceItem key={raceType} raceType={raceType}>
            <span>
              {t('Races type', {
                context: raceType,
              })}
            </span>

            <span>{leaders[raceType] || '1000+'}</span>
          </PlaceItem>
        ))}
      </PlacesList>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 1rem;
  padding: 1rem;
  background: var(--color-gray-700);

  & > span {
    font-size: 0.9rem;
    line-height: 1.79;
    color: var(--color-white);
  }
`;

const PlacesList = styled.div`
  display: flex;
  flex-flow: column;
  background: inherit;

  --fastPlace: var(--fast-race);
  --regularPlace: var(--regular-race);
  --greatPlace: var(--great-race);
`;

const PlaceItem = styled.div<{ raceType: string }>`
  display: flex;
  justify-content: space-between;

  font-size: 0.9rem;
  line-height: 2;
  color: ${p => `var(--${p.raceType})`};

  position: relative;
  background: inherit;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    border-bottom: 1px dashed ${p => `var(--${p.raceType})`};
    position: absolute;
    bottom: 10px;
  }

  & > span {
    background: inherit;
    position: relative;

    &:first-child {
      padding-right: 0.3rem;
    }

    &:last-child {
      padding-left: 0.3rem;
    }
  }
`;
