import { Button, NavigationLink } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-50);
`;

export const Top = styled.div`
  padding: 36px 24px 28px;
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 26px;
  padding-bottom: 14px;
  text-align: left;
`;

export const Text = styled.p`
  color: var(--color-white);
  font-size: 14px;
  line-height: 20px;

  &:last-child {
    padding-top: 10px;
  }
`;

export const Header = styled.span`
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  padding: 0 16px 17px;
`;

export const ButtonLink = styled(NavigationLink)`
  background: var(--color-dark-600);
`;

export const Body = styled.div`
  padding: 34px 18px 24px;
`;

export const ConfirmTitle = styled.p`
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 20px;
  padding-bottom: 13px;
`;

export const HelpWrap = styled.div`
  display: flex;
  gap: 15px;
  padding: 36px 0 60px;

  & > svg {
    min-width: fit-content;
  }
`;

export const HelpText = styled.p`
  color: var(--color-white);
  font-size: 14px;
  line-height: 20px;

  & > span {
    color: #53b7d5;
    display: block;
  }
`;

export const MyButton = styled(Button)`
  &.primary {
    &.disabled,
    &:disabled {
      background-color: var(--yellow);
      opacity: 0.2;
    }
  }
`;
