import React from 'react';
import styled from 'styled-components';
import { SlotGameGridView } from './slot-game-grid-view';
import { SlotGameComboLines } from './slot-game-combo-lines';
import { SlotGameControllers } from './slot-game-controllers';
import { FormProvider, useForm } from 'react-hook-form';

export const SlotGame = () => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      betPerLine: 0.01,
      selectedLines: 9,
      autoplay: null,
    },
    shouldUnregister: false,
  });

  return (
    <FormProvider {...methods}>
      <Container>
        <form>
          <div className="slot-game-wrapper">
            <SlotGameComboLines>
              <SlotGameGridView />
            </SlotGameComboLines>
            <SlotGameControllers />
          </div>
        </form>
      </Container>
    </FormProvider>
  );
};

const Container = styled.div`
  display: flex;

  & form {
    display: flex;
    flex: 1;
    height: 100%;

    & .slot-game-wrapper {
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: 6.25rem minmax(min-content, 1fr) min-content;
      width: 100%;
      min-height: 100%;
    }
  }
`;
