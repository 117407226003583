import { Button } from '@components/Common';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .item-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .item-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 500ms ease-in;
  }

  .item-exit {
    opacity: 1;
  }

  .item-exit-active {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
    transition: all 500ms ease-in;
  }
`;

export const WrapperCardBattle = styled(TransitionGroup)`
  flex-grow: 1;
  display: flex;
  gap: 1px;
  flex-direction: column;

  &.tablet {
    @media screen and (min-width: 500px) {
      margin: 0;
      width: 100%;
      padding: 0 10px;
    }
  }
`;

export const StyledButton = styled(Button)`
  background: var(--yellow);
  width: 90%;
  margin: 8px 0 16px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  background: none;
  bottom: 0;
  backdrop-filter: blur(5px);
  position: sticky;
  height: fit-content;
  display: flex;
  justify-content: center;
`;
