import { Reducer } from 'react';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { ActionsObservable, ofType } from 'redux-observable';
import { addNotification } from 'modules/Notifications/duck';
import { SUMMERTIME_EVENT_ACTIONS_TYPES } from '../actionTypes';
import eventRepository from '../../repository/event.repository';
import {
  ActionRequestLRInfo,
  LRInfoState,
  LRInfoActions,
  LRInfoResponse,
} from './event-results.interfaces';

export const actionRequestLRInfo = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_DATA,
});

const actionResponseLRInfo = (data: LRInfoResponse) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_DATA,
  payload: data,
});

export const leaderRaceEpic = (action$: ActionsObservable<ActionRequestLRInfo>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_DATA),
    switchMap(() =>
      eventRepository.getLRLeaders().pipe(
        map(({ response }: { response: LRInfoResponse }) => actionResponseLRInfo(response)),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

const initState: LRInfoState = {
  data: null,
  isLoaded: false,
  isLoading: true,
};

export const leaderRaceInfoReducer: Reducer<LRInfoState, LRInfoActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_LR_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_LR_DATA: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
