export enum StockActionTypes {
  STOCK_ATTACH_LISTENERS = 'app/exchange/STOCK_ATTACH_LISTENERS',
  STOCK_DETACH_LISTENERS = 'app/exchange/STOCK_DETACH_LISTENERS',
}

export interface IAttachListener {
  type: typeof StockActionTypes.STOCK_ATTACH_LISTENERS;
}

export interface IDetachListener {
  type: typeof StockActionTypes.STOCK_DETACH_LISTENERS;
}

export type marketplaceActions = IAttachListener | IDetachListener;
