import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAppSound } from '../selectors';
import { updateSound } from '../duck';
import {
  ICommonSounds,
  ICommonSoundsOptions,
  IGameOptionsKeys,
  IGameSounds,
  IGameSoundsOptions,
  ISounds,
} from '../interfaces/appshell.reducer.interface';

interface IHandleToggleSound {
  moduleName: keyof ISounds;
  option?: keyof ICommonSoundsOptions | IGameOptionsKeys;
  game?: keyof IGameSoundsOptions;
}

export const useSoundSettings = () => {
  const soundSettings = useSelector(getAppSound, shallowEqual);
  const dispatch = useDispatch();

  const handleToggleSound = useCallback(
    ({ moduleName, option, game }: IHandleToggleSound) => () => {
      const updateAllOptions = (module: IGameSounds | ICommonSounds) => {
        if (moduleName === 'commonSounds') {
          return {
            enabled: !module.enabled,
            options: Object.fromEntries(
              Object.entries(module.options).map(([key]) => [key, !module.enabled])
            ),
          };
        } else {
          return {
            enabled: !module.enabled,
            options: Object.fromEntries(
              Object.entries(module.options).map(([key, value]) => {
                return [
                  key,
                  {
                    enabled: !module.enabled,
                    options: Object.fromEntries(
                      Object.entries(value.options).map(([key, value]) => [key, !module.enabled])
                    ),
                  },
                ];
              })
            ),
          };
        }
      };

      const updateCommonOption = (module: ICommonSounds, option: keyof ICommonSoundsOptions) => {
        const activeOptions = Object.entries({
          ...module.options,
          [option]: !module.options[option],
        }).filter(([_name, value]) => value);

        return {
          enabled: !!activeOptions.length,
          options: { ...module.options, [option]: !module.options[option] },
        };
      };

      const updateGame = (module: IGameSounds, game: keyof IGameSoundsOptions) => {
        const activeGames = Object.entries({
          ...module.options,
          [game]: {
            ...module.options[game],
            enabled: !module.options[game].enabled,
          },
        }).filter(([_name, value]) => value.enabled);

        return {
          enabled: !!activeGames.length,
          options: {
            ...module.options,
            [game]: {
              enabled: !module.options[game].enabled,
              options: Object.fromEntries(
                Object.entries(module.options[game].options).map(([key]) => [
                  key,
                  !module.options[game].enabled,
                ])
              ),
            },
          },
        };
      };

      const updateGameOption = (
        module: IGameSounds,
        game: keyof IGameSoundsOptions,
        option: IGameOptionsKeys
      ) => {
        const activeOptions = Object.entries({
          ...module.options[game].options,
          //@ts-ignore
          [option]: !module.options[game].options[option],
        }).filter(([_name, value]) => value);

        const activeGames = Object.entries({
          ...module.options,
          [game]: {
            ...module.options[game],
            enabled: activeOptions.length,
          },
        }).filter(([_name, value]) => value.enabled);

        return {
          enabled: !!activeGames.length,
          options: {
            ...module.options,
            [game]: {
              enabled: !!activeOptions.length,
              options: {
                ...module.options[game].options,
                //@ts-ignore
                [option]: !module.options[game].options[option],
              },
            },
          },
        };
      };

      const updateData = () => {
        if (moduleName === 'gameSounds') {
          if (game && option) {
            return updateGameOption(soundSettings[moduleName], game, option as IGameOptionsKeys);
          } else if (game) {
            return updateGame(soundSettings[moduleName], game);
          }
        } else if (moduleName === 'commonSounds' && option) {
          return updateCommonOption(
            soundSettings[moduleName],
            option as keyof ICommonSoundsOptions
          );
        }

        return updateAllOptions(soundSettings[moduleName]);
      };

      dispatch(
        updateSound({
          ...soundSettings,
          [moduleName]: updateData(),
        })
      );
    },
    [soundSettings, dispatch]
  );

  return {
    soundSettings,
    handleToggleSound,
  };
};
