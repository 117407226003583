import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { ACTION_TYPE } from '../actionType';
import {
  ActionRequestAddNextCaseHistory,
  ActionRequestCaseHistory,
  ActionRequestType,
  ActionSellItemInHistoryRequest,
  ActionsCaseHistory,
  CaseHistoryState,
  ResponseDataCaseHistory,
} from './case-history-duck.interfaces';
import { catchError, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import casesRepository from 'games/CaseGame/repository/casesRepository';
import { of } from 'rxjs';
import { addNotification } from 'modules/Notifications/duck';

export const actionRequestCaseHistory = (pageNumber: number, pageSize: number) => ({
  type: ACTION_TYPE.ACTION_REQUEST_CASE_HISTORY,
  payload: {
    pageNumber,
    pageSize,
  },
});

export const actionRequestAddNextCaseHistory = (pageNumber: number, pageSize: number) => ({
  type: ACTION_TYPE.ACTION_REQUEST_CASE_HISTORY_ADD_NEXT,
  payload: {
    pageNumber,
    pageSize,
  },
});

const actionResponseCaseHistory = (data: ResponseDataCaseHistory, type: ActionRequestType) => ({
  type: ACTION_TYPE.ACTION_RESPONSE_CASE_HISTORY,
  payload: {
    type,
    data,
  },
});

export const sellItemInHistory = ($action: ActionsObservable<ActionSellItemInHistoryRequest>) =>
  $action.pipe(
    ofType(ACTION_TYPE.ACTION_REQUEST_SELL_ITEM_IN_HISTORY),
    pluck('payload'),
    switchMap(id => {
      return casesRepository.sellItems([id]).pipe(
        mergeMap(() => of()),
        catchError(({ response }) =>
          of(addNotification({ type: 'error', body: response?.message }))
        )
      );
    })
  );

export const actionSellItemInHistoryRequest = (id: number) => ({
  type: ACTION_TYPE.ACTION_REQUEST_SELL_ITEM_IN_HISTORY,
  payload: id,
});

export const caseHistoryEpic: Epic = (action$: ActionsObservable<ActionRequestCaseHistory>) =>
  action$.pipe(
    ofType(ACTION_TYPE.ACTION_REQUEST_CASE_HISTORY),
    switchMap(({ payload }) =>
      casesRepository.getCaseHistory(payload.pageNumber, payload.pageSize).pipe(
        map(({ response }) => actionResponseCaseHistory(response, 'init')),
        catchError(() => of())
      )
    )
  );

export const addNextCaseHistory: Epic = (
  action$: ActionsObservable<ActionRequestAddNextCaseHistory>
) =>
  action$.pipe(
    ofType(ACTION_TYPE.ACTION_REQUEST_CASE_HISTORY_ADD_NEXT),
    switchMap(({ payload }) =>
      casesRepository.getCaseHistory(payload.pageNumber, payload.pageSize).pipe(
        map(({ response }: { response: ResponseDataCaseHistory }) =>
          actionResponseCaseHistory(response, 'add')
        ),
        catchError(() => of())
      )
    )
  );

export const caseHistoryReducer = (
  state: CaseHistoryState = { data: null, isLoading: true },
  action: ActionsCaseHistory
) => {
  switch (action.type) {
    case ACTION_TYPE.ACTION_REQUEST_CASE_HISTORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_TYPE.ACTION_RESPONSE_CASE_HISTORY: {
      if (action.payload.type === 'add') {
        return {
          isLoading: false,
          data: {
            ...state.data,
            items: state.data.items.concat(action.payload.data.items),
          },
        };
      } else {
        return {
          data: action.payload.data,
          isLoading: false,
        };
      }
    }
    default:
      return state;
  }
};
