import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';
import { QueryService } from 'services/query';

export class UserGofastInventory {
  static fetchInventory(params: Record<string, any>): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/inventory/v2/items${QueryService.paramsToQuery(params)}`,
      method: 'GET',
      withCredentials: true,
    });
  }

  static fetchInventoryInfo(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/info`,
      method: 'GET',
      withCredentials: true,
    });
  }

  // the same request, doesn't use
  // static fetchExchange({
  //   ids,
  //   userInventoryIds,
  // }: Record<string, number[]>): Observable<AjaxResponse> {
  //   return ajax({
  //     url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/items/purchase`,
  //     method: 'POST',
  //     withCredentials: true,
  //     crossDomain: true,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       ids,
  //       userInventoryIds,
  //     }),
  //   });
  // }

  static fetchWithdraw(userItems: any): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/items/withdraw`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userItems }),
    });
  }
}
