import React from 'react';
import { TransactionList } from '../TransactionList';
import { TransactionsTab } from '../TransactionsTab';
import { EmptyTransactions } from '../EmptyTransactions';
import { useTransactionFacade } from '../../../hooks';
import { Wrapper } from './Transactions.styles';

export const Transactions = () => {
  const { transactions, changeFilter, filterId, handleLoadingMore } = useTransactionFacade();

  const hasTransactions = !!transactions.entities.valueSeq().toArray().length;

  return (
    <Wrapper className="content">
      <TransactionsTab changeFilter={changeFilter} filterId={filterId} />
      {hasTransactions && (
        <TransactionList handleLoadingMore={handleLoadingMore} transactions={transactions} />
      )}
      {!hasTransactions && transactions.loaded && <EmptyTransactions />}
    </Wrapper>
  );
};
