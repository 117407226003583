import { ITotalStatistics, IYearlyStatistics } from './reducer.statistics.interfaces';

export enum statisticsTypes {
  FETCH_STATISTICS_REQUEST = 'app/leader-race/statistics/FETCH_STATISTICS_REQUEST',
  FETCH_STATISTICS_RESPONSE = 'app/leader-race/statistics/FETCH_STATISTICS_RESPONSE',
  FETCH_STATISTICS_FAILURE = 'app/leader-race/statistics/FETCH_STATISTICS_FAILURE',
  FETCH_YEARLY_STATISTICS_RESPONSE = 'app/leader-race/statistics/FETCH_YEARLY_STATISTICS_RESPONSE',
}

export interface IFetchStatistics {
  type: typeof statisticsTypes.FETCH_STATISTICS_REQUEST;
}

export interface IUpdateStatistics {
  type: typeof statisticsTypes.FETCH_STATISTICS_RESPONSE;
  payload: ITotalStatistics;
}

export interface IFailureStatistics {
  type: typeof statisticsTypes.FETCH_STATISTICS_FAILURE;
}

export interface IUpdateYearlyStatistics {
  type: typeof statisticsTypes.FETCH_YEARLY_STATISTICS_RESPONSE;
  payload: IYearlyStatistics;
}

export type statisticsActions =
  | IFetchStatistics
  | IUpdateStatistics
  | IFailureStatistics
  | IUpdateYearlyStatistics;
