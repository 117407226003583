import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { rootEpics$ } from 'store/epicMiddleware';
import { store } from 'store/configureStore';
import { clearance, clearanceEpic, sellAllEpic } from './clearance.duck';
import { StockActionTypes } from '../interfaces';
import { freeCaseInfo, freeCaseInfoEpic } from './free-case.duck';
import { FreeCaseAction } from '../interfaces/free-case-info.interfaces';

const reducer = combineReducers({ clearance });
export const exchangeEpics = combineEpics(clearanceEpic, sellAllEpic);

export const registerStockModule = () => {
  rootEpics$.next([exchangeEpics, StockActionTypes.STOCK_DETACH_LISTENERS]);
  store.reducerManager.add('stock', reducer);
};

export const unregisterStockModule = () => {
  store.reducerManager.remove('stock');
};

export const registerFastCaseInfoModule = () => {
  rootEpics$.next([freeCaseInfoEpic, FreeCaseAction.FREE_CASE_INFO_DETACH_LISTENERS]);
  store.reducerManager.add('freeCaseInfo', freeCaseInfo);
};
