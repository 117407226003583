import { DroppedItemsResults } from '../ducks';

export const getLine = (
  ids: number[][],
  strip: number[][],
  prevItemReward: DroppedItemsResults[]
) => {
  return ids?.map((idsLine, index) => {
    if (prevItemReward[index]?.caseRevisionItem?.inventoryItem.id) {
      const prevResult = prevItemReward[index]?.caseRevisionItem?.inventoryItem.id;
      return [
        ...idsLine.slice(27, 28),
        prevResult,
        ...idsLine.slice(29, 30),
        ...strip[index].slice(3),
      ];
    } else {
      return [...idsLine.slice(0, 3), ...strip[index].slice(3)];
    }
  });
};
