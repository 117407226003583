import * as React from 'react';

import { FieldPlay } from './FieldPlay';
import { FieldGame } from './FieldGame';

export class Ticket extends React.PureComponent {
  render() {
    const { ticket, ticketType, doStep, bet } = this.props;
    return (
      <React.Fragment>
        {ticket.status && ticket.status !== 'waiting' ? (
          <FieldPlay ticket={ticket} ticketType={ticketType} doStep={doStep} />
        ) : (
          <FieldGame ticketType={ticketType} bet={bet} />
        )}
      </React.Fragment>
    );
  }
}
