import './locales';
import { IModule } from '../../core/rootInterfaces/root.interface';

export { GameRouter as SlotsRouter } from './components';
// export const SlotsRouter = lazy(() => import('./components').then(module => ({ default: module.GameRouter })))
export const slotModule: IModule = {
  moduleName: 'slot',
  moduleId: 5,
  path: '/slot',
  type: 'solo-pve',
  reducer: null,
};
