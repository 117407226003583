import React from 'react';
import { AppBar } from '@components/bars';
import { Top } from '@components/Top';

export const TopsPage = () => (
  <>
    <AppBar deep />
    <Top type="double" />
  </>
);
