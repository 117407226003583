import styled, { css } from 'styled-components';

export const RouletteContainer = styled.div<{ playerId: number }>(
  ({ playerId }) => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 100%;
      z-index: -1;
  
      &.anima {
        animation: roulette-vertical-${playerId} cubic-bezier(0, 0.2, 0.1, 1);
        animation-delay: 100ms;
        -webkit-animation-fill-mode: forwards;
        animation-duration: 1750ms;
      }
  
      @keyframes roulette-vertical-${playerId} {
        0% {
          transform: translateY(0);
        }
  
        5% {
          transform: translateY(${Math.random() * (68 / 5)}px);
        }
  
        90% {
          transform: translateY(
            ${-((68 + 5) * 8 + (Math.random() * (68 / 2) + 5) * getrandomsign())}px
          );
        }
  
        100% {
          transform: translateY(${-(68 + 5) * 8}px);
        }
      }
    `
);

const getrandomsign = () => {
  return Math.random() >= 0.5 ? -1 : 1;
};
