export default {
  'Fair game': '公平遊戲',
  Bet: '賭注',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '（0 =禁用）',
  'Bet coins': '投注硬币',
  Stop: '停止',
  'Wait for next round...': '等待下一轮...',
  Example: '範例',
  'You won': '您贏了',
};
