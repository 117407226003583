import { ActionsObservable, Epic, ofType } from 'redux-observable';
import {
  CustomItemInfo,
  ItemsModified,
  // SuitCaseData,
} from '../../interfaces/cases.interface';
import { ACTION_TYPE } from '../actionType';
import { CaseDataState } from '../interfaces';
import casesRepository from '../../repository/casesRepository';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Reducer } from 'react';
import { ActionRequestSuitCase, ActionsCaseData } from './case-items.interfaces';
import { CaseLRInfo } from 'modules/event-module/event-summertime';

export const actionRequestSuitCase = (id: number) => ({
  type: ACTION_TYPE.SUIT_CASE_ACTION_REQUEST,
  payload: { id },
});
//NOTE: updated for summertime-event
const actionResponseSuitCase = (data: CaseLRInfo) => ({
  type: ACTION_TYPE.SUIT_CASE_ACTION_RESPONSE,
  payload: data,
});

export const suitCaseEpic: Epic = (action$: ActionsObservable<ActionRequestSuitCase>) =>
  action$.pipe(
    ofType(ACTION_TYPE.SUIT_CASE_ACTION_REQUEST),
    switchMap(({ payload }) =>
      casesRepository.fetchCaseDataById(payload.id).pipe(
        //NOTE: updated for summertime-event
        map(({ response }: { response: CaseLRInfo }) => actionResponseSuitCase(response)),
        catchError(() => of())
      )
    )
  );

export const caseData: Reducer<CaseDataState, ActionsCaseData> = (
  state = { data: {}, isLoading: true },
  action
) => {
  switch (action.type) {
    case ACTION_TYPE.SUIT_CASE_ACTION_RESPONSE: {
      const lastRevisionItems = action.payload.lastRevision.items;
      const possibleItems: Record<string, ItemsModified> = {};
      const customItems: Record<string, CustomItemInfo[]> = {};

      lastRevisionItems
        .sort((a, b) => (a.inventoryItem.price > b.inventoryItem.price ? -1 : 1))
        .forEach(({ inventoryItem, count }) => {
          const { baseItem, price, steamPrice, id } = inventoryItem;
          const { color, icon, phase, rarity, skin, shortName, type, weapon, name } = baseItem;
          const fullName = shortName ? shortName + (skin ? ` | ${skin}` : '') : skin;
          const customObj = {
            price,
            steamPrice,
            statTrak: baseItem.statTrak,
            exterior: inventoryItem.baseItem.exterior,
            count,
            name: inventoryItem.baseItem.name,
          };

          if (customItems[fullName]) {
            customItems[fullName].push(customObj);
          } else {
            customItems[fullName] = [customObj];
          }
          possibleItems[fullName] = {
            generalInfo: {
              fullName,
              name,
              color,
              icon,
              phase,
              rarity,
              type,
              weapon,
              id,
            },
            customItemInfo: customItems[fullName],
          };
        });

      return {
        data: {
          ...action.payload,
          itemsForCaseShow: possibleItems,
        },

        isLoading: false,
      };
    }
    case ACTION_TYPE.SUIT_CASE_ACTION_REQUEST: {
      return { ...state, isLoading: true };
    }
    default:
      return state;
  }
};
