import styled from 'styled-components';

export const Container = styled.div`
  &.container {
    padding: 0;
    position: relative;

    &::before,
    &::after {
      display: none;
    }

    & > img {
      left: 12px;
      position: absolute;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px 0 50px;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 14px;
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
`;
