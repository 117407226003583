import React from 'react';
import { Transactions } from '../../components/Transactions';
import { AppBar } from '@components/bars';

export const TransactionsPage = () => {
  return (
    <>
      <AppBar deep />
      <Transactions />
    </>
  );
};
