import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

interface IParticipationList {
  participate: number[];
  handleUnPickChip: (isx: number) => () => void;
}

export const ParticipationList: FC<IParticipationList> = ({ participate, handleUnPickChip }) => {
  const list = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => ({
      amount: participate[i] ?? null,
      key: i + 1,
    }));
  }, [participate]);
  return (
    <Container>
      {list.map(({ amount, key }, i) =>
        amount ? (
          <div key={key} onClick={handleUnPickChip(i)}>
            <img src={`//d2lomvz2jrw9ac.cloudfront.net/common/chips/${amount}.svg`} alt="" />
          </div>
        ) : (
          <div key={key} />
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(10, 1.3rem);
  grid-template-rows: 1.3rem;
  grid-gap: 0.5rem;
  justify-content: center;
  padding: 0.5rem 0 1rem;

  & > div {
    background: var(--color-dark-100);
    border-radius: 50%;
  }
`;
