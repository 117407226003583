import React, { FC } from 'react';
import styled from 'styled-components';
import BgImg from '../../../assets/card-back.png';
import { dataCards } from '../../../utils';

interface IPlayingCard {
  card: any;
}

export const PlayingCard: FC<IPlayingCard> = ({ card }) => {
  const [value, sign] = card;
  // @ts-ignore
  const { color, img } = dataCards[sign];
  return (
    <Container>
      <div className="from-side">
        <span style={{ color }}>{value}</span>
        <img src={img} alt="" />
      </div>
      <div className="back-side" />
    </Container>
  );
};

const Container = styled.div`
  width: 2rem;
  position: relative;
  transform-style: preserve-3d;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    float: left;
    padding-top: 156%;
  }

  &:after {
    display: block;
    clear: both;
  }

  & .from-side,
  & .back-side {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: var(--size-radius-s);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .from-side {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content 1fr;
    grid-gap: 0.125rem;
    padding: 0.25rem;
    background: var(--color-white);
    backface-visibility: hidden;
    transform: rotateY(180deg);

    & span {
      justify-self: center;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.25rem;
      font-weight: bold;
      letter-spacing: 1.5px;
    }

    & img {
      max-width: 90%;
      max-height: 100%;
      overflow: hidden;
      object-fit: cover;
      justify-self: center;
      align-self: stretch;
      align-content: stretch;
    }
  }

  & .back-side {
    background-color: var(--color-white);
    background-image: url(${BgImg});
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    padding: 0.125rem;
  }
`;
