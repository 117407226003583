import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { useInventoryFilterController, useInventoryRefresh } from '../../../hooks';
import { MarketplaceDialogs } from '../../../marketplace-dialogs.config';

export const InventoryFilters = () => {
  const { t } = useTranslation('marketplace');
  const { handleRefreshInventory } = useInventoryRefresh();
  const { filters } = useInventoryFilterController();
  const filterDialog = useDialog(MarketplaceDialogs.INVENTORY_SORT_BY_FILTERS);

  return (
    <Container>
      <Button className="secondary" onClick={handleRefreshInventory}>
        <i className="icon-refresh" />
      </Button>
      <Button className="secondary" onClick={filterDialog.handleOpenDialog}>
        <div className={`sort-type ${filters.sortBy}`}>
          <i className="icon-asc-sort-price" />
          <i className="icon-desc-sort-price" />
          <i className="icon-asc-sort-name" />
          <i className="icon-desc-sort-name" />
          <i className="icon-sort-overprice" />
          <i className="icon-sort-underprice" />
        </div>
        {t(`sortBy.${filters.sortBy}`)}
        <div className="arrows" />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: 2.5rem;
  grid-gap: 0.25rem;
  padding: 1.25rem 1rem;

  & button {
    width: auto;
    height: auto;

    &:last-of-type {
      justify-content: space-between;
      padding: 0 0.75rem;
    }

    & i {
      font-size: 1.25rem;
    }
  }

  & .sort-type {
    width: 1.125rem;
    height: 0.75rem;
    margin-right: 0.8rem;
    position: relative;

    &.price .icon-asc-sort-price,
    &.-price .icon-desc-sort-price,
    &.name .icon-asc-sort-name,
    &.-name .icon-desc-sort-name {
      opacity: 1;
    }

    & i {
      font-size: 1.2rem;
      color: var(--color-white);
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color 150ms ease-out;
    }
  }

  & .arrows {
    width: 10px;
    margin-left: 0.8rem;

    &:after,
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
    }

    &:before {
      transform: translate(0, 7px);
    }

    &:after {
      transform: rotate(180deg) translate(0, 7px);
    }
  }
`;
