import styled from 'styled-components';

export const Container = styled.div<{
  hide: boolean;
  shift: number;
  width: number;
}>`
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: ${({ hide }) => (hide ? '0' : 'auto')};
  left: ${({ shift }) => shift}px;
  overflow: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  position: absolute;
  transition: left 0.5s cubic-bezier(0.37, 0.62, 0.37, 1);
  width: ${({ width }) => width}px;
`;
