import React from 'react';
import { AppBar } from '@components/bars';
import { Rules } from 'games/CaseGame/components';

export const RulesPage = () => (
  <>
    <AppBar deep />
    <Rules />
  </>
);
