export default {
  'What is the Ticket System?': 'Hva er billettsystemet?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Når spillerne plasserer en bet får de virtuelle billetter, blir en av dem blir vinneren. Hvis skinnet ditt er verdt $ 1, får du 100 billetter, men hvis du legger inn et skin verdt $ 10, får du 1000 billetter, også videre.',

  // GAMES

  'How is the winner determined?': 'Hvordan er vinneren trukket?',
  'When is the winner determined?': 'Når er vinneren trukket?',
  'How many bets can I place per one round?': 'Hvor mange ganger kan jeg legge inn per runde?',
  'What Service Commission is there in this game?': 'Hva er tjenestekommisjonen i spillet?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'spillet for de som liker å spille for de høye innsatsene. De som ønsker å prøve lykken legger en innsats på et generelt innskudd som raffles blant spillere på slutten av en runde.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'Vinneren er bestemt av billettsystemet i Classic game.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Vinneren er bestemt på slutten av en rundens tid, eller når det maksimale antall skins oppnås på innskudd.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'En Tjenestekommisjon er fra 0 opp til 10% avhengig av summen av alle skinsa som er i runden.\nTilfeldige skins av det generelle innskuddet tas som en tjenestekommisjon.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'spillet for å prøve lykken din umiddelbart. Det er bare 3 spillere, maksimal innsats er begrenset. Vinneren får totalt innskudd på 3 spillere.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Vinnerne blir trukket tilfeldig, men jo høyere innsats, desto større sjanse til å vinne.',
  'One player can place one bet per one game round in this game.':
    'Du kan legge til en gang per runde.',
  'Where can I see my chances of the winning?': 'Hvor kan jeg se sjansen min for å vinne?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Sjansene for vinneren er angitt i prosentvise vilkår. Du kan se prosent av sjansen din ved siden av spillernes innsats (på høyre side av avataren og liste over skins som er i runden).',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Vinneren er trukket når 3 spillere plasserer sine innsatser i den nåværende spillrunden.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'En Tjenestekommisjon er fra 0 opp til 15% avhengig av summen av alle skinsa.\nTilfeldige skins fra det generelle innskudd av runden er tatt som en tjenestekommisjon.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'i denne modusen kan du få Fast mynter (servicevaluta) og bytte dem til skins i butikken. Gjett vinnerfargen og returner innsatsen din multiplisert med 2 eller 14.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Hvis fargen er svart eller rød, vinner spilleren den plasserte innsatsen multiplisert med 2.',
  'The green slot returns the placed bet multiplied by 14.':
    'Den grønne fargen returnerer den innsatte innsatsen multiplisert med 14.',
  'The bet sum of every slot can be different.': '',

  'How can I Get Fast Coins?': 'Hvordan kan jeg skaffe Fast Mynter?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Delta i verveprogrammet. Inviter nye spillere til prosjektet ved hjelp av henvisningslink eller kode. Du får mynter for hver henvisning og hvert spill han spiller.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Bytt dine skins verdt $1 for Fast mynter.For hver cent av skinnet så får du en Fast mynt. Du kan sende maks 30 skins per trade. Trykk her for mer informasjon om bytting. Vi anbefaler at du bruker Steam Inventory Helperfor å vinne verdien.',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Etterfyll balansen ved hjelp av betalingssystemet. Velg den riktige betalingsmåten og få det nødvendige antallet Fast mynter på kontoen din.',

  'How is the winner slot determined (fair game)?': 'Hvordan er vinneren funnet(rettferdig spill)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'I begynnelsen av en runde genererer Tjenesten et tilfeldig langnummer fra 0 til 1 (f.eks. 0. 223088) og krypterer den gjennom sha224 algoritmen. Resultatet vises i begynnelsen av en runde. Ved slutten av runden multipliserer tjenesten det krypterte nummeret med 15 og får dermed nummeret til en vinneren.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Et eksempel: I begynnelsen av runden er den krypterte koden 0,23088232334703230728. Tjenesten multipliserer den med 15 og får dermed nummer 3.34632348495. Som et resultat er tellingenummeret 3 (det røde sporet). I denne runden er vinnerne de som har lagt sine innsatser på rødfargen.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Du kan sjekke om et vinner er rettferdig eller ikke alene. På slutten av en runde, ta det krypterte nummeret og dekrypter det enda en gang med hjelp av en hvilken som helst online-tjeneste, f.eks http://sha224.net. Du vil få den samme hashverdien som var i begynnelsen av en runde. Dette betyr at spillet ikke var rettferdig og ikke rigget.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Siden systemet velger vinnersektor i begynnelsen av runden, og enhver spiller kan overvåke det er endring - vi har ingen mulighet til å påvirke resultatene av en raffle.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Du kan gjøre så mange innsatser du vil, uavhengig av hvor mye du legger inn.',

  'What bet limitations are there?': 'Hvilke innsatsbegrensninger er der?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'Minimum innsats er $0,1. På en farge, Kan du legge til en innsats på maks $5000 pe runde.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Vær oppmerksom! Du kan ikke legge inn innsats på rød og svart i samme runde, men innsats på grønn er tilgjengelig hver runde!',

  'When does the determination of the winner slot begin?': 'Når finner dere vinnerfargen?',
  'The winner slot is determined at the end of a round.':
    'Vinnerfargen blir funnet på slutten av runden',

  'Is there any commission in this mode?': 'Er det kommisjon i spillet?',
  'There is no commission at all. :)': 'Det er ingen kommisjon i det hele tatt. :)',
};
