import { Reducer } from 'redux';
import Immutable from 'immutable';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, debounceTime, map, pluck, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  IBidsResponse,
  IPurchasesRecord,
  MarketplaceActionTypes,
  purchaseActions,
  PurchaseActionTypes,
  PurchasesRecord,
} from '../interfaces';
import {
  BIDDING_STATUSES,
  OrderStatusEnum,
  WAITING_STATUSES,
} from '../configs/order-statuses.enum';
import { addNotification } from 'modules/Notifications/duck';
import { PurchaseRepository } from '../repositories';
import { AjaxError } from 'rxjs/ajax';

export const purchases: Reducer<Immutable.Record<IPurchasesRecord>, purchaseActions> = (
  state = new PurchasesRecord(),
  action
) => {
  switch (action.type) {
    case PurchaseActionTypes.FETCH_GET_BIDS_SUCCESS: {
      const { meta, kits } = action.payload;

      const { ids, entities, ...computed } = kits.reduce(
        (acc, item) => {
          const bidding = BIDDING_STATUSES.includes(item.status);
          const waiting = WAITING_STATUSES.includes(item.status);
          return {
            active: bidding || waiting ? acc.active + 1 : acc.active,
            wait: waiting ? acc.wait + 1 : acc.wait,
            cost: bidding || waiting ? acc.cost + item.price : acc.cost,
            ids:
              bidding || waiting
                ? [item.id.toString(), ...acc.ids]
                : [...acc.ids, item.id.toString()],
            entities: {
              ...acc.entities,
              [item.id]: item,
            },
          };
        },
        { active: 0, wait: 0, cost: 0, ids: [], entities: {} }
      );

      return state.set('meta', meta).set('computed', computed).set('kits', { ids, entities });
    }

    case PurchaseActionTypes.FETCH_CREATE_BID_SUCCESS: {
      const { id, ...rest } = action.payload;
      const updater = state
        .updateIn(['meta', 'amount'], amount => amount + 1)
        .updateIn(['kits', 'ids'], origin => [id.toString(), ...origin])
        .setIn(['kits', 'entities', id.toString()], { id, ...rest });

      return updater.update('computed', () => getComputed(state.get('kits')));
    }

    case MarketplaceActionTypes.MARKETPLACE_PARTICIPANT_UPDATE: {
      const { id, ...rest } = action.payload;
      const identifier = id.toString();
      const item = state?.getIn(['kits', 'entities', identifier]);

      if (!state.hasIn(['kits', 'entities', identifier])) {
        return state;
      }

      const updater = state.mergeIn(['kits', 'entities', identifier], {
        ...rest,
        status:
          (item.status === OrderStatusEnum.WAIT_FOR_TRADE &&
            rest.status === OrderStatusEnum.ORDER_CRATED) ||
          !rest.status
            ? item.status
            : rest.status,
      });

      return updater.update('computed', () => getComputed(state.get('kits')));
    }

    case PurchaseActionTypes.FETCH_DELETE_BID_SUCCESS: {
      const id = action.payload.id.toString();
      return state
        .updateIn(['meta', 'amount'], (amount: number) => amount - 1)
        .updateIn(['kits', 'ids'], (origin: string[]) => origin.filter((key: string) => key !== id))
        .deleteIn(['kits', 'entities', id]);
    }

    default: {
      return state;
    }
  }
};

const getComputed = ({ ids, entities }: IPurchasesRecord['kits']) =>
  ids.reduce(
    (acc: IPurchasesRecord['computed'], id: string) => {
      const bidding = BIDDING_STATUSES.includes(entities[id].status);
      const waiting = WAITING_STATUSES.includes(entities[id].status);
      return {
        active: bidding || waiting ? acc.active + 1 : acc.active,
        wait: waiting ? acc.wait + 1 : acc.wait,
        cost: bidding || waiting ? acc.cost + entities[id].price : acc.cost,
      };
    },
    { active: 0, wait: 0, cost: 0 }
  );

export const updateBids = (data: IBidsResponse) => ({
  type: PurchaseActionTypes.FETCH_GET_BIDS_SUCCESS,
  payload: data,
});

export const fetchCreateBid = (id: number) => ({
  type: PurchaseActionTypes.FETCH_CREATE_BID,
  payload: {
    id,
  },
});

const fetchCreateBidUpdate = (entity: any) => ({
  type: PurchaseActionTypes.FETCH_CREATE_BID_SUCCESS,
  payload: entity,
});

export const fetchDeleteBid = (id: number) => ({
  type: PurchaseActionTypes.FETCH_DELETE_BID,
  payload: {
    id,
  },
});

const fetchDeleteBidSuccess = (id: number) => ({
  type: PurchaseActionTypes.FETCH_DELETE_BID_SUCCESS,
  payload: {
    id,
  },
});

const createBid: Epic = action$ =>
  action$.pipe(
    ofType(PurchaseActionTypes.FETCH_CREATE_BID),
    debounceTime(500),
    pluck('payload', 'id'),
    switchMap(id =>
      PurchaseRepository.fetchCreateBid(id).pipe(
        switchMap(({ response }) =>
          of(
            fetchCreateBidUpdate({ ...response, isLastBidByUser: true }),
            addNotification({
              type: 'success',
              body: 'Your bid has been accepted',
            })
          )
        ),
        catchError((error: AjaxError) =>
          of(addNotification({ type: 'error', body: error.response.message }))
        )
      )
    )
  );

const deletePurchase: Epic = action$ =>
  action$.pipe(
    ofType(PurchaseActionTypes.FETCH_DELETE_BID),
    debounceTime(500),
    pluck('payload', 'id'),
    switchMap(id =>
      PurchaseRepository.fetchDeleteBid(id).pipe(
        map(() => fetchDeleteBidSuccess(id)),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

export const purchaseEpics = combineEpics(createBid, deletePurchase);
