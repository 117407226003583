import React, { createElement, FC, Fragment, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { SteamErrorsEnum } from 'core/User';
import { SteamTradeLinkStep } from './steam-trade-link-step';
import { PrivateInventoryStep } from './private-inventory-step';
import { SteamApiKeyStep } from './steam-api-key-step';
import { SettingUpStepperHeader } from '../setting-up-stepper-header';
import { SteamBindingStep } from './steam-buinding-step';
import { SteamGuardStep } from './steam-guard-step';

const FIELDS_MAP = new Map<SteamErrorsEnum, FC<any>>([
  [SteamErrorsEnum.UNBINDING_STEAM, SteamBindingStep],
  [SteamErrorsEnum.STEAM_GUARD, SteamGuardStep],
  [SteamErrorsEnum.PRIVATE_INVENTORY, PrivateInventoryStep],
  [SteamErrorsEnum.INVALID_TRADELINK, SteamTradeLinkStep],
  [SteamErrorsEnum.INVALID_API_KEY, SteamApiKeyStep],
]);

const FIELDS_ARRAY = Array.from(FIELDS_MAP.entries());

export const SettingUpStepperContent: FC<any> = field => {
  const stepsRef = useRef<HTMLDivElement>(null);
  const idx = useMemo(() => {
    return FIELDS_ARRAY.findIndex(([k]) => k === field.value);
  }, [field.value]);

  const maxHeight = useMemo(() => {
    return (stepsRef.current?.childNodes?.[idx * 2 + 1] as any)?.childNodes[0]?.offsetHeight ?? 700;
  }, [stepsRef, idx]);

  return (
    <>
      <SettingUpStepperHeader idx={idx} />
      <Container ref={stepsRef}>
        {FIELDS_ARRAY.map(([name, component]) => (
          <Fragment key={name}>
            <input {...field} type="radio" value={name} checked={field.value === name} />
            <div style={{ maxHeight }}>{createElement(component)}</div>
          </Fragment>
        ))}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100vw;
  background: var(--color-dark-600);
  position: relative;

  & > input {
    display: none;

    &:not(:checked) + div {
      transform: translate3d(-100%, 0, 1px);
    }
    &:checked ~ div {
      transform: translate3d(100%, 0, 1px);
    }

    &:checked + div {
      position: relative;
      transform: translate3d(0, 0, 1px);
    }
  }

  & > div {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(100%, 0, 1px);
    transition: all 300ms ease-in-out;
  }
`;
