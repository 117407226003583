import { ProfileFreezeTimeDialog } from './profile-freeze-time-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.PROFILE_FREEZE, {
  component: ProfileFreezeTimeDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
