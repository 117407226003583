export default {
  '100$ for the new players!': '¡100 $ para los nuevos jugadores!',
  '100$ bonus is credited to a demo balance':
    'El bono de $ 100 se acredita a un saldo de demostración',
  'Get 100$ now!': '¡Obtén 100 $ ahora!',

  'Top up your balance from 25$ and get bonuses':
    'Recargue su saldo desde 25 $ y obtenga bonificaciones',
  'Exp bonus': 'Bono de experiencia',
  'Wheel Of Fortune free spin': 'Rueda de la fortuna giro gratis',
  'Free case': 'Caso libre',
  'Access to account level-up': 'Acceso a la subida de nivel de la cuenta',
};
