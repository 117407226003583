export default {
  'Refill sum:': 'Сумма пополнения:',
  'Popular methods:': 'Популярные методы:',
  'Region methods:': 'Региональные методы:',
  'Select country:': 'Выберите страну:',
  'Last successful method:': 'Последний метод пополнения:',
  'Payment systems:': 'Платежные системы:',
  Commission: 'Комиссия ',
  'Refill via': 'Пополнение через',
  Promo: 'При пополнении от ◎15.4',
  'Input code:': 'Введите код:',
  Activate: 'Активировать',
  'Select the country of replenishment': 'Выберите страну пополнения',
  'Choose your country': 'Выберите вашу страну',
  'The available methods':
    'От страны пополнения может зависеть доступные способы и скорость пополнения баланса.',
  'This flag will be displayed in your profile':
    'Выбранный флаг будет отображаться в вашем профиле',
  'Confirm country': 'Подтвердить страну',

  //input placeholder
  'pay input placeholder': '',
  'pay input placeholder_phone': 'Введите номер телефона (без +)',
  'pay input placeholder_email': 'Введите ваш email',
  'pay input placeholder_wallet': '',

  'pay input label': '',
  'pay input label_phone': 'Введите номер телефона (без +)',
  'pay input label_email': 'Email',
  'pay input label_wallet': '',

  'Bonus:': 'Бонус: ',

  'Temporarily unavailable': 'Временно недоступно',
  'Unfortunately, this payment method is temporarily unavailable.':
    'К сожалению, данный метод оплаты временно недоступен.',

  'Please use other methods. We apologize for any inconvenience caused.':
    'Пожалуйста, воспользуйтесь другими методами. Приносим извинения за доставленные неудобства.',
  'Unfortunately, this payment method is temporarily unavailable. Please use other methods. We apologize for any inconvenience caused.':
    'К сожалению, данный метод оплаты временно недоступен. Пожалуйста, воспользуйтесь другими методами. Приносим извинения за доставленные неудобства.',
  'Step 1 Buy a card from our partners:': 'Шаг 1. Купите карту у наших партнёров:',
  'Step 2 Use the code from the purchased card':
    'Шаг 2. Используйте код пополнения с купленной карты',
  bonus: 'ВАМ БОНУС',
  topUp: 'к пополнению баланса',
  receive: 'Вы получите',
  name: 'Имя:',
  surname: 'Фамилия:',
  required: 'Обязательное поле',
  phone: 'Номер телефона',
  cpf: 'Номер CPF',
  cpfFormatError: 'CPF введен неверно',
  'Via SIH account': 'На SIH аккаунт',
  'Refill via SIH': 'Пополнение через SIH',
};
