import { useSelector } from 'react-redux';
import { getBaccaratBet, getBaccaratMembers, getBaccaratTotalAmounts } from '../selectors';
import { BoardTypes } from '../interfaces';

export const useGameBoard = (type: BoardTypes, protectType: BoardTypes) => ({
  members: useSelector(getBaccaratMembers(type)),
  bet: useSelector(getBaccaratBet(type)),
  opposite: useSelector(getBaccaratBet(protectType)),
  totals: useSelector(getBaccaratTotalAmounts),
});
