import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../selectors';
import { fetchUpdateSteamLink } from '../ducks/duck';

export const useUserTradeLinks = () => {
  const tradeLinks = useSelector(
    (state: any) => getUserDetails<'links'>(state, 'links'),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleSaveSteamLink = useCallback(
    (link: string) => {
      dispatch(fetchUpdateSteamLink(link));
    },
    [dispatch]
  );

  // const handleSaveVgoLink = useCallback(
  //   (link: string) => () => {
  //     dispatch(fetchUpdateVgoLink(link))
  //   },
  //   [dispatch]
  // )

  return {
    tradeLinks,
    handleSaveSteamLink,
    // handleSaveVgoLink,
  };
};
