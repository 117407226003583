import * as React from 'react';
import { NavigationLink } from '@components/Common';
import { useTranslation } from 'react-i18next';
// import { LeadersNavigationLink } from './leaders-navigation-link'

interface IDrawerNavigation {
  handlePushTo: (path: string) => () => void;
  handleOpenDesktop: () => void;
}

export const DrawerNavigation: React.FC<IDrawerNavigation> = React.memo(
  ({ handlePushTo, handleOpenDesktop }) => {
    const { t } = useTranslation();
    return (
      <>
        <NavigationLink onClick={handlePushTo('/')}>
          <div className="leading">
            <i className="icon-lobby" />
          </div>
          <div>
            <span>{t('/')}</span>
          </div>
        </NavigationLink>
        {/* <LeadersNavigationLink /> */}
        <NavigationLink onClick={handlePushTo('/inventory')}>
          <div className="leading">
            <i className="icon-bonus-case" />
          </div>
          <div>
            <span>{t('/inventory')}</span>
          </div>
        </NavigationLink>
        {/* <NavigationLink onClick={handlePushTo('/faucet')}>
          <div className="leading">
            <i className="icon-faucet" />
          </div>
          <div>
            <span>{t('/faucet')}</span>
          </div>
        </NavigationLink> */}
        <NavigationLink onClick={handlePushTo('/assistance')}>
          <div className="leading">
            <i className="icon-assistance" />
          </div>
          <div>
            <span>{t('/assistance')}</span>
          </div>
        </NavigationLink>
        <NavigationLink onClick={handlePushTo('/market')}>
          <div className="leading">
            <i className="icon-marketplace" />
          </div>
          <div>
            <span>{t('/marketplace')}</span>
          </div>
        </NavigationLink>
        <NavigationLink onClick={handleOpenDesktop}>
          <div className="leading">
            <i className="icon-sait" />
          </div>
          <div>
            <span>{t('PC version')}</span>
          </div>
        </NavigationLink>
      </>
    );
  }
);
