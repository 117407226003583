export default {
  'Fair game': 'Rättvist spel',
  Bet: 'Satsa',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = inaktiverad)',
  'Bet coins': 'Satsa mynt',
  Stop: 'Sluta',
  'Wait for next round...': 'Invänta nästa omgång...',
  Example: 'Exempel',
  'You won': 'Du vann',
};
