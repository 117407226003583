import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyService } from 'services/currency';
import {
  Container,
  ContainerRange,
  HeaderSlider,
  InputContainer,
  RangeSliderContainer,
  Span,
  Title,
} from './price-range.styles';
import { ButtonContainer, CancelButton, Header, HeaderContainer } from '../general.styles';
import { RangeSlider } from '@UIkit/form-controls/range-slider/range-slider';
import { Button } from '@components/Common';

interface PriceRangeConfig {
  minPrice: number;
  maxPrice: number | null;
  text: string;
}
export interface Range {
  minPrice: number;
  maxPrice: number;
}
interface PriceRangeProps {
  selected: string;
  i18nextKey?: string;
  range: Range;
  minValue: number;
  maxValue: number;
  changeHandler: (range: Range) => void;
  handleCloseDialog: () => void;
  setData: (text: string, minPrice: number, maxPrice: number) => void;
  onSubmit: () => void;
}

const priceRangeConfig: Array<PriceRangeConfig> = [
  {
    minPrice: 20,
    maxPrice: 100,
    text: `${currencyService.display(20)} — ${currencyService.display(100)}`,
  },
  {
    minPrice: 100,
    maxPrice: 500,
    text: `${currencyService.display(100)} — ${currencyService.display(500)}`,
  },
  {
    minPrice: 500,
    maxPrice: 2500,
    text: `${currencyService.display(500)} — ${currencyService.display(2500)}`,
  },
  {
    minPrice: 2500,
    maxPrice: 10000,
    text: `${currencyService.display(2500)} — ${currencyService.display(10000)}`,
  },
  {
    minPrice: 10000,
    maxPrice: 22000,
    text: `> ${currencyService.display(10000)}`,
  },
];

export const PriceRange: React.FC<PriceRangeProps> = ({
  selected,
  i18nextKey,
  handleCloseDialog,
  setData,
  range,
  maxValue,
  minValue,
  changeHandler,
  onSubmit,
}) => {
  const { t } = useTranslation(i18nextKey);

  return (
    <Container>
      <HeaderContainer>
        <Header>{t('priceRange.label')}</Header>
        <CancelButton onClick={handleCloseDialog}>{t('Close')}</CancelButton>
      </HeaderContainer>
      <ContainerRange>
        {priceRangeConfig.map(({ text, minPrice, maxPrice }) => (
          <InputContainer
            data-is-active={selected === text ? 'selected' : 'notSelected'}
            data-disabled={
              minPrice >= maxValue || minValue >= maxPrice ? 'disabled' : 'notDisabled'
            }
            key={text}
            onClick={() => setData(text as string, minPrice as number, maxPrice as number)}
          >
            <Span>{text}</Span>
          </InputContainer>
        ))}
        <RangeSliderContainer>
          <HeaderSlider>
            <Title>
              {t('priceRange.setManually')}
              <div>
                {range.minPrice < range.maxPrice ? (
                  <>
                    <span>{currencyService.display(range.minPrice)}</span>
                    {' - '}
                    <span>{currencyService.display(range.maxPrice)}</span>
                  </>
                ) : (
                  <>
                    <span>{currencyService.display(range.maxPrice)}</span>
                    {' - '}
                    <span>{currencyService.display(range.minPrice)}</span>
                  </>
                )}
              </div>
            </Title>
          </HeaderSlider>
          <RangeSlider
            minPrice={minValue}
            maxPrice={maxValue}
            step={10}
            value={{ ...range }}
            onChange={changeHandler}
          />
        </RangeSliderContainer>
      </ContainerRange>
      <ButtonContainer>
        <Button className="primary" onClick={onSubmit}>
          {t('priceRange.button')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};
