export default {
  Games: 'เกม',
  'Refill balance': 'การเติมเงิน',
  Settings: 'ตั่งค่า',
  Store: 'ร้านค้า',
  'Get free money': 'รับ',
  Notifications: 'การแจ้งเตือน',
  FAQ: 'FAQ',
  'Log out': 'ออก',
  'Steam trade link': 'ลิงค์เพื่อแลกเปลี่ยนใน Steam',
  'Where can I get a link?': 'สามารทเอาลิงก์ได้ที่ไหน',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'คุณได้ขาดการเชื่อมต่อจากบริการ CSGOFAST กรุณาแตะที่หน้าจอเพื่อเชื่อมต่ออีกครั้ง',
  'App was offline. Check your internet connection to continue!':
    'แอปกำลังออฟไลน์ ตรวจสอบการเชื่อมต่ออินเตอร์เน็ตของคุณเพื่อใช้งานต่อ!',
  SAVE: 'SAVE',
};
