import { combineReducers } from 'redux';
import {
  eventGeneralQuestInfoReducer,
  motivatorEventReducer,
  eventCategoryReducer,
  rareCaseInfoReducer,
} from './default-event-duck/default-event.duck';
import {
  leaderRaceBalanceReducer,
  leaderRaceQuestsReducer,
  casesLRReducer,
} from './leader-race-duck';
import { leaderRaceInfoReducer } from './event-results-duck';

export const reducer = combineReducers({
  eventGeneralQuestInfoReducer,
  motivatorEventReducer,
  eventCategoryReducer,
  rareCaseInfoReducer,
  leaderRaceBalanceReducer,
  leaderRaceQuestsReducer,
  casesLRReducer,
  leaderRaceInfoReducer,
});
