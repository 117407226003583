import { SettingsSounds } from 'modules/PersonalArea/components/Settings/Sounds/assets';
import { soundMap } from '../core/utils/sound.utils';
import { soundDefaultStatus } from 'core/AppShell/app-shell.dictionary';

let instance = null;

HTMLAudioElement.prototype.stop = function () {
  this.pause();
  this.currentTime = 0.0;
};

export default class SoundService {
  constructor() {
    if (instance !== null) {
      return instance;
    }
    this.soundDictionary = {};
    this.soundMap = SettingsSounds;
    this.soundMapOld = soundMap;
    this.soundSettings = JSON.parse(localStorage.getItem('@csgofast:sound') || 'null');
    this.soundsState = {};
    this.activeSound = {
      name: '',
      sound: null,
    };

    this.loadSounds();
    instance = this;
  }

  loadSounds(data = this.soundSettings) {
    if (data) {
      const commonSounds = data.commonSounds;
      const gameSounds = data.gameSounds;
      const plainSounds = {
        ...gameSounds?.options,
        commonSounds,
        gameSounds: {
          enabled: data.gameSounds?.enabled,
        },
      };

      function getAllOptions(options) {
        const result = {};

        function traverseOptions(obj, path = '') {
          for (const key in obj) {
            const newPath = path !== '' ? `${path}.${key}` : key;
            if (typeof obj[key] === 'boolean') {
              const category = newPath.split('.')[0].toUpperCase();
              result[category] = result[category] || {};
              result[category][key] = obj[key];
              continue;
            }
            traverseOptions(obj[key], newPath);
          }
        }

        traverseOptions(options);
        return result;
      }

      this.soundsState = getAllOptions(plainSounds);

      const newSounds = Object.keys(this.soundMap).reduce(
        (dictionary, category) => ({
          ...dictionary,
          [category]: Object.keys(this.soundMap[category]).reduce((object, sound) => {
            const convertedSounds = sounds => {
              if (Array.isArray(sounds)) {
                return sounds.map(sound => {
                  const audio = new Audio(sound);
                  audio.preload = 'metadata';
                  return audio;
                });
              } else {
                const audio = new Audio(this.soundMap[category][sound]);
                audio.preload = 'metadata';
                return audio;
              }
            };

            return {
              ...object,
              [sound]: {
                track: convertedSounds(this.soundMap[category][sound]),
                enabled: this.soundsState?.[category.toUpperCase()]?.[sound],
              },
            };
          }, {}),
        }),
        {}
      );

      const oldSounds = Object.keys(this.soundMapOld).reduce(
        (dictionary, category) => ({
          ...dictionary,
          [category]: Object.keys(this.soundMapOld[category]).reduce((object, sound) => {
            const audio = new Audio(this.soundMapOld[category][sound]);
            audio.preload = 'metadata';

            const enabledAudio = () => {
              if (this.soundsState?.[category.toUpperCase()]) {
                return this.soundsState?.[category.toUpperCase()].enabled;
              } else {
                return this.soundsState.GAMESOUNDS?.enabled;
              }
            };

            return {
              ...object,
              [sound]: {
                track: audio,
                enabled: enabledAudio(),
              },
            };
          }, {}),
        }),
        {}
      );

      this.soundDictionary = { ...newSounds, ...oldSounds };
    }
  }

  play(category, name, options = {}) {
    const soundName = name.split('#')[0];
    const soundNumber = name.split('#')[1];

    let promise;

    if (!Object.keys(this.soundDictionary).length) {
      this.loadSounds(soundDefaultStatus);
    }

    if (!this.soundDictionary?.[category]?.[soundName]?.enabled && !options?.isSettings) return;
    const track = this.soundDictionary[category][soundName].track;

    const getSound = () => {
      if (soundNumber) {
        return track[soundNumber];
      } else if (Array.isArray(track)) {
        return track[Math.floor(Math.random() * track.length)];
      } else {
        return track;
      }
    };

    const sound = getSound();

    this.activeSound = {
      name: soundName,
      sound,
    };

    if (options.hasOwnProperty('from') && typeof options.from === 'number') {
      sound.currentTime = options.from;
    }

    if (options.hasOwnProperty('volume')) {
      sound.volume = options.volume;
    }

    if (options.hasOwnProperty('repeat') && options.repeat === true) {
      sound.loop = true;
      if (options.hasOwnProperty('currentTime')) {
        sound.currentTime = options.currentTime;
      }
    }

    if (options.hasOwnProperty('repeat') && options.repeat === false) {
      sound.loop = false;
      sound.stop();

      if (this.activeSound.sound) {
        this.activeSound.sound.stop();
      }
      this.activeSound = {
        name: '',
        sound: null,
      };
      return;
    }

    if (options.hasOwnProperty('stop') && options.stop === true) {
      if (this.activeSound.sound) {
        this.activeSound.sound.stop();
      }
      this.activeSound = {
        name: '',
        sound: null,
      };
    }

    promise = sound.play();
    if (promise !== undefined) {
      promise
        .catch(error => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  }

  toggleSound(enabled) {
    Object.keys(this.soundDictionary).forEach(category => {
      Object.keys(this.soundDictionary[category]).forEach(sound => {
        if (Array.isArray(this.soundDictionary[category][sound].track)) {
          return this.soundDictionary[category][sound].track.forEach(
            track => (track.volume = enabled)
          );
        } else {
          return (this.soundDictionary[category][sound].track.volume = enabled);
        }
      });
    });
  }

  getDuration() {
    if (this.activeSound.sound) {
      return this.activeSound.sound.duration * 1000;
    }
  }

  stop({ name = '', close = false }) {
    const soundName = name.split('#')[0];

    if (this.activeSound.sound === soundName || close) {
      if (this.activeSound.sound) {
        this.activeSound.sound.stop();
      }
      this.activeSound = {
        name: '',
        sound: null,
      };
    }
  }
}
