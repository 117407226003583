import React, { FC, useEffect, memo } from 'react';
import {
  useGetCaseBattleGameData,
  useGetCaseBattleSoundType,
  useGetRouletteAnimationStatuses,
} from 'games/CaseBattle/hooks';
import { Header } from './components';
import { CasesLine } from './components/CasesLine';
import { Container } from './CaseBattleGame.styles';
import { PlayersItems } from './components/PlayersItems';
import { TotalPriceCounter } from './components/TotalPriceCounter';
import { Players } from './components/Players';
import { Participants } from './components/Participants';
import { useGetSounds } from 'games/CaseGame/hooks';
import { RouletteStatuses } from 'games/CaseBattle/constants';

interface CaseBattleGameProps {
  isUpIcons: boolean;
}

export const CaseBattleGame: FC<CaseBattleGameProps> = memo(({ isUpIcons }) => {
  const {
    data: { game, currentRound, gameStatus },
  } = useGetCaseBattleGameData();
  const { cases, players_in_team } = game;
  const { linesStatus } = useGetRouletteAnimationStatuses();
  const { soundType } = useGetCaseBattleSoundType();
  const { play, playEnd, stop, playStart } = useGetSounds(soundType, 'fast');

  useEffect(() => {
    if (linesStatus === RouletteStatuses.roll) {
      play();
      playStart();
    }
    if (linesStatus === RouletteStatuses.afterRoll) {
      stop();
      playEnd();
    }

    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linesStatus]);

  return (
    <Container>
      <Header />
      <CasesLine casesIds={cases} round={currentRound} status={gameStatus} />
      {players_in_team > 1 && <TotalPriceCounter />}
      <Participants />
      <Players />
      <PlayersItems isUpIcons={isUpIcons} />
    </Container>
  );
});
