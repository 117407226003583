import React from 'react';
import { HistoryTabs } from './history-tabs';
import { HistoryList } from './history-list';

export const SlotGameHistory = () => {
  return (
    <>
      <HistoryTabs>{({ sortBy }: any) => <HistoryList sortBy={sortBy} />}</HistoryTabs>
    </>
  );
};
