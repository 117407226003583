/* eslint-disable no-template-curly-in-string */
export default {
  // game
  'Stats for the last {{count}} rounds': 'Stats for the last {{count}} rounds',
  'amount on type': '${currency(amount)} on $t({{type}})',
  'Stats for': 'Stats for',
  'Make your bet': 'Make your bet',

  //sings
  hi: 'HI',
  lo: 'LO',
  colorRed: 'RED',
  colorBlack: 'BLACK',
  twoToNine: '2-9',
  jackToAce: 'JQKA',
  kingToAce: 'KA',
  ace: 'A',
  joker: 'J',
  // factors
  //rules
  'In this mode, the card is opened repeatedly. You bet on the appearance of the next card. You can make bets on the following parameters:':
    'In this mode, the card is opened repeatedly. You bet on the appearance of the next card. You can make bets on the following parameters:',
  'Next card will be higher or lower by seniority.':
    'Next card will be higher or lower by seniority.',
  'In this category, all bets are summed up and coefficients are calculated simultaneously. Winners will get the sum of their bet multiplied by the result coefficient. You can bet on one of the options. When the joker falls out, next card will be compared to the one that fell before the joker.':
    'In this category, all bets are summed up and coefficients are calculated simultaneously. Winners will get the sum of their bet multiplied by the result coefficient. You can bet on one of the options. When the joker falls out, next card will be compared to the one that fell before the joker.',
  'Color of the next card.': 'Color of the next card.',
  'In this category, if you win, your bet doubles.':
    'In this category, if you win, your bet doubles.',
  'The precedence of the next card.': 'The precedence of the next card.',
  "In this category, if you win, your bet will increase by a multiplier of the card's seniority (from 2 to 9 – x1.5, from jack to ace – x3, from king to ace – x6b only ace – x12.) If you guessed the appearance of a joker, your bet will multiply by 24 ). You can put on each of the 5 options.":
    "In this category, if you win, your bet will increase by a multiplier of the card's seniority (from 2 to 9 – x1.5, from jack to ace – x3, from king to ace – x6b only ace – x12.) If you guessed the appearance of a joker, your bet will multiply by 24 ). You can put on each of the 5 options.",
  'Max bet has been reached': 'Max bet has been reached',
};
