import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-gray-700);
  border-radius: var(--size-radius-m);
  color: var(--color-white);
  display: grid;
  grid-auto-flow: row;

  & .modal-title {
    font-size: 1.2rem;
    text-align: center;
  }

  & .modal-description {
    display: block;
    font-size: 0.85rem;
    text-align: center;

    &_error {
      color: var(--red);
      height: 1rem;
    }
  }

  & .modal-header {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    letter-spacing: 0.29px;
    padding: 2rem 1rem 1rem;
  }

  & .modal-body {
    border-bottom: solid 1px var(--color-dark-300);
    border-top: solid 1px var(--color-dark-300);
    padding: 1.4rem 0;
    position: relative;
    text-align: center;

    & > a {
      color: var(--teal-100);
      font-size: 0.9rem;
      font-weight: bold;
      letter-spacing: 0.29px;
    }

    & .nickname-copy {
      color: var(--color-white);
      font-size: 1.25rem;
      line-height: 1.25rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & .modal-footer {
    display: grid;
    grid-gap: 1.4rem;
    padding: 1.4rem 1rem;

    & > * {
      justify-self: center;
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  justify-self: auto !important;
  margin: 0.5rem 0 1rem;
  position: relative;

  & input {
    background-color: var(--color-white);
    border-radius: var(--size-radius-m);
    border: 0;
    color: var(--color-dark-600);
    font-size: 1.5rem;
    font-weight: 500;
    height: 50px;
    padding: 0;
    text-align: center;
    width: 14%;

    &:nth-child(3) {
      margin-right: 1.5rem;
    }
  }

  &::before {
    content: '-';
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
