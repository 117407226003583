import * as React from 'react';
import styled from 'styled-components';
import { selectDoubleWinningColor } from 'core/utils/colors.utils';

interface IDoubleField {
  sector: number;
}

export const DoubleField: React.FC<IDoubleField> = ({ sector }) => (
  <Circle sector={sector}>{sector}</Circle>
);

interface ICircleStyle {
  sector: number;
}

const Circle = styled.div<ICircleStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${p => selectDoubleWinningColor(p.sector)};

  font-size: 0.9rem;
  font-weight: bold;
  color: var(--color-white);
`;
