import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { skinItemImage } from 'core/utils';
import { Category, Container, ImageWrap, Info, OpenWrap, Replay } from './category-item.styles';
import { EventIcons } from 'modules/event-module/comics-event/assets';

interface CategoryItemCompletedProps {
  categoryName: string;
  handler: () => void;
  itemImgPath?: string;
}

export const CategoryItemCompleted: FC<CategoryItemCompletedProps> = ({
  categoryName,
  handler,
  itemImgPath,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ImageWrap>
        <img src={skinItemImage(itemImgPath)} alt="prize" />
      </ImageWrap>
      <Info>
        <Category>{categoryName}</Category>
        <OpenWrap onClick={handler}>
          <Replay>{t('replay')}</Replay>
          <EventIcons.Replay />
        </OpenWrap>
      </Info>
    </Container>
  );
};
