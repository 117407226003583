import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const payoutChart = Array.from({ length: 20 }, (_, i) => (i < 9 ? `0${i + 1}` : `${i + 1}`));

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <Container>
        <RulesP>
          <RulesSpan>{t('Pick a team')}</RulesSpan>
          {t('Before the game begins choose your team: Terrorists or Counter-Terrorists.')}
          <br />
          {t('Symbols that belong to your team will earn you more winnings!')}
          <br />
          {t(
            'You can change your team at any time by clicking the soldier on the left side of the screen.'
          )}
          <br />

          <br />
          <img src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/boiz.png" alt="example" />
          <br />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Scatter Symbols and Rounds')}</RulesSpan>
          <RulesSpan>{t('Scatter')}</RulesSpan>
          {t(
            'Scatter Symbols of your and enemy teams will show up on the screen. Watch them closely, they can influence the game in major ways!'
          )}

          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/T_CT_element.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Victory')}</RulesSpan>

          {t(
            'Three Scatters of your team appearing anywhere on the screen win the round, you get Loss Bonus and an opportunity to open a Crate with an additional reward inside.'
          )}
          <br />
          <img src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/tctwin.png" alt="example" />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Crate opening')}</RulesSpan>
          {t(
            'All symbols on the screen that have reward value get placed in the crate, along with Jackpot Symbol (constitutes the sum of all rewards * 10). Opening the crate wins you one of those rewards.'
          )}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/case_black_open_00049.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Free Spins')}</RulesSpan>
          {t(
            "Three consecutive round victories grant you 30 Free Spins. During Free Spins Scatter Elements don't appear on the screen, thus increasing Free Spins win rate."
          )}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/round_board.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Round Loss')}</RulesSpan>
          {t(
            "Three enemy Scatter Symbols mean that you lose the round. But don't worry! Every lost round increases your Loss Bonus, that gets paid to you after round win or a draw."
          )}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/loss_bonus_zoom.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Round Draw')}</RulesSpan>
          {t(
            'Three Scatter Symbols of both teams on the screen mean that the round is a draw. Congrats, you get your Loss Bonus.'
          )}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/rounddraw.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Grenades')}</RulesSpan>
          <RulesSpan>{t('Assault (neutral)')}</RulesSpan>
          {t('Destroys all non-allied symbols on the screen, new symbols drop from above.')}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/expl_m67.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Flashbang (neutral)')}</RulesSpan>
          {t('All enemy symbols switch team, the flashbang itself turns into a Flash Wild.')}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/expl_flash.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Molotov/Incendiary')}</RulesSpan>
          {t(
            "Only activates if you're on a Terrorist or Counter-Terrorist team respectively. Horizontally and vertically adjacent symbols get replaced with Burning Wilds."
          )}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/expl_incend.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Special Symbols')}</RulesSpan>
          {t('Burning Wild, Flash Wild')}
          <br />
          {t('Substitutes for the most beneficial for the player symbol in that position.')}
          <br />
          <img
            src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/Wild_Flash_059.png"
            alt="example"
          />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Symbol Payout Chart')}</RulesSpan>
          {payoutChart.map((item: string) => (
            <img
              key={item}
              src={`//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/rewards/rewards_${item}.png`}
              alt="example"
            />
          ))}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Bet Lines')}</RulesSpan>
          {t(
            'Three or more matching symbols on one or several of the lines win. Winning combinations must start at the leftmost reel.'
          )}
          <br />
          <img src="//d2lomvz2jrw9ac.cloudfront.net/poggi-assets/images/lines.png" alt="example" />
        </RulesP>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding: 16px;
  line-height: 1.4;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 14px;

  & > img {
    margin: 5px 0;
    /* max-width: 75%; */
  }
`;

const RulesSpan = styled.span`
  color: var(--gray-100);
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;
