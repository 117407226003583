import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetOrder } from '../../ducks/order.duck';

export const useResetOrder = () => {
  const dispatch = useDispatch();

  const handleResetOrder = useCallback(() => {
    dispatch(resetOrder());
  }, [dispatch]);

  return {
    handleResetOrder,
  };
};
