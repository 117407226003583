import styled from 'styled-components';

export const Container = styled.div<{ isToolBarOpen: boolean }>`
  display: flex;
  gap: 8px;
  color: var(--color-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  letter-spacing: 0.1px;
  margin: 0 0 20px 16px;
  align-items: center;
  ${({ isToolBarOpen }) => !isToolBarOpen && 'margin-top:20px;'}
  transition: margin-top 0.3s ease;

  @media screen and (min-width: 500px) {
    margin: 0 auto 20px;
    ${({ isToolBarOpen }) => !isToolBarOpen && 'margin-top:20px;'}
    width: 500px;
  }
`;
