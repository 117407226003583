import { IItemMember } from './interfaces/reducer.leaders.interfaces';

export const colorScheme: Record<string, string> = {
  green: 'var(--regular-race)',
  blue: 'var(--fast-race)',
  orange: 'var(--great-race)',
};

export const couponType: Record<string, string> = {
  green: 'regular',
  blue: 'fast',
  orange: 'great',
};

export const colorByTypes: Record<string, string> = {
  'two-week': 'regular',
  'two-month': 'fast',
  'one-year': 'great',
};

export const generateLent = (
  members: { [k: string]: IItemMember },
  feed: number[] = []
): string[] => {
  return feed.reduce((acc: any[], key: number) => [...acc, members[key]], []);
};

export const ROULETTE_ANIMATION = 11000;
export const QUEUE_ANIMATION = 4000;

export const FULL_ANIMATION = ROULETTE_ANIMATION + QUEUE_ANIMATION;
