export default {
  Language: 'Språk',
  'Bets in current room': 'Bets in current room',
  Timers: 'Tid',
  'Game start': 'Runden starter',
  'Game ending': 'Runden avsluttes',
  'Roulette ticking': 'Roulette ticking',
  'Roulette start': 'Rulett starter',
  'Item set': 'Item set',
  'My bet': 'Mitt innskudd',
  'Coins transfer': 'Mynter overført',
  'Start button': 'Start knapp',
  'Stop button': 'Stop knapp',
  Countdown: 'Nedtelling',
  Soundtrack: 'Soundtrack',
  Crash: 'Kræsj',
  'Picking item': 'Velg skin',
  Crafting: 'Crafter',
  Failure: 'Tap',
  Win: 'Vinne',
  Sell: 'Selg for',
  Take: 'Ta',
  'Rooms bets': 'Rom innsats',
  'Chat message sending': 'chat melding sender',
  'Chat message receiving': 'Chat melding mottatt',
  'Chat sound': 'Chat sound',
  'Game sounds': 'Game sounds',
  Volume: 'Volume',
  steamApi: {
    noKey: 'INGEN STEAM API-NØKKEL',
    need: 'Nødvendig for å selge og handle skins med andre spillere',
    addLink: 'LEGG TIL LENKE',
    link: 'HVOR FÅR JEG DET FRA?',
  },
};
