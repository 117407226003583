export default {
  'What is the Ticket System?': 'Vad är biljettsystemet?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'När spelare placerar insatser får de virtuella biljetter och en av dem vinner. Om ditt föremål är värt $1 får du 100 biljetter, men om du sätter in ett skin som är värt $10 får du 1000 biljetter osv.',

  // GAMES

  'How is the winner determined?': 'Hur utses vinnaren?',
  'When is the winner determined?': 'När utses vinnaren?',
  'How many bets can I place per one round?': 'Hur många insatser kan placeras per runda?',
  'What Service Commission is there in this game?': 'Vilka är serviceavgifterna i det här spelet?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'spelet för de som gillar att spela med höga insatser. De som vill pröva lyckan placerar en insats på en allmän insättning som lottas ut bland spelarna i slutet av rundan.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'Vinnaren utses av Biljettsystemet i Classic Game.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Vinnaren utses i slutet av rundan eller så fort det maximala antalet skins har uppnåtts i insättningen.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'Serviceavgiften är mellan 0 och 10%, beroende på vinstsumman.\nSlumpmässiga skins från den allmänna insättningen tas som serviceavgift.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'ett fantastiskt sätt att omedelbart pröva lyckan. Detta är enbart för 3 spelare och den maximala insatsen är begränsad. Vinnaren får den allmänna insättningen från de 3 spelarna.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Vinnaren utses slumpmässigt men ju högre insatsen är desto större chans att vinna.',
  'One player can place one bet per one game round in this game.':
    'Varje spelare kan placera en insats per runda.',
  'Where can I see my chances of the winning?': 'Var kan jag se mina vinstchanser?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Vinstchanserna visas i procent. Du kan se procenten av dina chanser bredvid spelarens insatssumma för rundan (till höger om din avatar och en lista över insatta skins)',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Vinnaren utses så fort 3 spelare placerar sina insatser under aktuell runda.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'Serviceavgiften är mellan 0 och 15%, beroende på vinstsumman. Slumpmässiga skins från den allmänna insättningen från rundan tas som serviceavgift.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'ett spelläge där spelare satsar på svarta, röda eller gröna fack. I det här spelet placeras snabba poäng som insatser. Vinnarfacket avgörs i slutet av rundan.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Om vinnaren är svart eller rött fack dubblas spelarens insats.',
  'The green slot returns the placed bet multiplied by 14.':
    'Det gröna facket multiplicerar den placerade insatsen med 14.',
  'The bet sum of every slot can be different.': 'Insatssumman på varje fack kan vara olika.',

  'How can I Get Fast Coins?': 'Hur får jag snabba poäng?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Delta i hänvisningsprogrammet. Bjud in nya spelare till projektet med hjälp av hänvisningslänkar eller kod. Du får poäng för varje hänvisning och varje spel som han eller hon spelar.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Byt ut skins som är värda $1 mot snabba poäng. Du får en poäng för varje cent av skin-värdet. Du kan maximalt skicka 30 föremål åt gången. Klicka här för mer information om utbyte. Vi rekommenderar att du använder Steam Inventory Helper för att uppskatta värdet.',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Fyll på bonussaldot genom ett betalningssystem. Välj en bekväm betalningsmetod och få så många snabba poäng som behövs på ditt konto.',

  'How is the winner slot determined (fair game)?': 'Hur utses vinnarfacket (rättvist spel)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'I början av varje runda genereras ett slumpmässigt långt nummer från 0 till 1 (t.ex. 0. 223088) som krypteras genom sha224-algoritmen. Resultatet visas i början av rundan. I slutet av rundan multipliceras det krypterade numret med 15 vilket utser vinstfacket.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'ПEtt exempel: I början av rundan är det kodade numret 0.223088232334703230728. Detta multiplicerar med 15 vilket genererar numret 3.34632348495. Som ett resultat är beräkningsnumret 3 (det röda facket). I den här rundan är vinnarna de som placerat insatserna på ett rött fack.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Du kan på egen hand kontrollera om ett vinnarfack är rättvist eller inte. I slutet av rundan tar du det kodade numret och krypterar det en gång till med hjälp av en onlinetjänst, t.ex. http://sha224.net. Du kommer att få samma värde som i början av rundan. Detta innebär att spelet inte är riggat.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Eftersom systemet väljer den vinnande sektorn i början av rundan, och alla spelare kan övervaka hur den ändras, kan vi inte påverka lottningsresultatet.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Du kan förbättra dina chanser ett obegränsat antal gånger tills början av rundan. Insatssumman på varje fack kan vara olika.',

  'What bet limitations are there?': 'Vilka är insatsgränserna?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'Minsta insats är $0,1. På en färg kan du lägga maximalt $5000 vid ett tillfälle.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Obs! Under en dragning kan du inte satsa på rött och svart på samma gång. Insatser på grönt är tillgängliga på alla rundor!',

  'When does the determination of the winner slot begin?': 'När avgörs vinnarfacket?',
  'The winner slot is determined at the end of a round.': 'Vinnarfacket avgörs i slutet av rundan.',

  'Is there any commission in this mode?': 'Finns det någon avgift för det här läget?',
  'There is no commission at all. :)': 'Det finns inga avgifter alls. :)',
};
