import { IFetchCreateBidRequest } from './reducer.double.interfaces';

export enum DoubleActionsTypes {
  ATTACH_LISTENERS = 'app/double/ATTACH_LISTENERS',
  DETACH_LISTENERS = 'app/double/DETACH_LISTENERS',

  UPDATE_GAME_STATE = 'app/double/UPDATE_GAME_STATE',

  FETCH_CREATE_PARTICIPATE = 'app/double/FETCH_CREATE_PARTICIPATE',
}

export interface IFetchCreateBid {
  type: DoubleActionsTypes.FETCH_CREATE_PARTICIPATE;
  payload: IFetchCreateBidRequest;
}
