import React from 'react';
import styled from 'styled-components';
// import { TabBar } from '@UIkit'
// import { useForm } from 'react-hook-form'
import { ReferralCampaignsTabContent } from './referral-campaigns-tab-content';
import { useTranslation } from 'react-i18next';

// const TABS = ['active', 'deleted']

export const ReferralCampaignsTabs = () => {
  const { t } = useTranslation();
  // const { register, watch } = useForm({
  //   mode: 'onChange',
  //   defaultValues: {
  //     mode: 'active',
  //   },
  // })

  // const mode = watch('mode')

  return (
    <Container>
      <div className="tab-title">{t('My promotions ( My advertising campaigns)')}</div>
      {/*<TabBar>*/}
      {/*  {TABS.map((type: string) => (*/}
      {/*    <label key={type} className={`tab-item ${mode === type && 'active'}`}>*/}
      {/*      {t('campaign-tab-bar.status', { context: type })}*/}
      {/*      <input type="radio" name="mode" ref={register} value={type} />*/}
      {/*    </label>*/}
      {/*  ))}*/}
      {/*</TabBar>*/}
      <ReferralCampaignsTabContent />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;

  & .tab-title {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: var(--color-gray-200);
    text-transform: uppercase;
    padding: 0 1rem;
  }

  & > div:nth-child(2) {
    min-height: 3rem;
    align-content: center;
  }

  & input[type='radio'] {
    display: none;
  }
`;
