import { useSelector, shallowEqual } from 'react-redux';
import { getPayConfig } from '../selectors';
import { getMobileOperatingSystem } from 'core/utils';
import { useMemo } from 'react';

export const useConfigMethods = () => {
  const { regional, systems, popular } = useSelector(getPayConfig, shallowEqual);

  const OSType = getMobileOperatingSystem();

  const regionalUpdatedPayments = regional?.filter(
    value => value.title !== 'Apple Pay' && value.title !== 'Google Pay'
  );

  const appleAndGooglePayments = regional
    ?.filter(value => value.title === 'Apple Pay' || value.title === 'Google Pay')
    .sort((a, b) => (a.title > b.title ? 1 : -1));

  const appleOrGooglePayment =
    OSType === 'iOS' ? appleAndGooglePayments?.[0] : appleAndGooglePayments?.[1];

  const updatedSystemPayments = systems.concat(
    appleAndGooglePayments.length ? appleOrGooglePayment : []
  );

  return useMemo(
    () => ({
      popular,
      systems: updatedSystemPayments,
      regional: regionalUpdatedPayments,
    }),
    [popular, updatedSystemPayments, regionalUpdatedPayments]
  );
};
