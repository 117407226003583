import React, { FC, MutableRefObject, useCallback } from 'react';
import styled from 'styled-components';
import { useVirtual, VirtualItem } from 'react-virtual';
import { useSalesSelectedIdentifiers } from '../../../hooks';
import { PublicationElement } from './publication-element';
import { EmptyLayout } from '@UIkit';

interface IPublicationItems {
  parentRef: MutableRefObject<HTMLDivElement>;
}

export const PublicationItems: FC<IPublicationItems> = ({ parentRef }) => {
  const { selected } = useSalesSelectedIdentifiers();

  const elements = useVirtual({
    size: selected.identifiers.length,
    parentRef,
    estimateSize: useCallback(() => 148, []),
    overscan: 2,
  });

  const height = selected.identifiers.length ? elements.totalSize + 136 : elements.totalSize;

  if (!selected.identifiers.length) {
    return <EmptyLayout ctx="publication-found" namespace="marketplace" />;
  }

  return (
    <Container>
      <div className="scrollable-container" style={{ height }}>
        {elements.virtualItems.map(({ index, start, size }: VirtualItem) => (
          <div
            key={selected.identifiers[index]}
            className="element"
            style={{ transform: `translateY(${start}px)`, height: size - 8 }}
          >
            <PublicationElement id={selected.identifiers[index]} />
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  & .scrollable-container {
    position: relative;

    & .element {
      position: absolute;
      top: 0;
      left: 1rem;
      right: 1rem;
      transition: transform 150ms ease-in-out;
    }
  }
`;
