import React, { useEffect, useState } from 'react';
import {
  Container,
  IconContainer,
  AnimationBlock,
  Text,
  SoundIconsContainer,
} from './sounds-settings-item.styles';
import { SvgIcons } from 'games/CaseGame/assets';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';

interface SoundsSettingsItemProps {
  type: string;
  Icon: JSX.Element;
  sound: string;
  selected: string;
  selectHandler: (type: string) => void;
  selectedToPlayHandler: (type: string) => void;
  selectedToPlay: string;
}

export const SoundsSettingsItem: React.FC<SoundsSettingsItemProps> = ({
  type,
  Icon,
  sound,
  selected,
  selectHandler,
  selectedToPlayHandler,
  selectedToPlay,
}) => {
  const [isPlayed, setIsPlayed] = useState(false);
  const [play, { duration, stop }] = useSound(sound);
  const { t } = useTranslation('cases2.0');

  useEffect(() => {
    if (selectedToPlay !== type) {
      setIsPlayed(false);
      stop();
    }
  }, [selectedToPlay, stop, type]);

  useEffect(() => {
    return () => stop();
  }, [stop]);
  const playHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    selectedToPlayHandler(type);
    setIsPlayed(prev => !prev);
    play();
  };
  const pauseHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setIsPlayed(prev => !prev);
    stop();
  };
  const animationEndHandler = () => {
    setIsPlayed(false);
    stop();
  };

  return (
    <Container isSelected={selected === type} onClick={() => selectHandler(type)}>
      {!!sound && (
        <AnimationBlock
          onAnimationEnd={animationEndHandler}
          isPlayed={isPlayed}
          duration={duration}
        />
      )}
      <IconContainer>
        {Icon}
        <Text>{t(`soundsSettings.${type}`)}</Text>
      </IconContainer>
      {!!sound && (
        <SoundIconsContainer>
          {!isPlayed ? (
            <SvgIcons.PlayIcon onClick={playHandler} />
          ) : (
            <SvgIcons.PauseIcon onClick={pauseHandler} />
          )}
        </SoundIconsContainer>
      )}
    </Container>
  );
};
