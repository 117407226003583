import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { profileModals } from '../../../../modal.config';

export const ReferralController = () => {
  const { t } = useTranslation();
  const { handleOpenDialog } = useDialog(profileModals.CREATE_REFERRAL_COMPANY);

  return (
    <Container>
      <Button className="primary" onClick={handleOpenDialog}>
        {t('Create a campaign')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
  background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
  position: sticky;
  bottom: 0;
`;
