/* eslint-disable */
export default {
  Error: 'Error',
  'Error placing a bet': 'Error placing a bet',
  'Error entering code': 'Error entering code',
  'Invalid bet': 'Error placing a bet',
  'Bet added to queue': 'Bet added to queue',
  'Bet successfully placed': 'Bet successfully placed',
  'Error reserving an item': 'Error reserving an item',
  'Link saved successfully': 'Link saved successfully!',
  'Skins was successful sold': 'Skins was successful sold',
  'Round number is copied': 'Round number is copied',
  'Round hash is copied': 'Round hash is copied',
  'Hash salt is copied': 'Hash salt is copied',
  "You've successfully updated a bet": "You've successfully updated a bet",
  "You've successfully sell winning item": "You've successfully sell winning item",
  "You've successfully take winning item": "You've successfully take winning item",
  'Your bet has been added to the queue': 'Your bet has been added to the queue',
  'Support address was copied': 'Support address was copied',
  'No notifications.': 'No notifications.',
  'Incorrect link. Enter the correct link and try again.': 'Incorrect link.',
  'This is not your link. Enter your link and try again.': 'This is not your link.',
  "Don't forget to make your inventory public to get your winnings!":
    "Don't forget to make your inventory public to get your winnings!",
  'min. number of items per trade not exceeded': 'min. number of items per trade not exceeded',
  'Too many requests, try again later': 'Too many requests, try again later',
  'Your trade has been cancelled for the following reason:':
    'Your trade has been cancelled for the following reason:',
  'cheating attempt': 'cheating attempt',
  'items not from CS:GO game': 'items not from CS:GO game',
  'max. number of items per trade exceeded': 'max. number of items per trade exceeded',
  'player not registered on website': 'player not registered on website',
  'souvenir items are not allowed': 'souvenir items are not allowed',
  'some of the items cannot be appraised': 'some of the items cannot be appraised',
  'some of the items are below minimum price': 'some of the items are below minimum price',
  'total price of a trade is below minimum': 'total price of a trade is below minimum',
  'Your trade has been accepted!': 'Your trade has been accepted!',
  'The items have been added to the queue.': 'The items have been added to the queue.',
  'Your trade has been checked out!': 'Your trade has been checked out!',
  'It is currently being accepted': 'It is currently being accepted',
  'Unable to send a trade': 'Unable to send a trade',
  'Update your trade link in the settings of the site':
    'Update your trade link in the settings of the site',
  'Enter correct trade link and make sure your inventory is not full.':
    'Enter correct trade link and make sure your inventory is not full.',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Your account is not available to trade. More information will be shown if you invite someone to trade.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam is unavailable at this moment. Please wait for retry.',
  'Not enough money on balance': 'Not enough money on balance',
  'Not enough points on balance': 'Not enough money on balance',
  'Game is ending, try again later': 'Game is ending, try again later',
  'Congratulations!': 'Congratulations!',
  "You've successfully placed a bet": "You've successfully placed a bet",
  "You've successfully placed a bet with </amount>":
    "You've successfully placed a bet with {{</amount>}}",
  'To the game #</game> on </color> ': 'To the game #{{</game>}} on {{</color>}}',
  'Store is locked. Win </amount> more to unlock.':
    'Store is locked. Win ${currency(amount)} more to unlock.',
  'Item has not been found, coins have been returned':
    'Item has not been found, money have been returned',
  'Your order in the store has been cancelled': 'Your order in the store has been cancelled',
  'The min bet is </minBet>': 'The min bet is {{</minBet>}}',
  'The max bet is </maxBet>': 'The max bet is {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'You cannot place bet on red and black at same time',
  "You don't have CSGOFAST.com in Steam nickname!":
    "You don't have CSGOFAST.com in Steam nickname!",
  'Referral code is copied': 'Referral code is copied',
  'Referral link is copied': 'Referral code is copied',
  'You have exceeded the limit of 150 free coins per account':
    'You have exceeded the limit of 150 free coins per account',
  'Account locked': 'Account locked',
  'Referral code possible to use only until 15 minutes after registration on site. Promo code can be found in chat.':
    'Referral code possible to use only until 15 minutes after registration on site. Promo code can be found in chat.',
  'Code is not correct, result:': 'Code is not correct, result:',
  'Wrong promo code, sorry. Check code again.': 'Wrong promo code, sorry. Check code again.',
  "You've bet </amount> on the crash game #</game>!":
    "You've bet ${currency(amount)} on the crash game #{{</game>}}!",
  "You've updated coef to </coef> for crash game #</game>!":
    "You've updated coef to {{</coef>}} for crash game #{{</game>}}!",
  "You've won </amount> in the crash game #</number>!":
    "You've won ${currency(amount)} in the crash game #{{</number>}}!",
  'Too low auto stop coef, increase it': 'Too low auto stop coef, increase it',
  'Code for </amount> has been created': 'Code for ${currency(amount)} has been created',
  'Item has not been found in store, full price of skin will be refunded':
    'Item has not been found in store, full price of skin will be refunded',
  'You already have an item': 'You already have an item',
  '</amount> refilled on your balance': '${currency(amount)} refilled on your balance',
  'Your balance is replenished by </amount>': '${currency(amount)} have been added to your account',
  'Item has not been found, retry in few minutes (1)':
    'Item has not been found, retry in few minutes (1)',
  'We will sent it to your Steam in few moments': 'We will sent it to your Steam in few moments',
  'You already sold this skin or get a trade offer with them in Steam.':
    'You already sold this skin or get a trade offer with them in Steam.',
  'Please, just wait for few minutes.': 'Please, just wait for few minutes.',
  'Your items has been sended to game!': 'Your items has been sent to game!',
  '</amount> have been returned': '${currency(amount)} have been returned',
  'Item has not been found, retry in few minutes (</amount>)':
    'Item has not been found, retry in few minutes (${currency(amount)})',
  'It is currently being processing by game bots': 'It is currently being processing by game bots',
  '</amount> have been refilled': '${currency(amount)} have been refilled',
  'Skins get into the game instantly in the order of the queue':
    'Skins get into the game instantly in the order of the queue',
  'The bet amount is less than the minimum': 'The bet amount is less than the minimum',
  'The bet amount is greater than the maximum': 'The bet amount is greater than the maximum',
  'The number of items is greater than the maximum':
    'The number of items is greater than the maximum',
  'The number of items is less than the minimum': 'The number of items is less than the minimum',
  'There is not enough money on your balance for this bet':
    'There is not enough money on your balance for this bet',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Your order #{{</id>}} for ${currency(amount)} was accepted, wait for trade in Steam.',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Your bet for ${currency(amount)} was accepted and will added to game in {{</time>}} seconds',
  'Your bet was added to queue, in few seconds will added to the game':
    'Your bet was added to queue, in few seconds will added to the game',
  'Level Up reward added: </prizeAmount>': 'Level Up reward added: {{</prizeAmount>}}',
  'Level up': 'Level up',
  'Minimum refill sum must be more than </amount>':
    'Minimum refill sum must be more than ${currency(amount)}',
  'Your balance is replenished by <%= currency(amount) %>':
    'Your balance is replenished by ${currency(amount)}',
  'Your order </order_id> is successfully processed':
    'Your order {{</order_id>}} is successfully processed',
  Cabinet: 'Cabinet',
  'Level Up reward added:': 'Level Up reward added: ${currency(amount)}',
  'Withdrawal {{ id }} was canceled. Contact technical support':
    'Withdrawal {{ id }} was canceled. Contact technical support',
  ERROR: {
    INVENTORY_SERVICE: {
      ADD_PURCHASED_ITEMS: `Exchange failed. Items can't be credited to your inventory`,
      CREDIT_MONEY: `Exchange failed. Items/cash can't be written off`,
      DEBIT_MONEY: `Exchange failed. Items/cash can't be credited`,
      NOT_ENOUGH_BALANCE: 'Not enough balance, please select a different amount',
      NOT_ENOUGH_MONEY: 'Exchange failed. Not enough money',
      NOT_FOUND_IN_SHOP: 'This item is no longer available in shop',
      NOT_FOUND_IN_USER_INVENTORY: 'Item no longer available in your inventory',
      SELL_FROM_INVENTORY: 'Item sell failed',
      WITHDRAWAL_ERROR: 'You account is not able to withdraw. Please contact tech.support',
      WRONG_ACCOUNT_TYPE_BRONZE: 'You need a silver account to open a case',
    },
    STEAM_STORE: {
      NO_STEAM_TRADE_LINK: 'Please save your current trade link in the settings.',
    },
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE: 'Withdrawal is locked. Win ${currency(amount)} more to unlock',
    },
  },
  ERRORS: {
    MARKET: {
      ADD_ITEM_USER_PROCESSING_WAIT: 'Request is being processed, please wait',
      ADD_KIT_SELLER_PRICE_MORE: 'A set creation error occurred, please try again',
      BASE_ITEM_NOT_FOUND: 'Item not found',
      BID_ITEM_PROCESSING_WAIT: 'Too many requests. Please, try again later',
      BID_KIT_NOT_FOUND: 'Pack not found',
      BID_NOT_ENOUGH_COINS: 'Not enough money on your balance',
      BID_ON_OWN_KIT: 'Buying your own items is not possible.',
      BID_SELLER_PRICE_MORE: 'A set creation error occurred, please try again',
      BID_USER_LAST_BIDDER: 'Your bid is the best at the moment',
      CONFIRM_ITEM_PROCESSING_WAIT: 'Packs are being processed. Please wait',
      CONFIRM_KIT_NOT_FOUND: 'Pack not found',
      CONFIRM_USER_NOT_LAST_BIDDER: 'You cannot confirm that item',
      CREATE_ORDER: {
        BUYER_WITHOUT_STEAM_ID: 'You try to buy item without Steam account',
        ITEM_STATUS_WRONG: 'Failed to get the status of items',
        SELLER_WITHOUT_STEAM_ID: 'You try to sell item without Steam account',
      },
      DECLINE_ITEM_PROCESSING_WAIT: 'The pack is being processed. Please wait',
      DECLINE_KIT_NOT_FOUND: 'Pack not found',
      DECLINE_USER_IS_NOT_SELLER: 'You are trying to sell the wrong item',
      DELETE_ALL_ITEMS_PROCESSING_WAIT: 'Packs are being processed. Please wait',
      DELETE_BIDS_NOT_FOUND: 'Items not found',
      DELETE_KITS_NOT_FOUND: 'Pack not found',
      DELETE_PROCESSING_WAIT: 'Item is being deleted please wait',
      DELETE_USER_IS_NOT_SELLER: 'You are trying to sell the wrong item',
      INSERT_KIT_SOME_ITEM_NOT_FOUND: 'One or more items are not available in the pack',
      INSERT_KIT: 'The item is already on the market',
      P2P_DISABLED: 'There was an issue in the Steam side, please try again later',
      PAUSE_KITS_NOT_FOUND: 'Pack not found',
      RESUME_KITS_NOT_FOUND: 'Pack not found',
      SELL_NOW_ITEM_PROCESSING_WAIT: 'Item in process, please wait',
      SELL_NOW_KIT_NOT_FOUND: 'The item has gone from the market',
      SELL_NOW_USER_NOT_OWNER: 'You are trying to sell the wrong item',
      SIH_STATS_ITEMS_NOT_FOUND: 'There are no statistics on the item',
      STEAM_INVENTORY_CAN_NOT_GET: 'Failed to load your Steam inventory',
      STEAM_INVENTORY_USER_WITHOUT_STEAM: 'You try to use Steam services without Steam account',
      STEAM_PROFILE_IS_PRIVATE: 'Steam profile is private, please, make it public',
      UPDATE_ORDER: { ORDER_NOT_FOUND: 'Order not found' },
      USER_BANNED: 'Trade block. Please try again later',
      USER_CHECK_FAILED: 'Verification failed',
      USER_DEMO_ACCOUNT: 'Not available for bronze and demo account type',
      USER_ITEM_ALREADY_IN_TRADE: 'This item is already in trade',
      USER_ITEM_BANNED:
        'Failed to verify Steam settings. Please check API-key and Trade Link im profile settings on the site',
      USER_ITEM_NOT_ALLOWED: 'This item is flagged as unavailable in our system',
      USER_ITEM_NOT_FOUND: 'Item not found',
      USER_ITEM_PRICE_NOT_FOUND: "There's no item available",
      USER_ITEM_PRICE_TOO_HIGH: 'Item price is too high',
      USER_ITEM_PRICE_TOO_LOW: 'Item price is too low',
      USER_ITEM_PROCESSING_WAIT: 'Please wait, your request is being processed',
      USER_ITEM_TRADE_LOCK: 'Item has trade lock, please try another one',
      USER_LOCKED: 'You are temporarily blocked in the P2P system',
      USER_PAYOUT_LOCKED: 'Your account cannot withdraw this amount. Please contact tech support',
      USER_SKINS_LOCKED: 'Your skins are locked',
    },
    INVENTORY: {
      WITHDRAW_USER_DEMO_ACCOUNT: 'Refill your balance with any amount to be able to withdraw',
    },
    INVENTORY_SERVICE: {
      WRONG_ACCOUNT_TYPE_BRONZE: 'You need a silver account to open a case',
    },
    CASES: {
      NOT_FOUND: 'Case disabled, try again later',
      QUEST_REWARD: {
        IDX_ALREADY_PASSED: 'Target has already been shot ',
        NO_AVAILABLE_SHOTS: 'A shot had already been fired at this target',
        OPEN_NOT_ENOUGH_COINS: "You don't have enough tickets or experience to make the shot.",
        RATE_LIMIT: 'Too many requests. Please try again later.',
      },
    },
  },
  marketplace: {
    'empty-layouts': {
      message_bids: 'Buying items!',
      'sub message_bids': 'You can buy items in our marketplace.',
      message_failure: 'Service unavailable',
      'sub message_failure': 'Something went wrong.',
      message_found: 'No search results.',
      'sub message_found': "There's nothing found for your request.",
      'message_publication-found': 'Selling items!',
      'sub message_publication-found': 'Select items that you like to sell.',
    },
  },
};
