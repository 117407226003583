import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Span, Title, TitleContainer } from './sounds-settings-dialog.styles';
import { useTranslation } from 'react-i18next';
import { IDialogComponent } from 'core/interfaces';
import { soundsConfig } from 'games/CaseGame/assets';
import { SoundsSettingsItem } from '../sounds-settings-item';
import { useDispatch } from 'react-redux';
import { actionSetCasesSoundType } from 'games/CaseGame/ducks';
import { actionSetCaseBattleSoundType } from 'games/CaseBattle/ducks';
import { SoundGames, getSounds } from 'games/CaseGame/utils';

interface SoundsSettingsProps extends IDialogComponent<boolean> {}

export const SoundsSettings: React.FC<SoundsSettingsProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation('cases2.0');
  const location = useLocation();
  const gameName = location.pathname.split('/')[1] as SoundGames;
  const sounds = getSounds();
  const [selected, setSelected] = useState(sounds[gameName]);
  const [selectedToPlay, setSelectedToPlay] = useState(sounds[gameName]);
  const dispatch = useDispatch();

  const selectHandler = (type: string) => {
    if (gameName === 'case-battle') {
      dispatch(actionSetCaseBattleSoundType(type));
    } else {
      dispatch(actionSetCasesSoundType(type));
    }
    setSelected(type);

    localStorage.setItem('sounds', JSON.stringify({ ...sounds, [gameName]: type }));
  };
  const selectedToPlayHandler = (type: string) => {
    setSelectedToPlay(type);
  };

  return (
    <Container>
      <TitleContainer>
        <Title>{t('soundsSettings.sounds')}</Title>
        <Span onClick={handleCloseModal}>{t('close')}</Span>
      </TitleContainer>
      {Object.values(soundsConfig).map(sound => (
        <SoundsSettingsItem
          selectedToPlayHandler={selectedToPlayHandler}
          selectedToPlay={selectedToPlay}
          selected={selected}
          selectHandler={selectHandler}
          {...sound}
          key={sound.type}
        />
      ))}
    </Container>
  );
};
