import React, { createElement, FC, memo, useEffect, useState } from 'react';

import { UserBalance } from 'core/User/widgets';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useUser } from 'core/User';
import { useDrawer } from 'core/Drawer/hooks';
import { Container, IconsContainer, Progress } from './app-bar.styles';
import { ToolBarIcons } from 'assets/tool-bac-assets';
import { useToolBar } from 'core/hooks';
import { useGetGeneralQuestInfo } from 'modules/event-module/comics-event/hooks';
import { actionRequestQuestInfo } from 'modules/event-module/comics-event/duck';
import { EventIcons } from 'modules/event-module/comics-event/assets';
import { CategoryItem } from 'modules/event-module/comics-event/interfaces';
import { useDispatch } from 'react-redux';

interface IAppBar {
  deep?: boolean;
  prependWidget?: FC;
  isGame?: boolean;
  className?: string;
  progressCategory?: number;
  loader?: number;
  category?: CategoryItem;
}

export const AppBar: FC<IAppBar> = memo(
  ({
    deep,
    children,
    prependWidget,
    isGame = false,
    className = '',
    progressCategory,
    loader,
    category,
  }) => {
    const { user } = useUser();
    const { handleToggleToolBar } = useToolBar();
    const { handleToggleDrawer } = useDrawer();
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { endAt, progressEndAt } = useGetGeneralQuestInfo();
    const [hasProgress, setHasProgress] = useState(false);
    const currentDate = new Date();

    useEffect(() => {
      if (
        (category?.count - category?.progress > 0 && loader < 100) ||
        (progressCategory > loader && hasProgress)
      ) {
        setHasProgress(true);
      } else {
        setHasProgress(false);
      }
    }, [category, hasProgress, loader, progressCategory]);

    const handleGoBack = () => {
      if (!location.key) {
        const path = location.pathname.split('/');
        const newPath = path.slice(0, path.length - 1).join('/');
        history.replace(newPath);
      } else {
        history.goBack();
      }
    };

    const handleBar = () => {
      handleToggleToolBar();
      if (!endAt) {
        dispatch(actionRequestQuestInfo());
      }
    };

    return (
      <Container className={className} isGame={isGame}>
        {hasProgress && new Date(progressEndAt) > currentDate && (
          <Progress percent={progressCategory > 100 ? 100 : progressCategory} />
        )}
        <div className="app-bar-drawer">
          {deep ? (
            <button onClick={handleGoBack}>
              <i className="icon-arrow" />
            </button>
          ) : (
            <button onClick={handleToggleDrawer(true)}>
              <i className="icon-menu" />
            </button>
          )}
        </div>
        <div className="app-bar-title">{children || <span>{t(match?.path || '')}</span>}</div>
        {isGame && (
          <IconsContainer onClick={handleBar}>
            <EventIcons.AppBarEventIcon className="event" width={11} height={11} />
            {/* <ToolBarIcons.Rule width={11} height={16} /> */}
            <ToolBarIcons.Chat width={10} height={16} />
            <ToolBarIcons.HistoryIcon width={16} height={16} />
          </IconsContainer>
        )}
        {user.id && (
          <div className="app-bar-actions">
            {prependWidget ? (
              createElement(prependWidget)
            ) : (
              <Link className="balance-action" to="/personal/balance">
                <UserBalance />
              </Link>
            )}
          </div>
        )}
      </Container>
    );
  }
);
