import * as React from 'react';
import { userColors } from '../../fast.utils';
import { CurrencyText } from '@components/currency';

import { IFastGame } from '../../interfaces/reducer.fast.interface';
import { usePlayerTrade, useSortedPlayers } from '../../hooks';
import { PlayerAvatar } from '@components/Common';
import { Player, PlayersArea } from './styles';
import { getPercent } from 'core/utils/percent.util';

interface IPassedGame {
  gameData: IFastGame;
}

export const PassedGame: React.FC<IPassedGame> = React.memo(({ gameData }) => {
  const { sortedPlayers } = useSortedPlayers(
    Object.keys(gameData.trades),
    gameData.winner as number
  );

  const { sortedTradeId } = usePlayerTrade(gameData.trades);

  return (
    <PlayersArea>
      {sortedPlayers.map((userId: string) => (
        <Player
          className="player-reward"
          key={userId}
          color={userColors[sortedTradeId.indexOf(userId)]}
        >
          <PlayerAvatar
            uri={gameData.trades[userId].playerImage}
            userId={gameData.trades[userId].playerId}
          />
          <span className="player-name">{gameData.trades[userId].playerName}</span>
          <div className="player-bet">
            <span>{getPercent(gameData.trades[userId].totalPrice, gameData.prize)}%</span>
            {' | '}
            <span>
              <CurrencyText value={gameData.trades[userId].totalPrice} />
            </span>
          </div>
        </Player>
      ))}
    </PlayersArea>
  );
});
