import styled from 'styled-components';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

export const Container = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 75%, rgb(18, 23, 25) 100%),
    url(${eventImagesPath.mainBannerPath});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 173%;
  border-radius: var(--size-radius-l);
  height: 213px;
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media screen and (min-width: 400px) {
    background-size: cover;
  }
`;

export const Times = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -10px;
  padding: 8px 0;
  border-radius: 8px;
  background-color: #121719;
`;

export const Time = styled.div`
  display: flex;
  height: 41px;
  justify-content: center;
  align-items: center;

  .time,
  & > span {
    font-size: 24px;
    font-weight: 500;
    color: #f7fbfc;
  }

  .time-color {
    font-size: 14px;
    color: #93a3ab;
    font-weight: 300;
  }
`;

export const ContainerForTime = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
`;
