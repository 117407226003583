import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AppBar, NavBar } from '@components/bars';
import { Balance } from '../Balance';
import { Raffles } from '../Raffles';
import { RafflesHistoryNavigatePage } from '../Pages';
import { Rules } from '../../components';

export const RaffleStack: FC<RouteComponentProps> = ({ match, location }) => (
  <>
    <AppBar deep prependWidget={Balance} />
    <NavBar tabs={['/active', '/history', '/rules']} />
    <div className="content">
      <Switch>
        <Route path={`${match.url}/active`} component={Raffles} />
        <Route path={`${match.url}/history`} component={RafflesHistoryNavigatePage} />
        <Route exact path={`${match.url}/rules`} component={Rules} />
        <Redirect
          to={{
            pathname: '/leaders-race-next',
            state: { from: location },
          }}
        />
      </Switch>
    </div>
  </>
);
