import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--color-white);
  width: 40%;
`;

export const Input = styled.input`
  display: flex;
  background-color: var(--color-dark-200);
  color: var(--yellow);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.24px;
  border: none;
  border-radius: 8px;
  width: 50%;
  padding-right: 16px;
`;

export const IconContainer = styled.div<{ isHighestPrice?: boolean }>`
  position: absolute;
  right: 12px;
  top: 8px;
  ${({ isHighestPrice }) => isHighestPrice && 'opacity:0.5;'}
`;

export const IconContainerMinus = styled.div<{ isLowestPrice: boolean }>`
  left: 12px;
  position: absolute;
  top: 8px;
  width: fit-content;
  ${({ isLowestPrice }) => isLowestPrice && 'opacity:0.5;'}
`;

export const InputContainer = styled.div<{ isOutOfRange: boolean }>`
  position: relative;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-dark-200);
  border-radius: 8px;
  padding: 4px 12px;
  gap: 2px;
  ${({ isOutOfRange }) => isOutOfRange && 'border: 1px solid var(--red);'}
`;

export const FastCoinIconContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
