import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { usePayoutAggregator } from '../../../hooks';
import { IWithdrawalGate } from '../../../interfaces/reducers.withdrawal.interface';
import { URLS } from 'services/constant-urls';

interface IPayoutAggregator {
  gates: IWithdrawalGate[];
}

export const PayoutAggregator: FC<IPayoutAggregator> = ({ gates }) => {
  const { t } = useTranslation();
  const { aggregator, handleChangeAggregator } = usePayoutAggregator();
  return (
    <Container>
      <span className="aggregator-title">{t('Select payment provider:')}</span>
      <div className="aggregators-container ">
        {gates.map((item, idx) => (
          <div
            key={item.name}
            onClick={handleChangeAggregator(idx)}
            className={`aggregator ${idx === aggregator ? 'active' : ''}`}
          >
            <div className="aggregator__thumbnail">
              <img src={`${URLS.refill.icon}/refill-${item.name}.svg`} alt="" />
            </div>
            <span className="aggregator__name">{t(item.title)}</span>
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.9375rem;
  min-height: 5.25rem;
  overflow: hidden;

  & .aggregator-title {
    display: block;
    color: var(--color-white-600);
    font-size: 0.75rem;
    padding: 0 1rem;
    letter-spacing: 0.4px;
  }

  & .aggregators-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: 0.25rem;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 0.75rem;
      height: 100%;
    }

    & .aggregator {
      display: flex;
      flex-flow: row;
      min-width: calc((100vw - 2.25rem) / 2);
      height: 3.5rem;
      border-radius: var(--size-radius-s);
      padding: 0.4375rem 1rem;
      position: relative;
      background-color: var(--color-dark-200);
      transition: background 0.3s linear;

      &.active {
        background-color: #47555f;
      }

      &__thumbnail,
      &__name {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__thumbnail {
        flex-basis: 2rem;

        & img {
          width: 1.875rem;
          height: 1.875rem;
        }
      }

      &__name {
        justify-content: flex-start;
        flex-basis: 100%;
        color: white;
        font-size: 0.875rem;
        margin-left: 0.75rem;
      }
    }
  }
`;
