import React from 'react';
import styled from 'styled-components';
import { TieBoard } from './tie-board';
import { PairBoard } from './pair-board';
import { SideBoard } from './side-board';
import { BoardTypes } from '../../../interfaces';

export const GameBoards = () => {
  return (
    <Container>
      <div className="inner-game-board-container">
        <PairBoard type={BoardTypes.PAIR_PLAYER_BOARD} protectType={BoardTypes.PLAYER_BOARD} />
        <PairBoard type={BoardTypes.PAIR_BANKER_BOARD} protectType={BoardTypes.BANKER_BOARD} />
        <SideBoard
          type={BoardTypes.PLAYER_BOARD}
          protectType={BoardTypes.BANKER_BOARD}
          side="playerHand"
        />
        <SideBoard
          type={BoardTypes.BANKER_BOARD}
          protectType={BoardTypes.PLAYER_BOARD}
          side="bankHand"
        />
        <TieBoard type={BoardTypes.TIE} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: min-content;
  align-self: self-end;
  margin-bottom: 2rem;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
  }

  &:before {
    float: left;
    padding-top: 66%;
  }

  &:after {
    display: block;
    clear: both;
  }

  &.pulse-sector {
    animation: baccarat-pulse-sector-animation 1s infinite;
  }

  & .inner-game-board-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template: 'pair-player pair-banker' 'player banker' 'tie tie' / 1fr 1fr;
    grid-template-rows: 48px 1fr 48px;
    grid-gap: 0.25rem;
    position: relative;
    padding: 0.125rem;
  }

  @keyframes baccarat-pulse-sector-animation {
    0% {
      background: var(--color-stroke);
    }
    50% {
      background: var(--color-fill);
    }
    100% {
      background: var(--color-stroke);
    }
  }
`;
