/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'SPILL',
  Chat: 'Chat',
  'Email Support': 'E-poststøtte',
  'Live Support Chat': 'Direkte støtte chat',
  History: 'Logg',
  Top: 'Topp',
  Rules: 'Rules',
  'At stake': 'På spill',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Logg inn for å skrive en melding',
  'Only players who have played the game are allowed to write':
    'Bare brukere som har spilt spillet kan skrive',
  'You are banned': 'Du er utestengt',
  Reason: 'Grunn',
  'Time left': 'Tid igjen',
  'Time to summarizing': 'Tid til oppsummering',
  spam: 'spam',
  'advertising (auto)': 'reklamering (auto)',
  advertising: 'reklamering (auto)',
  rudeness: 'dårlig oppførsel',
  begging: 'tigging',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Idag',
  Yesterday: 'Igår',
  'Last year': 'i fjor',
  'Show my wins': 'Vis mine gevinster',
  'History is empty': 'Loggen er tom',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Close: 'Lukk',
  Confirm: 'Confirm',
  'Are you sure to delete all notifications?': 'Are you sure to delete all notifications?',
  'Log in': 'Logg inn',
  'Log in to try your luck': 'Logg inn for å prøv lykken',
  'try your luck!': 'Prøv lykken!',
  'Take your prize': 'Ta imot gevinsten',
  'Sell for': 'Selg for',
  BACK: 'BACK',
  'Warning!': 'Advarsel!',
  'Congratulations!': 'Gratulerer!',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Gratulerer! En tradeoffer med dine gevinster har blitt sendt ut til din bruker.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'Traden vil bli kansellert om 1 time',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Etter at bestillingen er behandlet, kan du samle dine gevinster på innleveringssiden til Steam',

  'Round number': 'Runde nummer',
  'Round hash': 'Runde hash',
  'Hash salt': 'Hash salt',

  'Refill amount': 'Fyll på beløp',
  Bonus: 'Bonus',
  'Bonus experience': 'Bonuserfaring',
  'Bonus wheel spins': 'Bonus-hjulspinn',
  'Bonus cases': 'Bonuskasser',

  'Trick or treat?': 'Knask eller knep?',
  'Keep your halloween tip jars ready!': 'Hold halloween-glassene klare!',
  'Bonuses on every balance refill': 'Bonuser på hver påfylling av saldoen',

  '${currency(amount)} bonus is ready!': '${currency(amount)} bonus er klar!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Påfyll balansen og få opptil  ${currency(amount)} i gratisspinn og saker',

  'Get bonus': 'få nå',
};
