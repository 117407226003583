export const convertGMTtoFullDate = (gmtDate: Date) => {
  const date = new Date(gmtDate);

  // Get the day, month, year, hours, minutes, and seconds
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const getLeadingZero = (number: number) => (number < 10 ? `0${number}` : number);
  // Pad the day, month, hours, minutes, and seconds with leading zeros if necessary
  const dayConverted = getLeadingZero(day);
  const monthConverted = getLeadingZero(month);
  const hoursConverted = getLeadingZero(hours);
  const minutesConverted = getLeadingZero(minutes);
  const secondsConverted = getLeadingZero(seconds);

  // Create the formatted date string

  return {
    DDMMYYY: `${dayConverted}.${monthConverted}.${year}`,
    HHMMSS: `(${hoursConverted}:${minutesConverted}:${secondsConverted})`,
  };
};
