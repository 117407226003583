import React from 'react';
import { AppBar, GetToolBarConfig, ToolBar } from '../../../../@components/bars';
import { useTranslation } from 'react-i18next';
import { WheelGame } from 'games/FortuneWheel/components/Wheel';

export const GamePage = () => {
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];
  return (
    <>
      <AppBar isGame />
      <div className="content">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <WheelGame />
      </div>
    </>
  );
};
