import * as React from 'react';
import { CurrencyText } from '@components/currency';
import { ITrade } from '../../../interfaces/reducer.fast.interface';
import { getPercent } from 'core/utils/percent.util';

interface IPlayerDescribe {
  player: ITrade;
  prize: number;
  index: number;
}

export const PlayerDescribe: React.FC<IPlayerDescribe> = ({ player, prize }) => (
  <>
    <span className="player-name">{player.playerName}</span>
    <div className="player-bet">
      <span>{getPercent(player.totalPrice, prize)}%</span>
      {' | '}
      <span>
        <CurrencyText value={player.totalPrice} />
      </span>
    </div>
  </>
);
