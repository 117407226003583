import { shallowEqual, useSelector } from 'react-redux';
import { getUserDetails } from '../selectors';

export const useUserProviders = () => {
  const userProviders = useSelector(
    (state: any) => getUserDetails<'providers'>(state, 'providers'),
    shallowEqual
  );
  return {
    userProviders,
  };
};
