import React from 'react';
import { URLS } from 'services/constant-urls';
import { Container } from './pay-gift-aggregators.styles';

export const PayGiftAggregators = React.memo(() => (
  <Container>
    <img src={`${URLS.refill.icon}/new-providers/master-card.svg`} alt="" />
    <img src={`${URLS.refill.icon}/new-providers/visa.svg`} alt="" />
    <img src={`${URLS.refill.icon}/new-providers/paysafe-card.svg`} alt="" />
    <img src={`${URLS.refill.icon}/new-providers/skrill.svg`} alt="" />
    <img src={`${URLS.refill.icon}/new-providers/paypal.svg`} alt="" />
  </Container>
));
