import styled from 'styled-components';

interface IButtonProps {
  current?: number;
  finished?: number;
  duration?: number;
  progress?: number;
}

export const Button = styled.button.attrs<IButtonProps>(({ current, finished }) => ({
  progress: ((current / finished) * 100).toFixed(2),
}))<IButtonProps>`
  display: flex;
  color: var(--color-dark-600);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  border-radius: var(--size-radius-m);
  border: 0;
  position: relative;
  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 0.286px;
  line-height: 0.9375rem;
  text-transform: uppercase;

  &.full {
    width: 100% !important;
  }

  &.small {
    width: 130px;
    height: 34px;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }

  &.primary {
    background-color: var(--yellow);
    color: var(--color-dark-600);

    &:active {
      background-color: #ad9d15;
    }

    &.disabled,
    &:disabled {
      background: var(--color-dark-600);
      color: var(--color-gray-100);
    }
  }

  &.primary-outline {
    background-color: transparent;
    border: 1px solid var(--yellow);
    color: var(--color-white);

    &:active {
      border-color: #ad9d15;
    }
  }

  &.secondary {
    background-color: var(--color-dark-200);
    color: var(--color-white);

    &.disabled,
    &:disabled,
    &:active {
      background: var(--color-dark-600);
      color: var(--color-gray-200);
    }
  }

  &.secondary-transparent {
    background-color: transparent;
    color: var(--color-white);

    &:active {
      color: var(--color-gray-200);
    }
  }

  &.danger {
    background: var(--color-gray-700);
    color: var(--color-red);

    &:active {
      color: var(--color-gray-100);
      background: var(--color-dark-600);
    }

    &:disabled {
      color: var(--color-dark-200);
      background: var(--color-dark-600);
    }
  }

  &.dark {
    background: var(--color-dark);
    color: var(--color-white);
  }

  &[class^='link-'],
  &[class*=' link-'] {
    background: transparent;
    width: auto;
    height: auto;
  }

  &.link-info {
    color: var(--aqua-500);
    background: transparent;

    &:active {
      color: #08778a;
    }
  }

  &.link-light {
    color: var(--color-gray-200);
  }

  &.prepend-icon {
    padding-right: 1rem;

    &:after {
      content: '\\e90e';
      transform: rotateZ(90deg) translateX(-50%);
      font-family: icomoon;
      font-size: 0.8rem;
      line-height: 100%;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }

  // links

  &[class^='link-'],
  &[class*=' link-'] {
    background: transparent;
    width: auto;
    height: auto;
  }

  &.link-primary {
    color: var(--yellow);

    &:active {
      color: #ad9d15;
    }
  }

  &.link-info {
    color: var(--aqua-500);

    &:active {
      color: var(--aqua-800);
    }
  }

  &.prepend-icon {
    padding-right: 1rem;

    &:after {
      content: '\\e90e';
      position: absolute;
      transform: rotateZ(90deg) translateX(-50%);
      font-family: icomoon;
      font-size: 0.8rem;
      right: 0rem;
      top: 50%;
    }
  }

  &.with-progress {
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.3);
      animation-timing-function: linear;
      animation-name: glide;
      animation-fill-mode: forwards;
      animation-duration: ${({ duration }) => `${duration}ms`};
      transform: ${({ progress }) => `translate3d(-${progress}%, 0, 1px)`};
    }
  }

  &:disabled .multi-line span,
  &.disabled .multi-line span {
    color: inherit;
  }

  & .multi-line {
    display: grid;
    grid-auto-flow: row;

    & span {
      font-size: 0.8125rem;
      line-height: 1rem;
      text-align: center;
      letter-spacing: 0.286px;
      color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:first-of-type:not(:last-of-type) {
        font-weight: 700;
      }

      &:last-of-type:not(:first-of-type) {
        font-weight: 400;
        opacity: 0.75;
      }
    }
  }
`;

export const RouterLink = styled.button`
  background: transparent;
  border: none;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0;
  position: relative;

  &.prepend-icon {
    padding-right: 1rem;

    &:after {
      content: '\\e90e';
      position: absolute;
      transform: rotateZ(90deg) translate(1px, -10px);
      font-family: icomoon;
      font-size: 0.8rem;
    }
  }

  &.primary {
    color: var(--yellow);

    &:active {
      color: #ad9d15;
    }
  }
`;
