import { RefillIcons } from 'modules/Refill/assets';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  padding: 22px 20px 30px;
  background: url(${RefillIcons.background}) center center no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-size: 125% auto;

  @media screen and (max-width: 375px) {
    background-size: 160%;
  }

  @media screen and (max-width: 420px) and (min-width: 375px) {
    background-size: 135%;
  }
`;

//NOTE: BlackFriday Event modification

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.24px;
  text-align: left;
  color: var(--color-gray-200);
`;

export const FastCoinPriceBeforeBonus = styled.div`
  display: flex;
  height: 100%;
  gap: 6px;
  justify-content: start;
  align-items: center;
`;

export const DollarPrice = styled.div`
  height: 100%;
  letter-spacing: 0.24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Span = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const BonusReward = styled.div`
  color: var(--success);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const ResultRefill = styled(Wrapper)`
  color: var(--color-white);
  margin-top: 6px;
`;

export const ResultFillCount = styled(BonusReward)`
  color: var(--color-white);
`;
export const FastCoinContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
