import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

class NewYearRepository {
  getQuestInfo() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/categories/quest`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  pickUpPrize(entityId: number, questId: number = 8) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/quest/${entityId}/quest/${questId}/reward`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  getMotivatorList() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/motivator/cases-quest`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  getCategoryEvent() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/events`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  //NOTE: all requests below for leader race
  getBalanceTickets() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/me/leaders-race/tickets/balance`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  getLRQuests() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/me/leaders-race/quests`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  takeReward(questId: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/me/leaders-race/quests/${questId}/take-reward`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
    });
  }

  getLRLeaders() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/quests/latest`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchLeaderRaceCases() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases?leaderRace=true`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
}

export default new NewYearRepository();
