import * as React from 'react';
import styled from 'styled-components';
import { CurrencyText } from '../../currency';
import { useTranslation } from 'react-i18next';
import { DoubleField } from './GameField';

interface IHistoryHeader {
  gameId: number;
  timeAdded: string;
  totalPrize: number;
  coef?: number;
  sector?: string;
}

export const HistoryHeader: React.FC<IHistoryHeader> = props => {
  const { t } = useTranslation();

  return (
    <Container>
      {Number(props.sector) >= 0 && <DoubleField sector={Number(props.sector)} />}
      <Column>
        <Title>
          {t('GAME')}
          {' №'}
          {props.gameId}
        </Title>
        {props.timeAdded && <Time>{props.timeAdded}</Time>}
      </Column>
      {props.totalPrize && (
        <Column>
          <Prize>
            <CurrencyText value={props.totalPrize} />
          </Prize>
        </Column>
      )}
      {props.coef && (
        <Column>
          <CoefValue>
            {'x'}
            {props.coef}
          </CoefValue>
        </Column>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 1rem 2rem;
`;

const Column = styled.div`
  display: flex;
  flex: 1 0;
  flex-flow: column;
`;

const Title = styled.div`
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: bold;
  letter-spacing: 0.65px;
  color: var(--color-white);
`;

const Time = styled.div`
  font-size: 0.9rem;
  line-height: 1.4;
  letter-spacing: 0.77px;
  color: var(--light);
`;

const Prize = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.77px;
  text-align: right;
  color: var(--green-100);
`;

const CoefValue = styled.div`
  font-size: 1.6rem;
  color: var(--yellow);
  text-align: right;
`;
