import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IPayMethodGates } from '../../../interfaces/reduces.refill.interfaces';

interface ILockScreen {
  trust: boolean;
  gate: number;
  gates: IPayMethodGates;
}

interface ILockScreenStyle {
  trust: boolean;
  type: string;
  gateType: string;
}

export const LockScreen: FC<ILockScreen> = ({ trust, gate, gates }) => {
  const { t } = useTranslation();
  const { type } = useParams();
  const gateType = gates[gate]?.name || '';
  return (
    <Container trust={trust} type={type} gateType={gateType}>
      <div className="lock-message">
        <i className="icon-alert-error" />
        <span>{t('Unfortunately, this payment method is temporarily unavailable.')}</span>
        <span>{t('Please use other methods. We apologize for any inconvenience caused.')}</span>
      </div>
    </Container>
  );
};

const Container = styled.div.attrs(({ trust, type, gateType }: ILockScreenStyle) => ({
  lock: !trust && ['card', 'qiwi'].includes(type) && ['jirapay'].includes(gateType),
}))<ILockScreenStyle>`
  opacity: ${p => (p.lock ? 1 : 0)};
  background: rgba(19, 26, 29, 0.95);
  transition: opacity 150ms ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: ${p => (p.lock ? 'auto' : 'none')};

  & .lock-message {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.3125rem;
    position: sticky;
    top: 200px;
    padding: 0 2.4375rem;

    & i {
      text-align: center;
      font-size: 3rem;
      color: var(--color-red);
    }

    & span {
      font-size: 0.875rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.54px;
      color: white;
    }
  }
`;
