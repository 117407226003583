export enum SUMMERTIME_EVENT_ACTIONS_TYPES {
  ACTION_REQUEST_EVENT_DATA = 'app/comics-heroes-event/ACTION_REQUEST_EVENT_DATA',
  ACTION_RESPONSE_EVENT_DATA = 'app/comics-heroes-event/ACTION_RESPONSE_EVENT_DATA',
  ACTION_RESPONSE_MOTIVATOR_EVENT = 'app/comics-heroes-event/ACTION_RESPONSE_MOTIVATOR_EVENT',
  ACTION_REQUEST_MOTIVATOR_DATA = 'app/comics-heroes-event/ACTION_REQUEST_MOTIVATOR_DATA',
  ACTION_RESPONSE_MOTIVATOR_DATA = 'app/comics-heroes-event/ACTION_RESPONSE_MOTIVATOR_DATA',
  ACTION_EVENT_DATA_FROM_SOCKET_EVENT = 'app/comics-heroes-event/ACTION_EVENT_DATA_FROM_SOCKET_EVENT',
  ACTION_PICK_UP_PRIZE = 'app/comics-heroes-event/ACTION_PICK_UP_PRIZE',
  ACTION_RESPONSE_PICK_UP_PRIZE = 'app/comics-heroes-event/ACTION_RESPONSE_PICK_UP_PRIZE',
  ACTION_PICK_UP_PRIZE_OPENED = 'app/comics-heroes-event/ACTION_PICK_UP_PRIZE_OPENED',
  ACTION_REQUEST_CATEGORY = 'app/comics-heroes-event/ACTION_REQUEST_CATEGORY',
  ACTION_RESPONSE_CATEGORY = 'app/comics-heroes-event/ACTION_RESPONSE_CATEGORY',
  ACTION_RESPONSE_CASE_RARE_INFO = 'app/comics-heroes-event/ACTION_RESPONSE_CASE_RARE_INFO',
  ACTION_CLEAR_RARE_CASE_INFO = 'app/comics-heroes-event/ACTION_CLEAR_RARE_CASE_INFO',
  //NOTE: Leader race types
  ACTION_RESPONSE_LR_BALANCE = 'app/comics-heroes-event/ACTION_RESPONSE_LR_BALANCE',
  ACTION_REQUEST_LR_BALANCE = 'app/comics-heroes-event/ACTION_REQUEST_LR_BALANCE',
  ACTION_REQUEST_LR_QUESTS = 'app/comics-heroes-event/ACTION_REQUEST_LR_QUESTS',
  ACTION_RESPONSE_LR_QUESTS = 'app/comics-heroes-event/ACTION_RESPONSE_LR_QUESTS',
  EVENT_ACTION_LR_QUEST = 'app/comics-heroes-event/EVENT_ACTION_LR_QUEST',
  ACTION_TAKE_REWARD = 'app/comics-heroes-event/ACTION_TAKE_REWARD',
  ACTION_REQUEST_LR_BALANCE_LOADING = 'app/comics-heroes-event/ACTION_REQUEST_LR_BALANCE_LOADING',
  ACTION_REQUEST_LR_QUESTS_LOADING = 'app/comics-heroes-event/ACTION_REQUEST_LR_QUESTS_LOADING',
  ACTION_REQUEST_LR_CASES = 'app/comics-heroes-event/ACTION_REQUEST_LR_CASES',
  ACTION_RESPONSE_LR_CASES = 'app/comics-heroes-event/ACTION_RESPONSE_LR_CASES',
  ACTION_REQUEST_LR_DATA = 'app/comics-heroes-event/ACTION_REQUEST_LR_DATA',
  ACTION_RESPONSE_LR_DATA = 'app/comics-heroes-event/ACTION_RESPONSE_LR_DATA',
}
