import React, { FC, useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnimatedRouter } from '@components/animated-router';
import { LotteryPage } from '@components/Histories';
import { cleanUp, fetchHistories } from '../duck';
import { GamePage, HistoryPage, RulesPage } from './Pages';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHistories());

    return () => {
      dispatch(cleanUp());
    };
  }, [dispatch]);

  return (
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/history`} component={HistoryPage} />
        <Route path={`${match.url}/rules`} component={RulesPage} />
        <Route path={`${match.url}/lottery`} component={LotteryPage} />
        <Route path={`${match.url}`} component={GamePage} />
      </AnimatedRouter>
    </div>
  );
};
