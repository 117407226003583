import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';
import { QueryService } from 'services/query';

export class UserSteamInventoryRepository {
  static fetchInventory(
    params: Record<string, { ignoreCache: boolean }>
  ): Observable<AjaxResponse> {
    const q = QueryService.paramsToQuery(params);
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/steam/inventory${q}`,
      method: 'GET',
      withCredentials: true,
    });
  }
  static fetchSteamStatus() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/steam/status`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
}
