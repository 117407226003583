import React from 'react';
import { AppBar } from '@components/bars';
import { ReferralCampaignsTabs } from './refferal-campaigns-tabs';
import { ReferralCampaignsMetadata } from './referral-campaigns-metadata';
import { ReferralNavigator } from '../@shared';
import { ReferralController } from './referral-controller';

export const ReferralCampaignsPage = () => {
  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <ReferralCampaignsMetadata />
        <ReferralCampaignsTabs />
        <ReferralNavigator />
        <ReferralController />
      </div>
    </>
  );
};
