import { useCallback, useState } from 'react';
import { ModeType } from '../interfaces';
import { useDispatch } from 'react-redux';
import { resetClearance } from '../ducks/clearance.duck';

export const useStockMode = () => {
  const [mode, set] = useState(null);
  const dispatch = useDispatch();
  const setMode = useCallback((v: ModeType) => () => set(v), []);

  const reset = useCallback(() => {
    set(null);
    dispatch(resetClearance());
  }, [dispatch]);

  return { mode, setMode, reset };
};
