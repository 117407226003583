export default {
  Cart: 'Корзина',
  Price: 'Цена',
  Type: 'Type',
  Categories: 'Категории',
  Rarity: 'Раритетность',
  OK: 'OK',
  Clear: 'Очистить',
  Autoselect: 'Автовыбор',
  Remove: 'Remove',
  Bet: 'Ставка',
  Min: 'Мин',
  Max: 'Макс',
  'it.': '',
  Total: 'Общий',
  items: 'предметов',
  Pistols: 'Пистолеты',
  Rifles: 'Винтовки',
  'Submachine guns': 'ПП',
  Heavy: 'Тяжелое',
  Knives: 'Ножи',
  Gloves: 'Перчатки',
  Keys: 'Ключи',
  Other: 'Разное',
  'Base Grade': 'Базового класса',
  'Consumer Grade': 'Ширпотреб',
  'Industrial Grade': 'Промышленное качество',
  Restricted: 'Запрещенное',
  'Mil-Spec Grade': 'Армейское качество',
  Classified: 'Засекреченное',
  'High Grade': 'Высшего класса',
  Covert: 'Тайное',
  Remarkable: 'Примечательного типа',
  Exotic: 'Экзотичного вида',
  Contraband: 'Контрабанда',
  Extraordinary: 'Экстраординарного типа',
  Buy: 'Купить',
  'Repeat trade': 'Повторить',
  "I'm 18+ and I know about": 'Мне 18 лет и я знаю об',
  'the limitations of the store': 'ограничениях магазина',
  'Store only for the withdrawal of winnings or unused money. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Магазин работает исключительно для вывода выигрыша или неиспользованных монет. Обмен скинов с целью получения прибыли от разницы в цене с другими маркетами строго запрещен. Нарушители будут забанены навсегда.',
  'Your purchase will be delivered within 15 minutes.':
    'Ваша покупка будет доставлена в течении 15 минут.',
  'Congratulations! You have successfully purchased': 'Поздравляем! Вы успешно приобрели',
  'Order confirmation': 'Подтверждение заказа',
  'Trade bot': 'Трейд бот',
  'Verification code': 'Проверочный код',
  'You can accept trade offer using steam mobile app, browser or steam client':
    'Вы можете принять трейд в мобильном приложении Steam, в браузере, или в Steam Client',
  'Trade offer is automatically cancelled 3 minutes after creation':
    'Трейд автоматически отменяется через 3 минуты после создания',
  Filters: 'Filters',
  'By price': 'По цене',
  Filter: 'Фильтр',
  'Not found': 'Ничего не найдено',
  'At present it is unable to find any items on your request. Try again later.':
    'Вещей по вашему запросу не найдено. Попробуйте чуть позже.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
  'Not accepted': 'Не принимается',
  ACCEPT: 'Принять',
  Items: 'Предметы',
  'Skins available': 'Доступно скинов',
  'Show only items with fast delivery': 'Показать только предметы с быстрой доставкой',
};
