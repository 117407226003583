export default {
  '100$ for the new players!': '100 $ dla nowych graczy!',
  '100$ bonus is credited to a demo balance': 'Bonus 100 $ jest dodawany do salda demo',
  'Get 100$ now!': 'Zdobądź 100 $ teraz!',

  'Top up your balance from 25$ and get bonuses': 'Uzupełnij saldo od 25 $ i otrzymaj bonusy',
  'Exp bonus': 'Bonus exp',
  'Wheel Of Fortune free spin': 'Wheel Of Fortune darmowy spin',
  'Free case': 'Darmowe etui',
  'Access to account level-up': 'Dostęp do poziomu konta',
};
