import styled from 'styled-components';

export const Container = styled.div<{ url: string }>`
  border-radius: 8px;
  position: relative;
  background: ${p => `url(${p.url})`} no-repeat center/100%;
  display: flex;
  height: 168px;
  max-width: 168px;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 5px;
  -webkit-box-shadow: 0 5px 75px 23px rgba(34, 60, 80, 30%) inset;
  -moz-box-shadow: 0 5px 75px 23px rgba(34, 60, 80, 30%) inset;
  box-shadow: 0 5px 75px 23px rgba(34, 60, 80, 30%) inset;

  @media only screen and (max-width: 320px) {
    height: 141px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

export const NameSpan = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--color-white);
  text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #060606,
    1px -1px #000, -1px 1px #000;
`;

export const PriceContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-white);
  text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #060606,
    1px -1px #000, -1px 1px #000;
`;

export const GrayComponent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: grayscale(100%);
`;
