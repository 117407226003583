import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { Settings } from 'modules/PersonalArea/components/Settings';

export const SettingsPage: FC = () => {
  return (
    <>
      <AppBar deep />
      <Settings />
    </>
  );
};
