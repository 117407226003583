import * as React from 'react';
import { HistoryTrigger } from './HistoryTrigger';
import { HistoryVerify } from './HistoryVerify';
import { HistoryHeader } from './HistoryHeader';
import { HistoryWinnerProfile } from './HistoryWinnerProfile';
import { UserList } from './UserList';

import { IHistoryItem } from '../interfaces/reducer.history.interface';

interface IHistoryContent extends IHistoryItem {
  gameTypeId: string;
}

export const HistoryContent: React.FC<IHistoryContent> = props => (
  <HistoryTrigger gameTypeId={props.gameTypeId}>
    <HistoryHeader
      gameId={props.gameId || (props.id as number)}
      timeAdded={props.timeAdded}
      totalPrize={props.totalPrize as number}
      coef={props.coef}
      sector={props.sector}
    />
    {props.winnerId && (
      <>
        <HistoryWinnerProfile
          {...props.items[props.winnerId]}
          totalPrize={props.totalPrize as number}
          winnerName={props.winnerName}
        />
        <UserList items={props.items} totalPrize={props.totalPrize as number} />
      </>
    )}

    <HistoryVerify rand={props.rand} hash={props.hash} salt={props.salt} />
  </HistoryTrigger>
);
