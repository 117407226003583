import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components/Loader';
import {
  actionCaseBattleReplay,
  actionRemoveBattleGameData,
  actionRemoveData,
  actionRequestCaseBattle,
} from 'games/CaseBattle/ducks/case-battle-game-duck';
import {
  useCloseToolBar,
  useGetCaseBattleGameData,
  useGetCaseBattleSoundType,
  useGetCasesByIds,
  useGetGameId,
} from 'games/CaseBattle/hooks';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { Container } from './CaseBattleGame.styles';
import { CaseBattleGame } from 'games/CaseBattle/components';
import { EmojiButton } from 'games/CaseBattle/components/CaseBattleGame/components/EmojiButton';
import { RecreateButton } from 'games/CaseBattle/components/CaseBattleGame/components/RecreateButton';
import {
  ActionRemoveRouletteAnimationStatuses,
  actionRemoveRouletteData,
  actionResponseGamesId,
} from 'games/CaseBattle/ducks';
import { Modules } from 'core/interfaces';
import { usePageVisibility } from 'core/AppShell';
import { SoundsSettingsDialogConfig } from 'games/CaseGame';
import { useDialog } from 'core/hooks';

export const CaseBattleGamePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { soundType } = useGetCaseBattleSoundType();
  useCloseToolBar();
  const { handleToggleDialog } = useDialog();
  const history = useHistory();
  const location = useLocation();
  const params: Record<string, string> | undefined = useParams();
  const [isUpIcons, setIsUpIcons] = useState(false);
  const gameId = useGetGameId();
  const isVisible = usePageVisibility();
  const isCalledBattleGameInfo = useRef(false);
  const { isLoading: isLoadingCases } = useGetCasesByIds();
  const {
    isLoading,
    data: { game },
    isReplay,
  } = useGetCaseBattleGameData();
  const [locationReplay, setLocationReplay] = useState(location.state?.isReplay || isReplay);

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: '',
      iconKey: soundType as 'standard',
      type: 'modal',
      isNested: false,
      isNeedAuth: false,
      handler: handleToggleDialog(SoundsSettingsDialogConfig.SOUNDS_DIALOG, true),
      text: t('sounds'),
    },
  ];

  const handleIconsPosition = useCallback((isUp?: boolean) => {
    setIsUpIcons(isUp !== undefined ? isUp : prev => !prev);
  }, []);

  useEffect(() => {
    if (isVisible && params && !isReplay) {
      window.history.replaceState({}, '');
      dispatch(
        actionRequestCaseBattle({
          battleId: +params.battleId,
          isRecreate: !!location.state?.isRecreate,
        })
      );
      isCalledBattleGameInfo.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (params && !isCalledBattleGameInfo) {
      window.history.replaceState({}, '');

      dispatch(
        actionRequestCaseBattle({
          battleId: +params.battleId,
          isRecreate: !!location.state?.isRecreate,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameId && !history.location.pathname.includes(String(gameId))) {
      history.replace({
        pathname: `${Modules.CASE_BATTLE}/${gameId}`,
        state: { isRecreate: location.state?.isRecreate || !!gameId },
      });

      dispatch(actionResponseGamesId(0));
    }
    return () => {
      if (
        !history.location.pathname.includes(Modules.CASE_BATTLE) ||
        history.location.pathname === Modules.CASE_BATTLE ||
        history.location.pathname === `${Modules.CASE_BATTLE}/history`
      ) {
        dispatch(actionRemoveBattleGameData());
        dispatch(actionRemoveRouletteData());
        dispatch(actionRemoveData());
        dispatch(ActionRemoveRouletteAnimationStatuses());
      }
    };
  }, [dispatch, gameId, history, location.state]);

  useEffect(() => {
    if (!isLoadingCases && !isLoading && locationReplay) {
      dispatch(actionCaseBattleReplay({ isReplay: true }));
    }
  }, [dispatch, isLoading, isLoadingCases, locationReplay]);

  useEffect(() => {
    if (isReplay) {
      setLocationReplay(false);
    }
  }, [isReplay]);

  return (
    <>
      {isLoadingCases || isLoading || !game || locationReplay ? (
        <Loader />
      ) : (
        <>
          <AppBar isGame />
          <Container className="content dark-layout">
            <ToolBar toolBarItemsConfig={toolBarConfig} />
            <CaseBattleGame isUpIcons={isUpIcons} />
          </Container>
          <EmojiButton isUpIcons={isUpIcons} handleIconsPosition={handleIconsPosition} />
          <RecreateButton />
        </>
      )}
    </>
  );
};
