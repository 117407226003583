import { AuthDialogs } from '../../core/Auth';
import { TelegramLinkingDialog } from './telegram-linking-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';

DIALOG.add(AuthDialogs.SIGN_IN_TELEGRAM, {
  component: TelegramLinkingDialog,
  transition: transitions.SCALABLE,
  anchor: 'portal',
});
