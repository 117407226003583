import React, { useState } from 'react';
import {
  CaseItemContainer,
  Container,
  CreatedAt,
  Icon,
  InventorySpanContainer,
  ItemContainer,
  ItemIcon,
  ItemIconContainer,
  Line,
  Name,
  PlayIconContainer,
  Price,
  PriceNameContainer,
  Skin,
  StatusContainer,
  StyledButton,
} from './case-history-item.styles';
import { CaseHistoryItemStatus } from 'games/CaseGame/interfaces/cases.interface';
import { SvgIcons } from 'games/CaseGame/assets';
import { useHistory } from 'react-router-dom';
import { CurrencyText } from '@components/currency';
import { convertGMTtoFullDate } from 'games/CaseGame/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actionSellItemInHistoryRequest } from 'games/CaseGame/ducks/case-history-duck/case-history-duck';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface CaseHistoryItemProps {
  style: React.CSSProperties;
  index: number;
  caseImg: string;
  casePrice: number;
  caseItemImage: string;
  openUUID: string;
  itemPrice: number;
  itemSkin: string;
  itemName: string;
  itemId: number;
  caseId: number;
  handleCloseModal: () => void;
  createdAt: Date;
  fullName: string;
  status: CaseHistoryItemStatus;
  userInventoryItemId: number;
}

export const CaseHistoryItem: React.FC<CaseHistoryItemProps> = ({
  style,
  index,
  caseImg,
  caseId,
  handleCloseModal,
  openUUID,
  caseItemImage,
  itemName,
  itemSkin,
  itemPrice,
  createdAt,
  itemId,
  fullName,
  status,
  userInventoryItemId,
}) => {
  const { t } = useTranslation('cases2.0');
  const [statusItem, setStatus] = useState(status);
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectToCaseHandler = () => {
    handleCloseModal();
    history.push(`../cases/${caseId}`);
  };

  const redirectToReplayHandler = () => {
    handleCloseModal();
    history.push(`../cases/${caseId}?uuid=${openUUID}`);
  };

  const redirectToItemDetailsHandler = () => {
    handleCloseModal();
    history.push(`../cases/item/${itemId}`, {
      name: fullName,
      id: itemId,
    });
  };
  const sellItemHandler = () => {
    dispatch(actionSellItemInHistoryRequest(userInventoryItemId));
    setStatus('sold');
  };

  const { DDMMYYY, HHMMSS } = convertGMTtoFullDate(createdAt);

  return (
    <Container data-index={index % 2 === 0 ? 'even' : 'odd'} style={style}>
      <CaseItemContainer onClick={redirectToCaseHandler}>
        <Icon src={caseImg} />
      </CaseItemContainer>
      <PlayIconContainer>
        <SvgIcons.PlayIconHistoryCase onClick={redirectToReplayHandler} />
      </PlayIconContainer>
      <ItemContainer>
        <ItemIconContainer onClick={redirectToItemDetailsHandler}>
          <ItemIcon src={skinItemImage(caseItemImage, 100, 100)} />
        </ItemIconContainer>
        <Line />
        <PriceNameContainer>
          <Name>{itemName}</Name>
          <Skin>{itemSkin}</Skin>
          <Price>
            <CurrencyText value={itemPrice} />
          </Price>
        </PriceNameContainer>
      </ItemContainer>
      <StatusContainer>
        <CreatedAt>
          {DDMMYYY}
          <div>{HHMMSS}</div>
        </CreatedAt>
        <InventorySpanContainer>
          <StyledButton
            disabled={statusItem === 'sold'}
            onClick={sellItemHandler}
            data-item-status={statusItem}
          >
            {statusItem === 'taken' ? t('sell') : t('sold')}
          </StyledButton>
        </InventorySpanContainer>
      </StatusContainer>
    </Container>
  );
};
