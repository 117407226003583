import systemAvatar from '../../assets/images/system.png';
import avatarFallback from '../../assets/images/avatar-fallback.png';
import { skinItemImage } from './skinItemImage.utils';
import { URLS } from 'services/constant-urls';

export const getItemUri = (image: string | null, width: number = 150, height: number = 150) =>
  image
    ? image.startsWith('http') || image.startsWith('//')
      ? image
      : skinItemImage(image, width, height)
    : URLS.moneyIconUrl;

export const getSteamItemImageSrc = (imagePart: string) => {
  if (imagePart?.startsWith('http')) return imagePart;
  return getItemUri(imagePart);
};

export const getUserAvatarUrl = (
  userAvaPart: string | null,
  width: number = 80,
  height: number = 0
) => {
  if (!userAvaPart) return avatarFallback;

  if (userAvaPart.startsWith('https://api.adorable.io/avatars')) {
    const avatar = userAvaPart.split('/');
    const path = avatar[avatar.length - 1];
    return `https://api.adorable.io/avatars/${width}/${path}`;
  }

  if (
    userAvaPart.startsWith('data:') ||
    userAvaPart.startsWith('http') ||
    userAvaPart.startsWith('//') ||
    userAvaPart.startsWith('https')
  ) {
    if (userAvaPart.startsWith('https://din8r827idtuo.cloudfront.net/')) {
      const [avatarSource, ...query] = userAvaPart.split('?');
      query.push(`width=${width}`);
      query.push(`height=${height ? height : width}`);
      return `${avatarSource.replace(
        'https://din8r827idtuo.cloudfront.net/',
        'https://de4khei8i4ut2.cloudfront.net/'
      )}?${query.join('&')}`;
    }
    return userAvaPart;
  }

  if (userAvaPart === 'system') return systemAvatar;
  return `https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/${userAvaPart}_full.jpg`;
};

export const getBase64File = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({ base64: reader.result, name: file.name });
    reader.onerror = error => reject(error);
  });
};
