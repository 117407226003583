import styled from 'styled-components';

export const Container = styled.div`
  height: 30px;
  display: flex;
  width: 100%;
  gap: 4px;
  flex: 1;
  align-items: center;
`;
export const TeamSpan = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  letter-spacing: 0.1px;
  color: var(--color-gray-200);

  @media (max-width: 375px) {
    font-size: 10px;
  }

  @media (max-width: 350px) {
    font-size: 8px;
  }
`;
