import { AnimatedRouter } from '@components/animated-router';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { EventPage } from './pages/event.page';

export const ComicsHeroesEventRouter: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <AnimatedRouter>
      <Route path={match.url} exact component={EventPage} />
    </AnimatedRouter>
  );
};
