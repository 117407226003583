export default {
  Language: '语言',
  'Bets in current room': '在当前房间中下注',
  Timers: '计时器',
  'Game start': '游戏开始',
  'Game ending': '游戏结束',
  'Roulette ticking': '轮盘转动',
  'Roulette start': '輪盤開始',
  'Item set': 'Item set',
  'My bet': '我的賭注',
  'Coins transfer': '轉移錢幣',
  'Start button': '開始鍵',
  'Stop button': '停止鍵',
  Countdown: '倒數',
  Soundtrack: '音軌',
  Crash: '爆炸時刻',
  'Picking item': '挑選道具',
  Crafting: '製作中',
  Failure: '失敗',
  Win: '奖品',
  Sell: '販售',
  Take: '收取',
  'Rooms bets': '房间下注',
  'Chat message sending': '发送聊天消息',
  'Chat message receiving': '接收聊天消息',
  Sound: '音效',
  'Chat sound': '聊天音效',
  'Game sounds': '遊戲音效',
  Volume: '音量',
};
