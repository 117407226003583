import reducer, { eventsTypes } from './duck';
import './locales';
export { GameRouter as ClassicRouter } from './containers';

// export const ClassicRouter = lazy(() => import('./containers').then(module => ({ default: module.GameRouter })))

export const classicModule = {
  moduleName: 'classic',
  moduleId: 1,
  path: '/classic',
  type: 'pvp',
  reducer,
  eventsTypes,
};
