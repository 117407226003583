import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTakeWinnerLot } from '../../ducks/raffles.duck';

export const useTakeAction = () => {
  const dispatch = useDispatch();

  const handleTakeAt = useCallback(
    (raffleId: number, itemId: number) => () => {
      dispatch(fetchTakeWinnerLot(raffleId, itemId));
    },
    [dispatch]
  );

  return [handleTakeAt];
};
