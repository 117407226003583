import Immutable from 'immutable';
export interface UserSettings {
  loading: boolean;
  currency: string | null;
  locale: string | null;
  market: {
    autoApprove: boolean;
    steamApiKey: string | null;
    steamAppId: number | null;
    steamTradeLink: string | null;
    tradesDuration: string | null;
  };

  privacy: {
    socialsIsHidden: boolean;
    statisticsIsHidden: boolean;
  };
  refillCountry: string | null;
  theme: string | null;
}

export const userSettingsRecord = Immutable.Record<UserSettings>({
  loading: false,
  currency: null,
  locale: null,
  market: {
    autoApprove: false,
    steamApiKey: null,
    steamAppId: null,
    steamTradeLink: null,
    tradesDuration: null,
  },
  privacy: {
    socialsIsHidden: false,
    statisticsIsHidden: false,
  },
  refillCountry: null,
  theme: null,
});
