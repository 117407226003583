import React from 'react';
import { Modal } from 'core/Modals/container';
import { Purchase } from './purchase';
import { leaderModals } from '../../../modal.config';
import { transitions } from 'core/Modals';

export const PurchaseModal = () => (
  <Modal
    modalComponent={Purchase}
    modalName={leaderModals.PURCHASE}
    anchor="portal-leaders"
    transition={transitions.SLIDE_UP}
  />
);
