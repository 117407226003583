import { useSelector } from 'react-redux';
import {
  getErrorText,
  getInventoryFailure,
  getInventoryLoaded,
  getInventoryLoading,
  getInventoryMetaData,
} from '../../selector';

export const useInventoryMetaData = () => ({
  meta: useSelector(getInventoryMetaData),
  loading: useSelector(getInventoryLoading),
  loaded: useSelector(getInventoryLoaded),
  failure: useSelector(getInventoryFailure),
  errorText: useSelector(getErrorText),
});
