import { combineReducers } from 'redux';
// import { combineEpics } from 'redux-observable'
import { history } from './ducks/history.duck';
import { raffles } from './ducks/raffles.duck';
import { quests, updateQuests } from './ducks/quests.duck';
import { races } from './ducks/tournaments.duck';
import { coupons } from './ducks/coupons.duck';
import { store } from './ducks/store.duck';
import { statistics } from './ducks/statistics.duck';
import { IEventsTypes } from 'core/rootInterfaces/root.interface';

export default combineReducers({
  races,
  quests,
  coupons,
  history,
  raffles,
  store,
  statistics,
});

export const eventsTypes: Array<IEventsTypes> = [
  {
    event: 'leaders-race.quest:update',
    action: updateQuests,
  },
];

// export const leaderRaceEpics = combineEpics(raffleEpic, statisticEpic, storeEpics, ticketsEpic)
