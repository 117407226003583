import { IPlayer } from './top.reduce.interface';

export enum TopActions {
  UPDATE = 'app/top/UPDATE',
  CLEAR = 'app/top/CLEAR',
}

interface IClearPlayersAction {
  type: typeof TopActions.CLEAR;
  status: string;
}

interface IUpdateAction {
  type: typeof TopActions.UPDATE;
  payload: {
    players: IPlayer[];
  };
}

export type TopAllActions = IClearPlayersAction | IUpdateAction;

export interface IUpdateData {
  players: IPlayer[];
  statistics: {
    maxGame: number;
    totalGamesPlayed: number;
    totalItems: number;
    totalUniquePlayers: number;
  };
  timeToEndOfDay: number;
  withGifts: boolean;
}
