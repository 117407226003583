import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { BalancePanel, BalanceActions, BalanceNavigate } from '../../components/Balance';

import { useChangeBalanceFacade } from '../../hooks';

export const BalancePage: FC = () => {
  const { handleSetCurrency, currencySettings, currentCurrency } = useChangeBalanceFacade();
  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <BalancePanel
          handleSetCurrency={handleSetCurrency}
          currencySettings={currencySettings}
          currentCurrency={currentCurrency}
        />
        <BalanceActions />
        <BalanceNavigate />
      </div>
    </>
  );
};
