import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Description, Title, Rules } from './stage3.styles';
import { StageHeader } from '../stage-header';
import { EventIcons } from '../../../assets';

export const Stage3 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StageHeader />
      <EventIcons.Slide3 />
      <Description>
        <Title>{t('special')}</Title>
        <Rules>
          <span>
            <Trans i18nKey="availableQuest" t={t}>
              Available after completing all
              <span className="accent-first">weekly</span> quests.
            </Trans>
          </span>{' '}
          <span>
            <Trans i18nKey="completeSpecialQuest" t={t}>
              For completing the <span className="accent-second">special</span> quest, you will
              receive 1<span className="accent-second">golder grenade</span>.
            </Trans>
          </span>{' '}
          <span>
            <Trans i18nKey="blockedSpecialQuest" t={t}>
              You won't know what the special quest is until you complete all the
              <span className="accent-first">weekly</span> quests!
            </Trans>
          </span>{' '}
          <span>{t('remember')}</span>
        </Rules>
      </Description>
    </Container>
  );
};
