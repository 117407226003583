import React from 'react';
import { Icon, Preload } from './Loader.styles';
import logo from 'assets/images/logo-go.png';
import loading from 'assets/images/loading.gif';
// import { loader } from 'modules/event-module';

export const Loader = React.memo(() => {
  return (
    <Preload>
      <Icon src={loading} />
      <img src={logo} alt="" width="150px" height="41px" />
    </Preload>
  );
});
