import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchBindingUserProvider } from 'core/User/ducks/duck';
import { getUserDetails } from 'core/User';

export const useUserBindingProvider = (social: string) => {
  const dispatch = useDispatch();

  const provider = useSelector(
    (state: any) => getUserDetails<'providers'>(state, 'providers'),
    shallowEqual
  )[social];

  const handleConfirmBinding = useCallback(
    (code: string) => () => {
      dispatch(fetchBindingUserProvider(code));
    },
    [dispatch]
  );

  return {
    provider,
    handleConfirmBinding,
  };
};
