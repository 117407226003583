export default {
  '100$ for the new players!': '100 $ für die neuen Spieler!',
  '100$ bonus is credited to a demo balance':
    'Ein 100-Dollar-Bonus wird einem Demo-Guthaben gutgeschrieben',
  'Get 100$ now!': 'Holen Sie sich jetzt 100 $!',

  // refill modal
  'Top up your balance from 25$ and get bonuses':
    'Laden Sie Ihr Guthaben von 25 $ auf und erhalten Sie Boni',
  'Exp bonus': 'Exp Bonus',
  'Wheel Of Fortune free spin': 'Glücksrad Freispiel',
  'Free case': 'Freier Fall',
  'Access to account level-up': 'Zugriff auf Kontoebene',
  Cashback: 'Cashback',
  'Refill balance': 'Balance nachfüllen',
};
