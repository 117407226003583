import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import { useOrder, usePayoutFee } from '../../../hooks';
import { WithdrawalDialogs } from '../../../withdrawal-dialogs.config';
import { useParams } from 'react-router';

interface IPayoutFee {
  feeSelectors: string[];
}

export const PayoutFee: FC<IPayoutFee> = ({ feeSelectors }) => {
  const { t } = useTranslation();
  const { method } = useParams();
  const { fee } = usePayoutFee();
  const { order } = useOrder();

  const { handleToggleDialog } = useDialog(WithdrawalDialogs.FEE_SELECTORS);
  const feeKey = feeSelectors[order.feeIndex];
  const choose = fee[feeKey];

  return (
    <>
      <Container>
        <div className="fee-title">{t('Select the network fee')}</div>
        <NavigationLink
          onClick={handleToggleDialog(WithdrawalDialogs.FEE_SELECTORS, {
            method,
          })}
          disabled={!choose}
        >
          <div>
            <span>
              {t(feeKey)}
              {choose && (
                <>
                  {' - '}
                  <CurrencyText value={choose?.payment} />
                  {' ('}
                  {choose?.percent}
                  {'%)'}
                </>
              )}
            </span>
            <span className="badge-light">
              {t('Estimated transfer time:')} {choose?.waitingTime}
            </span>
          </div>
        </NavigationLink>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
  margin-top: 2.4rem;

  & .fee-title {
    margin: 0 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: var(--color-gray-200);
    text-transform: uppercase;
  }

  ${NavigationLink} {
    background: var(--color-dark-600);
  }
`;
