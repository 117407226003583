import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { textLimit } from '../../../../core/utils/text.utils';
import { getItemUri } from '../../../../core/utils/image.utils';
import { CurrencyText } from '../../../currency/index';

// import checkedIcon from '../../assets/checked.svg'
// import realIcon from '../../assets/real.svg'
// import basketIcon from '../../assets/basket.svg'

export class TradeItem extends PureComponent {
  render() {
    const { name, icon, price } = this.props;
    return (
      <Container>
        {/*{!onlySell && <SelectItem isSelect={isSelect} />}*/}
        {/*{!onlySell && <TypeSkin src={type === 'market' ? basketIcon : realIcon} />}*/}
        <SkinIcon src={getItemUri(icon)} />
        <SkinTitle>{textLimit(name, 10)}</SkinTitle>
        <SkinPrize>
          <CurrencyText value={price} />
        </SkinPrize>
      </Container>
    );
  }

  changeItemToCart = () => {
    const { onlySell, addToCart, removeToCart, isSelect, id } = this.props;

    if (onlySell) return;

    if (isSelect) return removeToCart(id);

    return addToCart(id);
  };
}

const width = window.innerWidth;
const widthItem = (width - 36) / 3;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  flex-shrink: 0;

  width: ${widthItem - 6}px;
  height: ${widthItem - 6}px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  background-color: var(--color-gray-950);

  border-top: 2px;
  border-top-color: var(--color-white);
  border-top-style: solid;
  margin: 3px;
  padding: 3px;

  position: relative;
`;

const SkinIcon = styled.img`
  width: 100%;
  height: 50%;
  object-fit: contain;
`;

const SkinTitle = styled.span`
  font-size: 12px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #9ea7b3;
`;

const SkinPrize = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-align: center;
  color: var(--yellow);
`;

// const SelectItem = styled.span`
//   width: 15px;
//   height: 15px;
//   border: 1px solid var(--color-white);
//   border-radius: 50%;
//
//   position: absolute;
//   left: 4px;
//   top: 4px;
//
//   &:before {
//     content: '';
//     width: 15px;
//     height: 15px;
//     display: block;
//     position: absolute;
//     top: 3px;
//     left: 2px;
//     background-image: ${({ isSelect }) => (isSelect ? `url(${checkedIcon})` : 'none')};
//   }
//
//   ${({ isSelect }) =>
//     isSelect &&
//     `
//     border: 1px solid #2ab6d5;
//     background-color: #2ab6d5;
//
//   `}
// `

// const TypeSkin = styled.img`
//   width: 15px;
//   height: 15px;
//   position: absolute;
//   right: 4px;
//   top: 4px;
// `
