import React, { FC, memo, useEffect, useRef } from 'react';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { Container } from './PlayerItemCardSkeleton.styles';

interface PlayerItemCardSkeletonProps {
  cardHeight: number;
  hasRounds: boolean;
  cardWidth: number;
  setCardWidth: (w: number) => void;
}

export const PlayerItemCardSkeleton: FC<PlayerItemCardSkeletonProps> = memo(
  ({ cardHeight, hasRounds, cardWidth, setCardWidth }) => {
    const containerRef = useRef<HTMLDivElement>();

    useEffect(() => {
      const updateCardWidth = () => {
        if (containerRef.current) {
          setCardWidth(containerRef.current.offsetWidth);
        }
      };

      updateCardWidth();

      window.addEventListener('resize', updateCardWidth);

      return () => {
        window.removeEventListener('resize', updateCardWidth);
      };
    }, [setCardWidth]);

    return (
      <Container
        cardHeight={cardHeight}
        cardWidth={cardWidth}
        hasRounds={hasRounds}
        ref={containerRef}
        className="skeleton"
      >
        <CaseBattleIcon.Weapon />
      </Container>
    );
  }
);
