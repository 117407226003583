import { Reducer } from 'redux';
import { Record } from 'immutable';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, iif, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  mergeMap,
  startWith,
  switchMap,
} from 'rxjs/operators';
import storeRepository from '../repositories/leaders.store.repository';
import { addNotification } from '../../Notifications/duck';
import { storeActions, storeActionsTypes } from '../interfaces/actions.leaders.interfaces';
import {
  IStoreRecord,
  IStoreTypeRecord,
  SteamAppId,
} from '../interfaces/reducer.leaders.interfaces';
import { fetchTickets } from './coupons.duck';

const StoreTypeRecord = Record<IStoreTypeRecord>({
  loading: false,
  loaded: false,
  items: [],
});

const StoreRecord = Record<IStoreRecord>({
  [SteamAppId.CSGO]: new StoreTypeRecord(),
  // [SteamAppId.DOTA2]: new StoreTypeRecord(),
  // [SteamAppId.GOLD]: new StoreTypeRecord(),
});

export const store: Reducer<Record<IStoreRecord>, storeActions> = (
  state = new StoreRecord(),
  action
) => {
  switch (action.type) {
    case storeActionsTypes.FETCH_STORE_ITEMS: {
      return state.setIn([action.payload.appId, 'loading'], true);
    }

    case storeActionsTypes.UPDATE_STORE_ITEMS: {
      return state
        .setIn([action.payload.appId, 'loading'], false)
        .setIn([action.payload.appId, 'loaded'], true)
        .setIn([action.payload.appId, 'items'], action.payload.items);
    }

    default: {
      return state;
    }
  }
};

const fetchStoreItems = (appId: SteamAppId) => ({
  type: storeActionsTypes.FETCH_STORE_ITEMS,
  payload: {
    appId,
  },
});

const updateStoreItems = (appId: SteamAppId, items: any[]) => ({
  type: storeActionsTypes.UPDATE_STORE_ITEMS,
  payload: {
    items,
    appId,
  },
});

export const fetchPurchaseProduct = (
  itemName: string,
  appId: number,
  ticketColor: string,
  itemId?: number
) => ({
  type: storeActionsTypes.FETCH_PURCHASE_ITEM,
  payload: {
    itemName,
    appId,
    ticketColor,
    itemId,
  },
});

const loaderStoreEpic: Epic = action$ =>
  action$.pipe(
    ofType('INITIALIZE_LEADER_RACE'),
    switchMap(() =>
      from([/*SteamAppId.DOTA2,*/ SteamAppId.CSGO]).pipe(
        mergeMap(appId =>
          iif(
            () => appId === SteamAppId.GOLD,
            storeRepository.fetchStoreGoldItems(),
            storeRepository.fetchStoreItems(appId)
          ).pipe(
            startWith(fetchStoreItems(appId)),
            map(({ response }) => updateStoreItems(appId, response)),
            catchError(({ error }) =>
              of(
                addNotification({
                  type: 'error',
                  body: error,
                })
              )
            )
          )
        )
      )
    )
  );

const purchaseEpic: Epic = action$ =>
  action$.pipe(
    ofType(storeActionsTypes.FETCH_PURCHASE_ITEM),
    debounceTime(700),
    mergeMap(({ payload }) =>
      iif(
        () => !!payload?.itemId,
        storeRepository.fetchPurchaseGoldProduct(payload.itemId),
        storeRepository.fetchPurchaseProduct(payload.itemName, payload.appId, payload.ticketColor)
      ).pipe(
        concatMap(({ response }) => [
          addNotification({
            type: response.success ? 'success' : 'error',
            body: response?.error,
          }),
          fetchTickets(),
        ]),
        catchError(({ error }) =>
          of(
            addNotification({
              type: 'error',
              body: error,
            })
          )
        )
      )
    )
  );

export const storeEpics = combineEpics(loaderStoreEpic, purchaseEpic);
