import * as React from 'react';
import { ITrades } from '../interfaces/reducer.fast.interface';

const { useState, useEffect } = React;
export const usePlayerTrade = (trades: ITrades) => {
  const [sortedTradeId, setTradeId] = useState<string[]>(Array.from({ length: 3 }));

  useEffect(() => {
    const keys = Object.keys(trades);
    const sortedTradeId = keys.sort((a, b) => {
      return trades[a].id < trades[b].id ? -1 : 1;
    });
    setTradeId(Array.from({ length: 3 }, (_, i) => sortedTradeId[i]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trades]);

  return {
    sortedTradeId,
  };
};
