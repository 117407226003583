import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser, useUserMailboxForm } from 'core/User';
import { SettingsNavigate } from '../SettingsNavigate/SettingsNavigate';
import { CheckBoxWrap, ConfirmEmailWrap, EmailWrap, Wrapper } from './Settings.styles';
import { EmailStatus } from '../../SelfExclusion/EmailStatus';
import { EmailBlock } from '../../SelfExclusion/EmailBlock';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { CheckBoxTitle } from 'dialogs/profile-email-dialog/profile-email-dialog/profile-email-dialog.styles';

export const Settings = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  const {
    form: { register },
    onUpdateSubscribe,
  } = useUserMailboxForm();

  const { activeDialogs, handleCloseByName, handleToggleDialog } = useDialog();

  useEffect(() => {
    return () => {
      activeDialogs.forEach(item => handleCloseByName(item));
    };
  }, [activeDialogs, handleCloseByName]);

  return (
    <Wrapper className="content">
      {user.email.status !== 'confirmed' && (
        <ConfirmEmailWrap>
          <EmailBlock handleOpen={handleToggleDialog(userModals.PROFILE_EMAIL, true)} />
        </ConfirmEmailWrap>
      )}
      <SettingsNavigate />
      {user.email.status === 'confirmed' && (
        <EmailWrap>
          <EmailStatus
            handleOpen={handleToggleDialog(userModals.PROFILE_CHANGE_EMAIL, true)}
            isEditable
          />
          <CheckBoxWrap>
            <input
              {...register('isSubscribed')}
              id="isSubscribed"
              name="isSubscribed"
              ref={register()}
              type="checkbox"
              onChange={e => onUpdateSubscribe(e.target.checked)}
            />
            <label htmlFor="isSubscribed">
              <CheckBoxTitle>{t('Subscribe to email notifications')}</CheckBoxTitle>
            </label>
          </CheckBoxWrap>
        </EmailWrap>
      )}
    </Wrapper>
  );
};
