import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { animated, config, useSpring } from 'react-spring';
import BezierEasing from 'bezier-easing';
import { useTranslation } from 'react-i18next';
import { Timer } from '@components/TimerEvent';
import { CurrencyText } from '@components/currency';
import { LotteryType } from 'core/interfaces';
import { useActingLotteryElement } from 'core/hooks';
import { getItemUri } from 'core/utils/image.utils';
import { URLS } from 'services/constant-urls';

interface IActingLottery {
  type: LotteryType;
}

const LOTTERY_WITH_TICKETS = [LotteryType.DEPOSIT, LotteryType.WEEKLY, LotteryType.MONTHLY];

const members = Array.from({ length: 20 });

export const ActingLottery: FC<IActingLottery> = ({ type }) => {
  const { data } = useActingLotteryElement(type);
  const { t } = useTranslation('lottery');
  const invited = !!data?.deposit?.userTickets;
  const winnerAvatar = data.winnerInfo?.ava_link || URLS.avatarUrl;

  const [{ transform, height, opacity }, set] = useSpring(() => ({
    transform: 'translateY(-25px)',
    height: 50,
    opacity: 0,
  }));

  useEffect(() => {
    if (!data.winnerInfo?.id) return;
    const d = Date.now() - +new Date(data.gameEndTimestamp);
    set({
      transform: 'translateY(-1185px)',
      config: {
        duration: 4000,
        easing: BezierEasing(0.68, -0.05, 0.32, 1.05),
      },
      onRest(ds) {
        set({
          transform: 'translateY(-1229px)',
          height: 138,
          opacity: 1,
          config: {
            ...config.slow,
            delay: 4000,
          },
          immediate: d > 60000 || d < 0,
        });
      },
      immediate: d > 60000 || d < 0,
    });
  }, [data.winnerInfo, data.gameEndTimestamp, set]);

  return (
    <Container className={type}>
      <div className="lottery-type">
        <span>
          {t('lottery', {
            context: type,
            amount: 500,
            postProcess: 'with-template',
          })}
        </span>
        <span>
          <Timer finishEvent={data.gameEndTimestamp} />
        </span>
      </div>
      <div className="lottery-element">
        {LotteryType.FREE === type && (
          <span className="members">
            <i className="icon-users" />
            {data?.tickets}
          </span>
        )}
        <div className="thumbnail">
          <img
            srcSet={`${getItemUri(data.gameInfo.prizeImage, 280, 170)} 2x`}
            src={getItemUri(data.gameInfo.prizeImage, 140, 90)}
            alt=""
          />
          <div className="prize-name">{data.gameInfo.prizeName}</div>
        </div>
        <div className={`inner-container ${data.winnerInfo?.id ? 'raffling' : ''}`}>
          <div className="details-container">
            <span className="details-container-price">{t('Prize')}</span>
            <span className="details-container-value">
              <CurrencyText value={data.gameInfo.prize} />
            </span>
            {LOTTERY_WITH_TICKETS.includes(type) && (
              <span className="details-container-tickets">
                <i className="icon-lottery-ticket" />
                <span>
                  {invited && <>{data.deposit.userTickets}/</>}
                  {data.tickets}
                </span>
              </span>
            )}
            {LotteryType.FREE === type && (
              <span className="details-container-invited">
                {invited && <span>{t('You are already participating')}</span>}
              </span>
            )}
          </div>
          <div className="tape-container">
            <animated.div
              className="pointers-container"
              style={{ opacity: opacity.interpolate((v: any) => `${1 - v}`) }}
            >
              <i className="icon-cursor" />
              <div className="pointers-container-dotted">
                <span />
                <span />
                <span />
              </div>
              <i className="icon-cursor" />
            </animated.div>
            <animated.div key={data.players.length} className="raffle" style={{ transform }}>
              {members.map((_, i, arr) => (
                <img
                  key={data.players.length + i}
                  src={
                    data.players[(i % data.players.length) - arr.length + arr.length]?.ava_link ||
                    winnerAvatar
                  }
                  alt=""
                />
              ))}
              <div className="winner-member">
                <animated.div className="winner-member-info" style={{ height, opacity }}>
                  <span className="title">{t('Won')}</span>
                  <i className="icon-award-symbol" />
                  <span className="player-name">{data.winnerInfo?.userName}</span>
                </animated.div>
                <img src={winnerAvatar} alt="" />
              </div>
              <img src={winnerAvatar} alt="" />
            </animated.div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding: 0 1rem;

  &.free {
    --color-type: var(--color-free-lottery);
  }

  &.deposit {
    --color-type: var(--color-deposit-lottery);
  }

  &.weekly {
    --color-type: var(--color-weekly-lottery);
  }

  &.monthly {
    --color-type: var(--color-monthly-lottery);
  }

  & .lottery-type {
    display: flex;
    justify-content: space-between;

    & span {
      font-size: 1rem;
      line-height: 1.125rem;
      color: var(--color-type);

      @media screen and (min-width: 375px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }

  & .lottery-element {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    grid-gap: 1.5rem;
    width: 100%;
    height: 140px;
    border: 1px solid var(--color-type);
    border-radius: var(--size-radius-m);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    background: radial-gradient(76.43% 137.86% at 50.15% 0%, var(--color-type) 0.17%, #131a1d 100%);
    overflow: hidden;
    position: relative;

    & .members {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.25rem;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 1rem;
      letter-spacing: 0.3px;
      color: var(--color-white);

      position: absolute;
      bottom: 0.25rem;
      left: 0.5rem;

      & i {
        font-size: 1rem;
        color: inherit;
      }
    }

    & .thumbnail {
      display: flex;
      flex-flow: column;
      overflow: hidden;
      padding: 1rem 0 1rem 1rem;

      & img {
        width: 100%;
        height: 80px;
        object-fit: contain;
        overflow: hidden;
      }

      & .prize-name {
        margin-top: 0.125rem;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        letter-spacing: 0.1px;
        color: var(--color-white);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    & .inner-container {
      position: relative;

      &.raffling .details-container {
        opacity: 0;
        transform: translate3d(0, -140px, 0);
        transition: all 120ms ease-in;
      }

      &.raffling .tape-container {
        opacity: 1;
      }

      & .details-container {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: min-content min-content 1fr;
        grid-gap: 0.5rem;
        padding: 1.75rem 1rem 1rem 0;
        height: 100%;

        &-price,
        &-value {
          color: var(--color-white);
        }

        &-price {
          font-size: 0.875rem;
          line-height: 1rem;
          letter-spacing: 0.24px;
        }

        &-value {
          font-weight: bold;
          font-size: 1.875rem;
          line-height: 2rem;
          letter-spacing: 0.54px;
        }

        &-tickets {
          align-self: center;
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: min-content;
          grid-template-rows: min-content;
          grid-gap: 0.5rem;

          & i {
            font-size: 1.25rem;
            color: var(--color-type);
          }

          & span {
            align-self: center;
            font-size: 0.875rem;
            color: var(--color-white);
          }
        }

        &-invited {
          align-self: center;
          font-size: 0.875rem;
          color: var(--color-white);
        }
      }

      & .tape-container {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity 120ms ease-in;

        & .raffle {
          display: grid;
          grid-auto-flow: row;
          grid-auto-rows: min-content;
          grid-template-columns: 50px;
          grid-gap: 0.5rem;
          position: relative;
          top: 50%;

          & .winner-member {
            position: relative;

            &-info {
              display: grid;
              grid-auto-flow: row;
              grid-template-rows: 1rem 1rem;
              grid-gap: 80px;
              width: 100px;
              align-content: center;
              position: relative;
              left: 50%;
              transform: translate3d(-50%, 0, 0);

              & span {
                text-align: center;
                color: var(--color-white);
              }

              & .player-name,
              & .title {
                display: block;
                width: 100px;
                max-width: 100px;
                font-size: 0.75rem;
                line-height: 1rem;
                letter-spacing: 0.1px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              & .icon-award-symbol {
                font-size: 6.25rem;
                position: absolute;
                left: 50%;
                top: 54%;
                transform: translate3d(-50%, -50%, 0);
                color: var(--color-white);
              }
            }

            & img {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate3d(0, -50%, 0);
            }
          }

          & img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 0.125rem solid var(--color-white);
            padding: 0.125rem;
          }
        }
      }

      & .pointers-container {
        display: grid;
        grid-template-columns: min-content 120px min-content;
        grid-gap: 0.125rem;
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translate3d(0, -50%, 0);

        & .icon-cursor {
          font-size: 1.125rem;
          color: var(--color-white);

          &:last-of-type {
            transform: rotate(180deg);
          }
        }

        &-dotted {
          display: grid;
          grid-template-columns: 0.25rem 1fr 0.25rem;
          grid-template-rows: 0.125rem;
          grid-gap: 0.125rem;
          align-self: center;

          & span {
            border-radius: 0.125rem;
            background: var(--color-white);

            &:nth-child(2) {
              background: rgba(255, 255, 255, 0.4);
            }
          }
        }
      }
    }
  }
`;
