import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <Container>
        <RulesP>
          <RulesSpan>Tower Game </RulesSpan>-{' '}
          {t(
            'is a classic mode to climb to the top of the tower and collect a coin chest guessing the winning sectors.'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How to play Tower Game?')} </RulesSpan>
          <RulesSpan>{t('Choose the complexity you need:')} </RulesSpan>
          {t(
            'in this mode, the tower has three columns with two winning sectors in each line. In light mode you have the highest chance to get to the top!'
          )}
        </RulesP>

        <RulesP>
          {t('Easy')}
          {' - '}
          {t(
            'in this mode, the tower has three columns with two winning sectors in each line. In light mode you have the highest chance to get to the top!'
          )}
        </RulesP>

        <RulesP>
          {t('Medium')}
          {' - '}
          {t(
            'in the middle mode two columns with one losing sector in the line. Chances for each line are 50/50.'
          )}
        </RulesP>

        <RulesP>
          {t('Hard')}
          {' - '}
          {t(
            'there are also three columns, but unlike previous modes in each line only one winning sector. This mode is for real luckies! With increasing complexity, the reward for passing the tower also increases!'
          )}
        </RulesP>

        <RulesP>
          {t(
            "Having chosen the appropriate complexity, you only need to determine the bet and try your luck by opening the winning sectors in each line from the bottom of the tower. The amount of winnings for each line depends on the size of the bet. The higher the bet, the higher the win, it's easy! You can stop at any step in the tower and withdraw the winnings."
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Fair play')} </RulesSpan> <br />
          {t('You can independently verify the correct definition of the lucky sector.')}
        </RulesP>

        <RulesP>
          {t(
            'To do this, at the end of the round, take the number that was encrypted, add to it the hash salt (without a space between the number and the salt) and re-encode it using any of the online services, for example http://sha224.net.'
          )}
        </RulesP>

        <RulesP>
          {t(
            "You will see the same value of 'hash' in the beginning of the round. This means that the result of the game was not adjusted."
          )}
        </RulesP>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding: 16px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 14px;
`;

const RulesSpan = styled.span`
  color: #33ffff;
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;
