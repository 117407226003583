import { SkeletonElement, TabBar } from '@UIkit';
import styled from 'styled-components';

export const TabItemSkeleton = styled(SkeletonElement)`
  width: 50px;
  height: 30px;
  background: var(--color-dark-200);
  border-radius: 8px;
`;

export const TabItem = styled.div`
  &[data-disable='disable'] {
    pointer-events: none;
  }

  &[data-event='true'] {
    color: #f9741c !important;
  }
`;

export const TabBarCategory = styled(TabBar)`
  &.tablet {
    @media screen and (min-width: 500px) {
      width: 100%;
    }
  }
`;
