import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  gap: 15px;
  width: 50%;
  left: -5px;
  transition: top 0.3s, opacity 0.3s;

  &[data-roulette-status='hide'] {
    opacity: 0;
    top: 52px;
    pointer-events: none;
  }

  &[data-roulette-status='show'] {
    opacity: 1;
    top: 0;
  }
`;
