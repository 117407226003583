import React, { useCallback } from 'react';
import { CustomItemInfo } from 'games/CaseGame/interfaces/cases.interface';
import { isEqual } from 'lodash';
import { CaseEnum } from 'games/CaseGame/constants';
import { useHistory } from 'react-router-dom';
import { CaseItemDefault } from '@components/CaseItemDefault';

interface CaseItemProps {
  icon: string;
  type: string;
  color: string | null;
  fullName: string | null;
  customItemInfo: CustomItemInfo[];
  id: number;
  name: string;
  isFree: boolean;
  caseType: CaseEnum;
}
//NOTE:this type was created to possibly extend component type

export const CaseItem: React.FC<CaseItemProps> = React.memo(
  ({ customItemInfo, icon, type, color, fullName, id, name, isFree, caseType }) => {
    const history = useHistory();

    const redirectHandler = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        history.push(`/cases/item/${id}`, {
          name: name,
          id: id,
        });
      },
      [id, name, history]
    );

    return (
      <CaseItemDefault
        fullName={fullName}
        color={color}
        icon={icon}
        redirectHandler={redirectHandler}
        type={type}
        customItemInfo={customItemInfo}
        isFree={isFree}
        caseType={caseType}
      />
    );
  },
  isEqual
);
