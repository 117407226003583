import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerForTime, Time, Times } from './header.styles';
import { useGetEventTime } from 'modules/event-module/comics-event/hooks';

export const Header = () => {
  const { t } = useTranslation();
  const {
    time: { days, hours, minutes, seconds },
  } = useGetEventTime();

  return (
    <Container>
      {typeof days === 'number' && (
        <Times>
          <Time>
            <ContainerForTime>
              <span className="time">{days}</span>
              <span className="time-color">
                {t('pluralize time.days', {
                  ns: 'marketplace',
                  count: days,
                  postProcess: 'interval',
                })}
              </span>
            </ContainerForTime>
            <span>:</span>
          </Time>

          <Time>
            <ContainerForTime>
              <span className="time">{hours}</span>
              <span className="time-color">
                {t('pluralize time.hours', {
                  ns: 'marketplace',
                  count: hours,
                  postProcess: 'interval',
                })}
              </span>
            </ContainerForTime>
            <span>:</span>
          </Time>

          <Time>
            <ContainerForTime>
              <span className="time">{minutes}</span>
              <span className="time-color">
                {t('pluralize time.minutes', {
                  ns: 'marketplace',
                  count: minutes,
                  postProcess: 'interval',
                })}
              </span>
            </ContainerForTime>
            <span>:</span>
          </Time>

          <Time>
            <ContainerForTime>
              <span className="time">{seconds}</span>
              <span className="time-color">
                {t('pluralize time.seconds', {
                  ns: 'marketplace',
                  count: seconds,
                  postProcess: 'interval',
                }).slice(0, 3)}
              </span>
            </ContainerForTime>
          </Time>
        </Times>
      )}
    </Container>
  );
};
