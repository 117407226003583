import './locales';
import { eventsTypes, reducer } from './duck';

export type { IPersonalArea } from './interfaces/personalArea.reducer.interfaces';
export { bonusCodeEpic } from './duck';
export { ProfileRouter } from './containers';
// export const ProfileRouter = lazy(() => import('./containers').then(module => ({ default: module.ProfileRouter })))
export const personalAreaModule = {
  moduleName: 'personal',
  path: '/personal',
  reducer,
  eventsTypes,
};
