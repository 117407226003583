import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';
import { useTranslation } from 'react-i18next';

import { HistoryNavigateLink } from '../../components';
import { useRaffleHistoryFacade } from '../../hooks';
import { BigPreloader } from '@components/Preloader';

export const RafflesHistoryNavigatePage: React.FC<RouteComponentProps> = ({ match }) => {
  const { raffleHistory, userid } = useRaffleHistoryFacade();
  const { t } = useTranslation();

  if (raffleHistory.loading && !raffleHistory.loaded) {
    return (
      <Container>
        <BigPreloader />
      </Container>
    );
  }

  if (raffleHistory.loaded && !raffleHistory.entities.length) {
    return (
      <Container>
        <NotLent> {t('History is empty')} </NotLent>
      </Container>
    );
  }

  return (
    <Container>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowCount={raffleHistory.entities.length}
            rowHeight={70}
            overscanRowCount={10}
            rowRenderer={({ key, index, style }) => (
              <div style={style} key={key}>
                <HistoryNavigateLink
                  match={match}
                  story={raffleHistory.entities[index]}
                  userid={userid as number}
                />
              </div>
            )}
          />
        )}
      </AutoSizer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

const NotLent = styled.span`
  text-align: center;
  padding: 5px;
  font-size: 17px;
  color: var(--color-white);
`;
