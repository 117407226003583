import { Periods } from '../components/referral-next/referral-campaign-page/referral-campaign-reports/referral-campaign-reports';
import { IReferralCampaign, IReferralIncomes } from './referral.reducers.interfaces';

export enum ReferralActionsTypes {
  FETCH_REFERRAL_REPORT_INCOMES = 'app/modules/personal_area/FETCH_REFERRAL_REPORT_INCOMES',
  FETCH_REFERRAL_REPORT_INCOMES_SUCCESS = 'app/modules/personal_area/FETCH_REFERRAL_REPORT_INCOMES_SUCCESS',

  FETCH_REFERRAL_CAMPAIGNS = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGNS',
  FETCH_REFERRAL_CAMPAIGNS_SUCCESS = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGNS_SUCCESS',
  FETCH_REFERRAL_CAMPAIGNS_FAILURE = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGNS_FAILURE',

  // FETCH_REFERRAL_CAMPAIGN_REPORT_NEW_MEMBERS_SUCCESS = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGN_REPORT_NEW_MEMBERS_SUCCESS',
  FETCH_REFERRAL_CAMPAIGN_REPORT_INCOMES_SUCCESS = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGN_REPORT_INCOMES_SUCCESS',

  CREATE_REFERRAL_CAMPAIGNS = 'app/modules/personal_area/CREATE_REFERRAL_CAMPAIGNS',
  CREATE_REFERRAL_CAMPAIGNS_SUCCESS = 'app/modules/personal_area/CREATE_REFERRAL_CAMPAIGNS_SUCCESS',
  CREATE_REFERRAL_CAMPAIGNS_FAILURE = 'app/modules/personal_area/CREATE_REFERRAL_CAMPAIGNS_FAILURE',

  PATCH_REFERRAL_CAMPAIGN = 'app/modules/personal_area/PATCH_REFERRAL_CAMPAIGN',
  PATCH_REFERRAL_CAMPAIGN_SUCCESS = 'app/modules/personal_area/PATCH_REFERRAL_CAMPAIGN_SUCCESS',

  FETCH_TAKE_REFERRAL_INCOME = 'app/modules/personal_area/FETCH_TAKE_REFERRAL_INCOME',

  FETCH_REFERRAL_CAMPAIGN_REPORT = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGN_REPORT',
  FETCH_REFERRAL_CAMPAIGN_REPORT_SUCCESS = 'app/modules/personal_area/FETCH_REFERRAL_CAMPAIGN_REPORT_SUCCESS',
}

export interface IFetchReferralIncomes {
  type: ReferralActionsTypes.FETCH_REFERRAL_REPORT_INCOMES;
}

export interface IFetchReferralIncomesSuccess {
  type: ReferralActionsTypes.FETCH_REFERRAL_REPORT_INCOMES_SUCCESS;
  payload: IReferralIncomes;
}

export interface IFetchReferralCampaigns {
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS;
}

export interface IFetchReferralCampaignsSuccess {
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS_SUCCESS;
  payload: {
    campaigns: any[];
  };
}

export interface IFetchReferralCampaignsFailure {
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGNS_FAILURE;
}

export interface ICreateReferralCampaigns {
  type: ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS;
  payload: Record<string, string>;
}

export interface ICreateReferralCampaignsSuccess {
  type: ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS_SUCCESS;
}

export interface ICreateReferralCampaignsFailure {
  type: ReferralActionsTypes.CREATE_REFERRAL_CAMPAIGNS_FAILURE;
}

// export interface IFetchReferralCampaignReportNewReferralSuccess {
//   type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_NEW_MEMBERS_SUCCESS
//   payload: {
//     id: number
//     report: any[]
//   }
// }

export interface IFetchReferralCampaignReportIncomesSuccess {
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_INCOMES_SUCCESS;
  payload: {
    id: number;
    report: any[];
  };
}

export interface IPatchReferralCampaignSuccess {
  type: ReferralActionsTypes.PATCH_REFERRAL_CAMPAIGN_SUCCESS;
  payload: Partial<IReferralCampaign>;
}

export interface IFetchTakeReferralIncome {
  type: ReferralActionsTypes.FETCH_TAKE_REFERRAL_INCOME;
}

export interface IFetchReferralCampaignReport {
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT;
  payload: {
    id: number;
    period: Periods;
  };
}

export interface IFetchReferralCampaignReportSuccess {
  type: ReferralActionsTypes.FETCH_REFERRAL_CAMPAIGN_REPORT_SUCCESS;
  payload: {
    id: number;
    period: Periods;
    report?: any[];
  };
}

export type referralActions =
  | IFetchReferralIncomesSuccess
  | IFetchReferralCampaigns
  | IFetchReferralCampaignsSuccess
  | IFetchReferralCampaignsFailure
  | ICreateReferralCampaigns
  | ICreateReferralCampaignsSuccess
  | ICreateReferralCampaignsFailure
  // | IFetchReferralCampaignReportNewReferralSuccess
  | IFetchReferralCampaignReportIncomesSuccess
  | IPatchReferralCampaignSuccess
  | IFetchReferralCampaignReport
  | IFetchReferralCampaignReportSuccess;
