import React from 'react';
import { Container } from './super-prize.styles';
import { useGetGeneralQuestInfo } from '../../../hooks';
import { CasesQuestStatusEnum } from '../../../interfaces';
import { SuperPrizeBlocked } from './super-prize-blocked';
import { SuperPrizeAvailable } from './super-prize-available';
import { SuperPrizeCompleted } from './super-prize-completed';

export const SuperPrize = ({ isFinal }: { isFinal?: boolean }) => {
  const { categoriesData, reward } = useGetGeneralQuestInfo();
  const available = categoriesData.filter(
    ({ status }) => status === CasesQuestStatusEnum.TAKEN || status === CasesQuestStatusEnum.OPENED
  ).length;

  return (
    <Container>
      {!!reward?.caseRevisionResult ? (
        <SuperPrizeCompleted />
      ) : (
        <>
          {available === categoriesData.length ? (
            <SuperPrizeAvailable />
          ) : (
            <SuperPrizeBlocked isFinal={isFinal} />
          )}
        </>
      )}
    </Container>
  );
};
