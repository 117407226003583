import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Cart } from '../Styles';
import { rangeSelect } from '../../hooks';

import { FactorTypes } from '../../interfaces';

const uiFactorType: any = {
  [FactorTypes['2-9']]: '2-9',
  [FactorTypes.JQKA]: 'JQKA',
  [FactorTypes.KA]: 'KA',
  [FactorTypes.A]: 'A',
  [FactorTypes.JOKER]: FactorTypes.JOKER,
};

interface IGameStatistic {
  statistic: { [k: string]: number[] };
  select: number;
  handleChangeRange: (index: number) => () => void;
}

export const GameStatistic: FC<IGameStatistic> = ({ statistic, select, handleChangeRange }) => {
  const { t } = useTranslation();

  const jokers = statistic['joker']?.length || 0;

  const redPercent =
    ((statistic[FactorTypes.RED]?.length || 0) / (rangeSelect[select] - jokers)) * 100;

  const blackPercent =
    ((statistic[FactorTypes.BLACK]?.length || 0) / (rangeSelect[select] - jokers)) * 100;

  return (
    <Container>
      <RangeSelectorContainer>
        <span>{t('Stats for')}</span>
        {rangeSelect.map((count: number, index) => (
          <div
            key={count}
            onClick={handleChangeRange(index)}
            className={select === index ? 'select' : ''}
          >
            {count}
          </div>
        ))}
      </RangeSelectorContainer>
      <div className="single-card-container">
        {Object.keys(uiFactorType).map((key: string) => (
          <SingleFactorCart key={key} factorType={uiFactorType[key]}>
            <span>{t(key)}</span>
            <span>
              {' x'}
              {statistic[key]?.length || 0}
            </span>
          </SingleFactorCart>
        ))}
      </div>
      <div>
        <MultipleFactorCart factorType={uiFactorType['colorRed']} percent={redPercent}>
          <span>{Number(redPercent).toFixed() || 0}%</span>
          <span>{Number(blackPercent).toFixed() || 0}%</span>
        </MultipleFactorCart>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 0.45rem 0.25rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1.3rem 1fr 0.9rem;
  padding-right: 0.9rem;

  & .single-card-container {
    display: grid;
    grid-gap: 0.2rem;
    grid-auto-flow: column;
    grid-template-columns: 2.5rem 2.5rem 2.5rem 1.5rem 1.5rem;
    grid-auto-rows: 2.1875rem;
    align-self: flex-end;
  }
`;

const RangeSelectorContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.2rem;
  grid-template-columns: 1fr 1.75rem 1.75rem 1.75rem;

  & span {
    align-self: center;
    justify-self: self-start;
    font-weight: bold;
    font-size: 0.7rem;
    line-height: 1.4;
    color: var(--color-gray-200);
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-700);
    border-radius: var(--size-radius-s);
    transition: all var(--transition-ease-out);

    color: var(--color-gray-200);
    font-size: 0.6rem;
    font-weight: bold;

    &.select {
      background: var(--color-gray-300);
      color: var(--color-white);
    }
  }
`;

interface ISingleFactorCart {
  factorType: string | string[];
  percent?: number;
}

const SingleFactorCart = styled(Cart)<ISingleFactorCart>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  & span {
    font-size: 0.65rem;
    font-weight: bold;
    position: relative;
    z-index: 10;

    &:first-of-type {
      color: var(--color-white-600);
    }
  }

  &:after,
  &:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: all var(--transition-ease-out);
  }

  &:after {
    background: ${p => `var(--hilo-factor-${p.factorType})`};
  }
`;

const MultipleFactorCart = styled(SingleFactorCart)`
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0.25rem;

  & > span:first-of-type {
    color: var(--color-white);
  }

  &:before {
    z-index: 1;
  }

  &:after {
    background: var(--hilo-factor-colorBlack);
  }

  &:before {
    top: 0;
    bottom: 0;
    background: var(--hilo-factor-colorRed);
  }

  ${p =>
    p.percent &&
    `
    &:before {
      left: ${p.percent}%;
    }
  `}
`;
