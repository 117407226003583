import styled from 'styled-components';

export const Chart = styled.div`
  width: 100%;
  position: relative;
  z-index: 999;
`;
export const ChartContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ContentChart = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const GameInfo = styled.div`
  display: block;
  width: 72%;

  & .calculation {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content min-content;
    grid-gap: 0.25rem;
    justify-content: center;

    &-total-currency,
    &-your-chance {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.125rem;
      color: var(--color-white);
      font-size: 0.85rem;

      & span {
        font-size: inherit;
        font-weight: bold;
      }
    }

    &-total-currency {
      & span {
        color: var(--green-100);
      }
    }

    &-divider {
      background: var(--color-white);
    }

    &-your-chance {
      & span {
        color: var(--aqua-800);
      }
    }
  }
`;

export const GameId = styled.div`
  letter-spacing: 0.7px;
  text-align: center;
  color: var(--color-white);
  font-size: 14px;
`;

export const CountItems = styled.div`
  letter-spacing: 0.7px;
  text-align: center;
  color: var(--aqua-300);
  font-size: 48px;
  padding: 5px 5px 0;
`;

export const CenterCircle = styled.div`
  width: 86%;
  position: absolute;
  padding: 16px;
`;
