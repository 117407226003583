import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUserEmail } from 'core/User';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';

export const PayoutMailbox: FC = () => {
  const { t } = useTranslation();
  const { email } = useUserEmail();
  const { handleOpenDialog } = useDialog(userModals.MAILBOX_BINDING);
  return (
    <Container>
      <div className="mailbox-container">
        <div className="mailbox-icon">
          <i className="icon-email" />
        </div>
        <div className="mailbox">
          <div className={`mailbox-status ${email.status}`}>
            {t('Email')} {t('email-form-status', { context: email.status })}
          </div>
          {email.address && <div className="mailbox-reference">{email.address}</div>}
        </div>
        <button className="mailbox-edit" onClick={handleOpenDialog}>
          <i className="icon-edit" />
        </button>
      </div>
      <div className="mailbox-description">
        {t(
          'To complete the withdrawal, you have to add an email to which you will receive a confirmation link'
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 1.25rem;
  height: 9rem;
  align-content: end;
  padding: 0 1rem;

  & .mailbox-container {
    display: grid;
    grid-template-columns: 1.625rem 1fr 1.625rem;
    grid-gap: 0 0.5rem;
    height: 2.875rem;

    & .mailbox-icon,
    & .mailbox-edit {
      justify-self: center;
      align-self: center;

      & i {
        color: var(--color-white);
      }
    }

    & .mailbox-edit {
      justify-self: stretch;
      align-self: stretch;
      background: transparent;
      border: 0;
    }

    & .mailbox-icon i {
      font-size: 1.5rem;
    }

    & .mailbox {
      display: grid;
      grid-auto-rows: 0.75rem;
      grid-gap: 0.5rem;
      align-self: center;

      &-status,
      &-reference {
        font-size: 0.8125rem;
        line-height: 0.9375;
        letter-spacing: 0.286px;
      }

      &-status {
        font-weight: bold;
        color: var(--green);
        text-transform: uppercase;

        &.empty {
          color: var(--danger);
        }

        &.not.confirmed {
          color: var(--primary);
        }
      }

      &-reference {
        color: var(--color-white);
      }
    }
  }

  & .mailbox-description {
    align-self: center;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.24px;
    color: var(--color-white);
  }
`;
