import { ICashback } from './cashback.reducer.interface';

export enum cashbackActionTypes {
  RECEIVE_DATA = 'app/modules/personal_area/cashback/RECEIVE_DATA',
}

export interface IUpdateCashback {
  type: typeof cashbackActionTypes.RECEIVE_DATA;
  payload: ICashback;
}

export type cashbackActions = IUpdateCashback;
