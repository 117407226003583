import * as React from 'react';
import {
  ISlideRenderProp,
  TournamentList,
  TournamentPlaces,
  TournamentSlider,
} from '../components/Tournaments';
import { useRacesFacade } from '../hooks';

export const TournamentBoard = () => {
  const { races } = useRacesFacade();

  return (
    <div>
      <TournamentPlaces races={races} />
      <TournamentSlider races={races}>
        {({ leaderboard, raceType }: ISlideRenderProp) => (
          <TournamentList leaderboard={leaderboard.slice(0, 5)} raceType={raceType} />
        )}
      </TournamentSlider>
    </div>
  );
};
