import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { TradeItem } from './TradeItem';

export class TradeList extends PureComponent {
  render() {
    const { onlySell, items, showItems, cartItems, addToCart, removeToCart } = this.props;
    return (
      <Container>
        {showItems.map(item => (
          <TradeItem
            isSelect={!onlySell && cartItems.includes(items[item].id)}
            key={items[item].id}
            {...items[item]}
            onlySell={onlySell}
            addToCart={addToCart}
            removeToCart={removeToCart}
          />
        ))}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-content: flex-start;
  overflow-x: scroll;
  padding: 0 0 15px 15px;
`;
