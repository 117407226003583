import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FactorControl } from './FactorControl';
import { FactorTypes } from '../../interfaces';
import { useDialog } from 'core/hooks';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';

const factorTypes: [string, FactorTypes][] = Object.entries(FactorTypes);

export const Controls = memo(() => {
  const { t } = useTranslation();
  const { handleToggleDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);
  return (
    <>
      <ControlHeader>{t('Make your bet')}</ControlHeader>
      <Container>
        {factorTypes.map(([factor, type]) => (
          <FactorControl
            key={factor}
            type={type}
            factor={factor}
            onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
              module: ParticipateModule.HILO,
              type,
              factor,
            })}
          />
        ))}
      </Container>
    </>
  );
});

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 5.25rem);
  grid-gap: 0.25rem;
  padding: 1rem;
  flex: 1 0;

  & div[class^='factor'] {
    border-radius: var(--size-radius-s);
  }

  & .factor-HI,
  & .factor-LO {
    grid-column-start: span 6;
  }

  & .factor-RED,
  & .factor-JOKER,
  & .factor-BLACK {
    grid-column-start: span 4;
  }

  & .factor-2-9,
  & .factor-JQKA,
  & .factor-KA,
  & .factor-A {
    grid-column-start: span 3;
  }
`;

const ControlHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  color: var(--color-white);
  text-align: center;
`;
