export default {
  'Unfortunately full screen is not supported in iOS':
    'К сожалению, полный экран не поддерживается на iOS',

  'Pick a team': 'Выбор команды',
  'Before the game begins choose your team: Terrorists or Counter-Terrorists.':
    'Перед началом игры выбери команду: террористы или контр-террористы.',
  'Symbols that belong to your team will earn you more winnings!':
    'Элементы играющие за твою команду приносят больше наград!',
  'You can change your team at any time by clicking the soldier on the left side of the screen.':
    '',

  'Scatter Symbols and Rounds': 'Скаттер и раунды',

  Scatter: 'Скаттер',
  'Scatter Symbols of your and enemy teams will show up on the screen. Watch them closely, they can influence the game in major ways!':
    'На поле появляются скаттеры твоей и вражеской команд. Следи за ними внимательно, они могут поменять ход игры в любой момент.',

  Victory: 'Победа',
  'Three Scatters of your team appearing anywhere on the screen win the round, you get Loss Bonus and an opportunity to open a Crate with an additional reward inside.':
    'Три коина твоей команды на поле выиграют раунд, loss bonus обналичится и ты получишь возможность открыть кейс с дополнительным призом внутри.',

  'Crate opening': 'Открытие кейса',
  'All symbols on the screen that have reward value get placed in the crate, along with Jackpot Symbol (constitutes the sum of all rewards * 10). Opening the crate wins you one of those rewards.':
    'В кейс попадают все элементы на поле, имеющие вознаграждение, и символ Джекпот (сумма всех элементов * 10). Открыв кейс ты выиграешь один из них.',

  'Free Spins': 'Фриспины',
  "Three consecutive round victories grant you 30 Free Spins. During Free Spins Scatter Elements don't appear on the screen, thus increasing Free Spins win rate.":
    'Три выигранных раунда подряд активируют 30 Фриспинов. На время фриспинов на поле перестанут появляться скаттеры команд. Больше оружия, больше призов!',

  'Round Loss': 'Поражение',
  "Three enemy Scatter Symbols mean that you lose the round. But don't worry! Every lost round increases your Loss Bonus, that gets paid to you after round win or a draw.":
    'Три коина вражеской команды на поле означают, что раунд проигран, но не отчаивайся. Каждый проигранный раунд увеличивает твой Loss Bonus, который обналичится как только ты выиграешь или сыграешь в ничью.',

  'Round Draw': 'Ничья',
  'Three Scatter Symbols of both teams on the screen mean that the round is a draw. Congrats, you get your Loss Bonus.':
    'По три коина каждой команды на поле одновременно означают ничью. При таком исходе твой Loss Bonus обналичится.',

  Grenades: 'Гранаты',

  'Assault (neutral)': 'Боевая (нейтральная)',
  'Destroys all non-allied symbols on the screen, new symbols drop from above.':
    'Уничтожит вокруг себя все элементы кроме союзных, сверху на их место упадут новые.',

  'Flashbang (neutral)': 'Ослепляющая (нейтральная)',
  'All enemy symbols switch team, the flashbang itself turns into a Flash Wild.':
    'Все недружественные игроку элементы поменяются на дружественные, сама граната превратится в световой Wild.',

  'Molotov/Incendiary': 'Молотов/зажигательная',
  "Only activates if you're on a Terrorist or Counter-Terrorist team respectively. Horizontally and vertically adjacent symbols get replaced with Burning Wilds.":
    'Cработает только при игре за террористов/контртеррористов, соседние по горизонтали и вертикали элементы заменятся огненным Wild символом.',

  'Special Symbols': 'Специальные символы',
  'Burning Wild, Flash Wild': 'Горящий Wild, световой Wild',
  'Substitutes for the most beneficial for the player symbol in that position.':
    'Служит заменой наиболее выгодного для игрока символа на линии.',

  'Symbol Payout Chart': 'Таблица ценности элементов',

  'Bet Lines': 'Таблица призовых линий',
  'Three or more matching symbols on one or several of the lines win. Winning combinations must start at the leftmost reel.':
    'Выигрывает совпадение трёх и более элементов по одной из приведенных ниже призовых линий. Выигрышные комбинации всегда считаются от крайнего слева столбца.',
};
