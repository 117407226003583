import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPayoutOrderFee } from '../../selectors';
import { useCallback } from 'react';
import { updatePayoutOrderFee } from '../../duck';

export const useOrderFee = () => {
  const feeIndex = useSelector(getPayoutOrderFee, shallowEqual);
  const dispatch = useDispatch();

  const changeFeeAction = useCallback(
    (feeIndex: number, method: string) => () => {
      dispatch(updatePayoutOrderFee(feeIndex, method));
    },
    [dispatch]
  );

  return {
    feeIndex,
    changeFeeAction,
  };
};
