import React, { FC, useState } from 'react';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { useTranslation } from 'react-i18next';
import { useUser, useUserTradeLinks } from 'core/User';
import { URLS } from 'services/constant-urls';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import {
  ContentWrapper,
  Input,
  Link,
  SubTitle,
  Title,
  Container,
} from './steam-trade-link-dialog.styles';

interface ISteamTradeLinkDialog extends IDialogComponent<boolean> {}

export const SteamTradeLinkDialog: FC<ISteamTradeLinkDialog> = ({ handleCloseModal }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { handleCloseDialog } = useDialog(userModals.STEAM_TRADE_LINK);
  const { tradeLinks, handleSaveSteamLink } = useUserTradeLinks();
  const [value, setValue] = useState(tradeLinks['steam'] ?? '');

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const submitHandler = () => {
    if (value) {
      handleSaveSteamLink(value);
      handleCloseDialog();
    }
  };

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>
        {tradeLinks['steam'] ? t('steamApi.editSteamTradeLink') : t('steamApi.addSteamTradeLink')}
      </Title>
      <SubTitle>{t('steamApi.need')}</SubTitle>
      <ContentWrapper>
        <Input
          value={value}
          onChange={changeHandler}
          type="text"
          placeholder={t('steamApi.enterLink')}
        />
        <Link
          target="_blank"
          href={`${URLS.urlTradeLink}${user.providers.steam?.profileId}/tradeoffers/privacy`}
        >
          {t('steamApi.link')}
        </Link>
        <Button
          className="primary"
          disabled={!value || value === tradeLinks['steam']}
          onClick={submitHandler}
        >
          {t('Submit').toLocaleUpperCase()}
        </Button>
      </ContentWrapper>
    </Container>
  );
};
