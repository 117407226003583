import styled from 'styled-components';

export const Container = styled.div`
  height: 3rem;
  position: relative;
  width: 100%;

  & input {
    background-color: var(--color-white);
    border-radius: var(--size-radius-m);
    border: 2px solid rgba(24, 35, 40, 0.4);
    color: var(--color-dark);
    font-size: 1rem;
    height: 3rem;
    line-height: 1.125rem;
    padding: 0.875rem;
    width: 100%;
  }

  & span {
    align-items: center;
    bottom: 0;
    color: var(--color-dark);
    display: flex;
    font-size: 1rem;
    justify-content: center;
    line-height: 1.125rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.25rem;
  }
`;
