import { IStore } from 'store/interface';

export const getShopMeta = ({ exchange }: IStore) => exchange.shop.get('meta');
export const getShopLoading = ({ exchange }: IStore) => exchange.shop.get('loading');
export const getShopLoaded = ({ exchange }: IStore) => exchange.shop.get('loaded');
export const getShopFailure = ({ exchange }: IStore) => exchange.shop.get('failure');

export const getShopElement = (idx: number) => ({ exchange }: IStore) =>
  exchange.shop.get('items')[idx];
export const getShopIdentifiers = ({ exchange }: IStore) => exchange.shop.get('items').length;
export const getShopFilters = ({ exchange }: IStore) => exchange.filters;
export const getShopFilter = (key: any) => ({ exchange }: IStore) => exchange?.filters.get(key);

export const getOrderAmount = ({ exchange }: IStore) => exchange.order.get('amount');
export const getOrder = ({ exchange }: IStore) => exchange.order.get('items');
export const hasInOrder = (id?: number) => ({ exchange }: IStore) =>
  exchange.order.get('items').some(item => item.id === id);
