import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ParticipateType } from 'core/interfaces';

interface IParticipateTypeSwitch {
  type: ParticipateType;
  handleChangeType: (type: ParticipateType) => () => void;
  types: ParticipateType[];
}

export const ParticipateTypeSwitch: FC<IParticipateTypeSwitch> = ({
  type,
  handleChangeType,
  types,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {types.length > 1 && (
        <Container>
          {types.map(key => (
            <button
              key={key}
              onClick={handleChangeType(key)}
              className={key === type ? 'active' : ''}
            >
              {t(key)}
            </button>
          ))}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 2px;
  flex-shrink: 0;
  height: 40px;
  position: relative;
  z-index: 2000;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray-900);
    transition: all 0.4s cubic-bezier(0.37, 0, 0.63, 1);

    border: 0;
    color: var(--color-white);
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;

    &:disabled {
      color: var(--color-gray-700);
      background: var(--color-dark-600);
    }
  }

  & .active {
    background: var(--color-gray-300);
  }
`;
