import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import styled from 'styled-components';

import minusImg from '../assets/minus.png';
import plusImg from '../assets/plus.png';

export class SelectPanel extends Component {
  constructor() {
    super();
    this.state = {
      value: 0,
    };
    this.input = null;
    this.longPressFunction = null;
    this.pickerHeightAnimation = new Animated.Value(0);
    this.pickerOpacityAnimation = new Animated.Value(0);
  }

  shouldComponentUpdate(props, state) {
    return props.value !== this.state.value || this.state.pickerIsOpen !== state.pickerIsOpen;
  }

  componentDidUpdate() {
    if (this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const pickerHeight = this.pickerHeightAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 70],
    });

    const { value, lent = [], panel, disabled } = this.props;
    // https://github.com/facebook/react/issues/9402 (input value 00001)
    return (
      <Container>
        <ControlPanel disabled={disabled}>
          <Button onClick={() => this._changeValue(false)}>
            <ImgMinus src={minusImg} />
          </Button>

          <InputSum
            disabled={disabled}
            onClick={() => this.togglePicker()}
            onChange={this._handleInput}
            type="number"
            value={value}
          />

          <Button onClick={() => this._changeValue(true)}>
            <ControlImg src={plusImg} />
          </Button>
        </ControlPanel>

        {panel === 'scrollbar' && (
          <Animated.div
            style={{
              height: pickerHeight,
              opacity: this.pickerHeightAnimation,
              width: '100%',
            }}
          >
            <Animated.div
              style={{
                height: pickerHeight,
                opacity: this.pickerHeightAnimation,
              }}
            >
              <ControlRole>
                {lent.map(({ key, label, value }) => (
                  <ControlRoleItem key={key} onClick={() => this._handleLent(value)}>
                    {label}
                  </ControlRoleItem>
                ))}
              </ControlRole>
            </Animated.div>
          </Animated.div>
        )}

        {panel === 'bar' && (
          <Animated.div
            style={{
              height: pickerHeight,
              opacity: this.pickerHeightAnimation,
              width: '100%',
            }}
          >
            <Animated.div
              style={{
                height: pickerHeight,
                opacity: this.pickerHeightAnimation,
              }}
            >
              <ControlRole>
                {lent.map(({ key, label, value }) => (
                  <ControlRoleItem key={key} onClick={() => this._handleLent(value)}>
                    {label}
                  </ControlRoleItem>
                ))}
              </ControlRole>
            </Animated.div>
          </Animated.div>
        )}
      </Container>
    );
  }

  togglePicker = () => {
    const { pickerIsOpen } = this.state;
    this.setState({ pickerIsOpen: !pickerIsOpen });
    this.pickerHeightAnimation.setValue(pickerIsOpen ? 1 : 0);
    this.pickerOpacityAnimation.setValue(pickerIsOpen ? 1 : 0);

    Animated.timing(this.pickerHeightAnimation, {
      toValue: pickerIsOpen ? 0 : 1,
      duration: 500,
    }).start();

    Animated.timing(this.pickerOpacityAnimation, {
      toValue: pickerIsOpen ? 0 : 1,
      duration: pickerIsOpen ? 150 : 1300,
    }).start();
  };

  _longPressIn = (direction, interval = 500) => {
    this.longPressFunction = setTimeout(() => {
      this._changeValue(direction);
      interval = interval > 10 ? interval / 2 : 10;
      this._longPressIn(direction, interval);
    }, interval);
  };

  _longPressOut = () => {
    clearTimeout(this.longPressFunction);
    this.longPressFunction = null;
  };

  _changeValue = direction => {
    const { type, value, maxValue } = this.props;
    if (value > maxValue && value - value < maxValue) {
      return;
    }
    if (type === 'TextPrice') {
      const factor = direction
        ? value < 10
          ? 0.1
          : value < 500
          ? 5
          : value >= 1000
          ? 100
          : value <= 5000 && 500
        : value <= 10
        ? -0.1
        : value < 500
        ? -5
        : value >= 1000
        ? -100
        : value <= 5000 && -500;

      let newValue = value + factor;

      newValue = newValue <= 0 ? 0 : parseFloat(newValue.toFixed(1));
      if (newValue > maxValue) {
        newValue = maxValue;
      }
      this.props.setValue(newValue);
    }

    if (type === 'coefficient') {
      let valueCoef = parseFloat(value);
      let newValue =
        valueCoef >= 1.05
          ? direction
            ? valueCoef + 0.05
            : valueCoef - 0.05
          : direction
          ? valueCoef + 1.05
          : 0;

      if (newValue >= maxValue) {
        newValue = maxValue;
      }
      if (newValue < 0 || newValue === 1) {
        newValue = 0;
      }
      newValue = newValue === 0 ? 0 : parseFloat(newValue).toFixed(2);
      this.props.setValue(newValue);
    }
  };

  _handleInput = e => {
    const { maxValue } = this.props;
    let value = parseInt(e.target.value);

    if (value > maxValue) {
      return;
    }
    if (value === '' || value !== parseInt(value)) {
      return this.props.setValue(0);
    }

    return this.props.setValue(value);
  };

  _handleLent = value => {
    const { maxValue } = this.props;
    if (value > maxValue) {
      return;
    }
    const newValue = parseFloat(value.toFixed(1));
    this.props.setValue(newValue);
  };

  handleClear = () => {
    this.setState({ value: 0 });
    this.props.setValue(0);
  };
}

const Button = styled.button`
  border: 0;
  background: transparent;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0 5px 0;
`;

const ControlPanel = styled.div`
  padding: 8px 14px 8px 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${p => (p.disabled ? '#afafaf' : 'var(--color-white)')};
  border-radius: 5px;
  align-items: center;
`;

const ImgMinus = styled.img`
  height: 2px;
  width: 17px;
  filter: brightness(0.6);
`;

const ControlImg = styled.img`
  width: 18px;
  height: 18px;
  filter: brightness(0.6);
`;

const InputSum = styled.input`
  min-width: 35px;
  color: #131212;
  font-weight: 700;
  text-align: center;
  background: transparent;
  border: 0;
  max-width: 80px;
`;

const ControlRole = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  color: var(--color-white);
  display: flex;
  align-items: center;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ControlRoleItem = styled.div`
  padding: 0px 10px 0px 10px;
`;
