import React, { useLayoutEffect, FC, useRef } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { ToolBarConfig, ToolBarConfigKeyType } from 'assets/tool-bac-assets';
import { useUser } from 'core/User';
import { AnimatedContainer, Container, StyledTransitionGroup } from './tool-bar.styles';
import { useToolBar } from 'core/hooks';
import { CSSTransition } from 'react-transition-group';
import { EventGiftsWidget, EventQuestsWidget } from 'modules/event-module';

export interface GetToolBarConfig {
  name: string;
  iconKey: ToolBarConfigKeyType;
  type: 'page' | 'modal';
  isNested: boolean;
  isNeedAuth: boolean;
  handler?: () => void;
  text?: string;
}
interface IToolBar {
  routes?: string[];
  gameTypeId?: number;
  toolBarItemsConfig?: GetToolBarConfig[];
}

export const ToolBar: FC<IToolBar> = ({ toolBarItemsConfig }) => {
  const scrollRef = useRef<HTMLDivElement>();
  const match = useRouteMatch();
  const location = useLocation();
  const { toolBarState } = useToolBar();
  const { user } = useUser();

  useLayoutEffect(() => {
    const container = scrollRef.current;
    const { pathname } = location;

    const scrollLeft = +sessionStorage.getItem(`top-bar-scroll_${pathname}`);

    if (scrollLeft > 0) scrollRef.current?.scrollTo({ left: scrollLeft, top: 0 });

    return () => {
      if (container?.scrollLeft) {
        const { scrollLeft } = container;
        sessionStorage.setItem(`top-bar-scroll_${pathname}`, scrollLeft.toString());
      }
    };
  }, [scrollRef, location]);

  return (
    <StyledTransitionGroup>
      <CSSTransition
        in={toolBarState}
        classNames="tool-bar-animation"
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 350,
          exit: 500,
        }}
      >
        <AnimatedContainer>
          <Container ref={scrollRef}>
            <EventGiftsWidget />
            <EventQuestsWidget />
            {toolBarItemsConfig
              ? toolBarItemsConfig.map(toolBarItemConfigData => {
                  return !toolBarItemConfigData.isNeedAuth ? (
                    <ToolBarItem
                      key={toolBarItemConfigData.iconKey}
                      {...toolBarItemConfigData}
                      matchUrl={match?.url}
                      locationPath={location.pathname}
                    />
                  ) : user?.id ? (
                    <ToolBarItem
                      {...toolBarItemConfigData}
                      matchUrl={match?.url}
                      locationPath={location.pathname}
                      key={toolBarItemConfigData.iconKey}
                    />
                  ) : null;
                })
              : null}
          </Container>
        </AnimatedContainer>
      </CSSTransition>
    </StyledTransitionGroup>
  );
};

interface ToolBarItemProps extends Omit<GetToolBarConfig, 'isNeedAuth'> {
  locationPath: string;
  matchUrl?: string;
}

const ToolBarItem: React.FC<ToolBarItemProps> = ({
  type,
  name,
  iconKey,
  text,
  handler,
  matchUrl,
  isNested,
  locationPath,
}) => {
  if (type === 'page') {
    const checkRoute = !isNested ? name : `${matchUrl}/${name}`;
    return (
      <Link key={name} replace={matchUrl !== locationPath} to={checkRoute}>
        {ToolBarConfig[iconKey]}
        {text}
      </Link>
    );
  }
  if (type === 'modal') {
    return (
      <div key={name} onClick={handler}>
        {ToolBarConfig[iconKey]}
        {text}
      </div>
    );
  }
};
