import { FiltersContainer } from '@components/filters';
import { useDialog } from 'core/hooks';
import { caseBattlePriceRangeAction, caseBattleSearchParamsAction } from 'games/CaseBattle';
import { AddCasesConfig } from 'games/CaseBattle/dialog-config';
import { useGetFilterParams } from 'games/CaseBattle/hooks';
import { actionRequestCaseList } from 'games/CaseGame';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Container } from './filters.styles';

export const Filters = React.memo(() => {
  const { filterParams } = useGetFilterParams();
  const dispatch = useDispatch();
  const { handleToggleDialog } = useDialog();

  const cleanRangePrice = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      actionRequestCaseList({
        id: 'all',
        filterParams: { sortBy: filterParams.sortBy, query: filterParams.query, battles: true },
      })
    );
    dispatch(caseBattlePriceRangeAction(null, null));
  };

  const cleanSearch = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      actionRequestCaseList({
        id: 'all',
        filterParams: {
          sortBy: filterParams.sortBy,
          maxPrice: filterParams.maxPrice,
          minPrice: filterParams.minPrice,
          battles: true,
        },
      })
    );
    dispatch(caseBattleSearchParamsAction(null));
  };

  return (
    <Container>
      <FiltersContainer
        sortProps={{
          text: filterParams.sortBy,
          openSortDialog: handleToggleDialog(AddCasesConfig.SORT, true),
        }}
        priceRangeProps={{
          maxPrice: filterParams.maxPrice,
          minPrice: filterParams.minPrice,
          cleanRangePrice,
          openPriceRangeDialog: handleToggleDialog(AddCasesConfig.PRICE_RANGE, true),
        }}
        searchProps={{
          openSearchDialog: handleToggleDialog(AddCasesConfig.SEARCH, true),
          query: filterParams.query,
          cleanSearch,
        }}
      />
    </Container>
  );
});
