export enum FastActionsTypes {
  UPDATE_GAME = 'app/fast-game/UPDATE_GAME',
  CREATE_PARTICIPATE = 'app/fast-game/CREATE_PARTICIPATE',
  ATTACH_LISTENERS = 'app/fast-game/ATTACH_LISTENERS',
  DETACH_LISTENERS = 'app/fast-game/DETACH_LISTENERS',
}

export interface IUpdateGame {
  type: typeof FastActionsTypes.UPDATE_GAME;
  payload: any;
}

export type fastActions = IUpdateGame;
