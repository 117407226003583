import React, { FC } from 'react';
import styled from 'styled-components';
import { RaffleLot } from '../@shared';

import { IRafflingItem, Phases } from '../../interfaces/reducer.leaders.interfaces';

interface IQueueAnimations {
  stage: number;
  phase: Phases;
  items: IRafflingItem[];
  type: string;
}

interface QueueAnimationsStyle {
  count: number;
  phase: Phases;
}

export const QueueAnimations: FC<IQueueAnimations> = ({ items, stage, phase, type }) => (
  <Container count={items.length} phase={phase}>
    {items.map((item: any, idx) => (
      <RaffleLot
        key={item.id}
        {...item}
        order={(items.length + idx - stage) % items.length}
        stage={stage}
        phase={phase}
        idx={idx}
        type={type}
      />
    ))}
  </Container>
);

const Container = styled.div.attrs((p: QueueAnimationsStyle) => ({
  height: p.count * 136,
  raffling: [Phases.step_raffling, Phases.raffling].includes(p.phase),
}))<QueueAnimationsStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  margin-top: 0.25rem;
  padding: 0 1rem 1rem;
  position: relative;
  height: 100%;
  min-height: ${p => p.height}px;

  &:before {
    content: '';
    top: -0.25rem;
    left: 0;
    right: 0;
    height: 8rem;
    position: absolute;
    transition: background-color 700ms ease-out 1s;
    background-color: ${p => (p.raffling ? '#47555f' : 'transparent')};
  }

  & > div {
    left: 1rem;
    right: 1rem;
    transition: transform 700ms ease-out 1500ms;
    will-change: transform;
  }
`;
