export default {
  Games: '遊戲',
  'Refill balance': '充值余额',
  Settings: '设置',
  Store: '商店',
  'Get free money': '獲得免費硬幣',
  Notifications: '通知',
  FAQ: 'FAQ',
  'Log out': '注销',
  'Steam trade link': 'Steam 交易链接',
  'Where can I get a link?': '从哪里可以获得链接？',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    '你與 CSGOFAST 服務的連線被切斷。請觸碰螢幕來重新連線。',
  'App was offline. Check your internet connection to continue!':
    '應用程式離線。檢查你的連線以便繼續！',
  SAVE: 'SAVE',
};
