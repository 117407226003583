import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-gap: 23px;
  padding: 19px;

  & > span {
    color: var(--color-white);
    font-size: 20px;
    line-height: 23px;
  }

  & .input-container {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2.125rem;
  }
`;

export const SteamLinkInput = styled.input`
  background-color: var(--color-white);
  border-radius: 5px;
  border: 0;
  color: var(--dark);
  font-size: 1rem;
  height: 40px;
  letter-spacing: 0.47px;
  padding: 0 0.7rem;
  width: 100%;

  &::placeholder {
    color: #161e21;
    font-size: 1rem;
    letter-spacing: 0.47px;
    opacity: 0.5;
  }
`;

export const Fields = styled.div`
  & > div {
    color: var(--color-white);
    font-size: 0.8rem;
    letter-spacing: 0.03px;
    margin: 0 0 0.5rem;
  }
`;
