import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';
import {
  Container,
  InfoKey,
  InfoValue,
  Line,
  UserStatisticsInfo,
} from './user-statistics-info.styles';

interface IUserStatisticsInfoComp {
  profile: IUserInfo;
}

export const UserStatisticsInfoComp: FC<IUserStatisticsInfoComp> = React.memo(({ profile }) => {
  const { t } = useTranslation();
  const hasProfile = !!profile?.id;
  const { id, activity } = profile ?? {};

  return hasProfile ? (
    <UserStatisticsInfo>
      <Line>
        <InfoKey>{t('Fast ID')}</InfoKey>
        <InfoKey>{t('First game')}</InfoKey>
      </Line>
      <Line>
        {id && <InfoValue>{id}</InfoValue>}
        {activity && (
          <InfoValue>
            {activity.registered && dayjs(activity.registered).format('DD.MM.YYYY')}
          </InfoValue>
        )}
      </Line>
    </UserStatisticsInfo>
  ) : (
    <Container className="skeleton" />
  );
}, isEqual);
