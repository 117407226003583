import { i18n } from 'locales';
import de from './de';
import en from './en';
import es from './es';
import no from './no';
import ru from './ru';
import pl from './pl';
import ro from './ro';
import pt from './pt';
import sv from './sv';
import th from './th';
import tr from './tr';
import zh_cn from './zh_cn';
import zh_tw from './zh_tw';

i18n.addResourceBundle('de', 'cases2.0', de, true);
i18n.addResourceBundle('en', 'cases2.0', en, true);
i18n.addResourceBundle('es', 'cases2.0', es, true);
i18n.addResourceBundle('no', 'cases2.0', no, true);
i18n.addResourceBundle('ru', 'cases2.0', ru, true);
i18n.addResourceBundle('pl', 'cases2.0', pl, true);
i18n.addResourceBundle('ro', 'cases2.0', ro, true);
i18n.addResourceBundle('pt', 'cases2.0', pt, true);
i18n.addResourceBundle('sv', 'cases2.0', sv, true);
i18n.addResourceBundle('th', 'cases2.0', th, true);
i18n.addResourceBundle('tr', 'cases2.0', tr, true);
i18n.addResourceBundle('zh_cn', 'cases2.0', zh_cn, true);
i18n.addResourceBundle('zh_tw', 'cases2.0', zh_tw, true);
