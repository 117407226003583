import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useShopResetFilter, useShopSortFilter } from '../../../hooks';
import { useModal } from 'core/Modals';
import { ExchangeModal, ShopFiltersConfig } from '../../../configs';

export const ShopFilters = () => {
  const { t } = useTranslation('exchange');
  const { handleToggleModal } = useModal(ExchangeModal.EXCHANGE_SHOP_FILTERS);
  const { sort, changeSort } = useShopSortFilter();
  const { refresh } = useShopResetFilter();

  return (
    <Container>
      <Button className="secondary" onClick={refresh}>
        <i className="icon-refresh" />
      </Button>
      <Button
        className="secondary"
        onClick={handleToggleModal(ExchangeModal.EXCHANGE_SHOP_FILTERS, true)}
      >
        <i className="icon-search" />
      </Button>
      <Button className="secondary" onClick={changeSort}>
        <div className={`sort-type ${ShopFiltersConfig.sortBy(sort)}`}>
          <i className="icon-asc-sort-price" />
          <i className="icon-desc-sort-price" />
        </div>
        {t(`sortBy.${ShopFiltersConfig.sortBy(sort)}`, { context: 'short' })}
        <div className="arrows" />
      </Button>
      <Button
        className="secondary"
        onClick={handleToggleModal(ExchangeModal.EXCHANGE_SHOP_FILTERS, true)}
      >
        {t('price from - to')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2.5rem 2.5rem 1fr 1fr;
  grid-template-rows: 2.5rem;
  grid-gap: 0.25rem;
  padding: 0.5rem 1rem 1.25rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & button {
    align-items: center;
    justify-content: space-around;
    height: 2.5rem;
    padding: 0 0.75rem;

    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    white-space: nowrap;
    text-transform: none;

    & > i {
      font-size: 1.25rem;
    }

    & .sort-type {
      width: 1.125rem;
      height: 0.75rem;
      margin-right: 0.8rem;
      position: relative;

      &.ASC_PRICE .icon-asc-sort-price,
      &.DESC_PRICE .icon-desc-sort-price {
        opacity: 1;
      }

      & i {
        font-size: 1.2rem;
        color: var(--color-white);
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: color 150ms ease-out;
      }
    }

    & .arrows {
      width: 10px;
      margin-left: 0.8rem;

      &:after,
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
      }

      &:before {
        transform: translate(0, 7px);
      }

      &:after {
        transform: rotate(180deg) translate(0, 7px);
      }
    }
  }
`;
