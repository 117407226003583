import React, { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useVirtual, VirtualItem } from 'react-virtual';
import { Link } from 'react-router-dom';
import {
  IFastInventoryElement,
  useFastInventoryFilters,
  useFastInventoryInfo,
  useFastInventoryPagination,
} from 'core/User';
import { FastInventoryItem } from './fast-inventory-item';
import { getItemUri } from 'core/utils/image.utils';
import { useDialog, useParticipateSettings } from 'core/hooks';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';

const list = Array.from({ length: 10 }, (_, i) => i);

interface IFastInventoryPanel {
  module: ParticipateModule;
  participate: Set<IFastInventoryElement | any>;
  toggleParticipate: any;
}

export const FastInventoryPanel: FC<IFastInventoryPanel> = ({
  module,
  participate,
  toggleParticipate,
}) => {
  const { handleCloseDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);

  const parentRef = useRef<HTMLDivElement>(null);
  const { info, identifiers, loaded, loading } = useFastInventoryInfo();
  const { loadMore } = useFastInventoryPagination();
  const { settings } = useParticipateSettings(module);
  const { filters } = useFastInventoryFilters();

  const elements = useVirtual({
    size: identifiers,
    parentRef,
    estimateSize: useCallback(() => 60, []),
    overscan: 3,
    horizontal: true,
  });

  const items = Array.from(participate);

  useEffect(() => {
    const last = elements.virtualItems[elements.virtualItems.length - 1];
    if (!last || info.itemsCount === identifiers) return;
    const size = identifiers / 2;
    if (last.index > size - 5 && info.itemsCount > identifiers && !loading && loaded) {
      loadMore(Math.floor(identifiers / 100) + 1);
    }
  }, [elements.virtualItems, identifiers, info, loading, loaded, loadMore]);

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.scrollLeft = 0;
    }
  }, [filters.sortBy]);

  return (
    <Container className="inventory-panel">
      <div className="participation-list">
        {list.map(idx => (
          <button
            key={idx}
            className="participation-list-item"
            onClick={toggleParticipate(items[idx])}
            disabled={items[idx]?.isInventory}
          >
            {items[idx] && (
              <img
                srcSet={`${getItemUri(
                  items[idx]?.inventoryItem?.baseItem?.icon,
                  28,
                  28
                )} 1x, ${getItemUri(items[idx]?.inventoryItem?.baseItem?.icon, 56, 56)} 2x`}
                src={getItemUri(items[idx]?.inventoryItem?.baseItem?.icon, 28, 28)}
                alt=""
              />
            )}
          </button>
        ))}
      </div>
      <div ref={parentRef} className="stocks-list">
        <div className="stocks-list-wrapper" style={{ width: elements.totalSize + 12 }}>
          {elements.virtualItems.map(({ start, index }: VirtualItem) => (
            <div
              key={index}
              className="stocks-list-item"
              style={{ transform: `translateX(${start + 12}px)` }}
            >
              <FastInventoryItem
                participate={participate}
                identifier={index}
                toggleParticipate={toggleParticipate}
                disabled={participate.size >= settings.max_items_per_trade}
              />
            </div>
          ))}
        </div>
        <div className="link-container">
          <Link className="exchange-link" to="/inventory/exchange" onClick={handleCloseDialog}>
            <i className="icon-plus" />
          </Link>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  align-self: self-end;
  display: grid;
  grid-template-rows: 2.5rem 3.5rem;
  align-content: space-between;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;

  @media (min-width: 375px) {
    grid-template-rows: 2.75rem 3.5rem;
  }

  & .participation-list {
    display: grid;
    grid-template-columns: repeat(10, 1.5rem);
    grid-auto-rows: 1.5rem;
    grid-gap: 0.25rem;
    justify-content: center;

    @media (min-width: 375px) {
      grid-template-columns: repeat(10, 1.75rem);
      grid-auto-rows: 1.75rem;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: var(--color-dark-100);
      border-radius: 0.125rem;
      border: 0;
      padding: 1px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: grayscale(2);
      }
    }
  }

  & .stocks-list {
    display: grid;
    grid-template-columns: min-content 4.75rem;
    grid-template-rows: 3.5rem;
    position: relative;
    overflow: scroll hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &-wrapper {
      height: 100%;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 0;
      }
    }

    &-item {
      width: 3.5rem;
      height: 3.5rem;
      background: var(--color-dark-200);
      border-radius: var(--size-radius-s);
      position: absolute;
      top: 0;
      left: 0.25rem;
    }

    & .link-container {
      & .exchange-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 3.5rem;
        background: var(--color-dark-200);
        margin-left: 0.25rem;
        color: var(--color-white);
        font-size: 1.5rem;
        border-radius: 0.125rem;
      }
    }
  }
`;
