export default {
  GAME: '遊戲',
  Winner: '获胜者',
  Win: '奖品',
  Chance: '获胜几率',
  'Chance to win': 'Chance to win',
  'At stake': '赌注赌注',
  'Sign in through STEAM to participate': '透過STEAM登入，參加遊戲',
  'Steam trade link': 'Steam 交易链接',
  'Your chance': 'Your chance',
  'Be first to deposit': '成为第一个下注的人成为第',
  'Place a bet': '下注',
  'Deposit more': '押注更多',
  'Min bet': '最小赌注',
  'Max bet': '最高賭注',
  'Bet amount': '赌注金额',
  'max. items': '最大道具',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',
  'Tickets to from: from to': '游戏票：从 #{{from}} 至 #{{to}}',
  Skins: '裝備',
  Coins: '快点',
  'VIRTUAL SKINS': '虛擬皮膚',
  'Round number': '回合数字',
  'Winning after commission': '在委託後獲勝',
  Loading: '正在加载',
};
