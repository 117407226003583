export enum bonusCodeTypes {
  ACTION_REQUEST = 'app/modules/personal_area/bonus_code/ACTION_REQUEST',
  ACTION_RESPONSE = 'app/modules/personal_area/bonus_code/ACTION_RESPONSE',
  ACTION_ERROR = 'app/modules/personal_area/bonus_code/ACTION_ERROR',
}

interface ActionResponseBonusCode {
  type: typeof bonusCodeTypes.ACTION_RESPONSE;
}
export interface ActionRequestBonusCode {
  type: typeof bonusCodeTypes.ACTION_REQUEST;
}
interface ActionErrorBonusCode {
  type: typeof bonusCodeTypes.ACTION_ERROR;
}
export type BonusCodeAction =
  | ActionResponseBonusCode
  | ActionRequestBonusCode
  | ActionErrorBonusCode;
