import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

import { i18n } from 'locales';
import { generateURL } from 'core/utils';
import { FilterParams } from '../interfaces';
import { TicketTypeEnum } from 'modules/event-module/event-summertime';

class CasesRepository {
  fetchGetCases(visibleInAll: boolean | null, filterParams?: FilterParams) {
    return ajax({
      url: generateURL(
        { ...filterParams, visibleInAll },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchCategoryCases(filterParams: FilterParams | null, id: string) {
    return ajax({
      url: generateURL(
        filterParams,
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/categories/${id}`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchCategory() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/categories?detailed=false`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchCaseDataById(id: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/${id}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchRevisionData(caseId: string, page: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/revision/${caseId}/${page}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchItemData({
    itemInventoryId,
    shortName,
    skin,
  }: {
    shortName: string | null;
    skin: string;
    itemInventoryId?: string;
  }) {
    return ajax({
      url: generateURL(
        { lang: 'EN', shortName, skin, itemInventoryId },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/item`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  // doesn't use
  // fetchItemDataDetailed({
  //   itemInventoryId,
  //   shortName,
  //   skin,
  // }: {
  //   shortName: string | null;
  //   skin: string;
  //   itemInventoryId?: string;
  // }) {
  //   return ajax({
  //     url: generateURL(
  //       { shortName, skin, itemInventoryId, lang: 'EN' },
  //       `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/items/detailed`
  //     ),
  //     method: 'GET',
  //     withCredentials: true,
  //     crossDomain: true,
  //   });
  // }
  fetchItemDataDetailed2Dev(name: string) {
    return ajax({
      url: generateURL(
        { fullName: name },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/v2/items/detailed`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  fetchItemDataById(id: string) {
    return ajax({
      url: generateURL(
        { lang: i18n.language.toLocaleUpperCase(), inventoryId: id },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/item-id`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  openCase(id: string, lineCount: number, isFree: boolean, ticketColor?: TicketTypeEnum) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/${id}/open`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        count: lineCount,
        isFree,
        ticketColor,
      }),
    });
  }
  openCaseDemo(id: string, lineCount: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/${id}/open/demo`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        count: lineCount,
        isFree: false,
      }),
    });
  }
  fetchRouletteItemsIds({ lines, caseId }: { lines: string; caseId: string }) {
    return ajax({
      url: generateURL(
        { lines, caseId },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/strip/calc`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  sellItems(ids: number[]) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/v2/items/sell`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
      }),
    });
  }
  fetchMotivatorData() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/motivator/cases?keys=bestLast,last&limit=10`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  getReplay(uuid: string) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/replay/${uuid}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  getCaseHistory(pageNumber: number, size: number) {
    return ajax({
      url: generateURL(
        {
          'page[number]': pageNumber,
          'page[size]': size,
        },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/suitcases/history`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
}

export default new CasesRepository();
