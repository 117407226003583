import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { IDialogComponent } from 'core/interfaces';
import { useUser } from 'core/User';
import { updateName } from 'core/User/ducks/duck';
import { SvgIcons } from '../assets';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { fileAvatarUpload, getDataUploadRequest } from 'modules/PersonalArea/duck';
import { addNotification } from 'modules/Notifications/duck';
import { getBase64File } from 'core/utils/image.utils';
import {
  BackBtn,
  Container,
  Content,
  Header,
  Input,
  ReadyBtn,
  Text,
  Title,
  UploadFile,
  Username,
} from './profile-settings-dialog.styles';

const maxKiloBytes = 512000;
const maxMegaBytes = 5242880;

export interface IBase64 {
  base64: string;
  name: string;
}

interface IProfileSettingsDialog extends IDialogComponent<boolean> {}

export const ProfileSettingsDialog: FC<IProfileSettingsDialog> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useUser();
  const [username, setUsername] = useState<string>(user.name);
  const [errorName, setErrorName] = useState(false);
  const { handleOpenDialog, handleCloseDialog } = useDialog(userModals.PROFILE_SETTINGS_AVATAR);

  useEffect(() => {
    return () => handleCloseDialog();
  }, [handleCloseDialog]);

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 32) {
      setErrorName(true);
    } else {
      if (e.target.value !== user.name) {
        setUsername(e.target.value);
        setErrorName(false);
      }
    }
  };

  const compressFile = async (imageFile: File) => {
    const options = {
      maxSizeMB: 0.488281,
      maxWidthOrHeight: 1920,
      useWebWorker: false,
    };

    try {
      return await imageCompression(imageFile, options);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const files = e.target.files;

    if (files.length < 1 || files.length > 1) {
      return;
    } else if (files[0].size > maxMegaBytes || !files[0].type.match('image.*')) {
      dispatch(addNotification({ type: 'error', body: t('Max size file') }));
      return;
    } else if (files[0].size <= maxKiloBytes) {
      const file = (await getBase64File(files[0])) as IBase64;
      dispatch(fileAvatarUpload(file?.base64));
      dispatch(getDataUploadRequest());
      handleOpenDialog();
    } else {
      const compressedFile = await compressFile(files[0]);
      const file = (await getBase64File(compressedFile)) as IBase64;
      dispatch(fileAvatarUpload(file?.base64));
      dispatch(getDataUploadRequest());
      handleOpenDialog();
    }
    e.target.value = '';
  };

  const handleConfirm = () => {
    if (username !== user.name) {
      dispatch(updateName(username));
    }

    handleCloseModal();
  };

  return (
    <>
      <Header>
        <BackBtn onClick={handleCloseModal}>
          <i className="icon-arrow" />
        </BackBtn>
        <Title>{t('Change account')}</Title>
        <ReadyBtn onClick={handleConfirm}>{t('Done')}</ReadyBtn>
      </Header>
      <Container className="content">
        <Content>
          <UploadFile>
            <label>
              <input type="file" name="file" onChange={onChangeAvatar} />
              <SvgIcons.CameraIcon />
            </label>
          </UploadFile>
          <Text>{t('Nickname:')}</Text>
        </Content>
        <Username className={errorName ? 'error' : ''}>
          <Input onChange={onChangeUsername} type="text" value={username} />
        </Username>
      </Container>
    </>
  );
};
