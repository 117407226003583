import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as PauseIcon } from './pause.svg';

// Common
import chatMessageReceiving_1 from './sounds/commonSounds/chatMessageReceiving_1.mp3';
import chatMessageSending_1 from './sounds/commonSounds/chatMessageSending_1.mp3';
import chatMessageSending_2 from './sounds/commonSounds/chatMessageSending_2.mp3';
import chatMessageSending_3 from './sounds/commonSounds/chatMessageSending_3.mp3';
import levelUp_1 from './sounds/commonSounds/levelUp_1.mp3';
import nextRank_1 from './sounds/commonSounds/nextRank_1.mp3';
// import roomsBets_1 from './sounds/commonSounds/roomsBets_1.mp3';
// import roomsBets_2 from './sounds/commonSounds/roomsBets_2.mp3';
// import roomsBets_3 from './sounds/commonSounds/roomsBets_3.mp3';
// import roomsBets_4 from './sounds/commonSounds/roomsBets_4.mp3';
// import roomsBets_5 from './sounds/commonSounds/roomsBets_5.mp3';
// import roomsBets_6 from './sounds/commonSounds/roomsBets_6.mp3';
// import roomsBets_7 from './sounds/commonSounds/roomsBets_7.mp3';
// import roomsBets_8 from './sounds/commonSounds/roomsBets_8.mp3';

// Classic
import betsInCurrentRoom_1 from './sounds/gameSounds/Classic/betsInCurrentRoom_1.mp3';
import betsInCurrentRoom_2 from './sounds/gameSounds/Classic/betsInCurrentRoom_2.mp3';
import betsInCurrentRoom_3 from './sounds/gameSounds/Classic/betsInCurrentRoom_3.mp3';
import timers_1 from './sounds/gameSounds/Classic/timers_1.mp3';
import timers_2 from './sounds/gameSounds/Classic/timers_2.mp3';
import gameStart_1 from './sounds/gameSounds/Classic/gameStart_1.mp3';
import gameEndingClassic_1 from './sounds/gameSounds/Classic/gameEnding_1.mp3';
import gameEndingClassic_2 from './sounds/gameSounds/Classic/gameEnding_2.mp3';
import gameEndingClassic_3 from './sounds/gameSounds/Classic/gameEnding_3.mp3';
import rouletteTicking_1 from './sounds/gameSounds/Classic/rouletteTicking_1.mp3';

// Double
import myBet_1 from './sounds/gameSounds/Double/myBet_1.mp3';
import myBet_2 from './sounds/gameSounds/Double/myBet_2.mp3';
import myBet_3 from './sounds/gameSounds/Double/myBet_3.mp3';
import rouletteStart_1 from './sounds/gameSounds/Double/rouletteStart_1.mp3';
import rouletteStart_2 from './sounds/gameSounds/Double/rouletteStart_2.mp3';
import rouletteStart_3 from './sounds/gameSounds/Double/rouletteStart_3.mp3';
import rouletteStart_4 from './sounds/gameSounds/Double/rouletteStart_4.mp3';
import rouletteStart_5 from './sounds/gameSounds/Double/rouletteStart_5.mp3';
import gameEndingDouble_1 from './sounds/gameSounds/Double/gameEnding_1.mp3';
import gameEndingDouble_2 from './sounds/gameSounds/Double/gameEnding_2.mp3';
import coinsTransfer_1 from './sounds/gameSounds/Double/coinsTransfer_1.mp3';

// Baccarat
import bet_1 from './sounds/gameSounds/Baccarat/bet_1.mp3';
import flipTheCards_1 from './sounds/gameSounds/Baccarat/flipTheCards_1.mp3';
import flipTheCards_2 from './sounds/gameSounds/Baccarat/flipTheCards_2.mp3';
import flipTheCards_3 from './sounds/gameSounds/Baccarat/flipTheCards_3.mp3';
import winning_1 from './sounds/gameSounds/Baccarat/winning_1.mp3';
import loosing_1 from './sounds/gameSounds/Baccarat/loosing_1.mp3';
import timer_1 from './sounds/gameSounds/Baccarat/timer_1.mp3';
import gameEnd_1 from './sounds/gameSounds/Baccarat/gameEnd_1.mp3';
import dispenseTheCards_1 from './sounds/gameSounds/Baccarat/dispenseTheCards_1.mp3';
import dispenseTheCards_2 from './sounds/gameSounds/Baccarat/dispenseTheCards_2.mp3';
import dispenseTheCards_3 from './sounds/gameSounds/Baccarat/dispenseTheCards_3.mp3';

// Crash
import betCrash_1 from './sounds/gameSounds/Crash/bet_1.mp3';
import crash_1 from './sounds/gameSounds/Crash/crash_1.mp3';
import crash_2 from './sounds/gameSounds/Crash/crash_2.mp3';
import crash_3 from './sounds/gameSounds/Crash/crash_3.mp3';
import crash_4 from './sounds/gameSounds/Crash/crash_4.mp3';
import crash_5 from './sounds/gameSounds/Crash/crash_5.mp3';
import crash_6 from './sounds/gameSounds/Crash/crash_6.mp3';
import stopButtonCrash_1 from './sounds/gameSounds/Crash/stopButton_1.mp3';
import gameStartCrash_1 from './sounds/gameSounds/Crash/gameStart_1.mp3';
import soundTrack_1 from './sounds/gameSounds/Crash/soundTrack_1.mp3';
import countDown_1 from './sounds/gameSounds/Crash/countDown_1.mp3';

// Slot
import gameStartSlot_1 from './sounds/gameSounds/Slot/gameStart_1.mp3';
import spinSounds_1 from './sounds/gameSounds/Slot/spinSounds_1.mp3';
import spinSounds_2 from './sounds/gameSounds/Slot/spinSounds_2.mp3';
import spinSounds_3 from './sounds/gameSounds/Slot/spinSounds_3.mp3';
import win_1 from './sounds/gameSounds/Slot/win_1.mp3';
import bonusGame_1 from './sounds/gameSounds/Slot/bonusGame_1.mp3';
import bonusGame_2 from './sounds/gameSounds/Slot/bonusGame_2.mp3';
import toggleLines_1 from './sounds/gameSounds/Slot/toggleLines_1.mp3';
import toggleLines_2 from './sounds/gameSounds/Slot/toggleLines_2.mp3';

// Tower
import winningTower_1 from './sounds/gameSounds/Tower/winning_1.mp3';
import loosingTower_1 from './sounds/gameSounds/Tower/loosing_1.mp3';
import step_1 from './sounds/gameSounds/Tower/step_1.mp3';
import play_1 from './sounds/gameSounds/Tower/play_1.mp3';
import playAgain_1 from './sounds/gameSounds/Tower/playAgain_1.mp3';
import take_1 from './sounds/gameSounds/Tower/take_1.mp3';

export const SvgIcons = {
  ArrowIcon,
  PlayIcon,
  PauseIcon,
};

export interface ISettingsSoundClassic {
  'Bets in current room': string[];
  Timers: string[];
  'Game start': string;
  'Game ending': string[];
  'Roulette ticking': string;
}

export interface ISettingsSoundDouble {
  'My bet': string[];
  'Roulette start': string[];
  'Game ending': string[];
  'Coins transfer': string;
}

export interface ISettingsSoundBaccarat {
  Bet: string;
  'Dispense the cards': string[];
  'Flip the cards': string[];
  Winning: string;
  Loosing: string;
  Timer: string;
  'Game end': string;
}

export interface ISettingsSoundCrash {
  Bet: string;
  'Crash button': string[];
  'Stop button': string;
  'Game start': string;
  'Sound track': string;
  'Count down': string;
}

export interface ISettingsSoundSlot {
  'Game start': string;
  'Spin sounds': string[];
  Win: string;
  'Bonus game': string[];
  'Toggle lines': string[];
}

export interface ISettingsSoundTower {
  Winning: string;
  Loosing: string;
  Step: string;
  Play: string;
  'Play again': string;
  Take: string;
}

export interface ISettingsSoundGames {
  Classic: ISettingsSoundClassic;
  Double: ISettingsSoundDouble;
  Baccarat: ISettingsSoundBaccarat;
  Crash: ISettingsSoundCrash;
  Slot: ISettingsSoundSlot;
  Tower: ISettingsSoundTower;
}

export interface ISettingsSoundCommon {
  'Chat message receiving': string;
  'Chat message sending': string[];
  'Level up': string;
  'Next rank': string;
  // 'Rooms bets': string[];
}
export interface ISettingsSounds {
  Classic: ISettingsSoundClassic;
  Double: ISettingsSoundDouble;
  Baccarat: ISettingsSoundBaccarat;
  Crash: ISettingsSoundCrash;
  Slot: ISettingsSoundSlot;
  Tower: ISettingsSoundTower;
  commonSounds: ISettingsSoundCommon;
}

export const SettingsSounds: ISettingsSounds = {
  Classic: {
    'Bets in current room': [betsInCurrentRoom_1, betsInCurrentRoom_2, betsInCurrentRoom_3],
    Timers: [timers_1, timers_2],
    'Game start': gameStart_1,
    'Game ending': [gameEndingClassic_1, gameEndingClassic_2, gameEndingClassic_3],
    'Roulette ticking': rouletteTicking_1,
  },
  Double: {
    'My bet': [myBet_1, myBet_2, myBet_3],
    'Roulette start': [
      rouletteStart_1,
      rouletteStart_2,
      rouletteStart_3,
      rouletteStart_4,
      rouletteStart_5,
    ],
    'Game ending': [gameEndingDouble_1, gameEndingDouble_2],
    'Coins transfer': coinsTransfer_1,
  },
  Baccarat: {
    Bet: bet_1,
    'Dispense the cards': [dispenseTheCards_1, dispenseTheCards_2, dispenseTheCards_3],
    'Flip the cards': [flipTheCards_1, flipTheCards_2, flipTheCards_3],
    Winning: winning_1,
    Loosing: loosing_1,
    Timer: timer_1,
    'Game end': gameEnd_1,
  },
  Crash: {
    Bet: betCrash_1,
    'Crash button': [crash_1, crash_2, crash_3, crash_4, crash_5, crash_6],
    'Stop button': stopButtonCrash_1,
    'Game start': gameStartCrash_1,
    'Sound track': soundTrack_1,
    'Count down': countDown_1,
  },
  Slot: {
    'Game start': gameStartSlot_1,
    'Spin sounds': [spinSounds_1, spinSounds_2, spinSounds_3],
    Win: win_1,
    'Bonus game': [bonusGame_1, bonusGame_2],
    'Toggle lines': [toggleLines_1, toggleLines_2],
  },
  Tower: {
    Winning: winningTower_1,
    Loosing: loosingTower_1,
    Step: step_1,
    Play: play_1,
    'Play again': playAgain_1,
    Take: take_1,
  },
  commonSounds: {
    'Chat message receiving': chatMessageReceiving_1,
    'Chat message sending': [chatMessageSending_1, chatMessageSending_2, chatMessageSending_3],
    'Level up': levelUp_1,
    'Next rank': nextRank_1,
    // 'Rooms bets': [
    //   roomsBets_1,
    //   roomsBets_2,
    //   roomsBets_3,
    //   roomsBets_4,
    //   roomsBets_5,
    //   roomsBets_6,
    //   roomsBets_7,
    //   roomsBets_8,
    // ],
  },
};
