import React from 'react';
import { ButtonOpenContainer, StyledButton } from './ButtonOpen.styles';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { useGetCasePrice, useGetOpenCaseType, useGetRouletteStatus } from 'games/CaseGame/hooks';
import { useDispatch } from 'react-redux';
import { useUser } from 'core/User';
import { useDialog } from 'core/hooks';
import { AuthDialogs } from 'core/Auth';
import { addNotification } from 'modules/Notifications/duck';
import {
  RouletteStatus,
  actionRequestReplay,
  openCaseActionRequest,
  rouletteStatusActionAllStartRoll,
} from 'games/CaseGame/ducks';
import { FastRollButton } from '../FastRoll';
import { useHistory } from 'react-router-dom';
import { actionClearRareCaseInfo } from 'modules/event-module/event-summertime/duck/default-event-duck';

interface ButtonOpenProps {
  caseId: string;
  lineCount: number;
}

export const ButtonOpen: React.FC<ButtonOpenProps> = React.memo(({ caseId, lineCount }) => {
  const dispatch = useDispatch();
  const { rouletteStatus, isAllInRoll, isAllStartRoll, isAllAfterRoll } = useGetRouletteStatus();
  const { t } = useTranslation('cases2.0');
  const { price } = useGetCasePrice();
  const { user } = useUser();
  const { handleOpenDialog } = useDialog(AuthDialogs.SIGN_IN);
  const history = useHistory();
  const { openCaseType } = useGetOpenCaseType();

  const redirectRefillHandler = () => {
    history.push('../refill');
  };

  const clickHandler = () => {
    if (rouletteStatus[0] !== RouletteStatus.IN_ROLL && lineCount) {
      dispatch(rouletteStatusActionAllStartRoll());
      dispatch(actionClearRareCaseInfo());
      dispatch(
        openCaseActionRequest({
          caseId,
          type: 'payable',
          lineCount,
          isFree: false,
        })
      );
    }
  };

  const replayHandler = () => {
    const search = new URLSearchParams(history.location.search).get('uuid');
    dispatch(actionRequestReplay(search, true));
    dispatch(rouletteStatusActionAllStartRoll());
  };

  const redirectHandler = () => {
    handleOpenDialog();
  };

  const notificationHandler = () => {
    dispatch(
      addNotification({
        type: 'error',
        body: 'You need a silver account to open case',
      })
    );
  };

  const getText = () => {
    if (openCaseType === 'replay') {
      return <>{t('replay')}</>;
    }
    if (user.id) {
      if (user.coins < price * lineCount) {
        return (
          <>
            {t('refill')}
            <CurrencyText value={price * lineCount - user.coins} />
          </>
        );
      } else {
        if (user.profileType === 'bronze') {
          return (
            <>
              {' '}
              {t('openCase')}
              <CurrencyText value={price * lineCount} />
            </>
          );
        }
        if (isAllAfterRoll) {
          return (
            <>
              {' '}
              {t('openAgain')}
              <CurrencyText value={price * lineCount} />
            </>
          );
        }
        return (
          <>
            {' '}
            {t('openCase')}
            <CurrencyText value={price * lineCount} />
          </>
        );
      }
    } else {
      return <>{t('login')}</>;
    }
  };
  const getClickHandler = () => {
    if (openCaseType === 'replay') {
      return replayHandler();
    }
    if (user.id) {
      if (user.coins < price * lineCount) {
        return redirectRefillHandler();
      } else {
        if (user.profileType === 'bronze') {
          return notificationHandler();
        }

        return clickHandler();
      }
    } else {
      return redirectHandler();
    }
  };

  return (
    <ButtonOpenContainer>
      <FastRollButton />
      <StyledButton
        disabled={isAllInRoll || isAllStartRoll || !lineCount}
        onClick={getClickHandler}
      >
        {isAllInRoll || isAllStartRoll ? t('draw') : getText()}
      </StyledButton>
    </ButtonOpenContainer>
  );
});
