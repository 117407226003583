import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { useUserMailboxForm } from 'core/User';
import { Container, MyButton, SubTitle, Title } from './profile-change-email-dialog.styles';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { EmailStatus } from 'modules/PersonalArea/components/SelfExclusion/EmailStatus';

interface IProfileChangeEmailDialog extends IDialogComponent<boolean> {}

export const ProfileChangeEmailDialog: FC<IProfileChangeEmailDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { handleCloseDialog } = useDialog(userModals.PROFILE_CHANGE_EMAIL);
  const { onRefresh } = useUserMailboxForm();

  const handleConfirm = () => {
    onRefresh();
    handleCloseDialog();
  };

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>{t('Change my email')}</Title>
      <SubTitle>{t('To change your email')}</SubTitle>
      <EmailStatus />
      <MyButton className="primary" onClick={handleConfirm}>
        {t('Send an email change request')}
      </MyButton>
    </Container>
  );
};
