import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnHeader, ColumnSpan, Container } from './ItemInfo.styles';
import { GeneralInfo, Header, Wear } from './components';
import { getImageFromSteamWithCustomSize } from 'core/utils';
import { NormalizesSimilar } from 'games/CaseGame/interfaces';

interface ItemInfoProps {
  collection: string;
  description: string;
  name?: string;
  rarity: string;
  similar?: NormalizesSimilar[];
}

export const ItemInfo: React.FC<ItemInfoProps> = ({
  collection,
  description,
  name,
  rarity: _rarity,
  similar,
}) => {
  const { t } = useTranslation('cases2.0');
  const [itemData, setItemData] = useState(similar ? similar[0].img : '');
  const changeHandler = useCallback((img: string) => setItemData(img), []);
  const wearColumns = [t('itemInfo.wear'), t('itemInfo.steamPrice'), t('itemInfo.statTrak')];
  return (
    <Container>
      <Header
        skin={similar[0].skin}
        img={getImageFromSteamWithCustomSize(itemData)}
        shortName={similar[0].shortName}
        name={name}
      />
      {
        <ColumnHeader>
          {wearColumns.map(column => (
            <ColumnSpan key={column}>{column}</ColumnSpan>
          ))}
        </ColumnHeader>
      }
      {similar?.map(({ exterior, statTrakPrice, steamPrice, img }) => (
        <Wear
          key={exterior}
          exterior={exterior}
          statTrakPrice={statTrakPrice}
          steamPrice={steamPrice}
          img={img}
          onClick={changeHandler}
        />
      ))}
      <GeneralInfo collection={collection} description={description} />
    </Container>
  );
};
