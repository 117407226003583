import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import { withTranslation } from 'react-i18next';
import SoundService from 'services/sound.service';

class _ControlsPassGame extends React.PureComponent {
  controlsPosition = new Animated.Value(1);
  lastPosition = 0;

  constructor() {
    super();
    this.sound = new SoundService();
  }

  componentDidMount() {
    this.controlsPosition.setValue(0);
  }

  render() {
    const controlsInterpolate = this.controlsPosition.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '-100%'],
      useNativeDriver: true,
    });

    const { t } = this.props;

    const handlePlayAgain = () => {
      this.sound.play('Tower', 'Play again');
      this.props.onBet();
    };

    return (
      <Animated.div
        style={{
          position: 'sticky',
          bottom: controlsInterpolate,
          left: 0,
          right: 0,
          zIndex: 1010,
        }}
        className="tablet"
      >
        <Container>
          <BtnInGame onClick={this.props.clearTicket}>{t('New game')}</BtnInGame>
          <BtnInPlay onClick={handlePlayAgain}>{t('Play again')}</BtnInPlay>
        </Container>
      </Animated.div>
    );
  }
}

export const ControlsPassGame = withTranslation()(_ControlsPassGame);

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(22, 30, 33, 0.8);
`;

const BtnInGame = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 45%;
  height: 40px;
  border: 2px solid var(--yellow);
  border-radius: 5px;
  background: transparent;

  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--yellow);
`;

const BtnInPlay = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 45%;
  height: 40px;
  border: 0;
  border-radius: 5px;
  background-color: var(--yellow);

  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--color-gray-950);
`;
