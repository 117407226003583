import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <Container className="content dark-layout">
      <p>
        <span>Hilo Game</span>
        {t(
          'In this mode, the card is opened repeatedly. You bet on the appearance of the next card. You can make bets on the following parameters:'
        )}
      </p>

      <p>
        <span>{t('Next card will be higher or lower by seniority.')} </span>
        {t(
          'In this category, all bets are summed up and coefficients are calculated simultaneously. Winners will get the sum of their bet multiplied by the result coefficient. You can bet on one of the options. When the joker falls out, next card will be compared to the one that fell before the joker.'
        )}
      </p>

      <p>
        <span>{t('Color of the next card.')}</span>
        {t('In this category, if you win, your bet doubles.')}
      </p>

      <p>
        <span>{t('The precedence of the next card.')} </span>
        {t(
          "In this category, if you win, your bet will increase by a multiplier of the card's seniority (from 2 to 9 – x1.5, from jack to ace – x3, from king to ace – x6b only ace – x12.) If you guessed the appearance of a joker, your bet will multiply by 24 ). You can put on each of the 5 options."
        )}
      </p>
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;

  & p {
    color: var(--color-white);
    padding: 9px 0px 8px 0px;
    font-size: 14px;
  }

  & span {
    color: #33ffff;
    padding-bottom: 5px;
    display: block;
    font-weight: 700;
  }
`;
