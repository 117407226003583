export default {
  'What is the Ticket System?': 'Co to jest system biletów?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Kiedy uczestnicy stawiają zakłady, otrzymują wirtualne bilety, jeden z którychbędzie zwycięski. Za każdy wniesiony skin o wartości $1 otrzymujesz 100 biletów. Za skin o wartości $10 otrzymasz 1000 biletów i tak dalej.',

  // GAMES

  'How is the winner determined?': 'Jak określa się zwycięzca rundy?',
  'When is the winner determined?': 'Kiedy zostanie wybrany zwycięzca rundy?',
  'How many bets can I place per one round?': 'Ile zakładów można stawiać w rundzie?',
  'What Service Commission is there in this game?': 'Jaka jest prowizja w tym trybie gry?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'gameto tryb dla tych, którzy lubią grać o wysokie stawki! Chętni spróbować szczęścia stawiają zakłady na ogólny depozyt, który rozgrywa się wśród uczestników w końcu rundy.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'W trybie Classic gamezwycięzcę określa się za pomocą systemu biletów.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Zwycięzca zostanie określony w końcu czasu rundy bądź po osiągnięciu maksymalnej liczby skinów na depozycie.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'Prowizja serwisu wynosi od 0-10% w zależności od wielkości wygranej.\nJako prowizję pobiera się losowe skiny z ogólnego depozytu rundy.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'game to wspaniały sposób natychmiast spróbować szczęścia. W rundzie tego trybu uczestniczą tylko trzech graczy z ograniczeniem maksymalnego zakładu. Zwycięzca otrzymuje depozyt trzech graczy.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Zwycięzcę rundy określa się losowo, lecz im większy zakład, tym większa jest szansa na zwycięstwo.',
  'One player can place one bet per one game round in this game.':
    'W tym trybie od każdego uczestnika przyjmuje się jeden zakład dla jednej rundy gry.',
  'Where can I see my chances of the winning?':
    'Jak dowiedzieć się o szansach na zwycięstwo w rundzie?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Szanse na zwycięstwo podawane są w odsetkach i znajdują się obok kwoty zakładu uczestników rundy (po prawej stronie od awatarów i listy włożonych do gry skinów',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Zwycięzca będzie określony, kiedy trzech graczy postawią zakłady na bieżącą rundę gry.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'Prowizja serwisu wynosi od 0 do 15% w zależności od wielkości wygranej. Jako prowizję pobiera się losowe skiny z łącznego depozytu rundy.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'to tryb gry, w którym gracze stawiają zakłady na zwycięstwo czarnego, czerwonego bądź zielonego sektora. Do zakładów w tym trybie gry wykorzystywane są Fast Coins. W końcu rundy zostanie określony kolor zwycięskiego sektora.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Jeśli w końcu zostanie wylosowany czerwony bądź czarny kolor sektora, kwota wygranej wraca do gracza w dwukrotnej wysokości.',
  'The green slot returns the placed bet multiplied by 14.':
    'Zielony sektor wraca 14-krotną kwotę zakładu.',
  'The bet sum of every slot can be different.': 'Kwota zakładu na każdy sektor może być różna.',

  'How can I Get Fast Coins?': 'Jak otrzymać Fast Coins?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Bierz udział w programie polecającym. Zapraszaj nowych graczy do projektu za pomocą linku polecającego bądź kodu. Za każdego poleconego gracza oraz za jego gry będziesz otrzymywał punkty.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Wymień swoje skiny wartością od $1 na Fast Coins. Za każdy cent wartości skinów otrzymasz 1 punkt. Można wysyłać do 30 przedmiotów za jednym razem. Dla oceny wartości zalecamy korzystanie ze Steam Inventory Helper',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Zasil konto bonusowe za pomocą systemu płatniczego. Wybierz dogodny sposób płatności i otrzymaj potrzebną ilość Fast pointsna swoim koncie.',

  'How is the winner slot determined (fair game)?':
    'Jak określa się zwycięski sektor (uczciwa gra)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'Na początku każdej rundy serwisgeneruje długą liczbę losową od 0 do 1 (na przykład, 0.223088) i szyfruje ją przez algorytm sha224. Wynik szyfrowania pokazywany jest na początku rundy. W końcu rundy usługa mnoży zaszyfrowaną uprzednio liczbę na 15, otrzymując numer wygranego sektora.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Przykład:Na początku rundy zaszyfrowano liczbę 0.223088232334703230728. Usługa mnoży liczbę 0.223088232334703230728 przez15 i otrzymuje liczbę 3,34632348495. Jako liczba całościowa wyników mnożenia wyszła liczba 3 (czerwony sektor). W tej rundzie wygrywają gracze, którzy stawiali na zwycięstwo czerwonego sektora.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Możesz samodzielnie sprawdzić prawidłowość określenia zwycięskiego sektora. W tym celu weź w końcu rundy zaszyfrowaną liczbę i powtórnie zaszyfruj ją za pomocą dowolnego z serwisów online, na przykład http://sha224.net. Zobaczysz tę samą wartość hash, co i w początku rundy. Oznacza to, że wyniki gry nie został sfałszowany.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'System określa zwycięski sektor jeszcze przed początkiem gry i każdy gracz może to przekontrolować, dlatego nie mamy możliwości wpływu na wyniki losowania.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Możesz zwiększać swoje zakłady nieograniczoną ilość razy przed początkiem rozgrywki. Kwota zakładu na każdy sektor może być różna.',

  'What bet limitations are there?': 'Jakie są ograniczenia dla zakładów?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'Minimalna stawka zakładu wynosi $0,1. Na jeden kolor można za jednym razem postawić maksymalnie $5000.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Uwaga! Podczas jednego losowania nie można obstawiać jednocześnie koloru czerwonego i czarnego. Obstawianie koloru zielonego jest dostępne podczas każdej rundy!',

  'When does the determination of the winner slot begin?':
    'Kiedy zacznie się wybór zwycięskiego sektora?',
  'The winner slot is determined at the end of a round.':
    'Zwycięski sektor zostanie określony w końcu czasu rundy.',

  'Is there any commission in this mode?': 'Czy jest jakaś prowizja w tym trybie gry?',
  'There is no commission at all. :)': 'Ten tryb gry nie przewiduje żadnych prowizji. :)',
};
