export default {
  'What is the Ticket System?': 'Was ist ein Ticket-System?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Wenn die Spieler Wetteinsätze platzieren, erhalten sie virtuelle Tickets. Eines der Tickets gewinnt. Wenn Ihr Item 1 $ Wert ist, erhalten Sie 100 Tickets. Ist der Skin, den Sie einsetzen, dagegen 10 $ Wert erhalten Sie 1000 Tickets usw.',

  // GAMES

  'How is the winner determined?': 'Wie wird der Gewinner bestimmt?',
  'When is the winner determined?': 'Wann wird der Gewinner ermittelt?',
  'How many bets can I place per one round?':
    'Wie viele Wetteinsätze können pro Runde platziert werden?',
  'What Service Commission is there in this game?': 'Welche Service-Gebühr wird im Spiel erhoben?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'ist ein Spiel für alle, die gern um hohe Einsätze spielen. Wer sein Glück versuchen möchte, trägt wie alle Spieler seinen Wetteinsatz bei. Am Ende der Runde werden alle Einsätze an einen der Spieler verlost.',
  'The winner is determined by the Ticket System in the Classic Game.': 'Ticket-Systems bestimmt.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Der Gewinner wird am Ende der Rundenzeit ermittelt oder wenn die zulässige Gesamtzahl gesetzten Skins einer Runde erreicht ist.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'Die Service-Gebühr beträgt zwischen 0 und 10 % je nach Betrag des Gewinns.\nDie Service-Gebühr sind Skins, die per Zufallsprinzip unter allen gesetzten Skins ausgewählt werden.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'ist eine großartige Chance, sein Glück sofort zu versuchen. Am Spiel nehmen nur drei Spieler teil und der maximale Wetteinsatz ist begrenzt. Der Gewinner erhält alle Einsätze von drei Spielern.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Der Gewinner wird nach dem Zufallsprinzip ausgewählt. Je höher der Einsatz, umso größer ist die Gewinnchance.',
  'One player can place one bet per one game round in this game.':
    'In diesem Spiel kann jeder Spieler pro Runde einmal setzen.',
  'Where can I see my chances of the winning?': 'Wo kann ich meine Gewinnchancen verfolgen?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Die Gewinnchancen werden in Prozent neben der Höhe des Wetteinsatzes eines Spieler in dieser Runde angezeigt (rechts von den Avatars und der Liste der gesetzten Skins).',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Der Gewinner wird ermittelt, nachdem die drei Spieler Ihre Wettangebote für die Runde abgegeben haben.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'Die Service-Gebühr beträgt zwischen 0 und 15% je nach Betrag des Gewinns. Als Service-Gebühr werden per Zufallsprinzip Skins unter allen gesetzten Skins ausgewählt.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'ist ein Spielmodus, bei dem die Spieler auf schwarze, rote oder grüne Slots wetten. In diesem Spiel werden als Wetteinsätze Fast Coins gesetzt. Die Farbe, die gewinnt wird am Ende der Runde ermittelt.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Gewinnen der schwarze oder der rote Slot, erhält der Spieler den doppelten Einsatz.',
  'The green slot returns the placed bet multiplied by 14.':
    'Gewinnt der grüne Slot ist der Wettgewinn das Vierzehnfache des Einsatzes.',
  'The bet sum of every slot can be different.':
    'Der Wetteinsatz kann für jeden Slot unterschiedlich sein.',

  'How can I Get Fast Coins?': 'Wie erhalte ich Fast Coins?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Nehme Sie am Partnerprogramm teil. Laden Sie mithilfe des Empfehlungslinks oder des Codes neue Spieler zum Projekt ein. Für jeden empfohlenen Spieler und jedes Spiel das er spielt, erhalten Sie Fast Coins.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Tauschen Sie Skins im Wert von 1 $ gegen Fast Coins ein. Für jeden Cent des Skinwerts erhalten Sie einen Punkt. Sie können höchstens 30 Items gleichzeitig senden.',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Zahlen Sie mithilfe des Zahlungssystems auf Ihr Bonus-Konto ein. Wählen Sie eine Zahlungsmethode und erwerben Sie Fast Coins für Ihr Konto.',

  'How is the winner slot determined (fair game)?': 'Wie wird der Gewinnslot ermittelt (Fairplay)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'Zu Beginn der Runde generiert der Service eine Zufallszahl zwischen 0 und 1 (z. B. 0, 223088) und verschlüsselt Sie mit dem Algorithmus sha224. Das Ergebnis wird zu Beginn jeder Runde angezeigt. Am Ende der Runde multipliziert der Service die verschlüsselte Zahl mit 15 und erhält die Zahl eines Gewinners.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Zum Beispiel: Die kodierte Nummer lautet zu Beginn des Spiels 0,223088232334703230728. Der Service multipliziert sie mit 15 und ermittelt die Zahl 3,34632348495. Die ganze Zahl dieser Runde ist die 3 (der rote Slot). Es gewinnen die Spieler, die ihre Wetteinsätze auf den roten Slot gesetzt haben.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Sie können selbst überprüfen, ob ein Gewinnerslot fair ermittelt wurde. Verschlüsseln Sie am Ende der Runde die kodierte Zahl mithilfe eines Online-Service z. B. http://sha224.net erneut. Sie erhalten denselben Hashwert wie zu Beginn der Runde. Das bedeutet, dass das Ergebnis nicht vorher festgelegt wird.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.",

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Bis zum Beginn der Verlosung können Sie Ihren Wetteinsatz beliebig oft erhöhen. Der Wetteinsatz kann für jeden Slot unterschiedlich sein.',

  'What bet limitations are there?': 'Welche Limits gelten für die Wetteinsätze?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'Der kleinste Wetteinsatz sind 0,1 $. Zu einem Zeitpunkt können Sie auf eine Farbe höchstens 5000 $ setzen.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Achtung! Sie können während einer Ziehung nicht gleichzeitig auf Rot und auf Schwarz wetten. Auf Grün können Sie in jeder Runde wetten!',

  'When does the determination of the winner slot begin?':
    'Wann beginnt die Ermittlung der Gewinnslots?',
  'The winner slot is determined at the end of a round.':
    'Der Slot, der gewonnen hat, wird am Ende der Runde ermittelt.',

  'Is there any commission in this mode?': 'Gibt es in diesem Modus eine Gebühr?',
  'There is no commission at all. :)': 'Nein, es wird überhaupt keine Gebühr erhoben. :)',
};
