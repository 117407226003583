import React from 'react';
import { Wrapper } from './CreateBattlePage.styles';
import { AppBar, ToolBar } from '@components/bars';
import { CreateGame } from 'games/CaseBattle/components';

export const CreateBattlePage = () => {
  return (
    <>
      <AppBar deep />
      <Wrapper className="content dark-layout position">
        <ToolBar />
        <CreateGame />
      </Wrapper>
    </>
  );
};
