import React from 'react';
import { RefillSystemsList } from './refill-systems-list';
import { CountrySelector } from './country-selector';
import { Bonus } from './bonus/bonus';
import { useRefillLoading } from 'modules/Refill/hooks/useRefillLoading.hook';
import { AppBar } from '@components/bars';

export const RefillSystemsPage = () => {
  const { loading } = useRefillLoading();
  const isDisableLayout = loading ? 'disable-layout' : '';

  return (
    <>
      <AppBar deep />
      <div className={`content dark-layout ${isDisableLayout}`}>
        <CountrySelector />
        <RefillSystemsList />
        <Bonus />
      </div>
    </>
  );
};
