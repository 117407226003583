import React from 'react';
import { OptionWrapper } from './drop-down.styles';
import { useLocation } from 'react-router-dom';

interface OptionProps {
  id: number;
  index: number;
  onClick: (caseId: string, page: number) => void;
  toggleHandler?: () => void;
}
export const Option: React.FC<OptionProps> = React.memo(({ onClick, id, index, toggleHandler }) => {
  const { pathname } = useLocation();
  const caseId = pathname.split('/')[2];
  const clickHandler = () => {
    onClick(caseId, index);
    toggleHandler();
  };

  return <OptionWrapper onClick={clickHandler}>{id}</OptionWrapper>;
});
