import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  position: relative;
  width: fit-content;
  height: fit-content;

  & > .card-size-add-case {
    width: 64px;
    height: auto;
    aspect-ratio: 0.92 / 1;

    @media screen and (min-width: 500px) {
      width: 80px;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  right: 2px;
  background: var(--dark-opacity-500);
`;
