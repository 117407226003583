import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppBar } from '@components/bars';

import { AssistanceNavigator } from '../components';
import { faqModule as faq } from 'modules/FAQ';
import { AnimatedRouter } from '@components/animated-router';

export const AssistanceRoute: React.FC<RouteComponentProps> = ({ match }) => (
  <>
    <AppBar />
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/faq`} component={faq.component} />
        <Route path={`${match.url}`} component={AssistanceNavigator} />
      </AnimatedRouter>
    </div>
  </>
);
