import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { countriesDictionary } from '../../../refill.util';
import { useCountryPay } from '../../../hooks';
import { URLS } from 'services/constant-urls';
import { userModals } from 'core/User/modal.config';
import { useDialog } from 'core/hooks';
import { refillLoading } from 'modules/Refill/duck';
import { dialogOn } from 'core/ducks/dialog';
import { useAutoRefillCountry } from 'modules/Refill/hooks/useAutoRefillCountry.hook';

export const CountrySelector: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { refillCountry } = useCountryPay();
  const country = countriesDictionary?.[refillCountry];

  const { handleOpenDialog, handleCloseDialog } = useDialog(userModals.REFILL_COUNTRY);
  const { autoRefillCountry } = useAutoRefillCountry();

  useEffect(() => {
    return () => handleCloseDialog();
  }, [handleCloseDialog]);

  useEffect(() => {
    if (!refillCountry) {
      dispatch(refillLoading(true));
      if (autoRefillCountry) {
        dispatch(dialogOn(userModals.REFILL_COUNTRY));
      }
    } else {
      dispatch(refillLoading(false));
    }
  }, [dispatch, refillCountry, autoRefillCountry]);

  return (
    <Container>
      <span>{t('Select country:')}</span>
      <SelectControl className="select-control" onClick={handleOpenDialog}>
        <img
          className="circle"
          src={refillCountry ? `${URLS.countryFlags}/${refillCountry?.toLowerCase()}.png` : ''}
          alt={country}
        />
        <span>{country}</span>
        <i className="icon-arrow append" />
      </SelectControl>
    </Container>
  );
};

const SelectControl = styled.div`
  display: grid;
  font-size: 1rem;
  grid-gap: 0.5rem;
  grid-template-columns: 30px 1fr 15px;
  height: 62px;
  padding: 0 1rem;
  width: 100%;

  border: solid 1px var(--gray-400);

  &:nth-child(odd) {
    border-left: 0;
    border-right: 0;
  }

  &:nth-child(even) {
    border-right: 0;
  }

  & > * {
    align-self: center;
  }

  & > span {
    color: var(--color-white);
    font-size: 1em;
    text-transform: uppercase;
  }

  & > i {
    color: var(--color-white);
    font-size: 1.35em;
    justify-self: center;

    &.append {
      font-size: 0.8em;
    }
  }

  & > img {
    height: 30px;
    object-fit: cover;
    text-indent: -9999px;
    width: 30px;

    &.circle {
      border-radius: 50%;
    }
  }
`;

const Container = styled.div`
  & .select-control {
    background-color: var(--color-dark-600);
  }

  & > span {
    display: block;
    padding: 2rem 1rem 0.8rem;
    opacity: 0.7;
    font-size: 0.8rem;
    letter-spacing: 0.69px;
    color: var(--color-white);
    text-transform: uppercase;
    background: var(--dark);
  }

  & > div {
    border: 1px solid var(--color-gray-900);
    border-left: 0;
    border-right: 0;
  }
`;
