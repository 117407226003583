import React from 'react';
import { AppBar } from '@components/bars';
import { UserVerification } from './user-verification';

export const PayoutUserVerificationPage = () => (
  <>
    <AppBar deep />
    <UserVerification />
    <div id="verification-portal" />
  </>
);
