import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from '@components/Common';
import { IDataTradeDurationDialog, IDialogComponent, ITradeDurationForm } from 'core/interfaces';
import { RadioButtonControl } from '@UIkit';
import { Container } from './trades-duration-settings-dialog.styles';

interface ITradesDurationSettingsDialog extends IDialogComponent<IDataTradeDurationDialog> {}

const durations = ['1', '3', '12'];

export const TradesDurationSettingsDialog: FC<ITradesDurationSettingsDialog> = ({
  handleCloseModal,
  data: { defaultValues, onSubmit },
}) => {
  const { t } = useTranslation('marketplace');
  const { register, handleSubmit } = useForm<ITradeDurationForm>({
    mode: 'onChange',
    defaultValues,
  });

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Button className="link-light close-dialog" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <div className="header-dialog">{t('Trading duration')}</div>
      <div className="trades-duration-container">
        {durations.map(v => (
          <RadioButtonControl key={v}>
            <input ref={register} name="duration" type="radio" value={v} />
            <span>
              {v}{' '}
              {t('pluralize time.hours', {
                postProcess: 'interval',
                count: Number(v),
              })}
            </span>
          </RadioButtonControl>
        ))}
      </div>
      <div className="controllers">
        <Button className="primary" onClick={handleSubmit(onSubmit)}>
          {t('Confirm')}
        </Button>
      </div>
    </Container>
  );
};
