import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { FastInventoryFiltersConfig, useFastInventorySortFilter } from 'core/User';
import { IDialogComponent } from 'core/interfaces';

interface ISortByFilterDialog extends IDialogComponent<boolean> {}

export const SortByFilterDialog: FC<ISortByFilterDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation(['stock', 'translations']);
  const { values, changeSort } = useFastInventorySortFilter();
  return (
    <Container>
      <div className="filter-title">{t('sortBy.label')}</div>
      <div className="filter-list">
        {Array.from(FastInventoryFiltersConfig.sorts.entries()).map(([k, v]) => (
          <button key={k} className="filter-list-item" onClick={changeSort(k)}>
            <div>
              <span className={`indicator ${values === k ? 'selected' : ''}`} />
            </div>
            <div className="description">
              <span>{t(`sortBy.${FastInventoryFiltersConfig.sortBy(k)}`)}</span>
            </div>
          </button>
        ))}
        <div className="item-buffer" />
        <div className="filter-controller">
          <Button className="primary full" onClick={handleCloseModal}>
            {t('translations:::Confirm')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);

  & .filter-title {
    height: 3.75rem;
    padding: 2.125rem 1rem 0.5rem;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-white);

    &:not(:first-of-type) {
      padding-top: 1.5rem;
    }
  }

  & .filter-list {
    display: grid;
    grid-auto-flow: row;

    &-item {
      display: grid;
      grid-template-columns: 1.625rem 1fr;
      grid-template-rows: 3.75rem;
      grid-gap: 1.25rem;
      padding: 0 1.125rem;
      background: transparent;
      border: 0;

      & div {
        align-self: center;
        justify-self: self-start;
      }

      & .indicator {
        display: block;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background: var(--color-dark-100);
        border: 3px solid var(--gray-220);
        transition: all 70ms linear;

        &.selected {
          border-width: 4px;
          background: var(--warning);
        }
      }

      & .description {
        display: grid;
        grid-auto-rows: 1rem;
        grid-auto-flow: row;
        grid-gap: 2px;

        & span {
          font-size: 13px;
          line-height: 0.875rem;
          letter-spacing: 0.286px;
          color: var(--color-white);
        }
      }
    }
  }

  & .underprice,
  & .overprice {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    padding: 0.875rem 2.5rem 0.875rem 1.3rem;

    &:first-of-type {
      border-top: none;
    }

    &-title {
      font-size: 0.875rem;
      line-height: 25px;
      letter-spacing: 0.24px;
      color: var(--color-white);
    }

    &-selector {
      display: flex;
      flex-flow: row wrap;
      margin-right: -1.625rem;

      & button {
        margin: 0 0.75rem 0.5rem 0;
        padding: 0.5rem 0.75rem;
        background: var(--color-dark);
        border: 0.125rem solid var(--color-dark-200);
        border-radius: var(--size-radius-s);
        font-weight: bold;
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        color: var(--color-white);

        &:disabled {
          background: var(--color-dark-200);
        }
      }
    }
  }

  & .filter-controller {
    display: flex;
    padding: 0 1rem;
    overflow: hidden;
    height: 6rem;

    & button {
      margin: 1.25rem 0 1.75rem;
    }
  }
`;
