import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchCreateBid, setMyBet } from '../duck';
import { BoardTypes } from '../interfaces';

export const useBaccaratParticipateController = () => {
  const dispatch = useDispatch();

  const handleCreateParticipate = useCallback(
    (type: BoardTypes, amount: number) => () => {
      dispatch(fetchCreateBid({ [type]: amount }));
      dispatch(setMyBet([type]));
    },
    [dispatch]
  );

  return {
    handleCreateParticipate,
  };
};
