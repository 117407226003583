import { MutableRefObject, useLayoutEffect, useState } from 'react';

export const useScrollStickyView = (scrollRefs: MutableRefObject<HTMLElement | null>) => {
  const [visible, setVisible] = useState(true);

  useLayoutEffect(() => {
    if (!scrollRefs.current) return;

    let prevPosition = 0;
    let throttleTimeout: null | number = null;

    const element = scrollRefs.current;

    const onScroll = (ev: any) => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(() => {
          if (ev.target.scrollTop + ev.target.clientHeight >= ev.target.scrollHeight) {
            setVisible(false);
            prevPosition = ev.target.scrollHeight;
          } else {
            setVisible(ev.target.scrollTop <= prevPosition ? true : false);
            prevPosition = ev.target.scrollTop >= 0 ? ev.target.scrollTop : 0;
          }

          throttleTimeout = null;
        }, 500);
      }
    };

    element.addEventListener('scroll', onScroll);

    return () => element.removeEventListener('scroll', onScroll);
  }, [scrollRefs]);

  return {
    visible,
  };
};
