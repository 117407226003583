import { CaseBattleIcon } from 'games/CaseBattle/assets';
import styled from 'styled-components';

export const Container = styled.div<{ color: string; isDroppedItem: boolean; isJoker: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color ?? '#243038'};
  border-radius: 5px;
  width: 80px;
  min-height: 68px;
  ${({ isDroppedItem, isJoker }) =>
    isDroppedItem &&
    `background-image:url(${
      !isJoker ? CaseBattleIcon.droppedItemAnimation : CaseBattleIcon.jokerDroppedItemAnimation
    });
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    `};

  @media screen and (max-width: 374px) {
    min-height: 68px;
    width: 66px;
  }
`;

export const ItemImg = styled.img`
  width: 54px;
  height: 40px;
`;
