import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePrivacySettings } from 'core/AppShell/hooks/usePrivacySettings.hook';
import { CheckBox, CheckboxContainer, Wrapper } from './Privacy.styles';

export const Privacy = () => {
  const { t } = useTranslation();
  const {
    statsIsHidden,
    providersSteam,
    handleToggleStats,
    handleToggleSteam,
  } = usePrivacySettings();

  return (
    <Wrapper className="content">
      <CheckboxContainer>
        {t('Hide game statistics from other users')}
        <CheckBox type="checkbox" id="stat" checked={statsIsHidden} onChange={handleToggleStats} />
        <label htmlFor="stat" />
      </CheckboxContainer>
      {providersSteam && (
        <CheckboxContainer>
          {t('Hide Steam link in profile')}
          <CheckBox
            type="checkbox"
            id="steam"
            checked={providersSteam?.is_hidden}
            onChange={handleToggleSteam(!providersSteam?.is_hidden)}
          />
          <label htmlFor="steam" />
        </CheckboxContainer>
      )}
    </Wrapper>
  );
};
