import React, { FC } from 'react';
import styled from 'styled-components';
import { ModeType } from '../../interfaces';
import { FastInventoryClearance } from './fast-inventory-clearance';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/hooks';
import { FastInventoryModal } from '../../fast-inventory-modal.config';
import { useFastInventoryInfo } from 'core/User';
import { CurrencyText } from '@components/currency';

interface IFastInventoryController {
  mode: ModeType;
  reset: () => void;
}

export const FastInventoryController: FC<IFastInventoryController> = ({ mode, reset }) => {
  const { t } = useTranslation('stock');
  const { handleOpenDialog } = useDialog(FastInventoryModal.CONFIRM_SELLING);
  const { identifiers, loading } = useFastInventoryInfo();
  const { info } = useFastInventoryInfo();

  return (
    <>
      <Container visible={!!mode}>
        <div className="controllers">
          {/* <button onClick={setMode(ModeType.WITHDRAWAL)}>{t('withdraw')}</button> */}
          <StyledButton onClick={handleOpenDialog} disabled={identifiers === 0 || loading}>
            {t('sellALL')} (<CurrencyText value={info?.itemsSum ?? 0} />)
          </StyledButton>
        </div>
        <FastInventoryClearance mode={mode} reset={reset} />
      </Container>
    </>
  );
};

const Container = styled.div<{ visible: boolean }>`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${({ visible }) => (visible ? '13.25rem' : '5rem')};
  transition: height 50ms linear;
  overflow: hidden;

  & > .controllers {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 1px;
    height: 5rem;
    padding: 1.5rem 0 1.25rem;
    background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
    backdrop-filter: blur(4px);
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-dark-200);
      border: 0;
      padding: 0;
      transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

      color: var(--color-white);
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.75rem;
      text-transform: uppercase;

      &:active {
        background: var(--color-dark-600);
      }
    }
  }
`;
const StyledButton = styled.button`
  ${p => p.disabled && 'color: var( --mGray-300)!important;'}
`;
