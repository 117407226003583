export default {
  SPIN: 'КРУТИТЬ',
  'You are not eligible for free spins':
    'Бесплатные монеты могут получать только Steam пользователи с CSGO. Перелогиньтесь чтобы обновить.',
  'It must be 24 hours since last roll': 'Должно пройти 24 часа с последней игры.',
  'You already played maximum times in this month. Wait for end of current month.':
    'Вы уже использовали все попытки в текущем месяце!',
  'game from:': 'Игра от:',
  'Case once a month': 'Кейс один раз в месяц',
};
