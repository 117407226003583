import { reducer } from './duck';
import './locales';

export type { IHiloReducer } from './interfaces';
export { hiloEpic } from './duck';

export { GameRouter as HiloRouter } from './containers';
// export const HiloRouter = lazy(() => import('./containers').then(module => ({ default: module.GameRouter })))
export const hiloNewModule = {
  moduleName: 'hilo',
  moduleId: 16,
  path: '/hilo',
  type: 'pve',
  reducer,
};
