import * as React from 'react';
import styled from 'styled-components';
// @ts-ignore
import * as Animated from 'animated/lib/targets/react-dom';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';

import { IHistory as IPoggiHistory } from 'games/Poggi/interfaces/poggi.reducer.interfaces';
import { textLimit } from 'core/utils/text.utils';

const AnimatedDiv = Animated.createAnimatedComponent('div');

const { useRef, useState, useEffect } = React;

interface IHistoryItem extends IPoggiHistory {
  number?: number;
  content: (props: React.ComponentProps<typeof HistoryItem>) => JSX.Element;
}

export const HistoryItem: React.FC<IHistoryItem> = props => {
  const animatedValue = useRef({
    toggleAnimate: new Animated.Value(0),
  }).current;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    Animated.timing(animatedValue.toggleAnimate, {
      toValue: isOpen ? 1 : 0,
      duration: 300,
    }).start();
  }, [isOpen, animatedValue.toggleAnimate]);

  return (
    <AnimatedContainer
      style={{
        height: animatedValue.toggleAnimate.interpolate({
          inputRange: [0, 1],
          outputRange: [75, 155],
        }),
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <HistoryHeader>
        <TitleContainer>
          {props.number ? (
            <HistoryTitle>
              {t('GAME')}
              {` №${props.number}`}
            </HistoryTitle>
          ) : (
            <UserInfo>
              <UserAvatar src={props.avatar} alt="user-avatar" />
              <UserName>{textLimit(props.username, 20)}</UserName>
            </UserInfo>
          )}
        </TitleContainer>
        <BetContainer>
          <HistoryBet>
            <CurrencyText value={props.profit} />
          </HistoryBet>
        </BetContainer>
      </HistoryHeader>
      {props.content({ ...props })}
    </AnimatedContainer>
  );
};

const AnimatedContainer = styled(AnimatedDiv)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 10px 17px;
  background-color: var(--gray-350);
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    display: block;

    width: 15px;
    height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
  }

  &:before {
    right: 0;
    background-image: linear-gradient(to left, #212f35, rgba(33, 47, 53, 0.1));
  }

  &:after {
    left: 0;
    background-image: linear-gradient(to right, #212f35, rgba(33, 47, 53, 0.1));
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
`;

const HistoryTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.7px;
  line-height: 1.8;
  color: var(--color-white);
`;

const HistoryHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  flex-shrink: 0;
  padding: 21px 15px 15px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border: solid 1px var(--color-white);
  padding: 1px;
  margin-right: 7px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
`;

const UserName = styled.span`
  font-size: 14px;
  letter-spacing: 0.77px;
  color: var(--color-white);
`;

const BetContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 50%;
`;

const HistoryBet = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: var(--warning);
  line-height: 1.8;
`;
