import { createSelector } from 'reselect';
import { statisticsAdapter } from '../../adapters/statistics.adapter';
import { getChartStatistics } from '../PersonalArea/statistics.utils';
import { IStore } from 'store/interface';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';

export const getAccountProfile = ({ accounts }: IStore): IUserInfo => accounts.getIn(['profile']);

export const getAccountLeaderStatistics = ({ accounts }: IStore) =>
  accounts.getIn(['statistics', 'leader']);

export const getAccountStatistics = createSelector(
  ({ accounts }: IStore) => accounts.getIn(['statistics', 'profile']),
  stats => {
    const statistics = statisticsAdapter(stats);
    const charts = getChartStatistics(statistics);

    return {
      statistics,
      charts,
    };
  }
);
