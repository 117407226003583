import { useSelector } from 'react-redux';
import { getRouletteAnimationStatuses } from '../ducks';

export const useGetRouletteAnimationStatuses = () => {
  const { linesStatus, battleId } = useSelector(getRouletteAnimationStatuses);
  return {
    linesStatus,
    battleId,
  };
};
