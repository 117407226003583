import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-100);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 10px 22px;
  width: 100%;
`;

export const Point = styled.div<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'transparent')};
  border-radius: 50%;
  border: 1px solid var(--color-white);
  height: 5px;
  width: 5px;
`;
