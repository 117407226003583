import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <Container>
        <RulesP>
          <RulesSpan>Wheel of Fortune </RulesSpan>
          {t(
            'is a mode of game where you can spin the wheel of fortune and get chance to win great prizes!'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How to play Wheel of Fortune?')} </RulesSpan>
          {t(
            'After selecting this mode, you need to click the "Spin" button. After that the wheel starts to spin and after stopping it you will see your prize.'
          )}
        </RulesP>

        <RulesP>{t('All winnings will come directly to your balance.')}</RulesP>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding: 16px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 14px;
`;

const RulesSpan = styled.span`
  color: #33ffff;
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;
