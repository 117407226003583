import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.25rem;
  padding: 1.6875rem 1rem 0;

  & .event-description {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 3rem;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.54px;
    color: white;

    & i {
      font-size: 1rem;
      color: var(--color-orange-ticket);
      margin-right: 0.25rem;
    }
  }
`;

export const NumberInput = styled.input`
  width: 100%;
  height: 40px;
  background-color: var(--color-white);
  font-size: 1rem;
  letter-spacing: 0.47px;
  color: var(--dark);
  border: 0;
  border-radius: 5px;
  padding: 0 0.7rem;

  &::placeholder {
    opacity: 0.5;
    font-size: 1rem;
    letter-spacing: 0.47px;
    color: #161e21;
  }
`;

export const Fields = styled.div`
  display: grid;
  grid-gap: 0.5rem;

  & div {
    font-size: 0.8rem;
    letter-spacing: 0.03px;
    color: var(--color-white);

    &.withdraw {
      color: var(--color-gray-200);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      letter-spacing: 0.1px;
      line-height: 15px;
      padding-top: 20px;
      text-transform: uppercase;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  & .input-wrapper {
    display: flex;
    flex-flow: row nowrap;

    & .amount-bonuses {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10rem;
      font-weight: bold;
      color: var(--green);
      margin-left: -5px;
      padding-left: 5px;
      background: var(--color-dark-200);
      border-radius: 0 5px 5px 0;
      position: relative;
      z-index: -1;
      font-size: 12px;
    }
  }
`;

export const ErrorSpan = styled.span`
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: var(--danger);
`;
