import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  padding: 20px 20px 20px 30px;
  grid-template-areas: 'b a a' 'b c c' 'b c c';
`;
export const CasePriceNameContainer = styled.div`
  display: flex;
  grid-area: a;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
`;
export const TimeContainer = styled.div`
  display: flex;
  color: var(--color-white);
  gap: 2px;
`;
export const TimeInfoWrapper = styled.div`
  grid-area: c;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CaseName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--color-white);
`;
export const CasePrice = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: var(--yellow);
`;
export const CaseImage = styled.img`
  grid-area: b;
  max-width: 125px;
  border-radius: var(--size-radius-l);
  max-height: 140px;
`;
export const Text = styled.span`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: left;
  color: var(--color-white);
`;

export const TimeWrapper = styled(Text)`
  color: var(--gray-time);
`;
