export default {
  Error: '錯誤',
  'Error placing a bet': '下注时发生错误',
  'Error reserving an item': '保留项目时出错',
  'Link saved successfully': '連結已成功儲存',
  "You've successfully updated a bet": '您已成功更新了一个投注',
  "You've successfully sell winning item": '您已成功售出赢得的物品',
  "You've successfully take winning item": '您已成功拿走赢得的物品',
  'Incorrect link. Enter the correct link and try again.': '链接错误。输入正确的链接，然后重试。',
  'This is not your link. Enter your link and try again.': '這不是您的連結。請輸入您的連結並重試。',
  "Don't forget to make your inventory public to get your winnings!":
    '為接收獎品，別忘了將您的庫存設為公開！',
  'min. number of items per trade not exceeded': '未超过每笔交易的最少物品数量',
  'Too many requests, try again later': '請求過多，請稍後再試',
  'Your trade has been cancelled for the following reason': '您的交易因下列原因已取消：',
  'cheating attempt': '企圖作弊',
  'max. number of items per trade exceeded': '超出每筆交易的最大道具數',
  'player not registered on website': '玩家未在網站上註冊',
  'souvenir items are not allowed': '不允許使用紀念道具',
  'some of the items cannot be appraised': '部分道具無法估價',
  'some of the items are below minimum price': '部分道具低於最低價格',
  'total price of a trade is below minimum': '交易總價低於最小值',
  'Your trade has been accepted!': '您的交易已被接受！',
  'The items have been added to the queue.': '道具已添加到佇列中。',
  'Your trade has been checked out!': '您的交易已結帳！',
  'It is currently being accepted': '交易正被接受',
  'Unable to send a trade': '無法發送交易',
  'Update your trade link in the settings of the site': '在網站的設定中更新您的交易連結',
  'Enter correct trade link and make sure your inventory is not full.':
    '輸入正確的交易連結，並確保您的庫存未滿',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    '您的帐户不可用于交易。如果您邀请某人进行交易，更多信息将显示。',
  'Steam is unavailable at this moment. Please wait for retry.': 'Steam 此时不可用。请稍等重试。',
  'Not enough money on balance': '剩餘快點不足',
  'Not enough points on balance': '剩餘快點不足',
  'Game is ending, try again later': '遊戲即將結束，請稍後再試',
  'Congratulations!': '恭喜！',
  "You've successfully placed a bet": '您已成功下注',
  "You've successfully placed a bet with </amount>": '您已成功下注 {{</amount>}}',
  'Item has not been found, coins have been returned': '商品缺貨，退還金幣',
  'Your order in the store has been cancelled': '您在商店中的訂單已取消',
  'The min bet is </minBet>': '最小賭注為 {{</minBet>}}。',
  'The max bet is </maxBet>': '最大下注是 {{</maxBet>}}。',
  'You cannot place bet on red and black at same time': '您不能同时下注红色和黑色',
  "You don't have CSGOFAST.com in Steam nickname!": '您在 Steam 昵称中没有 CSGOFAST.com！',
  'You have exceeded the limit of 150 free coins per account':
    '您已经超过了每个帐户 150 个免费钱币的限制',
  "You've bet </amount> on the crash game #</game>!":
    '你已经在 crash 游戏 #{{</game>}} 投注了 {{</amount>}} 硬币！',
  "You've updated coef to </coef> for crash game #</game>!":
    '你已经将 crash 游戏 #{{</game>}}的系数更新为 {{</coef>}} ！',
  "You've won </amount> in the crash game #</number>!":
    '你已经在 crash 游戏 #{{</number>}} 中赢得了 {{</amount>}} 硬币！',
  'Code for $</amount> has been created': '{{</amount>}} 枚硬币的代码已创建',
  'You already have an item': '您已经有一件物品',
  '</amount> refilled on your balance': '您的余额已充值 {{</amount>}}',
  'We will sent it to your Steam in few moments': '我们会在几分钟之内将它发送到您的 Steam',
  'Your items has been sended to game!': '您的物品已被发送到游戏！',
  '</amount> have been returned': '已退还 {{</amount>}}',
  'Item has not been found, retry in few minutes (</amount>)':
    '物品尚未找到，请在几分钟后重试 ({{</amount>}})',
  'It is currently being processing by game bots': '游戏机器人目前正对它进行处理',
  '</amount> have been refilled': '已充值 {{</amount>}}',
  'Request successfully created': '已成功创建请求',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    '您 {{</amount>}} 的订单 #{{</id>}} 已被接受，请等待 Steam 交易。',
  'Reason of order rejection': '拒绝订单的原因',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    '您 {currency(amount)} 的下注已被接受，并将在 </time> 秒内添加到游戏',
  'Your bet was added to queue, in few seconds will added to the game':
    '您的下注被添加到队列中，几秒钟后将添加到游戏中',
};
