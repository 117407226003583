import React from 'react';
import { textLimit } from '../../../core/utils/text.utils';
import styled from 'styled-components';
import { AccountAvatar } from '@components/Common';
import { IPlayer } from '../interfaces/top.reduce.interface';

import { CurrencyText } from '../../currency';

interface IListProps {
  topList: IPlayer[];
}

interface IListState {
  ladder: IPlayer[];
}

export class List extends React.PureComponent<IListProps, IListState> {
  state: IListState = {
    ladder: [],
  };

  componentDidMount() {
    const { topList } = this.props;
    const ladder = topList.slice(0, 10);
    this.setState({ ladder: ladder });

    this.getUserLast(topList, ladder);
  }

  componentDidUpdate(prevProps: IListProps) {
    if (prevProps.topList.length !== this.props.topList.length) {
      const ladder = this.props.topList.slice(0, 10);
      this.setState({ ladder });
      this.getUserLast(this.props.topList, ladder);
    }
  }

  render() {
    const { ladder } = this.state;
    return (
      <>
        {ladder.map((player: IPlayer, i: number) => {
          const isTop = i <= 2;

          return (
            <TopItem key={i} colorBg={player.me || isTop}>
              <ItemFlex>
                <TopId top={isTop}> {player.rank}</TopId>
                <TopItemImg>
                  <AccountAvatar uri={player.ava_link} userId={player.id} />
                </TopItemImg>

                <TopUsername>{textLimit(player.name, 14)}</TopUsername>
              </ItemFlex>
              <TopPrize top={isTop}>
                <CurrencyText value={player.volume} />
              </TopPrize>
            </TopItem>
          );
        })}
      </>
    );
  }

  getUserLast = (topList: IPlayer[], ladder: IPlayer[]): void => {
    const userProfile = topList.find(profile => profile.me);

    if (userProfile && topList.indexOf(userProfile) > 8)
      this.setState({ ladder: [...ladder, userProfile] });
  };
}

const ItemFlex = styled.div`
  display: flex;
  align-items: center;
`;

interface ITopItem {
  colorBg: boolean;
}

const TopItem = styled.div<ITopItem>`
  padding: 8px;
  margin: 2px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${p => (p.colorBg ? 'var(--color-dark-600)' : 'transparent')};
`;

const TopItemImg = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  & img {
    pointer-events: auto;
  }
`;

interface ITopId {
  top: boolean;
}

const TopId = styled.div<ITopId>`
  color: ${p => (p.top ? 'var(--ochre)' : '#33ffff')};
  display: flex;
  align-items: center;
  min-width: 18px;
  justify-content: center;
`;

const TopUsername = styled.div`
  color: var(--color-white);
  margin-left: 8px;
  font-size: 13px;
`;

interface ITopPrize {
  top: boolean;
}

const TopPrize = styled.div<ITopPrize>`
  color: ${p => (p.top ? 'var(--ochre)' : 'var(--color-white)')};
`;
