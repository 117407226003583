import React, { FC } from 'react';
import { Container, Subtitle, Title, Type } from './slide.styles';

interface SlideProps {
  subtitles: {
    personalData: string;
    whenWeCollect: string;
    howWeUse: string[];
    whyWeUse: string[];
  };
}

export const Slide: FC<SlideProps> = ({ subtitles }) => {
  return (
    <Container>
      <Type>
        <Title>Personal Data</Title>
        <Subtitle>{subtitles.personalData}</Subtitle>
      </Type>
      <Type>
        <Title>When We collect</Title>
        <Subtitle>{subtitles.whenWeCollect}</Subtitle>
      </Type>
      <Type>
        <Title>How We use</Title>
        {subtitles.howWeUse.map(item => (
          <Subtitle key={item}>{item}</Subtitle>
        ))}
      </Type>
      <Type>
        <Title>Why We use</Title>
        {subtitles.whyWeUse.map(item => (
          <Subtitle key={item}>{item}</Subtitle>
        ))}
      </Type>
    </Container>
  );
};
