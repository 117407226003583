export default {
  Language: 'Sprache',
  'Bets in current room': 'Wettraum',
  Timers: 'Timers',
  'Game start': 'Spielstart',
  'Game ending': 'Spielende',
  'Roulette ticking': 'Rouletterad',
  'Roulette start': 'Roulette-Start',
  'Item set': 'Item set',
  'My bet': 'Meine Wette',
  'Coins transfer': 'Münztransfer',
  'Start button': 'Start-Knopf',
  'Stop button': 'Stop-Knopf',
  Countdown: 'Countdown',
  Soundtrack: 'Soundtrack',
  Crash: 'Crash',
  'Picking item': 'Item auswählen',
  Crafting: 'Wird hergestellt',
  Failure: 'Fehler',
  Win: 'Gewinn',
  Sell: 'Sell',
  Take: 'Nehmen',
  'Rooms bets': 'Andere Wetträume',
  'Chat message sending': 'Chatnachricht senden',
  'Chat message receiving': 'Chatnachricht empfangen',
  Sound: 'Ton',
  'Chat sound': 'Ton beim Chat',
  'Game sounds': 'Ton beim Spiel',
  Volume: 'Lautstärke',
  steamApi: {
    noKey: 'KEIN STEAM API SCHLÜSSEL',
    need: 'Erforderlich zum Verkauf und Handel von Skins mit anderen Spielern',
    addLink: 'LINK HINZUFÜGEN',
    link: 'WOHER BEKOMMEN?',
  },
};
