import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100vw;
  justify-content: center;
  padding: 0 1.25rem;
  overflow: hidden;
  position: relative;

  & svg {
    position: absolute;
  }
`;

export const ChartLabels = styled.div`
  bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1.5rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  & > span {
    align-self: self-start;
    color: var(--color-gray-200);
    font-size: 0.7rem;
    font-weight: 500;
    justify-self: center;
    letter-spacing: 1.11px;
    position: relative;

    &::before,
    &::after {
      border-radius: 50%;
      content: '';
      display: block;
      height: 12px;
      left: 50%;
      margin-left: -10px;
      top: -1.7rem;
      width: 12px;
      position: absolute;
    }

    &::before {
      border: 2px solid var(--color-pve);
    }

    &::after {
      background: var(--color-pve);
    }

    &:first-of-type {
      &::before {
        border-color: var(--color-pvp);
      }

      &::after {
        background: var(--color-pvp);
      }
    }

    &:last-of-type {
      &::before {
        border-color: var(--color-solo-pve);
      }

      &::after {
        background: var(--color-solo-pve);
      }
    }
  }
`;

export const Profit = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  width: 45%;

  & > * {
    padding: 0.2rem 0;
  }

  & .profit-title {
    color: var(--color-gray-100);
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.86px;
    line-height: 1.33;
    max-width: 90%;
    text-transform: uppercase;
  }

  & .profit-total {
    color: var(--color-white);
    font-size: 1.8rem;
    font-stretch: condensed;
    letter-spacing: 0.61px;
    text-align: center;
  }

  & .profit-description {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
    justify-content: center;
    max-width: 150px;

    & span {
      display: block;
      font-size: 0.7rem;
      font-weight: bold;
      letter-spacing: 0.86px;
      line-height: 1.33;
    }
  }
`;
