export class FastInventoryFiltersConfig {
  static get sorts(): Map<string, string> {
    return new Map<string, string>([
      ['price', 'ASC_PRICE'],
      ['-price', 'DESC_PRICE'],
      ['name', 'ASC_NAME'],
      ['-name', 'DESC_NAME'],
    ]);
  }

  static sortBy(key: string): string {
    return FastInventoryFiltersConfig.sorts.get(key);
  }
}
