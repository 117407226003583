import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 56px 0;
  gap: 2px;
`;

export const Icon = styled.img`
  width: 120px;
  height: 90px;
  margin: 30px;
`;

export const SkinSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-white);
`;

export const NameSpan = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.76px;
  text-align: center;
  color: var(--color-white);
`;
