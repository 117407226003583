import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { StorePage } from '../Pages';
import { Balance } from '../Balance';
import { SteamAppId } from '../../interfaces/reducer.leaders.interfaces';

export const StoreStack: FC<RouteComponentProps> = ({ match }) => (
  <>
    <AppBar deep prependWidget={Balance} />
    {/*<NavBar tabs={['', '/dota2']} />*/}
    <div className="content">
      <Switch>
        <Route path={match.url} render={() => <StorePage appId={SteamAppId.CSGO} />} />
      </Switch>
    </div>
  </>
);
