import { useCallback, useEffect } from 'react';
import { useUserEmail } from './useUserEmail.hook';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchChangeEmail, fetchUpdateUserEmail } from '../ducks/duck';
import { addChatraEmail } from '../user.utils';

type IMailBoxForm = {
  email: string;
  isSubscribed: boolean;
  status: string;
};

export const useUserMailboxForm = (): any => {
  const dispatch = useDispatch();
  const { email, handleChangeEmail, handleChangeSubscribe } = useUserEmail();

  const { reset, ...form } = useForm<IMailBoxForm>({
    mode: 'onChange',
    defaultValues: {
      email: email.address ?? '',
      isSubscribed: email.isSubscribed ?? true,
      status: email.status,
    },
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });

  useEffect(() => {
    reset({
      email: email.address ?? '',
      isSubscribed: email.isSubscribed ?? true,
      status: email.status,
    });
  }, [email, reset]);

  const onValidate = useCallback(value => value !== email.address, [email.address]);

  const onSubmit = useCallback(
    ({ email, isSubscribed }: IMailBoxForm) => {
      addChatraEmail(email);
      return handleChangeEmail(email, isSubscribed);
    },
    [handleChangeEmail]
  );
  const onReset = useCallback(() => {
    addChatraEmail();
    return reset({ email: '', isSubscribed: true, status: 'empty' });
  }, [reset]);

  const onUpdate = useCallback(() => dispatch(fetchUpdateUserEmail(email)), [dispatch, email]);

  const onRefresh = useCallback(() => {
    dispatch(fetchChangeEmail('special-crutch@change-my-mail.pls', false));
    reset({ email: email.address, isSubscribed: true, status: 'unsubscribe' });
    addChatraEmail(email.address);
  }, [dispatch, reset, email.address]);

  const onUpdateSubscribe = useCallback(
    (isSubscribed: boolean) => handleChangeSubscribe(isSubscribed),
    [handleChangeSubscribe]
  );

  return {
    form,
    onSubmit,
    onReset,
    onUpdate,
    onValidate,
    onRefresh,
    onUpdateSubscribe,
  };
};
