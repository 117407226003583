export const generateId = (length: number): string => {
  const crypto = window.crypto;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let id = '';
  const randomValues = new Uint32Array(length);

  if (crypto && crypto.getRandomValues) {
    crypto.getRandomValues(randomValues);
  } else {
    // Fallback to Math.random() if crypto.getRandomValues() is not available
    for (let i = 0; i < length; i++) {
      randomValues[i] = Math.floor(Math.random() * 4294967296); // 2^32
    }
  }

  for (let i = 0; i < length; i++) {
    id += characters.charAt(randomValues[i] % charactersLength);
  }

  return id;
};
