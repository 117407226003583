import { Record } from 'immutable';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import {
  ActionResponseGamesId,
  CaseBattleType,
  CaseBattleTypeAction,
  ChosenCaseData,
  ChosenCases,
  CreateBattleActions,
  CreateBattleGame,
  CreateBattleReducer,
  ResponseCreateGame,
  SetDefaultTotalPrice,
  SetTotalPrice,
} from './create-battle.interfaces';
import { Reducer } from 'redux';
import { generateId } from 'games/CaseGame/utils';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { RequestBody } from 'games/CaseBattle/interfaces/case-battle.interfaces';
import { catchError, map, switchMap } from 'rxjs/operators';
import caseBattleRepository from 'games/CaseBattle/repository/caseBattleRepository';
import { of } from 'rxjs';
import { addNotification } from 'modules/Notifications/duck';

export const addCase = (id: number, price: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ADD_CASE,
  payload: {
    price,
    id,
  },
});

export const removeCase = (uniqId: string) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_CASE,
  payload: uniqId,
});

export const removeAllCases = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ALL,
});

export const addRandomCases = (ids: ChosenCaseData[], price: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ADD_RANDOM_CASES,
  payload: { ids, price },
});

export const setTotalPrice = (price: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.SET_TOTAL_PRICE,
  payload: price,
});

export const setDefaultTotalPrice = () => ({
  type: CASE_BATTLE_ACTION_TYPE.SET_DEFAULT_TOTAL_PRICE,
});

export const caseBattleTypeAction = (type: CaseBattleType) => ({
  type: CASE_BATTLE_ACTION_TYPE.SET_CASE_BATTLE_TYPE,
  payload: type,
});

export const actionCreateGame = (requestBody: RequestBody) => ({
  type: CASE_BATTLE_ACTION_TYPE.CREATE_BATTLE_GAME,
  payload: requestBody,
});

export const createGameEpic: Epic = (action$: ActionsObservable<CreateBattleGame>) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.CREATE_BATTLE_GAME),
    switchMap(({ payload }) =>
      caseBattleRepository.createGame(payload).pipe(
        map(({ response }: { response: ResponseCreateGame }) =>
          actionResponseGamesId(response.battleId)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something was wrong. Please reload page.',
            })
          )
        )
      )
    )
  );

export const actionResponseGamesId = (battleId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.SET_GAME_ID,
  payload: battleId,
});

const InitState = Record<CreateBattleReducer>({
  casesForBattle: [],
  caseAmount: 0,
  chosenCases: {},
  price: 0,
});

export const createBattleReducer: Reducer<Record<CreateBattleReducer>, CreateBattleActions> = (
  state = new InitState(),
  action
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_ADD_CASE: {
      if (state.get('caseAmount') === 10) {
        return state;
      }

      if (state.hasIn(['chosenCases', action.payload.id])) {
        return state
          .mergeIn(
            ['casesForBattle'],
            [{ uniqId: generateId(10), id: action.payload.id, casePrice: action.payload.price }]
          )
          .update('caseAmount', value => value + 1)
          .update('price', value => value + action.payload.price)
          .updateIn(['chosenCases', action.payload.id], (value: number) => value + 1);
      } else {
        return state
          .mergeIn(
            ['casesForBattle'],
            [{ uniqId: generateId(10), id: action.payload.id, casePrice: action.payload.price }]
          )
          .update('caseAmount', value => value + 1)
          .update('price', value => value + action.payload.price)
          .mergeDeepIn(['chosenCases'], { [action.payload.id]: 1 });
      }
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ALL: {
      return state.clear();
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_CASE: {
      const currArray = state
        .get('casesForBattle')
        .filter(({ uniqId }) => uniqId !== action.payload);
      const item = state.get('casesForBattle').find(({ uniqId }) => uniqId === action.payload);
      return state
        .set('casesForBattle', currArray)
        .set('caseAmount', item ? state.get('caseAmount') - 1 : state.get('caseAmount'))
        .update('price', value => value - item?.casePrice)
        .updateIn(['chosenCases', item.id], value => value - 1);
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_ADD_RANDOM_CASES: {
      const obj: ChosenCases = {};

      action.payload.ids.forEach(({ id }) => {
        if (obj[id]) {
          obj[id] += 1;
        } else {
          obj[id] = 1;
        }
      });

      return state
        .set('casesForBattle', action.payload.ids)
        .set('caseAmount', action.payload.ids.length)
        .set('price', action.payload.price)
        .set('chosenCases', obj);
    }
    default: {
      return state;
    }
  }
};

export const totalPriceReducer: Reducer<number, SetTotalPrice | SetDefaultTotalPrice> = (
  state = 1,
  action
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.SET_TOTAL_PRICE: {
      return action.payload;
    }
    case CASE_BATTLE_ACTION_TYPE.SET_DEFAULT_TOTAL_PRICE: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

export const caseBattleTypeReducer = (
  state: CaseBattleType = '2',
  action: CaseBattleTypeAction
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.SET_CASE_BATTLE_TYPE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const currentGameIdReducer = (state: number = 0, action: ActionResponseGamesId) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.SET_GAME_ID: {
      return action.payload ?? 0;
    }
    default: {
      return state;
    }
  }
};
