import * as React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { CurrencyInput, CurrencyText } from '../../../../@components/currency';
import { SelectPanel } from '../../../../@components/Selects';

import { getCoefficients as getCrashCoefficients } from '../../crash-game.utils';

class ControlsRate extends React.PureComponent {
  render() {
    const { amount, coefficient, handleAmount, handleCoefficient, settings, t } = this.props;

    const maxCoef = 50;
    const coefLent = getCrashCoefficients(maxCoef);

    return (
      <Container>
        <ControlLine>
          {t('Bet')} <ControlLineSpan>{t('min')}</ControlLineSpan>{' '}
          <CurrencyText value={settings.min_bet} />
          <ControlLineSpan>{t('max')}</ControlLineSpan> <CurrencyText value={settings.max_bet} />
        </ControlLine>
        <CurrencyInput
          value={amount}
          maxValue={settings.max_bet / 100}
          minValue={settings.min_bet / 100}
          setValue={handleAmount}
        />

        <ControlLine>
          AUTOSTOP <ControlLineSpan> {t('(0 = Disabled)')}</ControlLineSpan>
        </ControlLine>

        <SelectPanel
          lent={coefLent}
          value={coefficient}
          maxValue={maxCoef}
          setValue={handleCoefficient}
          additions
          type="coefficient"
          panel="scrollbar"
        />
        <WaitControl>
          <WaitInfo>
            <WaitSum>
              <CurrencyText value={amount * 100} />
            </WaitSum>
            <WaitStatus>{coefficient === 0 ? 'OFF' : `x${coefficient}`}</WaitStatus>
          </WaitInfo>
        </WaitControl>
      </Container>
    );
  }
}

export default withTranslation()(ControlsRate);

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 20px;
  position: relative;
  top: -40px;
  background: var(--color-dark-500);

  &:before {
    content: '';
    display: block;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    background-image: linear-gradient(to bottom, rgba(26, 38, 42, 0.71), var(--color-dark-500));
  }
`;

const ControlLine = styled.div`
  text-align: center;
  padding: 12px 0 4px;
  color: var(--color-white);
  font-size: 15px;
`;

const ControlLineSpan = styled.span`
  color: #999999;
`;

const WaitControl = styled.div`
  display: flex;
  height: 47px;

  justify-content: flex-start;
  padding: 3px;
  border-radius: 5px;
  background-color: var(--dark);
  margin-top: 19px;
`;

const WaitInfo = styled.div`
  padding: 3px 5px 2px;
`;

const WaitSum = styled.div`
  font-size: 14px;
  color: var(--color-white);
  margin-bottom: 5px;
`;

const WaitStatus = styled.div`
  font-size: 18px;
  color: #12b0d1;
  font-weight: bold;
  line-height: 1.2;
`;
