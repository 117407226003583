import React from 'react';
import { PayGiftCodes } from './pay-gift-codes';
import { PayGiftChipList } from './pay-gift-chip-list';
import { PayGiftAggregators } from './pay-gift-aggregators';
import { useGatePay, useMethodPay } from '../../hooks';
// import { G2aGiftCardsList } from './g2a-giftcards-list';
import { AppBar } from '@components/bars';

export const GiftCardsPage = () => {
  const { method } = useMethodPay();
  const { gate } = useGatePay();
  const gates = method?.gates || [];

  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <PayGiftChipList gate={gate} gates={gates} method={method} />
        {/* <G2aGiftCardsList /> HIDE FROM FAST_MOB-1227 */}
        <PayGiftCodes gate={gate} gates={gates} method={method} />
        <PayGiftAggregators />
      </div>
    </>
  );
};
