import React from 'react';
import {
  Container,
  Header,
  IconContainer,
  OpenLaterButton,
  StyledButtonOpen,
} from './free-case.styles';
import { useTranslation } from 'react-i18next';
import { ReferralIcons } from '../assets';
import { FreeCaseReward } from 'core/User/interfaces';
import { IDialogComponent } from 'core/interfaces';
import { useHistory } from 'react-router-dom';

interface FreeCaseDialogProps extends IDialogComponent<FreeCaseReward> {}

export const FreeCaseDialog: React.FC<FreeCaseDialogProps> = ({ handleCloseModal, data }) => {
  const { t } = useTranslation('referralInfo');
  const history = useHistory();

  const handlerClick = () => {
    handleCloseModal();
    history.push(`../cases/${data.rewardedCases[0].caseId}`);
  };

  return (
    <Container>
      <Header>{t('freeCase')}</Header>
      <IconContainer>
        <ReferralIcons.Case width={234} height={170} />
      </IconContainer>
      <StyledButtonOpen onClick={handlerClick}>{t('openCase')}</StyledButtonOpen>
      <OpenLaterButton onClick={handleCloseModal}>{t('notNow')}</OpenLaterButton>
    </Container>
  );
};
