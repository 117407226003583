import React, { FC } from 'react';
import styled from 'styled-components';
import { DefaultContainer } from './combo-line.styles';
import { IComboLineComponent } from './combo-line.interface';

export const SixToSeven: FC<IComboLineComponent> = ({ className, children }) => (
  <Container className={className}>
    <div className="left-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="right-indicator" />
    {children}
  </Container>
);

const Container = styled(DefaultContainer)`
  &.line-6 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 1;
    }

    & .line-indicator {
      &:nth-child(3) {
        transform: rotate(45deg) translate(-1px, -1px);
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 142%;
        grid-row: 1 / 9;
        justify-self: center;
        border-radius: 0.125rem;
      }
      &:nth-child(4) {
        transform: rotate(-45deg) translate(1px, -1px);
      }
    }
  }

  &.line-7 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 9;
    }

    & .line-indicator {
      &:nth-child(3) {
        transform: rotate(-45deg) translate(-1px, 1px);
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 142%;
        grid-row: 2 / 10;
        justify-self: center;
        border-radius: 0.125rem;
      }
      &:nth-child(4) {
        transform: rotate(45deg) translate(1px, 1px);
      }
    }
  }

  & .line-indicator {
    &:nth-child(3) {
      grid-column: 3 / 6;
    }

    &:nth-child(4) {
      grid-column: 6 / 9;
    }

    &:nth-child(5) {
      grid-column: 9 / 10;
    }
  }
`;
