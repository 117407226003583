export default {
  'Fair game': 'Joc cinstit',
  Bet: 'Pariu',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = dezactivat)',
  'Bet coins': 'Pariați monede',
  Stop: 'Oprire',
  'Wait for next round...': 'Așteptați runda următoare…',
  Example: 'Exemplu',
  '!! Crash !!': '!! Eroare !!',
  'You won': 'Ați câștigat',
};
