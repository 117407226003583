import React from 'react';
import { IconContainer, Container, Title, Span } from './EmptyList.styles';
import { useTranslation } from 'react-i18next';
import { SvgIcons } from 'games/CaseGame/assets';

export const EmptyList = () => {
  const { t } = useTranslation('cases2.0');
  return (
    <Container>
      <IconContainer>
        <SvgIcons.EmptyIcon />
      </IconContainer>
      <Title>{t('notFound')}</Title>
      <Span> {t('searchTerms')} </Span>
    </Container>
  );
};
