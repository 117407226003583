import { ILotteryEntity } from './lottery.reducers.interface';

export enum LotteryActionTypes {
  LOTTERY_ATTACH_LISTENERS = 'app/lottery/LOTTERY_ATTACH_LISTENERS',
  LOTTERY_DETACH_LISTENERS = 'app/lottery/LOTTERY_DETACH_LISTENERS',
  RESUBSCRIBE_LISTENERS = 'app/lottery/RESUBSCRIBE_LISTENERS',

  UPDATE_LOTTERY_ELEMENTS = 'app/lottery/UPDATE_LOTTERY_ELEMENTS',
  UPDATE_LOTTERY_ELEMENT = 'app/lottery/UPDATE_LOTTERY_ELEMENT',

  PARTICIPATE_LOTTERY = 'app/lottery/PARTICIPATE_LOTTERY',
}

export interface IUpdateLotteryElements {
  type: typeof LotteryActionTypes.UPDATE_LOTTERY_ELEMENTS;
  payload: {
    items: ILotteryEntity[];
  };
}

export interface IUpdateLotteryElement {
  type: typeof LotteryActionTypes.UPDATE_LOTTERY_ELEMENT;
  payload: {
    id: number;
    item: ILotteryEntity;
  };
}

export type lotteryActions = IUpdateLotteryElements | IUpdateLotteryElement;
