/* eslint-disable no-template-curly-in-string */
export default {
  'WHAT IS CSGOFAST.COM?': 'ЧТО ЖЕ ТАКОЕ CSGOFAST.COM?',

  'The Project CSGOFAST.COM is a Service for Counter-Strike: Global Offensive players. Right here everyone can try his luck and get awesome skins.':
    'Проект CSGOFAST.COM – интернет сервис для поклонников игры Counter-Strike: Global Offensive, в котором каждый желающий может испытать удачу и получить крутые скины.',

  'General rules and questions': 'Общие правила и вопросы по игре',
  // links
  'What games are there in the project?': 'Какие игры есть на проекте?',
  'How to take part in the game?': 'Как принять участие в игре?',
  'How to place a bet?': 'Как сделать ставку?',
  'What is acceptable as a bet?': 'Что принимается в виде ставки?',
  'What are the max and min bets?': 'Какая минимальная и максимальная ставки?',
  'How many skins can be accepted per one bet?': 'Сколько скинов принимается в одной ставке?',
  'How many bets can be placed per one round?': 'Сколько ставок можно делать в одном раунде?',
  'What max number of skins is accepted per one round?':
    'Какое максимальное количество скинов в раунде?',
  'How long is one round?': 'Какая длительность раунда?',
  'How are skins appraised?': 'Как формируются цены на скины?',
  'What is the Ticket System?': 'Что такое система билетов?',
  'What is Fair Game?': 'Что такое честная игра?',
  'How is the winner determined?': 'Как определяется победитель раунда?',
  'What does the winner get at the end of a round?': 'Что получает победитель раунда?',
  'What Service Commission is there?': 'Какая комиссия сервиса?',
  'How to increase the chances of winning?': 'Как повысить шанс на победу?',
  'When is the winner determined?': 'Когда будет выбран победитель игры?',
  'How to get the winning?': 'Как получить выигрыш?',
  'Can I accept a Trade Offer later?': 'Могу ли я принять выигрыш позже?',
  'How to get a bonus to my winning?': 'Как получить бонус к выигрышу?',
  'What is Referral?': 'Что такое партнерка?',
  'I`ve got an issue Where can I apply for help?': 'У меня проблема. Куда обращаться?',
  'How does tech support work? How long should I wait for reply?':
    'Как работает служба поддержки? Как долго ждать ответ?',

  'Bet questions': 'Вопросы по ставкам',
  // links
  'Why does a bot cancel my bet?': 'Почему бот отклоняет мою ставку?',
  'What is Escrow?': 'Что такое Escrow?',
  'My skins went to another game': 'Мои скины попали в другую игру',
  'My skins did not go to any game': 'Мои скины не попали ни в одну из игр',
  "I've canceled a trade, but a bet went in": 'Я отменил обмен, но ставка всё равно вошла',
  'I placed a bet accidently, how to get my skins back?':
    'Я сделал ставку случайно, как вернуть скины?',

  'Getting your winning': 'Получение выигрыша',
  //links
  'When does the winning come?': 'В какие сроки приходит выигрыш?',
  'I did not get the winning according to Terms of Service':
    'Я не получил выигрыш в указанные сервисом сроки',
  'The winning has come, but some skins are not available for a Trade Offer':
    'Мне пришел выигрыш, но некоторые скины недоступны для обмена',
  'I canceled a Trade Offer with my winning accidently': 'Я случайно отменил обмен с выигрышем',
  'How to get my winning if I didn`t get it for some reason or other?':
    'Как получить выигрыш, если я не получил его по какой-либо причине',
  "I've got a trade ban I want my winning to be sent to my secondary account or friend`s account":
    'Я получил трейд-бан, хочу выигрыш на свой второй аккаунт или на аккаунт друга',

  'Referral program': 'Партнерская программа',
  //links
  'What is it?': 'Что это такое?',
  'Who is recognized as my referral?': 'Кто считается моим рефералом?',
  'How many coins can it be received?': 'Сколько начисляется Монет?',
  'How to determine the percent and what is a "Level" in Referral Program?':
    'Как определяется процент и что такое "уровень" в партнёрской программе?',
  'What can I exchange my Coins for?': 'На что можно потратить Монеты?',
  'When will Coins for referrals be sent to me?':
    'Когда будут начислены мои Монеты за приглашенных рефералов?',
  'Where should my code be entered?': 'Куда должны вводить мой код?',
  'What bonuses does the referral get?': 'Какие бонусы получает приглашенный игрок?',

  'Service commission': 'Комиссия сервиса',
  //links
  'What is Service Commission?': 'Какая комиссия сервиса?',
  'My skins were taken as a commission How to get them back or exchange for others?':
    'В комиссию ушли мои скины. Как получить их назад или обменять на другие?',
  'Service has deducted a big Service Commission': 'Сервис взял большую комиссию',

  'Chat rules': 'Правила чата',

  // GENERAL

  // How to take part in the game?
  '1 Sign in through Steam': '1. Авторизируйтесь на сайте.',
  '2 Put an actual Trade Offer link into a Settings (You can get your actual link here)':
    '2. Укажите актуальную ссылку для обменов в разделе Настройки.',
  '3 Make your inventory public in the Steam Profile': '3. Откройте инвентарь в профиле Steam.',
  '4 Select the game, place a bet and try your luck!':
    '4. Выберите игру, сделайте ставку и испытайте удачу!',
  // How to place a bet?
  'For Classic Game and Fast Game: Select the game mode and click at Deposit more or Place a bet Select skins that you want to deposit as a bet from your inventory Click at Make Offer Bot accepts your bet and places it into the game':
    'Для Classic Game и Fast Game: Выберите режим игры и нажмите кнопку Вложить ещё или Сделать ставку. Выберите скины из своего инвентаря, которые хотите отправить в виде ставки. Нажмите кнопку Предложить обмен. Бот примет Вашу ставку и поместит её в игру.',
  'For Double Game: Enter a wishful number of Fast Coins as a bet and click at the color slot button, that will win in the round from your coin of view The bet of a chosen color will be placed automatically':
    'Для Double Game: Введите желаемое количество монет для ставки и нажмите на кнопку с цветом сектора, который, по Вашему мнению, победит в раунде. Ставка на выбранный цвет будет сделана автоматически.',
  // What is acceptable as a bet?
  'For Classic Game and Fast Game: Bot accepts skins from Counter-Strike: Global Offensive only Trade Offers with the items from other games or containing souvenir items (other than gift sets) are canceled automatically':
    'Для Classic Game и Fast Game: Бот принимает скины только из Counter-Strike: Global Offensive. Торговые предложения с предметами из других игр или содержащие сувенирные предметы (кроме сувенирных наборов), будут автоматически отклонены.',
  'For Double Game: In this game Fast Coins are acceptable as bets The ways to get Fast Coins are listed here':
    'Для Double Game: В этой игре в виде ставок принимаются монеты.',
  // What are the max and min bets?
  'A bet sum limit is indicated in the selected game Inappropriate bets are canceled automatically':
    'Ограничения для суммы ставки указаны в комнате с выбранным режимом игры. Ставки, которые не подходят под указанные параметры, будут отклонены.',
  // How many skins can be accepted per one bet?
  'For Classic Game and Fast Game: The acceptable number of skins per one bet is indicated in the selected game mode Inappropriate bets are canceled automatically':
    'Допустимое количество скинов в одной ставке для Classic Game и Fast Game указано в комнате с выбранным режимом игры. Ставки, которые не подходят под указанные параметры, будут автоматически отклонены.',
  // How many bets can be placed per one round?
  'The number of accepted bets per one round is different for every game mode. Have a look at more details about bet limitations per round for Classic Game, Fast Game and Double Game.':
    'Количество принимаемых ставок в одном раунде отличается для каждого режима игры. Ознакомьтесь с ограничениями по количеству ставок в раунде для Classic Game, Fast Game и Double Game.',
  // What max number of skins is accepted per one round?
  'The max number of skins (if it is prescribed) on the round deposit for Classic Game and Fast Game is indicated in the selected game mode':
    'Максимальное количество скинов на депозите раунда в Classic Game и Fast Game (если оно предусмотрено) указано в комнате с выбранным режимом игры.',
  // How long is one round?
  'The allotted time (if it is prescribed) of one round is indicated in the selected game':
    'Длительность раунда (если она предусмотрена) указана в комнате с выбранным режимом игры.',
  // How are skins appraised?
  'Skins are appraised regularly in accordance with Markets Prices and set up as an average sum for a certain period of time Site Skin prices can differ from prices of Steam Market':
    'Цены на скины регулярно обновляются в соответствии с ценами маркетов и устанавливаются как среднее значение за определенный интервал времени. Цены скинов на сайте могут отличаться от цен на торговой площадке Steam.',
  // What is the Ticket System?
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Когда участники делают ставки, они получают виртуальные билеты, один из которых станет победным. За каждый внесенный скин стоимостью $1 Вы получите 100 билетов. За скин, стоимостью $10, Вы получите 1000 билетов и так далее. При игре в рублях Вы получаете количество билетов эквивалентно установленному курсу.',
  'The higher and bigger your bets, the more tickets you get':
    'Чем больше и выше Ваши ставки, тем больше билетов вы получите.',
  'The more tickets you get, the greater chance to become a winner':
    'Чем больше билетов, тем больше шанс получить выигрышный и стать победителем!',
  // What is Fair Game?
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (eg 0282796734) and encrypts it through the sha224 algorithm The result is displayed at the beginning of a round':
    'В начале каждого раунда сервис генерирует случайное длинное число от 0 до 1 (например, 0.282796734) и шифрует его через алгоритм sha224. Результат шифрования виден в начале раунда.',
  'At the end of the round the Service multiplies the number by the general number of all tickets thus getting the number of a winner':
    'В конце раунда сервис умножает зашифрованное ранее число на общую сумму билетов, получая номер выигрышного билета.',
  'An example: At the end of a round the general deposit is 1000$ (there are 100000 tickets in the game), a generated number was 0.282796734 The Service multiplies the number 0282796734 by 100000 thus getting the number of a winner 28279 that is being displayed at the moment of a raffle':
    'Пример: В конце раунда общий депозит составил 1000$ (100000 билетов в игре), а заданным числом было 0.282796734. Сервис умножает число 0.282796734 на 100000 и получает число 28279. Это и будет номер выигрышного билета, который будет показан в момент розыгрыша.',
  "A Fair Game Principle: we can't know a tickets number that is displayed at the end of a round in advance and the multiplied random number is generated at the very beginning of a game That is why we have no opportunity to influence the results of a raffle":
    'Принцип работы честной игры в том, что мы не можем знать сколько билетов будет в конце раунда, а случайное число для умножения задается в самом начале игры. Именно поэтому у нас нет возможности влиять на результаты розыгрыша.',
  // How is the winner determined?
  'The ways of winner determination differ in every game mode Have a look at winner determination terms for Classic Game, Fast Game and Double Game':
    'Способы определения победителя для каждого режима игры отличаются. Ознакомьтесь с условиями определения победителей для Classic Game, Fast Game и Double Game.',
  // What does the winner get at the end of a round?
  'The Classic Game and Fast Game winner gets all deposited skins except a Service Commission Have a look at Service Commission Terms for Classic Game and Fast Game':
    'обедитель Classic Game и Fast Game забирает все внесенные в раунд скины за вычетом комиссии сервиса. Ознакомьтесь с условиями комиссии для Classic Game и Fast Game.',
  'In the Double Game if the winner is black or red slot, the gamer wins the placed bet multiplied by 2. The green slot returns the placed bet multiplied by 14.':
    'Если в Double Game выпадает черный или красный сектор, то ставки всех игроков возращаются им в 2х кратном размере. Зеленый сектор возвращает 14ти кратную сумму ставки.',
  // What Service Commission is there?
  'A Service Commission depends on a selected game and general round deposit Have a look at Service Commission Terms for Classic Game and Fast Game':
    'Комиссия зависит от выбранного режима игры и общего депозита раунда. Ознакомьтесь с условиями комиссии для Classic Game и Fast Game.',
  'There is no Commission in the Double Game':
    'Режим Double Game не предусматривает какие либо виды комиссии.',
  // How to increase the chances of winning?
  'The higher and bigger your bets in the Fast and Classic games, the greater chance to become a winner':
    'Чем больше и выше ставка в Classic Game и Fast Game, тем больше шанс стать победителем.',
  "A max bet doesn't ensure a win, but it increases a chance of winning vastly":
    'Максимальная ставка не гарантирует выигрыш, но значительно увеличивает шанс победить.',
  'In the Double Game everything depends on your luck and ability to guess the winner color slot of the round':
    'В режиме Double Game всё зависит от Вашей удачи и способности угадать победный сектор раунда.',
  'Play any game you like and try your luck!':
    'Примите участие в понравившейся игре и испытайте свою удачу!',
  // When is the winner determined?
  'Have a look at terms of round winner determination in the Classic Game, Fast Game, Double Game, Crash Game':
    'С данной информацией Вы можете ознакомиться в соответствующих разделах для Classic Game, Fast Game и Double Game.',
  // How to get the winning?
  "Trade Offers with the winner's prize are transferred by our bot in automatic mode":
    'Выдача выигрыша в Classic Game и Fast Game производится в автоматическом режиме нашими ботами.',
  'If you win you will see a pop-up with congratulations and the won sum':
    'В случае победы Вы увидите всплывающее окно с поздравлением и выигранной суммой.',
  'A Trade Offer is sent to the indicated Trade Offer link in your Settings within 5-30 minutes You should accept the Trade Offer':
    'В срок от 5 до 30 минут по указанной в настройках ссылке будет отправлено торговое предложение, которое необходимо принять.',
  'Sending of items can rarely take longer period of time because of Steam Lags or issues with Mobile Confirmation':
    'В редких случаях отправка выигрыша может занимать больше времени из-за задержек Steam или трудностей с мобильными подтверждениями.',
  'If you have got any issues with getting your prize, contact our 24/7 tech support:':
    'Если у Вас появились трудности с получением приза, обратитесь к разделу «Получение выигрыша» или в службу круглосуточной технической поддержки: ',
  // Can I accept a Trade Offer later?
  'It is necessary to accept a winning as soon as you get a Trade Offer from our bot':
    'Выигрыш необходимо принять сразу, как только Вы получите предложение обмена от нашего бота.',
  'A winning that had not been accepted for an hour is refused It is necessary to avoid bot inventory overloading in order to keep system working without malfunctions':
    'Выигрыш, который не был принят в течении часа, будет аннулирован. Это необходимо для того, чтобы инвентари ботов не забивались и работа сервиса не приостанавливалась.',
  // How to get a bonus to my winning?
  'Add to any part of your Steam Nickname an adscript CSGOFASTCOM and get 5% to your winning The adscript must be written in one word This bonus operates at CLASSIC GAME and FAST GAME only':
    'Добавьте в любое место Вашего ника приписку CSGOFAST.COM и получите 5% к выигрышу. Приписка должна быть указана слитно. Данный бонус действует только для Classic Game и Fast Game.',
  // What is Referral?
  'There is a Referral Program in our Project You get some Fast Coins for every invited player and his games Then you can exchange these coins for skins in the Store There is detailed information in the Referral':
    'На нашем проекте работает партнерская программа. За каждого привеченного игрока и его игры Вы будете получать монеты, которые можно обменять на скины в Магазине. Подробная информация находится в разделе Партнерская программа.',
  // I`ve got an issue. Where can I apply for help?
  "It's our pleasure to help you in case of any issues occurred to our Service In order to solve your issue write a letter to our tech support: ":
    'Мы всегда рады помочь в случае возникновения трудностей с нашим сервисом. Для этого, напишите нам письмо в службу поддержки: ',
  // How does tech support work? How long should I wait for reply?
  'Our tech support works 24/7 If you need help write about your issue to':
    'Наша служба поддержки работает круглосуточно. Если Вам нужна помощь – напишите нам о своей проблеме по адресу ',
  'As a rule, we reply within 60 min, but there might be longer period because of many requests or holidays':
    'Как правило, мы отвечаем в течении 60 минут, но возможны более длительные сроки из-за большой очереди обращений, выходных или праздничных дней.',

  // BET QUESTIONS

  // Why does a bot cancel my bet?
  'Make sure that:': 'Убедитесь в том, что:',
  'You send skins from Counter-Strike: Global Offensive, there are no souvenir items or skins from other games;':
    'Вы отправляете скины из Counter-Strike: Global Offensive, в обмене нет сувенирных предметов или скинов из других игр;',
  'Number of deposited skins meets the rules of a selected game;':
    'Количество скинов в ставке соответствует правилам выбранного режима игры;',
  'The value of a bet meets the rules of a selected game;':
    'Сумма ставки соответствует правилам выбранного режима игры;',
  'Your account is attached to your Steam Guard Mobile Authenticator minimum 7 days;':
    'Ваш аккаунт привязан к мобильному аутентификатору Steam Guard не менее 7 дней;',
  'While trading an offer you should not ask a bot for skins':
    'При обмене Вы не должны запрашивать скины у бота.',
  // What is Escrow?
  "- a new type of SteamGuard, that secures users accounts from unauthorized trades Starting on Dec 9, it's necessary to confirm all Trade Offers by Steam Guard Mobile Authenticator, which is attached to your account minimum 7 days Otherwise, Trade Offers are frozen for 12 hours to secure the users Our bot accepts the Trade Offers which are confirmed by Steam Guard Mobile Authenticator only":
    ' – новый вид Steam Guard, который защищает пользовательские аккаунты от несанкционированного доступа. Начиная с 09.12.15 все обмены в системе Steam необходимо подтверждать с помощью мобильного аутентификатора Steam, который привязан к аккаунту не менее 7 дней. В противном случае обмены «замораживаются» на 12 часов для безопасности пользователей. Наш бот принимает только те обмены, которые подтверждены с помощью мобильного аутентификатора Steam.',
  // My skins went to another game
  'The time period of placing a bet in a round depends on the load upon the Steam Servers and can range from 2 up to 180 seconds Items can rarely be added after a longer period of time because of extreme load upon Steam If a bet is placed close to the end of a current round, it can be placed to the next game We are not responsible for the situations which happen because of Steam Lags':
    'Время входа ставки в раунд зависит от нагрузки на серверы Steam и может варьироваться от 2 до 180 секунд. В редких случаях вещи могут добавляться дольше из-за экстремальной нагрузки на Steam. Если вы делаете ставку перед завершением текущего раунда, она может попасть в следующую игру. Мы не несем ответственность за подобные ситуации, которые случаются из-за задержек Steam.',
  // My skins did not go to any game
  '1 Make sure, you confirmed a bet by Steam Guard Mobile Authenticator':
    '1. Убедитесь, что Вы подтвердили ставку через мобильный аутентификатор Steam Guard.',
  '2 Check the status of a Trade Offer with our bot': '2. Проверьте статус обмена с нашим ботом.',
  '3 If a Trade Offer is accepted, but the bet is not placed to any game contact our tech support: support@csgofastcom':
    '3. Если обмен был принят, но ставка не участвовала ни в одной из игр, обратитесь в службу технической поддержки: support@csgofast.com с темой письма «Ставка не попала в игру».',
  // I’ve canceled a trade, but a bet went in
  'Our bots accept incoming trades with min lags, then they send skins to the game, thus it is almost impossible to cancel a deposited bet':
    'Наши боты принимают входящие обмены с минимальной задержкой, после чего отправляют скины в игру, потому отменить сделанную ставку не всегда возможно.',
  'If you cancel your bet and get a confirmation "Trade Offer Cancelled", but the skins play in, so it means your cancellation was made too late, and you got a confirmation "Trade Offer Cancelled", because of Steam Error We can\'t return the skins which have been already raffled':
    'Если Вы отменяли ставку и получили сообщение об успехе, но скины появились в раунде, то это означает, что отмена была произведена слишком поздно, а сообщение об успехе Вы получили из-за ошибки Steam. Мы не сможем вернуть скины, которые участвовали в игре и были разыграны.',
  // I placed a bet accidently, how to get my skins back?
  'We are working only with Steam Trade Offer System Every bet is confirmed by Steam Guard Mobile Authenticator Thus, the situations when your items play in the game without your confirmation or accidently are impossible We cannot return the deposited and raffled skins':
    'Мы работаем только с системой обменов Steam. Каждая ставка подтверждается с помощью мобильного аутентификатора Steam. Таким образом, ситуации, когда вещи попали в игру без Вашего подтверждения или случайно, исключены. Мы не сможем вернуть скины, которые участвовали в игре и были разыграны.',

  // GETTING YOUR WINNING

  // When does the winning come?
  'The winning is sent by our bot within 5-30 minutes automatically The sending rarely takes more time because of Steam Lags or issues with Mobile confirmations':
    'Выигрыш высылается автоматически одним из наших ботов в срок от 5 до 30 минут. В редких случаях отправка выигрыша может занимать больше времени из-за проблем в работе Steam или трудностей с мобильными подтверждениями.',
  // I did not get the winning according to Terms of Service.
  "If you won and didn't receive the winning within indicated allotted time, check the following:":
    'Если Вы не получили выигрыш в указанные сроки, проверьте следующее:',
  'You have entered a proper link in the Settings;':
    'Правильность указанной ссылки в разделе Настройки;',
  'You have enough space for new items in your inventory to accept the winning (less than 1000 items for Counter-Strike: Global Offensive);':
    'В инвентаре должно быть достаточно места для принятия выигрыша (менее 1000 скинов для Counter-Strike: Global Offensive);',
  'Your inventory is public': 'Ваш инвентарь должен быть открыт и публично доступен для просмотра.',
  'If there are some errors in above mentioned coins and you solve them, our bot will send you a Trade Offer repeatedly within 5-60 minutes (it depends on the load upon Steam Server)':
    'Если в указанных пунктах были ошибки, и Вы их устранили, то бот автоматически отправит обмен повторно в течении 5 - 60 минут в зависимости от нагрузки на сервера Steam.',
  // The winning has come, but some skins are not available for a Trade Offer
  'If you get a Trade Offer with unavailable skins, contact our tech support support@csgofastcom':
    'Если вы получили торговое предложение с недоступными к обмену скинами, обратитесь, в службу поддержки support@csgofast.com с темой письма «Не получил выигрыш».',
  // I canceled a Trade Offer with my winning accidently
  'If you cancel a Trade Offer, contact our tech support support@csgofastcom':
    'Если вы отменили торговое предложение, обратитесь в службу поддержки support@csgofast.com с темой письма «Не получил выигрыш».',
  // How to get my winning if I didn`t get it for some reason or other?
  "If you don't get your winning for some reason or other, contact our tech support support@csgofastcom After a prompt investigation we will send you the winning in a manual way In this case we reserve the right to send you the winning by random skins that are equal to the winning sum":
    'Если Вы не получили свой выигрыш по какой-либо из причин, то после обращения в службу поддержки support@csgofast.com и короткой проверки необходимых данных мы отправим выигрыш вручную. В данной ситуации мы оставляем за собой право выслать выигрыш случайными скинами эквивалентно выигранной сумме.',
  // I’ve got a trade ban. I want my winning to be sent to my secondary account or friend`s account.
  "We don't send the winnings to other accounts": 'Мы не высылаем выигрыш на другие аккаунты.',
  'For the avoidance of scam we send the winnings to the account that won only':
    'Во избежание случаев мошенничества мы отправляем выигрыш только на тот аккаунт, который победил в игре.',

  // REFERRAL PROGRAM

  // What is it?
  'Referral Program - an encouragement system of users, who invite new players (referrals) to our project You will get Coins for each referral and game he/she plays':
    'Партнерская (реферальная программа) – система поощрения пользователей, которые привлекают новых игроков (рефералов) на проект. За каждого привлеченного реферала и его игры Вы будете получать "Монеты".',
  // Who is recognized as my referral?
  'Referral is a new project user, who follows your referral link or enters your personal code If the invited user had been signed in previously, the Referral Program will not accept him':
    'Рефералом считается новый участник проекта, который пришел по Вашей партнерской ссылке или ввел Ваш персональный код. Если привлеченный игрок ранее авторизировался на проекте – реферальная программа его не засчитает.',
  // How many coins can it be received?
  'You get Coins for each referral, who is signed in following your referral link or entering your personal code The number of Coins depends on your level in Referral System Also, you get a certain percent of referral`s bets in any game of our project':
    'За каждого зарегистрировавшегося реферала по Вашей ссылке или Вашему коду вы получаете Монеты, количество которых зависит от Вашего уровня в реферальной системе. Также Вы получаете определённый процент от ставок реферала в любой из игр нашего проекта.',
  'Referral should have a public Steam account and Counter-Strike: Global Offensive game to get the bonus':
    'Для получения бонуса реферал должен иметь открытый аккаунт и игру Counter-Strike: Global Offensive',
  //'How to determine the percent and what is a "Level" in Referral Program?'
  'Percent, that you get of your referrals` bets, depends on your level in Referral System - the higher your level, the higher per cent you get':
    'Процент, получаемый от ставок Ваших рефералов зависит от Вашего уровня в реферальной системе - чем выше уровень, тем выше процент, который вы получаете.',
  'You can raise your level in Referral System increasing the number of your referrals - more referrals you have, the higher level you get':
    'Уровень в реферальной системе повышается с увеличением количества Ваших рефералов - чем больше у Вас рефералов, тем выше Ваш уровень!',
  // What can I exchange my Coins for?
  'Collected Coins can be exchanged for skins in the Store Also, they can be used in order to play in Double Game':
    'Накопленные Монеты можно обменять на скины в Магазине, а также для игр в Double.',
  // When will Coins for referrals be sent to me?
  'The Coins are sent to you after your referral has been signed in following your link or entering your referral code The page information is updated every 15 minutes':
    'Начисление Монет осуществляется после его регистрации по Вашей ссылке или после ввода Вашего реферального кода. Информация на странице обновляется раз в 15 минут.',
  // Where should my code be entered?
  'Code entering for new users is available on the page of Referral System':
    'Ввод кода для новых участников проекта доступен на странице реферальной системы',
  'Referral should enter the code within 15 minutes since the moment of the first authorization':
    'Рефералам нужно ввести код в течении 15 минут с момента первой авторизации.',
  // What bonuses does the referral get?
  'Everyone, who becomes a referral, gets the number of Coins, which depends on the Referral level of user who invites, to his/her account':
    'Каждый, кто становится рефералом, получает количество Монет, зависящее от реферального уровня приглашающего игрока, на свой счет.',

  'Forbidden to use:': 'Запрещено использовать:',
  'It is not allowed to use official CSGOFASTCOM sources: groups, social networking pages, forums, etc':
    '1. Запрещено использовать официальные источники CSGOFAST.COM: группы, страницы в социальных сетях, форумы и т.д.;',
  'Use invalid/unacceptable sources: PPC and SEO advertising for the CSGOFASTCOM brand':
    '2. Использовать недопустимые источники: контекстную рекламу на бренд CSGOFAST.COM и SEO-оптимизацию на бренд CSGOFAST.COM;',
  'Use sources that violate legislation, including legislation on intellectual property':
    '3. Использовать источники нарушающие законодательство, в том числе законодательство об интеллектуальной собственности;',
  'Complete the achievement of the goals of registration by forcing other users as a result of a request, compensation, deception or misrepresentation':
    '4. Призывать выполнять достижение целей по регистрации другими пользователями в результате просьбы, вознаграждения, обмана или ввода в заблуждение;',
  "Change the IP address within the same subnet or using a proxy server and anonymizers, hide or modify the 'referer'":
    '5. Изменять IP-адрес в пределах одной подсети или используя прокси-сервера и анонимайзеры, скрывать или изменять referer',
  "Use invalid/unacceptable sources: clichunder / popander including clichunder / popander via prelanding; Popup format; PPC advertising for the CSGOFAST.COM brand; Motivated traffic by BUX's(Active advertising system); Doorway traffic.":
    '6. Использовать недопустимые источники: clichunder/popander, в том числе clichunder/popander через прелендинг, popup формат, мотивированный трафик в т.ч с буксов, doorway-трафик;',
  'Abusing spam to attract users or other webmasters':
    '7. Использовать нежелательную рассылку (spam) для привлечения пользователей;',
  'Initiate the automatic actions of users by using scripts, bots, and any other means':
    '8. Инициировать автоматические действия посетителей при помощи скриптов, ботов и любых других средств;',
  'Use the toolbars and any extensions for browsers to force traffic':
    '9. Использовать для привлечения трафика тулбары и любые расширения для браузеров.',
  'Referral accounts of violators will be blocked':
    'Реферальные аккаунты нарушителей будут заблокированы.',

  // SERVICE COMMISSION

  // What is Service Commission?
  'Commission depends on a selected game mode and total deposit of a round Have a look at Service Commission for Classic Game and Fast Game There is no commission in the Double Game':
    'Комиссия зависит от выбранного режима игры и общего депозита раунда. Ознакомьтесь с условиями комиссии для Classic Game и Fast Game. Режим Double Game не предусматривает какие либо виды комиссии.',
  // My skins were taken as a commission. How to get them back or exchange for others
  'Random skins from a general deposit are taken as a Service Commission':
    'В качестве комиссии берутся случайные скины из депозита раунда.',
  'There is no opportunity to return you taken as a Service Commission skins or exchange them for others':
    'У нас нет возможности вернуть взятые в виде комиссии скины или обменять их на другие.',
  // Service has deducted a big Service Commission
  'If a Service Commission is bigger than it is indicated by our rules, contact our tech support: support@csgofastcom':
    'Если сервис удержал комиссию, которая больше заявленной данными правилами, обратитесь в службу поддержки: support@csgofast.com',

  // CHAT RULES

  'In the chat it is forbidden:': 'В чате запрещено:',
  '1 To offend, humiliate and threaten other participants of the project;':
    '1. Оскорблять, унижать и угрожать другим участникам проекта;',
  '2 To ask skins, to be engaged in begging;': '2. Просить скины, заниматься попрошайничеством;',
  "3 To place any links which don't belong to service work;":
    '3. Размещать любые ссылки, которые не относятся к работе сервиса;',
  '4 To place advertizing links, to advertize and mention third-party services and communities on Counter-Strike: Global Offensive;':
    '4. Размещать рекламные ссылки, рекламировать и упоминать сторонние сервисы и сообщества по Counter-Strike: Global Offensive;',
  '5 To send spam and flood;': '5. Спамить и флудить;',
  '6 To post referral links and codes;': '6. Постить реферальные ссылки и коды;',
  '7 To be fake service administrator or moderator;':
    '7. Выдавать себя за администратора или модератора сервиса;',
  '8 To imitate a nickname or an avatar of system messages;':
    '8. Имитировать ник или аватар системных сообщений;',
  '9 To offer purchase, sale of skins and game values bypassing service Store;':
    '9. Предлагать покупку, продажу скинов и игровых ценностей в обход магазина сервиса;',
  '10 To offer purchase, sale and boost accounts;':
    '10. Предлагать покупку, продажу и буст аккаунтов;',
  '11 To send to a chat phone numbers, messengers, e-wallets;':
    '11. Отправлять в чат номера телефонов, месенджеров, электронных кошельков;',
  '12 To arrange conflict situations in the other language chat;':
    '12. Устраивать конфликтные ситуации в другом языковом чате;',
  '13 To communicate on political or religious subjects;':
    '13. Общаться на политические или религиозные темы;',
  '14 To show signs of racism and nationalism;': '14. Проявлять признаки расизма и национализма;',
  '15 To propagandize drugs, alcohol, violence;':
    '15. Пропагандировать наркотики, алкоголь, насилие;',
  '16 To offend and threaten service administration.':
    '16. Оскорблять и угрожать администрации сервиса',

  'Violators will be blocked on all chat channels for the period from 10 minutes to a permanent block':
    'Нарушители будут заблокированы на всех каналах чата на срок от 10 минут до пожизненной блокировки.',
  'Chat Moderating is provided manually and automatically.':
    'Модерирование чата происходит в ручном и автоматическом режимах.',
  'Relocks duration increases on time automatically':
    'Срок действия повторных блокировок увеличивается по времени автоматически.',
  'The appeal of the received one week period Ban is carried out through tech support':
    'Обжалование полученного бана на срок от одной недели осуществляется через тех. поддержку.',
  'Moderators have the right to delete messages or ban the chat participant without explanation':
    'Модераторы имеют право удалять сообщения или забанить участника чата без объяснения причин.',

  // GAMES

  'How many bets can I place per one round?': 'Сколько ставок можно делать в раунде?',
  'What Service Commission is there in this game?': 'Какая комиссия в данном режиме игры?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'режим для тех, кто любит играть по-крупному! Желающие испытать удачу вносят ставки на общий депозит, который разыгрывается среди участников в конце раунда.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'В режиме Classic game победитель определяется с помощью системы билетов.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Победитель определится в конце времени раунда или при достижении максимального количества скинов на депозите.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'Комиссия сервиса составляет от 0-10%, в зависимости от величины выигрыша.\nВ качестве комиссии берутся случайные скины из всего банка раунда.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'отличный способ мгновенно испытать удачу. В раунде этого режима участвует всего 3 игрока с ограничением максимальной ставки. Победитель получит депозит троих игроков.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Победитель определяется случайным образом, но чем больше ставка, тем выше шанс победить.',
  'One player can place one bet per one game round in this game.':
    'В данном режиме от каждого участника принимается одна ставка для одного раунда игры.',
  'Where can I see my chances of the winning?': 'Как узнать шансы на победу в раунде?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Шансы на победу в раунде указаны в процентах и находятся рядом с суммой ставки участников раунда (справа от аватаров и списка вложенных в игру скинов).',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Победитель определится, когда трое игроков внесут ставки в текущий раунд игры.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'Комиссия составляет 0-15%, в зависимости от величины выигрыша.\nВ качестве комиссии берутся случайные скины из всего банка раунда.',

  // Double

  'in this mode you can get Fast Coins (service currency) and exchange them for skins in the Store Guess the winner color and return your bet multiplied by 2 or 14':
    'режим игры, в котором Вы можете заработать монеты (валюта сервиса) и обменять их на скины из Магазина. Угадайте победный цвет и верните свою ставку в 2х или 14ти кратном размере.',
  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'режим игры, в котором игроки делают ставки на победу черного, красного или зеленого сектора. Для ставок в данном режиме игры используются монеты. В конце раунда будет определен цвет победного сектора.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Если результатом розыгрыша будет красный или чёрный цвет сектора, то сумма ставки возвращается игроку в 2х кратном размере.',
  'The green slot returns the placed bet multiplied by 14.':
    'Зеленый сектор возвращает 14ти кратную сумму ставки.',
  'The bet sum of every slot can be different.': 'Сумма ставки на каждый сектор может быть разной.',

  'How can I Get Fast Coins?': 'Как получить монеты?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Участвуйте в партнерской программе. Приглашайте игроков (рефералов) на проект с помощью реферальной ссылки или кода. За каждого привлеченного реферала и его игры Вы будете получать монеты.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Обменяйте свои скины стоимостью от $1 на монеты. За каждый 1 цент стоимости скинов Вы получите 1 монету. Можно отправлять до 30 предметов за раз. Для оценки стоимости рекомендуем использовать Steam Inventory Helper.',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Пополните баланс с помощью платежной системы. Выберите удобный способ оплаты и получите необходимое количество монет на свой аккаунт.',

  'How is the winner slot determined (fair game)?':
    'Как определяется победный сектор (честная игра)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'В начале каждого раунда сервис генерирует случайное длинное число от 0 до 1 (например, 0.223088) и шифрует его через алгоритм sha224. Результат шифрования виден в начале раунда. В конце раунда сервис умножает зашифрованное ранее число на 15, получая номер победного сектора.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Пример: В начале раунда было зашифровано число 0.223088232334703230728. Сервис умножает число 0.223088232334703230728 на 15 и получает число 3,34632348495. Целым числом результата умножения получилось число 3 (красный сектор). В данном раунде победят игроки, которые делали ставки на победу красного сектора.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Вы можете самостоятельно проверить правильность определения победного сектора. Для этого в конце раунда возьмите число, которое было зашифровано и повторно закодируйте его с помощью любого из онлайн сервисов, например http://sha224.net. Вы увидите то же значение hash, что и в начале раунда. Это означает что результат игры не был подстроен.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Поскольку система определяет победный сектор ещё до начала игры и любой игрок может проконтролировать отсутствие его изменений - вмешательство в игру для нас теряет смысл. Поэтому эта система является гарантом честности наших игр.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Вы можете увеличивать свои ставки неограниченное количество раз до начала розыгрыша. Сумма ставки на каждый сектор может быть разной.',

  'What bet limitations are there?': 'Какие ограничения есть для ставок?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'Минимальная сумма ставки $0,1. На один цвет вы можете поставить не более $5000 единовременно.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Внимание! За один розыгрыш Вы не можете ставить на красное и чёрное одновременно. Ставки на зелёное доступны каждый раунд!',

  'When does the determination of the winner slot begin?':
    'Когда начнется выбор победного сектора?',
  'The winner slot is determined at the end of a round.':
    'Победный сектор определится в конце времени раунда.',

  'Is there any commission in this mode?': 'Есть ли комиссия в данном режиме игры?',
  'There is no commission at all. :)':
    'Данный режим игры не предусматривает какие либо виды комиссии.',

  // Crash

  'is a mode, where players have to press Stop button before Crash comes Crash comes randomly but as long as you are in game the coefficient rises as your winning':
    'это режим игры, в котором нужно успеть нажать кнопку Stop и выйти из игры до того как произойдет Crash и игра остановится. Crash происходит в случайный момент времени, но чем дольше ты продержишься в игре, тем выше множитель ставки и тем больше Монет ты выиграешь!',
  "Bet ${currency(amount)} before round will start and choose the strategy: manual or automatic with the coefficient you want. With the game beginning the coef starts to grow up till the CRASH comes. Those who press Stop button in time before the Crash win the game. Winners' winning rise depends of the coefficient on which you stop the game.":
    'Сделай ставку от ${currency(amount)} до начала раунда и выбери стратегию игры: ручной выход или автоматический выход при достижении установленного тобой множителя. С началом раунда множитель начнет расти пока не произойдет Crash. Выиграют те, кто вышел из игры с помощью кнопки Stop до момента Crash. Каждый победитель увеличит свою ставку на множитель в моменте выхода из игры.',
  'Using automatic strategy you can also press Stop button before the coefficient you put in the box.':
    'Во время игры с автоматическим выходом при достижении установленного множителя ты по-прежнему можешь использовать кнопку Stop для более раннего выхода из игры.',
  'At the beginning of each round, the service generates a random long random number from 0 to 1 (for example, 0.223088) and encrypts it through the sha224 algorithm. The result of the encryption can be seen in the early rounds. At the end of the round, the service uses this random number to calculate the coefficient using the following formula:':
    'В начале каждого раунда сервис генерирует случайное длинное число random от 0 до 1 (например, 0.223088) и шифрует его через алгоритм sha224. Результат шифрования виден в начале раунда. В конце раунда сервис использует это число random для расчета коэффициента по следующей формуле:',
  'Example: At the beginning of the round, the number 0.24412312303230728 was encrypted. The service uses the formula above to get the number 1.32.':
    'Пример: В начале раунда было зашифровано число 0.24412312303230728. Сервис, использую формулу выше, получает число 1.32.',
  'You can independently verify the correct definition of the victorious sector. To do this, at the end of the round, take a number that has been encrypted and re-encode it using any of the online services, for example http://sha224.net. You will see the same value of hash as in the beginning of the round. This means that the result of the game was not adjusted.':
    'Вы можете самостоятельно проверить правильность определения победного сектора. Для этого в конце раунда возьмите число, которое было зашифровано и повторно закодируйте его с помощью любого из онлайн сервисов, например http://sha224.net. Вы увидите то же значение hash, что и в начале раунда. Это означает что результат игры не был подстроен.',
  'Since the system determines the final coefficient even before the game starts and any player can control the absence of its changes - interference in the game makes no sense for us. Therefore, this system is the guarantor of the honesty of our games.':
    'Поскольку система определяет конечные коэффициент ещё до начала игры и любой игрок может проконтролировать отсутствие его изменений - вмешательство в игру для нас теряет смысл. Поэтому эта система является гарантом честности наших игр.',

  // Craft

  'How do I create an item?': 'Как мне создать вещь?',
  "What do I do with an item once it's created?": 'Что мне делать с созданной вещью?',
  'gives all users a great opportunity to obtain their desired items below their market value':
    'это отличная возможность для каждого пользователя заполучить желаемую вещь гораздо ниже её рыночной стоимости!',
  'It\'s very simple. Pick an item you like from the list, drag the slider to indicate how many money you are prepared to spend on the parts, and press "Create".':
    'Всё очень просто! Вам нужно выбрать понравившуюся вам вещь из списка, ползунком определить сколько вы готовы выделить денег на комплектующие и нажать “создать”.',
  'Keep in mind that the more expensive the part, the higher your chances to create an item.':
    'Помните, чем выше стоимость комплектующий, тем выше вероятность создания вещи!',
  'Once you have successfully created an item, you can either sell it to the shop straight away at its full value or add it to your inventory by pressing the "Claim" button. The bot will automatically send you a trade offer for your item.':
    'Успешно созданную вещь вы сразу можете продать в магазин за её полную цену, либо вы можете вывести её к себе в инвентарь нажав кнопку “забрать”. Бот автоматически вышлет вам трейд-оффер с этой вещью.',
  'If you claimed an item and the bot sent you an exchange offer, you must confirm the exchange within an hour, otherwise the item will be automatically sold and the money for it will be credited to your account':
    'Если вы забрали вещь и бот отправил вам предложение обмена, то вам следует в течении часа подтвердить обмен, иначе вещь будет автоматически продана и вам на аккаунт начислятся за неё деньги',

  // Wheel

  'is a mode of game where you can spin the wheel of fortune and get chance to win great prizes!':
    'это комната, где, прокрутив колесо фортуны, вы можете выиграть отличные призы!',
  'How to play Wheel of Fortune?': 'Как играть в Wheel of Fortune?',
  'After selecting this mode, you need to click the "Spin" button. After that the wheel starts to spin and after stopping it you will see your prize.':
    'Выбрав этом режим, Вам нужно нажать кнопку "Крутить". После этого колесо начнет вращаться и, после его остановки, Вы увидете вещь, которую выиграли.',
  'All winnings will come directly to your balance.':
    'Все выигрыши зачисляются напрямую на Ваш баланс.',

  // Tower

  'is a classic mode to climb to the top of the tower and collect a coin chest guessing the winning sectors.':
    'это классический режим, в котором вы можете взобраться на вершину башни и забрать сундук с монетами угадывая выигрышные сектора.',

  'How to play Tower Game?': 'Как играть в Tower Game?',
  'Choose the complexity you need:': 'Выберите нужную вам сложность из:',
  Easy: 'Лёгкая',
  'in this mode, the tower has three columns with two winning sectors in each line. In light mode you have the highest chance to get to the top!':
    'в этом режиме башня имеет три столбца с двумя выигрышными секторами в каждой линии. В лёгком режиме вы имеете самый высокий шанс добраться до вершины!',
  Medium: 'Средняя',
  'in the middle mode two columns with one losing sector in the line. Chances for each line are 50/50.':
    'в среднем режиме два столбца с одним проигрышным сектором в линии. Шансы для каждой линии 50/50.',
  Hard: 'Тяжёлая',
  'there are also three columns, but unlike previous modes in each line only one winning sector. This mode is for real luckies! With increasing complexity, the reward for passing the tower also increases!':
    'тут так же три столбца, но в отличие от лёгкого режима в каждой линии только один победный сектор. Это режим для настоящих везунчиков! С повышением сложности, также повышается награда за прохождение башни!',
  "Having chosen the appropriate complexity, you only need to determine the bet and try your luck by opening the winning sectors in each line from the bottom of the tower. The amount of winnings for each line depends on the size of the bet. The higher the bet, the higher the win, it's easy! You can stop at any step in the tower and withdraw the winnings.":
    'Выбрав подходящую сложность вам нужно только определить ставку в раунде и попробовать свою удачу открывая выигрышные секторы в каждой линии начиная с низа башни. От размера ставки зависит сумма выигрыша по каждой линии. Чем выше ставка, тем выше выигрыш, всё просто! Вы можете остановиться на любом шаге в башне и забрать выигрыш.',

  'Fair play': 'Честная игра',
  'You can independently verify the correct definition of the lucky sector.':
    'Вы можете самостоятельно проверить правильность определения победного сектора.',
  'To do this, at the end of the round, take the number that was encrypted, add to it the hash salt (without a space between the number and the salt) and re-encode it using any of the online services, for example http://sha224.net.':
    'Для этого в конце раунда возьмите число, которое было зашифровано, допишите к нему Соль хэша (без пробела между числом и солью) и повторно закодируйте его с помощью любого из онлайн сервисов, например http://sha224.net.',
  "You will see the same value of 'hash' in the beginning of the round. This means that the result of the game was not adjusted.":
    'Вы увидите то же значение hash, что и в начале раунда. Это означает что результат игры не был подстроен.',
};
