import styled from 'styled-components';

interface IPremiumContentStyle {
  premiumType: string;
}

export const Container = styled.div<IPremiumContentStyle>`
  background: var(--color-gray-700);
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.8rem;
  grid-template-rows: 35px;
  padding: 1.2rem 1rem;

  & .premium-heading {
    color: var(--color-white);
    font-size: 1.2rem;
    letter-spacing: 0.54px;

    & span {
      color: ${p => `var(--color-account-type-${p.premiumType})`};
      position: relative;

      &::before {
        background: var(--color-white);
        bottom: -5px;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
      }
    }
  }

  & > div:not(.premium-heading) {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    font-size: 0.9rem;
    justify-content: space-between;
    line-height: 1.4;

    & > span {
      color: ${p => `var(--color-account-type-${p.premiumType})`};
      font-size: 0.9rem;
      font-weight: bold;
      letter-spacing: 0.07px;
      text-transform: uppercase;

      & .icon-checked-fill {
        font-size: 0.7rem;
      }

      & .icon-lock {
        font-size: 1rem;
      }
    }
  }

  & .premium-advice {
    color: var(--color-white);
    font-size: 0.85rem;
    letter-spacing: 0.1px;
    line-height: 1.47;
    padding-top: 0.5rem;
    text-align: center;
  }
`;
