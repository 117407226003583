export default {
  '100$ for the new players!': '$ 100 para os novos jogadores!',
  '100$ bonus is credited to a demo balance':
    'O bônus de $ 100 é creditado em um saldo de demonstração',
  'Get 100$ now!': 'Ganhe 100 $ agora!',

  'Top up your balance from 25$ and get bonuses': 'Aumente seu saldo de $ 25 e ganhe bônus',
  'Exp bonus': 'Bônus de exp',
  'Wheel Of Fortune free spin': 'Roda da fortuna grátis',
  'Free case': 'Caso grátis',
  'Access to account level-up': 'Acesso para aumentar o nível da conta',
};
