import React from 'react';
import { CurrencyText } from '@components/currency';
import {
  CaseIcon,
  CaseIconFree,
  CasePrice,
  Container,
  GrayContainer,
  PriceContainer,
} from './CaseListItemDefault.styles';
import { getSteamItemImageSrc } from 'core/utils';
import { CaseEnum } from 'games/CaseGame/constants';
import { GameStatus } from 'games/CaseBattle/constants';

interface CaseListItemDefaultProps {
  image: string;
  free: boolean;
  caseType: CaseEnum;
  handler?: () => void;
  price?: number;
  style?: React.CSSProperties;
  className?: string;
  enabled?: boolean;
  isActive?: boolean;
  status?: GameStatus;
}
export const CaseListItemDefault: React.FC<CaseListItemDefaultProps> = ({
  image,
  handler,
  price,
  free,
  style,
  className,
  enabled = true,
  caseType,
  isActive,
  status,
}) => {
  return (
    <Container className={className} style={style} onClick={handler}>
      {caseType !== CaseEnum.FREE && !!price && (
        <PriceContainer>
          <CasePrice data-status={`${status} ${isActive ? 'active' : 'inactive'}`}>
            <CurrencyText value={price} />
          </CasePrice>
        </PriceContainer>
      )}
      {free ? (
        <CaseIconFree src={image} data-status={`${status} ${isActive ? 'active' : 'inactive'}`} />
      ) : (
        <CaseIcon
          src={getSteamItemImageSrc(image)}
          data-status={`${status} ${isActive ? 'active' : 'inactive'}`}
        />
      )}
      {!enabled && <GrayContainer />}
    </Container>
  );
};
