export default {
  'What is the Ticket System?': 'O que é o sistema de bilhete?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Quando os participantes fazem lances, recebem bilhetes virtuais, um dos quais será vitorioso. Para cada pele enviado custo $1 você obter 100 bilhetes. Para peles, avaliados em US $10, você receberá 1000 bilhetes e assim por diante.',

  // GAMES

  'How is the winner determined?': 'Como é o vencedor da rodada?',
  'When is the winner determined?': 'Quando é seleccionado o vencedor da rodada?',
  'How many bets can I place per one round?': 'Quantas apostas que você pode fazer na rodada?',
  'What Service Commission is there in this game?': 'O que Comissão desta modalidade de jogo?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'modo de jogo clássico para quem gosta de apostar em grande! Aqueles que desejam tentar a sua sorte fazem uma aposta sobre o depósito total que está sendo jogado para fora entre os participantes no final da rodada.\n',
  'The winner is determined by the Ticket System in the Classic Game.':
    'No clássico vencedor do jogo modo é determinado usando sistema de ticket .',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Vencedor será determinado no final do tempo redondo ou quando o número máximo de peles no depósito.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'O serviço da Comissão variou de 0-10%, dependendo do tamanho dos ganhos. Como a Comissão proceder a aleatório peles de todos o banco redondo.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'Três jogadores-uma ótima maneira de instantaneamente tentar a sua sorte. Neste modo, apenas 3 jogadores envolveram com a limitação da taxa máxima. O vencedor receberá um depósito de três jogadores.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'O vencedor é determinado aleatoriamente, mas quanto maior a aposta, maior a chance de ganhar.',
  'One player can place one bet per one game round in this game.':
    'Neste modo, cada participante foi adoptada uma taxa para uma ronda de jogo.',
  'Where can I see my chances of the winning?': 'Como sei as chances de ganhar na rodada?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'As chances de ganhar na rodada são indicadas em percentagem e aproximam-se a soma das taxas dos participantes na rodada (o direito dos avatares) e a lista de skins jogos aninhados.',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Vencedor, quando três jogadores fazem apostas na atual rodada de jogos.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'A Comissão é de 0-15%, dependendo do tamanho dos ganhos.\nComo a Comissão proceder a aleatório peles de todos o banco redondo.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'Modo de jogo-jogo duplo, em que os jogadores apostar a vitória do setor de preto, vermelho ou verde. Aposto que este modo de jogo é usado. At o fim da rodada será determinada a cor do setor vitorioso.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Se o resultado do sorteio será de cor vermelha ou preta do setor, o montante da aposta é retornado para o jogador em 2 vezes.',
  'The green slot returns the placed bet multiplied by 14.':
    'Setor verde Obtém 14ti vezes o montante apostado.',
  'The bet sum of every slot can be different.':
    'O montante apostado em cada setor pode ser diferente.',

  'How can I Get Fast Coins?': 'Como conseguir moedas?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Participar no programa de afiliados. Começa a atrair jogadores (referências) para seu projeto usando o link de referência ou código. Por cada referals e seus jogos, você receberá as moedas.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Redimir suas peles custo de USD 57 por moeda... para todos os 57 centavos custam peles te moeda 1. você pode enviar até 30 itens em uma hora. recomendar o uso de avaliaÃ § ã £ Vapor auxiliar de inventário',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Carregamento através do sistema de pagamento. Escolha a maneira conveniente de pagar e obter o número necessário de moedas para sua conta.',

  'How is the winner slot determined (fair game)?': 'Como é o setor vencedor (jogo justo)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'No início de cada rodada, o serviço gera um número aleatório de tempo de 0 a 1 (por exemplo, 0.223088) e criptografa-lo através do algoritmo sha224 o resultado de criptografia visível no início da rodada. no final do serviço redondo multiplica o número criptografado anteriormente aos 15 anos, recebendo sector vitorioso espaço.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Exemplo: no início da rodada foi criptografado número 0.223088232334703230728. Multiplica um número por serviço 0.223088232334703230728 15 e Obtém o número de 3.34632348495. O resultado inteiro da multiplicação afinal número 3 (vermelho). Esta rodada vai ganhar os jogadores, que fizeram apostas na vitória do setor vermelho.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Pode independentemente verificar a exactidão do sector vitorioso. para este fim, no final da rodada pegar um número que foi criptografado e depois codificá-lo usando qualquer um dos serviços online, tais como http://sha224.net. Você verá o mesmo valor de hash, como as rodadas cedo. isto significa que o resultado do jogo não foi orquestrado.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Porque o sistema determina o setor vencedor ainda antes do início do jogo, e qualquer jogador pode controlar a falta de mudança-intervenção em jogo para nós é sem sentido. Portanto, este sistema é o guardião da integridade de nossos jogos.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Você pode aumentar sua aposta qualquer número de vezes antes do sorteio. O montante apostado em cada setor pode ser diferente.',

  'What bet limitations are there?': 'Quais as limitações de lá são para apostas?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'A aposta mínima é de $0,1. Sobre uma mesma cor, pode colocar no máximo $5000 ao mesmo tempo.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Atenção! Durante a mesma rodada não pode apostar em vermelho e preto ao mesmo tempo. As apostas no Verde estão disponíveis em todas as rodadas!',

  'When does the determination of the winner slot begin?': 'Quando a seleção do sector vitorioso?',
  'The winner slot is determined at the end of a round.':
    'O setor vencedor será determinado no final do tempo limite por rodada.',

  'Is there any commission in this mode?': 'Existe uma Comissão nesse modo de jogo?',
  'There is no commission at all. :)': 'Este modo de jogo não fornece qualquer tipo de Comissão.',
};
