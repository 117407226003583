import { animated } from 'react-spring';
import { List } from 'react-virtualized';
import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-100);
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.25rem;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
`;

export const BtnWrap = styled.div`
  background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
  bottom: 0;
  display: grid;
  grid-auto-columns: auto 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-rows: 3rem;
  position: sticky;

  & > button {
    text-transform: initial;
  }
`;

export const Header = styled.div`
  padding: 0.9rem 0 1.19rem;
`;

export const TitleWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;
`;

export const Description = styled.span`
  color: var(--color-white);
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 400;
  height: 36px;
  line-height: 1.5;
  opacity: 50%;
`;

export const Line = styled.span`
  border-top: 1px solid var(--color-white-gray-100);
`;

export const ContainerList = styled(animated.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: -1.25rem;
  padding-top: 12px;
`;

export const ListComp = styled(List)`
  width: 100% !important;
`;
