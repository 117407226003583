import styled from 'styled-components';

export const ContainerLine = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 100%;
  position: absolute;
  left: 0;
`;

export const ContainerLineAll = styled(ContainerLine)`
  transition:
    opacity 0.3s ease,
    top 0.3s ease;

  &[data-visible='all'] {
    opacity: 1;
    top: 0;
  }

  &[data-visible='best'] {
    opacity: 0;
    top: -52px;
    pointer-events: none;
  }
`;
export const ContainerLineBest = styled(ContainerLine)`
  transition:
    opacity 0.3s ease,
    bottom 0.3s ease;

  &[data-visible='best'] {
    opacity: 1;
    bottom: 0;
  }

  &[data-visible='all'] {
    opacity: 0;
    bottom: -52px;
    pointer-events: none;
  }
`;
export const StyledButton = styled.div`
  background: var(--color-dark-200);
  height: 28px;
  padding: 0 10px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  margin: 12px 0;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const Container = styled.div`
  height: 52px;
  position: relative;
  padding: 26px;
  border-top: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  &.tablet {
    @media screen and (min-width: 500px) {
      width: 100%;
    }
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 20px;

  .motivator-item-appear {
    transform: translateY(50px);
  }

  .motivator-item-appear-active {
    transform: translateY(0);
    transition: transform 1s ease;
  }

  .motivator-item-enter-done {
    transform: translateY(0);
  }
`;
