import React, { FC } from 'react';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { useGame, useHistory, useStatistic } from '../../../hooks';
import { Controls, Game, GameStatistic } from '../../../components';
import { Players } from '../../Game';
import { AuthControlPanel } from '@components/auth-control-panel';
import { useTranslation } from 'react-i18next';
import { Container } from './GamePage.styles';

export const GamePage: FC = () => {
  const { game } = useGame();
  const { statistic, select, handleChangeRange } = useStatistic();
  const { history } = useHistory();
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];
  return (
    <>
      <AppBar isGame />
      <div className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <Container className="tablet">
          <div className="game-container">
            <Game game={game} history={history} />
            <GameStatistic
              statistic={statistic}
              select={select}
              handleChangeRange={handleChangeRange}
            />
          </div>
          <Controls />
        </Container>
        <Players />
      </div>
      <AuthControlPanel />
      <div id="bet-panel-portal" />
    </>
  );
};
