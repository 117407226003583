import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { usePlayers } from '../../hooks';
import { Player } from '../../components';
import type { IPlayingBet } from '../../interfaces';

export const Players = () => {
  const { players } = usePlayers();
  const { t } = useTranslation();

  return (
    <Container className="tablet">
      <div className="players-header">
        <div className="player-description">{t('Player')}</div>
        <div className="player-factor">{t('Bet')}</div>
        <div className="player-coefficient">X</div>
        <div className="player-profit">{t('Profit')}</div>
      </div>

      {players.map((player: IPlayingBet) => (
        <Player key={player.id} {...player} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;

  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 1.75fr 0.5fr 1.25fr;
    grid-template-rows: 50px;
    grid-gap: 0.3rem;
    align-content: center;
    padding: 0 1rem;
    background: var(--color-dark-600);

    color: var(--color-gray-200);
    font-size: 0.65rem;

    &.players-header {
      grid-template-rows: 2rem;
    }

    & .player-description,
    & .player-coefficient,
    & .player-factor,
    & .player-profit {
      align-self: center;
    }

    & .player-coefficient,
    & .player-factor {
      justify-self: center;
    }

    & .player-profit {
      justify-self: end;
    }
  }
`;
