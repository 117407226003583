import _ from 'lodash';

export const getTitleByKey = {
  cases_avg_win: 'Average profit',
  cases_biggest_drop: 'Biggest drop',
  // 'cases_games': 'Opened cases',
  cases_blue: 'Opened blue cases',
  cases_orange: 'Opened orange cases',
  cases_silver: 'Opened silver cases',
  cases_profit: 'Profit',
  cases_success: 'Paid off cases',
  cases_total_games_played: 'Total games played',

  classic_avg_bet: 'Average bet',
  classic_avg_win: 'Average profit',
  classic_games: 'Played games',
  classic_max_bet: 'Max bet',
  classic_money_betted: 'Total bet',
  classic_profit: 'Profit',
  classic_skins_count: 'Total skins was betted',
  classic_win_games: 'Total wins',
  classic_total_games_played: 'Total games played',

  craft_avg_profit: 'Average profit',
  craft_big_profit: 'Max win',
  craft_games: 'Played games',
  craft_low_chance: 'Minimal chance',
  craft_profit: 'Profit',
  craft_wins: 'Total paid off games',
  craft_total_games_played: 'Total games played',

  crash_avg_bet: 'Average bet',
  crash_avg_win: 'Average profit',
  crash_bets: 'Total bet',
  crash_max_bet: 'Max bet',
  crash_max_coef1: 'Max coef x1',
  crash_max_coef2: 'Max coef x2',
  crash_max_coef3: 'Max coef',
  crash_max_win: 'Max win',
  crash_profit: 'Profit',
  crash_total_bet: 'Total bet',
  crash_win_games: 'Total paid off games',
  crash_total_games_played: 'Total games played',

  double_average_bet: 'Average bet',
  double_profit: 'Profit',
  double_black_bets: 'Total bets on black',
  double_green_bets: 'Total bets on zero',
  double_red_bets: 'Total bets on red',
  double_max_bet: 'Max bet',
  double_max_profit: 'Max profit',
  double_total_bet_sum: 'Total bet',
  double_total_played_games: 'Played games',
  double_total_winned_games: 'Total wins',
  double_total_games_played: 'Total games played',

  fast_avg_bet: 'Average bet (excluded)',
  fast_avg_win: 'Average profit',
  fast_games: 'Played games',
  fast_max_bet: 'Max bet',
  fast_money_betted: 'Total bet',
  fast_profit: 'Profit',
  fast_skins_count: 'Total skins was betted',
  fast_win_games: 'Total wins',
  fast_total_games_played: 'Total games played',

  slot_avg_bet: 'Average bet',
  slot_avg_winning: 'Average profit',
  slot_bonus_winning: 'Bonus winning',
  slot_games: 'Played games',
  slot_profit: 'Profit',
  slot_winning_spins: 'Successful spins',
  slot_total_games_played: 'Total games played',

  wheel_avg_drop: 'Average profit',
  wheel_biggest_drop: 'Biggest drop',
  wheel_games: 'Played games',
  wheel_profit: 'Profit',
  wheel_success: 'Successful spins',
  wheel_total_games_played: 'Total games played',

  x50_average_bet: 'Average bet',
  x50_average_profit: 'Average profit',
  x50_blue_bets: 'Total bets on blue',
  x50_red_bets: 'Total bets on red',
  x50_green_bets: 'Total bets on green',
  x50_gold_bets: 'Total bets on gold',
  x50_max_bet: 'Max bet',
  x50_max_profit: 'Max profit',
  x50_profit: 'Profit',
  x50_total_bet_sum: 'Total max bets',
  x50_total_played_games: 'Played games',
  x50_total_winned_games: 'Total won games',
  x50_total_games_played: 'Total games played',

  tower_avg_bet: 'Average bet',
  tower_avg_profit: 'Average profit',
  tower_games: 'Played games',
  tower_max_bet: 'Max bet',
  tower_max_profit: 'Max profit',
  tower_profit: 'Profit',
  tower_sum_bet: 'Total bet',
  tower_total_games_played: 'Total games played',

  hilo_count_catched_jokers: 'Total catched jokers',
  hilo_black_profit: 'Total profit on black',
  hilo_games: 'Played games',
  hilo_games_won: 'Total paid off games',
  hilo_joker_profit: 'Total profit on joker',
  hilo_max_bet: 'Max bet',
  hilo_max_profit: 'Max profit',
  hilo_profit: 'Profit',
  hilo_red_profit: 'Total profit on red',
  hilo_sum_bet: 'Total bet',
  hilo_total_games_played: 'Total games played',

  baccarat_avg_bet: 'Average Bet',
  baccarat_bank_bet_count: 'Total bets on Bank',
  baccarat_bank_pair_bet_count: 'Total bets on Bank Pairs',
  baccarat_best_profit: 'Max profit',
  baccarat_max_bet: 'Max bet',
  baccarat_player_bet_count: 'Total bets on Player',
  baccarat_profit: 'Profit',
  baccarat_tie_bet_count: 'Total bets on Tie',
  baccarat_total_played_games: 'Total games played',
  baccarat_player_pair_bet_count: 'Total bets on Player Pairs',

  poggi_buildings: 'Buildings',
  poggi_max_buildings: 'Skyscrapers',

  poggi_lucky_spins: 'Lucky spins',
  poggi_profit: 'Profit',
  poggi_avg_bet: 'Average bet',
  poggi_games: 'Total games played',

  totalBet: 'Total bet',
};

export const withCurrency = key =>
  [
    'Average bet',
    'Average profit',
    'Biggest drop',
    'Max bet',
    'Max profit',
    'Max win',
    'Profit',
    'Total bet',
    'Total profit on black',
    'Total profit on red',
    'Total profit on joker',
  ].includes(key);

export const withExistingStatistics = key =>
  [
    'Profit',
    'Total games played',
    'Max win',
    'Max bet',
    'Max coef',
    'Max profit',
    'Total catched jokers',
    'Total bets on black',
    'Total bets on zero',
    'Total bets on red',
    'Total bets on blue',
    'Total bets on green',
    'Total bets on gold',
    'Total profit on joker',
    'Total profit on red',
    'Total profit on black',
    'Total bets on Bank',
    'Total bets on Bank Pairs',
    'Total bets on Player',
    'Total bets on Player Pairs',
    'Total bets on Tie',
    'Minimal chance',
    'Biggest drop',
    'Opened cases',
    'Average bet',
    'Bonus winning',
    'Total skins was betted',
    'Opened blue cases',
    'Opened orange cases',
    'Opened silver cases',
    'Buildings',
    'Skyscrapers',
    'Top coefs captured (x50)',
    'Lucky spins',
  ].includes(key);

export const isPrimaryStatistic = key =>
  ['Profit', 'Total games played', 'Total wins', 'Max win'].includes(key);

export const findTopStatistics = items =>
  _.chain(items)
    .map(item =>
      _.chain(item.statistics)
        .filter(item => ['Profit', 'Total games played'].includes(item.title))
        .value()
    )
    .flatten()
    .map(item => ({ ...item, value: parseFloat(item.value) }))
    .groupBy('title')
    .mapValues(values => _.maxBy(values, 'value'))
    .toArray()
    .map(item => item.key)
    .value();

export const convertHexToRgba = (hex, opacity) => {
  hex = hex.trim().replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getChartStatistics = stats => {
  const totalGamePlayed = getStatisticDataByParam('title', 'Total games played', stats);
  const totalGameSum = totalGamePlayed.reduce((acc, i) => acc + i.value, 0);
  const totalWon = getStatisticDataByParam('key', 'profit', stats);
  const totalWonSum = totalWon.reduce((acc, i) => acc + i.value, 0);
  return { totalGamePlayed, totalGameSum, totalWon, totalWonSum };
};

const getStatisticDataByParam = (param, statisticDataKey, stats) => {
  const categories = _.groupBy(stats, model => model.mode);
  return _.chain(categories)
    .keys()
    .map(key =>
      _.reduce(
        categories[key],
        (result, model) => {
          const { value = 0 } =
            model.statistics.find(item => item[param].includes(statisticDataKey)) || {};
          return {
            key: _.kebabCase(key),
            value: result.value + Math.round(value),
          };
        },
        { value: 0 }
      )
    )
    .value();
};
