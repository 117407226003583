import * as React from 'react';
import { IControl } from '@components/Selects';
import { URLS } from 'services/constant-urls';

export const Control: React.FC<IControl> = ({ selectValue }) => (
  <>
    <img className="circle" src={`${URLS.countryFlags}/${selectValue}.png`} alt={selectValue} />
    <span>{selectValue}</span>
  </>
);
