import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { ACTION_TYPE } from '../actionType';
import { RevisionData, RevisionState } from '../interfaces';
import { catchError, switchMap, map } from 'rxjs/operators';
import casesRepository from '../../repository/casesRepository';
import { of } from 'rxjs';
import { addNotification } from 'modules/Notifications/duck';
import { AjaxError } from 'rxjs/ajax';
import { ActionRevisionRequest, ActionsRevision } from './case-revision.interfaces';

export const actionRevisionRequest = (caseId: string, page: number = 0) => ({
  type: ACTION_TYPE.CASE_REVISION_ACTION_REQUEST,
  payload: {
    caseId,
    page,
  },
});
const actionRevisionError = () => ({
  type: ACTION_TYPE.CASE_REVISION_ACTION_ERROR,
});
const actionRevisionResponse = (revisionData: RevisionData) => ({
  type: ACTION_TYPE.CASE_REVISION_ACTION_RESPONSE,
  payload: revisionData,
});

export const revisionEpic: Epic = (action$: ActionsObservable<ActionRevisionRequest>) =>
  action$.pipe(
    ofType(ACTION_TYPE.CASE_REVISION_ACTION_REQUEST),
    switchMap(({ payload }) =>
      casesRepository.fetchRevisionData(payload.caseId, payload.page).pipe(
        map(({ response }: { response: RevisionData }) => actionRevisionResponse(response)),
        catchError(({ response }: { response: AjaxError }) =>
          of(addNotification({ type: 'error', body: response.message }), actionRevisionError())
        )
      )
    )
  );

export const revisionData = (
  state: RevisionState = { data: {}, isLoading: true },
  action: ActionsRevision
) => {
  switch (action.type) {
    case ACTION_TYPE.CASE_REVISION_ACTION_REQUEST: {
      return { ...state, isLoading: true };
    }
    case ACTION_TYPE.CASE_REVISION_ACTION_RESPONSE: {
      return {
        data: { ...action.payload },
        isLoading: false,
      };
    }
    case ACTION_TYPE.CASE_REVISION_ACTION_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
