import styled from 'styled-components';

export const Container = styled.div`
  height: 53px;
`;

export const UserStatisticsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding-bottom: 1.25rem;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoKey = styled.span`
  color: var(--color-white);
  font-size: 0.63rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const InfoValue = styled.span`
  color: var(--color-gray-100);
  font-size: 0.88rem;
  font-weight: 400;
`;
