import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { AnimatedRouter } from '../../../@components/animated-router';
import { getTicket, getTypes } from '../duck';
import { GamePage, HistoryPage, RulesPage, TopsPage } from './Pages';
import { LotteryPage } from '../../../@components/Histories';

class TowerGame extends React.PureComponent {
  componentDidMount() {
    const { getTypes, getTicket } = this.props;

    getTypes();
    getTicket();
  }

  render() {
    const { match } = this.props;

    return (
      <div className="content">
        <AnimatedRouter>
          <Route path={`${match.url}/history`} component={HistoryPage} />
          <Route path={`${match.url}/rules`} component={RulesPage} />
          <Route path={`${match.url}/top`} component={TopsPage} />
          <Route path={`${match.url}/lottery`} component={LotteryPage} />
          <Route path={match.url} component={GamePage} />
        </AnimatedRouter>
      </div>
    );
  }
}

export default connect(null, {
  getTypes,
  getTicket,
})(TowerGame);
