import styled from 'styled-components';

export const ContainerIcon = styled.div<{ isUp: boolean }>`
  align-items: center;
  animation: ${({ isUp }) => (isUp ? 'move-up-icon' : 'move-down-icon')} 300ms forwards;
  bottom: 24px;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 12px;
  width: 32px;
  z-index: 10;

  @keyframes move-up-icon {
    0% {
      opacity: 0.3;
      pointer-events: none;
      transform: translateY(50px);
    }

    99% {
      pointer-events: none;
    }

    100% {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  }

  @keyframes move-down-icon {
    0% {
      opacity: 1;
      pointer-events: none;
      transform: translateY(0);
    }

    99% {
      pointer-events: none;
    }

    100% {
      opacity: 0;
      pointer-events: auto;
      transform: translateY(50px);
    }
  }

  & > svg {
    height: 16px;
    width: 16px;
  }

  &::after {
    background: #243038;
    border-radius: 50%;
    box-shadow: 0 0 4px 0 #0000003f;
    content: '';
    height: 32px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    z-index: -1;
  }
`;

export const Container = styled.div<{ isUp: boolean }>`
  animation: ${({ isUp }) => (isUp ? 'move-up-icons' : 'move-down-icons')} 300ms forwards;
  background: var(--color-dark-600);
  bottom: 0;
  display: flex;
  height: 100px;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 6;
`;

export const ContainerIcons = styled.div`
  position: absolute;
  width: 100%;

  @keyframes move-up-icons {
    0% {
      opacity: 0.5;
      pointer-events: none;
      transform: translate(0, 100px);
    }

    99% {
      pointer-events: none;
    }

    100% {
      opacity: 1;
      pointer-events: auto;
      transform: translate(0, 0);
    }
  }

  @keyframes move-down-icons {
    0% {
      opacity: 1;
      pointer-events: none;
      transform: translate(0, 0);
    }

    99% {
      pointer-events: none;
    }

    100% {
      opacity: 0.3;
      pointer-events: auto;
      transform: translate(0, 100px);
    }
  }
`;

export const Close = styled.span`
  color: var(--color-gray-200);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 125%;
  position: absolute;
  right: 15px;
  text-align: center;
  text-transform: capitalize;
  top: 10px;
`;

export const Icons = styled.div`
  display: flex;
  gap: 24px;
  height: 32px;
  margin: 46px 0 30px;
  overflow-x: scroll;
  padding: 0 28px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ContainerEmoji = styled.div<{ isEmojiAwaiting: boolean }>`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  touch-action: manipulation;
  width: 32px;

  & > svg {
    filter: ${({ isEmojiAwaiting }) => (isEmojiAwaiting ? 'grayscale(1)' : 'unset')};
    transition: filter 300ms ease;
  }

  &.active {
    &::after {
      background: #243038;
      border-radius: 50%;
      box-shadow: 0 0 4px 0 #0000003f;
      content: '';
      height: 32px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      z-index: -1;
    }
  }
`;
