import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { useSalesSelectedIdentifiers } from '../../../hooks';
import { CheckboxControl } from '@UIkit';
import { MarketplaceDialogs } from '../../../marketplace-dialogs.config';

export const PublicationController = () => {
  const { t } = useTranslation('marketplace');
  const { selected } = useSalesSelectedIdentifiers();
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.INVENTORY_PENALTY_WARNING_DIALOG);
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      autoApprove: false,
    },
  });

  const onSubmit = useCallback(
    (data: { autoApprove: boolean }) => {
      return handleToggleDialog(MarketplaceDialogs.INVENTORY_PENALTY_WARNING_DIALOG, data)();
    },
    [handleToggleDialog]
  );

  return (
    <Container className={selected.identifiers.length ? 'visible' : ''}>
      <CheckboxControl>
        <input type="checkbox" name="autoApprove" id="autoApprove" ref={register} />
        <label htmlFor="autoApprove">
          <span>
            {t(
              'Automatic confirmation of the transaction with the sold item at the end of the auction'
            )}
          </span>
        </label>
      </CheckboxControl>
      <Button
        className="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!selected.identifiers.length}
      >
        <span className="multi-line">
          <span>{t('Place')}</span>
          <span>
            {t('{{count}} items for {amount}', {
              count: selected.identifiers.length,
              amount: selected.computed.amount,
              postProcess: 'with-template',
            })}
          </span>
        </span>
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding: 0.75rem 1rem 1.5rem;
  height: 8.5rem;
  background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  transform: translate3d(0, 100%, 0);
  transition: transform 300ms var(--transition-ease-out);

  &.visible {
    transform: translate3d(0, 0, 0);
  }
`;
