import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { ChartLabels, Container, Profit } from './ChartSemi.styles';

interface IStatisticProfit {
  key: string;
  value: number;
}

interface IChartSemi {
  totalWon: IStatisticProfit[];
  totalWonSum: number;
}

const { innerWidth } = window;

export const ChartSemi: FC<IChartSemi> = ({ totalWon, totalWonSum }) => {
  const { t } = useTranslation();
  const size = innerWidth - 40;
  const strokeWidth = 65;
  const r = (size - strokeWidth) / 2;
  const cx = size / 2;
  const cy = size / 2;
  const A = Math.PI + Math.PI * 0.4;
  const startAngle = Math.PI + Math.PI * 0.2;
  const endAngle = 2 * Math.PI - Math.PI * 0.2;
  const x1 = cx - r * Math.cos(startAngle);
  const y1 = -r * Math.sin(startAngle) + cy;
  const x2 = cx - r * Math.cos(endAngle);
  const y2 = -r * Math.sin(endAngle) + cy;
  const d = `M ${x1} ${y1} A ${r} ${r} 0 1 0 ${x2} ${y2}`;
  const circumference = r * A;

  return (
    <Container>
      <svg width={size} height={size}>
        <path
          stroke="#212f35"
          fill="none"
          strokeDasharray={`${circumference}, ${circumference}`}
          {...{ d, strokeWidth }}
        />
      </svg>
      {totalWon.map((item: IStatisticProfit, i: number, arr: IStatisticProfit[]) => {
        const progress = item.value / totalWonSum || 0;

        const prevItem = i ? arr[i - 1].value : 0;
        const rotateZ = i > 1 ? arr[i - 2].value + arr[i - 1].value : prevItem;
        const strokeDashoffset = r * (A * (progress - 0.005)) - circumference;
        const strokeDashoffsetShadow = r * (A * progress) - circumference;

        return (
          <svg
            key={item.key}
            width={size}
            height={size}
            style={{
              transform: `rotateZ(${(252 * rotateZ) / totalWonSum}deg)`,
            }}
          >
            <path
              stroke="#1a262a"
              fill="none"
              strokeDasharray={`${circumference}, ${circumference}`}
              strokeDashoffset={strokeDashoffsetShadow}
              {...{ d, strokeWidth }}
            >
              <animate
                id="pvp"
                attributeName="stroke-dashoffset"
                from={-circumference}
                to={strokeDashoffset}
                dur="2s"
              />
            </path>
            <path
              stroke={`var(--color-${totalWonSum > 0 ? item.key : 'gray-350'})`}
              fill="none"
              strokeDasharray={`${circumference}, ${circumference}`}
              {...{ d, strokeWidth, strokeDashoffset }}
            >
              <animate
                id="pvp"
                attributeName="stroke-dashoffset"
                from={-circumference}
                to={strokeDashoffset}
                dur="2s"
              />
            </path>
          </svg>
        );
      })}
      <Profit>
        <span className="profit-title">{t('Total profit')}</span>
        <span className="profit-total">
          <CurrencyText value={totalWonSum} />
        </span>
        <div className="profit-description">
          {totalWon.map((item: IStatisticProfit) => (
            <span key={item.key} style={{ color: `var(--color-${item.key})` }}>
              <CurrencyText value={item.value} />
            </span>
          ))}
        </div>
      </Profit>
      <ChartLabels>
        <span>PvP</span>
        <span>PvE</span>
        <span>Solo PvE</span>
      </ChartLabels>
    </Container>
  );
};
