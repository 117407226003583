import React, { memo } from 'react';
import { getColorBySector, SECTORS } from '../../../../utils';
import { GameRouletteGradientReel } from './game-roulette-gradient-reel';

export const GameRouletteReel = memo(() => (
  <GameRouletteGradientReel>
    <>
      {SECTORS.map((sector, idx) => (
        <svg
          key={sector}
          className={`sector-${getColorBySector(sector)}`}
          viewBox="0 0 303 303"
          style={{
            transform: `translate3d(-50%, -50%, 0) rotate(${idx * 6.666666666666667}deg)`,
          }}
        >
          <path
            d="M149.525 294.893C144.945 294.832 140.412 294.559 135.926 294.072L134.912 302.118C139.695 302.639 144.543 302.932 149.454 303L149.525 294.893Z"
            fill="var(--stroke-color)"
          />
        </svg>
      ))}
    </>
  </GameRouletteGradientReel>
));
