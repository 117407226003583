import styled from 'styled-components';

export const Container = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  letter-spacing: 0.1px;
  color: var(--color-gray-200);
  display: flex;
  gap: 5px;
  white-space: nowrap;
  align-items: flex-end;

  &[data-status='finish'] {
    gap: 0;
    flex-direction: column;
  }

  &[data-status='waiting'] {
    display: grid;
    column-gap: 5px;

    & > div {
      grid-column: 1 / 3;
    }
  }

  @media screen and (max-width: 360px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

export const CurrencyWrapper = styled.div`
  color: var(--yellow);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.24px;
  display: flex;
  justify-content: flex-end;
`;
