import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { Icon, SecretField, VerifyLink } from '@components/Common';
import { ITrades } from '../../interfaces/reducer.fast.interface';

interface IVerifiedGame {
  number: number;
  md5: string;
  rand: string | null;
  userid: number | null;
  setGameId: () => void;
  trades: ITrades;
  gameId: number;
  completed: boolean;
  winner: number | null;
  toggleId: number | null;
}

export const VerifiedGame: React.FC<IVerifiedGame> = React.memo(props => {
  const { t } = useTranslation();
  return (
    <GameContainer>
      <Inner>
        <AnimateContainer>
          <GameNumber onClick={props.setGameId}>
            <>
              {t('GAME')}
              {' №'}
              {props.number}
              <DropdownIcon isOpen={props.toggleId === props.number}>
                <Icon name="arrow" />
              </DropdownIcon>
            </>
          </GameNumber>
          {props.children}
          <SecretField value={props.md5} placeholder="Round hash sha224" />
          <SecretField value={props.rand} placeholder="Round number" />
          <VerifyLink rand={props.rand} />
        </AnimateContainer>
      </Inner>
    </GameContainer>
  );
});

interface IDropdownIcon {
  isOpen: boolean;
}

const GameContainer = styled.div`
  display: flex;
  flex: 10;
  height: 100%;
  padding: 10px 20px;
`;

const Inner = styled.div`
  display: flex;
  flex: 1 0;
  flex-flow: column;
  border-radius: var(--size-radius-m);
  background: var(--color-gray-700);
  overflow: hidden;
`;

const AnimateContainer = styled(animated.div)`
  display: flex;
  flex-flow: column;
`;

const GameNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 30px;
  padding: 5px 0;

  font-size: 0.8rem;
  line-height: 1.4;
  letter-spacing: 0.53px;
  color: var(--color-white);
  text-transform: uppercase;
`;

const DropdownIcon = styled.div<IDropdownIcon>`
  margin-left: 6px;
  color: var(--color-white);
  transition: transform 0.3s linear;
  transform-origin: center;
  transform: ${p => (p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
