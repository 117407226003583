import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const promoParamsHandler = (): Observable<{
  clickId: string | null;
  utm_campaign: string | null;
}> => {
  return of(window.location.href).pipe(
    map(value => new URLSearchParams(value.split('?')[1])),
    map((params: URLSearchParams) => {
      const clickId: string | null = params.get('clickid');
      const utm: string | null = params.get('utm_campaign');

      !!clickId && localStorage.setItem('clickid', clickId);
      !!utm && localStorage.setItem('utm_campaign', utm);

      return {
        clickId: !!clickId ? clickId : localStorage.getItem('clickid'),
        utm_campaign: !!utm ? utm : localStorage.getItem('utm_campaign'),
      };
    })
  );
};
