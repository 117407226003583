/* eslint-disable */
export default {
  Error: 'Ошибка',
  'Error placing a bet': 'Ошибка принятия ставки',
  'Error entering code': 'Ошибка ввода кода',
  'Invalid bet': 'Ошибка ставки',
  'Error reserving an item': 'Ошибка резервирования вещи',
  'Link saved successfully': 'Ссылка успешно сохранена',
  'Skins was successful sold': 'Вы успешно продали предметы',
  'Round number is copied': 'Число раунда скопировано',
  'Round hash is copied': 'Хэш раунда скопирован',
  'Hash salt is copied': 'Соль хэша скопирована',
  "You've successfully updated a bet": 'Вы успешно обновили ставку',
  "You've successfully sell winning item": 'Вы успешно продали выигранный предмет',
  "You've successfully take winning item": 'Вы успешно забрали выигранный предмет',
  'Your bet has been added to the queue': 'Ваша ставка была добавлена в очередь',
  'Bet added to queue': 'Ставка добавлена в очередь',
  'Bet successfully placed': 'Ставка успешно размещена',
  'Support address was copied': 'Адрес техподдержки скопирован',
  'No notifications.': 'Нет уведомлений.',
  'Incorrect link. Enter the correct link and try again.':
    'Некорректная ссылка. Введите корректную ссылку и попробуйте еще раз',
  'This is not your link. Enter your link and try again.':
    'Это не ваша ссылка. Введите свою и попробуйте ещё раз',
  "Don't forget to make your inventory public to get your winnings!":
    'Не забудьте открыть инвентарь чтобы получить выигрыш!',
  'min. number of items per trade not exceeded':
    'Минимальное количество предметов за трейд не достигнуто',
  'Too many requests, try again later': 'Слишком много запросов, попробуйте позже',
  'Your trade has been cancelled for the following reason':
    'Your trade has been cancelled for the following reason',
  'cheating attempt': 'попытка обмана',
  'items not from CS': 'items not from CS',
  'max. number of items per trade exceeded': 'слишком много вещей в трейде',
  'player not registered on website': 'игрок не зарегистрирован на сайте',
  'souvenir items are not allowed': 'сувенирные вещи запрещены',
  'some of the items cannot be appraised': 'некоторые предметы невозможно оценить',
  'some of the items are below minimum price': 'некоторые предметы ниже минимальной цены',
  'total price of a trade is below minimum': 'цена трейда меньше минимальной',
  'Your trade has been accepted!': 'Ваш трейд принят!',
  'The items have been added to the queue.': 'Вещи добавлены в очередь.',
  'Your trade has been checked out!': 'Ваш трейд проверен!',
  'It is currently being accepted': 'В данный момент он принимается.',
  'Unable to send a trade': 'Не получается отправить вам обмен',
  'Update your trade link in the settings of the site': 'Обновите вашу трейд ссылку в настройках',
  'Enter correct trade link and make sure your inventory is not full.':
    'Введите корректную трейд ссылку и убедитесь что ваш инвентарь не переполнен',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Ваш аккаунт не доступен для получения трейда. Более подробная информация будет показана если вы откроете трейд с кем нибудь.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'В данный момент Steam не доступен. Пожалуйста, ожидайте повторной отправки.',
  'Not enough money on balance': 'Недостаточно денег на балансе',
  'Not enough points on balance': 'Недостаточно денег на балансе',
  'Game is ending, try again later': 'Игра заканчивается, попробуйте позже',
  'Congratulations!': 'Поздравляем!',
  "You've successfully placed a bet": 'Вы успешно разместили ставку',
  "You've successfully placed a bet with </amount>":
    'Вы успешно разместили ставку в размере ${currency(amount)}',
  'To the game #</game> on </color> ': 'В игру #{{</game>}} на {{</color>}} ',
  'Store is locked. Win </amount> more to unlock.':
    'Магазин заблокирован. Выиграйте ${currency(amount)} чтобы разблокировать.',
  'Item has not been found, coins have been returned':
    'Вещь не найдена на складе. Деньги возвращены.',
  'Your order in the store has been cancelled': 'Ваш заказ в магазине был отменён',
  'The min bet is </minBet>': 'Минимальная ставка {{</minBet>}}',
  'The max bet is </maxBet>': 'Максимальная ставка {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Вы не можете сделать ставку на красное и черное одновременно',
  "You don't have CSGOFAST.com in Steam nickname!":
    'В вашем Steam никнейме отсутствует CSGOFAST.com!',
  'Referral code is copied': 'Реферальный код скопирован',
  'Referral link is copied': 'Реферальная ссылка скопирована',
  'You have exceeded the limit of 150 free coins per account':
    'Вы превысили ограничение в 150 бесплатных монет на один аккаунт',
  'Account locked': 'Account locked',
  'Referral code possible to use only until 15 minutes after registration on site. Promo code can be found in chat.':
    'Referral code possible to use only until 15 minutes after registration on site. Promo code can be found in chat.',
  'Code is not correct, result': 'Code is not correct, result',
  'Wrong promo code, sorry. Check code again.': 'Неправильный промо-код, попробуйте ещё раз!',
  "You've bet </amount> on the crash game #</game>!":
    'Вы поставили ${currency(amount)} на краш игру #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Вы обновили коэффициент {{</coef>}} на краш игру #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'Вы выиграли ${currency(amount)} в краш игре #{{</number>}}!',
  'Too low auto stop coef, increase it': 'Too low auto stop coef, increase it',
  'Code for $</amount> has been created': 'Код на ${currency(amount)} успешно создан',
  'Item has not been found in store, full price of skin will be refunded':
    'Item has not been found in store, full price of skin will be refunded',
  'You already have an item': 'Вы уже получили предмет',
  '</amount> refilled on your balance': '${currency(amount)} начислено на ваш баланс',
  'Your balance is replenished by </amount>': '${currency(amount)} начислено на ваш баланс',
  'We will sent it to your Steam in few moments': 'Очень скоро мы вышлем его на ваш Steam аккаунт',
  'You already sold this skin or get a trade offer with them in Steam.':
    'You already sold this skin or get a trade offer with them in Steam.',
  'Please, just wait for few minutes.': 'Please, just wait for few minutes.',
  'Your items has been sended to game!': 'Ваши вещи были отправлены в игру',
  '</amount> have been returned': 'Сумма, в размере {{</amount>}}, была возвращена на счет',
  'Item has not been found, retry in few minutes (</amount>)':
    'Предмет не найден. Попробуйте через несколько минут(${currency(amount)})',
  'Item has not been found, retry in few minutes (1)':
    'Предмет не найден. Попробуйте через несколько минут (1)',
  'It is currently being processing by game bots':
    'В настоящее время они обрабатываются игровыми ботами',
  '</amount> have been refilled': 'Пополнение на сумму ${currency(amount)}',
  'Minimum refill sum must be more than </amount>':
    'Минимальная сумма пополнения должна быть не меньше ${currency(amount)}',
  'Skins get into the game instantly in the order of the queue':
    'Предметы попадают в игру моментально, в порядке очереди',
  'The bet amount is less than the minimum': 'Ваша ставка меньше минимальной',
  'The bet amount is greater than the maximum': 'Ваша ставка больше максимальной',
  'The number of items is greater than the maximum': 'Количество вещей больше максимального',
  'The number of items is less than the minimum': 'Количество вещей меньше минимального',
  'There is not enough money on your balance for this bet':
    'На Вашем счету недостаточно средств для данной ставки',
  'Request successfully created': 'Запрос успешно создан',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Ваш заказ #{{</id>}} на сумму ${currency(amount)} успешно принят, ожидайте предложение обмена в Steam.',
  'Reason of order rejection': 'Причина отказа',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Ваша ставка ${currency(amount)} была принята и попадет в игру в течение {{</time>}} секунд',
  'Your bet was added to queue, in few seconds will added to the game':
    'Ваша ставка добавлена в очередь и в скором времени появится в игре',
  'Level Up reward added: </prizeAmount>':
    'Начислена награда за достижение уровня: {{</prizeAmount>}}',
  'Level up': 'У вас новый уровень!',
  'Your balance is replenished by <%= currency(amount) %>':
    'Ваш баланс пополнен на ${currency(amount)}',
  'Your order </order_id> is successfully processed': 'Ваш ордер {{</order_id>}} успешно обработан',
  Cabinet: 'Кабинет',
  'Level Up reward added: ${currency(prizeAmount)}':
    'Level Up вознаграждение начислено: ${currency(prizeAmount)}',
  'Copy hash': 'Hash скопирован',
  'Copy salt': 'Salt скопирован',
  'Copy cards': 'Карты скопированы',
  'Withdrawal {{ id }} was canceled. Contact technical support':
    'Вывод {{ id }} был отменен. Обратитесь в техническую поддержку',
  ERROR: {
    INVENTORY_SERVICE: {
      ADD_PURCHASED_ITEMS: 'Не удалось обменять. Предметы не могут быть начислены в ваш инвентарь',
      CREDIT_MONEY: 'Не удалось обменять. Предметы/деньги не могут быть списаны',
      DEBIT_MONEY: 'Не удалось обменять. Предметы/деньги не могут быть начислены',
      NOT_ENOUGH_BALANCE: 'Недостаточно средств, пожалуйста, выберите другую сумму',
      NOT_ENOUGH_MONEY: 'Не удалось обменять. Недостаточно средств',
      NOT_FOUND_IN_SHOP: 'Этот предмет больше не доступен в магазине',
      NOT_FOUND_IN_USER_INVENTORY: 'Предмет больше не доступен в вашем инвентаре',
      SELL_FROM_INVENTORY: 'Не удалось продать предмет',
      WITHDRAWAL_ERROR:
        'Ваш аккаунт не может выполнять вывод средств. Пожалуйста, свяжитесь с технической поддержкой',
      WRONG_ACCOUNT_TYPE_BRONZE: 'Вам нужен серебряный аккаунт, чтобы открыть кейс',
    },
    STEAM_STORE: {
      NO_STEAM_TRADE_LINK: 'Пожалуйста, сохраните вашу актуальную трейд ссылку в настройках.',
    },
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Вывод заблокирован. Выиграйте от ${currency(amount)} для разблокировки.',
    },
  },
  ERRORS: {
    MARKET: {
      ADD_ITEM_USER_PROCESSING_WAIT: 'Запрос обрабатывается, пожалуйста, подождите',
      ADD_KIT_SELLER_PRICE_MORE: 'Произошла ошибка создания набора, пожалуйста, попробуйте еще раз',
      BASE_ITEM_NOT_FOUND: 'Предмет не найден',
      BID_ITEM_PROCESSING_WAIT: 'Слишком много запросов. Пожалуйста, попробуйте позже',
      BID_KIT_NOT_FOUND: 'Набор не найден',
      BID_NOT_ENOUGH_COINS: 'На вашем балансе недостаточно средств',
      BID_ON_OWN_KIT: 'Покупка собственных предметов невозможна',
      BID_SELLER_PRICE_MORE: 'Произошла ошибка создания набора, пожалуйста, попробуйте еще раз',
      BID_USER_LAST_BIDDER: 'Ваше предложение лучшее на данный момент',
      CONFIRM_ITEM_PROCESSING_WAIT: 'Наборы находится в обработке. Пожалуйста, подождите',
      CONFIRM_KIT_NOT_FOUND: 'Набор не найден',
      CONFIRM_USER_NOT_LAST_BIDDER: 'Вы не можете подтвердить этот предмет',
      CREATE_ORDER: {
        BUYER_WITHOUT_STEAM_ID: 'Вы пытаетесь купить предмет без стим аккаунта',
        ITEM_STATUS_WRONG: 'Ошибка получения статуса предметов',
        SELLER_WITHOUT_STEAM_ID: 'Вы пытаетесь продать предмет без стим аккаунта',
      },
      DECLINE_ITEM_PROCESSING_WAIT: 'Набор находится в обработке. Пожалуйста, подождите',
      DECLINE_KIT_NOT_FOUND: 'Набор не найден',
      DECLINE_USER_IS_NOT_SELLER: 'Вы пытаетесь продать не тот предмет',
      DELETE_ALL_ITEMS_PROCESSING_WAIT: 'Наборы находится в обработке. Пожалуйста, подождите',
      DELETE_BIDS_NOT_FOUND: 'Предметы не найдены',
      DELETE_KITS_NOT_FOUND: 'Набор не найден',
      DELETE_PROCESSING_WAIT: 'Слишком много запросов. Пожалуйста, попробуйте позже',
      DELETE_USER_IS_NOT_SELLER: 'Вы пытаетесь продать не тот предмет',
      INSERT_KIT_SOME_ITEM_NOT_FOUND: 'В наборе отсутствует один или несколько предметов',
      INSERT_KIT: 'Предмет уже на продаже',
      P2P_DISABLED: 'Произошла проблема с сервером Steam, пожалуйста, попробуйте позже',
      PAUSE_KITS_NOT_FOUND: 'Набор не найден',
      RESUME_KITS_NOT_FOUND: 'Набор не найден',
      SELL_NOW_ITEM_PROCESSING_WAIT: 'Предмет в процессе, пожалуйста, подождите',
      SELL_NOW_KIT_NOT_FOUND: 'Товар исчез из продажи',
      SELL_NOW_USER_NOT_OWNER: 'Вы пытаетесь продать не тот предмет',
      SIH_STATS_ITEMS_NOT_FOUND: 'Статистика по предмету отсутствует',
      STEAM_INVENTORY_CAN_NOT_GET: 'Не удалось загрузить ваш стим инвентарь',
      STEAM_INVENTORY_USER_WITHOUT_STEAM:
        'Вы пытаетесь использовать стим сервисы без стим аккаунта',
      STEAM_PROFILE_IS_PRIVATE:
        'Профиль Steam является приватным, пожалуйста, сделайте его общедоступным',
      UPDATE_ORDER: { ORDER_NOT_FOUND: 'Заказ не найден' },
      USER_BANNED: 'Вы заблокированы',
      USER_CHECK_FAILED: 'Вы заблокированы',
      USER_DEMO_ACCOUNT: 'Недоступно для аккаунтов бронзового и демонстрационного типа',
      USER_ITEM_ALREADY_IN_TRADE: 'Этот предмет уже находится в обмене',
      USER_ITEM_BANNED: 'Этот предмет временно заблокирован',
      USER_ITEM_NOT_ALLOWED: 'Этот предмет помечен недоступным в нашей системе',
      USER_ITEM_NOT_FOUND: 'Предмет не найден',
      USER_ITEM_PRICE_NOT_FOUND: 'Предмет отсутствует',
      USER_ITEM_PRICE_TOO_HIGH: 'Цена предмета слишком высокая',
      USER_ITEM_PRICE_TOO_LOW: 'Цена предмета слишком низкая',
      USER_ITEM_PROCESSING_WAIT: 'Пожалуйста, подождите, ваш запрос находится в обработке',
      USER_ITEM_TRADE_LOCK: 'Предмет имеет блокировку для обмена, пожалуйста, попробуйте другой',
      USER_LOCKED: 'Вы временно заблокированы в системе P2P',
      USER_PAYOUT_LOCKED:
        'Ваш аккаунт не может выполнить вывод этой суммы. Пожалуйста, свяжитесь с технической поддержкой',
      USER_SKINS_LOCKED: 'Ваши предметы заблокированы',
    },
    INVENTORY: {
      WITHDRAW_USER_DEMO_ACCOUNT:
        'Пополните свой баланс любой суммой, чтобы иметь возможность выполнить вывод',
    },
    INVENTORY_SERVICE: {
      WRONG_ACCOUNT_TYPE_BRONZE: 'Для открытия кейса вам нужен аккаунт серебряного уровня',
    },
    CASES: {
      NOT_FOUND: 'Кейс отключен, пожалуйста, попробуйте позже',
      QUEST_REWARD: {
        IDX_ALREADY_PASSED: 'Мишень уже застрелена',
        NO_AVAILABLE_SHOTS: 'Выстрел по этой мишени уже был совершен',
        OPEN_NOT_ENOUGH_COINS: 'У вас недостаточно билетов или опыта для совершения выстрела.',
        RATE_LIMIT: 'Слишком много запросов, пожалуйста попробуйте позже.',
      },
    },
  },
  marketplace: {
    'empty-layouts': {
      message_bids: 'Покупка предметов!',
      'sub message_bids': 'Вы можете купить предметы в нашем магазине.',
      message_failure: 'Сервис недоступен',
      'sub message_failure': 'Что-то пошло не так. Попробуйте обновить страницу или зайти позже.',
      message_found: 'Поиск не дал результатов.',
      'sub message_found': 'По вашему запросу ничего не найдено.',
      'message_publication-found': 'Продажа предметов!',
      'sub message_publication-found': 'Выберите предметы, которые вы хотите продать.',
    },
  },
};
