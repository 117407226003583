export default {
  '/settings': 'Einstellungen',
  '/balance': 'Kontostand',
  '/marketplace': 'Markt',
  '/notifications': 'Benachrichtigungen',
  '/auth': 'Erl',

  RULES: 'REGELN',
  GAME: 'SPIEL',
  Top: 'Beste',
  HISTORY: 'VERLAUF',
};
