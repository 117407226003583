import { Reducer } from 'redux';
import Immutable from 'immutable';
import {
  fastInventoryActions,
  FastInventoryActionTypes,
  FastInventoryRecord,
  IFastInventoryRecord,
  IRefreshFastInventory,
  IUpdateFastInventoryFilter,
  MergeType,
  userActionsTypes,
} from '../interfaces';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, debounceTime, map, pluck, switchMap } from 'rxjs/operators';
import { UserGofastInventory } from '../repositories';
import { forkJoin, of } from 'rxjs';
import { getUserInventoryFilters } from '../selectors';

export const fast: Reducer<Immutable.Record<IFastInventoryRecord>, fastInventoryActions> = (
  state = new FastInventoryRecord(),
  action
) => {
  switch (action.type) {
    case FastInventoryActionTypes.FETCH_FAST_INVENTORY: {
      return state.set('loading', true).set('loaded', false).set('failure', false);
    }

    case FastInventoryActionTypes.FETCH_FAST_INVENTORY_SUCCESS: {
      const { info, items, merge } = action.payload;
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('info', info)
        .update('items', origin => (merge === MergeType.APPEND ? [...origin, ...items] : items));
    }

    case FastInventoryActionTypes.UPDATE_FAST_INVENTORY_FILTER: {
      const { key, value } = action.payload;

      if (key[0] === 'sortBy') {
        return state
          .set('loading', true)
          .set('loaded', false)
          .set('failure', false)
          .setIn(['filters', ...key], value)
          .setIn(['filters', 'page', 'number'], 1);
      } else {
        return state
          .set('loading', true)
          .set('loaded', false)
          .set('failure', false)
          .setIn(['filters', ...key], value);
      }
    }

    case FastInventoryActionTypes.REFRESH_FAST_INVENTORY: {
      return new FastInventoryRecord({
        loading: true,
        loaded: false,
        failure: false,
      });
    }

    default: {
      return state;
    }
  }
};

export const fetchGofastInventory = () => ({
  type: FastInventoryActionTypes.FETCH_FAST_INVENTORY,
});

const updateInventorySuccess = (
  items: IFastInventoryRecord['items'],
  info: IFastInventoryRecord['info'],
  merge: MergeType
) => ({
  type: FastInventoryActionTypes.FETCH_FAST_INVENTORY_SUCCESS,
  payload: {
    info,
    items,
    merge,
  },
});

export const updateFastInventoryFilter = (key: string[], value: any, merge: MergeType) => ({
  type: FastInventoryActionTypes.UPDATE_FAST_INVENTORY_FILTER,
  payload: {
    key,
    value,
    merge,
  },
});

export const refreshFastInventory = () => ({
  type: FastInventoryActionTypes.REFRESH_FAST_INVENTORY,
  payload: {
    merge: MergeType.RESET,
  },
});

const loader: Epic = (action$, store$) =>
  action$.pipe(
    ofType<any | IUpdateFastInventoryFilter | IRefreshFastInventory>(
      userActionsTypes.LOGIN,
      FastInventoryActionTypes.UPDATE_FAST_INVENTORY_FILTER,
      FastInventoryActionTypes.REFRESH_FAST_INVENTORY
    ),
    debounceTime(700),
    map(({ payload }) => ({
      params: getUserInventoryFilters(store$.value),
      merge: payload?.merge,
    })),
    switchMap(({ params, merge }) =>
      forkJoin({
        items: UserGofastInventory.fetchInventory(params).pipe(
          pluck('response', '730'),
          catchError(() => of([]))
        ),
        info: UserGofastInventory.fetchInventoryInfo().pipe(
          map(({ response }) => response.find(({ appId }: any) => appId === 730)),
          catchError(() => of({ itemsCount: 0, itemsSum: 0 }))
        ),
      }).pipe(map(({ items, info }) => updateInventorySuccess(items, info, merge)))
    )
  );

export const gofastEpic = combineEpics(loader);
