import styled from 'styled-components';

export const Container = styled.div`
  & .close {
    font-weight: normal;
    position: absolute;
    right: 1rem;
    text-transform: none;
    top: 0.625rem;
  }
`;
