import { IModule } from 'core/rootInterfaces/root.interface';
import './locales';

export { MarketplaceRouter } from './components';
// export const MarketplaceRouter = lazy(() =>
//   import('./components').then(module => ({ default: module.MarketplaceRouter }))
// )
export const marketplace: IModule = {
  moduleName: 'marketplace',
  moduleId: 33,
  path: '/market',
  reducer: null,
};
