import styled from 'styled-components';
import { Button } from '@components/Common';
import { getJokerColors } from 'games/CaseBattle/utils';

export const Container = styled.div<{ isUp: boolean }>`
  animation: ${({ isUp }) => (isUp ? 'move-up-icons 300ms forwards' : 'none')};
  backdrop-filter: blur(5px);
  bottom: 0;
  display: flex;
  height: 144px;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 6;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  background: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 15px 16px;
  width: 100%;

  @keyframes move-up-icons {
    0% {
      opacity: 0.5;
      pointer-events: none;
      transform: translate(0, 100px);
    }

    99% {
      pointer-events: none;
    }

    100% {
      opacity: 1;
      pointer-events: auto;
      transform: translate(0, 0);
    }
  }
`;

export const ReplayWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`;

export const StyledButton = styled(Button)<{ isJoker?: boolean }>`
  flex-shrink: 1;
  text-transform: inherit;
  ${({ isJoker }) => getJokerColors(isJoker)};
`;

export const ReplayButton = styled.div`
  background: var(--color-dark-200);
  border-radius: 10px;
  height: 48px;
  padding: 12px;
  width: 48px;

  & > svg {
    path {
      fill: var(--color-white);
    }
  }
`;

export const BackBtn = styled(Button)`
  text-transform: initial;
`;
