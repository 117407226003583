import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import {
  ActionRequestCaseBattle,
  ActionRequestCasesByIds,
  AddBotAction,
  CaseBattleGameActions,
  CasesByIdsActions,
  CasesByIdsReducer,
  InitStateCaseBattleGame,
  JoinToGameAction,
  AddEmojiAction,
  IEmojis,
  EmojiResponse,
  InitStateCaseBattleGameReplay,
  CaseBattleGameReplayActions,
} from './case-battle-game.interfaces';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import caseBattleRepository from 'games/CaseBattle/repository/caseBattleRepository';
import { iif, of } from 'rxjs';
import { addNotification } from 'modules/Notifications/duck';
import { ResponseBattleById } from '../case-battle-duck';
import { Reducer } from 'redux';
import { Record } from 'immutable';
import { GameStatus } from 'games/CaseBattle/constants';
import { SuitCaseData } from 'games/CaseGame/interfaces';
import { getRandomEmojiShift } from 'games/CaseBattle/utils';
import { GameMember } from 'games/CaseBattle/interfaces';

export const actionRequestCaseBattle = ({
  battleId,
  isRecreate = false,
}: {
  battleId: number;
  isRecreate?: boolean;
}) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE,
  payload: { battleId, isRecreate },
});

export const actionRemoveBattleGameData = () => ({
  type: CASE_BATTLE_ACTION_TYPE.REMOVE_DATA_BATTLE_GAME,
});

export const actionRequestCasesByIds = (ids: number[]) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_ADD_CASES_BY_IDS,
  payload: ids,
});

export const actionResponseCasesByIds = (data: SuitCaseData[]) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_ADD_CASES_BY_IDS,
  payload: data,
});

export const actionRemoveData = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_CASES_BY_IDS_DATA,
});

export const actionResponseCaseBattle = (data: ResponseBattleById) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE,
  payload: data,
});

export const addBotAction = ({ battleId, team }: { battleId: number; team?: number }) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ADD_BOT,
  payload: { battleId, team },
});

export const joinToGameAction = (battleId: number, team: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_JOIN_TO_GAME,
  payload: { battleId, team },
});

export const addEmojiAction = (battleId: number, emojiId: number) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ADD_EMOJI,
  payload: { battleId, emojiId },
});

export const actionResponseEmojiAwaiting = (isAwaiting: boolean) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJIS_AWAITING,
  payload: isAwaiting,
});

export const actionCaseBattleReplay = ({ isReplay }: { isReplay: boolean }) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_CASE_BATTLE_REPLAY,
  payload: { isReplay },
});

export const getCasesByIdsEpic: Epic = (action$: ActionsObservable<ActionRequestCasesByIds>) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_ADD_CASES_BY_IDS),
    switchMap(({ payload }) =>
      caseBattleRepository.getCasesByIds(payload).pipe(
        map(({ response }: { response: SuitCaseData[] }) => actionResponseCasesByIds(response)),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );

export const joinToGameEpic: Epic = (action$: ActionsObservable<JoinToGameAction>) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_JOIN_TO_GAME),
    switchMap(({ payload: { battleId, team } }) =>
      caseBattleRepository.joinGame(battleId, team).pipe(
        map(() =>
          addNotification({
            type: 'success',
            body: 'joinSuccess',
          })
        ),
        catchError(({ response }) =>
          of(
            addNotification({
              type: 'error',
              body: response?.message,
            })
          )
        )
      )
    )
  );

export const addBotEpic: Epic = (action$: ActionsObservable<AddBotAction>) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_ADD_BOT),
    switchMap(({ payload }) =>
      caseBattleRepository.addBot(payload).pipe(
        map(() =>
          addNotification({
            type: 'success',
            body: 'botAdded',
          })
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );

export const addEmojiEpic: Epic = (action$: ActionsObservable<AddEmojiAction>) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_ADD_EMOJI),
    switchMap(({ payload: { battleId, emojiId } }) =>
      caseBattleRepository.addEmoji(battleId, emojiId).pipe(
        mergeMap(({ response }: { response: EmojiResponse }) =>
          iif(() => !response.success, of(actionResponseEmojiAwaiting(false)))
        ),
        catchError(({ response }) =>
          of(
            addNotification({
              type: 'error',
              body: response?.message,
            })
          )
        )
      )
    )
  );

export const caseBattleGameEpic: Epic = (action$: ActionsObservable<ActionRequestCaseBattle>) =>
  action$.pipe(
    ofType(CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE),
    switchMap(({ payload: { battleId, isRecreate } }) =>
      caseBattleRepository.getCaseBattleById(battleId).pipe(
        switchMap(({ response }: { response: ResponseBattleById }) =>
          iif(
            () => isRecreate,
            of(actionResponseCaseBattle(response)),
            of(actionResponseCaseBattle(response), actionRequestCasesByIds(response.game.cases))
          )
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page.',
            })
          )
        )
      )
    )
  );

const InitState = Record<InitStateCaseBattleGame>({
  isLoading: true,
  data: {
    gameStatus: GameStatus.waiting,
    game: null,
    currentRound: 0,
    isEmojiAwaiting: false,
  },
});

export const caseBattleGameReducer: Reducer<
  Record<InitStateCaseBattleGame>,
  CaseBattleGameActions
> = (state = new InitState(), action) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.REMOVE_DATA_BATTLE_GAME: {
      return new InitState();
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_CASE_BATTLE: {
      return state.set('isLoading', true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJIS_AWAITING: {
      return state.setIn(['data', 'isEmojiAwaiting'], action.payload);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJI_SOCKET: {
      const { count, emojiId, userId } = action.payload;

      if (
        state.hasIn(['data', 'game', 'emoji']) &&
        state.getIn(['data', 'game', 'id']) === action.payload.battleId
      ) {
        return state
          .updateIn(['data', 'game', 'emoji'], emoji => {
            return {
              ...emoji,
              [emojiId]: {
                ...emoji[emojiId],
                id: emojiId,
                count,
                ts: [...(emoji[emojiId]?.ts ?? []), Date.now()],
                shift: [...(emoji[emojiId]?.shift ?? []), getRandomEmojiShift()],
                userIds: [...(emoji[emojiId]?.userIds ?? []), userId],
                isDateNow: true,
              },
            };
          })
          .setIn(['data', 'isEmojiAwaiting'], false);
      }

      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJIS_SOCKET: {
      if (
        action.payload?.state?.emojis &&
        state.hasIn(['data', 'game', 'emoji']) &&
        state.getIn(['data', 'game', 'id']) === action.payload.battleId
      ) {
        return state.setIn(
          ['data', 'game', 'emoji'],
          Object.entries(action.payload.state.emojis).reduce((acc, [key, value]) => {
            acc[key] = {
              ...value,
              shift: value?.ts.map(() => getRandomEmojiShift()),
            };

            return acc;
          }, {} as IEmojis)
        );
      }

      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE: {
      const status = action.payload.game.finished_at
        ? GameStatus.finish
        : action.payload.game.players_count !== action.payload.game.players.length
        ? GameStatus.waiting
        : action.payload.game.rounds.length > 0
        ? GameStatus.roll
        : GameStatus.start;

      return state
        .setIn(['data', 'game'], action.payload.game)
        .setIn(
          ['data', 'currentRound'],
          status === GameStatus.roll
            ? action.payload.game.rounds[action.payload.game.rounds.length - 1]?.roundNumber
            : 0
        )
        .setIn(['data', 'gameStatus'], status)
        .setIn(
          ['data', 'game', 'emoji'],
          Object.entries(action.payload.game.emoji).reduce((acc, [key, value]) => {
            acc[key] = {
              ...value,
              shift: value?.ts.map(() => getRandomEmojiShift()),
            };

            return acc;
          }, {} as IEmojis)
        )
        .set('isLoading', false);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_JOIN_NEW_BATTLE: {
      if (state.getIn(['data', 'game'])?.id === action.payload.data.battleId) {
        const countActivePlayer = state.getIn(['data', 'game'])?.players.length;
        const maxPlayersInGame = state.getIn(['data', 'game', 'players_count']);
        const newPlayer = action.payload.data.player;

        const filteredData: GameMember[] =
          ([...state.getIn(['data', 'game', 'players'])] as GameMember[]).filter(
            ({ user_id }) => newPlayer.user_id !== user_id
          ) ?? [];
        //NOTE: logic for deleting possible duplicates
        const playersData = [...filteredData, action.payload.data.player];
        //NOTE: combine actual players
        return state
          .setIn(['data', 'game', 'players'], playersData)
          .setIn(
            ['data', 'gameStatus'],
            maxPlayersInGame === countActivePlayer ? GameStatus.start : GameStatus.waiting
          );
      }
      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL: {
      if (state.getIn(['data', 'game'])?.id === action.payload.data[0].battleId) {
        return state
          .mergeIn(['data', 'game', 'rounds'], action.payload.data)
          .setIn(
            ['data', 'currentRound'],
            action.payload.data[action.payload.data.length - 1].roundNumber
          )
          .setIn(['data', 'gameStatus'], GameStatus.roll);
      }
      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_FINISH: {
      if (state.getIn(['data', 'game'])?.id === action.payload.data.battleId) {
        return state
          .setIn(['data', 'game', 'prizes'], action.payload.data.prizes)
          .setIn(['data', 'game', 'total_winning'], action.payload.data.totalWinning)
          .setIn(['data', 'game', 'winner_ids'], action.payload.data.winnerIds)
          .setIn(['data', 'game', 'finished_at'], new Date(action.payload.data.finished_at))
          .setIn(['data', 'game', 'started_at'], action.payload.data.battle.started_at)
          .setIn(['data', 'gameStatus'], GameStatus.finish)
          .setIn(['data', 'currentRound'], 0);
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const InitStateCasesByIds = Record<CasesByIdsReducer>({
  isLoading: true,
  data: [],
});

export const casesByIdsReducer: Reducer<Record<CasesByIdsReducer>, CasesByIdsActions> = (
  state = new InitStateCasesByIds(),
  action
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_REQUEST_ADD_CASES_BY_IDS: {
      return state.set('isLoading', true);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_ADD_CASES_BY_IDS: {
      return state.set('data', action.payload).set('isLoading', false);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_CASES_BY_IDS_DATA: {
      return new InitStateCasesByIds();
    }
    default: {
      return state;
    }
  }
};

const InitStateRepeat = Record<InitStateCaseBattleGameReplay>({
  isLoading: false,
  data: {
    gameStatus: GameStatus.waiting,
    game: null,
    currentRound: 0,
    isEmojiAwaiting: false,
  },
  isReplay: false,
});

export const caseBattleGameReplayReducer: Reducer<
  Record<InitStateCaseBattleGameReplay>,
  CaseBattleGameReplayActions
> = (state = new InitStateRepeat(), action) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.REMOVE_DATA_BATTLE_GAME: {
      return new InitStateRepeat();
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJIS_AWAITING: {
      return state.setIn(['data', 'isEmojiAwaiting'], action.payload);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJI_SOCKET: {
      const { count, emojiId } = action.payload;

      if (
        state.hasIn(['data', 'game', 'emoji']) &&
        state.getIn(['data', 'game', 'id']) === action.payload.battleId
      ) {
        return state
          .updateIn(['data', 'game', 'emoji'], emoji => {
            return {
              ...emoji,
              [emojiId]: {
                ...emoji[emojiId],
                id: emojiId,
                count,
                ts: [...(emoji[emojiId]?.ts ?? []), Date.now()],
                shift: [...(emoji[emojiId]?.shift ?? []), getRandomEmojiShift()],
                isDateNow: true,
              },
            };
          })
          .setIn(['data', 'isEmojiAwaiting'], false);
      }

      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_CASE_BATTLE_REPLAY: {
      return state
        .set('isReplay', action.payload.isReplay)
        .setIn(['data', 'currentRound'], 0)
        .setIn(['data', 'game', 'rounds'], [])
        .setIn(['data', 'gameStatus'], GameStatus.waiting);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_EMOJIS_SOCKET: {
      if (
        action.payload?.state?.emojis &&
        state.hasIn(['data', 'game', 'emoji']) &&
        state.getIn(['data', 'game', 'id']) === action.payload.battleId
      ) {
        return state.setIn(
          ['data', 'game', 'emoji'],
          Object.entries(action.payload.state.emojis).reduce((acc, [key, value]) => {
            acc[key] = {
              ...value,
              shift: value?.ts.map(() => getRandomEmojiShift()),
            };

            return acc;
          }, {} as IEmojis)
        );
      }

      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE: {
      return state
        .setIn(['data', 'game'], action.payload.game)
        .setIn(
          ['data', 'game', 'emoji'],
          Object.entries(action.payload.game.emoji).reduce((acc, [key, value]) => {
            acc[key] = {
              ...value,
              shift: value?.ts.map(() => getRandomEmojiShift()),
            };

            return acc;
          }, {} as IEmojis)
        )
        .set('isLoading', false);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_JOIN_NEW_BATTLE: {
      if (state.getIn(['data', 'game'])?.id === action.payload.data.battleId) {
        const countActivePlayer = state.getIn(['data', 'game'])?.players.length;
        const maxPlayersInGame = state.getIn(['data', 'game', 'players_count']);
        const newPlayer = action.payload.data.player;

        const filteredData: GameMember[] =
          ([...state.getIn(['data', 'game', 'players'])] as GameMember[]).filter(
            ({ user_id }) => newPlayer.user_id !== user_id
          ) ?? [];
        //NOTE: logic for deleting possible duplicates
        const playersData = [...filteredData, action.payload.data.player];
        //NOTE: combine actual players
        return state
          .setIn(['data', 'game', 'players'], playersData)
          .setIn(
            ['data', 'gameStatus'],
            maxPlayersInGame === countActivePlayer ? GameStatus.start : GameStatus.waiting
          );
      }
      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL_REPLAY: {
      if (state.getIn(['data', 'game'])?.id === action.payload.data[0].battleId) {
        return state
          .mergeIn(['data', 'game', 'rounds'], action.payload.data)
          .setIn(['data', 'gameStatus'], GameStatus.roll)
          .updateIn(['data', 'currentRound'], currentRound => {
            return currentRound < state.getIn(['data', 'game', 'cases']).length
              ? currentRound + 1
              : currentRound;
          });
      }
      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_FINISH_REPLAY: {
      if (state.getIn(['data', 'game'])?.id === action.payload.battleId) {
        return state.setIn(['data', 'gameStatus'], GameStatus.finish).set('isReplay', false);
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
