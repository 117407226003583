import React from 'react';
import { Close, HeaderContainer, Title } from './header.styles';
import { useDialog } from 'core/hooks';
import { CaseItemsDialogConfig } from 'games/CaseGame';

interface HeaderProps {
  title: string;
  buttonText: string;
}

export const Header: React.FC<HeaderProps> = ({ title, buttonText }) => {
  const { handleCloseDialog } = useDialog(CaseItemsDialogConfig.LAST_REVISION);

  return (
    <HeaderContainer>
      <Close onClick={handleCloseDialog}>{buttonText}</Close>
      <Title>{title}</Title>
    </HeaderContainer>
  );
};
