import styled from 'styled-components';

export const Container = styled.div`
  background: var(--bonus-blue);
  position: sticky;
  width: 100%;
  height: 54px;
  display: flex;
  gap: 16px;
  bottom: 0;
  padding: 14px 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.286px;
  text-align: left;
`;

export const Text = styled(Title)`
  font-weight: 400;
`;
