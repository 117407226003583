import * as React from 'react';
import styled from 'styled-components';
import { AppBar } from '@components/bars';
import { useSelectRaffleHistoryFacade } from '../../hooks';
import { HistoryLot } from '../../components/@shared';
import { Balance } from '../Balance';

export const RaffleHistoryPage = () => {
  const { history } = useSelectRaffleHistoryFacade();

  return (
    <>
      <AppBar deep prependWidget={Balance} />
      <div className="content">
        <Container>
          {history && history.items.map((item: any) => <HistoryLot key={item.id} {...item} />)}
        </Container>
      </div>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding: 1rem;
`;
