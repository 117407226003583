import en from './en.js';
import ru from './ru.js';
import de from './de.js';
import pl from './pl.js';
import ro from './ro.js';
import es from './es.js';
import no from './no.js';
import pt from './pt.js';
import sv from './sv.js';
import th from './th.js';
import tr from './tr.js';
import zh_cn from './zh_cn.js';
import zh_tw from './zh_tw.js';
import { addResources } from '../../locales';

addResources({ en, ru, de, pl, ro, es, no, pt, sv, th, tr, zh_cn, zh_tw });
