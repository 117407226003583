import { List } from 'immutable';

export interface IInitStateToken {
  success: boolean | null;
  token: string | null;
}

export const initStateToken: IInitStateToken = {
  success: null,
  token: null,
};

export interface IMatches {
  id: string;
  count: number;
}

export interface IHistory {
  matches: IMatches[];
  userId: number;
  username: string;
  avatar: string;
  bet: number;
  profit: number;
  bonus: boolean;
}

export interface IHistories {
  loading: boolean;
  loaded: boolean;
  recent: List<IHistory>;
  own: List<IHistory>;
}
