import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './empty-layout.styles';

interface IFailureLayout {
  ctx: string;
  namespace?: string;
  subMessage?: string;
  fieldKey?: string;
}

export const EmptyLayout: FC<IFailureLayout> = ({ ctx, namespace, subMessage, fieldKey }) => {
  const { t } = useTranslation(namespace);

  const localesPath = fieldKey ? `${fieldKey}.empty-layouts.message` : 'empty-layouts.message';
  const localesPathSub = fieldKey
    ? `${fieldKey}.empty-layouts.sub message `
    : 'empty-layouts.sub message';

  return (
    <Container>
      <span className="smile">:(</span>
      <span className="message">
        {t(localesPath, {
          context: ctx,
        })}
      </span>
      <span className="sub-message">
        {t(subMessage ?? localesPathSub, {
          context: ctx,
        })}
      </span>
    </Container>
  );
};
