export default {
  //CryptoPage title
  crypto: 'Retirada de criptomoedas',
  cryptocurrency: 'Criptomoeda',
  from: 'a partir de',
  //
  'Email is required': 'É necessário um e-mail',
  Withdraw: 'Retirar',
  'Withdrawal sum': 'Soma de retirada',
  'Amount in {{title}}': 'Quantidade em $t({{title}}):',
  'Receive in {{title}}': 'Receber em $t({{title}}):',
  'You will receive:': 'Você receberá:',
  'Select the network fee': 'Selecione a taxa de rede',
  'Commission:': 'Comissão:',
  'Ultra Low': 'Muito devagar',
  Low: 'Lenta',
  Medium: 'Padrão',
  High: 'Rápida',
  'No commission': 'Sem comissão',
  commission: 'Possível comissão',
};
