import { Reducer } from 'redux';
import Immutable from 'immutable';
import { Epic, ofType } from 'redux-observable';
import {
  FiltersActionTypes,
  HotOffersRecord,
  IFiltersRecord,
  IHotOffers,
  IMarketplaceElement,
  IRefreshStore,
  IStoreFailureResponse,
  IStoreResponse,
  IUpdateFilters,
  MarketplaceActionTypes,
  storeActions,
  StoreActionTypes,
  StoreRecord,
} from '../interfaces';
import { catchError, debounceTime, map, pluck, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StoreRepository } from '../repositories';
import { getStoreFilters } from '../selector';
import { OrderStatusEnum } from '../configs';

export const store: Reducer<Immutable.Record<any>, storeActions> = (
  state = new StoreRecord(),
  action
) => {
  switch (action.type) {
    case FiltersActionTypes.CLEANUP_FILTERS:
    case FiltersActionTypes.UPDATE_FILTERS: {
      return state.set('loading', true).set('loaded', false).set('failure', false);
    }

    case StoreActionTypes.FETCH_STORE_ITEMS_SUCCESS: {
      const { meta: metaWithPack, kits: kitsWithPack } = action.payload;

      const kits = kitsWithPack.filter((item: IMarketplaceElement) => item.items?.length < 2);

      const meta = {
        ...metaWithPack,
        limit: kits?.length,
      };

      const kitsState = state.getIn(['kits']);
      const ids = meta.offset ? kitsState.ids : [];
      const entities = meta.offset ? kitsState.entities : {};

      return state
        .set('loading', false)
        .set('loaded', true)
        .set('meta', meta)
        .set(
          'kits',
          kits?.reduce(
            (acc, item) => ({
              ids: [...acc['ids'], item.id.toString()],
              entities: { ...acc.entities, [item.id]: item },
            }),
            { ids, entities }
          )
        );
    }

    case StoreActionTypes.FETCH_STORE_ITEMS_FAILURE: {
      return state.set('failure', true);
    }

    case StoreActionTypes.FETCH_STORE_COUNT: {
      return state.setIn(['meta', 'amount'], action.payload.count);
    }

    case StoreActionTypes.REFRESH_STORE_ITEMS: {
      return state
        .set('loading', true)
        .set('loaded', false)
        .set('meta', {
          amount: 10,
          limit: 0,
          offset: 0,
        })
        .set('items', {
          ids: [],
          entities: {},
        });
    }

    case MarketplaceActionTypes.MARKETPLACE_PARTICIPANT_UPDATE: {
      const { id, ...rest } = action.payload;
      const identifier = id.toString();
      const item = state?.getIn(['kits', 'entities', identifier]);

      if (state.hasIn(['kits', 'entities', identifier])) {
        return state.mergeIn(['kits', 'entities', identifier], {
          ...rest,
          status:
            (item.status === OrderStatusEnum.WAIT_FOR_TRADE &&
              rest.status === OrderStatusEnum.ORDER_CRATED) ||
            !rest.status
              ? item.status
              : rest.status,
        });
      }
      return state;
    }

    case MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_DELETED:
    case MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_UPDATE: {
      const { items } = action.payload;
      return items.reduce(
        (acc, item) =>
          acc.hasIn(['kits', 'entities', item.id.toString()])
            ? state.mergeIn(['kits', 'entities', item.id.toString()], item)
            : acc,
        state
      );
    }

    default: {
      return state;
    }
  }
};

export const hotOffers: Reducer<Immutable.Record<IHotOffers>, storeActions> = (
  state = new HotOffersRecord(),
  action
) => {
  switch (action.type) {
    case MarketplaceActionTypes.MARKETPLACE_HOT_OFFERS_UPDATE: {
      const { kits, count } = action.payload;

      return state.set('count', count ?? 0).set(
        'kits',
        kits.reduce(
          (acc, item: IMarketplaceElement) => ({
            ids: [...acc['ids'], item.id],
            entities: { ...acc.entities, [item.id]: item },
          }),
          { ids: [], entities: {} }
        )
      );
    }

    case MarketplaceActionTypes.MARKETPLACE_PARTICIPANT_UPDATE: {
      const { id, ...rest } = action.payload;
      const identifier = id.toString();
      const item = state?.getIn(['kits', 'entities', identifier]);

      if (state.hasIn(['kits', 'entities', identifier])) {
        return state.mergeIn(['kits', 'entities', identifier], {
          ...rest,
          status:
            (item.status === OrderStatusEnum.WAIT_FOR_TRADE &&
              rest.status === OrderStatusEnum.ORDER_CRATED) ||
            !rest.status
              ? item.status
              : rest.status,
        });
      }
      return state;
    }

    case MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_UPDATE: {
      const { items } = action.payload;

      return items.reduce(
        (acc, item) =>
          acc.hasIn(['kits', 'entities', item.id.toString()])
            ? state.mergeIn(['kits', 'entities', item.id.toString()], item)
            : acc,
        state
      );
    }

    default: {
      return state;
    }
  }
};

const updateStoreItems = ({ meta, kits }: IStoreResponse) => ({
  type: StoreActionTypes.FETCH_STORE_ITEMS_SUCCESS,
  payload: {
    meta,
    kits,
  },
});

// const updateCountItem = ({ count }: { count: number }) => ({
//   type: StoreActionTypes.FETCH_STORE_COUNT,
//   payload: {
//     count,
//   },
// });

const updateStoreItemsFailure = (data: IStoreFailureResponse) => ({
  type: StoreActionTypes.FETCH_STORE_ITEMS_FAILURE,
  payload: data,
});

export const refreshStore = () => ({
  type: StoreActionTypes.REFRESH_STORE_ITEMS,
});

export const storeLoader: Epic = (action$, store$) =>
  action$.pipe(
    ofType<IRefreshStore | IUpdateFilters>(
      StoreActionTypes.REFRESH_STORE_ITEMS,
      FiltersActionTypes.UPDATE_FILTERS
    ),
    debounceTime(700),
    map(() => getStoreFilters(store$.value).toObject()),
    switchMap(({ overprice, ...restParams }: IFiltersRecord) => {
      const updatedParams = {
        ...restParams,
        minOverprice: overprice.minOverprice,
        maxOverprice: overprice.maxOverprice,
        mobile: true,
      };
      return StoreRepository.fetchStore(updatedParams).pipe(
        pluck('response'),
        map(p => updateStoreItems(p)),
        catchError(e => of(updateStoreItemsFailure(e)))
      );
    })
  );

//TODO: add when BE will be updated

// export const storeLoader: Epic = (action$, store$) =>
//   action$.pipe(
//     ofType<IRefreshStore | IUpdateFilters>(
//       StoreActionTypes.REFRESH_STORE_ITEMS,
//       FiltersActionTypes.UPDATE_FILTERS
//     ),
//     debounceTime(700),
//     map(() => getStoreFilters(store$.value).toObject()),
//     switchMap(({ overprice, ...restParams }: IFiltersRecord) => {
//       const updatedParams = {
//         ...restParams,
//         minOverprice: overprice.minOverprice,
//         maxOverprice: overprice.maxOverprice,
//       };
//       return forkJoin({
//         storeItems: StoreRepository.fetchStore(updatedParams),
//         countItems: StoreRepository.getStoreCount(updatedParams),
//       }).pipe(
//         switchMap(({ storeItems, countItems }) =>
//           of(updateStoreItems(storeItems.response), updateCountItem(countItems.response))
//         ),
//         catchError(e => of(updateStoreItemsFailure(e)))
//       );
//     })
//   );
