import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
`;

export const GameInfoContainer = styled.div`
  display: flex;
  gap: 13px;
  flex-direction: column;
  width: 100%;
`;

export const GameStatusInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Span = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

export const GrayColorSpan = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: var(--color-gray-200);
  line-height: 18px;
`;

export const WaitingWrapper = styled.div`
  display: flex;
  gap: 6px;
`;
export const StartWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const RoundSpan = styled(Span)`
  color: var(--yellow);
  display: flex;
  gap: 3px;

  & > * {
    font-weight: 700;
  }
`;

export const TotalSpentContainer = styled.div<{ isJoker: boolean }>`
  display: flex;
  gap: 6px;
  color: ${({ isJoker }) => (isJoker ? 'var(--pink-100)' : 'var(--yellow)')};
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
