export const listToMatrix = (list: number[], elementsPerSubArray: number): number[][] => {
  let matrix = [],
    i,
    k;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(list[i]);
  }
  return matrix;
};

export const randomSlotList = (length: number): number[] =>
  Array.from({ length }, () => Math.floor(7 * Math.random()));

export const lines: number[] = [6, 2, 4, 9, 1, 8, 5, 3, 7];

export const linesColor: string[] = [
  '#f5f800',
  '#f20200',
  '#e6aa02',
  '#9ff78b',
  '#28adca',
  '#f4f6fc',
  '#8bf7fd',
  '#29b81e',
  '#f540f7',
];

export const slotType: string[] = [
  'slot-1', //# 0
  'slot-2', //# 1
  'slot-3', //# 2
  'slot-4', //# 3
  'slot-5', //# 4
  'slot-6', //# 5
  'slot-7', //# 6
  'slot-bonus-3', //# 9 ???
  'slot-bonus-2', //# 8
  'slot-bonus-1', //# 7 ???
  'slot-scatter', //#10
  'slot-joker', //# 11
  'slot-bonus', // 12
];
