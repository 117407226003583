import { FC } from 'react';
import { transitions } from './transition.config';

export interface IDialog {
  component: FC<any>;
  transition: transitions;
  anchor: string;
  class?: string;
}

const dialogCombiner = () => {
  const d = new Map();

  return {
    add: (name: string, dialog: IDialog) => d.set(name, dialog),
    has: (name: string): boolean => d.has(name),
    get: (name: string): IDialog => d.get(name),
  };
};

export const DIALOG = dialogCombiner();
