import styled from 'styled-components';

export const Container = styled.div<{ color: string }>`
  align-items: center;
  animation: open 300ms ease-in-out;
  background: ${({ color }) =>
    color
      ? `linear-gradient(180deg, var(--color-dark-200) 0%, ${color} 57.81%, ${color} 100%)`
      : 'var(--color-dark-200)'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 6px 6px 8px;
  position: relative;
  max-width: 80px;
  max-height: 148px;

  @media screen and (max-width: 374px) {
    width: 66px;
    height: 120px;
  }

  @keyframes open {
    0% {
      opacity: 0;
      transform: rotateX(90deg);
    }

    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
`;

export const Item = styled.div<{ isJoker: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > svg {
    & > path {
      fill: ${({ isJoker }) => (isJoker ? 'var(--orange-400)' : 'var(--green-300)')};
    }
  }

  & > .clover {
    left: 5px;
    position: absolute;
    top: 6px;
  }

  & > .arrow_top {
    animation: opacity 900ms ease-in-out infinite;
    position: absolute;
    right: 6px;
    top: 9px;
  }

  & > .arrow_bottom {
    animation: opacity 900ms 300ms ease-in-out infinite;
    position: absolute;
    right: 6px;
    top: 14px;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Exterior = styled.span`
  color: var(--color-gray-200);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: 11px;
  padding-top: 1.6px;
  text-align: center;
  text-transform: uppercase;
`;

export const Image = styled.img`
  padding: 5px 0;
`;

export const ItemShortName = styled.span`
  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 14px;
  overflow: hidden;
  padding-bottom: 3px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &.hasStatTrak {
    color: var(--orange-100);
  }

  &.hasSouvenir {
    color: var(--yellow-100);
  }
`;

export const ItemSkin = styled.span`
  color: var(--color-gray-200);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: 11px;
  overflow: hidden;
  padding-bottom: 4px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Price = styled.div`
  display: flex;
  gap: 4px;
`;

export const ItemPrice = styled.span`
  color: var(--yellow);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 14px;
  text-align: center;
`;
