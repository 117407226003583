import { CaseListItemDefault } from '@components/CaseListItemDefault';
import { useDialog } from 'core/hooks';
import { CaseData } from 'games/CaseGame/interfaces';
import React, { useCallback } from 'react';
import { Container, Wrapper } from './case-list-item.styles';
import { AddCasesConfig } from 'games/CaseBattle/dialog-config';
import { useGetIdsForCreateBattle } from 'games/CaseBattle/hooks';
import { useDispatch } from 'react-redux';
import { addCase } from 'games/CaseBattle';
import { isEqual } from 'lodash';

interface CaseListItemProps {
  caseData: CaseData;
}

export const CaseListItem: React.FC<CaseListItemProps> = React.memo(({ caseData }) => {
  const { handleCloseDialog } = useDialog(AddCasesConfig.SEARCH);
  const dispatch = useDispatch();
  const { chosenCases } = useGetIdsForCreateBattle();

  const addCaseHandler = useCallback(() => {
    if (caseData.enabled) {
      handleCloseDialog();
      dispatch(addCase(caseData.id, caseData.lastRevision.price));
    }
  }, [handleCloseDialog, caseData.id, caseData.lastRevision.price, caseData.enabled, dispatch]);

  return (
    <Container>
      <CaseListItemDefault
        handler={addCaseHandler}
        caseType={caseData.type}
        free={!!caseData?.free}
        enabled={caseData.enabled}
        image={
          caseData?.free ? caseData.image ?? caseData.backgroundImage : caseData.backgroundImage
        }
        price={caseData.lastRevision.price}
      />
      {!!chosenCases[caseData.id] && <Wrapper>{chosenCases[caseData.id]}</Wrapper>}
    </Container>
  );
}, isEqual);
