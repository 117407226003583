export default {
  Cart: 'Kundvagn',
  Price: 'Pris',
  Type: 'Type',
  Categories: 'Kategorier',
  Rarity: 'Sällsynthet',
  OK: 'OK',
  Clear: 'Rensa',
  Autoselect: 'Välj automatiskt',
  Remove: 'Remove',
  Bet: 'Satsa',
  Min: 'Minimum',
  Max: 'Maximum',
  'it.': '',
  Total: 'Total',
  items: 'föremål',
  Pistols: 'Pistol',
  Rifles: 'Gevär',
  'Submachine guns': 'SMG',
  Heavy: 'Tunga',
  Knives: 'Kniv',
  Gloves: 'Handskar',
  Keys: 'Nyckel',
  Other: 'Övrigt',
  'Base Grade': 'Grundkvalitet',
  'Consumer Grade': 'Konsumentklass',
  'Industrial Grade': 'Industriklass',
  Restricted: 'Begränsad',
  'Mil-Spec Grade': 'Militärstandardklass',
  Classified: 'Klassificerat',
  'High Grade': 'Högkvalitet',
  Covert: 'Hemlig',
  Remarkable: 'Anmärkningsvärd',
  Exotic: 'Exotisk',
  Contraband: 'Kontraband',
  Extraordinary: 'Enastående',
  Buy: 'Köp',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Spara endast för uttag av vinster eller oanvända mynt. Att byta skins och tjäna på det på grund av prisskillnaden med andra marknader är strängt förbjudet. De som går emot detta kommer att portas permanent.',
  'Your purchase will be delivered within 15 minutes.':
    'Ditt köp kommer att levereras inom 15 minuter.',
  'Congratulations! You have successfully purchased': 'Grattis! Du har köpt',
  'Order confirmation': 'Bekräfta beställning',
  Filters: 'Filters',
  'By price': 'På pris',
  Filter: 'Filter',
  'Not found': 'Hittades inte',
  'At present it is unable to find any items on your request. Try again later.':
    'De begärda objekten kunde för närvarande inte hittas. Försök igen senare.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
