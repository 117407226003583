import React, { Component } from 'react';
import styled from 'styled-components';
import { getItemUri } from '../../../core/utils/image.utils';
import * as Animated from 'animated/lib/targets/react-dom';
import BezierEasing from 'bezier-easing';
import Control from './Control';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getFortuneWheelAvailability,
  getFortuneWheelItems,
  getFortuneWheelResult,
  getFortuneWheelWinnerModalStatus,
} from '../selectors';
import * as gameActions from '../duck';
import StarImg from './../../../assets/images/star.png';
import { CurrencyText } from '../../../@components/currency';
import { Prize } from '../../../@components/Prize';
import { getAppDiffTime } from 'core/AppShell/selectors';
import { getUser, getUserDetails } from 'core/User';

class Wheel extends Component {
  constructor() {
    super();
    this.animation = new Animated.Value(0);
    this.state = {
      index: 0,
      spinButtonPress: false,
    };
    this.animationWasEnded = false;
    this.animationWasStarted = false;
  }

  componentDidMount() {
    this.props.actions.getGame();
    if (this.props.result) {
      this.setState({ index: 9 });
      this.animationWasEnded = true;
      this.animation.setValue(1);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.result !== this.props.result) {
      if (this.props.result !== null && this.animationWasStarted === false) {
        this.animate(this.props.result);
      }
    }
    if (this.props.access.availability && this.animationWasEnded && !this.props.modal) {
      this.reset();
    }
  }

  render() {
    const { items, access, appDiffTime, user, userRewards, modal, result, actions } = this.props;
    const rotation = this.animation.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', `${-720 - (this.state.index - 1) * 20}deg`],
      useNativeDriver: true,
    });

    return (
      <>
        {modal && result !== null && (
          <Prize.Single
            user={user}
            result={result}
            closeModal={() => actions.toggleWinnerModal(false)}
            fetchDecision={actions.prizeDecision}
          />
        )}
        <Container>
          <div className="game-container">
            <div className="wheel-container">
              {/*<div className='wheel-wrapper'>*/}
              <AnimatedWheel style={{ transform: [{ rotate: rotation }] }}>
                <WheelRouletteWrapper>
                  {items.map((item, index) => (
                    <WheelCover
                      key={index}
                      style={{
                        transform: `translate(0, -50%) rotate(${20 * index}deg)`,
                        // transform: `translate(0, -335px) rotate(${20 * index}deg)`,
                      }}
                    >
                      <Section>
                        <PrizeContainer>
                          {item.itemImage ? (
                            <PrizeImg src={getItemUri(item.itemImage)} />
                          ) : (
                            <PrizeImg src={StarImg} />
                          )}
                          <Flex>
                            <PrizeText>
                              <CurrencyText value={item.itemPrice / 100} />
                            </PrizeText>
                          </Flex>
                        </PrizeContainer>

                        <svg viewBox="0 0 139 130" width="135" height="135">
                          <path
                            fill={'#217487'}
                            d="M116.57 129.64a280.12 280.12 0 0 0-93.9-.04L.77 5.73a408.64 408.64 0 0 1 137.77.23z"
                          />
                        </svg>
                      </Section>
                    </WheelCover>
                  ))}
                </WheelRouletteWrapper>
              </AnimatedWheel>
              {/*</div>*/}
            </div>
            <div className="arrows-container" />
          </div>
          <div className="controller-container">
            <Control
              user={user}
              onPress={this.handlePlay}
              appDiffTime={appDiffTime}
              access={access}
              userRewards={userRewards}
              disabled={
                this.animationWasStarted ||
                this.state.spinButtonPress ||
                access.availability === false
              }
            />
          </div>
        </Container>
      </>
    );
  }

  animate = () => {
    if (this.animationWasStarted) return;
    this.setState(() => ({ spinButtonPress: true, index: 9 }));

    this.animation.setValue(0);

    Animated.timing(this.animation, {
      toValue: 1,
      easing: BezierEasing(0, 0.4, 0.4, 1.025),
      duration: 10000,
    }).start(e => {
      if (e.finished) {
        this.animationWasStarted = true;
        this.props.actions.toggleWinnerModal(true);
        this.props.actions.refreshWheelItems();
        this.animationWasEnded = true;
        this.setState(() => ({ spinButtonPress: false }));
      }
    });
  };

  handlePlay = () => {
    this.props.actions.getResult();
  };

  reset = () => {
    this.animation.setValue(0);
    this.props.actions.resetResult();
    this.setState({
      index: 0,
      spinButtonPress: false,
    });
    this.animationWasStarted = false;
    this.animationWasEnded = false;
  };
}

export const WheelGame = connect(
  state => ({
    appDiffTime: getAppDiffTime(state),
    user: getUser(state),
    items: getFortuneWheelItems(state),
    result: getFortuneWheelResult(state),
    modal: getFortuneWheelWinnerModalStatus(state),
    access: getFortuneWheelAvailability(state),
    userRewards: getUserDetails(state, 'rewards'),
  }),
  dispatch => ({
    actions: bindActionCreators(gameActions, dispatch),
  })
)(Wheel);

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content;
  grid-gap: 1rem;
  flex: 1;
  height: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 140vw;
    height: 140vw;
    left: 50%;
    top: calc(50% - 40px);
    transform: translate3d(-50%, -50%, 0);
    background: url('${StarImg}') no-repeat;
    background-size: contain;
    position: absolute;
  }

  & .game-container {
    display: flex;
    position: relative;

    & .wheel-container {
      display: flex;
      position: absolute;
      left: 50%;
      top: calc(50% - 67.5px);
      transform: translate(-50%, 0);
    }

    & .arrows-container {
      width: 1.25rem;
      height: 130px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);

      &:before,
      &:after {
        content: '';
        display: block;
        width: 1.25rem;
        height: 0.625rem;
        clip-path: polygon(51% 0, 0% 100%, 100% 100%);
        background: var(--yellow);
        position: absolute;
      }

      &:before {
        top: 0;
        transform: rotate(180deg);
      }

      &:after {
        bottom: 0.25rem;
      }
    }
  }

  & .controller-container {
    padding: 0 1rem 1.5rem;
    position: relative;
    z-index: 100;
  }
`;

const AnimatedWheel = styled(Animated.div)`
  display: flex;
  height: 800px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
`;

const PrizeText = styled.div`
  background: #252b13cc;
  border-radius: 10px;
  color: #ff3;
  font-weight: 700;
  padding: 4px;
  font-size: 13px;
`;

const PrizeImg = styled.img`
  width: 80px;
  height: 65px;
  object-fit: contain;
  margin: 0 auto;
`;

const PrizeContainer = styled.div`
  margin-top: 10px;
  width: 130px;
  height: 110px;
  position: absolute;
  z-index: 1;
  text-align: center;
  color: var(--color-white);

  font-size: 14px;
`;

const WheelCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  height: 800px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const WheelRouletteWrapper = styled.div`
  width: 800px;
  height: 800px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, 0);
`;
