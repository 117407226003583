import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchParticipateLottery } from 'core/ducks/lottery';

export const useParticipateFreeLottery = () => {
  const dispatch = useDispatch();

  const handleCreateParticipate = useCallback(() => {
    dispatch(fetchParticipateLottery());
  }, [dispatch]);

  return {
    handleCreateParticipate,
  };
};
