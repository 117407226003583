import { useSelector } from 'react-redux';
import { getDetailedItemInfo } from '../ducks';

export const useGetDetailedItemInfo = () => {
  const { data, isLoading, isLoadingSecond } = useSelector(getDetailedItemInfo);
  return {
    data,
    isLoading,
    isLoadingSecond,
  };
};
