import React from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionContainer, Span, DescriptionContainer, Title } from './GeneralInfo.styles';

interface GeneralInfoProps {
  collection: string | null;
  description: string;
}

export const GeneralInfo: React.FC<GeneralInfoProps> = React.memo(({ collection, description }) => {
  const { t } = useTranslation('cases2.0');

  return (
    <>
      {!!collection && (
        <CollectionContainer>
          <Title>{t('itemInfo.collection')}</Title>
          <Span>{collection}</Span>
        </CollectionContainer>
      )}
      <DescriptionContainer>
        <Title>{t('itemInfo.description')}</Title>
        <Span>{description}</Span>
      </DescriptionContainer>
    </>
  );
});
