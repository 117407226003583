import Immutable from 'immutable';

export const InventoryRecord = Immutable.Record<IInventoryRecord>({
  loaded: false,
  loading: true,
  failure: false,
  errorText: null,
  filters: {
    sortBy: '-price',
  },
  meta: {
    count: 0,
    amount: 0,
  },
  kits: {
    identifiers: [],
    entities: {},
  },
});

export interface IInventoryElement {
  steamInventoryId: number;
  steamBotId: number;
  baseItem: {
    name: string;
    icon: string;
    color: string;
    weapon: string;
    type: string;
    rarity: string;
    shortName: string;
    skin: string;
    exterior: string;
    statTrak: false;
    steamPrice: number;
  };
  tradable: boolean;
  tradeLockEndAt: null;
  passed: boolean;
  custom: boolean;
  price: number;
  safePrice: number;
  float: number;
  priceRange: {
    start: number;
    end: number;
  };
  inspectLink: string;
  stickers: any[];
  inTrade?: boolean;
}

export interface IInventoryRecord {
  loading: boolean;
  loaded: boolean;
  failure: boolean;
  filters: {
    sortBy: 'price' | '-price' | 'name' | '-name';
  };
  meta: {
    count: number;
    amount: number;
  };
  kits: {
    identifiers: string[];
    entities: Record<string, IInventoryElement>;
  };
  errorText: string;
}
