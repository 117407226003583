import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import { useSalesElement, useSalesSelectedControllers } from '../../../../hooks';
import { MarketplaceDialogs } from '../../../../marketplace-dialogs.config';
import { ISalesInventoryElement } from '../../../../interfaces';
import { skinStickerImage } from 'core/utils/skinStickerImage.utils';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IPublicationElement {
  id: string;
}

export const PublicationElement: FC<IPublicationElement> = ({ id }) => {
  const { t } = useTranslation('marketplace');
  const { item } = useSalesElement<ISalesInventoryElement>(id);
  const { handleRemoveFromSelected } = useSalesSelectedControllers(id);
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.INVENTORY_SALE_CONFIGURATION);

  const newPrice = item.selling.price - item.price;
  const percent = parseInt(((100 * newPrice) / item.price).toString());

  return (
    <Container>
      <div className="thumbnails">
        <img
          srcSet={`${skinItemImage(item?.baseItem.icon, 112, 80)} 320w, ${skinItemImage(
            item?.baseItem.icon,
            224,
            160
          )} 375w`}
          src={skinItemImage(item?.baseItem.icon, 112, 80)}
          alt=""
        />
        <div className="stickers">
          {item.stickers?.map(({ icon }: { icon: string }, i: number) => (
            <img key={i} src={skinStickerImage(icon)} alt="" />
          ))}
        </div>
      </div>
      <div className="detailed">
        <div className={`float ${getExteriorByFloat(item.float)}`}>
          {!!item.float && item.float?.toFixed(4)}
        </div>
        <div className="trades-duration">
          {item.selling.tradesDuration}{' '}
          {t('pluralize time.hours', {
            postProcess: 'interval',
            count: Number(item.selling.tradesDuration),
          })}
        </div>
        <div className="display-name">
          <span>{item?.baseItem.name}</span>
        </div>
        <div className="display-price">
          <CurrencyText value={item.selling.price} />
          <span
            className={`${Number(percent) <= -5 && 'underprice'} ${
              Number(percent) > 5 && 'overprice'
            }`}
          >
            {' ('}
            {item.selling.price > item.price && '+'}
            {percent}
            {'%)'}
          </span>
        </div>
        <div className="controllers">
          <Button
            className="primary-outline"
            onClick={handleToggleDialog(MarketplaceDialogs.INVENTORY_SALE_CONFIGURATION, { id })}
          >
            {t('Edit')}
          </Button>
          <Button className="link-light" onClick={handleRemoveFromSelected(id)}>
            <i className="icon-trash-bin" />
          </Button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.75fr 1fr;
  grid-gap: 1.25rem;
  width: 100%;
  height: 100%;
  padding: 0.875rem 0.875rem 0.8rem;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  position: relative;
  overflow: hidden;

  & .thumbnails {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr minmax(0, 2rem);
    grid-gap: 0.175rem;
    overflow: hidden;

    & img {
      align-self: center;
      justify-self: center;
      max-width: 90%;
      width: 100%;
      object-fit: contain;
    }

    & .stickers {
      justify-self: center;
      align-self: center;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1.5rem;
      grid-template-rows: 1.5rem;
      grid-gap: 0.5rem;

      & img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
      }
    }
  }

  & .detailed {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content 1.4rem 1fr min-content;
    grid-gap: 0.25rem 1.375rem;

    & .float,
    & .trades-duration {
      height: 0.875rem;
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
    }

    & .float {
      color: transparent;

      &.factory-new {
        color: var(--color-exterior-factory-new);
      }

      &.minimal-wear {
        color: var(--color-exterior-minimal-wear);
      }

      &.field-tested {
        color: var(--color-exterior-field-tested);
      }

      &.well-worn {
        color: var(--color-exterior-well-worn);
      }

      &.battle-scarred {
        color: var(--color-exterior-battle-scarred);
      }
    }

    & .trades-duration {
      white-space: nowrap;
      color: var(--color-gray-200);
    }

    & .display-name,
    & .display-price {
      grid-column: 1 / 4;
      letter-spacing: 0.24px;
    }

    & .display-name {
      align-self: self-end;

      & span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.875rem;
        color: var(--color-white);
      }
    }

    & .display-price {
      align-self: center;
      height: 2rem;
      font-size: 0.875rem;
      letter-spacing: 0.24px;
      color: var(--color-white);

      & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--primary);

        &.overprice {
          color: var(--color-red);
        }

        &.underprice {
          color: var(--color-green);
        }
      }
    }

    & .controllers {
      grid-column: 1 / 4;
      grid-row: 4;
      display: grid;
      grid-template-columns: 1fr 2rem;
      grid-template-rows: 2rem;
      grid-gap: 0.5rem;

      & button {
        width: auto;
        height: auto;
        justify-self: stretch;
        align-self: stretch;

        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        letter-spacing: 0.1px;
        text-transform: none;

        & i {
          font-size: 1.25rem;
        }
      }
    }
  }
`;
