import styled from 'styled-components';

export const Cart = styled.div`
  --hilo-factor-HI: #f1ba17;
  --hilo-factor-LO: #3621c8;
  --hilo-factor-red: #f91e32;
  --hilo-factor-black: #444444;
  --hilo-factor-JOCKER: #0a92ff;
  --hilo-factor-A: #21a31b;
  --hilo-factor-KA: #21a31b;
  --hilo-factor-JQKA: #21a31b;
  --hilo-factor-2-9: #21a31b;

  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 46px;

  border-radius: var(--size-radius-s);

  & span {
    font-style: normal;
    font-weight: bold;
    font-size: 0.7rem;
    color: var(--color-white);
  }
`;
