import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actionRequestCaseList, sortAction } from 'games/CaseGame/ducks';
import { Sort } from '@components/filters';
import { IDialogComponent } from 'core/interfaces';
import { useGetCaseListData, useGetSortParams } from 'games/CaseGame/hooks';

const sortBy = ['popular', 'price', '-price', 'name', '-name'];

interface SortDialogProps extends IDialogComponent<boolean> {}

const SortDialog: React.FC<SortDialogProps> = ({ handleCloseModal }) => {
  const sortParams = useGetSortParams();
  const { casesData } = useGetCaseListData();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (value: string) => {
      dispatch(
        actionRequestCaseList({
          id: casesData.data.idCategory,
          filterParams: {
            sortBy: value,
            maxPrice: sortParams.maxPrice,
            minPrice: sortParams.minPrice,
            query: sortParams.query,
          },
        })
      );
      dispatch(sortAction(value));
      handleCloseModal();
    },
    [dispatch, handleCloseModal, sortParams, casesData.data.idCategory]
  );

  return (
    <Sort
      sortParams={sortBy}
      i18nextKey={'cases2.0'}
      closeHandler={handleCloseModal}
      submitHandler={onSubmit}
      value={sortParams.sortBy}
    />
  );
};

export default SortDialog;
