import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';
import {
  AdditionalSpan,
  AnimatedContainer,
  AuthContent,
  AuthMethod,
  AuthMethodContainer,
  AuthSpan,
  AuthTitle,
  BonusContainer,
  BonusCount,
  BonusSpan,
  Container,
  ContentContainer,
  Header,
  Info,
  InputContainer,
  PromoCode,
  Span,
  SteamButton,
  SteamButtonTextContainer,
  StyledButton,
  StyledLabel,
  Title,
  TroubleButton,
  TroubleContainer,
  UserDetailContainer,
  UserDetailContainerSpan,
  UserIcon,
  InfoFull,
} from './referral.styles';

import { Trans, useTranslation } from 'react-i18next';
import { ReferralIcons } from '../assets/index';
import { useHistory } from 'react-router-dom';
import { AuthTypes } from 'core/Auth/interfaces';
import { useBindingProvider } from 'core/Auth';
import { ReferralCampaignTypes } from 'core/User/interfaces';
import { CurrencyText } from '@components/currency';

export type ModalType = 'authenticated' | 'not-authenticated';

interface ReferralDialogProps {
  handleCloseModal: () => void;
  code: string;
  avatar: string;
  userName: string;
  reward: number;
  type: ModalType;
  bonusType: ReferralCampaignTypes;
}

const authArray: AuthTypes[] = ['google', 'vkontakte', 'meta', 'twitch', 'telegram'];
//NOTE: delete yandex  'yandex',
export const ReferralDialog: React.FC<ReferralDialogProps> = ({
  handleCloseModal,
  code,
  reward,
  type,
  bonusType,
  userName,
  avatar,
}) => {
  const [agree, setAgree] = useState<boolean>(true);
  const { t } = useTranslation();
  const { handleBindingProvider } = useBindingProvider();
  const history = useHistory();
  const [showFullTOS, setShowFullTOS] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const heightElement = ref?.current?.offsetHeight ?? 12;

  const handleShowTOS = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    setShowFullTOS(prev => !prev);
  };

  const clickHandler = () => {
    handleCloseModal();
    history.push('/refill');
  };

  const changeHandler = () => {
    setAgree(!agree);
  };

  const springProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    maxHeight: showFullTOS ? 70 : heightElement,
    minHeight: 12,
  });

  const modalTypeContent: Record<ModalType, () => JSX.Element> = {
    authenticated: () => (
      <>
        <StyledButton onClick={clickHandler}>
          {t('fillBalance', {
            ns: 'referralInfo',
          })}
        </StyledButton>
        <AdditionalSpan>
          {t('additionalInfo', {
            ns: 'referralInfo',
          })}
        </AdditionalSpan>
      </>
    ),

    'not-authenticated': () => (
      <AuthContent>
        <AuthTitle>
          {t('authForBonus', {
            ns: 'referralInfo',
          })}
        </AuthTitle>
        <SteamButton
          disabled={!agree}
          onClick={handleBindingProvider(ReferralIcons.ReferralIconsComponent.steam.keyWord)}
        >
          <SteamButtonTextContainer>
            {ReferralIcons.ReferralIconsComponent.steam.component} STEAM
          </SteamButtonTextContainer>
        </SteamButton>
        <AuthSpan>
          {t('other', {
            ns: 'referralInfo',
          })}
        </AuthSpan>
        <AuthMethodContainer>
          {authArray.map(
            value =>
              !!ReferralIcons.ReferralIconsComponent[value].component && (
                <AuthMethod
                  onClick={handleBindingProvider(
                    ReferralIcons.ReferralIconsComponent[value].keyWord
                  )}
                  key={value}
                  disabled={!agree}
                >
                  {ReferralIcons.ReferralIconsComponent[value].component}
                </AuthMethod>
              )
          )}
        </AuthMethodContainer>
        <TroubleContainer>
          <InputContainer>
            <input
              type="checkbox"
              className="checkboxBlue"
              id="checkbox10"
              checked={agree}
              onChange={changeHandler}
            />
            <StyledLabel htmlFor="checkbox10">
              <Trans i18nKey="iam18" t={t}>
                I'm 18+ and I agree to the
                <Link to="/tos" className="tos">
                  Terms and Conditions.
                </Link>
              </Trans>{' '}
              <AnimatedContainer className="dropdown" style={springProps} onClick={handleShowTOS}>
                <Info className={`${showFullTOS ? 'hide' : ''}`} ref={ref}>
                  {t('inAddition')}
                </Info>
                <InfoFull>{t('inAdditionFull')}</InfoFull>
              </AnimatedContainer>
            </StyledLabel>
          </InputContainer>
          <TroubleButton as="a" href="mailto:support@csgofast.com">
            {t('Trouble logging in?', {
              ns: 'referralInfo',
            })}
          </TroubleButton>
        </TroubleContainer>
      </AuthContent>
    ),
  };

  return (
    <Container modalType={type}>
      <Header>
        <Title>
          {t('modalBonusTitle', {
            ns: 'referralInfo',
          })}
        </Title>
      </Header>
      <PromoCode>
        [<Span>{code}</Span>]
      </PromoCode>
      {userName && (
        <UserDetailContainer>
          <UserDetailContainerSpan>
            {t('from', {
              ns: 'referralInfo',
            })}
          </UserDetailContainerSpan>
          <UserIcon src={avatar} />
          <UserDetailContainerSpan>{userName}</UserDetailContainerSpan>
        </UserDetailContainer>
      )}
      <ContentContainer>
        {bonusType === ReferralCampaignTypes.PERCENT ||
        bonusType === ReferralCampaignTypes.PROMO_PERCENT ? (
          <img alt="bonus" src={ReferralIcons.discountObject[reward]} />
        ) : bonusType === ReferralCampaignTypes.MONEY_OLD ||
          bonusType === ReferralCampaignTypes.MONEY_NEW ? (
          <BonusContainer>
            <BonusCount>
              +<CurrencyText value={reward} />
            </BonusCount>
            <BonusSpan>
              {t('onAccount', {
                ns: 'referralInfo',
              })}
            </BonusSpan>
          </BonusContainer>
        ) : (
          <>
            <ReferralIcons.Case width={139} height={101} />
            <BonusContainer>
              <BonusCount>+{reward}%</BonusCount>
              <BonusSpan>
                {t('onAccount', {
                  ns: 'referralInfo',
                })}
              </BonusSpan>
            </BonusContainer>
          </>
        )}
      </ContentContainer>
      {modalTypeContent[type]()}
    </Container>
  );
};
