import React, { FC } from 'react';
import { IPayMethodGates } from '../../../interfaces/reduces.refill.interfaces';
import { URLS } from 'services/constant-urls';
import { onErrorHandler } from 'core/utils';
import { Container, Gate, TitleSpan, Wrap } from './pay-gate-list.styles';

interface IPayGateList {
  gates: IPayMethodGates;
  gate: number;
  handleChangeGate: (idx: number) => () => void;
}

export const PayGateList: FC<IPayGateList> = ({ gate, handleChangeGate, gates }) => (
  <Container>
    {gates.map((item, i) => (
      <Wrap key={item.id}>
        <Gate selected={gate === i} onClick={handleChangeGate(i)} disabled={!item.is_enabled}>
          <div className="method-thumbnail">
            <img
              src={`${URLS.refill.icon}/refill-${item.name}.svg`}
              alt=""
              onError={e => onErrorHandler(e, URLS.refill.error)}
            />
          </div>
          <span className="method-name">{item?.method_name}</span>
          <TitleSpan>{item.title}</TitleSpan>
        </Gate>
      </Wrap>
    ))}
  </Container>
);
