import React, { FC } from 'react';
import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';
import { IFastInventoryElement, useFastInventoryElement } from 'core/User';
import { CurrencyText } from '@components/currency';
import { getItemUri } from 'core/utils/image.utils';

interface IFastInventoryItem {
  identifier: number;
  toggleParticipate: any;
  participate: Set<IFastInventoryElement>;
  disabled: boolean;
}

interface IFastInventoryItemStyle {
  selected?: boolean;
  disabled?: boolean;
}

export const FastInventoryItem: FC<IFastInventoryItem> = ({
  identifier,
  toggleParticipate,
  participate,
  disabled,
}) => {
  const { item } = useFastInventoryElement(identifier);
  if (!item) return <Container className="skeleton" />;

  return (
    <Container
      selected={participate.has(item)}
      disabled={disabled}
      onClick={toggleParticipate(item)}
    >
      <div className="thumbnail">
        <img
          srcSet={`${getItemUri(item.inventoryItem?.baseItem.icon, 45, 30)} 1x, ${getItemUri(
            item.inventoryItem?.baseItem.icon,
            90,
            60
          )} 2x`}
          src={getItemUri(item.inventoryItem?.baseItem.icon, 45, 30)}
          alt=""
        />
      </div>
      <div className="price">
        <CurrencyText value={item.inventoryItem.price} />
      </div>
    </Container>
  );
};

const Container = styled(SkeletonElement)<IFastInventoryItemStyle>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content;
  grid-gap: 0.375rem;
  width: 100%;
  height: 100%;
  padding: 0.375rem;
  background: ${({ selected }) => (selected ? 'var(--color-white-gray)' : 'var(--color-dark-200)')};
  border-radius: var(--size-radius-s);
  position: relative;
  transition: background 100ms ease-in;
  pointer-events: ${({ selected, disabled }) => (!selected && disabled ? 'none' : 'auto')};

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .price {
    text-align: center;
    font-size: 0.5625rem;
    line-height: 0.6875rem;
    color: var(--color-white);
  }
`;
