import React, { Fragment, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TabBar } from '@UIkit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CurrencyText } from '@components/currency';
import { useReferralCampaign } from 'modules/PersonalArea/hooks';
import { updateReferralCampaignReport } from 'modules/PersonalArea/ducks/referral.duck';

export enum Periods {
  TODAY = 'today',
  WEEK = 'week',
  MOUTH = 'month',
  YEAR = 'year',
}

const periods = [Periods.TODAY, Periods.WEEK, Periods.MOUTH, Periods.YEAR];

const formatTemplates: Record<Periods, string> = {
  today: 'HH:mm',
  week: 'DD.MM',
  month: 'DD.MM',
  year: 'MM.YYYY',
};

interface IReferralCampaignReports {
  id: number;
}

export const ReferralCampaignReports: FC<IReferralCampaignReports> = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, watch } = useForm<{ period: Periods }>({
    mode: 'onChange',
    defaultValues: {
      period: periods[0],
    },
  });

  const period = watch('period');
  const reports =
    useReferralCampaign(id.toString()).campaign?.reports?.incomesByTime?.[period] ?? [];

  useEffect(() => {
    if (!reports.length) {
      dispatch(updateReferralCampaignReport(id, period));
    }
    // eslint-disable-next-line
  }, [period]);

  return (
    <Container>
      <div className="reports-header">
        <div className="reports-header-title">{t('Statistic')}</div>
        <TabBar>
          {periods.map(key => (
            <Fragment key={key}>
              <label className={`tab-item ${period === key ? 'active' : ''}`}>
                <input type="radio" name="period" value={key} ref={register} />
                {t('periods.period', { context: key })}
              </label>
            </Fragment>
          ))}
        </TabBar>
        <div className="reports-header-labels">
          <div />
          <div>{t('Clicks')}</div>
          <div>
            {t('referrals')} / {t('Lost referrals')}
          </div>
          <div>{t('Profit')}</div>
        </div>
      </div>
      <div className="reports-list">
        {reports.map(({ date, clicks, count, lostCount, amount }) => (
          <div key={date} className="reports-list-item">
            <div>{dayjs(date).format(formatTemplates[period])}</div>
            <div>{clicks}</div>
            <div>
              {count} / {lostCount}
            </div>
            <div>
              +<CurrencyText value={amount} />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 3.75rem;

  & .reports-header {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 2.25rem 1fr min-content;
    background: var(--color-dark-600);
    min-height: 8.375rem;
    border-bottom: 1px solid var(--color-gray-900);

    & div:not(.reports-header-title):not(.reports-header-labels) {
      min-height: 1.875rem;
    }

    &-title {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-white);
      padding: 0 1rem;
      align-self: self-end;
    }

    &-labels {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(min-content, 1fr);
      padding: 0 1rem;
      height: 1.875rem;

      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        white-space: nowrap;
        color: var(--color-gray-100);

        &:last-of-type {
          justify-content: flex-end;
        }
      }
    }

    & input {
      display: none;
    }
  }

  & .reports-list {
    min-height: 21rem;

    &-item {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(min-content, 1fr);
      padding: 0 1rem;
      background: #131a1d;

      &:nth-child(even) {
        background: var(--color-dark-600);
      }

      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        color: var(--color-white);

        &:first-of-type {
          justify-content: flex-start;
        }

        &:last-of-type {
          justify-content: flex-end;
        }

        & .come,
        & .departed {
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }

        & .come {
          color: var(--color-white);
        }

        & .departed {
          color: var(--color-red);
          margin-left: 0.25rem;
        }
      }
    }
  }
`;
