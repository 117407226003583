import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
// import { PayoutReceiveAmount } from '../payout-receive-amount'
import { PayoutAgree } from '../payout-agree';
import { useOrderStatus, usePayoutAgree, usePayoutWallet } from '../../../hooks';
import { useModal } from 'core/Modals';
import { withdrawalModals } from '../../../modal.config';
import { OrderStatuses } from '../../../interfaces/reducers.withdrawal.interface';

interface IPayoutContentStyle {
  status: OrderStatuses | null;
}

export const PayoutContent = () => {
  const { t } = useTranslation();
  // const { config } = usePayoutConfig()
  // const { amount } = usePayoutReceive()
  const { agree, handleChangeAgree } = usePayoutAgree();
  const { wallet } = usePayoutWallet();
  const { handleCloseModal } = useModal(withdrawalModals.PAYOUT);

  // const { createOrderAction } = useCreateOrder()
  const { status } = useOrderStatus();
  const disabled = !agree;

  return (
    <Container status={status.condition}>
      <div className="inner-container">
        {/*<PayoutReceiveAmount amount={amount} commission={config.commission} />*/}
        <i className="icon-arrow-up" />
        <div className="payout-description">
          {/*<img*/}
          {/*  src={`//d2lomvz2jrw9ac.cloudfront.net/common/refill-icons/refill-${config.name}.svg`}*/}
          {/*  alt={config.name}*/}
          {/*/>*/}
          <span>{wallet}</span>
        </div>
      </div>
      <PayoutAgree agree={agree} handleChangeAgree={handleChangeAgree} />
      <div className="action-container">
        <CreateOrderButton
          className={`primary full ${status.condition}`}
          disabled={disabled}
          // onClick={createOrderAction(config.name)}
        >
          {t('Withdraw')}
        </CreateOrderButton>
        <Button className="link-light" onClick={handleCloseModal}>
          {t('Close')}
        </Button>
      </div>
      <div className="status-layout" onClick={handleCloseModal}>
        <div className={`status-layout__circle-loader ${status.condition}`}>
          <div className="status draw" />
        </div>
        <div className="status-layout__message">
          {t(status.messages, {
            ...status?.params,
            postProcess: 'with-template',
          })}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div.attrs((p: IPayoutContentStyle) => ({
  visible: ['success', 'failure'].includes(p.status),
}))<IPayoutContentStyle>`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1.874rem;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-layout);
  padding: 1.625rem 1rem 2rem;
  position: relative;

  @media screen and (min-width: 375px) {
    grid-gap: 2.5rem;
  }

  & .inner-container {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: 1.6875rem;

    @media screen and (min-width: 375px) {
      grid-gap: 2.25rem;
    }

    & .icon-arrow-up {
      font-size: 2rem;
      color: var(--color-white);
      transform: rotate(180deg);
      text-align: center;
    }

    & .payout-description {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: min-content;
      grid-gap: 0.75rem;

      & img {
        width: 1.375rem;
        height: 1.375rem;
        margin: 0 auto;
      }

      & span {
        font-size: 0.875rem;
        line-height: 1rem;
        text-align: center;
        color: var(--color-white);
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  & .action-container {
    display: grid;
    grid-gap: 1rem;
    grid-auto-rows: 3.125rem;
  }

  & .status-layout {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: 1.6rem;

    justify-content: center;
    align-content: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 150ms ease-in;
    background-color: var(--color-dark-layout);

    ${p =>
      p.visible &&
      `
        opacity: 1;
        pointer-events: auto;
      `};

    &__circle-loader {
      --sin45: 0.70710678;
      --brand-success: #5cb85c;
      --brand-failure: #9ea7b3;
      --loader-size: 7em;
      --symbol-thickness: 2px;
      --check-height: calc(var(--loader-size) / 2);
      --check-width: calc(var(--check-height) / 2);
      --check-left: calc(var(--loader-size) / 7 + var(--loader-size) / 12);
      --check-color: var(--brand-success);
      --cross-color: var(--brand-failure);
      --cross-offset: calc(var(--check-height) / var(--sin45) - var(--check-height));
      justify-self: center;
      border: var(--symbol-thickness) solid rgba(0, 0, 0, 0.2);
      border-left-color: var(--check-color);
      animation: loader-spin 1s linear infinite;
      position: relative;
      display: inline-block;
      vertical-align: top;
      transform: scale(0.6);

      &,
      &:after {
        border-radius: 50%;
        width: var(--loader-size);
        height: var(--loader-size);
      }

      &.success {
        animation: none;
        border-color: var(--brand-success);
        transition: all 500ms ease-out;

        & .draw:after {
          animation-duration: 1.2s;
          animation-timing-function: ease;
          animation-name: checkmark;
          animation-fill-mode: forwards;
          transform: scaleX(-1) rotate(135deg);
        }

        & .status:after {
          opacity: 1;
          height: var(--check-height);
          width: var(--check-width);
          transform-origin: left top;
          border-right: var(--symbol-thickness) solid var(--check-color);
          border-top: var(--symbol-thickness) solid var(--check-color);
          content: '';
          left: var(--check-left);
          top: var(--check-height);
          position: absolute;
        }
      }

      &.failure {
        animation: none;
        border-color: var(--brand-failure);
        transition: all 500ms ease-out;

        & .draw {
          &:before,
          &:after {
            animation-duration: 1.2s;
            animation-timing-function: ease;
            animation-name: crossmark;
            animation-fill-mode: forwards;
          }

          &:before {
            transform: scaleX(-1) rotate(45deg);
          }

          &:after {
            transform: scaleX(-1) rotate(225deg);
          }
        }

        & .status {
          top: 50%;
          left: 50%;
          position: absolute;

          &:before,
          &:after {
            opacity: 1;
            height: calc(var(--check-height) / 2);
            width: calc(var(--check-height) / 2);
            transform-origin: left top;
            border-right: var(--symbol-thickness) solid var(--cross-color);
            border-top: var(--symbol-thickness) solid var(--cross-color);
            content: '';
            position: absolute;
          }

          &:before {
            left: calc(var(--cross-offset) - var(--symbol-thickness) * 2);
            top: calc(var(--symbol-thickness) * 2 - var(--cross-offset));
          }

          &:after {
            left: calc(var(--symbol-thickness) * 2 / var(--sin45) - var(--cross-offset));
            top: calc(var(--cross-offset) - (var(--symbol-thickness)) / var(--sin45));
          }
        }
      }

      @keyframes loader-spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes checkmark {
        0% {
          height: 0;
          width: 0;
          opacity: 1;
        }
        20% {
          height: 0;
          width: var(--check-width);
          opacity: 1;
        }
        40% {
          height: var(--check-height);
          width: var(--check-width);
          opacity: 1;
        }
        100% {
          height: var(--check-height);
          width: var(--check-width);
          opacity: 1;
        }
      }

      @keyframes crossmark {
        0% {
          height: 0;
          width: 0;
          opacity: 1;
        }
        20% {
          height: 0;
          width: var(--check-width);
          opacity: 1;
        }
        40% {
          height: var(--check-width);
          width: var(--check-width);
          opacity: 1;
        }
        100% {
          height: var(--check-width);
          width: var(--check-width);
          opacity: 1;
        }
      }
    }

    &__message {
      padding: 0 1.5rem;
      text-align: center;
      justify-self: center;
      font-size: 1.25rem;
      line-height: 1.4375rem;
      color: var(--color-white);
    }
  }
`;

const CreateOrderButton = styled(Button)`
  background-size: 50px 50px;
  background-color: var(--yellow);

  &.pending {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    animation: 3s linear infinite progress-bar-stripes;
  }

  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 50px;
    }
  }
`;
