import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { delay, filter, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { modalChangeState } from 'core/Modals';
import { promoModals } from './modal.config';
import { userActionsTypes } from 'core/User/interfaces/user.actions.interface';
import { getUser, IUserState } from 'core/User';

export const promoEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(userActionsTypes.UPDATE_USER),
    pluck('payload', 'coins_demo'),
    filter(coins_demo => typeof coins_demo === 'number' && coins_demo === 0),
    take(1),
    delay(2 * 60 * 1000),
    map(() => getUser(store$.value)),
    filter((user: IUserState) => {
      const day = Number(localStorage.getItem(`@csgofast:promo-${user.id}`));
      const today = new Date().getDay();
      return (
        day !== today && // модалка 1 раз в день
        user.profileType === 'demo' && // только demo акк
        !user.coins_demo && // demo баланс === 0
        user.bonuses.promo && // взял бонус на 100$
        !user.bonuses.deposit // не пополнял акк
      );
    }),
    switchMap((user: IUserState) =>
      of(modalChangeState(promoModals.REFILL_PROMO, true)).pipe(
        tap(() =>
          localStorage.setItem(`@csgofast:promo-${user.id}`, new Date().getDay().toString())
        )
      )
    )
  );
