import React from 'react';
import styled from 'styled-components';
import { ReferralCampaign } from './referral-campaign';
import { useReferralCampaigns } from '../../../../../hooks';

export const ReferralCampaignsTabContent = () => {
  const { campaigns } = useReferralCampaigns();
  return (
    <Container>
      {Object.keys(campaigns).map((key: string) => (
        <ReferralCampaign key={key} {...campaigns[key]} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  padding: 1rem;
`;
