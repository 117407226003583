import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchSteamInventory } from '../../ducks/inventory.duck';

export const useInventoryRefresh = () => {
  const dispatch = useDispatch();

  const handleRefreshInventory = useCallback(() => {
    dispatch(fetchSteamInventory(true));
  }, [dispatch]);

  return {
    handleRefreshInventory,
  };
};
