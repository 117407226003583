import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import successImg from '../../assets/Notices/success.svg';
import errorImg from '../../assets/Notices/error.svg';
import infoImg from '../../assets/Notices/info.svg';
import warningImg from '../../assets/Notices/warning.svg';
import { ReactComponent as CheckImg } from '../../assets/Notices/check.svg';
import { Date, Icon, Left, Notice, Text, Right } from './Item.styles';
import { INotification } from './Notification';

interface IItem {
  isSelect: boolean;
  item: INotification;
  statusDeleteNotices: boolean;
  handleSelect: (addDate: number) => void;
}

export const Item: FC<IItem> = ({ isSelect, item, handleSelect, statusDeleteNotices }) => {
  const { t } = useTranslation();

  return (
    <Notice
      select={isSelect}
      disabled={!statusDeleteNotices}
      onClick={() => handleSelect(item.addDate)}
    >
      <Left>
        {item.type === 'success' && <Icon src={successImg} />}
        {item.type === 'error' && <Icon src={errorImg} />}
        {item.type === 'info' && <Icon src={infoImg} />}
        {item.type === 'warning' && <Icon src={warningImg} />}
      </Left>
      <div>
        <Text>
          {t(item.header, {
            ...item.params,
            defaultValue: item.header,
            postProcess: 'with-template',
          })}
        </Text>
        <Text>
          {t(item.body, {
            ...item.params,
            defaultValue: item.body,
            postProcess: 'with-template',
          })}
        </Text>
        <Date>{dayjs(item.addDate).format('DD.MM.YYYY (HH:mm)')}</Date>
      </div>
      {isSelect && (
        <Right>
          <CheckImg />
        </Right>
      )}
    </Notice>
  );
};
