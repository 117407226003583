export default {
  Games: 'Spel',
  'Refill balance': 'Fyll på saldo',
  Settings: 'Intällningar',
  Store: 'Butik',
  'Get free money': 'Få gratis mynt',
  Notifications: 'Meddelanden',
  FAQ: 'FAQ',
  'Log out': 'Logga ut',
  'Steam trade link': 'Steam-byteslänk',
  'Where can I get a link?': 'Var hämtar jag länken?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Ai fost deconectat de la serviciul CSGOFAST. Atinge ecranul pentru a te reconecta.',
  'App was offline. Check your internet connection to continue!':
    'Aplicația a fost offline. Verifică legătura ta la internet pentru a continua!',
  SAVE: 'SAVE',
};
