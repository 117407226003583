import React, { FC } from 'react';
import styled from 'styled-components';
import { NavigationLink } from '@components/Common';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IAccountNavigation {
  statisticAccess: boolean;
}

export const AccountNavigation: FC<IAccountNavigation> = ({ statisticAccess }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Container>
      {statisticAccess && (
        <NavigationLink to={`${pathname}/statistics`} as={Link}>
          <div className="leading">
            <i className="icon-statistics" />
          </div>
          <div>
            <span>{t(`${pathname}/statistics`)}</span>
          </div>
        </NavigationLink>
      )}
      <NavigationLink to={`${pathname}/race-activity`} as={Link}>
        <div className="leading">
          <i className="icon-leaders" />
        </div>
        <div>
          <span>{t(`${pathname}/race-activity`)}</span>
        </div>
      </NavigationLink>
    </Container>
  );
};

const Container = styled.div`
  flex-shrink: 0;
  background-color: var(--color-dark-600);
`;
