import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { SecretField, VerifyLink } from '@components/Common';

interface IHistoryVerify {
  rand: string;
  hash: string;
  salt?: string;
}

export const HistoryVerify: React.FC<IHistoryVerify> = ({ hash, rand, salt }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Label>{t('Round hash')}</Label>
      <SecretField value={hash} placeholder="Round hash sha224" />
      {salt && (
        <>
          <Label>{t('Round salt')}</Label>
          <SecretField value={salt} placeholder="salt" />
        </>
      )}
      <Label>{t('Round number')}</Label>
      <SecretField value={rand} placeholder="Round number" />
      <LinkWrapper>
        <VerifyLink rand={rand} salt={salt} />
      </LinkWrapper>
    </div>
  );
};

const LinkWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Label = styled.label`
  margin: 0.8rem 1rem 0.5rem;
  font-size: 0.8rem;
  letter-spacing: 0.86px;
  color: var(--color-white);
`;
