import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 69px;
  justify-content: center;
  padding: 28px 24px 68px;
`;

export const Type = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

export const Subtitle = styled.span`
  color: var(--color-gray-200);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
`;
