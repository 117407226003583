export default {
  'What is the Ticket System?': 'Ce este Sistemul de Tichete?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Când jucătorii plasează pariurile, aceştia primesc tichete virtuale, iar unul dintre ei devine câştigător. Dacă articolul dvs. valorează 1 $, primiţi 100 de tichete, iar dacă depuneţi un model în valoare de 10 $, primiţi 1.000 de tichete, şi aşa mai departe.',

  // GAMES

  'How is the winner determined?': 'Cum este determinat câştigătorul?',
  'When is the winner determined?': 'Când este determinat câştigătorul?',
  'How many bets can I place per one round?': 'Câte pariuri pot fi puse într-o singură rundă?',
  'What Service Commission is there in this game?':
    'Care sunt Comisioanele Serviciului în acest joc?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'â€“ jocul pentru cei cărora le place să joace pentru mizele mari. Cei care vor să îşi încerce norocul plasează un pariu într-un depozit general din care se trage apoi la sorţi jucătorul câştigător, la finalul unei runde.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'Câştigătorul este determinat de Sistemul de tichete în Classic Game.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Câştigătorul este determinat la finalul duratei unei runde sau atunci când a fost atins numărul maxim de modele depuse.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'Comisionul Serviciul variază de la 0 până la 10% în funcţie de suma câştigată.\nModele aleatorii din depozitul general sunt preluate în calitate de Comision al Serviciului.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'cea mai bună variantă de aţi încerca norocul instant. Există doar 3 jucători, iar pariul maxim este limitat. Câştigătorul primeşte depozitul general al celor 3 jucători.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Câştigătorul este determinat aleatoriu, dar cu cât este mai mare pariul, cu atât este mai mare şansa de a câştiga.',
  'One player can place one bet per one game round in this game.':
    'Un jucător poate plasa un singur pariu într-o singură rundă în cadrul acestui joc.',
  'Where can I see my chances of the winning?': 'Unde îmi pot vizualiza şansele de câştig?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Şansele de câştig sunt indicate în procente. Puteţi vizualiza procentajul şansei dvs. alături de suma pariată a jucătorului pentru runda respectivă (în partea dreaptă a avatarurilor şi a listei de modele depuse)',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Câştigătorul este determinat după ce 3 jucători îşi plasează pariurile în runda curentă a jocului.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'Comisionul Serviciul variază de la 0 până la 15% în funcţie de suma câştigată. Modele aleatorii din depozitul general al rundei sunt preluate în calitate de Comision al Serviciului.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'este un mod de joc în care jucătorii pariază pe sloturi negre, roşii sau verzi. În acest mod, Fast coins sunt acceptate ca pariuri. Slotul câştigător este determinat la finalul rundei. ',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Dacă slotul câştigător este negru sau roşu, jucătorul câştigă pariul plasat, multiplicat cu 2.',
  'The green slot returns the placed bet multiplied by 14.':
    'Slotul verde revine pariului plasat multiplicat cu 14.',
  'The bet sum of every slot can be different.':
    'Suma pariată pentru fiecare slot poate fi diferită',

  'How can I Get Fast Coins?': 'Cum pot obţine Fast coins?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Participaţi în Programul de recomandare. Invitaţi jucători noi în proiect cu ajutorul unui link de recomandare sau al unui cod. Primiţi puncte pentru fiecare recomandare şi pentru fiecare joc jucat de acesta.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Schimbaţi modelele care valorează cel puţin 1 $ cu puncte rapide. Pentru fiecare cent de valoare a modelului primiţi un punct. Puteţi transmite maxim 30 de articole odată. Faceţi clic aici pentru mai multe informaţii despre schimb. Recomandăm utilizarea funcţiei Steam Inventory Helper pentru a evalua valoarea',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Completaţi soldul de bonusuri cu ajutorul Sistemului de Plată. Selectaţi modalitatea convenabilă de plată şi primiţi numărul necesar de Fast coins în contul dvs.',

  'How is the winner slot determined (fair game)?':
    'Cum este determinat slotul câştigător (fair game)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'La începutul rundei Serviciul generează un număr lung aleatoriu de la 0 la 1 (ex. 0. 223088) şi îl criptează prin algoritmul sha224. Rezultatul este afişat la începutul rundei. La finalul rundei, Serviciul multiplică numărul criptat cu 15, obţinând astfel numărul slotului câştigător.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Exemplu: La începutul rundei numărul codificat este 0.223088232334703230728. Serviciul îl multiplică cu 15, obţinând numărul 3.34632348495. Numărul rezultat este 3 (slotul roşu). În această rundă câştigătorii sunt cei care au plasat pariurile pe slotul roşu.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Puteţi verifica dacă un slot câştigător este corect sau nu pe cont propriu. La finalul unei runde, preluaţi numărul codificat şi criptaţi-l încă o dată cu ajutorul unui serviciu online, de ex. http://sha224.net. Veţi obţine aceeaşi valoare hash de la începutul rundei. Aceasta înseamnă că jocul nu a fost falsificat.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Pentru că sistemul selectează sectorul câştigător chiar la începutul rundei şi orice jucător poate monitoriza schimbarea acestuia - nu avem nicio oportunitate de a influenţa rezultatele tombolei',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Puteţi împrospăta de un număr nelimitat de ori până la începutul rundei. Suma pariată pentru fiecare slot poate fi diferită.',

  'What bet limitations are there?': 'Ce limitări există pentru pariuri?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'Pariul minim este $0,1. Pe o culoare poți să pui maximum $5000 o dată.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Atenție! În timpul unei extrageri nu poți să pariezi pe roşu şi negru în acelaşi timp. Pariurile pe Verde sunt disponibile în fiecare rundă!',

  'When does the determination of the winner slot begin?':
    'Când începe determinarea slotului câştigător?',
  'The winner slot is determined at the end of a round.':
    'Slotul câştigător este determinat la finalul unei runde.',

  'Is there any commission in this mode?': 'Există vreun comision în acest mod?',
  'There is no commission at all. :)': 'Nu există niciun comision. :)',
};
