import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  width: fit-content;
  height: fit-content;
  position: relative;
`;

export const ArrowContainer = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .arrow {
    transition: all 0.4s ease;
  }

  &[data-status='active'] {
    .arrow {
      opacity: 1;
    }

    backdrop-filter: none;
  }

  &[data-status='inactive'] {
    .arrow {
      opacity: 0;
    }

    backdrop-filter: grayscale(100%);
  }

  .arrow-down-yellow {
    transform: rotate(180deg);
  }

  transition: all 0.4s ease;
`;
