import React, { useState, FC, useEffect } from 'react';
import { Container } from './tabDecorator.styles';

export interface DecoratorProps {
  activeTab: number;
  currentTab: number;
  children: React.ReactNode;
}

export const TabDecorator: FC<DecoratorProps> = ({ activeTab, currentTab, children }) => {
  const [shift, setShift] = useState(0);
  const width = window.innerWidth;
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (activeTab === currentTab) {
      setHide(false);
      setShift(0);
    } else {
      currentTab === 0 ? setShift(-width) : setShift(width);
    }

    const timer = setTimeout(() => {
      if (activeTab !== currentTab) {
        setHide(true);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [activeTab, currentTab, width]);

  return (
    <Container shift={shift} width={width} hide={hide}>
      {children}
    </Container>
  );
};
