import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '@UIkit';
import { Button } from '@components/Common';
import { StepStyleContainer } from '../@shared/step-style-container';
import { useUserApiKeyForm } from 'core/User/hooks/user-settings/api-key';

export const SteamApiKeyStep = () => {
  const { t } = useTranslation('steam-settings');
  const { form, onSubmit } = useUserApiKeyForm();
  return (
    <Container>
      <div className="step-header">
        <h5 className="step-header-title">{t('Add your API-key')}</h5>
        <div className="step-header-warning-message">
          <i className="icon-warn" />
          <p>
            {t('You can use any domain name when creating an API key')}{' '}
            <Button
              className="link-info"
              as="a"
              href="https://steamcommunity.com/dev/apikey"
              target="_blank"
            >
              {t('Where to get API-key?')}
            </Button>
          </p>
        </div>
      </div>
      <div className={form.errors?.apikey?.type}>
        <SearchInput
          {...form.register('apikey')}
          name="apikey"
          type="text"
          placeholder={t('Enter API-key')}
          ref={form.register({ required: true })}
        />
      </div>
      <Button className="steam-provider" onClick={form.handleSubmit(onSubmit)}>
        {t('Add your API key')}
      </Button>
    </Container>
  );
};

const Container = styled(StepStyleContainer)``;
