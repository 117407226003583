import React from 'react';
import styled from 'styled-components';
import { useSteamStatus } from 'modules/marketplace/hooks';

export const SteamStatus = () => {
  const { status } = useSteamStatus();
  return (
    <Container className={status}>
      <i className="icon-steam-provider" />
      <div className="indicator">
        <span className="indicator-item" />
        <span className="indicator-item" />
        <span className="indicator-item" />
        <span className="indicator-item" />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.375rem;
  align-self: center;
  position: relative;

  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--color-white);

  &.offline .indicator-item:nth-child(1) {
    color: var(--color-red);
  }

  &.surge .indicator-item,
  &.delayed .indicator-item {
    &:nth-child(1),
    &:nth-child(2) {
      background: var(--yellow);
    }
  }

  &.delayed .indicator-item:nth-child(3) {
    background: var(--yellow);
  }

  &.normal .indicator-item {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      background: var(--color-green);
    }
  }

  & i {
    font-size: 1.125rem;
  }

  & .indicator {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.1875rem;

    &-item {
      width: 0.1875rem;
      background: #47555f;
      align-self: self-end;
      transition: background 100ms ease-in;

      &:nth-child(1) {
        height: 6px;
      }

      &:nth-child(2) {
        height: 10px;
      }

      &:nth-child(3) {
        height: 14px;
      }

      &:nth-child(4) {
        height: 18px;
      }
    }
  }
`;
