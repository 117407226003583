import React from 'react';
import { StyledNavigationLink } from '../styles';
import { Link } from 'react-router-dom';
import { URLS } from 'services/constant-urls';
import { onErrorHandler } from 'core/utils';

interface WithDrawalMethodsProps {
  title: string;
  name: string;
}
export const CryptoMethodsComponent: React.FC<WithDrawalMethodsProps> = ({ title, name }) => {
  return (
    <StyledNavigationLink to={`/withdrawal/method/${name}`} as={Link}>
      <div className="leading">
        <img
          src={`${URLS.refill.icon}/refill-${name}.svg`}
          alt={name}
          onError={e => onErrorHandler(e, URLS.refill.error)}
        />
      </div>
      <div>
        <span>{title}</span>
      </div>
    </StyledNavigationLink>
  );
};
//NOTE: added hardcode link  to navigation
