import React, { CSSProperties } from 'react';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { GameRoulette } from './game-roulette';
import { GameHistory } from './game-history';
import { FactorControls } from './factor-controls';
import { GameNumber } from './game-number';
import { MembersGrid } from './members-grid';
import { GameNavigation } from './game-navigation';
import { AuthControlPanel } from '@components/auth-control-panel';
import { useTranslation } from 'react-i18next';
import { Container } from './game-page.styles';

export const GamePage = () => {
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];

  return (
    <>
      <AppBar isGame />
      <div className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <Container className="tablet">
          <div
            className="inner-wrapper"
            style={
              {
                '--height': `${Math.max(window.innerHeight, window.innerWidth)}px`,
              } as CSSProperties
            }
          >
            <GameHistory />
            <GameNumber />
            <GameRoulette />
            <FactorControls />
          </div>
          <GameNavigation />
          <MembersGrid />
        </Container>
      </div>
      <AuthControlPanel />
    </>
  );
};
