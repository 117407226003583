import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { AnimatedRouter } from '@components/animated-router';
import PrivateRoute from 'decorators/PrivateRoute.decorator';
import { useStorePageFilter } from '../hooks';
import { attachMarketplace, detachMarketplace } from '../ducks/marketplace.duck';
import { registerReducer } from '../ducks';
import { FilterPage } from './filter-page';
import { StorePage } from './store-page';
import { OrdersPage } from './orders-page';
import { InventoryPage } from './inventory-page';
import { PublicationPage } from './publication-page';

const _MarketplaceRouter: FC<RouteComponentProps> = ({ match }) => {
  const { updatePageAction } = useStorePageFilter();
  const dispatch = useDispatch();

  useMemo(() => {
    registerReducer();
    dispatch(attachMarketplace());
  }, [dispatch]);

  useEffect(() => {
    updatePageAction(1);
    return () => {
      dispatch(detachMarketplace());
      // unregisterReducer();
      //NOTE: it calls bug, because it doesn`t work correctly modals and removing field from store,
      // when you close modal component, it calls rerender, and component try again call filed from store, that already removed from that, that`s call an error
    };
  }, [dispatch, updatePageAction]);

  return (
    <>
      <AppBar />
      <div className="content dark-layout">
        <AnimatedRouter>
          <Route path={`${match.url}/inventory/publication`} component={PublicationPage} />
          <Route path={`${match.url}/inventory`} component={InventoryPage} />
          <Route path={`${match.url}/orders`} component={OrdersPage} />
          <Route path={`${match.url}/filters`} component={FilterPage} />
          <Route path={match.url} component={StorePage} />
        </AnimatedRouter>
      </div>
      <div id="marketplace" />
    </>
  );
};

export const MarketplaceRouter = PrivateRoute(_MarketplaceRouter);
