import styled from 'styled-components';
import { SvgIcons } from '../assets';

export const Container = styled.div`
  align-items: center;
  background: var(--color-gray-700);
  border-radius: var(--size-radius-m);
  opacity: 0.98;
  padding: 26px 15px 35px;
`;

export const PaymentIcon = styled(SvgIcons.PaymentIcon)`
  fill: transparent;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 20px;
  padding: 25px 0 13px;
`;

export const Subtitle = styled.span`
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
  padding-bottom: 20px;
  text-align: center;
`;
