import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { Icon } from '@components/Common';

export const RafflePointer = () => {
  const x = useSpring({
    to: {
      transform: 'translateX(0px)',
    },
    from: { transform: 'translateX(210px)' },
    config: { mass: 2, tension: 100, friction: 10 },
  });

  return (
    <Container style={x}>
      <Icon name="pointer" />
    </Container>
  );
};

const Container = styled(animated.div)`
  display: flex;
  flex-shrink: 0;
  height: 25px;
  justify-content: center;
  margin-top: 0.5rem;

  & > i {
    font-size: 1rem;
    color: var(--gray-100);
  }
`;
