export enum AuthType {
  Reg = 'registration',
  Auth = 'authentication',
  Bind = 'bind',
}
export type AuthTypes =
  | 'steam'
  | 'vkontakte'
  | 'google'
  | 'meta'
  | 'twitch'
  | 'telegram'
  | 'twitter';

//NOTE: delete | 'yandex'
