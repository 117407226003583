import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules: FC = () => {
  const { t } = useTranslation('x50');
  return (
    <div className="content dark-layout">
      <RulesContainer>
        <RulesP>
          <span>{t('Game mode')} x50 </span>-{' '}
          {t('allows user significantly increase his bet and inventory!')}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How to play at x50?')} </RulesSpan>
          <RulesP>
            {t(
              'Name of the game is quite simple – you have to make a bet on color and coefficient, which in your opinion is going to drop in the round'
            )}
          </RulesP>

          <RulesP>
            {' '}
            {t('Coefficient values the amount of increasing of your bet: x2, x3, x5 and x50!')}
          </RulesP>

          <RulesP>
            {' '}
            {t(
              'In each round, you can make bets on not more than 2 individual coefficients The minimum bet in 1 round is 10 coins, the maximum bet is 50000 coins'
            )}
          </RulesP>
        </RulesP>

        <RulesP>
          <RulesSpan>{t('When does the determination of the winner slot begin?')} </RulesSpan>
          {t('The winner slot is determined at the end of a round')}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Is there any commission in this mode?')} </RulesSpan>
          {t('There is no commission at all')}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Guess the color 10 times in a row')} </RulesSpan>
          <RulesP>{t('Additional prize for players with great intuition')}</RulesP>
          <RulesP>
            {t(
              'If playing x50 and you win 10 times in a row, then your winning is $1 After 10th winning in a row, you will have for $1 every next game'
            )}
          </RulesP>

          <RulesP>
            {' '}
            {t('Number of guessed colors in a row can be seen in a special window')}{' '}
          </RulesP>
          <RulesP>
            {' '}
            {t(
              'In case of betting for 2 colors within one round, the first bet will be taken into account'
            )}{' '}
          </RulesP>
        </RulesP>
      </RulesContainer>
    </div>
  );
};

const RulesContainer = styled.div`
  padding: 0 10px 10px 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 0.5rem 0;
  font-size: 12px;
  & span {
    color: #33ffff;
    font-weight: 700;
  }
`;

const RulesSpan = styled.span`
  color: #33ffff;
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;
