import { SkeletonElement } from '@UIkit';
import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  border-top: 1px solid var(--color-dark-200);
  background: var(--color-dark-600);
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  display: grid;
  padding: 0 15px 100px;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  justify-content: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 78px;
  margin-bottom: 28px;
  padding: 15px 15px 0;
`;

export const IconContainer = styled(SkeletonElement)`
  display: flex;
  flex: 1;
  width: 60px;
  height: 64px;
`;

export const Title = styled.div`
  display: flex;
  flex: 2;
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Close = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-gray-200);
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: flex-start;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  background: none;
  bottom: 0;
  backdrop-filter: blur(5px);
  position: fixed;
  height: fit-content;
  display: flex;
  padding: 10px 15px 40px;
  justify-content: center;
  gap: 8px;
`;

export const StyledButtonOpenCase = styled(Button)`
  background: var(--color-dark-200);
  color: var(--color-white);
  flex: 1;
  text-transform: uppercase;
`;

export const StyledButtonAddToBattle = styled(Button)`
  background: var(--yellow);
  color: var(--color-dark-600);
  flex: 1;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.2;
  }
`;
