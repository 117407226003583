import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '@components/Common/Avatars';
import { useTotalWinners } from '../../../hooks';
import { CurrencyText } from '@components/currency';

export const LobbyPedestal: FC = () => {
  const { users } = useTotalWinners();
  const { t } = useTranslation();
  return (
    <Container>
      <div className="pedestal-title">{t('TOP 3 PLAYERS')}</div>
      <div className="pedestal-container">
        {users?.map(item => (
          <div key={item.userId} className="pedestal-item">
            <div className="pedestal-item__award">
              <UserAvatar uri={item.avaLink} />
              <i className="icon-award-player" />
            </div>
            <span className="pedestal-item__player-name">{item.name}</span>
            <ul className="pedestal-item__completed-list">
              <li>
                <span className="sup-title">{t('Tickets won:')}</span>
                <div>
                  <span>
                    <i className="icon-coupon green" />
                    {item.tickets.green}
                  </span>
                  <span>
                    <i className="icon-coupon blue" />
                    {item.tickets.blue}
                  </span>
                  <span>
                    <i className="icon-coupon orange" />
                    {item.tickets.orange}
                  </span>
                </div>
              </li>
              <li>
                <span className="sup-title">{t('Money won:')}</span>
                <div>
                  <span>
                    <CurrencyText value={item.prizeCoinsAmount} />
                  </span>
                </div>
              </li>
              <li>
                <span className="sup-title">{t('Skins won:')}</span>
                <div>
                  <span className="sup-title">{item.prizeSkinsCount}</span>
                  <span className="arrow" />
                  <span>
                    <CurrencyText value={item.prizeSkinsSum} />
                  </span>
                </div>
              </li>
              {/*<li>*/}
              {/*  <span className="sup-title">{t('Quests passed:')}</span>*/}
              {/*  <div>*/}
              {/*    <span>*/}
              {/*      <i className="icon-coupon green" />*/}
              {/*      {item.quests.regular}*/}
              {/*    </span>*/}
              {/*    <span>*/}
              {/*      <i className="icon-coupon blue" />*/}
              {/*      {item.quests.fast}*/}
              {/*    </span>*/}
              {/*    <span>*/}
              {/*      <i className="icon-coupon orange" />*/}
              {/*      {item.quests.great}*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</li>*/}
            </ul>
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 4.25rem 0 4.25em;
  & .pedestal-title {
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    text-align: center;
    letter-spacing: 0.54px;

    color: white;
    margin-bottom: 1.3125rem;
  }
  & .pedestal-container {
    display: grid;
    grid-template-columns: 0.5rem repeat(3, 16.25rem) 0.5rem;
    grid-auto-rows: minmax(20rem, min-content);
    grid-gap: 0.5rem;
    padding: 3.625rem 0 0;
    overflow-x: scroll;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
      display: none;
    }

    &:before,
    &:after {
      content: '';
      scroll-snap-align: center;
    }

    & .pedestal-item {
      background-color: var(--color-dark-200);
      padding: 4.6875rem 1.25rem 1.25rem 0.9375rem;
      position: relative;
      border-radius: var(--size-radius-m);
      scroll-snap-align: center;
      --color-place: #e1e1e2;

      &:first-of-type {
        --color-place: #ffdb0c;

        & .pedestal-item__award:before {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(255, 219, 1, 0.26) 0%,
            rgba(255, 219, 1, 0) 100%
          );
        }
      }
      &:last-of-type {
        --color-place: #ed9100;

        & .pedestal-item__award:before {
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(237, 145, 0, 0.26) 0%,
            rgba(237, 145, 0, 0) 100%
          );
        }
      }

      &__award {
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
        position: absolute;
        top: -3.625rem;
        left: 50%;

        & .icon-award-player {
          font-size: 6.9rem;
          color: var(--color-place);
        }

        & img {
          width: 4.875rem;
          height: 4.875rem;
          border-radius: 50%;
          position: absolute;
          top: 1.0625rem;
        }

        &:before {
          content: '';
          width: 6.4375rem;
          height: 1.375rem;
          display: block;
          position: absolute;
          left: 50%;
          bottom: -1.375rem;
          transform: translateX(-50%);
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(225, 225, 226, 0.26) 0%,
            rgba(225, 225, 226, 0) 100%
          );
        }
      }

      &__player-name {
        display: block;
        width: 100%;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.25rem;
        color: white;
        letter-spacing: 0.54px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 1.5rem;
      }

      &__completed-list {
        li {
          margin-bottom: 1rem;
          &:nth-child(3) div {
            grid-auto-columns: min-content;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          & .sup-title {
            font-size: 0.625rem;
            line-height: 0.875rem;
            letter-spacing: 0.2px;
            color: var(--color-white-600);
            text-transform: uppercase;
          }

          & div {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            grid-gap: 0.25rem;
            align-items: center;

            font-size: 0.875rem;
            line-height: 1.25rem;
            color: white;

            & .arrow {
              width: 0.5rem;
              height: 0.5rem;
              background-color: var(--yellow);
              clip-path: polygon(0 0, 0% 100%, 100% 50%);
              margin: 0 0.25rem;
            }

            & i[class^='icon-coupon'] {
              font-size: 1rem;
              margin: 0 0.25rem;
              &.blue {
                color: var(--color-blue-ticket);
              }

              &.green {
                color: var(--color-green-ticket);
              }

              &.orange {
                color: var(--color-orange-ticket);
              }
            }
          }
        }
      }
    }
  }
`;
