import { useCallback, useState } from 'react';
import { transactionsTabConfig } from '../transaction.utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchTransactions } from '../duck';
import { getTransactions } from '../selectors';

export const useTransactionFacade = () => {
  const [filterId, setFilterId] = useState<number>(0);

  const transactions = useSelector(getTransactions, shallowEqual);
  const dispatch = useDispatch();

  const changeFilter = useCallback(
    (index: number) => () => {
      setFilterId(index);
      dispatch(fetchTransactions(1, transactionsTabConfig[index].options));
    },
    [dispatch]
  );

  const handleLoadingMore = useCallback(
    (page: number) => {
      if (!transactions.loading && transactions.hasMore)
        dispatch(fetchTransactions(page, transactionsTabConfig[filterId].options));
    },
    [transactions.loading, transactions.hasMore, filterId, dispatch]
  );

  return {
    transactions,
    changeFilter,
    filterId,
    handleLoadingMore,
  };
};
