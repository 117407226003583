import Immutable from 'immutable';
import { IMarketplaceElement, IMarketplaceMeta } from './marketplace.reducer.interfaces';

export const StoreRecord = Immutable.Record<IStoreRecord>({
  loading: false,
  loaded: false,
  failure: null,
  meta: {
    amount: 0,
    limit: 0,
    offset: 0,
  },
  kits: {
    ids: [],
    entities: {},
  },
});

export const HotOffersRecord = Immutable.Record<IHotOffers>({
  kits: {
    ids: [],
    entities: {},
  },
  count: 0,
});

export interface IStoreRecord {
  loading: boolean;
  loaded: boolean;
  failure: boolean;
  meta: IMarketplaceMeta;
  kits: {
    ids: string[];
    entities: Record<string, IMarketplaceElement>;
  };
}

export interface IHotOffers {
  kits: {
    ids: string[];
    entities: Record<string, IMarketplaceElement>;
  };
  count: number;
}

export interface ITradeOffer {
  order: {
    buyerSteamName: string;
    buyerId: number;
    buyerMarket: string;
    buyerSteamAvatar: string;
    buyerSteamCreatedAt: string;
    buyerSteamId: number;
    buyerSteamLevel: number;
    buyerTradeLink: string;
    fastOfferError: null;
    fastOfferReason: null;
    id: number;
    steamOfferId: null;
  };
}

export interface IStoreResponse {
  kits: IMarketplaceElement[];
  meta?: {
    amount: number;
    limit: number;
    offset: number;
  };
}

export interface IStoreFailureResponse {}

export interface IUpdatedParams {
  minOverprice: number;
  maxOverprice: number;
  query: string;
  page: {
    number: number;
  };
  sortBy: string;
  minPrice: number;
  maxPrice: number;
  type: string[];
  weapon: string[];
  exterior: string[];
  phase: string[];
}
