import { getToolBarStatus, toolBarChangeState, toolBarHide, toolBarShow } from 'core/ducks';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const useToolBar = () => {
  const toolBarState = useSelector(getToolBarStatus, shallowEqual);

  const dispatch = useDispatch();

  const handleHideToolBar = useCallback(() => dispatch(toolBarHide()), [dispatch]);

  const handleShowToolBar = useCallback(() => dispatch(toolBarShow()), [dispatch]);

  const handleToggleToolBar = useCallback(() => dispatch(toolBarChangeState()), [dispatch]);

  return {
    toolBarState,
    handleHideToolBar,
    handleShowToolBar,
    handleToggleToolBar,
  };
};
