import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDialogComponent } from 'core/interfaces';
import { Container, PaymentIcon, Subtitle, Title } from './payment-process-dialog.styles';
import { Button } from '@components/Common';

interface IPaymentProcessDialog extends IDialogComponent<boolean> {}

export const PaymentProcessDialog: FC<IPaymentProcessDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();

  return (
    <Container className="content">
      <PaymentIcon />
      <Title>{t('Payment in process')}</Title>
      <Subtitle>
        {t('Transaction is currently in process, it may take from few seconds up to 72 hours')}
      </Subtitle>
      <Button className="primary" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
    </Container>
  );
};
