import Immutable from 'immutable';
import { LotteryType } from './lottery-type.enum';
import { LotteryStatuses } from './lottery-statuses.enum';

export const LotteryRecord = Immutable.Record<ILotteryRecord>({
  status: LotteryStatuses.LOADING,
  free: [],
  deposit: [],
  // hourly: [],
  // daily: [],
  weekly: [],
  monthly: [],
  entities: {},
});

export interface ILotteryRecord {
  status: LotteryStatuses;
  free: number[];
  deposit: number[];
  // hourly: number[]
  // daily: number[]
  weekly: number[];
  monthly: number[];
  entities: Record<number, ILotteryEntity>;
}

export interface ILotteryEntity {
  type: LotteryType;
  gameEndTimestamp: string;
  hash: string;
  rand: string;
  salt: string;
  id: number;
  gameInfo: ILotteryGameInfo;
  tickets: number;
  players: any[];
  playersNumber: number;
  winnerInfo: ILotteryWinnerInfo;
  deposit: ILotteryDeposit;
}

export interface ILotteryDeposit {
  userChance: string;
  userDepositedInThisGame: number;
  userTickets: number;
}

export interface ILotteryGameInfo {
  prize: number;
  prizeImage: string;
  prizeName: string;
  ticketPrice: number;
}

export interface ILotteryWinnerInfo {
  id: number;
  ava_link: string;
  userName: string;
  tickets: [
    {
      ticketsFrom: number;
      ticketsTo: number;
    }
  ];
}
