import { useSelector } from 'react-redux';
import { IStore } from 'store/interface';
import { getMarketplaceElement } from '../selector';
import { IMarketplaceElement } from '../interfaces';

export const useMarketplaceElement = (entity: 'store' | 'purchases' | 'hotOffers', id: string) => {
  return {
    item: useSelector<IStore, IMarketplaceElement>(getMarketplaceElement(entity, id)),
  };
};
