import { shallowEqual, useSelector } from 'react-redux';
import { getPayoutMethods } from '../selectors';
import { IWithdrawalMethod } from '../interfaces/reducers.withdrawal.interface';
import { IStore } from 'store/interface';

export const usePayoutMethods = () => {
  const methods = useSelector<IStore, Record<string, IWithdrawalMethod>>(
    getPayoutMethods,
    shallowEqual
  );
  const cryptoArrayMethods = [];
  const others = [];
  const minCoinsForCrypto = [];
  const minForOtherPayments: Record<string, number> = {};

  for (let key in methods) {
    if (methods[key].gates[0].type === 'wallet') {
      cryptoArrayMethods.push(methods[key]);
      minCoinsForCrypto.push(methods[key].gates[0].minimum);
    } else {
      others.push(methods[key]);
      minForOtherPayments[methods[key].title] = methods[key].gates[0].minimum;
    }
  }
  return {
    cryptoArrayMethods,
    others,
    minCoinsForCrypto: Math.max(...minCoinsForCrypto),
    minForOtherPayments,
  };
};
