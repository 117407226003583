import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { currencyService } from '../../../services/currency/index';
import minusImg from '../../assets/minus.png';
import plusImg from '../../assets/plus.png';
import { addNotification } from '../../../modules/Notifications/duck';

interface ICurrencyInput {
  value: number;
  maxValue: number;
  minValue?: number;
  setValue: (bet: number) => void;
  disabled?: boolean;
}

const availableSteps: { [key: number]: number } = {
  0: 1,
  1: 1,
  2: 2,
  3: 5,
  4: 5,
  5: 5,
  6: 10,
  7: 10,
  8: 10,
  9: 10,
};

export const CurrencyInput: FC<ICurrencyInput> = ({
  value,
  maxValue,
  minValue,
  setValue,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string | number>(0.01);

  useEffect(() => {
    setInputValue(Number(currencyService.convert(value * 100).toString()));
  }, [value]);

  const stepAmount = (val: string | number) => {
    if (val === 0) val = inputValue;
    const bet = Math.pow(10, Math.floor(Math.log10(Number(val))));
    const ratio = availableSteps[Math.floor(Number(val) / bet)];

    return bet * ratio;
  };

  const isValidNumber = (val: string) => val && /^[+-]?\d+(\.\d+)?$/g.test(val);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target?.value;

    if (val.length > 6) return;

    let display: string | number = '';
    if (isValidNumber(val)) {
      display = Number(currencyService.revert(val));
    }

    if (isNaN(Number(val))) {
      setInputValue(0);
    }
    display = display || 0;
    setInputValue(val);
    setValue(Number(display) / 100);

    if (Number(val) > maxValue) {
      setInputValue(maxValue);
      setValue(maxValue);
      dispatch(
        addNotification({
          type: 'error',
          body: t('Max bet has been reached'),
        })
      );
    }
  };

  const setValueCallback = (val: number) => {
    let min = minValue ? minValue : 0.01;

    if (val > maxValue) {
      val = maxValue;
      dispatch(
        addNotification({
          type: 'error',
          body: t('Max bet has been reached'),
        })
      );
    }
    if (val < min || Number.isNaN(val)) val = min || 0;

    setInputValue(val);
    setValue(val);
  };

  const increment = () => setValueCallback(stepAmount((value ?? 0) * 2));

  const decrement = () => setValueCallback(stepAmount((value ?? 0) / 2));

  return (
    <Container>
      <ControlPanel>
        <Button type="button" onClick={decrement} disabled={disabled}>
          <ImgMinus src={minusImg} />
        </Button>
        <InputSum
          disabled={disabled}
          value={inputValue}
          type="number"
          inputMode="decimal"
          onChange={onTextChange}
          onBlur={onTextChange}
        />
        <Button type="button" onClick={increment} disabled={disabled}>
          <ControlImg src={plusImg} />
        </Button>
      </ControlPanel>
    </Container>
  );
};

const Button = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const ControlPanel = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 2.75rem;
  padding: 8px 15px 8px 15px;
  width: 100%;
`;

const ImgMinus = styled.img`
  filter: brightness(0.6);
  height: 2px;
  width: 17px;
`;

const ControlImg = styled.img`
  filter: brightness(0.6);
  height: 18px;
  width: 18px;
`;

const InputSum = styled.input`
  background: transparent;
  border: 0;
  color: var(--color-gray-200);
  font-weight: 400;
  max-width: 80px;
  min-width: 35px;
  text-align: center;

  &:disabled {
    opacity: 1;
  }
`;
