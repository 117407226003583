export default {
  'What is the Ticket System?': '什么是游戏票系统？',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    '当玩家下注时，他们会获得虚拟游戏票，其中一人将成为获胜者。如果您的物品价值为 1 美元，您可以获得 100 张游戏票，如果您的皮肤价值为 10 美元，则可获得 1000 张游戏票，依此类推。',

  // GAMES

  'How is the winner determined?': '如何确定获胜者？',
  'When is the winner determined?': '何时确定获胜者？',
  'How many bets can I place per one round?': '每个回合可以下注多少次？',
  'What Service Commission is there in this game?': '本游戏的手续费为多少？',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    '经典游戏适合喜欢高风险的游戏玩家。这些玩家将尝试常规下注，回合结束时在玩家之间抽奖，玩家凭运气获得奖励。',
  'The winner is determined by the Ticket System in the Classic Game.':
    '获胜者通过经典游戏中的游戏票系统确定。',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    '回合时间结束或者达到最大押注皮肤数后将确定获胜者。',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    '手续费为 0 至 10%，具体取决于奖励总和。\n一般赌注的随机皮肤将作为手续费。',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    '快速游戏 - 快速试试运气的最佳方式。只有 3 名玩家，最大赌注有限。获胜者将获得 3 名玩家的全部赌注。',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    '获胜者随机确定，不过赌注越大，获胜几率越大。',
  'One player can place one bet per one game round in this game.':
    '在本游戏中，一名玩家每个游戏回合仅可以下注一次。',
  'Where can I see my chances of the winning?': '在何处可以看到我的快点？',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    '获胜几率以百分比指示。您可以在玩家的回合赌注总和旁边看到自己的获胜几率（位于头像和已押注皮肤列表的右侧）',
  'The winner is determined once 3 players place their bets in the current game round.':
    '3 名玩家在当前游戏回合中下注后即会确定获胜者。',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    '手续费为 0 至 15%，具体取决于奖励总和。回合内一般赌注的随机皮肤将作为手续费。',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    '轮盘游戏 - 一种游戏模式，玩家在游戏中下注轮盘开出黑色、红色还是绿色分区。在此模式中，玩家使用快点作为赌注。回合结束时将确定获胜分区。',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    '如果开出黑色或红色，玩家将赢得 2 倍赌注。',
  'The green slot returns the placed bet multiplied by 14.': '如果是绿色分区，则会赢得 14 倍赌注。',
  'The bet sum of every slot can be different.': '每种颜色的赌注总和可以不同。',

  'How can I Get Fast Coins?': '如何获得快点',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1.参与推荐计划。通过推荐链接或代码邀请新玩家加入项目。对于每个被推荐人和他玩的每一场游戏，您都能获得快点',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2.使用您价值 1 美元及以上的皮肤兑换快点。1 美分对应 1 快点。您一次最多可以发送 30 个道具。单击此处，了解更多兑换信息。我们建议使用 Steam Inventory Helper 估价。',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3.通过支付系统充值。选择方便的支付方式并为帐户购买所需的快点数。',

  'How is the winner slot determined (fair game)?': '如何确定获胜分区（公平游戏）？',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    '在每个回合一开始，服务会生成一个介于 0 到 1 间的长随机数（例如 0.223088），并通过 sha224 算法进行加密。结果将在回合开始时显示。回合结束时，服务会将此加密数字乘以 15，进而获得获胜分区的编号。',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    '示例：回合开始时，加密数字为 0.223088232334703230728。服务将其乘以 15，得到 3.34632348495。因此，自然数为 3（红色分区）。在本回合中，获胜者将是下注开出红色分区的玩家。',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    '您可以查看获胜分区是否公平。回合结束时，记下加密数字并通过任何一种在线服务（例如，http://sha224.net）将其再加密一次。您将获得与回合开始时相同的哈希值。也就是说，游戏没有作弊。',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    '由于系统在回合开始时就已选定获胜分区，任何玩家都可以监视其是否发生变化 - 我们没有任何机会影响抽奖结果。',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    '在回合开始前，您可以下注无数次。每个分区的赌注总和可以不同。',

  'What bet limitations are there?': '赌注限制是多少？',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    '最低下注為 0,1 美元。，你可以在一個顏色一次下注最高 5000 美元',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    '注意！在同一輪回合之下不能同時對紅色和黑色下注。 綠色在每一回合都可以投注！',

  'When does the determination of the winner slot begin?': '何时开始确定获胜分区？',
  'The winner slot is determined at the end of a round.': '回合结束时将确定获胜分区。',

  'Is there any commission in this mode?': '这种模式是否有手续费？',
  'There is no commission at all. :)': '没有任何手续费。:)',
};
