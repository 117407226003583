import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useUserVerification } from 'core/User';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';

export const PayoutPreviewUserVerification = () => {
  const { verification } = useUserVerification();
  const { t } = useTranslation();
  return (
    <Container>
      <div className="verification-container">
        <div className="verification-container__status">
          <i className="icon-decagram" />
          <i className="icon-checked" />

          <div className="status-circle">
            {verification.status === 'pending' && <i className="icon-hourglass" />}
            {['verified'].includes(verification.status) && <i className="icon-lock-fill" />}
            {verification.status === 'failed' && <span className="status-circle__failed">!</span>}
          </div>
        </div>
        <div className="verification-container__title">
          {t('identification title', {
            context: verification.status,
          })}
        </div>
        <div className="verification-container__description">
          {verification.message}
          {/*{t('identification description', {*/}
          {/*  context: verification.status,*/}
          {/*})}*/}
        </div>
      </div>

      <Button
        className={`primary verify-action ${verification.status}`}
        as={Link}
        to="/withdrawal/method/verify"
      >
        {t('Identification')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 1fr min-content;
  padding: 1.5rem 1rem;
  min-height: 100%;
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(19, 26, 29, 1);

  & .verification-container {
    display: grid;
    grid-gap: 2rem;
    grid-auto-rows: min-content;
    align-self: center;

    &__status {
      display: flex;
      width: 5rem;
      height: 5rem;
      align-items: center;
      justify-content: center;
      align-self: center;
      justify-self: center;
      position: relative;

      & i {
        &.icon-decagram {
          font-size: 4.5rem;
          color: var(--blue);
          transform: rotate(18deg);
        }

        &.icon-checked {
          font-size: 1.5rem;
          position: absolute;
        }
      }

      & .status-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background-color: rgba(19, 26, 29, 1);

        position: absolute;
        bottom: 0;
        right: 8px;

        & .icon-hourglass {
          color: var(--yellow);
          font-size: 0.95rem;
          animation: flip 3s infinite ease-in-out;
        }

        & .icon-lock-fill {
          color: var(--color-white);
          font-size: 1.1rem;
        }

        &__failed {
          width: 1.2rem;
          height: 1.2rem;
          background-color: var(--color-red);
          border-radius: 50%;
          color: rgba(19, 26, 29, 1);
          font-weight: bold;
          text-align: center;
          line-height: 1.2rem;
          vertical-align: middle;
        }
        @keyframes flip {
          from,
          90% {
            transform: rotate(0);
          }
          to {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__title,
    &__description {
      text-align: center;
      line-height: 1.5rem;
      letter-spacing: 0.5px;
    }

    &__title {
      font-size: 1.25rem;
      color: var(--color-white);
    }

    &__description {
      font-size: 0.875rem;
      color: var(--color-white-600);
    }
  }

  & .verify-action {
    &.pending {
      pointer-events: none;
      color: var(--color-dark-200);
      background: var(--color-dark-600);
    }
  }
`;
