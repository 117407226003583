import { AnimatedRouter } from '@components/animated-router';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BattlePageList, CaseBattleGamePage, CreateBattlePage } from './pages';
// import { socketService } from 'services/socket.service';
// import { eventsTypes } from './ducks';
// import { useDispatch } from 'react-redux';

export const CaseBattleRouter: React.FC<RouteComponentProps> = ({ match }) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   eventsTypes.forEach(event => {
  //     socketService.subscribeV2({ event: event.event, action: e => dispatch(event.action(e)) });
  //   });
  //   return () =>
  //     eventsTypes.forEach(event => {
  //       socketService.unsubscribe(event.event);
  //     });
  // }, [dispatch]);

  return (
    <div className="content">
      <AnimatedRouter>
        {/* <Route path={`${match.url}/rules`} component={} />
            
            <Route
              path={`${match.url}/item/:itemId`}
              component={}
            /> */}
        <Route path={`${match.url}/create-battle`} exact component={CreateBattlePage} />
        <Route path={`${match.url}/history`} exact component={BattlePageList} />
        <Route path={`${match.url}/:battleId`} component={CaseBattleGamePage} />
        <Route path={match.url} exact component={BattlePageList} />
      </AnimatedRouter>
    </div>
  );
};
