export default {
  'What is the Ticket System?': '¿Qué es el Sistema de Billetes?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Cuando los jugadores hacen apuestas, consiguen billetes virtuales, uno de los cuales será ganador. Por cada skin depositada que cueste $1, obtendrá 100 billetes. Por una skin que cueste $10, obtendrá 1.000 billetes, etc.',

  // GAMES

  'How is the winner determined?': '¿Cómo se determina el ganador?',
  'When is the winner determined?': '¿Cuándo se determina el ganador?',
  'How many bets can I place per one round?': '¿Cuántas apuestas se puede hacer durante una ronda?',
  'What Service Commission is there in this game?': 'W¿Qué Comisión del Servicio tiene este juego?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'es un juego para los que les gusta apostar fuerte. Los que desean aprobar su suerte hacen una apuesta en el depósito general que se sortea entre los jugadores al final de la ronda.',
  'The winner is determined by the Ticket System in the Classic Game.':
    'En Classic Game el ganador se determina por el Sistema de Billetes.',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'El ganador se determina al final de la ronda o al llegar al máximo el número de las skins en el depósito.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'La Comisión del Servicio es de 0 a 10 %, depende de la suma del premio.\nComo la Comisión del Servicio se recogen skins ocasionales del depósito general.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'es un juego para aprobar la suerte al instante. En este juego participan sólo 3 jugadores, la apuesta máxima es limitada. El ganador obtiene el depósito general de los 3 jugadores.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'El ganador se determina al azar, pero cuanto más grande sea la apuesta, más alta será la probabilidad de ganar.',
  'One player can place one bet per one game round in this game.':
    'Un jugador puede hacer una apuesta durante una ronda del juego.',
  'Where can I see my chances of the winning?': '¿Dónde puedo ver mi probabilidad de ganar?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'La probabilidad de ganar se indica en por ciento. Se puede ver el porcentaje junto a la suma de apuesta de los jugadores de la ronda (a la derecha de los avatares y la lista de las skins depositadas).',
  'The winner is determined once 3 players place their bets in the current game round.':
    'El ganador será determinado después de que los 3 jugadores hagan sus apuestas en la ronda actual del juego.',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'La Comisión del Servicio es de 0 a 15 % según la suma del premio. Como la Comisión del Servicio se recogen skins ocasionales del depósito general.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'es un modo del juego en el que los jugadores apuestan en los sectores negros, rojos o verdes. En este modo para apostar se usan las Monedas Rápidas. El color ganador se determina al final de la ronda.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Si gana un sector negro o rojo, el jugador gana la apuesta hecha multiplicada por 2.',
  'The green slot returns the placed bet multiplied by 14.':
    'El sector verde devolverá la apuesta hecha multiplicada por 14.',
  'The bet sum of every slot can be different.':
    'La suma de apuesta de cada sector puede ser diferente',

  'How can I Get Fast Coins?': '¿Cómo se puede obtener las Monedas Rápidas?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Participe en el Programa de Referencia. Invite a nuevos jugadores (afiliados) en el proyecto con ayuda de enlace de referencia o código. Recibe monedas por cada afiliado/a y cada partido que él/ella juegue.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. Cambie sus skins que cuesten más de $1 por Monedas Rápidas. Por cada céntimo del valor de skin obtendrá una moneda. Se puede enviar hasta 30 artículos a la vez. Haga clic aquí para ver más información sobre el intercambio. Le recomendamos usar Steam Inventory Helper para determinar el valor de skins.',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. El valor de una moneda puede variar un poco debido a la tasa de cambio vigente. Le recomendamos usar Steam Inventory Helper con el fin de determinar el valor de sus skins.',

  'How is the winner slot determined (fair game)?':
    '¿Cómo se determina el sector ganador (juego limpio)?',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    'Al principio de la ronda el Servicio genera un número largo aleatorio de 0 a 1 (por ejemplo, 0.223088) y lo cifra vía el algoritmo sha224. El resultado se muestra al principio de la ronda. Al final de la ronda el Servicio multiplica el número cifrado por 15 obteniendo así el número del sector ganador.',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    'Por ejemplo: Al comienzo de la ronda el número codificado es 0.223088232334703230728. El Servicio lo multiplica por 15 obteniendo así el número 3.34632348495. El número entero resultado de la multiplicación es 3 (el sector rojo). En esta ronda, los ganadores serán aquellos que hayan hecho sus apuestas en el sector rojo.',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Puede comprobar si la victoria es justa o no independientemente. Para esto al final de la ronda tome el número codificado y vuelva a cifrarlo con ayuda de cualquier servicio en línea, por ejemplo, http://sha224.net. Obtendrá el mismo valor hash que figuró al principio de la ronda. Esto significará que el juego no ha sido manipulado.',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Ya que el sistema elige el sector ganador antes de comenzar el juego y cualquier jugador puede controlar sus cambios, no tenemos ninguna posibilidad de influir en los resultados del sorteo. Por esto el sistema es el garante de la limpieza de nuestros juegos.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Puede aumentar la tasa un número ilimitado de veces hasta comenzar la ronda. La suma de apuesta de cada sector puede ser diferente.',

  'What bet limitations are there?': '¿Qué limitaciones de apuestas existen en este juego?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'La apuesta mínima es de $0,1. En un solo color, puedes poner un máximo de $5000 de una vez.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    '¡Atención! Durante una tirada no puedes apostar al rojo y al negro a la vez. ¡Las apuestas al verde están disponibles en todas las rondas!',

  'When does the determination of the winner slot begin?':
    '¿Cuándo empieza la determinación del sector ganador?',
  'The winner slot is determined at the end of a round.':
    'El sector ganador se determina al final de la ronda.',

  'Is there any commission in this mode?': '¿Hay alguna Comisión en este modo?',
  'There is no commission at all. :)': 'No hay ninguna Comisión. :)',
};
