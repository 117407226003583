import { SellerTradeDialog } from './seller-trade-dialog';
import { BuyerTradeDialog } from './buyer-trade-dialog';
import { SaleDialog } from './sale-dialog';

import { DIALOG, transitions } from 'dialogs';
import { MarketplaceDialogs } from '../../../marketplace-dialogs.config';

DIALOG.add(MarketplaceDialogs.INVENTORY_SELLER_TRADE_DIALOG, {
  component: SellerTradeDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});

DIALOG.add(MarketplaceDialogs.INVENTORY_BUYER_TRADE_DIALOG, {
  component: BuyerTradeDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});

DIALOG.add(MarketplaceDialogs.MARKETPLACE_STORE_SALE, {
  component: SaleDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'marketplace',
});
