import styled from 'styled-components';

export const RadioButtonControl = styled('label')`
  & input {
    display: none;
  }

  & input:checked + span:before {
    border-width: 4px;
    background: var(--warning);
  }

  & > span:before {
    content: '';
    align-self: center;
    justify-self: self-start;
    display: block;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--color-dark-100);
    border: 3px solid var(--gray-220);
    transition: all 70ms linear;
  }

  & > span {
    justify-self: self-start;
    display: grid;
    grid-template-columns: 1.625rem 1fr;
    grid-template-rows: 3.75rem;
    grid-gap: 1.25rem;
    align-items: center;
    padding: 0 1.125rem;

    font-size: 13px;
    line-height: 0.875rem;
    letter-spacing: 0.286px;
    color: var(--color-white);
  }
`;
