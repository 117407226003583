import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import {
  Container,
  Description,
  DescriptionContainer,
  Progress,
  ProgressBar,
  ProgressReady,
  StateContainer,
  StateText,
} from './task-item.styles';
import { GameTypes, LRQuestParams } from 'modules/event-module/comics-event/interfaces';
import { actionTakeReward } from 'modules/event-module/comics-event/duck/leader-race-duck';
import { GamesName } from '../weekly-tasks/weekly-tasks';
import { GamesIcons } from 'assets';
import { ModuleName } from 'modules/Lobby/components';
import { eventImagesPath, EventIcons } from 'modules/event-module/comics-event/assets';

interface TaskItemProps {
  gameTypeId: GameTypes;
  id: number;
  name: string;
  params: LRQuestParams;
  progress: number;
  rewardTaken: boolean;
}

export const TaskItem: FC<TaskItemProps> = React.memo(
  ({ name, params, gameTypeId, progress, rewardTaken, id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const ref = useRef<HTMLDivElement | null>(null);
    const percent = (100 * progress) / params.count;

    const handlerTakePrize = () => {
      dispatch(actionTakeReward(id));
    };

    const redirectHandler = (gameTypeId: GameTypes) => {
      if (GamesName[gameTypeId]) {
        history.push(`../${GamesName[gameTypeId].toLocaleLowerCase()}`);
      }
    };

    return (
      <Container
        ref={ref}
        isTaskReady={rewardTaken}
        isProgressReady={progress >= params.count && !rewardTaken}
      >
        <DescriptionContainer isTaskReady={rewardTaken}>
          <Description isTaskReady={rewardTaken}>
            {t(name, {
              ...params,
              postProcess: 'with-template',
            })}
          </Description>
          {GamesName?.[gameTypeId] && (
            <>
              {' '}
              <Description isTaskReady={rewardTaken} onClick={() => redirectHandler(gameTypeId)}>
                {GamesIcons[GamesName?.[gameTypeId]?.toLowerCase() as ModuleName]?.(20, 20)}{' '}
                {GamesName?.[gameTypeId]}
              </Description>
            </>
          )}
        </DescriptionContainer>
        {progress < params.count && (
          <StateContainer>
            <Progress>
              <StateText>
                {progress}/{params.count}
              </StateText>
              <ProgressBar>
                <ProgressReady percent={percent > 100 ? 100 : percent} />
              </ProgressBar>
            </Progress>
          </StateContainer>
        )}
        {progress >= params.count && !rewardTaken && (
          <StateContainer onClick={handlerTakePrize}>
            <StateText className="accent">{t('takePrize')}</StateText>
            <img src={eventImagesPath.bronzeDailyPath} width={16} height={16} alt="" />
          </StateContainer>
        )}
        {rewardTaken && (
          <StateContainer className="ready">
            <EventIcons.Completed />
            <img src={eventImagesPath.bronzeDailyPath} width={24} height={24} alt="" />
          </StateContainer>
        )}
      </Container>
    );
  },
  isEqual
);
