import { Reducer } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { delay, map, pluck, tap, throttleTime } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import {
  DoubleActionsTypes,
  IDoubleGame,
  IDoubleGameHistory,
  IFetchCreateBidRequest,
  IMember,
} from '../interfaces';
import { SectorColors } from '../utils';
import { dialogOff } from 'core/ducks/dialog';
import { ParticipateDialogs } from 'core/interfaces';
import SoundService from 'services/sound.service';

const sound = new SoundService();

const defaultGameState: IDoubleGame = {
  md5: null,
  number: null,
  rand: null,
  salt: null,
  timeOldEnds: null,
  history: [],
  members: {
    [SectorColors.ZERO]: {},
    [SectorColors.RED]: {},
    [SectorColors.BLACK]: {},
  },
  totalSum: {
    [SectorColors.ZERO]: 0,
    [SectorColors.RED]: 0,
    [SectorColors.BLACK]: 0,
  },
};

function generateId() {
  return Math.round(Math.random() * 100000);
}

export const reducer: Reducer<any, any> = (state = defaultGameState, action) => {
  switch (action.type) {
    case DoubleActionsTypes.UPDATE_GAME_STATE: {
      const { topPlayers, ...data } = action.payload.data;

      const _history = (data?.history ?? []).reduce(
        (acc: IDoubleGameHistory[], sector: number) => [{ id: generateId(), sector }, ...acc],
        []
      );

      const history = [..._history, ...state.history].slice(0, 10);

      const members = (topPlayers ?? []).reduce(
        (acc: any, item: IMember) => ({
          ...acc,
          [item.betType]: { ...acc[item.betType], [item.playerId]: item },
        }),
        state.number !== data.number && data.number ? defaultGameState.members : state.members
      );

      if (state.number !== data.number && data.number && state.number) {
        return {
          ...defaultGameState,
          ...data,
          members,
          history,
        };
      }

      return {
        ...state,
        ...data,
        members,
        history,
      };
    }

    default: {
      return state;
    }
  }
};

const updateGameState = (data: Partial<IDoubleGame>) => ({
  type: DoubleActionsTypes.UPDATE_GAME_STATE,
  payload: {
    data,
  },
});

const listenerEic: Epic = (action$, _, { socket }) =>
  merge(
    fromEvent(socket.io, 'all:init').pipe(pluck('state', 'rooms', 'double')),
    fromEvent(socket.io, 'rooms:double:update')
  ).pipe(map(data => updateGameState(data)));

export const fetchCreateBid = (payload: IFetchCreateBidRequest) => ({
  type: DoubleActionsTypes.FETCH_CREATE_PARTICIPATE,
  payload,
});

export const participateEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(DoubleActionsTypes.FETCH_CREATE_PARTICIPATE),
    throttleTime(500),
    pluck('payload'),
    tap(({ color, amount }) => {
      sound.play('Double', 'My bet');
      socket.io.emit('game:double:bet', { color, amount });
    }),
    delay(500),
    map(() => dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG))
  );

export const doubleEpics = combineEpics(listenerEic);
