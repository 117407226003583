import React, { FC } from 'react';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';
import { useCreatePay, useGatePay, useMethodPay } from '../../../hooks';
import { Container, Fields, SteamLinkInput } from './pay-items.styles';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { useUserTradeLinks } from 'core/User';
import { Loader } from '@components/Loader';

export const PayItems: FC = () => {
  const { t } = useTranslation();
  const { method } = useMethodPay();
  const { gate } = useGatePay();
  const gates = method?.gates || [];
  const [handleCreatePay] = useCreatePay();
  const { handleOpenDialog } = useDialog(userModals.STEAM_TRADE_LINK);
  const { tradeLinks } = useUserTradeLinks();

  if (gates.length === 0) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <span>{t('Refill via SIH')}</span>
      <div className="input-container">
        <Fields>
          <SteamLinkInput
            name="itempay"
            defaultValue={tradeLinks['steam']}
            onClick={handleOpenDialog}
            type="text"
            readOnly={true}
            placeholder={t('steamApi.enterLink')}
          />
        </Fields>
        <Button
          className="primary"
          onClick={handleCreatePay({
            name: method?.name,
            gate: gates[gate],
            isOpenNewTab: true,
            wallet: tradeLinks['steam'],
          })}
          disabled={!tradeLinks['steam']}
        >
          {t('refill')}
        </Button>
      </div>
    </Container>
  );
};
