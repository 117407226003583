import { ActionsObservable, Epic, StateObservable, ofType } from 'redux-observable';
import { ACTION_TYPE } from '../actionType';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { actionRequestCaseList } from '../case-list-duck/case-list.duck';
import CasesRepository from '../../repository/casesRepository';
import {
  ActionRequestCategory,
  ActionSetSelectedCategory,
  CategoryActions,
  CategoryNotDetailed,
  StateCategory,
} from './category.interfaces';
import { IStore } from 'store/interface';

//init state for Reducer
const initialState: StateCategory = {
  data: [],
  isLoading: true,
};

///ActionRequest
export const actionRequestCategory = () => ({
  type: ACTION_TYPE.CATEGORY_ACTION_REQUEST,
});
///ActionResponse
const actionResponseCategory = (payload: CategoryNotDetailed[]) => ({
  type: ACTION_TYPE.CATEGORY_ACTION_RESPONSE,
  payload,
});

///ActionError
const actionErrorCategory = () => ({
  type: ACTION_TYPE.CATEGORY_ACTION_ERROR,
});
///EPIC
export const categoryEpic: Epic = (
  action$: ActionsObservable<ActionRequestCategory>,
  state$: StateObservable<IStore>
) =>
  action$.pipe(
    ofType(ACTION_TYPE.CATEGORY_ACTION_REQUEST),
    switchMap(() =>
      CasesRepository.fetchCategory().pipe(
        mergeMap(({ response }: { response: CategoryNotDetailed[] }) => {
          return merge(
            of(actionResponseCategory(response)),
            of(
              actionRequestCaseList({
                id: state$.value.cases.selectedCategory,
                filterParams: {
                  ...state$.value.cases.sortParams,
                  sortBy: state$.value.cases.sortParams.sortBy ?? 'popular',
                },
              })
            )
          );
        }),
        catchError(() => of(actionErrorCategory()))
      )
    )
  );
///Reducer
export const category = (state = initialState, action: CategoryActions) => {
  switch (action.type) {
    case ACTION_TYPE.CATEGORY_ACTION_RESPONSE:
      action.payload.unshift({
        id: 'all',
        name: 'all',
        image: null,
        cases: [],
        disableAt: null,
        grid: null,
        sortable: false,
        tags: [],
      });
      action.payload.push({
        id: 'free',
        name: 'free',
        image: null,
        cases: [],
        disableAt: null,
        grid: null,
        sortable: false,
        tags: [],
      });
      return {
        data: action.payload,
        isLoading: false,
      };

    case ACTION_TYPE.CATEGORY_ACTION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPE.CATEGORY_ACTION_REQUEST: {
      return {
        ...state,
        isLoading: state.data.length === 0,
      };
    }
    default:
      return state;
  }
};

export const actionSetSelectedCategory = (category: string) => {
  return {
    type: ACTION_TYPE.SET_SELECTED_CATEGORY,
    payload: category,
  };
};

export const selectedCategory = (state: string = 'all', action: ActionSetSelectedCategory) => {
  switch (action.type) {
    case ACTION_TYPE.SET_SELECTED_CATEGORY: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
