import { useDispatch } from 'react-redux';
import { watchSteamSettings } from '../ducks/duck';
import { useCallback } from 'react';

export const useWatchSteamSettings = () => {
  const dispatch = useDispatch();

  const handleOpenSettings = useCallback(
    (url: string) => () => {
      dispatch(watchSteamSettings(url));
    },
    [dispatch]
  );

  return {
    handleOpenSettings,
  };
};
