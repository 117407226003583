import { createSelector } from 'reselect';
import { IStore } from 'store/interface';
import type {
  IHistoryGame,
  IHistoryGameRecord,
  IPlayer,
  IPlayers,
  IPlayingBet,
} from './interfaces';
import { protectFactorType, signTypes } from './interfaces';

export const getGame = ({ hilo }: IStore) => hilo.game;

export const getterHistory = ({ hilo }: IStore) => hilo.history;

export const getGameHistory = createSelector(getterHistory, history => {
  return history
    .slice(0, 9)
    .reduce(
      (acc: IHistoryGame[], item: IHistoryGameRecord) => [
        ...acc,
        { ...item.toJS(), offset: acc.length + 1 },
      ],
      []
    );
});

export const getStatistics = createSelector(
  getterHistory,
  (_: any, range: number) => range,
  (history, range) => {
    const statistic = history
      .slice(0, range)
      .reduce((acc: { [k: string]: number[] }, e: IHistoryGameRecord) => {
        const { sign, color, value } = e.getIn(['card']);
        const id = e.get('id');
        const previousCard = e.get('previousCard');

        if (value !== 0) {
          const factorType = previousCard.value > value ? 'lo' : 'hi';
          acc = {
            ...acc,
            [factorType]: acc[factorType] ? [...acc[factorType], id] : [id],
          };
        }

        if (color in signTypes) {
          acc = {
            ...acc,
            [signTypes[color][0]]: acc[signTypes[color][0]]
              ? [...acc[signTypes[color][0]], id]
              : [id],
          };
        }

        if (sign in signTypes) {
          acc = signTypes[sign].reduce(
            (ac: { [k: string]: number[] }, key: any) => ({
              ...ac,
              [key]: ac[key] ? [...ac[key], id] : [id],
            }),
            acc
          );
        }

        return acc;
      }, {});

    return statistic;
  }
);

export const getPreviousFactorType = ({ hilo }: IStore, type: string) =>
  hilo.game.getIn(['previousFactors'])[type];

export const getFactorType = ({ hilo }: IStore, type: string) => hilo.game.getIn(['factors'])[type];

export const getPlayer = ({ hilo, user }: IStore, type: string) =>
  hilo.players.getIn([user.base.get('id'), type]);

export const getProtecFactor = ({ hilo, user }: IStore, type: string) =>
  hilo.players.getIn([user.base.get('id'), protectFactorType[type]]);

export const getPlayers = createSelector(
  ({ hilo }: IStore) => hilo.players,
  (players: IPlayers) =>
    players
      .valueSeq()
      .reduce((acc: IPlayingBet[], item: IPlayer) => acc.concat(item.valueSeq().toArray()), [])
      .sort((l: IPlayingBet, r: IPlayingBet) => (l.amount < r.amount ? 1 : -1))
);
