import styled from 'styled-components';

export const LobbyContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 9px;
  margin-bottom: 10px;
`;

export const ModulesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 30%);
  grid-gap: 8px;
  grid-auto-rows: 1fr;
  padding: 1px;
  justify-content: center;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`;

export const ModulesTypeTitle = styled.div`
  margin: 2rem 1rem 0.5rem;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: var(--color-white);
`;

type IStickyHistoryNavigationStyle = {
  showHistory: boolean;
};

export const StickyHistoryNavigation = styled.div<IStickyHistoryNavigationStyle>`
  position: fixed;
  left: 0;
  right: 0;
  height: 110px;
  bottom: ${({ showHistory }) => (showHistory ? 0 : -100)}%;
  z-index: 100;
  transition: bottom 0.3s cubic-bezier(0.4, 0, 1, 1);
`;
