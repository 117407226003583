export default {
  Cart: 'Cart',
  Price: 'Price',
  Type: 'Type',
  Categories: 'Categories',
  Rarity: 'Rarity',

  OK: 'OK',
  Clear: 'CLEAR',
  Autoselect: 'Autoselect',
  Remove: 'Remove',
  Bet: 'Bet',
  Min: 'Min',
  Max: 'Max',

  'it.': '',

  Total: 'Sum:',
  'Items:': 'Items: ',
  'Repeat trade': 'Repeat trade',

  Pistols: 'Pistols',
  Rifles: 'Rifles',
  'Submachine guns': 'Submachine guns',
  Heavy: 'Heavy',
  Knives: 'Knives',
  Gloves: 'Gloves',
  Keys: 'Keys',
  Other: 'Other',

  'Base Grade': 'Base Grade',
  'Consumer Grade': 'Consumer Grade',
  'Industrial Grade': 'Industrial Grade',
  Restricted: 'Restricted',
  'Mil-Spec Grade': 'Mil-Spec Grade',
  Classified: 'Classified',
  'High Grade': 'High Grade',
  Covert: 'Covert',
  Remarkable: 'Remarkable',
  Exotic: 'Exotic',
  Contraband: 'Contraband',
  Extraordinary: 'Extraordinary',

  Buy: 'BUY',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': ' the limitations of the store',

  'Store only for the withdrawal of winnings or unused money. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Store only for the withdrawal of winnings or unused money. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.',
  'Your purchase will be delivered within 15 minutes.':
    'Your purchase will be delivered within 15 minutes.',
  'Congratulations on your purchase!': 'Congratulations on your purchase!',
  'Order approving': 'Order approving',
  Filters: 'Filters',

  'By price': 'By price',
  Filter: 'Filter',

  'Trade bot': 'Trade bot',
  'Verification code': 'Verification code',
  'You can accept trade offer using steam mobile app, browser or steam client':
    'You can accept trade offer using steam mobile app, browser or steam client',
  'Trade offer is automatically cancelled 3 minutes after creation':
    'Trade offer is automatically cancelled 3 minutes after creation',

  'Not found': 'Not found',
  'At present it is unable to find any items on your request. Try again later.':
    'At present it is unable to find any items on your request. Try again later.',

  common: 'Common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',

  'Not accepted': 'Not accepted',
  'To bet with {{gameType}} items you need to attach your Steam account to CSGOFAST profile and attach a trade-link in your profile settings.':
    'To bet with {{gameType}} items you need to attach your Steam account to CSGOFAST profile and attach a trade-link in your profile settings.',
  'Skins available': 'Skins available',
  for_refill: 'Sell for',
  for_market: 'Buy for',
  'Show only items with fast delivery': 'Show only items with fast delivery',
};
