import styled from 'styled-components';

export const Header = styled.div`
  align-items: center;
  background: var(--color-dark-60);
  color: var(--color-white);
  display: flex;
  height: var(--size-nav-bar);
  justify-content: center;
  padding: 0 1rem;
  position: relative;

  span {
    font-size: 0.94rem;
    font-weight: 700;
  }
`;

export const BackBtn = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  height: 30px;
  left: 1.25rem;
  padding-left: 0;
  position: absolute;
  width: 30px;

  & > i[class^='icon-'] {
    display: block;
    font-size: 1rem;
  }

  & > i[class='icon-arrow'] {
    transform-origin: center;
    transform: rotate(90deg);
  }
`;
//---------------------------------

export const Container = styled.div`
  align-items: center;
  background: var(--color-dark-60);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--size-nav-bar));
  justify-content: flex-start;
  padding: 1.5rem;
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;

export const CropWrap = styled.div`
  height: calc(100% - 6rem);
  padding: 1rem 0;
  width: 100%;

  .cropper-modal {
    background: transparent;
  }

  .cropper-point.point-n,
  .cropper-point.point-s,
  .cropper-point.point-w,
  .cropper-point.point-e {
    display: none;
  }

  .cropper-point.point-se,
  .cropper-point.point-ne,
  .cropper-point.point-nw,
  .cropper-point.point-sw {
    height: 10px;
    width: 10px;
  }
`;

export const Confirm = styled.button`
  background: var(--blue);
  border-radius: var(--size-radius-m);
  border: 0;
  color: var(--color-white);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  margin-top: auto;
  padding: 1rem;
  width: 100%;
`;
