import React, { useCallback, useEffect, useState } from 'react';
import {
  ButtonWrapper,
  ChooseCases,
  Container,
  CountContainer,
  Description,
  IconContainer,
  Wrapper,
} from './CreateGame.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useDispatch } from 'react-redux';
import { actionRequestCaseList } from 'games/CaseGame';
import { ChosenCases } from './components/ChosenCases/ChosenCases';
import {
  addRandomCases,
  setDefaultTotalPrice,
} from 'games/CaseBattle/ducks/create-battle-duck/create-battle-duck';
import { useGetIdsForCreateBattle, useGetTotalPrice } from 'games/CaseBattle/hooks';
import { GameSettings } from './components/GameSettings';
import { useGetCaseListData } from 'games/CaseGame/hooks';
import { getCasesByRandom } from 'games/CaseBattle/utils';
import { MAX_RANGE, MIN_RANGE } from 'games/CaseBattle/constants';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/hooks';
import { AddCasesConfig } from 'games/CaseBattle/dialog-config';
import { SvgIcons } from 'games/CaseGame/assets';

export const CreateGame = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { casesData } = useGetCaseListData();
  const { caseAmount, casesForBattle } = useGetIdsForCreateBattle();
  const { totalPrice } = useGetTotalPrice();
  const [isAnimate, setAnimate] = useState(false);
  const { handleOpenDialog } = useDialog(AddCasesConfig.ADD_CASE);

  useEffect(() => {
    dispatch(
      actionRequestCaseList({
        id: 'all',
        filterParams: { sortBy: 'popular', battles: true },
        isBattle: true,
      })
    );
    return () => dispatch(setDefaultTotalPrice());
  }, [dispatch]);

  const randomCases = useCallback(
    (price?: number) => {
      const priceForCount = price ?? totalPrice;
      if (priceForCount > MIN_RANGE && priceForCount <= MAX_RANGE) {
        const { cases, price } = getCasesByRandom(priceForCount * 100, casesData);
        dispatch(addRandomCases(cases, price));
      }
      setAnimate(prev => !prev);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [casesData, totalPrice]
  );

  const onTransitionEndHandler = () => {
    setAnimate(prev => !prev);
  };

  return (
    <Container>
      <Wrapper className="tablet">
        <ButtonWrapper onClick={() => randomCases()}>
          <IconContainer>
            <CaseBattleIcon.RandomIcon
              className={`${isAnimate ? 'animate' : ''}`}
              onTransitionEnd={onTransitionEndHandler}
            />
          </IconContainer>
          <Description>{t('random')}</Description>
        </ButtonWrapper>
        <ButtonWrapper onClick={handleOpenDialog}>
          <IconContainer>
            <CaseBattleIcon.AddCaseIcon />
            {!!caseAmount && <CountContainer>{caseAmount}</CountContainer>}
          </IconContainer>
          <Description>
            {!!caseAmount ? `${t('cases')} ${caseAmount}/10` : t('addCases')}
          </Description>
        </ButtonWrapper>
      </Wrapper>
      {!!casesForBattle.length ? (
        <ChosenCases caseIds={casesForBattle} />
      ) : (
        <ChooseCases>
          <SvgIcons.Arrow />
          <span> {t('chooseCases')}</span>
        </ChooseCases>
      )}
      <GameSettings generateCasePackHandler={randomCases} />
    </Container>
  );
});
