import { Map, Record as iRecord } from 'immutable';
import { IPremium, IUserExperienceLevel } from 'core/User';
import { IQuest, IRaceRecord } from 'modules/LeadersRace/interfaces/reducer.leaders.interfaces';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';

// export const AccountsRecord = iRecord<IAccount>({
//   loading: false,
//   profile: {
//     activity: [],
//     avatar: '',
//     chevron: '',
//     chevronImage: '',
//     country: '',
//     date_registred: '',
//     email: { address: null },
//     experience: { level: 0 },
//     id: null,
//     name: '',
//     premium: { until: null, goldLink: '' },
//     profileType: 'demo',
//     providers: {},
//     statsIsHidden: false,
//   },
//   statistics: {
//     leader: { fastPlace: null, greatPlace: null, regularPlace: null },
//     profile: {},
//     quest: null,
//   },
// })

// export interface IAccount {
//   loading: boolean
//   profile: IAccountProfile
//   statistics: IAccountStatistic
// }

export const AccountsRecord = iRecord<IAccount>({
  loading: false,
  profile: {
    activity: { registered: '' },
    avatar: '',
    country: '',
    experience: {
      level: 0,
      nextLevelXp: 0,
      xp: 0,
      userCanUpdateLevel: false,
    },
    id: 0,
    links: {
      steam: null,
      vgo: null,
    },
    name: '',
    premium: {
      until: null,
      goldLink: '',
    },
    privacy: {
      socialsIsHidden: true,
      statisticsIsHidden: true,
    },
    profileType: '',
    providers: [],
    referral: {
      availableSum: 0,
      code: null,
      count: 0,
      earnedSum: 0,
      level: 0,
    },
    rewards: {
      freeSpins: 0,
      blueCases: 0,
      orangeCases: 0,
      silverCases: 0,
    },
    statistics: [],
    theme: null,
  },
});

export interface IAccount {
  loading: boolean;
  profile: IUserInfo;
}

// export interface IAccountProfile {
//   id: number | null
//   name: string
//   avatar: string
//   chevron: string
//   chevronImage: string
//   profileType: string
//   date_registred: string
//   country: string
//   activity: any[]
//   email: IAccountEmail
//   experience: IAccountExperience
//   premium: IAccountPremium
//   providers: IAccountProviders
//   statsIsHidden: boolean
// }

export interface IAccountEmail {
  address: string | null;
}

export interface IAccountExperience extends IUserExperienceLevel {}

export interface IAccountPremium extends IPremium {}

export type IAccountProviders = Record<string, { name: string }>;

export type IAccountStatisticLeader = Record<string, number>;

export interface IASIPlaceholder {
  amount: number;
  raceType: string;
  type: string;
}

export interface IASItem {
  amount: number;
  color: string;
  createdAt: string;
  userId: number;
}

export interface IASIQuest extends IASItem {
  data: { questId: number };
  detailed: Partial<IQuest>;
}
export interface IASIRace extends IASItem {
  data: { raceId: number };
  detailed: Partial<IRaceRecord>;
}

export type IASQItems = (IASIPlaceholder | IASIQuest | IASIRace)[];

export interface IASQPlayer {
  date: string;
  items: IASQItems;
}

export interface IAccountStatistic {
  leader: IAccountStatisticLeader;
  quest: IAccountStatisticQuests;
  profile: any;
}

export type IAccountStatisticQuests = Map<string, IASQPlayer[]>;

export type IAccountRecord = iRecord<IAccount>;
