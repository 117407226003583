import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

export class UserPermissionsRepository {
  static fetchCheckUser(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/steam/check-user`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchUpdateApiKey(key: string): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/users/me/steam-api-key`,
      method: 'PATCH',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key }),
    });
  }
}
