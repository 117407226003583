interface IGame {
  items: IGameItem[];

  filter(param: (item: IGameItem) => boolean): any;
}

interface IGameItem {
  value: number;
  itemPrice: number;
  playerId: number;
  playerImage: string;
  playerName: string;
  inCom: boolean;
}

/**
 * функция преобразования истории игр.
 * Подсчитывает шансы игроков и определяет победителя
 * @param(game)
 * @return (Function)
 * */
export const historyAdapter = (game: IGame) => {
  const winBet = game.items.reduce((value: number, item: IGameItem) => {
    return value + parseFloat(String(item.itemPrice));
  }, 0);
  return getPlayersChances(game.items, winBet, []);
};

interface IChance {
  playerImage: string;
  playerName: string;
  playerChance: number;
  playerId: number;
}

/**
 * рекурсивная функция подсчета количества предметов.
 * @param(items, winBet, chances)
 * @return (Array<> | Function)
 * */
const getPlayersChances = (items: IGameItem[], winBet: number, chances: IChance[] = []): any => {
  if (items.length === 0) return chances;

  const currentItem = items[0];

  const bet = items
    .filter((item: IGameItem) => item.playerId === currentItem.playerId)
    .map((item: IGameItem) => parseFloat(String(item.itemPrice)))
    .reduce((value: number, price: number) => price + value, 0);

  const chance = (bet / winBet) * 100;

  const userChance = {
    playerImage: currentItem.playerImage,
    playerName: currentItem.playerName,
    playerChance: parseFloat(chance.toFixed(2)),
    playerId: currentItem.playerId,
  };

  return getPlayersChances(
    items.filter((item: IGameItem) => item.playerId !== currentItem.playerId),
    winBet,
    [...chances, userChance]
  );
};
/**
 * функция подсчета игрового приза с учетом комиссии.
 * @param(items)
 * @return (Number)
 * */
export const getPlayerWinningCost = (items: IGame) => {
  return items
    .filter((item: IGameItem) => !item.inCom)
    .reduce((cost: number, item: IGameItem) => item.itemPrice + cost, 0);
};

/**
 * Преобразование таймера
 * @param {*} timeStamp
 */
export const calculateTimer = (timeStamp: number): string => {
  const minutes = Math.floor(timeStamp / 60);
  const seconds = Math.round(timeStamp % 60);
  if (seconds <= 0 && minutes <= 0) {
    return '00:00';
  }
  return `${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`;
};
/**
 * Большой таймер
 * @param {*} hours
 * @param {*} minutes
 * @param {*} seconds
 */
export const calculateLongTimer = (hours: number, minutes: number, seconds: number): string => {
  if (seconds <= 0 && minutes <= 0 && hours <= 0) return '00 : 00 : 00';
  return `${hours < 10 ? `0${hours}` : hours} : ${minutes < 10 ? `0${minutes}` : minutes} : ${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};
export const debounce = (func: any, delay: number) => {
  let inDebounce: any;
  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export const getRandomInt = (min: number = 0, max: number = 10): number => {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  rand = Math.round(rand);
  return rand;
};
