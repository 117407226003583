import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { IMessage } from '../interfaces/reducer.interface';

import { getUserAvatarUrl } from 'core/utils/image.utils';
import { rouletteColors } from 'core/utils/colors.utils';
import { stickers } from '../stickers.utils';
import { textLimit } from 'core/utils/text.utils';
import { useLoadAccountProfile } from 'modules/Accounts';

interface IBubble {
  message: IMessage;
  nextUserId: number | null;
}

export const Bubble: FC<IBubble> = memo(({ message, nextUserId }) => {
  const { handleOpenProfile } = useLoadAccountProfile();

  return (
    <Msg data-automation-id={`chat_message-${message.time}`} myBubble={message.my}>
      {!message.my && message.userId !== nextUserId && (
        <Avatar
          src={getUserAvatarUrl(message.userImage)}
          onClick={handleOpenProfile(message.userId)}
        />
      )}
      <ContentMsg myBubble={message.my} isStickers={!!message.stickerPack}>
        {!message.my && !message.stickerPack && (
          <Username color={rouletteColors[(message.userId % rouletteColors.length) - 1]}>
            {textLimit(message.userName, 20)}
          </Username>
        )}
        {message.message}
        {stickers[message.stickerPack] && (
          <Sticker src={`${stickers[message.stickerPack].path}/${message.stickerId}.png`} />
        )}
        <Time myBubble={message.my}>{message.time}</Time>
      </ContentMsg>
    </Msg>
  );
}, isEqual);

interface IBubbleStyle {
  myBubble?: boolean;
  color?: string;
  isStickers?: boolean;
}

const Msg = styled.div<IBubbleStyle>`
  display: flex;
  flex-shrink: 0;
  margin-top: 5px;
  flex-direction: row;
  width: 100%;
  position: relative;

  font-size: 15px;
  justify-content: ${p => (p.myBubble ? 'flex-end' : 'flex-start')};
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  margin: 0 3px 0px 9px;
  border-radius: 50%;
  align-self: flex-end;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: auto;
`;

const ContentMsg = styled.div<IBubbleStyle>`
  display: flex;
  flex-flow: column;
  max-width: 70%;
  background: ${p =>
    (p.isStickers && 'transparent') || (p.myBubble && '#33ffff') || 'var(--mGray-100)'};
  border-radius: 18px;
  margin-left: 59px;
  padding: 9px 19px 5px 11px;
  position: relative;
  z-index: 101;

  color: #000;
  font-size: 15px;
  word-wrap: break-word;

  &::before {
    content: '\\e90d';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 3px;
    font-family: 'icomoon';
    color: ${p =>
      (p.isStickers && 'transparent') || (p.myBubble && 'var(--aqua-300)') || 'var(--mGray-100)'};
    font-size: 20px;
    z-index: -1;
    ${p =>
      p.myBubble ? 'right: -5px; transform: scaleX(-1);' : 'left: -5px; transform: scaleX(1);'}
  }
`;

const Username = styled.div<IBubbleStyle>`
  color: ${props => props.color};
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
`;

const Time = styled.span<IBubbleStyle>`
  font-size: 11px;
  font-weight: bold;
  font-style: italic;
  color: ${p => (p.myBubble ? 'var(--aqua-800)' : '#999999')};
  margin-top: 4px;
  text-align: end;
`;

const Sticker = styled.img`
  width: 128px;
  height: 128px;
  object-fit: contain;
`;
