import styled from 'styled-components';

export const DefaultContainer = styled.div`
  display: grid;
  grid-template-columns: 0.016fr 0.0586fr 0.106fr 0.186fr repeat(2, 0.132fr) 0.186fr 0.106fr 0.0586fr 0.016fr;
  grid-template-rows: repeat(9, 1fr);
  position: absolute;
  inset: 0;
  z-index: 2;
  opacity: 0;
  transition: all 200ms linear 100ms;
  transform: translateZ(1px);
  will-change: opacity;

  @supports not (aspect-ratio: 375 / 212) {
    height: calc(min(100vw, 100vh) / (375 / 212));
  }

  & .line-indicator {
    height: 2px;
    align-self: center;
    background: var(--color-combo-line);
    visibility: hidden;

    &:nth-child(5) {
      grid-column-start: 5;
    }

    &:nth-child(6) {
      grid-column-start: 6;
    }

    &:nth-child(7) {
      grid-column-start: 7;
    }

    &:nth-child(8) {
      grid-column-start: 8;
    }

    &:nth-child(9) {
      grid-column-start: 9;
    }
  }

  & .left-indicator,
  & .right-indicator {
    align-self: center;
    width: 50%;
    height: 60%;
    clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%, 0 49%);
    background: var(--color-combo-line);
    visibility: hidden;
  }

  & .left-indicator {
    transform: rotateY(-180deg);
  }

  & .right-indicator {
    justify-self: self-end;
    grid-column-start: 10 !important;
  }

  &.visible {
    opacity: 1;
    transition: all 0ms linear;

    & .left-indicator,
    & .right-indicator,
    & .line-indicator {
      visibility: visible;
    }
  }

  & .combo-line-grid-view {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin: 0 3%;
    position: absolute;
    inset: 0;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: -1rem;
      background: rgba(19, 26, 29, 70%);
      z-index: -1;
    }

    & > div {
      & img {
        box-shadow: 0 0 0.75rem var(--color-combo-line);
        border: 2px solid var(--color-combo-line);
      }
    }
  }
`;
