import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ILobbyWrapper {
  title: string;
  layoutColor: string;
  moreAt?: string;
  children: any;
}

export const LobbyWrapper: React.FC<ILobbyWrapper> = ({ title, layoutColor, children, moreAt }) => {
  const { t } = useTranslation();
  return (
    <Container layoutColor={layoutColor}>
      <WrapperHeading>{t(title)}</WrapperHeading>
      {children()}
      {moreAt && (
        <div className="link">
          <LinkMore to={`/leaders-race-next${moreAt}`}>{t('More')}</LinkMore>
        </div>
      )}
    </Container>
  );
};

type ILobbyWrapperStyle = {
  layoutColor: string;
};

const Container = styled.div<ILobbyWrapperStyle>`
  background-color: ${p => p.layoutColor};
  padding: 1.3rem 0 0.7rem;

  & > div.link {
    text-align: center;
  }
`;

const WrapperHeading = styled.span`
  display: block;
  font-size: 1.2rem;
  line-height: 1.7;
  letter-spacing: 0.54px;
  color: var(--color-white);
  text-align: center;
`;

const LinkMore = styled(Link)`
  display: inline-flex;
  margin: 1rem 0;

  font-size: 0.9rem;
  color: var(--color-white);
  text-transform: uppercase;

  position: relative;

  &:after {
    content: '\\e901';
    font-family: 'icomoon';
    color: var(--yellow);
    position: absolute;
    right: -20px;
    top: -1px;
    transform: rotate(-90deg);
  }
`;
