import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUser } from 'core/User';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { useUserBanTime } from 'core/User/hooks/useUserBanTime';
import { updateUserBanTimeRequest } from 'core/User/ducks/duck';
import { SvgIcons } from '../assets';
import { EmailBlock } from '../EmailBlock';
import { EmailStatus } from '../EmailStatus';
import {
  Top,
  Text,
  Title,
  Header,
  Body,
  ButtonLink,
  ConfirmTitle,
  HelpWrap,
  HelpText,
  MyButton,
  Container,
} from './SelfExclusion.styles';

export const SelfExclusion = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useUser();
  const { banTime } = useUserBanTime();
  const { activeDialogs, handleCloseByName, handleToggleDialog } = useDialog();

  useEffect(() => {
    return () => {
      activeDialogs.forEach(item => handleCloseByName(item));
    };
  }, [activeDialogs, handleCloseByName]);

  const handleConfirm = () => {
    dispatch(updateUserBanTimeRequest(banTime.toString()));
  };

  return (
    <Container className="content">
      <Top>
        <Title>{t('Self-exclusion')}</Title>
        <Text>{t('CSGOFAST offers')}</Text>
        <Text>{t('If you feel')}</Text>
      </Top>
      <Header>{t('Duration')}</Header>
      <ButtonLink onClick={handleToggleDialog(userModals.PROFILE_FREEZE, true)}>
        <div>
          <span>
            {`${banTime} `}
            {t('pluralize-days', {
              count: banTime,
              postProcess: 'with-template',
            })}
          </span>
        </div>
      </ButtonLink>
      <Body>
        {user.email.status === 'confirmed' ? (
          <>
            <ConfirmTitle>
              {t('To activate self-exclusion, a confirmation by mail is required')}
            </ConfirmTitle>
            <EmailStatus
              handleOpen={handleToggleDialog(userModals.PROFILE_CHANGE_EMAIL, true)}
              isEditable
            />
          </>
        ) : (
          <EmailBlock handleOpen={handleToggleDialog(userModals.PROFILE_EMAIL, true)} />
        )}
        <HelpWrap>
          <SvgIcons.HelpIcon />
          <HelpText>
            {t(
              'If necessary, you can contact specialized organizations for help with gambling addiction:'
            )}
            <span>
              Begambleaware, Gamblers Anonymous, Gam Care, National Council on Problem Gambling
              (NCPG)
            </span>
          </HelpText>
        </HelpWrap>
        <MyButton
          className="primary"
          onClick={handleConfirm}
          disabled={user.email.status !== 'confirmed'}
        >
          {t('self-exclusion')}
        </MyButton>
      </Body>
    </Container>
  );
};
