import { IStore } from 'store/interface';

export const getSuitCaseData = ({ cases }: IStore) => cases.caseData;
export const getCasesList = ({ cases }: IStore) => cases.caseList;

export const getCategory = ({ cases }: IStore) => cases.category;

export const getCaseList = ({ cases }: IStore) => cases.caseList;

export const getSortParams = ({ cases }: IStore) => cases.sortParams;

export const getRevisionData = ({ cases }: IStore) => cases.revisionData;

export const getDetailedItemInfo = ({ cases }: IStore) => cases.caseItemInfoDetailed;

export const getRouletteData = ({ cases }: IStore) => cases.rouletteReducer;

export const getRouletteStatus = ({ cases }: IStore) => cases.rouletteStatusReducer;

export const getWinItemsStatus = ({ cases }: IStore) => cases.winItemStatusReducer;

export const getOpenCaseType = ({ cases }: IStore) => cases.openCaseTypeReducer;

export const getCasesSoundType = ({ cases }: IStore) => cases.soundTypeReducer;

export const getSpeedType = ({ cases }: IStore) => cases.speedTypeReducer;

export const getMotivatorData = ({ cases }: IStore) => cases.motivatorDataReducer;

export const getCaseHistory = ({ cases }: IStore) => cases.caseHistoryReducer;

export const getSelectedCategory = ({ cases }: IStore) => cases.selectedCategory;
