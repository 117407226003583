import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationLink } from '@components/Common';
import { useReferralCampaigns } from '../../hooks';
import { Container } from './ProfileNavigation.styles';
import { SvgIcons } from './assets';
// import { useUserSelfLockUntill } from 'core/User/hooks/useUserSelfLockUntill';

export const ProfileNavigation = () => {
  const { t } = useTranslation();
  const { campaigns } = useReferralCampaigns();
  // const { selfLockUntill } = useUserSelfLockUntill();

  return (
    <Container>
      <NavigationLink to={'/personal/statistics'} as={Link}>
        <div className="leading">
          <SvgIcons.StatisticsIcon />
        </div>
        <div>
          <span>{t('Statistics account')}</span>
        </div>
      </NavigationLink>
      {/* <NavigationLink to={'/personal/levels'} as={Link}>
        <div className="leading">
          <SvgIcons.LevelsIcon />
        </div>
        <div>
          <span>{t('Levels account')}</span>
        </div>
      </NavigationLink> */}
      {!!Object.keys(campaigns) ? (
        <NavigationLink to={'/personal/referral/campaigns'} as={Link}>
          <div className="leading">
            <SvgIcons.ReferralsIcon />
          </div>
          <div>
            <span>{t('Referral program account')}</span>
          </div>
        </NavigationLink>
      ) : (
        <NavigationLink to={'/personal/referral'} as={Link}>
          <div className="leading">
            <SvgIcons.ReferralsIcon />
          </div>
          <div>
            <span>{t('Referral program account')}</span>
          </div>
        </NavigationLink>
      )}
      <NavigationLink to={'/personal/balance'} as={Link}>
        <div className="leading">
          <SvgIcons.BalanceIcon />
        </div>
        <div>
          <span>{t('Balance account')}</span>
        </div>
      </NavigationLink>
      <NavigationLink to={'/personal/notification'} as={Link}>
        <div className="leading">
          <SvgIcons.NotificationsIcon />
        </div>
        <div>
          <span>{t('Notifications')}</span>
        </div>
      </NavigationLink>
      <NavigationLink to={'/personal/settings'} as={Link}>
        <div className="leading">
          <SvgIcons.SettingsIcon />
        </div>
        <div>
          <span>{t('Settings account')}</span>
        </div>
      </NavigationLink>
      {/* <NavigationLink to="/personal/self-exclusion" as={Link}>
        <div className="leading">
          <SvgIcons.FreezeIcon />
        </div>
        <div>
          {selfLockUntill ? (
            <>
              <span>{t('Frozen input/output funds')}</span>
              <span className="badge-light">{selfLockUntill}</span>
            </>
          ) : (
            <span>{t('Freeze input/output funds')}</span>
          )}
        </div>
      </NavigationLink> */}
    </Container>
  );
};
