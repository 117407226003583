import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useInventoryMetaData } from '../../../hooks';
import { Container } from './inventory-metadata.styles';

export const InventoryMetadata: FC = () => {
  const { t } = useTranslation('marketplace');
  const { meta } = useInventoryMetaData();
  return (
    <Container>
      <span className="info">{t('{ count } of your Steam skins', { count: meta.count })}</span>
      <span className="sub-info">
        {t('are acceptable for refilling (In total for { amount })', {
          amount: meta.amount,
          postProcess: 'with-template',
        })}
      </span>
    </Container>
  );
};
