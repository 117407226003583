export default {
  sortBy: {
    label: 'Сортировать по',
    ASC_PRICE: 'По цене (сначала дешевые)',
    ASC_PRICE_short: 'По цене',
    DESC_PRICE: 'По цене (сначала дорогие)',
    DESC_PRICE_short: 'По цене',
    ASC_NAME: 'По имени A-Z',
    ASC_NAME_short: 'По имени',
    DESC_NAME: 'По имени Z-A',
    DESC_NAME_short: 'По имени',
    OVERPRICE: 'По наценке',
    UNDERPRICE: 'По уценке',
    price: '$t(sortBy.ASC_PRICE)',
    '-price': '$t(sortBy.DESC_PRICE)',
    name: '$t(sortBy.ASC_NAME)',
    '-name': '$t(sortBy.DESC_NAME)',
    popular: 'По популярности',
    button: 'Показать',
    '-created_at': 'Сначала новые',
    created_at: 'Сначала старые',
    '-total_price': 'Сначала дорогие',
    total_price: 'Сначала дешевые',
  },
  priceRange: {
    label: 'Задать стоимость',
    button: 'Готово',
    setManually: 'Задать вручную',
  },
  search: {
    searchTitle: 'Найти кейс',
    placeholder: 'Найти кейс',
  },
  addBattle: 'Добавить в батл',
  addBot: '+ Бот',
  join: 'Играть',
  recreate: 'Пересоздать',
  open: 'Открыть',
  refillOn: 'Пополнить на',
  waiting: 'ожидание',
  waitingPeople: 'Ожидание игроков',
  waitingForRoll: 'Ожидание батла',
  battleRunning: 'Батл в процессе',
  battleWinnings: 'Общий выигрыш',
  createGame: 'Создать игру',
  recreateGame: 'Пересоздать игру за',
  players: 'Игроки',
  randomCasesBefore: 'Случайные кейсы до:',
  createGameFor: 'Создать игру за',
  createJokerGameFor: 'Создать Joker игру за',
  outOfRange: 'Итоговая цена за допустимыми значениями. Пожалуйста введите корректную цену!',
  caseSelection: 'Подбор кейсов',
  remove: 'Сбросить',
  waitingPlayers: 'Ожидание игроков',
  round: 'Раунд',
  from: 'из',
  battleFinish: 'Батл завершился',
  jokerBattleFinish: 'Joker батл завершился',
  atStake: 'На кону',
  ready: 'Готов',
  waitingGamer: 'Ожидание игрока',
  awaiting: 'В ожидании',
  loginAndLuck: 'ВОЙТИ И ИСПЫТАТЬ СВОЮ УДАЧУ',
  botAdded: 'Бот добавлен',
  joinSuccess: 'Вы присоединились к игре',
  win: 'Победа',
  unLuck: 'Неудача',
  winnings: 'Выигрыш',
  random: 'Случайно',
  cases: 'Кейсы',
  addCases: 'Добавить кейсы',
  titleJoker: 'А что это за режим Joker?',
  descriptionJoker:
    'Представляем новый режим батлов - <1>Joker</1>! Он является противоположностью обычному режиму батлов и в нём побеждает игрок, набравший наименьшую сумму после всех раундов.',
  back: 'Назад',
  chooseCases: 'Выбери кейсы или воспользуйся автоподбором',
};
