import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.span`
  color: #fffafa;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

export const StatisticsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Skeleton = styled(SkeletonElement)`
  background-color: var(--color-dark-600);
  border-radius: 8px;
  height: 542px;
  min-width: 312px;
`;
