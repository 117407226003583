import reducer, { eventsTypes } from './duck';
import './locales';

export { useBonusRefillEvent } from './hooks';
export { refillEpics } from './duck';

export { RefillRouter as RefillRoute } from './components';
// export const RefillRoute = lazy(() => import('./components').then(module => ({ default: module.RefillRouter })))
export const refillModule = {
  moduleName: 'refill',
  path: '/refill',
  reducer,
  eventsTypes,
};
