import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCreateParticipate } from '../duck';

export const useClassicParticipateController = () => {
  const dispatch = useDispatch();

  const handleCreateParticipate = useCallback(
    data => () => {
      dispatch(fetchCreateParticipate(data));
    },
    [dispatch]
  );

  return {
    handleCreateParticipate,
  };
};
