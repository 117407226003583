import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreSingleFilter } from '../../../hooks';
import { StoreFiltersConfig } from '../../../configs';
import { Button } from '@components/Common';
import { Container } from './filter-modal.style';
import { IDialogComponent } from 'core/interfaces';
import { IFiltersRecord } from '../../../interfaces';

interface ISingleFilterDialog extends IDialogComponent<string[]> {}

export const SingleFilterDialog: FC<ISingleFilterDialog> = ({ handleCloseModal, data }) => {
  const { t } = useTranslation('marketplace');
  const [key] = data;
  const { changeDetailed, values } = useStoreSingleFilter<IFiltersRecord['sortBy']>(key);

  return (
    <Container>
      <div className="filter-title">{Array.isArray(data) && <>{t(`${key}.label`)}</>}</div>
      <div className="filter-list">
        {Array.from(StoreFiltersConfig.detailed(key).entries()).map(([k, v]) => {
          const selected = values === k;
          return (
            <button key={k} className="filter-list-item" onClick={changeDetailed(k)}>
              <div>
                <span className={`indicator ${selected ? 'selected' : ''}`} />
              </div>
              <div className="description">
                <span>{t(`${key}.${StoreFiltersConfig.detailed(key).get(k)}`)}</span>
              </div>
            </button>
          );
        })}
        <div className="item-buffer" />
        <div className="filter-controller" onClick={handleCloseModal}>
          <Button className="primary full" onClick={handleCloseModal}>
            {t('show selected')}
          </Button>
        </div>
      </div>
    </Container>
  );
};
