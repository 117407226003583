export default {
  '100$ for the new players!': '100 $ för de nya spelarna!',
  '100$ bonus is credited to a demo balance': '100 $ bonus krediteras en demobalans',
  'Get 100$ now!': 'Få 100 $ nu!',

  'Top up your balance from 25$ and get bonuses': 'Fyll på ditt saldo från 25 $ och få bonusar',
  'Exp bonus': 'Exp-bonus',
  'Wheel Of Fortune free spin': 'Wheel Of Fortune gratissnurr',
  'Free case': 'Fritt fall',
  'Access to account level-up': 'Tillgång till kontohöjning',
};
