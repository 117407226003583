import React, { FC } from 'react';
import styled from 'styled-components';
import { SearchInput } from '@UIkit';
import { Button } from '@components/Common';
import { IModalComponent, Modal, transitions } from 'core/Modals';
import { ExchangeModal, ShopFiltersConfig } from '../../../configs';
import { CurrencyText } from '@components/currency';
import { useShopPriceFilter, useShopQueryFilter } from '../../../hooks';
import { useTranslation } from 'react-i18next';

export const FilterModal = () => (
  <Modal
    modalComponent={Filter}
    modalName={ExchangeModal.EXCHANGE_SHOP_FILTERS}
    anchor="exchange"
    transition={transitions.SLIDE_UP}
  />
);

const Filter: FC<IModalComponent> = ({ handleCloseModal }) => {
  const { t } = useTranslation('exchange');
  const { query, changeQuery } = useShopQueryFilter();
  const { min, max, changePrice } = useShopPriceFilter();

  return (
    <Container>
      <div className="title">Фильтр и поиск</div>
      <div className="search">
        <SearchInput placeholder={t('search items...')} value={query} onChange={changeQuery} />
      </div>
      <div className="prices">
        <div className="prices-title">{t('price range')}</div>
        <div className="prices-selector">
          {Array.from(ShopFiltersConfig.prices.entries()).map(([key, [from, to]]) => (
            <button key={key} onClick={changePrice(key)} disabled={from === min && to === max}>
              {from === null && to === null && <>{key}</>}
              {!!from && !to && <>{t('from')} </>}
              {from !== null && <CurrencyText value={from} />}
              {!!to && ' — '}
              {to !== null && <CurrencyText value={to} />}
            </button>
          ))}
        </div>
      </div>
      <div className="controller">
        <Button className="primary" onClick={handleCloseModal}>
          {t('show selected')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content min-content 1fr min-content;
  align-content: space-between;
  border-top: 1px solid var(--color-gray-900);
  background: var(--color-dark-600);
  height: 24.75rem;

  & .search,
  & .prices {
    border-bottom: 1px solid var(--color-gray-900);
  }

  & .title {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-white);
    padding: 1rem;
    height: 3.75rem;
  }

  & .search {
    display: flex;
    padding: 0.75rem 1rem 0;
    height: 6rem;
  }

  & .prices {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    padding: 0.875rem 2.5rem 0 1.3rem;
    border-top: 1px solid var(--color-gray-900);

    &:first-of-type {
      border-top: none;
    }

    &-title {
      font-size: 0.875rem;
      line-height: 25px;
      letter-spacing: 0.24px;
      color: var(--color-white);
    }

    &-selector {
      display: flex;
      flex-flow: row wrap;
      margin-right: -1.625rem;

      & button {
        margin: 0 0.75rem 0.5rem 0;
        padding: 0.5rem 0.75rem;
        background: var(--color-dark);
        border: 0.125rem solid var(--color-dark-200);
        border-radius: var(--size-radius-s);
        font-weight: bold;
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        color: var(--color-white);

        &:disabled {
          background: #47555f;
          border-color: #47555f;
        }
      }
    }
  }

  & .controller {
    padding: 2rem 1rem 1.75rem;
  }
`;
