import { SteamSettingsDialog } from './steam-settings-dialog';
import { DIALOG } from '../dialog-combiner';
import { userModals } from 'core/User/modal.config';
import { transitions } from '../transition.config';

DIALOG.add(userModals.STEAM_SETTINGS, {
  component: SteamSettingsDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
