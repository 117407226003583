/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Description, HeaderInfo, Time } from './result-timer.styles';
import { timeFormat } from 'core/utils';
import { useGetEventTime } from 'modules/event-module/comics-event/hooks';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

export const ResultsTimer = () => {
  const { t } = useTranslation();
  const { time } = useGetEventTime(true);

  return (
    <Container>
      <HeaderInfo>
        <img src={eventImagesPath.badgePath} width={159} alt="" />
        <Description>{t('finishEvent')}</Description>
        {typeof time.hours === 'number' && (
          <Time>
            {time.days > 1 ? (
              <>
                {t('${count} ${left} ${days}', {
                  count: time.days,
                  left: t('pluralize-left', {
                    count: time.days,
                    postProcess: 'with-template',
                  }),
                  days: t('pluralize-days', {
                    count: time.days,
                    postProcess: 'with-template',
                  }),
                  postProcess: 'with-template',
                })}
              </>
            ) : (
              <>
                {timeFormat(time.hours.toString())}:{timeFormat(time.minutes.toString())}:
                {timeFormat(time.seconds.toString())}
              </>
            )}
          </Time>
        )}
      </HeaderInfo>
    </Container>
  );
};
