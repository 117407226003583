export default {
  //CryptoPage title
  crypto: 'Retiro de criptomonedas',
  cryptocurrency: 'Criptomoneda',
  from: 'desde',
  //
  'Email is required': 'Se necesita un correo electrónico',
  Withdraw: 'Retirar',
  'Withdrawal sum': 'Suma de retiro',
  'Amount in {{title}}:': 'Cantidad en $t({{title}}):',
  'Receive in {{title}}:': 'Recibe en $t({{title}}):',
  'You will receive:': 'Usted recibirá:',
  'Select the network fee': 'Seleccione la tarifa de la red',
  'Commission:': 'Comisión:',
  'Ultra Low': 'Muy lenta',
  Low: 'Lenta',
  Medium: 'Estándar',
  High: 'Rápida',
  'No commission': 'Sin comisión',
  commission: 'Comisión posible',
};
