import styled from 'styled-components';

export const Icon = styled.img`
  width: 150px;
`;
export const Preload = styled.div`
  position: fixed;
  z-index: 999;
  height: 150px;
  width: 201px;
  margin: auto;
  inset: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;
