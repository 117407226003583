import one from './1.png';
import two from './2.png';
import three from './3.png';
import four from './4.png';
import five from './5.png';
import sixth from './6.png';
import seven from './7.png';
import eight from './8.png';
import nine from './9.png';
import ten from './10.png';
import eleven from './11.png';
import twelve from './12.png';
import fifteen from './15.png';
import twente from './20.png';
import twenteFour from './24.png';
import twenteFive from './25.png';
import thirty from './30.png';
import fifty from './50.png';
import hundred from './100.png';

interface IValue {
  path: string;
  reward: number;
}

export const discountObject: Record<number, string> = {
  1: one,
  2: two,
  3: three,
  4: four,
  5: five,
  6: sixth,
  7: seven,
  8: eight,
  9: nine,
  10: ten,
  11: eleven,
  12: twelve,
  15: fifteen,
  20: twente,
  24: twenteFour,
  25: twenteFive,
  30: thirty,
  50: fifty,
  100: hundred,
};
