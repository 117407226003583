import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';

import { GameFilter } from '../GameFilter';
import { SortDirection } from '../SortDirection';
import { CurrencyInput } from '../../../../@components/currency';
import { withTranslation } from 'react-i18next';

import { getItemUri } from '../../../../core/utils/image.utils';
import { dropItems } from '../../marketplace.utils';
import { debounce } from 'lodash';

const maxBetValue = 10000;

class _Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: Array.from({ length: 10 }, (_, i) => null),
      betValue: props.gameSettings.min_bet,
      passedQuantity: 0,
      itemsQuantity: 0,
    };
    this.changeBet = debounce(this.changeBetValue, 1000, {
      leading: true,
      maxWait: 500,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { items } = props;
    if (state.itemsQuantity !== items.length) {
      return {
        passedQuantity: items.reduce((count, item) => {
          if (item.passed) return count + 1;
          return count;
        }, 0),
        itemsQuantity: items.length,
      };
    }

    return null;
  }

  render() {
    const { type, mode, gameTypes, filterValue, updateFilter, requestUserInventory } = this.props;
    const { cartItems } = this.state;

    return (
      <Fragment>
        {type === 'virtual' && this.autoSelectItems}
        {(type === 'virtual' || filterValue.currentGameTypes === 1912) && this.cartList}
        {type !== 'virtual' && (
          <GameFilter
            mode={mode}
            gameTypes={gameTypes}
            filterValues={filterValue}
            updateFilter={updateFilter}
            requestAction={requestUserInventory}
          />
        )}
        {this.filtersItems}
        {this.props.children({
          addItemToCart: this.addItemToCart,
          cartItems: cartItems,
        })}
      </Fragment>
    );
  }

  get cartList() {
    const { cartItems } = this.state;
    return (
      <List>
        {cartItems.map((itemCart, index) => (
          <ItemSelect key={index} onClick={this.removeItemToCart(index)}>
            {!!itemCart && <img src={getItemUri(itemCart.icon)} alt="cheap" />}
          </ItemSelect>
        ))}
      </List>
    );
  }

  get autoSelectItems() {
    const { gameSettings } = this.props;
    return (
      <AutoSelectContainer>
        <CurrencyInput
          value={this.state.betValue}
          setValue={this.changeBet}
          minValue={gameSettings.min_bet}
          maxValue={gameSettings.max_bet || maxBetValue}
        />
      </AutoSelectContainer>
    );
  }

  get filtersItems() {
    const { filterValue, updateFilter, t } = this.props;
    const { passedQuantity, itemsQuantity } = this.state;
    return (
      <FiltersContainer>
        <CanBetInfo>
          {t('Skins available')}: {`${passedQuantity} / ${itemsQuantity}`}
        </CanBetInfo>
        <SortDirection filterValues={filterValue} sortAction={updateFilter} />
      </FiltersContainer>
    );
  }

  addItemToCart = item => () => {
    const {
      type,
      filterValue: { currentGameTypes },
      gameTypes,
    } = this.props;

    const { cartItems } = this.state;

    if (type !== 'virtual' && currentGameTypes === 570) {
      return window.open(gameTypes[currentGameTypes].link);
    }

    if (type !== 'virtual' && cartItems.find(cartItem => !!cartItem && cartItem.id === item.id)) {
      return;
    }

    this.setState(state => ({
      cartItems: [item, ...state.cartItems].splice(0, 10),
    }));
  };

  removeItemToCart = index => () => {
    this.setState(state => ({
      cartItems: [
        ...state.cartItems.slice(0, index),
        ...state.cartItems.slice(index + 1, state.cartItems.length + 1),
        null,
      ],
    }));
  };

  changeBetValue = value => {
    const { items } = this.props;

    const autoSelectItems = dropItems(items, value * 100, 3, 10);
    this.setState({
      betValue: value,
      cartItems: Array.from({ length: 10 }, (_, i) => autoSelectItems[i]) || null,
    });
  };
}

export const Header = withTranslation()(_Header);

const AutoSelectContainer = styled.div`
  padding: 17px 18px 7px;
  background-color: var(--color-gray-950);
`;

const List = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 10px 14px;
  background-color: var(--color-gray-950);
  overflow-x: auto;
  overflow-y: hidden;
`;

const ItemSelect = styled.div`
  width: 55px;
  height: 55px;
  background: ${({ hasItem }) => (hasItem ? 'var(--color-dark-500)' : 'var(--color-gray-950)')};
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: solid 4px var(--color-dark-500);
  & img {
    height: 100%;
    object-fit: contain;
    padding: 6px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 10px 18px 5px;
  background-color: var(--color-dark-500);
`;

const CanBetInfo = styled.div`
  font-size: 10px;
  letter-spacing: 0.3px;
  color: var(--color-white);
  text-transform: uppercase;
`;
