import React, { ComponentClass, createElement, FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { config } from 'react-spring';
import { animated, Transition } from 'react-spring/renderprops';

interface IToast {
  initState: boolean;
  component: FC | ComponentClass;
}

export const Toast: FC<IToast> = ({ component, initState }) => {
  const [open, setOpen] = useState(initState);
  const handleCloseToast = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Transition
      items={open}
      from={{ height: 'auto' }}
      enter={{ height: 'auto' }}
      leave={{ height: 0 }}
      config={config.stiff}
    >
      {collapsible => props =>
        collapsible && (
          <AnimatedDiv style={props}>
            {createElement(component)}
            <CloseButton onClick={handleCloseToast} />
          </AnimatedDiv>
        )}
    </Transition>
  );
};

const AnimatedDiv = styled(animated.div)`
  display: flex;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  will-change: height;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 2.5rem;
  height: 100%;
  padding: 0.74rem;
  position: absolute;
  bottom: 0;
  right: 0;

  &:before {
    content: '\\e929';
    font-family: icomoon;
    color: var(--color-gray-100);
  }
`;
