import styled from 'styled-components';

export const ButtonContainer = styled.div`
  padding: 20px 16px 28px;
  box-sizing: border-box;
`;

export const CancelButton = styled.span`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 11px 15px 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background: transparent;
  border: none;
  text-align: center;
  color: var(--light-grayish-blue);
  letter-spacing: 0.1px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.h3`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-50);
  padding: 25px 19px 19px;
`;
