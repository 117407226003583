import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Quest from './Quest';

export const GeneralRulesAndQuestions = () => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <Quest height={600} title={t('What games are there in the project?')}>
        <div>Classic game -</div>
        {t(
          'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round'
        )}
        <br /> <br />
        <div>Fast game -</div>
        {t(
          'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players'
        )}
        <br /> <br />
        <div>Double game -</div>
        {t(
          'in this mode you can get Fast Coins (service currency) and exchange them for skins in the Store Guess the winner color and return your bet multiplied by 2 or 14'
        )}
        <br /> <br />
        <div>Crash game -</div>
        {t(
          'is a mode, where players have to press Stop button before Crash comes Crash comes randomly but as long as you are in game the coefficient rises as your winning'
        )}
        <br /> <br />
        <div>Craft game -</div>
        {t(
          'gives all users a great opportunity to obtain their desired items below their market value'
        )}
      </Quest>

      <Quest height={300} title={t('How to take part in the game?')}>
        {t('1 Sign in through Steam')}
        <br /> <br />
        {t('2 Put an actual Trade Offer link into a Settings (You can get your actual link here)')}
        <br /> <br />
        {t('3 Make your inventory public in the Steam Profile')}
        <br /> <br />
        {t('4 Select the game, place a bet and try your luck!')}
      </Quest>

      <Quest height={400} title={t('How to place a bet?')}>
        {t(
          'For Classic Game and Fast Game: Select the game mode and click at Deposit more or Place a bet Select skins that you want to deposit as a bet from your inventory Click at Make Offer Bot accepts your bet and places it into the game'
        )}
        <br /> <br />
        {t(
          'For Double Game: Enter a wishful number of Fast Coins as a bet and click at the color slot button, that will win in the round from your coin of view The bet of a chosen color will be placed automatically'
        )}
      </Quest>

      <Quest height={400} title={t('What is acceptable as a bet?')}>
        {t(
          'For Classic Game and Fast Game: Bot accepts skins from Counter-Strike: Global Offensive only Trade Offers with the items from other games or containing souvenir items (other than gift sets) are canceled automatically'
        )}
        <br /> <br />
        {t(
          'For Double Game: In this game Fast Coins are acceptable as bets The ways to get Fast Coins are listed here'
        )}
      </Quest>
      <Quest height={300} title={t('What are the max and min bets?')}>
        {t(
          'A bet sum limit is indicated in the selected game Inappropriate bets are canceled automatically'
        )}
      </Quest>
      <Quest height={300} title={t('How many skins can be accepted per one bet?')}>
        {t(
          'For Classic Game and Fast Game: The acceptable number of skins per one bet is indicated in the selected game mode Inappropriate bets are canceled automatically'
        )}
      </Quest>
      <Quest height={300} title={t('What max number of skins is accepted per one round?')}>
        {t(
          'The max number of skins (if it is prescribed) on the round deposit for Classic Game and Fast Game is indicated in the selected game mode'
        )}
      </Quest>
      <Quest height={300} title={t('How long is one round?')}>
        {t(
          'The allotted time (if it is prescribed) of one round is indicated in the selected game'
        )}
      </Quest>
      <Quest height={300} title={t('How are skins appraised?')}>
        {t(
          'Skins are appraised regularly in accordance with Markets Prices and set up as an average sum for a certain period of time Site Skin prices can differ from prices of Steam Market'
        )}
      </Quest>
      <Quest height={300} title={t('What is the Ticket System?')}>
        {t(
          'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth {currency}, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on',
          { amount: 1 }
        )}

        {t('The higher and bigger your bets, the more tickets you get')}
        {t('The more tickets you get, the greater chance to become a winner')}
      </Quest>
      <Quest height={700} title={t('What is Fair Game?')}>
        {t(
          'At the very beginning of a round the Service generates a random long number from 0 to 1 (eg 0282796734) and encrypts it through the sha224 algorithm The result is displayed at the beginning of a round'
        )}
        <br /> <br />
        {t(
          'At the end of the round the Service multiplies the number by the general number of all tickets thus getting the number of a winner'
        )}
        <br /> <br />
        {t(
          'An example: At the end of a round the general deposit is 1000$ (there are 100000 tickets in the game), a generated number was 0.282796734 The Service multiplies the number 0282796734 by 100000 thus getting the number of a winner 28279 that is being displayed at the moment of a raffle'
        )}
        <br /> <br />
        {t(
          "A Fair Game Principle: we can't know a tickets number that is displayed at the end of a round in advance and the multiplied random number is generated at the very beginning of a game That is why we have no opportunity to influence the results of a raffle"
        )}
      </Quest>

      <Quest height={300} title={t('How is the winner determined?')}>
        {t(
          'The ways of winner determination differ in every game mode Have a look at winner determination terms for Classic Game, Fast Game and Double Game'
        )}
      </Quest>
      <Quest height={300} title={t('What does the winner get at the end of a round?')}>
        {t(
          'The Classic Game and Fast Game winner gets all deposited skins except a Service Commission Have a look at Service Commission Terms for Classic Game and Fast Game'
        )}
      </Quest>

      <Quest height={300} title={t('What Service Commission is there?')}>
        {t(
          'A Service Commission depends on a selected game and general round deposit Have a look at Service Commission Terms for Classic Game and Fast Game'
        )}
        <br /> <br />
        {t('There is no Commission in the Double Game')}
      </Quest>
      <Quest height={300} title={t('How to increase the chances of winning?')}>
        {t(
          'The higher and bigger your bets in the Fast and Classic games, the greater chance to become a winner'
        )}
        <br /> <br />
        {t("A max bet doesn't ensure a win, but it increases a chance of winning vastly")}
        <br /> <br />
        {t(
          'In the Double Game everything depends on your luck and ability to guess the winner color slot of the round'
        )}
        <br /> <br />
        {t('Play any game you like and try your luck!')}
      </Quest>
      <Quest height={300} title={t('When is the winner determined?')}>
        {t(
          'Have a look at terms of round winner determination in the Classic Game, Fast Game, Double Game, Crash Game'
        )}
      </Quest>
      <Quest height={600} title={t('How to get the winning?')}>
        {t("Trade Offers with the winner's prize are transferred by our bot in automatic mode")}
        <br /> <br />
        {t('If you win you will see a pop-up with congratulations and the won sum')}
        <br /> <br />
        {t(
          'A Trade Offer is sent to the indicated Trade Offer link in your Settings within 5-30 minutes You should accept the Trade Offer'
        )}
        <br /> <br />
        {t(
          'Sending of items can rarely take longer period of time because of Steam Lags or issues with Mobile Confirmation'
        )}
        <br /> <br />
        {t('If you have got any issues with getting your prize, contact our 24/7 tech support:')}
        <br />
        support@csgofast.com
      </Quest>
      <Quest height={300} title={t('Can I accept a Trade Offer later?')}>
        {t('It is necessary to accept a winning as soon as you get a Trade Offer from our bot')}
        <br /> <br />
        {t(
          'A winning that had not been accepted for an hour is refused It is necessary to avoid bot inventory overloading in order to keep system working without malfunctions'
        )}
      </Quest>
      <Quest height={300} title={t('How to get a bonus to my winning?')}>
        {t(
          'Add to any part of your Steam Nickname an adscript CSGOFASTCOM and get 5% to your winning The adscript must be written in one word This bonus operates at CLASSIC GAME and FAST GAME only'
        )}
      </Quest>
      <Quest height={300} title={t('What is Referral?')}>
        {t(
          'There is a Referral Program in our Project You get some Fast Coins for every invited player and his games Then you can exchange these coins for skins in the Store There is detailed information in the Referral'
        )}
      </Quest>
      <Quest height={300} title={t('I`ve got an issue Where can I apply for help?')}>
        {t(
          "It's our pleasure to help you in case of any issues occurred to our Service In order to solve your issue write a letter to our tech support: "
        )}
        <br />
        support@csgofast.com
      </Quest>
      <Quest
        height={300}
        title={t('How does tech support work? How long should I wait for reply?')}
      >
        {t('Our tech support works 24/7 If you need help write about your issue to')}
        support@csgofast.com.{' '}
        {t(
          'As a rule, we reply within 60 min, but there might be longer period because of many requests or holidays'
        )}
      </Quest>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;
