import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { Link } from 'react-router-dom';

const components = [
  <Button className="link-info" to="/refill" as={Link} />,
  <Button className="link-info" to="/leaders-race-next" as={Link} />,
];

export const LandingSteps = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <img src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/weapon.png" alt="weapon" />
      <div className="inner-container">
        <h2>{t('How to get more golden tickets and participate in few raffles at once?')}</h2>
        <ul className="steps-list">
          <li>
            <img src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/a.png" alt="" />
            <Trans t={t} components={components}>
              <p>{t('Starting December 13, get golden tickets by refilling your balance')}</p>
            </Trans>
          </li>
          <li>
            <img src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/b.png" alt="" />
            <Trans t={t} components={components}>
              <p>{t('Have time to complete the available weekly quests before December 31')}</p>
            </Trans>
          </li>
        </ul>
      </div>
      <img
        src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/bullet-3.png"
        alt="weapon"
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  & .inner-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 1.875rem;
    padding: 3.5rem 1.5rem;
    min-height: 38.4375rem;
    background: linear-gradient(180deg, var(--color-white) 0%, var(--color-gray-80) 100%);
    clip-path: polygon(0 0, 100% 14%, 100% 90%, 0% 100%);

    & > h2 {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2.1875rem;
      letter-spacing: 0.54px;
      color: var(--color-dark-100);
      text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);

      @media screen and (min-width: 375px) {
        font-size: 1.875rem;
      }

      &::before {
        content: '';
        float: right;
        height: 40px;
        width: 50%;
        shape-outside: polygon(0 0, 100% 100%, 100% 0);
        shape-margin: 7%;
      }
    }

    & .steps-list {
      display: grid;
      grid-auto-rows: min-content;
      grid-gap: 4.5rem;

      & li {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 4.75rem 1fr;
        grid-gap: 0.5rem;
        align-items: center;
        justify-content: center;
        justify-items: center;

        & img {
          width: 65%;
        }

        & p {
          font-size: 0.875rem;
          line-height: 1.5rem;
          letter-spacing: 0.54px;
          color: var(--color-dark-100);

          & a {
            display: inline-block;
            font-size: inherit;
            line-height: inherit;
            text-transform: none;
          }
        }
      }
    }
  }

  & > img {
    position: absolute;
    z-index: 5;

    &:first-of-type {
      top: -30px;
      left: 0;
      right: 0;
      transform: rotate(4deg) scale(1.05) translate3d(4px, 0, 0);
    }

    &:last-of-type {
      width: 20%;
      right: 3.75rem;
      bottom: 2px;
    }
  }
`;
