import * as React from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { getFastGameIds } from '../selectors';
import { animated, useTransition } from 'react-spring';
import { Game } from './Game';
import { GameControls } from '../components';
import { useToggleGame } from '../hooks';

interface IGameState {
  number: number;
}

export const GameTape = () => {
  const gameIds: IGameState[] = useSelector(getFastGameIds, shallowEqual);
  const { toggleId, toggleGame } = useToggleGame();

  let height = 0;
  const gameLent = useTransition(
    gameIds.map((data: IGameState) => {
      const heightGame = toggleId === data.number ? 445 : 170;
      return {
        ...data,
        y: (height += heightGame) - heightGame,
        height: heightGame,
      };
    }),
    d => {
      return d.number;
    },
    {
      initial: { height: 170, opacity: 0 },
      from: { height: 170, opacity: 0 },
      leave: { height: 170, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
    }
  );
  return (
    <>
      <Container className="tablet" style={{ minHeight: height }}>
        {gameLent.map(({ item, props: { y, ...rest }, key }: any, index) => (
          <AnimatedGame
            key={key}
            style={{
              zIndex: gameIds.length - index,
              transform: y.interpolate((y: number) => `translate3d(0,${y}px,0)`),
              ...rest,
            }}
          >
            <Game gameId={item.number} setGameId={toggleGame(item.number)} toggleId={toggleId} />
          </AnimatedGame>
        ))}
      </Container>
      <GameControls />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  flex: 1 0;
`;

const AnimatedGame = styled(animated.div)`
  width: 100%;
  position: absolute;
  will-change: transform, height, opacity;
`;
