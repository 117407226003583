import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  &.position {
    overflow: visible;
    z-index: 15;
  }
`;
