import React from 'react';
import { AppBar } from '@components/bars';
import { PayoutMethod } from '../payout-method';

export const PayoutMethodPage = () => (
  <>
    <AppBar deep />
    <div className="content dark-layout">
      <PayoutMethod />
    </div>
    {/*<PayoutModal />*/}
  </>
);
