export default {
  'What is the Ticket System?': '什麼是遊戲票系統？',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    '玩家下注時，他們會獲得虛擬遊戲票，其中一人將成為獲勝者。如果您的物品價值為 1 美元，您就能獲得 100 張遊戲票，如果您的面板價值為 10 美元，則可獲得 1,000 張遊戲票，依此類推。',

  // GAMES

  'How is the winner determined?': '如何確定獲勝者？',
  'When is the winner determined?': '何時確定獲勝者？',
  'How many bets can I place per one round?': '每回合可以下注多少次？',
  'What Service Commission is there in this game?': '本遊戲的手續費為多少？',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    '經典遊戲適合喜歡高風險的遊戲玩家。這些玩家將嘗試一般下注，回合結束時在玩家之間抽獎，玩家憑運氣獲得獎勵。',
  'The winner is determined by the Ticket System in the Classic Game.':
    'В獲勝者通過經典遊戲中的遊戲票系統確定。',
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    '回合時間結束或達到最大押注面板數後，將確定獲勝者。',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    '手續費為 0 至 10%，具體視獎勵總和而定。\n一般賭注的隨機面板將作為手續費。',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    '快速遊戲 —— 快速試試運氣的最佳方式。只有 3 名玩家，最大賭注有限。獲勝者將獲得 3 名玩家的全部賭注',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    '獲勝者隨機確定。不過，賭注越大，獲勝機率越大。',
  'One player can place one bet per one game round in this game.':
    '在本遊戲中，一名玩家每個遊戲回合僅能下注一次。',
  'Where can I see my chances of the winning?': '在何處可以看到我的快點？',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    '獲勝機率以百分比顯示。在玩家的回合賭注總和旁，您可以看到自己的獲勝機率（位於頭像和已押注面板列表的右側）。',
  'The winner is determined once 3 players place their bets in the current game round.':
    '3 名玩家在目前遊戲回合下注後，即會確定獲勝者。',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    '手續費為 0 至 15%，具體視獎勵總和而定。回合內一般賭注的隨機面板將作為手續費',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    '輪盤遊戲 —— 一種遊戲模式：玩家下注輪盤開出黑色、紅色還是綠色分區。在此模式，玩家使用快點作為賭注。回合結束時，將確定獲勝分區。',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    '如果開出黑色或紅色，玩家將贏得 2 倍賭注。',
  'The green slot returns the placed bet multiplied by 14.': '如果是綠色分區，則會贏得 14 倍賭注。',
  'The bet sum of every slot can be different.': '每種顏色的賭注總和可以不同。',

  'How can I Get Fast Coins?': '如何獲得快點？',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. 參與推薦計劃。經由推薦連結或代碼，邀請新玩家加入專案。對於每個被推薦人及其玩的每一場遊戲，您皆能獲得快點。',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    '2. 使用您價值 1 美元及以上的面板兌換快點。1 美分兌換 1 快點。您一次最多能發送 30 個道具。按一下此處，瞭解更多兌換資訊。我們建議使用 Steam Inventory Helper 估價。',
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3.透過支付系統儲值。選擇您方便的支付方式，並為帳號購買所需的快點數。',

  'How is the winner slot determined (fair game)?': '如何確定獲勝分區（公平遊戲）？',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    '在每回合一開始，服務會生成一個介於 0 到 1 間的長亂數（例如 0.223088），並透過 sha224 演算法進行加密。結果將在回合開始時顯示。回合結束時，服務會將此加密數字乘以 15，即是獲勝分區的編號。\n',
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    '範例：回合開始時，加密數字為 0.223088232334703230728。服務將其乘以 15，得到 3.34632348495。因此，自然數為 3（紅色分區）。在本回合中，獲勝者將是下注開出紅色分區的玩家。',
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    '您可以查看獲勝分區是否公平。回合結束時，記下加密數字，並透過任何一種線上服務（例如，http://sha224.net），將其再加密一次。您將獲得與回合開始時相同的雜湊值。也就是說，遊戲沒有作弊。',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    '由於系統在回合開始時就已選定獲勝分區，任何玩家皆能監視它是否發生變化 —— 我們沒有任何機會影響抽獎結果。',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    '回合開始前，您可以下注無數次，每個分區的賭注總和可以不同。',

  'What bet limitations are there?': '賭注限制是多少？',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    '最低赌注为 0,1 美元。 在一种颜色上，一次最多可以下注 5000 美元。',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    '注意！ 在同一轮中，您不能同时投注红色和黑色。 每轮都可以投注绿色！',

  'When does the determination of the winner slot begin?': '何時開始確定獲勝分區？',
  'The winner slot is determined at the end of a round.': '回合結束時，將確定獲勝分區。',

  'Is there any commission in this mode?': '這種模式是否有手續費？',
  'There is no commission at all. :)': '沒有任何手續費。:)',
};
