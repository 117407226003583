import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { URLS } from 'services/constant-urls';
import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';
import {
  Avatar,
  Container,
  Header,
  Img,
  Level,
  Name,
  ProfileType,
  UserInfo,
} from './header.styles';

interface IHeaderComp {
  profile: IUserInfo;
}

export const HeaderComp: FC<IHeaderComp> = React.memo(({ profile }) => {
  const { t } = useTranslation();
  const hasProfile = !!profile?.id;
  const { avatar, name, experience, profileType, country, premium } = profile ?? {};
  const showDay = profileType === 'diamond' || profileType === 'platinum';
  const days = Math.floor(
    dayjs.duration(dayjs(premium?.until).diff(dayjs.utc().format())).asDays()
  );

  return hasProfile ? (
    <Header>
      {avatar && <Avatar src={avatar} alt={name} />}
      <UserInfo>
        {name && <Name>{name}</Name>}
        {profileType && (
          <ProfileType data-color={profileType}>
            {t(`${profileType}_adjective`)}
            {showDay && days > 0 && (
              <>
                {` ${days} `}
                {t('pluralize-days', {
                  count: days,
                  postProcess: 'with-template',
                })}
              </>
            )}
          </ProfileType>
        )}
      </UserInfo>
      {experience && <Level>{experience.level} lvl</Level>}
      {country && <Img src={`${URLS.countryFlags}/${country?.toLowerCase()}.png`} alt={country} />}
    </Header>
  ) : (
    <Container className="skeleton" />
  );
}, isEqual);
