import { Reducer } from 'redux';
import Immutable from 'immutable';
import { combineEpics, Epic, ofType } from 'redux-observable';
import {
  IBatchRequest,
  IFetchSellingSuccessResponse,
  IInventoryElement,
  IMarketplaceElement,
  ISalesRecord,
  IStoreUpdateResponse,
  MarketplaceActionTypes,
  salesActions,
  SalesActionTypes,
  SalesRecord,
  TradeActions,
} from '../interfaces';
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { StoreRepository } from '../repositories';
import { concat, iif, of, throwError } from 'rxjs';
import { addNotification } from '../../Notifications/duck';
import { fetchSteamInventory } from './inventory.duck';
import { BIDDING_STATUSES, OrderStatusEnum, WAITING_STATUSES } from '../configs';

export const sales: Reducer<Immutable.Record<ISalesRecord>, salesActions> = (
  state = new SalesRecord(),
  action
) => {
  switch (action.type) {
    case SalesActionTypes.FETCH_SELLING_ITEMS: {
      return new SalesRecord({ loading: true, loaded: false, failure: false });
    }

    case SalesActionTypes.FETCH_SELLING_ITEMS_SUCCESS: {
      const { entities, selling } = action.payload;
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('failure', false)
        .set('selling', selling)
        .set('entities', entities);
    }

    case SalesActionTypes.ADD_TO_SELECTED: {
      const { item } = action.payload;
      const id = item.steamInventoryId.toString();
      const PRICE_PERCENT_DEFAULT_VALUE = 5;
      const itemInitialPrice = item.safePrice
        ? item.safePrice
        : item.price - (PRICE_PERCENT_DEFAULT_VALUE * item.price) / 100;
      const price = Math.max(Math.floor(itemInitialPrice), item.priceRange.start);

      return state
        .updateIn(['selected', 'identifiers'], v => [id, ...v])
        .updateIn(['selected', 'computed', 'amount'], v => v + price)
        .setIn(['entities', id], {
          ...item,
          priceRange: {
            start: item.priceRange.start,
            end: item.priceRange.end,
          },
          selling: {
            price: price,
            tradesDuration: 1,
          },
        });
    }

    case SalesActionTypes.UPDATE_IN_SELECTED: {
      const { steamInventoryId, ...rest } = action.payload;
      const id = steamInventoryId.toString();
      const price = state.getIn(['entities', id, 'selling', 'price']);
      return state
        .updateIn(['entities', id, 'selling'], origin => ({
          ...origin,
          ...rest,
        }))
        .updateIn(['selected', 'computed', 'amount'], v => v - price + rest.price);
    }

    case SalesActionTypes.REMOVE_FROM_SELECTED: {
      const { id } = action.payload;
      const price = state.getIn(['entities', id, 'selling', 'price']);
      return state
        .deleteIn(['entities', id])
        .updateIn(['selected', 'computed', 'amount'], v => v - price)
        .updateIn(['selected', 'identifiers'], origin => origin.filter((v: string) => v !== id));
    }

    case SalesActionTypes.RESET_SELECTED: {
      return state.setIn(['selected'], {
        computed: {
          amount: 0,
        },
        identifiers: [],
      });
    }

    case SalesActionTypes.FETCH_CREATE_PUBLICATION_SUCCESS: {
      return state.set('selected', {
        computed: { amount: 0 },
        identifiers: [],
      });
    }

    case SalesActionTypes.FETCH_PAUSE_SELLING_SUCCESS:
    case SalesActionTypes.FETCH_RESUME_SELLING_SUCCESS:
    case MarketplaceActionTypes.MARKETPLACE_PARTICIPANT_UPDATE: {
      const { id, ...rest } = action.payload;
      const idx = id.toString();
      const item = state?.getIn(['entities', idx]);

      if (state.hasIn(['entities', idx])) {
        return state.mergeIn(['entities', idx], {
          ...rest,
          status:
            (item.status === OrderStatusEnum.WAIT_FOR_TRADE &&
              rest.status === OrderStatusEnum.ORDER_CRATED) ||
            !rest.status
              ? item.status
              : rest.status,
        });
      }

      return state;
    }

    case MarketplaceActionTypes.MARKETPLACE_STORE_ITEMS_UPDATE: {
      const { items } = action.payload;
      return items.reduce(
        (acc: Immutable.Record<ISalesRecord>, item: IStoreUpdateResponse) =>
          acc.hasIn(['entities', item.id.toString()])
            ? state.mergeIn(['entities', item.id.toString()], item)
            : acc,
        state
      );
    }

    case SalesActionTypes.FETCH_DELETE_SELLING_ITEM_SUCCESS: {
      const { id } = action.payload;
      return state.updateIn(['selling', 'identifiers'], origin =>
        origin.filter((key: string) => key !== id)
      );
    }

    default: {
      return state;
    }
  }
};

export const addToSelected = (item: IInventoryElement) => ({
  type: SalesActionTypes.ADD_TO_SELECTED,
  payload: {
    item,
  },
});

export const updateInSelected = (data: Partial<IBatchRequest>) => ({
  type: SalesActionTypes.UPDATE_IN_SELECTED,
  payload: data,
});

export const removeFromSelected = (id: string) => ({
  type: SalesActionTypes.REMOVE_FROM_SELECTED,
  payload: {
    id,
  },
});

export const resetSelected = () => ({
  type: SalesActionTypes.RESET_SELECTED,
});

export const fetchSellingItems = () => ({
  type: SalesActionTypes.FETCH_SELLING_ITEMS,
});

const fetchSellingItemsSuccess = (payload: IFetchSellingSuccessResponse) => ({
  type: SalesActionTypes.FETCH_SELLING_ITEMS_SUCCESS,
  payload,
});

const salesLoaderEpic: Epic = action$ =>
  action$.pipe(
    ofType(SalesActionTypes.FETCH_SELLING_ITEMS),
    startWith({}),
    switchMap(() =>
      StoreRepository.fetchSales().pipe(
        pluck('response', 'kits'),
        map((kits: IMarketplaceElement[]) =>
          kits?.reduce(
            ({ selling: { identifiers, computed }, entities }, item) => {
              const isActive =
                BIDDING_STATUSES.includes(item.status) || WAITING_STATUSES.includes(item.status);
              return {
                selling: {
                  computed: {
                    active: isActive ? computed.active + 1 : computed.active,
                    amount: isActive ? item.price + computed.amount : computed.amount,
                  },
                  identifiers: [item.id.toString(), ...identifiers],
                },
                entities: { ...entities, [item.id.toString()]: item },
              };
            },
            {
              selling: { computed: { amount: 0, active: 0 }, identifiers: [] },
              entities: {},
            }
          )
        ),
        map(response => fetchSellingItemsSuccess(response))
      )
    )
  );

export const fetchCreatePublication = (payload: Record<string, boolean>) => ({
  type: SalesActionTypes.FETCH_CREATE_PUBLICATION,
  payload,
});

const fetchCreatePublicationSuccess = () => ({
  type: SalesActionTypes.FETCH_CREATE_PUBLICATION_SUCCESS,
});

const createPublicationEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(SalesActionTypes.FETCH_CREATE_PUBLICATION),
    debounceTime(500),
    pluck('payload'),
    map(({ autoApprove }: { autoApprove: boolean }) => {
      const sales = store$.value.market.sales;
      const identifiers = sales.getIn(['selected', 'identifiers']);
      const entities = sales.get('entities');
      return {
        items: identifiers.map((id: string) => ({
          steamInventoryId: Number(entities[id].steamInventoryId),
          price: entities[id].selling.price,
        })),
        autoApprove,
        fastDelivery: false,
        tradesDuration: entities[identifiers[0]]?.selling.tradesDuration ?? 1,
      };
    }),
    switchMap(items =>
      StoreRepository.fetchCreateSales(items).pipe(
        concatMap(() => [
          fetchCreatePublicationSuccess(),
          fetchSellingItems(),
          fetchSteamInventory(true),
        ]),
        catchError(({ response }) =>
          concat([resetSelected(), addNotification({ type: 'error', body: response.message })])
        )
      )
    )
  );

export const fetchPauseSelling = (id: number) => ({
  type: SalesActionTypes.FETCH_PAUSE_SELLING,
  payload: {
    id,
  },
});

const fetchPauseSellingSuccess = (response: IMarketplaceElement) => ({
  type: SalesActionTypes.FETCH_PAUSE_SELLING_SUCCESS,
  payload: response,
});

const pauseSellingEpic: Epic = action$ =>
  action$.pipe(
    ofType(SalesActionTypes.FETCH_PAUSE_SELLING),
    debounceTime(500),
    pluck('payload', 'id'),
    switchMap((id: number) =>
      StoreRepository.fetchPauseItems({ itemIds: [id] }).pipe(
        pluck('response', 'items'),
        map(([response]) => fetchPauseSellingSuccess(response)),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

export const fetchResumeSelling = (id: number) => ({
  type: SalesActionTypes.FETCH_RESUME_SELLING,
  payload: {
    id,
  },
});

const fetchResumeSellingSuccess = (response: IMarketplaceElement) => ({
  type: SalesActionTypes.FETCH_RESUME_SELLING_SUCCESS,
  payload: response,
});

const resumeSellingEpic: Epic = action$ =>
  action$.pipe(
    ofType(SalesActionTypes.FETCH_RESUME_SELLING),
    debounceTime(500),
    pluck('payload', 'id'),
    switchMap(id =>
      StoreRepository.fetchResumeItems({ kitIds: [id] }).pipe(
        pluck('response'),
        mergeMap(({ errors, items }: Record<string, any[]>) =>
          iif(
            () => items.length === 0,
            throwError({ response: { message: errors[0]?.error } }),
            of(items[0])
          )
        ),
        map(response => fetchResumeSellingSuccess(response)),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

export const fetchDeleteSellingItem = (id: number) => ({
  type: SalesActionTypes.FETCH_DELETE_SELLING_ITEM,
  payload: { id },
});

const fetchDeleteSellingItemSuccess = (id: string) => ({
  type: SalesActionTypes.FETCH_DELETE_SELLING_ITEM_SUCCESS,
  payload: {
    id,
  },
});

const deleteSellingEpic: Epic = action$ =>
  action$.pipe(
    ofType(SalesActionTypes.FETCH_DELETE_SELLING_ITEM),
    debounceTime(500),
    pluck('payload', 'id'),
    switchMap((id: number) =>
      StoreRepository.fetchDeleteSellingItems(id).pipe(
        map(() => fetchDeleteSellingItemSuccess(id.toString())),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

export const fetchTradeAction = (id: number, tradeAction: TradeActions) => ({
  type: SalesActionTypes.FETCH_TRADE_ACTION,
  payload: { id, tradeAction },
});

const sellerEpic: Epic = action$ =>
  action$.pipe(
    ofType(SalesActionTypes.FETCH_TRADE_ACTION),
    debounceTime(500),
    pluck('payload'),
    switchMap(({ id, tradeAction }) =>
      StoreRepository.fetchTradeActionItem(id, tradeAction).pipe(
        map(() => fetchSteamInventory(false)),
        catchError(({ response }) => of(addNotification({ type: 'error', body: response.message })))
      )
    )
  );

export const salesEpics = combineEpics(
  createPublicationEpic,
  pauseSellingEpic,
  resumeSellingEpic,
  deleteSellingEpic,
  salesLoaderEpic,
  sellerEpic
);
