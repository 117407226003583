import styled from 'styled-components';

export const Container = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content 4.125rem min-content;
  grid-gap: 1.25rem;
  padding: 1.125rem 1rem 2rem;
`;
export const Header = styled.div`
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0.5px;
  color: var(--color-white);
`;
