import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getStatusDeleteNotices } from '../../../Notifications/selectors';
import { toggleDeleteNotices } from '../../../Notifications/duck';
import { Text } from './NoticeNavigateWidget.styles';

export const NoticeNavigateWidget = () => {
  const status = useSelector(getStatusDeleteNotices, shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Text onClick={() => dispatch(toggleDeleteNotices())}>
      {!status ? t('Choose') : t('Cancel')}
    </Text>
  );
};
