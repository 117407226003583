import { Button } from '@components/Common';
import styled from 'styled-components';

export const StyledDemoButton = styled(Button)`
  color: var(--aqua-500);
  background: transparent;
  width: 0;
  border: 1px solid var(--aqua-500);
  display: flex;
  height: 48px;
  flex: 2;
`;
