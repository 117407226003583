import React from 'react';
import styled from 'styled-components';
import { TimeToRaffling } from './time-to-raffling';
import { FactorControl } from './factor-control';
import { SectorColors } from '../../../utils';
import { useDialog } from 'core/hooks';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';

export const FactorControls = () => {
  const { handleToggleDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);

  return (
    <Container>
      <TimeToRaffling />
      <div className="controllers">
        <FactorControl
          color={SectorColors.RED}
          factor="2"
          onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
            module: ParticipateModule.DOUBLE,
            color: SectorColors.RED,
            factor: '2',
          })}
        />
        <FactorControl
          color={SectorColors.ZERO}
          factor="14"
          onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
            module: ParticipateModule.DOUBLE,
            color: SectorColors.ZERO,
            factor: '14',
          })}
        />
        <FactorControl
          color={SectorColors.BLACK}
          factor="2"
          onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
            module: ParticipateModule.DOUBLE,
            color: SectorColors.BLACK,
            factor: '2',
          })}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  align-self: self-end;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.3125rem;

  & .controllers {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 4.75rem;
    grid-gap: 0.25rem;
    padding: 0 1rem 1.75rem;

    @media screen and (min-width: 375px) {
      grid-template-rows: 5.25rem;
    }
  }
`;
