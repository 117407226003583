import React, { FC, memo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Timer } from '@components/TimerEvent';
import { Button } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { BIDDING_STATUSES, FINISH_STATUSES, OrderStatusEnum } from '../../../../../configs';
import { useDeleteBid, useMarketplaceElement } from '../../../../../hooks';
import { MarketplaceDialogs } from '../../../../../marketplace-dialogs.config';
import { skinItemImage } from 'core/utils/skinItemImage.utils';
import { skinStickerImage } from 'core/utils/skinStickerImage.utils';
interface IOrdersPurchaseElement {
  identifier: string;
}
interface IOrdersPurchaseElementStyle {
  next: string;
  current: string;
  delta?: number;
  progress?: number;
  rarity?: string;
}
export const OrdersPurchaseElement: FC<IOrdersPurchaseElement> = memo(({ identifier }) => {
  const { t } = useTranslation('marketplace');
  const { item } = useMarketplaceElement('purchases', identifier);
  const { deleteBid } = useDeleteBid();
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.INVENTORY_BUYER_TRADE_DIALOG);
  if (!item) return <div />;
  return (
    <>
      <Container
        className={item.status}
        rarity={item.items?.[0].baseItem.color}
        next={item.nextStatusAt}
        current={item.statusAt}
      >
        <div className="side-bar-controllers">
          <Button onClick={deleteBid(item.id)} disabled={!FINISH_STATUSES.includes(item.status)}>
            <i className="icon-marketplace-delete" />
          </Button>
        </div>
        <div className="wrapper">
          <div className="progress" />
          <div className="thumbnails">
            <img
              srcSet={`${skinItemImage(item.items?.[0].baseItem?.icon, 80, 60)} 1x, ${skinItemImage(
                item.items?.[0].baseItem?.icon,
                160,
                120
              )} 2x`}
              src={skinItemImage(item.items?.[0].baseItem?.icon, 80, 60)}
              alt=""
            />
            <div className="stickers">
              {item.items?.[0]?.stickers?.map(({ icon }: { icon: string }, i: number) => (
                <img key={i} src={skinStickerImage(icon)} alt="" />
              ))}
            </div>
          </div>
          <div className="detailed">
            <div className={`float ${getExteriorByFloat(item.items?.[0]?.float)}`}>
              {!!item.items?.[0]?.float && item.items?.[0]?.float?.toFixed(4)}
            </div>
            <div className="until-time">
              <Timer finishEvent={item.nextStatusAt} />
            </div>
            <div className="display-name">{item.items?.[0].baseItem.name}</div>
            <div className="display-price">
              <CurrencyText value={item.price} />
              {item.price !== item.recommendedPrice && (
                <span
                  className={`${item.price > item.recommendedPrice ? 'overprice' : 'underprice'}`}
                >
                  {' ('}
                  {item.price > item.recommendedPrice && '+'}
                  {Math.round((item.price / item.recommendedPrice) * 100) - 100}
                  {'%)'}
                </span>
              )}
            </div>
            {item.status === OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT && (
              <div className="controller">
                <Button
                  className="primary small"
                  onClick={handleToggleDialog(MarketplaceDialogs.INVENTORY_BUYER_TRADE_DIALOG, [
                    item.id,
                  ])}
                >
                  <div className="trade-offer-btn">
                    <i className="icon-steam-provider" />
                    <span>{t('Accept trade')}</span>
                  </div>
                </Button>
              </div>
            )}
            {item.status === OrderStatusEnum.WAIT_FOR_TRADE && (
              <div className="statuses">{t('purchase-statuses.wait-for-trade')}</div>
            )}
            {item.status === OrderStatusEnum.ORDER_CRATED && (
              <div className="statuses">{t('purchase-statuses.order-crated')}</div>
            )}
            {BIDDING_STATUSES.includes(item.status) && (
              <div className="controller">
                <Button
                  className="primary-outline small"
                  onClick={handleToggleDialog(MarketplaceDialogs.MARKETPLACE_STORE_PURCHASE, [
                    'purchases',
                    item.id,
                  ])}
                >
                  {item.isLastBidByUser ? t('Your offer is the best') : t('Offer')}
                </Button>
              </div>
            )}
            {FINISH_STATUSES.includes(item.status) && (
              <>
                <div className="status-at">{dayjs(item.statusAt).format('DD:MM:YYYY HH:mm')}</div>
                <div className="statuses">{t(`purchase-statuses.${item.status}`)}</div>
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
});
const Container = styled.div.attrs<IOrdersPurchaseElementStyle>(({ next, current }) => ({
  delta: (Date.parse(next) - Date.now()).toFixed(0),
  progress: (dayjs(current).diff(dayjs()) / dayjs(current).diff(next)) * 100,
}))<IOrdersPurchaseElementStyle>`
  position: relative;
  & .side-bar-controllers {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    width: 3rem;
    border-radius: var(--size-radius-m) 0 0 var(--size-radius-m);
    padding: 0.125rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    & button {
      justify-self: center;
      align-self: center;
      background: transparent;
      & i {
        font-size: 1.375rem;
        color: var(--color-white);
      }
    }
  }
  & .wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 0.75fr 1fr;
    grid-gap: 1rem;
    width: 100%;
    height: 100%;
    padding: 0.875rem;
    background: var(--color-dark-200);
    border-radius: var(--size-radius-m);
    position: relative;
    overflow: hidden;
    transition: all 150ms ease;
    will-change: transform;
    mask-image: radial-gradient(white, black);
    &:before {
      content: '';
      width: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: var(--size-radius-m) 0 0 var(--size-radius-m);
      box-shadow: inset 0.125rem 0 0 ${({ rarity }) => rarity || 'transparent'};
    }
    &.canceled-by-seller-before-trade,
    &.canceled-by-buyer,
    &.canceled-by-seller,
    &.canceled-by-timer,
    &.canceled-by-fastoffer,
    &.auction-finished,
    &.completed,
    &.deleted {
      & .progress,
      & .until-time {
        display: none;
      }
    }
    & .progress {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      animation-timing-function: linear;
      animation-name: slide;
      animation-fill-mode: forwards;
      background-color: var(--color-gray-300);
      animation-duration: ${({ delta }) => `${delta}ms`};
      transform: ${({ progress }) => `translate3d(-${(100 - progress).toFixed(2)}%, 0, -1px)`};
    }
    & .thumbnails,
    & .detailed {
      position: relative;
      z-index: 20;
    }
    & .thumbnails {
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: 1fr minmax(0, 2rem);
      grid-gap: 0.175rem;
      overflow: hidden;
      & img {
        align-self: center;
        justify-self: center;
        max-width: 90%;
        width: 100%;
        object-fit: contain;
      }
      & .stickers {
        justify-self: center;
        align-self: center;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1.5rem;
        grid-template-rows: 1.5rem;
        grid-gap: 0.5rem;
        & img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
        }
      }
    }
    & .detailed {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: min-content;
      grid-gap: 0.25rem 1.375rem;
      & .until-time,
      & .float {
        height: 0.875rem;
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.3px;
      }
      & .float {
        color: transparent;
        &.factory-new {
          color: var(--color-exterior-factory-new);
        }
        &.minimal-wear {
          color: var(--color-exterior-minimal-wear);
        }
        &.field-tested {
          color: var(--color-exterior-field-tested);
        }
        &.well-worn {
          color: var(--color-exterior-well-worn);
        }
        &.battle-scarred {
          color: var(--color-exterior-battle-scarred);
        }
      }
      & .until-time {
        color: var(--color-gray-200);
      }
      & .display-name,
      & .display-price {
        grid-column: 1 / 4;
        font-size: 0.875rem;
        letter-spacing: 0.24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--color-white);
      }
      & .display-name {
        grid-row: 2;
        align-self: center;
      }
      & .display-price {
        grid-row: 3;
        align-self: self-start;
        height: 1.75rem;
        & span {
          font-size: inherit;
          letter-spacing: inherit;
          &.overprice {
            color: var(--color-red);
          }
          &.underprice {
            color: var(--color-green);
          }
        }
      }
      & .controller,
      & .statuses,
      & .status-at {
        grid-column: 1 / 4;
      }
      & .controller {
        grid-row: 4;
        height: 2.5rem;
        & button {
          justify-content: center;
          width: 100%;
          height: 2rem;
          padding: 0 0.5rem;
          font-size: 0.6875rem;
          line-height: 1rem;
          text-transform: none;
          font-weight: 500;
          @media screen and (min-width: 375px) {
            font-size: 0.75rem;
          }
          & .trade-offer-btn {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1rem 1fr 1rem;
            grid-gap: 0.5rem;
            align-content: stretch;
            width: 100%;
            height: 100%;
            & i,
            & span {
              align-self: center;
              justify-self: center;
            }
            & i {
              font-size: 1rem;
            }
          }
        }
      }
      & .statuses,
      & .status-at {
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.3px;
        color: var(--color-white);
      }
    }
    @keyframes slide {
      to {
        transform: translate3d(-100%, 0, 1px);
      }
    }
  }
`;
