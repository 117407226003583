import React from 'react';
import { Modal } from 'core/Modals/container';
import { transitions } from 'core/Modals';
import { promoModals } from '../../modal.config';
import { PromoContent } from './modal-content';

export const PromoModal = () => (
  <Modal
    modalComponent={PromoContent}
    modalName={promoModals.REFILL_PROMO}
    anchor="portal"
    transition={transitions.SLIDE_UP}
  />
);
