import React, { FC, useMemo } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AnimatedRoute } from './animated-router.styles';

export interface AnimatedRouteProps {
  children?: React.ReactNode;
  deep?: boolean;
}

export const AnimatedRouter: FC<AnimatedRouteProps> = ({ children, deep = true }) => {
  const location = useLocation();

  const keys = useMemo(() => {
    return deep ? location.pathname : location.pathname.split('/')[1];
  }, [location.pathname, deep]);

  return (
    <TransitionGroup>
      <CSSTransition
        key={keys}
        timeout={300}
        classNames="my-class"
        mountOnEnter={false}
        unmountOnExit={true}
      >
        {() => {
          return (
            <AnimatedRoute>
              <Switch location={location}>{children}</Switch>
            </AnimatedRoute>
          );
        }}
      </CSSTransition>
    </TransitionGroup>
  );
};
