import React, { useState } from 'react';
import { useGetCaseData, useGetRouletteStatus } from 'games/CaseGame/hooks';
import { FreeButton } from './FreeOpenButton.styles';
import {
  RouletteStatus,
  openCaseActionRequest,
  rouletteStatusActionAllStartRoll,
} from 'games/CaseGame/ducks/roulette-duck';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetCategory } from 'modules/event-module/comics-event/hooks';
import { CasesQuestStatusEnum } from 'modules/event-module/comics-event/interfaces';
import { actionPickUpPrizeOpened } from 'modules/event-module/comics-event/duck/default-event-duck';
interface FreeOpenButtonProps {
  caseId: string;
  lineCount: number;
  categoryId: number;
}

export const FreeOpenButton: React.FC<FreeOpenButtonProps> = React.memo(
  ({ lineCount, caseId, categoryId }) => {
    const { category } = useGetCategory(categoryId);
    const { data } = useGetCaseData();
    const [count, setCount] = useState(data?.free?.count ?? 'not available');
    const history = useHistory();
    const { t } = useTranslation('cases2.0');
    const { rouletteStatus, isAllInRoll, isAllStartRoll, isAllAfterRoll } = useGetRouletteStatus();
    const dispatch = useDispatch();

    const clickHandler = () => {
      if (rouletteStatus[0] !== RouletteStatus.IN_ROLL && lineCount) {
        dispatch(rouletteStatusActionAllStartRoll());
        dispatch(
          openCaseActionRequest({
            caseId,
            type: 'payable',
            lineCount,
            isFree: true,
            setCount: () => setCount(prev => (prev as number) - 1),
          })
        );

        if (
          category?.status === CasesQuestStatusEnum.AVAILABLE ||
          category?.status === CasesQuestStatusEnum.TAKEN
        ) {
          dispatch(actionPickUpPrizeOpened(categoryId));
        }
      }
    };

    const getClickHandler = () => {
      if (count === 0) {
        return history.push('../cases');
      } else {
        return clickHandler();
      }
    };

    const getText = () => {
      if (count === 'not available') {
        return t('Not available');
      }
      if (count === 0) {
        return t('okey');
      } else {
        return (
          <>
            {t('openCount')} {count}
          </>
        );
      }
    };
    return (
      <FreeButton
        isAllStartRoll={isAllStartRoll}
        isAllAfterRoll={isAllAfterRoll}
        onClick={getClickHandler}
        disabled={isAllInRoll || isAllStartRoll || count === 'not available'}
      >
        {isAllInRoll || isAllStartRoll ? t('draw') : getText()}
      </FreeButton>
    );
  }
);
