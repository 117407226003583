import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { MiniPreloader } from '../../../../@components/Preloader';
import { SkinItem } from '../SkinItem';

class _List extends PureComponent {
  render() {
    const { addItemToCart, gameType, type, isFetching, items, t } = this.props;

    if (isFetching) {
      return (
        <NotLent>
          <MiniPreloader />
        </NotLent>
      );
    }

    if (!isFetching && !!gameType.error && !items.length) {
      return (
        <Message>
          <span>{t(gameType.error)}</span>
        </Message>
      );
    }

    return (
      <Container isVirtual={type === 'virtual'}>
        <div>
          {items.map(item => (
            <SkinItem key={item.id} {...item} addAction={addItemToCart(item)} />
          ))}
        </div>
      </Container>
    );
  }
}

export const List = withTranslation()(_List);

const Container = styled.div`
  height: 100%;

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32%);
    grid-gap: 5px;
    grid-auto-rows: 1fr;
    width: 100%;
    padding: 10px;

    &::before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    & *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  & > span {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1.4px;
    text-align: center;
    color: var(--color-white);

    & > a {
      text-decoration: underline;
      color: inherit;
    }
  }
`;

const NotLent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 91px);
`;
