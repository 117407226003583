import styled from 'styled-components';

export const Container = styled.div`
  & .gift-card-description {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.75rem;
    padding: 1.125rem 1.125rem 1rem;

    & span {
      color: var(--color-white);

      &:first-of-type {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }

      &:last-of-type {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }

    & img {
      margin-top: 1.375rem;
    }
  }

  & .inner-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32%);
    grid-gap: 0.3125rem;
    grid-auto-rows: 1fr;
    justify-content: center;
    padding: 0 1rem 1.1875rem;

    &::before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    & *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  }
`;

export const ChipItem = styled.a`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--color-dark-200);
  border-radius: var(--size-radius-s);
  border: 0;
  transition: all var(--transition-ease-out);
  position: relative;
  overflow: hidden;

  &:disabled {
    opacity: 0.3;
  }

  &:active {
    background: var(--color-dark-600);
  }

  &.active {
    background: #47555f;
  }

  & .chip-thumbnail {
    width: 3.375rem;
    height: 3.5rem;
    object-fit: contain;
  }

  & .chip-price {
    font-weight: bold;
    font-size: 0.8125rem;
    line-height: 0.9375rem;
    letter-spacing: 0.69px;
    color: white;
  }

  & .chip-bonuses {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.3px;
    color: var(--green);
  }

  & .event-boost {
    position: absolute;
    top: -1.25rem;
    right: -1.25rem;

    & span {
      font-weight: bold;
      font-size: 0.8125rem;
      line-height: 0.9375rem;
      letter-spacing: 0.69px;
      color: black;
      position: absolute;
      left: 0.75rem;
      bottom: 0.75rem;
    }

    & .icon-coupon {
      font-size: 3.2rem;
      color: var(--color-orange-ticket);

      &:last-of-type {
        color: rgba(255, 219, 12, 0.3);
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        z-index: 1;
        transform: rotate(-5deg);
      }
    }
  }
`;
