import React from 'react';
import { StyledNavigationLink, Title } from '../styles';
import { Link } from 'react-router-dom';
import { usePayoutMethods } from 'modules/withdrawal/hooks';
import { useUserBalance } from 'core/User';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { getMinimalMaxValue } from 'modules/withdrawal/components/utils';
import { URLS } from 'services/constant-urls';
import { onErrorHandler } from 'core/utils';

interface WithDrawalMethodsProps {
  title: string;
  name: string;
  rate: string;
}
export const WithDrawalMethods: React.FC<WithDrawalMethodsProps> = React.memo(
  ({ title, name, rate }) => {
    const { minForOtherPayments } = usePayoutMethods();
    const { coins } = useUserBalance();
    const { t } = useTranslation();

    const { minimum } = getMinimalMaxValue(coins, minForOtherPayments[title], +rate);

    const isActive = coins > minimum;

    return (
      <StyledNavigationLink to={isActive ? `/withdrawal/method/${name}` : '#'} as={Link}>
        <div className="leading">
          <img
            src={`${URLS.refill.icon}/refill-${name}.svg`}
            alt={name}
            onError={e => onErrorHandler(e, URLS.refill.error)}
          />
        </div>
        <div>
          <Title isActive={isActive}>{title}</Title>

          {!isActive && (
            <span className="badge-light">
              {t('from')} <CurrencyText value={minimum} />
            </span>
          )}
        </div>
      </StyledNavigationLink>
    );
  }
);

//NOTE: isActive ? `/withdrawal/method/${name}` : '#' we need this operation to avoid skipping out condition by DOM (Link === a ), that`s why user can inspect element and found route
//TODO: need to add disabled icon
