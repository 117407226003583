export default {
  Language: '語言',
  'Bets in current room': '在目前房間內下注',
  Timers: '計時器',
  'Game start': '遊戲開始',
  'Game ending': '遊戲結束',
  'Roulette ticking': '輪盤滴答聲',
  'Roulette start': '轮盘赌开始',
  'Item set': 'Item set',
  'My bet': '我的投注',
  'Coins transfer': '奖金转移',
  'Start button': '开始按钮',
  'Stop button': '停止按钮',
  Countdown: '倒计时',
  Soundtrack: '原声带',
  Crash: 'Crash',
  'Picking item': '挑选物品',
  Crafting: '手工制作',
  Failure: '失败',
  Win: '獎品',
  Sell: '出售',
  Take: '拿',
  'Rooms bets': '房間賭注',
  'Chat message sending': '交談訊息傳送',
  'Chat message receiving': '交談訊息接收',
  Sound: '声音',
  'Chat sound': '游戏声音',
  'Game sounds': '聊天声音',
  Volume: '音量',
  steamApi: {
    noKey: '無 STEAM API 金鑰',
    need: '需要用於與其他玩家出售和交易皮膚',
    addLink: '添加連結',
    link: '如何獲取？',
  },
};
