import {
  BetConditions,
  IRaceRecord,
  IRacesStoryRecord,
  IRaffle,
  IRafflingItem,
  ITournamnetType,
  receiveTakenAtResponse,
  responseBetConfirm,
  SteamAppId,
  updateParams,
} from './reducer.leaders.interfaces';

// races
export enum recesActionsTypes {
  FETCH_RACES = 'app/leader-race/FETCH_RACES',
  UPDATE_RACES = 'app/leader-race/UPDATE_RACES',
}

export interface IUpdateRaces {
  type: typeof recesActionsTypes.UPDATE_RACES;
  payload: {
    races: IRaceRecord[];
  };
}

export type recesActions = IUpdateRaces;

//quests
export enum questActionsTypes {
  ADD_QUESTS = 'app/leader-race/quest/ADD_QUESTS',
  UPDATE_QUESTS = 'app/leader-race/quest/UPDATE_QUESTS',
}

interface IAddQuests {
  type: typeof questActionsTypes.ADD_QUESTS;
  payload: {
    quests: any[];
  };
}

interface IUpdateQuests {
  type: typeof questActionsTypes.UPDATE_QUESTS;
  payload: {
    params: updateParams;
  };
}

export type questActions = IAddQuests | IUpdateQuests;
//coupons
export enum couponsActionsTypes {
  FETCH_TICKETS = 'app/leader-race/tickets/FETCH_TICKETS',
  UPDATE_TICKETS = 'app/leader-race/tickets/UPDATE_COUPONS',
}

export interface IUpdateCoupon {
  type: typeof couponsActionsTypes.UPDATE_TICKETS;
  payload: {
    coupons: any;
  };
}

export type couponActions = IUpdateCoupon;

//raffles

export enum raffleActionsTypes {
  INITIALIZE_RAFFLE = 'app/leader-race/raffles/INITIALIZE_RAFFLE',
  UPDATE_RAFFLE_STAGE = 'app/leader-race/raffles/UPDATE_RAFFLE_STAGE',
  UPDATE_RAFFLE_PHASE = 'app/leader-race/raffles/UPDATE_RAFFLE_PHASE',
  UPDATE_RAFFLE_ITEMS = 'app/leader-race/raffles/UPDATE_RAFFLE_ITEMS',
  UPDATE_RAFFLES = 'app/leader-race/raffles/UPDATE_RAFFLES',
  UPDATE_GREAT_RAFFLES = 'app/leader-race/raffles/UPDATE_GREAT_RAFFLES',

  // create bet
  FETCH_ADD_BET_REQUEST = 'app/leader-race/raffles/FETCH_ADD_BET_REQUEST',
  FETCH_ADD_BET_RESPONSE = 'app/leader-race/raffles/FETCH_ADD_BET_RESPONSE',
  UPDATE_BETS_STATUS = 'app/leader-race/raffles/UPDATE_BETS_STATUS',
  RECEIVE_BET_CONFIRM = 'app/leader-race/raffles/RECEIVE_BET_CONFIRM',
  CLEAN_UP_ALL_RAFFLES = 'app/leader-race/raffles/CLEAN_UP_ALL_RAFFLES',
  RECEIVE_TAKEN_LOT_SUCCESS = 'app/leader-race/raffle/RECEIVE_TAKEN_LOT_SUCCESS',
}

export interface initRaffles {
  type: typeof raffleActionsTypes.INITIALIZE_RAFFLE;
  payload: {
    raffle: IRaffle;
    patchId: number;
  };
}

export interface IUpdateRaffleItems {
  type: typeof raffleActionsTypes.UPDATE_RAFFLE_ITEMS;
  payload: {
    id: number;
    items: any[];
  };
}

export interface IUpdateRaffles {
  type: typeof raffleActionsTypes.UPDATE_RAFFLES;
  payload: {
    data: any;
  };
}

export interface IUpdateRaffleStage {
  type: typeof raffleActionsTypes.UPDATE_RAFFLE_STAGE;
  payload: {
    raffleIds: number;
  };
}

export interface IUpdateRafflePhase {
  type: typeof raffleActionsTypes.UPDATE_RAFFLE_PHASE;
  payload: {
    phase: string;
    raffleId: number;
  };
}

export interface IUpdateGreatRaffles {
  type: typeof raffleActionsTypes.UPDATE_GREAT_RAFFLES;
  payload: {
    //TODO add base lot interface
    lot: any;
  };
}

export interface IFetchAddBet {
  type: typeof raffleActionsTypes.FETCH_ADD_BET_REQUEST;
  payload: {
    raceId: number;
    itemId: number;
  };
}

export interface IUpdateBetStatus {
  type: typeof raffleActionsTypes.FETCH_ADD_BET_RESPONSE;
  payload: IFetchAddBet['payload'] & {
    success?: BetConditions;
    error: string;
  };
}

export interface IUpdateBetsStatus {
  type: typeof raffleActionsTypes.UPDATE_BETS_STATUS;
  payload: {
    raffleId: number;
    items: IRafflingItem[];
    userId?: number;
  };
}

export interface IReceiveBetConfirm {
  type: typeof raffleActionsTypes.RECEIVE_BET_CONFIRM;
  payload: {
    betStatus: responseBetConfirm;
  };
}

export interface IReceiveTakenLot {
  type: typeof raffleActionsTypes.RECEIVE_TAKEN_LOT_SUCCESS;
  payload: {
    lot: any;
  };
}

export type raffleActions =
  | initRaffles
  | IUpdateRaffleItems
  | IUpdateRaffleStage
  | IUpdateRafflePhase
  | IUpdateGreatRaffles
  | IUpdateRaffles
  | IFetchAddBet
  | IUpdateBetStatus
  | IUpdateBetsStatus
  | IReceiveBetConfirm
  | IReceiveTakenLot;

// history

export enum historyActionsTypes {
  FETCH_All_HISTORY_START = 'app/leader-race/history/FETCH_HISTORY_START',
  RECEIVE_HISTORY_SUCCESS = 'app/leader-race/history/RECEIVE_HISTORY_SUCCESS',
  UPDATE_HISTORY_BY_ID = 'app/leader-race/history/UPDATE_HISTORY_BY_ID',
}

export interface IFetchHistory {
  type: typeof historyActionsTypes.FETCH_All_HISTORY_START;
  payload: {
    raceType: string;
  };
}

export interface IReceivHistory {
  type: typeof historyActionsTypes.RECEIVE_HISTORY_SUCCESS;
  payload: {
    raceType: string;
    history: ITournamnetType[];
  };
}

export interface IUpdateHistoryById {
  type: typeof historyActionsTypes.UPDATE_HISTORY_BY_ID;
  payload: {
    raceType: string;
    id: number;
    tournament: ITournamnetType;
  };
}

export enum hisotyRaffleActionsTypes {
  FETCH_ALL_RAFFLE_HISTORY_START = 'app/leader-race/history/raffle/CHANGE_REQUEST_START',
  RECEIVE_RAFFLE_HISTORY_SUCCESS = 'app/leader-race/history/raffle/RECEIVE_RAFFLE_HISTORY_SUCCESS',
  RECEIVE_RAFFLE_HISTORY_WITH_ITEMS_SUCCESS = 'app/leader-race/history/raffle/RECEIVE_RAFFLE_HISTORY_WITH_ITEMS_SUCCESS',
  RECEIVE_TAKEN_HISTORY_LOT_SUCCESS = 'app/leader-race/history/raffle/RECEIVE_TAKEN_HISTORY_LOT_SUCCESS',
}

interface IFetchHistoryInit {
  type: typeof hisotyRaffleActionsTypes.FETCH_ALL_RAFFLE_HISTORY_START;
}

interface IReceiveRaffleHistory {
  type: typeof hisotyRaffleActionsTypes.RECEIVE_RAFFLE_HISTORY_SUCCESS;
  payload: {
    history: IRacesStoryRecord[];
  };
}

interface IReceiveRaffleHistoryWithItems {
  type: typeof hisotyRaffleActionsTypes.RECEIVE_RAFFLE_HISTORY_WITH_ITEMS_SUCCESS;
  payload: {
    raffleId: number;
    items: any[];
  };
}

interface IReceiveTakenLotHistory {
  type: typeof hisotyRaffleActionsTypes.RECEIVE_TAKEN_HISTORY_LOT_SUCCESS;
  payload: {
    lot: receiveTakenAtResponse;
  };
}

export type raffleHistoryActions =
  | IFetchHistoryInit
  | IReceiveRaffleHistory
  | IReceiveRaffleHistoryWithItems
  | IReceiveTakenLotHistory;

export type historyActions = IFetchHistory | IReceivHistory | IUpdateHistoryById;

// store

export enum storeActionsTypes {
  FETCH_STORE_ITEMS = 'app/leader-race/store/FETCH_STORE_ITEMS',
  UPDATE_STORE_ITEMS = 'app/leader-race/store/UPDATE_STORE_ITEMS',
  //purchase

  FETCH_PURCHASE_ITEM = 'app/leader-race/store/FETCH_PURCHASE_ITEM',
  FETCH_PURCHASE_ITEM_RESPONSE = 'app/leader-race/store/FETCH_PURCHASE_ITEM_RESPONSE',
}

export interface IFetchStoreItems {
  type: typeof storeActionsTypes.FETCH_STORE_ITEMS;
  payload: {
    appId: number;
  };
}

export interface IUpdateStoreItems {
  type: typeof storeActionsTypes.UPDATE_STORE_ITEMS;
  payload: {
    items: any[];
    appId: SteamAppId;
  };
}

export type storeActions = IUpdateStoreItems | IFetchStoreItems;
