import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import { useSalesElement, useSalesSelectedControllers } from '../../../../hooks';
import { MarketplaceDialogs } from '../../../../marketplace-dialogs.config';
import { ISalesInventoryElement } from '../../../../interfaces';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IInventorySelectedElement {
  isSelloutMode: boolean;
  id: string;
}

export const InventorySelectedElement: FC<IInventorySelectedElement> = memo(
  ({ isSelloutMode, id }) => {
    const { item } = useSalesElement<ISalesInventoryElement>(id);
    const { handleRemoveFromSelected } = useSalesSelectedControllers(id);
    const { handleToggleDialog } = useDialog(MarketplaceDialogs.INVENTORY_SALE_CONFIGURATION);

    const onClick = useCallback(
      (id: string) => {
        if (isSelloutMode) return handleRemoveFromSelected(id);
        return handleToggleDialog(MarketplaceDialogs.INVENTORY_SALE_CONFIGURATION, { id });
      },
      [isSelloutMode, handleRemoveFromSelected, handleToggleDialog]
    );

    const newPrice = item.selling.price - item.price;
    const percent = parseInt(((100 * newPrice) / item.price).toString());

    return (
      <Container onClick={onClick(id)}>
        <div
          className={`${
            Number(percent) <= -5 ? 'underprice' : Number(percent) > 5 ? 'overprice' : ''
          }   percent`}
        >
          {item.selling.price > item.price && '+'}
          {percent}
          {'%'}
        </div>
        <button className={`close-button ${isSelloutMode ? 'visible' : ''}`}>
          <i className="icon-close" />
        </button>
        <div className="thumbnail">
          <img src={skinItemImage(item?.baseItem.icon, 50, 50)} alt="" />
        </div>
        <div className="price">
          <CurrencyText value={item.selling.price} />
        </div>
      </Container>
    );
  }
);

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content;
  grid-gap: 0.375rem;
  padding: 0.375rem;
  width: 100%;
  height: 100%;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-s);
  position: relative;
  transition: background 150ms ease-in-out;

  &:active {
    background: #47555f;
  }

  & .close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 0.5rem;
    height: 0.5rem;
    border: 0;
    background: transparent;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    transform: scale(0);
    transition: transform 150ms ease-in-out;

    &.visible {
      transform: scale(1);
    }

    & i {
      font-size: 0.5rem;
      color: var(--color-white);
    }
  }

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      opacity: 0.3;
    }
  }

  & .price {
    text-align: center;
    font-size: 0.5625rem;
    line-height: 0.6875rem;
    color: var(--color-white);
  }

  & .percent {
    text-align: center;
    font-size: 0.5625rem;
    line-height: 0.6875rem;
    color: var(--primary);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1.125rem;

    &.overprice {
      color: var(--color-red);
    }

    &.underprice {
      color: var(--color-green);
    }
  }
`;
