import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container } from './sort.styles';
import { RadioButtonControl } from '@UIkit';
import { Button } from '@components/Common';
import { ButtonContainer, CancelButton, Header, HeaderContainer } from '../general.styles';

interface SortProps {
  submitHandler: (value?: string) => void;
  sortParams: string[];
  closeHandler: () => void;
  i18nextKey?: string;
  value: string;
}

interface Form {
  sortBy: string;
}

export const Sort: React.FC<SortProps> = React.memo(
  ({ sortParams, submitHandler, closeHandler, i18nextKey, value }) => {
    const { t } = useTranslation(i18nextKey);

    const { register, handleSubmit } = useForm<Form>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

    const onSubmit = ({ sortBy }: Form) => {
      submitHandler(sortBy);
    };

    return (
      <Container>
        <HeaderContainer>
          <Header>{t('sortBy.label')}</Header>
          <CancelButton onClick={closeHandler}>{t('Close')}</CancelButton>
        </HeaderContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          {sortParams.map(sortValue => (
            <RadioButtonControl key={sortValue}>
              <input
                ref={register}
                name="sortBy"
                type="radio"
                value={sortValue}
                defaultChecked={value === sortValue}
              />
              <span>{t(`sortBy.${sortValue}`)}</span>
            </RadioButtonControl>
          ))}
          <ButtonContainer>
            <Button className="primary" type="submit">
              {t('sortBy.button')}
            </Button>
          </ButtonContainer>
        </form>
      </Container>
    );
  }
);
