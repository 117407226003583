import { List, Map, Record } from 'immutable';

export enum FactorTypes {
  HI = 'hi',
  LO = 'lo',
  RED = 'colorRed',
  JOKER = 'joker',
  BLACK = 'colorBlack',
  '2-9' = 'twoToNine',
  JQKA = 'jackToAce',
  KA = 'kingToAce',
  A = 'ace',
}

export const protectFactorType: {
  [k: string]: FactorTypes;
} = {
  [FactorTypes.HI]: FactorTypes.LO,
  [FactorTypes.LO]: FactorTypes.HI,
  [FactorTypes.BLACK]: FactorTypes.RED,
  [FactorTypes.RED]: FactorTypes.BLACK,
};

export const signTypes: {
  [k: string]: FactorTypes[];
} = {
  hi: [FactorTypes.HI],
  lo: [FactorTypes.LO],
  red: [FactorTypes.RED],
  black: [FactorTypes.BLACK],
  2: [FactorTypes['2-9']],
  3: [FactorTypes['2-9']],
  4: [FactorTypes['2-9']],
  5: [FactorTypes['2-9']],
  6: [FactorTypes['2-9']],
  7: [FactorTypes['2-9']],
  8: [FactorTypes['2-9']],
  9: [FactorTypes['2-9']],
  J: [FactorTypes.JQKA],
  Q: [FactorTypes.JQKA],
  K: [FactorTypes.KA, FactorTypes.JQKA],
  A: [FactorTypes.A, FactorTypes.KA, FactorTypes.JQKA],
  '🃏': [FactorTypes.JOKER],
};

export const profitFactorType: { [k: string]: string } = {
  HI: '1',
  LO: '1',
  RED: '2',
  JOKER: '24',
  BLACK: '2',
  '2-9': '1.5',
  JQKA: '3',
  KA: '6',
  A: '12',
};

export interface IGameCardRecord {
  color: string;
  id: number;
  secret: string;
  sign: string;
  suit: string;
  value: number;
}

export const GameCardRecord = Record<IGameCardRecord>({
  color: null,
  id: null,
  secret: null,
  sign: null,
  suit: null,
  value: null,
});

const FactorRecord = Record({ factor: 0, isWin: null });

export const FactorTypesRecord = Record({
  [FactorTypes.HI]: new FactorRecord(),
  [FactorTypes.LO]: new FactorRecord(),
  [FactorTypes.RED]: new FactorRecord(),
  [FactorTypes.BLACK]: new FactorRecord(),
  [FactorTypes.JOKER]: new FactorRecord(),
  [FactorTypes['2-9']]: new FactorRecord(),
  [FactorTypes.JQKA]: new FactorRecord(),
  [FactorTypes.KA]: new FactorRecord(),
  [FactorTypes.A]: new FactorRecord(),
});

export interface IGame {
  hash: string;
  id: number;
  factors: any;
  previousFactors: any;
  startTime: number;
}

export const GameRecord = Record<IGame>({
  hash: null,
  id: null,
  factors: {
    lo: {
      factor: null,
      probability: null,
    },
    hi: {
      factor: null,
      probability: null,
    },
  },
  previousFactors: new FactorTypesRecord(),
  startTime: null,
});

export interface IHistoryGame {
  card: IGameCardRecord;
  previousCard: IGameCardRecord;
  id: number;
  startTime: number | string;
  offset?: number;
}

export const HistoryGameRecord = Record<IHistoryGame>({
  card: new GameCardRecord(),
  previousCard: new GameCardRecord(),
  id: null,
  startTime: null,
});

export type IPlayer = Map<string, IPlayingBet>;

export interface IPlayingBet {
  id: number;
  amount: number;
  factor: string;
  reward: number;
  type: string;
  user: {
    id: number;
    name: string;
    avaLink: string;
  };
}

export type IGameRecord = Record<IGame>;
export const HistoryList = List<IHistoryGameRecord>();
export type IHistoryList = List<IHistoryGameRecord>;
export type IHistoryGameRecord = Record<IHistoryGame>;
export const PlayerMap = Map<number, IPlayer>();
export type IPlayers = Map<number, IPlayer>;

export interface IHiloReducer {
  game: IGameRecord;
  history: IHistoryList;
  players: IPlayers;
}
