import { useMemo } from 'react';
import { DroppedItemsResults } from '../ducks';

import { useGetItemsForRoulette } from './useGetItemsForRoulette.hook';
import { useGetWinningItem } from './useGetWinningItem.hook';

export interface DroppedItemsResultsModified extends DroppedItemsResults {
  isProfit?: boolean;
}
type Obj = { [key: string]: Partial<DroppedItemsResultsModified> };

export const useRouletteBuilder = (ids: number[], index: number) => {
  const { winningItems } = useGetWinningItem();
  const { items } = useGetItemsForRoulette();

  const itemDictionary: Obj = useMemo(
    () =>
      items.reduce((acc, item) => {
        acc[item?.inventoryItem.id] = { caseRevisionItem: item };
        return acc;
      }, {} as Obj),
    [items]
  );

  const rewardItem = {
    ...winningItems[index],
    isProfit: winningItems[index]?.caseRevisionPrice < winningItems[index]?.winningPrice,
  };
  return ids.map((id, index) =>
    index === 28 ? rewardItem ?? itemDictionary[id] : itemDictionary[id]
  );
};
