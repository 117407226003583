import styled from 'styled-components';

export const NavigationLink = styled.button<Record<string, any>>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 60px;
  flex-shrink: 0;
  padding: 0;
  background: inherit;
  border: 0;

  transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:active {
    background: var(--color-gray-300);
  }

  &:last-child {
    & > div:not(.leading) {
      border-bottom: none;
    }
  }

  & > div:first-child {
    margin-left: 1.3rem;
    padding-right: 1.3rem;
  }

  & > .leading {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-basis: 30px;
    flex-shrink: 0;
    width: 30px;
    border-bottom: 0;
    position: relative;

    & > i {
      font-size: 1.3rem;
      color: var(--color-white);
    }

    & > svg {
      position: absolute;
      width: 25px;
      height: 25px;
      transform: translate(0, -50%);
    }
  }

  & > div:not(.leading) {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.125rem;
    align-self: center;
    justify-items: stretch;
    flex-basis: 100%;
    position: relative;
    padding-right: 2.5rem;
    background: inherit;

    & > span {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1rem;
      color: var(--color-white);
      text-align: left;
    }

    & > span.badge-light {
      font-size: 0.8rem;
      color: #9ea7b3;
    }

    &:after {
      content: '\\e901';
      font-size: 0.9rem;
      color: var(--color-gray-100);
      font-family: icomoon;
      position: absolute;
      right: 0.65rem;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
    }

    & .last-added {
      color: var(--yellow);
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      right: 0.9rem;
      background: inherit;
      z-index: 1;
    }
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }
`;
