import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Container, Info, Img } from './event-quests-widget.styles';

import { Modules } from 'core/interfaces';
import { useHistory } from 'react-router-dom';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

export const EventQuestsWidget: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirect = () => {
    history.push(Modules.EVENT);
    localStorage.setItem('activeTab', '1');
    localStorage.setItem('scrollpos', '0');
  };

  return (
    <Container onClick={redirect} className="container">
      <Img src={eventImagesPath.bronzeDailyPath} alt="bronzes" />
      <Info>
        <Title>{t('comicsHeroes')}</Title>
      </Info>
    </Container>
  );
};
