export default {
  GAME: 'GRA',
  Winner: 'Zwycięzca',
  Win: 'Wygrana',
  Chance: 'Szansa',
  'Chance to win': 'Chance to win',
  'At stake': 'Zakład o',
  'Sign in through STEAM to participate': 'Zarejestruj się na portalu STEAM, aby uczestniczyć',
  'Steam trade link': 'Link na wymianę w Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Zrób depozyt jako pierwszy',
  'Place a bet': 'Postaw zakład',
  'Deposit more': 'Wnieś jeszcze',
  'Min bet': 'Min. zakład',
  'Max bet': 'Maks. zakład',
  'Bet amount': 'Wysokość zakładu',
  'max. items': 'maksimum przedmiotów',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',

  'Tickets to from': 'Bilety: od #{{ from  }} do #{{ to }} ',
  Skins: 'Skórki',
  Coins: 'Coins',
  'VIRTUAL SKINS': 'wirtualne skórki',
  'Round number': 'Liczba rundy',
  'Winning after commission': 'Wygrana po prowizji',
  Loading: 'Ładowanie',
};
