import { useModal } from 'core/Modals/hooks';
import { leaderModals } from '../../modal.config';
import { useSelector, shallowEqual } from 'react-redux';
import { getInviteItem, getBetConditions } from '../../selectors';
import type { IRafflingItem, IOwnBet } from '../../interfaces/reducer.leaders.interfaces';
import { IStore } from 'store/interface';

export const useInviteModal = (data: any) => {
  const { handleCloseModal } = useModal(leaderModals.INVITE_RAFFLE);
  const item = useSelector<IStore, IRafflingItem | null>(
    store => getInviteItem(store, data),
    shallowEqual
  );

  const betConditions = useSelector<IStore, IOwnBet>(
    store => getBetConditions(store, item.raffleId, item.id),
    shallowEqual
  );

  return {
    handleCloseModal,
    item,
    betConditions,
  };
};
