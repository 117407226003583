export default {
  winning: 'Winning',
  line: 'Linje',
  lines: 'Linjer',
  total: 'Totalt',
  Autoplay: 'Autoplay',
  Start: 'Start',
  Paytable: 'Gevinsttabell',
  'Recent wins': 'Nylige gevinster',
  'Your games': 'Dine spill',
  'Bonus game': 'Bonus spill',
  'Slot works regardless of location': 'Spillmaskiner funker uansett sted',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Kan erstatte alle spor i linjen (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    'Samle alle tre spilleautomater og få 10 bonusspill!',
  'Total won': 'Totalt vunnet',
  'Bonus games': 'Bonus spill',
};
