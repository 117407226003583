import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { CaseQuestMotivatorLast } from '../../../interfaces';
import { loadProfile } from 'modules/Accounts/duck';
import {
  AvatarImage,
  CaseImg,
  CaseNumber,
  Container,
  UserIcon,
  UserInfoContainer,
  UserName,
} from './motivator-item.styles';
import { EventIcons } from 'modules/event-module/event-summertime/assets';
import premium_sprite from 'modules/PersonalArea/assets/premium-sprite.svg';

type MotivatorItem = CaseQuestMotivatorLast;

export const MotivatorItem: FC<MotivatorItem> = ({ user, passed, total, image }) => {
  const dispatch = useDispatch();

  const openUserInfoHandler = () => {
    dispatch(loadProfile(user.id));
  };

  return (
    <CSSTransition in={!!image} classNames="motivator-item" timeout={1500} appear>
      <Container>
        <AvatarImage profileType={user.profileType} onClick={openUserInfoHandler}>
          <UserIcon src={user.avatar} />
          <svg viewBox="0 0 12 16" width="12px" height="16px">
            <use xlinkHref={`${premium_sprite}#${user.profileType}`} />
          </svg>
        </AvatarImage>
        <UserInfoContainer>
          <UserName onClick={openUserInfoHandler}>{user.name}</UserName>
          <CaseNumber>
            {passed}/{total}
          </CaseNumber>
        </UserInfoContainer>
        <CaseImg src={image} alt="" />
        <EventIcons.MotivatorBg className="bg" />
      </Container>
    </CSSTransition>
  );
};
