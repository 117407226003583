import { SkeletonElement } from '@UIkit';
import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-top: 40px;
`;

export const Circle = styled(SkeletonElement)`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--color-dark-200);
`;

export const Status = styled.div<{ isReady: boolean }>`
  color: ${({ isReady }) => (isReady ? 'var(--color-white)' : 'var(--color-gray-200)')};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.286px;
`;

export const StyledButton = styled(Button)<{ isJoker: boolean }>`
  display: flex;
  max-width: 80px;
  padding: 4px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  height: 36px;

  &.add-bot {
    background-color: var(--color-white-gray);
    color: var(--color-white);
  }

  &.primary {
    background-color: ${({ isJoker }) => (isJoker ? 'var(--pink-100)' : 'var(--yellow)')};
    color: ${({ isJoker }) => (isJoker ? 'var(--color-white)' : 'var(--color-dark-100)')};
  }

  &.refill {
    padding: 4px 10px;
  }

  @media screen and (max-width: 374px) {
    width: 65px;
    font-size: 8px;
    font-weight: 600;
  }
`;
//TODO: add to global Styles

export const IconContainer = styled.div<{ isJoker?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    path:first-child {
      fill: ${({ isJoker }) => (isJoker ? 'var(--pink-100)' : 'var(--yellow)')};
    }

    path:nth-child(2) {
      fill: ${({ isJoker }) => (isJoker ? 'var(--color-white)' : 'var(--color-dark-100)')};
    }
  }
`;

export const CurrencyTextContainer = styled.div<{ isJoker: boolean }>`
  color: ${({ isJoker }) => (isJoker ? 'var(--color-white)' : 'var(--color-dark-100)')};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 14px;
  text-align: center;
`;
