export default {
  Loading: 'Загрузка',
  'Select your favorite to login': 'Выберите удобный способ входа',
  iam18: 'Мне 18+ и я согласен с <1>Условиями и Положениями.</1>',
  inAddition: 'А также...',
  inAdditionFull:
    'Кроме того, я не являюсь гражданином и не захожу на сайт с территории следующих стран: США, ОАЭ, Австралии, Гонконга, Индии, Индонезии, Японии, Кореи, Малайзии и Сингапура.',
  'Authorization successful': 'Успешная авторизация',

  // tg modal
  Connect: 'Присоединиться',
  'Connect Telegram': 'Подключить Telegram',
  'Write to our telegram bot, press start and it will send you a code':
    'Напишите нашему телеграмм боту, нажмите start и он пришлет вам код',
  'Enter received code in bellow input:': 'Введите полученный код в поле ниже:',
  '401 Unauthorized': 'Вы ввели неправильный код',
  'Code not found': 'Вы ввели неправильный код',
  'Go to Telegram': 'Перейти в Telegram',
};
