import React, { FC, memo, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BezierEasing from 'bezier-easing';
import { animated, useChain, useSpring, useTransition } from 'react-spring';
import { useFormContext } from 'react-hook-form';
import { Button } from '@components/Common';
import { useScrollContext } from '../../slot-game-scroll-provider';

const AUTOPLAY_FIELDS = [500, 100, 50, 10];

interface IAutoplayGameController {}

export const AutoplayGameController: FC<IAutoplayGameController> = memo(() => {
  const scroll = useScrollContext();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const [open, set] = useState<boolean>(false);
  const springRef = useRef();
  const autoplay = watch('autoplay');

  const spring = useSpring({
    ref: springRef,
    from: {
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0)',
      borderRadius: 4,
    },
    to: {
      width: open ? '230%' : '100%',
      height: open ? '260%' : '100%',
      background: open ? '#1a262a' : 'rgba(0,0,0,0)',
      borderRadius: open ? 4 : 8,
    },
    config: {
      duration: 100,
      easing: BezierEasing(0.12, 0, 0.39, 0),
    },
  });

  const transitionRef = useRef();
  const transition = useTransition(open ? AUTOPLAY_FIELDS : [], null, {
    ref: transitionRef,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' },
    unique: true,
    reset: true,
    trail: 100,
    config: {
      duration: 200,
      easing: BezierEasing(0.12, 0, 0.39, 0),
    },
  });

  useChain(open ? [springRef, transitionRef] : [transitionRef, springRef], [0, open ? 0.1 : 0.4]);

  const onClick = useCallback(() => {
    set((v: boolean) => !v);
  }, []);

  const onChangeAutoplay = useCallback(
    (value: number) => () => {
      setValue('autoplay', value);
    },
    [setValue]
  );

  return (
    <>
      {open && (
        <AutoplayOverlay onClick={onClick} style={{ height: scroll?.current.scrollHeight }} />
      )}
      <Container>
        <div className="autoplay-value">
          {t('Autoplay')} {autoplay && ` ${autoplay}`}
        </div>
        <AnimatedButton style={spring} onClick={onClick}>
          {transition.map(({ props, item }) => (
            <animated.div
              key={item}
              className="autoplay-controller"
              style={props}
              onClick={onChangeAutoplay(item + autoplay)}
            >
              +{item}
              <span>{t('Games')}</span>
            </animated.div>
          ))}
          <Button
            type="button"
            className={`link-light ${open && 'open'}`}
            onClick={onChangeAutoplay(null)}
          >
            {t('Cancel')}
          </Button>
        </AnimatedButton>
      </Container>
    </>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  opacity: 1;
  transform: translate3d(0, 0, 1px);

  & .autoplay-value {
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 0.286px;
    line-height: 0.9375rem;
    color: var(--yellow);
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  & .autoplay-controller {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    border-radius: 0.125rem;
    background-color: var(--yellow);

    font-size: 1.4375rem;
    color: #212f35;
    font-weight: bold;
    overflow: hidden;
    will-change: opacity, transform;

    & span {
      font-size: 0.625rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  & button.link-light {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    justify-content: center;
    opacity: 0;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

const AnimatedButton = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 2.25rem;
  grid-gap: 0.25rem;
  border-radius: var(--size-radius-m);
  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  background-color: transparent;
  border: 0.125rem solid var(--yellow);
  overflow: hidden;
  padding: 0.25rem;

  color: var(--color-white);
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 0.286px;
  line-height: 0.9375rem;
  text-transform: uppercase;

  width: auto;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 110;

  &:active {
    border-color: #ad9d15;
  }
`;

const AutoplayOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 25;
`;
