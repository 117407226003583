import { combineReducers, Reducer } from 'redux';
import { IDispatch, IEventsTypes } from 'core/rootInterfaces/root.interface';
import { ISocketService } from 'services/interfaces/services.interface';
import {
  addOwnHistoryAction,
  historiesActions,
  historiesActionsTypes,
  IAuthToken,
  ICleanUp,
  poggiActionsTypes,
  tokenActions,
} from './interfaces/poggi.actions.interfaces';
import {
  IHistories,
  IHistory,
  IInitStateToken,
  initStateToken,
} from './interfaces/poggi.reducer.interfaces';

import { List, Record } from 'immutable';
import { getUser } from '../../core/User/selectors';

const token = (state = initStateToken, action: tokenActions) => {
  switch (action.type) {
    case poggiActionsTypes.TOKEN_RESPONSE:
      return { ...action.payload };

    case poggiActionsTypes.CLEAN_UP:
      return initStateToken;

    default:
      return state;
  }
};

export const HistoryRecord = Record<IHistories>({
  loading: false,
  loaded: false,
  recent: List<IHistory>(),
  own: List<IHistory>(),
});

const history: Reducer<Record<IHistories>> = (
  state = new HistoryRecord(),
  action: historiesActions
) => {
  switch (action.type) {
    case historiesActionsTypes.ADD_OWN_HISTORY:
      return state
        .set('loaded', true)
        .updateIn(['own'], (own: IHistory[]) =>
          Array.isArray(action.payload) ? own.unshift(...action.payload) : List()
        );

    case historiesActionsTypes.ADD_RECENT_HISTORY:
      return state.updateIn(['recent'], (own: any) => own.unshift(action.payload));

    case historiesActionsTypes.CLEAN_UP_HISTORY:
      return new HistoryRecord();

    default:
      return state;
  }
};

const reducer = combineReducers({
  token,
  history,
});

export default reducer;

export const getAuthToken = () => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
): void => {
  socket.emit('poggi-api.get-token.request', {});
};

export const cleanUpModule = (): ICleanUp => ({
  type: poggiActionsTypes.CLEAN_UP,
});

const authToken = (token: IInitStateToken): IAuthToken => ({
  type: poggiActionsTypes.TOKEN_RESPONSE,
  payload: token,
});

export const fetchHistories = () => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  socket.subscribe({
    event: 'poggi-api.history.recent',
    action: (message: any) => dispatch(addRecentHistory(message)),
  });
  socket.subscribe({
    event: 'poggi-api.get-history.response',
    action: (message: any) => dispatch(addOwnHistory(message)),
  });
  socket.emit('poggi-api.get-history.request', {});
};

export const cleanUp = () => (dispatch: IDispatch, getState: any, { socket }: ISocketService) => {
  socket.unsubscribe('poggi-api.history.recent');
  socket.unsubscribe('poggi-api.get-history.response');
  dispatch({
    type: historiesActionsTypes.CLEAN_UP_HISTORY,
  });
};

const addOwnHistory = (histories: IHistory[]): addOwnHistoryAction => ({
  type: historiesActionsTypes.ADD_OWN_HISTORY,
  payload: histories,
});

const addRecentHistory = (histories: IHistory) => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  dispatch({
    type: historiesActionsTypes.ADD_RECENT_HISTORY,
    payload: histories,
  });

  const user = getUser(getState());
  if (user.id === histories.userId) {
    dispatch({
      type: historiesActionsTypes.ADD_OWN_HISTORY,
      payload: [histories],
    });
  }
};

export const eventsTypes: IEventsTypes[] = [
  { event: 'poggi-api.get-token.response', action: authToken },
];
