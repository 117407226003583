import styled from 'styled-components';

export const SvgContainer = styled.svg`
  display: block;
`;

export const Circle = styled.circle<{ progress: number }>`
  animation: dash 2s ease-in-out;

  @keyframes dash {
    0% {
      stroke-dashoffset: ${({ strokeDasharray }) => strokeDasharray};
    }

    100% {
      stroke-dashoffset: ${({ progress }) => progress};
    }
  }
`;
