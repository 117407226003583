import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Header } from './Header';
import styled from 'styled-components';

import { getGameTopList } from '../selectors';
import { changeTopType } from '../duck';
import { connect } from 'react-redux';
import { MiniPreloader } from '../../Preloader';

import { List } from './List';
import { IPlayer } from '../interfaces/top.reduce.interface';

type TParams = { select: string };

interface IMainTop extends RouteComponentProps<TParams> {
  type: string;
  topList: IPlayer[];
  changeTopType: (type: string, period: string) => void;
}

class MainTop extends React.Component<IMainTop, {}> {
  componentDidMount() {
    this.changeAction(this.props.type, this.props.location.state?.select || 'today');
  }

  shouldComponentUpdate(nextProps: IMainTop) {
    return (
      nextProps.location.state !== this.props.location.state ||
      nextProps.topList.length !== this.props.topList.length
    );
  }

  componentDidUpdate(prevProps: IMainTop) {
    if (prevProps.location.state !== this.props.location.state) {
      this.changeAction(this.props.type, this.props.location.state?.select || 'today');
    }
  }

  render() {
    const { topList } = this.props;

    return (
      <div className="content dark-layout">
        <BgTop>
          <Header />
          {topList.length === 0 ? <MiniPreloader /> : <List topList={topList} />}
        </BgTop>
      </div>
    );
  }

  changeAction = (type: string, period: string | undefined) =>
    this.props.changeTopType(type, this.getPeriod(period));

  getPeriod = (period: string | undefined): string => (period ? period : 'today');
}

export const Top = withRouter(
  connect(
    (state: any) => ({
      topList: getGameTopList(state),
    }),
    { changeTopType }
  )(MainTop)
);

const BgTop = styled.div`
  border-radius: 15px;
  background-color: var(--gray-300);
  padding: 2px;
  margin: 15px 6px 6px 6px;
  padding-bottom: 15px;
`;
