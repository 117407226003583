import React, { FC } from 'react';
import styled from 'styled-components';
import { Collapse } from '@components/Animates';
import { StoreHotOfferHeader } from './store-hot-offer-header';
import { StoreHotOffersList } from './store-hot-offers-list';

export const StoreHotOffers: FC = () => {
  return (
    <Container>
      <Collapse header={StoreHotOfferHeader}>
        <StoreHotOffersList />
      </Collapse>
    </Container>
  );
};

const Container = styled.div``;
