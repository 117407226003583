import React, { FC } from 'react';
import styled from 'styled-components';
import { UserAvatar } from '@components/Common';
import { AvatarImage } from './styles';
import { IAvatarImageStyles } from './interfaces';

interface IUserProfileAvatar extends IAvatarImageStyles {
  avatar: string;
}

export const UserProfileAvatar: FC<IUserProfileAvatar> = ({ profileType, avatar }) => (
  <Container>
    <AvatarImage profileType={profileType}>
      <UserAvatar uri={avatar} />
    </AvatarImage>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  padding: 35px 10px 10px;
`;
