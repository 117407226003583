import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';

interface IReferralAnswer {
  answer: string;
}

export const ReferralAnswer: FC<IReferralAnswer> = ({ answer }) => {
  const { handleOpenDialog, handleCloseDialog } = useDialog(userModals.PROFILE_PREMIUM);

  useEffect(() => {
    return () => handleCloseDialog();
  }, [handleCloseDialog]);

  const components = [
    <Button className="link-info" to="/withdrawal/method" as={Link} />,
    <Button className="link-info" onClick={handleOpenDialog} />,
  ];

  const { t } = useTranslation();
  return (
    <Container>
      <Trans t={t} components={components}>
        {answer}
      </Trans>
    </Container>
  );
};

const Container = styled.div`
  padding: 0.375rem 1rem 1.25rem;
  background: var(--color-dark-600);

  font-size: 0.75rem;
  line-height: 1.125rem;
  color: rgba(255, 255, 255, 0.5);

  & strong {
    display: block;
    margin: 0.75rem 0 0.5rem;
    color: var(--color-white-400);

    &:first-of-type {
      margin-top: 0;
    }
  }

  & p {
    margin-bottom: 0.125rem;
  }

  & a {
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    text-transform: none;
  }

  & button {
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    text-transform: none;
    padding: 0;
  }
`;
