import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemUri } from 'core/utils/image.utils';
import { CurrencyText } from '@components/currency';
import { AccountAvatar } from '@components/Common/Avatars';
import { Timer } from '@components/TimerEvent';
import { useUser } from 'core/User';
import { useFinishedTakeAction } from '../../../hooks';
import { LotContainer, ParticipationAction, WinnerUser } from './styles';
import { IRafflingItem } from '../../../interfaces/reducer.leaders.interfaces';

export const HistoryLot: FC<IRafflingItem> = ({
  coinsAmount,
  ticketColor,
  items,
  icon,
  color,
  name,
  membersIds,
  members,
  membersLimit,
  id,
  raffleId,
  raffleAt,
  winnerId,
  takenAt,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [handleTakeAt] = useFinishedTakeAction();
  const combination = !!items?.length;
  const participant = membersIds.some(id => Number(id) === user?.id);
  const clamp = Math.floor(membersLimit - membersLimit * 0.1);

  return (
    <LotContainer
      ticketColor={ticketColor}
      participant={participant}
      limit={membersLimit}
      members={membersIds.length}
      clamp={clamp}
    >
      <span className="lot-participant">{participant && <i className="icon-users-plus" />}</span>
      <ul className="lot-rarity-list">
        <li style={{ background: color || 'white' }} />
        {combination &&
          items.map((lot, idx) => <li key={idx} style={{ background: lot.color || 'white' }} />)}
      </ul>
      <div className="lot-thumbnail">
        <img src={getItemUri(icon)} alt="" />
        {raffleAt && <Timer finishEvent={raffleAt} />}
      </div>
      <div className="lot-info">
        <span className="lot-info__total-price">
          <CurrencyText value={coinsAmount} />
        </span>
        <span className="lot-info__all-members">
          {!!membersLimit && (
            <>
              <i className="icon-users" />
              {membersIds?.length || 0}/{membersLimit}
            </>
          )}
        </span>
      </div>
      <div className="lot-description">
        {combination ? (
          <span className="lot-description__lot-count">
            {items.length + 1}{' '}
            {t('items_interval', {
              count: items.length + 1,
              postProcess: 'interval',
            })}
          </span>
        ) : (
          <span className="lot-description__lot-name" style={{ color: color || 'white' }}>
            {name}
          </span>
        )}
      </div>
      <div className="lot-actions">
        {((takenAt && winnerId) || (winnerId && winnerId !== user?.id)) && (
          <WinnerUser className={`lot-actions__winner ${members[winnerId]?.type}`}>
            <AccountAvatar uri={members[winnerId]?.avaLink} userId={winnerId} />
            <span>{members[winnerId].name}</span>
          </WinnerUser>
        )}
        {!takenAt && winnerId && winnerId === user?.id && (
          <ParticipationAction className="lot-actions__take" onClick={handleTakeAt(raffleId, id)}>
            <i className="icon-checked" />
            {t('Take')}
          </ParticipationAction>
        )}
      </div>
    </LotContainer>
  );
};
