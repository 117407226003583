import { DIALOG } from 'dialogs/dialog-combiner';

import { EventDialog } from './rare-case/rare-case';
import { transitions } from 'dialogs/transition.config';
import { DIALOG_EVENT_CONFIG } from '../dialog-config';
import { GuideDialog } from './guide-dialog/guide-dialog';
import { CaseShop } from './case-shop/case-shop';

DIALOG.add(DIALOG_EVENT_CONFIG.RARE_CASE, {
  component: EventDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

DIALOG.add(DIALOG_EVENT_CONFIG.GUIDE, {
  component: GuideDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});

DIALOG.add(DIALOG_EVENT_CONFIG.CASE_SHOP, {
  component: CaseShop,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

//NOTE: for correct work import file to /src/dialogs/index.ts
