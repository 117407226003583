import styled from 'styled-components';
import { Button } from '@components/Common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 55px 16px 28px;
`;

export const MyBtn = styled(Button)`
  background: #ffd11a;
  color: #0c0c10;
`;

export const ProgressTitle = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 700;
`;
