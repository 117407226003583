import styled from 'styled-components';

export const TeamsContainer = styled.div<{ isJoker: boolean; isWinner: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 6px;
  max-width: 175px;
  overflow: hidden;
  padding: 6px 0 12px;
  position: relative;

  ${({ isWinner, isJoker }) =>
    isWinner &&
    `
    background: linear-gradient(
      90deg,
      #fff0 0%,
      #fff0 5%,
      ${isJoker ? '#5f6d6338' : '#9dff7a1A'} 50%,
      #fff0 95%,
      #fff0 100%
      );
    border-width: 1px;
    border-style: solid;
    border-image-source: linear-gradient(
      90deg,
      #fff0 0%,
      #fff0 5%,
      ${isJoker ? '#444e47' : '#9dff7a'} 50%,
      #fff0 95%,
      #fff0 100%
    );
    border-image-slice: 1;
    border-left: none;
    border-right: none;
  `}

  & > svg {
    height: 100%;
    position: absolute;
    z-index: -1;
    transform: scale(1.2);
    opacity: 0.5;

    &.joker {
      path {
        fill: #bababa;
      }
    }
  }
`;
