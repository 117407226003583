import games from '../games';
import modules from '../modules';
import core from '../core';
import { dialog, toolBarReducer, lottery } from '../core/ducks';
import { connectRouter } from 'connected-react-router';
import { _history } from 'core/AppShell/containers/async-browser-router';

const createFlatReducer = (modules, rootReducers) => {
  // ToDo: eslint
  // eslint-disable-next-line
  for (const module in modules) {
    let module1 = modules[module];
    const { reducer } = module1;

    if (reducer === null) continue;

    if (typeof reducer !== 'function') throw new Error(`Module ${module} does not define reducer!`);

    rootReducers[module] = reducer;

    if (modules[module].childrenModule)
      createFlatReducer(modules[module].childrenModule, rootReducers);
  }
};

const combineModuleReducers = modules => {
  const rootReducers = {};
  createFlatReducer(modules, rootReducers);

  return {
    ...rootReducers,
    dialog,
    toolBarReducer,
    lottery,
    router: connectRouter(_history),
  };
};

export const rootReducer = combineModuleReducers({
  ...games,
  ...modules,
  ...core,
});
