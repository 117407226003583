import { UserProfileDialog } from './user-profile-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.USER_PROFILE, {
  component: UserProfileDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'user-portal',
});
