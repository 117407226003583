import { comicsLocalizationRU } from '../comics-event';

export default {
  bonusHeader: 'Так же мы добавили вам <1> приятный бонус</1>!',
  bonusDescription:
    'Каждый кейс может содержать в себе дополнительный приз в виде ещё одного бесплатного кейса!',
  recentWinnings: 'Последние победы',
  luckyWinners: 'Ниже представлены счастливчики, которые получили наибольший выигрыш',
  superPrize: 'Суперприз',
  surpriseDescription: 'Невероятный приз ждёт тех, кто соберёт подарки во всех категориях!',
  searchCollection: 'Ищи в коллекции',
  title: 'Подарки за тыквы',
  eventDescription: 'Открывай кейсы определенной коллекции и получай подарок',
  openFreeCase: 'Открыть бесплатный кейс',
  open: 'Открыть',
  surprizeAvailable: 'Суперприз доступен',
  receiveSurprize: 'Получить суперприз',
  openSurprize: 'Открыть суперприз',
  surprizeReceived: 'Суперприз получен',
  rareCaseTitle: 'Вам выпал редкий бонус!',
  rareCaseDescription: 'Вам выпал редкий бонусный кейс, идите откройте',
  rareCaseButtonText: 'КРУТО! ОТКРЫТЬ КЕЙС',
  progressSurprize: 'Прогресс до суперприза',
  bannerTextBlackFriday: 'Освой все типы оружия и получи главный приз',
  blackFridayDescription: 'Пройди все уровни и получи подарочный кейс',
  superPrizeCompleted: 'Супер приз получен. Смотреть реплей',
  superPrizeWarning: 'Супер приз недоступен. Необходимо пройти все предыдущие этапы',
  lockMessage: 'Пройди предыдущий этап',
  levelPassed: 'Уровень пройден!',
  youWin: 'Вы выиграли',
  viewPrizes: 'Показать все призы',
  hidePrizes: 'Скрыть все призы',
  guidTextTicket: 'Получай билеты бесплатно за каждое пополнение',
  guidTextBullet: 'Выстрелы по мишеням оплачиваются билетами',
  guidTextExp:
    'Каждый выстрел требует перезарядки. Получай опыт в других играх, чтобы заполнить шкалу',
  guidButtonText: 'Далее',
  guidButtonTextFinalStep: 'Играть',
  watchReplay: 'СМОТРЕТЬ РЕПЛЕЙ',
  itemsTitle: 'На этом уровне вы сможете найти',
  ...comicsLocalizationRU,
};
