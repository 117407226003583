import { SingleLotteryDialog } from './single-lottery-dialog';
import { ClassicLotteryDialog } from './classic-lottery-dialog';
import { LotteryRulesDialog } from './lottery-rules-dialog';
import { LotteryDialogs } from 'core/interfaces';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';

DIALOG.add(LotteryDialogs.SINGLE_LOTTERY, {
  component: SingleLotteryDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

DIALOG.add(LotteryDialogs.CLASSIC_LOTTERY, {
  component: ClassicLotteryDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});

DIALOG.add(LotteryDialogs.RULES, {
  component: LotteryRulesDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});
