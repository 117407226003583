import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { CurrencyText } from '@components/currency';
import { useDialog } from 'core/hooks';
import { getPercent } from 'core/utils/percent.util';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';
import { IBoard } from '../board.interfaces';
import {
  useGameBoard,
  useGameCardsSide,
  useGameCondition,
  useHighlightSector,
} from '../../../../hooks';
import { ICardsSide, factors } from '../../../../interfaces';
import { PlayingCard } from '../../../@shared';
import { ICardItem, transitions, rotates, delays, lefts, tops } from './transition.config';
import { GamePhase } from '../../../../interfaces/game-phase.enum';
import { getHandCardsAmount } from '../../../../utils';

interface ISideBoard extends IBoard {
  side: ICardsSide;
  protectType: IBoard['type'];
}

export const SideBoard: FC<ISideBoard> = ({ type, side, protectType }) => {
  const { t } = useTranslation('baccarat');
  const { members, totals, bet, opposite } = useGameBoard(type, protectType);
  const cards = useGameCardsSide(side);
  const phase = useGameCondition();
  const sectors = useHighlightSector();
  const { handleToggleDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);

  const cost = useMemo<number>(() => {
    return getHandCardsAmount(cards);
  }, [cards]);

  const items = useMemo<ICardItem[]>(() => {
    return cards.map((item, i) => ({
      card: item,
      key: item,
      i: i,
      rotate: rotates[side][i],
      delay: delays[side][i],
      left: lefts[side][i],
      top: tops[side][i],
    }));
  }, [cards, side]);

  const transition = useTransition(items, ({ key }) => key, {
    from: transitions[side]['from'],
    enter: transitions[side]['enter'] as any,
    leave: transitions[side]['leave'],
    unique: true,
  });

  return (
    <Container
      className={`${type} ${bet?.id && 'active'} ${
        sectors?.includes(type) && phase === GamePhase.TIMEOUT && 'pulse-sector'
      }`}
      disabled={!!opposite?.id}
      onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
        module: ParticipateModule.BACCARAT,
        type,
        protectType,
      })}
    >
      <div className="inner-side-board">
        <div className="board-info">
          <span className="board-info-percent">{getPercent(totals[type], totals.total)}%</span>
          <span className="board-info-members">
            <i className="icon-users" />
            {members ? Object.keys(members).length : 0}
          </span>
          <span className="board-info-amount">
            <CurrencyText value={totals[type] || 0} />
          </span>
        </div>
        {phase === GamePhase.TIMEOUT && (
          <CountUp className="board-amount" start={0} end={cost} duration={1.5} />
        )}
        <div className="game-card-list-outline">
          <div className="game-card-list-outline-item" />
          <div className="game-card-list-outline-item" />
          <div className="game-card-list-outline-item" />
        </div>
        <div className="game-card-list">
          {transition.map(({ props, key, item }, i) => (
            <animated.div key={key} style={props}>
              <PlayingCard card={item.card} />
            </animated.div>
          ))}
        </div>
        <div className="board-factor">
          <span>
            {bet?.amount && <CurrencyText value={bet.amount} />}
            {!bet?.amount && <>{t(type)}</>}
          </span>
          <span>
            x<span>{factors[type]}</span>
          </span>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.button`
  padding: 0;
  border: 0.125rem solid var(--color-stroke);
  border-radius: 0.5rem;
  transition: all 100ms linear;
  background: var(--color-fill);
  min-height: 8rem;
  position: relative;

  &.pulse-sector {
    animation: baccarat-pulse-sector-animation 1s infinite;
  }

  &.active {
    background: var(--color-stroke);
  }

  &.P {
    grid-area: player;
    --color-fill: var(--bacarrat-player-fill);
    --color-stroke: var(--bacarrat-player-stroke);

    & .inner-side-board {
      & .board-amount {
        left: 1.75rem;
      }
      & .game-card-list-outline {
        grid-template-columns: 50px repeat(2, min-content);

        &-item {
          justify-self: self-start;
          transform-origin: bottom center;
          position: absolute;

          &:nth-child(1) {
            left: 1px;
            transform: rotate(90deg) translate3d(-50%, 30%, 0);
          }
          &:nth-child(2) {
            left: 48%;
          }
          &:nth-child(3) {
            left: 78%;
          }
        }
      }
    }
  }

  &.B {
    grid-area: banker;
    --color-fill: var(--bacarrat-banker-fill);
    --color-stroke: var(--bacarrat-banker-stroke);

    & .inner-side-board {
      & .board-amount {
        right: 1.75rem;
      }
      & .game-card-list-outline {
        grid-template-columns: repeat(2, min-content) 50px;

        &-item {
          justify-self: self-start;
          transform-origin: bottom center;
          position: absolute;

          &:nth-child(1) {
            left: 0;
          }
          &:nth-child(2) {
            left: 30%;
          }
          &:nth-child(3) {
            left: 60%;
            transform: rotate(-90deg) translate3d(50%, 70%, 0);
          }
        }
      }
    }
  }

  &:active:not(:disabled) {
    background: var(--color-stroke);
  }

  &:disabled {
    opacity: 0.5;
  }

  & .inner-side-board {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    padding: 0.5rem 0.5rem 0;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & .board-info {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-gap: 0.125rem;

      &-percent,
      &-members,
      &-amount {
        align-self: center;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        line-height: 0.875rem;
        letter-spacing: 0.1px;
        color: var(--color-white);
      }

      &-percent {
        justify-content: flex-start;
      }

      &-members {
        justify-content: center;

        & i {
          margin-right: 0.25rem;
          font-size: 0.875rem;
        }
      }

      &-amount {
        justify-content: flex-end;
      }
    }

    & .board-amount {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: 0.13058px;
      color: var(--color-white);
      position: absolute;
      top: 1.5rem;
    }

    & .game-card-list-outline,
    & .game-card-list {
      width: 140px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    & .game-card-list-outline {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: 50px;
      justify-content: space-between;

      &-item {
        justify-self: self-end;
        width: 32px;
        height: 50px;
        border: 0.125rem solid var(--color-stroke);
        border-radius: var(--size-radius-s);
        background: var(--color-fill);
      }
    }

    & .game-card-list {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 50px;
      justify-content: space-between;

      & > div {
        justify-self: self-end;
        width: 32px;
        height: 50px;
        position: absolute;
        transform-style: preserve-3d;
      }
    }

    & .board-factor {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content;
      grid-gap: 0.5rem;

      & span {
        align-self: self-end;
        font-weight: bold;
        font-size: 0.9375rem;
        line-height: 1.75rem;
        letter-spacing: 0.13058px;
        color: var(--color-white);
        white-space: nowrap;

        & span {
          font-size: 1.5rem;
          line-height: 1.75rem;
        }
      }
    }
  }
`;
