import React, { FC } from 'react';
import { Container, Point } from './pagination.styles';

interface PaginationProps {
  currentSlide: number;
}

export const Pagination: FC<PaginationProps> = ({ currentSlide }) => {
  const countCategories = new Array(14).fill(0).map((_, index) => index);

  return (
    <Container>
      {countCategories.map(index => (
        <Point key={index} isActive={index === currentSlide} />
      ))}
    </Container>
  );
};
