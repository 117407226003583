import { createElement, FC } from 'react';
import { IDialogComponent, ParticipateModule } from 'core/interfaces';
import { CONTROLLERS_MEMORY } from './controllers-memory';
import { Participate } from './participate';
import { useUser } from '../../core/User';
import { SigninDialog } from '../signin-dialog/signin-dialog';

interface IParticipateDialog
  extends IDialogComponent<{
    data: boolean | ParticipateModule;
    handleCloseModal: () => void;
    module: ParticipateModule;
  }> {}

export const ParticipateDialog: FC<IParticipateDialog> = ({ data, handleCloseModal }) => {
  const { user } = useUser();
  return user.id
    ? createElement(CONTROLLERS_MEMORY.get(data.module), data, Participate)
    : createElement(SigninDialog, { data, handleCloseModal });
};
