import styled from 'styled-components';

export const Container = styled.div`
  --roulette-reel-size: calc(min(100vw, 100vh) * 0.7653);
  --roulette-gradient-reel-size: calc(var(--roulette-reel-size) - 1rem);

  height: 100%;

  & .sector-blue {
    --stop-color-0: var(--x50-blue-stroke);
    --stop-color-1: var(--x50-blue-stroke);
    --stroke-color: var(--x50-blue-stroke);
    --fill-color: var(--x50-blue-fill);
  }

  & .sector-red {
    --stop-color-0: var(--x50-red-stroke);
    --stop-color-1: var(--x50-red-stroke);
    --stroke-color: var(--x50-red-stroke);
    --fill-color: var(--x50-red-fill);
  }

  & .sector-green {
    --stop-color-0: var(--x50-green-stroke);
    --stop-color-1: var(--x50-green-stroke);
    --stroke-color: var(--x50-green-stroke);
    --fill-color: var(--x50-green-fill);
  }

  & .sector-gold {
    --stop-color-0: var(--x50-gold-stroke);
    --stop-color-1: var(--x50-gold-stroke);
    --stroke-color: var(--x50-gold-stroke);
    --fill-color: var(--x50-gold-fill);
  }

  & .inner-wrapper {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(2, min-content) 1fr;
    min-height: calc(var(--height) - var(--size-nav-bar) - 5rem);
    width: 100%;
    position: relative;
    overflow: hidden;
  }
`;
