import React, { FC } from 'react';
import styled from 'styled-components';
import { gameIconConfig } from 'modules/LeadersRace/quests.utils';
import games from 'games';
import { IASIQuest, IASIRace } from '../../../interfaces';

type IPlayerComparisonItem = IASIQuest | IASIRace;

export const PlayerComparisonItem: FC<IPlayerComparisonItem> = ({
  detailed,
  amount,
  createdAt,
  color,
}) => {
  return (
    <Container color={color}>
      <div className="item-type">
        {'gameTypeId' in detailed ? (
          <div
            className="item-type_quest"
            style={{
              borderColor: `var(--color-${
                games[gameIconConfig[detailed?.gameTypeId]?.name.toLowerCase()]?.type ||
                'orange-ticket'
              })`,
            }}
          >
            <i className={gameIconConfig[detailed?.gameTypeId].icon} />
          </div>
        ) : (
          <div className="item-type_race">
            <i className="icon-leaders" />
          </div>
        )}
      </div>
      <div className="item-reward">
        <i className="icon-coupon" />
        {amount}
      </div>
      <div className="item-created">{createdAt}</div>
    </Container>
  );
};

interface IPlayerComparisonItemStyle {
  color: string;
}

const Container = styled.div<IPlayerComparisonItemStyle>`
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-template-rows: 1fr 1rem;
  grid-gap: 0.7rem 0.3rem;
  padding: 0.9rem 1rem;
  background: var(--color-dark-600);
  border-radius: var(--size-radius-s);

  & .icon-coupon {
    color: ${p => `var(--color-${p.color}-ticket)`};
  }

  & .item-type {
    align-self: center;
    justify-self: self-start;
    &_quest {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      position: relative;

      &:after {
        content: '\\e940';
        width: 12px;
        height: 12px;
        display: inline-block;
        box-sizing: content-box;
        background: ${p => `var(--color-${p.color}-ticket)`};

        border: 2px solid var(--color-dark-600);
        border-radius: 50%;

        font-family: icomoon;
        line-height: 12px;
        color: var(--color-gray-700);
        text-align: center;
        font-size: 0.4rem;

        position: absolute;
        top: -4px;
        right: -5px;
      }

      & > i {
        color: var(--color-white);
        font-size: 1rem;
      }
    }

    &_race {
      & i {
        color: var(--color-white);
        font-size: 1.5rem;
      }
    }
  }

  & .item-reward {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    justify-self: stretch;

    color: var(--color-white);
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.69px;
  }

  & .item-created {
    grid-column-start: 1;
    grid-column-end: 3;

    font-size: 0.9rem;
    line-height: 1.4;
    color: var(--color-gray-100);
  }
`;
