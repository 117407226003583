import React, { FC } from 'react';
import type { SwitchTypeRenderProp } from './orders-switch-type';
import { OrdersSwitchType } from './orders-switch-type';
import { OrdersTabs } from './orders-tabs';

export const OrdersPage: FC = () => (
  <OrdersSwitchType>
    {({ type, parentRef }: SwitchTypeRenderProp) => (
      <OrdersTabs type={type} parentRef={parentRef} />
    )}
  </OrdersSwitchType>
);
