import React, { Fragment, FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VictoryPie } from 'victory';
import dayjs from 'dayjs';
import Circle from 'react-circle';
import { useTranslation } from 'react-i18next';

import Tape from '../Tape';

import {
  CenterCircle,
  ChartContainer,
  ContentChart,
  CountItems,
  GameId,
  GameInfo,
  Chart,
} from './Roulette.styles';

import { CurrencyText } from '@components/currency';
import { getPercent, rouletteColors } from 'core/utils';
import SoundService from 'services/sound.service';
import { ITrade } from 'games/Fast/interfaces/reducer.fast.interface';
import { IUserState } from 'core/User';
import { refreshFastInventory } from 'core/User/ducks/fast-inventory.duck';

const sound = new SoundService();

interface IRoulette {
  animationTimeout: number;
  // разница между временем сервера и клиента
  appDiffTime: number;
  game: {
    itemsNum: number;
    number: number;
    prize: number;
    rand: string | null;
    tape: string[] | null;
    timeOldEnds: number | null;
  };
  gameActions: { showWinner: () => void };
  gameIsOver: boolean;
  isVisible: boolean;
  profile: ITrade;
  // видимость ленты
  tapeStatus: { tapeIsVisible: boolean; tapePosition: number };
  // значение с сервера, обозначающее промежуток до следующей игры
  timerTimeout: number;
  totalUserPrize: number;
  trades: ITrade[];
  user: IUserState;
  userChances?: any;
}

export const Roulette: FC<IRoulette> = ({
  animationTimeout,
  appDiffTime,
  game: { itemsNum, number, prize, rand, tape, timeOldEnds },
  gameActions,
  gameIsOver,
  isVisible,
  profile,
  tapeStatus: { tapeIsVisible, tapePosition },
  timerTimeout,
  totalUserPrize,
  trades,
  user,
  userChances,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [progressValue, setProgressValue] = useState(0);
  const [timer, setTimer] = useState(null);
  const [secondTimer, setSecondTimer] = useState(null);

  useEffect(() => {
    if (user.id === profile.playerId) {
      dispatch(refreshFastInventory());
    }
  }, [dispatch, profile.playerId, user.id]);

  const playTickSound = () => {
    sound.play('Classic', 'Timers#1');
  };

  useEffect(() => {
    if (timeOldEnds + 3000 - (Date.now() + appDiffTime) > 0 && !tapeIsVisible) {
      /** функция установки таймера!
       * Сервер возвращает либо время до начала следующей игры, либо время начала анимации.
       * Регулируется различие времени триггером tapeIsVisible,
       * здесь считается время до начала розыгрыша, устанавливается либо при начале новой игры,
       * либо при появлении компонента
       */

      const calculateProgress = () => {
        const currentValue = (timeOldEnds - 1000 - (Date.now() + appDiffTime)) / 1000;

        return 1 - currentValue / (timerTimeout / 1000);
      };

      if (timer === null) {
        setTimer(
          setInterval(() => {
            const duration = dayjs.duration(dayjs(timeOldEnds).diff(Date.now()));
            if (duration.seconds() > 3) {
              playTickSound();
            }
            if (duration.seconds() === 0) {
              clearInterval(timer);
              setTimer(null);
            }
            const progress = calculateProgress();

            if (progress <= 1) setProgressValue(progress);
          }, 1000)
        );
      }
      if (secondTimer === null) {
        setSecondTimer(
          setInterval(() => {
            const duration = dayjs.duration(dayjs(timeOldEnds).diff(Date.now()));
            if (duration.seconds() <= 3) {
              sound.stop({
                close: true,
              });
              playTickSound();
            }
            if (duration.seconds() === 0) {
              clearInterval(secondTimer);
              setSecondTimer(null);
            }
          }, 150)
        );
      }
    }

    if (tapeIsVisible) {
      clearInterval(timer);
      clearInterval(secondTimer);
      setTimer(null);
      setSecondTimer(null);
      setProgressValue(0);
      return;
    }

    return () => {
      clearInterval(timer);
      clearInterval(secondTimer);
    };
  }, [appDiffTime, secondTimer, tapeIsVisible, timeOldEnds, timer, timerTimeout]);

  // подсчитывает данные для графиков а также их размеры.
  const calculatePieData = () => {
    if (trades && trades.length > 1) {
      return trades.map((trade: ITrade) => ({
        y: (trade.totalPrice / totalUserPrize) * 100,
        label: ' ',
      }));
    } else {
      return [];
    }
  };

  return (
    <ChartContainer>
      <Chart>
        <VictoryPie
          padding={25}
          width={390}
          height={390}
          innerRadius={140}
          animate={{
            duration: 1500,
          }}
          startAngle={360}
          endAngle={0}
          colorScale={!tapeIsVisible && trades && trades.length > 1 ? rouletteColors : ['#1d5155']}
          data={!tapeIsVisible && trades && trades.length > 1 ? calculatePieData() : [{ y: 100 }]}
        />
      </Chart>
      <ContentChart>
        {!tapeIsVisible ? (
          <Fragment>
            <CenterCircle>
              <Circle
                bgColor="#3d5875"
                lineWidth={'3'}
                progress={Number.isNaN(progressValue) ? 0 : progressValue * 100}
                progressColor="#33ffff"
                responsive={true}
                showPercentage={false}
              />
            </CenterCircle>
            <GameInfo>
              <GameId>
                {t('GAME')}: #{number}
              </GameId>
              <CountItems> {itemsNum} / 50</CountItems>
              <div className="calculation">
                <div className="calculation-total-currency">
                  {t('Win')}
                  {': '}
                  <span>
                    <CurrencyText value={prize} />
                  </span>
                </div>
                {userChances && (
                  <>
                    <div className="calculation-divider" />
                    <div className="calculation-your-chance">
                      {t('Chance')}
                      {': '}
                      <span>{`${getPercent(userChances.totalPrice, totalUserPrize)}%`}</span>
                    </div>
                  </>
                )}
              </div>
            </GameInfo>
          </Fragment>
        ) : (
          <Tape
            animationTimeout={animationTimeout}
            appDiffTime={appDiffTime}
            gameActions={gameActions}
            gameIsOver={gameIsOver}
            gameNumber={number}
            isVisible={isVisible}
            prize={prize}
            profile={profile}
            rand={rand}
            tape={tape}
            tapePosition={tapePosition}
            timeOldEnds={timeOldEnds}
            user={user}
          />
        )}
      </ContentChart>
    </ChartContainer>
  );
};
