import _ from 'lodash';
import {
  findTopStatistics,
  getTitleByKey,
  withCurrency,
  withExistingStatistics,
} from '../modules/PersonalArea/statistics.utils';

/***
 * Бекенд присылает объект с ключами типа: 'classic_max_bet';
 * Адаптер преобразует данные из объекта в массив, распределяя значения в подходящие категории игр
 * имена некоторых ключей отличаются от декларированных имен, так что приходится проверять напрямую;
 * В объекте содержатся данные по ставкам в каждой игре, записанные отдельным параметром: money_spent;
 * В объекте содержаться данные по сыгранным играм, записанные отдельным параметром: games_played;
 * Чтобы определить принадлежность параметра к играм, используется массив доступных игр,
 * получаемый из игровых утилит;
 * Статистика записывается в массив для удобного обращения шаблонизатора.
 * */

const enabledGames = [
  { key: 'classic', mode: 'pvp' },
  { key: 'fast', mode: 'pvp' },
  { key: 'match-bets', mode: 'pvp' },
  { key: 'hilo', mode: 'pve' },
  { key: 'crash', mode: 'pve' },
  { key: 'double', mode: 'pve' },
  { key: 'x50', mode: 'pve' },
  { key: 'baccarat', mode: 'pve' },
  { key: 'craft', mode: 'solo-pve' },
  { key: 'wheel', mode: 'solo-pve' },
  { key: 'tower', mode: 'solo-pve' },
  { key: 'cases', mode: 'solo-pve' },
  { key: 'slot', mode: 'solo-pve' },
  { key: 'poggi', mode: 'solo-pve' },
  { key: 'case-battle', mode: 'pvp' },
];

export const statisticsAdapter = data => {
  const gamesPoorStatistic = getGamePoorStatistic(data);
  const gamesRichStatistic = getGameRichStatistic(data, gamesPoorStatistic);
  const statistics = _.keys(gamesRichStatistic).map(key => gamesRichStatistic[key]);
  const topStatisticsKeys = findTopStatistics(statistics);
  return _.map(statistics, item => ({
    ...item,
    statistics: _.map(item.statistics, entity => ({
      ...entity,
      isTop: topStatisticsKeys.includes(entity.key),
    })),
  }));
};

const getGamePoorStatistic = data =>
  _.chain(data)
    .keys()
    .reduce((dictionary, key) => {
      const value = data[key];
      let [shortcut] = key.split('_');

      return {
        ...dictionary,
        [shortcut]: { ...dictionary[shortcut], [key]: value },
      };
    }, {})
    .pick(_.map(enabledGames, game => game.key))
    .value();

const getGameRichStatistic = (data, gamesPoorStatistic) =>
  _.mapValues(gamesPoorStatistic, (value, gameName) => {
    const totalGameBet = _.find(data.money_spent, item => item.game.includes(gameName)) || {};
    const gamePlayed = data.games_played[0];
    let playedGamesKey = _.find(_.keys(gamePlayed), item => item === gameName);
    let totalGamePlayed = 0;

    if (!playedGamesKey) {
      playedGamesKey = `${gameName}_games`;
      totalGamePlayed = data[playedGamesKey];
    } else {
      totalGamePlayed = gamePlayed[playedGamesKey];
    }

    const { mode = 'pve' } = _.find(enabledGames, game => game.key === gameName) || {};
    const statistics = {
      ...value,
      totalBet: totalGameBet.total || null,
      [`${gameName}_total_games_played`]: totalGamePlayed,
    };
    return {
      mode,
      key: gameName,
      statistics: prepareStatistics(statistics),
    };
  });

const prepareStatistics = statistics =>
  _.chain(statistics)
    .keys()
    .filter(key => withExistingStatistics(getTitleByKey[key]))
    .sort((i1, i2) => {
      const key2 = getTitleByKey[i2];
      const key1 = getTitleByKey[i1];
      if (key2 === 'Profit') return 1;
      if (key1 === 'Profit') return -1;
      if (key2 === 'Total games played') return 2;
      if (key1 === 'Total games played') return -2;
      if (key2 === 'Total wins') return 3;
      if (key1 === 'Total wins') return -3;
      if (key2 === 'Max win') return 4;
      if (key1 === 'Max win') return -4;
      return -1;
    })
    .map(key => {
      let value = statistics[key] || 0;
      const title = getTitleByKey[key];
      if (withCurrency(title)) {
        value = parseFloat(value).toFixed(2);
      }
      return { key, title, value, isCurrency: withCurrency(title) };
    })
    .value();
