import { currencyService } from 'services/currency';
import { i18n } from './i18n.config';
import _ from 'lodash';
import { declOfNum } from 'core/utils/text.utils';
import * as i18next from 'i18next';

interface I18nextPostProcessor extends i18next.PostProcessorModule {
  name: string;
  type: 'postProcessor';
  options: {
    intervalSuffix: string;
  };
  process(value: any, key: string, options: any): any;
}

export const withTemplate: I18nextPostProcessor = {
  name: 'with-template',
  type: 'postProcessor',

  options: {
    intervalSuffix: '_with-template',
  },

  process(value, key, options) {
    return _.template(value, {
      imports: {
        currency: (value: string) => currencyService.display(value),
        pluralize: declOfNum,
        translate: (value: string) =>
          // @ts-ignore
          _.has(i18n[i18n.locale], value) ? i18n[i18n.locale][value] : value,
        map: (
          array: {
            map: (arg0: (item: string) => string) => { join: (arg0: string) => void };
          },
          keys?: any
        ) =>
          array
            .map(item =>
              _.map(keys, key =>
                i18n.t(item[key].toString(), {
                  defaultValue: item[key].toString(),
                })
              ).join(': ')
            )
            .join('; '),
      },
    })(options);
  },
};
