import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { AccountAvatar } from '@components/Common';
import { ISlotHistory } from '../../../interfaces';
import { slotType } from '../../../slot.utils';

export const HistoryListElement: FC<ISlotHistory> = ({
  combs,
  slots,
  selectedLines,
  betPerLine,
  totalPrize,
  ava_link,
  userId,
  name,
}) => {
  const { t } = useTranslation();
  const bonuses = useMemo(() => {
    return combs.filter(({ bonus }) => !!bonus).length * 10;
  }, [combs]);

  return (
    <Container>
      <div className="history-user">
        <div className="history-user-avatar">
          <AccountAvatar uri={ava_link} userId={userId} />
        </div>
        <div className="history-user-name">{name}</div>
        <div className="history-user-lines">
          {t('lines')} {selectedLines}
        </div>
        <div className="history-user-bid">
          {t('Total bet:')} <CurrencyText value={selectedLines * betPerLine} />
        </div>
        <div className="history-user-bonuses">
          {!!bonuses && (
            <>
              <span>{bonuses}</span>
              <i className="icon-loop" />
            </>
          )}
        </div>
        <div className="history-user-profit">
          +<CurrencyText value={totalPrize} />
        </div>
      </div>
      <div className="history-lines">
        {combs.map(({ line, bonus, slotPos }) => (
          <div key={line} className={`line-${line}`}>
            <span>{`x${slotPos.length}`}</span>
            {bonus ? (
              <img
                src={`https://d2lomvz2jrw9ac.cloudfront.net/slot-assets/csgo/items/${slotType[12]}.png`}
                alt="bonus"
              />
            ) : (
              <img
                src={`https://d2lomvz2jrw9ac.cloudfront.net/slot-assets/csgo/items/${
                  slotType[slots[slotPos[0]]]
                }.png`}
                alt="bonus"
              />
            )}
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
  height: 100%;
  border-bottom: 1px solid var(--color-gray-900);
  padding: 0.875rem 0 1rem;

  & .history-user {
    display: grid;
    grid-template: 'avatar name name bonuses' 'avatar lines bid profit';
    grid-template-columns: min-content min-content 1fr min-content;
    grid-template-rows: min-content min-content;
    grid-gap: 0 0.5rem;
    padding: 0 1rem;

    &-bonuses,
    &-profit,
    &-name,
    &-bid,
    &-lines {
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.1px;
      color: var(--color-white);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-avatar {
      grid-area: avatar;
      align-self: center;
      justify-self: center;

      & button {
        width: 1.75rem;
        height: 1.75rem;

        & img {
          border-radius: 50%;
        }
      }
    }

    &-name {
      grid-area: name;
    }

    &-combo {
      grid-area: combo;
    }

    &-bid {
      grid-area: bid;
    }

    &-lines {
      grid-area: lines;
    }

    &-bonuses {
      grid-area: bonuses;
      color: var(--primary);
      position: relative;

      & span {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        margin-right: 0.25rem;
      }

      & i {
        font-size: 1rem;
      }
    }

    &-profit {
      grid-area: profit;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;

      text-align: right;
      color: #00f900;
    }
  }

  & .history-lines {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-template-rows: min-content;
    grid-gap: 0.25rem;
    overflow-x: scroll;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
      display: none;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 0.75rem;
      scroll-snap-align: center;
    }

    & > div {
      scroll-snap-align: center;
      width: 4rem;
      height: 3.125rem;
      border: 0.125rem solid var(--color-combo-line);
      border-radius: var(--size-radius-s);
      position: relative;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
      }

      & span {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 60%;
        height: 70%;
        padding: 0.125rem 0.125rem 0 0;

        position: absolute;
        top: -0.125rem;
        right: -0.125rem;
        z-index: 20;

        background-color: var(--color-combo-line);
        clip-path: polygon(100% 0, 0 0, 100% 100%);

        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.8px;
        color: var(--color-dark-500);
      }
    }
  }
`;
