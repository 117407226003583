import { SubModule } from 'core/rootInterfaces/root.interface';
import { eventTypesLR, eventsTypes } from './duck';
export { DIALOG_EVENT_CONFIG } from './dialog-config';

export const comicsHeroesEventModule: SubModule = {
  moduleName: 'comics-heroes',
  path: '/event',
  eventsTypes: [...eventsTypes, ...eventTypesLR],
};

export { reducer, comicsHeroesEventEpic } from './duck';
export type { ComicsHeroesReducerState } from './duck';
export { comicsLocalizationRU, comicsLocalizationEN } from './locales';
export * from './components';
export type { CaseLRInfo } from './interfaces';
export { TicketTypeEnum } from './interfaces';

export { ComicsHeroesEventRouter } from './event.router';
