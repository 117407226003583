import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { hotOffers, store, storeLoader } from './store.duck';
import { filters, filtersEpic } from './filters.duck';
import { purchaseEpics, purchases } from './purchases.duck';
import { marketplaceWatcher, steamStatusEpic, steam } from './marketplace.duck';
import { inventory, inventoryEpics } from './inventory.duck';
import { sales, salesEpics } from './sales.duck';
import { store as configure } from 'store/configureStore';
import { rootEpics$ } from 'store/epicMiddleware';
import { MarketplaceActionTypes } from '../interfaces';

const reducer = combineReducers({
  filters,
  store,
  hotOffers,
  purchases,
  inventory,
  sales,
  steam,
});
export const marketplaceEpics: any = combineEpics(
  marketplaceWatcher,
  filtersEpic,
  storeLoader,
  purchaseEpics,
  inventoryEpics,
  salesEpics,
  steamStatusEpic
);

export const registerReducer = () => {
  rootEpics$.next([marketplaceEpics, MarketplaceActionTypes.MARKETPLACE_DETACH_LISTENERS]);
  configure.reducerManager.add('market', reducer);
};

// export const unregisterReducer = () => {
//   configure.reducerManager.remove('market');
// };
