import React, { createElement, FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { COMBO_ENTRIES } from './combo-lines';
import { useComboGame, useGameNumber } from '../../../hooks';
import { ComboLineOutcome } from './combo-line-outcome';
import { ComboLineGridView } from './combo-lines/combo-line-grid-view';
import { FreeSpinsPopup } from '../free-spins-popup';
import { SideComboLineIndicator } from './side-combo-line-indicator';

export const SlotGameComboLines: FC = ({ children }) => {
  const intervalId = useRef(null);
  const [display, set] = useState<number>(null);
  const { watch } = useFormContext();
  const { combo, slots } = useComboGame();
  const { number } = useGameNumber();
  const selectedLines = watch('selectedLines');

  const lines = useMemo<number[]>(() => {
    if (!!number && display === null) return [];
    if (combo?.length && display >= 0) return [combo[(combo.length + display) % combo.length].line];
    return Array.from({ length: selectedLines }, (_, i) => i + 1);
  }, [selectedLines, combo, display, number]);

  useLayoutEffect(() => {
    if (!combo?.length) return;

    const timeoutId = setTimeout(() => {
      set(() => 0);

      intervalId.current = setInterval(() => {
        set(i => i + 1);
      }, 1000);
    }, 3000);

    return () => {
      set(() => null);
      clearInterval(intervalId.current);
      clearTimeout(timeoutId);
    };
  }, [combo]);

  const el = (combo.length + display) % combo.length;

  return (
    <>
      <ComboLineOutcome display={display} />
      <Container>
        <FreeSpinsPopup display={display} />
        <SideComboLineIndicator side="left" />
        <div className="slot-game-grid-view">{children}</div>
        <SideComboLineIndicator side="right" />
        {COMBO_ENTRIES.map(([key, line]) => {
          const contains = lines.includes(key);
          return createElement(line, {
            key: key + (el || 0),
            className: `line-${key} ${contains && 'visible'}`,
            children: createElement(ComboLineGridView, {
              slots,
              positions: display >= 0 && contains ? combo[el]?.slotPos : null,
            }),
          });
        })}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.75rem 1fr 0.75rem;
  grid-template-rows: 1fr;
  align-self: self-end;
  position: relative;

  @supports (aspect-ratio: 375 / 212) {
    aspect-ratio: 375 / 212;
  }

  @supports not (aspect-ratio: 375 / 212) {
    height: calc(min(100vw, 100vh) / (375 / 212));
  }

  & .slot-game-grid-view {
    position: relative;
    z-index: -1;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 0.5rem;
      width: 100%;
      position: absolute;
      z-index: 1;
      background: linear-gradient(180deg, #131a1d 0%, rgba(19, 26, 29, 0) 100%);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
      transform: rotate(-180deg);
    }
  }
`;
