import React, { FC } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { lines } from '../../../../slot.utils';

interface ISideComboLinaIndicator {
  side: 'left' | 'right';
}

export const SideComboLineIndicator: FC<ISideComboLinaIndicator> = ({ side }) => {
  const { watch } = useFormContext();
  const selectedLines = watch('selectedLines');
  return (
    <Container>
      {lines.map(key => (
        <div key={key} className={`line-${key} ${selectedLines >= key && 'visible'} ${side}`} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9, 1fr);
  position: relative;
  z-index: 10;
  transition: all 100ms ease-in;

  & .left,
  & .right {
    align-self: center;
    width: 50%;
    height: 60%;
    clip-path: polygon(70% 0, 100% 0, 100% 100%, 70% 100%, 0 49%);
    background: var(--color-combo-line);
    opacity: 0;
    transition: inherit;
  }

  & .visible {
    opacity: 1;
  }

  & .left {
    transform: rotateY(-180deg);
  }

  & .right {
    justify-self: self-end;
  }
`;
