import React, { FC, useEffect, useRef, useState } from 'react';
import { Container, SliderContainer, SliderItemContainer } from './slider.styles';
import { Stage1 } from '../stage1';
import { Stage2 } from '../stage2';
import { Stage3 } from '../stage3';
import { Stage4 } from '../stage4';
import { Pagination } from '../pagination';
import { usePreventVerticalScroll } from 'core/hooks/slider/useSlider.hook';

export const Slider: FC = () => {
  const ref = useRef<HTMLDivElement>();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasAnimation, setHasAnimation] = useState(true);
  const [disableScreen, setDisableScreen] = useState(false);
  usePreventVerticalScroll(ref);

  const [start, setStart] = useState({
    x: null,
    y: null,
  });

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setHasAnimation(true);

    setStart({
      y: e.touches[0].clientY,
      x: e.touches[0].clientX,
    });
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    setStart({ x: null, y: null });
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!start.x) return;
    const currentX = e.touches[0].clientX;
    const differenceY = Math.abs(e.touches[0].clientY - start.y);
    const differenceX = start.x - currentX;

    if (differenceX > 50 && differenceY < 25) {
      if (currentPage === 3) {
        setCurrentPage(0);
      } else {
        setCurrentPage(prev => prev + 1);
      }

      if (currentSlide < 5) {
        setDisableScreen(true);
        setCurrentSlide(prev => prev + 1);
      }

      setStart({ x: null, y: null });
      return;
    } else if (differenceX < -50 && differenceY < 25) {
      if (currentPage === 0) {
        setCurrentPage(3);
      } else {
        setCurrentPage(prev => prev - 1);
      }

      if (currentSlide > 0) {
        setDisableScreen(true);
        setCurrentSlide(prev => prev - 1);
      }

      setStart({ x: null, y: null });
      return;
    }
  };

  useEffect(() => {
    let timer: null | number = null;

    if (disableScreen) {
      timer = setTimeout(() => {
        setDisableScreen(false);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [disableScreen]);

  interface Stage {
    [key: number]: JSX.Element;
  }

  useEffect(() => {
    let timer: null | number = null;

    if (currentSlide === 5) {
      timer = setTimeout(() => {
        setHasAnimation(false);
        setCurrentSlide(1);
      }, 500);
    } else if (currentSlide === 0) {
      timer = setTimeout(() => {
        setHasAnimation(false);
        setCurrentSlide(4);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide]);

  const stage: Stage = {
    0: <Stage4 />,
    1: <Stage1 />,
    2: <Stage2 />,
    3: <Stage3 />,
    4: <Stage4 />,
    5: <Stage1 />,
  };

  return (
    <Container ref={ref}>
      <SliderContainer
        currentSlide={currentSlide}
        hasAnimation={hasAnimation}
        disableScreen={disableScreen}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {[0, 1, 2, 3, 4, 5].map(index => (
          <SliderItemContainer key={index}>{stage[index as keyof Stage]}</SliderItemContainer>
        ))}
      </SliderContainer>
      <Pagination currentSlide={currentPage} />
    </Container>
  );
};
