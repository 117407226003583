import * as React from 'react';
import styled from 'styled-components';
import performance from '../../assets/performance.svg';
import { useTranslation } from 'react-i18next';

export const WaitingScreen = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <img src={performance} alt="performance" />
      <span>{t('Raffle will be available again soon. Come back later!')}</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  padding: 90px 55px;

  & > img {
    width: 112px;
    height: 112px;
    object-fit: contain;
    margin-bottom: 2rem;
  }

  & > span {
    font-size: 0.9rem;
    line-height: 1.62;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
`;
