export enum CASE_BATTLE_ACTION_TYPE {
  //case-battle-duck
  ACTION_REQUEST_CASE_BATTLE_LIST = 'caseBattle/ACTION_REQUEST_CASE_BATTLE_LIST',
  ACTION_RESPONSE_CASE_BATTLE_LIST = 'caseBattle/ACTION_RESPONSE_CASE_BATTLE_LIST',
  //case-battle socket event battle: new;
  ACTION_ADD_NEW_BATTLE = 'caseBattle/ACTION_ADD_NEW_BATTLE',
  // case-battle socket event battle:join;
  ACTION_JOIN_NEW_BATTLE = 'caseBattle/ACTION_JOIN_NEW_BATTLE',
  // case-battle socket event battle:finish
  ACTION_BATTLE_FINISH = 'caseBattle/ACTION_BATTLE_FINISH',
  //case-battle socket event battle:roll
  ACTION_BATTLE_ROLL = 'caseBattle/ACTION_BATTLE_ROLL',

  //sort for main page
  ACTION_SET_SORT_PARAM = 'caseBattle/ACTION_SET_SORT_PARAM',
  ACTION_SET_SORT_HISTORY_PARAM = 'caseBattle/ACTION_SET_SORT_HISTORY_PARAM',

  //for history mp = main page
  ACTION_REQUEST_CASE_BATTLE_LIST_HISTORY_MP = 'caseBattle/ACTION_REQUEST_CASE_BATTLE_LIST_HISTORY_MP',
  ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_MP = 'caseBattle/ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_MP',
  ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_NEW_MP = 'caseBattle/ACTION_REQUEST_CASE_MY_BATTLE_LIST_HISTORY_NEW_MP',
  ACTION_RESPONSE_CASE_BATTLE_LIST_HISTORY_MP = 'caseBattle/ACTION_RESPONSE_CASE_BATTLE_LIST_HISTORY_MP',
  ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_MP = 'caseBattle/ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_MP',
  ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_NEW_MP = 'caseBattle/ACTION_RESPONSE_CASE_MY_BATTLE_LIST_HISTORY_NEW_MP',
  ACTION_REMOVE_ELEMENT = 'caseBattle/ACTION_REMOVE_ELEMENT',

  // my battle list actions
  ACTION_REQUEST_MY_CASE_BATTLE_LIST = 'caseBattle/ACTION_REQUEST_MY_CASE_BATTLE_LIST',
  ACTION_RESPONSE_MY_CASE_BATTLE_LIST = 'caseBattle/ACTION_RESPONSE_MY_CASE_BATTLE_LIST',
  //NOTE: need to check is this actions is useful

  // add cases for creating game
  ACTION_ADD_CASE = 'caseBattle/ACTION_ADD_CASE',
  ACTION_REMOVE_CASE = 'caseBattle/ACTION_REMOVE_CASE',
  ACTION_REMOVE_ALL = 'caseBattle/ACTION_REMOVE_ALL',
  ACTION_ADD_RANDOM_CASES = 'caseBattle/ACTION_ADD_RANDOM_CASES',
  SET_TOTAL_PRICE = 'caseBattle/SET_TOTAL_PRICE',
  SET_DEFAULT_TOTAL_PRICE = 'casesBattle/SET_DEFAULT_TOTAL_PRICE',
  SET_CASE = 'caseBattle/SET_CASE',
  REMOVE_CASE_ID = 'caseBattle/REMOVE_CASE_ID',

  //filters
  SORT_TYPE = 'caseBattle/SORT_TYPE',
  SORT_PRICE_RANGE = 'caseBattle/PRICE_RANGE',
  SORT_CLEAN_SORT_PARAMS = 'caseBattle/CLEAN_SORT_PARAMS',
  SORT_SEARCH = 'caseBattle/SEARCH',

  //cases
  CASE_BATTLE_CASE_LIST_ACTION_RESPONSE = 'caseBattle/CASE_BATTLE_CASE_LIST_ACTION_RESPONSE',

  //caseBattleType
  SET_CASE_BATTLE_TYPE = 'caseBattle/SET_CASE_BATTLE_TYPE',

  //creteBattleGame
  CREATE_BATTLE_GAME = 'caseBattle/CREATE_BATTLE_GAME',
  SET_GAME_ID = 'caseBattle/SET_GAME_ID',
  REMOVE_DATA_BATTLE_GAME = 'caseBattle/REMOVE_DATA',

  //case battle game
  ACTION_REQUEST_CASE_BATTLE = 'caseBattle/ACTION_REQUEST_CASE_BATTLE',
  ACTION_RESPONSE_CASE_BATTLE = 'caseBattle/ACTION_RESPONSE_CASE_BATTLE',

  //case battle repeat
  ACTION_CASE_BATTLE_REPLAY = 'caseBattle/ACTION_CASE_BATTLE_REPLAY',
  ACTION_BATTLE_ROLL_REPLAY = 'caseBattle/ACTION_BATTLE_ROLL_REPLAY',
  ACTION_BATTLE_FINISH_REPLAY = 'caseBattle/ACTION_BATTLE_FINISH_REPLAY',

  //add bot
  ACTION_ADD_BOT = 'caseBattle/ACTION_ADD_BOT',

  //join to game
  ACTION_JOIN_TO_GAME = 'caseBattle/ACTION_JOIN_TO_GAME',

  // addCasesByIds
  ACTION_REQUEST_ADD_CASES_BY_IDS = 'caseBattle/ACTION_REQUEST_ADD_CASES_BY_IDS',
  ACTION_RESPONSE_ADD_CASES_BY_IDS = 'caseBattle/ACTION_RESPONSE_ADD_CASES_BY_IDS',
  ACTION_REMOVE_CASES_BY_IDS_DATA = 'caseBattle/ACTION_REMOVE_CASES_BY_IDS_DATA',
  //roulette
  ACTION_REMOVE_ROULETTE_DATA = 'caseBattle/ACTION_REMOVE_ROULETTE_DATA',

  ///roulette statuses
  ACTION_REMOVE_ROULETTE_ANIMATION_STATUSES = 'caseBattle/ACTION_REMOVE_ROULETTE_ANIMATION_STATUSES',
  ACTION_ANIMATION_STATUS_AFTER_ROLL = 'caseBattle/ACTION_ANIMATION_STATUS_AFTER_ROLL',
  ACTION_ANIMATION_STATUS_FINISH = 'caseBattle/ACTION_ANIMATION_STATUS_FINISH',

  //emoji
  ACTION_ADD_EMOJI = 'caseBattle/ACTION_ADD_EMOJI',
  ACTION_RESPONSE_EMOJI_SOCKET = 'caseBattle/ACTION_RESPONSE_EMOJI_SOCKET',
  ACTION_RESPONSE_EMOJIS_SOCKET = 'caseBattle/ACTION_RESPONSE_EMOJIS_SOCKET',
  ACTION_RESPONSE_EMOJIS_AWAITING = 'caseBattle/ACTION_RESPONSE_EMOJIS_AWAITING',

  //sounds
  SET_SOUND_TYPE = 'caseBattle/SET_SOUND_TYPE',
}
