import React, { FC } from 'react';
import {
  Container,
  FastCoinIconContainer,
  IconContainer,
  IconContainerMinus,
  Input,
  InputContainer,
  Title,
} from './PriceInput.styles';
import { useTranslation } from 'react-i18next';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { setTotalPrice } from 'games/CaseBattle/ducks/create-battle-duck';
import { useDispatch } from 'react-redux';
import { useGetTotalPrice } from 'games/CaseBattle/hooks';
import { MAX_RANGE, MIN_RANGE } from 'games/CaseBattle/constants';

interface PriceInputProps {
  generateCasePackHandler: (price?: number) => void;
}

export const PriceInput: FC<PriceInputProps> = React.memo(({ generateCasePackHandler }) => {
  const { t } = useTranslation('');
  const { totalPrice } = useGetTotalPrice();
  const dispatch = useDispatch();

  const increment = () => {
    if (totalPrice < MAX_RANGE) {
      dispatch(setTotalPrice(totalPrice + 1));
      generateCasePackHandler(totalPrice + 1);
    }
  };

  const decrement = () => {
    if (totalPrice > MIN_RANGE) {
      dispatch(setTotalPrice(totalPrice - 1));
      generateCasePackHandler(totalPrice - 1);
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(+e.target.value)) {
      const value = +e.target.value;
      if (value !== totalPrice) {
        dispatch(setTotalPrice(value));
        generateCasePackHandler(value);
      }
    }
  };

  return (
    <Container>
      <Title>{t('randomCasesBefore')}</Title>
      <InputContainer isOutOfRange={totalPrice > MAX_RANGE}>
        <IconContainerMinus onClick={decrement} isLowestPrice={totalPrice === MIN_RANGE}>
          <CaseBattleIcon.MinusIcon />
        </IconContainerMinus>
        <FastCoinIconContainer>
          <CaseBattleIcon.FastCoinIcon />
        </FastCoinIconContainer>
        <Input type="text" value={totalPrice} onChange={onChangeHandler} placeholder="1 - 2200" />
        <IconContainer isHighestPrice={totalPrice >= MAX_RANGE} onClick={increment}>
          <CaseBattleIcon.PlusIcon />
        </IconContainer>
      </InputContainer>
    </Container>
  );
});
