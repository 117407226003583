import { createSelector } from 'reselect';

export const getCrashGameData = ({ crash }) => crash.data;

export const getCrashGameBetStatus = ({ crash }) => crash.betIsSet;

export const getCrashGamePing = ({ crash }) => crash.ping;

export const getCrashGameHistory = ({ crash }) => crash.history;

export const getCrashGameBets = createSelector(
  ({ crash }) => crash.playerBetsByID,
  bets => {
    const keys = Object.keys(bets).sort((l, r) => {
      if (bets[l].coef) {
        return 1;
      }

      if (bets[l].coef && bets[r].coef) {
        return bets[l].coef > bets[r].coef ? 1 : -1;
      }

      return -1 * bets[l].amount > -1 * bets[r].amount ? 1 : -1;
    });

    return keys.map(key => bets[key]);
  }
);
