import { ILastPayment, IPayMethod, IPayMethods } from './reduces.refill.interfaces';

export enum refillActionsTypes {
  REFILL_ATTACH_LISTENERS = 'app/refill/REFILL_ATTACH_LISTENERS',
  REFILL_DETACH_LISTENERS = 'app/refill/REFILL_DETACH_LISTENERS',

  SET_SELECT_COUNTRY = 'app/refill/SET_SELECT_COUNTRY',
  AUTO_SELECT_COUNTRY = 'app/refill/AUTO_SELECT_COUNTRY',
  AUTO_SELECT_COUNTRY_RESPONSE = 'app/refill/AUTO_SELECT_COUNTRY_RESPONSE',
  REFILL_LOADING = 'app/refill/REFILL_LOADING',
  FETCH_AVAILABLE_REFILL_METHODS = 'app/refill/FETCH_AVAILABLE_REFILL_METHODS',
  FETCH_REFILL_METHODS_SUCCESS = 'app/refill/FETCH_REFILL_METHODS_SUCCESS',
  UPDATE_LAST_PAYMENT = 'app/refill/UPDATE_LAST_PAYMENT',
  UPDATE_TRUST_USER = 'app/refill/UPDATE_TRUST_USER',

  UPDATE_AVAILABLE_TICKETS = 'app/refill/UPDATE_AVAILABLE_TICKETS',

  CREATE_PAYOUT = 'app/refill/CREATE_PAYOUT',
  ACTIVATED_DEPOSIT_CODE = 'app/refill/ACTIVATED_DEPOSIT_CODE',

  ACTION_REFERRAL_BONUS_REFILL = 'app/refill/ACTION_REFERRAL_BONUS_REFILL',
}

export interface IFetchAvailableMethod {
  type: typeof refillActionsTypes.FETCH_AVAILABLE_REFILL_METHODS;
}

export interface IReceiveAvailableMethod {
  type: typeof refillActionsTypes.FETCH_REFILL_METHODS_SUCCESS;
  payload: {
    methods: IPayMethods & {
      success: boolean;
      lastSuccess: IPayMethod;
      depositBonus: number;
    };
  };
}

export interface IUpdateSelectCountry {
  type: typeof refillActionsTypes.SET_SELECT_COUNTRY;
  payload: {
    refillCountry?: string;
  };
}

export interface IAutoSelectCountry {
  type: typeof refillActionsTypes.AUTO_SELECT_COUNTRY;
}

export interface IAutoSelectCountryResponse {
  type: typeof refillActionsTypes.AUTO_SELECT_COUNTRY_RESPONSE;
  payload: {
    autoRefillCountry?: string;
  };
}

export interface IRefillLoading {
  type: typeof refillActionsTypes.REFILL_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface IUpdateLastPayment {
  type: typeof refillActionsTypes.UPDATE_LAST_PAYMENT;
  payload: {
    lastPayment: ILastPayment;
  };
}

interface IUpdateTrustUser {
  type: typeof refillActionsTypes.UPDATE_TRUST_USER;
  payload: {
    trust: {
      status: boolean;
    };
  };
}

interface IUpdateEventTickets {
  type: typeof refillActionsTypes.UPDATE_AVAILABLE_TICKETS;
  payload: {
    amount: number;
  };
}

export type refillMethodActions =
  | IFetchAvailableMethod
  | IReceiveAvailableMethod
  | IUpdateSelectCountry
  | IUpdateLastPayment
  | IUpdateTrustUser
  | IUpdateEventTickets
  | IRefillLoading
  | IAutoSelectCountry
  | IAutoSelectCountryResponse;
