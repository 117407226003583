import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { changeQueryFilter } from '../../ducks/filters.duck';

export const useStoreQueryFilter = () => {
  const dispatch = useDispatch();

  const changeQuery = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      dispatch(changeQueryFilter(ev.target.value));
    },
    [dispatch]
  );

  const resetQuery = useCallback(() => {
    dispatch(changeQueryFilter(''));
  }, [dispatch]);

  return {
    changeQuery,
    resetQuery,
  };
};
