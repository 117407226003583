import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { GameRoulette } from './game-roulette';
import { GameHistory } from './game-history';
import { FactorControls } from './factor-controls';
import { GameNumber } from './game-number';
import { MembersGrid } from './members-grid';
import { GameNavigation } from './game-navigation';
import { AuthControlPanel } from '@components/auth-control-panel';
import { useTranslation } from 'react-i18next';

export const GamePage = () => {
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'history',
      iconKey: 'history',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('history'),
    },
    {
      name: 'top',
      iconKey: 'top',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('top'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];
  return (
    <>
      <AppBar isGame />
      <div className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <Container className="tablet">
          <div
            className="inner-wrapper"
            style={
              {
                '--height': `${Math.max(window.innerHeight, window.innerWidth)}px`,
              } as CSSProperties
            }
          >
            <GameHistory />
            <GameNumber />
            <GameRoulette />
            <FactorControls />
          </div>
          <GameNavigation />
          <MembersGrid />
        </Container>
      </div>
      <AuthControlPanel />
    </>
  );
};

const Container = styled.div`
  --roulette-gradient-reel-size: calc(min(100vw, 100vh) * 0.9866666666666667);
  --roulette-reel-size: calc(min(100vw, 100vh) * 0.7653333333333333);

  height: 100%;

  @media screen and (min-width: 500px) {
    --roulette-gradient-reel-size: 600px;
    --roulette-reel-size: 450px;
  }

  & .sector-0 {
    --stop-color-0: #0a6d07;
    --stop-color-1: #28e033;
    --stroke-color: var(--double-zero-stroke);
    --fill-color: var(--double-zero-fill);
  }

  & .sector-1,
  & .sector-2,
  & .sector-3,
  & .sector-4,
  & .sector-5,
  & .sector-6,
  & .sector-7 {
    --stop-color-0: #810612;
    --stop-color-1: #f91e32;
    --stroke-color: var(--double-red-stroke);
    --fill-color: var(--double-red-fill);
  }

  & .sector-8,
  & .sector-9,
  & .sector-10,
  & .sector-11,
  & .sector-12,
  & .sector-13,
  & .sector-14 {
    --stop-color-0: #202122;
    --stop-color-1: #444444;
    --stroke-color: var(--double-black-stroke);
    --fill-color: var(--double-black-fill);
  }

  & .inner-wrapper {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(2, min-content) 1fr;
    width: 100%;
    min-height: calc(var(--height) - var(--size-nav-bar) - 5rem);
    position: relative;
    overflow: hidden;
  }
`;
