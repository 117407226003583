import styled from 'styled-components';

export const CheckboxControl = styled.div`
  display: grid;
  grid-template-columns: 1.875rem 1fr;
  grid-gap: 0.25rem;
  grid-auto-flow: column;

  & > label {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1.375rem;
      height: 1.375rem;
      top: 50%;
      transform: translateY(-50%);
      left: -2.125rem;
    }

    &:before {
      content: '';
      position: absolute;
      background: #344d56;
      border-radius: var(--size-radius-s);
    }

    &:after {
      content: '';
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary);
      font-size: 0.8rem;
    }

    & > span {
      font-size: 0.75rem;
      line-height: 0.875rem;
      letter-spacing: 0.570769px;
      color: var(--color-white);
    }
  }

  & > input {
    z-index: -1;
    opacity: 0;

    &:checked + label:after {
      content: '\\e940';
      font-family: icomoon;
    }
  }
`;
