import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getAppCurrency, getAppCurrencySettings } from '../../../core/AppShell/selectors';
import { setCurrency } from '../../../core/AppShell/duck';

const mapStateToProps = state => ({
  currency: getAppCurrency(state),
  currencyConfig: getAppCurrencySettings(state),
});

const mapDispatchToProps = dispatch => ({
  setCurrency: bindActionCreators(setCurrency, dispatch),
});

class CurrencySelectorContainer extends Component {
  state = {
    activeId: 0,
  };

  render = () => <Btn onClick={this.selectCurrency}>{this.props.currency.key}</Btn>;

  selectCurrency = () => {
    const { currencyConfig } = this.props;
    this.setState(state => ({
      activeId: (state.activeId + 1) % currencyConfig.length,
    }));
    this.props.setCurrency(currencyConfig[this.state.activeId].key);
  };
}

const Btn = styled.button`
  border: 1px solid #5f5f5f;
  background: transparent;
  padding: 3px 5px 3px 5px;
  border-radius: 5px;

  color: var(--color-white);
  margin-left: 5px;
`;

export const CurrencySelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencySelectorContainer);
