export default {
  Games: 'Juegos',
  'Refill balance': 'Recargar balance',
  Settings: 'Configuración',
  Store: 'Tienda',
  'Get free money': 'Obtenga monedas gratis',
  Notifications: 'Notificaciones',
  FAQ: 'FAQ',
  'Log out': 'Terminar sesión',
  'Steam trade link': 'Enlace de intercambio de Steam',
  'Where can I get a link?': '¿Dónde puedo conseguir el enlace?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Has sido desconectado del servicio CSGOFAST. Toca en la pantalla para reconectar.',
  'App was offline. Check your internet connection to continue!':
    'La app estaba sin conexión. ¡Comprueba tu conexión a internet para continuar!',
  SAVE: 'SAVE',
};
