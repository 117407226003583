/* eslint-disable */

export default {
  'Difficulty level': 'Уровень сложности',
  'Your bet': 'Ваша ставка',
  'Start game': 'Начать игру',
  'Take prize': 'Забрать выигрыш',
  'Try again': 'Попробовать еще раз',
  Date: 'Дата',
  Bet: 'Ставка',
  Profit: 'Выигрыш',
  Step: 'Шаг',
  Player: 'Игрок',
  'You climbed to the </floor> floor of the tower': 'Вы забрались на {{</floor>}} этаж башни',
  'And found there </prize>': 'И нашли там {{</prize>}}',
  'You conquered the tower!': 'Вы покорили башню!',
  'Verify ticket': 'Проверить билет',
  'New game': 'Новая игра',
  'Play again': 'Играть еще',
  'And found there ${currency(prize)}': 'И нашли там ${currency(prize)}',
  'You climbed to the ${floor} floor of the tower': 'Вы забрались на  {{  floor }}  этаж башни',
  'And there found a chest with ${currency(prize)}': 'И нашли там сундук с ${currency(prize)}',
};
