import reducer, { eventsTypes } from './duck';
import TowerRouter from './containers';
import './locales';

export { TowerRouter };
// export const TowerRouter = lazy(() => import('./containers'))
export const towerModule = {
  moduleName: 'tower',
  moduleId: 14,
  path: '/tower',
  type: 'solo-pve',
  reducer,
  eventsTypes,
};
