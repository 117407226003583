import { IBidsResponse } from './purchases.reducer.interface';
import { IMarketplaceParticipantUpdate } from './marketplace.actions.interface';

export enum PurchaseActionTypes {
  FETCH_GET_BIDS = 'app/marketplace/FETCH_GET_BIDS',
  FETCH_GET_BIDS_SUCCESS = 'app/marketplace/FETCH_GET_BIDS_SUCCESS',

  FETCH_CREATE_BID = 'app/marketplace/FETCH_CREATE_BID',
  FETCH_CREATE_BID_SUCCESS = 'app/marketplace/FETCH_CREATE_BID_SUCCESS',

  FETCH_DELETE_BID = 'app/marketplace/FETCH_DELETE_BID',
  FETCH_DELETE_BID_SUCCESS = 'app/marketplace/FETCH_DELETE_BID_SUCCESS',
}

export interface IFetchGetBids {
  type: typeof PurchaseActionTypes.FETCH_GET_BIDS;
}

export interface IFetchGetBidsSuccess {
  type: typeof PurchaseActionTypes.FETCH_GET_BIDS_SUCCESS;
  payload: IBidsResponse;
}

export interface IFetchCreateBid {
  type: typeof PurchaseActionTypes.FETCH_CREATE_BID;
  payload: {
    id: number;
  };
}

// export interface IFetchCreateBidSuccess {
//   type: typeof PurchaseActionTypes.FETCH_CREATE_BID_SUCCESS
//   payload: {
//     id: number
//   }
// }

export interface IFetchCreateBidSuccess {
  type: typeof PurchaseActionTypes.FETCH_CREATE_BID_SUCCESS;
  payload: any;
}

export interface IFetchDeleteBid {
  type: typeof PurchaseActionTypes.FETCH_DELETE_BID;
  payload: {
    id: string;
  };
}

export interface IFetchDeleteBidSuccess {
  type: typeof PurchaseActionTypes.FETCH_DELETE_BID_SUCCESS;
  payload: {
    id: string;
  };
}

export type purchaseActions =
  | IFetchGetBids
  | IFetchGetBidsSuccess
  | IFetchCreateBid
  | IFetchCreateBidSuccess
  | IFetchDeleteBidSuccess
  | IMarketplaceParticipantUpdate;
