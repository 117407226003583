import * as React from 'react';
import styled from 'styled-components';
import { QuestPageItem } from './QuestPageItem';
import { IQuest } from '../../interfaces/reducer.leaders.interfaces';
import { useTranslation } from 'react-i18next';

interface IQuestPageList {
  questTypes: IQuest[][];
  takeRewarHandle: (id: number) => () => void;
}

export const QuestPageList: React.FC<IQuestPageList> = ({ questTypes, takeRewarHandle }) => {
  const { t } = useTranslation();
  return (
    <div>
      {questTypes.map((quests: IQuest[]) => (
        <div key={quests[0].type}>
          <QuestInfo>
            {t('Quests interval', {
              context: quests[0].type,
            })}
          </QuestInfo>
          <QuestList questType={quests[0].type}>
            {quests.map((quest: IQuest) => (
              <QuestPageItem
                key={quest.id}
                {...quest}
                takeRewarHandle={takeRewarHandle(quest.id)}
              />
            ))}
          </QuestList>
        </div>
      ))}
    </div>
  );
};

type QuestListStyle = {
  questType: string;
};

const QuestList = styled.div<QuestListStyle>`
  display: flex;
  flex-flow: column;
  padding: ${p => (p.questType === 'daily' ? '0 1rem' : '0 1rem 0 0')};
`;

const QuestInfo = styled.div`
  font-size: 1.3rem;
  letter-spacing: 0.54px;
  text-align: center;
  color: var(--color-white);
  padding: 1.3rem;
`;
