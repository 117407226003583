import styled from 'styled-components';
import { DEFAULT_PRICE_HEIGHT } from 'games/CaseBattle/constants';

export const Container = styled.div<{ hasAllPlayers: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  padding: 6px 6px 8px;
  width: 100%;
`;

export const User = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const Arrows = styled.div<{ isJoker: boolean }>`
  display: inline-block;
  height: 16px;
  margin-right: 4px;
  position: relative;
  width: 11px;

  & > svg {
    & > path {
      fill: ${({ isJoker }) => (isJoker ? 'var(--orange-400)' : 'var(--green-300)')};
    }
  }

  & > .arrow_top {
    animation: opacity 900ms ease-in-out infinite;
    left: 0;
    position: absolute;
    top: 5px;
  }

  & > .arrow_bottom {
    animation: opacity 900ms 300ms ease-in-out infinite;
    left: 0;
    position: absolute;
    top: 10px;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Currency = styled.div<{ priceHeight: number }>`
  color: var(--yellow);
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.24px;
  line-height: 24px;
  text-align: center;

  & > .symbol {
    margin-right: 2px;
  }

  & > .value {
    display: inline-block;
    width: ${({ priceHeight }) => (priceHeight > DEFAULT_PRICE_HEIGHT ? '100%' : 'auto')};
  }
`;

export const PlayerName = styled.span`
  color: var(--color-white);
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.1px;
  line-height: 15px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Avatar = styled.div<{
  profileType: string;
  isWinner: boolean;
}>`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 80px;

  @media screen and (max-width: 374px) {
    height: 66px;
    width: 66px;
  }

  &::before {
    background-color: ${({ isWinner }) => (isWinner ? '#00000099' : 'transparent')};
    border-radius: 50%;
    border: 2px solid ${p => `var(--color-account-type-${p.profileType})`};
    content: '';
    height: 100%;
    left: 0%;
    position: absolute;
    top: 0%;
    width: 100%;
  }

  > img {
    border-radius: 50%;
    height: 72px;
    width: 72px;

    @media screen and (max-width: 374px) {
      height: 58px;
      width: 58px;
    }
  }

  > .flag {
    bottom: -2px;
    height: 36px;
    position: absolute;
    right: -8px;
    width: 32px;

    @media screen and (max-width: 374px) {
      height: 30px;
      width: 30px;
    }
  }

  > .crown {
    height: 36px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 39px;

    @media screen and (max-width: 374px) {
      height: 30px;
      width: 30px;
    }

    & > path {
      fill: var(--yellow);
    }
  }
`;
