import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--color-dark-50);
  padding-bottom: 60px;
`;

export const Title = styled.span`
  color: var(--color-gray-200);
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 15px;
  padding: 12px 15px;
  text-transform: uppercase;
`;

export const SoundsWrap = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1px;
`;

export const SoundItem = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  display: flex;
  padding: 18px 14px;

  & svg {
    margin-left: auto;
  }
`;

export const CheckBoxTitle = styled.span`
  align-items: center;
  color: var(--color-white);
  display: flex;
  font-size: 14px;
  height: 24px;
`;

export const CheckboxContainer = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  border-bottom: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  display: flex;
  margin-bottom: 29px;
  padding: 18px 14px;

  &:not(:first-of-type) {
    margin-top: 39px;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  & + label {
    cursor: pointer;
    padding: 2px 0 0 66px;
    position: relative;
  }

  & + label::before {
    background: var(--color-white-gray);
    border-radius: 20px;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    content: '';
    height: 32px;
    left: 0;
    position: absolute;
    top: -15px;
    transition: 0.2s;
    width: 52px;
  }

  & + label::after {
    background: var(--color-white);
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 28px;
    left: 3px;
    position: absolute;
    top: -13px;
    transition: 0.2s;
    width: 28px;
  }

  &:checked + label::before {
    background: var(--yellow);
  }

  &:checked + label::after {
    background: var(--color-dark-50);
    left: 22px;
  }
`;
