/*eslint no-template-curly-in-string: "off"*/
import { ITransactionsItem } from './interfaces/personalArea.reducer.interfaces';

export const nicknameBonuses = [
  {
    day: 1,
    percent: 1,
  },
  {
    day: 5,
    percent: 3,
  },
  {
    day: 10,
    percent: 5,
  },
  {
    day: 20,
    percent: 10,
  },
  {
    day: 30,
    percent: 15,
  },
];

export interface ITransactionType {
  icon: string;
  name: string;
}

export const gameTypes: { [k: string]: ITransactionType } = {
  0: { icon: 'icon-refill', name: 'Bonus' },
  7: { icon: 'icon-crash', name: 'Crash' },
  9: { icon: 'icon-craft', name: 'Craft' }, // craft
  14: { icon: 'icon-tower', name: 'Tower' },
  6: { icon: 'icon-skins', name: 'CSGO_BOTS' }, //csgo_bots
  15: { icon: 'icon-wheel', name: 'Wheel' },
  3: { icon: 'icon-double', name: 'Double' },
  5: { icon: 'icon-slots', name: 'Slot' }, // slot
  13: { icon: 'icon-x50', name: 'X50' }, // x50
  4: { icon: 'icon-double', name: 'Duel' }, // duel,
  2: { icon: 'icon-fast', name: 'Fast' },
  1: { icon: 'icon-classic', name: 'Classic' },
  16: { icon: 'icon-hilo', name: 'Hilo' },
  10: { icon: 'icon-cases', name: 'Cases' },
  18: { icon: 'icon-baccarat', name: 'Baccarat' },
  19: { icon: 'icon-poggi', name: 'Poggi' },
  50: { icon: 'icon-case-battle', name: 'Case battle' },
  widthdrawal: { icon: 'icon-skins', name: 'Withdrawal' },
  'skins-withdrawal': { icon: 'icon-skins', name: 'Skins Withdrawal' },
  skins: { icon: 'icon-skins', name: 'Skins' },
  // bonus: { icon: 'icon-freecoins', name: 'Faucet' },
  bonusXp: { icon: 'icon-level-up', name: 'Level Up' },
  bonusAdmin: { icon: 'icon-refill', name: 'Payment' },
  payment: { icon: 'icon-refill', name: 'Payment' },
  deposit: { icon: 'icon-refill', name: 'Deposit' },
  referral: { icon: 'icon-referral', name: 'Referral' },
  cashback: { icon: 'icon-cashback', name: 'Cashback' },
  freeсoins: { icon: 'icon-cashback', name: 'Free coins' },
};

export const getItemType = (item: ITransactionsItem): ITransactionType => {
  switch (item.type) {
    case 'bonus':
      switch (item.payload.bonusType) {
        case 'xp':
          return gameTypes['bonusXp'];
        case 'admin':
          return gameTypes['bonusAdmin'];
        case 'cashback':
          return gameTypes['cashback'];
        default:
          return gameTypes['payment'];
      }
    case 'deposit':
      switch (item.payload.method) {
        case 'giftcards':
          return gameTypes['freeсoins'];
      }
      break;
    case 'skins-revoke':
      return gameTypes['skins'];
    case 'game-bet':
    case 'game-win':
      break;
    default:
      return gameTypes[item.type];
  }

  return gameTypes[item.payload.gameTypeId];
};

export const premiumOrders = ['demo', 'bronze', 'silver', 'platinum', 'diamond'];

export interface IPremiumPermission {
  text: string;
  status: string;
  currency: number | boolean;
  lock: boolean;
}

export interface IPremiumConfig {
  type: string;
  permissions: {
    [k: string]: IPremiumPermission;
  };
  get: {
    textBtn: string;
    currency: number;
    text: string;
  } | null;
}

export const premiumConfig: { [k: string]: IPremiumConfig } = {
  demo: {
    type: 'demo',
    permissions: {
      max_bet: {
        text: 'Max bet amount',
        status: '',
        currency: 100,
        lock: false,
      },
      balance: {
        text: 'Unlimited balance',
        status: 'LIMIT',
        currency: 500,
        lock: false,
      },
      access_leaders: {
        text: 'Access to Leaders Race',
        status: '',
        currency: false,
        lock: true,
      },
      // cashback: {
      //   text: 'Ability to take cashback',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      access_games: {
        text: 'Access to all games',
        status: '',
        currency: false,
        lock: true,
      },
      // experience: {
      //   text: 'Experience gain',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      giveaways: {
        text: 'Giveaways',
        status: '',
        currency: false,
        lock: true,
      },
      // access_cashback: {
      //   text: 'Ability to save cashback',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      // additional_experience: {
      //   text: 'Additional experience',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      free_cases: {
        text: 'Free case each month',
        status: '',
        currency: false,
        lock: true,
      },
      free_spins: {
        text: 'Free spin (4 per month)',
        status: '',
        currency: false,
        lock: true,
      },
      // additional_points_leader_race: {
      //   text: 'Additional points in Leaders Race',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
    },
    get: null,
  },
  bronze: {
    type: 'bronze',
    permissions: {
      max_bet: {
        text: 'Max bet amount',
        status: '',
        currency: 100,
        lock: false,
      },
      balance: {
        text: 'Unlimited balance',
        status: '',
        currency: false,
        lock: false,
      },
      access_leaders: {
        text: 'Access to Leaders Race',
        status: '',
        currency: false,
        lock: false,
      },
      // cashback: {
      //   text: 'Ability to take cashback',
      //   status: 'from',
      //   currency: 1000,
      //   lock: false,
      // },
      access_games: {
        text: 'Access to all games',
        status: '',
        currency: false,
        lock: true,
      },
      // experience: {
      //   text: 'Experience gain',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      giveaways: {
        text: 'Giveaways',
        status: '',
        currency: false,
        lock: true,
      },
      // access_cashback: {
      //   text: 'Ability to save cashback',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      // additional_experience: {
      //   text: 'Additional experience',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      free_cases: {
        text: 'Free case each month',
        status: '',
        currency: false,
        lock: true,
      },
      free_spins: {
        text: 'Free spin (4 per month)',
        status: '',
        currency: false,
        lock: true,
      },
      // additional_points_leader_race: {
      //   text: 'Additional points in Leaders Race',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
    },
    get: {
      textBtn: 'Refill',
      currency: 100,
      text: 'To get bronze account you`ve got to refill for ${currency(amount)} at once',
    },
  },
  silver: {
    type: 'silver',
    permissions: {
      max_bet: {
        text: 'Max bet amount',
        status: 'UNLIMITED',
        currency: false,
        lock: false,
      },
      balance: {
        text: 'Unlimited balance',
        status: '',
        currency: false,
        lock: false,
      },
      access_leaders: {
        text: 'Access to Leaders Race',
        status: '',
        currency: false,
        lock: false,
      },
      // cashback: {
      //   text: 'Ability to take cashback',
      //   status: 'from',
      //   currency: 1000,
      //   lock: false,
      // },
      access_games: {
        text: 'Access to all games',
        status: '',
        currency: false,
        lock: false,
      },
      // experience: {
      //   text: 'Experience gain',
      //   status: '',
      //   currency: false,
      //   lock: false,
      // },
      giveaways: {
        text: 'Giveaways',
        status: '',
        currency: false,
        lock: false,
      },
      // access_cashback: {
      //   text: 'Ability to save cashback',
      //   status: '',
      //   currency: false,
      //   lock: false,
      // },
      // additional_experience: {
      //   text: 'Additional experience',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
      free_cases: {
        text: 'Free case each month',
        status: '',
        currency: false,
        lock: true,
      },
      free_spins: {
        text: 'Free spin (4 per month)',
        status: '',
        currency: false,
        lock: true,
      },
      // additional_points_leader_race: {
      //   text: 'Additional points in Leaders Race',
      //   status: '',
      //   currency: false,
      //   lock: true,
      // },
    },
    get: {
      textBtn: 'Refill',
      currency: 1000,
      text:
        'To obtain a Silver account, you need to make a deposit using any available method with any amount',
    },
  },
  platinum: {
    type: 'platinum',
    permissions: {
      max_bet: {
        text: 'Max bet amount',
        status: 'UNLIMITED',
        currency: false,
        lock: false,
      },
      balance: {
        text: 'Unlimited balance',
        status: '',
        currency: false,
        lock: false,
      },
      access_leaders: {
        text: 'Access to Leaders Race',
        status: '',
        currency: false,
        lock: false,
      },
      // cashback: {
      //   text: 'Ability to take cashback',
      //   status: 'from',
      //   currency: 500,
      //   lock: false,
      // },
      access_games: {
        text: 'Access to all games',
        status: '',
        currency: false,
        lock: false,
      },
      // experience: {
      //   text: 'Experience gain',
      //   status: '',
      //   currency: false,
      //   lock: false,
      // },
      giveaways: {
        text: 'Giveaways',
        status: '',
        currency: false,
        lock: false,
      },
      // access_cashback: {
      //   text: 'Ability to save cashback',
      //   status: '',
      //   currency: false,
      //   lock: false,
      // },
      // additional_experience: {
      //   text: 'Additional experience',
      //   status: '+20%',
      //   currency: false,
      //   lock: false,
      // },
      free_cases: {
        text: 'Free case each month',
        status: '',
        currency: false,
        lock: false,
      },
      free_spins: {
        text: 'Free spin (4 per month)',
        status: '',
        currency: false,
        lock: false,
      },
      // additional_points_leader_race: {
      //   text: 'Additional points in Leaders Race',
      //   status: '+20%',
      //   currency: false,
      //   lock: false,
      // },
    },
    get: {
      textBtn: 'Refill',
      currency: 770000,
      text:
        'To get a platinum account, you need to refill your balance by a total of ${currency(amount)} per month',
    },
  },
  diamond: {
    type: 'diamond',
    permissions: {
      max_bet: {
        text: 'Max bet amount',
        status: 'UNLIMITED',
        currency: false,
        lock: false,
      },
      balance: {
        text: 'Unlimited balance',
        status: '',
        currency: false,
        lock: false,
      },
      access_leaders: {
        text: 'Access to Leaders Race',
        status: '',
        currency: false,
        lock: false,
      },
      // cashback: {
      //   text: 'Ability to take cashback',
      //   status: 'from',
      //   currency: 500,
      //   lock: false,
      // },
      access_games: {
        text: 'Access to all games',
        status: '',
        currency: false,
        lock: false,
      },
      // experience: {
      //   text: 'Experience gain',
      //   status: '',
      //   currency: false,
      //   lock: false,
      // },
      giveaways: {
        text: 'Giveaways',
        status: '',
        currency: false,
        lock: false,
      },
      // access_cashback: {
      //   text: 'Ability to save cashback',
      //   status: '',
      //   currency: false,
      //   lock: false,
      // },
      // additional_experience: {
      //   text: 'Additional experience',
      //   status: '+30%',
      //   currency: false,
      //   lock: false,
      // },
      free_cases: {
        text: 'Free case each month',
        status: '',
        currency: false,
        lock: false,
      },
      free_spins: {
        text: 'Free spin (4 per month)',
        status: '',
        currency: false,
        lock: false,
      },
      // additional_points_leader_race: {
      //   text: 'Additional points in Leaders Race',
      //   status: '+30%',
      //   currency: false,
      //   lock: false,
      // },
    },
    get: {
      textBtn: 'Refill',
      currency: 7700000,
      text:
        'To get a diamond account, you need to refill your balance by a total of ${currency(amount)} per month',
    },
  },
};
