import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <RulesContainer>
        <RulesP>
          <span>Classic game </span>-{' '}
          {t(
            'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How is the winner determined?')} </RulesSpan>
          {t('The winner is determined by the Ticket System in the Classic Game.')}
        </RulesP>

        <RulesDistinguish>
          <RulesSpan>{t('What is the Ticket System?')} </RulesSpan>
          {t(
            'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on'
          )}
        </RulesDistinguish>

        <RulesP>
          <RulesSpan>{t('When is the winner determined?')} </RulesSpan>
          {t(
            'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('What Service Commission is there in this game?')} </RulesSpan>
          {t(
            'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.'
          )}
        </RulesP>
      </RulesContainer>
    </div>
  );
};

const RulesContainer = styled.div`
  padding: 0px 10px 10px 10px;

  line-height: 14px;
  letter-spacing: 0.2px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 12px;
  & span {
    color: #33ffff;
    font-weight: 700;
  }
`;

const RulesSpan = styled.span`
  color: #33ffff;
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;

const RulesDistinguish = styled.div`
  border-radius: 15px;
  background: var(--gray-300);
  padding: 15px 10px 15px 10px;
  color: var(--color-white);
  font-size: 12px;
  margin-bottom: 4px;
  & span {
    color: var(--color-white);
    font-weight: 700;
    padding-bottom: 5px;
    display: block;
  }
`;
