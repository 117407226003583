import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { RadioButtonControl } from '@UIkit';
import { Button } from '@components/Common';
import { ModalFilterStyle } from '../../../@shared';
import { IDialogComponent } from 'core/interfaces';
import { useInventoryFilterController } from '../../../../hooks';
import { IInventoryRecord } from '../../../../interfaces';

interface IInventorySortByDialog extends IDialogComponent<boolean> {}

const sortBy = ['price', '-price', 'name', '-name'];

export const InventorySortByDialog: FC<IInventorySortByDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation('marketplace');

  const { filters, handleChangeFilters } = useInventoryFilterController();

  const { register, handleSubmit, formState } = useForm<Partial<IInventoryRecord['filters']>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      sortBy: filters.sortBy,
    },
  });

  const onSubmit = useCallback(
    (data: Partial<IInventoryRecord['filters']>) => {
      handleCloseModal();
      handleChangeFilters(data);
    },
    [handleCloseModal, handleChangeFilters]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Button type="button" className="link-light close-button" onClick={handleCloseModal}>
          {t('close')}
        </Button>
        <div className="filter-title">{t('sortBy.label')}</div>
        <div className="filter-list">
          {sortBy.map(v => (
            <RadioButtonControl key={v}>
              <input ref={register} name="sortBy" type="radio" value={v} />
              <span>{t(`sortBy.${v}`)}</span>
            </RadioButtonControl>
          ))}
        </div>
        <div className={`filter-controller ${formState.isDirty && 'visible'}`}>
          <Button className="primary" type="submit">
            {t('show selected')}
          </Button>
        </div>
      </Container>
    </form>
  );
};

const Container = styled(ModalFilterStyle)``;
