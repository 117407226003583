import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useVirtual, VirtualItem } from 'react-virtual';
import { Button } from '@components/Common';
import { SteamErrorsEnum, useUserPermission } from 'core/User';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { InventoryElement } from './inventory-element';
import { useClearance, useClearanceSwap } from '../../../hooks';
import { ModeType } from '../../../interfaces';

interface IFastInventoryClearance {
  mode: ModeType;
  reset: () => void;
}

interface IShopOrderStyle {
  visible: boolean;
}

const canWithdraw: SteamErrorsEnum[] = [
  SteamErrorsEnum.INVALID_TRADELINK,
  SteamErrorsEnum.PRIVATE_INVENTORY,
  SteamErrorsEnum.STEAM_GUARD,
];

export const FastInventoryClearance: FC<IFastInventoryClearance> = ({ mode, reset }) => {
  const { t, i18n } = useTranslation(['stock', 'steam-settings']);
  const { handleToggleDialog } = useDialog(userModals.STEAM_SETTINGS);
  const { permissions } = useUserPermission();
  const { items, amount } = useClearance();
  const { createClearance } = useClearanceSwap();
  const parentRef = useRef<HTMLDivElement>(null);

  const elements = useVirtual({
    size: items.length,
    parentRef,
    estimateSize: useCallback(() => 60, []),
    overscan: 2,
    horizontal: true,
  });

  return (
    <Container visible={!!mode}>
      <div className={` ${mode} header`}>
        <TextContainer>{t('clearance.header', { context: mode })}</TextContainer>
        <ButtonContainer>
          <Button className="link-light" onClick={reset}>
            {t('close')}
          </Button>
        </ButtonContainer>
      </div>
      <div ref={parentRef} className={'order-elements'}>
        {!items.length && (
          <div className="order-elements-empty">{t('clearance.empty', { context: mode })}</div>
        )}
        {!!items.length && (
          <div className="order-list" style={{ width: elements.totalSize }}>
            {elements.virtualItems.map(({ index, start }: VirtualItem) => (
              <div
                key={items[index].id}
                className="order-list-item"
                style={{ transform: `translateX(${start + 16}px)` }}
              >
                <InventoryElement item={items[index]} />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="controllers">
        {mode === ModeType.WITHDRAWAL ? (
          <>
            {permissions?.canTrade?.error === SteamErrorsEnum.STEAM_GUARD && (
              <Button
                className="primary"
                as="a"
                href={`https://help.steampowered.com/${i18n.language}/faqs/view/6891-E071-C9D9-0134`}
                target="_blank"
              >
                {t('steam-settings:::Add Steam Guard')}
              </Button>
            )}
            {(permissions?.canTrade?.error === SteamErrorsEnum.PRIVATE_INVENTORY ||
              permissions?.error === SteamErrorsEnum.UNBINDING_STEAM) && (
              <Button
                className="primary"
                onClick={handleToggleDialog(userModals.STEAM_BINDING, true)}
              >
                {t('steam-settings:::Update settings')}
              </Button>
            )}
            {SteamErrorsEnum.INVALID_TRADELINK === permissions?.canTrade?.error && (
              <Button
                className="primary"
                onClick={handleToggleDialog(userModals.STEAM_SETTINGS, true)}
              >
                {t('steam-settings:::Add Steam trade-link')}
              </Button>
            )}
            {!canWithdraw.includes(permissions?.canTrade?.error) && permissions?.success && (
              <Button
                className="primary"
                onClick={createClearance(mode)}
                disabled={!items.length && !!mode}
              >
                <div className="controllers-inner">
                  <div>{t('clearance.controller', { context: mode })}</div>
                  <div>
                    {t('{{count}} items for {amount}', {
                      count: items.length,
                      amount,
                      postProcess: 'with-template',
                    })}
                  </div>
                </div>
              </Button>
            )}
          </>
        ) : (
          <Button
            className="primary"
            onClick={createClearance(mode)}
            disabled={!items.length && !!mode}
          >
            <div className="controllers-inner">
              <div>{t('clearance.controller', { context: mode })}</div>
              <div>
                {t('{{count}} items for {amount}', {
                  count: items.length,
                  amount,
                  postProcess: 'with-template',
                })}
              </div>
            </div>
          </Button>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div<IShopOrderStyle>`
  display: grid;
  grid-template-rows: 2.25rem 6.5rem 4.5rem;
  background: var(--color-dark-600);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, ${({ visible }) => (visible ? '0%' : '100%')}, 0);
  transition: transform 50ms linear;
  z-index: 10;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-900);
    position: relative;

    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-white);

    &:before {
      content: '';
      display: block;
      width: 100vw;
      height: 175px;
      position: absolute;
      top: 100%;
      left: 50%;
      background: radial-gradient(
        57.49% 102.86% at 49.87% 100%,
        var(--color-status) 0%,
        transparent 61.3%
      );
      opacity: 0.2;
      transform: translate3d(-50%, 0, 0) matrix(1, 0, 0, -1, 0, 0);
      transition: background 100ms ease-in;
      z-index: -5;
    }

    & button {
      position: relative;
      right: 1rem;

      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
      text-transform: none;
    }
  }

  & .order-elements {
    overflow: scroll hidden;
    align-self: stretch;
    position: relative;
    z-index: 10;

    &::-webkit-scrollbar {
      display: none;
    }

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
    }

    & .order-list {
      width: 100%;
      height: 100%;
      position: relative;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 0.75rem;
        height: 3.5rem;
      }

      &-item {
        width: 3.5rem;
        height: 3.5rem;
        position: absolute;
        top: 1.5rem;
        left: 0;
        transition: transform 70ms ease-out;
      }
    }
  }

  & .controllers {
    padding: 0 1rem 1.5rem;

    & button {
      padding: 0;
      &:disabled {
        background-color: var(--yellow);
        color: var(--color-dark-600);

        & .controllers-inner {
          background: rgba(0, 0, 0, 0.3);

          & div {
            color: inherit;
          }
        }
      }
    }

    &-inner {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: 1fr;
      grid-gap: 0.125rem;
      width: 100%;
      padding: 0.5rem;
      position: relative;

      & div:first-of-type {
        font-weight: bold;
        font-size: 0.8125rem;
        line-height: 1rem;
        letter-spacing: 0.286px;
        color: var(--color-dark-600);
      }

      & div:last-of-type:not(:first-of-type) {
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        color: var(--color-dark-500);
      }
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex: 0.9;
  justify-content: flex-end;
`;
