import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 0;
`;

export const Title = styled.span`
  color: var(--color-gray-200);
  font-size: 0.86rem;
  letter-spacing: 0.53px;
  padding-left: 1.25rem;
  text-transform: uppercase;
`;

export const Activities = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  padding: 0.8rem 0;

  &::before,
  &::after {
    content: '';
    display: block;
    min-height: 100%;
    min-width: 1.25rem;
  }

  & .activities-item {
    background: var(--color-gray-700);
    border-radius: var(--size-radius-m);
    color: var(--color-white);
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 30px 1fr 1fr;
    letter-spacing: 0.61px;
    margin-right: 0.5rem;
    padding: 0.3rem 0.62rem;

    &:last-of-type {
      margin-right: 0;
    }

    &__icon {
      justify-self: center;
    }

    &__game {
      font-size: 0.75rem;
    }

    &__currency {
      color: var(--color-weekly-lottery);
      font-size: 0.75rem;
      justify-self: flex-end;
      white-space: nowrap;
    }

    & > * {
      align-self: center;
    }
  }
`;
