import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { SECTORS } from '../../../../utils';

export const GameRouletteGradientReel: FC = memo(({ children }) => (
  <>
    {SECTORS.map((sector, idx) => (
      <svg
        key={sector}
        className={`sector-${sector} roulette-gradient-reel`}
        viewBox="0 0 287 287"
        style={{
          transform: `translate3d(-50%, -50%, 0) rotate(${idx * 24}deg)`,
        }}
      >
        <linearGradient
          id={`gradient-wrapper-${sector}`}
          gradientUnits="userSpaceOnUse"
          x1="143.7"
          y1="231.1505"
          x2="143.7"
          y2="286.525"
        >
          <stop offset="0.5" stopColor="var(--stop-color-0)" />
          <stop offset="1" stopColor="rgba(19, 26, 29, 0.4)" />
        </linearGradient>
        <path
          fill={`url(#gradient-wrapper-${sector})`}
          d="M153.5,231.2L153.5,231.2c1.8-0.2,3.6,0.2,5.1,1.3c1.5,1,2.5,2.6,2.9,4.4l8.4,39.6l0,0  c0.2,1,0.2,2,0,2.9c-0.2,1-0.6,1.9-1.1,2.7c-0.6,0.8-1.3,1.5-2.1,2s-1.8,0.9-2.7,1c-13.5,1.9-27.1,1.9-40.6,0c-1-0.1-1.9-0.5-2.7-1  c-0.8-0.5-1.6-1.2-2.1-2c-0.6-0.8-1-1.7-1.1-2.7c-0.2-1-0.2-2,0-2.9l0,0l8.4-39.6c0.4-1.8,1.4-3.3,2.9-4.4c1.5-1,3.3-1.5,5.1-1.3  l0,0l0,0C140.2,232,146.8,232,153.5,231.2z"
        />
      </svg>
    ))}
    <Container />
    {children}
  </>
));

const Container = styled.div`
  width: calc(var(--roulette-reel-size) + 0.75rem);
  height: calc(var(--roulette-reel-size) + 0.75rem);
  background: var(--color-dark-layout);
  border-radius: 50%;
  position: absolute;

  transform: translate3d(-50%, -50%, 0);

  &:before {
    content: '';
    display: block;
    width: var(--roulette-gradient-reel-size);
    height: var(--roulette-gradient-reel-size);
    background: rgba(19, 26, 29, 0.3);
    backdrop-filter: blur(2px);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;
