import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 40px !important;
  padding: 0 16px;
  width: 100%;

  &.tablet {
    @media screen and (min-width: 500px) {
      margin: 0;
      width: 100%;
      padding: 0 10px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  background: var(--color-dark-200);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  height: 110px;
  justify-content: center;
`;

export const IconContainer = styled.div`
  align-items: center;
  border-radius: 100%;
  border: 1px solid var(--white-opacity-200);
  display: flex;
  justify-content: center;
  padding: 8px;
  position: relative;

  & > svg.animate {
    transform: rotate(360deg);
    transition: transform 0.5s ease;
  }

  & > svg {
    height: 24px;
    width: 24px;

    path {
      fill: var(--color-white);
    }
  }
`;

export const CountContainer = styled.div`
  align-items: center;
  background: var(--yellow);
  border-radius: 100%;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 20px;
  justify-content: center;
  letter-spacing: 0.3px;
  line-height: 14px; /* 100% */
  position: absolute;
  right: -10px;
  top: 0;
  width: 20px;
`;

export const Description = styled.span`
  color: var(--color-white);
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  text-align: center;
`;

export const ChooseCases = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  margin-top: 58px;
  width: 70%;

  & > span {
    color: var(--color-white);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 150%;
    text-align: center;
  }
`;
