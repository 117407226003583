import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Header,
  ItemContainer,
  TasksContainer,
  Time,
  Title,
} from './daily-tasks.styles';
import { useGetCategoryTime, useGetQuestsInfo } from 'modules/event-module/comics-event/hooks';
import { TaskItem } from '../task-item';
import { PeriodTime } from 'modules/event-module/comics-event/hooks/use-get-category-time.hook';
import { timeFormat } from 'core/utils';

export const DailyTasks = () => {
  const { t } = useTranslation();
  const { daily } = useGetQuestsInfo();
  const { time } = useGetCategoryTime(PeriodTime.DAILY);
  const [skeletonHeight, setSkeletonHeight] = useState(162);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      setSkeletonHeight(element.clientHeight);
    }
  }, [daily]);

  return (
    <Container>
      <Header>
        <Title>{t('dailyQuests')}</Title>{' '}
        {typeof time.hours === 'number' && (
          <Time>
            ({timeFormat(time.hours?.toString())}:{timeFormat(time.minutes?.toString())}:
            {timeFormat(time.seconds?.toString())})
          </Time>
        )}
      </Header>
      <TasksContainer ref={ref}>
        {!daily.length
          ? [1, 2, 3, 4, 5, 6].map(value => (
              <ItemContainer className="skeleton" key={value} height={skeletonHeight} />
            ))
          : daily.map(({ gameTypeId, id, name, params, progress, rewardTaken }, index) => (
              <TaskItem
                gameTypeId={gameTypeId}
                id={id}
                key={index}
                name={name}
                params={params}
                progress={progress}
                rewardTaken={rewardTaken}
              />
            ))}
      </TasksContainer>
    </Container>
  );
};
