import React from 'react';
import { Container } from './cases-last-revision.styles';
import { useTranslation } from 'react-i18next';
import { CaseInfo, Header } from './components';
import { useGetRevisionData } from 'games/CaseGame/hooks';
import { DropDown } from './components/drop-down/drop-down.component';
import { RevisionItems } from './components/revision-item/revision-items.component';

export const CasesLastRevisionDialog = () => {
  const { t } = useTranslation('cases2.0');
  const { data } = useGetRevisionData();
  const { revision, revisionIds } = data;
  const revisionItemHeader = [t('revision.skinPrice'), t('revision.range'), t('revision.chance')];
  return (
    <Container>
      <Header title={t('revision.caseRevision')} buttonText={t('revision.close')} />
      <CaseInfo
        img={revision?.case.backgroundImage}
        caseName={revision?.case.name}
        casePrice={revision?.price}
        createdAt={revision?.createdAt}
        updatedAt={revision?.updatedAt}
      />
      <DropDown ids={revisionIds} currentId={revision?.id} />
      <RevisionItems revisionItemHeader={revisionItemHeader} revisionItems={revision?.items} />
    </Container>
  );
};

//TODO: add correct revision id
