import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FullTime } from '../duck/default-event-duck';
import { useGetGeneralQuestInfo } from './use-get-general-quest-info.hook';

export const useGetEventTime = (isFinal?: boolean) => {
  const { endAt: endAtEvent, progressEndAt, isLoaded } = useGetGeneralQuestInfo();

  const endAt = isFinal ? endAtEvent : progressEndAt;

  const [time, setTime] = useState<FullTime>({
    days: isLoaded ? dayjs.duration(dayjs(endAt).diff(Date.now())).days() : null,
    hours: isLoaded ? dayjs.duration(dayjs(endAt).diff(Date.now())).hours() : null,
    minutes: isLoaded ? dayjs.duration(dayjs(endAt).diff(Date.now())).minutes() : null,
    seconds: isLoaded ? dayjs.duration(dayjs(endAt).diff(Date.now())).minutes() : null,
  });

  useEffect(() => {
    const duration = dayjs.duration(dayjs(endAt).diff(Date.now()));

    if (duration.milliseconds() < 0) {
      setTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      return;
    }

    const timerId = setInterval(() => {
      const d = dayjs.duration(dayjs(endAt).diff(Date.now()));

      if (d.milliseconds() < 0) {
        clearInterval(timerId);
      } else {
        setTime({
          days: d.days(),
          hours: d.hours(),
          minutes: d.minutes(),
          seconds: d.seconds(),
        });
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [endAt]);

  return {
    time,
  };
};
