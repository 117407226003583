import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindIn } from '../duck';

export const useBindingProvider = () => {
  const dispatch = useDispatch();
  const [provider, setProvider] = useState<string | null>(null);

  const handleBindingProvider = useCallback(
    (provider: string) => () => {
      setProvider(provider);
      if (provider === 'meta') {
        dispatch(bindIn('facebook'));
      } else {
        dispatch(bindIn(provider));
      }
      setTimeout(() => setProvider(null), 1500);
    },
    [dispatch, setProvider]
  );

  return {
    provider,
    handleBindingProvider,
  };
};
