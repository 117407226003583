export default {
  GAME: 'GAME',
  Winner: 'Winner',
  Win: 'Win',
  Chance: 'Chance',
  'Chance to win:': 'Chance to win:',
  'At stake': 'At stake',
  'Sign in through STEAM to participate': 'Sign in through STEAM to participate',
  'Steam trade link': 'Steam trade link',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Be first to deposit',
  'Place a bet': 'Place a bet',
  'Deposit more': 'Deposit more',
  'Min bet': 'Min bet',
  'Max bet': 'Max bet',
  'Bet amount': 'Bet amount',
  'max. items': 'max. items',
  Tickets: 'Tickets',
  from: 'from',
  'Tickets to from': 'Tickets: from #{{ from  }} to #{{ to }}',
  to: 'to',
  Skins: 'Skins',
  Coins: 'Coins',
  'VIRTUAL SKINS': 'VIRTUAL SKINS',
  'Round number': 'Round number',
  'Winning after commission': 'Winning after commission',
  Loading: 'Loading...',
  'Min {{ count }} items requires': 'Min. {{ count }} items requires',
  'From {{ min_items_per_trade }} to {{ max_items_per_trade }} items':
    'From {{ min_items_per_trade }} to {{ max_items_per_trade }} items',
};
