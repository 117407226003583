import { createBrowserHistory } from 'history';
import React, { FC, useContext, useMemo } from 'react';
import { RouterContext } from './router-context';
import { Router } from 'react-router-dom';

export const _history = createBrowserHistory();

export const AsyncBrowserRouter: FC = ({ children }) => {
  const [routerContext, dispatch] = useContext(RouterContext);

  const history = useMemo(() => {
    const loadModule = (path: string) =>
      routerContext
        .get(path)
        ?._ctor()
        .then((m: any) => dispatch({ type: 'set', name: path, module: m.default }));

    const { push, replace, go, goBack } = _history;

    _history.push = async (...rest: any) => {
      const [path] = rest;
      if (
        routerContext.has(path) &&
        routerContext.get(path)?._ctor &&
        routerContext.get(path)?._status === -1
      )
        await loadModule(path);
      push.apply(history, rest);
    };
    _history.replace = async (...rest: any) => {
      const [path] = rest;
      if (
        routerContext.has(path) &&
        routerContext.get(path)?._ctor &&
        routerContext.get(path)?._status === -1
      )
        await loadModule(path);
      replace.apply(history, rest);
    };
    _history.go = async (...rest: any) => {
      const [path] = rest;
      if (
        routerContext.has(path) &&
        routerContext.get(path)?._ctor &&
        routerContext.get(path)?._status === -1
      )
        await loadModule(path);
      go.apply(history, rest);
    };

    _history.goBack = async (...rest: any) => {
      const [path] = rest;
      if (
        routerContext.has(path) &&
        routerContext.get(path)?._ctor &&
        routerContext.get(path)?._status === -1
      )
        await loadModule(path);
      goBack.apply(history, rest);
    };

    return _history;
  }, [routerContext, dispatch]);

  return <Router history={history}>{children}</Router>;
};
