import { IStore } from 'store/interface';

export const getRefillCountry = ({ refill }: IStore) => refill.get('refillCountry');

export const getAutoRefillCountry = ({ refill }: IStore) => refill.get('autoRefillCountry');

export const getRefillLoading = ({ refill }: IStore) => refill.get('loading');

export const getPayConfig = ({ refill }: IStore) => refill.get('config');

export const getLastPaymentMethod = ({ refill }: IStore) => refill.get('lastPayment');

export const getMethod = ({ refill }: IStore, type: string, name: string) =>
  refill.getIn(['config', type])?.find((item: any) => item.name === name);

export const getTicketsRefillEvent = ({ refill }: IStore) => refill.getIn(['events', 'tickets']);

export const getBonusRefillEvent = ({ refill }: IStore) => refill.getIn(['events', 'bonus']);

export const getTrustUser = ({ refill }: IStore) => refill.get('trust');
