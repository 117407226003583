import styled from 'styled-components';

export const Notice = styled.div<{ select: boolean; disabled: boolean }>`
  align-items: center;
  background: ${({ select }) => (select ? 'var(--color-white-gray)' : 'var(--color-dark-600)')};
  display: flex;
  gap: 13px;
  padding: 12px 16px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Icon = styled.img`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 30px;
  width: 30px;

  & > svg {
    align-items: center;
    background: var(-color-white);
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
  }
`;

export const Text = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

export const Date = styled.span`
  color: var(--color-gray-200);
  display: block;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

export const Right = styled.div`
  margin-left: auto;
`;
