import React, { FC, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { AnimatedDrawerLayout } from './AnimatedDrawerLayout';

import { Balance, DrawerNavigation, DrawerSettings, SignIn } from '../components';
import { QuestWidget } from 'modules/LeadersRace';
import { UserProfile } from './UserProfile';
import { HistoryNavigation } from './HistoryNavigation/HistoryNavigation';
import { useUser } from '../../User';
import { useDrawer, useDrawerNavigation } from '../hooks';
import { useDialog } from 'core/hooks';
import { AuthDialogs } from 'core/Auth';
import { userModals } from 'core/User/modal.config';
import { getDrawerOpenStatus } from 'core/AppShell/selectors';

export const Drawer: FC = () => {
  const { handleToggleDrawer } = useDrawer();
  const { handlePushTo, handleOpenDesktop } = useDrawerNavigation();
  const active = useSelector(getDrawerOpenStatus, shallowEqual);

  const { user } = useUser();
  const { handleToggleDialog, handleCloseByName } = useDialog(AuthDialogs.SIGN_IN);

  useEffect(() => {
    if (active) handleCloseByName(userModals.PROFILE_PREMIUM);
  }, [active, handleCloseByName]);

  return (
    <AnimatedDrawerLayout handleToggleDrawer={handleToggleDrawer}>
      {user.id ? (
        <UserProfile handlePushTo={handlePushTo} />
      ) : (
        <SignIn handlePushTo={handleToggleDialog(AuthDialogs.SIGN_IN, true)} />
      )}
      <Balance handlePushTo={handlePushTo} />
      <DrawerNavigation handlePushTo={handlePushTo} handleOpenDesktop={handleOpenDesktop} />

      <QuestWidget />
      <HistoryNavigation handlePushTo={handlePushTo} />
      <DrawerSettings />
    </AnimatedDrawerLayout>
  );
};
