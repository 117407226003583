import React, { FC, memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { animated, useSprings } from 'react-spring';
import { SlotGameGridViewItem } from '../slot-game-grid-view-item';
import BezierEasing from 'bezier-easing';
import { useSlotMatrix } from '../../../hooks';
import SoundService from 'services/sound.service';

const sound = new SoundService();

interface IElement {
  items: number[];
  id: number;
}

export const SlotGameGridView: FC = memo(() => {
  const ref = useRef<boolean>(true);
  const [startSound, setStartSound] = useState(false);
  const { matrix } = useSlotMatrix();
  const elements = useMemo<IElement[]>(() => {
    const id = Date.now();
    return matrix.reduce((acc: IElement[], elem, i) => [...acc, { items: elem, id: id + i }], []);
  }, [matrix]);

  const [springs, set] = useSprings(elements.length, (index: number) => ({
    transform: 'translate3d(0, 0, 0)',
    left: `${index * 20}%`,
    reset: true,
    immediate: ref.current,
  }));

  useEffect(() => {
    if (startSound) {
      sound.play('Slot', 'Game start');
      sound.play('Slot', 'Spin sounds#0', { repeat: true });
    }
    return () => sound.stop({ close: true });
  }, [startSound]);

  useLayoutEffect(() => {
    // @ts-ignore
    set(index => {
      if (!ref.current) {
        setStartSound(true);

        setTimeout(() => {
          sound.play('Slot', 'Spin sounds#2', {
            repeat: true,
            currentTime: 1.5,
          });
        }, 500 + index * 500);

        setTimeout(() => {
          sound.play('Slot', 'Spin sounds#0', { repeat: false });
          sound.play('Slot', 'Spin sounds#2', {
            repeat: false,
          });
          setStartSound(false);
        }, 3000);
      }

      return {
        from: { transform: 'translate3d(0, -900%, 0)' },
        transform: 'translate3d(0, 0, 0)',
        reset: true,
        config: {
          duration: 500 + index * 500,
          easing: BezierEasing(0.455, 0.03, 0.515, 0.955),
        },
        immediate: ref.current,
      };
    });

    if (ref.current) ref.current = false;
  }, [matrix, set]);

  return (
    <Container>
      {springs.map((props, key) => (
        <AnimatedContainer key={elements[key].id} style={props}>
          {elements[key].items.map((elm: number, idx: number) => (
            <SlotGameGridViewItem key={elements[key].id + idx} idx={elm} />
          ))}
        </AnimatedContainer>
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0.25rem;
  min-height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const AnimatedContainer = styled(animated.div)`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row: 1 / 4;
  min-width: 20%;
  position: absolute;
  top: 0;
  bottom: 0;
`;
