import * as React from 'react';
import styled from 'styled-components';
import { SignIn } from '../../../../core/Auth';

import { ControlsBet } from './ControlsBet';
import { ControlsGame } from './ControlsGame';
import { ControlsPassGame } from './ControlsPassGame';

export class Controls extends React.PureComponent {
  componentWillUnmount() {
    if (this.props.ticket.status !== 'active') this.props.clearTicket();
  }

  render() {
    const {
      user,
      changeBetValue,
      changeType,
      settings,
      bet,
      types,
      typeIndex,
      ticketType,
      onBet,
      ticket,
      sellTicket,
      clearTicket,
    } = this.props;

    if (!user.id) {
      return (
        <ControlsContainer>
          <SignIn className="primary full" />
        </ControlsContainer>
      );
    }

    return (
      <>
        {['active', 'failed', 'finished'].includes(ticket.status) ? (
          <ControlsGame ticket={ticket} sellTicket={sellTicket} ticketType={ticketType} />
        ) : (
          <ControlsBet
            changeBetValue={changeBetValue}
            changeType={changeType}
            settings={settings}
            bet={bet}
            types={types}
            typeIndex={typeIndex}
            onBet={onBet}
          />
        )}
        {['failed', 'finished'].includes(ticket.status) && (
          <ControlsPassGame onBet={onBet} clearTicket={clearTicket} />
        )}
      </>
    );
  }
}

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 1rem;
`;
