import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchCreatePublication } from '../../ducks/sales.duck';

export const useSalesCreatePublication = () => {
  const dispatch = useDispatch();

  const handleCreatePublication = useCallback(
    (data: Record<string, boolean>) => {
      dispatch(fetchCreatePublication(data));
    },
    [dispatch]
  );

  return {
    handleCreatePublication,
  };
};
