import React from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { NavigationLink } from '@components/Common';
import { useTranslation } from 'react-i18next';

export const ReferralNavigator = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  return (
    <Container>
      <NavigationLink to={`${match.url}/members`} as={Link}>
        <div className="leading">
          <i className="icon-referrals" />
        </div>
        <div>
          <span>{t('All campaign referrals')}</span>
        </div>
      </NavigationLink>
      <NavigationLink to="/personal/referral" as={Link}>
        <div className="leading">
          <i className="icon-support" />
        </div>
        <div>
          <span>{t('Help')}</span>
        </div>
      </NavigationLink>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  background: var(--color-dark-600);
  margin: 1.5rem 0;
`;
