import React, { FC, useEffect, useMemo } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { LotteryPage } from '@components/Histories';
import { GamePage } from './game-page';
import { RulesPage } from './rules-page';
import { registerSlotModule, unregisterSlotModule } from '../duck';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => {
  useMemo(() => {
    registerSlotModule();
  }, []);

  useEffect(() => {
    return () => unregisterSlotModule();
  }, []);

  return (
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/rules`} component={RulesPage} />
        <Route path={`${match.url}/lottery`} component={LotteryPage} />
        <Route path={match.url} component={GamePage} />
      </AnimatedRouter>
    </div>
  );
};
