import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { ReferralMembersList } from './referral-members-list';
import { RouteComponentProps } from 'react-router';
import { useReferralCampaign } from '../../../hooks';
import { useTranslation } from 'react-i18next';

interface RouteParams {
  id: string;
}

export const ReferralMembersPage: FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { campaign } = useReferralCampaign(match.params.id);
  const { t } = useTranslation();
  return (
    <>
      <AppBar deep>
        <span>{t(campaign?.name || 'All campaign referrals')}</span>
      </AppBar>
      <div className="content dark-layout">
        <ReferralMembersList id={match.params.id} />
      </div>
    </>
  );
};
