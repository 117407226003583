export enum CaseEnum {
  PAID = 'paid',
  FREE = 'free',
  PAID_FREE = 'paid-free',
  INIT = 'INIT',
}
export enum ProfileEnum {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  DIAMOND = 'diamond',
}
