import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Sort } from '@components/filters';
import { IDialogComponent } from 'core/interfaces';
import {
  actionRequestCaseBattleList,
  actionRequestCaseMyBattleListHistory,
  actionSetSortParam,
} from 'games/CaseBattle';
import { SortParam } from 'games/CaseBattle/constants';
import { actionSetSortHistoryParam } from 'games/CaseBattle/ducks/sort-history-page-duck';
import { useGetSortHistoryParam, useGetSortParam } from 'games/CaseBattle/hooks';

const sortParams = [SortParam.newest, SortParam.oldest, SortParam.cheap, SortParam.expensive];

interface SortDialogProps extends IDialogComponent<boolean> {}

export const SortDialogCaseBattle: React.FC<SortDialogProps> = ({ handleCloseModal }) => {
  const { sortParam } = useGetSortParam();
  const { sortHistoryParam } = useGetSortHistoryParam();
  const location = useLocation();
  const isHistoryPage = location.pathname.split('/')?.[2] === 'history';
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (value: string) => {
      if (isHistoryPage) {
        dispatch(
          actionRequestCaseMyBattleListHistory({
            sortBy: value,
            user: true,
            limit: 20,
            offset: 0,
            force: true,
          })
        );
        dispatch(actionSetSortHistoryParam(value as SortParam));
      } else {
        dispatch(actionRequestCaseBattleList(value as SortParam));
        dispatch(actionSetSortParam(value as SortParam));
      }

      handleCloseModal();
    },
    [dispatch, handleCloseModal, isHistoryPage]
  );

  return (
    <Sort
      sortParams={sortParams}
      closeHandler={handleCloseModal}
      submitHandler={onSubmit}
      value={isHistoryPage ? sortHistoryParam : sortParam}
    />
  );
};
