import { IHistory, IInitStateToken } from './poggi.reducer.interfaces';

export enum poggiActionsTypes {
  TOKEN_RESPONSE = 'app/poggi-game/TOKEN_RESPONSE',
  CLEAN_UP = 'app/poggi-game/CLEAN_UP',
}

export interface IAuthToken {
  type: typeof poggiActionsTypes.TOKEN_RESPONSE;
  payload: IInitStateToken;
}

export interface ICleanUp {
  type: typeof poggiActionsTypes.CLEAN_UP;
}

export type tokenActions = IAuthToken | ICleanUp;

export enum historiesActionsTypes {
  ADD_OWN_HISTORY = 'app/poggi-game/ADD_OWN_HISTORY',
  ADD_RECENT_HISTORY = 'app/poggi-game/ADD_RECENT_HISTORY',
  CLEAN_UP_HISTORY = 'app/poggi-game/CLEAN_UP_HISTORY',
  HISTORY_SWITCH = 'app/poggi-game/HISTORY_SWITCH',
}

export interface addOwnHistoryAction {
  type: typeof historiesActionsTypes.ADD_OWN_HISTORY;
  payload: IHistory[];
}

export interface addRecentHistoryAction {
  type: typeof historiesActionsTypes.ADD_RECENT_HISTORY;
  payload: IHistory;
}

export interface historiesSwitchAction {
  type: typeof historiesActionsTypes.HISTORY_SWITCH;
}

export interface cleanUpAction {
  type: typeof historiesActionsTypes.CLEAN_UP_HISTORY;
}

export type historiesActions =
  | addOwnHistoryAction
  | addRecentHistoryAction
  | historiesSwitchAction
  | cleanUpAction;
