import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import styled from 'styled-components';

export default class Quest extends Component {
  static defaultProps = {
    height: 20,
  };

  constructor() {
    super();
    this.state = { open: false };
    this.headerHeightAnimation = new Animated.Value(0);
    this.headerOpacityAnimation = new Animated.Value(0);

    this._toggleInfo = this._toggleInfo.bind(this);
  }

  render() {
    const height = this.headerHeightAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [1, this.props.height],
    });

    const paddingVertical = this.headerHeightAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 10],
    });

    const { open } = this.state;
    return (
      <FaqQuest>
        <Flex onClick={this._toggleInfo}>
          <Title>{this.props.title}</Title>
          <Indicator>{open ? String.fromCharCode(8211) : '+'}</Indicator>
        </Flex>
        <div>
          <Animated.div
            style={{
              maxHeight: height,
              paddingVertical: paddingVertical,
              overflow: 'hidden',
            }}
          >
            <Animated.div style={{ opacity: this.headerOpacityAnimation }}>
              <Content>{this.props.children}</Content>
            </Animated.div>
          </Animated.div>
        </div>
      </FaqQuest>
    );
  }

  _toggleInfo() {
    const { open } = this.state;
    this.setState({ open: !open });

    this.headerHeightAnimation.setValue(open ? 1 : 0);
    this.headerOpacityAnimation.setValue(open ? 1 : 0);

    Animated.timing(this.headerHeightAnimation, {
      toValue: open ? 0 : 1,
      duration: open ? 500 : 300,
    }).start();
    Animated.timing(this.headerOpacityAnimation, {
      toValue: open ? 0 : 1,
      duration: open ? 150 : 1300,
    }).start();
  }
}

const FaqQuest = styled.div`
  padding: 8px 8px 8px 0px;
  border-bottom: 1px solid #386369;
  margin-left: 10px;
`;

const Title = styled.div`
  padding: 8px 8px 8px 0;
  color: var(--color-white);

  font-size: 13px;
  display: flex;
  justify-content: space-between;
  letter-spacing: 1px;
`;

const Indicator = styled.div`
  color: #386369;
  font-size: 30px;
`;

const Content = styled.div`
  color: var(--color-white);
  font-size: 13px;

  line-height: 20px;
  padding-top: 15px;
  letter-spacing: 0.2px;
  & span {
    color: #4897b1;
    border-bottom: 1px solid;
  }
  & div {
    color: #4897b1;
    display: inline;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
`;
