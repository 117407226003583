import * as React from 'react';
import styled from 'styled-components';
import { PlayerAvatar } from '../../Common';
import { CurrencyText } from '../../currency';
import { useTranslation } from 'react-i18next';

import { IPlayer } from '../interfaces/reducer.history.interface';
import { getPercent } from 'core/utils/percent.util';

interface HistoryWinnerProfile extends IPlayer {
  totalPrize: number;
  winnerName: string;
}

export const HistoryWinnerProfile: React.FC<HistoryWinnerProfile> = ({
  playerImage,
  itemPrice,
  totalPrize,
  winnerName,
  playerId,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <PlayerInfo>
        <PlayerAvatar uri={playerImage} userId={playerId} />
        <div>
          <span>{t('Winner')}</span>
          <span>{winnerName}</span>
        </div>
      </PlayerInfo>
      <ProfileDescription>
        <div>
          <span>
            {t('winning')}
            {': '}
          </span>
          <span style={{ color: 'var(--green-100)' }}>
            <CurrencyText value={totalPrize} />
          </span>
        </div>
        <div>
          <span>
            {t('Chance')}
            {': '}
          </span>
          <span style={{ color: 'var(--gray-100)' }}>
            {getPercent(itemPrice, totalPrize)}
            {'%'}
          </span>
        </div>
      </ProfileDescription>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 0 1rem 1.5rem;
  flex-flow: column;
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 1rem;

  & > div {
    display: flex;
    flex-flow: column;
    justify-content: center;

    &:last-child {
      margin-left: 0.7rem;
    }

    & > span {
      font-size: 0.9rem;
      letter-spacing: 0.77px;
      color: var(--color-white);
      font-weight: bold;
      line-height: 1.3;
    }

    & > span:last-child {
      font-weight: normal;
    }
  }
`;

const ProfileDescription = styled.div`
  display: flex;
  flex-flow: column;

  & > div {
    & > span {
      font-size: 0.9rem;
      letter-spacing: 0.77px;
      color: var(--color-white);
      line-height: 1.3;
    }

    & > span:last-child {
      font-weight: bold;
    }
  }
`;
