import React from 'react';
import { Circle, SvgContainer } from './progress-chart.styles';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { TicketTypeEnum } from 'modules/event-module/comics-event/interfaces';

const coinTypeObject = {
  [TicketTypeEnum.DAILY]: (
    <img src={eventImagesPath.bronzeDailyPath} width={32} height={32} className="coin" alt="" />
  ),
  [TicketTypeEnum.SPECIAL]: (
    <img src={eventImagesPath.goldSpecialPath} width={32} height={32} className="coin" alt="" />
  ),
  [TicketTypeEnum.WEEKLY]: (
    <img src={eventImagesPath.silverWeeklyPath} width={32} height={32} className="coin" alt="" />
  ),
};

export const ProgressChart = ({
  progress,
  color,
  type = TicketTypeEnum.WEEKLY,
}: {
  progress: number;
  color: string;
  type?: TicketTypeEnum;
}) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  const height = 64;
  const width = 64;

  const x = width / 2; // координата X центра круга
  const y = height / 2; // координата Y центра круга

  return (
    <>
      <SvgContainer width={width} height={height}>
        <circle
          cx={x}
          cy={y}
          fill="transparent"
          r={radius}
          stroke="var(--color-dark-600)"
          strokeDasharray={circumference}
          strokeDashoffset={0}
          strokeWidth="4"
        />
        <Circle
          cx={x}
          cy={y}
          fill="transparent"
          progress={offset}
          r={radius}
          stroke={color}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeWidth="4"
        />
      </SvgContainer>
      {coinTypeObject[type]}
    </>
  );
};
