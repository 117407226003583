import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IRelativeTimer {
  finishEvent: string;
}

export const PluralizeTimer: FC<IRelativeTimer> = ({ finishEvent }) => {
  const [unit, set] = useState(() => {
    const d = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
    return { h: d.hours(), m: d.minutes(), s: d.seconds() };
  });
  const { t } = useTranslation('marketplace');

  useEffect(() => {
    const diff = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
    if (diff.seconds() < 0) return;

    const timerId = setInterval(() => {
      const d = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
      if (d.seconds() < 0) {
        clearInterval(timerId);
      } else {
        set({ h: d.hours(), m: d.minutes(), s: d.seconds() });
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [finishEvent, set]);

  return (
    <>
      {unit.h > 0 && (
        <span className="hours">
          {unit.h}{' '}
          {t('pluralize time.hours', {
            postProcess: 'interval',
            count: unit.h,
          })}{' '}
        </span>
      )}
      {unit.m > 0 && (
        <span className="minutes">
          {unit.m}{' '}
          {t('pluralize time.minutes', {
            context: unit.h > 0 ? 'short' : null,
            postProcess: 'interval',
            count: unit.m,
          })}{' '}
        </span>
      )}
      {!!unit.s && !unit.m && !unit.h && (
        <span className="seconds">
          {unit.s}{' '}
          {t('pluralize time.seconds', {
            context: unit.m > 0 ? 'short' : null,
            postProcess: 'interval',
            count: unit.s,
          })}{' '}
        </span>
      )}
    </>
  );
};
