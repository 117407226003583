import './locales';
import { ConfirmSellingDialog, SortByFilterDialog } from './components';
import { DIALOG, transitions } from 'dialogs';
import { FastInventoryModal } from './fast-inventory-modal.config';

export { FastInventoryRouter } from './fast-inventory-router';
export type { IStockReducer } from './interfaces';

DIALOG.add(FastInventoryModal.SORT_BY_INVENTORY, {
  component: SortByFilterDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'inventory',
});

DIALOG.add(FastInventoryModal.CONFIRM_SELLING, {
  component: ConfirmSellingDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'stock',
});
