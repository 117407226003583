import React, { FC, ChangeEvent } from 'react';
// import { useTranslation } from 'react-i18next';
// import { CurrencyText } from '@components/currency';
import { UserBalance } from 'core/User/widgets';
import { ICurrency } from 'core/AppShell/interfaces/appshell.reducer.interface';
// import { useBonusRefillEvent } from '../../../Refill/hooks';
// import { useCashbackFacade } from '../../hooks';
import { Container } from './BalancePanel.styles';

interface IBalancePanel {
  currentCurrency: ICurrency;
  handleSetCurrency: (ev: ChangeEvent<HTMLSelectElement>) => void;
  currencySettings: ICurrency[];
}

export const BalancePanel: FC<IBalancePanel> = () => {
  // const { t } = useTranslation();
  // const { bonus } = useBonusRefillEvent();
  // const { cashback } = useCashbackFacade();

  return (
    <Container>
      <div className="balance">
        <span>
          <UserBalance />
        </span>
        {/* <select value={currentCurrency.key} onChange={handleSetCurrency}>
          {currencySettings.map((currency: ICurrency) => (
            <option key={currency.key} value={currency.key}>
              {currency.prefix || currency.postfix}
            </option>
          ))}
        </select> */}
      </div>
      {/* <div className="cashback">
        {t('Cashback')} <CurrencyText value={cashback.amount} />
      </div> */}
    </Container>
  );
};
