import { Reducer } from 'react';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import {
  AnimationRouletteStatusesActions,
  AnimationRouletteStatusesReducer,
  DefaultRouletteInfo,
  RouletteActionsCaseBattle,
  RouletteReducer,
  Strips,
  ActionSetSoundType,
} from './roulette.interfaces';
import { Record } from 'immutable';
import { GameStatus, RouletteStatuses } from 'games/CaseBattle/constants';
import { CaseBattleType } from '../create-battle-duck/create-battle.interfaces';
import { updatedStrip } from 'games/CaseBattle/utils';
import { getSounds } from 'games/CaseGame/utils';

const initState = Record<RouletteReducer>({
  data: {
    battleId: null,
    cases: [],
    strips: [],
    playerCount: null,
    playerInTeam: null,
    lastRound: null,
    gameStatus: GameStatus.waiting,
    currentRound: null,
    battleType: null,
  },
});

export const actionRemoveRouletteData = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ROULETTE_DATA,
});

export const rouletteReducer: Reducer<Record<RouletteReducer>, RouletteActionsCaseBattle> = (
  state = new initState(),
  action
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL: {
      const battleIds = action.payload.data.map(({ battleId }) => battleId);
      if (state.get('data').battleId === battleIds[0]) {
        const strips = action.payload.data.map(({ strip, user_id }) => ({
          userId: user_id,
          strip,
        }));

        return state
          .setIn(['data', 'strips'], updatedStrip(state.get('data').strips, strips))
          .setIn(['data', 'currentRound'], action.payload.data[0].roundNumber)
          .setIn(['data', 'gameStatus'], GameStatus.roll);
      }
      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_CASE_BATTLE_REPLAY: {
      return state.setIn(['data', 'strips'], []);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL_REPLAY: {
      const battleIds = action.payload.data.map(({ battleId }) => battleId);
      if (state.get('data').battleId === battleIds[0]) {
        const strips = action.payload.data.map(({ strip, user_id }) => ({
          userId: user_id,
          strip,
        }));

        return state
          .setIn(['data', 'strips'], updatedStrip(state.get('data').strips, strips))
          .setIn(['data', 'currentRound'], action.payload.data[0].roundNumber)
          .setIn(['data', 'gameStatus'], GameStatus.roll);
      }
      return state;
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE: {
      const {
        rounds,
        finished_at,
        players,
        players_count,
        players_in_team,
        id,
        cases,
        cases_count,
      } = action.payload.game;
      const currentRound = rounds.length / players_count;
      const gameStatus = finished_at
        ? GameStatus.finish
        : players_count !== players.length
        ? GameStatus.waiting
        : rounds.length > 0
        ? GameStatus.roll
        : GameStatus.start;

      let strips: Strips[] = [];

      const objectTypeGamesForSingleWinner: { [key: number]: Partial<CaseBattleType> } = {
        2: '2',
        3: '3',
        4: '4',
      };

      const battleType =
        players_in_team === 2 ? '2 x 2' : objectTypeGamesForSingleWinner[players_count];

      if (rounds) {
        if (gameStatus === GameStatus.start || gameStatus === GameStatus.waiting) {
          strips = rounds
            .slice(rounds.length - players_count)
            .map(value => ({ userId: value?.user_id, strip: value.strip }));
        } else {
          const prevStrips = rounds
            .slice(rounds.length - players_count * 2)
            .map(value => ({ userId: value?.user_id, strip: value.strip }));
          const currentStrip = rounds
            .slice(rounds.length - players_count)
            .map(value => ({ userId: value?.user_id, strip: value.strip }));

          strips = updatedStrip(prevStrips, currentStrip);
        }
      }
      const rouletteInfo: DefaultRouletteInfo = {
        battleId: id,
        cases: cases,
        playerCount: players_count,
        playerInTeam: players_in_team,
        lastRound: cases_count,
        currentRound: currentRound,
        battleType: battleType,
        gameStatus,
        strips,
      };

      return state.set('data', rouletteInfo);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ROULETTE_DATA: {
      return new initState();
    }
    default: {
      return state;
    }
  }
};

export const ActionRemoveRouletteAnimationStatuses = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ROULETTE_ANIMATION_STATUSES,
});

export const actionAnimationStatusAfterRoll = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ANIMATION_STATUS_AFTER_ROLL,
});

export const actionAnimationFinish = () => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_ANIMATION_STATUS_FINISH,
});

const initStateAnimationStatusesReducer = Record<AnimationRouletteStatusesReducer>({
  linesStatus: null,
  battleId: -1,
});
//TODO; make it easier
export const animationRouletteStatusesReducer: Reducer<
  Record<AnimationRouletteStatusesReducer>,
  AnimationRouletteStatusesActions
> = (state = new initStateAnimationStatusesReducer(), action) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_RESPONSE_CASE_BATTLE: {
      return state
        .set('battleId', action.payload.game.id)
        .set('linesStatus', RouletteStatuses.beforeRoll);
    }
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL:
    case CASE_BATTLE_ACTION_TYPE.ACTION_BATTLE_ROLL_REPLAY: {
      if (action.payload.data[0].battleId === state.get('battleId')) {
        return state.set('linesStatus', RouletteStatuses.roll);
      }
      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_ANIMATION_STATUS_AFTER_ROLL: {
      if (state.get('linesStatus').length > 0) {
        //NOTE: need this condition for case when we will have some unexpected renders
        return state.set('linesStatus', RouletteStatuses.afterRoll);
      }
      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_ANIMATION_STATUS_FINISH: {
      if (state.get('linesStatus').length > 0) {
        //NOTE: need this condition for case when we will have some unexpected renders

        return state.set('linesStatus', RouletteStatuses.finishAnimation);
      }
      return state;
    }

    case CASE_BATTLE_ACTION_TYPE.ACTION_REMOVE_ROULETTE_ANIMATION_STATUSES: {
      return state.delete('battleId').delete('linesStatus');
    }
    default: {
      return state;
    }
  }
};

export const actionSetCaseBattleSoundType = (type: string) => ({
  type: CASE_BATTLE_ACTION_TYPE.SET_SOUND_TYPE,
  payload: type,
});

export const soundTypeReducer = (
  state: string = getSounds()['case-battle'],
  action: ActionSetSoundType
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.SET_SOUND_TYPE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
