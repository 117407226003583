import { FilterDialog as FilterDialogConfig } from 'games/CaseGame';
import { DIALOG } from 'dialogs/dialog-combiner';
import FilterDialog from './sort-dialog';
import { transitions } from 'core/Modals';

DIALOG.add(FilterDialogConfig.SORT_DIALOG, {
  component: FilterDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
