import { SoundsDialog } from './sounds-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.SOUNDS_SETTINGS, {
  component: SoundsDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
