import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import PrivateRoute from 'decorators/PrivateRoute.decorator';
import { AnimatedRouter } from '@components/animated-router';
import { RefillSystemsPage } from './refill-systems';
import { RefillMethodPage } from './refill-method';
import { GiftCardsPage } from './gift-cards-page';
import { ItemPayPage } from './item-pay-page';
import { actionReferralBonus, attachListener, detachListener } from '../duck';

const _RefillRouter: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(attachListener());
    dispatch(actionReferralBonus());
    return () => {
      dispatch(detachListener());
    };
  }, [dispatch]);

  return (
    <>
      <AnimatedRouter>
        <Route path={`${match.url}/:type/giftcards`} component={GiftCardsPage} />
        <Route
          path={`${match.url}/:type/:name`}
          render={({ match }) =>
            match.params.name === 'itempay' ? <ItemPayPage /> : <RefillMethodPage />
          }
        />
        <Route path={match.url} component={RefillSystemsPage} />
      </AnimatedRouter>
      <div id="pay-portal" />
    </>
  );
};

export const RefillRouter = PrivateRoute(_RefillRouter);
