import React, { useRef } from 'react';
import styled from 'styled-components';
import { PublicationItems } from './publication-items';
import { PublicationMetadata } from './publication-metadata';
import { PublicationController } from './publication-controller';

export const PublicationPage = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div className="content dark-layout">
        <Container ref={parentRef}>
          <PublicationMetadata />
          <PublicationItems parentRef={parentRef} />
        </Container>
      </div>
      <PublicationController />
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content minmax(min-content, 1fr);
  min-height: 100%;
  overflow: auto;
`;
