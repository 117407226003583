import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IDialogComponent } from 'core/interfaces';
import { useUserPermission } from '../../core/User';
import { SettingUpStepperContent } from './setting-up-stepper-content';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';
import { Container } from './setting-up-steam-account-dialog.styles';

interface ISettingUpSteamAccountDialog extends IDialogComponent<boolean> {}

export const SettingUpSteamAccountDialog: FC<ISettingUpSteamAccountDialog> = ({
  handleCloseModal,
}) => {
  const { t } = useTranslation('steam-settings');
  const { permissions } = useUserPermission();
  const error =
    permissions?.canTrade?.error ||
    permissions?.canSteamAPI?.error ||
    permissions?.error ||
    'private inventory';

  //NOTE: right now backend doesn`t send any errors for private inventory, so if permission?.canTrade.successful === false && permissions?.error === false && permission?.canTrade.error === false,
  //mean that user steam inventory is private

  const { control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      step: error,
    },
  });

  useEffect(() => {
    if (error) {
      setValue('step', error);
    } else {
      handleCloseModal();
    }
  }, [error, setValue, handleCloseModal]);

  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <Controller name="step" control={control} render={SettingUpStepperContent} />
    </Container>
  );
};
