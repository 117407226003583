import React from 'react';
import styled from 'styled-components';
import { useTimerEvent } from 'core/hooks';
import { useGameRoundNumbers, useGameUntilTime } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

export const TimeToRaffling = () => {
  const { t } = useTranslation('x50');
  const untilTime = useGameUntilTime();
  const [until] = useTimerEvent(untilTime);
  const [current] = useGameRoundNumbers();
  return (
    <Container>
      <span>{t('Pick a color')}</span>
      <span style={{ opacity: +!current }}>
        {t('Bets are being accepted')} (
        {until.m.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
        :
        {until.s.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
        )
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.25rem;

  & span {
    text-align: center;

    &:first-of-type {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: var(--color-white);
    }

    &:last-of-type {
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 0.286px;
      color: var(--color-gray-200);
      transition: all 100ms ease-in 1s;
    }
  }
`;
