import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getHistory } from '../selectors';

import { EmptyHistory, HistoryContent, LoadingHistory } from '../components';
import { Controls } from './Controls';

import { IHistoryItem, IRecordHistory } from '../interfaces/reducer.history.interface';

interface IHistory {
  gameType: number;
}

export const History = React.memo<IHistory>(({ gameType }) => {
  const data: IRecordHistory = useSelector(getHistory, shallowEqual);

  return (
    <>
      <div className="content dark-layout">
        {data.loading && <LoadingHistory />}
        {data.loaded && !data.entities.length && <EmptyHistory />}
        {!!data.entities.length &&
          data.entities.map((item: IHistoryItem) => (
            <HistoryContent key={item.gameId} {...item} gameTypeId={data.gameTypeId} />
          ))}
      </div>

      <Controls gameType={gameType} />
    </>
  );
});
