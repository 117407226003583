import reducer, { eventsTypes } from './duck';
import './locales';

export { GameRouter as WheelRouter } from './containers';
// export const WheelRouter = lazy(() => import('./containers').then(module => ({ default: module.GameRouter })))
export const fortuneWheelModule = {
  moduleName: 'wheel',
  moduleId: 15,
  path: '/wheel',
  type: 'solo-pve',
  reducer,
  eventsTypes,
};
