import React, { FC } from 'react';
import { Container, Point } from './pagination.styles';

interface PaginationProps {
  currentSlide: number;
}

export const Pagination: FC<PaginationProps> = ({ currentSlide }) => (
  <Container>
    {[0, 1, 2, 3].map(index => (
      <Point key={index} isActive={index === currentSlide} />
    ))}
  </Container>
);
