export default {
  //CryptoPage title
  crypto: 'Вывод криптовалюты',
  cryptocurrency: 'Криптовалюта',
  from: 'от',
  //
  'Email is required': 'Нужен email',
  'Add an email': 'Добавить email',
  'email-form-status_confirmed': 'подтверждена',
  'email-form-status_not confirmed': 'ожидает подтверждения',
  'email-form-status_empty': 'не подтверждена',
  Withdraw: 'Вывести',
  'Add a card': 'Добавить карту',
  'Choose a card:': 'Выберите карту:',
  'Withdrawal amount:': 'Сумма вывода:',
  'I confirm the accuracy of the details and agree with the amount of payment':
    'Подтверждаю правильность реквизитов и согласен с суммой выплаты',
  'Activation link was sent to your email': 'Письмо с активацией было выслано на указанный адрес',
  'Send an email with a link': 'Отправить письмо с ссылкой',
  'to {{ address }}': 'на {{ address }}',
  'To complete the withdrawal, you have to add an email to which you will receive a confirmation link':
    'Для завершения вывода нужно привязать email, на который придёт письмо с подтверждением',
  'Almost done!': 'Почти готово!',
  'Email with a link to complete the withdrawal has been sent to {{ address }}':
    'Письмо с ссылкой для совершения операции отправлено на {{ address }}',
  'Please note that the amount required for withdrawal should remain on your balance until the withdrawal is confirmed':
    'Обратите внимание, что необходимая для вывода сумма должна оставаться у вас на балансе вплоть до подтверждения вывода средств.',
  'If at the time of confirmation you do not have the required amount on the balance - the withdrawal will be canceled':
    'Если на момент подтверждения у вас не будет необходимой суммы на балансе - вывод будет отменён.',
  'You will receive:': 'Вы получите:',
  'Rate:': 'Курс:',
  'To improve the exchange rate, you need to play more':
    'Чтобы улучшить курс, необходимо больше играть.',
  'To improve the rate, bet on:': 'Для улучшения курса сделайте ставки на:',
  'Subscribe to email notifications': 'Подписаться на email-рассылку',
  'Select payment provider:': 'Выберите систему пополнения:',
  'not confirmed': 'Ожидание подтверждения...',
  confirmed: 'Привязана',
  Refresh: 'Обновить',
  'Withdrawal sum': 'Сумма вывода',
  'Select the network fee': 'Выберите вознаграждение сети',
  'Estimated transfer time:': 'Ожидаемое время перевода:',
  'Amount in {{title}}:': 'Сумма в {{title}}:',
  'Commission:': 'Комиссия:',
  'Receive in {{title}}:': 'Получите в {{title}}:',
  'No commission': 'Без комиссии',
  commission: 'Возможна комисси',

  'Ultra Low': 'Очень медленно',
  Low: 'Медленно',
  Medium: 'Средне',
  High: 'Быстро',

  'Enter your wallet': 'Номер {{wallet}} кошелька',
  'wallet description': 'Введите свой {{wallet}} кошелёк',
  'wallet incorrect': 'Неверный номер кошелька',

  btc_title: 'Адрес Bitcoin кошелька',
  btc_type: 'text',
  btc_description: '',
  btc_incorrect: 'Неверный номер кошелька',

  bitcoin_title: 'Адрес Bitcoin кошелька',
  bitcoin_type: 'text',
  bitcoin_description: '',
  bitcoin_incorrect: 'Неверный номер кошелька',

  doge_title: 'Адрес Doge кошелька',
  doge_type: 'text',
  doge_description: '',
  doge_incorrect: 'Неверный номер кошелька',

  eth_title: 'Адрес Ethereum кошелька',
  eth_type: 'text',
  eth_description: '',
  eth_incorrect: 'Неверный номер кошелька',

  //pix
  accountType: 'Тип аккаунта',
  documentType: 'Тип документа',
  document: 'Документ',
  taxAccount: 'Налоговый счёт',
  fullName: 'Полное имя',
  emailAddress: 'Почтовый адрес',
  incorrectEmail: 'Неверный почтовый адрес',

  //

  'Please enter an email address to withdraw funds.':
    'Пожалуйста, укажите адрес электронной почты, чтобы вывести средства.',

  //Verification
  Identification: 'Идентификация',
  'For identification, send us a photo that contains:':
    'Для идентификации личности, отправьте нам фотографию, что содержит:',
  'Your face': 'Ваше лицо',
  'Passport with a clearly visible photo and readable full name':
    'Паспорт с чётко видной фотографией и читаемыми ФИО',
  'Bank card with which the balance was replenished (the last 4 digits of the card and the full name of the card holder should be visible)':
    'Банковскую карточку, с которой производилось пополнение баланса (должны быть видны последние 4 цифры карты и ФИО владельца)',
  'Paper sheet with your handwritten FastID': 'Бумажный листок с вашим, написанным от руки, FastID',
  'Why is identification necessary?': 'Зачем нужна идентификация?',
  'Identification required': 'Требуется идентификация',
  'To withdraw funds you need to confirm your identity':
    'Для вывода средств вам необходимо подтвердить свою личность',
  'Identification request sent': 'Запрос на идентификацию отправлен',
  'Your data is being verified. Please wait for our support to verify your data':
    'Ваши данные проходят проверку. Пожалуйста, подождите пока наша поддержка подтвердит ваши данные',
  'Identification failed': 'Идентификация не пройдена',
  'Reasons for refusal has been sent to your email': 'Причины отказа были отправлены на вашу почту',
  'Send photo': 'Отправить фото',
  'Update photo': 'Обновить фотографию',

  'identification title': 'Требуется идентификация',
  'identification title_verified': 'Требуется идентификация',
  'identification title_pending': 'Запрос на идентификацию отправлен',
  'identification title_failed': 'Идентификация не пройдена',

  'identification description': 'Для вывода средств вам необходимо подтвердить свою личность',
  'identification description_verified':
    'Для вывода средств вам необходимо подтвердить свою личность',
  'identification description_pending':
    'Ваши данные проходят проверку. Пожалуйста, подождите пока наша поддержка подтвердит ваши данные',
  'identification description_failed': 'Причины отказа были отправлены на вашу почту',
};
