import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { slotType } from '../../slot.utils';
import { payTableConfig } from './pay-table.config';
import payTableIcon from '../../assets/pay-table.png';

export const Rules = () => {
  const { t } = useTranslation();
  return (
    <div className="content dark-layout">
      <PayTableHeader className="tablet">
        <PayTableInner>
          <PayTableIcon src={payTableIcon} />
          {t('Paytable')}
        </PayTableInner>
      </PayTableHeader>
      <Container className="tablet">
        {payTableConfig.map((item: any, i) => (
          <SlotContainer key={item.slot[0]}>
            <Wrapper alignIcon={item.slot.length > 1}>
              {item.slot.map((icon: any, i: any) => (
                <SlotIcon
                  key={icon}
                  alignIcon={item.slot.length > 1}
                  src={`https://d2lomvz2jrw9ac.cloudfront.net/slot-assets/csgo/items/${
                    slotType[item.slot[i]]
                  }.png`}
                />
              ))}
            </Wrapper>
            <SlotInfo>
              {Array.isArray(item.prize) &&
                item.prize?.map((prize: any) => (
                  <PrizeContainer key={prize.prizeFactor}>
                    <PrizeFactor>{prize.prizeFactor}</PrizeFactor>
                    <PrizeValue
                      bgColor={prize.bgColor}
                      color={prize.color}
                    >{`x ${prize.prizeValue}`}</PrizeValue>
                  </PrizeContainer>
                ))}

              {item.description && <Description>{t(item.description)}</Description>}
            </SlotInfo>
          </SlotContainer>
        ))}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 8px 18px;
  background-color: #212f35;
`;

const PayTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 13px 16px;
  background: #212f35;

  font-size: 16px;
  letter-spacing: 0.5px;
  color: var(--color-white);
`;

const PayTableInner = styled.div`
  display: flex;
  align-items: center;
`;

const PayTableIcon = styled.img`
  width: 23px;
  height: 23px;
  object-fit: contain;
  margin-right: 15px;
`;

//Slots
const SlotContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  margin: 10px 0;
  background-color: var(--color-dark-500);

  align-items: stretch;
`;

const SlotIcon = styled.img<any>`
  width: 100%;
`;

const Wrapper = styled.div<any>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background: #0e1619;
  flex-basis: ${({ alignIcon }) => (alignIcon ? '100%' : '35%')};
  min-height: 100px;

  justify-content: space-around;
  align-items: center;
  position: relative;

  ${({ alignIcon }) =>
    alignIcon &&
    `&:before,
  &:after {
    content: '+';
  }`};

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: var(--yellow);
    transform: translateY(-50%);
  }

  &:before {
    left: 30%;
  }

  &:after {
    right: 30%;
  }
`;

const SlotInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  align-self: stretch;

  padding: 10px;
`;

const PrizeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const PrizeFactor = styled.div`
  flex-basis: 20%;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: var(--color-white);
  text-align: center;
`;

const PrizeValue = styled.div<any>`
  min-width: 80px;
  padding: 3px 12px;
  margin: 3px 0;
  background-color: ${({ bgColor }) => bgColor || '#212f35'};

  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${({ color }) => color || 'var(--color-white)'};
  text-align: center;
`;

const Description = styled.div`
  padding: 8px 0 13px;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--color-white);
`;
