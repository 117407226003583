import React, { FC, useCallback, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useUserBindingProvider } from 'core/User';
import { Button } from '@components/Common';
import { Container, InputGroup } from './telegram-linking-dialog.styles';
import { IDialogComponent } from 'core/interfaces';

interface ITelegramLinkingDialog extends IDialogComponent<boolean> {}

export const TelegramLinkingDialog: FC<ITelegramLinkingDialog> = ({ handleCloseModal }) => {
  const { provider, handleConfirmBinding } = useUserBindingProvider('telegram');

  const { t } = useTranslation();
  const [code, setCode] = useState<string[]>(Array.from({ length: 6 }, () => ''));
  const inputGroup = useRef<HTMLInputElement | null>(null);

  const handleOnChange = useCallback(
    i => (ev: any) => {
      ev.persist();
      const keyCode = ev.target.value.charCodeAt();
      if (keyCode >= 48 && keyCode <= 57) {
        setCode(state =>
          state.map((item, k) => (k === i ? ev.target.value.substring(0, 1) : item))
        );
      }
    },
    [setCode]
  );

  const handleKeyUp = useCallback(
    (i: number) => (ev: any) => {
      ev.persist();
      if (inputGroup.current === null) return;
      const elm: Element[] = Array.from(inputGroup.current.children);
      const keyCode = ev.target.value.charCodeAt();

      if (ev.keyCode === 8) {
        setCode(state => state.map((item, k) => (k === i ? '' : item)));
      }

      if (ev.keyCode === 8 && elm[i - 1]) {
        setTimeout(() => {
          (elm[i - 1] as HTMLInputElement).focus();
        }, 0);
        return;
      }
      if (ev.keyCode !== 8 && keyCode && elm[i + 1]) {
        setTimeout(() => {
          (elm[i + 1] as HTMLInputElement).focus();
        }, 0);
        return;
      }
    },
    []
  );

  const handleOnPast = useCallback((ev: any) => {
    const code = ev.clipboardData.getData('text/plain').slice(0, 6);
    setCode(() => code.split(''));
    ev.target.blur();
  }, []);

  return (
    <Container>
      <div className="modal-header">
        <span className="modal-title">{t('Connect Telegram')}</span>
        <span className="modal-description">
          {t('Write to our telegram bot, press start and it will send you a code')}
        </span>
      </div>

      <div className="modal-body">
        <a href="//t.me/csgo_fastbot" target="_blank" rel="noopener noreferrer">
          @csgo_fastbot
        </a>
        <span className="nickname-copy">
          <CopyToClipboard text="@csgo_fastbot">
            <i className="icon-content-copy" />
          </CopyToClipboard>
        </span>
      </div>
      <div className="modal-footer">
        <Button
          className="link-primary prepend-icon"
          as="a"
          target="_blank"
          href="//t.me/csgo_fastbot"
        >
          {t('Go to Telegram')}
        </Button>
        <div>
          <span className="modal-description">{t('Enter received code in bellow input:')}</span>
          <InputGroup ref={inputGroup}>
            {code.map((item: string, i: number) => (
              <input
                key={i}
                type="tel"
                maxLength={1}
                value={item}
                onChange={handleOnChange(i)}
                onKeyUp={handleKeyUp(i)}
                onPaste={handleOnPast}
              />
            ))}
          </InputGroup>
          <span className="modal-description modal-description_error">{t(provider?.status)}</span>
        </div>
        <Button
          className="primary full"
          disabled={!code.every(item => !!item)}
          onClick={handleConfirmBinding(code.join(''))}
        >
          {t('Connect')}
        </Button>
        <Button className="small full link-light" onClick={handleCloseModal}>
          {t('Cancel')}
        </Button>
      </div>
    </Container>
  );
};
