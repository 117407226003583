import { ReactComponent as Gift } from './gift.svg';
import background from './background.svg';
import { ReactComponent as FastCoin } from './fastcoin.svg';
import { ReactComponent as FastCoinGreen } from './fastcoin-green.svg';
import { ReactComponent as FastCoinWhite } from './fastcoin-white.svg';
import SihLogo from './sihLogo.png';

export const RefillIcons = {
  Gift,
  background,
  FastCoin,
  FastCoinGreen,
  FastCoinWhite,
  SihLogo,
};
