import React from 'react';
import { AppBar } from '@components/bars';
import { ReferralPreview } from './referral-preview';
import { ReferralQuestionsAndAnswers } from './referral-questions-and-answers';

export const ReferralLandingPage = () => {
  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <ReferralPreview />
        <ReferralQuestionsAndAnswers />
      </div>
    </>
  );
};
