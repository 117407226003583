export default {
  winning: 'Winning',
  line: 'Zeile',
  lines: 'Zeilen',
  total: 'Gesamt',
  Autoplay: 'Autoplay',
  Start: 'Start',
  Paytable: 'Zahltisch',
  'Recent wins': 'Letzte Gewinne',
  'Your games': 'Ihre Spiele',
  'Bonus game': 'Bonusspiele',
  'Slot works regardless of location': 'Spielautomat funktioniert ortsunabhängig',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Kann jeden Slot in der Zeile ersetzen (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    'Sammeln Sie alle drei Slots und erhalten Sie 10 Bonusspiele!',
  'Total won': 'Insgesamt gewonnen',
  'Bonus games': 'Nicht genug',
};
