import React, { FC, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { IDialogComponent } from 'core/interfaces';
import { getDataUploadAvatar, getFileAvatar } from 'modules/PersonalArea/selectors';
import { fetchUserAvatar } from 'core/User/ducks/duck';
import {
  BackBtn,
  Confirm,
  Container,
  CropWrap,
  Header,
  Title,
} from './profile-settings-avatar-dialog.styles';

interface IProfileSettingsDialog extends IDialogComponent<boolean> {}

export const ProfileSettingsAvatarDialog: FC<IProfileSettingsDialog> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { file } = useSelector(getFileAvatar, shallowEqual);
  const { imageToken, signedRequest } = useSelector(getDataUploadAvatar, shallowEqual);
  const cropperRef = useRef<ReactCropperElement>(null);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      cropperRef.current?.cropper
        .getCroppedCanvas()
        .toBlob(
          res => dispatch(fetchUserAvatar({ imageToken, signedRequest, blob: res })),
          'image/png',
          1
        );

      handleCloseModal();
    }
  };

  return (
    <>
      <Header>
        <BackBtn onClick={handleCloseModal}>
          <i className="icon-arrow" />
        </BackBtn>
        <span>{t('Avatar')}</span>
      </Header>
      <Container>
        <Title>{t('Crop warning')}</Title>
        <CropWrap>
          {file && (
            <Cropper
              style={{ width: '100%', height: '100%' }}
              initialAspectRatio={1}
              aspectRatio={1}
              src={file}
              center
              ref={cropperRef}
              viewMode={1}
              guides={true}
              minCropBoxHeight={100}
              minCropBoxWidth={100}
              background={false}
              zoomOnWheel={false}
            />
          )}
        </CropWrap>
        <Confirm onClick={getCropData}>{t('Confirm')}</Confirm>
      </Container>
    </>
  );
};
