export default {
  line: 'Línea',
  lines: 'Líneas',
  total: 'Total',
  Autoplay: 'Autojuego',
  Start: 'Empezar',
  Paytable: 'Tabla de pagos',
  'Recent wins': 'Victorias recientes',
  'Your games': 'Sus juegos',
  'Bonus game': 'Juego adicional',
  'Slot works regardless of location': 'El sector funciona independientemente de ubicación',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Se puede cambiar cualquier sector de la línea (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!':
    '¡Acumule todos los tres sectores y obtenga 10 juegos adicionales!',
  'Total won': 'Total ganado',
  'Bonus games': 'Juegos adicionales',
};
