import dayjs from 'dayjs';
import { chatsActions, MessageActionTypes } from './interfaces/actions.interface';

import { IMessage, IMessages } from './interfaces/reducer.interface';

import { IPointer, ISendMessage, ISendSticker } from './interfaces/services.interface';

import { IDispatch, IEventsTypes } from 'core/rootInterfaces/root.interface';

import { ISocketService } from 'services/interfaces/services.interface';

import { OrderedMap } from 'immutable';
import { combineReducers, Reducer } from 'redux';
import { getUser } from 'core/User/selectors';
import { getAppFilterRegex } from 'core/AppShell/selectors';
import { i18n } from 'locales';
import SoundService from 'services/sound.service';

const sound = new SoundService();

const MessageRecord = OrderedMap<IMessage>({});

const messages: Reducer<OrderedMap<string, IMessage>> = (
  messagesState = MessageRecord,
  action: MessageActionTypes
) => {
  switch (action.type) {
    case chatsActions.UPDATE_CHAT:
      return messagesState.merge(action.payload);

    case chatsActions.REMOVE_CHAT_MESSAGE: {
      if (!!action.payload.msgIds[0]) {
        return messagesState.deleteAll(action.payload.msgIds);
      }
      return messagesState.filter(message => message.userId !== action.payload.userId);
    }

    case chatsActions.CHANGE_ROOM:
      return messagesState.clear();

    default:
      return messagesState;
  }
};

const reducer = combineReducers({
  messages,
});

export default reducer;

// action creators

const updateChat = (messages: IMessage[]) => (dispatch: IDispatch, getState: any) => {
  const user = getUser(getState());
  const filterRegex = getAppFilterRegex(getState());
  const regex = new RegExp(filterRegex, 'gi');

  const formatData: IMessages = messages.reduce(
    (acc: IMessages, message: IMessage) => ({
      ...acc,
      [`${message.userId}:${message.time}`]: {
        ...message,
        userName: message.userName.replace(regex, '***'),
        my: user.id === message.userId,
        time: dayjs(message.time).format('HH:mm'),
        messageId: `${message.userId}:${message.time}`,
      },
    }),
    {}
  );

  dispatch({ type: chatsActions.UPDATE_CHAT, payload: formatData });
};

const addChatMessage = (message: IMessage) => (dispatch: IDispatch, getState: any) => {
  const user = getUser(getState());
  const filterRegex = getAppFilterRegex(getState());
  const regex = new RegExp(filterRegex, 'gi');

  const formatData: IMessages = {
    [`${message.userId}:${message.time}`]: {
      ...message,
      userName: message.userName.replace(regex, '***'),
      my: user.id === message.userId,
      time: dayjs(message.time).format('HH:mm'),
      messageId: `${message.userId}:${message.time}`,
    },
  };

  if (user.id !== message.userId) {
    sound.play('commonSounds', 'Chat message receiving');
  }

  dispatch({ type: chatsActions.UPDATE_CHAT, payload: formatData });
};

export const sendMessage = (message: ISendMessage) => (
  _dispatch: IDispatch,
  _getState: any,
  { socket }: ISocketService
) => {
  socket.emit('chat:message:add', message);
};

export const sendSticker = (requestParams: ISendSticker) => (
  _dispatch: IDispatch,
  _getState: any,
  { socket }: ISocketService
) => {
  socket.emit('chat:message:add', requestParams);
};

export const removeMessage = (pointer: IPointer) => ({
  type: chatsActions.REMOVE_CHAT_MESSAGE,
  payload: pointer,
});

export const changeRoom = (name: string) => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  let locale = i18n.language;
  if (!/en|ru|de|pl|ro|sv|zh-cn|zn-tw|es|tr|pt|th/.test(locale)) {
    locale = 'en';
  }
  const path = `chat:${locale}:${name}`;
  dispatch({ type: chatsActions.CHANGE_ROOM });
  socket.emit('chat:switch', path);
};

export const eventsTypes: IEventsTypes[] = [
  { event: 'chat:room:join', action: updateChat },
  { event: 'chat:messages:remove', action: removeMessage },
  { event: 'chat:message', action: addChatMessage },
];
