import { combineEpics } from 'redux-observable';
import {
  eventDataEpic,
  motivatorEventEpic,
  pickUpPrize,
  eventCategoryEpic,
} from './default-event-duck/default-event.duck';

import {
  leaderRaceBalance,
  leaderRaceQuestsEpic,
  takeRewardEpic,
  leaderRaceCasesEpic,
} from './leader-race-duck';

import { leaderRaceEpic } from './event-results-duck';

export const comicsHeroesEventEpic = combineEpics(
  eventDataEpic,
  motivatorEventEpic,
  pickUpPrize,
  eventCategoryEpic,
  takeRewardEpic,
  leaderRaceBalance,
  leaderRaceCasesEpic,
  leaderRaceQuestsEpic,
  leaderRaceEpic
);
