import SoundService from 'services/sound.service';

const sound = new SoundService();

export interface ICardItem {
  card: string;
  key: string;
  i: number;
  delay: number;
  rotate: string;
  left: string;
  top: string;
}

const sleep = (time: number) => new Promise(res => setTimeout(res, time));

export const delays = {
  playerHand: [0, 3000, 6000],
  bankHand: [1200, 4200, 7200],
};

export const lefts = {
  playerHand: ['48%', '78%', '8%'],
  bankHand: ['0%', '30%', '68%'],
};

export const rotates = {
  playerHand: ['0deg', '0deg', '90deg'],
  bankHand: ['0deg', '0deg', '-90deg'],
};

export const tops = {
  playerHand: ['0%', '0%', '20%'],
  bankHand: ['0%', '0%', '20%'],
};

export const transitions = {
  playerHand: {
    from: ({ rotate, left }: ICardItem) => ({
      top: '1000%',
      left,
      opacity: 1,
      transform: `rotateY(0deg) rotate(${rotate})`,
    }),
    enter: ({ delay, rotate, left, top }: ICardItem) => async (next: any) => {
      await sleep(delay);
      sound.play('Baccarat', 'Dispense the cards');
      await next({
        top,
        left,
        opacity: 1,
        transform: `rotateY(0deg) rotate(${rotate})`,
      });
      await sleep(200);
      sound.play('Baccarat', 'Flip the cards');
      await next({ transform: `rotateY(180deg) rotate(${rotate})` });
    },
    leave: ({ rotate, left, top }: ICardItem) => ({
      top,
      left,
      opacity: 0,
      transform: `rotateY(180deg) rotate(${rotate})`,
    }),
  },
  bankHand: {
    from: ({ rotate, left }: ICardItem) => ({
      top: '1000%',
      left,
      opacity: 1,
      transform: `rotateY(0deg) rotate(${rotate})`,
    }),
    enter: ({ delay, rotate, left, top }: ICardItem) => async (next: any) => {
      await sleep(delay);
      sound.play('Baccarat', 'Dispense the cards');
      await next({
        top,
        left,
        opacity: 1,
        transform: `rotateY(0deg) rotate(${rotate})`,
      });
      await sleep(200);
      sound.play('Baccarat', 'Flip the cards');
      await next({ transform: `rotateY(180deg) rotate(${rotate})` });
    },
    leave: ({ rotate, left, top }: ICardItem) => ({
      top,
      left,
      opacity: 0,
      transform: `rotateY(180deg) rotate(${rotate})`,
    }),
  },
};
