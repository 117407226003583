import { isNull } from 'lodash';

export function generateURL<T extends object>(params: T, url: string): string {
  const formedUrl = new URL(url);
  if (params) {
    for (const key in params) {
      if (!isNull(params[key])) {
        formedUrl.searchParams.append(key, `${params[key]}`);
      }
    }
  }
  return formedUrl.toString();
}
