import React, { FC } from 'react';
import styled from 'styled-components';
import { SkeletonElement } from '@UIkit';
import { CurrencyText } from '@components/currency';
import { useOrderControllers, useShopElement } from '../../../../hooks';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface IShopElement {
  identifier: number;
}

interface IShopElementStyle {
  rarity?: string;
  selected?: boolean;
}

export const ShopElement: FC<IShopElement> = ({ identifier }) => {
  const { item } = useShopElement(identifier);
  const { has, toggleElement } = useOrderControllers(item?.id);

  if (!item) return <Container className="skeleton" />;

  return (
    <Container rarity={item?.baseItem.color} selected={has} onClick={toggleElement(item)}>
      <div className="indicators">
        <span className="selected">
          <i className="icon-checked-fill" />
        </span>
      </div>
      <div />
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item?.baseItem.icon, 140, 90)} 1x, ${skinItemImage(
            item?.baseItem.icon,
            280,
            170
          )} 2x`}
          src={`${skinItemImage(item?.baseItem.icon, 140, 90)}`}
          alt=""
        />
      </div>
      <span className="display-name">{item?.baseItem.name}</span>
      <span className="display-price">
        <CurrencyText value={item.price} />
      </span>
    </Container>
  );
};

const Container = styled(SkeletonElement)<IShopElementStyle>`
  display: grid;
  grid-template-rows: 1rem 1fr min-content min-content;
  grid-gap: 0.125rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 0.75rem;
  border-radius: var(--size-radius-m);
  background: ${({ selected }) => (selected ? 'var(--color-white-gray)' : 'var(--color-dark-200)')};
  box-shadow: inset 0 0.125rem 0 ${({ rarity }) => rarity || 'transparent'};
  transition: background 150ms ease-in-out;

  & .thumbnail {
    overflow: hidden;

    & img {
      width: 100%;
      height: 80%;
      object-fit: contain;
    }
  }

  & .display-name,
  & .display-price {
    line-height: 1rem;
    color: var(--color-white);
    text-align: center;
  }

  & .display-name {
    font-size: 0.8125rem;
    letter-spacing: 0.636923px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .display-price {
    font-size: 0.75rem;
    letter-spacing: 0.1px;
  }

  & .indicators {
    display: grid;
    grid-template-columns: 1rem;
    grid-auto-rows: 1rem;
    grid-gap: 0.5rem;
    grid-auto-flow: row;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;

    & .selected {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
      background: var(--primary);
      transform: ${({ selected }) => (selected ? 'scale(1)' : 'scale(0)')};
      transition: transform 150ms ease-in-out;
      border-radius: 50%;

      & i {
        font-size: 0.5rem;
        color: var(--color-dark-100);
      }
    }
  }
`;
