export const splitString = (str: string): string => {
  if (str.includes(' ')) {
    const splitName = str.split(' ');
    return splitName[0].charAt(0) + splitName[1].charAt(0);
  } else if (str.includes('-')) {
    const splitName = str.split('-');
    return splitName[0].charAt(0) + splitName[1].charAt(0);
  } else {
    return str.charAt(0);
  }
};
