import { BoardTypes } from './board-types.enum';
import { IGame, IGameBets, IGameHistory } from './reducer.interfaces';

export enum BaccaratActionTypes {
  BACCARAT_ATTACH_LISTENERS = 'app/baccarat/BACCARAT_ATTACH_LISTENERS',
  BACCARAT_DETACH_LISTENERS = 'app/baccarat/BACCARAT_DETACH_LISTENERS',

  UPDATE_GAME = 'app/baccarat/UPDATE_GAME',
  UPDATE_BETS = 'app/baccarat/UPDATE_BETS',
  UPDATE_HISTORY = 'app/baccarat/UPDATE_HISTORY',

  FETCH_CREATE_PARTICIPATE = 'app/baccarat/FETCH_CREATE_PARTICIPATE',
  SET_MY_BET = 'app/baccarat/SET_MY_BET',

  UPDATE_PHASE_GAME = 'app/baccarat/UPDATE_PHASE_GAME',
  UPDATE_PHASE_TIMEOUT = 'app/baccarat/UPDATE_PHASE_TIMEOUT',
  UPDATE_PHASE_BET = 'app/baccarat/UPDATE_PHASE_BET',
}

interface IUpdateGame {
  type: BaccaratActionTypes.UPDATE_GAME;
  payload: IGame;
}

interface IUpdatePhaseBet {
  type: BaccaratActionTypes.UPDATE_PHASE_BET;
}

interface IUpdatePhaseWon {
  type: BaccaratActionTypes.UPDATE_PHASE_TIMEOUT;
}

interface IUpdatePhaseGame {
  type: BaccaratActionTypes.UPDATE_PHASE_GAME;
  payload: IGame;
}

interface ISetMyBet {
  type: BaccaratActionTypes.SET_MY_BET;
  payload: BoardTypes[];
}

export type baccaratGameActions =
  | IUpdateGame
  | IUpdatePhaseBet
  | IUpdatePhaseWon
  | IUpdatePhaseGame
  | ISetMyBet;

interface IUpdateHistory {
  type: BaccaratActionTypes.UPDATE_HISTORY;
  payload: IGameHistory[];
}

export type baccaratHistoryActions = IUpdateHistory;

export interface IUpdateBets {
  type: BaccaratActionTypes.UPDATE_BETS;
  payload: IGameBets;
}

export type baccaratBetsActions = IUpdateBets | IUpdatePhaseBet;
