import React, { FC } from 'react';
import { List } from 'immutable';
import { UserProfileInfo, UserProfileDescription } from '@components/UserProfile';
import { IUserState } from 'core/User';
import { IExperiences } from 'core/AppShell';

interface IProfileDescription {
  user: IUserState;
  experiences: List<IExperiences>;
}

export const ProfileDescription: FC<IProfileDescription> = ({ user, experiences }) => {
  // const experienceLevel = experiences.get(Math.max(user.experience.level - 1, 0)) as IExperiences;

  // const levelProgress =
  //   ((user.experience.xp - experienceLevel.xp) /
  //     (user.experience.nextLevelXp - experienceLevel.xp)) *
  //   100;
  const levelProgress = 0;
  return (
    <>
      <UserProfileInfo userId={user.id} date_registred={user.date_registred} />
      <UserProfileDescription
        name={user.name}
        profileType={user.profileType}
        levelProgress={levelProgress}
        experience={user.experience}
        premium={user.premium}
      />
    </>
  );
};
