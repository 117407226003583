import * as React from 'react';
import { SwitchHistory } from '../components';
import { useHistoryHandles } from '../hooks';

interface IControls {
  gameType: number;
}

export const Controls: React.FC<IControls> = ({ gameType }) => {
  const switchProps = useHistoryHandles(gameType);
  return <SwitchHistory {...switchProps} gameType={gameType} />;
};
