import { CaseListItemDefault } from '@components/CaseListItemDefault';
import { useGetBattleGameStatusById, useGetCaseById } from 'games/CaseBattle/hooks';
import React from 'react';
import { ArrowContainer, Container } from './CaseListItem.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useDispatch } from 'react-redux';
import { actionRequestSuitCase } from 'games/CaseGame';
import { useDialog } from 'core/hooks';
import { CaseDetailsConfig } from 'games/CaseBattle/dialog-config/dialog-config';
import { GameStatus } from 'games/CaseBattle/constants/constants';

interface CaseListItemProps {
  id: number;
  isActive: boolean;
  battleId: number;
  width?: number;
  height?: number;
}

export const CaseListItem: React.FC<CaseListItemProps> = ({
  id,
  isActive,
  battleId,
  width = 44,
  height = 48,
}) => {
  const { caseData, isLoading } = useGetCaseById(id);
  const { status } = useGetBattleGameStatusById(battleId);
  const dispatch = useDispatch();
  const { handleOpenDialog } = useDialog(CaseDetailsConfig.CASE_DETAIL);

  const redirectHandler = () => {
    dispatch(actionRequestSuitCase(id));
    handleOpenDialog();
  };

  if (isLoading || !caseData) {
    return <Container className="skeleton" />;
  }

  return (
    <Container onClick={redirectHandler}>
      <CaseListItemDefault
        caseType={caseData.type}
        style={{ width: `${width}px`, height: `${height}px` }}
        free={!!caseData?.free}
        image={
          caseData?.free ? caseData.image ?? caseData?.backgroundImage : caseData?.backgroundImage
        }
      />
      {status === GameStatus.roll && (
        <ArrowContainer
          data-status={isActive ? 'active' : 'inactive'}
          width={width}
          height={height}
        >
          <CaseBattleIcon.ArrowIcon className="arrow" />
          <CaseBattleIcon.ArrowIcon className="arrow arrow-down-yellow" />
        </ArrowContainer>
      )}
    </Container>
  );
};
