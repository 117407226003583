import { Strips } from '../ducks/roulette-duck/roulette.interfaces';

export const updatedStrip = (prevStrips: Strips[], currentStrips: Strips[]): Strips[] => {
  if (prevStrips.length < 1) {
    return currentStrips;
  }
  return currentStrips.map(item => {
    const prevItem = prevStrips.find(el => el.userId === item.userId);

    if (!prevItem) return item;

    item.strip = [...prevItem.strip.slice(8, 11), ...item.strip.slice(3)];
    return item;
  });
};
