import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 20px;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

export const Description = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
`;
