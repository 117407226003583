import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAddBet } from '../../ducks/raffles.duck';

export const useParticipationAction = () => {
  const dispatch = useDispatch();

  const handleParticipation = useCallback(
    (raffleId: number, itemId: number) => () => {
      dispatch(fetchAddBet(raffleId, itemId));
    },
    [dispatch]
  );

  return [handleParticipation];
};
