import { IUserInfo } from 'modules/PersonalArea/interfaces/accounts.reducer.interface';

export enum accountsActionsTypes {
  FETCH_PROFILE = 'app/modules/accounts/FETCH_PROFILE',
  FETCH_PROFILE_RESPONSE = 'app/modules/FETCH_PROFILE_RESPONSE',
  FETCH_RACE_STATISTICS = 'app/modules/accounts/FETCH_RACE_STATISTICS',
  // RECEIVE_SHORT_ACCOUNT = 'app/modules/accounts/RECEIVE_SHORT_ACCOUNT',
  // RECEIVE_STATISTICS_ACCOUNT = 'app/modules/accounts/RECEIVE_STATISTICS_ACCOUNT',
  // RECEIVE_QUEST_STATISTICS_ACCOUNT = 'app/modules/accounts/RECEIVE_QUEST_STATISTICS_ACCOUNT',
}

interface ILoadProfile {
  type: typeof accountsActionsTypes.FETCH_PROFILE;
  payload: {
    userId: number;
  };
}

interface ILoadProfileResponse {
  type: typeof accountsActionsTypes.FETCH_PROFILE_RESPONSE;
  payload: {
    user: IUserInfo;
  };
}

// interface IReceiveUserAccount {
//   type: typeof accountsActionsTypes.RECEIVE_SHORT_ACCOUNT
//   payload: {
//     profile: IAccountProfile
//     leaders_statistics: IAccountStatisticLeader
//   }
// }

// interface IReceiveAccountQuestStatistics {
//   type: typeof accountsActionsTypes.RECEIVE_QUEST_STATISTICS_ACCOUNT
//   payload: {
//     quest_statistics: any
//   }
// }

// interface IReceiveAccountStatistics {
//   type: typeof accountsActionsTypes.RECEIVE_STATISTICS_ACCOUNT
//   payload: {
//     statistics: any
//   }
// }

export type accountActions = ILoadProfile | ILoadProfileResponse;
// | IReceiveUserAccount
// | IReceiveAccountQuestStatistics
// | IReceiveAccountStatistics
