import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { ISlotComboLine } from '../../../../../interfaces';
import SoundService from 'services/sound.service';

const sound = new SoundService();

interface IVisibleComboLineProfit {
  combo: ISlotComboLine;
}

export const VisibleComboLineProfit: FC<IVisibleComboLineProfit> = ({ combo }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (combo.bonus) sound.play('Slot', 'Bonus game');
    return () => sound.stop({ name: 'Bonus game' });
  }, [combo]);

  return (
    <Container className={`line-${combo?.line}`}>
      {combo.bonus ? (
        <>{t('Bonus game xN', { count: 10 })}</>
      ) : (
        <>
          + <CurrencyText value={combo.winning} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--color-combo-line);
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
