import React, { FC, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import PrivateRoute from 'decorators/PrivateRoute.decorator';
import { startSocket } from '../duck';
import {
  BalancePage,
  // CashbackPage,
  // LevelsPage,
  NotificationPage,
  ProfilePage,
  SettingsPage,
  StatisticsPage,
  TransactionsPage,
  SelfExclusionPage,
  TradingPage,
  SoundsPage,
  PrivacyPage,
} from './Pages';
import { registerProfileModule, unregisterProfileModule } from '../ducks/combine-ducks';
import {
  ReferralLandingPage,
  ReferralCampaignsPage,
  ReferralCampaignPage,
  ReferralMembersPage,
} from '../components';
import { BonusCodePage } from '../components/bonus-code-page';
import { useReferralIncomes } from '../hooks';

const _ProfileRouter: FC<RouteComponentProps> = ({ match, location }) => {
  const dispatch = useDispatch();
  const isReferralPage = location.pathname.includes('referral');

  const {
    incomes: { isLoaded },
  } = useReferralIncomes();

  useMemo(() => {
    dispatch(startSocket());
  }, [dispatch]);

  useMemo(() => {
    if (isReferralPage && !isLoaded) registerProfileModule();
  }, [isReferralPage, isLoaded]);

  useLayoutEffect(() => {
    return () => unregisterProfileModule();
  }, [dispatch]);

  return (
    <>
      <div className="content">
        <AnimatedRouter>
          <Route
            path={[
              `${match.url}/referral/campaigns/members`,
              `${match.url}/referral/campaigns/:id/members`,
            ]}
            component={ReferralMembersPage}
          />
          <Route path={`${match.url}/referral/campaigns/:id`} component={ReferralCampaignPage} />
          <Route path={`${match.url}/referral/campaigns`} component={ReferralCampaignsPage} />
          <Route path={`${match.url}/referral`} component={ReferralLandingPage} />
          <Route path={`${match.url}/settings/trading`} component={TradingPage} />
          <Route path={`${match.url}/settings/sounds`} component={SoundsPage} />
          <Route path={`${match.url}/settings/privacy`} component={PrivacyPage} />
          <Route path={`${match.url}/settings`} component={SettingsPage} />
          <Route path={`${match.url}/self-exclusion`} component={SelfExclusionPage} />
          <Route path={`${match.url}/balance/bonus-code`} component={BonusCodePage} />
          {/* <Route path={`${match.url}/balance/cashback`} component={CashbackPage} /> */}
          <Route path={`${match.url}/balance/transaction`} component={TransactionsPage} />
          <Route path={`${match.url}/balance`} component={BalancePage} />
          <Route path={`${match.url}/statistics`} component={StatisticsPage} />
          {/* <Route path={`${match.url}/levels`} component={LevelsPage} /> */}
          <Route path={`${match.url}/notification`} component={NotificationPage} />
          <Route path={`${match.url}`} component={ProfilePage} />
        </AnimatedRouter>
      </div>
      <div id="profile-portal" />
    </>
  );
};

export const ProfileRouter = PrivateRoute(_ProfileRouter);
