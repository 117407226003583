export default {
  Cart: 'Carro',
  Price: 'Precio',
  Type: 'Type',
  Categories: 'Categorías',
  Rarity: 'Raridad',
  OK: 'OK',
  Clear: 'Borrar',
  Autoselect: 'Autoseleccionar',
  Remove: 'Remove',
  Bet: 'Apuesta',
  Min: 'Mín.',
  Max: 'Máx.',
  'it.': '',
  Total: 'Total',
  items: 'artículos',
  Pistols: 'Pistolas',
  Rifles: 'Fusiles',
  'Submachine guns': 'Metralletas',
  Heavy: 'Pesadas',
  Knives: 'Cuchillos',
  Gloves: 'Guantes',
  Keys: 'Llaves',
  Other: 'Otros',
  'Base Grade': 'Grado de base',
  'Consumer Grade': 'Grado de consumidor',
  'Industrial Grade': 'Grado industrial',
  Restricted: 'Restringido',
  'Mil-Spec Grade': 'Grado de Mil-Spec',
  Classified: 'Clasificado',
  'High Grade': 'Grado alto',
  Covert: 'Encubierto',
  Remarkable: 'Remarcable',
  Exotic: 'Exótico',
  Contraband: 'Contrabando',
  Extraordinary: 'Extraordinario',
  Buy: 'Comprar',
  "I'm 18+ and I know about": "I'm 18+ and I know about",
  'the limitations of the store': 'the limitations of the store',
  'Store only for the withdrawal of winnings or unused coins. Exchange of skins with aim to profit because of the price difference with other markets is strictly prohibited. Violators will be banned permanently.':
    'Tienda sólo para recomprar premios o monedas no usadas. El intercambio de skins con ánimo de lucro gracias a la diferencia en el precio con otras tiendas está estrictamente prohibido. Los violadores serán bloqueados para siempre.',
  'Your purchase will be delivered within 15 minutes.': 'Su compra será entregada en 15 minutos.',
  'Congratulations! You have successfully purchased': '¡Felicitaciones! Ha comprado',
  'Order confirmation': 'Confirmación de orden',
  Filters: 'Filters',
  'By price': 'Por precio',
  Filter: 'Filtro',
  'Not found': 'No encontrado',
  'At present it is unable to find any items on your request. Try again later.':
    'Actualmente es imposible encontrar los artículos de su solicitud. Vuelva a intentar más tarde.',
  common: 'common',
  'StatTrak™': 'StatTrak™',
  '★': '★',
  '★ StatTrak™': '★ StatTrak™',
};
