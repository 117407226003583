import { ISlotGame } from './reducer.slot.interfaces';

export enum SlotActionsTypes {
  SLOT_ATTACH_LISTENERS = 'app/slot-game/SLOT_ATTACH_LISTENERS',
  SLOT_DETACH_LISTENERS = 'app/slot-game/SLOT_DETACH_LISTENERS',

  FETCH_CREATE_BID = 'app/slot-game/FETCH_CREATE_BID',

  SLOT_UPDATE_GAME = 'app/slot-game/SLOT_UPDATE_GAME',

  SLOT_RESET_GAME = 'app/slot-game/SLOT_RESET_GAME',

  SLOT_UPDATE_USER_HISTORY = 'app/slot-game/UPDATE_USER_HISTORY',
  SLOT_UPDATE_OWN_HISTORY = 'app/slot-game/UPDATE_OWN_HISTORY',
}

interface ISlotUpdateGame {
  type: SlotActionsTypes.SLOT_UPDATE_GAME;
  payload: ISlotGame;
}

interface ISlotResetGame {
  type: SlotActionsTypes.SLOT_RESET_GAME;
}

export type slotGameActions = ISlotUpdateGame | ISlotResetGame;
