import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { BoardTypes, factors } from '../../../../interfaces';
import { useGameMembers, useGameTotalsAmount } from '../../../../hooks';
import { MembersListItem } from './members-list-item';

interface IGameMembersList {
  type: BoardTypes;
}

export const GameMembersList: FC<IGameMembersList> = ({ type }) => {
  const members = useGameMembers(type);
  const totals = useGameTotalsAmount();
  const keys = Object.keys(members || {});
  return (
    <Container>
      <div>
        <div className={`member-header ${type}`}>
          <div className="member-header-factor">x{factors[type]}</div>
          <div className="member-header-divider" />
          <div className="member-header-info">
            <span>
              <i className="icon-users" />
              {keys.length}
            </span>
            <span>
              <CurrencyText value={totals[type] || 0} />
            </span>
          </div>
        </div>
      </div>
      {keys.map(key => (
        <div key={key}>
          <MembersListItem {...members[key]} />
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content;
  grid-gap: 0.125rem;
  height: min-content;

  & .member-header {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr 0.25rem 1fr;
    height: 100%;

    &-factor {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-white);
    }

    &-divider {
      background: var(--fill-color);
    }

    &-info {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: min-content;
      grid-gap: 0.25rem;
      align-content: center;
      background: var(--color-dark-200);

      & span {
        font-size: 0.625rem;
        line-height: 0.75rem;
        letter-spacing: 2px;
        color: var(--color-white);
        text-align: center;
        height: 0.875rem;

        &:last-of-type {
          font-weight: bold;
        }

        @media screen and (min-width: 375px) {
          font-size: 0.8125rem;
          line-height: 1rem;
          height: 0.875rem;
        }

        & i {
          font-size: 0.875rem;
          margin-right: 0.25rem;
        }
      }
    }
  }

  & > div {
    &:before {
      content: '';
      float: left;
      padding-top: 100%;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
`;
