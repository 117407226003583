import { combineEpics, createEpicMiddleware, ofType } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { authEpics } from 'core/Auth/duck';
import { userEpics } from 'core/User/ducks/combine-duck';
import { appEpics } from 'core/AppShell';
import { classicEpics } from 'games/ClassicGame/duck';
import { hiloEpic } from 'games/Hilo/duck';
import { profileLoaderEpic } from 'modules/Accounts';
// import { leaderRaceEpics } from 'modules/LeadersRace'
import { refillEpics } from 'modules/Refill';
import { promoEpic } from 'modules/promo';
import { withdrawalEpics } from 'modules/withdrawal';
import { fastEpics } from 'games/Fast';
import { lotteryEpics } from 'core/ducks/lottery';
import { socketService } from 'services/socket.service';
import { doubleEpics } from 'games/double/duck';
import { x50Epics } from 'games/x50/duck';
import { casesEpic } from 'games/CaseGame/ducks/epics';
import { bonusCodeEpic } from 'modules/PersonalArea';
import { caseBattleEpic } from 'games/CaseBattle';
import { eventEpic } from 'modules/event-module';

const rootEpics$ = new BehaviorSubject([
  combineEpics(
    appEpics,
    authEpics,
    userEpics,
    lotteryEpics,
    classicEpics,
    hiloEpic,
    fastEpics,
    doubleEpics,
    x50Epics,
    profileLoaderEpic,
    // leaderRaceEpics,
    promoEpic,
    refillEpics,
    withdrawalEpics,
    casesEpic,
    caseBattleEpic,
    bonusCodeEpic,
    eventEpic
  ),
  null,
]);

export const rootEpics = (action$: any, state$: any, dependencies: any) =>
  rootEpics$.pipe(
    mergeMap(([epic, action]: any) =>
      !!action
        ? epic(action$, state$, dependencies).pipe(takeUntil(action$.pipe(ofType(action))))
        : epic(action$, state$, dependencies)
    )
  );

export const epicMiddleware = createEpicMiddleware({
  dependencies: {
    io: socketService.io,
    socket: socketService,
  },
});

export { rootEpics$ };

//NOTE(Leader-Race): comment // import { leaderRaceEpics } from 'modules/LeadersRace' , just uncomment to add
