import React from 'react';
import styled from 'styled-components';
import Quest from './Quest';
import { useTranslation } from 'react-i18next';

export const GettingYourWinning = () => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <Quest height={200} title={t('When does the winning come?')}>
        {t(
          'The winning is sent by our bot within 5-30 minutes automatically The sending rarely takes more time because of Steam Lags or issues with Mobile confirmations'
        )}
      </Quest>
      <Quest height={400} title={t('I did not get the winning according to Terms of Service')}>
        {t(
          "If you won and didn't receive the winning within indicated allotted time, check the following:"
        )}
        <br />
        <br />
        {t('You have entered a proper link in the Settings;')}
        <br />
        <br />
        {t(
          'You have enough space for new items in your inventory to accept the winning (less than 1000 items for Counter-Strike: Global Offensive);'
        )}
        <br />
        <br />
        {t('Your inventory is public')}
        <br />
        <br />
        {t(
          'If there are some errors in above mentioned coins and you solve them, our bot will send you a Trade Offer repeatedly within 5-60 minutes (it depends on the load upon Steam Server)'
        )}
      </Quest>
      <Quest
        height={200}
        title={t('The winning has come, but some skins are not available for a Trade Offer')}
      >
        {t(
          'If you get a Trade Offer with unavailable skins, contact our tech support support@csgofastcom'
        )}
      </Quest>
      <Quest height={200} title={t('I canceled a Trade Offer with my winning accidently')}>
        {t('If you cancel a Trade Offer, contact our tech support support@csgofastcom')}
      </Quest>
      <Quest
        height={200}
        title={t('If you cancel a Trade Offer, contact our tech support support@csgofastcom')}
      >
        {t(
          "If you don't get your winning for some reason or other, contact our tech support support@csgofastcom After a prompt investigation we will send you the winning in a manual way In this case we reserve the right to send you the winning by random skins that are equal to the winning sum"
        )}
      </Quest>
      <Quest
        height={200}
        title={t(
          "I've got a trade ban I want my winning to be sent to my secondary account or friend`s account"
        )}
      >
        {t("We don't send the winnings to other accounts")}
        <br /> <br />
        {t('For the avoidance of scam we send the winnings to the account that won only')}
      </Quest>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;
