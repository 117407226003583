export default {
  Loading: 'Loading',
  'Select your favorite to login': 'Select your favorite to login',
  iam18: "I'm 18+ and I agree to the <1>Terms and Conditions.</1>",
  inAddition: 'In addition...',
  inAdditionFull:
    'In addition, I am not a citizen and don’t entry to the site from the territory of the following countries: USA, UAE, Australia, Hong Kong, India, Indonesia, Japan, Korea, Malaysia, and Singapore.',
  'Authorization successful': 'Authorization successful',
  // tg modal
  Connect: 'Connect',
  'Connect Telegram': 'Connect Telegram',
  'Write to our telegram bot, press start and it will send you a code':
    'Write to our telegram bot, press start and it will send you a code',
  'Enter received code in bellow input:': 'Enter received code in bellow input:',
  '401 Unauthorized': 'Wrong code entered',
  'Code not found': 'Wrong code entered',
  'Go to Telegram': 'Go to Telegram',
};
