import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@components/Common';
import { useTranslation } from 'react-i18next';

interface IProfileLogout {
  handleLogout: () => void;
}

export const ProfileLogout: FC<IProfileLogout> = ({ handleLogout }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Button className="danger" onClick={handleLogout}>
        {t('Log out')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem 1rem;
`;
