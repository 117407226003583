import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 18px;
`;

export const Text = styled.span`
  color: var(--color-white);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

export const WaitingText = styled.span`
  color: var(--color-gray-200);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 25px;
`;

export const ChangeEmail = styled.span`
  color: var(--color-white);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.286px;
  text-align: center;
`;
