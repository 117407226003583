import React from 'react';
import styled from 'styled-components';
import { useGameHistory } from '../../../hooks';
import { animated, useTransition } from 'react-spring';
import { getColorBySector } from '../../../utils';

export const GameHistory = () => {
  const { history } = useGameHistory();
  const items = useTransition(
    history.map(({ s, game_id }: any, i: number) => ({ s, x: i * 8, game_id })),
    d => d.game_id,
    {
      initial: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      from: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(0)`,
        opacity: 0,
      }),
      enter: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      update: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(1)`,
        opacity: 1,
      }),
      leave: ({ x }) => ({
        transform: `translate3d(${x}px, 0, 0) scale(0)`,
        opacity: 0,
      }),
      unique: true,
    }
  );

  return (
    <Container>
      {items.map(({ item, props, key }) => (
        <animated.div key={key} style={props} className={`sector-${getColorBySector(item.s)}`} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: self-end;
  height: 3.25rem;
  background: rgba(19, 26, 29, 0.9);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 100;
  border-top: 1px solid var(--color-gray-900);
  border-bottom: 1px solid var(--color-gray-900);

  & > div {
    width: 0.25rem;
    height: 1.75rem;
    position: absolute;
    left: 0.875rem;
    bottom: 1rem;
    background: var(--stroke-color);

    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--color-white);

    &.sector-blue {
      height: 0.625rem;
    }

    &.sector-red {
      height: 0.9375rem;
    }

    &.sector-green {
      height: 1.125rem;
    }

    &.sector-gold {
      height: 1.3125rem;
    }
  }
`;
