import { FilterDialog as FilterDialogConfig } from 'games/CaseGame';
import { DIALOG } from 'dialogs/dialog-combiner';
import { SearchDialog } from './search-dialog';
import { transitions } from 'core/Modals';

DIALOG.add(FilterDialogConfig.SEARCH, {
  component: SearchDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'portal',
});
