import React, { FC, memo } from 'react';
import { TeamsContainer, Container } from './PlayersItems.styles';
import { useGetCaseBattleGameData, useGetTeams } from 'games/CaseBattle/hooks';
import { calculateGap } from 'games/CaseBattle/utils';
import { Rounds } from '../Rounds';
import { GameStatus } from 'games/CaseBattle/constants';

interface PlayersItemsProps {
  isUpIcons: boolean;
}

export const PlayersItems: FC<PlayersItemsProps> = memo(({ isUpIcons }) => {
  const {
    data: { game, gameStatus },
  } = useGetCaseBattleGameData();
  const { players_in_team, players_count } = game;
  const { delayedTeams } = useGetTeams();

  return (
    <Container
      isTeam={players_in_team > 1}
      gap={calculateGap(players_count)}
      playersCount={players_in_team > 1 ? players_count / players_in_team : players_count}
      isUpIcons={isUpIcons}
      isFinish={gameStatus === GameStatus.finish}
    >
      {delayedTeams.map(team => (
        <TeamsContainer key={team.team_id} playersCount={players_in_team}>
          {team.players.map(player => (
            <Rounds
              key={player.playerInfo.user_id}
              game={game}
              player={player}
              gameStatus={gameStatus}
            />
          ))}
        </TeamsContainer>
      ))}
    </Container>
  );
});
