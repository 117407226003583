import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';

import { getRewards } from '../../utils';

import { FieldPlayItem } from './FieldPlayItem';
import SoundService from './../../../../services/sound.service';

export class FieldPlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowRewards: getRewards(props.ticketType, props.ticket.price).reverse(),
    };

    this.transformPlayItems = this.state.rowRewards.map(() => new Animated.Value(0));
    this.sound = new SoundService();
    this.clientHeight = window.innerHeight;
  }

  componentDidMount() {
    const currentRow = this.state.rowRewards.length - this.props.ticket.position - 1;
    Animated.timing(this.transformPlayItems[currentRow], {
      toValue: 1,
      duration: 300,
    }).start();
    this.sound.play('Tower', 'Play');
  }

  componentDidUpdate(prevProps) {
    const prevTicket = prevProps.ticket;
    const currentTicket = this.props.ticket;
    const { rows } = currentTicket;

    const prevPosition = rows - prevTicket.position;
    const currentPosition = rows - currentTicket.position;
    if (
      (currentTicket.status === 'active' && ['failed', 'finished'].includes(prevTicket.status)) ||
      prevTicket.rows === prevTicket.position
    ) {
      this.sound.play('Tower', 'Play');
      this.setState({
        rowRewards: getRewards(this.props.ticketType, this.props.ticket.price).reverse(),
      });
      return this.changePositionAnimation(prevPosition, currentPosition - 1);
    }
    if (prevTicket.position !== currentTicket.position && currentTicket.status === 'active') {
      this.sound.play('Tower', 'Step');
      return this.changePositionAnimation(prevPosition - 1, currentPosition - 1);
    }
    if (currentTicket.status === 'finished') {
      this.sound.play('Tower', 'Winning');
      this.sound.play('Tower', 'Take');
      return this.changePositionAnimation(prevPosition - 1, currentPosition);
    }

    if (currentTicket.status === 'failed') {
      this.sound.play('Tower', 'Loosing');
    }
  }

  render() {
    const { ticket, ticketType } = this.props;
    const { rowRewards } = this.state;

    const secrets = ticket.secret && ticket.secret.split(',');

    return (
      <FieldPlayContainer clientHeight={this.clientHeight} className="tablet">
        {rowRewards.map((rewards, rowIndex, array) => (
          <Animated.div
            key={rowIndex}
            style={{
              transform: [
                {
                  scale: this.transformPlayItems[rowIndex].interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 1.1],
                  }),
                },
              ],
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <Row isActiveRow={this.isActiveRow(rowIndex)}>
              {rewards.map((reward, index) => (
                <FieldPlayItem
                  key={rowIndex + index}
                  reward={reward}
                  rowIndex={rowRewards.length - rowIndex}
                  itemIndex={index}
                  isActiveRow={this.isActiveRow(rowIndex)}
                  secret={ticket.secret && secrets}
                  doStep={this.props.doStep}
                  ticket={ticket}
                  ticketType={ticketType}
                />
              ))}
            </Row>
          </Animated.div>
        ))}
      </FieldPlayContainer>
    );
  }

  isActiveRow = index => {
    const { ticket } = this.props;
    const { rowRewards } = this.state;
    return (
      (rowRewards.length - index - 1 === ticket.position &&
        !['failed', 'finished'].includes(ticket.status)) ||
      (rowRewards.length - index === ticket.position &&
        ['failed', 'finished'].includes(ticket.status))
    );
  };

  changePositionAnimation = (prevPosition, currentPosition) => {
    Animated.timing(this.transformPlayItems[prevPosition], {
      toValue: 0,
      duration: 180,
    }).start(() => {
      Animated.timing(this.transformPlayItems[currentPosition], {
        toValue: 1,
        duration: 230,
      }).start();
    });
  };
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 15px;
  flex: 1;
  margin: ${props => (props.isActiveRow ? '6px 0' : '2px 0')};
`;

const FieldPlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 20px 11px;
  flex-shrink: 0;
  flex-basis: ${({ clientHeight }) => clientHeight - 130}px;
`;
