import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToOrder, deleteFromOrder } from '../../ducks/order.duck';
import { hasInOrder } from '../../selectors';
import { IStore } from 'store/interface';
import { InventoryItem } from 'games/CaseGame/interfaces';

export const useOrderControllers = (id?: number) => {
  const dispatch = useDispatch();
  const has = useSelector<IStore, boolean>(hasInOrder(id));

  const toggleElement = useCallback(
    (item: InventoryItem) => () => {
      if (has) dispatch(deleteFromOrder(item));
      else dispatch(addToOrder(item));
    },
    [has, dispatch]
  );

  return {
    toggleElement,
    has,
  };
};
