import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { socketService } from '../../../services/socket.service';
import { QueryService } from 'services/query';

export class ReferralRepository {
  static fetchCreateCampaigns(params: Record<string, string | number>): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-campaigns`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });
  }

  static fetchReferralCampaigns(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-campaigns`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  // static fetchReferralReportsNewMembers(params: Record<string, any>): Observable<AjaxResponse> {
  //   return ajax({
  //     url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
  //       socketService.domain
  //     }api/referral-reports/new-referrals${QueryService.paramsToQuery(params)}`,
  //     method: 'GET',
  //     withCredentials: true,
  //     crossDomain: true,
  //   })
  // }

  static fetchReferralReportsIncomes(params: Record<string, any>): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/referral-reports/incomes${QueryService.paramsToQuery(params)}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchReferralIncomes(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-income`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchTakeReferralIncomes(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-income/take`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
