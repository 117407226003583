import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCaseBattleGameData, getCaseBattleGameReplayData } from '../ducks';

export const useGetCaseBattleGameData = () => {
  const location = useLocation();
  const data = useSelector(getCaseBattleGameData);
  const dataReplay = useSelector(getCaseBattleGameReplayData);

  return {
    isLoading: data.get('isLoading'),
    data:
      dataReplay.get('isReplay') || location.state?.isReplay
        ? dataReplay.get('data')
        : data.get('data'),
    dataFinish: data.get('data'),
    isReplay: dataReplay.get('isReplay'),
  };
};
