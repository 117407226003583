import * as React from 'react';
import styled from 'styled-components';
import { QuestWidgetItem } from './QuestWidgetItem';
import { animated } from 'react-spring';
import { useSwipeSlide } from '@components/hooks';
import { IQuest } from '../../interfaces/reducer.leaders.interfaces';
import { useTranslation } from 'react-i18next';

interface IQuestList {
  quests: IQuest[];
  takeRewarHandle: (id: number) => () => void;
}

const nodeWidth = window.screen.width / 1.55;

const paginationColorConfig: { [key: string]: string } = {
  weekly: '#00bad9',
  daily: '#25c30a',
};

export const QuestList: React.FC<IQuestList> = ({ quests, takeRewarHandle }) => {
  const { animatedProps, bind, handleSwipe } = useSwipeSlide(quests.length, nodeWidth);

  const { t } = useTranslation();

  return (
    <Container>
      <QuestHeading>
        {t('Quests interval', {
          context: quests[0].type,
        })}
      </QuestHeading>
      <QuestListContainer>
        {animatedProps.map(({ x, display }, i) => (
          <animated.div
            {...bind()}
            key={i}
            style={{
              display,
              transform: x.interpolate(x => `translate3d(${x + 15}px,0,0)`),
              zIndex: quests.length - i,
              paddingRight: '0.9rem',
            }}
          >
            <QuestWidgetItem {...quests[i]} takeRewarHandle={takeRewarHandle(quests[i].id)} />
          </animated.div>
        ))}
      </QuestListContainer>
      <QuestDots>
        {animatedProps.map(({ opacity }: any, i) => (
          <QuestDotsItem
            key={quests[i].id}
            onClick={handleSwipe(i)}
            styled={{
              completed: quests[i].rewardTaken,
              disabled: quests[i].isHidden,
              type: quests[i].type,
            }}
            style={{
              color: opacity.interpolate({
                range: [0, 1],
                output: ['#374951', paginationColorConfig[quests[i].type]],
              }),
            }}
          />
        ))}
      </QuestDots>
    </Container>
  );
};

interface IQuestDotsItem {
  styled: {
    completed?: boolean;
    disabled: boolean;
    type: string;
  };
}

const Container = styled.div``;

const QuestHeading = styled.div`
  font-size: 0.9rem;
  letter-spacing: 0.54px;
  color: var(--mGray-100);
  padding: 0.3rem 1.1rem;
  text-align: left;
`;

const QuestListContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-height: 140px;

  & > div {
    position: absolute;
    width: ${nodeWidth}px;
    will-change: transform;
  }
`;

const QuestDots = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  overflow: hidden;
`;

const QuestDotsItem = styled(animated.li)<IQuestDotsItem>`
  display: block;
  width: 10px;
  height: 10px;
  margin: 0.5rem;
  position: relative;
  color: ${({ styled }) => `var(--${styled.type}-quest)`};

  &:after {
    font-family: 'icomoon' !important;
    font-size: 0.9rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--gray-250);
  }

  ${({ styled }) =>
    !styled.completed &&
    !styled.disabled &&
    `
    &:before {
      content: '\\2022';
      font-size: 3rem;
      display: inline-block;
      line-height: 0.9rem;
      text-align: center;
    }
  `};

  ${({ styled }) =>
    styled.completed &&
    `
    &:after {
      content: "\\e92b";
      font-size: 0.7rem;
      color: inherit;
    }
  `};

  ${({ styled }) =>
    styled.disabled &&
    `
    &:after {
      content: "\\e92a";
      color: inherit;
    }
  `};
`;
