import * as React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { CurrencyText } from '../../../../@components/currency';

import { VerifyTicket } from './VerifyTicket';

class _ControlsGame extends React.PureComponent {
  render() {
    const { ticket, ticketType, t } = this.props;
    return (
      <React.Fragment>
        <Btn
          onClick={this.props.sellTicket}
          isDisable={this.isDisabledSellTicket()}
          disabled={this.isDisabledSellTicket()}
          className="tablet"
        >
          {t('Take prize')} <CurrencyText value={ticket.prize} />
        </Btn>
        <VerifyTicket ticket={ticket} ticketType={ticketType} />
      </React.Fragment>
    );
  }

  isDisabledSellTicket = () => {
    const { ticket } = this.props;
    return ticket.position < 1 || ticket.status === 'failed' || ticket.status === 'finished';
  };
}

export const ControlsGame = withTranslation()(_ControlsGame);

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-height: 40px;
  border-radius: 5px;
  background: var(--yellow);

  font-size: 15px;
  border: 0;
  opacity: ${({ isDisable }) => (isDisable ? 0.35 : 1)};
  margin: 0 1rem;
`;
