import { Button } from '@components/Common';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  z-index: 2;
  width: 0;
  display: flex;
  height: 48px;
  flex: 2;
  transition: 0.3s background;
  background: var(--color-dark-200);
  border-radius: 10px;
  text-transform: uppercase;
  color: var(--color-white);
  gap: 10px;

  &:disabled {
    opacity: 0.3;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: 10px;
`;

export const PriceContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;
