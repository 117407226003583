import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useActingLotteryElement, useParticipateFreeLottery } from 'core/hooks';
import { LotteryType } from 'core/interfaces';

export const FreeLotteryController = () => {
  const { t } = useTranslation(['lottery']);
  const { data } = useActingLotteryElement(LotteryType.FREE);
  const { handleCreateParticipate } = useParticipateFreeLottery();
  return !!data?.deposit?.userTickets ? (
    <Button className="primary" disabled={true}>
      {t('You are already participating')}
    </Button>
  ) : (
    <Button className="primary" onClick={handleCreateParticipate}>
      {t('Participate')}
    </Button>
  );
};
