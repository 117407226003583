import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { BonusCodeComponent } from './components/bonus-code/bonus-code.component';

export const BonusCodePage: FC = () => {
  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <BonusCodeComponent />
      </div>
    </>
  );
};
