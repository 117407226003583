import { Reducer } from 'redux';

import { fetchRacesQuest } from './quests.duck';
import { List } from 'immutable';
import { IDispatch } from 'core/rootInterfaces/root.interface';
import { recesActions, recesActionsTypes } from '../interfaces/actions.leaders.interfaces';

import { IRaceRecord, IRacesList, RacesList } from '../interfaces/reducer.leaders.interfaces';
import { ISocketService } from '../../../services/interfaces/services.interface';

export const races: Reducer<IRacesList, recesActions> = (state = RacesList, action) => {
  const { type, payload } = action;
  switch (type) {
    case recesActionsTypes.UPDATE_RACES:
      return List(payload.races);

    default:
      return state;
  }
};

export const fetchRaces = () => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  try {
    const response = await fetch(
      `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/latest-races`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      }
    );

    const data = await response.json();

    if ('error' in data) throw new Error(data.error);

    dispatch(updateRaces(data));
    dispatch(fetchRacesQuest());
  } catch (e) {
    console.log(e);
  }
};

const updateRaces = (races: IRaceRecord[]) => ({
  type: recesActionsTypes.UPDATE_RACES,
  payload: {
    races,
  },
});
