import styled from 'styled-components';
import { MyButton as DefaultButton } from 'modules/PersonalArea/components/SelfExclusion/SelfExclusion/SelfExclusion.styles';

export const Container = styled.div`
  background: var(--color-dark-600);
  padding: 24px 16px;
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 26px;
  text-align: center;
`;

export const SubTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 18px;
  padding: 15px 0 31px;
  text-align: center;
`;

export const FreezeTitle = styled.span`
  color: var(--color-gray-50);
  font-size: 14px;
  font-weight: 700;
`;

export const MyButton = styled(DefaultButton)`
  margin-top: 20px;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 240px;
  overflow: scroll;

  label {
    align-items: center;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    gap: 15px;
    min-height: 60px;
  }

  input[type='radio'] {
    appearance: none;
    background: color(--color-dark-100);
    border-radius: 50%;
    border: 3px solid var(--gray-220);
    height: 24px;
    margin: 0;
    width: 24px;
  }

  input[type='radio']::before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 18px;
    width: 18px;
  }

  input[type='radio']:checked::before {
    background: var(--yellow);
  }
`;
