import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Header, Description, Image } from './bonus-info.styles';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

export const BonusInfo = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Image src={eventImagesPath.rarePrizePath} alt="prize" />
      <Header>{t('bonusHeader')}</Header>
      <Description>{t('bonusDescription')}</Description>
    </Container>
  );
};
