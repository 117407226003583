import React from 'react';
import Quest from './Quest';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const BetQuestions = () => {
  const { t } = useTranslation();
  return (
    <Container className="content">
      <Quest height={400} title={t('Why does a bot cancel my bet?')}>
        {t('Make sure that:')}
        <br />
        <br />-{' '}
        {t(
          'You send skins from Counter-Strike: Global Offensive, there are no souvenir items or skins from other games;'
        )}
        <br />
        <br />- {t('Number of deposited skins meets the rules of a selected game;')}
        <br />
        <br />- {t('The value of a bet meets the rules of a selected game;')}
        <br />
        <br />-{' '}
        {t(
          'Your account is attached to your Steam Guard Mobile Authenticator minimum 7 days;'
        )}{' '}
        <br />
        <br />- {t('While trading an offer you should not ask a bot for skins')} <br />
        <br />
      </Quest>

      <Quest height={260} title={t('What is Escrow?')}>
        <span>Escrow</span>{' '}
        {t(
          "- a new type of SteamGuard, that secures users accounts from unauthorized trades Starting on Dec 9, it's necessary to confirm all Trade Offers by Steam Guard Mobile Authenticator, which is attached to your account minimum 7 days Otherwise, Trade Offers are frozen for 12 hours to secure the users Our bot accepts the Trade Offers which are confirmed by Steam Guard Mobile Authenticator only"
        )}
      </Quest>

      <Quest height={200} title={t('My skins went to another game')}>
        {t(
          'The time period of placing a bet in a round depends on the load upon the Steam Servers and can range from 2 up to 180 seconds Items can rarely be added after a longer period of time because of extreme load upon Steam If a bet is placed close to the end of a current round, it can be placed to the next game We are not responsible for the situations which happen because of Steam Lags'
        )}
      </Quest>

      <Quest height={200} title={t('My skins did not go to any game')}>
        {t('1 Make sure, you confirmed a bet by Steam Guard Mobile Authenticator')}
        <br />
        <br />
        {t('2 Check the status of a Trade Offer with our bot')}
        <br />
        <br />
        {t(
          '3 If a Trade Offer is accepted, but the bet is not placed to any game contact our tech support: support@csgofastcom'
        )}
      </Quest>

      <Quest height={300} title={t("I've canceled a trade, but a bet went in")}>
        {t(
          'Our bots accept incoming trades with min lags, then they send skins to the game, thus it is almost impossible to cancel a deposited bet'
        )}
        <br />
        <br />
        {t(
          'If you cancel your bet and get a confirmation "Trade Offer Cancelled", but the skins play in, so it means your cancellation was made too late, and you got a confirmation "Trade Offer Cancelled", because of Steam Error We can\'t return the skins which have been already raffled'
        )}
      </Quest>

      <Quest height={200} title={t('I placed a bet accidently, how to get my skins back?')}>
        {t(
          'We are working only with Steam Trade Offer System Every bet is confirmed by Steam Guard Mobile Authenticator Thus, the situations when your items play in the game without your confirmation or accidently are impossible We cannot return the deposited and raffled skins'
        )}
      </Quest>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--gray-350);
`;
