import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { Lobby } from './LobbyPage/Lobby';

export const LobbyRouter: React.FC<RouteComponentProps> = () => (
  <>
    <AppBar />
    <Lobby />
  </>
);
