import React, { FC } from 'react';
import { IDialogComponent } from 'core/interfaces';
import { useAccountProfile } from 'modules/Accounts/hooks';
import { SwipeModal } from '@components/SwipeModal';
import { HeaderComp } from '../header';
import { UserStatisticsInfoComp } from '../user-statistics-info';
import { ButtonComp } from '../user-profile-button';
import { AchievementsComp } from '../achievements';
import { UserStatistics } from './user-profile-dialog.styles';

interface IUserProfileDialog extends IDialogComponent<boolean> {}

export const UserProfileDialog: FC<IUserProfileDialog> = ({ handleCloseModal }) => {
  const { profile } = useAccountProfile();

  return (
    <SwipeModal handleCloseModal={handleCloseModal}>
      <HeaderComp profile={profile} />
      <UserStatistics>
        <UserStatisticsInfoComp profile={profile} />
        <ButtonComp profile={profile} />
        <AchievementsComp profile={profile} />
      </UserStatistics>
    </SwipeModal>
  );
};
