import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  background: var(--color-dark-200);
  height: 58px;
  padding: 0.81rem 1.25rem;
`;

export const Header = styled.div`
  align-items: center;
  background: var(--color-dark-200);
  display: flex;
  justify-content: space-between;
  padding: 0.81rem 1.25rem;
  width: 100%;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  color: var(--color-white);
  font-size: 0.88rem;
  font-weight: 700;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  height: 1.88rem;
  margin-right: 0.44rem;
  object-fit: cover;
  width: 1.88rem;
`;

export const Level = styled(Name)`
  line-height: initial;
  margin-left: auto;
`;

export const ProfileType = styled.span`
  font-size: 0.63rem;
  font-weight: 700;
  line-height: 0.87rem;
  text-transform: uppercase;

  &[data-color='bronze'] {
    color: var(--color-account-type-bronze);
  }

  &[data-color='silver'] {
    color: var(--color-account-type-silver);
  }

  &[data-color='gold'] {
    color: var(--color-account-type-gold);
  }

  &[data-color='platinum'] {
    color: var(--color-account-type-platinum);
  }

  &[data-color='diamond'] {
    color: var(--color-account-type-diamond);
  }
`;

export const Img = styled.img`
  border-radius: 50%;
  height: 1.5rem;
  margin-left: 0.75rem;
  object-fit: cover;
  width: 1.5rem;
`;
