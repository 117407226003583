import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { useParticipateSettings } from 'core/hooks';
import { ParticipateModule } from 'core/interfaces';
import {
  FastInventoryFiltersConfig,
  useFastInventoryFilters,
  useFastInventorySortFilter,
} from 'core/User';
import { classicModule } from 'games/ClassicGame';
import { fastModule } from 'games/Fast';

interface IParticipateLimits {
  module: ParticipateModule;
}

export const ParticipateLimits: FC<IParticipateLimits> = ({ module }) => {
  const { t } = useTranslation();
  const { settings } = useParticipateSettings(module);
  const { filters } = useFastInventoryFilters();
  const { values, changeSort } = useFastInventorySortFilter();

  return (
    <Container>
      <span>
        <CurrencyText value={settings.min_bet || 1} />{' '}
        {settings?.max_bet && (
          <>
            {'- '}
            <CurrencyText value={settings.max_bet} />{' '}
          </>
        )}
        {t('From {{ min_items_per_trade }} to {{ max_items_per_trade }} items', {
          min_items_per_trade: settings?.min_items_per_trade ?? 1,
          max_items_per_trade: settings?.max_items_per_trade,
        })}
      </span>
      {(module === classicModule.moduleName || module === fastModule.moduleName) && (
        <div onClick={changeSort(values === 'price' ? '-price' : 'price')}>
          <div className={`sort-type ${FastInventoryFiltersConfig.sortBy(filters.sortBy)}`}>
            <i className="icon-asc-sort-price" />
            <i className="icon-desc-sort-price" />
          </div>
          <span>{t('price')}</span>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  background: var(--color-dark-600);
  display: flex;
  gap: 1.25rem;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;

  & > span {
    color: var(--color-white);
    font-size: 0.65rem;
    font-weight: bold;
    line-height: 12px;
  }

  & > div {
    display: flex;
    position: absolute;
    right: 16px;

    & .sort-type {
      height: 0.75rem;
      margin-right: 16px;
      position: relative;

      &.ASC_PRICE .icon-asc-sort-price,
      &.DESC_PRICE .icon-desc-sort-price {
        opacity: 1;
      }

      & i {
        color: var(--aqua-500);
        font-size: 1.2rem;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 70%;
        transform: translate(-50%, -50%);
        transition: color 150ms ease-out;
      }
    }

    & > span {
      color: var(--aqua-500);
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0.3px;
      line-height: 14px;
      text-align: center;
      text-transform: capitalize;
    }
  }
`;
