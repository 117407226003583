import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { changeSingleFilters } from '../../ducks/filters.duck';

export const useStorePageFilter = () => {
  const dispatch = useDispatch();

  const updatePageAction = useCallback(
    (number: number) => {
      dispatch(changeSingleFilters('page', number));
    },
    [dispatch]
  );

  return {
    updatePageAction,
  };
};
