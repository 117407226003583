import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useUserTradeLinks } from '../../useUserTradeLinks.hook';

export const useUserTradeLinkForm = () => {
  const { tradeLinks, handleSaveSteamLink } = useUserTradeLinks();
  const form = useForm<{ link: string }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      link: tradeLinks?.steam ?? '',
    },
    criteriaMode: 'firstError',
  });

  const onSubmit = useCallback(({ link }) => handleSaveSteamLink(link), [handleSaveSteamLink]);

  return {
    form,
    onSubmit,
    tradeLinks,
  };
};
