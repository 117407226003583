import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;
export const DropDownHeader = styled.div`
  background: var(--color-dark-200);
  border-radius: 8px;
  padding: 12px 15px 12px 20px;
  position: relative;
`;
export const Text = styled.span`
  color: var(--color-white);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
  text-align: center;
`;
export const IconContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 15px;
  top: 12px;
  transform: ${p => (p.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
`;
export const OptionWrapper = styled.div`
  padding: 12px 15px 12px 20px;
  color: var(--color-white);
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.1px;
`;

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  top: 38px;
  left: 0;
  max-height: 110px;
  overflow: scroll;
  background: var(--color-dark-200);
  border-radius: 0 0 8px 8px;
  height: ${p => (p.isOpen ? '110' : '0')}px;
  transition: height 0.3s;
`;
