import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { Container } from './TOS.styles';
import { Dropdown } from './Dropdown/Dropdown';

export const TOS: FC = () => {
  const address = window.location.href.split('//')[1].split('/')[0];
  const mirrors = [
    { name: 'csgofastx.com', value: 'https://csgofastx.com' },
    { name: 'csgofast.com', value: 'https://csgofast.com' },
    { name: 'csgofast4.com', value: 'https://csgofast4.com' },
    { name: 'csgofast123.com', value: 'https://csgofast123.com' },
    { name: 'xcsgofast.com', value: 'https://xcsgofast.com' },
    { name: 'csgofast.tl', value: 'https://csgofast.tl' },
    { name: 'csgofast.gg', value: 'https://csgofast.gg' },
    { name: 'cs2gofast.com', value: 'https://cs2gofast.com' },
  ];

  return (
    <>
      <AppBar />
      <Container>
        <div>
          <h2>Terms and conditions (public offer)</h2>
          <p>
            The text of the present Terms and Conditions, which is constantly available on the
            Internet at the network address:
            <Link to="./tos">{` ${address}/fos`}</Link>, contains all essential terms of accession
            agreement, and is the public offer containing conditions under which Users are given an
            opportunity to use the Website which is constantly available on the Internet at the
            network address:
            <Link to="/">{` ${address}`}</Link>. The acceptance of the present offer is an
            authorization by any physical person according to provisions of the present Agreement.
            To sign up on
            <Link to="/">{` ${address}`}</Link> you use your account of STEAM, Google +, VK,
            Twitter, Facebook, Yandex. However, the Site and STEAM, Google +, VK, Twitter, Facebook
            and Yandex are different resources that are not subordinate to each other.
          </p>
          <b className="attention">
            ATTENTION! If you do not agree with Terms and Conditions of the present User Agreement,
            do not become authorized on the Website
            <Link to="/">{` ${address}`}</Link>, and do not use services of this Website.
          </b>
          <p className="our-sites">
            Our sites:{' '}
            {mirrors.map(item => (
              <a href={item.value} target="_blank" rel="noopener noreferrer" key={item.value}>
                {item.name}
              </a>
            ))}
          </p>
        </div>

        <div className="info">
          <Dropdown title={'Glossary'}>
            <b>
              Unless it is expressly provided otherwise, the following definitions shall have the
              meaning as stipulated below.
            </b>
            <p>
              <b>Website</b> - a group of interrelated webpages available in the internet via
              addresses (URL’s) <Link to="/">{address}</Link>, containing information, texts,
              graphical elements, design, images, photo and video records, computer programs that
              provide access to aforesaid information. The website are the Internet resource
              intended for proviion of attraction-entertainment services solely to natural persons.
            </p>
            <p>
              <b>Gamevio Ltd.</b> - Gamevio Ltd., a company incorporated in the Cyprus with
              registration number HE 404862 and registered address at Loutrakiou 5, Chara Benezia
              Bld, 1st floor, Office 101, Strovolos, 2027, Nicosia, Cyprus. The Website, Gamevio
              Ltd. are not affiliated with STEAM and VALVE corp.
            </p>
            <p>
              <b>User, or You</b> – you, as the person who has entered into agreement with Gamevio
              Ltd. via acceptance of this offer, is available on the Internet.
            </p>
            <p>
              <b>Parties</b> – Gamevio Ltd. and the User collectively, each shall be referread to as
              the “Party”.
            </p>
            <p>
              <b>The Services</b> - the process of provision to the User with a chargeable and or
              free of charge opportunities for leisure activities to participate in games and
              entertainment using the Website.
            </p>
            <p>
              <b>Coins</b> - virtual game unit, record of participation at the Website. Coins are
              used only within the Website and cannot be a subject of any transactions out of the
              Website.
            </p>
            <p>
              <b>Skin</b> – virtual game attribute (virtual inventory) of the multiplayer network
              game (software) Counter-Strike: Global Offensive, DOTA 2.
            </p>
            <p>
              <b>Virtual Game Units</b> – are the visual images generated by the software of the
              Website (Coin) and/or the software used on other resources on the Internet (Skins).
              All rights to the specified visual images (Coins and Skins) belong to owners of the
              corresponding software and are not transferred / not assigned to Users of the Website
              neither into the property right, nor into other chose in possession and/or a law of
              obligations. Virtual Game Units – are not virtual and/or electronic money or mean of
              exchange.
            </p>
            <p>
              <b>Bet</b> - the electronic document created by the Website upon receiving order from
              the User, that are made via on the Website. Bet serves as an indication of the User
              participation in Games offered at the Website. Bets are being made via using Coins and
              Skins.
            </p>
            <p>
              <b>Users Account Balance</b> - the amount of Skins, Coins or funds deposited by the
              User to the Website.
            </p>
            <p>
              <i>
                All other terms and definitions which are found in the text of the present Terms
                shall be interpreted by the Parties in the context of the definitions specified
                hereabove according to the regular rules of interpretation of the corresponding
                terms, provided, however, that such interpretation shall not be in contrary to the
                provisions of the Terms.
              </i>
            </p>
            <p>
              <i>
                The headings contained in these Terms are for reference purposes only and shall not
                affect in any way the meaning or interpretation of these Terms.
              </i>
            </p>
            <p>
              <i>
                In case of discrepancies in the interpretation of terms and definitions in the text
                of the present Terms and in the text available at the Website (for example, in the
                section F.A.Q. at the Website) the interpretation contained in the present Terms
                shall prevail.
              </i>
            </p>
          </Dropdown>

          <Dropdown title={'1. General provisions'}>
            <p>
              1.1. These Terms and Conditions ("Terms" or "Agreement") constitute and govern the
              contractual relationship between Gamevio Ltd., hereinafter referred to as "Gamevio
              Ltd." or "We", "Us"; or "Our"; and the User.
            </p>
            <p>
              1.2. The Terms and Conditions stipulated below are amended as of 4 june, 2019. Before
              using the Website please read Terms and Conditions carefully. The fact of using the
              Website confirms your consent with the Terms and Conditions.
            </p>
            <p>1.3. The Website belong to and are operated by Gamevio Ltd.</p>
            <p>
              1.4. The User must read, agree with and accept all of the Terms and Conditions
              contained in this Agreement without modifications, which include those Terms and
              Conditions expressly set forth below and those incorporated by reference, before the
              User may become an active, registered User of the Website. By continuing to access or
              use the Website, the User agrees to follow the Terms and Conditions of this Agreement.
              This Agreement shall also apply to all Services provided via mobile devices, including
              downloadable applications. All references to the use of the Website shall be construed
              as references to the use of our Services provided for mobile devices.
            </p>
            <p>
              1.5. Gamevio Ltd. may amend and/or assign this Agreement or any rights and/or
              obligations from time to time. The Terms and Conditions,
              <Link to="/privacy-policy"> the Privacy Policy</Link>,
              <Link to="/aml-policy"> AML Policy</Link>,
              <Link to="/anti-bribery-policy"> Anti-Bribery Policy</Link> and
              <Link to="/refund-policy"> Refund Policy</Link> are published on the Website and may
              be modified at any time. The new version of these Terms and Conditions will take
              effect immediately upon Your next login on the Website. By continuing using the
              Website, the Services or the software offered by Gamevio Ltd., the User agrees to be
              bound by the Terms as well as by the latest modifications to it. If the User does not
              agree to be bound by the changes to these Terms, the User must not use or access the
              Website. In order to avoid misunderstandings and disputes at a later stage, Users can
              print out these Terms. Gamevio Ltd. may publish these Terms in a number of languages.
              This, however, may be done <b>SOLELY</b> for information purposes. The English version
              constitutes the legal basis of the relationship between You and Gamevio Ltd. Should
              there be any discrepancy between the Terms in the English version and the version in
              any other language, the English version shall prevail.
            </p>
            <p>
              1.6. These Terms enter into force when You register and confirm Your registration
              details in the registration process on the Website. By registering an account with the
              Website You agree that You have read and understood these Terms and Conditions. By
              using the Website You confirm that You have accepted and agreed to the contents
              contained therein. Should You not agree with any part of these Terms, You are
              prohibited to use the Website.
            </p>
            <p>
              1.7. Gamevio Ltd. reserves the right to refuse and/or cancel Services at its own
              discretion where the Services are illegal to be used.
            </p>
          </Dropdown>

          <Dropdown title={'2. Eligible users'}>
            <p>
              2.1. The Website accept Users only from those countries and geographic regions where
              online gambling is allowed by local legislation. It is the User’s sole responsibility
              to be informed about the existing gambling laws and regulations of User's jurisdiction
              before placing Bets on the Website.
            </p>
            <p>
              2.2. The User guarantees at all times not to be a resident of countries including the
              United States and its dependencies, France, Netherlands, Former Dutch Antilles,
              Australia, Hong Kong, China, India, Indonesia, Japan, Malaysia, Singapore.
            </p>
            <p>
              2.3. The Users guarantees that he is not limited in his legal and mental capacity
              (User has the ability to understand and weigh up consequences of his/her actions) to
              enter into agreements with third parties (e.g. the User shall not be mentally ill,
              shall not suffer from drug, alcohol, gambling or other types of addictions).
            </p>
            <p>
              2.4. The User assures Gamevio Ltd. that he/she is over 18 years of age. No person
              under 18 years of age or other higher minimum legal age in User's jurisdiction may be
              registered as a User and any funds deposited or any wins shall be forfeited. We are
              entitled to require User to provide proof of age and to refuse a User's admission to
              the Service if We have reasons to believe that the minimum age requirement is not
              fulfilled.
            </p>
            <p>
              2.5. The User agrees to proceed with KYC/AML procedures stipulated by Section 4 of
              <Link to="/privacy-policy"> Privacy Policy</Link> prior to access to the some
              Services.
            </p>
            <p>
              2.6. The Services are available to, and may only be used by individuals, who can enter
              into legally binding contracts under the law applicable to their country of domicile.
            </p>
          </Dropdown>

          <Dropdown title={'3. Registration and account'}>
            <p>
              3.1. In order to use the Website, the User must complete the registration form and
              open a User account ("Account") on the Website. The User may be eligible for an
              account only if registering at the Website via his accounts on STEAM or Google + or VK
              or Twitter or Facebook or Yandex. However, User understands and agrees that the
              Website and STEAM, Google +, VK, Twitter, Facebook and Yandex are different resources
              and are not subordinate each other. The User warrants that the User's identity (his
              name, surname, date of birth, place of residence, valid email address and in some
              cases, phone number) is real and not fake (is not intended to impersonate any other
              person or legal entity). The User warrants, by the request of Gamevio Ltd. to provide
              true, accurate, current and complete information regarding his identity and proceed
              with KYC procedure stipulated by Section 4 of the
              <Link to="/privacy-policy"> Privacy Policy</Link>. Any false information or
              impersonation of any person or entity, misrepresentation regarding any affiliation
              with another person, entity or association, use of false headers or other acts or
              omissions to conceal one's identity from Gamevio Ltd. B.V. for any purpose will be
              prosecuted under the fullest extent of the law. A User accessing the Website may be
              asked to provide valid subsisting verification of identity and relevant information.
              Gamevio Ltd. reserves the right to ask for the proof of age from the User and limit
              access to the Website or suspend the User’s Account if the User fails to meet this
              requirement.
            </p>
            <p>
              3.2. Furthermore, Gamevio Ltd. may receive public information about the User in STEAM,
              Google +, VK, Twitter, Facebook, Yandex.
            </p>
            <p>
              3.3. When the User authorizes through STEAM, Google +, VK, Twitter, Facebook, Yandex,
              the Website receive a unique numeric identifier, but not private data expressly
              specified by the User in the social network he used for authorization.
            </p>
            <p>
              3.4. With the use of an Account on the Website, the User ensures at least the
              following:
              <br />
              <span>a) The User has completed the registration form truthfully and correctly.</span>
              <br />
              <span>
                b) The User is not an individual under 18 years of age or other higher minimum legal
                age in User's jurisdiction.
              </span>
              <br />
              <span>
                c) The User is neither under legal supervision nor restricted in his business
                activities.
              </span>
              <br />
              <span>
                d) The User has registered personally on his/her behalf and not on the behalf of
                someone else.
              </span>
              <br />
              <span>
                e) The User uses the account for personal use and has no commercial intentions.
              </span>
              <br />
              <span>
                f) The User has no knowledge about any bet result of the underlying bet before
                placing it.
              </span>
              <br />
              <span>g) The User does not have multiple accounts on the Website.</span>
              <br />
              <span>h) The User has read and accepts these General Terms and Conditions.</span>
              <br />
              <span>
                i) The User has read and accepts
                <Link to="/privacy-policy"> Privacy Policy</Link>.
              </span>
              <br />
              <span>
                j) The User agrees that he/she is forbidden to somehow manipulate with the outcomes
                of games (for example, collaborate with each other for the purpose of influence on
                result of any game for the benefit of one or several Users).
              </span>
              <br />
              <span>
                k) The User agrees that it is forbidden to receive Virtual Game Units by means of
                intentional or careless use of malicious/virus software and/or using
                defects/disruptions in work of the Website or disruptions of STEAM or any other
                third-party software that may be used by Gamevio Ltd..
              </span>
              <br />
            </p>
            <p>
              3.5. We accept only one account per person. The use of more than one account per
              physical User is strictly forbidden. We retain the right to close a User's account at
              any time and to cancel all the transactions pertaining to any User that has registered
              more than one account in his own name or under different names, in the event that We
              have reasonable suspicion that the User has registered multiple accounts. It makes no
              difference whether the accounts have been used with the intent of defrauding or
              cheating, or otherwise.
            </p>
            <p>
              3.6. It is prohibited for Users to sell, pledge, transfer and/or acquire accounts
              to/from other Users. Funds can only be remitted to the same account from where they
              originated at the deposit stage or to the different account, provided, however, that
              such different account shall belong to the User and Gamevio Ltd. shall verify it by
              requesting the necessary information and supporting documents.
            </p>
            <p>
              3.7. Gamevio Ltd. accepts only natural persons with a single account as a User.
              Neither a legal entity nor corporate body is allowed to open or to have an account on
              theWebsite.
            </p>
            <p>
              3.8. We will treat the information the User entrusts to us as highly confidential in
              accordance with the disclosures we provide during the registration process and in
              accordance with our
              <Link to="/privacy-policy"> Privacy Policy</Link>.
            </p>
            <p>
              3.9. The User shall not treat Gamevio Ltd. as a financial institution nor expect any
              interest on his/her deposit/s.
            </p>
            <p>
              3.10. Any amount credited erroneously to a User's Account Balance should be reported
              immediately to Gamevio Ltd. by the User. Any winnings and funds caused by an error are
              invalid regardless of how they occurred.
            </p>
            <p>
              3.11. If the User has not authorized at the Website by any available means within six
              (6) consecutive months – Gamevio Ltd. may terminate this agreement and therefore,
              delete the User Account, provided, however, that the electronic notification on User
              Account termination is sent to the User. If within 30 (thirty) calendar days from the
              moment of notification sending, the User has not resumed the use of the Website,
              Gamevio Ltd. will terminate this agreement and delete an User Account.
            </p>
            <p>
              3.12. In case of termination of the present agreement all remaining balance of virtual
              game units – the Coins and Skins which were available at the User Account Balance
              shall be annulled without any compensation towards the User. After termination of the
              present agreement the User has no right to raise any claims to the Website and/or
              Gamevio Ltd.
            </p>
            <p>
              3.13. In the event of misconduct by a User, Gamevio Ltd. is entitled to immediately
              terminate the Agreement with the User and exclude him from further use of the Website.
              Further, We are entitled to interrupt and terminate on-going activities, to block the
              User or the User's Account with or without prior notice and to retain the User's
              credit and winnings achieved through misconduct until clarification of the situation.
            </p>
            <p>
              3.14. Any indication of fraud, manipulation, cash-back arbitrage, or other forms of
              deceitful or fraudulent activity which was the basis of a provision of a bonus or
              otherwise will render the Used Account inactive along with any and all profits or
              losses gathered.
            </p>
            <p>
              3.15. Using the Website or other Services you can exchange any digital items available
              on the Website or other Services. All Skins that you have redeemed from the Services
              are to be used solely for personal gameplay within the Steam platform. Any Skins that
              you have redeemed from the Services cannot be sold or exchanged for cash, cash
              equivalents, or other things of value on the Service or elsewhere (“Real-World
              Trading”). If we, in our sole and absolute discretion, determine that you engaged in
              Real-World Trading, we reserve the right to immediately suspend or terminate your
              account indefinitely.
            </p>
          </Dropdown>

          <Dropdown title={'4. Game rules'}>
            <p>
              4.1. In order to place a Bet with an account on the Website, the User must:
              <br />
              <span>a) purchase Coins.</span>
              <br />
              <span>b) Use Skins.</span>
              <br />
              <span>c) Deposit money (USD, EUR, RUR) to the Users Account Balance.</span>
            </p>
            <p>
              4.2. The Website allow Users to acquire and lose Virtual Game Units via participating
              in Games.
            </p>
            <p>
              4.3. The Website may provide services to Users on paid and/or on a pro bono basis
              depending on a method of receiving Virtual Game Units and attributes by the User.
            </p>
            <p>
              4.4. Virtual Game Units on the User`s Account Balance can be spent by him/her for
              placing Bets at the Website as described below.
            </p>
            <p>
              4.5. It is possible to exchange one Virtual Game Unit for another (Coins for Skins,
              Skins for Coins) in "Coins and Items store". Exchange of one type of Virtual Game Unit
              for another is a final transaction and cannot be canceled. All actions performed by
              You at the Website are final and cannot be canceled upon Your demand.
            </p>
            <p>
              Gamevio Ltd. can cancel Users Bets without prior notice. In this case Virtual Game
              Units placed as a Bet shall be returned to the User`s Account Balance.
            </p>
            <p>
              4.6. Bet is being made by debiting Coins, Skins or money from the Users Account
              Balance (the amount of Skins or Coins displayed in virtual units of the Website, for
              the convenience of the User, this Users Account Balance becomes available after
              authorization of the User at the Website).
            </p>
            <p>
              4.7. The User confirms that he/ she knows and understands the rules of Games offered
              by the Website. It is at User’s discretion to know the payout percentage of each game.
            </p>
            <p>4.8. Once any Bet has been submitted, it cannot be altered or cancelled.</p>
            <p>
              4.9. Winnings on any Bet shall be credited to the User Account Balance and the User is
              able to view any such Winnings on their User Account on the Website. In addition,
              Gamevio Ltd. may decide, but is not obliged, to inform the User with respect to
              Winnings by email or other ways of communication.
            </p>
            <p>
              4.10. There are several games available on the Website, and some may be added later
              after the Platform’s launch. For more information regarding Games and its rules please
              refer to the FAQ page, available via address
              <Link to="/faq">{` ${address}/faq`}</Link>. By playing Games you agree to be bound by
              Games rules stipulated at the FAQ page mentioned aforesaid.
            </p>
          </Dropdown>

          <Dropdown title={'5. Use of user’s account'}>
            <p>
              5.1. Each User can create only one personal user account. Creating multiple user
              accounts by a User can lead to termination of all the accounts. The User shall not
              provide access to his user account or allow using the Website to any third party
              including but not limited to minors.
            </p>
            <p>
              5.2. Also any returns, winnings or bonuses which User has gained or accrued during
              such time as the Duplicate Account was active will be forfeited by you and may be
              reclaimed by us, and you will return to us on demand any such funds which have been
              withdrawn from the Duplicate Account.
            </p>
            <p>
              5.3. The Website can only be used for personal purposes and shall not be used for any
              type of commercial profit.
            </p>
            <p>
              5.4. User acknowledges and agrees that Gamevio Ltd. reserves the right to close Your
              Account and withhold all funds credited at Users Account Balance if such User are
              trying to take advantage from Gamevio Ltd. Website, Games, welcome offers / other
              promotions, for example by:
              <br />
              <span>a) Using stolen cards or cryptocurrency wallets;</span>
              <br />
              <span>b) Performing chargebacks;</span>
              <br />
              <span>
                c) Creating more than one account in order to get advantage from Gamevio Ltd.
                promotions and Games, or for any other purposes
              </span>
              <br />
              <span>d) Providing incorrect registration data;</span>
              <br />
              <span>
                e) Using malicious software to take advantage of Gamevio Ltd. algorithms (I.e. bots,
                viruses, its)
              </span>
              <br />
              <span>g) Performing any other actions which may damage the Gamevio Ltd.</span>
            </p>
            <p>
              5.5. Gamevio Ltd. also reserves the right to close Users Account and to refund User
              the funds credited at Users Account Balance, subject to the deduction of relevant
              withdrawal charges, at Gamevio Ltd.’s absolute discretion and without any obligation
              to state a reason or give prior notice.
            </p>
            <p>
              5.6. Gamevio Ltd. shall not be liable for losses of any kind incured by the User in
              the the event if Users account in STEAM and/or Google + and/or VK and/or Twitter
              and/or Facebook and/or Yandex has/have been terminated on the basis of using the
              Website. By using the Website through STEAM and/or Google + and/or VK and/or Twitter
              and/or Facebook and/or Yandex a User agrees that there are risks of STEAM and/or
              Google + and/or VK and/or Twitter and/or Facebook and/or Yandex account suspension. By
              using the Website, User understands and accepts (1) such risks and (2) that all
              related consequences shall be born by the User, not Gamevio Ltd.
            </p>
          </Dropdown>

          <Dropdown title={'6. Fees and taxes'}>
            <p>
              6.1. The User is fully responsible for paying all fees and taxes applied to their
              winnings according to the laws of the jurisdiction of User’s residence.
            </p>
            <p>
              6.2. In countries where the report of winnings and losses is legally prescribed by
              legal authority or financial institution, the User is responsible for reporting
              his/her winnings and losses such authorities.
            </p>
          </Dropdown>

          <Dropdown title={'7. Referral program'}>
            <p>
              7.1. For the purpose of attracting new Users the Service introduces Referral program
              (hereinafter the "Program"). The Program provides Users with the opportunity to refer
              other individuals to join the Gamevio Ltd. Website as new Users. Details of Referral
              Program is available at
              <Link to="/bonuses/referral">{` ${address}/bonuses/referral`}</Link>.
            </p>
            <p>
              7.2. After a Referred User creates an account on the Website and confirms his email,
              the Referred User get a free Skins or Coins. The user may receive Skins in STEAM and
              the game Counter-Strike: Global Offensive or DOTA 2.
            </p>
            <p>
              7.3. All Referral Program remunerations are added automatically to the User’s account
              and can be used at his discretion in accordance with present Terms.
            </p>
            <p>
              7.4. When distributing, promoting or communicating their Referral Code(s) Users must
              agree to the following restrictions:
              <br />
              <span>
                7.4.1 No spamming. Users agree that they will not "spam" anyone with invitations to
                join the Website, and that at all times they will remain compliant with all
                applicable laws. The following specific activities are prohibited:
                <br />
                <span>
                  a. Mass emailing, texting or messaging people they do not personally know;
                </span>
                <br />
                <span>
                  b. Use of automated systems or bots through any channel to distribute, post or
                  respond to their Referral Code;
                </span>
                <br />
                <span>
                  c. Use of scripts, programed or automatic dialers to send invites or to
                  communicate Referral Codes;
                </span>
                <br />
                <span>
                  d. Posting referral codes on event or venue pages without express permission from
                  the owner.
                </span>
              </span>
              <br />
              <span>
                7.4.2 No misrepresentations. The Registered Users agree they you will not attempt to
                mislead anyone in connection with the Referral Program, either by affirmative
                representation, implication, or omission. In particular, they agree that they will
                not:
                <br />
                <span>a. Impersonate any person or entity;</span>
                <br />
                <span>
                  b. Create fake accounts, blogs, webpages, profiles, Website, links or messages;
                </span>
                <br />
                <span>
                  c. Misrepresent their relationship with Gamevio Ltd. or any other third party;
                </span>
                <br />
                <span>
                  d. Suggest that an affiliation or partnership exists with a third party where none
                  exists. Make misrepresentations with respect to the characteristics or
                  qualification requirements for any referral rewards.
                </span>
              </span>
              <br />
              <span>
                7.4.3. No prohibited content. Users agree that they will not use the Gamevio Ltd.
                brand in connection with:
                <br />
                <span>
                  a. Disparaging or defamatory content concerning Gamevio Ltd. or third parties;
                </span>
                <br />
                <span>
                  b. Content which promotes racism, bigotry, hatred, discrimination or physical harm
                  of any kind against any group or individual;
                </span>
                <br />
                <span>c. Offensive, abusive, intimidating or harassing content;</span>
                <br />
                <span>d. Content that is sexually explicit, obscene and/or contains nudity;</span>
                <br />
                <span>e. Any political and/or religious statement;</span>
                <br />
                <span>
                  f. Content containing images or reference to drugs, alcohol, tobacco, weapons or
                  firearms; Content that violates someone else’s privacy.
                </span>
              </span>
              <br />
              <span>
                7.4.4. Other restrictions. Users also agree that they will not:
                <br />
                <span>
                  a. Open accounts with their affiliate links and with a single IP address;
                </span>
                <br />
                <span>
                  b. Create Website, domains, URLs, social media handles or email addresses
                  containing the word “Gamevio Ltd.” or “CSGOFAST”;
                </span>
                <br />
                <span>
                  c. Engage in fraudulent activity.Users agree that they and their referrals will
                  not defraud or abuse (or attempt to defraud or abuse) Gamevio Ltd., the terms of
                  the Gamevio Ltd. Referral Program, or any invited users.
                </span>
              </span>
            </p>
            <p>
              7.5. Gamevio Ltd. reserves the right to disqualify any User and/or cancel any
              Reward(s) it finds to be violating these Terms and Conditions. Referrals generated by
              a script, macro or other automated means will be disqualified.
            </p>
          </Dropdown>

          <Dropdown title={'8. Disclaimer of liability'}>
            <p>
              8.1. The User is aware of the fact that gambling at the Website may lead to losing
              money. Gamevio Ltd. is not liable for any possible financial damage arising from the
              use of the website
            </p>
            <p>
              8.2. Gamevio Ltd. is taking effective measures to protect User’s private data from any
              unauthorized use and is only making it available to parties involved in providing of
              gambling services through the website. Notwithstanding this, Gamevio Ltd. is not
              responsible for how the information is further treated by third parties, for example
              third party software providers or affiliates. Treatment of User’s private data by such
              parties is subject to terms and conditions of these parties, if any.
            </p>
            <p>
              8.3. Gamevio Ltd. is in no way responsible for any access to a User's account by a
              third person and will not be held responsible for any loss suffered due to the illicit
              use of a User's password by a third person, of unauthorized access, and/or for any
              transaction in which the name and password of a User was registered correctly. In this
              point also loss of access or the password from social networks or STEAM of the account
              of the user through which the entrance on the website is carried out means.
            </p>
            <p>
              8.4. Gamevio Ltd. is not liable of any hardware or software, defects, unstable or lost
              Internet connections, or any other technical errors that may limit User’s access to
              the Website or prevent User from an uninterrupted play.
            </p>
            <p>
              8.5. Some circumstances may arise where a wager is confirmed, or a payment is
              performed, by us in error. In all these cases Gamevio Ltd. reserves the right to
              cancel all the wagers accepted containing such an error, or to correct the mistake
              made re-settling all the wagers at the correct prices/spreads/terms that should have
              been available at the time that the wager was placed in the absence of the error.
            </p>
            <p>
              8.6. If we mistakenly credited your Account with winnings that do not belong to you,
              whether due to a technical, error in the pay-tables, or human error or otherwise, the
              amount will remain our property and will be deducted from your Account. If you have
              withdrawn funds that do not belong to you prior to us becoming aware of the error, the
              mistakenly paid amount will (without prejudice to other remedies and actions that may
              be available at law) constitute a debt owed by you to us. In the event of an incorrect
              crediting, you are obliged to notify us immediately by email.
            </p>
            <p>
              8.7. Gamevio Ltd., its directors, employees, partners, service providers:
              <br />
              <span>
                a) do not warrant that the software or the Website is/are fit for their purpose;
              </span>
              <br />
              <span>b) do not warrant that the software and Website are free from errors;</span>
              <br />
              <span>
                c) do not warrant that the Website and/or Games will be accessible without
                interruptions;
              </span>
              <br />
              <span>
                d) shall not be liable for any loss, costs, expenses or damages, whether direct,
                indirect, special, consequential, incidental or otherwise, arising in relation to
                Your use of the Website or Your participation in the Games.
              </span>
              <br />
            </p>
            <p>
              8.8. You hereby agree to fully indemnify and hold harmless Gamevio Ltd., its
              directors, employees, partners, and service providers for any cost, expense, loss,
              damages, claims and liabilities howsoever caused that may arise in relation to your
              use of the Website.
            </p>
            <p>
              8.9. You acknowledge that Gamevio Ltd. shall be the final decision-maker of whether
              you have violated Gamevio Ltd. rules, terms or conditions in a manner that results in
              your suspension or permanent barring from participation in our site.
            </p>
            <p>
              8.10. The Website can only be used for personal purposes and shall not be used for any
              type of commercial profit.
            </p>
          </Dropdown>

          <Dropdown title={'9. Social responsibility and self protection'}>
            <p>
              9.1. The Users are aware of the risks of excessive gambling. In order to minimize the
              risks the User is able to voluntarily put limits through our support on the Player's
              gaming in the following ways:
              <br />
              <span>
                a. Set a limit on the total amount the User may wager during a certain time period
                by contacting support.
              </span>
              <br />
              <span>
                b. Set a limit on the total losses the User is allowed to incur during a certain
                time period by contacting Support.
              </span>
              <br />
              <span>
                c. Set a limit of the time during which the User is allowed to play during a certain
                session.
              </span>
              <br />
              <span>
                d. Set a limit on the amount that the User is allowed to deposit into the User's
                Account Balance per day/month/year.
              </span>
              <br />
              <span>
                e. Self-Exclusion of the User’s Account. A Self-Exclusion of the Users Account can
                have the following effective periods: Six months.
              </span>
            </p>
            <p>
              9.2. The above Gaming Limitations are carried out by contacting our support on the
              website. A request to Self-Exclude is a formal acknowledgement by You as a User that
              You are experiencing problems with your gambling and wish to take steps to address
              these problems. Gamevio Ltd. expects Users to abide by this Self-Exclusion agreement
              and not try to breach it by trying to open additional accounts on the Website.
            </p>
          </Dropdown>

          <Dropdown title={'10. Payment'}>
            <p>
              10.1. Gamevio Ltd, with registration number HE 404862 and registered address at
              Loutrakiou 5, Chara Benezia Bld, 1st floor, Office 101, Strovolos, 2027, Nicosia,
              Cyprus, is solely responsible for processing its own payments. The relevant payment
              methods available on the payment page are managed and facilitated directly by Gamevio
              Ltd.
            </p>
            <p>
              10.2. The prices for Coins and Skins on the Website are estimated by Gamevio Ltd. and
              can be changed on Gamevio Ltd. sole discretion. The prices are specified on the
              corresponding page of the Website.
            </p>
            <p>
              10.3. The User has the right to pay the Website Services with one of the payment
              methods provided on the Website. Payment is made by the User by means of the
              aggregator of an electronic payment system (an electronic payment service provider)
              allowing in real time through the Internet to pay for services available at Website.
            </p>
            <p>
              10.4. Payment service provider may charge the commission from the User by rules of a
              payment service provider (electronic payment system). Gamevio Ltd. is not responsible
              for charging of such commissions of payment service providers.
            </p>
            <p>
              10.5. When the Users Account Balance is replenished by any of the available methods,
              the User guarantees that the funds or Skins used by him are owned only by him and
              received by lawful means. Gamevio Ltd. prohibits the use of third party funds,
              including relatives, friends, partners of the User, etc. The User is obliged to
              deposit funds only from his account, payment card or payment system registered for the
              name of the User. In case of revealing the facts of violation of this condition, the
              User"s may be blocked and/or his/her account terminated.
            </p>
          </Dropdown>

          <Dropdown title={'11. Avoidance of discrepancies'}>
            <p>
              11.1. The Website use random generation software (random number generator) in order to
              provide entertaining services to Users and to determine the result of a game.
            </p>
            <p>
              11.2. The User has no risk of money loss when he is placing Coins or Skins as Bets and
              any result of a game can not return the User his/her money. Refunds can only be made
              for cash-paid games.
            </p>
            <p>
              11.3. The User agrees that he/she will not try to conclude any transactions, which may
              result to exchange of the Virtual Game Units of the Website for money and/or for any
              material values and law of obligations anywhere and by any method neither on the
              Website nor out of the Website.
            </p>
          </Dropdown>

          <Dropdown title={'12. Intellectual property'}>
            <p>
              12.1. The Website, services offered by Gamevio Ltd., including, but not limited to
              inventions, processes, marks, methods, compositions, formulae (-s), techniques, data,
              text, graphics, logos, and charts, as well as any compilation thereof, and any
              software used on the Website, is the property of Gamevio Ltd. and protected by
              applicable copyright laws. Accordingly, You may not copy, distribute, modify, post or
              frame-in the Website, including any text, graphics, video, audio, software code,
              Purchaser interface design or logos.
            </p>
            <p>
              12.2. Unless You and Gamevio Ltd. have agreed otherwise, links from another website
              (-s) to the Website must resolve to the top-level homepage of the Website domain.
            </p>
          </Dropdown>

          <Dropdown title={'13. Third party content'}>
            <p>
              13.1. The Website may include general news and information, commentary, interactive
              tools, quotes, research reports and data concerning the financial markets,
              cryptocurrency market, securities and other subjects. Some of this content is supplied
              by companies that are not affiliated with Gamevio Ltd. (hereinafter referred to as
              "Third Party Content"). On the Website, the source of all Third Party Content is
              clearly and promingh hyperlinks to third party Website, or is simply published on the
              Website. The Third Party Content is protected by copyright pursuant to the laws of
              Cyprus.
            </p>
            <p>
              13.2. Gamevio Ltd. have not been involved in the preparation, adoption or editing of
              Third Party Content but may approve or disapprove such content.
            </p>
            <p>
              13.3. While Gamevio Ltd. make every attempt to provide You with accurate and timely
              information to serve Your needs, Gamevio Ltd. do not guarantee accuracy, timeliness,
              completeness or usefulness of Third Party Content, and shall not be responsible or
              liable for any such content, including any advertising, products, or other materials
              on or available from third party Website. Third Party Content is provided for
              informational purposes only and Gamevio Ltd. B.V. specifically disclaim any
              responsibility for Third Party Content available on the Website. You will use Third
              Party Content only at Your own risk.
            </p>
            <p>
              13.4. the third party content is provided on an "as-is" basis. Gamevio ltd. expressly
              disclaims all warranties of any kind, express or implied, including without limitation
              any warranty of merchantability, fitness for a particular purpose or non-infringement.
            </p>
            <p>
              13.5. Gamevio ltd.’s affiliates, subsidiaries, service providers, licensors, officers,
              directors or employees shall not be liable for any direct, indirect, incidental,
              special or consequential damages arising out of or relating to the use or the
              inability to use the third party content, including but not limited to damages for
              loss of profits, use, data or other intangible damages.
            </p>
            <p>
              13.6. In order to comply with applicable laws and regulations, Gamevio Ltd. reserve
              the right at times to exclude any content at Gamevio Ltd. sole discretion.
            </p>
          </Dropdown>

          <Dropdown title={'14. Disclaimer of warranties'}>
            <p>
              14.1. Gamevio ltd. does not make any express or implied warranties about the website,
              including but not limited to implied warranties of merchantability, fitness for a
              particular purpose, or non-infringement. the website is made available to you "as is"
              and "as available”. Gamevio ltd. will not be liable for discrepancy of user"s
              subjective impression of the website and website services with user`s expectations.
            </p>
            <p>
              14.2. Gamevio ltd. is not responsible for what impact and impressions of the website
              design, fonts and style of content placement on the website have on users.
            </p>
            <p>
              14.3. Gamevio ltd. does not warrant that the website will certainly or in any other
              way meet your needs, or that it will be uninterrupted, timely, secure and / or
              error-free. Gamevio ltd. also make no warranty that the results obtained from the use
              of the website will be accurate or reliable, or that the quality of any products,
              services, information, or other material available at the website will meet your
              expectations.
            </p>
            <p>
              14.4. Gamevio ltd. does not warrant any winnings or positive results that you may
              achieve by using the website.
            </p>
          </Dropdown>

          <Dropdown title={'15. Limitation of liability'}>
            <p>
              15.1. Gamevio ltd. will not be liable to you or anyone else for any consequential,
              incidental, special, direct, or indirect damages (including but not limited to lost
              profits, trading losses or damages that result from use or loss of use of the website
              and third party content, inconvenience or delay).
            </p>
            <p>
              15.2. Gamevio ltd. will not be liable to you or anyone else for any loss resulting
              from a cause over which Gamevio ltd. do not have direct control (force majeure). this
              includes acts of war, tempest, fire, earthquake or any other natural disaster of
              overwhelming proportions, insurrection, terrorist act, riot, commotion, strikes, go
              slows, lock outs or disorder, accident, emergency or action of government, failure of
              electronic or mechanical equipment or communications lines (including telephone, cable
              and internet), unauthorized access, viruses, trojan viruses theft, operator errors.
            </p>
          </Dropdown>

          <Dropdown title={'16. Indemnification'}>
            <p>
              16.1. You consent to defend and indemnify Gamevio Ltd., its officers, directors,
              employees, and agents and to hold them harmless from and against any and all types of
              liabilities, claims, damages, losses, and/or expenses. This clause includes without
              limitation reasonable attorney’s fees and costs that arises out of or which may be in
              any way connected with your access to the use of the Website or Services or of the
              violation or breach of any of the terms in this Terms or any applicable laws or
              regulations.
            </p>
          </Dropdown>

          <Dropdown title={'17. Changes to the website'}>
            <p>
              17.1. Unless otherwise agreed, Gamevio Ltd. may discontinue or modify the Website at
              any time without prior notice to You, and You accept those modifications if You
              continue to use the Website.
            </p>
            <p>
              17.2. Any changes in these rules and other documents come into force after your
              repeated entrance on the website
            </p>
          </Dropdown>

          <Dropdown title={'18. Applicable legislation'}>
            <p>
              18.1. Unless otherwise agreed, these Terms and their enforcement are governed by the
              legislation of Cyprus.
            </p>
          </Dropdown>

          <Dropdown title={'19. Dispute resolution'}>
            <p>
              19.1. If any claim or dispute arises out of or in regard to this Terms, the parties
              hereto will use their reasonable best efforts to resolve any dispute hereunder through
              good faith negotiations to come to an arrangement. For customer service inquiries or
              disputes, User shall contact Gamevio Ltd. <b>SOLELY</b> by email at{' '}
              <a href="mailto:support@csgofast.com">support@csgofast.com</a>. If the dispute cannot
              be settled and the parties fail to come to an agreement, either party may decide to
              submit the dispute to confidential arbitration proceedings by a sole arbitrator under
              the ICC ADR Rules, whose decision shall be final and binding. The arbitration
              proceedings shall be conducted in Cyprus or another place agreed in writing by both
              parties.
            </p>
            <p>
              19.2. Section 19.1. sets exclusive jurisdiction to the arbitration process, and
              neither the Gamevio Ltd., neither the User shall be entitled to submit any dispute to
              the courts of its residence that contradicts to the arbitration process aforesaid.
            </p>
          </Dropdown>
        </div>
      </Container>
    </>
  );
};
