import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { QuestProgress } from './QuestProgress';
import { gameIconConfig } from '../../quests.utils';
import { IQuest } from '../../interfaces/reducer.leaders.interfaces';

interface IQuestPageItem extends IQuest {
  takeRewarHandle: () => void;
}

export const QuestPageItem: React.FC<IQuestPageItem> = ({
  type,
  isHidden,
  isOpened,
  userCount,
  targetCount,
  gameTypeId,
  rewardTaken,
  name,
  params,
  takeRewarHandle,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Container type={type} isHidden={isHidden} isActive={isOpened && userCount !== targetCount}>
        <CircleContainer
          type={type}
          isActive={isOpened && userCount !== targetCount}
          isComplite={userCount === targetCount}
        >
          <QuestProgress
            dashed={false}
            type={type}
            isOpened={isOpened}
            userCount={userCount}
            targetCount={targetCount}
            rewardTaken={rewardTaken}
            isHidden={isHidden}
            gameTypeId={gameTypeId}
          />
        </CircleContainer>
        <DescriptionContainer>
          {isHidden && !isOpened ? (
            <span>{t('Special quest. Unlocked after completing all weekly quests')}</span>
          ) : (
            <span>
              {t(name, {
                ...params,
                postProcess: 'with-template',
              })}
              {gameIconConfig[gameTypeId.toString()] && (
                <span className="type_game">{gameIconConfig[gameTypeId.toString()].name}</span>
              )}
            </span>
          )}
        </DescriptionContainer>
      </Container>
      {Number(userCount) >= targetCount && !rewardTaken && (
        <QuestAction onClick={takeRewarHandle}>{t('Take')}</QuestAction>
      )}
    </>
  );
};

type IQuestPageItemStyle = {
  type?: string;
  isHidden?: boolean;
  isActive?: boolean;
  isComplite?: boolean;
};

const Container = styled.div<IQuestPageItemStyle>`
  display: flex;
  flex-flow: row;
  margin: 0.3rem 0;
  min-height: 140px;
  border-radius: 0.5rem;
  background-color: var(--gray-350);
  position: relative;
  z-index: 2;

  ${p =>
    p.type === 'weekly' &&
    `
    border-radius: 0 0.5rem 0.5rem 0;
    margin: 1px 0;

    &:before {
      content: '';
      display: block;
      width: ${p.isActive ? 8 : 2}px;
      background: var(--${p.isHidden ? 'special' : 'weekly'}-quest);

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    } 
  `};
`;

const CircleContainer = styled.div<IQuestPageItemStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 35%;
  position: relative;

  ${p =>
    p.type === 'weekly' &&
    `
    &:before,
    &:after {
      content: ':';
      font-size: 2rem;
      color: #4d5051;
      position: absolute;
      left: calc(50% - 3px);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 3px;
    }


    ${
      p.isComplite &&
      `
      &:before,
      &:after {
        color: var(--fast-race);
      }
    `
    };

    ${
      p.isActive &&
      `
      &:before {
        color: var(--fast-race);
      }
    `
    };
  `};
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 65%;
  padding: 0.5rem;

  font-size: 0.8rem;
  color: var(--color-white);
  text-align: left;
  line-height: 1.4;

  & span[class='type_game'] {
    color: var(--yellow);
  }
`;

const QuestAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0 0.15rem;
  padding-top: 10px;
  background: var(--yellow);
  border-radius: 0 0 0.5rem 0.5rem;

  position: relative;
  top: -10px;
  z-index: 1;

  color: var(--color-dark-600);
  font-size: 0.9rem;
  font-weight: bold;
`;
