import React from 'react';
import {
  Container,
  HeaderContainer,
  ListContainer,
  StyledButton,
  StyledLink,
  Title,
  TitleContainer,
} from './CaseItems.styles';
import { ItemsModified } from 'games/CaseGame/interfaces';
import { CaseItem } from './components';
import { useDialog } from 'core/hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'games/CaseGame/hooks';
import { CaseItemsDialogConfig } from 'games/CaseGame/dialog-config';
import { isEqual } from 'lodash';
import { actionRevisionRequest } from 'games/CaseGame/ducks';
import { CaseEnum } from 'games/CaseGame/constants';

interface CaseItemsListProps {
  caseItems: Record<string, ItemsModified>;
  isFree: boolean;
  caseType: CaseEnum;
}

export const CaseItemsList: React.FC<CaseItemsListProps> = React.memo(
  ({ caseItems, isFree, caseType }) => {
    const { isOpen, toggle } = useToggle();
    const { handleOpenDialog } = useDialog(CaseItemsDialogConfig.LAST_REVISION);
    const dispatch = useDispatch();
    const { t } = useTranslation('cases2.0');

    const { caseId } = useParams<Record<string, string>>();

    const clickHandler = () => {
      dispatch(actionRevisionRequest(caseId));
      handleOpenDialog();
    };
    return (
      <Container className="tablet">
        <HeaderContainer>
          <TitleContainer>
            <Title>{t('caseItems')}</Title>
            <StyledButton onClick={toggle}>{isOpen ? t('closeItems') : t('open')}</StyledButton>
          </TitleContainer>
          {(!isFree || caseType !== CaseEnum.FREE) && (
            <StyledLink onClick={clickHandler}>{t('caseRevision')}</StyledLink>
          )}
        </HeaderContainer>
        <ListContainer className="tablet" isOpen={isOpen}>
          {Object.values(caseItems).map(({ generalInfo, customItemInfo }) => (
            <CaseItem
              isFree={isFree}
              caseType={caseType}
              key={generalInfo.fullName}
              {...generalInfo}
              customItemInfo={customItemInfo}
            />
          ))}
        </ListContainer>
      </Container>
    );
  },
  isEqual
);
