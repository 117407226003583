import React from 'react';
import { Container, TextContainer, Title, Text } from './bonus.styles';

import { useTranslation } from 'react-i18next';
import { useUserReferrerInfo } from 'core/User';
import { RefillIcons } from 'modules/Refill/assets';

export enum ReferralCampaignTypes {
  PERCENT = 'gofast-custom-percent',
  MONEY = 'gofast-custom-money',
  GOFAST = 'gofast',
  // чисто хэл история пока не юзается
  CASES = 'cases',
}

export const Bonus = () => {
  const { t } = useTranslation();
  const { referrerInfo } = useUserReferrerInfo();

  return (
    !Boolean(referrerInfo.rewarded) &&
    Boolean(referrerInfo.reward) && (
      <Container>
        <RefillIcons.Gift />
        <TextContainer>
          <Title>{t('bonus')}</Title>
          <Text>
            {referrerInfo.reward}% {t('topUp')}
          </Text>
        </TextContainer>
      </Container>
    )
  );
};
