import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent, LotteryType } from 'core/interfaces';

interface ILotteryRulesDialog extends IDialogComponent<boolean> {}

export const LotteryRulesDialog: FC<ILotteryRulesDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation('lottery');

  return (
    <Container>
      <Button className="link-light" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <div className="title">{t('How to participate in regular skin raffle?')}</div>
      <div className={`rule ${LotteryType.FREE}`}>
        <div>
          <span />
        </div>
        <div>
          <p>{t('lottery-type', { context: LotteryType.FREE })}</p>
          <p>{t('lottery-type-description', { context: LotteryType.FREE })}</p>
        </div>
      </div>
      <div className={`rule ${LotteryType.DEPOSIT}`}>
        <div>
          <span />
        </div>
        <div>
          <p>{t('lottery-type', { context: LotteryType.DEPOSIT })}</p>
          <p>
            {t('lottery-type-description', {
              context: LotteryType.DEPOSIT,
              amount: 500,
              postProcess: 'with-template',
            })}
          </p>
        </div>
      </div>
      <div className={`rule ${LotteryType.WEEKLY}`}>
        <div>
          <span className={LotteryType.WEEKLY} />
        </div>
        <div>
          <p>{t('lottery-type', { context: LotteryType.WEEKLY })}</p>
          <p>
            {t('lottery-type-description', {
              context: LotteryType.WEEKLY,
              amount: 500,
              postProcess: 'with-template',
            })}
          </p>
        </div>
      </div>
      <div className={`rule ${LotteryType.MONTHLY}`}>
        <div>
          <span className={LotteryType.WEEKLY} />
          <span className={LotteryType.MONTHLY} />
        </div>
        <div>
          <p>{t('lottery-type', { context: LotteryType.MONTHLY })}</p>
          <p>
            {t('lottery-type-description', {
              context: LotteryType.MONTHLY,
              amount: 2000,
              postProcess: 'with-template',
            })}
          </p>
        </div>
      </div>
      <div className="rule">
        <div>
          <i className="icon-warn" />
        </div>
        <div>
          <p>
            {t('The winner of the raffle will automatically receive a skin in his game inventory')}
          </p>
        </div>
      </div>
      <Button className="primary" onClick={handleCloseModal}>
        {t('Back to rafflings')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(5, min-content) 3.75rem min-content;
  grid-gap: 0.75rem;
  padding: 2rem 1rem 1.75rem;
  max-height: 100%;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  overflow: hidden scroll;
  position: absolute;
  bottom: 0;

  & button.link-light {
    position: absolute;
    top: 0.75em;
    right: 1rem;
    font-size: 0.75rem;
    text-transform: none;
  }

  & .title {
    color: var(--color-white);
    padding: 0 0.5rem;
  }

  & .title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: 0.54px;

    @media (min-width: 375px) {
      font-size: 1.75rem;
    }
  }

  & .rule {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1.75rem 1fr;
    grid-gap: 0.25rem;

    &.free {
      --color-type: var(--color-free-lottery);
    }

    &.deposit {
      --color-type: var(--color-deposit-lottery);
    }

    &.weekly {
      --color-type: var(--color-weekly-lottery);
    }

    &.monthly {
      --color-type: var(--color-monthly-lottery);
    }

    & div:first-of-type {
      position: relative;
      & span {
        display: block;
        width: 1.125rem;
        height: 0.75rem;
        border-radius: var(--size-radius-s);
        background: var(--color-type);
        position: absolute;
        top: 0.125rem;
        left: 50%;
        transform: translate3d(-50%, 0, 0);

        &:nth-child(2) {
          width: 1.25rem;
          height: 0.875rem;
          transform: translate3d(-75%, 0.25rem, 0);
          border: 1px solid var(--color-dark-600);
        }

        &.weekly {
          --color-type: var(--color-weekly-lottery);
        }

        &.monthly {
          --color-type: var(--color-monthly-lottery);
        }
      }
    }

    & i {
      justify-self: center;
      font-size: 1.5rem;
      color: var(--warning);
    }

    & p {
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.570769px;

      &:first-of-type {
        font-weight: bold;
        color: var(--color-type);
      }

      &:last-of-type {
        color: var(--color-white);
      }
    }
  }
`;
