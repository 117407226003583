import { CaseBattleType } from 'games/CaseBattle/ducks/create-battle-duck/create-battle.interfaces';
import React, { FC, memo } from 'react';
import { Container, Dote, Line, RouletteLine } from './RouletteArrowLine.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';

interface RouletteArrowLineProps {
  battleType: CaseBattleType;
}

const isSmallWindowSize = window.innerWidth < 374;

const linesTypes: Record<CaseBattleType, JSX.Element> = {
  2: (
    <RouletteLine data-line-type="2">
      <CaseBattleIcon.RouletteLineArrow />
      <Dote />
      <Line />
      <Dote />
      <Line />
      <Dote />
      <CaseBattleIcon.RouletteLineArrow className="rotate" />
    </RouletteLine>
  ),
  3: (
    <RouletteLine data-line-type="3">
      <CaseBattleIcon.RouletteLineArrow />
      <Dote />
      <Line className="line" />
      <Dote />
      <Line className={`line ${isSmallWindowSize && 'center-line'}`} />
      <Dote />
      <Line className="line" />
      <Dote />
      <CaseBattleIcon.RouletteLineArrow className="rotate" />
    </RouletteLine>
  ),
  4: (
    <RouletteLine data-line-type="4">
      <CaseBattleIcon.RouletteLineArrow />
      <Dote />
      <Line className="line" />
      <Dote className="dote-circle" />
      <Line className="line" />
      <Dote className="dote-circle" />
      <Line className="line" />
      <Dote className="dote-circle" />
      <Line className="line" />
      <Dote />
      <CaseBattleIcon.RouletteLineArrow className="rotate" />
    </RouletteLine>
  ),
  '2 x 2': (
    <RouletteLine data-line-type="2 x 2">
      <CaseBattleIcon.RouletteLineArrow />
      <Line className="line" />
      <Dote className="dote-absolute" />
      <CaseBattleIcon.RouletteLineArrow className="rotate" />
    </RouletteLine>
  ),
  '2 x 2 TEAM': null,
};
//NOTE: 2x2 and 2x2 TEAM have similar types of structure for roulette line , so in case of team game we will have only 2x2 for both of types
export const RouletteArrowLine: FC<RouletteArrowLineProps> = memo(({ battleType }) => {
  if (!battleType) {
    return null;
  }
  return <Container>{linesTypes[battleType]}</Container>;
});
