import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserWinningItems } from '../selectors';

import { fetchUserWinningItems, selectItemsToBuy } from '../duck';

import { Multiple } from '../components/Multiple';
import { Single } from '../components/Single';

class PrizeContainer extends Component {
  static Single = Single;

  componentDidMount() {
    this.props.fetchUserWinningItems();
  }

  render() {
    if (!this.props.items) return null;
    return <Multiple {...this.props} />;
  }
}

export const Prize = connect(
  (state, ownProps) => ({
    items: getUserWinningItems(state, ownProps.gameTypeId),
  }),
  { selectItemsToBuy, fetchUserWinningItems }
)(PrizeContainer);
