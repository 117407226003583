import styled from 'styled-components';

export const Container = styled.div<{ isTeam: boolean; gap: number; playersCount: number }>`
  align-items: stretch;
  display: grid;
  gap: ${({ isTeam, gap }) => (isTeam ? '15px' : `${gap}px`)};
  ${({ playersCount, isTeam }) =>
    `grid-template-columns: repeat(${playersCount}, ${isTeam ? 160 : 80}px)`};

  @media screen and (max-width: 374px) {
    ${({ playersCount, isTeam }) =>
      `grid-template-columns: repeat(${playersCount}, ${isTeam ? 132 : 66}px)`};
  }

  justify-content: center;
  justify-items: center;
  margin-top: 40px;
  position: relative;
`;

export const TeamsContainer = styled.div<{ playersCount: number }>`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(${({ playersCount }) => playersCount}, 80px);

  @media screen and (max-width: 374px) {
    grid-template-columns: repeat(${({ playersCount }) => playersCount}, 66px);
  }

  justify-content: space-around;
  width: 100%;
`;

export const TeamTitle = styled.span`
  color: var(--color-gray-200);
  font-size: 12px;
  font-style: normal;
  left: 50%;
  letter-spacing: 0.1px;
  line-height: 15px;
  position: absolute;
  text-align: center;
  top: -27px;
  transform: translateX(-50%);
  width: 32px;
`;
