import React, { FC, useEffect, useMemo } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { GamePage } from './game-page';
import { useDispatch } from 'react-redux';
import { registerBaccaratModule, unregisterBaccaratModule } from '../duck';
import { RulesPage } from './rules-page';
import { HistoryPage } from './history-page';
import '../locales';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();

  useMemo(() => {
    registerBaccaratModule();
  }, []);

  useEffect(() => {
    return () => {
      unregisterBaccaratModule();
    };
  }, [dispatch]);

  return (
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/rules`} component={RulesPage} />
        <Route path={`${match.url}/history`} component={HistoryPage} />
        <Route path={match.url} component={GamePage} />
      </AnimatedRouter>
    </div>
  );
};
