import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { List } from '../components/List';
import { GeneralRulesAndQuestions } from '../components/GeneralRulesAndQuestions';
import { BetQuestions } from '../components/BetQuestions';
import { GettingYourWinning } from '../components/GettingYourWinning';
import { ReferralProgram } from '../components/ReferralProgram';
import { ServiceCommission } from '../components/ServiceCommission';
import { ChatRules } from '../components/ChatRules';

export const FAQ = ({ match, location }) => (
  <Switch>
    <Route path={`${match.url}/common`} component={GeneralRulesAndQuestions} />
    <Route path={`${match.url}/bet-questions`} component={BetQuestions} />
    <Route path={`${match.url}/getting-your-win`} component={GettingYourWinning} />
    <Route path={`${match.url}/referral-program`} component={ReferralProgram} />
    <Route path={`${match.url}/service-commission`} component={ServiceCommission} />
    <Route exact path={`${match.url}/chat-rules`} component={ChatRules} />

    <Route path={match.url} component={List} />

    <Redirect
      to={{
        pathname: match.url,
        state: { from: location },
      }}
    />
  </Switch>
);
