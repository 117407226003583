import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { SelfExclusion } from 'modules/PersonalArea/components/SelfExclusion';

export const SelfExclusionPage: FC = () => (
  <>
    <AppBar deep />
    <SelfExclusion />
  </>
);
