/* eslint-disable */

export default {
  GAME: 'ИГРА',
  Winner: 'Победитель',
  Won: 'Выиграл',
  Chance: 'Шанс',
  Prize: 'Приз',
  'At stake': 'На кону',
  'Sign in through STEAM to participate': 'Для участия войдите через STEAM',
  'Steam trade link': 'Ссылка на обмен в Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Внести депозит первым',
  'Place a bet': 'Сделать ставку',
  'Deposit more': 'Вложить еще',
  'Min bet': 'Мин. ставка',
  'Max bet': 'Макс.',
  'Bet amount': 'Размер ставки',
  'max. items': 'максимум предметов',
  hidden: 'скрыто',
  Copy: 'Скопировать',
  'Winning after commission': 'Выигрыш с учетом комиссии',
  Loading: 'Загрузка',
  'Your bet for ${currency(amount)} was accepted and will added to game in ${time} seconds':
    'Ваша ставка ${currency(amount)} была принята и будет добавлена в игру через {{  time  }} секунды',
  'Verify ticket': 'Проверить билет',
  'Waiting players': 'Ожидание <br/> игрока',
};
