import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px 16px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-dark-100);
  height: 100%;
`;

export const Close = styled.div`
  padding-bottom: 16px;
  display: flex;
  align-self: flex-end;
  color: var(--color-gray-200);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  letter-spacing: 0.1px;
`;

export const CaseSelectionContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  font-feature-settings:
    'clig' off,
    'liga' off;
  color: var(--color-white);
`;

export const RemoveAll = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  letter-spacing: 0.1px;
  color: var(--aqua-500);
`;

export const ListContainer = styled.div`
  display: flex;
  gap: 6px;
  overflow: scroll hidden;

  @media screen and (min-width: 500px) {
    margin: 0 auto;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  background: none;
  bottom: 0;
  backdrop-filter: blur(5px);
  position: sticky;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;
