import styled from 'styled-components';

export const StepStyleContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.75rem;
  padding: 1.875rem 1rem 1.75rem;

  & .step-header {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.25rem;

    &-title {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-gray-50);
      padding: 0 0.25rem;
    }

    &-warning-message {
      display: grid;
      grid-template-columns: 1.75rem 1fr;
      grid-gap: 0 0.75rem;

      & i {
        font-size: 1.75rem;
        color: var(--yellow);
      }

      & p {
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0.570769px;
        color: var(--color-white);

        & a {
          display: inline-block;
          font-size: inherit;
          line-height: inherit;
          text-transform: none;
        }

        &:not(:first-of-type) {
          grid-column: 2 / 3;
        }
      }
    }
  }

  & button {
    & svg {
      width: 1.375rem;
      height: 1.375rem;
      position: absolute;
      left: 1rem;
    }

    & i {
      font-size: 0.675rem;
      position: absolute;
      right: 1rem;
    }

    &.steam-provider {
      background: var(--color-provider-steam);
      color: var(--color-white);
    }
  }
`;
