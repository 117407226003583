import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as ErrorEmailIcon } from './errorEmail.svg';
import { ReactComponent as HelpIcon } from './help.svg';

export const SvgIcons = {
  EditIcon,
  EmailIcon,
  ErrorEmailIcon,
  HelpIcon,
};
