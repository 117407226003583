import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const LobbyRules = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h2 className="title">{t('How and when the raffles will proceed?')}</h2>
      <ul className="step-list">
        <li>
          <span>13</span>
          <p>
            {t(
              'On the 13th of December, registration will be open for the raffles of golden tickets'
            )}
          </p>
        </li>
        <li>
          <span>31</span>
          <p>
            {t(
              'On the 31st of December, there will be 24 independent raffles with an interval of 30 minutes'
            )}
          </p>
        </li>
        <li>
          <img src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/tickets.png" alt="" />
          <p>
            {t(
              'Everyone has a chance to win! The cost of participation varies from 2 to 50 gold tickets depending on the value of the prize'
            )}
          </p>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-gap: 2.375rem;
  min-height: 700px;
  padding: 4rem 1rem 0;

  & .title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2.1875rem;
    text-align: center;
    letter-spacing: 0.54px;
    color: var(--color-white);
    padding: 0;

    @media screen and (min-width: 375px) {
      font-size: 1.875rem;
      padding: 0 3rem;
    }
  }

  & .step-list {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: 3.25rem;

    & li {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 4.75rem 1fr;
      grid-gap: 1rem;
      align-items: center;
      justify-content: center;
      justify-items: center;

      & img {
        width: 65%;
        transform: translateY(-8px);
      }

      & span {
        font-weight: 900;
        font-size: 4rem;
        line-height: 4.625rem;
        text-align: center;
        background: -webkit-linear-gradient(
          180deg,
          #ffb800 18.75%,
          #ffdb0c 39.06%,
          #ffe99a 46.35%,
          #ffb800 73.96%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
      }

      & p {
        font-size: 0.875rem;
        line-height: 1.5rem;
        letter-spacing: 0.54px;
        color: var(--color-white);
      }
    }
  }
`;
