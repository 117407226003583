import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Rules = () => {
  const { t } = useTranslation('double');
  return (
    <div className="content dark-layout">
      <RulesContainer>
        <RulesP>
          <span>Double Game</span>
          {t(' - is a mode of a game, where gamers bet on black, red or green slots')}
          {t(' In this mode Fast Coins are placed into as bets')}
          {t(' The winner slot is determined at the end of the round')}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How can I Get Fast Coins?')} </RulesSpan>
          {t(
            '1 Participate in the Referral program Invite new players to the project with a help of referral links or code You get money for every referral and every game he plays'
          )}
          <br />
          <br />
          {t(
            '2 Exchange your skins worth from $1 for Fast Coins For every cent of skin value you get one coin You can send max 30 items at a time Click here for more information about exchanging We recommend to use Steam Inventory Helper in order to appraise the value'
          )}
          <br />
          <br />
          {t(
            '3 Replenish the balance with a help of Payment System Select the convenient way of payment and get the necessary number of Fast Coins on your account'
          )}
          <br />
          <br />
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How is the winner slot determined (fair game)?')}</RulesSpan>
          {t(
            'At the very beginning of a round the Service generates a random long number from 0 to 1 (eg 0 223088) and encrypts it through the SHA-2 algorithm The result is displayed at the beginning of a round At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot'
          )}
        </RulesP>

        <RulesP>
          {t(
            'An example: At the very beginning of the round the encoded number is 0 223088232334703230728 The Service multiplies it by 15 thus getting number 3 34632348495 As a result the counting number is 3 (the red slot) In this round the winners are those who have placed their bets on red slot'
          )}
        </RulesP>

        <RulesP>
          {t(
            'You can check whether a winner slot is fair or not on your own At the end of a round take the encoded number and encrypt it one more time with a help of any online service, eg You will get the same hash value which was at the very beginning of a round This means that the game was not rigged'
          )}
        </RulesP>

        <RulesP>
          {t(
            "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle"
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('How many bets can I place per one round?')}</RulesSpan>
          {t(
            'You can sweeten an unlimited number of times till the beginning of the round The sum of a bet of every slot can be different'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('What bet limitations are there?')}</RulesSpan>
          {t(
            'The minimum bet is 10 coins On one color, you can put a maximum of 500 000 coins at a time'
          )}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('When does the determination of the winner slot begin?')}</RulesSpan>
          {t('The winner slot is determined at the end of a round')}
        </RulesP>

        <RulesP>
          <RulesSpan>{t('Is there any commission in this mode?')}</RulesSpan>
          {t('There is no commission at all')}
        </RulesP>
      </RulesContainer>
    </div>
  );
};

const RulesContainer = styled.div`
  padding: 0px 10px 10px 10px;

  line-height: 14px;
  letter-spacing: 0.2px;
`;

const RulesP = styled.p`
  color: var(--color-white);
  padding: 9px 0px 8px 0px;
  font-size: 12px;
  & span {
    color: #33ffff;
    font-weight: 700;
  }
`;

const RulesSpan = styled.span`
  color: #33ffff;
  padding-bottom: 5px;
  display: block;
  font-weight: 700;
`;
