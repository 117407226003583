import React, { useEffect, useRef } from 'react';
import { CasesContainer } from './CasesLine.styles';
import { CaseLineItem } from '../CaseLineItem';
import { GameStatus } from 'games/CaseBattle/constants';

interface CaseLineProps {
  casesIds: number[];
  round: number;
  status: GameStatus;
}

export const CasesLine: React.FC<CaseLineProps> = ({ casesIds, round, status }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current.scrollTo({
      left: (round - 1) * 65,
      behavior: 'smooth',
    });
  }, [round]);

  return (
    <CasesContainer ref={ref} className="turn-off-default-scroll">
      {casesIds.map((id, index) => (
        <CaseLineItem
          key={index}
          id={id}
          status={status}
          width={87}
          height={94}
          isActive={round === index + 1}
        />
      ))}
    </CasesContainer>
  );
};
