import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
  bottom: 0;
  display: grid;
  grid-auto-columns: auto 1fr;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-rows: 3rem;
  padding: 1rem;
  position: sticky;

  & .secondary {
    height: 3rem;
    width: 3rem;
  }
`;
