import { useCallback, useState } from 'react';

export const useToggle = (currentState: boolean = true) => {
  const [isOpen, setOpen] = useState(currentState);

  const toggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return {
    isOpen,
    toggle,
  };
};
