import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../selectors';
import { fetchChangeEmail, fetchChangeSubscribe } from '../ducks/duck';
import { addNotification } from 'modules/Notifications/duck';

export const useUserEmail = () => {
  const email = useSelector((state: any) => getUserDetails<'email'>(state, 'email'), shallowEqual);
  const dispatch = useDispatch();

  const handleChangeEmail = useCallback(
    (address: string, isSubscriptionAllowed: boolean) => {
      if (email.status === 'confirmed') {
        dispatch(fetchChangeEmail('special-crutch@change-my-mail.pls', isSubscriptionAllowed));
        dispatch(
          addNotification({
            type: 'success',
            body: 'To unsubscribe current address please confirm action in your email',
          })
        );
      } else {
        dispatch(fetchChangeEmail(address, isSubscriptionAllowed));
      }
    },
    [dispatch, email]
  );

  const handleChangeSubscribe = useCallback(
    (isSubscribed: boolean) => {
      dispatch(fetchChangeSubscribe(isSubscribed));
    },
    [dispatch]
  );

  return {
    email,
    handleChangeEmail,
    handleChangeSubscribe,
  };
};
