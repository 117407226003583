import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { IMember } from '../../../../interfaces';
import { useLoadAccountProfile } from 'modules/Accounts';

export const MembersGridItem: FC<IMember> = ({
  betType,
  playerName,
  sum,
  playerImage,
  playerId,
}) => {
  const { handleOpenProfile } = useLoadAccountProfile();

  return (
    <Container className={betType} style={{ order: -sum }}>
      <div className="inner-member-container">
        <div className="thumbnail">
          <img src={playerImage} alt="" onClick={handleOpenProfile(playerId)} />
        </div>
        <div className="user-info">
          <span>{playerName}</span>
          <span>
            <CurrencyText value={sum} />
          </span>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: var(--color-dark-200);
  position: relative;

  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  & .inner-member-container {
    display: grid;
    grid-auto-flow: row;
    grid-row: auto;
    grid-gap: 0.125rem;
    background: var(--color-dark-200);
    padding: 0.25rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media screen and (min-width: 375px) {
      grid-gap: 0.25rem;
    }

    & .thumbnail {
      width: 1.875rem;
      height: 1.875rem;
      align-self: center;
      justify-self: center;

      & img {
        width: 1.875rem;
        height: 1.875rem;
        object-fit: contain;
        overflow: hidden;
        border-radius: 50%;
        pointer-events: auto;
      }
    }

    & .user-info {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.125rem;
      align-content: center;

      & span {
        width: 100%;
        height: 0.5rem;
        font-size: 0.5rem;
        line-height: 0.75rem;
        letter-spacing: 0.1px;
        text-align: center;
        color: var(--color-gray-200);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media screen and (min-width: 375px) {
          font-size: 0.75rem;
          line-height: 0.875rem;
          height: 0.875rem;
        }
      }
    }
  }
`;
