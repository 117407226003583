import { eventsTypes, reducer } from './duck';
import './locales/index';
import '../../@components/locales';
import { IModule } from '../rootInterfaces/root.interface';

export * from './hooks';
export * from './interfaces/appshell.reducer.interface';

export type { IAppShell, IExperiences, IGame } from './interfaces/appshell.reducer.interface';
export { appEpics } from './duck';
export const appShellModule: IModule = {
  moduleName: 'app',
  reducer: reducer,
  eventsTypes: eventsTypes,
};
