import * as React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { CurrencyInput } from '../../../../@components/currency';
import { getTypeImage } from '../../utils';

class _ControlsBet extends React.PureComponent {
  render() {
    const { changeBetValue, changeType, settings, bet, types, typeIndex, onBet, t } = this.props;

    return (
      <Container className="tablet">
        <Btn onClick={onBet}> {t('Start game')}</Btn>

        <CurrencyInput maxValue={settings.max_bet / 100} value={bet} setValue={changeBetValue} />

        <SwitchBtns>
          {types.map((type, index) => (
            <SwitchBtn key={type.id} isActive={index === typeIndex} onClick={changeType(index)}>
              <BtnIcon src={getTypeImage(type.title)} /> <span>{t(type.title)}</span>
            </SwitchBtn>
          ))}
        </SwitchBtns>
      </Container>
    );
  }
}

export const ControlsBet = withTranslation()(_ControlsBet);

const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  padding: 0 1rem;
`;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  min-height: 40px;
  border-radius: 8px;
  background: #ffff33;
  margin-bottom: 10px;
  font-size: 15px;
  border: 0;
`;

const SwitchBtns = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 10px 0;
`;

const SwitchBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32%;
  height: 36px;

  background: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'transparent')};
  border-radius: 5px;
  border: solid 1px var(--color-gray-90);

  color: ${({ isActive }) => (isActive ? '#161e21' : 'var(--color-gray-90)')};
`;
const BtnIcon = styled.img`
  width: 18px;
  height: auto;
  margin-right: 5px;
`;
