import React, { useRef } from 'react';
import styled from 'styled-components';
import { ShopElements } from './shop-elements';
import { ShopFilters } from './shop-filters';
import { FilterModal } from '../modals';
import { ShopOrder } from './shop-order';

export const ShopPage = () => {
  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="content dark-layout">
        <Container ref={parentRef}>
          <ShopFilters />
          <ShopElements parentRef={parentRef} />
          <FilterModal />
        </Container>
      </div>
      <ShopOrder />
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content 1fr;
  min-height: 100%;
  overflow: auto;
`;
