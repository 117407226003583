import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin: 56px 0 0;
  background-image: url(${eventImagesPath.resultBgPath});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 16px;
  align-items: center;
`;

export const Description = styled.span`
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: center;
`;

export const Time = styled.span`
  color: var(--color-white);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;
