import { useSelector } from 'react-redux';
import { getShopLoading, getShopMeta, getShopFailure, getShopLoaded } from '../../selectors';
import { IStore } from 'store/interface';
import { IShopRecord } from '../../interfaces';

export const useShopMeta = () => ({
  meta: useSelector<IStore, IShopRecord['meta']>(getShopMeta),
  loading: useSelector(getShopLoading),
  loaded: useSelector(getShopLoaded),
  failure: useSelector(getShopFailure),
});
