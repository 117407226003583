import * as React from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';

import check from '../../assets/check.svg';
import arrow from '../../assets/arrow.svg';
import ExternalLink from '../../assets/external-icon.svg';
import { getTypeImage } from '../../utils';

class _VerifyTicket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      hash: '',
      salt: 'hidden',
      secretPath: '',
    };

    this.toggleAnimate = new Animated.Value(0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.ticket.hash && nextProps.ticket.hash !== prevState.hash) {
      return {
        hash: nextProps.ticket.hash,
      };
    }
    if (nextProps.ticket.salt) {
      return {
        secretPath: nextProps.ticket.secret,
      };
    }
    return null;
  }

  render() {
    const { hash, salt } = this.state;
    const { ticket, ticketType, t } = this.props;
    const toggleHeightInterpolate = this.toggleAnimate.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 750],
    });
    return (
      <Container className="tablet">
        <VerifyHead onClick={this.toggleOpen}>
          <Align>
            <Icon src={check} />
            <HeaderTitle>{t('Verify ticket')}</HeaderTitle>
          </Align>
          <Arrow src={arrow} isOpen={this.state.isOpen} />
        </VerifyHead>
        <Animated.div
          style={{
            maxHeight: toggleHeightInterpolate,
            overflow: 'hidden',
          }}
        >
          <VerifyBody>
            <ControlGroup>
              <InputLabel>Hash</InputLabel>
              <Control>
                <InputControl
                  type="text"
                  name="hash"
                  value={this.state.hash}
                  onChange={this.handleChangeHash}
                />
                <CopyToClipboard text={this.state.hash}>
                  <InputControlAppend>{t('Copy')}</InputControlAppend>
                </CopyToClipboard>
              </Control>
            </ControlGroup>
            <ControlGroup>
              <InputLabel>Salt</InputLabel>
              <Control>
                <InputControl
                  type="text"
                  name="salt"
                  value={ticket.salt || 'hidden'}
                  disabled={!ticket.salt}
                />
                <CopyToClipboard text={this.state.salt}>
                  <InputControlAppend>{t('Copy')}</InputControlAppend>
                </CopyToClipboard>
              </Control>
            </ControlGroup>
            {ticket.secret && (
              <ControlGroup>
                <InputLabel>Secret</InputLabel>
                <Control>
                  <InputControl
                    type="text"
                    name="secretPath"
                    value={this.state.secretPath}
                    onChange={this.handleChangeHash}
                  />
                  <CopyToClipboard text={this.state.secretPath}>
                    <InputControlAppend>{t('Copy')}</InputControlAppend>
                  </CopyToClipboard>
                </Control>
              </ControlGroup>
            )}

            {ticket.secret && (
              <React.Fragment>
                <SecretsField>
                  {ticket.secret.split(',').map((itemRow, indexRow) => (
                    <FieldRow key={itemRow + indexRow}>
                      {[...itemRow].map((itemField, indexField) => (
                        <FieldItem
                          key={itemField + indexField + indexRow}
                          isReward={!!(itemField * 1)}
                          isOpen={Number(ticket.path[indexRow]) === indexField}
                        >
                          {!!(itemField * 1) && (
                            <FieldItemIcon src={getTypeImage(ticketType.title)} alt="field" />
                          )}
                        </FieldItem>
                      ))}
                    </FieldRow>
                  ))}
                </SecretsField>
                <Link href={`http://sha224.net/?val=${salt}${hash}`}>
                  {t('Verify ticked')}
                  <ExternalIcon src={ExternalLink} />
                </Link>
              </React.Fragment>
            )}
          </VerifyBody>
        </Animated.div>
      </Container>
    );
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      Animated.timing(this.toggleAnimate, {
        toValue: this.state.isOpen ? 1 : 0,
      }).start();
    });
  };

  handleChangeHash = ev => {
    this.setState({ [ev.target.name]: ev.target.value });
  };
}

export const VerifyTicket = withTranslation()(_VerifyTicket);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 24px !important;
`;

const VerifyHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 44px;
  background-color: #212f35;
  padding: 0 15px;
`;

const Align = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const HeaderTitle = styled.span`
  font-size: 16px;
  letter-spacing: 0.5px;
  color: var(--color-white);
`;

const Arrow = styled.img`
  transform: ${({ isOpen }) => `rotate(${isOpen ? -90 : 90}deg)`};
`;

const VerifyBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 20px;
`;

const InputLabel = styled.span`
  font-size: 14px;
  letter-spacing: 0.9px;
  color: var(--color-white);

  margin-bottom: 11px;
`;

const Control = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 40px;
`;

const InputControl = styled.input`
  border: 0;
  border-radius: 4px 0 0 4px;
  background-color: #141418;

  padding: 9px 13px 11px;

  font-size: 14px;
  letter-spacing: 0.8px;
  color: #7c7d7f;
  text-overflow: ellipsis;
`;

const InputControlAppend = styled.button`
  border: 0;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  background-color: var(--yellow);
  font-size: 15px;
  color: #000000;
`;

const SecretsField = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 20px;
`;

const FieldRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 27px;
  margin: 5px 0;
`;

const FieldItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 0;
  flex-grow: 0.2;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0 rgba(0, 2, 6, 0.35);
  background-color: ${({ isReward, isOpen }) =>
    isReward ? (isOpen ? 'var(--success)' : '#19d3f9') : '#015b69'};
`;

const FieldItemIcon = styled.img`
  max-width: 20px;
  height: auto;
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  margin: 0 20px;
  border-radius: 5px;
  background: var(--primary);
  color: #000000;
  font-size: 15px;
  border: 0;
  font-weight: normal;
  position: relative;
`;

const ExternalIcon = styled.img`
  position: relative;
  top: -7px;
  left: 7px;
  width: 12px;
  height: auto;
`;
