export default {
  Games: 'Jogos',
  'Refill balance': 'Saldo de recarga',
  Settings: 'Configurações',
  Store: 'Loja',
  'Get free money': 'Obter moedas grátis',
  Notifications: 'Notificações',
  FAQ: 'FAQ',
  'Log out': 'Sair',
  'Steam trade link': 'Link de comércio de vapor',
  'Where can I get a link?': 'Onde posso obter um link?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Foi desconectado do serviço CSGOFAST. Toque no ecrã para restabelecer a ligação.',
  'App was offline. Check your internet connection to continue!':
    'A app estava offline. Verifique a sua ligação à internet para continuar!',
  SAVE: 'SAVE',
};
