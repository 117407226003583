export default {
  GAME: 'OYUN',
  Winner: 'Kazanan',
  Win: 'Kazanmak',
  Chance: 'Şans',
  'Chance to win': 'Chance to win',
  'At stake': 'Tehlikede',
  'Sign in through STEAM to participate': 'Katılmak için STEAM ile oturum aç',
  'Steam trade link': 'Steam takas linki',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Önce yatırın',
  'Place a bet': 'Bahis koy',
  'Deposit more': 'Daha fazla yatır',
  'Min bet': 'Minimum bahis',
  'Max bet': 'Maksimum bahis',
  'Bet amount': 'Bahis miktari',
  'max. items': 'Yatırılacak en yüksek eşya',
  Tickets: 'Tickets',
  from: 'from',
  to: 'to',
  'Tickets to from: from to': 'Biletler: yer #{{from}} ve #{{to}}',
  Skins: 'Kostümler',
  Coins: 'Paralar',
  'VIRTUAL SKINS': 'Sanal skins',
  'Round number': 'Tur numarası',
  'Winning after commission': 'Kazanmanın ardındaki komisyon',
  Loading: 'Yükleniyor',
};
