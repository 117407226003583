import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchUpdateApikey } from '../../../ducks/duck';

export const useUserApiKeyForm = () => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      apikey: '',
    },
    criteriaMode: 'firstError',
  });

  const onSubmit = useCallback(({ apikey }) => dispatch(fetchUpdateApikey(apikey)), [dispatch]);

  return {
    form,
    onSubmit,
  };
};
