import React, { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import type { ITournamnetItemType } from 'modules/LeadersRace/interfaces/reducer.leaders.interfaces';

interface IRaceTypeTabBar {
  raceIndex: number;
  handleChangeRace: (index: number) => () => void;
  options: ITournamnetItemType[];
}

export const RaceTypeTabBar: FC<IRaceTypeTabBar> = ({ raceIndex, handleChangeRace, options }) => {
  const { t } = useTranslation();

  return (
    <GridContainer selectRace={raceIndex}>
      {options.map((item: ITournamnetItemType, i: number) => (
        <div key={item.id} onClick={handleChangeRace(i)}>
          <span className="title"> {options[i].raceType}</span>
          <span className="sub-title">
            {t('from')} {dayjs(options[i].startAt).format('DD.MM.YYYY')}
          </span>
        </div>
      ))}
    </GridContainer>
  );
};

interface IGridContainerStyle {
  selectRace: number;
}

const GridContainer = styled.div<IGridContainerStyle>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 105px;
  grid-auto-rows: 54px;
  flex-shrink: 0;
  overflow-x: scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: 105px;
    height: 2px;

    background: var(--color-white);

    position: absolute;
    left: ${p => p.selectRace * 105}px;
    bottom: 0;

    transition: left 0.4s cubic-bezier(0.37, 0, 0.63, 1);
  }

  & > div {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 0.5rem;

    color: var(--color-white);
    line-height: 1.3;

    & .title {
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    & .sub-title {
      font-size: 0.7rem;
    }
  }
`;
