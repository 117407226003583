import React from 'react';
import { AppBar } from '../../../../@components/bars';
import { History } from '../../components/History';

export const HistoryPage = () => (
  <>
    <AppBar deep />
    <History />
  </>
);
