import { useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import { useSprings } from 'react-spring';
import { clamp } from 'lodash';

const { innerWidth } = window;

export const useSwipeSlide = (slideQuantity: number, slideWidth: number) => {
  const index = useRef(0);
  const [props, set] = useSprings(slideQuantity, (i: number) => ({
    x: i * slideWidth,
    sc: 1,
    display: 'block',
    opacity: i === 0 ? 1 : 0,
  }));

  const bind = useDrag(({ down, direction: [xDir], distance, cancel, delta }) => {
    if (down && distance >= 120 && Math.abs(delta[1]) < 50) {
      // @ts-ignore
      cancel(
        // @ts-ignore
        (index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, slideQuantity - 1))
      );
    }

    // @ts-ignore
    set((i: number) => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none', opacity: 0 };
      const x = (i - index.current) * slideWidth;
      return {
        x,
        display: 'block',
        opacity: i < index.current || i > index.current ? 0 : 1,
      };
    });
  });

  const handleSwipe = (nodeIndex: number) => () => {
    // @ts-ignore
    set((i: number) => {
      if (i < nodeIndex - 1) return { display: 'none', x: innerWidth * -1, opacity: 0 };
      if (i > nodeIndex + 1) return { display: 'none', x: innerWidth * i, opacity: 0 };
      const x = (i - nodeIndex) * slideWidth;
      index.current = nodeIndex;
      return {
        x,
        display: 'block',
        opacity: i < nodeIndex || i > nodeIndex ? 0 : 1,
      };
    });
  };

  return {
    handleSwipe,
    animatedProps: props,
    bind,
  };
};
