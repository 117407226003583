import * as React from 'react';
import { connect } from 'react-redux';

import { getTowerTicket, getTowerTypes } from '../selectors';
import { getUser } from '../../../core/User/selectors';
import { getTowerGameSettings } from '../../../core/AppShell/selectors';

import { buyTicket, clearTicket, doStep, sellTicket } from '../duck';

import { Ticket } from '../components/Game/Ticket';
import { Controls } from '../components/Controls/Controls';
import { Modal } from '../components/Modal';

class Game extends React.PureComponent {
  state = {
    typeIndex: 0,
    bet: 0.1,
    isShowModal: false,
  };
  static getDerivedStateFromProps(props) {
    const { ticket } = props;
    if (ticket.status === 'finished' && ticket.position === ticket.rows) {
      return {
        isShowModal: true,
      };
    }

    if (!!ticket.ticketTypeId) {
      return {
        isShowModal: false,
        typeIndex: ticket.ticketTypeId - 1,
      };
    }

    return {
      isShowModal: false,
    };
  }
  render() {
    const { types, user, settings, ticket, sellTicket, clearTicket } = this.props;
    if (types.length === 0) {
      return null;
    }

    return (
      <>
        <Ticket
          ticket={ticket}
          ticketType={types[this.state.typeIndex]}
          doStep={this.props.doStep}
          types={types}
          bet={this.state.bet}
        />
        <Controls
          user={user}
          types={types}
          ticket={ticket}
          typeIndex={this.state.typeIndex}
          settings={settings}
          bet={this.state.bet}
          changeBetValue={this.changeBetValue}
          changeType={this.changeType}
          onBet={this.onBet}
          sellTicket={sellTicket}
          ticketType={types[this.state.typeIndex]}
          clearTicket={clearTicket}
        />
        {this.state.isShowModal && <Modal reward={ticket.prize} clearTicket={clearTicket} />}
      </>
    );
  }

  changeBetValue = bet => this.setState({ bet });

  changeType = typeIndex => () => this.setState({ typeIndex });

  onBet = () =>
    this.props.buyTicket(this.props.types[this.state.typeIndex].id, this.state.bet * 100);
}

export default connect(
  state => ({
    user: getUser(state),
    types: getTowerTypes(state),
    ticket: getTowerTicket(state),
    settings: getTowerGameSettings(state),
  }),
  { doStep, buyTicket, sellTicket, clearTicket }
)(Game);
