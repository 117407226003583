import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetSelected } from '../../ducks/sales.duck';

export const useResetSelected = () => {
  const dispatch = useDispatch();

  const handleResetSelected = useCallback(() => {
    dispatch(resetSelected());
  }, [dispatch]);

  return {
    handleResetSelected,
  };
};
