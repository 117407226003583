import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { List } from 'react-virtualized';
import { animated, config, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { IStickySelect } from './StickySelect.interface';

const modalRoot = document.getElementById('portal') as HTMLElement;

export const StickySelect: React.FC<IStickySelect> = ({
  options,
  select,
  components,
  onChange,
  listHeight = 248,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const countrySelect = Math.max(select, 0);

  const [{ y, opacity }, set] = useSpring(() => ({
    y: listHeight + 30,
    opacity: 0,
  }));

  const changeModal = React.useCallback(
    (state: boolean) => {
      set({
        y: state ? 0 : listHeight + 30,
        opacity: state ? 0.75 : 0,
        config: state ? config.default : { duration: 150 },
        onStart: () => (state ? setOpen(true) : null),
        onRest: () => (state ? null : setOpen(false)),
      });
    },
    [set, listHeight]
  );

  const handleChange = React.useCallback(
    (index: number) => () => {
      onChange(index);
      changeModal(false);
    },
    [onChange, changeModal]
  );

  const bind = useDrag(({ last, vxvy: [, vy], movement: [, my], cancel }) => {
    // @ts-ignore
    if (my < -70) cancel();
    if (last) my > (listHeight + 30) * 0.5 || vy > 0.5 ? changeModal(false) : changeModal(true);
    else
      set({
        y: my,
        immediate: false,
        config: config.stiff,
      });
  });

  return (
    <>
      <SelectControl className="select-control" onClick={() => changeModal(true)}>
        {React.createElement(components.Control, {
          selectValue: options[countrySelect],
        })}
        <i className="icon-arrow append" />
      </SelectControl>
      {isOpen &&
        ReactDOM.createPortal(
          <>
            <ContainerList
              style={{
                height: listHeight + 30,
                bottom: y.interpolate(y => `-${y}px`),
              }}
            >
              <Shade {...bind()} />
              <List
                width={window.innerWidth}
                height={listHeight}
                rowCount={options.length}
                rowHeight={62}
                overscanRowCount={3}
                scrollToIndex={countrySelect + 3}
                rowRenderer={({ key, style, index }) => (
                  <div key={key} style={style} className="list-item" onClick={handleChange(index)}>
                    {React.createElement(components.Option, {
                      item: options[index],
                      selected: index === countrySelect,
                    })}
                  </div>
                )}
              />
            </ContainerList>
            <Overlay style={{ opacity }} onClick={() => changeModal(false)} />
          </>,
          modalRoot
        )}
    </>
  );
};

const SelectControl = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 15px;
  width: 100%;
  height: 62px;
  grid-gap: 0.5rem;
  padding: 0 1rem;
  font-size: 1rem;

  border: solid 1px var(--gray-400);

  &:nth-child(odd) {
    border-left: 0;
    border-right: 0;
  }

  &:nth-child(even) {
    border-right: 0;
  }

  & > * {
    align-self: center;
  }

  & > span {
    font-size: 1em;
    color: var(--color-white);
    text-transform: uppercase;
  }

  & > i {
    font-size: 1.35em;
    color: var(--color-white);
    justify-self: center;

    &.append {
      font-size: 0.8em;
    }
  }

  & > img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    text-indent: -9999px;

    &.circle {
      border-radius: 50%;
    }
  }
`;

export const Shade = styled.div`
  width: 100%;
  height: 30px;
  background-color: transparent !important;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--color-white);
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

const ContainerList = styled(animated.div)`
  width: 100vw;
  //height: 278px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 55;

  & > div {
    background-color: var(--color-gray-900);
  }

  & .list-item {
    transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    &:active {
      background: var(--color-gray-300);
    }
  }
`;

export const Overlay = styled(animated.div)`
  width: 100%;
  height: 100%;
  background: var(--color-dark-600);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  will-change: opacity;
`;
