import styled from 'styled-components';

export const Container = styled.div`
  color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  gap: 32px;
  line-height: 25px;
  overflow-x: scroll;
  padding: 32px 24px 11px;

  &::-webkit-scrollbar {
    display: none;
  }

  & .attention {
    color: var(--color-white);
    display: inline-block;
    padding: 10px 0;
  }

  & .our-sites {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  & .info {
    display: flex;
    flex-direction: column;
    gap: 32px;

    & b {
      color: var(--color-white);
    }

    & p > i {
      color: var(--color-white);
    }

    & > .dropdown {
      display: flex;
      flex-direction: column;
      gap: 17px;
    }

    & .details {
      display: flex;
      flex-direction: column;
      gap: 17px;

      &.show {
        animation: show 300ms ease-in-out;

        @keyframes show {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }

          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }

  h2 {
    color: var(--color-white);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 35px;
    padding-bottom: 17px;
  }

  h3 {
    color: var(--color-white);
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 26px;
    padding-right: 15px;
    position: relative;
  }

  a {
    background-color: transparent;
    color: var(--aqua-500);
    text-decoration: none;
  }
`;
