import React, { FC, MutableRefObject, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useVirtual, VirtualItem } from 'react-virtual';
import {
  useFastInventoryInfo,
  useFastInventoryPagination,
  useFastInventoryRefresh,
} from 'core/User';
import { FastInventoryElement } from './fast-inventory-element';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModeType } from '../../interfaces';

interface IFastInventoryItems {
  setMode: (v: ModeType) => () => void;
  parentRef: MutableRefObject<HTMLDivElement>;
}

export const FastInventoryItems: FC<IFastInventoryItems> = ({ parentRef, setMode }) => {
  const { t } = useTranslation('stock');
  const { info, identifiers, loaded, loading } = useFastInventoryInfo();
  const { refreshAction } = useFastInventoryRefresh();
  const { loadMore } = useFastInventoryPagination();

  const estimateSize = useCallback(() => (document.querySelector('body').offsetWidth - 24) / 2, []);
  const size = estimateSize();

  const elements = useVirtual({
    size: Math.ceil(
      loading ? Math.max(identifiers + 10) : Math.min(identifiers - 1, identifiers) / 2
    ),
    parentRef,
    estimateSize: estimateSize,
    overscan: 3,
  });

  useEffect(() => {
    refreshAction();
  }, [refreshAction]);

  useEffect(() => {
    const last = elements.virtualItems[elements.virtualItems.length - 1];
    if (!last || info.itemsCount === identifiers) return;
    const size = identifiers / 2;
    if (last.index > size - 10 && info.itemsCount > identifiers && !loading && loaded) {
      loadMore(Math.floor(identifiers / 100) + 1);
    }
  }, [elements.virtualItems, identifiers, info, loading, loaded, loadMore]);

  return (
    <Container>
      <div style={{ height: elements.totalSize + size }}>
        <div className="row" style={{ transform: 'translateY(0)', height: size - 8 || 0 }}>
          <Link className="exchange-link" to="/inventory/exchange">
            <i className="icon-plus" />
            <span>{t('Exchange')}</span>
          </Link>
          {(info.itemsCount > 0 || loading) && (
            <FastInventoryElement setMode={setMode} identifier={0} />
          )}
        </div>
        {elements.virtualItems.map(({ index, start, size }: VirtualItem) => (
          <div
            key={index}
            className="row"
            style={{
              transform: `translateY(${start + size}px)`,
              height: size - 8 || 0,
            }}
          >
            {(info.itemsCount > index + index + 1 || loading) && (
              <FastInventoryElement setMode={setMode} identifier={index + index + 1} />
            )}
            {(info.itemsCount > index + index + 2 || loading) && (
              <FastInventoryElement setMode={setMode} identifier={index + index + 2} />
            )}
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  & > div {
    width: 100%;
    position: relative;

    & .row {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      position: absolute;
      top: 0;
      left: 1rem;
      right: 1rem;

      @media only screen and (min-width: 500px) {
        margin: 0 3rem;
      }

      & .exchange-link {
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: 1fr 0.5fr;
        padding: 0.75rem;
        position: relative;
        overflow: hidden;
        background: var(--color-dark-200);
        border-radius: var(--size-radius-m);

        & i {
          font-size: 3rem;
          align-self: center;
          justify-self: center;
          position: relative;
          top: 0.5rem;
          color: var(--color-white);
        }

        & span {
          align-self: center;
          justify-self: center;
          font-size: 0.75rem;
          line-height: 1rem;
          letter-spacing: 0.1px;
          color: var(--color-white);
        }
      }
    }
  }
`;
