import styled from 'styled-components';

export const BetContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 47px;
  justify-content: center;
  margin: 0 auto;
  max-width: 230px;
  width: 100%;
`;

export const Btn = styled.button`
  background: var(--yellow);
  border-radius: 5px;
  border: 0;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
  position: relative;
  text-align: center;
  width: 100%;

  &:disabled {
    background-color: var(--color-dark-600);
    color: var(--ochre);
    opacity: 0.9;
  }
`;

export const BtnInfo = styled.button`
  background-color: var(--color-dark-600);
  border-radius: 5px;
  border: 0;
  color: var(--color-gray-950);
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  width: 100%;
`;
