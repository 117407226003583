import Immutable, { OrderedMap } from 'immutable';
import { IFastInventoryRecord } from './fast-inventory.reducers.interface';
import { UserSettings } from './userSettings.reducer.interface';
import { ITransactionOrderedMap } from 'modules/PersonalArea/interfaces';

export enum SteamErrorsEnum {
  STEAM_GUARD = 'steam guard is not enabled',
  STEAM_GUARD_HOLD = 'steam guard is in hold',
  STEAM_TRADE_BAN = 'steam trade ban',
  UNBINDING_STEAM = 'User without steam',
  INVALID_TRADELINK = 'invalid tradelink',
  PRIVATE_INVENTORY = 'private inventory',
  INVALID_API_KEY = 'invalid API key',
  FAKE_API_KEY = 'fake API key',
  FAKE_TRADE_LINK = 'fake trade link',
}
export interface FreeCaseReward {
  rewardedCases: {
    freeCaseId: number;
    caseId: number;
    amount: number;
  }[];
}
export interface IUserExperienceLevel {
  level: number;
}

export interface IUserExperienceProgress {
  nextLevelXp: number;
  xp: number;
  userCanUpdateLevel: boolean;
}

export type VerificationStatuses = 'success' | 'failed' | 'pending' | 'verified';

export interface IVerification {
  requirement: boolean;
  status: VerificationStatuses;
  message: string;
}

export interface IUserExperience extends IUserExperienceLevel, IUserExperienceProgress {}

export interface IProviders {
  [k: string]: {
    name: string;
    profileId: string;
    is_hidden: boolean;
    status?: string;
  };
}

export interface IEmail {
  status: string;
  address: string;
  isSubscribed: boolean;
}

export interface IRewards {
  freeSpins: number;
  blueCases: number;
  orangeCases: number;
  silverCases: number;
}

export interface IPremium {
  until: string | null;
  goldLink: string;
}

export interface ILinks {
  steam: string | null;
  vgo: string | null;
}

export interface IChatInfo {
  hasAccess: boolean;
  reasonBanned: boolean;
}

export interface IBonuses {
  nickname: number;
  promo: boolean;
  deposit: boolean;
}

export interface IPermissions {
  success?: boolean;
  error?: SteamErrorsEnum.UNBINDING_STEAM | string;
  canTrade: {
    success: boolean;
    error?: SteamErrorsEnum;
    days?: number;
  };
  canSteamAPI: {
    success: boolean;
    error?: SteamErrorsEnum.INVALID_API_KEY | null;
  };
}

export interface Referrer {
  user: {
    id: number | null;
    avatar: string;
    name: string;
    profileType: string;
  };
}

export enum ReferralCampaignTypes {
  PERCENT = 'gofast-custom-percent',
  MONEY_NEW = 'gofast-custom-money',
  GOFAST = 'gofast',
  // чисто хэл история пока не юзается
  CASES = 'cases',
  PROMO_PERCENT = 'percent',
  MONEY_OLD = 'money',
}

export interface IReferralInfo {
  canActivate: boolean;
  code: string;
  type: ReferralCampaignTypes;
  activationEndDate: Date | null;
  reward: number;
  rewarded: boolean;
  referrer: Referrer;
}

export interface IUserState {
  activity: any; // {}
  avatar: string;
  banTime: number;
  bonuses: IBonuses;
  chatInfo: IUserChatInfo;
  chevron: string;
  chevronImage: string;
  coins_demo: number;
  coins: number;
  country: string | null;
  date_registred: string;
  email: IEmail;
  experience: IUserExperience;
  id: number | null;
  lastWins: ITransactionOrderedMap;
  links: ILinks;
  name: string;
  permissions: IPermissions;
  premium: IPremium;
  profileType: string;
  providers: IProviders;
  referralInfo: IReferralInfo;
  rewards: IUserRewardsRecord;
  selfLock: boolean;
  selfLockUntill: string | null;
  statsIsHidden: boolean;
  steamid_3: number | null;
  verification: IVerification;
  walletType: number;
}

const VerificationRecord = Immutable.Record<IVerification>({
  requirement: false,
  status: null,
  message: null,
});

const ExperienceRecord = Immutable.Record<IUserExperience>({
  level: 0,
  nextLevelXp: 0,
  xp: 0,
  userCanUpdateLevel: false,
});

const EmailRecord = Immutable.Record<IEmail>({
  status: 'empty',
  address: '',
  isSubscribed: false,
});

const RewardsRecord = Immutable.Record<IRewards>({
  freeSpins: 0,
  blueCases: 0,
  orangeCases: 0,
  silverCases: 0,
});

const PremiumRecord = Immutable.Record<IPremium>({
  until: null,
  goldLink: '',
});

const LinksRecord = Immutable.Record<ILinks>({
  steam: null,
  vgo: null,
});

const ChatInfoRecord = Immutable.Record<IChatInfo>({
  hasAccess: false,
  reasonBanned: false,
});

const BonusesRecord = Immutable.Record<IBonuses>({
  nickname: 0,
  promo: false,
  deposit: false,
});

const Permissions = Immutable.Record<IPermissions>({
  success: false,
  error: null,
  canTrade: {
    success: null,
    error: null,
    days: null,
  },
  canSteamAPI: {
    success: false,
    error: null,
  },
});
const ReferralInfo = Immutable.Record<IReferralInfo>({
  canActivate: false,
  code: '',
  type: ReferralCampaignTypes.GOFAST,
  activationEndDate: null,
  reward: 0,
  rewarded: false,
  referrer: {
    user: {
      id: null,
      avatar: '',
      name: '',
      profileType: 'bronze',
    },
  },
});
export const UserRecord = Immutable.Record<IUserState>({
  id: null,
  steamid_3: null,
  name: '',
  avatar: '',
  profileType: 'demo',
  activity: {},
  chevron: '',
  chevronImage: '',
  country: null,
  coins: 0,
  coins_demo: 0,
  walletType: 0,
  date_registred: '',
  providers: {},
  verification: new VerificationRecord(),
  experience: new ExperienceRecord(),
  email: new EmailRecord(),
  rewards: new RewardsRecord(),
  premium: new PremiumRecord(),
  links: new LinksRecord(),
  chatInfo: new ChatInfoRecord(),
  bonuses: new BonusesRecord(),
  permissions: new Permissions(),
  referralInfo: new ReferralInfo(),
  lastWins: OrderedMap(),
  banTime: 1,
  selfLock: false,
  selfLockUntill: null,
  statsIsHidden: false,
});

export type IUserRecord = {
  base: Immutable.Record<IUserState>;
  inventory: {
    fast: Immutable.Record<IFastInventoryRecord>;
  };
  settings: Immutable.Record<UserSettings>;
};
export type IUserExperienceRecord = Immutable.Record<IUserExperience>;
export type IUserEmailRecord = Immutable.Record<IEmail>;
export type IUserRewardsRecord = Immutable.Record<IRewards>;
export type IUserPremiumRecord = Immutable.Record<IPremium>;
export type IUserLinks = Immutable.Record<ILinks>;
export type IUserChatInfo = Immutable.Record<IChatInfo>;
