import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useUser } from 'core/User';
import { userModals } from 'core/User/modal.config';
import { useDialog } from 'core/hooks';
import { Modules } from 'core/interfaces';

export const SuccessPayment = () => {
  const { handleOpenDialog } = useDialog(userModals.PAYMENT_PROCESS);
  const {
    user: { id },
  } = useUser();

  const match = useRouteMatch(`/success-payment/${id}`);

  useEffect(() => {
    if (!!match?.isExact) {
      handleOpenDialog();
    }
  }, [handleOpenDialog, match]);

  return <Redirect to={Modules.PERSONAL} />;
};
