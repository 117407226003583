import './locales';
import reducer, { eventsTypes, fastEpics } from './duck';

export { GameRouter as FastRouter } from './containers';
// export const FastRouter = lazy(() => import('./containers').then(module => ({ default: module.GameRouter })))

export const fastModule = {
  moduleName: 'fast',
  moduleId: 2,
  path: '/fast',
  type: 'pvp',
  reducer,
  eventsTypes,
};

export { fastEpics };
