import React, { FC, useLayoutEffect, useMemo } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import PrivateRoute from 'decorators/PrivateRoute.decorator';
import { InventoryNavigationPage } from './componens';
import { FastInventoryRouter } from './+fast-inventory';
import { ExchangeRouter } from './+exchange';
import { useDispatch } from 'react-redux';
import { registerFastCaseInfoModule } from './+fast-inventory/ducks/combine-duck';
import { attachExchange, detachExchange } from './+fast-inventory/ducks/free-case.duck';

const _InventoryRouter: FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  useMemo(() => {
    registerFastCaseInfoModule();
    dispatch(attachExchange());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch(detachExchange());
    };
  }, [dispatch]);
  return (
    <>
      <AnimatedRouter>
        <Route path={`${match.url}/exchange`} component={ExchangeRouter} />
        <Route path={`${match.url}/fast`} component={FastInventoryRouter} />
        <Route path={match.url} component={InventoryNavigationPage} />
      </AnimatedRouter>
      <div id="inventory" />
    </>
  );
};

export const InventoryRouter = PrivateRoute(_InventoryRouter);
