import styled from 'styled-components';

export const Container = styled.div<{ isActive: boolean }>`
  border-radius: 10px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  background: ${({ isActive }) => (isActive ? 'var(--color-white-gray)' : 'var(--color-dark-200)')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    animation: rotate 500ms linear;
    background-color: ${({ isActive }) =>
      isActive ? 'var(--color-white-gray)' : 'var(--color-dark-200)'};
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    content: '';
    height: 200%;
    left: -50%;
    position: absolute;
    top: -50%;
    width: 200%;
    z-index: -2;
  }

  @keyframes rotate {
    0% {
      background-image: conic-gradient(
        ${({ isActive }) => (isActive ? '#00BAD9' : 'white')},
        ${({ isActive }) => (isActive ? '#00BAD9' : 'white')}
      );
      transform: rotate(315deg);
    }

    100% {
      background-image: conic-gradient(
        ${({ isActive }) => (isActive ? '#00BAD9' : 'white')},
        ${({ isActive }) => (isActive ? '#00BAD9' : 'white')}
      );
      transform: rotate(585deg);
    }
  }

  &::after {
    background: ${({ isActive }) =>
      isActive ? 'var(--color-white-gray)' : 'var(--color-dark-200)'};
    border-radius: 8px;
    content: '';
    height: calc(100% - 4px);
    left: 2px;
    position: absolute;
    top: 2px;
    width: calc(100% - 4px);
    z-index: 0;
  }

  & > div {
    overflow: hidden;
    position: absolute;
    z-index: 1;

    & > svg {
      fill: ${({ isActive }) => (isActive ? '#00BAD9' : 'white')} !important;
      animation: move 500ms linear;

      @keyframes move {
        0% {
          transform: translateY(22px);
        }

        100% {
          transform: translateY(0);
        }
      }
    }
  }

  &[data-disable='blocked'] {
    opacity: 0.5;
    pointer-events: none;
  }

  &[data-disable='enable'] {
    opacity: 1;
  }
`;
