import React, { FC } from 'react';
import { UserBalance } from 'core/User/widgets';
import { useTranslation } from 'react-i18next';
import { useBonusRefillEvent } from 'modules/Refill';
import { BalanceLink, Container, StyledImage } from './Balance.styles';

import { useUser } from 'core/User';
import { Modules } from 'core/interfaces';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';

interface IBalance {
  handlePushTo: (path: string) => () => void;
}

export const Balance: FC<IBalance> = ({ handlePushTo }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { bonus } = useBonusRefillEvent();

  const handleEvent = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    handlePushTo(Modules.EVENT)();
  };

  const handleClick = () => {
    if (user.id) {
      handlePushTo('/personal/balance')();
    } else {
      handlePushTo(Modules.EVENT)();
    }
  };

  return (
    <Container className={bonus ? 'bonus-event' : ''}>
      <BalanceLink onClick={handleClick}>
        <div>
          {user.id && (
            <span>
              <UserBalance />
            </span>
          )}
          <span className={`event-title ${user.id ? '' : 'guest'}`} onClick={handleEvent}>
            Comics Heroes
          </span>
        </div>
      </BalanceLink>
      <StyledImage src={eventImagesPath.badgePath} alt="" onClick={handleEvent} />
      {!!bonus && (
        <div className="event-title">
          {bonus}% {t('Refill bonus')}
        </div>
      )}
      {/* <SwitchBalance /> */}
    </Container>
  );
};
