import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar } from '@components/bars';
import { CaseBattleList } from 'games/CaseBattle/components/CaseBattleList/CaseBattleList';

export const BattlePageList = () => {
  const history = useHistory();
  const isHistoryPage = history.location.pathname.split('/')?.[2] === 'history';

  //TODO: add sounds
  return (
    <>
      <AppBar isGame={!isHistoryPage} deep={isHistoryPage} />
      <CaseBattleList />
    </>
  );
};
