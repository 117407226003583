import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export class GameFilter extends Component {
  static propTypes = {
    gameTypes: PropTypes.object.isRequired,
    filterValues: PropTypes.object.isRequired,
    updateFilter: PropTypes.func.isRequired,
    requestAction: PropTypes.func.isRequired,
  };

  render() {
    const { gameTypes, filterValues, mode } = this.props;

    return (
      <Container>
        {Object.keys(gameTypes).map(gameTypeId => {
          const { stem_appid, disableMode } = gameTypes[gameTypeId];
          if (disableMode.includes(mode)) return null;
          return (
            <Category
              key={stem_appid}
              disabled={stem_appid === filterValues.currentGameTypes}
              active={stem_appid === filterValues.currentGameTypes}
              onClick={this.changeGameType(stem_appid)}
            >
              {gameTypes[gameTypeId].name}
            </Category>
          );
        })}
      </Container>
    );
  }

  changeGameType = gameTypeId => () => {
    const { mode, updateFilter, requestAction, requestUserInventory } = this.props;
    updateFilter({ currentGameTypes: gameTypeId });

    if (mode === 'refill') {
      return requestUserInventory(true);
    }

    requestAction(true);
  };
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  padding: 10px;
`;

const Category = styled.button`
  border: 0;
  color: ${p => (p.active ? 'var(--color-dark-600)' : 'var(--yellow)')};
  font-size: 15px;

  padding: 5px;
  border: 1px solid var(--yellow);
  width: 50%;
  text-align: center;
  background: ${p => (p.active ? 'var(--yellow)' : 'transparent')};
  &:last-child {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
`;
