import * as React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import UsersBetItem from './UsersBetItem';

class UsersBet extends React.PureComponent {
  state = {
    winnersWidth: 0,
  };

  static getDerivedStateFromProps(props) {
    if (Object.keys(props.bets).length > 0) {
      const winnerUsers = props.bets.filter(bet => !!bet.coef);
      return {
        winnersWidth: winnerUsers.length / props.bets.length,
      };
    }
    return null;
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PlayersBetStatusBar className="tablet">
          <PlayersBetWin winnersWidth={this.state.winnersWidth} />
        </PlayersBetStatusBar>
        <UsersBetContainer className="tablet">
          <UsersBetHeader>
            <HeaderUser>{t('Player')}</HeaderUser>
            <HeaderInfo>{t('Bet')}</HeaderInfo>
            <HeaderInfo>X</HeaderInfo>
            <HeaderInfo>{t('Profit')}</HeaderInfo>
          </UsersBetHeader>
        </UsersBetContainer>
        <UsersBetBody className="tablet">{this.userBetsList()}</UsersBetBody>
      </React.Fragment>
    );
  }

  userBetsList = () => {
    const { bets, gameRun, crashed } = this.props;
    return bets.map(item => (
      <UsersBetItem key={item.id} user={item} gameRun={gameRun} crashed={crashed} />
    ));
  };
}

export default withTranslation()(UsersBet);

const UsersBetContainer = styled.div`
  background-color: #161e21;
`;

const UsersBetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 14px;
  border-bottom: 1px solid var(--color-dark-500);
`;

const HeaderUser = styled.div`
  font-size: 14px;
  color: var(--color-gray-90);
  flex-basis: 42%;
  flex-grow: 1;
  padding: 0 2px;
`;

const HeaderInfo = styled.div`
  font-size: 14px;
  color: var(--color-gray-90);
  flex-basis: 15%;
  flex-grow: 1;
  padding: 0 2px;
  text-align: center;
`;

const UsersBetBody = styled.div`
  background-color: #161e21;
`;

const PlayersBetStatusBar = styled.div`
  width: 100%;
  height: 3px;
  background-color: rgba(223, 208, 66, 0.2);
  position: relative;
`;

const PlayersBetWin = styled.div`
  width: ${props => `${props.winnersWidth * 100}%`};
  height: 3px;
  background-color: var(--ochre);
  position: absolute;
  left: 0;
`;
