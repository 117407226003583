import * as React from 'react';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MessageList } from '../components';
import { Controls } from './Controls';
import { changeRoom } from '../duck';
import { getGameChat } from '../selectors';

import { IMessage } from '../interfaces/reducer.interface';

const { useEffect, memo } = React;

interface IChat {
  chatRoomName: string;
}

export const Chat: React.FC<IChat> = memo(({ chatRoomName }) => {
  const chatMessage: IMessage[] = useSelector(getGameChat, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeRoom(chatRoomName));
  }, [chatRoomName, dispatch]);

  return (
    <>
      <ChatContent className="dark-layout">
        <MessageList messages={chatMessage} />
      </ChatContent>
      <Controls roomName={chatRoomName} />
    </>
  );
});

const ChatContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1 0;
  overflow: hidden;
  height: 100%;
  background: rgba(19, 26, 29, 0.95);
`;
