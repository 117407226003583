import React, { FC } from 'react';
import styled from 'styled-components';
import { UserAvatar } from './UserAvatar';
import { useLoadAccountProfile } from 'modules/Accounts';

interface IUserAva {
  uri: string;
  userId?: number;
}

export const AccountAvatar: FC<IUserAva> = ({ uri, userId }) => {
  const { handleOpenProfile } = useLoadAccountProfile();
  return (
    <UserButton onClick={handleOpenProfile(userId)}>
      <UserAvatar uri={uri} />
    </UserButton>
  );
};

const UserButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:active {
    background: var(--color-white-600);
  }

  &:active:after,
  &:focus:after,
  &:hover:after,
  &:visited:after {
    content: '';
  }

  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 20%,
      var(--color-white-600) 40%,
      transparent 60%,
      transparent 80%,
      transparent 100%
    );
    background-size: 400% 400%;
    animation-name: gradient;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: 2;

    @keyframes gradient {
      0% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
`;
