import React, { FC, useCallback, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, NavigationLink } from '@components/Common';
import { useDialog } from 'core/hooks';
import { ActingLottery } from '../@shared/acting-lottery';
import { IDialogComponent, LotteryDialogs, LotteryType } from 'core/interfaces';
import { Link } from 'react-router-dom';

interface IDataDialog {
  type: LotteryType.WEEKLY | LotteryType.MONTHLY;
  pathname: string;
}

interface IClassicLotteryDialog extends IDialogComponent<IDataDialog> {}

export const ClassicLotteryDialog: FC<IClassicLotteryDialog> = ({
  handleCloseModal,
  data: { type, pathname },
}) => {
  const ref = useRef<HTMLDivElement>();
  const weeklyRef = useRef<HTMLDivElement>();
  const monthlyRef = useRef<HTMLDivElement>();
  const { t } = useTranslation(['lottery', 'translations']);
  const { handleOpenDialog } = useDialog(LotteryDialogs.RULES);

  const onOpenLotteryRules = useCallback(() => {
    handleCloseModal();
    handleOpenDialog();
  }, [handleOpenDialog, handleCloseModal]);

  useLayoutEffect(() => {
    const offset: Record<LotteryType.WEEKLY | LotteryType.MONTHLY, number> = {
      [LotteryType.WEEKLY]: weeklyRef.current.offsetTop,
      [LotteryType.MONTHLY]: monthlyRef.current.offsetTop,
    };
    ref.current.scrollTo({
      top: offset[type] - 200 - (ref.current.offsetHeight - 400),
    });
  }, [type, ref, weeklyRef, monthlyRef]);

  return (
    <Container ref={ref}>
      <div className="buffer-container" onClick={handleCloseModal} />
      <div className="dialog-wrapper">
        <div className="lottery-header">
          <h2>{t('lottery-type', { context: 'classic' })}</h2>
          <p>
            {t('Place your bets in Classic game mode and participate in regular skin raffles!')}
          </p>
        </div>
        <div className="lottery-container">
          <div ref={weeklyRef}>
            <ActingLottery type={LotteryType.WEEKLY} />
          </div>
          <div ref={monthlyRef}>
            <ActingLottery type={LotteryType.MONTHLY} />
          </div>
        </div>
        <div className="navigation">
          <NavigationLink
            onClick={handleCloseModal}
            to={`${pathname}/lottery?type=${type}`}
            as={Link}
          >
            <div className="leading">
              <i className="icon-lottery-ticket-history" />
            </div>
            <div>
              <span>{t('translations:::/classic/lottery')}</span>
            </div>
          </NavigationLink>
          <NavigationLink onClick={onOpenLotteryRules} as="button">
            <div className="leading">
              <i className="icon-faq" />
            </div>
            <div>
              <span>{t('Details')}</span>
            </div>
          </NavigationLink>
        </div>
        <div className="controller">
          <Button className="primary" onClick={handleCloseModal}>
            {t('close')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & .buffer-container {
    display: block;
    width: 100%;
    height: max(calc(100vh - 400px));
  }

  & .dialog-wrapper {
    background: var(--color-dark-600);
    border-top: 1px solid var(--color-gray-900);

    & .lottery-header {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.75rem;
      padding: 3rem 1.25rem 2.5rem;

      & h2,
      & p {
        color: var(--color-white);
      }

      & h2 {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2.1875rem;
        letter-spacing: 0.54px;

        @media screen and (min-width: 375px) {
          font-size: 1.75rem;
        }
      }

      & p {
        font-size: 0.75rem;
        line-height: 0.9375rem;
        letter-spacing: 0.1px;
      }
    }

    & .lottery-container {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 2.625rem;
      padding-bottom: 2.625rem;

      & > div {
        &:nth-child(1) {
          --color-type: var(--color-hourly-lottery);
        }

        &:nth-child(2) {
          --color-type: var(--color-daily-lottery);
        }

        &:nth-child(3) {
          --color-type: var(--color-weekly-lottery);
        }
      }
    }

    & .navigation {
      border-top: 1px solid var(--color-gray-900);
      border-bottom: 1px solid var(--color-gray-900);

      & a,
      & button {
        width: 100%;
        border: 0;
      }
    }

    & .controller {
      padding: 1.75rem 1rem 1.5rem;
    }
  }
`;
