import { sellAllAction } from 'modules/inventory/+fast-inventory/ducks/clearance.duck';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CloseButton,
  ConfirmButton,
  Container,
  Content,
  DeclineButton,
  Header,
  Span,
  Title,
} from './confirm-selling.styles';
import { useTranslation } from 'react-i18next';
import { IDialogComponent } from 'core/interfaces';

interface ConfirmSellingDialogProps extends IDialogComponent<boolean> {}

export const ConfirmSellingDialog: React.FC<ConfirmSellingDialogProps> = ({ handleCloseModal }) => {
  const [isOpen, setOpen] = useState(true);
  const { t } = useTranslation('stock');
  const dispatch = useDispatch();
  const clickHandler = () => {
    setOpen(prev => !prev);
    handleCloseModal();
    dispatch(sellAllAction());
  };
  const declineHandler = () => {
    setOpen(prev => !prev);
    handleCloseModal();
  };
  return (
    <Container isOpen={isOpen}>
      <Header>
        <Span>{t('confirmAction')}</Span>
      </Header>
      <Content>
        <Title>{t('confirmTitle')}</Title>
        <ConfirmButton onClick={clickHandler}>{t('confirm')}</ConfirmButton>
        <DeclineButton onClick={declineHandler}>{t('decline')}</DeclineButton>
      </Content>
      <CloseButton onClick={declineHandler}>{t('close')}</CloseButton>
    </Container>
  );
};
