export default {
  GAME: 'ИГРА',
  Winner: 'Победитель',
  Win: 'Выигрыш',
  Chance: 'Шанс',
  'Chance to win': 'Шанс выиграть',
  'At stake': 'На кону',
  'Sign in through STEAM to participate': 'Для участия войдите через STEAM',
  'Steam trade link': 'Ссылка на обмен в Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Внести депозит первым',
  'Place a bet': 'Сделать ставку',
  'Deposit more': 'Вложить еще',
  'Min bet': 'Мин. ставка',
  'Max bet': 'Макс.',
  'Bet amount': 'Размер ставки',
  'max. items': 'максимум предметов',
  Tickets: 'Tickets',
  from: 'от',
  to: 'до',
  'Tickets to from': 'Билеты: от #{{ from }} до #{{ to }}',
  Skins: 'Cкины',
  Coins: 'Монеты',
  'VIRTUAL SKINS': 'ВИРТ. СКИНЫ',
  'Round number': 'Число раунда',
  'Winning after commission': 'Выигрыш с учетом комиссии',
  Loading: 'Загрузка',
  'Min {{ count }} items requires': 'Минимум {{ count }} предмета',
  'From {{ min_items_per_trade }} to {{ max_items_per_trade }} items':
    'От {{ min_items_per_trade }} до {{ max_items_per_trade }} предметов',
};
