/* eslint-disable */
export default {
  Error: 'Eroare',
  'Error placing a bet': 'A avut loc o eroare în cursul plasării pariului',
  'Error reserving an item': 'Eroare la rezervarea unui articol',
  'Link saved successfully': 'Link salvat cu succes',
  "You've successfully updated a bet": 'Ați actualizat cu succes un pariu',
  "You've successfully sell winning item": 'Ați vândut cu succes obiectul câștigător',
  "You've successfully take winning item": 'Ați luat cu succes obiectul câștigător',
  'No notifications.': 'No notifications.',
  'Incorrect link. Enter the correct link and try again.':
    'Link incorect. Introduceți link-ul corect și încercați din nou.',
  'This is not your link. Enter your link and try again.':
    'Acesta nu este link-ul tău. Introdu link-ul tău şi încearcă din nou.',
  "Don't forget to make your inventory public to get your winnings!":
    'Nu uita să-ţi faci stocul public pentru a obţine premiile!',
  'min. number of items per trade not exceeded':
    'numărul min. de obiecte per schimb nu a fost atins',
  'Too many requests, try again later': 'Prea multe solicitări, încearcă mai târziu',
  'cheating attempt': 'Tentativă de trişare',
  'max. number of items per trade exceeded':
    'numărul max. de articole per tranzacţie a fost depăşit',
  'player not registered on website': 'jucătorul nu e înregistrat pe site-ul web',
  'souvenir items are not allowed': 'articolele suvenir nu sunt acceptate',
  'some of the items cannot be appraised': 'unele articole nu pot fi estimate',
  'some of the items are below minimum price': 'unele articole sunt sub preţul minim',
  'total price of a trade is below minimum': 'preţul total al tranzacţiei este sub cel minim',
  'Your trade has been accepted!': 'Tranzacţia ta a fost acceptată!',
  'The items have been added to the queue.': 'Articolele au fost adăugate în lista de aşteptare.',
  'Your trade has been checked out!': 'Tranzacţia ta a fost verificată!',
  'It is currently being accepted': 'Aceasta este în curs de acceptare',
  'Unable to send a trade': 'Tranzacţie imposibil de trimis',
  'Update your trade link in the settings of the site':
    'Actualizează-ţi link-ul pentru tranzacţii din setările site-ului',
  'Enter correct trade link and make sure your inventory is not full.':
    'Introdu link-ul corect pentru tranzacţii şi asigură-te că stocul tău nu este complet',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Contul tău nu este disponibil pentru a face schimb. Vor fi arătate mai multe informații dacă inviți pe cineva să faceți schimb.',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam este indisponibil în acest moment. Te rugăm să aștepți ca să încerci din nou.',
  'Not enough money on balance': 'Puncte insuficiente în sold',
  'Not enough points on balance': 'Puncte insuficiente în sold',
  'Game is ending, try again later': 'Jocul e pe sfârşite, încearcă din nou mai târziu',
  'Congratulations!': 'Felicitări!',
  "You've successfully placed a bet": 'Ați plasat un pariu cu succes',
  "You've successfully placed a bet with </amount>":
    'Ai plasat cu succes un pariu de {{</amount>}}',
  'Item has not been found, coins have been returned': 'Bunul lipsește, vi se returnează jetoanele',
  'Your order in the store has been cancelled': 'Comanda dvs. din magazin a fost anulată',
  'The min bet is </minBet>': 'Pariul minim este de {{</minBet>}}',
  'The max bet is </maxBet>': 'Pariul maxim este de {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Nu poți paria pe roșu și pe negru în același timp',
  "You don't have CSGOFAST.com in Steam nickname!":
    'Porecla dvs. de Steam nu include CSGOFAST.com!',
  'You have exceeded the limit of 150 free coins per account':
    'Ai depășit limita de 150 de monezi gratuite per cont',
  "You've bet </amount> on the crash game #</game>!":
    'Ați pariat {{</amount>}} pe jocul crash #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Ați actualizat coef. la {{</coef>}} pentru jocul crash #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'Ați câștigat {{</amount>}} în jocul crash #{{</number>}}!',
  'Code for </amount> has been created': 'A fost creat codul pentru {{</amount>}}',
  'You already have an item': 'Aveți deja un obiect',
  '</amount> refilled on your balance': '{{</amount>}} reîncărcați în soldul tău',
  'We will sent it to your Steam in few moments':
    'Acesta va fi trimis către contul dvs. de Steam în câteva momente',
  'Your items has been sended to game!': 'Obiectele dvs. au fost trimise către joc!',
  '</amount> have been returned': '{{</amount>}} au fost returnați',
  'Item has not been found, retry in few minutes (</amount>)':
    'Obiectul nu a fost găsit în magazin. Reîncercați în câteva minute ({{</amount>}})',
  'It is currently being processing by game bots':
    'Este în curs de procesare de către roboții din joc',
  '</amount> have been refilled': '{{</amount>}} au fost reîncărcați',
  'Request successfully created': 'Solicitare creată cu succes',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Comanda ta #{{</id>}} pentru {{</amount>}} a fost acceptată, așteaptă schimbul în Steam.',
  'Reason of order rejection': 'Motivul respingerii comenzii',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Pariul tău de {{</amount>}} a fost acceptat și va fi adăugat la joc în {{</time>}} secunde',
  'Your bet was added to queue, in few seconds will added to the game':
    'Pariul tău a fost adăugat la coadă, în câteva secunde va fi adăugat la joc',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Retragerea este blocată. Câștigă încă ${currency(amount)} pentru a debloca',
    },
  },
};
