import styled, { keyframes } from 'styled-components';

const moveUpIconForBar = (randomShift: number) => keyframes`
  0% {
    opacity: 0.6;
    transform: translate(0, 0);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(${randomShift}px, -300px);
  }
`;

const scaleForAvatar = () => keyframes`
  0% {
    opacity: 0.6;
    transform: scale(0);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(100%);
  }
`;

export const Container = styled.div<{ randomShift: number }>`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 5;

  &[data-is-show-in-bar='true'] {
    animation: ${({ randomShift }) => moveUpIconForBar(randomShift)} 1000ms forwards;
    bottom: 28px;
    height: 24px;
    right: 16px;
    width: 24px;
  }

  &[data-is-show-in-bar='false'] {
    animation: ${() => scaleForAvatar()} 1000ms ease-out forwards;
    height: 100%;
    width: 100%;

    & > svg {
      height: 100%;
      width: 100%;
    }
  }
`;
