import { combineReducers } from 'redux';
// import { reducer as halloweenEventReducer } from '../event-halloween';
// import { reducer as blackFridayEventReducer } from '../event-black-friday';
// import { reducer as newYearReducer } from '../event-new-year';
// import { reducer as lunarNewYearReducer } from '../event-lunar-new-year';
// import { reducer as patrickEventReducer } from '../event-patrick';
// import { reducer as summertimeReducer } from '../event-summertime/';
import { reducer as comicsHeroesEventReducer } from '../comics-event';

export const reducer = combineReducers({
  // halloweenEventReducer,
  // blackFridayEventReducer,
  // newYearReducer,
  // lunarNewYearReducer,
  // patrickEventReducer,
  // summertimeReducer,
  // dragonEventReducer,
  comicsHeroesEventReducer,
});
