import { comicsLocalizationEN } from '../comics-event';

export default {
  bonusHeader: 'Also, we have added a <1>cool bonus</1>!',
  bonusDescription:
    'Each category of cases contains its own unique prize, which you can get just by opening cases of this category.',
  recentWinnings: 'Recent winnings',
  luckyWinners: 'Below are the lucky winners who received the winnings',
  superPrize: 'Super prize',
  surpriseDescription: 'An incredible prize awaits the one who collects prizes in all categories!',
  searchCollection: 'Search in  collections',
  title: 'Gifts for pumpkins',
  eventDescription: 'Open cases of a specified collection and receive a gift',
  openFreeCase: 'Open free case',
  open: 'Open',
  surprizeAvailable: 'Surprize available',
  receiveSurprize: 'Receive surprize',
  openSurprize: 'Open surprize',
  surprizeReceived: 'Surprize received',
  rareCaseTitle: "You've received a rare bonus!",
  rareCaseDescription: 'You get a rare bonus case, go open it',
  rareCaseButtonText: 'COOL! OPEN CASE',
  progressSurprize: 'Progress to the surprize',
  bannerTextBlackFriday: 'Master all types of weapons and receive the main prize.',
  blackFridayDescription: 'Complete all levels and get a gift case',
  superPrizeCompleted: 'Super prize received. Watch the replay.',
  superPrizeWarning: 'Super prize is not available. You need to complete all previous stages',
  lockMessage: 'Complete the previous step',
  levelPassed: 'Level passed!',
  youWin: 'You win',
  viewPrizes: 'View all prizes',
  hidePrizes: 'Hide all prizes',
  guidTextTicket: 'Gain tickets for free every time you refill balance',
  guidTextBullet: 'Shots at targets cost tickets',
  guidTextExp: 'Each shot requires reloading. Gain experience in other games to fill the bar.',
  guidButtonText: 'Next',
  guidButtonTextFinalStep: 'Play',
  watchReplay: 'Watch Replay',
  itemsTitle: 'At this level, you will be able to find',
  ...comicsLocalizationEN,
};
