import * as React from 'react';
import styled from 'styled-components';

const { useState, useEffect } = React;

interface IAccessDenied {
  chatInfo: any;
  updateUser: () => void;
}

export const AccessDenied: React.FC<IAccessDenied> = props => {
  const [time, setTime] = useState<number>(
    Math.floor((props.chatInfo.bannedUntil - Date.now()) / 60 / 1000)
  );

  useEffect(() => {
    if (!time) return;
    const timeID = setTimeout(() => {
      setTime(time - 1);
    }, 60 * 1000);

    return () => {
      clearTimeout(timeID);
      if (!(time - 1)) props.updateUser();
    };
  });

  if (!!props.chatInfo.reasonBanned) {
    return (
      <ErrorDenied>
        You are banned {props.chatInfo.reasonBanned}
        <div>Time left {`${time}`} min.</div>
      </ErrorDenied>
    );
  }

  return <ErrorDenied>Only players who have played the game are allowed to write</ErrorDenied>;
};

const ErrorDenied = styled.div`
  text-align: center;
  padding: 8px;
  color: var(--red);
`;
