import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-100);
  gap: 1px;
  padding-top: 38px;
`;

export const Delete = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;

  & > span {
    color: var(--color-gray-50);
    font-size: 14px;
    font-weight: 700;
  }
`;

export const DeleteAll = styled.div`
  color: var(--aqua-500);
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 18px;
`;

export const Control = styled.div<{ isCenter: boolean }>`
  align-items: center;
  backdrop-filter: blur(2px);
  background: linear-gradient(0deg, var(--color-dark-50) 0%, var(--color-dark-30) 97.96%);
  bottom: 0;
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'space-between')};
  padding: 30px 20px;
  position: absolute;
  width: 100%;
  z-index: 5;
`;

export const NoItems = styled.div`
  color: var(--color-white);
  font-size: 17px;
  left: 50%;
  padding: 5px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%);
  width: 100%;
`;
