import React, { useRef } from 'react';
import { AppBar, GetToolBarConfig, ToolBar } from '@components/bars';
import { SlotGame } from './slot-game';
import { SlotGameHistory } from './slot-game-history';
import { SlotGameNavigation } from './slot-game-navigation';
import { ScrollProvider } from './slot-game-scroll-provider';
import { useTranslation } from 'react-i18next';
import { Container } from './game-page.styles';

export const GamePage = () => {
  const scrollRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();

  const toolBarConfig: GetToolBarConfig[] = [
    {
      name: 'chat',
      iconKey: 'chat',
      type: 'page',
      isNested: false,
      isNeedAuth: false,
      text: t('chat'),
    },
    {
      name: 'rules',
      iconKey: 'rules',
      type: 'page',
      isNested: true,
      isNeedAuth: false,
      text: t('rules'),
    },
  ];
  return (
    <>
      <AppBar isGame />
      <Container ref={scrollRef} className="content dark-layout">
        <ToolBar toolBarItemsConfig={toolBarConfig} />
        <div className="tablet">
          <ScrollProvider value={scrollRef}>
            <SlotGame />
            <SlotGameNavigation />
            <SlotGameHistory />
          </ScrollProvider>
        </div>
      </Container>
    </>
  );
};
