export type IGameIconConfig = {
  [key: string]: {
    name: string;
    icon: string;
  };
};

export const gameIconConfig: IGameIconConfig = {
  18: {
    icon: 'icon-baccarat-mini',
    name: 'Baccarat',
  },
  10: {
    icon: 'icon-cases-mini',
    name: 'Cases',
  },
  14: {
    icon: 'icon-tower-mini',
    name: 'Tower',
  },
  9: {
    icon: 'icon-craft-mini',
    name: 'Craft',
  },
  5: {
    icon: 'icon-slot-mini',
    name: 'Slot',
  },
  1: {
    icon: 'icon-classic-mini',
    name: 'Classic',
  },
  2: {
    icon: 'icon-fast-mini',
    name: 'Fast',
  },
  15: {
    icon: 'icon-wheel-mini',
    name: 'Wheel',
  },
  7: {
    icon: 'icon-crash-mini',
    name: 'Crash',
  },
  3: {
    icon: 'icon-double-mini',
    name: 'Double',
  },
  13: {
    icon: 'icon-x50-mini',
    name: 'x50',
  },
  0: {
    icon: 'icon-refill',
    name: '',
  },
  16: {
    icon: 'icon-hilo-mini',
    name: 'HiLo',
  },
};

export const R = 28;
export const circumference = 2 * Math.PI * R;
export const SECTOR = circumference / 10;
export const X = (circumference - SECTOR) / 10;
export const Y = SECTOR / 10;
