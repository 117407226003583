import { SearchInput } from '@UIkit';
import { Button } from '@components/Common';
import { Container, Header } from './bonus-code.styles';
import { actionRequestBonusCode } from 'modules/PersonalArea/duck';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGetBonusCodeData } from 'modules/PersonalArea/hooks';

export const BonusCodeComponent = () => {
  const [value, setValue] = useState('');

  const { isLoading } = useGetBonusCodeData();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitHandler = () => {
    if (value) {
      dispatch(actionRequestBonusCode(value));
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Container>
      <Header>{t('Enter the code and get bonuses')}</Header>
      <SearchInput
        type="text"
        autoComplete="off"
        onChange={changeHandler}
        placeholder={t('Referral or promo code')}
      />
      <Button
        type="submit"
        disabled={isLoading || !Boolean(value)}
        onClick={submitHandler}
        className="primary"
      >
        {t('Activate code')}
      </Button>
    </Container>
  );
};
