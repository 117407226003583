import { eventsTypes } from './ducks/duck';
import { user } from './ducks';
import { IModule } from '../rootInterfaces/root.interface';

export type {
  IUserRecord,
  IUserState,
  IUserExperience,
  IUserExperienceLevel,
  IUserExperienceProgress,
  IPremium,
  IFastInventoryElement,
} from './interfaces';
export { SteamErrorsEnum } from './interfaces/user.reducer.interface';

export { MergeType } from './interfaces';

export * from './hooks';
export * from './selectors';
export * from './widgets';
export * from './configs';
export { updateFastInventoryFilter } from './ducks';

export const userModule: IModule = {
  moduleName: 'user',
  reducer: user,
  eventsTypes: eventsTypes,
};
