import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { useModuleSettings } from 'core/AppShell/hooks';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { ParticipateDialogs, ParticipateModule } from 'core/interfaces';
import { useUser } from 'core/User';

export const ControlPanel: FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { handleToggleDialog } = useDialog(ParticipateDialogs.PARTICIPATE_DIALOG);
  const { settings } = useModuleSettings('classic');

  return (
    <Container>
      <Button
        className="full primary"
        onClick={handleToggleDialog(ParticipateDialogs.PARTICIPATE_DIALOG, {
          module: ParticipateModule.CLASSIC,
        })}
      >
        {t(user.id ? 'Place a bet' : 'Log in to try your luck')}
      </Button>
      <div className="participate-limits">
        <CurrencyText value={settings.min_bet || 1} />{' '}
        {settings?.max_bet && (
          <>
            {'- '}
            <CurrencyText value={settings.max_bet} />
          </>
        )}
        {t('From {{ min_items_per_trade }} to {{ max_items_per_trade }} items', {
          min_items_per_trade: settings?.min_items_per_trade ?? 1,
          max_items_per_trade: settings?.max_items_per_trade,
        })}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.625rem;
  padding: 0.875rem 1rem 0.625rem;
  background: linear-gradient(0deg, rgba(19, 26, 29, 0.95) 0%, rgba(19, 26, 29, 0.475) 97.96%);
  position: sticky;
  bottom: 0;
  z-index: 1000;

  & .participate-limits {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 0.875rem;
    justify-self: center;
    letter-spacing: 0.3px;
    color: var(--color-gray-200);
  }
`;
