import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@components/Common';
// import { Timer } from '@components/TimerEvent'
// import { useRaffleCondition } from '../../hooks'
import layout from '../../assets/golden-raffle-layout.png';
import addons from '../../assets/golden-raffle-addons.png';
// import { Phases } from '../../interfaces/reducer.leaders.interfaces'

export const LobbyGoldRaffle = () => {
  const { t } = useTranslation();
  // const { raffle } = useRaffleCondition('one-year')
  // if (!raffle || !raffle?.isActive) return null
  return (
    <Container to="/leaders-race-next/landing">
      <div className="inner-container">
        <span className="type">
          {t('Raffle interval', {
            context: 'one-year',
          })}
        </span>
        <span className="description">
          {t('Raffle appeal', {
            context: 'one-year',
          })}{' '}
          {t('Raffle faq', {
            context: 'one-year',
          })}{' '}
          {t('Raffle info', {
            context: 'one-year',
          })}
        </span>
        <span className="event-timer">
          {/*{[Phases.waiting].includes(raffle.phase) && (*/}
          {/*  <>*/}
          {/*    <Timer finishEvent={raffle.finishAt} />*/}
          {/*    <span>{t('for raffle')}</span>*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{[Phases.step_waiting].includes(raffle.phase) && (*/}
          {/*  <>*/}
          {/*    <Timer key={raffle.phase} finishEvent={raffle.items[raffle.stage]?.raffleAt} />*/}
          {/*    <span>{t('for raffle')}</span>*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{Phases.step_raffling === raffle.phase && <span>{t('Raffling')}</span>}*/}
        </span>
        <Button className="full secondary">
          {t('To learn more')}
          <i className="icon-arrow-up" />
        </Button>
      </div>
    </Container>
  );
};

const Container = styled(Link)`
  & .inner-container {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.5rem;
    align-content: space-between;
    min-height: 23.75rem;
    margin: 1.5rem 1rem;
    padding: 2.5rem 1.5rem 1rem;
    background-color: #ffbf00;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${layout});
    border-radius: var(--size-radius-m);
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      width: 215px;
      height: 180px;
      background-image: url(${addons});
      background-repeat: no-repeat;
      position: absolute;
      right: -3rem;
      top: 0;
    }

    & .type {
      width: 60%;
      font-weight: bold;
      font-size: 1.875rem;
      line-height: 2.1875rem;
      letter-spacing: 0.54px;
      color: white;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
    }

    & .description {
      width: 70%;
      display: block;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: black;
    }

    & .event-timer {
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.4375rem;
      color: var(--color-dark-600);

      & span {
        font-weight: inherit;
        font-size: inherit;
        line-height: 1.4375rem;
        margin-left: 0.25rem;
      }
    }

    & .icon-arrow-up {
      transform: rotate(90deg);
      font-size: 1rem;
      margin-left: 1rem;
    }
  }
`;
