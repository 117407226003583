import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUser } from 'core/User';
import { useForm } from 'react-hook-form';

export const HistoryTabs: FC<any> = ({ children }) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const { register, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      sortBy: '',
    },
  });

  const sortBy = watch('sortBy');

  return (
    <>
      <Container>
        <label>
          <input type="radio" name="sortBy" value="" ref={register} />
          <span>{t('Recent wins')}</span>
        </label>
        {user?.id && (
          <label>
            <input type="radio" name="sortBy" value={user.id} ref={register} />
            <span>{t('Your games')}</span>
          </label>
        )}
      </Container>
      {children({ sortBy })}
    </>
  );
};

const Container = styled.form`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(1fr, 50%);
  flex-shrink: 0;
  height: 2.125rem;
  background: var(--color-dark-100);
  border-bottom: 1px solid var(--color-gray-900);
  position: relative;
  z-index: 31;

  @media screen and (min-width: 320px) {
    height: 3.125rem;
  }

  & label {
    justify-self: stretch;
    align-self: stretch;

    & input {
      display: none;
    }

    & input:checked + span {
      color: var(--color-white);
      border-bottom: 2px solid white;
    }

    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-bottom: 2px solid transparent;

      font-size: 0.75rem;
      line-height: 1rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
      text-transform: uppercase;
    }
  }
`;
