import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 27px calc(20px / 2) 34px calc(16px / 2);
  padding-top: 1.6rem;
  height: calc(16px + 1.6rem);

  .input-wrapper {
    width: calc(100% + 16px);
    margin: 0 calc(16px / -2);
    position: absolute;
    height: 16px;
  }

  .control-wrapper {
    width: 100%;
    position: absolute;
    height: 16px;
  }

  .input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;
    background-color: #fff;
    border-color: #dbdbdb;
    color: #363636;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 10%);
    max-width: 100%;

    &::-ms-track {
      appearance: none;
      background: transparent;
      border: transparent;
    }

    &::-moz-range-track {
      appearance: none;
      background: transparent;
      border: transparent;
    }

    &:focus::-webkit-slider-runnable-track {
      appearance: none;
      background: transparent;
      border: transparent;
    }

    &::-ms-thumb {
      appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      border-radius: none;
      border: 0 none;
      cursor: grab;
      background-color: red;

      &:active {
        cursor: grabbing;
      }
    }

    &::-moz-range-thumb {
      appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      border-radius: none;
      border: 0 none;
      cursor: grab;
      background-color: red;

      &:active {
        cursor: grabbing;
      }
    }

    &::-webkit-slider-thumb {
      appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      border-radius: none;
      border: 0 none;
      cursor: grab;
      background-color: red;

      &:active {
        cursor: grabbing;
      }
    }
  }

  .rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    border-radius: 3px;
    background: #182328;
  }

  .inner-rail {
    position: absolute;
    height: 100%;
    background: #47555f;
    opacity: 0.5;
  }

  .control {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    background: #47555f;
    top: 50%;
    margin-left: calc(16px / -2);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .control-inner {
      z-index: 3;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: var(--color-white);
      display: flex;
      justify-content: center;
      align-items: center;

      .control-3 {
        z-index: 4;
        width: 10px;
        border-radius: 50%;
        height: 10px;
        background: #131a1d;
      }
    }
  }
`;
