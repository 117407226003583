import React, { ComponentClass, FC } from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { SpringConfig } from 'react-spring';
import { animated, Transition } from 'react-spring/renderprops';
import { useCreateModal } from '../hooks';
import { transitions, transitionsConfig } from '../transition.config';

// import { useDrag } from 'react-use-gesture'

export interface IModalComponent {
  handleCloseModal: () => void;
  data: boolean | (string | number)[];
}

interface IModal {
  modalComponent: FC<IModalComponent> | ComponentClass<IModalComponent>;
  layoutComponent?: typeof animated.div;
  modalName: string;
  transition: transitions;
  anchor: string;
  duration?: SpringConfig;
}

export const Modal: FC<IModal> = ({
  modalComponent,
  layoutComponent,
  modalName,
  transition = transitions.SLIDE_UP,
  anchor,
  duration,
}) => {
  const { modalState, handleCloseModal } = useCreateModal(modalName);

  // const bind = useDrag(({ movement: [, y], cancel, canceled }) => {
  //   if (Math.abs(y) > 60 && !canceled) {
  //     cancel()
  //     handleCloseModal()
  //   }
  // })

  return (
    <Transition
      native
      items={modalState ? { modalState } : null}
      from={transitionsConfig[transition].from}
      enter={transitionsConfig[transition].enter}
      leave={transitionsConfig[transition].leave}
      config={transitionsConfig[transition].duration(duration)}
    >
      {item => ({ layout, ...rest }) =>
        item &&
        ReactDOM.createPortal(
          <>
            {React.createElement(layoutComponent || AnimatedLayout, {
              style: { opacity: layout },
              onClick: handleCloseModal,
            })}
            <AnimatedDiv style={{ ...rest }} className={transition}>
              {React.createElement(modalComponent, {
                handleCloseModal,
                data: item?.modalState,
              })}
            </AnimatedDiv>
          </>,
          document.getElementById(anchor) as HTMLElement
        )}
    </Transition>
  );
};

const AnimatedDiv = styled(animated.div)`
  transform-origin: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2400;
  will-change: transform, opacity;

  &.FULL_SCREEN {
    height: 100%;
  }

  &.SLIDE_UP_CENTER,
  &.SCALABLE {
    left: 1.5rem;
    right: 1.5rem;
    width: auto;
  }

  & + div {
    z-index: 2500;

    & + div {
      z-index: 3500;
    }
  }
`;

const AnimatedLayout = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  z-index: 2000;
  padding-top: var(--size-nav-bar);
`;
