import React, { FC, memo } from 'react';
import CountUp from 'react-countup';
import { isEqual } from 'lodash';
import { Arrows, Container, ItemPrice } from './TeamTotalPrice.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';

interface TeamTotalPriceProps {
  teamTotalPrice: number;
  isWinner: boolean;
  isJoker: boolean;
}

export const TeamTotalPrice: FC<TeamTotalPriceProps> = memo(
  ({ teamTotalPrice, isWinner, isJoker }) => {
    const color = isWinner
      ? isJoker
        ? 'var(--pink-100)'
        : 'var(--green-300)'
      : 'var(--orange-400)';

    return (
      <Container>
        {!!teamTotalPrice && (
          <Arrows color={color} isWinner={isWinner}>
            <CaseBattleIcon.ArrowPrice className="arrow_top" />
            <CaseBattleIcon.ArrowPrice className="arrow_bottom" />
          </Arrows>
        )}
        <ItemPrice color={!!teamTotalPrice ? color : null}>
          <CountUp
            decimal="."
            decimals={teamTotalPrice ? 2 : 0}
            duration={1.5}
            end={teamTotalPrice / 100}
            delay={0}
            prefix={'◎ '}
            preserveValue={true}
          />
        </ItemPrice>
      </Container>
    );
  },
  isEqual
);
