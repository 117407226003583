import { Reducer } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, merge, of } from 'rxjs';
import { Record } from 'immutable';
import { socketService } from 'services/socket.service';
import { addNotification } from '../../Notifications/duck';
import leaderStatisticRepository from '../repositories/leaders.statistics.repository';
import { statisticsActions, statisticsTypes } from '../interfaces/actions.statistics.interface';
import { ITotalStatistics, IYearlyStatistics } from '../interfaces/reducer.statistics.interfaces';
import { AppShellActionsTypes } from 'core/AppShell/interfaces/appshell.actions.interface';

const StatisticRecord = Record<ITotalStatistics>({
  total: null,
  topPlayers: [],
  leaderboards: [],
  early: {
    leaderboard: [],
    myStat: null,
  },
});

export const statistics: Reducer<Record<ITotalStatistics>, statisticsActions> = (
  state = new StatisticRecord(),
  action
) => {
  switch (action.type) {
    case statisticsTypes.FETCH_STATISTICS_RESPONSE: {
      const { total, topPlayers, leaderboards } = action.payload;
      return state
        .set('total', total)
        .set('topPlayers', topPlayers)
        .set('leaderboards', leaderboards);
    }

    case statisticsTypes.FETCH_YEARLY_STATISTICS_RESPONSE: {
      return state.set('early', action.payload);
    }

    default: {
      return state;
    }
  }
};

const updateStatistics = (data: ITotalStatistics) => ({
  type: statisticsTypes.FETCH_STATISTICS_RESPONSE,
  payload: data,
});

const updateYearlyStatistic = (data: IYearlyStatistics) => ({
  type: statisticsTypes.FETCH_YEARLY_STATISTICS_RESPONSE,
  payload: data,
});

export const statisticEpic: Epic = action$ =>
  action$.pipe(
    ofType(AppShellActionsTypes.INITIALIZED),
    mergeMap(() =>
      merge(
        leaderStatisticRepository
          .fetchYearlyStatistics()
          .pipe(map(({ response }) => updateYearlyStatistic(response))),
        from(
          fetch(
            `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/total-stat`,
            {
              method: 'GET',
              mode: 'cors',
              credentials: 'include',
            }
          ).then(r => r.json())
        ).pipe(
          map(data => updateStatistics(data)),
          catchError(() =>
            of(
              addNotification({
                type: 'error',
              })
            )
          )
        )
      )
    )
  );
