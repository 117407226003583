import styled from 'styled-components';
import { animated } from 'react-spring';
import { Button } from '@components/Common';
import { ModalType } from './referral-dialog';

export const Container = styled.div<{ modalType: ModalType }>`
  background: var(--color-dark-200);
  padding: 15px;
  border-radius: 10px;
  ${({ modalType }) =>
    modalType === 'not-authenticated' &&
    'background: var(--bonus-code-modal-bg);padding-bottom: 0px;border-radius: 10px;'}
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 7px;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.286px;
  text-align: center;
`;

export const PromoCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.396px;
  text-align: left;
  color: var(--color-gray-200);
  margin-bottom: 18px;
`;

export const Span = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.396px;
  text-align: left;
  color: var(--success);
`;

export const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--color-white);
  gap: 9px;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

export const UserIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const UserDetailContainerSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.308px;
  text-align: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  margin-bottom: 30px;
`;

export const BonusContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

export const BonusSpan = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.286px;
  text-align: center;
  color: var(--success);
`;

export const BonusCount = styled.span`
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.54px;
  text-align: center;
  color: var(--success);
`;

export const StyledButton = styled(Button)`
  background: var(--yellow);
`;

export const AdditionalSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5708px;
  text-align: left;
  margin-top: 16px;
`;

export const AuthContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const AuthTitle = styled.span`
  color: var(--color-white);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.286px;
  text-align: center;
`;
export const SteamButton = styled(Button)`
  background: var(--color-provider-steam);
  margin: 21px 0 29px;

  &:disabled {
    opacity: 0.2;
  }
`;

export const SteamButtonTextContainer = styled.div`
  color: var(--color-white);
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;

export const AuthSpan = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.31px;
  text-align: center;
  color: var(--color-gray-200);
  margin-bottom: 15px;
`;

export const AuthMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
`;

export const AuthMethod = styled.button`
  width: 44px;
  height: 44px;
  text-decoration: none;
  border: none;
  padding: 10px;
  border-radius: 24px;
  background: var(--color-dark-200);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 320px) {
    width: 36px;
    height: 36px;
    padding: 5px;
  }

  &:disabled {
    opacity: 0.2;
  }
`;

export const TroubleContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(24, 34, 40, 85%);
  backdrop-filter: blur(4px);
  bottom: 0;
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
  text-align: center;
  width: 100%;
  margin-top: 14px;
`;

export const TroubleButton = styled(Button)`
  color: var(--aqua-500);
  font-size: 0.625rem;
  font-weight: bold;
  height: auto;
  justify-content: center;
  padding-bottom: 16px;
  text-transform: none;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 16px;
  width: 100%;
`;

export const StyledLabel = styled.label`
  color: var(--color-white);
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5708px;
  line-height: 12px;
  padding-left: 42px !important;
  text-align: center;
  text-align: left;
  width: 100%;

  & .tos {
    color: var(--aqua-500);
    font-size: 0.625rem;
  }
`;

export const Info = styled.span`
  color: var(--aqua-500);
  display: block;

  &.hide {
    display: none;
  }
`;

export const AnimatedContainer = styled(animated.div)`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
`;

export const InfoFull = styled.span`
  overflow: hidden;
`;
