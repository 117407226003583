import React, { FC, memo, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import { isEqual } from 'lodash';
import { Arrows, Avatar, Container, Currency, PlayerName, User } from './Player.styles';
import { GameMember } from 'games/CaseBattle/interfaces';
import { getUserAvatarUrl } from 'core/utils';
import { useLoadAccountProfile } from 'modules/Accounts';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { DEFAULT_PRICE_HEIGHT, GameStatus } from 'games/CaseBattle/constants';
import { useGetCaseBattleGameData } from 'games/CaseBattle/hooks';
import { Emoji } from '../Emoji';
import { getArrayOfEmojis } from 'games/CaseBattle/utils';

interface PlayerProps {
  creatorId: number;
  gameStatus: GameStatus;
  hasAllPlayers: boolean;
  maxTotalPrice: number;
  player: GameMember;
  totalPrice: number;
  winnerIds: number[];
  priceHeight: number;
  setPriceHeight: (h: number) => void;
  containerWidth: number;
  setContainerWidth: (w: number) => void;
}

export const Player: FC<PlayerProps> = memo(
  ({
    hasAllPlayers,
    player,
    totalPrice,
    creatorId,
    maxTotalPrice,
    gameStatus,
    winnerIds,
    priceHeight,
    setPriceHeight,
    containerWidth,
    setContainerWidth,
  }) => {
    const {
      data: { game },
      isReplay,
    } = useGetCaseBattleGameData();
    const { handleOpenProfile } = useLoadAccountProfile();
    const priceRef = useRef<HTMLDivElement>();
    const containerRef = useRef<HTMLDivElement>();
    const { user_id, type, ava_link, name } = player;
    const isWinner = gameStatus === GameStatus.finish && winnerIds?.includes(user_id);

    useEffect(() => {
      if (isReplay) {
        setPriceHeight(DEFAULT_PRICE_HEIGHT);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReplay]);

    useEffect(() => {
      const priceElement = priceRef.current;

      if (!priceElement) return;

      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentRect.height !== priceHeight) {
            setPriceHeight(entry.contentRect.height);
          }
        }
      });

      observer.observe(priceElement);

      const updateContainerWidth = () => {
        if (containerRef.current.offsetWidth !== containerWidth) {
          setContainerWidth(containerRef.current.offsetWidth);
          setPriceHeight(DEFAULT_PRICE_HEIGHT);
        }
      };

      window.addEventListener('resize', updateContainerWidth);

      return () => {
        window.removeEventListener('resize', updateContainerWidth);
        observer.disconnect();
      };
    }, [containerWidth, priceHeight, setContainerWidth, setPriceHeight]);

    const handleOpenUser = () => {
      if (!name.toLowerCase().includes('bot #')) {
        handleOpenProfile(user_id)();
      }
    };

    return (
      <Container hasAllPlayers={hasAllPlayers} ref={containerRef}>
        <User>
          <Avatar profileType={type} isWinner={isWinner} onClick={handleOpenUser}>
            {((gameStatus !== GameStatus.finish && !isReplay) || isReplay) &&
              getArrayOfEmojis(game?.emoji)?.map(emoji => {
                if (user_id === emoji.userId) {
                  return <Emoji key={emoji.id} emoji={emoji} isBarEmoji={false} />;
                }
              })}
            <img src={getUserAvatarUrl(ava_link)} alt={name} />
            {creatorId === user_id && <CaseBattleIcon.FlagIcon className="flag" />}
            {isWinner &&
              (game.settings.jokerMode ? (
                <CaseBattleIcon.Joker className="crown" />
              ) : (
                <CaseBattleIcon.CrownIcon className="crown" />
              ))}
          </Avatar>
          <PlayerName>{name}</PlayerName>
        </User>
        <Currency ref={priceRef} priceHeight={priceHeight}>
          {!!totalPrice && maxTotalPrice === totalPrice && (
            <Arrows isJoker={game.settings.jokerMode}>
              <CaseBattleIcon.ArrowPrice className="arrow_top" />
              <CaseBattleIcon.ArrowPrice className="arrow_bottom" />
            </Arrows>
          )}
          <span className="symbol">◎</span>
          <CountUp
            decimal="."
            decimals={totalPrice ? 2 : 0}
            duration={1.5}
            end={totalPrice / 100}
            delay={0}
            preserveValue={true}
            className="value"
          />
        </Currency>
      </Container>
    );
  },
  isEqual
);
