import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createPayoutOrder, cleanUpOrder } from '../../duck';

export const useCreateOrder = () => {
  const dispatch = useDispatch();

  const createOrderAction = useCallback(
    (paymentSystem: string) => () => {
      dispatch(createPayoutOrder(paymentSystem));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(cleanUpOrder());
    };
  }, [dispatch]);

  return {
    createOrderAction,
  };
};
