export default {
  GAME: 'JOC',
  Winner: 'Câştigător',
  Won: 'Câştig',
  Chance: 'Şansă',
  Prize: 'Premiu',
  'At stake': 'În joc',
  'Sign in through STEAM to participate': 'Conectați-vă prin intermediul STEAM pentru a participa',
  'Steam trade link': 'Link către tranzacţii Steam',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Fii primul care depune',
  'Place a bet': 'Lansează un pariu',
  'Deposit more': 'Depune mai mult',
  'Min bet': 'Nr. min. pariu',
  'Max bet': 'Pariu max.',
  'Bet amount': 'Valoare pariu',
  'max. items': 'Nr. max. articole',
  hidden: 'ascunse',
  Copy: 'Copiază',
  'Winning after commission': 'Câștig după comision',
  Loading: 'Încărcare',
};
