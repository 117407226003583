import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  padding-bottom: 31px;
`;

export const Title = styled.div`
  align-self: flex-end;
  color: var(--color-white);
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
`;

export const Span = styled.span`
  align-self: flex-start;
  color: var(--color-gray-200);
  display: flex;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 15px;
  text-align: center;
`;
export const TitleContainer = styled.div`
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 11px 15px 19px 18px;
  width: 100%;
`;
