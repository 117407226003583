import { ICardsSide, BoardTypes } from './interfaces';

export const getHistoryBaccarat = ({ baccarat }: any) => baccarat.history;

export const getGameBaccarat = ({ baccarat }: any) => baccarat.game;

export const getBetsBaccarat = ({ baccarat }: any) => baccarat.bets;

export const getGameNumber = ({ baccarat }: any) => baccarat.game.gameId;
export const getGameHash = ({ baccarat }: any) => baccarat.game.hash;
export const getGameSalt = ({ baccarat }: any) => baccarat.game.salt;
export const getGameCondition = ({ baccarat }: any) => baccarat.game.phase;
export const getHighlightSector = ({ baccarat }: any) => baccarat.game.sectors;
export const getBaccaratTimeout = ({ baccarat }: any) => baccarat.game.timeout;
export const getBaccaratUntilTime = ({ baccarat }: any) =>
  baccarat.game.started + baccarat.game.timeout;
export const getBaccaratCards = (side: ICardsSide) => ({ baccarat }: any): string[] =>
  baccarat.game.cards[side];
export const getBaccaratHandsCard = ({ baccarat }: any) => baccarat.game.cards;
export const getBaccaratMyBet = ({ baccarat }: any) => baccarat.game.myBet;
export const getBaccaratMembers = (type: BoardTypes) => ({ baccarat }: any) =>
  baccarat.bets.members?.[type];
export const getBaccaratBet = (type: BoardTypes) => ({ baccarat, user }: any) =>
  baccarat.bets.members?.[type]?.[user.base?.id];
export const getBaccaratTotalAmounts = ({ baccarat }: any) => baccarat.bets.totals;
