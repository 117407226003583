import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FilterDialog } from 'games/CaseGame/dialog-config';
import { useGetCaseListData, useGetSortParams } from 'games/CaseGame/hooks';
import { useDialog } from 'core/hooks';
import { actionRequestCaseList, priceRangeAction, searchParamsAction } from 'games/CaseGame/ducks';
import { FiltersContainer } from '@components/filters';

export const CaseFilters = () => {
  const {
    casesData: { data },
  } = useGetCaseListData();
  const { sortBy, maxPrice, minPrice, query } = useGetSortParams();
  const { pathname } = useLocation();
  const { handleToggleDialog } = useDialog(FilterDialog.SORT_DIALOG);
  const dispatch = useDispatch();

  const cleanRangePrice = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      actionRequestCaseList({
        id: data.idCategory,
        filterParams: { sortBy, query },
      })
    );
    dispatch(priceRangeAction(null, null));
  };

  const cleanSearch = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      actionRequestCaseList({
        id: data.idCategory,
        filterParams: { sortBy, maxPrice, minPrice },
      })
    );
    dispatch(searchParamsAction(null));
  };

  return (
    <FiltersContainer
      sortProps={{
        text: sortBy,
        openSortDialog: handleToggleDialog(FilterDialog.SORT_DIALOG, pathname),
      }}
      priceRangeProps={{
        maxPrice,
        minPrice,
        cleanRangePrice,
        openPriceRangeDialog: handleToggleDialog(FilterDialog.PRICE_RANGE, pathname),
      }}
      searchProps={{
        openSearchDialog: handleToggleDialog(FilterDialog.SEARCH, pathname),
        query,
        cleanSearch,
      }}
      i18nextKey="cases2.0"
    />
  );
};
