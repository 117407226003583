import Immutable from 'immutable';
import { IInventoryElement } from './inventory.reducers.interface';

export const SalesRecord = Immutable.Record<ISalesRecord>({
  loading: false,
  loaded: false,
  failure: false,
  selected: {
    computed: {
      amount: 0,
    },
    identifiers: [],
  },
  selling: {
    computed: {
      active: 0,
      amount: 0,
    },
    identifiers: [],
  },
  entities: {},
});

export interface ISalesRecord {
  loading: boolean;
  loaded: boolean;
  failure: boolean;
  selected: {
    computed: {
      amount: number;
    };
    identifiers: string[];
  };
  selling: {
    computed: {
      active: number;
      amount: number;
    };
    identifiers: string[];
  };
  entities: Record<string, any>;
}

export type IFetchSellingSuccessResponse = {
  entities: Record<string, any>;
  selling: {
    computed: {
      active: number;
      amount: number;
    };
    identifiers: string[];
  };
};

export type IBatchRequest = {
  steamInventoryId: number;
  price: number;
  autoApprove: boolean;
  fastDelivery: boolean;
  tradesDuration: number;
};

export type TradeActions = 'sell-now' | 'confirm' | 'decline';

export interface ISalesInventoryElement extends IInventoryElement {
  selling: {
    price: IBatchRequest['price'];
    tradesDuration: IBatchRequest['tradesDuration'];
  };
}
