import React from 'react';
import { useDialog } from 'core/hooks';
import { useGetCaseData } from 'games/CaseGame/hooks';
import {
  Button,
  Container,
  Content,
  ContentWrapper,
  Header,
  HeaderSpan,
  Name,
  Price,
  SpanCount,
} from './cases-counts.styles';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { CaseItemDialog } from 'games/CaseGame';

export const CasesCountsDialog = () => {
  const { handleCloseDialog } = useDialog(CaseItemDialog.COUNT_DIALOG);
  const { t } = useTranslation('cases2.0');

  const { data } = useGetCaseData();
  const key = localStorage.getItem('itemName');
  const headerTabs = [
    t('countsDialogHeader.wear'),
    t('countsDialogHeader.price'),
    t('countsDialogHeader.odds'),
  ];

  return (
    <Container>
      <Header>
        {headerTabs.map(tab => (
          <HeaderSpan key={tab}>{tab}</HeaderSpan>
        ))}
      </Header>
      <ContentWrapper>
        {data.itemsForCaseShow[key].customItemInfo.map(({ exterior, count, price }) => (
          <Content key={price}>
            <Name>{exterior}</Name>
            <Price>
              <CurrencyText value={price} />
            </Price>
            <SpanCount>{count / 1000}%</SpanCount>
          </Content>
        ))}
      </ContentWrapper>
      <Button onClick={handleCloseDialog}>{t('countsDialogHeader.close')}</Button>
    </Container>
  );
};
