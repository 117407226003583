import { AjaxError } from 'rxjs/ajax';
import { ACTION_TYPE } from '../actionType';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import CasesRepository from '../../repository/casesRepository';
import { CaseList } from '../../interfaces/cases.interface';
import {
  ActionCaseList,
  ActionRequestCaseList,
  ActionRequestCaseListProps,
  CaseListState,
  CategoryDetailed,
} from './case-list.interfaces';
import { iif, of } from 'rxjs';
import { CASE_BATTLE_ACTION_TYPE } from 'games/CaseBattle/ducks/actionTypes';

const initialState: CaseListState = {
  data: {
    caseList: [],
    idCategory: '',
  },
  isLoading: true,
  error: null,
};

// action creator
export const actionRequestCaseList = ({
  id,
  filterParams,
  isBattle,
}: ActionRequestCaseListProps) => ({
  type: ACTION_TYPE.CASE_LIST_ACTION_REQUEST,
  payload: {
    id,
    filterParams,
    isBattle,
  },
});

const actionResponseCaseList = (payload: CategoryDetailed) => ({
  type: ACTION_TYPE.CASE_LIST_ACTION_RESPONSE,
  payload,
});

export const actionRemoveCasesData = () => ({
  type: ACTION_TYPE.CASE_LIST_ACTION_REMOVE_CASES_DATA,
});

const actionErrorCaseList = (error: AjaxError) => ({
  type: ACTION_TYPE.CASE_LIST_ACTION_ERROR,
  payload: error,
});

const actionResponseCaseListFromStaticCategory = (cases: CaseList, id: string) => {
  return {
    type: 'app/cases-game/ACTION_RESPONSE_STATIC_CATEGORY',
    payload: {
      cases: cases,
      id: id,
    },
  };
};

const actionResponseCaseListCaseBattle = (cases?: CaseList) => ({
  type: CASE_BATTLE_ACTION_TYPE.CASE_BATTLE_CASE_LIST_ACTION_RESPONSE,
  payload: cases,
});
//NOTE: этот экшн должен находиться в данном файле на момент разработки не было понятно почему он не работает при импорте поэтому из файла src/games/CaseBattle/ducks/cases-duck/cases-duck.ts
//NOTE: по какой-то причине (плохой заложенной архитектуре редакса) логика эпика не работала если импортнуть экшн, данный экшн относится к игре CaseBattle и нужна для сохранения всех кейсов в отдельный объект, чтобы отрисовать его , без изменений ,
//NOTE: для этого было создан параметр isBattle.

export const caseListEpic: Epic = (action$: ActionsObservable<ActionRequestCaseList>) =>
  action$.pipe(
    ofType(ACTION_TYPE.CASE_LIST_ACTION_REQUEST),
    switchMap(({ payload }) => {
      if (payload.id === 'all' || payload.id === 'free') {
        return CasesRepository.fetchGetCases(
          payload.id === 'all' && !payload.isBattle ? true : null,
          payload.filterParams
        ).pipe(
          switchMap(({ response }: { response: CaseList }) => {
            return iif(
              () => payload.isBattle,
              of(
                actionResponseCaseListFromStaticCategory(response, payload.id),
                actionResponseCaseListCaseBattle(response)
              ),
              of(actionResponseCaseListFromStaticCategory(response, payload.id))
            );
          })
        );
      } else {
        return CasesRepository.fetchCategoryCases(payload.filterParams, payload.id).pipe(
          map(({ response }: { response: CategoryDetailed }) => actionResponseCaseList(response)),
          catchError((error: AjaxError) => of(actionErrorCaseList(error)))
        );
      }
    })
  );

export const caseList = (state: CaseListState = initialState, action: ActionCaseList) => {
  switch (action.type) {
    case ACTION_TYPE.CASE_LIST_ACTION_REQUEST: {
      return {
        ...state,
        isLoading:
          state.data?.caseList?.length === 0 || action.payload.id !== state.data.idCategory,
      };
    }
    case ACTION_TYPE.CASE_LIST_ACTION_RESPONSE:
      return {
        ...state,
        data: {
          caseList: action.payload.cases,
          idCategory: action.payload.id,
        },
        isLoading: false,
      };
    case ACTION_TYPE.CASE_LIST_ACTION_RESPONSE_STATIC_CATEGORY:
      if (action.payload.id === 'free') {
        const freeCaseList = action.payload.cases.filter(value => value.free);
        return {
          ...state,
          data: {
            caseList: freeCaseList,
            idCategory: action.payload.id,
          },
          isLoading: false,
        };
      }
      if (action.payload.id === 'all') {
        const caseList = action.payload.cases.filter(value => value.type !== 'free');
        return {
          ...state,
          data: {
            caseList,
            idCategory: action.payload.id,
          },
          isLoading: false,
        };
      }
      return {
        ...state,
        data: {
          caseList: action.payload.cases,
          idCategory: action.payload.id,
        },
        isLoading: false,
      };

    case ACTION_TYPE.CASE_LIST_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
