import easyImg from './assets/easy@3x.png';
import mediumImg from './assets/medium@3x.png';
import hardImg from './assets/hard@3x.png';

import easy from './assets/group.png';
import medium from './assets/group_easy.png';
import hard from './assets/group-3.png';

export const getRewards = (ticketType, price) => {
  const { factor, max_rows, max_prize, columns } = ticketType;
  const rewards = [];
  for (let i = 0; i < max_rows && price < max_prize; i++) {
    price = price * factor;
    if (price > max_prize) {
      break;
    }
    rewards.push(new Array(columns).fill(Math.floor(price)));
  }

  return rewards;
};

export const getTypeImage = typeName => {
  switch (typeName) {
    case 'easy':
      return easyImg;
    case 'medium':
      return mediumImg;
    case 'hard':
      return hardImg;
    default:
      return null;
  }
};

export const getTypeImageHistory = typeName => {
  switch (typeName) {
    case 'easy':
      return easy;
    case 'medium':
      return medium;
    case 'hard':
      return hard;
    default:
      return null;
  }
};
