import React from 'react';
import { Modal, transitions } from 'core/Modals';
import { accountsModals } from '../../modal.config';
import { PlayerProfile } from './PlayerProfile';

export const PlayerProfileModal = () => (
  <Modal
    modalComponent={PlayerProfile}
    modalName={accountsModals.PLAYER_PROFILE}
    anchor="portal"
    transition={transitions.SLIDE_UP_CENTER}
  />
);
