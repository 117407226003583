import { Reducer } from 'redux';
import { Map, Record } from 'immutable';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, fromEvent, iif, merge, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  endWith,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { getUser } from 'core/User';
import { addNotification } from 'modules/Notifications/duck';
import { ROULETTE_ANIMATION } from '../common.utils';
import { AppShellActionsTypes } from 'core/AppShell/interfaces/appshell.actions.interface';
import raffleRepository from '../repositories/leaders.raffle.repository';
import { raffleActions, raffleActionsTypes } from '../interfaces/actions.leaders.interfaces';
import {
  BetConditions,
  IRaffle,
  IRafflingItem,
  Phases,
  responseBetConfirm,
} from '../interfaces/reducer.leaders.interfaces';
import { IDispatch } from 'core/rootInterfaces/root.interface';
import { ISocketService } from 'services/interfaces/services.interface';
import { fetchTickets } from './coupons.duck';

const RafflesMap = Map<number, Record<IRaffle>>();

const RaffleRecord = Record({
  id: null,
  type: '',
  startAt: null,
  finishAt: null,
  isActive: false,
  items: [],
  ownBets: {},
  phase: Phases.waiting,
  stage: null,
});

export const raffles: Reducer<Map<number, Record<IRaffle>>, raffleActions> = (
  state = RafflesMap,
  action
) => {
  switch (action.type) {
    case raffleActionsTypes.INITIALIZE_RAFFLE: {
      const { raffle, patchId } = action.payload;
      return state.set(patchId, new RaffleRecord(raffle));
    }

    case raffleActionsTypes.UPDATE_RAFFLE_ITEMS: {
      const { id, items } = action.payload;
      return state.setIn([id, 'items'], items);
    }

    case raffleActionsTypes.UPDATE_GREAT_RAFFLES: {
      const { lot } = action.payload;

      if (!state.hasIn([lot.raffleId])) {
        return state;
      }

      const raffle = state.get(lot.raffleId);
      const type = raffle.get('type');
      const lots = state.getIn([lot.raffleId, 'items']);
      const idx = lots.findIndex((item: IRafflingItem) => item.id === lot.id);

      const updater = state
        .setIn([lot.raffleId, 'items', idx], lot)
        .setIn([lot.raffleId, 'stage'], idx);

      if (['two-week', 'two-month'].includes(type)) {
        return updater.setIn([lot.raffleId, 'phase'], Phases.raffling);
      }
      if ('one-year' === type) {
        const phase = lot.winnerId ? Phases.step_raffling : Phases.step_waiting;
        return updater.setIn([lot.raffleId, 'phase'], phase);
      }

      return updater;
    }

    case raffleActionsTypes.UPDATE_RAFFLE_STAGE: {
      const { raffleIds } = action.payload;
      const origin = state.get(raffleIds);

      if (Date.now() > Date.parse(origin.get('finishAt'))) {
        if (['two-week', 'two-month'].includes(origin.get('type'))) {
          const lots = origin.get('items');
          const idx = Date.now() - Date.parse(origin.get('finishAt')) / 15000;
          const stage = idx >= 0 ? Math.min(idx, lots.length) : null;

          return state
            .setIn([raffleIds, 'stage'], Number(stage).toFixed(0))
            .setIn([raffleIds, 'phase'], Phases.raffling);
        }
        if ('one-year' === origin.get('type')) {
          const lots = origin.get('items');
          const index = lots.findIndex(lot => Date.now() < Date.parse(lot.raffleAt));

          if (index < 0) {
            return state
              .setIn([raffleIds, 'stage'], null)
              .setIn([raffleIds, 'phase'], Phases.finished);
          }

          const idx = index;
          const stage = idx >= 0 ? idx : null;
          const step = Math.abs(Date.now() - Date.parse(lots[stage].raffleAt)) > ROULETTE_ANIMATION;
          const phase = step ? Phases.step_waiting : Phases.step_raffling;

          return state.setIn([raffleIds, 'stage'], stage).setIn([raffleIds, 'phase'], phase);
        }
      }
      return state;
    }

    case raffleActionsTypes.UPDATE_RAFFLE_PHASE: {
      const { raffleId, phase } = action.payload;

      if (!state.hasIn([raffleId])) {
        return state;
      }

      const raffle = state.get(raffleId);
      const stage = raffle.get('stage');
      const items = raffle.get('items');

      if (items.length === stage + 1) {
        return state.setIn([raffleId, 'stage'], null).setIn([raffleId, 'phase'], Phases.finished);
      }
      return state.setIn([raffleId, 'phase'], phase);
    }

    case raffleActionsTypes.FETCH_ADD_BET_REQUEST: {
      const { raceId, itemId } = action.payload;
      return state.setIn([raceId, 'ownBets', itemId], {
        status: BetConditions.pending,
      });
    }

    case raffleActionsTypes.FETCH_ADD_BET_RESPONSE: {
      const { raceId, itemId, success, error } = action.payload;
      return state.setIn([raceId, 'ownBets', itemId], {
        status: success,
        error: error || '',
      });
    }

    case raffleActionsTypes.UPDATE_BETS_STATUS: {
      const { raffleId, items, userId } = action.payload;
      const bets = items.reduce((acc, item) => {
        if (item.members[userId]) {
          return {
            ...acc,
            [item.id]: {
              status: BetConditions.success,
              error: '',
            },
          };
        }
        return acc;
      }, {});

      return state.setIn([raffleId, 'ownBets'], bets);
    }

    case raffleActionsTypes.RECEIVE_BET_CONFIRM: {
      const {
        betStatus: { raffleId, raffleItemId, memberId },
      } = action.payload;

      const items: IRafflingItem[] = state.getIn([raffleId, 'items']);

      if (!items) return state;

      const idx = items.findIndex(({ id }) => id === raffleItemId);

      if (state.hasIn([raffleId, 'items', idx])) {
        return state.updateIn([raffleId, 'items', idx, 'membersIds'], (origin: number[]) => [
          ...origin,
          memberId,
        ]);
      }
      return state;
    }

    case raffleActionsTypes.RECEIVE_TAKEN_LOT_SUCCESS: {
      const { raffleId, id, takenAt } = action.payload.lot;
      const orignItems = state.getIn([raffleId, 'items']);
      const pathIndex = orignItems.findIndex((item: any) => item.id === id);
      return state.hasIn([raffleId, 'items', pathIndex])
        ? state.setIn([raffleId, 'items', pathIndex, 'takenAt'], takenAt)
        : state;
    }

    default: {
      return state;
    }
  }
};

export const fetchAllRaffles = () => ({
  type: 'INITIALIZE_LEADER_RACE',
});

export const fetchAddBet = (raceId: number, itemId: number) => ({
  type: raffleActionsTypes.FETCH_ADD_BET_REQUEST,
  payload: {
    raceId,
    itemId,
  },
});

const updateBetStatus = (
  raceId: number,
  itemId: number,
  success?: BetConditions,
  error?: string
) => ({
  type: raffleActionsTypes.FETCH_ADD_BET_RESPONSE,
  payload: {
    raceId,
    itemId,
    success,
    error,
  },
});

const updateBetsStatus = (raffleId: number, items: IRafflingItem[], userId?: number) => ({
  type: raffleActionsTypes.UPDATE_BETS_STATUS,
  payload: {
    raffleId,
    items,
    userId,
  },
});

export const fetchTakeWinnerLot = (raffleId: number, itemId: number) => async (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  const response = await fetch(
    `${process.env.PREFIX_GATEWAY_URL}${socket.domain}api/leaders-race/raffles/${raffleId}/items/${itemId}/take`,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    }
  );

  const date = await response.json();

  if (date.raffleId && date.id) {
    dispatch(receiveTakenLot(date));
  } else if (date.error) {
    dispatch(
      addNotification({
        type: 'error',
        header: 'error',
        body: date.error,
      })
    );
  }
};

const initRaffles = (raffle: IRaffle, patchId: number) => ({
  type: raffleActionsTypes.INITIALIZE_RAFFLE,
  payload: {
    raffle,
    patchId,
  },
});

const updateRaffleItems = (id: number, items: any[]) => ({
  type: raffleActionsTypes.UPDATE_RAFFLE_ITEMS,
  payload: {
    id,
    items,
  },
});

export const updateRaffles = (data: any) => ({
  type: raffleActionsTypes.UPDATE_RAFFLES,
  payload: {
    data,
  },
});

const updateGreatRaffle = (lot: any) => ({
  type: raffleActionsTypes.UPDATE_GREAT_RAFFLES,
  payload: {
    lot,
  },
});

const updateRaffleStage = (raffleIds: number) => ({
  type: raffleActionsTypes.UPDATE_RAFFLE_STAGE,
  payload: {
    raffleIds,
  },
});

const updateRafflePhase = (raffleId: number, phase: Phases) => ({
  type: raffleActionsTypes.UPDATE_RAFFLE_PHASE,
  payload: {
    raffleId,
    phase,
  },
});

const receiveTakenLot = (lot: any) => ({
  type: raffleActionsTypes.RECEIVE_TAKEN_LOT_SUCCESS,
  payload: {
    lot,
  },
});

const receiveBetConfirm = (betStatus: responseBetConfirm) => ({
  type: raffleActionsTypes.RECEIVE_BET_CONFIRM,
  payload: {
    betStatus,
  },
});

const loaderEpic: Epic = action$ =>
  action$.pipe(
    ofType(AppShellActionsTypes.INITIALIZED),
    mergeMap(() =>
      raffleRepository.fetchActiveRaffles().pipe(
        pluck('response'),
        switchMap((response: IRaffle[]) =>
          from(response).pipe(map((raffle: IRaffle) => initRaffles(raffle, raffle.id)))
        ),
        catchError(({ error }) => of(addNotification({ type: 'error', body: error })))
      )
    )
  );

const participantsEpic: Epic = action$ =>
  action$.pipe(
    ofType(raffleActionsTypes.FETCH_ADD_BET_REQUEST),
    debounceTime(700),
    pluck('payload'),
    mergeMap(({ raceId, itemId }) =>
      raffleRepository.fetchAddBet(raceId, itemId).pipe(
        switchMap(({ response }) =>
          iif(
            () => response.success,
            of(updateBetStatus(raceId, itemId, BetConditions.success)).pipe(
              delay(3000),
              startWith(updateBetStatus(raceId, itemId, BetConditions.temporary)),
              endWith(fetchTickets())
            ),
            of(updateBetStatus(raceId, itemId, BetConditions.error, response.error)).pipe(
              startWith(
                addNotification({
                  type: 'error',
                  header: 'error',
                })
              )
            )
          )
        ),
        catchError(({ error }) => of(addNotification({ type: 'error', body: error })))
      )
    )
  );

const raffleDetailedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(raffleActionsTypes.INITIALIZE_RAFFLE),
    mergeMap(({ payload }) =>
      raffleRepository.fetchDetailsRaffle(payload.patchId).pipe(
        map(({ response }) =>
          response.map((item: any) => {
            const formatMember = membersMapper(item.members);
            return {
              ...item,
              ...formatMember,
            };
          })
        ),
        concatMap(items => {
          const userId = getUser(state$.value);
          return [
            updateRaffleItems(payload.patchId, items),
            updateRaffleStage(payload.patchId),
            updateBetsStatus(payload.patchId, items, userId?.id),
          ];
        }),
        catchError(() => of(addNotification({ type: 'error', header: 'error' })))
      )
    )
  );

const listenersEpic: Epic = (action$, _, { socket }) =>
  action$.pipe(
    ofType(AppShellActionsTypes.INITIALIZED),
    switchMap(() =>
      merge(
        fromEvent(socket.io, 'leaders-race.raffling').pipe(
          switchMap((lots: any) =>
            merge(
              from(lots).pipe(
                map(({ members, ...rest }: any) => {
                  const formatMember = membersMapper(members);
                  return {
                    ...rest,
                    ...formatMember,
                  };
                }),
                concatMap((lot, idx) =>
                  of(lot).pipe(
                    delay(idx === 0 ? 0 : 15000),
                    map(lot => updateGreatRaffle(lot))
                  )
                )
              ),
              of(updateRafflePhase(lots[0].raffleId, Phases.finished)).pipe(
                delay(lots.length * 15000 + 3000)
              )
            )
          )
        ),
        fromEvent(socket.io, 'leaders-race.great.raffling').pipe(
          map(({ members, ...rest }: any) => {
            const formatMember = membersMapper(members);
            return {
              ...rest,
              ...formatMember,
            };
          }),
          switchMap(lot =>
            of(Phases.step_waiting).pipe(
              delay(lot.winnerId ? 17000 : 0),
              concatMap((phase: Phases) => [
                updateRafflePhase(lot.raffleId, phase),
                updateRaffleStage(lot.raffleId),
              ]),
              startWith(updateGreatRaffle(lot))
            )
          )
        ),
        fromEvent<responseBetConfirm>(socket.io, 'leaders-race.raffle-item:member-added').pipe(
          map(betStatus => receiveBetConfirm(betStatus))
        )
      )
    )
  );

export const raffleEpic = combineEpics(
  loaderEpic,
  participantsEpic,
  raffleDetailedEpic,
  listenersEpic
);

const membersMapper = (members: any[]) => {
  const format = members.reduce((acc: any, member: any) => {
    acc[member.id.toString()] = member;
    return acc;
  }, {});

  return {
    members: format,
    membersIds: Object.keys(format),
  };
};
