import * as React from 'react';
import styled from 'styled-components';
import { getItemUri } from 'core/utils/image.utils';
import { useTranslation } from 'react-i18next';

interface IStoreSkin {
  name: string;
  icon: string | null;
  color: string;
  ticketColor?: string;
  ticketsAmount?: number;
  available?: boolean;
  appId?: number;
  handleShowModal?: () => void;
}

export const StoreSkin: React.FC<IStoreSkin> = ({
  available,
  name,
  icon,
  color,
  ticketColor,
  ticketsAmount,
  appId,
  handleShowModal,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      rarity={color}
      onClick={handleShowModal && handleShowModal}
      available={available}
      disabled={!available}
      className={ticketColor}
    >
      <div className="skin-container">
        {appId && <i className={`icon-${appId}`} />}
        <div className="skin-thumbnail">
          <img src={getItemUri(icon, 75, 75)} alt="skin" />
        </div>
        <span className="skin-name">{name}</span>
        {ticketsAmount && available && (
          <div className="skin-tickets">
            <i className="icon-coupon" />
            <span>{ticketsAmount}</span>
          </div>
        )}
        {!available && <span>{t('Sold')}</span>}
      </div>
    </Container>
  );
};

interface IStoreSkinStyle {
  rarity: string;
  available: boolean;
}

const Container = styled.button<IStoreSkinStyle>`
  padding: 0.125rem 0 0 0;
  background: transparent;
  border: 0;
  position: relative;

  &:before {
    float: left;
    padding-top: 100%;
    content: '';
  }

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  &.green {
    --color-ticket: var(--regular-race);
  }
  &.blue {
    --color-ticket: var(--fast-race);
  }
  &.orange {
    --color-ticket: var(--great-race);
  }

  &:disabled .skin-container:before {
    content: '\\e929';
  }

  & .skin-container {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-rows: 1fr 1.125rem min-content;
    grid-gap: 0.2rem;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    box-shadow: 0 -0.125rem 0 ${p => p.rarity};
    border-radius: 0.5rem;
    background: var(--color-dark-200);
    position: relative;

    &:before {
      font-family: icomoon;
      font-size: 4.1rem;
      position: absolute;
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--color-dark-100);
    }

    & > * {
      align-self: center;
    }

    & > i {
      font-size: 0.8rem;
      color: var(--color-white-600);
      position: absolute;
      top: 0.4rem;
      right: 0.4rem;
    }

    & .skin-thumbnail {
      height: 100%;
      overflow: hidden;

      & img {
        width: 100%;
        height: 49%;
        object-fit: contain;
        position: absolute;
        left: -50%;
        transform: translate3d(50%, 0, 0);
      }
    }

    & .skin-name {
      align-self: end;
      font-size: 0.8rem;
      color: var(--color-white);
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & .skin-tickets {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      flex: 1;

      & i,
      & span {
        color: var(--color-ticket);
      }

      & span {
        margin-left: 0.3rem;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
  }
`;
