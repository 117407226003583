/* eslint-disable */
export default {
  Error: 'Feil',
  'Error placing a bet': 'Feil ved å legge til et bet',
  'Error reserving an item': 'Error reservere skins',
  'Link saved successfully': 'Linken er lagret',
  "You've successfully updated a bet": 'Du har vellykket oppdatert innsatsen',
  "You've successfully sell winning item": 'Du har solgt ditt skin',
  "You've successfully take winning item": 'Du har tatt ut ditt skinnet',
  'Incorrect link. Enter the correct link and try again.':
    'Ugyldig link. Skriv in korrekt lenke og prøv igjen',
  'This is not your link. Enter your link and try again.':
    'Dette er ikke din link. Skriv inn din lenke og prøv igjen.',
  "Don't forget to make your inventory public to get your winnings!":
    'Ikke glem å gjør lageret ditt offentlig for å motta gevinsten din',
  'min. number of items per trade not exceeded': 'min. Antall skins per trade ikke overskredet',
  'Too many requests, try again later': 'For mange forespørsler, prøv igjen senere',
  'cheating attempt': 'juksforsøk',
  'max. number of items per trade exceeded': 'max. antall skins per tradeoffer oversteg',
  'player not registered on website': 'Spilleren er ikke registrert på siden',
  'souvenir items are not allowed': 'Suvenir skins er ikke godtatt',
  'some of the items cannot be appraised': 'Noen skins kan ugyldig pris',
  'some of the items are below minimum price': 'Noen skins er under minste beløpet',
  'total price of a trade is below minimum': 'Total pris for en trade er under min.',
  'Your trade has been accepted!': 'Tradeofferet ditt har blit akseptert!',
  'The items have been added to the queue.': 'skinsene er lagt til i køen.',
  'Your trade has been checked out!': 'Tradeofferet har blitt sjekket ut!',
  'It is currently being accepted': 'Den holder på å bli akseptert',
  'Unable to send a trade': 'Kunne ikke sende tradeoffer',
  'Update your trade link in the settings of the site': 'Oppdater din tradelink i instillinger',
  'Enter correct trade link and make sure your inventory is not full.':
    'Skriv inn riktig tradelink og sjekk om lageret ikke er fult.',
  'Your account is not available to trade. More information will be shown if you invite someone to trade.':
    'Din bruker kan ikke bytte. Mer informasjon vil vises om du inviterer noen for et byttehandel',
  'Steam is unavailable at this moment. Please wait for retry.':
    'Steam er ikke tilgjengelig for øyeblikket. Vennligst vent før du forsøker på nytt.',
  'Not enough money on balance': 'Ikke nok mynter til å utføre handlingen',
  'Not enough points on balance': 'Ikke nok mynter til å utføre handlingen',
  'Game is ending, try again later': 'Runden avsluttes, prøv igjen senere',
  'Congratulations!': 'Gratulerer!',
  "You've successfully placed a bet": 'Du har plassert et bet',
  "You've successfully placed a bet with </amount>": 'Du inngikk et veddemål med {{</amount>}}',
  'Item has not been found, coins have been returned':
    'Skinnet ble ikke funnet. Myntene dine har blitt returnert',
  'Your order in the store has been cancelled': 'Din uttaksordre har blitt kansellert',
  'The min bet is </minBet>': 'Du må bette minst {{</minBet>}}',
  'The max bet is </maxBet>': 'Maks innsats er {{</maxBet>}}',
  'You cannot place bet on red and black at same time':
    'Du kan ikke legge inn mynter på rød og svart sametidig',
  "You don't have CSGOFAST.com in Steam nickname!":
    'Du har ikke CSGOFAST.com som kallenavn på steam!',
  'You have exceeded the limit of 150 free coins per account':
    'Du har overskredet grensen på 150 gratis mynter per konto',
  "You've bet </amount> on the crash game #</game>!":
    'Du har lagt inn en innsats på {{</amount>}} i crash game #{{</game>}}!',
  "You've updated coef to </coef> for crash game #</game>!":
    'Du har oppdatert din coef til {{</coef>}} for crash game #{{</game>}}!',
  "You've won </amount> in the crash game #</number>!":
    'Du har vunnet {{</amount>}} i crash game #{{</number>}}!',
  'Code for </amount> has been created': 'Kode for {{</amount>}} har blitt laget',
  'You already have an item': 'Du har allerede et skin',
  '</amount> refilled on your balance': '{{</amount>}} påfylt på kontoen din',
  'We will sent it to your Steam in few moments':
    'Vi vil sende det til din Steam om noen få sekunder',
  'Your items has been sended to game!': 'Dine gjenstander har blitt sendt til spill!',
  '</amount> have been returned': '{{</amount>}} har blitt returnert',
  'Item has not been found, retry in few minutes (</amount>)':
    'Skins er ikke funnet, prøv om få minutter ({{</amount>}})',
  'It is currently being processing by game bots': 'Det blir for øyeblikket behandliet av en bot',
  '</amount> have been refilled': '{{</amount>}} har blitt påfylt',
  'Request successfully created': 'Forespørsel ble opprettet',
  'Your order #</id> for </amount> was accepted, wait for trade in Steam.':
    'Din ordre med nummer #{{</id>}} på {{</amount>}} ble godkjent, vent på handelen i Steam.',
  'Reason of order rejection': 'Grunn til avvisning',
  'Your bet for </amount> was accepted and will added to game in </time> seconds':
    'Ditt veddemål på {{</amount>}} ble akseptert og vil bli lagt til i spillet om {{</time>}} sekunder',
  'Your bet was added to queue, in few seconds will added to the game':
    'Veddemålet ditt ble lagt til i køen og vil om få sekunder bli lagt til i spillet',
  ERROR: {
    WITHDRAW: {
      WITHDRAW_LOCKED_WIN_MORE:
        'Uttak er låst. Vinn ytterligere ${currency(amount)} for å låse opp',
    },
  },
};
