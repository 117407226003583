import { useSelector } from 'react-redux';
import { getRouletteData } from '../ducks';

export const useGetRouletteLineIds = () => {
  const { data, isLoading } = useSelector(getRouletteData);
  return {
    isLoading,
    rouletteLineIds: data.ids,
  };
};
