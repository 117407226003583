import * as React from 'react';
import styled from 'styled-components';
import { AccountAvatar } from '../../../../@components/Common';
import { CurrencyText } from '../../../../@components/currency';
import { textLimit } from '../../../../core/utils/text.utils';

const UsersBetItem = ({ user, crashed }) => {
  return (
    <UserItem>
      <AccountAvatar uri={user.playerImage} userId={user.playerId} />
      <UserName>{textLimit(user.playerName, 10)}</UserName>
      <UserBetInfo isWin={user.coef} crashed={crashed}>
        <CurrencyText value={user.amount} />
      </UserBetInfo>
      <UserBetInfo isWin={user.coef} crashed={crashed}>
        {user.coef ? `x${user.coef}` : '?'}
      </UserBetInfo>
      <UserBetInfo isWin={user.coef} crashed={crashed}>
        {user.coef && <CurrencyText value={user.amount * user.coef} />}
      </UserBetInfo>
    </UserItem>
  );
};

const UserItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;

  & button {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    pointer-events: auto;
  }
`;

const UserName = styled.span`
  font-size: 12px;
  color: var(--color-white);
  flex-basis: calc(42% - 35px);
  flex-grow: 1;
  word-break: break-all;
  white-space: pre-line;
  padding: 0 4px;
`;

const UserBetInfo = styled.span`
  font-size: 12px;
  color: ${({ isWin, crashed }) =>
    crashed ? (isWin ? 'var(--ochre)' : 'var(--red)') : 'var(--gray-100)'};
  flex-basis: 15%;
  flex-grow: 1;
  padding: 0 2px;
  text-align: center;
`;

export default UsersBetItem;
