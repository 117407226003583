import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Balance,
  BalanceContainer,
  BalanceList,
  Text,
  Container,
  ImageContainer,
  UserIcon,
  UserName,
  PriceContainer,
  PriceInfo,
  SkinCountContainer,
  SkinCount,
  AvatarImage,
  Position,
  UserInfo,
  SkinTotal,
  SkinResults,
} from './top-players-card.styles';
import { TopData, UserData } from 'modules/event-module/comics-event/duck/event-results-duck';
import { EventIcons, eventImagesPath } from 'modules/event-module/comics-event/assets';
import { loadProfile } from 'modules/Accounts/duck';
import { useDispatch } from 'react-redux';
import { currencyService } from 'services/currency';

interface TopPlayersCardProps extends TopData {
  position: number;
  user: UserData;
  tickets: {
    blueKeys: number;
    greenKeys: number;
    orangeKeys: number;
  };
  count: number;
  prizes: number;
  total: number;
}

export const TopPlayersCard: React.FC<TopPlayersCardProps> = ({
  position,
  user,
  tickets,
  count,
  prizes,
  total,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const openUserInfoHandler = () => {
    dispatch(loadProfile(user.id));
  };

  const imagesForPlaces: Record<number, JSX.Element> = {
    0: <EventIcons.FirstPlace />,
    1: <EventIcons.SecondPlace />,
    2: <EventIcons.ThirdPlace />,
  };

  const arrayBalance = [
    {
      icon: <img src={eventImagesPath.bronzeDailyPath} alt="" width={24} height={24} />,
      balance: tickets.greenKeys,
    },
    {
      icon: <img src={eventImagesPath.goldSpecialPath} alt="" width={24} height={24} />,
      balance: tickets.orangeKeys,
    },
    {
      icon: <img src={eventImagesPath.silverWeeklyPath} alt="" width={24} height={24} />,
      balance: tickets.blueKeys,
    },
  ];

  return (
    <Container>
      <ImageContainer onClick={openUserInfoHandler}>
        {imagesForPlaces[position]}
        <AvatarImage profileType={user.profileType}>
          <UserIcon src={user.avatar} />
        </AvatarImage>
      </ImageContainer>
      <UserInfo>
        <UserName>{user.name}</UserName>
        <Position>
          {position + 1} {t('place')}
        </Position>
      </UserInfo>
      <BalanceContainer>
        <Text>{t('get')}:</Text>
        <BalanceList>
          {arrayBalance.map(({ icon, balance }, index) => (
            <Balance key={index}>
              {icon}
              <span>{balance}</span>
            </Balance>
          ))}
        </BalanceList>
      </BalanceContainer>
      <PriceContainer>
        <Text>{t('giftFound')}:</Text>
        <PriceInfo>{prizes > 9 ? '9/9 + 1' : `${prizes}/9`}</PriceInfo>
      </PriceContainer>
      <SkinCountContainer>
        <Text>{t('skinsWin')}:</Text>
        <SkinResults>
          <SkinCount>{count}</SkinCount>
          <EventIcons.Next />
          <SkinTotal>
            ◎ <span>{currencyService.display(total)?.substring(3)}</span>
          </SkinTotal>
        </SkinResults>
      </SkinCountContainer>
    </Container>
  );
};
