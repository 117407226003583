import { Decimal } from 'decimal.js';

const HASH = new Decimal('76561197960265728');

export const getSteamId64 = (steamid_3: string): Decimal => HASH.plus(steamid_3);

export const addChatraEmail = (email: string | null = null) => {
  (window as any).Chatra('updateIntegrationData', {
    email: email,
  });
};
