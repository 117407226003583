import { useCallback, useState } from 'react';

export const useGatePay = () => {
  const [gate, setGate] = useState(0);

  const handleChangeGate = useCallback(
    (idx: number) => () => {
      setGate(idx);
    },
    [setGate]
  );

  return {
    gate,
    handleChangeGate,
  };
};
