import React from 'react';
import { Container } from './case-list.styles';
import { useGetCaseListData } from 'games/CaseGame/hooks';
import { CaseListItemContainer } from '@components/CaseListItemDefault';
import { EmptyList } from 'games/CaseGame/components/CaseList/components';
import { CaseListItem } from '../case-list-item';

const empty = new Array(12).fill(0).map((_, index) => index);

export const CaseList = () => {
  const { casesData } = useGetCaseListData();

  if (casesData.isLoading) {
    return (
      <Container>
        {empty.map((value: number) => (
          <CaseListItemContainer className="skeleton" key={value} />
        ))}
      </Container>
    );
  }
  return casesData.data.caseList.length === 0 ? (
    <EmptyList />
  ) : (
    <Container className="turn-off-default-scroll">
      {casesData.data.caseList.map(data => (
        <CaseListItem key={data.id} caseData={data} />
      ))}
    </Container>
  );
};
