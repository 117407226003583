import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStoreSelectedFilters } from '../../selector';
import { IStore } from 'store/interface';
import { resetFilters } from '../../ducks/filters.duck';

export const useStoreSelectedFilters = () => {
  const selected = useSelector<IStore, number>(getStoreSelectedFilters, shallowEqual);
  const dispatch = useDispatch();

  const resetSelected = useCallback(() => {
    dispatch(
      resetFilters({
        overprice: {
          minOverprice: null,
          maxOverprice: null,
        },
        type: [],
        weapon: [],
        exterior: [],
        phase: [],
        minPrice: null,
        maxPrice: null,
      })
    );
  }, [dispatch]);

  const resetPage = useCallback(() => {
    dispatch(resetFilters({ page: { number: 1 } }));
  }, [dispatch]);

  return {
    selected,
    resetSelected,
    resetPage,
  };
};
