import styled from 'styled-components';

export const Container = styled.div`
  & > div {
    width: 100%;
    height: 100%;
    position: relative;

    & .row {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      position: absolute;
      top: 0;
      left: 1rem;
      right: 1rem;

      @media only screen and (min-width: 500px) {
        margin: 0 3rem;
      }
    }
  }
`;
