import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  background: var(--color-dark-200);
  border-radius: 8px;
  height: 140px;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
