export default {
  GAME: '游戏',
  Winner: '獲勝者',
  Won: '獎金總額',
  Chance: '獲勝機率',
  Prize: '奖品',
  'At stake': '賭注',
  'Sign in through STEAM to participate': '通过 STEAM 登录来参与',
  'Steam trade link': 'Steam 交易連結',
  'Your chance': 'Your chance',
  'Be first to deposit': '成為第一個下注的人',
  'Place a bet': '下注',
  'Deposit more': '押注更多',
  'Min bet': '最小賭注',
  'Max bet': '最大赌注',
  'Bet amount': '賭注金額',
  'max. items': '最大道具數',
  hidden: '隱藏',
  Copy: '复制',
  'Winning after commission': '在委员会过后胜利',
  Loading: '正在載入',
};
