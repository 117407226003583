import React from 'react';
import {
  Container,
  UserAvatar,
  HeaderContainer,
  LevelSpan,
  UserInfoContainer,
  NickName,
  StatusRank,
  ContentContainer,
  Title,
  CaseImg,
  Price,
  ImgContainer,
  PriceContainer,
  StyledButton,
  CloseButton,
} from './case-motivator.styles';
import { IDialogComponent } from 'core/interfaces';
import { ProfileEnum } from 'games/CaseGame/constants/enums';
import { CurrencyText } from '@components/currency';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadProfile } from 'modules/Accounts/duck';

interface CaseMotivatorDialog
  extends IDialogComponent<{
    user: {
      id: number;
      avatar: string;
      name: string;
      profileType: ProfileEnum;
      level: number;
    };
    caseImg: string;
    caseId: number;
    casePrice: number;
    uuid: string;
    isBattle?: boolean;
    id: number;
  }> {}

export const CaseMotivatorDialog: React.FC<CaseMotivatorDialog> = ({
  handleCloseModal,
  data: { user, caseImg, caseId, casePrice, uuid, isBattle, id },
}) => {
  const { t } = useTranslation('cases2.0');
  const dispatch = useDispatch();
  const history = useHistory();

  const clickHandler = () => {
    handleCloseModal();

    if (isBattle) {
      history.push({
        pathname: `../case-battle/${id}`,
        state: { isReplay: true },
      });
    } else {
      history.push(`../cases/${caseId}?uuid=${uuid}`);
    }
  };

  const closeModalHandler = () => {
    handleCloseModal();
  };

  const openUserInfoHandler = () => {
    handleCloseModal();
    dispatch(loadProfile(user.id));
  };
  const redirectToCaseHandler = () => {
    handleCloseModal();
    history.push(`../cases/${caseId}`);
  };
  return (
    <Container>
      <HeaderContainer>
        <UserInfoContainer onClick={openUserInfoHandler}>
          <UserAvatar src={user.avatar} />
          <NickName>{user.name}</NickName>
          <StatusRank data-color={user.profileType}>{user.profileType}</StatusRank>
        </UserInfoContainer>
        <div>
          <LevelSpan>{user.level} Lvl</LevelSpan>
        </div>
      </HeaderContainer>
      <ContentContainer>
        <Title>{t('getItemFromCase')}</Title>
        <ImgContainer onClick={redirectToCaseHandler}>
          <CaseImg src={caseImg} />
          <PriceContainer>
            <Price>
              <CurrencyText value={casePrice}></CurrencyText>
            </Price>
          </PriceContainer>
        </ImgContainer>
        <StyledButton onClick={clickHandler}>{t('watchReplay')}</StyledButton>
        <CloseButton onClick={closeModalHandler}>{t('Close')}</CloseButton>
      </ContentContainer>
    </Container>
  );
};
