import { CaseList } from 'games/CaseGame/interfaces';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import { CaseBattleCases } from './cases.interfaces';
import { ACTION_TYPE } from 'games/CaseGame/ducks/actionType';

export interface CasesForCasesBattleState {
  data: CaseList;
  isLoading: boolean;
  isLoaded: boolean;
}

const InitState: CasesForCasesBattleState = {
  data: [],
  isLoading: true,
  isLoaded: false,
};

export const casesForCasesBattle = (
  state: CasesForCasesBattleState = InitState,
  action: CaseBattleCases
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.CASE_BATTLE_CASE_LIST_ACTION_RESPONSE: {
      const enableCases = action.payload.filter(({ enabled }) => enabled);
      return {
        isLoading: false,
        data: enableCases,
        isLoaded: true,
      };
    }
    case ACTION_TYPE.CASE_LIST_ACTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ACTION_TYPE.CASE_LIST_ACTION_RESPONSE_STATIC_CATEGORY: {
      //NOTE: this action from cases game
      return {
        ...state,
        isLoading: false,
      };
    }
    case ACTION_TYPE.CASE_LIST_ACTION_REMOVE_CASES_DATA: {
      return InitState;
    }
    default: {
      return state;
    }
  }
};
