import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
`;

export const ProgressFull = styled.div`
  background: var(--color-dark-600);
  border-radius: var(--size-radius-m);
  height: 50px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const ProgressReady = styled.div<{ percent: number }>`
  background: var(--color-gray-900);
  display: block;
  height: 100%;
  position: absolute;
  width: 0%;
  animation-name: width-percent;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @keyframes width-percent {
    from {
      width: 0;
    }

    to {
      width: ${({ percent }) => percent}%;
    }
  }
`;

export const XP = styled.span`
  color: var(--color-gray-200);
  font-size: 0.81rem;
  font-weight: 700;
  left: 50%;
  letter-spacing: 0.286px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;
