import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppBar } from '@components/bars';
import { TabBar, Header, EventResults } from '../components';
import { actionRequestLRInfo, actionRequestQuestInfo } from '../duck';
import { useGetGeneralQuestInfo, useGetLRBalance } from '../hooks';
import { Container } from './event.page.styles';

import {
  actionRequestLeaderRaceBalance,
  actionRequestLeaderRaceBalanceLoading,
} from '../duck/leader-race-duck';
import { useUser } from 'core/User';
import { Loader } from '@components/Loader';

export const EventPage = () => {
  const { user } = useUser();
  const { isLoading, isLoaded, endAt, progressEndAt } = useGetGeneralQuestInfo();
  const { isLoading: isLoadingBalance } = useGetLRBalance();
  const dispatch = useDispatch();
  const [isProgressPage, setIsProgressPage] = useState(true);
  const [isResultsPage, setIsResultsPage] = useState(true);
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = targetRef.current;
    if (element) {
      return () => {
        localStorage.setItem('scrollpos', element.scrollTop.toString());
      };
    }
  }, []);

  useEffect(() => {
    const element = targetRef.current;
    if (element) {
      const scrollPos = +localStorage.getItem('scrollpos');
      if (!isNaN(scrollPos)) {
        element.scrollTop = scrollPos;
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [targetRef.current]);

  useEffect(() => {
    dispatch(actionRequestQuestInfo());
    dispatch(actionRequestLRInfo());
    if (user.id) {
      dispatch(actionRequestLeaderRaceBalance());
    } else {
      dispatch(actionRequestLeaderRaceBalanceLoading(false));
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    let timerProgress: null | number = null;
    let timerEnd: null | number = null;

    if (progressEndAt && isProgressPage) {
      const progressEndDate = new Date(progressEndAt).getTime();

      if (Date.now() > progressEndDate) {
        setIsProgressPage(false);
      } else {
        timerProgress = setInterval(() => {
          if (Date.now() > progressEndDate) {
            setIsProgressPage(false);
            clearInterval(timerProgress);
          }
        }, 1000);
      }
    }

    if (endAt && isResultsPage) {
      const endDate = new Date(endAt).getTime();

      if (Date.now() > endDate) {
        setIsResultsPage(false);
      } else {
        timerEnd = setInterval(() => {
          if (Date.now() > endDate) {
            setIsResultsPage(false);
            clearInterval(timerEnd);
          }
        }, 1000);
      }
    }

    return () => {
      if (timerProgress) clearInterval(timerProgress);
      if (timerEnd) clearInterval(timerEnd);
    };
  }, [endAt, isResultsPage, isProgressPage, progressEndAt]);

  if (isLoadingBalance || (isLoading && !isLoaded)) {
    return <Loader />;
  }

  return (
    <Container className="content" ref={targetRef}>
      <AppBar className="gray-dark" />
      {isProgressPage ? (
        <>
          <Header />
          <TabBar />
        </>
      ) : (
        isResultsPage && <EventResults />
      )}
    </Container>
  );
};
