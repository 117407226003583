import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BasketImg } from '../../assets/Notices/basket.svg';
import { Item } from './Item';
import { Container, Control, Delete, DeleteAll, NoItems } from './Notification.styles';
import { removeAllNotifications, removeNotification } from 'modules/Notifications/duck';
import { getAppNotifications, getStatusDeleteNotices } from 'modules/Notifications/selectors';

export interface INotification {
  addDate: number;
  body: string;
  header?: string;
  params?: any;
  seen: boolean;
  type: string;
  userId?: number;
}

export const Notification = () => {
  const statusDeleteNotices = useSelector(getStatusDeleteNotices, shallowEqual);
  const notifications = useSelector(getAppNotifications, shallowEqual) as INotification[];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [select, setSelect] = useState<number[]>([]);

  useEffect(() => {
    if (!statusDeleteNotices) {
      setSelect([]);
    }
  }, [statusDeleteNotices]);

  const handleSelect = (addDate: number) => {
    if (statusDeleteNotices) {
      if (select.includes(addDate)) {
        setSelect(select.filter(select => select !== addDate));
      } else {
        setSelect(prevState => [...prevState, addDate]);
      }
    }
  };

  const removeNotices = () => {
    select.forEach(item => {
      dispatch(removeNotification(item));
    });
    setSelect([]);
  };

  const removeAll = () => {
    dispatch(removeAllNotifications());
  };

  return notifications.length ? (
    <>
      <Container className="content">
        {notifications.map(item => (
          <Item
            key={item.addDate}
            isSelect={select.includes(item.addDate)}
            item={item}
            handleSelect={handleSelect}
            statusDeleteNotices={statusDeleteNotices}
          />
        ))}
      </Container>
      {statusDeleteNotices && (
        <Control isCenter={!select.length}>
          {select.length !== 0 && (
            <Delete onClick={removeNotices}>
              <span>
                {t('Select')} {select.length}
              </span>
              <BasketImg />
            </Delete>
          )}
          <DeleteAll onClick={removeAll}>{t('Delete all')}</DeleteAll>
        </Control>
      )}
    </>
  ) : (
    <NoItems>{t('No notifications')}</NoItems>
  );
};
