import React, { FC, memo } from 'react';
import { AppBar } from '@components/bars';
import { Lottery } from './lottery';

export const LotteryPage: FC = memo(() => (
  <>
    <AppBar deep />
    <Lottery />
  </>
));
