import * as React from 'react';
import { SwitchGame, UserTrades, VerifiedGame } from '../components/Game';
import { shallowEqual, useSelector } from 'react-redux';
import { getFastGameById } from '../selectors';
import { getUser } from 'core/User/selectors';
import { getCrossGameId } from 'core/CrossServices/selectors';

import { IUserState } from 'core/User/interfaces/user.reducer.interface';
import { IFastGame } from '../interfaces/reducer.fast.interface';

interface IGame {
  gameId: number;
  toggleId: number | null;
  setGameId: () => void;
}

export interface ISwitchGameRenderProp {
  completed: boolean;
  children: () => JSX.Element;
}

export const Game: React.FC<IGame> = props => {
  const gameData: IFastGame = useSelector(state => getFastGameById(state, props.gameId));
  const user: IUserState = useSelector(getUser, shallowEqual);
  const gameId = useSelector(getCrossGameId, shallowEqual) as number;

  return (
    <SwitchGame gameData={gameData}>
      {(renderProp: ISwitchGameRenderProp) => (
        <VerifiedGame
          md5={gameData.md5}
          number={gameData.number}
          rand={gameData.rand}
          setGameId={props.setGameId}
          userid={user.id}
          trades={gameData.trades}
          gameId={gameId}
          completed={renderProp.completed}
          winner={gameData.winner}
          toggleId={props.toggleId}
        >
          {renderProp.children()}
          <UserTrades trades={gameData.trades} gameId={gameId} />
        </VerifiedGame>
      )}
    </SwitchGame>
  );
};
