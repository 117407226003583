import React from 'react';
import { AppBar } from '../../../../@components/bars';
import { Tops } from '../../components/Top';

export const TopsPage = () => (
  <>
    <AppBar deep />
    <Tops />
  </>
);
