export enum CaseItemsDialogConfig {
  LAST_REVISION = 'LAST_REVISION',
}
export enum FilterDialog {
  SORT_DIALOG = 'SORT_DIALOG',
  PRICE_RANGE = 'PRICE_RANGE',
  SEARCH = 'SEARCH',
}

export enum CaseItemDialog {
  COUNT_DIALOG = 'COUNT_DIALOG',
}

export enum SoundsSettingsDialogConfig {
  SOUNDS_DIALOG = 'SOUNDS_DIALOG',
}

export enum CaseMotivatorDialogConfig {
  MOTIVATOR_DIALOG = 'MOTIVATOR_DIALOG',
}
export enum CaseHistoryDialogConfig {
  DIALOG_HISTORY = 'DIALOG_HISTORY',
}
