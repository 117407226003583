import { createSelector } from 'reselect';

export const getToken = (state: any) => state.poggi.token;

const historiesGetter = (state: any) => state.poggi.history;

export const getHistories = createSelector(
  historiesGetter,
  (_: any, filter: string) => filter,
  (history: any, filter: string) => history[filter].toJS()
);
