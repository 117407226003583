import { useDispatch } from 'react-redux';
import { fetchCreateBid } from '../../ducks/purchases.duck';

export const useCreatePurchase = () => {
  const dispatch = useDispatch();

  const createPurchase = (id: number) => {
    dispatch(fetchCreateBid(id));
  };

  return {
    createPurchase,
  };
};
