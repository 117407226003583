import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchPurchaseOrder } from '../../ducks/order.duck';

export const useOrderPurchase = () => {
  const dispatch = useDispatch();

  const createPurchase = useCallback(() => {
    dispatch(fetchPurchaseOrder());
  }, [dispatch]);

  return {
    createPurchase,
  };
};
