import { withdrawal } from './duck';
import './locales';
import { DIALOG, transitions } from 'dialogs';
import { WithdrawalDialogs } from './withdrawal-dialogs.config';
import {
  WithdrawalRouter,
  WithdrawVerificationDialog,
  UserVerificationDialog,
  PayoutFeeDialog,
  RateDialog,
} from './components';

export { withdrawalEpics } from './duck';
export type { IWithdrawalRecord } from './interfaces/reducers.withdrawal.interface';
export { WithdrawalRouter };

DIALOG.add(WithdrawalDialogs.WITHDRAW_VERIFICATION, {
  component: WithdrawVerificationDialog,
  transition: transitions.FULL_SCREEN,
  anchor: 'payout-portal',
});

DIALOG.add(WithdrawalDialogs.USER_VERIFICATION, {
  component: UserVerificationDialog,
  transition: transitions.SCALABLE,
  anchor: 'verification-portal',
});

DIALOG.add(WithdrawalDialogs.FEE_SELECTORS, {
  component: PayoutFeeDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'payout-portal',
});
DIALOG.add(WithdrawalDialogs.RATE_DIALOG, {
  component: RateDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'payout-portal',
});

// export const WithdrawalRouter = lazy(() =>
//   import('./components').then(
//     ({ WithdrawalRouter, WithdrawVerificationDialog, UserVerificationDialog, PayoutFeeDialog }) => {
//       DIALOG.add(WithdrawalDialogs.WITHDRAW_VERIFICATION, {
//         component: WithdrawVerificationDialog,
//         transition: transitions.FULL_SCREEN,
//         anchor: 'payout-portal',
//       })
//
//       DIALOG.add(WithdrawalDialogs.USER_VERIFICATION, {
//         component: UserVerificationDialog,
//         transition: transitions.SCALABLE,
//         anchor: 'verification-portal',
//       })
//
//       DIALOG.add(WithdrawalDialogs.FEE_SELECTORS, {
//         component: PayoutFeeDialog,
//         transition: transitions.SLIDE_UP,
//         anchor: 'payout-portal',
//       })
//       return { default: WithdrawalRouter }
//     }
//   )
// )
export const withdrawalModule = {
  moduleName: 'withdrawal',
  path: '/withdrawal/method',
  reducer: withdrawal,
};
