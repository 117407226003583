import React, { FC } from 'react';
import { DefaultEvent } from '../../default-event-component';
import { LeaderRace } from '../../../leader-race';
import { TabDecorator } from '../tabDecorator';
import { useResizeWindow } from 'modules/event-module/comics-event/hooks';

export interface TabProps {
  activeTab: number;
}

export const Tab: FC<TabProps> = ({ activeTab }) => {
  const { containerRef } = useResizeWindow();

  return (
    <div ref={containerRef}>
      <TabDecorator activeTab={activeTab} currentTab={0}>
        <DefaultEvent />
      </TabDecorator>
      <TabDecorator activeTab={activeTab} currentTab={1}>
        <LeaderRace />
      </TabDecorator>
    </div>
  );
};
