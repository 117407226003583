import * as React from 'react';
// import styled from 'styled-components';
import { getUserAvatarUrl } from 'core/utils/image.utils';
// import { useExperiences } from 'core/AppShell/hooks';
import { UserProfilePanel } from '@components/UserProfile';
import { Timer } from '@components/TimerEvent';
import { useTranslation } from 'react-i18next';
// import { abbreviateNumber } from 'core/utils/text.utils';
import { useUser } from 'core/User/hooks';
// import { IExperiences } from 'core/AppShell';

interface IUserProfile {
  handlePushTo: (path: string) => () => void;
}

export const UserProfile: React.FC<IUserProfile> = ({ handlePushTo }) => {
  // const { experiences } = useExperiences();
  const {
    user: { name, avatar, profileType, premium },
  } = useUser();
  const { t } = useTranslation();

  // const experienceLevel = experiences.get(Math.max(experience.level - 1, 0)) as IExperiences;

  // const levelProgress =
  //   ((experience?.xp - experienceLevel?.xp) / (experience?.nextLevelXp - experienceLevel?.xp)) *
  //   100;

  // if (experience.xp > experience.nextLevelXp) {
  //   return (
  //     <UserProfilePanel profileType={profileType} exp={levelProgress}>
  //       <ContainerLevelUp>
  //         <div className="user-avatar" onClick={handlePushTo('/personal')}>
  //           <img src={getUserAvatarUrl(avatar, 50)} alt="user-avatar" />
  //         </div>
  //         <div className="level-up" onClick={handlePushTo('/personal/levels')}>
  //           <span>{t('Level up')}</span>
  //           <span className="current-level">
  //             <i className="icon-arrow-bold" />
  //             {experience.level + 1}
  //           </span>
  //         </div>
  //       </ContainerLevelUp>
  //     </UserProfilePanel>
  //   );
  // }
  //exp = levelProgress
  return (
    <UserProfilePanel
      onClick={handlePushTo('/personal')}
      profileType={profileType}
      exp={0}
      as="button"
    >
      <div className="user-avatar">
        <img src={getUserAvatarUrl(avatar, 50)} alt="user-avatar" />
      </div>
      <div className="user-info navbar">
        <span className="user-name">{name} </span>
        {/* <span className="user-lvl">{experience.level} lvl</span> */}
        <span className="user-account-type">
          {t(profileType)} {premium.until && <Timer finishEvent={premium.until} />}
        </span>
        {/* {experiences.get(experience.level - 1) && (
          <span className="user-exp">
            {abbreviateNumber(experience.xp - experienceLevel.xp)}/
            {abbreviateNumber(experience.nextLevelXp - experienceLevel.xp)}
          </span>
        )} */}
      </div>
    </UserProfilePanel>
  );
};

// const ContainerLevelUp = styled.div`
//   display: flex;
//   flex-flow: row nowrap;
//   padding-left: 0.8rem;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: var(--yellow);
//   z-index: 10;

//   & > .level-up {
//     display: grid;
//     grid-template-columns: 1fr 90px;
//     grid-template-rows: 1fr;
//     flex-basis: 100%;

//     & > span {
//       font-size: 0.65rem;
//       font-weight: bold;
//       color: var(--color-dark-600);
//       align-self: center;
//       text-transform: uppercase;
//     }

//     .current-level {
//       line-height: 62px;
//       text-align: right;
//       letter-spacing: -8px;
//       opacity: 0.07;
//       font-size: 85px;
//       font-weight: bold;
//       color: var(--mBlack);
//       position: relative;

//       i {
//         line-height: 62px;
//         font-size: 40px;
//         position: absolute;
//         left: -20px;
//         transform: rotate(180deg);
//         transform-origin: center;
//       }
//     }
//   }
// `;
