import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 0 0;
  min-height: 110px;
  overflow: hidden;
  background: var(--color-dark-layout);
`;

export const HistoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 70px;
  grid-gap: 1px;
  grid-auto-rows: 1fr;
  grid-auto-flow: column;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > a:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`;

export const HistoryTitle = styled.div`
  padding: 0 1.1rem;
  font-size: 0.75rem;
  color: var(--color-white);
  text-transform: uppercase;
`;

export const HistoryItem = styled.button`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.8rem;
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
  }

  & > .ripple-effect {
    width: 40px !important;
    height: 40px !important;
  }
`;
