import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IPayoutAgree {
  agree: boolean;
  handleChangeAgree: () => void;
}

export const PayoutAgree: FC<IPayoutAgree> = ({ agree, handleChangeAgree }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <input
        type="checkbox"
        className="checkboxBlue"
        id="checkbox10"
        checked={agree}
        onChange={handleChangeAgree}
      />
      <label htmlFor="checkbox10">
        <span>
          {t('I confirm the accuracy of the details and agree with the amount of payment')}
        </span>
      </label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  & label {
    font-size: 0.75rem;
    line-height: 1.43;
    letter-spacing: 0.4px;
    color: var(--color-white);
    margin: 0 5px 0 5px;

    & span {
      padding-left: 10px;
      display: block;
      font-size: 0.625rem;

      @media screen and (min-width: 375px) {
        font-size: 0.75rem;
      }
    }
  }
`;
