import immutable from 'immutable';

export type OrderStatuses = 'success' | 'failure' | 'pending';

export const WithdrawalRecord = immutable.Record<IWithdrawal>({
  methods: {},
  amount: {
    input: 0,
    output: 0,
    receive: '',
    currency: '',
    percent: '',
    receiveWithoutFee: '',
    rate: 0,
    payback: 0,
    needPlayCoinsAmount: 0,
  },
  currency: [],
  url: '',
  fee: {},
  order: {
    paymentSystem: 0,
    paymentWallet: null,
    // recaptchaToken: null,
    feeIndex: 0,
  },
  rate: {
    data: '',
    isLoading: false,
  },
  order_status: {
    condition: null,
    messages: null,
    params: null,
  },
});

export type IWithdrawal = {
  methods: Record<string, IWithdrawalMethod>;
  amount: {
    input: number;
    output: number;
    receive: string;
    currency: string;
    percent: string;
    receiveWithoutFee: string;
    rate: number;
    payback: number;
    needPlayCoinsAmount: number;
  };
  currency: IWithdrawalCurrency[];
  url: string;
  fee: Record<string, IFeeItem>;
  order: {
    paymentSystem: number;
    paymentWallet: string;
    // recaptchaToken: string
    feeIndex: number;
  };
  rate: {
    data: string;
    isLoading: boolean;
  };
  order_status: {
    condition: OrderStatuses;
    messages: string;
    params: { [key: string]: string | number };
  };
};

export interface IFeeItem {
  comission: string;
  comissionInUSD: number;
  crate: number;
  currency: string;
  receive: number;
  receiveInUSD: number;
  waitingTime: string;
  percent: string;
  payment: number;
}

export type IOutputAmount = {
  coinsAmount2: number;
  receive: string;
  percent: string;
  currency: string;
  reciveWithoutFee: string;
  coinsAmount: number;
  crate: number;
  decimal: number;
  rate: number;
  payback: number;
  needPlayCoinsAmount: number;
};
export type Rate = {
  rate: string;
};
export type ICreateOrderSuccess = {
  id: number;
  coinsAmount: number;
};

export type IWithdrawalMethod = {
  title: string;
  name: string;
  gates: IWithdrawalGate[];
};

export interface IWithdrawalGate {
  commission: string;
  gate: string;
  icon: null;
  minimum: number;
  name: string;
  regularExp: RegExp;
  title: string;
  type: string;
  feeSelector?: string[];
}

export interface IWithdrawalCurrency {
  id: number;
  title: string;
  convert_rate: number;
  updated_at: string;
  decimal: number;
  is_new: boolean;
}

export type IWithdrawalRecord = immutable.Record<IWithdrawal>;
