import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { Trading } from 'modules/PersonalArea/components/Settings/Trading';

export const TradingPage: FC = () => (
  <>
    <AppBar deep />
    <Trading />
  </>
);
