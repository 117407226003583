import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--color-dark-600);
  padding: 40px 18px;
  text-align: center;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  text-align: left;
`;

export const SubTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 18px;
  padding: 9px 0 20px;
  text-align: left;
`;

export const MyButton = styled(Button)`
  margin-top: 30px;
`;
