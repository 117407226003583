import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  padding: 40px 18px;
  text-align: center;

  & .close {
    font-size: 0.75rem;
    font-weight: normal;
    position: absolute;
    right: 0.94rem;
    text-transform: none;
    top: 0.63rem;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 20px;
  padding-bottom: 13px;
  text-align: left;
`;

export const SubTitle = styled.span`
  color: var(--color-white);
  display: block;
  font-size: 14px;
  letter-spacing: 0.571px;
  line-height: 18px;
  padding-bottom: 16px;
  text-align: left;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const Link = styled.a`
  color: var(--color-gray-100);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 14px;
  margin-bottom: 24px;
  text-align: left;
`;

export const Input = styled.input`
  background: var(--color-white);
  border-radius: 8px;
  color: var(--mBlack);
  font-size: 16px;
  overflow: hidden;
  padding: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
