import React from 'react';
import { Container, ItemImg } from './RouletteItem.styles';
import { colorDictionaryForCaseItem, skinItemImage } from 'core/utils';

interface RouletteItemProps {
  icon: string;
  color: string;
  isDroppedItem: boolean;
  isJoker: boolean;
}

export const RouletteItem: React.FC<RouletteItemProps> = ({
  icon,
  color,
  isDroppedItem = false,
  isJoker,
}) => {
  return (
    <Container
      color={colorDictionaryForCaseItem[color.toUpperCase()]}
      isDroppedItem={isDroppedItem}
      isJoker={isJoker}
    >
      <ItemImg src={skinItemImage(icon)} alt="" />
    </Container>
  );
};
