import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
  overflow: scroll;
`;

export const Header = styled.div`
  padding: 11px 26px 11px 20px;
  display: flex;
  background: var(--color-dark-100);
`;
export const HeaderTitle = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-white-gray);
  flex: 1;
  justify-content: center;

  &:first-child {
    flex: 3;
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;
export const RevisionItemContainer = styled.div<{ even: boolean }>`
  padding: 11px 26px 11px 20px;
  display: flex;
  background: ${p => (p.even ? 'var(--color-dark-100);' : 'transparent')};
`;
export const SkinPriceImageWrapper = styled.div`
  display: flex;
  gap: 9px;
  flex: 3;
  align-items: center;
`;
export const Image = styled.img`
  width: 53px;
  height: 40.381px;
  margin-right: 5px;
`;
export const Line = styled.div`
  width: 2px;
  height: 50px;
  border-radius: 4px;
  background: var(--orange-100);
`;
export const SkinPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const Name = styled.span`
  color: var(--orange-200);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.24px;
`;
export const Skin = styled.span`
  color: var(--color-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.24px;
`;
export const Currency = styled.div`
  color: var(--yellow);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
`;
export const Range = styled.div`
  color: var(--color-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;
export const Chance = styled(Range)`
  justify-content: flex-end;
`;
