export default {
  GAME: '游戏',
  Chat: '聊天聊天',
  'Email Support': '電子郵件支援',
  'Live Support Chat': '即時支援聊天',
  History: '遊戲歷史',
  Top: '顶部',
  Rules: 'Rules',
  'At stake': '賭注',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': '若要留言，您需要進行登入：',
  'Only players who have played the game are allowed to write': '只有遊戲玩家方可留言',
  'You are banned': '您已被禁止您已被禁止',
  Reason: '原因',
  'Time left': '剩餘時間',
  'Time to summarizing': '总结时间',
  spam: '垃圾郵件',
  'advertising (auto)': '行銷（自動）',
  advertising: '行銷',
  rudeness: '無禮',
  begging: '乞討',
  Search: 'Search',
  Sound: 'Sound',
  Today: '今天',
  Yesterday: '昨天',
  'Last year': '去年',
  'Show my wins': '顯示我的獲勝次數',
  'History is empty': '历史是空的',
  Cancel: '取消',
  Delete: '删除',
  Close: '關閉',
  Confirm: '确认',
  'Are you sure to delete all notifications?': '您确定要删除所有通知吗?',
  'Log in': '登录',
  'Log in to try your luck': '登入來試試運氣',
  'try your luck!': '試試運氣!',
  'Take your prize': '領取獎品',
  'Sell for': '卖',
  BACK: 'BACK',
  'Warning!': '警告！',
  'Congratulations!': '恭喜！',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    '恭喜！獎品的交易報價已發送至您的帳號。',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': '交易將在一小時後取消',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    '处理完你的订单后，你可以在Steam交易的提交页面上收集你的奖金',

  'Round number': '回合數字',
  'Round hash': '回合雜湊',
  'Refill amount': '充值数额',
  Bonus: '奖金',
  'Bonus experience': '奖励经验',
  'Bonus wheel spins': '奖励免费旋转',
  'Bonus cases': '奖励盒子',
};
