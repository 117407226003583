import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupQuests } from '../selectors';
import { fetchTakeReward } from '../ducks/quests.duck';

export const useQuestFacade = () => {
  const questTypes = useSelector(getGroupQuests);
  const dispatch = useDispatch();

  const takeRewarHandle = useCallback(
    (id: number) => () => {
      dispatch(fetchTakeReward(id));
    },
    [dispatch]
  );

  return {
    questTypes,
    takeRewarHandle,
  };
};
