import Socket from 'socket.io-client';
import { getDomains } from 'core/utils/get-domains.utils';
import { IListener } from './interfaces/services.interface';

class SocketService {
  private readonly currentSocketDomain: string;
  private static instance: SocketService;
  private instance?: this;
  private listeners: IListener[];
  private socket!: SocketIOClient.Socket;

  constructor() {
    this.listeners = [];
    this.currentSocketDomain = getDomains();

    if (SocketService.instance) return SocketService.instance;

    this.instance = this;
    this.connect();
  }

  get domain() {
    return this.currentSocketDomain;
  }

  get io() {
    return this.socket;
  }

  public connect() {
    if (this.socket !== undefined) {
      this.socket.close();
    }

    this.socket = Socket(`${process.env.PREFIX_GATEWAY_URL}${this.currentSocketDomain}`, {
      reconnection: true,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: Infinity,
      timeout: 10000,
      forceNew: true,
    });

    setTimeout(() => {
      this.listeners.forEach(listener => this.socket.on(listener.event, listener.action));
      this.socket.connect();
    }, 0);
  }
  //NOTE: added new method fro native disconnect
  public disconnect() {
    this.socket.disconnect();
  }
  public emit(event: string, args?: any): void {
    this.socket.emit(event, args);
  }

  public on(event: string, fn: () => void): void {
    this.socket.on(event, fn);
  }

  public removeListener(event: string): void {
    this.socket.removeListener(event);
  }

  public addListener(listener: IListener): void {
    this.listeners.push(listener);
  }

  public subscribe(listener: IListener): void {
    this.socket.on(listener.event, listener.action);
  }

  public unsubscribe(event: string): void {
    this.socket.off(event);
  }
  public subscribeV2(listener: IListener): void {
    this.socket.on(listener.event, (e: any) => listener.action(e));
  }
}

export const socketService = new SocketService();
