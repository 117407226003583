import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  height: 24.625rem;
  position: relative;

  & .close-dialog {
    font-weight: normal;
    position: absolute;
    right: 0.5rem;
    text-transform: none;
    top: 0.5rem;
  }

  & .header-dialog {
    color: var(--color-white);
    font-size: 1.25rem;
    justify-self: self-start;
    line-height: 1.5rem;
    padding: 1.75rem 1rem 0;
  }

  & .controllers {
    padding: 0 1rem 1.75rem;
  }
`;
