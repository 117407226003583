import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGameSoundsOptions, useSoundSettings } from 'core/AppShell';
import {
  CheckBox,
  CheckboxContainer,
  SoundsWrap,
  SoundItem,
  Title,
  Wrapper,
} from './Sounds.styles';
import { CheckBoxTitle } from './Sounds.styles';
import { SvgIcons } from './assets';
import { userModals } from 'core/User/modal.config';
import { useDialog } from 'core/hooks';
import { SoundOption } from './SoundOption';

export const Sounds: FC = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');
  const { soundSettings, handleToggleSound } = useSoundSettings();

  const { handleToggleDialog, handleCloseByName } = useDialog(userModals.SOUNDS_SETTINGS);

  useEffect(() => {
    return () => handleCloseByName(userModals.SOUNDS_SETTINGS);
  }, [handleCloseByName]);

  return (
    <Wrapper className="content">
      <Title>{t('Global sound settings')}</Title>
      <CheckboxContainer>
        <CheckBox
          type="checkbox"
          id="generalSounds"
          checked={soundSettings.commonSounds.enabled}
          onChange={handleToggleSound({ moduleName: 'commonSounds' })}
        />
        <label htmlFor="generalSounds" />
        <CheckBoxTitle>{t('General sounds')}</CheckBoxTitle>
      </CheckboxContainer>
      <SoundsWrap>
        {Object.entries(soundSettings.commonSounds.options).map(([option, value]) => (
          <SoundOption
            key={option}
            option={option}
            value={value}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </SoundsWrap>
      <CheckboxContainer>
        <CheckBox
          type="checkbox"
          id="gameSounds"
          checked={soundSettings.gameSounds.enabled}
          onChange={handleToggleSound({ moduleName: 'gameSounds' })}
        />
        <label htmlFor="gameSounds" />
        <CheckBoxTitle>{t('Game sounds')}</CheckBoxTitle>
      </CheckboxContainer>
      <SoundsWrap>
        {Object.entries(soundSettings.gameSounds.options).map(([game, value]) => (
          <SoundItem key={game}>
            <CheckBox
              id={game}
              checked={value.enabled}
              onChange={handleToggleSound({
                moduleName: 'gameSounds',
                game: game as keyof IGameSoundsOptions,
              })}
              name={game}
              type="checkbox"
            />
            <label htmlFor={game} />
            <CheckBoxTitle>{game}</CheckBoxTitle>
            <SvgIcons.ArrowIcon
              onClick={handleToggleDialog(userModals.SOUNDS_SETTINGS, {
                game,
                value,
              })}
            />
          </SoundItem>
        ))}
      </SoundsWrap>
    </Wrapper>
  );
};
