import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 40px 0 15px;
`;
