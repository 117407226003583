import * as React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

class GamePing extends React.PureComponent {
  state = {
    pingMs: 150,
  };
  pingTime = Date.now();

  componentDidMount() {
    this.props.fetchPing();
    this.fetchPingId = setInterval(() => {
      this.pingTime = Date.now();
      this.props.fetchPing();
    }, 10000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.ping !== prevProps.ping) {
      this.setState({ pingMs: this.props.ping - this.pingTime });
    }
  }

  componentWillUnmount() {
    clearInterval(this.fetchPingId);
  }

  render() {
    const { pingMs } = this.state;
    const { t } = this.props;
    return (
      <PingContainer>
        <PingTitle onClick={this.props.openModal}>{t('Fair game')}</PingTitle>
        <PingTitle color={pingMs < 100 ? '#00ff00' : pingMs < 1000 ? '#ffff33' : '#ff3333'}>
          Ping: <span>{pingMs}</span>ms
        </PingTitle>
      </PingContainer>
    );
  }
}

export default withTranslation()(GamePing);

const PingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 23px 5px;
`;
const PingTitle = styled.div`
  font-size: 12px;
  color: var(--color-white);

  & span {
    color: ${p => p.color};
  }
`;
