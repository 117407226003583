import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import couponsprite from '../../assets/couponsprite.svg';
import { ITournamnetItemType } from '../../interfaces/reducer.leaders.interfaces';

interface ITournamentHistoryLink {
  pushToHistory: () => void;
  history: ITournamnetItemType;
}

export const TournamentHistoryLink: React.FC<ITournamentHistoryLink> = ({
  history: { raceType, startAt, myStats, leaderboard },
  pushToHistory,
}) => {
  const { t } = useTranslation();
  return (
    <Container onClick={pushToHistory}>
      <i className="icon-arrow" />
      <div>
        {t('Races type', {
          context: raceType,
        })}
        {myStats && (
          <>
            {' '}
            {t('from')} {dayjs(startAt).format('DD.MM.YYYY')}
          </>
        )}
      </div>
      {myStats && myStats.place && !!leaderboard[myStats.place - 1] ? (
        <UserProgress raceType={raceType}>
          {!!myStats.place && (
            <span>
              {t('place')}: {myStats.place}
            </span>
          )}
          {!!leaderboard[myStats.place - 1].prizeCoinsAmount && (
            <span className="user-proggress_prize">
              <CurrencyText value={leaderboard[myStats.place - 1].prizeCoinsAmount} />
            </span>
          )}
          {leaderboard[myStats.place - 1].prizeTicketsAmount && (
            <span className="user-proggress_coupons">
              <svg className="icon">
                <use xlinkHref={`${couponsprite}#coupon-${raceType}`} />
              </svg>
              {leaderboard[myStats.place - 1].prizeTicketsAmount}
            </span>
          )}
        </UserProgress>
      ) : (
        <UserProgress>
          <span>
            {t('from')} {dayjs(startAt).format('DD.MM.YYYY')}
          </span>
        </UserProgress>
      )}
    </Container>
  );
};

type IUserProgressStyle = {
  raceType?: string;
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 61px;
  padding: 0.7rem 3rem 0.7rem 1.5rem;
  position: relative;

  &:before,
  &:after {
    content: '';
    width: calc(100vw - 1.5rem);
    position: absolute;
    right: 0;
    height: 1px;
    background: var(--gray-400);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  & [class='icon-arrow'] {
    position: absolute;
    right: 0.7rem;
    top: 50%;
    color: #858585;
    transform: rotate(-90deg) translateX(50%);
  }

  &:first-child {
    border-top: 1px solid var(--gray-400);
  }

  & > div {
    font-size: 0.85rem;
    color: var(--color-white);
  }
`;

const UserProgress = styled.div<IUserProgressStyle>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > span {
    margin-right: 1rem;
  }

  & > span:first-child {
    opacity: 0.51;
  }

  & > .user-proggress_prize {
    color: var(--yellow);
  }

  & > .user-proggress_coupons {
    color: ${p => `var(--${p.raceType}-race)`};
    & > svg {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.25rem;
    }
  }
`;
