import { useState, useCallback } from 'react';

export const useToggleGame = () => {
  const [toggleId, setToggle] = useState(null);

  const toggleGame = useCallback(
    openGame => () => {
      setToggle(toggleId === openGame && !!toggleId ? null : openGame);
    },
    [toggleId]
  );

  return {
    toggleId,
    toggleGame,
  };
};
