export default {
  '100$ for the new players!': '$ 100 for de nye spillerne!',
  '100$ bonus is credited to a demo balance': '100 $ bonus blir kreditert en demosaldo',
  'Get 100$ now!': 'Få 100 $ nå!',

  'Top up your balance from 25$ and get bonuses': 'Fyll på saldoen din fra 25 $ og få bonuser',
  'Exp bonus': 'Eksponeringsbonus',
  'Wheel Of Fortune free spin': 'Wheel Of Fortune gratis spinn',
  'Free case': 'Gratis sak',
  'Access to account level-up': 'Tilgang til kontonivå',
};
