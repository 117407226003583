export enum GameStatus {
  waiting = 'waiting',
  start = 'start',
  roll = 'roll',
  finish = 'finish',
}

export enum RouletteStatuses {
  beforeRoll = 'before-roll',
  afterRoll = 'after-roll',
  roll = 'roll',
  finishAnimation = 'finish-animation',
}

export enum SortParam {
  newest = '-created_at',
  oldest = 'created_at',
  cheap = 'total_price',
  expensive = '-total_price',
}

export const MAX_RANGE = 2200;
export const MIN_RANGE = 0;
export const DEFAULT_PRICE_HEIGHT = 25;
