import { SteamLinkAccountDialog } from './steam-link-account';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';
import { userModals } from 'core/User/modal.config';

DIALOG.add(userModals.STEAM_LINK_ACCOUNT, {
  component: SteamLinkAccountDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
