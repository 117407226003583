import { Button } from '@components/Common';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 15px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

export const StyledButton = styled(Button)<{
  isNeedToChangeVariant: boolean;
  isJoker: boolean;
  color?: string;
}>`
  height: 28px;
  position: absolute;
  width: fit-content;
  padding: 5px 15px;
  text-transform: none;
  background: var(--yellow);
  color: var(--color-dark-100);
  font-size: 10px;
  transition: right 0.3s ease, opacity 0.3s ease;

  &[data-visible='hide'] {
    right: -50px;
    opacity: 0;
  }

  &[data-visible='visible'] {
    right: 1px;
    opacity: 1;
  }

  @media (max-width: 375px) {
    padding: 5px 12px;
  }

  @media (max-width: 350px) {
    font-size: 8px;
    padding: 5px 10px;
  }

  ${({ isNeedToChangeVariant }) =>
    isNeedToChangeVariant && 'background: var(--color-deposit-lottery); color: var(--color-white);'}

  ${({ isJoker }) => isJoker && 'background: var(--pink-100)'}
`;

export const EmptyComponent = styled.button<{ isVisible: boolean }>`
  padding: 5px 15px;
  background: none;
  color: var(--color-dark-100);
  width: fit-content;
  text-transform: none;
  white-space: nowrap;
  border: none;
  font-size: 10px;

  @media (max-width: 375px) {
    padding: 5px 12px;
  }

  @media (max-width: 350px) {
    font-size: 8px;
    padding: 5px 10px;
  }

  border-radius: var(--size-radius-m);
  ${({ isVisible }) => !isVisible && 'display:none'}
`;
