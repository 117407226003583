export default {
  'Fair game': 'Juego limpio',
  Bet: 'Apuesta',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = desactivado)',
  'Bet coins': 'Apostar monedas',
  Stop: 'Parar',
  'Wait for next round...': 'Espera a la próxima ronda...',
  Example: 'Ejemplo',
  '!! Crash !!': '!! Choque !!',
  'You won': 'Ha ganado',
};
