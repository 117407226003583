export default {
  Games: 'Oyunlar',
  'Refill balance': 'Paranı doldur',
  Settings: 'Ayarlar',
  Store: 'Market',
  'Get free money': 'Ücretsiz para al!',
  Notifications: 'Bildirimler',
  FAQ: 'FAQ',
  'Log out': 'Çıkış yap',
  'Steam trade link': 'Steam takas linki',
  'Where can I get a link?': 'Linki nereden alabilirim?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'CSGOFAST servisi ile bağlantınız kesildi. Tekrar bağlanmak için lütfen ekrana dokununuz.',
  'App was offline. Check your internet connection to continue!':
    'Uygulama çevrimdışı. Devam etmek için internet bağlantınızı kontrol ediniz!',
  SAVE: 'SAVE',
};
