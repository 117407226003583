import { LeadersRouter } from './containers';
import reducer, { eventsTypes } from './duck';
import './locales';

// export { leaderRaceEpics } from './duck'

export { QuestWidget, QuestProgressWidget } from './containers';
export const leadersRaceNewModule = {
  moduleName: 'leaders',
  path: '/leaders-race-next',
  component: LeadersRouter,
  lastAdded: true,
  reducer,
  eventsTypes,
};
