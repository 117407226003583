import { rootEpics$ } from 'store/epicMiddleware';
import { store as configure } from 'store/configureStore';
import { combineEpics } from 'redux-observable';
import { participateEpic } from './duck';
import { DoubleActionsTypes } from '../interfaces';

const gameEpics = combineEpics(participateEpic);

export const registerDoubleModule = () => {
  rootEpics$.next([gameEpics, DoubleActionsTypes.DETACH_LISTENERS]);
  configure.dispatch({ type: DoubleActionsTypes.ATTACH_LISTENERS });
};

export const unregisterDoubleModule = () => {
  configure.dispatch({ type: DoubleActionsTypes.DETACH_LISTENERS });
};
