import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 188px;
  align-items: flex-start;
`;
