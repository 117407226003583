import { useState, useCallback, ChangeEvent } from 'react';
import { IWalletType } from '../interfaces/reduces.refill.interfaces';

export const useWalletPay = () => {
  const [wallet, setWallet] = useState('');
  const [error, setError] = useState(false);

  const changeWallet = useCallback(
    (ev: ChangeEvent<HTMLInputElement>, walletType: IWalletType = 'default') => {
      if (walletType === 'cpf' || walletType === 'cpfName') {
        const inputCPF = ev.target.value.replace(/\D/g, '');
        const formattedCPF = inputCPF.replace(
          /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2}).*/,
          (match, p1, p2, p3, p4) => {
            let formatted = '';
            if (p1) {
              formatted += p1;
            }
            if (p2) {
              formatted += `.${p2}`;
            }
            if (p3) {
              formatted += `.${p3}`;
            }
            if (p4) {
              formatted += `-${p4}`;
            }
            return formatted;
          }
        );
        setWallet(formattedCPF);
        return;
      }

      if (ev.target.type === 'number') {
        if (ev.target.value.length > 11) return;
        setWallet(ev.target.value);
      } else {
        setWallet(ev.target.value);
      }
    },
    []
  );

  const walletFieldError = useCallback((value: boolean) => {
    setError(value);
  }, []);

  return {
    wallet,
    changeWallet,
    walletFieldError,
    error,
  };
};
//TODO: FAST-5342  delete this hook
