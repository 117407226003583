import styled from 'styled-components';

export const ChartProfit = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  text-align: center;

  & > * {
    padding: 0.2rem 0;
  }

  & .profit-title {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.86px;
    text-transform: uppercase;
    color: var(--color-gray-100);
    max-width: 90%;
  }

  & .profit-total {
    font-size: 1.8rem;
    font-stretch: condensed;
    letter-spacing: 0.61px;
    text-align: center;
    color: var(--color-white);
  }

  & .profit-description {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    max-width: 140px;

    & span {
      display: block;
      font-size: 0.7rem;
      font-weight: bold;
      letter-spacing: 0.86px;
      line-height: 1.33;
    }
  }
`;
