export default {
  '/settings': '设置',
  '/marketplace': '市场',
  '/balance': '余额',
  '/auth': '授权',
  '/notifications': '通知',

  RULES: '规则',
  Chat: '聊天聊天',
  GAME: '游戏',
  Top: '顶部',
  HISTORY: '历史',
};
