import React, { FC, useReducer } from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/configureStore';
import { Shell } from './Shell';
import { Dialog } from 'dialogs';
import { asyncModules, MODULES, RouterContext } from './router-context';
import { AsyncBrowserRouter } from './async-browser-router';
import SoundService from 'services/sound.service';
import { usePageVisibility } from '../hooks';

const sound = new SoundService();

//NOTE: DON`T TOUCH IMPORTS ORDER!!!!
//STORE need to be at the high of file, because some *!$# try to use store in global env
//store import should be before 'router-context' because one of the file from 'router-context'
// re-imports tries to use something from redux before any initialization and it constantly breaks the application. Requires further investigation.

export const Application: FC = () => {
  const routes = useReducer(asyncModules, MODULES);
  const isVisible = usePageVisibility();

  isVisible ? sound.toggleSound(0) : sound.toggleSound(1);

  return (
    <RouterContext.Provider value={routes}>
      <Provider store={store}>
        <AsyncBrowserRouter>
          <Shell />
          <Dialog />
        </AsyncBrowserRouter>
      </Provider>
    </RouterContext.Provider>
  );
};
