export default {
  line: '行',
  lines: '行',
  total: '合计',
  Autoplay: '自动播放',
  Paytable: '赔率表',
  'Recent wins': '赢得的彩头',
  'Your games': '您的游戏',
  'Bonus game': '奖励游戏',
  'Slot works regardless of location': '每排的运行与位置无关',
  'Can substitute any slot in the line (Except for bonus slots)':
    '可以替换行中的任何一排 (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!': '集齐全部三排可获得 10 次奖励游戏！',
  'Total won': '赢得彩头合计',
  'Bonus games': '奖励游戏',
};
