import { ACTION_TYPE } from '../actionType';
import { SortData } from '../interfaces';
import { SortActions } from './sort.interfaces';

export const sortAction = (sortData: string) => ({
  type: ACTION_TYPE.SORT_SORT_TYPE,
  payload: {
    sortBy: sortData,
  },
});

export const priceRangeAction = (minPrice: number, maxPrice: number) => ({
  type: ACTION_TYPE.SORT_PRICE_RANGE,
  payload: {
    minPrice,
    maxPrice,
  },
});
export const searchParamsAction = (query: string) => ({
  type: ACTION_TYPE.SORT_SEARCH,
  payload: {
    query,
  },
});

export const cleanSortParamsAction = () => ({
  type: ACTION_TYPE.SORT_CLEAN_SORT_PARAMS,
});
const initialState: Record<string, SortData[keyof SortData] | null> = {
  sortBy: 'popular',
  minPrice: null,
  maxPrice: null,
  query: null,
};
export const sortParams = (state = initialState, action: SortActions) => {
  switch (action.type) {
    case ACTION_TYPE.SORT_SORT_TYPE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case ACTION_TYPE.SORT_PRICE_RANGE: {
      return {
        ...state,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
      };
    }
    case ACTION_TYPE.SORT_CLEAN_SORT_PARAMS: {
      return initialState;
    }
    case ACTION_TYPE.SORT_SEARCH: {
      return {
        ...state,
        query: action.payload.query,
      };
    }
    default:
      return state;
  }
};
