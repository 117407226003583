export default {
  'What is the Ticket System?': 'Bilet "Ticket" sistemi nedir?',
  'When players place the bets they get virtual tickets, one of them becomes a winner If your item is worth $1, you get 100 tickets, but if you deposit a skin worth $10, you get 1,000 tickets, and so on':
    'Örneğin eşyanız 1$ ise 100 bilet alırsınız. Ama eşyanız 10$ ise 1,000 bilet alırsınız. Ne kadar fazla yatırırsan kazanma şansın o kadar artar!',

  // GAMES

  'How is the winner determined?': 'Kazanan nasıl belirlenir?',
  'When is the winner determined?': 'Kazanan ne zaman belirlenir?',
  'How many bets can I place per one round?': 'Bir turda birden fazla bahis yapabilirmiyim?',
  'What Service Commission is there in this game?': 'Oyunda hizmet komisyonu varmıdır?',

  // Classic

  'the game for those, who like playing for the high stakes Those wishing to try their luck place a bet on a general deposit that is raffled among players at the end of a round':
    'aşırı yüksek bahisler içindir. Bu yüksek bahislerde şansını denemek isteyen bir kullanıcı turun sonunda çevirilip parasını alır.',
  'The winner is determined by the Ticket System in the Classic Game.':
    "Kazanan Classic Game'un bilet sistemi tarafından belirlenir.",
  'The winner is determined at the end of a round time or once the max number of skins is achieved on the deposit.':
    'Kazanan kişi süre bitiminde veya eşya limiti dolduğunda belirlenir.',
  'A Service Commission is from 0 up to 10% depending on a sum of winning. Random skins of the general deposit are taken as a Service Commission.':
    'Kazandığınız miktarın %10 gibi miktarı site tarafından hizmet komisyonu olarak alınmaktadır.',

  // Fast

  'the game to try your luck instantly There are only 3 players, the max bet is limited The winner gets a total deposit of 3 players':
    'gameda anında şansınızı deneyin. Orada sadece 3 oyuncu ve maksimum bahis vardır. 3 oyuncu arasından bir kişi seçilir.',
  'The winner is determined randomly, but the higher the bet, the greater the chance to win.':
    'Kazanan rastgele bilet sistemi ile belirlenir.',
  'One player can place one bet per one game round in this game.':
    'Hayır, oyun başına birkez yapabilirsiniz.',
  'Where can I see my chances of the winning?': 'Benim kazanma şansı nerede görebilirim?',
  'Chances of the winning are indicated in percentage terms. You can see the percent of your chance next to the players bet sum of the round (on the right hand of avatars and list of deposited skins).':
    'Kazanma şansı yüzde oranı açısından gösterilir. Eşyaların yanındaki kısımda yüzdelik şansınızı ve yatırdığınız miktarı görebilirsiniz.',
  'The winner is determined once 3 players place their bets in the current game round.':
    'Oyun başında bilet sistemi ile belirlenmektedir. 3 oyuncu arasından bir kişi seçilir. Daha yüksek tutar daha fazla şans demektir',
  'A Service Commission is from 0 up to 15% depending on a sum of winning. Random skins from the general deposit of the round are taken as a Service Commission.':
    'Oyuna bağlı olarak %15 olarak alınmaktadır. Düşük oyunlarda rastgele eşya alınabilir.',

  // Double

  'is a mode of a game, where gamers bet on black, red or green slots. In this mode Fast Coins are placed into as bets. The winner slot is determined at the end of the round.':
    'oyuncular bahis üzerinde siyah, kırmızı ya da yeşil bir çarkın bulunduğu bir oyun modudur. Bu modda hızlı para bahisler olarak içine yerleştirilir. Kazanan slot tur sonunda belirlenir.',

  'If the winner is black or red slot, the gamer wins the placed bet multiplied by 2.':
    'Kazanan siyah veya kırmızı yuvası ise, oyun 2 ile çarpılır yerleştirilmiş bahis kazanır.',
  'The green slot returns the placed bet multiplied by 14.':
    'Yeşil yuvası 14 tarafından çarpılan yerleştirilmiş bahis verir.',
  'The bet sum of every slot can be different.': 'Her slot bahis toplamı farklı olabilir',

  'How can I Get Fast Coins?': 'Hızlı para nasıl kazanırım?',
  '1. Participate in the Referral program. Invite new players to the project with a help of referral links or code. You get money for every referral and every game he plays.':
    '1. Referans programına katılmak. Referans tavsiye bağlantıları veya kod yardımıyla yeni oyuncular davet ediyoruz. Her başvuru ve o çalış her oyuncu için para alırsınız.',
  '2. Exchange your skins worth from $1 for Fast Coins. For every cent of skin value you get one coin. You can send max 30 items at a time.':
    "2. Eşya yatırarak para kazanabilirsin. 1$'lık bir eşyayı yatırarak 100 coin elde edebilirsin. Bir saat içerisinde maksimum 30 eşya gönderebilirsin. Eğer eşyaların fiyatı hakkında bilgin yoksa Steam Inventory Helper indirerek daha kolay öğrenebilirhemde daha rahat bir şekilde eşyalarını yatırabilirsin.",
  '3. Replenish the balance with a help of Payment System. Select the convenient way of payment and get the necessary number of Fast Coins on your account.':
    '3. Ödeme yöntemi seçerek coin yatırabilirsin.G2A',

  'How is the winner slot determined (fair game)?':
    'Kazananlar nasıl belirleniyor? (Adil oyun nedir?)',
  'At the very beginning of a round the Service generates a random long number from 0 to 1 (e.g. 0. 223088) and encrypts it through the sha224 algorithm. The result is displayed at the beginning of a round. At the end of the round the Service multiplies the encrypted number by 15 thus getting the number of a winner slot.':
    "Kazanan oyun başında tüm bahisler yatırıldıktan sonra belirlenmektedir. Site 0'dan 1'e doğru sırasıyla bir sayı oluşturur. (Örneğin: 0.282796734) ve sha224 algoritması ile sistem otomatik olarak tüm numaraları şifreler. Yöneticiler dahil bu bu numaralara kimse müdehale edemez. Turun sonunda sistem kazanan bileti otomatik olarak oyunculara gösterir ve numarayı oyunculara belirtir.",
  'An example: At the very beginning of the round the encoded number is 0.223088232334703230728. The Service multiplies it by 15 thus getting number 3.34632348495. As a result the counting number is 3 (the red slot). In this round the winners are those who have placed their bets on red slot.':
    "Örneğin: Tur belirlenmiş olan sayı üzerinden hareket ediyor. Bu sayı; 0.22388232334703230728'dir. Servisimiz eş zamanlı çalışarak kazanan numara olaak 3.34632348495'i belirledi. Sonuç olarak sayı (kırmızı slot). Böylece kazananlar korunmuş ve numaralarda erişime kapalı olarak yapılmış oldu. Oyuna dışardan müdehale edilemediği için kazanan gizli tutuldu ve renk belirlendi.",
  'You can check whether a winner slot is fair or not on your own. At the end of a round take the encoded number and encrypt it one more time with a help of any online service, e.g. http://sha224.net. You will get the same hash value which was at the very beginning of a round. This means that the game was not rigged.':
    'Buradaki siteden bunu test edebilirsin: http://sha224.net',
  "Since the system selects winning sector at the very beginning of the round and any player can monitor it's change - we have no opportunity to influence the results of a raffle.":
    'Adil oyun bu durumda ne işe yarar? Bu durumda önceden kazanacak olan kişiyi bilmediğimiz için oyuna herhangi bir müdehale yapılamaz. Herhangi bir oyuna etki etme yetkimiz tamamiyla kaldırılmıştır.',

  'You can sweeten an unlimited number of times till the beginning of the round. The sum of a bet of every slot can be different.':
    'Renk başına herhangi bir sınırlandırma yoktur. İstediğin kadar yatırabilirsin, aynı maçta birden fazla rengede koyabilirsin.',

  'What bet limitations are there?': 'Sınırlama varmıdır?',
  'The minimum bet is $0,1. On one color, you can put a maximum of $5000 at a time.':
    'En düşük bahis $0,1. Bir renge tek seferde en fazla $5000 yatırabilirsiniz.',
  'Attention! During one draw you can not bet on red and black at the same time. Bets on Green are available every round!':
    'Dikkat! Bir çekilişte, aynı anda kırmızı ve siyaha para yatıramazsınız. Yeşil üzerine bahisler her turda mümkündür!',

  'When does the determination of the winner slot begin?': 'Kazanan renk ne zaman belirlenir?',
  'The winner slot is determined at the end of a round.': 'Kazanan renk sonda belirlenir.',

  'Is there any commission in this mode?': 'Bu modda herhangi bir komisyon var mı?',
  'There is no commission at all. :)': 'Hiçbir komisyon yok. :)',
};
