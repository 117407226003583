export default {
  Games: 'Spiele',
  'Refill balance': 'Kontostand auffüllen',
  Settings: 'Einstellungen',
  Store: 'Store',
  'Get free money': 'Bekommen',
  Notifications: 'Benachrichtigungen',
  FAQ: 'FAQ',
  'Log out': 'Abmelden',
  'Steam trade link': 'Steamlink für den Handel',
  'Where can I get a link?': 'Wo erhalte ich den Link?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'Du wurdest vom CSGOFAST-Dienst getrennt. Bitte tippe auf den Bildschirm, um die Verbindung wiederherzustellen.',
  'App was offline. Check your internet connection to continue!':
    'Die App war offline. Überprüfe deine Internetverbindung, um fortzufahren.',
  SAVE: 'SAVE',
};
