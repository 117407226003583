import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: scroll !important;
  scroll-behavior: unset !important;

  & > div.gray-dark {
    background-color: var(--color-dark-100);
  }
`;
