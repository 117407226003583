import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { StoreHotOffers } from './store-hot-offers';
import { StoreFilters } from './store-filters';
import { StoreItems } from './store-items';
import { StoreNavigation } from './store-navigation';
import { InventoryNavigation } from './inventory-navigation';
import { ScrollContext } from './scroll-context';

export const StorePage: FC<RouteComponentProps> = () => {
  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <ScrollContext.Provider value={parentRef}>
      <div className="content dark-layout">
        <Container ref={parentRef}>
          <StoreNavigation />
          <StoreHotOffers />
          <StoreFilters />
          <StoreItems parentRef={parentRef} />
          <InventoryNavigation />
        </Container>
      </div>
    </ScrollContext.Provider>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 3.75rem min-content 2.75rem 1fr;
  grid-gap: 1.75rem;
  padding-top: 0.5rem;
  min-height: 100%;
  overflow: auto;
`;
