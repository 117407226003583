import { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getRaceByParams, getTournamentHistoryByParams } from '../selectors';
import { fetchRacesHistory } from '../ducks/history.duck';
import { useHistory } from 'react-router';

export const useTournamentFacade = (raceType: string) => {
  const race = useSelector((state: any) => getRaceByParams(state, raceType), shallowEqual);
  const tournamentHistory = useSelector(
    (state: any) => getTournamentHistoryByParams(state, raceType),
    shallowEqual
  );

  const { push } = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tournamentHistory.loading && !tournamentHistory.loaded)
      dispatch(fetchRacesHistory(raceType));
  }, [dispatch, raceType, tournamentHistory]);

  const pushToHistory = useCallback(
    (id: number, raceType: string) => () => {
      push(`/leaders-race-next/tournament/history/${raceType}`, {
        id,
      });
    },
    [push]
  );

  return {
    race,
    tournamentHistory,
    pushToHistory,
  };
};
