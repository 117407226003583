import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Description, Title, Rules } from './stage2.styles';
import { StageHeader } from '../stage-header';
import { EventIcons } from '../../../assets';

export const Stage2 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StageHeader />
      <EventIcons.Slide2 />
      <Description>
        <Title>{t('weekly')}</Title>
        <Rules>
          <span>{t('difficultQuests')}</span>
          <span>
            <Trans i18nKey="completeDifficultQuests" t={t}>
              For completing one quest, you will receive 1
              <span className="accent">silver grenade</span>.
            </Trans>
          </span>
          <span>{t('completeWeeklyDifficultQuests')}</span>
        </Rules>
      </Description>
    </Container>
  );
};
