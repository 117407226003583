import React from 'react';
import styled from 'styled-components';
import { Button } from '@components/Common';
import { StepStyleContainer } from '../@shared/step-style-container';
import { useWatchSteamSettings } from 'core/User';
import { useTranslation } from 'react-i18next';
import { SteamBinding } from '../../icons';
import { URLS } from 'services/constant-urls';

export const PrivateInventoryStep = () => {
  const { t } = useTranslation('steam-settings');
  const { handleOpenSettings } = useWatchSteamSettings();
  return (
    <Container>
      <div className="step-header">
        <h5 className="step-header-title">{t('Make your inventory public')}</h5>
        <div className="step-header-warning-message">
          <i className="icon-warn" />
          <p>{t('Your inventory is private Make it public to continue')}</p>
        </div>
      </div>
      <Button className="steam-provider" onClick={handleOpenSettings(URLS.steamSettings)}>
        <SteamBinding />
        {t('Make inventory public')}
        <i className="icon-redirect-link" />
      </Button>
    </Container>
  );
};

const Container = styled(StepStyleContainer)``;
