import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCreateParticipate } from '../duck';
import { IFastInventoryElement } from 'core/User';

export const useFastParticipateController = () => {
  const dispatch = useDispatch();

  const handleCreateParticipate = useCallback(
    (data: Set<IFastInventoryElement>) => () => {
      dispatch(fetchCreateParticipate(data));
    },
    [dispatch]
  );

  return {
    handleCreateParticipate,
  };
};
