import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../core/hooks';
import { IWithdrawal, IWithdrawalGate } from '../../../interfaces/reducers.withdrawal.interface';
import { useOrder, usePayoutFee } from '../../../hooks';
import { cryptPayoutSystems } from '../../../configs/crypt-payout-systems';
import { useSelector } from 'react-redux';
import { getPayoutCurrency } from '../../../selectors';
import { IStore } from 'store/interface';
import { WithdrawalDialogs } from '../../../withdrawal-dialogs.config';
import infoSvg from '../../../../../assets/images/svg/failure.svg';
import { getPrice } from '../../utils/getPrice.utils';

interface ICalculateAmount {
  amount: IWithdrawal['amount'];
  gate: IWithdrawalGate;
  feeSelectors: string[];
  name: string;
}

export const CalculateAmount: FC<ICalculateAmount> = React.memo(
  ({ amount, gate, feeSelectors, name }) => {
    const currency = useSelector((store: IStore) => getPayoutCurrency(store, amount.currency));
    const { t } = useTranslation();
    const { fee } = usePayoutFee();
    const { order } = useOrder();
    const { handleToggleDialog } = useDialog(WithdrawalDialogs.RATE_DIALOG);
    const feeKey = feeSelectors?.length ? feeSelectors[order.feeIndex] : null;
    const choose = feeKey ? fee[feeKey] : null;

    return (
      <Container>
        <div className="calculate-values">
          <div className="calculate-values-item">
            <span>{t('Commission:')}</span>
            <span>{choose?.comission || gate?.commission || '0%'}</span>
          </div>
          <div className="calculate-values-item">
            <span>{t('Receive in {{title}}:', { title: gate.title })}</span>
            {currency ? (
              <span>
                {'~ '}
                {getPrice(amount.currency, amount.output, currency.convert_rate, currency.decimal)}
                {` ${currency.title}`}
              </span>
            ) : (
              <span>
                {amount.receive} {amount.currency}
              </span>
            )}
          </div>
        </div>
        <div className="output-values">
          <span className="receive">
            <span>{t('You will receive:')}</span>
            {amount.needPlayCoinsAmount > 0 && (
              <span
                className="infoSpan"
                onClick={handleToggleDialog(WithdrawalDialogs.RATE_DIALOG, {
                  rate: amount.rate,
                  payback: amount.payback,
                  needPlayCoinsAmount: amount.needPlayCoinsAmount,
                })}
              >
                <img src={infoSvg} alt="info" />
              </span>
            )}
          </span>

          <span className="output">
            {cryptPayoutSystems.includes(name) && <>{'~ '}</>}$ {(amount.output / 100).toFixed(2)}
          </span>
        </div>
      </Container>
    );
  }
);

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 3rem;

  & .calculate-values {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1.5625rem;
    grid-gap: 0.375rem;
    padding: 0 1rem;

    &-item {
      display: flex;
      justify-content: space-between;

      & span {
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.24px;
        color: white;

        &:first-of-type {
          color: var(--color-gray-200);
        }
      }
    }
  }

  & .output-values {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;

    & span {
      font-size: 0.75rem;
      line-height: 1rem;
      text-align: center;
      letter-spacing: 0.1px;
      color: var(--color-gray-200);
      text-transform: uppercase;

      &:last-of-type {
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 2.1875rem;
        text-align: center;
        letter-spacing: 0.54px;
        color: white;
      }
    }
    & .receive {
      display: flex;
      justify-content: center;
      align-items: center;
      & span {
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        letter-spacing: 0.1px;
        color: var(--color-gray-200);
        text-transform: uppercase;
      }
      & img {
        width: 1rem;
        height: 1rem;
      }
      & .infoSpan {
        display: flex;
        margin: 0 0.5rem;
      }
    }
  }
`;
