import Immutable from 'immutable';
import { IClearanceRecord } from './clearance.reducers.interface';

export interface IStockReducer {
  clearance: Immutable.Record<IClearanceRecord>;
}

export enum ModeType {
  SELLOUT = 'SELLOUT',
  WITHDRAWAL = 'WITHDRAWAL',
  CONTRACT = 'CONTRACT',
}
