import { useSelector } from 'react-redux';
import { getGeneralQuestInfo } from '../duck';

export const useGetGeneralQuestInfo = () => {
  const { isLoading, data, isLoaded } = useSelector(getGeneralQuestInfo);

  return {
    isLoading,
    isLoaded,
    endAt: data?.endAt,
    progressEndAt: data?.progressEndAt,
    reward: data?.reward,
    categoriesData: data?.categories,
  };
};
