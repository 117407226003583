import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

type UntilTime = {
  d: number;
  h: number;
  m: number;
  s: number;
};

export const useTimerEvent = (finishEvent: string | number | Date): [UntilTime] => {
  const [unit, set] = useState(() => {
    const d = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
    return { d: d.days(), h: d.hours(), m: d.minutes(), s: d.seconds() };
  });

  useEffect(() => {
    const diff = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
    if (diff.seconds() < 0) return set({ d: 0, h: 0, m: 0, s: 0 });

    const timerId = setInterval(() => {
      const d = dayjs.duration(dayjs(finishEvent).diff(Date.now()));
      if (d.seconds() < 0) {
        clearInterval(timerId);
        set({ d: 0, h: 0, m: 0, s: 0 });
      } else {
        set({ d: d.days(), h: d.hours(), m: d.minutes(), s: d.seconds() });
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [finishEvent, set]);

  return [unit];
};
