import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { SlotsHistory } from '@components/Histories';

import { getHistories } from '../selectors';
import { getUser } from 'core/User/selectors';

import { IUserState } from 'core/User/interfaces/user.reducer.interface';
import { IHistory } from '../interfaces/poggi.reducer.interfaces';
import { filterableSwitch } from '@components/Histories/Slots';
import { HistoryContent } from '../components';

const { useState } = React;

export const History = () => {
  const [filterable, setFilterable] = useState<filterableSwitch.own | filterableSwitch.recent>(
    filterableSwitch.recent
  );

  const history = useSelector(state => getHistories(state, filterable));
  const user: IUserState = useSelector(getUser, shallowEqual);

  return (
    <SlotsHistory
      history={history}
      filterable={filterable}
      handleSwitch={() =>
        setFilterable(
          filterable === filterableSwitch.own ? filterableSwitch.recent : filterableSwitch.own
        )
      }
      user={user}
      content={(props: IHistory) => <HistoryContent {...props} />}
    />
  );
};
