import { usePayoutMethods } from 'modules/withdrawal/hooks';
import React from 'react';
import { AppBar } from '@components/bars';
import { useTranslation } from 'react-i18next';
import { CryptoMethodsComponent } from '../payout-system-list/components';

export const CryptoMethodsPage = () => {
  const { cryptoArrayMethods } = usePayoutMethods();
  const { t } = useTranslation();
  return (
    <>
      <AppBar deep>{t('crypto')}</AppBar>
      <div className="content dark-layout">
        {cryptoArrayMethods.map(({ title, name }) => (
          <CryptoMethodsComponent title={title} name={name} key={name} />
        ))}
      </div>
    </>
  );
};
