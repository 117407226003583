interface IStickerCollection {
  [key: string]: {
    name: string;
    path: string;
    size: number;
    logo: string;
  };
}

export const stickers: IStickerCollection = {
  sadpepe: {
    name: 'sad pepe',
    logo: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/SadPepe/previews/0.png',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/SadPepe/pack',
    size: 21,
  },
  ragefaces: {
    name: 'ragefaces',
    logo: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/ragefaces/previews/0.png',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/ragefaces/pack',
    size: 92,
  },
  southpack: {
    name: 'southpark',
    logo: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/southpark/previews/0.png',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/southpark/pack',
    size: 90,
  },
  meme_stickers: {
    name: 'meme',
    logo: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/meme_stickers/previews/0.png',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/meme_stickers/pack',
    size: 99,
  },
};
