import { RevisionDialogIcon } from 'dialogs/case-game-dialogs/cases-last-revision-dialog/assets';
import { useToggle } from 'games/CaseGame/hooks';
import React, { useCallback } from 'react';
import { Container, DropDownHeader, IconContainer, Text, Wrapper } from './drop-down.styles';
import { Option } from './option.component';
import { actionRevisionRequest } from 'games/CaseGame/ducks';
import { useDispatch } from 'react-redux';

interface DropDownProps {
  ids: number[];
  currentId: number;
}

export const DropDown: React.FC<DropDownProps> = ({ ids, currentId }) => {
  const { isOpen, toggle } = useToggle(false);
  const dispatch = useDispatch();

  const onClickHandler = useCallback(
    (caseId: string, page: number) => {
      dispatch(actionRevisionRequest(caseId, page));
    },
    [dispatch]
  );

  return (
    <Container>
      <DropDownHeader>
        <div onClick={toggle}>
          <Text>{currentId}</Text>
          <IconContainer isOpen={isOpen}>
            <RevisionDialogIcon.ArrowDown />
          </IconContainer>
        </div>
        <Wrapper isOpen={isOpen}>
          {ids?.map((id, index) => (
            <Option
              toggleHandler={toggle}
              key={id}
              id={id}
              index={index}
              onClick={onClickHandler}
            />
          ))}
        </Wrapper>
      </DropDownHeader>
    </Container>
  );
};
