import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { Rules } from '../../components';

export const RulesPage: FC = () => (
  <>
    <AppBar deep />
    <Rules />
  </>
);
