import React, { FC } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { GameCondition } from '../../../../utils';

interface IGameRoulettePointer {
  rand: number;
  condition: GameCondition;
}

const from = {
  opacity: 0,
  transform: 'translate3d(0, 100%, 0) scale(0.75)',
};

const to = {
  opacity: 1,
  transform: 'translate3d(0, 0, 0) scale(1)',
};

export const GameRoulettePointer: FC<IGameRoulettePointer> = ({ condition, rand }) => {
  const styles = useSpring({
    from,
    to: GameCondition.RESULT === condition ? to : from,
    reset: true,
  });

  return (
    <Container>
      <animated.div className={`indicator sector-${rand}`} style={styles}>
        {rand}
      </animated.div>
      <i className="icon-cursor" />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1.375rem;
  grid-auto-rows: min-content;
  align-content: flex-end;
  width: min-content;
  height: calc(min(100vw, 100vh) * 0.208);
  transform: translate3d(-50%, 0, 0);

  @media screen and (min-width: 500px) {
    height: 125px;
  }

  & .indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    background: var(--stroke-color);
    border-radius: 50%;

    font-weight: bold;
    font-size: 0.5625rem;
    line-height: 0.6875rem;
    letter-spacing: 0.13058px;
    color: var(--color-white);
  }

  & i {
    font-size: 2.25rem;
    transform: rotate(90deg);
    color: var(--color-white);
  }
`;
