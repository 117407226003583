import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ParticipateType as PT } from 'core/interfaces';
import { chips } from 'core/configs';
import { IFastInventoryElement } from 'core/User';
import { addNotification } from 'modules/Notifications/duck';

interface IParticipateState {
  [PT.INVENTORY]: Set<IFastInventoryElement>;
  [PT.CHIPS]: number[];
  [PT.MONEY]: number;
  amount: number;
}

export const useParticipateController = (initialType: PT) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [type, setType] = useState<PT>(initialType);
  const [participate, setParticipate] = useState<IParticipateState>(() => ({
    [PT.INVENTORY]: new Set([]),
    [PT.CHIPS]: [],
    [PT.MONEY]: 10,
    amount: 0,
  }));

  useEffect(() => {
    setParticipate(state => {
      if (PT.INVENTORY === type) {
        const amount = Array.from(state[type] as Set<any>).reduce(
          (acc, item) => acc + (item?.price || 0),
          0
        );
        return { ...state, amount };
      }

      if (PT.CHIPS === type) {
        const amount = state[PT.CHIPS].reduce((acc, amount) => acc + amount, 0);

        return { ...state, amount };
      }
      return { ...state, amount: state[PT.MONEY] };
    });
  }, [type]);

  const toggleParticipate = useCallback(
    (item?: any) => () => {
      setParticipate(participate => {
        const origin = new Set(participate[PT.INVENTORY]);
        if (!item || (origin.size === 10 && !origin.has(item))) {
          return participate;
        }
        if (!origin.delete(item)) {
          origin.add(item);
        }

        const amount = Array.from(origin).reduce(
          (acc, item) => acc + (item?.inventoryItem.price || 0),
          0
        );
        return {
          ...participate,
          [PT.INVENTORY]: origin,
          amount,
        };
      });
    },
    [setParticipate]
  );

  const handleChangeType = useCallback((type: PT) => () => setType(type), [setType]);

  const handleChangeAmount = useCallback((money: number) => {
    setParticipate(participate => ({
      ...participate,
      [PT.MONEY]: money * 100,
      amount: money * 100,
    }));
  }, []);

  const handleOnPickChip = useCallback(
    (index: number, maxBet: number) => () => {
      setParticipate(state => {
        const items = [...state[PT.CHIPS], chips[index]];
        const amount = items.reduce((acc, amount) => acc + amount, 0);

        if (amount > maxBet) {
          dispatch(
            addNotification({
              type: 'error',
              body: t('Max bet has been reached'),
            })
          );
        }

        if (state[PT.CHIPS].length >= 10 || !chips[index] || amount > maxBet) return state;
        return { ...state, [PT.CHIPS]: items, amount };
      });
    },
    [dispatch, t]
  );

  const handleUnPickChip = useCallback(
    (index: number) => () => {
      setParticipate(state => {
        if (typeof state[PT.CHIPS][index] !== 'number') return state;
        const items = state[PT.CHIPS].slice(0, index).concat(state[PT.CHIPS].slice(index + 1));
        const amount = items.reduce((acc, amount) => acc + amount, 0);
        return { ...state, [PT.CHIPS]: items, amount };
      });
    },
    []
  );

  const handleCleanupParticipate = useCallback(
    (typeParticipate: PT) => {
      switch (typeParticipate) {
        case PT.CHIPS:
          setParticipate(() => ({
            ...participate,
            [PT.CHIPS]: [],
            amount: 0,
          }));
          break;

        case PT.INVENTORY:
          setParticipate(() => ({
            ...participate,
            [PT.INVENTORY]: new Set([]),
            amount: 0,
          }));
          break;

        case PT.MONEY:
          setParticipate(() => ({
            ...participate,
            [PT.MONEY]: 0,
            amount: 0,
          }));
          break;

        default:
          setParticipate(() => ({
            [PT.INVENTORY]: new Set([]),
            [PT.CHIPS]: [],
            [PT.MONEY]: 0,
            amount: 0,
          }));
          break;
      }
    },
    [participate]
  );

  return [
    {
      type,
      handleChangeType,
      participate,
    },
    {
      [PT.INVENTORY]: {
        toggleParticipate,
        handleCleanupParticipate,
      },
      [PT.CHIPS]: {
        handleOnPickChip,
        handleUnPickChip,
        handleCleanupParticipate,
      },
      [PT.MONEY]: {
        handleChangeAmount,
        handleCleanupParticipate,
      },
    },
  ];
};
