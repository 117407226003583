import React, { FC } from 'react';
import styled from 'styled-components';

export interface IChartDoughnut {
  padding: number;
  data: {
    key: string | null;
    value: number | null;
    name: string | null;
  };
  fullProgress: number;
}
const { innerWidth } = window;
const offset = 25;

export const ChartDoughnut: FC<IChartDoughnut> = ({ padding, data, fullProgress }) => {
  const cxy = (innerWidth - padding) / 2;
  const r = cxy - offset / 2;
  const width = innerWidth - padding;
  const progress = data.value ? data.value / fullProgress : 0;
  const circumference = 2 * Math.PI * r;
  return (
    <Container type={data.key}>
      <svg viewBox={`0 0 ${width} ${width}`} width={width} height={width}>
        <circle
          fill="none"
          cx={cxy}
          cy={cxy}
          r={r}
          strokeWidth={22}
          stroke="#212f35"
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={1 - circumference * 0.25}
        />
        <circle
          fill="none"
          className="circle-offset"
          cx={cxy}
          cy={cxy}
          r={r}
          strokeWidth={22}
          stroke="#1a262a"
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={
            data.value
              ? 0 - (circumference - (progress + 0.005) * 0.75 * circumference)
              : 0 - circumference
          }
        />
        <circle
          fill="none"
          className="circle-progress"
          cx={cxy}
          cy={cxy}
          r={r}
          strokeWidth={22}
          strokeDasharray={`${circumference}, ${circumference}`}
          strokeDashoffset={
            data.value ? 0 - (circumference - progress * 0.75 * circumference) : 0 - circumference
          }
        />
      </svg>
      <div className="game-type">{data.name}</div>
      <div className="game-progress">{(progress * 100).toFixed(0)}%</div>
    </Container>
  );
};

ChartDoughnut.defaultProps = {
  data: {
    key: null,
    value: null,
    name: null,
  },
  fullProgress: 0,
};

interface IChartStyle {
  type: string | null;
}

const Container = styled.div<IChartStyle>`
  position: absolute;
  & svg {
    transform: rotate(90deg);
    & .circle-progress {
      stroke: ${p => p.type && `var(--color-${p.type})`};
    }

    & .circle-progress,
    & .circle-offset {
      transition: stroke-dashoffset 2s;
    }
  }
  & .game-type {
    display: flex;
    align-items: center;
    height: 22px;
    padding: 0 0.2rem;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-100%);

    font-size: 0.7rem;
    font-weight: 500;
    letter-spacing: 1.11px;
    color: var(--color-gray-200);
  }

  & .game-progress {
    width: 22px;
    position: absolute;
    left: 0;
    top: 50%;

    padding: 0.2rem 0;
    font-size: 0.7rem;
    font-weight: bold;
    letter-spacing: 1.11px;
    color: ${p => p.type && `var(--color-${p.type})`};
    text-align: center;
  }
`;
