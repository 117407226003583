import React from 'react';
import { AppBar } from '@components/bars';
import {
  UserProfileAvatar,
  UserProfileDescription,
  UserProfileInfo,
} from '@components/UserProfile';
import { AccountNavigation } from '../../components';
import { useAccountProfile } from '../../hooks';

export const AccountProfilesPage = () => {
  const { profile } = useAccountProfile();
  // const { leaders } = useAccountLeader()

  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <UserProfileAvatar profileType={profile.profileType} avatar={profile.avatar} />
        <UserProfileInfo userId={profile.id} date_registred={profile.activity.registered} />
        <UserProfileDescription
          name={profile.name}
          profileType={profile.profileType}
          levelProgress={0}
          experience={profile.experience}
          premium={profile.premium}
        />
        {/*<LeaderStatistic leaders={leaders} />*/}
        <AccountNavigation statisticAccess={!profile.privacy.statisticsIsHidden} />
      </div>
    </>
  );
};
