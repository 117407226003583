import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const LobbyPreview = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="inner-layout">
        <div className="title-wrapper">
          <h2>
            {t('Stock up your golden tickets and get ready for the final leaders race of 2021')}
          </h2>
        </div>
        <img
          src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/bullet-1.png"
          alt="bullet"
        />
        <div className="prize-container">
          <span>{t('Raffling items for the amount of')}</span>
          <span>$ 52,200</span>
        </div>
      </div>
      <img
        src="//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/bullet-2.png"
        alt="bullet"
      />
    </Container>
  );
};

const Container = styled.div`
  height: 34.5rem;
  position: relative;
  flex-shrink: 0;

  & .inner-layout {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    align-content: flex-end;
    grid-gap: 1.25rem;
    height: 34.5rem;
    position: relative;
    padding: 0 0 4rem;

    & > img {
      width: 31%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -100%, 0);
      right: 0;
    }

    & .title-wrapper {
      &:before {
        content: '';
        width: 14%;
        height: 100%;
        float: left;
        shape-outside: polygon(100% 0, 1% 50%, 50% 100%, 0 100%, 0 0);
      }

      & h2 {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2.1875rem;
        text-align: center;
        letter-spacing: 0.54px;
        color: var(--color-dark-100);
        height: 100%;

        &:before {
          content: '';
          width: 14%;
          height: 100%;
          float: right;
          shape-outside: polygon(100% 0, 100% 100%, 50% 100%, 100% 50%, 0 0);
        }

        @media screen and (min-width: 375px) {
          font-size: 1.875rem;
        }
      }
    }

    & .prize-container {
      display: grid;

      & span {
        color: var(--color-dark-100);
        text-align: center;

        &:first-of-type {
          font-size: 0.875rem;
          line-height: 1.625rem;
          letter-spacing: 0.54px;
        }

        &:last-of-type {
          font-weight: 900;
          font-size: 4rem;
          line-height: 4.625rem;
          letter-spacing: 0.54px;
        }
      }
    }

    &:before {
      content: '';
      background: url('//d2lomvz2jrw9ac.cloudfront.net/common/leaders-race-2021/mobile-top-background.png')
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  & > img {
    width: 31%;
    position: absolute;
    bottom: 0;
    transform: translate3d(0, 61%, 0);
    left: 26px;
  }
`;
