import {
  IFeeItem,
  IOutputAmount,
  IWithdrawalCurrency,
  IWithdrawalMethod,
} from './reducers.withdrawal.interface';

export enum withdrawalActionsTypes {
  WITHDRAWAL_ATTACH_LISTENERS = 'app/withdrawal/WITHDRAWAL_ATTACH_LISTENERS',
  WITHDRAWAL_DETACH_LISTENERS = 'app/withdrawal/WITHDRAWAL_DETACH_LISTENERS',

  UPDATE_REDIRECT_URL = 'app/withdrawal/UPDATE_REDIRECT_URL',

  UPDATE_FEE = 'app/withdrawal/UPDATE_FEE',

  UPDATE_INPUT_AMOUNT = 'app/withdrawal/UPDATE_INPUT_AMOUNT',
  UPDATE_OUTPUT_AMOUNT = 'app/withdrawal/UPDATE_OUTPUT_AMOUNT',

  CREATE_PAYOUT_ORDER = 'app/withdrawal/CREATE_PAYOUT_ORDER',
  UPDATE_PAYOUT_ORDER = 'app/withdrawal/UPDATE_PAYOUT_ORDER',
  UPDATE_PAYOUT_ORDER_FEE = 'app/withdrawal/UPDATE_PAYOUT_ORDER_FEE',
  CLEANUP_PAYOUT_ORDER = 'app/withdrawal/CLEANUP_PAYOUT_ORDER',
  UPDATE_PAYOUT_ORDER_STATUS = 'app/withdrawal/UPDATE_PAYOUT_ORDER_STATUS',

  UPDATE_PAYOUT_METHODS = 'app/withdrawal/UPDATE_PAYOUT_METHODS',

  UPDATE_PAYOUT_CURRENCY = 'app/withdrawal/UPDATE_PAYOUT_CURRENCY',

  ADD_RATE = 'app/withdrawal/ADD_RATE',
  REQUEST_RATE = 'app/withdrawal/REQUEST_RATE',
}

interface IUpdateRedirectUrl {
  type: typeof withdrawalActionsTypes.UPDATE_REDIRECT_URL;
  payload: {
    url: string;
  };
}

interface IUpdateFee {
  type: typeof withdrawalActionsTypes.UPDATE_FEE;
  payload: {
    fee: Record<string, IFeeItem>;
    input: number;
  };
}

interface IUpdateInputAmount {
  type: typeof withdrawalActionsTypes.UPDATE_INPUT_AMOUNT;
  payload: {
    input: number;
    method: string;
  };
}

interface IUpdateOutputAmount {
  type: typeof withdrawalActionsTypes.UPDATE_OUTPUT_AMOUNT;
  payload: IOutputAmount;
}

interface ICreatePayoutOrder {
  type: typeof withdrawalActionsTypes.CREATE_PAYOUT_ORDER;
}

interface IUpdatePayoutOrder {
  type: typeof withdrawalActionsTypes.UPDATE_PAYOUT_ORDER;
  payload: {
    order: Record<string, string>;
  };
}

interface IUpdatePayoutOrderFee {
  type: typeof withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_FEE;
  payload: {
    feeIndex: number;
    method: string;
  };
}

interface IUpdatePayoutOrderStatus {
  type: typeof withdrawalActionsTypes.UPDATE_PAYOUT_ORDER_STATUS;
  payload: {
    status: Record<string, string | number>;
  };
}

interface IUpdateConfig {
  type: typeof withdrawalActionsTypes.UPDATE_PAYOUT_METHODS;
  payload: {
    methods: IWithdrawalMethod[];
  };
}

interface ICleanUpOrder {
  type: typeof withdrawalActionsTypes.CLEANUP_PAYOUT_ORDER;
}

interface IUpdatePayoutCurrency {
  type: typeof withdrawalActionsTypes.UPDATE_PAYOUT_CURRENCY;
  payload: {
    currency: IWithdrawalCurrency[];
  };
}
interface IAddRate {
  type: typeof withdrawalActionsTypes.ADD_RATE;
  payload: {
    rate: string;
  };
}
interface IRequestRate {
  type: typeof withdrawalActionsTypes.REQUEST_RATE;
}

export type withdrawalActions =
  | IUpdateRedirectUrl
  | IUpdateFee
  | IUpdateInputAmount
  | IUpdateOutputAmount
  | ICreatePayoutOrder
  | IUpdatePayoutOrder
  | IUpdatePayoutOrderFee
  | IUpdatePayoutOrderStatus
  | IUpdateConfig
  | ICleanUpOrder
  | IUpdatePayoutCurrency
  | IAddRate
  | IRequestRate;
