export default {
  'Fair game': 'การที่ยุติธรรม',
  Bet: 'เดิมพัน',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = ปิดใช้งาน)',
  'Bet coins': 'เดิมพันเหรียญ',
  Stop: 'สต๊อป',
  'Wait for next round...': 'โปรดรอเกมถัดไป',
  Example: 'อย่างเช่น',
  'You won': 'คุณชนะแล้ว',
};
