import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CoinInput, CurrencyInputRange, CurrencyText } from '@components/currency';
import { useGetRate, usePayoutAmount } from '../../hooks';
import { IWithdrawalGate } from '../../interfaces/reducers.withdrawal.interface';
import { useUser } from 'core/User';
import { getMinimalMaxValue } from '../utils';

interface IPayoutAmount {
  minValue: IWithdrawalGate['minimum'];
}

export const PayoutAmount: FC<IPayoutAmount> = ({ minValue }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = usePayoutAmount();
  const { user } = useUser();
  const { rate } = useGetRate();
  const { minimum, maximum } = getMinimalMaxValue(user.coins, minValue, +rate.data);

  useEffect(() => {
    setAmount(minimum);
  }, [minimum, setAmount]);

  return (
    <Container>
      <div className="currency-input-container">
        <span className="payout-amount-title">{t('Withdrawal sum')}</span>
        <CoinInput value={amount} setValue={setAmount} min={minimum} max={maximum} />
        <span className="payout-amount-limit">
          {t('from')} <CurrencyText value={minimum} /> {t('to')} <CurrencyText value={maximum} />
        </span>
      </div>
      <CurrencyInputRange
        value={amount}
        setValue={setAmount}
        minValue={minimum}
        maxValue={maximum}
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  padding: 0 1rem;

  & .currency-input-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1rem min-content 1rem;
    grid-gap: 0.5rem;

    & .payout-amount-title {
      align-self: self-end;
      text-transform: uppercase;
    }

    & .payout-amount-title,
    & .payout-amount-limit {
      color: var(--color-white-600);
      font-size: 0.75rem;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      align-self: center;
    }

    & .payout-amount-limit {
      font-size: 0.625rem;
      font-weight: bold;
    }
  }

  & .currency-range-container {
  }
`;
