import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import { RangeInputControl } from '@UIkit/form-controls';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { IDialogComponent } from 'core/interfaces';
import { useSalesElement } from '../../../hooks';
import { ISalesInventoryElement } from '../../../interfaces';
import { skinItemImage } from 'core/utils/skinItemImage.utils';
import { skinStickerImage } from 'core/utils/skinStickerImage.utils';

interface ISaleConfigurationDialog extends IDialogComponent<{ id: string }> {}

export const SaleConfigurationDialog: FC<ISaleConfigurationDialog> = ({
  handleCloseModal,
  data: { id },
}) => {
  const { t } = useTranslation('marketplace');
  const { item, handleUpdateElement } = useSalesElement<ISalesInventoryElement>(id);
  const { end, start } = item.priceRange;

  const { control, register, watch, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      price: item.selling.price,
      tradesDuration: item.selling.tradesDuration,
    },
  });

  const onSubmit = useCallback(
    ({ steamInventoryId }: { steamInventoryId: number }) => ({
      price,
      tradesDuration,
    }: {
      price: string;
      tradesDuration: string;
    }) => {
      handleUpdateElement({
        steamInventoryId,
        price: +price,
        tradesDuration: +tradesDuration,
      });
      handleCloseModal();
    },
    [handleCloseModal, handleUpdateElement]
  );

  const duration = watch('tradesDuration');
  const customPrice = watch('price');

  const newPrice = customPrice - item.price;

  const percent = parseInt(((100 * newPrice) / item.price).toString());

  return (
    <Container>
      <Button className="link-light close-dialog" onClick={handleCloseModal}>
        {t('close')}
      </Button>
      <div className="stickers">
        {item.stickers.map(({ icon }: { icon: string }, idx: number) => (
          <img key={idx} src={skinStickerImage(icon)} alt="" />
        ))}
      </div>
      <div className="header-dialog">{t('Place a bet')}</div>
      <div className="thumbnail">
        <img
          srcSet={`${skinItemImage(item?.baseItem.icon, 112, 80)} 320w, ${skinItemImage(
            item?.baseItem.icon,
            224,
            160
          )} 375w`}
          src={skinItemImage(item?.baseItem.icon, 112, 80)}
          alt=""
        />
      </div>
      <div className="detailed">
        <span className={`detailed-float ${getExteriorByFloat(item.float)}`}>
          {item.float?.toFixed(4)}
        </span>
        <span className="detailed-name">{item?.baseItem?.name}</span>
        <span className="detailed-skin">{item?.baseItem?.shortName}</span>
        <span className="detailed-price">
          <CurrencyText value={customPrice} />
        </span>
        <span className="detailed-steam-price">
          {customPrice !== item.price && (
            <>
              <span>{t('Steam price')}</span>
              <span
                className={`${Number(percent) <= -5 && 'underprice'} ${
                  Number(percent) > 5 && 'overprice'
                }`}
              >
                {customPrice > item.price && '+'}
                <CurrencyText value={newPrice} />
                {' | '}
                {percent}%
              </span>
            </>
          )}
        </span>
      </div>
      <div className="range-slider">
        <Controller
          name="price"
          control={control}
          render={field => (
            <RangeInputControl
              field={field}
              min={start}
              max={end}
              initial={item.selling.price}
              step={1}
            />
          )}
        />
      </div>
      <div className="controllers">
        <Button className="primary" onClick={handleSubmit(onSubmit(item))}>
          {t('Confirm')}
        </Button>
        <Button as="label" className="secondary trade-duration">
          <div className="multi-line">
            <span>{t('sell')}</span>
            <span>
              {duration}{' '}
              {t('pluralize time.hours', {
                postProcess: 'interval',
                count: Number(duration),
              })}
              <input type="text" ref={register} name="tradesDuration" readOnly />
            </span>
            <span className="arrows" />
          </div>
          <select name="tradesDuration" ref={register}>
            <option value="1">
              1 {t('pluralize time.hours', { postProcess: 'interval', count: 1 })}
            </option>
            <option value="6">
              6 {t('pluralize time.hours', { postProcess: 'interval', count: 6 })}
            </option>
            <option value="12">
              12{' '}
              {t('pluralize time.hours', {
                postProcess: 'interval',
                count: 12,
              })}
            </option>
          </select>
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1.5rem 6rem 1fr 3.25rem 3.125rem;
  grid-gap: 1rem;
  padding: 1.75rem 1rem 1.25rem;
  height: 29rem;
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  position: relative;

  & .close-dialog {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    text-transform: none;
    font-weight: normal;
  }

  & .stickers {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1.875rem;
    grid-auto-rows: 1.875rem;
    grid-gap: 1.75rem;
    position: absolute;
    left: 1rem;
    top: 5.6rem;

    & img {
      width: 1.875rem;
      height: 1.875rem;
      object-fit: contain;
    }
  }

  & .header-dialog {
    justify-self: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--color-white);
  }

  & .thumbnail {
    justify-self: center;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .detailed {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1rem 1.125rem 1.3125rem 2rem 1.125rem;
    grid-gap: 0.125rem;
    justify-content: center;
    padding: 0.875rem 0 0;

    &-float,
    &-name,
    &-skin,
    &-price {
      align-self: center;
      text-align: center;
      letter-spacing: 0.1px;
      font-size: 0.75rem;
      line-height: 0.9375rem;
      color: var(--color-white);
    }

    &-float {
      &.factory-new {
        color: var(--color-exterior-factory-new);
      }

      &.minimal-wear {
        color: var(--color-exterior-minimal-wear);
      }

      &.field-tested {
        color: var(--color-exterior-field-tested);
      }

      &.well-worn {
        color: var(--color-exterior-well-worn);
      }

      &.battle-scarred {
        color: var(--color-exterior-battle-scarred);
      }
    }

    &-skin {
      font-size: 1.125rem;
      line-height: 1.3125rem;
    }

    &-price {
      align-self: self-end;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.4375rem;
      letter-spacing: 0.69px;
    }

    &-steam-price {
      justify-self: center;
      align-self: center;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.25rem;

      & > span {
        justify-self: self-end;
        font-size: 0.75rem;
        line-height: 0.9375rem;
        letter-spacing: 0.1px;
        color: var(--color-gray-200);

        &:last-of-type:not(:first-of-type) {
          justify-self: self-start;
          font-weight: bold;
          color: var(--primary);

          &.overprice {
            color: var(--danger);
          }

          &.underprice {
            color: var(--green-100);
          }
        }
      }
    }
  }

  & .range-slider {
    align-self: center;
  }

  & .controllers {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 0.64fr 0.36fr;
    background-color: var(--color-dark-200);
    border-radius: var(--size-radius-m);

    & .trade-duration {
      padding: 0.5rem 1.125rem;
      position: relative;

      & select {
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      & .multi-line {
        grid-gap: 0.25rem;
        width: 100%;

        & span:not(.arrows) {
          text-align: left;
          font-weight: bold;
          font-size: 0.625rem;
          line-height: 0.75rem;
          letter-spacing: 0.3px;
          color: var(--color-white);
          text-transform: none;

          &:last-of-type {
            font-size: 0.75rem;
            line-height: 1.5rem;
            letter-spacing: 0.24px;
          }
        }
      }
      & .arrows {
        width: 0.625rem;
        position: absolute;
        right: 0.8rem;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        color: var(--color-white);

        &:after,
        &:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid;
        }

        &:before {
          transform: translate(0, 7px);
        }

        &:after {
          transform: rotate(180deg) translate(0, 7px);
        }
      }
      & input {
        display: none;
      }
    }
  }
`;
