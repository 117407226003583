import React from 'react';
import { FreeOpenButton } from './components';
import { SellButtonDecorator } from './components/SellButtonDecorator/SellButtonDecorator';

interface FreeOpenProps {
  caseId: string;
  lineCount: number;
  categoryId: number;
}

export const FreeOpen: React.FC<FreeOpenProps> = React.memo(({ lineCount, caseId, categoryId }) => {
  return (
    <>
      <SellButtonDecorator />
      <FreeOpenButton lineCount={lineCount} caseId={caseId} categoryId={categoryId} />
    </>
  );
});
