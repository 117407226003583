import React, { Fragment } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { useMembers } from '../../../hooks';
import { SectorColors } from '../../../utils';
import { MembersGridItem } from './members-grid-item';

export const MembersGrid = () => {
  const { totalSum, members } = useMembers();
  return (
    <Container>
      <div className="grid-header red">
        <span>
          <i className="icon-users" />
        </span>
        <span>
          <CurrencyText value={totalSum[SectorColors.RED]} />
        </span>
      </div>
      <div className="grid-header zero">
        <span>
          <i className="icon-users" />
        </span>
        <span>
          <CurrencyText value={totalSum[SectorColors.ZERO]} />
        </span>
      </div>
      <div className="grid-header black">
        <span>
          <i className="icon-users" />
        </span>
        <span>
          <CurrencyText value={totalSum[SectorColors.BLACK]} />
        </span>
      </div>
      {(Object.keys(members) as SectorColors[]).map(key => (
        <Fragment key={key}>
          {Object.keys(members[key]).map((playerId: any) => (
            <MembersGridItem key={members[key][playerId].id} {...members[key][playerId]} />
          ))}
        </Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 2.75rem;
  grid-gap: 0.125rem 0.25rem;
  padding: 1.75rem 1rem;

  & .grid-header {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 0.25rem;
    align-content: center;
    background: var(--color-dark-200);
    order: -10000;

    &.zero {
      border-top: 0.25rem solid var(--double-zero-stroke);
    }
    &.red {
      border-top: 0.25rem solid var(--double-red-stroke);
    }
    &.black {
      border-top: 0.25rem solid var(--double-black-stroke);
    }

    & span {
      font-size: 0.8125rem;
      line-height: 1rem;
      letter-spacing: 2px;
      color: var(--color-white);
      text-align: center;
      height: 0.875rem;

      &:last-of-type {
        font-weight: bold;
      }

      & i {
        font-size: 0.875rem;
        margin-right: 0.25rem;
      }
    }
  }

  & .red {
    grid-column: 1;
  }

  & .zero {
    grid-column: 2;
  }

  & .black {
    grid-column: 3;
  }
`;
