import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 15px;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Title = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: var(--color-white);
`;
export const CaseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;
