import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CurrencyText } from '../../../@components/currency';

import { getItemUri } from '../../../core/utils/image.utils';
import { textLimit } from '../../../core/utils/text.utils';

import lockIcon from '../assets/lock.svg';
import skinpayIcon from '../assets/skinpay.svg';

export class SkinItem extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    addAction: PropTypes.func.isRequired,
  };

  render() {
    const { icon, name, value, virtual, passed, skinpay, addAction, color } = this.props;
    return (
      <ItemBlock passed={!passed} onClick={addAction} disabled={!passed} rarity={color}>
        {skinpay && <FastIcon src={skinpayIcon} />}
        <ItemIcon src={getItemUri(icon)} />
        {!virtual && <ItemName>{textLimit(name, 10)}</ItemName>}
        <ItemPrice>
          <CurrencyText value={value} />
        </ItemPrice>
        {!passed && <Lock src={lockIcon} />}
      </ItemBlock>
    );
  }
}

const ItemPrice = styled.div`
  text-align: center;
  color: #eef100;
  font-size: 12px;
  width: 100%;
  letter-spacing: 1.1px;
`;

const ItemName = styled.span`
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #9ea7b3;
  line-height: 1.4;
`;

const ItemIcon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 60%;
  object-fit: contain;
`;

const ItemBlock = styled.button`
  display: flex;
  border: 0;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 4px;

  position: relative;

  border-radius: 10px;
  border-top: 2px solid;
  border-color: ${({ rarity }) => rarity || 'transparent'};
  background-color: var(--color-gray-950);

  opacity: ${({ passed }) => (passed ? 0.5 : 1)};
`;

const Lock = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FastIcon = styled.img`
  width: 10px;
  height: 10px;
  object-fit: contain;

  position: absolute;
  top: 5px;
  left: 5px;
`;
