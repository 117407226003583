import React, { FC } from 'react';
import { useGetCaseBattleGameData, useGetRouletteData, useGetTeams } from 'games/CaseBattle/hooks';
import { Container, TeamsContainer } from './Participants.styles';
import { useUser } from 'core/User';
import { calculateGap } from 'games/CaseBattle/utils';
import { ParticipantInfo } from '../ParticipantInfo';
import { GameStatus } from 'games/CaseBattle/constants';
import { RouletteArrowLine } from '../RouletteArrowLine/RouletteArrowLine';
import { TeamsFinalResults } from '../FinalResultComponents/TeamsFinalResults';
import { SingleUserFinalResult } from '../FinalResultComponents/SingleUserFinalResult';

export const Participants: FC = () => {
  const { user } = useUser();

  const {
    data: { game, gameStatus },
  } = useGetCaseBattleGameData();
  const {
    creator_id,
    players,
    players_in_team,
    players_count,
    total_price,
    settings,
    winner_ids,
  } = game;
  const {
    data: { strips, battleType },
  } = useGetRouletteData();
  const isCreator = creator_id === user?.id;
  const isMember = !!players.find(({ user_id }) => user_id === user?.id) && !isCreator;
  const { teams } = useGetTeams();
  const isTeam = players_in_team > 1;

  //TODO: replace current position logic, try to use battleTypes for building players containers
  return (
    <Container
      isTeam={isTeam}
      gap={calculateGap(players_count)}
      playersCount={isTeam ? players_count / players_in_team : players_count}
    >
      {teams.map(({ players, team_id }) => (
        <TeamsContainer key={team_id} playersCount={players_in_team}>
          {players.map(({ playerInfo }, index) => {
            const isReady = !!playerInfo.name;
            const showAwaiting =
              !isMember &&
              isTeam &&
              team_id === 2 &&
              !!isCreator &&
              index === 1 &&
              !players?.[0]?.playerInfo.name;

            return (
              <ParticipantInfo
                playerId={playerInfo.user_id}
                gameStatus={gameStatus}
                gamePrice={total_price}
                isMember={isMember}
                isCreator={isCreator}
                isReady={isReady}
                key={playerInfo.user_id}
                teamNumber={team_id}
                showAwaiting={showAwaiting}
                isJoker={settings.jokerMode}
              />
            );
          })}
        </TeamsContainer>
      ))}
      {gameStatus === GameStatus.finish &&
        (isTeam ? (
          <TeamsFinalResults teams={teams} isJoker={settings.jokerMode} winnerIds={winner_ids} />
        ) : (
          <SingleUserFinalResult
            teams={teams}
            isJoker={settings.jokerMode}
            winnerIds={winner_ids}
          />
        ))}
      {gameStatus !== GameStatus.waiting &&
        gameStatus !== GameStatus.finish &&
        strips.length > 0 && <RouletteArrowLine battleType={battleType} />}
    </Container>
  );
};
