import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';
import { QueryService } from 'services/query';
import { IFiltersRecord } from '../interfaces';

export class ExchangeShopRepository {
  static fetchItems(params: IFiltersRecord): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/inventory/v2/shop${QueryService.paramsToQuery(params)}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchPurchase({
    ids,
    userInventoryIds,
  }: Record<string, number[]>): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/items/purchase`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
        userInventoryIds,
      }),
    });
  }

  // doesn't use
  // static fetchSellout({
  //   ids,
  // }: Record<string, number[]>): Observable<AjaxResponse> {
  //   return ajax({
  //     url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/v2/items/sell`,
  //     method: 'POST',
  //     withCredentials: true,
  //     crossDomain: true,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ ids }),
  //   });
  // }

  static fetchSellAll() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/inventory/v2/items/sell/all`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
