import { IUploadAvatar } from 'modules/PersonalArea/interfaces';
import {
  IBonuses,
  IEmail,
  IPermissions,
  IReferralInfo,
  IRewards,
  IUserExperience,
} from './user.reducer.interface';

export enum userActionsTypes {
  LOGIN = 'app/user/LOGIN',
  LOGOUT = 'app/user/LOGOUT',
  UPDATE_USER = 'app/user/UPDATE_USER',
  UPDATE_USER_LEVEL_UP = 'app/user/UPDATE_USER_LEVEL_UP',

  UPDATE_USER_EMAILS = 'app/user/UPDATE_USER_EMAILS',
  UPDATE_USER_STATS_IS_HIDDEN = 'app/user/UPDATE_USER_STATS_IS_HIDDEN',
  UPDATE_STEAM_HIDDEN = 'app/user/UPDATE_STEAM_HIDDEN',
  UPDATE_EMAIL_SUBSCRIPTION = 'app/user/UPDATE_EMAIL_SUBSCRIPTION',
  UPDATE_USER_BAN_TIME = 'app/user/UPDATE_USER_BAN_TIME',
  UPDATE_USER_BAN_TIME_REQUEST = 'app/user/UPDATE_USER_BAN_TIME_REQUEST',
  UPDATE_USER_EXPERIENCE = 'app/user/UPDATE_USER_EXPERIENCE',
  UPDATE_USER_LEVEL = 'app/user/UPDATE_USER_LEVEL',

  UPDATE_USER_BONUSES = 'app/user/UPDATE_USER_BONUSES',
  TAKE_USER_PROMO_BONUSES = 'app/user/UPDATE_USER_PROMO_TAKE',

  UPDATE_USER_WALLET = 'app/user/UPDATE_USER_WALLET',

  FETCH_BINDING_USER_PROVIDER = 'app/user/FETCH_BINDING_USER_PROVIDER',
  FETCH_BINDING_USER_PROVIDER_FAILURE = 'app/user/FETCH_BINDING_USER_PROVIDER_FAILURE',
  CLEAN_UP_BINDING_USER_PROVIDER = 'app/user/CLEAN_UP_BINDING_USER_PROVIDER',

  USER_AVATAR = 'USER_AVATAR',

  //verification
  FETCH_USER_VERIFICATION = 'app/user/FETCH_USER_VERIFICATION',
  UPDATE_USER_VERIFICATION_STATUS = 'app/user/UPDATE_USER_VERIFICATION_STATUS',

  //permission
  FETCH_USER_PERMISSIONS = 'app/user/FETCH_USER_PERMISSIONS',
  UPDATE_USER_PERMISSIONS = 'app/user/UPDATE_USER_PERMISSIONS',

  //watcher
  WATCH_STEAM_SETTINGS = 'app/user/WATCH_STEAM_SETTINGS',

  // api-key
  FETCH_PATCH_API_KEY = 'app/user/FETCH_PATCH_API_KEY',
  // referral
  ACTION_REFERRAL_USER_INFO_RESPONSE = 'app/user/ACTION_REFERRAL_USER_INFO_RESPONSE',
  ACTION_REFERRAL_USER_INFO_REQUEST = 'app/user/ACTION_REFERRAL_USER_INFO_REQUEST',
}

interface IUserUpdateLevelUpAction {
  type: typeof userActionsTypes.UPDATE_USER_LEVEL_UP;
  status: boolean;
}

interface IUserUpdateAction {
  type: typeof userActionsTypes.UPDATE_USER;
  payload: any;
}

interface IUpdateUserEmailsAction {
  type: typeof userActionsTypes.UPDATE_USER_EMAILS;
  payload: {
    email: IEmail;
  };
}

interface IUpdateUserStatsIsHiddenAction {
  type: typeof userActionsTypes.UPDATE_USER_STATS_IS_HIDDEN;
  payload: { isHidden: boolean };
}

interface IUpdateSteamHiddenAction {
  type: typeof userActionsTypes.UPDATE_STEAM_HIDDEN;
  payload: { isHidden: boolean };
}

interface IUpdateEmailSubscriptionAction {
  type: typeof userActionsTypes.UPDATE_EMAIL_SUBSCRIPTION;
}

interface IUpdateUserBanTimeAction {
  type: typeof userActionsTypes.UPDATE_USER_BAN_TIME;
  payload: number;
}

interface IUpdateUserBanTimeRequestAction {
  type: typeof userActionsTypes.UPDATE_USER_BAN_TIME_REQUEST;
  payload: number;
}

interface IUpdateUserExperienceAction {
  type: userActionsTypes.UPDATE_USER_EXPERIENCE;
  payload: {
    experience: IUserExperience;
    rewards: IRewards;
  };
}

interface IUpdateUserLevelAction {
  type: userActionsTypes.UPDATE_USER_LEVEL;
}

interface IUserLogoutAction {
  type: typeof userActionsTypes.LOGOUT;
}

interface IUserBonuses {
  type: typeof userActionsTypes.UPDATE_USER_BONUSES;
  payload: {
    bonuses: Partial<IBonuses>;
  };
}

interface IUpdateUserWalletAction {
  type: typeof userActionsTypes.UPDATE_USER_WALLET;
  payload: {
    walletType: number;
  };
}

interface IUpdateUserProviders {
  type: typeof userActionsTypes.FETCH_BINDING_USER_PROVIDER_FAILURE;
  payload: {
    status: string | { error: string };
  };
}

interface ICleanUpBindingUserProvider {
  type: typeof userActionsTypes.CLEAN_UP_BINDING_USER_PROVIDER;
  payload: {
    provider: string;
  };
}

interface IFetchUserVerification {
  type: typeof userActionsTypes.FETCH_USER_VERIFICATION;
  payload: {
    file: string;
  };
}

interface IUpdateVerificationStatus {
  type: typeof userActionsTypes.UPDATE_USER_VERIFICATION_STATUS;
  payload: {
    success: boolean;
    status: string;
  };
}

interface IFetchUserPermissions {
  type: typeof userActionsTypes.FETCH_USER_PERMISSIONS;
}

interface IUpdateUserPermission {
  type: typeof userActionsTypes.UPDATE_USER_PERMISSIONS;
  payload: IPermissions;
}
interface ActionReferralUserInfoResponse {
  type: typeof userActionsTypes.ACTION_REFERRAL_USER_INFO_RESPONSE;
  payload: IReferralInfo;
}

interface IFetchUserAvatar {
  type: typeof userActionsTypes.USER_AVATAR;
  payload: IUploadAvatar;
}

export type userActions =
  | IUserUpdateLevelUpAction
  | IUserUpdateAction
  | IUserLogoutAction
  | IUpdateUserEmailsAction
  | IUpdateUserStatsIsHiddenAction
  | IUpdateSteamHiddenAction
  | IUpdateEmailSubscriptionAction
  | IUpdateUserBanTimeAction
  | IUpdateUserBanTimeRequestAction
  | IUpdateUserExperienceAction
  | IUpdateUserLevelAction
  | IUserBonuses
  | IUpdateUserWalletAction
  | IUpdateUserProviders
  | ICleanUpBindingUserProvider
  | IFetchUserVerification
  | IUpdateVerificationStatus
  | IUpdateUserPermission
  | ActionReferralUserInfoResponse
  | IFetchUserAvatar;
