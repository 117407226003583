import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { useSalesCreatePublication } from '../../../../hooks';

type IDataDialog = Record<string, boolean>;

interface PenaltyWarningDialog extends IDialogComponent<IDataDialog> {}

export const PenaltyWarningDialog: FC<PenaltyWarningDialog> = ({ handleCloseModal, data }) => {
  const { t } = useTranslation('marketplace');
  const history = useHistory();
  const { handleCreatePublication } = useSalesCreatePublication();

  const handleConfirm = useCallback(() => {
    handleCreatePublication(data);
    history.replace('/market/orders?type=sell');

    const timer = setTimeout(() => {
      handleCloseModal();
    }, 1000);

    return () => clearTimeout(timer);
  }, [data, history, handleCloseModal, handleCreatePublication]);

  return (
    <Container>
      <div className="dialog-title">{t('Pay attention!')}</div>
      <div className="message">
        {t(
          'You have to send the skins within 30 minutes after purchase otherwise access to the store will be temporarily restricted'
        )}
      </div>
      <div className="controller">
        <Button className="primary" onClick={handleConfirm}>
          {t('Confirm')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
  padding: 3.25rem 1rem 2.25rem;
  background: var(--color-dark-600);
  border-radius: var(--size-radius-m);

  & .dialog-title,
  & .message {
    color: var(--color-white);
    text-align: center;
  }

  & .dialog-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  & .message {
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.24px;
    padding: 0 0.5rem 0.75rem;
  }
`;
