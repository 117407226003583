import { IModule } from 'core/rootInterfaces/root.interface';
export * from './interfaces';

export { GameRouter as BaccaratRouter } from './components';
// export const BaccaratRouter = lazy(() => import('./components').then(module => ({ default: module.GameRouter })))
export const Module: IModule = {
  moduleName: 'baccarat',
  moduleId: 18,
  path: '/baccarat',
  type: 'pve',
  reducer: null,
};
