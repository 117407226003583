import React, { FC } from 'react';
import SoundService from 'services/sound.service';
import { Container } from './ValueSelector.styles';

const sound = new SoundService();

interface IValueSelector {
  value: number;
  maxValue: number;
  minValue: number;
  setValue: (value: number) => void;
  disabled: boolean;
}

export const ValueSelector: FC<IValueSelector> = ({
  value,
  maxValue,
  minValue,
  setValue,
  disabled,
}) => {
  const increment = () => {
    if (value < maxValue) {
      sound.play('Slot', 'Toggle lines', { stop: true });
      setValue(value + 1);
    }
  };

  const decrement = () => {
    if (value > minValue) {
      sound.play('Slot', 'Toggle lines', { stop: true });
      setValue(value - 1);
    }
  };

  return (
    <Container className="value-selector">
      <button type="button" onClick={decrement} disabled={disabled}>
        <i className="icon-minus" />
      </button>
      <span>{value}</span>
      <button type="button" onClick={increment} disabled={disabled}>
        <i className="icon-plus" />
      </button>
    </Container>
  );
};
