import * as React from 'react';
import styled from 'styled-components';

export default class HistoryItem extends React.PureComponent {
  render() {
    return <ItemContainer>{this.props.coef}x</ItemContainer>;
  }
}

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 60px;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 0 10px;

  position: relative;
  font-size: 14px;
  color: var(--color-gray-90);
  border-right: 2px solid var(--gray-100);
`;
