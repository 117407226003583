import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { Icon } from '@components/Common';

const { useState } = React;

const sizeConfig: { [key: string]: number } = {
  1: 240, //Classic
  2: 240, // Fast
  3: 110, // Double
  7: 110, // Crash
  0: 110, // default key
};

interface IHistoryTrigger {
  gameTypeId: string;
}

export const HistoryTrigger: React.FC<IHistoryTrigger> = props => {
  const [open, toggle] = useState(false);

  const springProps = useSpring({
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
    maxHeight: open ? 700 : sizeConfig[props.gameTypeId],
  });

  return (
    <AnimatedContainer style={springProps}>
      <Wrapper>{props.children}</Wrapper>
      <ToggleIcon open={open} onClick={() => toggle(!open)}>
        <Icon name="arrow" />
      </ToggleIcon>
    </AnimatedContainer>
  );
};

interface IToggleIconStyle {
  open: boolean;
}

const AnimatedContainer = styled(animated.div)`
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  margin: 0.5rem 1rem;
  padding: 1rem 0 3rem;
  position: relative;
  background: var(--gray-400);

  &:first-child {
    margin-top: 1rem;
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  &:after,
  &:before {
    content: '';
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  &:after {
    right: 0;
    background-image: linear-gradient(to left, #212f35, rgba(33, 47, 53, 0.01));
  }

  &:before {
    left: 0;
    background-image: linear-gradient(to right, #212f35, rgba(33, 47, 53, 0.01));
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

const ToggleIcon = styled.div<IToggleIconStyle>`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;
  transform: ${p => (p.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transform-origin: center;
  transition: transform 0.3s linear;
  color: var(--color-white);
`;
