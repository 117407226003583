import styled from 'styled-components';
import { IAvatarImageStyles } from './interfaces';

export const AvatarImage = styled.div<IAvatarImageStyles>`
  width: 33vw;
  height: 33vw;
  position: relative;
  z-index: 10;

  &:before {
    content: '';
    width: 110%;
    height: 110%;
    position: absolute;
    left: -5%;
    top: -5%;
    border-radius: 50%;
    border: 3px solid ${p => `var(--color-account-type-${p.profileType})`};
  }
`;

export const UserProfileInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.45fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.3rem;
  flex-shrink: 0;
  margin-top: -1rem;
  padding: 0 1rem;
  z-index: 1;

  font-size: 0.8rem;
  color: var(--color-white);
  text-transform: uppercase;

  & span {
    letter-spacing: 0.54px;
    align-self: center;
  }

  & span:nth-child(2n + 2) {
    color: var(--color-gray-100);
  }

  & span:nth-child(3n + 3) {
    text-align: end;
  }

  & span:nth-child(1),
  & span:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 700;
  }
`;

interface IUserStyle {
  profileType: string;
  exp?: number;
}

export const UserDescription = styled.div<IUserStyle>`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.2rem;
  flex-shrink: 0;
  margin: 1.5rem 0 0;
  padding: 0.7rem 1rem;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--color-gray-900);
  border-left: 0;
  border-right: 0;

  &:after,
  &:before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    z-index: -2;
    width: 100%;
    background: var(--dark);
  }

  &:before {
    width: ${p => p.exp}%;
    background-color: var(--color-white);
    opacity: 0.05;
    z-index: -1;
  }

  & span {
    max-width: 100%;
    font-size: 1rem;
    color: var(--color-white);
    align-self: center;
  }

  & > span:nth-child(even) {
    text-align: end;
  }

  & > span:nth-child(odd) {
    justify-self: self-start;
  }

  & .user-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .user-experience {
    font-size: 0.8rem;
    color: var(--color-gray-100);
  }

  & .user-account-type {
    font-size: 0.8rem;
    font-weight: 700;
  }

  & .user-account-type,
  & .user-account-bonus {
    color: ${p => `var(--color-account-type-${p.profileType})`};
    text-transform: uppercase;
  }

  & .user-account-bonus {
    font-size: 0.8rem;
  }
`;

export const UserProfilePanel = styled.button<IUserStyle>`
  display: flex;
  flex-flow: row nowrap;
  padding: 0.8rem;
  width: 100%;
  height: 62px;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--color-gray-900);

  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  &:active {
    background: var(--color-gray-300);

    &:before {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    min-width: ${p => p.exp || 0}%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--color-white);
    opacity: 0.05;
  }

  & .user-avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 30px;
    flex-shrink: 0;
    margin-right: 0.6rem;

    & > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-indent: -9999px;
    }
  }

  & > .user-info {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 1fr 1fr;
    flex-basis: 100%;

    & > span {
      font-weight: 500;
      align-self: center;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & .user-name,
    .user-lvl {
      font-size: 0.8rem;
      color: var(--color-white);
    }

    & .user-name,
    & .user-account-type {
      text-align: left;
    }

    & .user-account-type,
    .user-exp {
      font-size: 0.7rem;
      color: var(--color-gray-100);
      text-transform: uppercase;
    }

    & .user-lvl,
    & .user-exp {
      justify-self: end;
    }

    .user-account-type {
      color: ${p => `var(--color-account-type-${p.profileType})`};
    }
  }
  & > .navbar {
    grid-template-columns: 1fr;
  }
`;

export const UserInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.45fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.3rem;
  flex-shrink: 0;
  margin-top: -1rem;
  padding: 0 1rem;
  z-index: 1;

  font-size: 0.8rem;
  color: var(--color-white);
  text-transform: uppercase;

  & span {
    letter-spacing: 0.54px;
    align-self: center;
  }

  & span:nth-child(2n + 2) {
    color: var(--color-gray-100);
  }

  & span:nth-child(3n + 3) {
    text-align: end;
  }

  & span:nth-child(1),
  & span:nth-child(3) {
    font-size: 0.6rem;
    font-weight: 700;
  }
`;
