import styled from 'styled-components';

export const Container = styled.div`
  z-index: 2;
  color: var(--aqua-500);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  transition: opacity 0.3s ease, bottom 0.3s ease;
  position: absolute;

  &[data-status='show'] {
    bottom: -30px;
    opacity: 1;
  }

  &[data-status='hide'] {
    bottom: 0;
    opacity: 0;
  }
`;
