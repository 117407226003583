import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { QueryService } from 'services/query';
import { socketService } from 'services/socket.service';
import { IUpdatedParams, TradeActions } from '../interfaces';

export class StoreRepository {
  static fetchStore(params: IUpdatedParams): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/market/kits${QueryService.paramsToQuery(params)}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static getStoreCount(params: IUpdatedParams): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/market/kits/count${QueryService.paramsToQuery(params)}`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchStoreHotOffers(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/hot`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchSales(): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/my/kits`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchCreateSales(params: Record<string, any>): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/new`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });
  }

  static fetchPauseItems(params: { itemIds: number[] }): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/pause`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });
  }

  static fetchResumeItems(params: { kitIds: number[] }): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/my/resume`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });
  }

  static fetchDeleteSellingItems(id: number): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/${id}`,
      method: 'DELETE',
      withCredentials: true,
      crossDomain: true,
    });
  }

  static fetchTradeActionItem(id: number, tradeAction: TradeActions): Observable<AjaxResponse> {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/market/kits/${id}/${tradeAction}`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
