export default {
  Games: 'Games',
  'recent games': 'Recent games',
  'Refill balance': 'Refill balance',
  Settings: 'Settings',
  Store: 'Store',
  'Get free money': 'Get free money',
  Notifications: 'Notifications',
  FAQ: 'FAQ',
  'Log out': 'Log out',
  'Not Found': 'Not Found',

  'Steam trade link': 'Steam trade link',
  'Where can I get a link?': 'Where can I get a link?',
  'Input your trade link:': 'Input your trade link:',

  'Be sure to': 'Be sure to ',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': ' in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.',
  'App was offline. Check your internet connection to continue!':
    'App was offline. Check your internet connection to continue!',

  SAVE: 'SAVE',
  'PC version': 'PC version',
  'Unfortunately screen rotation is not supported. For the correct display, rotate your device to a vertical position and refresh the page.':
    'Unfortunately screen rotation is not supported. For the correct display, rotate your device to a vertical position and refresh the page.',
  'TRY YOUR LUCK!': 'TRY YOUR LUCK!',
  'counter strike, cs go betting, luck bets, csgo skins, cs go fast, csgofast, gofast, dota 2 betting,csgo gambling,dota2 gambling, match bets':
    'counter strike, cs go betting, luck bets, csgo skins, cs go fast, csgofast, gofast, dota 2 betting,csgo gambling,dota2 gambling, match bets',
  'Service where players can try their luck and get awesome skins! Just deposit your skins to the raffle, become a winner and sweep the board!':
    'Service where players can try their luck and get awesome skins! Just deposit your skins to the raffle, become a winner and sweep the board!',
};
