export const getDayOrTime = (t: number): string => {
  if (t <= 0) return '00:00:00';
  let days = Math.floor(t / 1000 / 86400);

  if (days >= 1) return `${days}`;

  let hours = Math.floor((t / 1000 / 60 / 60) % 60);
  let seconds = Math.floor((t / 1000) % 60);
  let minutes = Math.floor((t / 1000 / 60) % 60);

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

export const timeFormat = (t: string): string => {
  const time = t?.toString();
  if (time?.length < 2) return `0${time}`;
  return time;
};
