import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchPurchaseProduct } from '../../ducks/store.duck';

export const usePurchaseAction = () => {
  const dispatch = useDispatch();

  const handlePurchase = useCallback(
    (itemName: string, appId: number, ticketColor: string, id?: number) => () => {
      dispatch(fetchPurchaseProduct(itemName, appId, ticketColor, id));
    },
    [dispatch]
  );

  return [handlePurchase];
};
