import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCreateBid } from '../duck';
import { IFetchCreateBidRequest } from '../interfaces';

export const useDoubleParticipateController = () => {
  const dispatch = useDispatch();

  const handleCreateParticipate = useCallback(
    (payload: IFetchCreateBidRequest) => () => {
      dispatch(fetchCreateBid(payload));
    },
    [dispatch]
  );

  return {
    handleCreateParticipate,
  };
};
