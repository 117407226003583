import { Reducer } from 'redux';
import { Map } from 'immutable';
import { dialogActions, DialogActionsType, IDialogState } from 'core/interfaces/';

const DialogMap = Map<string, IDialogState>();

export const dialog: Reducer<Map<string, IDialogState>, dialogActions> = (
  state = DialogMap,
  action
) => {
  switch (action.type) {
    case DialogActionsType.DIALOG_ON: {
      const { name } = action.payload;
      return state.setIn([name], true);
    }

    case DialogActionsType.DIALOG_OFF: {
      const { name } = action.payload;
      return state.delete(name);
    }

    case DialogActionsType.DIALOG_CHANGE_STATE: {
      const { name, value } = action.payload;
      return state.setIn([name], value);
    }

    default: {
      return state;
    }
  }
};

export const dialogOn = (name: string) => ({
  type: DialogActionsType.DIALOG_ON,
  payload: {
    name,
  },
});

export const dialogOff = (name: string) => ({
  type: DialogActionsType.DIALOG_OFF,
  payload: {
    name,
  },
});

export const dialogChangeState = (name: string, value: IDialogState) => ({
  type: DialogActionsType.DIALOG_CHANGE_STATE,
  payload: {
    name,
    value,
  },
});
