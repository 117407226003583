import * as React from 'react';
import styled from 'styled-components';
import { PlayerAvatar } from '../../Common';
import { CurrencyText } from '../../currency';
import { IPlayers } from '../interfaces/reducer.history.interface';
import { getPercent } from 'core/utils/percent.util';

interface IUserList {
  items: IPlayers;
  totalPrize: number;
}

export const UserList: React.FC<IUserList> = ({ items, totalPrize }) => (
  <Container>
    {Object.keys(items).map((userId: string) => (
      <UserItem key={userId}>
        <PlayerAvatar uri={items[userId].playerImage} userId={items[userId].playerId} />
        <span>
          {getPercent(items[userId].itemPrice, totalPrize)}
          {'%'}
        </span>
        <CurrencyText value={items[userId].itemPrice} />
      </UserItem>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0.5rem 1.5rem;
`;

const UserItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-shrink: 0;
  padding: 10px;
  width: 80px;

  font-size: 0.9rem;
  letter-spacing: 0.77px;
  line-height: 1.4;
  color: var(--color-white);

  & > div {
    margin-bottom: 0.8rem;
  }
`;
