import styled from 'styled-components';
import { Button } from '@components/Common';

export const Container = styled.div`
  align-items: center;
  background-color: var(--color-dark-600);
  border-radius: var(--size-radius-l);
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 16px;
  padding: 24px;

  & .colored {
    opacity: 0.25;
  }
`;

export const BlockedTitle = styled.p`
  color: var(--color-white);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;

  & span {
    color: #ffd119;
    font-weight: 700;
  }
`;

export const ProgressInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Find = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
`;

export const StyledButton = styled(Button)`
  background-color: #ffd11a;
  color: #0c0c10;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  max-width: 300px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
`;

export const PrizeTitle = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;

export const ImageWrap = styled.div`
  align-items: center;
  background-color: #29343a;
  border-radius: var(--size-radius-m);
  display: flex;
  height: 100px;
  justify-content: center;
  width: 80px;

  & > img {
    padding: 3px;
  }
`;

export const ReplayWrap = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`;

export const Replay = styled.span`
  color: #b4c5cd;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;
