import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import NotFoundImg from '../../assets/not-found.png';

class _NotFound extends Component {
  render() {
    const { t } = this.props;
    return (
      <NotFoundBlock>
        <Icon src={NotFoundImg} />
        <Title>{t('Not found')}</Title>
        <Text>
          {t('At present it is unable to find any items on your request. Try again later.')}
        </Text>
      </NotFoundBlock>
    );
  }
}

export const NotFound = withTranslation()(_NotFound);

const NotFoundBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: calc(100vh - 91px);
  text-align: center;
`;

const Icon = styled.img`
  width: 100px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  padding: 2px;
  color: var(--color-white);
  font-size: 16px;
  font-weight: bold;
`;

const Text = styled.div`
  padding: 2px 1rem;
  color: var(--color-white);
  font-size: 14px;
`;
