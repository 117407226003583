import React, { Component } from 'react';
import { getTypeImageHistory } from '../utils';
import styled from 'styled-components';
import { textLimit } from '../../../core/utils/text.utils';
import { withTranslation } from 'react-i18next';
import { CurrencyText } from '../../../@components/currency';
import { BigPreloader } from '../../../@components/Preloader';
import { getTowerLeaders, getTowerTypes } from '../selectors';
import { connect } from 'react-redux';
import { getLeaders } from '../duck';
class Top extends Component {
  componentDidMount() {
    this.props.getLeaders();
  }

  render() {
    const { items, t } = this.props;

    if (items.length === 0) {
      return (
        <div className="content">
          <BigPreloader />
        </div>
      );
    }

    return (
      <div className="content">
        <Tower>
          <table border="1" width="100%">
            <thead>
              <TheadTr>
                <TheadTdDate>{t('Player')}</TheadTdDate>
                <TheadTd>{t('Bet')}</TheadTd>
                <TheadTd>{t('Profit')}</TheadTd>
                <TheadTd>{t('Step')}</TheadTd>
              </TheadTr>
            </thead>
            <tbody>
              {items.map(item => {
                const type = this.props.types.find(t => t.id === item.ticket_type_id);
                const typeName = type && type.title;
                return (
                  <TbodyTr key={item.user_id}>
                    <TbodyTd>
                      <DateDiv>
                        {' '}
                        <Icon src={getTypeImageHistory(typeName)} />
                        {textLimit(item.username, 7)}
                      </DateDiv>
                    </TbodyTd>
                    <TbodyTd>
                      {' '}
                      <CurrencyText value={item.price} />
                    </TbodyTd>
                    <TbodyTd>
                      <Prize theme={{ color: '#00d330' }}>
                        {' '}
                        <CurrencyText value={item.prize} />{' '}
                      </Prize>
                    </TbodyTd>
                    <TbodyTd>{item.path.length}</TbodyTd>
                  </TbodyTr>
                );
              })}
            </tbody>
          </table>
        </Tower>
      </div>
    );
  }
}

const Tower = styled.div`
  color: #fff;
  margin-top: 3px;
  background: var(--color-light-dark-layout);
`;

const TheadTr = styled.tr`
  font-weight: 700;
  background: #000;
  text-align: center;
  font-size: 13px;
  color: #a9a7a7;
`;
const TheadTd = styled.td`
  padding: 20px 0px 13px 0px;
`;

const TheadTdDate = styled.td`
  padding: 20px 0px 13px 11px;
  text-align: left;
`;

const TbodyTr = styled.tr`
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #000;
`;

const TbodyTd = styled.td`
  padding: 15px 0px 5px 0px;
`;

const DateDiv = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  margin-top: -6px;
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: 0px 8px 0px 8px;
`;

const Prize = styled.div`
  color: ${props => props.theme.color};
`;

export const Tops = connect(
  state => ({
    types: getTowerTypes(state),
    items: getTowerLeaders(state),
  }),
  {
    getLeaders,
  }
)(withTranslation()(Top));
