import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUserWallet, useUserWalletController } from '../../hooks';

export const SwitchBalance = () => {
  const { walletType } = useUserWallet();
  const { handleChangeWallet } = useUserWalletController();
  const { t } = useTranslation();

  return (
    <Container>
      <input
        type="checkbox"
        name="walletswitch"
        id="walletswitch"
        checked={!!walletType}
        onChange={handleChangeWallet}
      />
      <label htmlFor="walletswitch">
        <span className="walletswitch-inner">{t('wallet-cash')}</span>
        <span className="walletswitch-switch">{t('wallet-demo')}</span>
      </label>
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem 1rem 1rem 3.2rem;
  padding: 0.12rem;
  background: #213137;
  border-radius: 2px;

  position: relative;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;

    &:checked + label {
      & span {
        &:first-of-type {
          color: var(--color-white);
        }
      }

      &:before {
        left: 0%;
      }
    }

    &:not(:checked) + label span:last-of-type {
      color: var(--color-white);
    }
  }
  & label {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    position: relative;
    background: #213137;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      background: #47555f;
      transition: left var(--transition-ease-out);
      z-index: 4;
    }

    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.625rem;
      background: transparent;
      border: 0;
      border-radius: 2px;
      z-index: 5;
      color: var(--color-white-600);
      font-size: 0.65rem;
    }
  }
`;
