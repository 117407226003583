import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { Prize } from '@components/Prize';
import { LotteryPage } from '@components/Histories';
import { GamePage, HistoryPage, RulesPage, TopsPage } from './Pages';

export const GameRouter: FC<RouteComponentProps> = ({ match }) => (
  <>
    <Prize gameTypeId="inventory" />
    <AnimatedRouter>
      <Route path={`${match.url}/rules`} component={RulesPage} />
      <Route path={`${match.url}/history`} component={HistoryPage} />
      <Route path={`${match.url}/top`} component={TopsPage} />
      <Route path={`${match.url}/lottery`} component={LotteryPage} />
      <Route path={match.url} component={GamePage} />
    </AnimatedRouter>
  </>
);
