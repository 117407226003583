import React from 'react';
import { AppBar } from '@components/bars';
import { useAccountProfile, useAccountStatistic } from '../../hooks';
import { UserGameActivities, UserStatisticChartDoughnut } from '@components/UserProfile';

export const AccountStatistics = () => {
  const { statistic } = useAccountStatistic();
  const { profile } = useAccountProfile();

  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <UserGameActivities activity={profile.activity} />
        <UserStatisticChartDoughnut
          totalGameSum={statistic.charts.totalGameSum}
          //@ts-ignore
          totalGamePlayed={statistic.charts.totalGamePlayed}
        />
      </div>
    </>
  );
};
