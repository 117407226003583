import React, { FC, memo } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

interface IRadialProgressBar {
  finishAt: number;
  duration: number;
}

interface IRadialProgressBarStyle {
  duration: number;
  diff: number;
}

const circumference = 2 * Math.PI * 26;

export const RadialProgressBar: FC<IRadialProgressBar> = memo(({ finishAt, duration }) => {
  const diff = dayjs.duration(dayjs(finishAt).diff(Date.now())).asMilliseconds();
  return (
    <Container width="56px" height="56px" viewBox="0 0 56 56" duration={duration} diff={diff}>
      <circle
        style={{ animationDuration: `${diff}ms` }}
        fill="none"
        cx="28px"
        cy="28px"
        r="26px"
        strokeWidth="2px"
        stroke="#47555F"
        strokeDasharray={`${circumference}, ${circumference}`}
      />
    </Container>
  );
});

const Container = styled.svg<IRadialProgressBarStyle>`
  position: absolute;
  top: -4px;
  right: 0;
  bottom: 0;
  left: -4px;

  & > circle {
    transform-origin: center;
    transform: rotate(-90deg);
    animation: ${({ duration }) => `radial-progress-keyframes ${duration}ms linear forwards`};

    @keyframes radial-progress-keyframes {
      from {
        stroke-dashoffset: ${({ diff, duration }) =>
          `-${circumference - circumference * (diff / duration)}`};
      }
      to {
        stroke-dashoffset: -${circumference};
      }
    }
  }
`;
