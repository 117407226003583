import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { nicknameBonuses } from '../../personalArea.utils';

interface INicknameBonus {
  nicknameBonus: number | null;
}

export const NicknameBonus: FC<INicknameBonus> = ({ nicknameBonus }) => {
  const { t } = useTranslation();
  return (
    <Container>
      {nicknameBonuses.map((item, i) => (
        <div
          key={item.day}
          className="day"
          style={{
            opacity: nicknameBonus ? (nicknameBonus >= item.percent ? 1 : 0.15) : 1,
          }}
        >
          <span
            style={{
              color: `var(--color-child-${i})`,
            }}
          >
            {item.percent}%
          </span>

          <span>
            {item.day}{' '}
            {t('pluralize-days', {
              count: item.day,
              postProcess: 'with-template',
            })}
          </span>
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  --color-child-0: #ffffff;
  --color-child-1: #83ebfd;
  --color-child-2: #83ebfd;
  --color-child-3: #43e0fb;
  --color-child-4: #00bad9;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 35px;

  & .day {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    & > span:first-child {
      font-size: 1.1rem;
      font-weight: bold;
      letter-spacing: 0.4px;
    }

    & > span:last-child {
      font-size: 0.7rem;
      letter-spacing: 0.4px;
      color: var(--color-gray-100);
    }
  }
`;
