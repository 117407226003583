import { useSelector, shallowEqual } from 'react-redux';
import { getAllRaffles } from '../selectors';

export const useRaffleFacade = () => {
  const raffles = useSelector(getAllRaffles, shallowEqual);

  return {
    raffles,
  };
};
