export default {
  //CryptoPage title
  crypto: 'Uttagskrypto',
  cryptocurrency: 'Kryptovaluta',
  from: 'från',
  //
  'Email is required': 'Behöver e-post',
  Withdraw: 'Dra tillbaka',
  'Withdrawal sum': 'Uttagssumma',
  'Amount in {{title}}:': 'Belopp i $t({{title}}):',
  'Receive in {{title}}:': 'Få i $t({{title}}):',
  'You will receive:': 'Du kommer att få:',
  'Select the network fee': 'Välj nätavgiften',
  'Commission:': 'Provision:',
  'Ultra Low': 'Väldigt långsam',
  Low: 'Långsam',
  Medium: 'Standart',
  High: 'Snabb',
  'No commission': 'Ingen provision',
  commission: 'Eventuell avgift',
};
