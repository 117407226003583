import * as React from 'react';
import styled from 'styled-components';
import { IOption } from '@components/Selects';

export const Option: React.FC<IOption> = ({ item, selected }) => (
  <OptionContainer>
    <div className="item-leading">
      <i className={`icon-${item.short_name}`} />
    </div>
    <div className="item-description">{item.name}</div>
    <div className="item-select">{selected && <i className="icon-checked" />}</div>
  </OptionContainer>
);

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 25px;
  grid-gap: 0.9rem;
  width: 100%;
  height: 62px;
  padding: 1rem;

  font-size: 1rem;
  color: var(--color-white);

  border-bottom: solid 1px var(--color-dark-350);

  & > div {
    align-self: center;

    &:not(.item-description) {
      justify-self: center;
    }

    &.item-description {
      font-size: 0.9em;
    }
  }

  & i {
    font-size: 1.35em;

    &.icon-checked {
      font-size: 1em;
      color: var(--aqua-500);
    }
  }
`;
