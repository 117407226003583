import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInventoryFilterByKey } from '../../selectors';
import { updateFastInventoryFilter } from '../../ducks/fast-inventory.duck';
import { MergeType } from '../../interfaces';

export const useFastInventorySortFilter = () => {
  const values = useSelector(getUserInventoryFilterByKey('sortBy'));
  const dispatch = useDispatch();

  const changeSort = useCallback(
    (value: string) => () => {
      dispatch(updateFastInventoryFilter(['sortBy'], value, MergeType.RESET));
    },
    [dispatch]
  );

  return {
    values,
    changeSort,
  };
};
