import {
  ActionRequestFreeCaseInfo,
  ActionResponseFreeCaseInfo,
  FreeCaseAction,
  IFreeCaseInfo,
} from '../interfaces/free-case-info.interfaces';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import casesRepository from 'games/CaseGame/repository/casesRepository';
import { addNotification } from 'modules/Notifications/duck';
import { of } from 'rxjs';
import { CaseList } from 'games/CaseGame/interfaces/cases.interface';

export const actionRequestFreeCaseInfo = () => ({
  type: FreeCaseAction.ACTION_REQUEST_FREE_CASE_INFO,
});

export const actionResponseFreeCaseInfo = (data: CaseList) => ({
  type: FreeCaseAction.ACTION_RESPONSE_FREE_CASE_INFO,
  payload: data,
});

export const attachExchange = () => ({
  type: FreeCaseAction.FREE_CASE_INFO_ATTACH_LISTENERS,
});

export const detachExchange = () => ({
  type: FreeCaseAction.FREE_CASE_INFO_DETACH_LISTENERS,
});

export const freeCaseInfoEpic: Epic = (action$: ActionsObservable<ActionRequestFreeCaseInfo>) =>
  action$.pipe(
    ofType(FreeCaseAction.ACTION_REQUEST_FREE_CASE_INFO),
    switchMap(() => {
      return casesRepository.fetchGetCases(null).pipe(
        map(({ response }: { response: CaseList }) => actionResponseFreeCaseInfo(response)),
        catchError(({ response }) =>
          of(addNotification({ type: 'error', body: response?.message }))
        )
      );
    })
  );

export const freeCaseInfo = (
  state: IFreeCaseInfo = { count: 0, price: 0, loaded: false },
  action: ActionResponseFreeCaseInfo
) => {
  switch (action.type) {
    case FreeCaseAction.ACTION_RESPONSE_FREE_CASE_INFO: {
      const freeCaseInfo = Object.values(action.payload).filter(value => value.free);

      if (freeCaseInfo?.length > 0) {
        const freeCaseInfoNormalizedData = freeCaseInfo
          .map(value => {
            return {
              count: value.free.count,
              price: value.lastRevision.price * value.free.count,
            };
          })
          .reduce(
            (acc, curr) => {
              acc.count += curr.count;
              acc.price += curr.price;
              return acc;
            },
            { count: 0, price: 0 }
          );

        return { ...freeCaseInfoNormalizedData, loaded: true };
      }
      return state;
    }
    default:
      return state;
  }
};
