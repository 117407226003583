import {
  IExperienceLog,
  IStatistics,
  IUploadAvatar,
  ICashback,
} from './personalArea.reducer.interfaces';

// experience

export enum experienceLogActionTypes {
  PROFILE_RECEIVE_EXP = 'modules/personal/PROFILE_RECEIVE_EXP',
  PROFILE_RECEIVE_EXP_HISTORY = 'modules/personal/PROFILE_RECEIVE_EXP_HISTORY',
}

export interface IReceiveUserExperienceAction {
  type: typeof experienceLogActionTypes.PROFILE_RECEIVE_EXP;
  payload: {
    log: IExperienceLog[];
  };
}

export interface IReceiveUserExperienceHistoryAction {
  type: typeof experienceLogActionTypes.PROFILE_RECEIVE_EXP_HISTORY;
  payload: IExperienceLog[];
}

export type experienceLogActions = IReceiveUserExperienceAction;
export type ExperienceHistoryLogActions = IReceiveUserExperienceHistoryAction;

//statistics

export enum statisticActionTypes {
  PROFILE_RECEIVE_STATISTICS = 'modules/personal/PROFILE_RECEIVE_STATISTICS',
}

export interface IReceiveProfileStatistic {
  type: typeof statisticActionTypes.PROFILE_RECEIVE_STATISTICS;
  payload: {
    statistic: IStatistics;
  };
}

export type statisticsActions = IReceiveProfileStatistic;

//transactions
export enum transactionActionsTypes {
  FETCH_TRANSACTIONS = 'app/personalArea/transaction/FETCH_TRANSACTIONS',
  UPDATE_TRANSACTIONS = 'app/personalArea/transaction/UPDATE_TRANSACTIONS',
  RESET_TRANSACTIONS = 'app/personalArea/transaction/RESET_TRANSACTIONS',
}

export interface IUpdateTransaction {
  type: typeof transactionActionsTypes.UPDATE_TRANSACTIONS;
  payload: {
    data: [];
  };
}

export interface IResetTransaction {
  type: typeof transactionActionsTypes.RESET_TRANSACTIONS;
}

export interface IChangeTransactionStatus {
  type: typeof transactionActionsTypes.FETCH_TRANSACTIONS;
}

export type transactionActions = IUpdateTransaction | IResetTransaction | IChangeTransactionStatus;

// avatar upload

export enum avatarUploadActionTypes {
  PROFILE_UPLOAD_AVATAR = 'modules/personal/PROFILE_UPLOAD_AVATAR',
  PROFILE_CLEANUP_UPLOAD_AVATAR = 'modules/personal/PROFILE_CLEANUP_UPLOAD_AVATAR',
}

export enum fileAvatarActionTypes {
  FILE_AVATAR = 'modules/personal/FILE_AVATAR',
}

export interface ICredentialsUploadAvatar {
  type: typeof avatarUploadActionTypes.PROFILE_UPLOAD_AVATAR;
  payload: IUploadAvatar;
}

export interface ICleanupUploadAvatar {
  type: typeof avatarUploadActionTypes.PROFILE_CLEANUP_UPLOAD_AVATAR;
}

export interface fileAvatarAction {
  type: typeof fileAvatarActionTypes.FILE_AVATAR;
  payload: string;
}

export type avatarUploadActions = ICredentialsUploadAvatar | ICleanupUploadAvatar;

// cashback
export enum cashbackActionTypes {
  RECEIVE_DATA = 'app/modules/personal_area/cashback/RECEIVE_DATA',
}

export interface IUpdateCashback {
  type: typeof cashbackActionTypes.RECEIVE_DATA;
  payload: ICashback;
}

export type cashbackActions = IUpdateCashback;
