import _ from 'lodash';
import { Decimal as DecimalBase } from 'decimal.js';
import { csgofastFixedRates } from './config';

const DECIMAL_PLACES = 2;
const EXPENSIVE_THRESHOLD = 0.00001;

const Decimal = DecimalBase.clone({ toExpNeg: -9 });

class CurrencyService {
  constructor() {
    this.fixedCurrencyRates = csgofastFixedRates;
    // this.currentCurrency = this.fixedCurrencyRates.find(currency => currency.isDefault) // or user selected currency
    // console.log(this.fixedCurrencyRates)
    //TODO что бы у всех и сразу стали фасткоины, подругому не понял как сделать нужно бы isDefault поставить но хз кто и где это должен ставить
    this.currentCurrency = this.fixedCurrencyRates.find(item => item.key === 'fastcoins');
    if (_.isUndefined(this.currentCurrency)) {
      throw new Error('Default currency is not found');
    }
  }

  setCurrencyRates(currencyRates) {
    this.fixedCurrencyRates = currencyRates;
  }

  setCurrency(key) {
    // if (key) this.currentCurrencyKey = key
    this.currentCurrencyKey = 'fastcoins';
    const currency = _.find(this.fixedCurrencyRates, c => c.key === this.currentCurrencyKey);
    if (_.isUndefined(currency)) return;
    this.currentCurrency = currency;
  }

  getCurrency() {
    return this.currentCurrency;
  }

  getCurrencies() {
    return this.fixedCurrencyRates;
  }

  getCurrencySybmol() {
    return this.currentCurrency.prefix || this.currentCurrency.postfix;
  }

  getRate(k) {
    return this.fixedCurrencyRates.find(currency => currency.key === k).rate;
  }

  // Если абсолютное значение целого числа === 1, оно округляется до фиксированного кол-во знаков после запятой
  // Если целое чичло 0 - используется roundNumberToFirstNonZero
  round(x) {
    // Для слишком дорогих валют - значение с точностью до 8 знаков
    if (this.currentCurrency.rate < EXPENSIVE_THRESHOLD) {
      return CurrencyService.roundNumberToFirstNonZero(x, 8);
    }
    if (Math.abs(parseInt(x)) === 0) {
      return CurrencyService.roundNumberToFirstNonZero(x);
    }
    return parseFloat(x.toFixed(DECIMAL_PLACES));
  }

  // Конвертирует базовую валюту приложения в валюту пользователя
  convert(v) {
    return new Decimal(v)
      .times(this.currentCurrency.rate)
      .toFixed(this.currentCurrency.dec_num || 2);
  }

  convertBySelectedRateKey(v, k) {
    const rate = this.fixedCurrencyRates.find(currency => currency.key === k).rate;
    return new Decimal(v).times(rate).toString();
  }

  // Конвертирует выбранную валюту в базовую
  // Базовая валюта может иметь только целые числа, поэтому округляется до целых
  revert(v) {
    return new Decimal(v).dividedBy(this.currentCurrency.rate).toFixed(0);
  }

  // Принимает базовую валюту на вход, возвращает отформатированную валюту в виде строки
  display(v) {
    // const x = Number(v)
    // if (isNaN(x)) return ''
    // const value = parseFloat(this.convert(x))
    // let rounded = Number(this.round(value).toString())
    // let prefix = this.currentCurrency.prefix || ''
    // const postfix = this.currentCurrency.postfix || ''
    // if (rounded < 0) {
    //   prefix = `-${prefix}`
    //   rounded *= -1
    // }

    // const pretty = rounded.toLocaleString(void 0, { maximumFractionDigits: 9 })
    // return `${prefix}${pretty}${postfix}`
    ///////////////////
    const x = Number(v);
    if (isNaN(x)) return '';
    const value = parseFloat(this.convert(x));
    let rounded = Number(this.round(value).toString());
    let prefix = ' ◎ ' || '';
    // let prefix = this.currentCurrency.prefix || "";
    if (rounded < 0) {
      prefix = `-${prefix}`;
      rounded *= -1;
    }

    const pretty = rounded.toLocaleString(void 0, {
      maximumFractionDigits: this.currentCurrency.dec_num || 2,
    });
    // return prefix + pretty + postfix;
    return prefix + pretty;
  }

  // Округляет число до первого знака после запятой, который !==0
  // Работает только если целая часть числа === 0
  static roundNumberToFirstNonZero(value, significantDigits = 2) {
    const number = Number(value);
    return new Decimal(
      parseFloat(
        number.toExponential(Math.max(1, significantDigits + Math.log10(Math.abs(number))))
      )
    );
  }
}

export const currencyService = new CurrencyService();
