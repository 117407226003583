import Immutable from 'immutable';
import { IMarketplaceElement } from './marketplace.reducer.interfaces';

export const PurchasesRecord = Immutable.Record<IPurchasesRecord>({
  meta: {
    amount: 0,
  },
  computed: {
    active: 0,
    wait: 0,
    cost: 0,
  },
  kits: {
    ids: [],
    entities: {},
  },
});

export interface IPurchasesRecord {
  meta: {
    amount: number;
  };
  computed: {
    active: number;
    wait: number;
    cost: number;
  };
  kits: {
    ids: string[];
    entities: Record<string, IMarketplaceElement>;
  };
}

export interface IBidsResponse {
  kits: IMarketplaceElement[];
  meta?: {
    amount: number;
    limit: number;
    offset: number;
  };
}
