import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { ITransactionOrderedMap, ITransactionsItem } from 'modules/PersonalArea/interfaces';
import { getItemType } from 'modules/PersonalArea/personalArea.utils';
import { Activities, Container, Title } from './ActivitiesGame.styles';
import { GamesIcons } from 'assets';
import { ModuleName } from 'modules/Lobby/components';

interface IHistoryActivities {
  lastWins: ITransactionOrderedMap;
}

export const ActivitiesGame: FC<IHistoryActivities> = ({ lastWins }) => {
  const { t } = useTranslation();

  const lastWinsItems = lastWins
    .valueSeq()
    .toArray()
    .reduce((items: ITransactionsItem[], item: ITransactionsItem) => {
      const parseData = getItemType(item);

      if (parseData && item.type === 'game-win') {
        items.push(item);
      }

      return items;
    }, []);

  return (
    <Container>
      <Title>{t('Recent wins:')}</Title>
      <Activities>
        {lastWinsItems?.map(item => {
          const parseData = getItemType(item);

          return (
            <div key={item.id} className="activities-item">
              {GamesIcons[parseData.name.toLowerCase() as ModuleName]?.(30, 30)}
              <div className="activities-item__game">{parseData.name}</div>
              <div className="activities-item__currency">
                +<CurrencyText value={item.amount} />
              </div>
            </div>
          );
        })}
      </Activities>
    </Container>
  );
};
