import { Reducer } from 'redux';
import { Record } from 'immutable';
import dayjs from 'dayjs';

import {
  historyActions,
  historyActionsTypes,
  ICleanUpHistory,
} from './interfaces/action.history.interfaces';
import { IHistoryItem, IRecordHistory } from './interfaces/reducer.history.interface';

import { IDispatch, IEventsTypes } from 'core/rootInterfaces/root.interface';
import { ISocketService } from '../../services/interfaces/services.interface';
import { getAppFilterRegex } from 'core/AppShell/selectors';

const HistoryRecord = Record({
  loading: false,
  loaded: false,
  gameTypeId: '0',
  entities: [],
});

const history: Reducer<Record<IRecordHistory>> = (
  state = new HistoryRecord(),
  action: historyActions
) => {
  switch (action.type) {
    case historyActionsTypes.REQUEST_HISTORY:
      return state.set('loading', true);

    case historyActionsTypes.RESPONSE_HISTORY:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('gameTypeId', action.payload.gameTypeId.toString())
        .set('entities', action.payload.history);

    case historyActionsTypes.CLEAN_UP:
      return new HistoryRecord();

    default:
      return state;
  }
};

export default history;

export const requestHistory = (gameTypeId: number, userId?: number) => (
  dispatch: IDispatch,
  getState: any,
  { socket }: ISocketService
) => {
  dispatch({ type: historyActionsTypes.REQUEST_HISTORY });
  socket.emit('history-request', { gameTypeId, userId });
};

export const cleanUpHistory = (): ICleanUpHistory => ({
  type: historyActionsTypes.CLEAN_UP,
});

const formatHistory = (history: [], filterRegex: string): IHistoryItem[] => {
  const regex = new RegExp(filterRegex, 'gi');
  return history.map((story: any) => {
    let formatStory: any = {};

    if (story.items) {
      const initState = {
        items: {},
        totalPrize: 0,
      };

      formatStory = story.items.reduce(
        (acc: any, item: any) => ({
          ...acc,
          totalPrize: acc.totalPrize + item.itemPrice,
          items: {
            ...acc.items,
            [item.playerId]: {
              ...item,
              itemPrice: acc.items[item.playerId]
                ? acc.items[item.playerId].itemPrice + item.itemPrice
                : item.itemPrice,
            },
          },
        }),
        initState
      );
    }

    if (story.winnerName) {
      formatStory.winnerName = story.winnerName.replace(regex, '***');
    }

    if (story.timeAdded) {
      formatStory.timeAdded = dayjs(story.timeAdded).format('DD.MM.YYYY, HH:mm');
    }

    if (story.time) {
      formatStory.timeAdded = dayjs(story.time).format('DD.MM.YYYY, HH:mm');
    }

    if (story.sector) {
      formatStory.sector = story.sector.toString();
    }

    if (story.id) {
      formatStory.gameId = story.id;
    }

    return {
      ...story,
      ...formatStory,
    };
  });
};

const responseHistory = (data: any) => (dispatch: IDispatch, getState: any) => {
  const filterRegex = getAppFilterRegex(getState());
  const history = formatHistory(data.history, filterRegex);

  dispatch({
    type: historyActionsTypes.RESPONSE_HISTORY,
    payload: { history, gameTypeId: data.gameTypeId },
  });
};

export const eventsTypes: IEventsTypes[] = [{ event: 'history:update', action: responseHistory }];
