export default {
  // promo page
  '100$ for the new players!': '100$ for the new players!',
  '100$ bonus is credited to a demo balance': '100$ bonus is credited to a demo balance',
  'Get 100$ now!': 'Get 100$ now!',

  // refill modal
  'Top up your balance from 25$ and get bonuses': 'Top up your balance from 25$ and get bonuses',
  'Exp bonus': 'Exp bonus',
  'Wheel Of Fortune free spin': 'Wheel Of Fortune free spin',
  'Free case': 'Free case',
  'Access to account level-up': 'Access to account level-up',
  Cashback: 'Cashback',
  'Refill balance': 'Refill balance',
};
