import React, { FC, useState } from 'react';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchUpdateApikey } from 'core/User/ducks/duck';
import { useGetApiKey } from 'core/User';
import { URLS } from 'services/constant-urls';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import {
  ContentWrapper,
  Input,
  Link,
  SubTitle,
  Title,
  Container,
} from './steam-apiKey-dialog.styles';

interface ISteamApiKeyDialog extends IDialogComponent<boolean> {}

export const SteamApiKeyDialog: FC<ISteamApiKeyDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleCloseDialog } = useDialog(userModals.STEAM_API_DIALOG);
  const { steamApiKey } = useGetApiKey();
  const [value, setValue] = useState(steamApiKey ?? '');

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const submitHandler = () => {
    if (value) {
      dispatch(fetchUpdateApikey(value));
      handleCloseDialog();
    }
  };

  return (
    <Container className="content">
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>
      <Title>{steamApiKey ? t('steamApi.changeApiKey') : t('steamApi.addApiKey')}</Title>
      <SubTitle>{t('steamApi.subTitle')}</SubTitle>
      <ContentWrapper>
        <Input
          value={value}
          onChange={changeHandler}
          type="text"
          placeholder={t('steamApi.enterApiKey')}
        />
        <Link target="_blank" href={URLS.linkSteamApiKey}>
          {t('steamApi.link')}
        </Link>
        <Button
          className="primary"
          disabled={!value || value === steamApiKey}
          onClick={submitHandler}
        >
          {t('Submit').toLocaleUpperCase()}
        </Button>
      </ContentWrapper>
    </Container>
  );
};
