import { ReactComponent as BonusesIcon } from './bonuses.svg';
import { ReactComponent as CashBackIcon } from './cashback.svg';
import { ReactComponent as GiftIcon } from './gift.svg';
import { ReactComponent as TimeIcon } from './time.svg';

export const SvgIcons = {
  BonusesIcon,
  CashBackIcon,
  GiftIcon,
  TimeIcon,
};
