import styled from 'styled-components';

export const Container = styled.div`
  background: #6dad52;
  height: 40.39px;
  padding: 11px 20px;
  position: absolute;
  width: 100%;
  z-index: 1;

  & .close {
    color: var(--color-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    padding: 0;
    position: absolute;
    right: 20px;
    text-transform: none;
    top: 13px;
  }
`;

export const Title = styled.span`
  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-weight: 700;
`;
