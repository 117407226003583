import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 3.125rem 1fr 3rem 3rem 4.5rem;
  grid-gap: 1.25rem;
  height: 422px;
  background-color: var(--color-dark-350);
  border-top: 1px solid var(--gray-250);

  &.empty.validate .mailbox-container input,
  &.empty.pattern .mailbox-container input {
    border: 2px solid var(--color-red);
  }

  & .dialog-header {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    padding: 0 1.125rem;
    height: 3.125rem;
    border-bottom: 1px solid var(--color-gray-900);

    & .dialog-close {
      text-transform: none;
      font-weight: normal;
    }

    & .dialog-title {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-white-400);
    }
  }

  & .dialog-description {
    position: relative;

    & .empty {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }

    & .not-confirmed,
    & .confirmed,
    & .unsubscribe {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }

    & .empty,
    & .not-confirmed,
    & .confirmed,
    & .unsubscribe {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;
      padding: 0 1.25rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: transform 120ms ease-in, opacity 30ms ease-in;

      & .description {
        font-size: 1.25rem;
        line-height: 1.625rem;
        letter-spacing: 0.5px;
        color: var(--color-white);
      }

      & .sub-description {
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: 0.571px;
        color: var(--color-white-600);
      }
    }
  }

  & .mailbox-container {
    align-self: self-end;
    padding: 0 1.125rem;
  }

  & .dialog-subcontroller {
    display: flex;
    align-items: center;
    padding: 0 1.125rem;
    position: relative;

    & .agree-container {
      position: absolute;
      bottom: 0.375rem;
      opacity: 1;
      transition: transform 120ms ease-in, opacity 60ms ease-in;
    }

    & .mailbox-status-container {
      display: grid;
      grid-auto-columns: min-content;
      grid-auto-flow: column;
      justify-content: space-between;
      padding: 0 1.25rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translate3d(0, -100%, 0) rotateX(15deg);
      opacity: 0;
      transition: transform 120ms ease-in, opacity 60ms ease-in;

      & .refresh-status,
      & .status {
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.54px;
        color: var(--color-gray-200);
        white-space: nowrap;
        text-decoration: underline;
      }
    }

    &:not(.not).confirmed .dialog-subcontroller .mailbox-status-container,
    &.not.confirmed .dialog-subcontroller .mailbox-status-container {
      transform: translate3d(0, 0, 0) rotateX(0deg);
      opacity: 1;
      transition: transform 120ms ease-out, opacity 60ms ease-out;
    }
  }

  &.confirmed .dialog-subcontroller .agree-container,
  &.unsubscribe .dialog-subcontroller .agree-container,
  &.not.confirmed .dialog-subcontroller .agree-container {
    transform: translate3d(0, 100%, 0) rotateX(15deg);
    opacity: 0;
    transition: transform 120ms ease-out, opacity 60ms ease-out;
  }

  &.empty .dialog-description .empty,
  &.not.confirmed .dialog-description .not-confirmed,
  &:not(.not).confirmed .dialog-description .confirmed,
  &.unsubscribe .dialog-description .unsubscribe {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  & .dialog-controller {
    padding: 0 1.125rem 1.5rem;
  }

  & > button.primary,
  & > button.secondary {
    margin-top: 1.375rem;
  }
`;
