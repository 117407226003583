import { AccountsRouter, PlayerProfileModal } from './containers';
import reducer, { profileLoaderEpic } from './duck';

export { accountsModals } from './modal.config';
export type { IAccountRecord } from './interfaces/reducer.account.interface';
export { PlayerProfileModal };
export { profileLoaderEpic };
export { useLoadAccountProfile } from './hooks';

export const accountsModule = {
  moduleName: 'accounts',
  path: '/accounts',
  component: AccountsRouter,
  reducer,
};
