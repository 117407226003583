/* eslint-disable */
export default {
  winning: 'Выигрыш',
  line: 'Линия',
  lines: 'Линии',
  total: 'Всего',
  Autoplay: 'Автоигра',
  Start: 'Старт',
  Paytable: 'Таблица выплат',
  'Recent wins': 'Последние победы',
  'Your games': 'Твои игры',
  'Bonus game': 'Бонусная игра',
  'bonus games': 'бонусных игр',
  'Bonus game xN': 'Бесплатные вращения х{{count}}',
  'Bet per line': 'Ставка на линию',
  'Slot works regardless of location': 'Слот работает независимо от расположения',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Может заменить любой слот в линии (Кроме бонусных слотов)',
  'Collect all three slots and get 10 bonus games!':
    'Собери все три слота и получи 10 бонусных игр!',
  'Total won:': 'Общий выигрыш:',
  'Bonus games': 'Бонусных игр',
  'Bid amount': 'Поставить ${currency(amount)}',
};
