import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'core/Modals';
import { accountsModals } from '../modal.config';

export const useAccountModal = () => {
  const { handleCloseModal } = useModal(accountsModals.PLAYER_PROFILE);
  const { push } = useHistory();

  const handlePushToAccount = useCallback(() => {
    handleCloseModal();
    push('/accounts');
  }, [push, handleCloseModal]);

  return {
    handlePushToAccount,
  };
};
