import { Button } from '@components/Common';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  z-index: 2;
  background: var(--yellow);
  width: 0;
  display: flex;
  height: 48px;
  flex: 2;
  transition: 0.3s background;
  color: var(--color-dark-600);

  &:disabled {
    pointer-events: none;
    background: var(--color-dark-600);
    color: var(--color-white);
  }
`;
export const StyledButtonDisabled = styled(Button)`
  background: var(--color-dark-600);
`;
export const ButtonOpenContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: 10px;
`;
