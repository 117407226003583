import { useSelector } from 'react-redux';
import { getCasesLR } from '../duck/selectors';

export const useGetCasesLr = () => {
  const { isLoading, data } = useSelector(getCasesLR);

  return {
    isLoading,
    data,
  };
};
