import { Observable } from 'rxjs';
import { socketService } from 'services/socket.service';
import { ajax } from 'rxjs/ajax';

class LeadersRaffleRepository {
  fetchActiveRaffles(): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/raffles?isActive=true`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  fetchDetailsRaffle(raceId: number): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/raffles/${raceId}/items?extend=members`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  fetchAddBet(raceId: number, itemId: number): Observable<any> {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/raffles/${raceId}/items/${itemId}/bet`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
    });
  }
}

export default new LeadersRaffleRepository();
