import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-start;
  padding: 0 18px 21px;

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: var(--color-white);
  }

  &.tablet {
    @media screen and (min-width: 500px) {
      width: 100%;
    }
  }
`;

export const SortContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
`;

export const PriceRangeContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
`;
export const SearchContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  max-width: 20%;
`;

export const RangeContainer = styled.div`
  background: var(--color-dark-200);
  border-radius: var(--size-radius-l);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  color: var(--color-white);
`;
export const PriceRange = styled.span`
  padding: 1px 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 0.6rem;
  text-align: center;
  line-height: 1rem;
`;
export const OperatorContainer = styled(PriceRange)`
  padding: 1px 0 1px 5px;
`;
export const CancelItemContainer = styled.div`
  padding: 1px 5px;
  display: flex;
  align-items: center;
`;
export const WordContainer = styled(RangeContainer)`
  padding: 1px 5px;
  overflow: hidden;
`;
export const SearchSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 90%;
`;
