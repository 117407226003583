import { BaseItem, SuitCaseData } from 'games/CaseGame/interfaces';

export interface RouletteBaseItem extends BaseItem {
  isProfit: boolean;
}

export const getRouletteLine = (
  line: number[],
  round: number,
  cases: number[],
  casesData: SuitCaseData[]
) => {
  if (line?.length > 0) {
    if (round === 1) {
      const rouletteLine: RouletteBaseItem[] = line.map(lineId => {
        const currentCase = casesData.find(({ id }) => id === cases[round - 1]);

        const { inventoryItem } = currentCase.lastRevision.items.find(
          ({ inventoryItem }) => lineId === inventoryItem.id
        );

        return {
          ...inventoryItem.baseItem,
          isProfit: currentCase.lastRevision.price < inventoryItem.price,
        };
      });

      return rouletteLine;
    }

    const firstPartOfLine = line.slice(0, 3);
    const secondPartOfLine = line.slice(3);
    const currentCaseForFirstPart = casesData.find(({ id }) => id === cases[round - 2]);
    const currentCaseForSecondPart = casesData.find(({ id }) => id === cases[round - 1]);

    const firstPartItemsArray: RouletteBaseItem[] = firstPartOfLine.map(lineId => {
      const { inventoryItem } = currentCaseForFirstPart.lastRevision.items.find(
        ({ inventoryItem }) => lineId === inventoryItem.id
      );
      return {
        ...inventoryItem.baseItem,
        isProfit: currentCaseForFirstPart.lastRevision.price < inventoryItem.price,
      };
    });
    const secondPartItemsArray: RouletteBaseItem[] = secondPartOfLine.map(lineId => {
      const { inventoryItem } = currentCaseForSecondPart.lastRevision.items.find(
        ({ inventoryItem }) => lineId === inventoryItem.id
      );
      return {
        ...inventoryItem.baseItem,
        isProfit: currentCaseForSecondPart.lastRevision.price < inventoryItem.price,
      };
    });

    return [...firstPartItemsArray, ...secondPartItemsArray];
  }
  return null;
};
