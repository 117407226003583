import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-height: 212px;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const BlurComponent = styled.div<{ position: 'top' | 'bottom' }>`
  position: absolute;
  height: 68px;
  width: 100%;
  z-index: 3;
  background: linear-gradient(180deg, #131a1d 0%, rgba(19, 26, 29, 0) 100%);
  ${({ position }) => (position === 'top' ? 'top:0;' : 'bottom:0;transform: rotate(180deg);')};
`;
