import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  border: 1px solid var(--color-dark-200);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  transition: top 0.3s, opacity 0.3s;
  width: 100%;
  position: absolute;

  &[data-roulette-status='hide'] {
    opacity: 0;
    top: 10px;
    pointer-events: none;
  }

  &[data-roulette-status='show'] {
    opacity: 1;
    top: 0;
  }

  &[data-roulette-disable='true'] {
    pointer-events: none;
  }
`;

export const CountItemContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 11px 0;
  align-items: center;
  border-right: 1px solid var(--color-dark-200);
  flex: 1;

  &:last-child {
    border: none;
  }

  background: transparent;
  transition: background 0.15s ease;
`;
export const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--color-dark-200);
`;
export const StyledButton = styled.div<{ left: number; width: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--color-white);
  background: var(--color-dark-200);
  border-right: ${p => (p.left === 0 ? '1px solid var(--color-dark-200)' : 'none')};
  transition: left 0.5s cubic-bezier(0.37, 0.62, 0.37, 1), opacity 0.3s ease;
  box-shadow: 0 0 0.075rem 0.0175rem var(--color-theme-600);
  z-index: 10;
  left: ${p => p.left}px;
  width: ${p => p.width + 1}px;

  &[data-roulette-status='hide'] {
    opacity: 0;
    pointer-events: none;
  }

  &[data-roulette-status='show'] {
    opacity: 1;
  }
`;
