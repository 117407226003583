import React from 'react';
import { AppBar } from '@components/bars';
import { LineChart, PlayerComparison, RaceTypeSwitch, RaceTypeTabBar } from '../../components';
import { useAccountProfile, useAccountRaceStatistic, useRaceActivity } from '../../hooks';
import { BigPreloader } from '@components/Preloader';
import { useUser } from 'core/User/hooks';

export const AccountRaceActivityPage = () => {
  const { quests } = useAccountRaceStatistic();
  const { profile } = useAccountProfile();
  const { user } = useUser();

  const {
    greatType,
    fastType,

    greatRace,
    fastRace,

    handleChangeGreatType,
    handleChangeFastType,
  } = useRaceActivity();

  if (!greatRace.loaded || !fastRace.loaded || !profile.id) {
    return <BigPreloader />;
  }

  const lng = fastRace.entities.length;
  const begin = greatType * 4 ? greatType * 4 - (lng % 4) : greatType * 4;
  const end = greatType * 4 ? begin + 4 : begin + (lng % 4);

  return (
    <>
      <AppBar deep />
      <div className="content dark-layout">
        <RaceTypeSwitch
          greatType={greatType}
          handleChangeRaceType={handleChangeGreatType}
          options={greatRace.entities}
        />
        <RaceTypeTabBar
          raceIndex={fastType}
          handleChangeRace={handleChangeFastType}
          options={fastRace.entities.slice(begin, end)}
        />
        <LineChart quests={quests} profile={profile} user={user} />
        <PlayerComparison quests={quests} profile={profile} />
      </div>
    </>
  );
};
