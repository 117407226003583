import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useUser } from 'core/User';
import { usePayoutVerification } from '../../../hooks';
import photo from '../../../assets/verification-photo.png';

export const UserVerification = () => {
  const { t } = useTranslation();
  const { file, onFileChange, verifyAction } = usePayoutVerification();
  const {
    user: { verification, id },
  } = useUser();

  return (
    <div className="content dark-layout">
      <Container>
        <img className="thumbnail-verification" src={photo} alt="thumbnail-verification" />
        <div className="title-verification">
          {t('For identification, send us a photo that contains:')}
        </div>
        <ul className="contains-verification">
          <li>{t('Your face')}</li>
          <li>{t('Passport with a clearly visible photo and readable full name')}</li>
          <li>
            {t(
              'Bank card with which the balance was replenished (the last 4 digits of the card and the full name of the card holder should be visible)'
            )}
          </li>
          <li>
            {t('Paper sheet with your handwritten FastID')}
            {' - '}
            {id}
          </li>
        </ul>
        {file && <img src={file} alt="" />}
        <VerificationInput className={`${file ? 'has-file' : ''}`}>
          <input
            onChange={onFileChange}
            type="file"
            accept=".jpg, .jpeg, .png"
            disabled={verification.status === 'pending'}
          />
          {file ? <span>{t('Update photo')}</span> : <span>{t('Send photo')}</span>}
        </VerificationInput>
        {file && (
          <Button
            onClick={verifyAction}
            className="primary"
            disabled={verification.status === 'pending'}
          >
            {t('Send photo')}
          </Button>
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: 1.375rem;
  padding: 1.625rem 1rem;

  & .thumbnail-verification {
    width: 70%;
    justify-self: center;
  }

  & .title-verification {
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: 0.5px;
    color: var(--color-white);
  }

  & .contains-verification {
    & li {
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      color: var(--color-white);
      list-style-type: decimal;
      margin: 0 0 1rem 1.3rem;
    }
  }
`;

const VerificationInput = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  border-radius: var(--size-radius-m);
  transition: all var(--transition-ease-out);

  &:not(.has-file) {
    height: 50px;
    background-color: var(--yellow);

    &:active {
      background-color: #ad9d15;
    }
  }

  & span {
    color: var(--color-dark-600);
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.29px;
    text-transform: uppercase;
  }

  & input[type='file'] {
    display: none;
  }

  &.has-file {
    & span {
      font-weight: bold;
      font-size: 13 / 16rem;
      line-height: 0.9375rem;
      text-align: center;
      text-decoration-line: underline;
      color: var(--color-gray-200);
    }
  }
`;
