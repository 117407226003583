import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '../../../../../@components/currency';

interface IDataDialog {
  payback: number;
  rate: number;
  needPlayCoinsAmount: number;
}

interface IFairGameDialog extends IDialogComponent<IDataDialog> {}

export const RateDialog: FC<IFairGameDialog> = ({
  handleCloseModal,
  data: { rate, payback, needPlayCoinsAmount },
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Button className="link-light close" onClick={handleCloseModal}>
        {t('Close')}
      </Button>

      <div className="rate_wrapper">
        <span>{t('To improve the exchange rate, you need to play more')}</span>
        <span>
          {t('To improve the rate, bet on:')} <CurrencyText value={needPlayCoinsAmount} />
        </span>
        <span>
          {t('Rate:')} ◎ 1 ~ ${rate} <span>- {(payback * 100).toFixed(2)}% </span>{' '}
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 1.75rem 1rem;
  background: var(--color-dark-600);
  border-top: 1px solid var(--gray-250);
  position: relative;
  color: var(--color-white);

  & .close {
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    text-transform: none;
    font-weight: normal;
  }
  & .rate_wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
