export default {
  //CryptoPage title
  crypto: 'Uttak av kryptovaluta',
  cryptocurrency: 'Kryptovaluta',
  from: 'fra',
  //
  'Email is required': 'Trenger e-post',
  Withdraw: 'Ta ut',
  'Withdrawal sum': 'Uttakssum',
  'Amount in {{title}}': 'Beløp i $t({{title}}):',
  'Receive in {{title}}': 'Motta i $t({{title}}):',
  'You will receive:': 'Du vil motta:',
  'Select the network fee': 'Velg nettverksavgift',
  'Commission:': 'Kommisjon:',
  'Ultra Low': 'Veldig treg',
  Low: 'Sakte',
  Medium: 'Standart',
  High: 'Rask',
  'No commission': 'Ingen provisjon',
  commission: 'Eventuell avgift',
};
