import { IGameBets, IGameHands, IMember } from './interfaces';

export const dataCards: Record<string, Record<string, string>> = {
  '♣': {
    img: '//d2lomvz2jrw9ac.cloudfront.net/baccarat-assets/baccarat-clubs.svg',
    color: '#515262',
  },
  '♠': {
    img: '//d2lomvz2jrw9ac.cloudfront.net/baccarat-assets/baccarat-spades.svg',
    color: '#515262',
  },
  '♥': {
    img: '//d2lomvz2jrw9ac.cloudfront.net/baccarat-assets/baccarat-hearts.svg',
    color: '#ff6464',
  },
  '♦': {
    img: '//d2lomvz2jrw9ac.cloudfront.net/baccarat-assets/baccarat-diamonds.svg',
    color: '#ff6464',
  },
};

export const getTypeGame = (playerHandValue: number, bankHandValue: number): string => {
  if (playerHandValue === bankHandValue) return 'T';
  if (playerHandValue < bankHandValue) return 'B';
  return 'P';
};

export const getDataCard = (card: any) => {
  if (!card) return null;

  let value = card[0];
  const type = card[1];

  if (value === 'T') value = 10;

  return { value, img: dataCards[type].img, color: dataCards[type].color };
};

export const getHandCardsAmount = (cards: string[]): number => {
  let amount = 0;

  cards.forEach(([v]: any) => {
    if (v === 'A') {
      amount += 1;
    } else {
      const val = parseInt(v);
      if (!!val) amount += val;
    }
  });
  return amount % 10;
};

export const isPair = (cards: string[]) => cards[0][0] === cards[1][0];

export const getBoardTypesByHandCards = ({ playerHand, bankHand }: IGameHands) => {
  if (!playerHand.length || !bankHand.length) return '';
  return getTypeGame(getHandCardsAmount(playerHand), getHandCardsAmount(bankHand));
};

export const betsMapper = (bets: IMember[]): IGameBets => {
  return bets.reduce(
    (acc: IGameBets, member) => ({
      totals: {
        ...acc.totals,
        total: (acc.totals?.total || 0) + member.amount,
        [member.field]: (acc.totals[member.field] || 0) + member.amount,
      },
      members: {
        ...acc.members,
        [member.field]: {
          ...acc.members[member.field],
          [member.user_id]: member,
        },
      },
    }),
    {
      totals: {},
      members: {},
    }
  );
};
