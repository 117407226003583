import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

class _SortDirection extends PureComponent {
  static propTypes = {
    filterValues: PropTypes.object.isRequired,
    sortAction: PropTypes.func.isRequired,
  };

  render() {
    const { filterValues, t } = this.props;
    return (
      <SortFilter onClick={this.changeSortValue} sort={filterValues.sortDirection === 'up'}>
        {t('By price')}
      </SortFilter>
    );
  }

  changeSortValue = () => {
    const { filterValues, sortAction } = this.props;
    const sortDirection = filterValues.sortDirection === 'up' ? 'down' : 'up';
    sortAction({ sortDirection });
  };
}

export const SortDirection = withTranslation()(_SortDirection);

const SortFilter = styled.div`
  padding-right: 20px;

  font-size: 10px;
  letter-spacing: 0.3px;
  color: #2ab6d5;
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    width: 9px;
    height: 5px;
    clip-path: polygon(
      ${({ sort }) => (sort ? '50% 0, 0 100%, 100% 100%' : '50% 100%, 0 0, 100% 0')}
    );
    background: #2ab6d5;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
