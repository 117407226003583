import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { UserInfo } from './styles';

interface IUserProfileInfo {
  userId: number | null;
  date_registred: string;
}

export const UserProfileInfo: FC<IUserProfileInfo> = ({ userId, date_registred }) => {
  const { t } = useTranslation();
  return (
    <UserInfo>
      <span>{t('Fast ID')}:</span>
      <span />
      <span>{t('First game:')}</span>
      <span> {userId}</span>
      <span />
      <span>{date_registred && dayjs(date_registred).format('DD.MM.YYYY, HH:mm')}</span>
    </UserInfo>
  );
};
