import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IDialogComponent } from 'core/interfaces';
import { SwipeModal } from '@components/SwipeModal';
import { updateUserBanTime } from 'core/User/ducks/duck';
import { useUserBanTime } from 'core/User/hooks/useUserBanTime';
import { useDialog } from 'core/hooks';
import { userModals } from 'core/User/modal.config';
import {
  Container,
  FreezeTitle,
  MyButton,
  RadioGroup,
  SubTitle,
  Title,
} from './profile-freeze-time-dialog.styles';

const DAYS = [1, 3, 7, 14, 21, 38, 60, 90];

interface IProfileFreezeTimeDialog extends IDialogComponent<boolean> {}

export const ProfileFreezeTimeDialog: FC<IProfileFreezeTimeDialog> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { banTime } = useUserBanTime();
  const { handleCloseDialog } = useDialog(userModals.PROFILE_FREEZE);
  const [height, setHeight] = useState(0);
  const [day, setDay] = useState(banTime);
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      setHeight(Number(elementRef.current.offsetHeight) + 30);
    }
  }, []);

  const handleConfirm = () => {
    dispatch(updateUserBanTime(day));
    handleCloseDialog();
  };

  const handleDay = (day: number) => setDay(day);

  return (
    <SwipeModal handleCloseModal={handleCloseModal} listHeight={height}>
      <Container ref={elementRef}>
        <Title>{t('Freeze the deposit/withdrawal of funds')}</Title>
        <SubTitle>
          {t(
            'At the time of freezing, the operations of depositing/withdrawing funds are suspended'
          )}
        </SubTitle>
        <FreezeTitle>{t('Duration of freezing:')}</FreezeTitle>
        <RadioGroup>
          {DAYS.map(item => (
            <React.Fragment key={item}>
              <label>
                <input
                  type="radio"
                  name="radio"
                  checked={item === day}
                  onChange={() => handleDay(item)}
                />
                {`${item} `}
                {t('pluralize-days', {
                  count: item,
                  postProcess: 'with-template',
                })}
              </label>
            </React.Fragment>
          ))}
        </RadioGroup>
        <MyButton className="primary" onClick={handleConfirm}>
          {t('Freeze for')}
          {` ${day} `}
          {t('pluralize-days', {
            count: day,
            postProcess: 'with-template',
          })}
        </MyButton>
      </Container>
    </SwipeModal>
  );
};
