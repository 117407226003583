import { useSelector } from 'react-redux';
import { getCases } from '../ducks';

export const useGetCaseById = (caseId?: number) => {
  const { data, isLoading } = useSelector(getCases);
  if (caseId) {
    const caseData = data.find(({ id }) => id === caseId);
    return { caseData, isLoading };
  }

  return { caseData: null, isLoading };
};
