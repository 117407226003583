import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSalesElement } from '../../selector';
import { updateInSelected } from '../../ducks/sales.duck';
import { IBatchRequest } from '../../interfaces';
import { IStore } from 'store/interface';

export const useSalesElement = <T>(id: string) => {
  const dispatch = useDispatch();
  const item = useSelector<IStore, T>(getSalesElement(id), shallowEqual);

  const handleUpdateElement = useCallback(
    (data: Partial<IBatchRequest>) => {
      dispatch(updateInSelected(data));
    },
    [dispatch]
  );

  return {
    item,
    handleUpdateElement,
  };
};
