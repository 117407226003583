/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'JOC',
  Chat: 'Chat',
  'Email Support': 'Asistență prin e-mail',
  'Live Support Chat': 'Chat de asistență live',
  History: 'Istoric joc',
  Top: 'Sus',
  Rules: 'Rules',
  'At stake': 'În joc',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Pentru a scrie un mesaj trebuie să te înregistrezi',
  'Only players who have played the game are allowed to write':
    'Numai jucătorilor care au jucat un joc li se permite să scrie',
  'You are banned': 'Eşti blocat',
  Reason: 'Motiv',
  'Time left': 'Timpul rămas',
  'Time to summarizing': 'Timpul rămas până la sintetizare',
  spam: 'spam',
  'advertising (auto)': 'publicitate (auto)',
  advertising: 'publicitate',
  rudeness: 'impoliteţe',
  begging: 'implorare',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Astăzi',
  Yesterday: 'Ieri',
  'Last year': 'Anul trecut',
  'Show my wins': 'Arată-mi câștigurile',
  'History is empty': 'Istoricul nu conține nimic',
  Cancel: 'Anulare',
  Delete: 'Ștergere',
  Close: 'Închide',
  Confirm: 'Confirmare',
  'Are you sure to delete all notifications?':
    'Ești sigur că dorești să ștergi toate notificările?',
  'Log in': 'Conectare',
  'Log in to try your luck': 'Înregistrează-te pentru a-ţi încerca norocul',
  'try your luck!': 'Încearcă-ţi norocul!',
  'Take your prize': 'Intră în posesia premiului',
  'Sell for': 'vinde pentru',
  BACK: 'BACK',
  'Warning!': 'Avertisment!',
  'Congratulations!': 'Felicitări!',
  'You have won': 'You have won',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Felicitări! O ofertă de tranzacţie în schimbul premiului tău a fost trimisă în contul tău.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'Tranzacţia a fost anulată după o oră',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'După ce comanda dvs. este procesată, veți putea primi câștigul prin intermediul paginii de anunțuri a pieței Steam',

  'Round number': 'Număr rundă',
  'Round hash': 'Cod hash rundă',
  'Hash salt': 'Hash salt',

  'Refill amount': 'Suma de realimentat',
  Bonus: 'Bônus',
  'Bonus experience': 'Experiență bonus',
  'Bonus wheel spins': 'Învârtiri bonus de ruletă',
  'Bonus cases': 'Cufere bonus',

  'Trick or treat?': 'Ne dati ori nu ne dati?',
  'Keep your halloween tip jars ready!': 'Păstrați gata borcanele de Halloween!',
  'Bonuses on every balance refill': 'Bonusuri la fiecare reumplere a soldului',

  '${currency(amount)} bonus is ready!': 'Bonusul de ${currency(amount)} este gata!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Completați soldul și obțineți până la ${currency(amount)} în rotiri și cutii gratuite',

  'Get bonus': 'obține acum',
};
