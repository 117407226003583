import { createSelector } from 'reselect';
import { IStore } from 'store/interface';

export const getPayoutMethods = ({ withdrawal }: IStore) => withdrawal.get('methods');

export const getPayoutConfig = (method: string) => ({ withdrawal }: IStore) =>
  withdrawal.getIn(['methods', method, 'gates', withdrawal.getIn(['order', 'paymentSystem'])]);

export const getPayoutGates = ({ withdrawal }: IStore, method: string) =>
  withdrawal.getIn(['methods', method, 'gates']);

export const getBindingUrl = ({ withdrawal }: IStore) => withdrawal.get('url');

export const getPayoutAmount = ({ withdrawal }: IStore) => withdrawal.get('amount');

export const getInputAmount = ({ withdrawal }: IStore) => withdrawal.getIn(['amount', 'input']);

export const getPayoutOrder = ({ withdrawal }: IStore) => withdrawal.getIn(['order']);

export const getPayoutOrderStatus = ({ withdrawal }: IStore) => withdrawal.getIn(['order_status']);

export const getPayoutRecaptcha = ({ withdrawal }: IStore) =>
  withdrawal.getIn(['order', 'recaptchaToken']);

export const getPayoutWallet = ({ withdrawal }: IStore) =>
  withdrawal.getIn(['order', 'paymentWallet']);

export const getPayoutAggregator = ({ withdrawal }: IStore) =>
  withdrawal.getIn(['order', 'paymentSystem']);

export const getPayoutOrderFee = ({ withdrawal }: IStore) =>
  withdrawal.getIn(['order', 'feeIndex']);

export const getPayoutFee = ({ withdrawal }: IStore) => withdrawal.get('fee');

export const getRate = ({ withdrawal }: IStore) => withdrawal.get('rate');

export const getPayoutCurrency = createSelector(
  ({ withdrawal }: IStore) => withdrawal.get('currency'),
  (store: IStore, key: string) => key,
  (currency, key) => {
    return currency.find(el => el.title === key);
  }
);
