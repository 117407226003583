import React, { FC } from 'react';

import { AppBar } from '@components/bars';
import { History } from '@components/History';

export const HistoryPage: FC = () => (
  <>
    <AppBar deep />
    <History gameType={2} />
  </>
);
