import * as React from 'react';
import styled from 'styled-components';
import TriangleSvg from './TriangleSvg';

export default class AutoStopRuler extends React.PureComponent {
  render() {
    return (
      <AutoStopContainer isShow={this.props.isShow}>
        <TriangleSvg />
      </AutoStopContainer>
    );
  }
}

const AutoStopContainer = styled.div`
  margin-left: -9px;
  position: absolute;
  bottom: -145px;
  transform: translateX(50%);
  z-index: 10;
  transition: opacity linear 0.2s;
  opacity: ${props => (props.isShow ? 1 : 0)};
`;
