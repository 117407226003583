import { useSelector } from 'react-redux';
import { getRouletteStatus } from '../ducks';

export const useGetRouletteStatus = () => {
  const {
    rouletteStatus,
    isAllAfterRoll,
    isAllBeforeRoll,
    isAllInRoll,
    isAllStartRoll,
  } = useSelector(getRouletteStatus);

  return {
    rouletteStatus,
    isAllAfterRoll,
    isAllBeforeRoll,
    isAllInRoll,
    isAllStartRoll,
  };
};
