import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import BezierEasing from 'bezier-easing';
import { GameRouletteReel } from './game-roulette-reel';
import { GameRoulettePointer } from './game-roulette-pointer';
import { useGameRoundNumbers, useGameUntilTime, useHighlightGameSectors } from '../../../hooks';
import { GameCondition, getColorBySector, getDegreeBySector, SectorColors } from '../../../utils';
import SoundService from 'services/sound.service';

const sound = new SoundService();
const easing = BezierEasing(0.32, 0.95, 0.45, 1);

export const GameRoulette = () => {
  const sectors = useHighlightGameSectors();
  const [current, previous] = useGameRoundNumbers();
  const [condition, setCondition] = useState(GameCondition.PENDING);
  const untilTime = useGameUntilTime();

  const classes = useMemo(() => {
    return (Object.keys(sectors) as SectorColors[])
      .map(v => (sectors[v] ? `highlight-${v}` : ''))
      .join(' ');
  }, [sectors]);

  const [styles, set] = useSpring(() => ({
    transform: 'rotate(0deg)',
  }));

  useEffect(() => {
    return () => sound.stop({ close: true });
  }, []);

  useLayoutEffect(() => {
    if (current !== null) {
      const duration = Math.max(untilTime + 15000 - Date.now(), 0);
      const rotationCount = Math.trunc((duration / 15000) * 10);
      setCondition(() => GameCondition.RAFFLING);

      sound.play('x50', 'roulette', { from: 19 - duration / 1000 });

      set({
        from: { transform: `rotate(${getDegreeBySector(previous)}deg)` },
        transform: `rotate(${rotationCount * 360 + getDegreeBySector(current)}deg)`,
        config: { duration, easing },
        immediate: false,
        onStart: null,
        onRest: () => setCondition(() => GameCondition.RESULT),
      });
    } else {
      set({
        transform: `rotate(${getDegreeBySector(previous)}deg)`,
        immediate: true,
        onStart: () => setCondition(() => GameCondition.PENDING),
        onRest: null,
      });
    }
  }, [current, previous, set, setCondition, untilTime]);

  const winnerSector = current ? getColorBySector(current) : null;

  return (
    <>
      <Container className={`${condition} next-sector-${winnerSector} ${classes}`}>
        <animated.div style={styles}>
          <GameRouletteReel />
        </animated.div>
        <div>
          <GameRoulettePointer condition={condition} sector={current} />
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.75rem;
  transform: translate3d(0, -27%, 0);

  @media screen and (min-width: 500px) {
    --roulette-gradient-reel-size: 430px;
    --roulette-reel-size: 450px;
  }

  &.PENDING,
  &.RAFFLING,
  &.RESULT {
    &.highlight-blue .roulette-gradient-reel.sector-blue,
    &.highlight-red .roulette-gradient-reel.sector-red,
    &.highlight-green .roulette-gradient-reel.sector-green,
    &.highlight-gold .roulette-gradient-reel.sector-gold {
      opacity: 1;
    }
  }

  &.RESULT {
    &.next-sector-blue .roulette-gradient-reel.sector-blue,
    &.next-sector-red .roulette-gradient-reel.sector-red,
    &.next-sector-green .roulette-gradient-reel.sector-green,
    &.next-sector-gold .roulette-gradient-reel.sector-gold {
      animation: x50-pulse-sector-animation 1s infinite;
    }
  }

  & > div {
    position: absolute;
    width: 0;
    height: 0;

    & .roulette-gradient-reel {
      min-width: var(--roulette-gradient-reel-size);
      min-height: var(--roulette-gradient-reel-size);
      opacity: 0;
      transition: all 300ms linear;
      z-index: 4;
    }

    & svg {
      min-width: var(--roulette-reel-size);
      min-height: var(--roulette-reel-size);
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
      z-index: 6;
    }
  }

  @keyframes x50-pulse-sector-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
