import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Timer } from '@components/TimerEvent';
import { CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import { IModalComponent } from 'core/Modals';
import { SignIn } from 'core/Auth';
import { useUser } from 'core/User/hooks';
import { StoreSkin } from '../../Store';
import { useCouponsFacade, useInviteModal, useParticipationAction } from '../../../hooks';
import { BetConditions } from '../../../interfaces/reducer.leaders.interfaces';

export const InviteRaffle: FC<IModalComponent> = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const tickets = useCouponsFacade();
  const [handleParticipation] = useParticipationAction();
  const { handleCloseModal, item, betConditions } = useInviteModal(data);

  const disabled = !!item.winnerId || betConditions?.status === BetConditions.success;
  const combination = !!item?.items?.length;
  const clamp = Math.floor(item.membersLimit - item.membersLimit * 0.1);
  const lock = item.membersIds?.length === item.membersLimit && !!item.membersLimit;

  return (
    <Container
      className={`${item.ticketColor} ${combination ? 'combination' : 'single'}`}
      condition={betConditions?.status}
      limit={item.membersLimit}
      members={item.membersIds.length}
      clamp={clamp}
    >
      <div className="buffer-container" onClick={handleCloseModal} />
      <div className="wrapper">
        <div className="lot-info">
          <span className="lot-info__detailed">
            {item?.items?.length + 1 || 1}{' '}
            {t('items_interval', {
              count: item?.items?.length + 1 || 1,
              postProcess: 'interval',
            })}
            <span className="lot-info__detailed-currency">
              <CurrencyText value={item.coinsAmount} />
            </span>
          </span>
          <span className="lot-info__until-timer">
            {item.raffleAt && <Timer finishEvent={item.raffleAt} />}
          </span>
          <ul className="lot-info__rarity-list">
            <li style={{ background: item.color || 'white' }} />
            {combination &&
              item.items.map((lot, idx) => (
                <li key={idx} style={{ background: lot.color || 'white' }} />
              ))}
          </ul>
          <span className="lot-info__all-members">
            {!!item.membersLimit && (
              <>
                <i className="icon-users" />
                {item.membersIds?.length || 0}/{item.membersLimit}
              </>
            )}
          </span>
        </div>
        <div className="lot-grid">
          <StoreSkin
            name={item.name}
            icon={item.icon}
            color={item.color}
            appId={item.appId}
            available
          />
          {combination &&
            item.items.map((lot: any, idx) => (
              <StoreSkin
                key={idx}
                name={lot.name}
                icon={lot.icon}
                color={lot.color}
                ticketColor={lot.color}
                ticketsAmount={lot.ticketsAmount}
                appId={lot.appId}
                available
              />
            ))}
        </div>
        <div className="inner-container">
          <div className="lot-description">
            <span className="total-price">
              {t('Participate for')}
              <i className="icon-coupon" />
              {item.ticketsAmount}
            </span>
            {tickets[item.ticketColor] < item.ticketsAmount && user.id && (
              <span className="limited-info">
                {t("You don't have enough tickets", {
                  context: item.ticketColor,
                })}
              </span>
            )}
          </div>
          <div className="lot-actions">
            {!user.id && <SignIn className="primary full" />}
            {tickets[item.ticketColor] >= item.ticketsAmount && user.id && (
              <Button
                className="primary"
                disabled={disabled || lock}
                onClick={handleParticipation(item.raffleId, item.id)}
              >
                {t('Participate')}
              </Button>
            )}
            {tickets[item.ticketColor] < item.ticketsAmount && user.id && (
              <>
                {['green', 'blue'].includes(item.ticketColor) && (
                  <Button className="primary" as={Link} to="/leaders-race-next">
                    {t('go to quests')}
                  </Button>
                )}
                {'orange' === item.ticketColor && (
                  <Button className="primary" as={Link} to="/refill">
                    {t('REFILL BALANCE')}
                  </Button>
                )}
              </>
            )}
            <Button className="link-light" onClick={handleCloseModal}>
              {t('Close')}
            </Button>
          </div>
        </div>
        <div className="successful-layout" onClick={handleCloseModal}>
          <div className="successful-layout__thumbnail">
            <i className="icon-users-plus" />
          </div>
          <div className="successful-layout__status">
            <span>{t('Done!')}</span>
            <span>{t('You got a place in the raffle')}</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

interface InviteRaffleStyles {
  condition?: BetConditions;
  limit?: number;
  members?: number;
  clamp?: number;
}

const Container = styled.div.attrs(({ limit, clamp, members, condition }: InviteRaffleStyles) => ({
  temporary: condition === BetConditions.temporary,
  pending: condition === BetConditions.pending,
  clamp: !!clamp && members >= clamp && 'var(--yellow)',
  limit: limit === members && 'var(--color-red)',
}))<InviteRaffleStyles>`
  display: block;
  width: 100%;
  height: 100vh;
  overflow: ${p => (p.temporary ? 'hidden' : 'scroll')};

  &::-webkit-scrollbar {
    display: none;
  }

  &.green {
    --ticket-color: var(--regular-race);
  }
  &.blue {
    --ticket-color: var(--fast-race);
  }
  &.orange {
    --ticket-color: var(--great-race);
  }

  & .buffer-container {
    display: block;
    width: 100%;
    height: calc(100vh - 276px - 100vw / 2.9);
    //height: calc(100vh - 24.125rem);
    //
    //@media screen and (min-width: 375px) {
    //  height: calc(100vh - 25.3125rem);
    //}
  }

  &.single {
    & .lot-grid {
      align-self: center;
      justify-self: center;
      & > button {
        grid-column: auto !important;
      }
    }
  }

  & .wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 100;
    border-top: solid 1px var(--gray-250);
    background-color: var(--color-dark-350);

    & .inner-container {
      position: sticky;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background-color: rgba(24, 34, 40, 0.97);
    }

    & .lot-grid,
    & .lot-description,
    & .lot-actions {
      display: grid;
      grid-gap: 0.6875rem;
    }

    & .lot-info {
      display: grid;
      grid-template-columns: 1fr 0.5fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 0.25rem;
      align-items: flex-end;
      padding: 1.75rem 1rem 1rem;
      height: 5.75rem;

      color: white;

      & span:nth-child(even) {
        justify-self: self-end;
      }

      &__detailed,
      &__detailed-currency {
        font-size: 1.125rem;
        line-height: 1.4375rem;
        color: white;
      }

      &__detailed-currency {
        font-weight: bold;
        margin-left: 0.5rem;
      }

      &__until-timer {
        align-self: self-end;
        font-size: 0.75rem;
        line-height: 1rem;
      }

      &__rarity-list {
        display: flex;
        flex-flow: row;
        align-self: center;

        & li {
          width: 0.25rem;
          height: 0.25rem;
          margin-right: 0.125rem;
          border-radius: 1px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &__all-members {
        color: var(--color-gray-200);

        &.member-edge {
          color: var(--yellow);
        }
        &.member-limited {
          color: var(--color-red);
        }

        & .icon-users {
          font-size: 1.1rem;
          margin-right: 0.125rem;
          vertical-align: bottom;
        }
      }
    }

    & .lot-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 0.3125rem;
      width: 100%;
      padding: 0 1rem 1rem;

      &::before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      & > :first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    }

    & .lot-description {
      grid-gap: 0.6875rem;
      padding: 1.25rem 1rem;
      color: white;

      & .total-price {
        justify-self: center;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: white;

        & .icon-coupon {
          margin: 0 0.25rem 0 0.6875rem;
          color: var(--ticket-color);
        }
      }

      & .limited-info {
        font-size: 0.8125rem;
        line-height: 0.9375rem;
        letter-spacing: 0.286px;
        text-align: center;
      }
    }

    & .lot-actions {
      grid-gap: 0.5rem;
      grid-template-rows: 1fr 1fr;
      padding: 0.5rem 1rem 0.6rem;
    }

    & .successful-layout {
      display: grid;
      grid-auto-rows: min-content;
      grid-auto-flow: row;
      grid-gap: 2rem;
      align-content: center;
      padding: 4rem 0 10rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(24, 34, 40, 0.97);
      z-index: 100;
      opacity: 0;
      pointer-events: none;
      transition: all 150ms ease-in;

      &__thumbnail,
      &__status {
        justify-self: center;
      }

      ${p =>
        p.temporary &&
        `
        opacity: 1;
        pointer-events: auto;
      `};

      &__thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 2px solid var(--yellow);
        position: sticky;
        bottom: 30%;

        & i {
          font-size: 2rem;
          color: var(--yellow);
        }
      }

      &__status {
        display: flex;
        flex-flow: column;
        color: white;
        position: sticky;
        bottom: 17%;

        & span:first-of-type {
          font-size: 1.25rem;
          line-height: 1.4375rem;
          text-align: center;
          margin-bottom: 0.9rem;
        }

        & span:last-of-type {
          font-size: 0.75rem;
        }
      }
    }
  }
`;
