/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'JUEGO',
  Chat: 'Chat',
  'Email Support': 'Soporte por Correo Electrónico',
  'Live Support Chat': 'Chat de Soporte en Vivo',
  History: 'Historial',
  Top: 'Arriba',
  Rules: 'Rules',
  'At stake': 'En el juego',
  Amount: 'Amount',
  Accept: 'Accept',
  'To write a message you need to sign in': 'Para escribir un mensaje tiene que iniciar sesión',
  'Only players who have played the game are allowed to write':
    'Sólo los jugadores que han participado en el juego pueden escribir',
  'You are banned': 'Usted está bloqueado',
  Reason: 'Motivo',
  'Time left': 'Tiempo que queda',
  'Time to summarizing': 'Hora de resumir',
  spam: 'spam',
  'advertising (auto)': 'publicidad (auto)',
  advertising: 'publicidad',
  rudeness: 'grosería',
  begging: 'mendicidad',
  Search: 'Search',
  Sound: 'Sound',
  Today: 'Hoy',
  Yesterday: 'Ayer',
  'Last year': 'El último año',
  'Show my wins': 'Mostrar mis victorias',
  'History is empty': 'El historial está vacío',
  Cancel: 'Cancelar',
  Delete: 'Borrar',
  Close: 'Cerrar',
  Confirm: 'Confirmar',
  'Are you sure to delete all notifications?':
    '¿Seguro que quieres borrar todas las notificaciones?',
  'Log in': 'Iniciar sesión',
  'Log in to try your luck': 'Inicie sesión para aprobar su suerte',
  'try your luck!': '¡Apruebe su suerte!',
  'Take your prize': 'Obtenga su premio',
  'Sell for': 'vender por',
  BACK: 'BACK',
  'Warning!': '¡Atención!',
  'Congratulations!': '¡Felicitaciones!',
  'You have won': 'Tu victoria',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    '¡Felicitaciones! La oferta comercial con su premio ha sido enviada a su cuenta.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.',
  'The trade will be cancelled after one hour': 'La operación será cancelada dentro de una hora',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Después de que tu pedido sea tramitado, puedes recoger tus ganancias en la página de entregas de Steam exchange',

  'Round number': 'Número de ronda',
  'Round hash': 'Hash de la ronda',
  'Hash salt': 'Resumen criptográfico aleatorizado',

  'Refill amount': 'Cantidad de recarga',
  Bonus: 'Bonificación',
  'Bonus experience': 'Experiencia bonificada',
  'Bonus wheel spins': 'Giros de ruleta de bonificación',
  'Bonus cases': 'Cajas de bonificación',

  'Trick or treat?': 'Truco o trato?',
  'Keep your halloween tip jars ready!': '¡Mantenga listos sus frascos de consejos de Halloween!',
  'Bonuses on every balance refill': 'Bonificaciones en cada recarga de saldo',

  '${currency(amount)} bonus is ready!': '${currency(amount)} bonus är klar!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Påfyll balans och få upp till ${currency(amount)} i gratissnurr och fall',

  'Get bonus': 'conseguir ahora',
};
