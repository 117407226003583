import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { ParticipateControllerChildren, ParticipateModule, ParticipateType } from 'core/interfaces';
import { useParticipateController, useParticipateSettings } from 'core/hooks';
import { getPercent } from 'core/utils/percent.util';
import { RadialProgressBar } from 'dialogs/participate-dialog';
import {
  useClassicGame,
  useClassicParticipateController,
  useGamePrize,
  useUserTrade,
} from '../../../hooks';
// import { AccumulatedLotteryTickets } from './accumulated-lottery-tickets';

const types = [ParticipateType.INVENTORY];

interface IParticipateController {
  module: ParticipateModule;
  children: (props: ParticipateControllerChildren) => JSX.Element;
}

export const ParticipateController: FC<IParticipateController> = ({ children, module }) => {
  const { t } = useTranslation();
  const [modifier, controllers] = useParticipateController(ParticipateType.INVENTORY);
  const { settings } = useParticipateSettings(ParticipateModule.CLASSIC);
  const { handleCreateParticipate } = useClassicParticipateController();
  const { prize } = useGamePrize();
  const { trade } = useUserTrade();
  const { game } = useClassicGame();

  const amount = modifier.participate.amount;

  const disabled = useMemo(() => {
    const size = modifier.participate[ParticipateType.INVENTORY].size;
    const tradeSize = trade?.items?.length ?? 0;
    const total = size + tradeSize;

    return (
      amount < settings.min_bet ||
      total > settings.max_items_per_trade ||
      total < settings.min_items_per_trade ||
      !!game.rand
    );
  }, [settings, modifier.participate, amount, trade, game.rand]);

  return (
    <>
      {/* <AccumulatedLotteryTickets amount={amount} visible={!trade?.id} /> */}
      <Container>
        <div className="participate-title">{t('Place a bet')}</div>
        <div className="participate-wrapper">
          <button
            className="participate-clean-up"
            onClick={() => controllers[modifier.type].handleCleanupParticipate(modifier.type)}
          >
            <i className="icon-close" />
          </button>
          <div className="participate-container">
            <span className="amount">
              <CurrencyText value={amount} />
            </span>
            <span className="settings">
              {t('{{ current }} to {{ max_items_per_trade }} items', {
                current:
                  modifier.participate[ParticipateType.INVENTORY].size +
                  (trade?.items?.length ?? 0),
                max_items_per_trade: settings.max_items_per_trade,
              })}
            </span>
            <span className="percent">{getPercent(amount, prize + amount)}%</span>
          </div>
          <div className="participate-confirm">
            <button
              onClick={handleCreateParticipate(modifier.participate[ParticipateType.INVENTORY])}
              disabled={disabled}
            >
              <i className="icon-checked" />
            </button>
            {!!game.timeOldEnds && !game.rand && (
              <RadialProgressBar
                key={game.timeOldEnds}
                finishAt={game.timeOldEnds}
                duration={settings.timeout_ms}
              />
            )}
          </div>
        </div>
        {children({ types, module, modifier, controllers })}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.25rem;
  background: var(--color-dark-600);
  position: relative;
  z-index: 10;
  border-top: 1px solid var(--color-gray-900);

  & .participate-title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-align: center;
    color: var(--color-white);
    height: 2.25rem;
  }

  & .participate-wrapper {
    display: grid;
    grid-template-columns: 3rem 1fr 3rem;
    align-self: stretch;
    grid-gap: 1.25rem;
    padding: 0 2.125rem;
    height: 5.75rem;

    & .participate-clean-up {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      justify-items: center;
      align-items: center;
      align-self: center;
      justify-self: center;
      padding: 0;
      background: transparent;
      border: 2px solid var(--color-gray-300);
      color: var(--color-gray-300);
      transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

      &:disabled {
        border-color: var(--color-dark-100);
        color: var(--color-dark-100);
      }

      & i {
        font-size: 1.5rem;
      }
    }

    & .participate-container {
      display: grid;
      grid-auto-rows: min-content min-content 1fr;
      grid-auto-flow: row;
      width: 100%;
      height: 5.75rem;
      padding: 0.5rem 0.625rem;
      background: var(--color-dark-200);
      border-radius: var(--size-radius-m);

      & .amount,
      & .settings,
      & .percent {
        justify-self: self-start;
        color: var(--color-white);
      }

      & .amount,
      & .percent {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }

      & .settings {
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.1px;
        text-transform: none;
        color: var(--color-gray-200);
      }

      & .percent {
        font-weight: bold;
        align-self: self-end;
      }
    }

    & .participate-confirm {
      align-self: center;
      justify-self: center;
      width: 3rem;
      height: 3rem;
      position: relative;

      & > button {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        justify-items: center;
        align-items: center;
        padding: 0;
        border: transparent;
        background: var(--color-green);
        transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
        position: relative;
        z-index: 10;

        &:disabled {
          background: var(--color-dark-100);
          color: var(--color-dark-600);
        }

        & i {
          font-size: 1rem;
        }
      }
    }
  }
`;
