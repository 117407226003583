import React, { Component } from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import BezierEasing from 'bezier-easing';
import { getItemUri, getUserAvatarUrl } from '../../../../core/utils/image.utils';
import { withTranslation } from 'react-i18next';
import SoundService from './../../../../services/sound.service';

import bgPrize from './../../assets/weapon-frame.png';
import { URLS } from 'services/constant-urls';

class Tape extends Component {
  constructor() {
    super();
    this.timer = null;
    this.winnerTimer = null;
    this.state = {
      // стартовая позиция, нужна для корректного подсчета позиции ленты,
      // если компонент появился в момент анимации.
      animationIsRunning: false,
      startPosition: 0,
    };
    this.animatedValue = new Animated.Value(0);
    this.sound = new SoundService();
  }

  componentDidMount() {
    const { gameIsOver } = this.props;
    if (!gameIsOver) {
      this.startGameSound();
      this._startAnimation(this.props);
    }
  }

  componentDidUpdate() {
    if (this.props.isVisible) {
      this.sound.play('Classic', 'Roulette ticking', { repeat: false });
    }
  }

  shouldComponentUpdate(props) {
    return props.isVisible !== this.props.isVisible;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.winnerTimer);
    this.sound.play('Classic', 'Roulette ticking', { repeat: false });
  }

  render() {
    const {
      // лента и позиция победителя
      tape,
      tapePosition,
      // окончина ли игра & номер текущей игры.
      gameIsOver,
      gameNumber,
      // информация о победителе и победной вещи, демонстрация регулируется isVisible,
      // которая тогглится окончанием анимации
      isVisible,
      profile,
      t,
    } = this.props;
    const { startPosition } = this.state;

    const playersTapePosition = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [startPosition, tapePosition],
      useNativeDriver: true,
    });

    return (
      <Container>
        <div style={{ height: '60%' }}>
          <Game>
            {t('GAME')}: #{gameNumber}
          </Game>
          <Winner>
            {t('Winner')}: {!isVisible ? ' ??' : <div>{profile.playerName}</div>}
          </Winner>
        </div>
        <CenterLine>
          <List>
            <Animated.div
              style={{
                ...styleAnimatePosition,
                transform: [
                  {
                    translateX: gameIsOver ? tapePosition : playersTapePosition,
                  },
                ],
              }}
            >
              {tape.map((item, index) => (
                <Img key={index} src={getUserAvatarUrl(item)} />
              ))}
            </Animated.div>
          </List>
          <ArrowBlock>
            <Arrow />
          </ArrowBlock>
        </CenterLine>
        {isVisible ? (
          <Prize>
            <PrizeBg>
              <PrizeImg
                src={
                  profile.item &&
                  getItemUri(this.itemIconUrl(profile.item.icon || profile.item.itemImage), 85, 70)
                }
              />
            </PrizeBg>
            <Ticket>
              {' '}
              #{profile.item && profile.tickets[0]} - #{profile.item && profile.tickets[1]}
            </Ticket>
          </Prize>
        ) : (
          <Null />
        )}
      </Container>
    );
  }

  itemIconUrl = icon => {
    return typeof icon === 'string' ? icon : URLS.moneyIconUrl;
  };

  animation() {
    this.setState({ animationIsRunning: true });
    const timePosition = this._calculateTimePosition();
    this.animatedValue.setValue(0);
    const createAnimation = (value, duration, easing, delay = 0) =>
      Animated.timing(value, { toValue: 1, duration, easing, delay });

    return createAnimation(this.animatedValue, timePosition, BezierEasing(0.32, 0.64, 0.45, 1));
  }

  /**
   * Подсчитывает позицию ленты, если компонент появился в момент анимации
   * */
  _calculateTimePosition = () => {
    const { animationTimeout, timeOldEnds, tape, appDiffTime } = this.props;
    const passedTime = Date.now() + appDiffTime - timeOldEnds;
    if (passedTime <= 0) return animationTimeout;

    const steps = tape.length - 12;
    const position = BezierEasing(0.64, 0.32, 1, 0.45);
    const arr = [];

    for (let i = 0; i < steps; i++) {
      arr.push(position(i / steps) * animationTimeout);
    }

    const startItems = arr.filter(item => item > passedTime);
    const startIndex = arr.indexOf(startItems[0]);

    this.setState({
      startPosition: startIndex * -60,
    });
    return animationTimeout - passedTime;
  };

  _startAnimation = () => {
    if (this.timer === null) {
      const timePosition = this._calculateTimePosition();
      this.timer = setTimeout(() => {
        this.props.gameActions.showWinner();
        clearTimeout(this.timer);
      }, timePosition);
    }

    this.animation().start(e => e.finished && this.setState({ animationIsRunning: false }));
  };

  startGameSound = () => {
    const { timeOldEnds, appDiffTime } = this.props;
    const passedTime = Date.now() + appDiffTime - timeOldEnds;
    this.sound.play('Classic', 'Game ending');
    this.sound.play('Classic', 'Roulette ticking', {
      from: (passedTime - 2000) / 1000,
    });
  };
}

const CenterLine = styled.div`
  width: 100%;
  overflow: hidden;
  height: 75px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  flex-wrap: wrap;
`;

const PrizeBg = styled.div`
  background: url(${bgPrize});
  height: 50px;
  width: 50px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styleAnimatePosition = {
  position: 'absolute',
  left: '50%',
  display: 'flex',
};

const Null = styled.div`
  height: 60px;
  width: 100%;
`;

const Container = styled.div`
  width: 73%;
  height: 65%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  padding-top: 7px;
  overflow: hidden;
`;

const Game = styled.div`
  width: 100%;
  text-align: center;
  color: var(--color-white);

  font-size: 11px;
  padding-top: 7px;
`;

const Winner = styled.div`
  color: #e2e204;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 11px;

  font-size: 13px;
`;

const Arrow = styled.div`
  width: 15px;
  height: 10px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: #ff0;
  margin-top: 1px;
`;

const Prize = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 11px;
  width: 100%;
`;
const Ticket = styled.div`
  width: 100%;
  color: var(--color-white);

  text-align: center;
  margin-top: -13px;
`;

const PrizeImg = styled.img`
  min-width: 35px;
  width: 35px;
  height: 35px;
`;

const Img = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;
`;

const ArrowBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const List = styled.div`
  width: 100%;
  overflow: hidden;
  height: 60px;
  display: flex;
`;

export default withTranslation()(Tape);
