import * as React from 'react';
import styled from 'styled-components';
import { useCouponsFacade } from '../hooks';

export const Balance: React.FC = () => {
  const { green, blue, orange } = useCouponsFacade();
  return (
    <Container>
      <i className="icon-coupon" />
      <span>{green}</span>/<span>{blue}</span>/<span>{orange}</span>
    </Container>
  );
};

const Container = styled.div`
  color: var(--color-white-600);
  font-weight: inherit;

  & i.icon-coupon {
    margin-right: 0.2rem;
    color: inherit;
    font-size: 0.8rem;
  }

  span {
    color: var(--fast-race);
    font-weight: inherit;
  }

  & span:first-of-type {
    color: var(--regular-race);
  }

  & span:last-of-type {
    color: var(--yellow);
  }
`;
