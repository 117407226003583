import React, { FC } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '@components/currency';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { IReferralCampaign } from '../../../../../../interfaces';
import { useTranslation } from 'react-i18next';

export const ReferralCampaign: FC<IReferralCampaign> = ({
  name,
  referralsCount,
  totalIncome,
  notTakenIncome,
  id,
  reports,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  return (
    <Container to={`${match.url}/${id}`} as={Link}>
      <div className="referral-status">
        <span>{t('campaign statuses.status', { context: 'active' })}</span>
      </div>
      <div className="referral-name">{name}</div>
      <div className="referral-profit">
        <span>{t('Profit')}</span>
        <span>
          <CurrencyText value={totalIncome} />
          {reports?.incomes > 0 && (
            <span>
              +<CurrencyText value={reports?.incomes} />
            </span>
          )}
        </span>
      </div>
      <div className="referral-members">
        <span>{t('Referrals')}</span>
        <span>
          {referralsCount?.toLocaleString()}
          {reports?.referrals > 0 && <span>+{reports?.referrals}</span>}
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template: 'referral-status referral-status' 'referral-name referral-name' 'referral-profit referral-members';
  grid-template-columns: 1fr 0.8fr;
  padding: 1.375rem 1.5625rem 1.25rem;
  height: 8.75rem;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);

  & .referral-status span,
  & .referral-name {
    color: var(--color-white);
  }

  & .referral-status {
    grid-area: referral-status;

    & span {
      font-weight: bold;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
    }
  }

  & .referral-name {
    grid-area: referral-name;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .referral-profit,
  & .referral-members {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr 1fr;

    & span {
      align-self: self-end;

      &:first-of-type {
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.3px;
        color: var(--color-gray-200);
      }

      &:last-of-type {
        font-size: 1rem;
        line-height: 1.1875rem;
        color: var(--color-white);

        & > span {
          margin-left: 0.25rem;
          font-size: 0.75rem;
          line-height: 0.9375rem;
          letter-spacing: 0.1px;
          color: #00f900;
          vertical-align: text-top;
        }
      }
    }
  }

  & .referral-profit {
    grid-area: referral-profit;
  }

  & .referral-members {
    grid-area: referral-members;
  }
`;
