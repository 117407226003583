export default {
  '/settings': 'Setări',
  '/marketplace': 'Piață',
  '/balance': 'Sold',
  '/auth': 'Autorizare',
  '/notifications': 'Notificări',

  RULES: 'REGULI',
  GAME: 'JOC',
  Top: 'Sus',
  HISTORY: 'ISTORIC',
};
