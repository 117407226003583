import { GameMember } from '../interfaces';

export const getPlayersObjectFromArray = (players: GameMember[]) => {
  const playersObjOfArray: Record<number, GameMember[]> = {};
  players.forEach(player => {
    if (playersObjOfArray[player.team]) {
      playersObjOfArray[player.team].push(player);
    } else {
      playersObjOfArray[player.team] = [player];
    }
  });
  return playersObjOfArray;
};
