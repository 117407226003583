export const rouletteColors: string[] = [
  '#b22227',
  '#30d8d8',
  '#b3792c',
  '#41b32f',
  '#942bb6',
  '#d62026',
  '#432bd5',
  '#d6258c',
  '#08e45a',
  '#2bb7b8',
  '#a02428',
  '#a1702c',
  '#87a431',
  '#bc2bd8',
  '#26956e',
  '#382aa6',
  '#2158a4',
  '#a02772',
  '#29a6a8',
  '#6a282d',
  '#317130',
  '#5a2a76',
  '#2c8e5d',
  '#2e2a76',
  '#b2267b',
  '#6a2958',
  '#247377',
  '#214675',
  '#d78c2d',
  '#71842b',
  '#b2267b',
  '#432bd5',
  '#96b531',
  '#2369d3',
  '#d6258c',
  '#30d8d8',
  '#8d252a',
  '#8e662d',
  '#799430',
  '#225db3',
  '#782a96',
  '#352a95',
  '#2ba05e',
  '#215294',
  '#8d286a',
  '#5b7230',
  '#279597',
  '#6a552f',
  '#30c56c',
  '#3b2ab6',
  '#38922f',
];

export const selectDoubleWinningColor = (number: number): string =>
  number === 0 ? '#449d44' : number <= 7 ? '#c9302c' : '#000000';

export const selectCrashColor = (coef: number): string => {
  return coef < 1.5 ? '#ff0017' : coef < 3 ? '#2bb1ce' : coef < 20 ? '#f5e253' : '#33cc66';
};

export const rarityColors = (rarity: string): string => {
  if (/★|контрабандное|contraband/gi.test(rarity)) {
    return 'rgb(246, 228, 70)';
  }

  if (/stattrak™/gi.test(rarity)) {
    return 'rgb(207, 106, 50)';
  }

  if (/армейское|mil-spec/gi.test(rarity)) {
    return '#3A5BFF';
  }

  if (/запрещенное|restricted/gi.test(rarity)) {
    return 'rgb(135, 73, 255)';
  }

  if (/засекреченное|classified/gi.test(rarity)) {
    return 'rgb(212, 45, 186)';
  }

  if (/тайное|covert/gi.test(rarity)) {
    return 'rgb(227, 65, 50)';
  }
  return 'rgb(255,255,255)';
};

export const selectFastGameSlotColor = (index: number): string => {
  switch (index) {
    case 0:
      return '#f7e62d';
    case 1:
      return '#33ffff';
    case 2:
      return '#8cde89';
    default:
      return 'transparent';
  }
};

export const getExteriorByFloat = (float: number): string => {
  if (float > 0.45) return 'battle-scarred';
  if (float > 0.38) return 'well-worn';
  if (float > 0.15) return 'field-tested';
  if (float > 0.07) return 'minimal-wear';
  return 'factory-new';
};
export const colorDictionaryForCaseItem: Record<string, string> = {
  '#ACBFD4': '#213137', //дефолтный
  '#B0C3D9': '#273546', //дефолтный
  '#5E98D9': '#1D2E41', //дефолтный
  '#5E97D8': '#1A2C40', //светло-синий
  '#4662E8': '#222345', //синий
  '#4B69FF': '#1E2D4E', //синий
  '#8546F9': '#2E1F41', //фиолетовый
  '#8847FF': '#2F2241', //фиолетовый
  '#D32CE6': '#3F1F41', //розово-фиолетовый
  '#D22CE5': '#351B3A', //розовый
  '#FA8072': '#2F222B', //бледно-красный
  '#EB4B4B': '#513333', //ярко-красный
};
