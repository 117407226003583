import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { getColorBySector, SECTORS } from '../../../../utils';

export const GameRouletteGradientReel: FC = memo(({ children }) => (
  <>
    {SECTORS.map((sector, idx) => (
      <svg
        key={sector}
        className={`sector-${getColorBySector(sector)} roulette-gradient-reel`}
        viewBox="0 0 279 275"
        style={{
          transform: `translate3d(-50%, -50%, 0) rotate(${idx * 6.666666666666667 + 2}deg)`,
        }}
      >
        <linearGradient
          id={`gradient-wrapper-${getColorBySector(sector)}`}
          x1="136.653"
          y1="245.907"
          x2="136.653"
          y2="274.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#var(--stop-color-0)" stopOpacity="0" />
          <stop offset="1" stopColor="var(--stop-color-1)" />
        </linearGradient>
        <path
          d="M143.615 246.545C140.068 246.484 136.521 246.282 133.066 245.907L129.691 274.079C134.238 274.579 138.849 274.859 143.524 274.92L143.615 246.545Z"
          fill={`url(#gradient-wrapper-${getColorBySector(sector)})`}
        />
      </svg>
    ))}
    <Container />
    {children}
  </>
));

const Container = styled.div`
  width: var(--roulette-gradient-reel-size);
  height: var(--roulette-gradient-reel-size);
  border-radius: 50%;
  position: absolute;
  z-index: 5;
  transform: translate3d(-50%, -50%, 0);

  &:before {
    content: '';
    display: block;
    width: calc(var(--roulette-gradient-reel-size) + 0.25rem);
    height: calc(var(--roulette-gradient-reel-size) + 0.25rem);
    background: rgba(19, 26, 29, 0.4);
    backdrop-filter: blur(2px);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border: 1rem solid rgba(19, 26, 29, 0.3);
  }
`;
