import React, { FC } from 'react';
import { AppBar } from '@components/bars';
import { Privacy } from 'modules/PersonalArea/components/Settings/Privacy';

export const PrivacyPage: FC = () => (
  <>
    <AppBar deep />
    <Privacy />
  </>
);
