import React, { FC } from 'react';
import { ParticipateLimits } from './participate-limits';
import { ParticipateTypeSwitch } from './participate-type-switch';
import { ParticipateTypePanels } from './participate-type-panels';
import { ParticipateControllerChildren } from 'core/interfaces';

export const Participate: FC<ParticipateControllerChildren> = ({
  types,
  module,
  modifier,
  controllers,
}) => (
  <>
    <ParticipateTypePanels
      modifier={modifier}
      types={types}
      controllers={controllers}
      module={module}
    />
    <ParticipateTypeSwitch
      type={modifier.type}
      types={types}
      handleChangeType={modifier.handleChangeType}
    />
    <ParticipateLimits module={module} />
  </>
);
