import { config as springConfig } from 'react-spring';

export enum userModals {
  SIGN_IN_FULFILLED = 'SIGN_IN_FULFILLED',
  STEAM_SETTINGS = 'STEAM_SETTINGS',
  STEAM_BINDING = 'STEAM_BINDING',
  MAILBOX_BINDING = 'MAILBOX_BINDING',
  TRADES_DURATIONS_SETTINGS = 'TRADES_DURATIONS_SETTINGS',
  USER_SETTING_UP_STEAM_ACCOUNT = 'USER_SETTING_UP_STEAM_ACCOUNT',
  STEAM_API_DIALOG = 'STEAM_API_DIALOG',
  STEAM_TRADE_LINK = 'STEAM_TRADE_LINK',
  REFERRAL_BONUS_MODAL = 'REFERRAL_BONUS_MODAL',
  REFERRAL_BONUS_MODAL_NOT_AUTH = 'REFERRAL_BONUS_MODAL_NOT_AUTH',
  FREE_CASE_BONUS = 'FREE_CASE_BONUS',
  REFILL_COUNTRY = 'REFILL_COUNTRY',
  USER_PROFILE = 'USER_PROFILE',
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  PROFILE_SETTINGS_AVATAR = 'PROFILE_SETTINGS_AVATAR',
  PROFILE_PREMIUM = 'PROFILE_PREMIUM',
  PROFILE_FREEZE = 'PROFILE_FREEZE',
  PROFILE_EMAIL = 'PROFILE_EMAIL',
  PROFILE_CHANGE_EMAIL = 'PROFILE_CHANGE_EMAIL',
  PROFILE_SOCIAL = 'PROFILE_SOCIAL',
  SOUNDS_SETTINGS = 'SOUNDS_SETTINGS',
  PAYMENT_PROCESS = 'PAYMENT_PROCESS',
  STEAM_LINK_ACCOUNT = 'STEAM_LINK_ACCOUNT',
}

export const config = {
  [userModals.SIGN_IN_FULFILLED]: {
    from: {
      transform: 'scale(0.2) translate(-50%, -50%)',
      opacity: 0,
      top: '10%',
      left: '10%',
    },
    enter: {
      transform: 'scale(1) translate(-50%, -50%)',
      opacity: 1,
      top: '50%',
      left: '50%',
    },
    leave: {
      transform: 'scale(0.2) translate(-50%, -50%)',
      opacity: 0,
      top: '10%',
      left: '10%',
    },
    duration: (_: any, state: string) =>
      state === 'leave' ? { ...springConfig.stiff, delay: 700 } : springConfig.gentle,
  },
};
