import React from 'react';
import styled from 'styled-components';
import { useUser, useUserExperience } from 'core/User';
import { Button } from '@components/Common';
import { CurrencyText } from '@components/currency';
import { useModal } from 'core/Modals/hooks';
import { useTranslation } from 'react-i18next';
import { useExperiences } from 'core/AppShell';

import star from 'assets/images/star.png';
import { profileModals } from '../../modal.config';
import { useDispatch } from 'react-redux';
import { userActionsTypes } from 'core/User/interfaces';
import SoundService from 'services/sound.service';

const sound = new SoundService();

export const LevelUpRewardModal = () => {
  const { user } = useUser();
  const { experience, handleTakeLevel } = useUserExperience();
  const { experiences } = useExperiences();
  const { t } = useTranslation();
  const { handleToggleModal } = useModal(profileModals.LEVEL_UP);
  const dispatch = useDispatch();

  const reward = experiences.get(experience.level + 1);

  const handleLevelUp = () => {
    handleTakeLevel();
    sound.play('commonSounds', 'Level up');
    dispatch({ type: userActionsTypes.UPDATE_USER_LEVEL });
  };

  return (
    <Container onClick={handleToggleModal(profileModals.LEVEL_UP, false)}>
      <RewardContainer className="modal-content">
        <span className="level-up_chevron">{(reward && reward.rank) || user.chevron}</span>

        <img
          className="level-up_chevron-image"
          src={(reward && reward.chevronSrc) || user.chevronImage}
          alt=""
        />

        <LevelUpPrize>
          <span className="level-up_prize-append" />

          {reward &&
            (reward.prize.type === 'reward' ? (
              <CurrencyText value={reward.prize.reward} />
            ) : (
              <>
                {reward.prize.name}
                {` x${reward.prize.count}`}
              </>
            ))}

          <span className="level-up_prize-prepend" />
        </LevelUpPrize>
      </RewardContainer>
      <div className="modal-controller">
        <Button className="primary full small" onClick={handleLevelUp}>
          {t('Pick up')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;

  &:before {
    content: '';
    display: block;
    width: 100vh;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: -50%;

    transform: translate(-50%, 0);
    transform-origin: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${star});
    background-position: 50% 50%;
    z-index: -1;
  }

  & .modal-content {
    flex: 1;
  }

  & .modal-controller {
    margin: 1rem;
  }
`;

const RewardContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5rem 70px 40px 1fr;
  grid-auto-flow: row;
  grid-gap: 1rem;

  &:before,
  &:after {
    content: '';
  }

  & .level-up_chevron {
    justify-self: center;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: 0.33px;
    color: var(--color-white);
  }

  & .level-up_chevron-stars {
  }

  & .level-up_chevron-image {
    justify-self: center;
    align-self: center;

    width: auto;
    height: 45px;
    object-fit: contain;
  }
`;

const LevelUpPrize = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  background: var(--color-gray-900);
  position: relative;
  justify-self: center;

  font-size: 1rem;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.24px;
  color: var(--color-white);

  & .level-up_prize-prepend,
  & .level-up_prize-append {
    display: block;
    width: 20px;
    position: absolute;
    top: 0;
    bottom: 0;

    &:before,
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      display: flex;
      background: var(--color-gray-900);
    }
  }

  & .level-up_prize-append {
    left: -20px;

    &:before {
      clip-path: polygon(100% 0, 0 0, 100% 100%);
    }

    &:after {
      clip-path: polygon(100% 0, 0 100%, 100% 100%);
    }
  }

  & .level-up_prize-prepend {
    right: -20px;

    &:before {
      clip-path: polygon(100% 0, 0 0, 0 100%);
    }

    &:after {
      clip-path: polygon(100% 100%, 0 0, 0 100%);
    }
  }
`;
