import * as React from 'react';
import { msToCoef } from '../crash-game.utils';
import PropTypes from 'prop-types';

export default CoefficientComponent =>
  class CoefCalc extends React.PureComponent {
    static propTypes = {
      crashed: PropTypes.bool.isRequired,
      appDiffTime: PropTypes.number.isRequired,
      gameRunTs: PropTypes.number.isRequired,
      gameRun: PropTypes.bool.isRequired,
    };

    state = {
      currentCoef: 0,
    };
    coefTimer = null;

    componentDidMount() {
      if (this.props.gameRun) {
        this.setCoefficientTimer();
      }
    }

    componentDidUpdate(prevProps) {
      const { coef, crashed, gameRun } = this.props;
      if (crashed && this.coefTimer) {
        clearInterval(this.coefTimer);
        this.coefTimer = null;
        this.setState({ currentCoef: coef });
      }

      if (!crashed && !gameRun) {
        this.setState({ currentCoef: 0 });
      }
      if (gameRun && !crashed && this.state.currentCoef === 0) {
        this.setCoefficientTimer();
      }
    }

    componentWillUnmount() {
      clearInterval(this.coefTimer);
    }

    render() {
      return (
        <CoefficientComponent
          {...this.props}
          {...this.state}
          cancelInterval={this.cancelInterval}
        />
      );
    }

    setCoefficientTimer() {
      this.coefTimer = setInterval(() => {
        const { appDiffTime, gameRunTs } = this.props;
        this.setState({
          currentCoef: msToCoef(appDiffTime + Date.now() - gameRunTs) || 0,
        });
      }, 150);
    }

    cancelInterval = () => {
      const { userBet } = this.props;
      clearInterval(this.coefTimer);
      this.setState({ currentCoef: userBet.coef || userBet.coefAutoStop });
      this.coefTimer = null;
    };
  };
