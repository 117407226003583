import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PlayerAvatar } from '@components/Common';
import { CurrencyText } from '@components/currency';
import type { IPlayingBet } from '../../interfaces';

export const Player: FC<IPlayingBet> = ({ user, type, amount, reward, factor }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="player-description">
        <PlayerAvatar uri={user.avaLink} userId={user.id} />
        <span>{user.name}</span>
      </div>
      <div className="player-factor">
        {t('amount on type', {
          type,
          amount,
          postProcess: 'with-template',
        })}
      </div>
      <div className="player-coefficient">x{Number(factor)}</div>
      <div className="player-profit">
        <CurrencyText value={reward} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  font-size: 0.65rem;

  & .player-description {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 0.7rem;
    justify-self: start;
    align-items: center;

    & > div {
      width: 40px;
      height: 40px;
    }

    & span {
      font-weight: 400;
      color: var(--color-white);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  & .player-factor {
    font-weight: bold;
    color: var(--color-white);
  }

  & .player-coefficient,
  & .player-profit {
    color: var(--color-white);
  }
`;
