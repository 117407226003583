export default {
  Language: 'Idioma',
  'Bets in current room': 'Apuestas en el salón actual',
  Timers: 'Temporizadores',
  'Game start': 'Inicio del juego',
  'Game ending': 'Terminación del juego',
  'Roulette ticking': 'Sonido de ruleta',
  'Roulette start': 'Puesta en marcha de la ruleta',
  'Item set': 'Item set',
  'My bet': 'Mi apuesta',
  'Coins transfer': 'Transferencia de monedas',
  'Start button': 'Botón de inicio',
  'Stop button': 'Botón de parada',
  Countdown: 'Cuenta atrás',
  Soundtrack: 'Pista de audio',
  Crash: 'Choque',
  'Picking item': 'Eligiendo objeto',
  Crafting: 'Creando',
  Failure: 'Fallo',
  Win: 'Ganar',
  Sell: 'Vender',
  Take: 'Coger',
  'Rooms bets': 'Apuestas de los salones',
  'Chat message sending': 'Envío de mensaje en el chat',
  'Chat message receiving': 'Recepción de mensaje en el chat',
  Sound: 'Sonido',
  'Chat sound': 'Sonido del chat',
  'Game sounds': 'Sonido del juego',
  Volume: 'Volumen',
  steamApi: {
    noKey: 'SIN CLAVE DE API DE STEAM',
    need: 'Necesario para vender e intercambiar skins con otros jugadores',
    addLink: 'AGREGAR ENLACE',
    link: '¿DÓNDE OBTENERLO?',
  },
};
