import styled from 'styled-components';

export const IconContainer = styled.div`
  border-radius: 10px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  position: absolute;
  gap: 15px;
  width: 100%;
  transition: bottom 0.3s, opacity 0.3s;

  &[data-roulette-status='hide'] {
    opacity: 0;
    bottom: 0;
    pointer-events: none;
  }

  &[data-roulette-status='show'] {
    opacity: 1;
    bottom: 80px;
  }
`;
