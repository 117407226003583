import { config, SpringConfig } from 'react-spring';
import { CSSProperties } from 'react';

export enum transitions {
  SLIDE_UP = 'SLIDE_UP',
  TRANSPARENT_SLIDE_UP = 'TRANSPARENT_SLIDE_UP',
  SLIDE_UP_CENTER = 'SLIDE_UP_CENTER',
  SCALABLE = 'SCALABLE',
  FULL_SCREEN = 'FULL_SCREEN',
  SHOW_NAV = 'SHOW_NAV',
}

interface ITransitionConfig {
  from: CSSProperties & { layout: number };
  enter: CSSProperties & { layout: number };
  leave: CSSProperties & { layout: number; zIndex?: number };
  duration: (duration: SpringConfig) => SpringConfig | any;
}

export const transitionsConfig: Record<transitions, ITransitionConfig> = {
  [transitions.FULL_SCREEN]: {
    from: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    enter: {
      transform: 'translate3d(0, 0, 0)',
      layout: 1,
    },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
      zIndex: -1,
    },
    duration: (duration: SpringConfig = config.default) => duration,
  },
  [transitions.SLIDE_UP]: {
    from: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    enter: {
      transform: 'translate3d(0, 0, 0)',
      layout: 1,
    },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    duration: (duration: SpringConfig = config.default) => duration,
  },
  [transitions.TRANSPARENT_SLIDE_UP]: {
    from: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    enter: {
      transform: 'translate3d(0, 0, 0)',
      layout: 0.1,
    },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    duration: (duration: SpringConfig = config.default) => duration,
  },
  [transitions.SLIDE_UP_CENTER]: {
    from: {
      transform: 'translate3d(0, 300%, 0)',
      layout: 0,
      bottom: '50%',
    },
    enter: {
      transform: 'translate3d(0, 50%, 0)',
      layout: 1,
      bottom: '50%',
    },
    leave: {
      transform: 'translate3d(0, 300%, 0)',
      layout: 0,
      bottom: '50%',
    },
    duration: (duration: SpringConfig = config.default) => duration,
  },
  [transitions.SCALABLE]: {
    from: {
      transform: 'scale(0.9) translate3d(0, 50%, 0)',
      opacity: 0,
      bottom: '50%',
      layout: 0,
    },
    enter: {
      transform: 'scale(1) translate3d(0, 50%, 0)',
      opacity: 1,
      bottom: '50%',
      layout: 1,
    },
    leave: {
      transform: 'scale(0.9) translate3d(0, 50%, 0)',
      opacity: 0,
      bottom: '50%',
      layout: 0,
    },
    duration: (duration?: SpringConfig) => (_: any, state: string) =>
      state === 'leave' ? { ...config.stiff, ...duration } : config.gentle,
  },
  [transitions.SHOW_NAV]: {
    from: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    enter: {
      transform: 'translate3d(0, 0, 0)',
      layout: 1,
    },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
      layout: 0,
    },
    duration: (duration: SpringConfig = config.default) => duration,
  },
};
