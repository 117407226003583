import React from 'react';

export const Progress = ({ progressWidth }: { progressWidth: number }) => {
  return (
    <svg
      width={`${progressWidth - 8}`}
      viewBox={`0 0 ${progressWidth} 12`}
      height="12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className="progress"
    >
      <path
        d={`M0 4C0 1.79086 1.79086 0 4 0H${progressWidth - 4}C${
          progressWidth - 1.79086
        } 0 ${progressWidth} 1.79086 ${progressWidth} 4V6C${progressWidth} 8.20914 ${
          progressWidth - 1.79086
        } 10 ${progressWidth - 4} 10H4C1.79086 10 0 8.20914 0 6V4Z`}
        fill="url(#paint0_linear_32140_281891)"
      />
      <path
        d={`M0 4C0 1.51472 2.01472 -0.5 4.5 -0.5H${progressWidth - 4.5}C${
          progressWidth - 2.015
        } -0.5 ${progressWidth} 1.51472 ${progressWidth} 4C${progressWidth} 2.067 ${
          progressWidth - 1.79086
        } 0.5 ${
          progressWidth - 4
        } 0.5H4C1.79086 0.5 0 2.067 0 4ZM${progressWidth} 6C${progressWidth} 8.48528 ${
          progressWidth - 2.015
        } 10.5 ${
          progressWidth - 4.5
        } 10.5H4.5C2.01472 10.5 0 8.48528 0 6C0 7.933 1.79086 9.5 4 9.5H${progressWidth - 4}C${
          progressWidth - 1.79086
        } 9.5 ${progressWidth} 7.933 ${progressWidth} 6ZM0 10V0V10ZM${progressWidth} 0V10V0Z`}
        fill="#07666B"
        mask="url(#path-1-inside-1_32140_281891)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_32140_281891"
          x1="56.9997"
          y1="-0.495332"
          x2="56.9997"
          y2="12.9675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14D9E3" />
          <stop offset="0.8314" stopColor="#07666B" />
          <stop offset="1" stopColor="#0A9CA4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
