import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { fetchTakeReferralIncome } from '../../ducks/referral.duck';

export const useReferralIncomeController = () => {
  const dispatch = useDispatch();

  const handleTakeIncome = useCallback(() => {
    dispatch(fetchTakeReferralIncome());
  }, [dispatch]);

  return { handleTakeIncome };
};
