import React, { FC } from 'react';
import styled from 'styled-components';
import { DefaultContainer } from './combo-line.styles';
import { IComboLineComponent } from './combo-line.interface';

export const OneToThree: FC<IComboLineComponent> = ({ className, children }) => (
  <Container className={className}>
    <div className="left-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="line-indicator" />
    <div className="right-indicator" />
    {children}
  </Container>
);

const Container = styled(DefaultContainer)`
  &.line-1 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 5;
    }
  }

  &.line-2 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 2;
    }
  }

  &.line-3 {
    & .left-indicator,
    & .line-indicator,
    & .right-indicator {
      grid-row: 8;
    }
  }
`;
