import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AnimatedRouter } from '@components/animated-router';
import { CaseItemInfoPage, CaseListPage, CasesGame, RulesPage } from './pages';

export const GameRouter: FC<RouteComponentProps> = React.memo(({ match }) => {
  return (
    <div className="content">
      <AnimatedRouter>
        <Route path={`${match.url}/rules`} component={RulesPage} />
        <Route path={`${match.url}/:caseId`} exact component={CasesGame} />
        <Route path={`${match.url}/item/:itemId`} component={CaseItemInfoPage} />
        <Route path={match.url} exact component={CaseListPage} />
      </AnimatedRouter>
    </div>
  );
});
