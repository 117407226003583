import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonStatistics,
  Container,
  Info,
  MyStatistics,
  MyStatisticsInfo,
  MyStatisticsTickets,
  PlayerCard,
  PlayerInfo,
  StatisticsTickets,
  Title,
  Total,
} from './leader-board-item.styles';
import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import { MyStats, Players } from 'modules/event-module/comics-event/duck/event-results-duck';
import { getUserAvatarUrl } from 'core/utils';
import { useUser } from 'core/User';
import { TicketTypeEnum } from 'modules/event-module/comics-event/interfaces';

interface LeaderBoardItemProps {
  color: TicketTypeEnum;
  firstTop: number;
  myStats: MyStats;
  players: Players[];
  secondTop: number;
  totalTickets: number;
}

export const LeaderBoardItem: FC<LeaderBoardItemProps> = ({
  color,
  firstTop,
  myStats,
  players,
  secondTop,
  totalTickets,
}) => {
  const { t } = useTranslation();
  const {
    user: { name, avatar },
  } = useUser();

  const coinColor = {
    [TicketTypeEnum.DAILY]: (width: number, height: number) => (
      <img src={eventImagesPath.bronzeDailyPath} alt="" width={width} height={height} />
    ),
    [TicketTypeEnum.WEEKLY]: (width: number, height: number) => (
      <img src={eventImagesPath.silverWeeklyPath} alt="" width={width} height={height} />
    ),
    [TicketTypeEnum.SPECIAL]: (width: number, height: number) => (
      <img src={eventImagesPath.goldSpecialPath} alt="" width={width} height={height} />
    ),
  };

  return (
    <Container>
      <Info>
        {coinColor[color](54, 54)}
        <div>
          <Title>{t('collected')}</Title>
          <Total>{totalTickets}</Total>
        </div>
      </Info>
      {!!name && (
        <MyStatistics>
          <MyStatisticsInfo>
            <span className="you">{t('you')}</span>
            <img className="avatar" src={getUserAvatarUrl(avatar, 24)} alt="" />
            <span className="my-place">
              {`#${myStats?.place > 998 ? '999+' : myStats?.place} ${name}`}
            </span>
          </MyStatisticsInfo>
          <MyStatisticsTickets>
            {coinColor[color](24, 24)}
            <span>{myStats?.tickets}</span>
          </MyStatisticsTickets>
        </MyStatistics>
      )}
      <CommonStatistics>
        {players?.map(player => (
          <PlayerCard key={player.place}>
            <PlayerInfo>
              <span className="place">{player.place}</span>
              <img className="avatar" src={getUserAvatarUrl(player.user.avatar, 24)} alt="" />
              <span className="name">{player.user.name}</span>
            </PlayerInfo>
            <StatisticsTickets>
              {coinColor[color](24, 24)}
              <span className="tickets">{player.tickets}</span>
            </StatisticsTickets>
          </PlayerCard>
        ))}
        {firstTop > 0 && (
          <PlayerCard>
            <PlayerInfo>
              <span className="place">
                {players.length + 1} - {firstTop}
              </span>
              <div className="points" />
            </PlayerInfo>
            <StatisticsTickets>
              {coinColor[color](24, 24)}
              <span className="tickets">{players[players.length - 1].tickets - 1}</span>
            </StatisticsTickets>
          </PlayerCard>
        )}
        {secondTop > 0 && (
          <PlayerCard>
            <PlayerInfo>
              <span className="place">
                {firstTop + 1} - {secondTop}
              </span>
              <div className="points" />
            </PlayerInfo>
            <StatisticsTickets>
              {coinColor[color](24, 24)}
              <span className="tickets">{players[players.length - 1].tickets - 2}</span>
            </StatisticsTickets>
          </PlayerCard>
        )}
      </CommonStatistics>
    </Container>
  );
};
