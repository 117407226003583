import styled from 'styled-components';
import { StepStyleContainer } from '../@shared/step-style-container';

export const Container = styled(StepStyleContainer)`
  & div {
    &.pattern input,
    &.validate input {
      border: 2px solid var(--color-red);
    }
  }
`;
