import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { ACTION_TYPE } from '../actionType';
import { CaseItemInfoDetailedState } from '../interfaces';
import { catchError, map, switchMap } from 'rxjs/operators';
import casesRepository from '../../repository/casesRepository';
import { merge, of } from 'rxjs';
import {
  CaseItemDetailedInfo,
  CasesWhereLocatedItem,
  CasesWhereLocatedItemNormalized,
  NormalizesSimilar,
} from '../../interfaces/cases.interface';
import { ActionCaseItemInfoRequest, ActionResponses } from './case-item-info.interfaces';

export const actionCaseItemInfoRequest = (id: string, name: string) => ({
  type: ACTION_TYPE.CASE_ITEM_INFO_ACTION_REQUEST,
  payload: {
    id,
    name,
  },
});
export const actionCaseItemInfoDetailedResponse = (data: CaseItemDetailedInfo) => ({
  type: ACTION_TYPE.CASE_ITEM_INFO_ACTION_RESPONSE_DETAILED,
  payload: data,
});
const actionCaseItemInfoResponse = (data: CasesWhereLocatedItem[]) => ({
  type: ACTION_TYPE.CASE_ITEM_INFO_ACTION_RESPONSE,
  payload: data,
});

export const caseInfo: Epic = (action$: ActionsObservable<ActionCaseItemInfoRequest>) =>
  action$.pipe(
    ofType(ACTION_TYPE.CASE_ITEM_INFO_ACTION_REQUEST),
    switchMap(({ payload }) =>
      merge(
        casesRepository.fetchItemDataById(payload.id).pipe(
          map(({ response }: { response: CasesWhereLocatedItem[] }) =>
            actionCaseItemInfoResponse(response)
          ),
          catchError(() => of())
        ),
        of(payload).pipe(
          switchMap(({ name }) =>
            casesRepository.fetchItemDataDetailed2Dev(name).pipe(
              map(({ response }: { response: CaseItemDetailedInfo }) =>
                actionCaseItemInfoDetailedResponse(response)
              ),
              catchError(() => of())
            )
          )
        )
      )
    )
  );

export const caseItemInfoDetailed = (
  state: CaseItemInfoDetailedState = {
    data: {},
    isLoading: true,
    isLoadingSecond: true,
  },
  action: ActionResponses
) => {
  switch (action.type) {
    case ACTION_TYPE.CASE_ITEM_INFO_ACTION_RESPONSE: {
      const caseData: CasesWhereLocatedItemNormalized[] = action.payload.map(
        ({ name, lastRevision, backgroundImage, id, type, ...rest }) => ({
          name,
          price: lastRevision.price,
          backgroundImage: backgroundImage ?? rest.image,
          id,
          type,
        })
      );
      return {
        ...state,
        data: {
          ...state.data,
          casesWhereLocated: caseData,
        },
        isLoading: false,
      };
    }
    case ACTION_TYPE.CASE_ITEM_INFO_ACTION_REQUEST: {
      return {
        data: {},
        isLoadingSecond: true,
        isLoading: true,
      };
    }
    case ACTION_TYPE.CASE_ITEM_INFO_ACTION_RESPONSE_DETAILED: {
      const similar: Record<string, NormalizesSimilar> = {};

      action.payload.similar.forEach(({ exterior, img, shortName, skin, statTrak, steamPrice }) => {
        const obj: NormalizesSimilar = {
          exterior: exterior,
          img: img,
          shortName: shortName,
          skin: skin,
        };
        if (similar[exterior]) {
          if (statTrak) {
            similar[exterior].statTrakPrice = steamPrice;
          } else {
            similar[exterior].steamPrice = steamPrice;
          }
        } else {
          if (statTrak) {
            obj.statTrakPrice = steamPrice;
            similar[exterior] = obj;
          } else {
            obj.steamPrice = steamPrice;
            similar[exterior] = obj;
          }
        }
      });
      const similarArray = Object.values(similar).sort((a, b) => {
        if (a.steamPrice) {
          return a.steamPrice > b.steamPrice ? -1 : 1;
        } else {
          return a.statTrakPrice > b.statTrakPrice ? -1 : 1;
        }
      });
      return {
        data: {
          ...state.data,
          detailedInfo: { ...action.payload, similar: similarArray },
        },
        isLoadingSecond: false,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
