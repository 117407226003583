import React from 'react';
import {
  ButtonContainer,
  Close,
  Container,
  HeaderContainer,
  IconContainer,
  ListContainer,
  StyledButtonAddToBattle,
  StyledButtonOpenCase,
  Title,
} from './case-info.styles';
import { useGetCaseData } from 'games/CaseGame/hooks';
import { IDialogComponent, Modules } from 'core/interfaces';
import { CaseListItemDefault } from '@components/CaseListItemDefault';
import { useTranslation } from 'react-i18next';
import { CaseItemDefault, CaseItemContainer } from '@components/CaseItemDefault';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetIdsForCreateBattle } from 'games/CaseBattle/hooks';
import { addCase } from 'games/CaseBattle';

interface CaseInfoDialog extends IDialogComponent<boolean> {}

const emptyArray = [1, 2, 3, 4, 5, 6];

export const CaseInfo: React.FC<CaseInfoDialog> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCaseData();
  const dispatch = useDispatch();
  const history = useHistory();
  const { caseAmount } = useGetIdsForCreateBattle();

  const redirect = () => {
    handleCloseModal();
    history.push(`/cases/${data?.id}`, {
      name: data?.name,
      id: data?.id,
    });
  };

  const redirectToBattleCreate = () => {
    handleCloseModal();
    dispatch(addCase(data?.id, data?.lastRevision?.price));
    if (window.location.pathname !== `${Modules.CASE_BATTLE}/create-battle`) {
      history.push('/case-battle/create-battle');
    }
    //NOTE: router hardcode, check index.ts in game module CaseBattle to check current path for page
  };
  return (
    <Container>
      <HeaderContainer>
        {!isLoading ? (
          <IconContainer>
            <CaseListItemDefault
              caseType={data.type}
              free={!!data?.free}
              style={{ width: '60px', height: '64px' }}
              image={data?.free ? data.image ?? data?.backgroundImage : data?.backgroundImage}
              price={data.lastRevision.price}
            />
          </IconContainer>
        ) : (
          <IconContainer className="skeleton" />
        )}
        <Title>{data.name}</Title>
        <Close onClick={handleCloseModal}>{t('Close')}</Close>
      </HeaderContainer>
      <ListContainer>
        {!isLoading
          ? Object.values(data?.itemsForCaseShow).map(({ generalInfo, customItemInfo }) => (
              <CaseItemDefault
                key={generalInfo.id}
                isFree={false}
                caseType={data?.type}
                customItemInfo={customItemInfo}
                fullName={generalInfo.fullName}
                color={generalInfo.color}
                icon={generalInfo.icon}
                type={generalInfo.type}
              />
            ))
          : emptyArray.map(value => {
              return <CaseItemContainer key={value} className="skeleton" />;
            })}
      </ListContainer>
      <ButtonContainer>
        <StyledButtonOpenCase onClick={redirect}>{t('open')}</StyledButtonOpenCase>
        <StyledButtonAddToBattle disabled={caseAmount === 10} onClick={redirectToBattleCreate}>
          {t('addBattle')}
        </StyledButtonAddToBattle>
      </ButtonContainer>
    </Container>
  );
};
