import { AuthDialogs } from 'core/Auth/auth-dialog.config';
import { SigninDialog } from './signin-dialog';
import { DIALOG } from '../dialog-combiner';
import { transitions } from '../transition.config';

DIALOG.add(AuthDialogs.SIGN_IN, {
  component: SigninDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'portal',
});
