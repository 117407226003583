import styled from 'styled-components';

export const Container = styled.form`
  background: var(--color-dark-600);
  border-top: 1px solid var(--color-gray-900);
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  padding: 2.125rem 1rem 1.75rem;
  position: relative;

  & .close {
    font-weight: normal;
    position: absolute;
    right: 1rem;
    text-transform: none;
    top: 0.625rem;
  }

  & .dialog-title {
    color: var(--color-white);
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1rem;
  }

  & .inner-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 1.25rem;
    padding: 2rem 0 2.125rem;

    & .trade-link {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.625rem;
      grid-template-rows: 1rem min-content 0.85rem;

      & > span {
        color: var(--color-gray-200);
        font-size: 0.75rem;
        letter-spacing: 0.1px;
        line-height: 1rem;
        text-transform: uppercase;
      }

      & div {
        display: flex;
        justify-content: flex-end;

        & span {
          font-size: 0.625rem;
          font-weight: bold;
          line-height: 0.875rem;
        }

        & > a {
          color: var(--color-gray-100);
          font-size: 0.625rem;
          font-weight: bold;
          justify-self: self-start;
          letter-spacing: 0.3px;
          line-height: 0.875rem;
          text-transform: uppercase;
        }
      }

      &.validate,
      &.pattern {
        input {
          border: 2px solid var(--color-red);
        }

        & > div > span,
        & a {
          color: var(--color-red);
        }

        & div {
          justify-content: space-between;
        }
      }
    }
  }
`;
