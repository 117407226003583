import React, { Component } from 'react';
import styled from 'styled-components';
import * as Animated from 'animated/lib/targets/react-dom';
import { rouletteColors } from '../../../../core/utils/colors.utils';
import SoundService from './../../../../services/sound.service';
import { getItemUri, getUserAvatarUrl } from '../../../../core/utils/image.utils';
import { withTranslation } from 'react-i18next';
import { CurrencyText } from '../../../../@components/currency';
import { getPercent } from '../../../../core/utils/percent.util';
import { URLS } from 'services/constant-urls';

class PlayerList extends Component {
  state = {
    players: [],
  };

  static getDerivedStateFromProps({ players }) {
    const sorted = players.sort((l, r) => (l.totalPrice > r.totalPrice ? -1 : 1));
    return {
      players: players.map(item => ({
        ...item,
        offset: sorted.findIndex(el => el.id === item.id),
      })),
    };
  }

  render() {
    const { tapeStatus, t, totalUserPrize } = this.props;
    return (
      <Bets style={{ height: this.state.players.length * 70 }}>
        {this.state.players.map((item, index) => (
          <Row
            player={item}
            tapeStatus={tapeStatus}
            index={index}
            key={item.id}
            totalUserPrize={totalUserPrize}
            t={t}
          />
        ))}
      </Bets>
    );
  }
}

class _Row extends Component {
  constructor() {
    super();
    this.state = {
      durationTimeout: 750,
      openInfo: false,
      animateInfo: new Animated.Value(0),
    };
    this.sound = new SoundService();
    this.rowOpacity = new Animated.Value(0);
  }

  componentDidMount() {
    Animated.timing(this.rowOpacity, {
      toValue: 1,
      duration: this.state.durationTimeout,
    }).start();
    const {
      tapeStatus: { tapeIsVisible },
    } = this.props;
    tapeIsVisible === false && this.playMountSound();
  }

  render() {
    const { player, index, totalUserPrize, t } = this.props;
    const widthAnimate = this.state.animateInfo.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '80%'],
    });

    return (
      <Container style={{ transform: `translate3d(0, ${player.offset * 70}px, 0)` }}>
        <Animated.div className="inner-container" style={{ opacity: this.state.rowOpacity }}>
          <Bet theme={{ borderColor: rouletteColors[index] }}>
            <Animated.div
              style={{
                minWidth: widthAnimate,
                width: widthAnimate,
                overflow: 'hidden',
                height: '70px',
                position: 'relative',
                left: '-1px',
              }}
            >
              <Tets>
                <InfoBet theme={{ bg: rouletteColors[index] }}>
                  <div>
                    <Username>{player.playerName}</Username>
                    <InfoTicket>
                      {t('Tickets to from', {
                        from: player.tickets[0],
                        to: player.tickets[1],
                      })}
                    </InfoTicket>
                  </div>
                  <InfoBetSum>
                    <CurrencyText value={player.totalPrice} />
                  </InfoBetSum>
                </InfoBet>
              </Tets>
            </Animated.div>
            <div>
              <div onClick={this.handleInfoOpen}>
                <Avatar src={getUserAvatarUrl(player.playerImage)} />
                <Percent>{`${getPercent(player.totalPrice, totalUserPrize)}%`}</Percent>
              </div>
            </div>
            <Items>{this.renderRow(player.items)}</Items>
          </Bet>
        </Animated.div>
      </Container>
    );
  }

  renderRow(items) {
    return (
      <Scroll>
        {items.map((item, index) => (
          <Item key={index}>
            <ItemImg src={item.icon ? getItemUri(item.icon, 85, 70) : URLS.moneyIconUrl} />
            <Price>
              <CurrencyText value={item.price} />
            </Price>
          </Item>
        ))}
      </Scroll>
    );
  }

  handleInfoOpen = () => {
    const value = this.state.openInfo === true ? 0 : 1;
    Animated.timing(this.state.animateInfo, {
      toValue: value,
      duration: 400,
    }).start(this.setState({ openInfo: !this.state.openInfo }));
  };

  playMountSound() {
    this.sound.play('Classic', 'Bets in current room');
  }
}

const Row = withTranslation()(_Row);

const Container = styled.div`
  position: absolute;
  transition: transform 70ms linear;
  left: 0;
  right: 0;
  top: 0;

  & .inner-container {
    position: relative;
  }
`;

const Tets = styled.div`
  min-width: 250px;
`;

const InfoBetSum = styled.div`
  color: var(--color-white);

  font-size: 13px;
  padding-top: 10px;
`;

const InfoTicket = styled.div`
  color: var(--color-white);

  font-size: 13px;
`;

const Username = styled.div`
  color: var(--color-white);

  padding: 5px 5px 5px 0;
  font-size: 13px;
`;
const InfoBet = styled.div`
  width: ${props => props.theme.widthAnimate};
  overflow: hidden;
  background: ${props => props.theme.bg};
  min-height: 70px;
  max-height: 70px;
  padding: 10px 30px 10px 10px;
  display: flex;
  justify-content: space-between;
`;
const Scroll = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
`;

const Price = styled.div`
  color: var(--color-white);

  font-size: 11px;
`;

const Item = styled.div`
  margin: 2px 1px 2px 2px;
  text-align: center;
  min-width: 64px;
`;

const ItemImg = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

const Items = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
`;

const Percent = styled.div`
  font-size: 12px;
  text-align: center;
  color: var(--color-white);
`;

const Avatar = styled.img`
  min-width: 45px;
  height: 45px;
  margin: 5px 5px 0 5px;
`;

const Bet = styled.div`
  display: flex;
  width: 100%;
  background: var(--dark);
  align-items: center;
  border-left: 5px solid ${props => props.theme.borderColor};
`;

const Bets = styled.div`
  margin-top: 10px;
  position: relative;
`;

export default PlayerList;
