import React from 'react';
import { Container, FlagIconContainer, Icon, IconContainer } from './UserIcon.styles';
import { CaseBattleIcon } from 'games/CaseBattle/assets';
import { useDispatch } from 'react-redux';
import { loadProfile } from 'modules/Accounts/duck';
import { useGetCreatorId, useGetWinner } from 'games/CaseBattle/hooks';

interface UserIconProps {
  battleId: number;
  isJoker: boolean;
  userIcon?: string;
  userId?: number;
  userName?: string;
}

export const UserIcon: React.FC<UserIconProps> = React.memo(
  ({ battleId, userIcon, userId, userName, isJoker }) => {
    const dispatch = useDispatch();
    const { isWinner } = useGetWinner(battleId, userId);
    const { creatorId } = useGetCreatorId(battleId);

    const openProfile = () => {
      if (userId && !userName.toLocaleUpperCase().includes('BOT')) {
        dispatch(loadProfile(userId));
      }
    };
    return (
      <Container className={!userId && 'skeleton'} onClick={openProfile}>
        {userIcon ? <Icon src={userIcon} /> : <CaseBattleIcon.EmptyIcon />}
        {isWinner && (
          <IconContainer>
            {isJoker ? <CaseBattleIcon.Joker /> : <CaseBattleIcon.CrownIcon />}
          </IconContainer>
        )}
        {creatorId === userId && (
          <FlagIconContainer>
            <CaseBattleIcon.FlagIcon />
          </FlagIconContainer>
        )}
      </Container>
    );
  }
);
//TODO: сделать так чтобы при клике на пустое место ты занимал именно ту позицию что обновил ,
// можно сделать массив, в котором будет длинной количества игроков и ключи что не заняты станут хранить строку 'empty'
//[player, empty, player, player]
