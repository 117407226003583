import styled from 'styled-components';

export const Container = styled.div`
  & .line-1 {
    --color-combo-line: #28adca;
  }

  & .line-2 {
    --color-combo-line: #f20200;
  }

  & .line-3 {
    --color-combo-line: #29b81e;
  }

  & .line-4 {
    --color-combo-line: #e6aa02;
  }

  & .line-5 {
    --color-combo-line: #8bf7fd;
  }

  & .line-6 {
    --color-combo-line: #f5f800;
  }

  & .line-7 {
    --color-combo-line: #f540f7;
  }

  & .line-8 {
    --color-combo-line: #9ff78b;
  }

  & .line-9 {
    --color-combo-line: #f4f6fc;
  }

  & > div {
    height: 100%;
  }
`;
