export default {
  GAME: 'GAME',
  Winner: 'Winner',
  Won: 'Won',
  Chance: 'Chance',
  Prize: 'Prize',
  'At stake': 'At stake',
  'Sign in through STEAM to participate': 'Sign in through STEAM to participate',
  'Steam trade link': 'Steam trade link',
  'Your chance': 'Your chance',
  'Be first to deposit': 'Be first to deposit',
  DEPOSIT: 'DEPOSIT',
  'DEPOSIT MORE': 'DEPOSIT MORE',
  'Min. bet': 'Min. bet',
  'Max. bet': 'Max. bet',
  'max. items': 'max. items',
  hidden: 'hidden',
  Copy: 'Copy',
  'Winning after commission': 'Winning after commission',
  Loading: 'Loading...',
  'Waiting players': 'Waiting <br/> players',
};
