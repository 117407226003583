import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePayoutAggregator, usePayoutWallet } from '../../../hooks';
import { IWithdrawalGate } from '../../../interfaces/reducers.withdrawal.interface';
import { URLS } from 'services/constant-urls';
import { onErrorHandler } from 'core/utils';
import { Container, Type, Types } from './payout-input.styles';
import {
  ErrorSpan,
  Fields,
} from 'modules/Refill/components/refill-method/pay-controls/pay-controls.styles';
import { CustomInput } from 'modules/Refill/components/refill-method/custom-input';
import { IGetDataForWalletPix } from 'modules/Refill/hooks/useGetDataForWalletPix.hook';

const DOCUMENT_TYPE = ['CPF', 'CNPJ', 'PHONE', 'EMAIL', 'EVP'];
const ACCOUNT_TYPE = ['CPF', 'CNPJ'];

interface IPayoutInput {
  name: IWithdrawalGate['name'];
  regularExp: IWithdrawalGate['regularExp'];
  walletType: string;
  dataForWalletPix: IGetDataForWalletPix;
}

export const PayoutInput: FC<IPayoutInput> = ({
  name,
  regularExp,
  walletType,
  dataForWalletPix,
}) => {
  const { t } = useTranslation();

  const { aggregator } = usePayoutAggregator();

  const { updateWallet } = usePayoutWallet();

  const [fields, setValue] = useState({
    value: '',
    error: false,
    valid: false,
  });

  const {
    wallet,
    onChangeAccountType,
    onChangeDocumentType,
    onChangeDocument,
    onChangeTaxAccount,
    onChangeFullName,
    onChangeEmailAddress,
    accountType,
    documentType,
    document,
    taxAccount,
    fullName,
    emailAddress,
    documentFieldError,
    taxAccountFieldError,
    fullNameFieldError,
    emailAddressFieldError,
    errorDocumentField,
    errorTaxAccountField,
    errorFullNameField,
    errorEmailAddressField,
    incorrectEmailAddressField,
    emailAddressFieldIncorrect,
  } = dataForWalletPix;

  useEffect(() => {
    if (walletType === 'pix') {
      updateWallet(wallet);
    }
  }, [updateWallet, wallet, walletType]);

  const onChange = useCallback(
    ev => {
      setValue({
        value: ev.target.value,
        error: false,
        valid: false,
      });
    },
    [setValue]
  );

  const onBlur = useCallback(
    ev => {
      const reg = new RegExp(regularExp);
      if (reg.test(ev.target.value)) {
        updateWallet(ev.target.value);
        setValue(state => ({ ...state, valid: true }));
      } else {
        updateWallet('');
        setValue(state => ({
          ...state,
          error: true,
        }));
      }
    },
    [updateWallet, regularExp, setValue]
  );

  // useEffect(() => {
  //   changeAggregator(name)
  // }, [name, changeAggregator])

  useEffect(() => {
    setValue({
      value: '',
      error: false,
      valid: false,
    });
  }, [aggregator]);

  const onBlurEmail = useCallback(
    ev => {
      if (ev.target.value) {
        if (new RegExp(regularExp).test(ev.target.value)) {
          emailAddressFieldIncorrect(false);
        } else {
          emailAddressFieldIncorrect(true);
        }
      }
    },
    [emailAddressFieldIncorrect, regularExp]
  );

  return (
    <>
      {walletType === 'pix' ? (
        <Fields>
          <div className="withdraw">{t('accountType')}</div>
          <Types>
            {DOCUMENT_TYPE.map(item => (
              <Type
                key={item}
                isActive={item === accountType}
                onClick={() => onChangeAccountType(item)}
              >
                {item}
              </Type>
            ))}
          </Types>
          <div className="withdraw">{t('documentType')}</div>
          <Types>
            {ACCOUNT_TYPE.map(item => (
              <Type
                key={item}
                isActive={item === documentType}
                onClick={() => onChangeDocumentType(item)}
              >
                {item}
              </Type>
            ))}
          </Types>
          <div className="withdraw">{t('document')}</div>
          <CustomInput
            isError={errorDocumentField}
            onFocus={() => documentFieldError(false)}
            onChange={onChangeDocument}
            value={document}
            type="number"
            name="document"
          />
          {errorDocumentField && <ErrorSpan>{t('required')}</ErrorSpan>}
          <div className="withdraw">{t('taxAccount')}</div>
          <CustomInput
            isError={errorTaxAccountField}
            onFocus={() => taxAccountFieldError(false)}
            onChange={onChangeTaxAccount}
            value={taxAccount}
            type="number"
            name="taxAccount"
          />
          {errorTaxAccountField && <ErrorSpan>{t('required')}</ErrorSpan>}
          <div className="withdraw">{t('fullName')}</div>
          <CustomInput
            isError={errorFullNameField}
            onFocus={() => fullNameFieldError(false)}
            onChange={onChangeFullName}
            value={fullName}
            type="text"
            name="fullName"
          />
          {errorFullNameField && <ErrorSpan>{t('required')}</ErrorSpan>}
          <div className="withdraw">{t('emailAddress')}</div>
          <CustomInput
            isError={errorEmailAddressField}
            onFocus={() => emailAddressFieldError(false)}
            onChange={onChangeEmailAddress}
            onBlur={onBlurEmail}
            value={emailAddress}
            type="text"
            name="emailAddress"
            placeholder="example@mail.com"
          />
          {errorEmailAddressField && <ErrorSpan>{t('required')}</ErrorSpan>}
          {incorrectEmailAddressField && <ErrorSpan>{t('incorrectEmail')}</ErrorSpan>}
        </Fields>
      ) : (
        <Container error={fields.error}>
          <div className="input-title">
            <img
              src={`${URLS.refill.icon}/refill-${name}.svg`}
              alt=""
              onError={e => onErrorHandler(e, URLS.refill.error)}
            />
            <span>
              {t(name, {
                context: 'title',
                defaultValue: 'Enter your wallet',
                wallet: name,
              })}
            </span>
          </div>
          <input
            type={t(name, { context: 'type', defaultValue: 'text' })}
            value={fields.value}
            onBlur={onBlur}
            onChange={onChange}
            className={`${fields.valid ? 'validated' : ''}`}
          />
          <div>
            <span className="input-subtitle">
              {t(name, {
                context: 'description',
                defaultValue: 'wallet description',
                wallet: name,
              })}
            </span>
            <span className="input-error">
              {t(name, {
                context: 'incorrect',
                defaultValue: 'wallet incorrect',
                wallet: name,
              })}
            </span>
          </div>
        </Container>
      )}
    </>
  );
};
