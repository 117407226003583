import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { IDialogComponent } from 'core/interfaces';
import { useUserEmail } from 'core/User';
import { useHistory } from 'react-router-dom';

interface IWithdrawVerificationDialog extends IDialogComponent<boolean> {}

export const WithdrawVerificationDialog: FC<IWithdrawVerificationDialog> = ({
  handleCloseModal,
}) => {
  const { replace } = useHistory();
  const { t } = useTranslation();
  const { email } = useUserEmail();

  const onCloseDialog = useCallback(() => {
    handleCloseModal();
    setTimeout(() => {
      replace('/withdrawal/method');
    }, 500);
  }, [replace, handleCloseModal]);

  return (
    <Container>
      <div className="withdraw-status">
        <div className="thumbnail-image">
          <i className="icon-checked" />
        </div>
        <span className="dialog-title">{t('Almost done!')}</span>
        <span className="dialog-subtitle">
          {t('Email with a link to complete the withdrawal has been sent to {{ address }}', {
            address: email.address,
          })}
        </span>
      </div>
      <div className="withdraw-warn">
        <i className="icon-warn" />
        <p>
          {t(
            'Please note that the amount required for withdrawal should remain on your balance until the withdrawal is confirmed'
          )}{' '}
          {t(
            'If at the time of confirmation you do not have the required amount on the balance - the withdrawal will be canceled'
          )}
        </p>
      </div>
      <Button className="secondary-transparent" onClick={onCloseDialog}>
        {t('Confirm')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr repeat(2, min-content);
  grid-gap: 0.75rem;
  height: 100%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.85);

  & .withdraw-status {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    align-self: center;
    grid-gap: 1rem;
    padding-bottom: 2.25rem;

    & .thumbnail-image {
      justify-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5.25rem;
      height: 5.25rem;
      border-radius: 50%;
      border: 2px solid var(--warning);
      & .icon-checked {
        font-size: 1.5rem;
        color: var(--warning);
      }
    }

    & .dialog-title {
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: var(--color-white);
    }

    & .dialog-subtitle {
      font-size: 0.875rem;
      line-height: 1.5rem;
      text-align: center;
      letter-spacing: 0.24px;
      color: var(--color-white);
    }
  }

  & .withdraw-warn {
    display: grid;
    grid-template-columns: 1.75rem 1fr;
    grid-gap: 0.625rem;

    & i {
      justify-self: center;
      align-self: self-start;
      font-size: 1.75rem;
      color: var(--warning);
    }

    & p {
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.570769px;
      color: var(--color-white);
    }
  }
`;
