export default {
  Games: 'Spill',
  'Refill balance': 'Innskudd',
  Settings: 'innstillinger',
  Store: 'Butikk',
  'Get free money': 'Få gratis mynter',
  Notifications: 'Notifications',
  FAQ: 'FAQ',
  'Log out': 'Logg ut',
  'Steam trade link': 'Steam trade link',
  'Where can I get a link?': 'Hvor kan jeg skaffe linken?',
  'Input your trade link': 'Input your trade link',
  'Be sure to': 'Be sure to',
  'make your inventory public': 'make your inventory public',
  'in Steam to receive the prize!': 'in Steam to receive the prize!',
  'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.':
    'You were disconnected from CSGOFAST service. Please tap on screen to reconnect.',
  'App was offline. Check your internet connection to continue!':
    'App was offline. Check your internet connection to continue!',
  SAVE: 'SAVE',
};
