export enum GameCondition {
  PENDING = 'PENDING',
  RAFFLING = 'RAFFLING',
  RESULT = 'RESULT',
}

export enum SectorColors {
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  GOLD = 'gold',
}

export const getColorBySector = (sector: number): SectorColors => {
  if (sector === 0) return SectorColors.GOLD;
  if ([1, 9, 11, 19, 21, 33, 35, 43, 45, 53].includes(sector)) return SectorColors.GREEN;
  if ([3, 5, 7, 13, 15, 17, 23, 25, 27, 29, 31, 37, 39, 41, 47, 49, 51].includes(sector))
    return SectorColors.RED;
  return SectorColors.BLUE;
};

export const getFactorBySector = (sector: number): number => {
  if (sector === 0) return 50;
  if ([1, 9, 11, 19, 21, 33, 35, 43, 45, 53].includes(sector)) return 5;
  if ([3, 5, 7, 13, 15, 17, 23, 25, 27, 29, 31, 37, 39, 41, 47, 49, 51].includes(sector)) return 3;
  return 2;
};

export const SECTORS = [
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
];

export const getDegreeBySector = (sector: number): number => {
  const perOne = SECTORS.length / 54; // кол-во deg для одного сектора
  const perSector = 360 / 54;
  const indent = (perSector - perOne) / 2; // отступ по бокам от сектора
  const fromDeg = perSector * (sector - 1) + indent;
  const toDeg = perSector * sector - indent;
  const deg = Math.random() * (toDeg - fromDeg) + fromDeg;

  return 360 - (180 - deg);
};

export const getSectorByRand = (rand: string): number => Math.floor(parseFloat(rand) * 54);
