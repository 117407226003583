import { eventImagesPath } from 'modules/event-module/comics-event/assets';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-image: url(${eventImagesPath.balanceBannerPath});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--size-radius-l);
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  margin: 8px auto;
  min-height: 120px;
  width: 346px;

  @media (max-width: 360px) {
    min-height: 100px;
    width: 285px;
  }
`;

export const Description = styled.div`
  margin-top: 45px;
  background-color: #ffd11a;
  border-radius: var(--size-radius-m);
  display: flex;
  padding: 8px 16px;

  & > span {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.286px;
    line-height: 15.23px;
    text-align: center;
  }
`;
