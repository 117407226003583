import React, { PureComponent } from 'react';
import styled from 'styled-components';
import arrow from '../../../../modules/PersonalArea/assets/arrowYellow.svg';
import dayjs from 'dayjs';
import { getDataCard, getHandCardsAmount } from '../../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import SpoilerAnimate from '../../../../@components/Animates/Spoiler/';

import { getHistoryBaccarat } from '../../selectors';
import { addNotification } from '../../../../modules/Notifications/duck';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const dataWon = {
  P: {
    color: '#00bad9',
    text: 'PLAYER WON',
  },
  B: {
    color: '#f05228',
    text: 'BANKER WON',
  },
  T: {
    color: '#39b701',
    text: 'TIE',
  },
};

const History = () => {
  const history = useSelector(getHistoryBaccarat, shallowEqual);
  const dispatch = useDispatch();
  const addNotice = message => dispatch(addNotification(message));
  return (
    <div className="content">
      <ListItems>
        {history.slice(0, 20).map((item, i) => (
          <Item item={item} key={i} addNotice={addNotice} />
        ))}
      </ListItems>
    </div>
  );
};

class _Item extends PureComponent {
  state = {
    open: false,
  };

  render() {
    const data = dataWon[this.props.item.result[0]];
    const cards = JSON.parse(this.props.item.cards);
    const { addNotice, t } = this.props;
    return (
      <Block>
        <Header>
          <div>
            <Name>Game №{this.props.item.id}</Name>
            <Time>Today at {dayjs(this.props.item.created_at).format('h:mm')}</Time>
          </div>
          <div>
            <Winner color={data.color}>{data.text}</Winner>
          </div>
        </Header>
        <Content>
          <LineBlock>
            <Line />
          </LineBlock>
          <Info>
            <Row>
              <Circle color={'#00bad9'} border={'#00bad9'}>
                {getHandCardsAmount(cards.playerHand)}
              </Circle>
              {cards.playerHand.map((item, i) => {
                const dataByCard = getDataCard(item);
                return (
                  <Card
                    key={i}
                    value={dataByCard.value}
                    color={dataByCard.color}
                    img={dataByCard.img}
                  />
                );
              })}
            </Row>
            <Row>
              <Circle color={'transparent'} border={'#f05228'}>
                {getHandCardsAmount(cards.bankHand)}
              </Circle>
              {cards.bankHand.map((item, i) => {
                const dataByCard = getDataCard(item);
                return (
                  <Card
                    key={i}
                    value={dataByCard.value}
                    color={dataByCard.color}
                    img={dataByCard.img}
                  />
                );
              })}
            </Row>
          </Info>
        </Content>
        <HideContent>
          <SpoilerAnimate height={300} open={this.state.open}>
            <ControlGroup>
              <InputLabel>Hash</InputLabel>
              <Control>
                <InputControl>{this.props.item.hash}</InputControl>
                <CopyToClipboard
                  onCopy={() =>
                    addNotice({
                      type: 'success',
                      body: 'Copy hash',
                    })
                  }
                  text={this.props.item.hash}
                >
                  <InputControlAppend>{t('Copy')}</InputControlAppend>
                </CopyToClipboard>
              </Control>
            </ControlGroup>
            <ControlGroup>
              <InputLabel>Salt</InputLabel>
              <Control>
                <InputControl>{this.props.item.salt}</InputControl>
                <CopyToClipboard
                  text={this.props.item.salt}
                  onCopy={() =>
                    addNotice({
                      type: 'success',
                      body: 'Copy salt',
                    })
                  }
                >
                  <InputControlAppend>{t('Copy')}</InputControlAppend>
                </CopyToClipboard>
              </Control>
            </ControlGroup>
            <ControlGroup>
              <InputLabel>Cards</InputLabel>
              <Control>
                <InputControl>
                  {`BANKER:${cards.bankHand.toString()} PLAYER:${cards.playerHand.toString()}`}
                </InputControl>
                <CopyToClipboard
                  text={`BANKER:${cards.bankHand.toString()} PLAYER:${cards.playerHand.toString()}`}
                  onCopy={() =>
                    addNotice({
                      type: 'success',
                      body: 'Copy cards',
                    })
                  }
                >
                  <InputControlAppend>{t('Copy')}</InputControlAppend>
                </CopyToClipboard>
              </Control>
            </ControlGroup>
          </SpoilerAnimate>
        </HideContent>
        <ArrowBlock rotate={this.state.open ? -90 : 90} onClick={this._toggleOpenSpoiler}>
          <ArrowOpen src={arrow} />
        </ArrowBlock>
      </Block>
    );
  }

  _toggleOpenSpoiler = () => this.setState({ open: !this.state.open });
}

const Item = withTranslation()(_Item);

const Card = props => {
  return (
    <CardBg>
      <CardType color={props.color}>{props.value}</CardType>
      <CardImg src={props.img} />
    </CardBg>
  );
};

const HideContent = styled.div`
  padding: 10px 0 10px 0;
`;

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 20px;
`;

const InputLabel = styled.span`
  font-size: 14px;
  letter-spacing: 0.9px;
  color: var(--color-white);

  margin-bottom: 11px;
`;

const Control = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 40px;
`;

const InputControl = styled.div`
  border-radius: 4px 0 0 4px;
  background-color: #141418;
  overflow: hidden;
  padding: 12px 13px 7px;
  width: 60%;
  font-size: 14px;
  letter-spacing: 0.8px;
  color: #7c7d7f;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InputControlAppend = styled.button`
  border: 0;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  background-color: var(--yellow);
  font-size: 15px;
  color: #000000;
`;

const ArrowOpen = styled.img`
  width: 30px;
  height: 30px;
`;

const ArrowBlock = styled.div`
  transform: rotate(${props => props.rotate}deg);
  position: absolute;
  right: 5px;
  bottom: 0px;
`;

const Info = styled.div`
  padding: 5px 0 5px 0;
`;

const CardImg = styled.img`
  width: 18px;
  height: 17px;
`;

const CardType = styled.div`
  font-family: icomoon, Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: ${p => p.color};
`;

const CardBg = styled.div`
  width: 36px;
  height: 52px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 4px var(--color-white);
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 8px;
`;

const Circle = styled.div`
  width: 54px;
  height: 54px;
  border: 2px solid ${p => p.border};
  background-color: ${p => p.color};
  font-size: 24px;
  font-weight: bold;
  line-height: 1.17;
  color: var(--color-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  align-items: center;
  padding: 10px 0 10px 0;
  display: flex;
`;

const LineBlock = styled.div`
  max-width: 29px;
  min-width: 29px;
  margin-right: 9px;
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background: var(--color-white);
`;

const Content = styled.div`
  display: flex;
  padding: 10px 5px 5px 5px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div`
  font-size: 14px;
  line-height: 2;
  color: var(--color-white);
`;

const Time = styled.div`
  font-size: 14px;
  color: #8d9498;
`;

const Winner = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  color: ${p => p.color};
`;

const ListItems = styled.div`
  padding: 10px 0px 10px 0px;
  background: var(--color-light-dark-layout);
`;

const Block = styled.div`
  width: 100%;
  min-height: 240px;
  padding: 10px;
  background-color: #212f35;
  position: relative;
  margin-top: 15px;
`;

export default History;
