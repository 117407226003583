import styled from 'styled-components';

export const Container = styled.div`
  background: #1d2529;
  border-radius: var(--size-radius-m);
  display: flex;
  gap: 16px;
  padding: 4px 8px;
  justify-content: center;
  z-index: 2;
`;

export const CandyContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  & > span {
    color: #f7fbfc;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
  }
`;

export const Img = styled.img<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
