import React, { FC } from 'react';
import styled from 'styled-components';
import { AccountAvatar } from './AccountAvatar';

interface IPlayerAvatar {
  uri: string;
  userId?: number;
}

export const PlayerAvatar: FC<IPlayerAvatar> = ({ uri, userId }) => (
  <Container className="player-avatar">
    <AccountAvatar uri={uri} userId={userId} />
  </Container>
);

const Container = styled.div`
  width: 50px;
  height: 50px;
  position: relative;

  & > button {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid var(--color-white);
  }

  & img {
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    pointer-events: auto;
  }
`;
