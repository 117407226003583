export default {
  '100$ for the new players!': '新玩家100美元！',
  '100$ bonus is credited to a demo balance': '100美元奖金记入模拟余额',
  'Get 100$ now!': '立即获得$ 100！',

  'Top up your balance from 25$ and get bonuses': '从25美元充值并获得奖金',
  'Exp bonus': '经验奖金',
  'Wheel Of Fortune free spin': '命运之轮免费旋转',
  'Free case': '免费案例',
  'Access to account level-up': '访问帐户升级',
};
