import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LobbyGameColorDictionary, ModuleName } from './LobbyGame.config';

export const Container = styled(Link)<{
  gamename: ModuleName;
}>`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  z-index: 5;
  font-size: 1rem;
  color: var(--color-white);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  }

  & .last-added {
    color: var(--yellow);
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
  }
  ${({ gamename }) => LobbyGameColorDictionary[gamename]};
`;

export const GameIcon = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  max-width: 60px;
  max-height: 60px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export const GameTitle = styled.span`
  font-size: 0.9rem;
  color: var(--color-white);
`;

export const NewGameTitle = styled.span`
  color: var(--yellow);
  position: absolute;
  font-style: normal;
  padding: 5px;
  width: 100%;
  font-size: 13px;
  text-align: right;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.286px;
  top: 2px;
  right: 2px;

  @media screen and (max-width: 374px) {
    font-size: 10px;
  }

  @media screen and (max-width: 345px) {
    font-size: 8px;
  }
`;
