import styled from 'styled-components';

export const AnimatedContainer = styled.div`
  display: flex;
  align-items: flex-end;
  grid-area: collapse;
  position: relative;
  z-index: 0;
  background: var(--color-dark-layout);
`;

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 0.25rem;
  width: 100%;
  padding: 0.5rem 0 1.5rem;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > a,
  > div {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    gap: 7px;
    height: 3rem;
    background: var(--color-dark-200);
    border-radius: var(--size-radius-m);
    color: var(--color-white);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 125% */
    letter-spacing: 0.1px;
    white-space: nowrap;
    padding: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      width: 0.75rem;
      height: 3rem;
    }

    & > a {
      transition: background 0.4s cubic-bezier(0.33, 1, 0.68, 1);

      &:active {
        background: var(--color-gray-300);
      }
    }

    & > i[class^='icon'] {
      font-size: 1.1rem;
      color: var(--color-white);
    }
  }
`;

export const StyledTransitionGroup = styled.div`
  .tool-bar-animation-enter {
    transform: translate(-50%, -50%) scale(1, 0);
    opacity: 0.01;
    height: 0;
    transition: transform 350ms ease-out, height 350ms ease-out, opacity 300ms ease-in;
  }

  .tool-bar-animation-enter-active {
    transform: translate(0, 0) scale(1, 1);
    opacity: 1;
    height: 80px;
  }

  .tool-bar-animation-enter-done {
    transform: translate(0, 0) scale(1, 1);
    opacity: 1;
  }

  .tool-bar-animation-exit {
    transform: translate(0, -50%) scale(1, 1);
    opacity: 1;
    height: 80px;
  }

  .tool-bar-animation-exit-active {
    transform: translate(0, -100%) scale(1, 0);
    opacity: 0;
    height: 0;
    transition: transform 500ms ease-in, opacity 300ms ease-out, height 350ms ease-out;
  }
`;
