import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.3rem;
  padding: 1.2rem 0 1rem;

  & .game-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    flex-shrink: 0;
    padding: 0.5rem 0;
    position: relative;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
