import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--color-dark-600);
  border-radius: var(--size-radius-m);
  display: flex;
  flex-direction: column;
  min-width: 312px;
`;

export const Info = styled.div`
  display: flex;
  gap: 4px;
  padding: 12px 10px;

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.span`
  color: #9ea7b3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Total = styled.span`
  color: var(--color-white);
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
`;

export const MyStatisticsInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  overflow: hidden;

  & > .you {
    color: var(--color-gray-200);
    font-size: 14px;
    line-height: 20px;
  }

  & > .avatar {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  & > .my-place {
    color: var(--color-white);
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

export const MyStatistics = styled.div`
  align-items: center;
  background-color: var(--color-dark-200);
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
`;

export const MyStatisticsTickets = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  & > span {
    color: #f5f5f6;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CommonStatistics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 16px;
`;

export const PlayerCard = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const PlayerInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  overflow: hidden;
  width: 100%;

  & > .place {
    color: var(--color-gray-200);
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }

  & > .avatar {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  & > .name {
    color: var(--color-white);
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  & > .points {
    background-image: linear-gradient(to right, var(--color-gray-200) 25%, transparent 0%);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 8px 1px;
    padding-top: 9px;
    width: 100%;
  }
`;

export const StatisticsTickets = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  & > span {
    color: #f5f5f6;
    font-size: 14px;
    line-height: 20px;
  }
`;
