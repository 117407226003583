import React, { Component } from 'react';
import styled from 'styled-components';
import { CurrencyText } from '../../../../@components/currency/';
import { withTranslation } from 'react-i18next';

class _Control extends Component {
  state = {
    itemsId: [],
    itemsArr: [],
    betValue: 0,
  };

  static getDerivedStateFromProps(props) {
    const { cartItems } = props;
    const betEntities = cartItems.reduce(
      (dictionary, item) => {
        if (!!item) {
          return {
            itemsId: [...dictionary.itemsId, item.id],
            itemsArr: [...dictionary.itemsArr, { value: item.value }],
            betValue: dictionary.betValue + item.value,
          };
        }
        return dictionary;
      },
      {
        itemsId: [],
        itemsArr: [],
        betValue: 0,
      }
    );
    return {
      ...betEntities,
    };
  }
  render() {
    const {
      gameSettings: { min_bet, max_items_per_trade },
      t,
    } = this.props;
    const { betValue, itemsArr } = this.state;

    return (
      <Container>
        <BetInfo>
          {t('Min bet')} <CurrencyText value={min_bet * 100} />
          {t('max. items')}
          {` ${max_items_per_trade}`}
        </BetInfo>
        <ControlFlex>
          <CancelBtn onClick={this.handleGoBack} default>
            {t('Close')}
          </CancelBtn>
          <ConfirmBtn
            onClick={this.handleConfirm}
            disabled={betValue < min_bet * 100 || itemsArr.length > max_items_per_trade}
          >
            {t('ACCEPT')}
          </ConfirmBtn>
        </ControlFlex>
      </Container>
    );
  }

  handleConfirm = () => {
    const { type, gameSettings, confirmBet, confirmInventoryBet, currentGameTypes } = this.props;
    const { itemsArr, itemsId } = this.state;

    if (type === 'inventory') {
      confirmInventoryBet(gameSettings.id, currentGameTypes, itemsId);
      this.props.history.push(`/${gameSettings.name}`);
      return;
    }

    confirmBet(gameSettings.id, itemsArr);
    this.props.history.push(`/${gameSettings.name}`);
  };

  handleGoBack = () => {
    const { gameSettings } = this.props;
    this.props.history.push(`/${gameSettings.name}`);
  };
}

export const Control = withTranslation()(_Control);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;

  width: 100%;
  height: 90px;

  background-color: var(--color-gray-950);
  padding: 10px 18px 18px;

  position: sticky;
  bottom: 0;
`;
const BetInfo = styled.span`
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0px;
  color: #828c98;
`;

const ControlFlex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-top: 14px;
`;

const ConfirmBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 60%;
  height: 40px;
  background-color: var(--yellow);
  border: 0;
  border-radius: 5px;

  color: var(--color-gray-950);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
  }
`;

const CancelBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  height: 40px;
  background-color: transparent;
  border: 0;

  color: var(--color-white);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;
