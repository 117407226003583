import React, { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from '@components/Common';
import { useModuleSettings } from 'core/AppShell';
import { useUser } from 'core/User';
import { useDialog } from 'core/hooks';
import { AuthDialogs } from 'core/Auth';
import { CurrencyInput, CurrencyText, ValueSelector } from '@components/currency';
import { fetchCreateBid, resetGame } from '../../../duck';
import { AutoplayGameController } from './autoplay-game-controller';
import { useBonusesGame, useComboGame } from '../../../hooks';

interface ISlotGameControllers {}

export const SlotGameControllers: FC<ISlotGameControllers> = () => {
  const { user } = useUser();
  const { handleToggleDialog } = useDialog(AuthDialogs.SIGN_IN);
  const autoplayTimeout = useRef(null);
  const autoplayDelay = useRef(null);
  const { t } = useTranslation();
  const { gameLeft } = useBonusesGame();
  const { settings } = useModuleSettings('slot');
  const { combo } = useComboGame();
  const dispatch = useDispatch();
  const { handleSubmit, setValue, watch, formState, reset, getValues } = useFormContext();
  const values = watch(['selectedLines', 'betPerLine', 'autoplay']);

  const runAutoplay = useCallback(
    onSubmit => {
      autoplayTimeout.current = setTimeout(() => {
        const values = getValues();
        const next = values.autoplay - 1 > 0 ? values.autoplay - 1 : null;
        onSubmit({ ...getValues(), autoplay: next });
      }, combo.length * 1000);
    },
    [combo, getValues]
  );

  const onSubmit = useCallback(
    ({ selectedLines, betPerLine, autoplay }) => {
      dispatch(fetchCreateBid(selectedLines, betPerLine * 100));
      clearTimeout(autoplayDelay.current);
      clearTimeout(autoplayTimeout.current);

      autoplayDelay.current = setTimeout(() => {
        const values = getValues();
        const next = values.autoplay - 1 > 0 ? values.autoplay - 1 : null;
        reset({ ...values, autoplay: next }, { isSubmitted: next === null ? false : next >= 0 });
        if (next) runAutoplay(onSubmit);
        else autoplayTimeout.current = null;
      }, 4000);
    },
    [dispatch, reset, getValues, runAutoplay]
  );

  const useChange = useCallback(
    (name: 'betPerLine' | 'selectedLines' | 'autoplay') => (value: string | number) => {
      if (name === 'selectedLines') dispatch(resetGame());
      setValue(name, value);
    },
    [dispatch, setValue]
  );

  const onResetAutoPlay = useCallback(() => {
    clearTimeout(autoplayTimeout.current);
    autoplayTimeout.current = null;
    reset({ ...getValues(), autoplay: null });
  }, [reset, getValues]);

  useEffect(() => {
    if (gameLeft === null || isFinite(gameLeft)) {
      clearTimeout(autoplayTimeout.current);
      autoplayTimeout.current = null;
      reset({ ...getValues(), autoplay: null }, { isSubmitted: false });
    }
  }, [gameLeft, reset, getValues]);

  useEffect(() => {
    return () => {
      clearTimeout(autoplayTimeout.current);
      clearTimeout(autoplayDelay.current);
    };
  }, []);

  const disabled = gameLeft > 0 || gameLeft === null || formState.isSubmitted;

  return (
    <Container>
      <span>{t('lines')}</span>
      <span>{t('Bet per line')}</span>
      <ValueSelector
        value={values.selectedLines}
        maxValue={9}
        minValue={1}
        setValue={useChange('selectedLines')}
        disabled={disabled}
      />
      <CurrencyInput
        value={values.betPerLine}
        maxValue={settings.max_bet / 100}
        minValue={settings.min_bet / 100}
        setValue={useChange('betPerLine')}
        disabled={disabled}
      />
      <AutoplayGameController />
      {user.id ? (
        <>
          {!!autoplayTimeout.current ? (
            <Button key="1" type="button" className="primary" onClick={onResetAutoPlay}>
              {t('Stop')}
            </Button>
          ) : (
            <Button
              key="2"
              type="submit"
              className="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={disabled}
            >
              {!!values.autoplay && <>{t('Start')}</>}
              {!values.autoplay && (
                <>
                  {t('Bid amount', {
                    amount: values.selectedLines * (values.betPerLine * 100),
                    postProcess: 'with-template',
                  })}
                </>
              )}
            </Button>
          )}
        </>
      ) : (
        <Button
          type="button"
          className="primary"
          onClick={handleToggleDialog(AuthDialogs.SIGN_IN, true)}
        >
          {t('Bid amount', {
            amount: values.selectedLines * (values.betPerLine * 100),
            postProcess: 'with-template',
          })}
        </Button>
      )}
      <span>
        <CurrencyText value={settings.min_bet} />
        {' - '}
        <CurrencyText value={settings.max_bet} />
      </span>
    </Container>
  );
};

const Container = styled.div`
  align-self: center;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-template-rows: 1rem repeat(2, 2.5rem) min-content;
  grid-gap: 0.375rem;
  padding: 1.25rem 1rem 1rem;

  @media screen and (min-width: 320px) {
    grid-template-rows: 1rem repeat(2, 2.75rem) min-content;
  }

  & > button {
    height: auto;
  }

  & > span {
    justify-self: self-start;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--color-gray-200);

    &:last-of-type {
      justify-self: center;
      grid-column: 1 / 3;
      font-size: 0.625rem;
      line-height: 0.875rem;
      letter-spacing: 0.3px;
      font-weight: 700;
    }
  }
`;
