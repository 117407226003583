import { useMemo } from 'react';
import { Round } from '../interfaces';

interface IMaxPricesByRounds {
  [key: number]: number;
}

export const useGetMaxPrice = (rounds: Round[]) => {
  const maxPriceByRounds = useMemo(
    () =>
      rounds.reduce((acc: IMaxPricesByRounds, round) => {
        const {
          roundNumber,
          result: { price },
        } = round;

        if (!(roundNumber in acc) || price > acc[roundNumber]) {
          return {
            ...acc,
            [roundNumber]: price,
          };
        }

        return acc;
      }, {}),
    [rounds]
  );

  const maxPriceGame = useMemo(() => Math.max(...Object.values(maxPriceByRounds)), [
    maxPriceByRounds,
  ]);

  return {
    maxPriceByRounds,
    maxPriceGame,
  };
};
