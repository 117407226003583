import { ReactComponent as EmptyIcon } from './empty-player.svg';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ReactComponent as CrownIcon } from './crown.svg';
import { ReactComponent as FlagIcon } from './flag.svg';
import { ReactComponent as WatchIcon } from './watch.svg';
import { ReactComponent as ReplayIcon } from './eye-outline.svg';
import { ReactComponent as RandomIcon } from './random.svg';
import { ReactComponent as AddCaseIcon } from './add-case.svg';
import { ReactComponent as RemoveIcon } from './close.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as FastCoinIcon } from './fastcoin.svg';
import { ReactComponent as BackArrow } from './back-arrow.svg';
import { ReactComponent as CheckMark } from './check-mark.svg';
import { ReactComponent as Clover } from './clover.svg';
import { ReactComponent as ArrowPrice } from './arrow-price.svg';
import { ReactComponent as Weapon } from './weapon.svg';
import { ReactComponent as RouletteLineArrow } from './roulette-line-arrow.svg';
import { ReactComponent as WinnerBackground } from './background-winner.svg';
import { ReactComponent as Joker } from './joker.svg';

import { ReactComponent as Angry } from './angry.svg';
import { ReactComponent as Arrogant } from './arrogant.svg';
import { ReactComponent as Confused } from './confused.svg';
import { ReactComponent as Embarrassed } from './embarrassed.svg';
import { ReactComponent as Greed } from './greed.svg';
import { ReactComponent as Happy } from './happy.svg';
import { ReactComponent as Kiss } from './kiss.svg';
import { ReactComponent as Sad } from './sad.svg';
import { ReactComponent as Shocked } from './shocked.svg';
import { ReactComponent as Sick } from './sick.svg';
import { ReactComponent as Surprised } from './surprised.svg';
import droppedItemAnimation from './item-dropped.gif';
import emojiHmmPath from './emojiHmm.png';
import jokerDroppedItemAnimation from './joker.gif';

export const CaseBattleIcon = {
  EmptyIcon,
  ArrowIcon,
  CrownIcon,
  FlagIcon,
  ReplayIcon,
  WatchIcon,
  RandomIcon,
  AddCaseIcon,
  RemoveIcon,
  PlusIcon,
  MinusIcon,
  FastCoinIcon,
  BackArrow,
  CheckMark,
  Clover,
  ArrowPrice,
  Weapon,
  RouletteLineArrow,
  WinnerBackground,
  Joker,
  emojiHmmPath,
  droppedItemAnimation,
  jokerDroppedItemAnimation,
};

export type EmojiIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export const EmojiIcons: Record<string, EmojiIcon> = {
  1: Happy,
  2: Confused,
  3: Surprised,
  4: Sad,
  5: Angry,
  6: Arrogant,
  7: Shocked,
  8: Sick,
  9: Greed,
  10: Embarrassed,
  11: Kiss,
};
