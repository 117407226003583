import { combineReducers } from 'redux';
import { getUser, getUserBalance } from '../../core/User/selectors';
import { getAppFilterRegex } from '../../core/AppShell/selectors';
import { addNotification } from '../../modules/Notifications/duck';

const prefix = 'app/crash-game/';

// Actions
const INIT_GAME = `${prefix}INIT_GAME`;
const UPDATE_GAME = `${prefix}UPDATE_GAME`;
const CLEAR_GAME = `${prefix}CLEAR_GAME`;
const UPDATE_BETS = `${prefix}UPDATE_BETS`;
const SET_BET = `${prefix}SET_BET`;
const UPDATE_HISTORY = `${prefix}UPDATE_HISTORY`;
const PONG = `${prefix}PONG`;

// game data
const data = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_GAME: {
      return payload;
    }
    case UPDATE_GAME: {
      return {
        ...state,
        ...payload,
      };
    }
    case CLEAR_GAME: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

const playerBetsByID = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BETS: {
      return action.payload.reduce(
        (dictionary, bet) => ({
          ...dictionary,
          [bet.id]: { ...dictionary[bet.id], ...bet },
        }),
        state
      );
    }
    case CLEAR_GAME: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const playerBetsIDs = (state = [], action) => {
  switch (action.type) {
    case UPDATE_BETS: {
      const ids = action.payload.map(player => player.id);
      return [...state.filter(id => !ids.includes(id)), ...ids];
    }
    case CLEAR_GAME: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const defaultBetState = {
  isSet: false,
  coef: 0,
  amount: 0,
  coefAutoStop: 0,
};

const betIsSet = (state = defaultBetState, action) => {
  switch (action.type) {
    case SET_BET: {
      const { coef, amount, coefAutoStop } = action.payload;
      return {
        isSet: true,
        coef: coef ? coef : state.coef,
        coefAutoStop: coefAutoStop,
        amount: amount ? amount / 100 : state.amount,
      };
    }
    case CLEAR_GAME: {
      return defaultBetState;
    }
    default: {
      return state;
    }
  }
};

const history = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_HISTORY:
      return [...payload, ...state];

    default:
      return state;
  }
};

const ping = (state = 0, action) => {
  switch (action.type) {
    case PONG: {
      return Date.now();
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  ping: ping,
  data: data,
  playerBetsIDs: playerBetsIDs,
  playerBetsByID: playerBetsByID,
  betIsSet: betIsSet,
  history: history,
});

export default reducer;

export const updateGame = data => (dispatch, getState) => {
  const { playerBets, history, ...gameData } = data;

  if (gameData.gameRun === false) {
    dispatch({ type: CLEAR_GAME });
  }

  if (playerBets) {
    const user = getUser(getState());
    const userBet = playerBets.find(bet => bet.playerId === user.id);

    if (userBet) {
      dispatch({
        type: SET_BET,
        payload: {
          coef: userBet.coef,
          coefAutoStop:
            userBet.coefAutoStop > userBet.coef && userBet.coef !== null
              ? userBet.coef
              : userBet.coefAutoStop,
          amount: userBet.amount,
        },
      });
    }
    const filterRegex = getAppFilterRegex(getState());
    const regex = new RegExp(filterRegex, 'gi');

    const replacePlayerBets = playerBets.map(bet => ({
      ...bet,
      playerName: bet.playerName && bet.playerName.replace(regex, '***'),
    }));

    dispatch({
      type: UPDATE_BETS,
      payload: replacePlayerBets,
    });
  }

  if (history) {
    dispatch({
      type: UPDATE_HISTORY,
      payload: history,
    });
  }

  if (!!gameData.coef) {
    dispatch({
      type: UPDATE_HISTORY,
      payload: [{ coef: gameData.coef }],
    });
  }
  dispatch({ type: UPDATE_GAME, payload: gameData });
};

export const setBet = (amount, coefAutoStop) => (dispatch, getState, { socket }) => {
  const coins = getUserBalance(getState());

  if (amount > coins) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'Not enough money on balance',
      })
    );
  }

  if (amount < 10) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'The min bet is </minBet>',
        params: { '</minBet>': 0.1 },
      })
    );
  }

  if (amount > 500000) {
    return dispatch(
      addNotification({
        type: 'error',
        header: 'Error placing a bet',
        body: 'The max bet is </maxBet>',
        params: { '</maxBet>': 5000 },
      })
    );
  }

  if (amount > 9 && (coefAutoStop === 0 || coefAutoStop > 1)) {
    socket.emit('game:crash:bet', {
      amount: amount,
      coefAutoStop: coefAutoStop,
    });
  }
};

export const stopBet = coefficient => (dispatch, getState, { socket }) => {
  socket.emit('game:crash:betStop', { coef: coefficient });
};

export const fetchPing = () => (dispatch, getState, { socket }) => socket.emit('game:crash:ping');

export const updatePing = () => ({
  type: PONG,
});

export const eventsTypes = [
  { event: 'rooms:crash:update', action: updateGame },
  { event: 'rooms:crash:ponge', action: updatePing },
];
