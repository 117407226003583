import * as React from 'react';
import styled from 'styled-components';
import BezierEasing from 'bezier-easing';
import { animated, useChain, useSpring } from 'react-spring';

import { createRaffleTape, rafflingTime, slotHeight } from '../../../../fast.utils';
import { getUserAvatarUrl } from 'core/utils/image.utils';

import { PlayerAvatar } from '@components/Common';
import { UserBlock } from '../../styles';
import { WinnerPlayer } from './WinnerPlayer';
import { RafflePointer } from './RafflePointer';
import { useSortedPlayers } from '../../../../hooks';

import { ITrades } from '../../../../interfaces/reducer.fast.interface';

export interface IRaffleTape {
  trades: ITrades;
  winner: number;
}

const { useState, useEffect, useRef } = React;

export const RaffleTape: React.FC<IRaffleTape> = ({ trades, winner }) => {
  const [raffleTape, setRaffleTape] = useState<string[]>([]);

  const { sortedPlayers } = useSortedPlayers(Object.keys(trades), winner);

  const raffleRef = useRef(null);
  const winnerRef = useRef(null);

  useEffect(() => {
    setRaffleTape(createRaffleTape(Object.keys(trades)));
  }, [trades]);

  const tapeProps = useSpring({
    to: {
      transform: `translateX(-${slotHeight * raffleTape.length}px)`,
    },
    from: { transform: 'translateX(0px)' },
    config: {
      duration: rafflingTime,
      easing: BezierEasing(0.165, 0.84, 0.44, 1),
    },
    ref: raffleRef,
  });

  useChain([raffleRef, winnerRef]);

  return (
    <>
      <RaffleContainer style={tapeProps}>
        {raffleTape.map((userId: string, index) => (
          <UserBlock key={userId + index}>
            <PlayerAvatar uri={getUserAvatarUrl(trades[userId].playerImage)} />
          </UserBlock>
        ))}
        <UserBlock>
          <PlayerAvatar
            uri={trades[sortedPlayers[0]].playerImage}
            userId={trades[sortedPlayers[0]].playerId}
          />
        </UserBlock>
        <UserBlock>
          <WinnerPlayer refs={winnerRef} trades={trades} winner={winner} />
        </UserBlock>
        <UserBlock>
          <PlayerAvatar
            uri={trades[sortedPlayers[2]].playerImage}
            userId={trades[sortedPlayers[2]].playerId}
          />{' '}
        </UserBlock>
      </RaffleContainer>
      <RafflePointer />
    </>
  );
};

const RaffleContainer = styled(animated.div)`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0;
  align-items: flex-start;
  will-change: transform;
  height: 66px;
  padding-top: 0.5rem;
`;
