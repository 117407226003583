import React, { ChangeEvent, FC, MouseEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { useDialog } from 'core/hooks';
import { BIDDING_STATUSES, FINISH_STATUSES, OrderStatusEnum } from '../../../../../../configs';
import { useSellingControllers, useSellingDelete, useSellingResume } from '../../../../../../hooks';
import { MarketplaceDialogs } from '../../../../../../marketplace-dialogs.config';

interface IOrdersSaleElementControllers {
  status: OrderStatusEnum;
  id: number;
  disabled: boolean;
}

export const OrdersSaleElementControllers: FC<IOrdersSaleElementControllers> = ({
  status,
  id,
  disabled,
}) => {
  const { t } = useTranslation('marketplace');
  const [checked, set] = useState<boolean>(false);
  const { handleSellingResume } = useSellingResume();
  const { handleSellingDelete } = useSellingDelete();
  const { handleTradeAction } = useSellingControllers();
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.INVENTORY_SELLER_TRADE_DIALOG);

  const onChange = useCallback((e: MouseEvent | ChangeEvent) => {
    e.stopPropagation();
    set(v => !v);
  }, []);

  return (
    <Container>
      {BIDDING_STATUSES.includes(status) && (
        <>
          {OrderStatusEnum.NEW === status ? (
            <span className="searching-button">{t('Searching for buyer')}</span>
          ) : (
            <>
              <input type="checkbox" checked={checked} onChange={onChange} id={`sell-now-${id}`} />
              <Button as="label" onClick={onChange} className="secondary toggle-close-label">
                <i className="icon-close" />
              </Button>
              <div className="toggle-wrapper">
                <Button as="label" onClick={onChange} className="primary toggle-open-label">
                  {t('Sell now!')}
                </Button>
                <Button
                  className="primary sale-now-button"
                  onClick={handleTradeAction(id, 'sell-now')}
                >
                  {t('Accept')}
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {OrderStatusEnum.WAIT_FOR_CONFIRM === status && (
        <>
          <Button className="dark decline-button" onClick={handleTradeAction(id, 'decline')}>
            <i className="icon-close" />
          </Button>
          <Button className="primary confirm-button" onClick={handleTradeAction(id, 'confirm')}>
            {t('Confirm')}
          </Button>
        </>
      )}
      {(OrderStatusEnum.WAIT_FOR_TRADE === status ||
        OrderStatusEnum.WAIT_FOR_TRADE_CONFIRM === status) && (
        <Button
          className="primary"
          onClick={handleToggleDialog(MarketplaceDialogs.INVENTORY_SELLER_TRADE_DIALOG, {
            id,
            immediate: true,
          })}
        >
          {t('Send trade')}
        </Button>
      )}
      {OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT === status && (
        <span className="searching-button">{t('Waiting for buyer`s reply')}</span>
      )}
      {OrderStatusEnum.ORDER_CRATED === status && (
        <div className="statuses">
          <span>{t('purchase-statuses.order-crated')}</span>
        </div>
      )}
      {OrderStatusEnum.ORDER_CRATED === status && (
        <div className="statuses">
          <span>{t('purchase-statuses.order-crated')}</span>
        </div>
      )}
      {OrderStatusEnum.PAUSED === status && (
        <Button className="primary" onClick={handleSellingResume(id)} disabled={disabled}>
          {t('Resume')}
        </Button>
      )}
      {FINISH_STATUSES.includes(status) && (
        <Button className="primary-outline" onClick={handleSellingDelete(id)}>
          {t('close')}
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  grid-area: controllers;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
  grid-template-rows: 2rem;

  & input {
    display: none;
  }

  & input:checked ~ .toggle-wrapper .sale-now-button {
    opacity: 1;
    pointer-events: auto;
  }

  & input:checked ~ .toggle-wrapper .toggle-open-label {
    opacity: 0;
    pointer-events: none;
  }
  & input:checked ~ .toggle-close-label {
    width: 2rem;
    margin-right: 0.5rem;
    transform: scale(1);
  }

  & .searching-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    background: #253138;
    border: 1px solid #131a1d;
    box-sizing: border-box;
    border-radius: var(--size-radius-m);

    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    color: #47555f;
  }

  & .decline-button {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    padding: 0;

    & i {
      font-size: 1.25rem;
    }
  }

  & .toggle-close-label {
    width: 0;
    height: 2rem;
    padding: 0;
    margin-right: 0;
    transform: scale(0);
    transition: all 70ms ease-in-out;

    & i {
      font-size: 1rem;
      color: var(--color-white);
    }
  }

  & .toggle-wrapper {
    position: relative;
    transition: all 70ms ease-in-out;

    & .toggle-open-label,
    & .sale-now-button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 70ms ease-in-out;
    }

    & .toggle-open-label {
      opacity: 1;
      height: 2rem;
      pointer-events: auto;
    }

    & .sale-now-button {
      opacity: 0;
      pointer-events: none;
    }
  }

  & .toggle-open-label,
  & .sale-now-button,
  & button {
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
