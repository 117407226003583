import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ISignIn {
  handlePushTo: () => void;
}

export const SignIn: React.FC<ISignIn> = ({ handlePushTo }) => {
  const { t } = useTranslation();
  return (
    <SignInLink onClick={handlePushTo} as="a">
      <div>
        <i className="icon-login" />
      </div>
      <div>
        <span>{t('Log in')}</span>
        <span>{t('try your luck!')}</span>
      </div>
    </SignInLink>
  );
};

export const SignInLink = styled.button`
  display: flex;
  flex-flow: row nowrap;
  padding: 0.8rem;
  height: 61px;
  background-color: var(--yellow);
  flex-shrink: 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: column;

    &:first-of-type {
      justify-content: center;
      align-self: center;
      flex-basis: 30px;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      margin-right: 0.6rem;
    }

    & > i[class='icon-login'] {
      font-size: 1.4rem;
      color: var(--color-dark-600);
    }

    & > span {
      font-size: 0.85rem;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--color-dark-600);
    }
  }
`;
