import { CreateReferralCompanyDialog } from './create-referral-company-dialog';
import { CustomizationReferralCampaignDialog } from './customization-referral-campaign-dialog';
import { transitions, DIALOG } from 'dialogs';
import { profileModals } from '../../../modal.config';

DIALOG.add(profileModals.CREATE_REFERRAL_COMPANY, {
  component: CreateReferralCompanyDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'profile-portal',
});

DIALOG.add(profileModals.CUSTOMIZATION_REFERRAL_CAMPAIGN, {
  component: CustomizationReferralCampaignDialog,
  transition: transitions.SLIDE_UP,
  anchor: 'profile-portal',
});
