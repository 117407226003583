export default {
  'Fair game': 'Uczciwa gra',
  Bet: 'Zakład',
  AUTOSTOP: 'AUTOSTOP',
  '(0 = Disabled)': '(0 = wyłączone)',
  'Bet coins': 'Ikony zakładu',
  Stop: 'Stop',
  'Wait for next round...': 'Czekaj na kolejną rundę...',
  Example: 'Na przykład',
  '!! Crash !!': '!! Awaria !!',
  'You won': 'Wygrana',
};
