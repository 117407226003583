import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const Container = styled(SkeletonElement)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background: var(--color-dark-200);
  border-radius: 50%;
  position: relative;

  @media (max-width: 350px) {
    width: 25px;
    height: 25px;
  }
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
  overflow: hidden;
  pointer-events: auto;
  border: 2px solid var(--color-free-lottery);
`;

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
`;

export const FlagIconContainer = styled(IconContainer)`
  inset: unset;
  bottom: -5px;
  right: -5px;
`;
