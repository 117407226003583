import { IDialogState } from './dialog.reducer.interface';

export enum DialogActionsType {
  DIALOG_ON = 'core/dialog/DIALOG_ON',
  DIALOG_OFF = 'core/dialog/DIALOG_OFF',
  DIALOG_CHANGE_STATE = 'core/dialog/DIALOG_CHANGE_STATE',
}

interface IDialogOnAction {
  type: typeof DialogActionsType.DIALOG_ON;
  payload: {
    name: string;
  };
}

interface IDialogOffAction {
  type: typeof DialogActionsType.DIALOG_OFF;
  payload: {
    name: string;
  };
}

interface IDialogChangeStateAction {
  type: typeof DialogActionsType.DIALOG_CHANGE_STATE;
  payload: {
    name: string;
    value: IDialogState;
  };
}

export type dialogActions = IDialogOnAction | IDialogOffAction | IDialogChangeStateAction;
