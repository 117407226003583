export default {
  line: 'Linje',
  lines: 'Linjer',
  Autoplay: 'Autospel',
  Paytable: 'Vinsttabell',
  'Recent wins': 'Senaste vinster',
  'Your games': 'Dina spel',
  'Bonus game': 'Bonusspel',
  'Slot works regardless of location': 'Slot fungerar oavsett<br/> plats',
  'Can substitute any slot in the line (Except for bonus slots)':
    'Kan ersätta vilken slot som helst i linjen (Except for bonus slots)',
  'Collect all three slots and get 10 bonus games!': 'Samla alla tre slots och få 10 bonusspel!',
  'Total won': 'Total vinst',
  'Bonus games': 'Bonusspel',
};
