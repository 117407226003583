import reducer, { eventsTypes } from './duck';
import './locales';

export * from './hooks';

export const notificationModule = {
  moduleName: 'notifications',
  reducer: reducer,
  eventsTypes: eventsTypes,
};
