/* eslint-disable no-template-curly-in-string */
export default {
  GAME: 'Spiel',
  Chat: 'Chatroom',
  'Email Support': 'E-Mail-Unterstützung',
  'Live Support Chat': 'Live-Unterstützung Chat',
  History: 'Spielverlauf',
  Top: 'Top-Spieler',
  Rules: 'Regln',
  'At stake': 'Alle Wetteinsätze:',

  Amount: 'Menge',
  Accept: 'Akzeptieren',

  'To write a message you need to sign in':
    'Um eine Nachricht schreiben zu können, müssen Sie angemeldet sein',
  'Only players who have played the game are allowed to write':
    'Nur Teilnehmer des Spiels dürfen Nachrichten schreiben',
  'You are banned': 'Sie sind gesperrt',
  'Time left': 'Verbliebene Zeit',

  'Time to summarizing': 'Zeit für die zusammenfassung',

  spam: 'Spam',
  'advertising (auto)': 'Werbung (autom.)',
  advertising: 'Werbung',
  rudeness: 'Beleidigung',
  begging: 'Betteln',

  Search: 'Suche',
  Sound: 'Soundeinstellungen',

  Today: 'Heute',
  Yesterday: 'Gestern',
  'Last year': 'Letztes Jahr',

  'Show my wins': 'Meine Gewinne anzeige',

  'History is empty': 'Verlauf ist leer',

  Cancel: 'Abbrechen',
  Delete: 'Löschen',
  Close: 'Schließen',
  Confirm: 'Bestätigen',

  'Are you sure to delete all notifications?':
    'Bist du sicher, dass du alle Benachrichtigungen löschen möchtest?',

  'Log in': 'Anmelden',
  'Log in to try your luck': 'Melden Sie sich an und versuchen Sie Ihr Glück',

  'try your luck!': 'Versuche dein Glück!',

  'Take your prize': 'Gewinn abholen',
  'Sell for': 'verkaufen für: ',
  BACK: 'Rückkehr',
  'Warning!': 'WARNUNG!',

  'Congratulations!': 'Herzlichen Glückwunsch!',
  'You have won:': 'Sie haben gewonnen:',
  'Congratulations! A trade offer with your prize has been sent to your account.':
    'Herzlichen Glückwunsch! Es wurde ein Handelsangebot mit Ihrem Preis an Ihr Konto gesendet.',
  'BEWARE OF SCAMMERS! We do not have any profiles in steam, only e-mail support. We may never ask you for items or account data.':
    'Hüten Sie sich vor Betrügern! Wir haben keine Steam-Profile, sondern nur einen E-Mail-Support. Wir werden Sie nie nach Ihren Kontoinformationen fragen.',
  'The trade will be cancelled after one hour.': 'Der Handel wird nach einer Stunde abgelehnt',
  'After your order is processed you can collect your winnings on the submissions page of the Steam exchange':
    'Nachdem Ihre Bestellung bearbeitet wurde, können Sie Ihre Gewinne auf der Anmeldeseite der Steam-Börse abholen',

  'Round number': 'Rundennummer',
  'Round hash': 'Runden-Hash',
  'Hash salt': 'Hash salt',

  'Refill amount': 'Nachfüllbetrag',
  Bonus: 'Bonus',
  'Bonus experience': 'Bonus-Erfahrung',
  'Bonus wheel spins': 'Bonus-Raddrehungen',
  'Bonus cases': 'Bonus-Kisten',

  'Trick or treat?': 'Süßes oder Saures?',
  'Keep your halloween tip jars ready!': 'Halten Sie Ihre Halloween-Gläser bereit!',
  'Bonuses on every balance refill': 'Boni auf jede Nachfüllung',

  '${currency(amount)} bonus is ready!': '${currency(amount)} Bonus ist fertig!',
  'Refill balance & get up to ${currency(amount)} in free spins and cases':
    'Füllen Sie das Guthaben nach und erhalten Sie bis zu ${currency(amount)} Freispiele und Hüllen',
  'Get bonus': 'jetzt holen',
};
