import React from 'react';
import {
  Currency,
  Image,
  Line,
  Name,
  RevisionItemContainer,
  Skin,
  SkinPriceImageWrapper,
  SkinPriceWrapper,
  Range,
  Chance,
} from './revision-items.styles';
import { CurrencyText } from '@components/currency';
import { skinItemImage } from 'core/utils/skinItemImage.utils';

interface RevisionItemProps {
  img: string;
  name: string;
  price: number;
  skin: string;
  rangeFrom: number;
  rangeTo: number;
  chance: string;
  index: number;
}

export const RevisionItem: React.FC<RevisionItemProps> = ({
  img,
  name,
  price,
  skin,
  rangeFrom,
  rangeTo,
  chance,
  index,
}) => {
  return (
    <RevisionItemContainer even={index % 2 === 1}>
      <SkinPriceImageWrapper>
        <Image src={skinItemImage(img)} />
        <Line />
        <SkinPriceWrapper>
          <Name>{name}</Name>
          <Skin>{skin}</Skin>
          <Currency>
            <CurrencyText value={price} />
          </Currency>
        </SkinPriceWrapper>
      </SkinPriceImageWrapper>
      <Range>
        {rangeFrom}-{rangeTo}
      </Range>
      <Chance>{chance}</Chance>
    </RevisionItemContainer>
  );
};
