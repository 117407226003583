import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div`
  align-items: center;
  background: var(--color-dark-200);
  display: flex;
  padding: 14px 20px;
  width: 100%;

  & > .joker {
    height: 24px;
    width: 24px;

    path {
      fill: var(--pink-100);
    }
  }

  & > .close {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    margin-left: auto;
    padding: 0 0 0 6px;
    text-transform: capitalize;
  }
`;

export const EmojiImg = styled.img`
  height: 24px;
  margin: 0 10px;
  width: 24px;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.29px;
  line-height: 16px;
`;

export const Wrapper = styled.div<{ open: boolean; status: string }>`
  animation: ${({ status }) => (status === 'hide' ? 'hide-slider 1500ms ease forwards' : '')};
  background: var(--color-dark-100);
  overflow: ${({ open }) => (open ? 'visible' : 'hidden')};
  padding: 28px 24px;

  & .description {
    &.show {
      animation: show 300ms ease-in-out;

      @keyframes show {
        0% {
          opacity: 0;
          transform: translateY(10px);
        }

        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    & > span {
      color: var(--color-white);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 28px;

      & > .jokerName {
        color: var(--pink-100);
      }
    }
  }

  @keyframes hide-slider {
    0% {
      overflow: visible;
    }

    100% {
      overflow: hidden;
    }
  }
`;

export const Shadow = styled(animated.div)`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  position: absolute;
  width: 100%;
  z-index: -1;
`;
