import { combineReducers } from 'redux';
import { addNotification } from '../Notifications/duck';

import {
  getMarketplaceFilterValues,
  getMarketplaceGameTypes,
  getMarketplaceMode,
  getMarketplacePagination,
  getMarketplaceType,
} from './selectors';

import { getCrossGameId } from '../../core/CrossServices/selectors';
import { getAppGameTypes } from '../../core/AppShell/selectors';
import { getUser } from '../../core/User/selectors';

/** ACTIONS **/

const prefix = 'app/marketplace/';

const SWITCH_MODE = `${prefix}SWITCH_MODE`;
const SWITCH_TYPE = `${prefix}SWITCH_TYPE`;

const REQUEST_MARKET = `${prefix}REQUEST_MARKET`;
const UPDATE_MARKET = `${prefix}UPDATE_MARKET`;
const CLEAR_MARKET = `${prefix}CLEAR_MARKET`;
const REQUEST_MARKET_FAIL = `${prefix}REQUEST_MARKET_FAIL`;

const UPDATE_PAGINATION_COUNT = `${prefix}UPDATE_PAGINATION_COUNT`;
const UP_CURRENT_PAGE = `${prefix}UP_CURRENT_PAGE`;
const CHANGE_FILTER = `${prefix}CHANGE_FILTER`;
const CLEAR_FILTER = `${prefix}CLEAR_FILTER`;

const ADD_ITEM = `${prefix}ADD_ITEM`;
const CLEAR_CART = `${prefix}CLEAR_CART`;

const UPDATE_GAME_TYPES = `${prefix}UPDATE_GAME_TYPES`;

/** REDUCERS **/

const mode = (state = 'market', action) => {
  switch (action.type) {
    case SWITCH_MODE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const type = (state = 'virtual', action) => {
  switch (action.type) {
    case SWITCH_TYPE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const itemsIDs = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MARKET: {
      return [...new Set([...state, ...Object.keys(action.payload)])];
    }
    case CLEAR_MARKET: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const gameTypesState = {
  730: {
    name: 'CS:GO',
    appid: 730,
    stem_appid: 730,
    disableMode: ['classic', 'fast', 'craft'],
    success: false,
    error: false,
  },
  570: {
    name: 'Dota 2',
    appid: 570,
    stem_appid: 570,
    disableMode: ['craft'],
    success: false,
    error: false,
  },
  1912: {
    name: 'VGO',
    appid: 2,
    stem_appid: 1912,
    disableMode: ['craft'],
    success: false,
    error: false,
  },
};

const gameTypes = (state = gameTypesState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_GAME_TYPES:
      return Object.keys(state).reduce((dictionary, key) => {
        if (!payload[key]) return dictionary;
        const { success, error, link } = payload[key];
        return {
          ...dictionary,
          [key]: {
            ...state[key],
            success,
            error,
            link,
          },
        };
      }, gameTypesState);

    default:
      return state;
  }
};

const itemsByID = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MARKET: {
      return {
        ...state,
        ...action.payload,
      };
    }
    // case LOGOUT:
    case CLEAR_MARKET: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const initialFilterState = {
  sortDirection: 'up',
  name: '',
  maxPrice: 0,
  minPrice: 0,
  currentGameTypes: 570,
  skinpay: false,
};

const filterValues = (state = initialFilterState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_FILTER:
      return { ...state, ...payload };

    // case LOGOUT:
    case CLEAR_FILTER:
      return initialFilterState;

    default:
      return state;
  }
};

const paginationCount = (state = 0, action) => {
  switch (action.type) {
    case UPDATE_PAGINATION_COUNT: {
      return action.payload;
    }
    case CLEAR_MARKET: {
      return 0;
    }
    default: {
      return state;
    }
  }
};

const currentPaginationPage = (state = 1, action) => {
  switch (action.type) {
    case UP_CURRENT_PAGE: {
      return action.payload;
    }
    case CLEAR_MARKET: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const cartItemByID = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_ITEM:
      return payload.id;

    // case LOGOUT:
    case CLEAR_MARKET:
    case CLEAR_CART:
      return null;

    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case UPDATE_MARKET:
      return false;

    case REQUEST_MARKET:
      return true;

    case REQUEST_MARKET_FAIL:
      return false;

    default:
      return state;
  }
};

const reducer = combineReducers({
  mode: mode,
  type: type,
  isFetching: isFetching,
  itemsByID: itemsByID,
  itemsIDs: itemsIDs,
  gameTypes: gameTypes,
  cartByID: cartItemByID,
  pagination: combineReducers({
    count: paginationCount,
    current: currentPaginationPage,
  }),
  filterValues: filterValues,
});

export default reducer;

/** ACTION CREATORS **/

export const loadMarket = (type, mode) => (dispatch, getState) => {
  dispatch({ type: CLEAR_MARKET });
  dispatch({ type: CLEAR_FILTER });

  dispatch({ type: SWITCH_TYPE, payload: type });
  dispatch({ type: SWITCH_MODE, payload: mode });

  if (type === 'inventory') {
    dispatch(requestUserInventory(true));
  } else {
    dispatch(requestMarket(true));
  }
};

export const subscribe = () => (dispatch, getState, { socket }) => {
  socket.emit('steam-store:join');
};

export const unsubscribe = () => (dispatch, getState, { socket }) => {
  socket.emit('steam-store:leave');
};

export const clearFilter = () => dispatch => {
  dispatch({ type: CLEAR_MARKET });
  dispatch({ type: CLEAR_FILTER });
};

// разные кусочки обновления фильтр клеятся по исходной модели, представенной/
// в initialFilterState, там много фильтрации по оружию, она сейчас на сайте в умирающей стадии
// но еще не отказались от нее
export const updateFilter = update => ({
  type: CHANGE_FILTER,
  payload: update,
});

// через него шлются запросы на сервер в магазин К.О.
// мерджит модель, фильтр и реквест из аргументов в единый запрос.
// если reset - то ответ перетирает имеющиеся вещи,
// если нет - получается пагинационный запрос и вещи добавляются в список
const requestModel = {
  subTypes: [],
  types: [],
  categories: [],
  rarities: [],
  itemsOnPage: 60,
  lang: 'en-us',
  name: '',
  page: 1,
  sortDirection: 'down',
  sortField: 'itemPrice',
};

export const requestMarket = reset => (dispatch, getState, { socket }) => {
  if (reset) {
    dispatch({ type: CLEAR_MARKET });
  }

  const mode = getMarketplaceMode(getState());
  const type = getMarketplaceType(getState());
  const gameId = getCrossGameId(getState());
  const AppGameTypes = getAppGameTypes(getState());
  const gameTypes = getMarketplaceGameTypes(getState());
  const { current } = getMarketplacePagination(getState());

  const {
    currentGameTypes,
    sortDirection,
    name,
    maxPrice,
    minPrice,
    skinpay,
  } = getMarketplaceFilterValues(getState());

  const filter = {
    sortDirection: sortDirection,
    name: name,
    maxPrice: maxPrice === 0 ? false : Number(maxPrice),
    minPrice: minPrice === 0 ? false : Number(minPrice),
    gameTypeId: 33,
    skinpay,
  };

  dispatch({ type: REQUEST_MARKET });

  const params = { ...requestModel, ...filter, ...{ page: current } };

  if (mode === 'market') {
    return socket.emit('steam-store:items:get', {
      ...params,
      appid: gameTypes[currentGameTypes].appid,
    });
  }

  if (type === 'virtual' && mode !== 'craft') {
    const gameTypeId = AppGameTypes[mode].id;
    return socket.emit('inventory:get-chips', { gameTypeId });
  }

  if (type === 'virtual' && mode === 'craft') {
    return socket.emit('virtual-store:get-items', {
      ...params,
      gameTypeId: 9,
      appid: gameId,
    });
  }
};

// Основной двигатель обнлвления магазина.
// Мерджит то, что пользователь выбрал в корзину с новыйми запросами
// также через него идут все запросы в виртуальный стор
// и автоселект тоже через него.
// всего в 30 строках кода! (без пробелов)
export const updateMarket = (update, virtual) => (dispatch, getState) => {
  const user = getUser(getState());
  if (Array.isArray(update)) {
    const itemsDictionary = update.reduce((dictionary, item) => {
      return {
        ...dictionary,
        [item.id || item.itemName]: {
          id: item.id || item.itemName,
          icon: item.icon || item.itemImage,
          value: item.value || item.itemPrice || item.price || 0,
          virtual: !!virtual,
          passed: virtual ? user.coins > (item.value || item.itemPrice) : true,
          name: item.name || item.itemName,
          ...item,
        },
      };
    }, {});
    dispatch({ type: UPDATE_MARKET, payload: itemsDictionary });
  }
};

// вынесен в отдельную функцию ввиду того, что пагинации у запроса инвентаря нет.
// так что все вещи приходят сразу
// вещи запрашиваются по id игры. Так что на каждый таб - отдельный запрос
// reset - стирает старые вещи
export const requestUserInventory = reset => (dispatch, getState, { socket }) => {
  if (reset) {
    dispatch({ type: CLEAR_MARKET });
    dispatch({ type: REQUEST_MARKET });
  }
  const mode = getMarketplaceMode(getState());

  if (mode === 'refill') {
    return socket.emit('inventory:get-items', {
      gameTypeId: 3,
    });
  }

  const AppGameTypes = getAppGameTypes(getState());
  const gameTypeId = AppGameTypes[mode].id;
  socket.emit('inventory:get-items', {
    gameTypeId,
  });
};

// обновление пользовательского инвентаря
// все мапится под привычный магазину вид
// если больше предметов в сумке больше чем maxEqualItems - они игнорируются
// (чтобы пользователь не видел 100 одинаковых предметов из своей сумки, группировка в одну ячейку неудобна из-за дизайна)
export const updateUserInventory = update => (dispatch, getState) => {
  const { currentGameTypes } = getMarketplaceFilterValues(getState());
  if (update.status[currentGameTypes] && update[currentGameTypes].success) {
    dispatch(updateMarket(update[currentGameTypes].items));
  }

  if (!update.status[currentGameTypes]) {
    dispatch({ type: REQUEST_MARKET_FAIL });
  }

  const { ...gameTypesConfig } = update;
  dispatch({
    type: UPDATE_GAME_TYPES,
    payload: gameTypesConfig,
  });
};

export const updatePaginationPage = newPage => ({
  type: UP_CURRENT_PAGE,
  payload: newPage,
});

// функция добавления предмета в корзину из магазина
export const addItemToCart = id => ({ type: ADD_ITEM, payload: { id } });

export const removeItemFromCart = () => ({
  type: CLEAR_CART,
});

// подтверждение покупки
export const confirmBuying = id => (dispatch, getState, { socket }) => {
  socket.emit('steam-store:buy', [id]);
};

export const cleanUpMarket = () => ({
  type: CLEAR_MARKET,
});

export const confirmBet = (gameTypeId, itemsArr) => (dispatch, getState, { socket }) => {
  socket.emit('virtual-store:bet', { gameTypeId, itemsArr });
};

export const confirmInventoryBet = (gameTypeId, appid, itemsId) => (
  dispatch,
  getState,
  { socket }
) => {
  socket.emit('inventory:bet', { gameTypeId, appid, itemsId });
};

const virtualItemsUpdate = data => dispatch => {
  dispatch(updateMarket(data.items, false));
  if (data.maxPages) dispatch({ type: UPDATE_PAGINATION_COUNT, payload: data.maxPages });
};

const cheapItemsUpdate = data => dispatch => dispatch(updateMarket(data, true));

const steamItemsUpdate = data => dispatch => {
  dispatch(updateMarket(data.items, false));
  if (data.maxPages) dispatch({ type: UPDATE_PAGINATION_COUNT, payload: data.maxPages });
};

const steamBuyRes = () => dispatch => {
  dispatch(
    addNotification({
      type: 'success',
      header: 'Congratulations!',
      description: 'Your purchase will be delivered within 15 minutes.',
    })
  );
};

export const eventsTypes = [
  { event: 'inventory:items', action: updateUserInventory }, // user skins
  { event: 'steam-store:items:response', action: steamItemsUpdate }, //steam skins
  { event: 'steam-store:buy:result', action: steamBuyRes },
  { event: 'virtual-store:items', action: virtualItemsUpdate },
  { event: 'inventory:chips', action: cheapItemsUpdate }, // cheap
];
