import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchResumeSelling } from '../../ducks/sales.duck';

export const useSellingResume = () => {
  const dispatch = useDispatch();

  const handleSellingResume = useCallback(
    (id: number) => (e: any) => {
      e?.stopPropagation();
      dispatch(fetchResumeSelling(id));
    },
    [dispatch]
  );

  return {
    handleSellingResume,
  };
};
