import React, { useRef } from 'react';
import {
  Container,
  GrayComponent,
  NameSpan,
  PriceContainer,
  TextContainer,
} from './CaseWhereLocatedItem.styles';
import { CurrencyText } from '@components/currency';
import { useHistory } from 'react-router-dom';

interface CaseWhereLocatedItem {
  id: number;
  price: number;
  name: string;
  backgroundImage: string;
  type: string;
}

export const CaseWhereLocatedItem: React.FC<CaseWhereLocatedItem> = ({
  id,
  price,
  name,
  backgroundImage,
  type,
}) => {
  const history = useHistory();
  const windowSize = useRef(window.innerWidth);

  const redirectHandler = () => {
    if (type !== 'free') {
      history.push(`/cases/${id}`);
    }
  };

  return (
    <Container aria-disabled={type === 'free'} url={backgroundImage} onClick={redirectHandler}>
      <TextContainer>
        {windowSize.current > 320 ? (
          <>
            <NameSpan>{name}</NameSpan>
            <PriceContainer>{type !== 'free' && <CurrencyText value={price} />}</PriceContainer>
          </>
        ) : null}
      </TextContainer>
      {type === 'free' && <GrayComponent />}
    </Container>
  );
};
