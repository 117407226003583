import React, { FC } from 'react';
import styled from 'styled-components';

import { StoreSkin } from './StoreSkin';

import { IStoreProduct } from '../../interfaces/reducer.leaders.interfaces';
import { useModal } from 'core/Modals/hooks';
import { leaderModals } from '../../modal.config';

interface IStoreProducts {
  items: IStoreProduct[];
  // appId: SteamAppId
  // goldItems: IStoreProduct[]
}

export const StoreProducts: FC<IStoreProducts> = ({
  items,
  // appId,
  // goldItems,
}) => {
  const { handleToggleModal } = useModal(leaderModals.PURCHASE);
  return (
    <Container>
      {/*{appId === SteamAppId.CSGO &&*/}
      {/*  goldItems.map((item, index) => (*/}
      {/*    <StoreSkin*/}
      {/*      key={item.name + index}*/}
      {/*      {...item}*/}
      {/*      handleShowModal={handleToggleModal(leaderModals.PURCHASE, [*/}
      {/*        SteamAppId.GOLD,*/}
      {/*        'items',*/}
      {/*        index,*/}
      {/*      ])}*/}
      {/*    />*/}
      {/*  ))}*/}

      {items.map((item, index) => (
        <StoreSkin
          key={item.name + index}
          {...item}
          available={item?.available || true}
          handleShowModal={handleToggleModal(leaderModals.PURCHASE, [item.appId, 'items', index])}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  grid-auto-rows: 1fr;
  padding: 1.1rem;

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
