import { SortParam } from 'games/CaseBattle/constants';
import { CASE_BATTLE_ACTION_TYPE } from '../actionTypes';
import { ActionSetSortParam } from './sort-main-page.interfaces';

export const actionSetSortParam = (sortParam: SortParam) => ({
  type: CASE_BATTLE_ACTION_TYPE.ACTION_SET_SORT_PARAM,
  payload: sortParam,
});

export const sortMainPageReducer = (
  state: SortParam = SortParam.newest,
  action: ActionSetSortParam
) => {
  switch (action.type) {
    case CASE_BATTLE_ACTION_TYPE.ACTION_SET_SORT_PARAM: {
      return action.payload;
    }
    default:
      return state;
  }
};
