import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common';
import { AccountProfilePanel, LineChart } from '../../components';
import { useAccountModal, useAccountProfile, useAccountRaceStatistic } from '../../hooks';
import { useUser } from 'core/User/hooks';

export const PlayerProfile = () => {
  const { t } = useTranslation();
  const { handlePushToAccount } = useAccountModal();
  const { profile } = useAccountProfile();
  // const { leaders } = useAccountLeader()
  const { quests } = useAccountRaceStatistic();
  const { user } = useUser();

  return (
    <Container>
      <AccountProfilePanel {...profile} handlePushToAccount={handlePushToAccount} />
      {/*<LeaderStatistic leaders={leaders} />*/}
      <LineChart profile={profile} quests={quests} user={user} />
      <div className="modal-wrapper">
        <Button className="primary full" onClick={handlePushToAccount}>
          {t('Show profile')}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  border-top: 1px solid var(--color-dark-300);
  border-radius: 0 0 var(--size-radius-m) var(--size-radius-m);
  background: var(--color-dark-600);

  & > .modal-wrapper {
    padding: 1rem;
  }
`;
