import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { slotType } from '../../../slot.utils';

interface ISlotGameGridViewItem {
  idx: number;
  style?: CSSProperties;
}

export const SlotGameGridViewItem: FC<ISlotGameGridViewItem> = ({ idx, style }) => (
  <Container style={style}>
    <img
      src={`https://d2lomvz2jrw9ac.cloudfront.net/slot-assets/csgo/items/${slotType[idx]}.png`}
      alt=""
    />
  </Container>
);

const Container = styled.div`
  position: relative;
  margin: 0.125rem;

  &::before {
    float: left;
    padding-top: 100%;
    content: '';
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
